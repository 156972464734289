import {
  ERROR,
  PUBLISHERS_SAVE_REQUEST,
  START,
  SUCCESS,
} from "../action-types";
import { createNotification } from "../../../components/notification/notification";

export const savePublishersDataRequestStart = (data, callback) => ({
  type: `${PUBLISHERS_SAVE_REQUEST}${START}`,
  payload: data,
  callback,
});

export const savePublishersDataRequestSuccess = (data) => {
  createNotification("success", "Успешно создано");

  return {
    type: `${PUBLISHERS_SAVE_REQUEST}${SUCCESS}`,
    payload: data,
  };
};

export const savePublishersDataRequestError = (data) => {
  if (data.response.status === 500) {
    const { data: res } = data.response;
    createNotification("error", res.ERROR);
  } else createNotification("error", "Ошибка создания");

  return {
    type: `${PUBLISHERS_SAVE_REQUEST}${ERROR}`,
    payload: data,
  };
};
