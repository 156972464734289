import {ERROR, START, SUCCESS, ELECTRONIC_COURSES_ITEM_EDIT} from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const electronicCoursesItemEditStart = ({data, id, callback}) => ({
    type: `${ELECTRONIC_COURSES_ITEM_EDIT}${START}`,
    payload: {data, id, callback},
});

export const electronicCoursesItemEditSuccess = (data) => {
    createNotification('success', 'Курс успешно создан');
    return {
        type: `${ELECTRONIC_COURSES_ITEM_EDIT}${SUCCESS}`,
        payload: data
    }
};

export const electronicCoursesItemEditError = (data) => {
    createNotification('error', 'Ошибка создания курса');
    return {
        type: `${ELECTRONIC_COURSES_ITEM_EDIT}${ERROR}`,
        payload: data
    }
};

