import {call, put, takeLatest, select} from "redux-saga/effects";
import {
    ALPHABET_COUNT_REQUEST,
    ALPHABET_SINGLE_ITEM_REQUEST,
    START
} from "../../../../actions/action-types";

import {alphabetBooks, alphabetCount} from "../../../../../api/library/alphabet";
import {alphabetCountRequestError, alphabetCountRequestSuccess} from "../../../../actions/library/alphabet/count";
import {
    alphabetSingleItemRequestError, alphabetSingleItemRequestStart,
    alphabetSingleItemRequestSuccess, alphabetSingleItemSetPosition
} from "../../../../actions/library/alphabet/singleItem";


const ALL_LETTERS = "аа - ая";

export default function* alphabetItemsWatcher() {
    yield takeLatest(`${ALPHABET_COUNT_REQUEST}${START}`, alphabetCountWorker);
    yield takeLatest(`${ALPHABET_SINGLE_ITEM_REQUEST}${START}`, singleItemWorker);
}


function* singleItemWorker({payload: amount}) {
    const reduxData = yield select(({library}) => library.alphabet);
    const request = formRequest(reduxData.search);
    const count = reduxData.count;
    const currentItemPosition = reduxData.currentItem.position;
    let nextItemPosition = currentItemPosition + amount;

    if (nextItemPosition > count) {
        nextItemPosition = count;
    } else if (nextItemPosition < 1) {
        nextItemPosition = 1;
    }

    request.paginateFrom = nextItemPosition === 0 ? nextItemPosition : nextItemPosition - 1;
    try {
        yield put(alphabetSingleItemSetPosition(nextItemPosition));
        const response = yield call(alphabetBooks, request);
        yield put(alphabetSingleItemRequestSuccess(response.data));
    } catch (error) {
        yield put(alphabetSingleItemRequestError(error));
    }
}

function* alphabetCountWorker() {
    const searchData = yield select(({library}) => library.alphabet.search);
    if (searchData.letters === "") {
        searchData.letters = ALL_LETTERS;
    }
    const request = formRequest(searchData);
    try {
        const response = yield call(alphabetCount, request);
        const count = parseInt(response.data.count, 10);
        yield put(alphabetCountRequestSuccess(count));
        yield put(alphabetSingleItemRequestStart(0));
    } catch (error) {
        yield put(alphabetCountRequestError(error))
    }
}

const formRequest = (searchData) => {
    const request = {
        ...searchData,
        afrom: "",
        ato: "",
    };
    const splittedLetters = searchData.letters.split(' - ');
    request.afrom = splittedLetters[0];
    request.ato = splittedLetters[1];
    return request;
};
