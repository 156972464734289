import { ERROR, START, SUCCESS, USERS_CHANGE_PASSWORD_REQUEST } from "../action-types";

export const changeUserPasswordRequestStart = (mid, password) => ({
  type: `${USERS_CHANGE_PASSWORD_REQUEST}${START}`,
  payload: {
    mid, password
  }
});

export const changeUserPasswordRequestSuccess = (data) => ({
  type: `${USERS_CHANGE_PASSWORD_REQUEST}${SUCCESS}`,
  payload: data
});

export const changeUserPasswordRequestError = (data) => ({
  type: `${USERS_CHANGE_PASSWORD_REQUEST}${ERROR}`,
  payload: data
});