import {combineReducers} from "redux";
import {formReducer} from "./form/formReducer";
import {searchReducer} from "./search/searchReducer";
import {listReducer} from "./list/listReducer";
import {countReducer} from "./count/countReducer";
import {loaderReducer} from "./loader/loaderReducer";
import {emptyReducer} from "./empty/emptyReducer";


export const borrowingTestReducer = combineReducers({
  empty: emptyReducer,
  form: formReducer,
  search: searchReducer,
  list: listReducer,
  loader: loaderReducer,
  count: countReducer
});