import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import styles from './CoverPhotoPlaceholder.module.css';
import photoPlaceholder from '../../../../../img/noCover.png';
import { resolveBookCoverUrl } from "../../../../../utils/resolvePhotoUrl";

const CoverPhotoPlaceholder = ({url, bookId, alt}) => {
  const [src, setSrc] = useState(photoPlaceholder);

  useEffect(() => {
    setSrc(url ? resolveBookCoverUrl(url, bookId) : photoPlaceholder);
  }, [url, bookId]);

  return (
    <img onError={() => {setSrc(photoPlaceholder)}} className={styles.image} alt={alt} src={src}/>
  );
};

CoverPhotoPlaceholder.propTypes = {
  url: T.string,
  alt: T.string,
  bookId: T.string
};

export default CoverPhotoPlaceholder;
