import { api } from '../../App';

export const getBookScanOrdersCount = ({title, ...otherParams}) => {
  const params = {
    count: true,
    title,
    ...otherParams
  };

  return api.get(`/bookscanorders`, {params});
};

export const getBookScanOrders = ({title, ...otherParams}) => {
  const params = {
    title,
    ...otherParams
  };

  return api.get(`/bookscanorders`, {params});
};

export const createBookScanOrder = ({ bookId, locationId }) => {
  const params = {
    bookId,
    locationId
  };

  return api.post(`/bookscanorder`, params);
};

export const deleteBookScanOrder = (orderId) => {
  return api.delete(`/bookscanorder/${orderId}`);
};
