import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import styles from "./CodeItem.module.css";
import pencilIcon from "../../../../../../img/icons/pencil-edit-button.svg";
import trashIcon from "../../../../../../img/icons/delete-photo.svg";
import {udkList} from "../../../../../../api/udk/list";
import {bbkList} from "../../../../../../api/bbk/list";
import {svniList} from "../../../../../../api/svni/list";

const BookEditCodeItem = ({item, index, data, setData, openModal, type}) => {
    
    const [codeDecryption, setCodeDecryption] = useState('-');
    
    useEffect(() => {
        // Шифр
        codeDescriptor(0, item, true)
        .then((response) => {
            if (response.data.length) {
                const checkKey = 'description' in response.data[0] ? 'description' : 'Описание';
                setCodeDecryption(response.data[0][checkKey]);
            }
        });
    }, [item]);
    
    const codeDescriptor = (() => {
        switch (type) {
            case 'udk': return udkList;
            case 'bbk': return bbkList;
            case 'svni': return svniList;
            case 'isbn': return bbkList;
            default: return () => {}
        }
    })()

    return (
        <div className={styles.item}>
            <div className={styles.encryptionOuter}>
                <span className={styles.encryption}>{item}</span>
            </div>
            {!(type==='isbn') &&
                <div className={styles.decryptionOuter}>{codeDecryption}1</div>}
            <div className='d-flex'>
                <button
                    title="Редактировать"
                    className={styles.editButton}
                    onClick={() => openModal({index, type: type, value: item})}
                >
                    <img src={pencilIcon} alt=""/>
                </button>
                <button
                    title="Удалить"
                    className={styles.deleteButton}
                    onClick={() => {
                        let newCodeList = [...data[type]];
                        newCodeList.splice(index, 1);
                        setData({...data, [type]: newCodeList});
                    }}
                >
                    <img src={trashIcon} alt=""/>
                </button>
            </div>
        </div>
    );
};

BookEditCodeItem.propTypes = {
    item: T.string,
    index: T.number,
    data: T.object,
    type: T.string
};

export default BookEditCodeItem;
