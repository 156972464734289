import React from 'react'
import PdfPrintPanel from '../../../PDFPrint/Panel'
import jsPDF from 'jspdf'
import addFont from '../../../../fonts/fonts'

export default function SeizureActPdf ({ data, options, actData }) {
  
  const title = 'Изъятие книг из оборота'
  const orientation = 'landscape'

  

  const createDocData = async () => {
    
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    const {width, height} = doc.internal.pageSize
    let lastY = 10

    const getY = (indent, newValue) => {
      let newY = newValue ? newValue + indent : lastY + indent
      if (newY > height - 15) {
        doc.addPage('a4', orientation)
        newY = 10
      }
      lastY = newY
      return newY
    }

    doc.setFont('Montserrat')

    doc.setFontSize(14)
    doc.text('Изъятие книг из оборота', width/2, lastY, 'center')

    doc.autoTable({
      html: '#seizureActPdfTable',
      styles: { font: 'Montserrat', fontSize: 10 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      columnStyles: {
        2: { cellWidth: 100 },
      },

      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
    })

    doc.text(`Председатель комиссии:`, 15, getY(20, doc.autoTableEndPosY()))
    doc.line(15, getY(10), 285, lastY)
    doc.setFontSize(8)
      .text(`(звание, должность)`, 30, getY(5))
      .text(`(подпись)`, 140, lastY)
      .text(`(расшифровка)`, 240, lastY)
    .setFontSize(14)
    
    doc.text(`Члены комиссии:`, 15, getY(20))
    doc.line(15, getY(15), 285, lastY)
    doc.setFontSize(8)
      .text(`(звание, должность)`, 30, getY(5))
      .text(`(подпись)`, 140, lastY)
      .text(`(расшифровка)`, 240, lastY)
    .setFontSize(14)
    
    doc.line(15, getY(15), 285, lastY)
    doc.setFontSize(8)
      .text(`(звание, должность)`, 30, getY(5))
      .text(`(подпись)`, 140, lastY)
      .text(`(расшифровка)`, 240, lastY)
    .setFontSize(14)
    
    doc.line(15, getY(15), 285, lastY)
    doc.setFontSize(8)
      .text(`(звание, должность)`, 30, getY(5))
      .text(`(подпись)`, 140, lastY)
      .text(`(расшифровка)`, 240, lastY)
    .setFontSize(14)
    
    doc.line(15, getY(15), 285, lastY)
    doc.setFontSize(8)
      .text(`(звание, должность)`, 30, getY(5))
      .text(`(подпись)`, 140, lastY)
      .text(`(расшифровка)`, 240, lastY)
    .setFontSize(14)

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getNumberOfPages())
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  const renderTableRow = (book, index) => {
    const { title, id, bookid, amount } = book
    return (
      <tr key={id}>
        <td>{index+1}</td>
        <td>{title}</td>
        <td>{bookid}</td>
        <td>{amount}</td>
      </tr>
    )
  }

  const secretDestrTable = (
    <table hidden id='seizureActPdfTable'>
      <thead>
        <tr>
          <th>№ п/п</th>
          <th>Название</th>
          <th>Инвентарный номер</th>
          <th>Количество</th>
        </tr>
      </thead>
      <tbody>
        {data.map(renderTableRow)}
      </tbody>
    </table>
  )
  
  
  
  return (
    <div className='ml-auto'>
      {secretDestrTable}      
      <PdfPrintPanel loadPdf={toPdf} printPdf={toPrint} />
    </div>
  )
}
