import {combineReducers} from "redux";
import { litersCountReducer } from './count/litersCountReducer';
import { litersListReducer } from './list/litersListReducer';
import { litersLoaderReducer } from './loader/litersLoaderReducer';
import { litersFormReducer } from './form/litersFormReducer';
import { litersSearchReducer } from './search/litersSearchReducer';

export const litersReducer = combineReducers({
  count: litersCountReducer,
  list: litersListReducer,
  loader: litersLoaderReducer,
  form: litersFormReducer,
  search: litersSearchReducer,
});