import { ERROR, AUTHORS_SAVE_REQUEST, START, SUCCESS } from "../action-types";

export const saveAuthorRequestStart = (data, callback) => ({
  type: `${AUTHORS_SAVE_REQUEST}${START}`,
  payload: data, callback
});

export const saveAuthorRequestSuccess = (data) => ({
  type: `${AUTHORS_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const saveAuthorRequestError = (error) => ({
  type: `${AUTHORS_SAVE_REQUEST}${ERROR}`,
  payload: error
});

