import { Button } from 'antd'
import React, {useState} from 'react'
import { resolveDateWithTimeZone } from '../../../utils/Dates/resolveDateWithTimeZone'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'

export const AntiplagiatTable = ({ data, selectedItem, setSelectedItem, onDelete, setModalData, modalData }) => {
  return (
    <>
      {selectedItem && <Button onClick={onDelete} colorType={'red'} type='primary' danger>удалить</Button>}
      <DataTable data={data}>
        <DataTableCol 
        type='anti'
        setModalData={setModalData} title={'Название документа'} colSpan={3} value='name' />
        <DataTableCol 
        type='anti'
        setModalData={setModalData} title={'ID'} colSpan={1} value='id_page' />
        <DataTableCol 
        type='anti'
        setModalData={setModalData} title={'Заглавие'} colSpan={1} value='Title' />
        <DataTableCol 
        type='anti'
        setModalData={setModalData} title={'Автор'} colSpan={1} value='Author' />
        <DataTableCol 
        type='anti'
        setModalData={setModalData} title={'Издание'} colSpan={1} value='Publication' />
        <DataTableCol 
        type='anti'
        setModalData={setModalData} title={'Библиографическое описание'} colSpan={1} value='Description' />
        <DataTableCol
          type='anti'
          setModalData={setModalData}
          title={'Дата добавления в индекс'}
          resolver={item =>
            item.addToIndexDate ? resolveDateWithTimeZone(new Date(item.addToIndexDate).toLocaleString()) : ''
          }
        />

        <DataTableCol
         setModalData={() => {}}
          title='Выбрано'
          type='anti'
          resolver={item => (
            <input
              type='checkbox'
              checked={item.documentId === selectedItem.documentId}
              onChange={() => {
                setSelectedItem(item)
              }}
            />
          )}
        />
      </DataTable>
    </>
  )
}
