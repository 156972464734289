export const extractReasons = [
  {
    name: "Не указано",
    value: ""
  },
  {
    name: "Непрофильность",
    value: 1
  },
  {
    name: "Устарелость по содержанию",
    value: 2
  },
  {
    name: "Дефектность",
    value: 3
  },
  {
    name: "Утрата",
    value: 4
  }
];
