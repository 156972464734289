import React from 'react';
import T from 'prop-types';
import styles from './SearchAuditTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import {AUDIT_ACCESS_SORT} from "../../../constants/sort/auditAccessSort";
import {SORT_DIRECTION} from "../../../constants/sortDirections";


const SearchAuditTable = ({data, sortBy, setSort}) => (
    <div className={styles.container}>
        <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
            <DataTableCol title="Дата и время" sortParams={[AUDIT_ACCESS_SORT.EVENT_TIME, SORT_DIRECTION.DESC]}
                          value="eventtime" resolver={item => new Date(item.eventtime).toLocaleString()}
                          colSpan={2}/>
            <DataTableCol title="Название" resolver={item => (item.payloadbody.title || '-')} colSpan={4}/>
            <DataTableCol title="Автор" resolver={item => (item.payloadbody.author || '-')} colSpan={2}/>
            <DataTableCol title="Издательство" resolver={item => (item.payloadbody.publisher || '-')} colSpan={2}/>
            <DataTableCol title="ISBN" resolver={item => (item.payloadbody.isbn || '-')} colSpan={2}/>
            <DataTableCol title="Год" resolver={item => (item.payloadbody.year || '-')} colSpan={2}/>
            <DataTableCol title="UDK" resolver={item => (item.payloadbody.udk || '-')} colSpan={2}/>
            <DataTableCol title="BBK" resolver={item => (item.payloadbody.bbk || '-')} colSpan={2}/>
            <DataTableCol title="IP" sortParams={[AUDIT_ACCESS_SORT.CLIENT_IP]} value="clientip" colSpan={2}/>
        </DataTable>
    </div>
);

SearchAuditTable.propTypes = {
    data: T.array,
    sortBy: T.string,
    setSort: T.func,
};

export default SearchAuditTable;
