import { SET_DUPLICATE_SEARCH_DATA } from "../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../constants/pagination";

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const duplicateSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DUPLICATE_SEARCH_DATA:
      return action.payload;

    default:
      return state;
  }
};
