import React from 'react';
import T from 'prop-types';

import ModalInputWrapper from "../../Modal/InputWrapper";
import { Button as ButtonAnt } from 'antd'

const SDPT645 = ({options, setOptions, saveOptions, combineOptionsSave}) => {

    const onChange = (e) => {
        setOptions({...options, [e.target.name]: e.target.value});
    };


    return (
        <div>
            <div className={'formContainer'}>

                <ModalInputWrapper
                    label={'Профессорско-преподавательский состав'}
                    name={'PasportPPSLink'}
                    value={options.PasportPPSLink}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    label={'Расписание'}
                    name={'PasportScheduleLink'}
                    value={options.PasportScheduleLink}
                    onChange={onChange}
                />

            </div>

            <div className={'d-flex justify-content-end'} style={{marginTop: '1rem'}}>
                <ButtonAnt primary onClick={() => combineOptionsSave()}>Сохранить</ButtonAnt>
            </div>
        </div>
    );
};

SDPT645.propTypes = {
    options: T.object,
    setOptions: T.func,
    saveOptions: T.func,
};

export default SDPT645;