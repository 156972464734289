import {call, put, select, takeLatest} from "redux-saga/effects";
import {DOCUMENTS_SEARCH_COUNT, DOCUMENTS_SEARCH_LIST, START} from "../../../actions/action-types";
import {documentsCountRequestError, documentsCountRequestSuccess} from "../../../actions/documents/count/counterDocuments";
import {getCountDocuments, searchDocuments} from "../../../../api/documents/search";
import {documentsListRequestError, documentsListRequestStart, documentsListRequestSuccess} from "../../../actions/documents/list/action";
import {setDocumentSearchData} from "../../../actions/documents/documents/search";

export default function* documentsSearchWorker() {
    yield takeLatest(`${DOCUMENTS_SEARCH_COUNT}${START}`, documentsCountWorker);
    yield takeLatest(`${DOCUMENTS_SEARCH_LIST}${START}`, documentsListWorker);
}

function* documentsCountWorker() {
    const formData = yield select(({documents}) => documents.form);
    const searchData = yield select(({documents}) => documents.searchDataModal);
    const count = yield select(({documents}) => documents.searchModalCount);

    try {
        let response = yield call(getCountDocuments, formData);
        yield put(documentsCountRequestSuccess(parseInt(response.data.count)));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setDocumentSearchData({...searchData, pageCount}));
        yield put(documentsListRequestStart());
    } catch (error) {
        yield put(documentsCountRequestError(error));
    }
}

function* documentsListWorker() {
    const searchData = yield select(({documents}) => documents.search);

    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);

    try {
        let response = yield call(searchDocuments, searchData.maxResults, searchData.paginateFrom)
        yield put(documentsListRequestSuccess(response.data))
    } catch (error) {
        yield put(documentsListRequestError(error))
    }
}
