import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import styles from './IndexRowItem.module.css'
import { Col } from 'react-bootstrap'
import { getLibrarytypeImage } from '../../../api/library/types'
import { IMAGE_PLUG } from '../../../constants/imagePlug'

const IndexRowItem = ({ libraryTypes, title, subtitle, formData, setListPages, setLoadSuccess, setSearchData, background, amount, setId, onSubmit, newAmount, id, setbreadCrumbList, setFormData }) => {
  newAmount = Number(newAmount)
  const [image, setImage] = useState(IMAGE_PLUG)

  useEffect(() => {
    getLibrarytypeImage(id)
      .then(res => {
        setImage(res.config.url)
      })
      .catch(e => {
        setImage(background)
      })
  }, [id])

  const linkBody = (
    <div className={styles.link} style={{ backgroundImage: `url(${image})`, cursor: 'pointer' }}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
    </div>
  )

  const handleList = (id) => {
      setSearchData({
        page: 1,
        maxResults: 15,
        pageCount: 0
    })
      setListPages([])
      setLoadSuccess(false)
      setId(id);
      setbreadCrumbList(prev => ([...prev, {name: title, id: id}]));
      // handleGetByCat();
  }

  // const handleGetByCat = () => {
  //   setFormData({ ...formData, typeId: id })
  //   onSubmit(id);
  // }

  return (
    <Col xl={3} lg={4} style={{marginTop: '1rem'}} onClick={() => handleList(id, title)}>
        <div>
          {!!newAmount && (
            <div className={styles.novsLabel}>
              {/* Шифр */ id == 1 ? `+${libraryTypes.reduce((a, i) => a + +i.newamount, 0)}` : `+${newAmount}`} 
            </div>
          )}
          {linkBody}
          <span className={styles.counter} style={{cursor: 'pointer'}}>
            {amount} {subtitle}
          </span>
        </div>
    </Col>
  )
}

IndexRowItem.propTypes = {
  destination: T.string,
  title: T.string,
  amount: T.number,
  image: T.string,
}

export default IndexRowItem
