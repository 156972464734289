import { createSimpleActionsForReducer } from '../actions/actionsFactory'
import { START, SUCCESS, ERROR } from '../actions/action-types'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { api } from '../../App'

export const types = {
  INDIVIDUAL_TYPE: 'user',
  GROUP_TYPE: 'group',
}

export const typesOfWork = {
  HOME_WORK: '',
  RESULT_WORK: 'result',
}

const CURRENT_PERFOMANCE_REQUEST = 'CURRENT_PERFOMANCE_REQUEST'
const CURRENT_PERFOMANCE_DATA_TO_STATE = 'CURRENT_PERFOMANCE_DATA_TO_STATE'
const CURRENT_PERFOMANCE_LOAD_REQUEST = 'CURRENT_PERFOMANCE_LOAD_REQUEST'
const CURRENT_PERFOMANCE_TERM_REQUEST = 'CURRENT_PERFOMANCE_FACULTY_REQUEST'
const CURRENT_PERFOMANCE_DISCIPLINE_REQUEST = 'CURRENT_PERFOMANCE_CURS_REQUEST'
const CURRENT_PERFOMANCE_YEAR_REQUEST = 'CURRENT_PERFOMANCE_YEAR_REQUEST'
const USER_DATA_REQUEST = 'USER_DATA_REQUEST'
const CURRENT_PERFOMANCE_FILES_REQUEST = 'CURRENT_PERFOMANCE_FILES_REQUEST'
const TASKS_FETCH_COMPLETED = 'TASKS_FETCH_COMPLETED'

export const getYearApi = () => {
  return api.get('/hlms/api/year/scy')
}

export const getTermApi = yearId => {
  return api.get(`/hlms/api/year/term/${yearId}`)
}

export const getDisciplineApi = termId => {
  return api.get(`/hlms/api/course/student/${termId}`)
}

const getCurrentPerfomanceApi = data => {
  return api.get(`/hlms/api/current_performance/${data}`)
}

const getFileApi = (sheId, instance, type, typeOfWork = typesOfWork.HOME_WORK, userId) => {
  return api.get(
    `/hlms/api/homework/${type}${typeOfWork}${
      type === types.GROUP_TYPE && typeOfWork === typesOfWork.HOME_WORK ? '/' : `/${userId}/`
    }${sheId}/${instance}`
  )
}

export const currentPerfomanceActions = {
  ...createSimpleActionsForReducer('request', `${CURRENT_PERFOMANCE_REQUEST}`),
  ...createSimpleActionsForReducer('load', `${CURRENT_PERFOMANCE_LOAD_REQUEST}`),
  ...createSimpleActionsForReducer('term', `${CURRENT_PERFOMANCE_TERM_REQUEST}`),
  ...createSimpleActionsForReducer('discipline', `${CURRENT_PERFOMANCE_DISCIPLINE_REQUEST}`),
  ...createSimpleActionsForReducer('year', `${CURRENT_PERFOMANCE_YEAR_REQUEST}`),
  ...createSimpleActionsForReducer('user', `${USER_DATA_REQUEST}`),
  ...createSimpleActionsForReducer('files', `${CURRENT_PERFOMANCE_FILES_REQUEST}`),
  ...createSimpleActionsForReducer('tasks', `${TASKS_FETCH_COMPLETED}`),
}

export const requestAction = data => ({
  type: CURRENT_PERFOMANCE_DATA_TO_STATE,
  data,
})

const initialState = {
  loader: false,
}

export const currentPerfomanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${TASKS_FETCH_COMPLETED}${SUCCESS}`:
      return {
        ...state,
        modal: action.payload,
      }
    case `${CURRENT_PERFOMANCE_REQUEST}${START}`:
      return {
        ...state,
        loader: true,
      }
    case `${CURRENT_PERFOMANCE_TERM_REQUEST}${SUCCESS}`:
      return {
        ...state,
        term: action.payload,
      }
    case `${CURRENT_PERFOMANCE_DISCIPLINE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        discipline: action.payload,
      }
    case `${CURRENT_PERFOMANCE_YEAR_REQUEST}${SUCCESS}`:
      return {
        ...state,
        year: action.payload,
      }
    case `${CURRENT_PERFOMANCE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        loader: false,
        response: action.payload,
      }
    case `${CURRENT_PERFOMANCE_REQUEST}${ERROR}`:
      return {
        ...state,
        loader: false,
      }
    case `${USER_DATA_REQUEST}${SUCCESS}`:
      return {
        ...state,
        userData: action.payload,
      }
    case CURRENT_PERFOMANCE_DATA_TO_STATE:
      return {
        ...state,
        user: action.data,
      }

    default:
      return state
  }
}

function* currentPerfomanceLoadSaga({ payload }) {
  try {
    // console.log(payload)
    const resYear = yield call(getYearApi)
    const resTerm = yield call(
      getTermApi,
      payload?.year ? payload?.year : resYear.data.schoolYear[resYear.data.schoolYear.length - 1].xp_key
    )
    const resDiscipline = yield call(
      getDisciplineApi,
      payload?.term ? payload?.term : resTerm.data.terms[resTerm.data.terms.length - 1].trmid
    )

    yield put(currentPerfomanceActions.yearRequestSuccess(resYear.data))
    yield put(currentPerfomanceActions.termRequestSuccess(resTerm.data))
    yield put(currentPerfomanceActions.disciplineRequestSuccess(resDiscipline.data))
  } catch (e) {
    console.log(e)
  }
}

function* currentPerfomanceRequestSaga() {
  const data = yield select(({ currentPerfomance }) => currentPerfomance.user)
  try {
    const res = yield call(getCurrentPerfomanceApi, data)
    yield put(currentPerfomanceActions.requestRequestSuccess(res))
  } catch (e) {
    yield put(currentPerfomanceActions.requestRequestError())
    console.log(e)
  }
}

function* currentPerfomanceRequestFilesSaga({ payload: { sheid, instance, type, typesOfWork } }) {
  const userId = yield select(({ auth }) => auth.data.mid)

  try {
    const res = yield call(getFileApi, sheid, instance, type, typesOfWork, userId)
    yield put(currentPerfomanceActions.tasksRequestSuccess(res.data))
    console.log(1111)
  } catch (e) {
    console.log(e)
  }
}

export function* currentPerfomanceSagaWatcher() {
  yield takeLatest(`${CURRENT_PERFOMANCE_LOAD_REQUEST}${START}`, currentPerfomanceLoadSaga)
  yield takeLatest(`${CURRENT_PERFOMANCE_REQUEST}${START}`, currentPerfomanceRequestSaga)
  yield takeLatest(`${CURRENT_PERFOMANCE_FILES_REQUEST}${START}`, currentPerfomanceRequestFilesSaga)
}
