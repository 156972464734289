import { api } from '../../../../App'

const SET_LIBRARY_FUNDS = 'INVENTORY/SET_LIBRARY_FUNDS'

const initialState = []

export const libraryFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SET_LIBRARY_FUNDS}`:
      return action.payload

    default:
      return state
  }
}

export const requestLibraryFunds = libraryId => async dispatch => {
  const data = await api.get(`/library/${libraryId}/fund`)
  dispatch({ type: SET_LIBRARY_FUNDS, payload: data.data })
}
