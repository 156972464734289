import { call, put, takeLatest } from 'redux-saga/effects'
import {
  START,
  INVENTORY_BOOK_LIST_REQUEST,
  INVENTORY_BOOK_SAVE_REQUEST,
  INVENTORY_BOOK_DELETE_REQUEST,
  INVENTORY_NEW_BOOK_SAVE_REQUEST,
} from '../../../actions/action-types'
import {
  addBookToInventory,
  deleteBookToInventory,
  editBookToInventory,
  getInventoryBooksList,
} from '../../../../api/inventory/bookToDocument'
import {
  inventoryBookListRequestError,
  inventoryBookListRequestStart,
  inventoryBookListRequestSuccess,
} from '../../../actions/inventory/books/list'
import { inventoryBookSaveRequestError, inventoryBookSaveRequestStart, inventoryBookSaveRequestSuccess } from '../../../actions/inventory/books/save'
import { inventoryBookDeleteRequestError, inventoryBookDeleteRequestSuccess } from '../../../actions/inventory/books/delete'
import { editBook } from '../../../../api/book/edit'
import { addInvNumber } from '../../../../api/invNumbers/invNumbers'
import { inventoryNewBookSaveRequestError } from '../../../actions/inventory/books/new_book'

export default function* documentBooksWatcher() {
  yield takeLatest(`${INVENTORY_BOOK_LIST_REQUEST}${START}`, inventoryBooksListWorker)
  yield takeLatest(`${INVENTORY_BOOK_SAVE_REQUEST}${START}`, inventoryBooksSaveWorker)
  yield takeLatest(`${INVENTORY_BOOK_DELETE_REQUEST}${START}`, inventoryBooksDeleteWorker)
  yield takeLatest(`${INVENTORY_NEW_BOOK_SAVE_REQUEST}${START}`, inventoryNewBookSaveWorker)
}

function* inventoryBooksListWorker({ payload: documentId }) {
  try {
    const response = yield call(getInventoryBooksList, documentId)
    yield put(inventoryBookListRequestSuccess(response.data))
  } catch (error) {
    yield put(inventoryBookListRequestError(error))
  }
}

function* inventoryBooksSaveWorker({ payload: data }) {
  try {
    const response = yield call(data.id ? editBookToInventory : addBookToInventory, data)
    yield put(inventoryBookSaveRequestSuccess(response.data))
    yield put(inventoryBookListRequestStart(data.inventory))
  } catch (error) {
    yield put(inventoryBookSaveRequestError(error))
  }
}

function* inventoryBooksDeleteWorker({ payload: data }) {
  try {
    const response = yield call(deleteBookToInventory, data.id)
    yield put(inventoryBookDeleteRequestSuccess(response.data))
    yield put(inventoryBookListRequestStart(parseInt(data.inventory)))
  } catch (error) {
    yield put(inventoryBookDeleteRequestError(error))
  }
}

function* inventoryNewBookSaveWorker({ payload: { data, invNumber, documentId } }) {
  try {
    yield call(editBook, data)
    const { data: { id: book_instance } } = yield call(addInvNumber, data.bookid, invNumber, '')
    yield put(inventoryBookSaveRequestStart({ inventory: documentId, amount: 0, book_instance }))

  } catch (error) {
    yield put(inventoryNewBookSaveRequestError(error))
  }
}
