import { combineReducers } from "redux";
import { mobileLibraryCountReducer } from "./count";
import { mobileLibraryListReducer } from "./list";
import { mobileLibraryLoaderReducer } from "./loader";
import { mobileLibrarySearchReducer } from "./search";
import { currentMobileLibraryReducer } from "./currentLibrary";
import { mobileLibraryResponsibleReducer } from "./responsible";
import { mobileLibraryDocumentsReducer } from "./documents";

export const mobileLibraryReducer = combineReducers({
  count: mobileLibraryCountReducer,
  list: mobileLibraryListReducer,
  current: currentMobileLibraryReducer,
  loader: mobileLibraryLoaderReducer,
  search: mobileLibrarySearchReducer,
  responsible: mobileLibraryResponsibleReducer,
  documents: mobileLibraryDocumentsReducer
});
