import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

import styles from './PopoverOuter.module.css'

const PopoverOuter = ({ children, content, trigger, disabled, ...props }) => {
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = visible => {
    !disabled && setVisible(visible)
  }

  return (
    <Popover
      trigger={trigger}
      content={
        <div style={{ position: 'relative' }}>
          <button onClick={() => setVisible(false)} className={styles.close}>
            <CloseOutlined />
          </button>
          {content}
        </div>
      }
      visible={visible}
      onVisibleChange={handleVisibleChange}
      {...props}
    >
      {children}
    </Popover>
  )
}

PopoverOuter.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  trigger: PropTypes.string,
}

export default PopoverOuter
