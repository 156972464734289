import React, { useState } from 'react'
import styles from './CurrentPerfomanceTable.module.css'
import { Table, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Modal from '../../Modal'
import arrow from '../../../img/icons/arrow-up-icon.png'
import { types, typesOfWork } from '../../../redux/factory/currentPerfomanceReduxData'
import { TasksFiles } from '../TasksFile'
import { ResultsFilesForm } from '../ResultsFilesForm'
import moment from 'moment'

const ScheduleTable = ({ res, isTableVisible, filesRequest, modalData }) => {
  const [state, setState] = useState({
    tabIndex: 0,
    isModalVisible: false,
    modalTarget: null,
    type: null,
    row: null,
  })

  const handleToggleModal = (isModalVisible, row = null, type = null, typesOfWork = null) => {
    setState({ isModalVisible, row, type, modalTarget: typesOfWork })
    if (row) {
      const { sheid, instance } = row
      filesRequest({ sheid, instance, type, typesOfWork })
    }
  }

  return (
    <>
      <Modal
        title={`Роль: ${state.type === 'EDIT' ? 'редактирование' : 'добавление'}`}
        isVisible={state.isModalVisible}
        // contentClassName={styles.modalContent}
      >
        {state.modalTarget === typesOfWork.HOME_WORK && (
          <TasksFiles modalData={modalData} row={state.row} type={state.type} download={'download'} />
        )}
        {state.modalTarget === typesOfWork.RESULT_WORK && (
          <ResultsFilesForm
            modalData={modalData}
            row={state.row}
            type={state.type}
            uploadFile={'uploadFile'}
            deleteFile={'deleteFile'}
            download={'download'}
          />
        )}
        <Button variant='danger' size='sm' onClick={() => setState({ ...state, isModalVisible: false })}>
          Close
        </Button>
      </Modal>

      {isTableVisible && (
        <Table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.title}>
                <Container>Дата</Container>
              </th>
              <th className={styles.title}>
                <Container>Тема</Container>
              </th>
              <th className={styles.title}>
                <Container>Вид занятия</Container>
              </th>
              <th className={styles.title}>
                <Container>Оценка</Container>
              </th>
              <th className={styles.title}>
                <Container>
                  Групповое
                  <br />
                  задание
                </Container>
              </th>
              <th className={styles.title}>
                <Container>
                  Индивидуальное
                  <br />
                  задание
                </Container>
              </th>
            </tr>
          </thead>
          <tbody>
            {res.map((row, i) => {
              return (
                <tr>
                  <td>
                    <Container>{moment(row.lessonfulldate).format('DD.MM.YYYY')}</Container>
                  </td>
                  <td>
                    <Container>{row.otitle}</Container>
                  </td>
                  <td>
                    <Container>{row.lessontype || 'не указано'}</Container>
                  </td>
                  <td>
                    <Container>{row.grade}</Container>
                  </td>
                  <td>
                    <Container>
                      <span>
                        <a
                          role='button'
                          className='underline small-text'
                          onClick={() => handleToggleModal(true, row, types.GROUP_TYPE, typesOfWork.HOME_WORK)}
                        >
                          <span className={true ? 'bold blackColor' : ''}>Задание</span>&nbsp;
                          <img src={arrow} alt='*' width={7} />
                        </a>
                        <a
                          role='button'
                          className='underline small-text'
                          onClick={() => handleToggleModal(true, row, types.GROUP_TYPE, typesOfWork.RESULT_WORK)}
                        >
                          <span className={true ? 'bold blackColor' : ''}> Результат</span>&nbsp;
                          <img src={arrow} alt='*' width={7} />
                        </a>
                      </span>
                    </Container>
                  </td>
                  <td>
                    <Container>
                      <span>
                        <a
                          role='button'
                          className='underline small-text'
                          onClick={() => handleToggleModal(true, row, types.INDIVIDUAL_TYPE, typesOfWork.HOME_WORK)}
                        >
                          <span className={true && 'bold'}>Задание</span>&nbsp;
                          <img src={arrow} alt='*' width={7} />
                        </a>
                        <a
                          role='button'
                          className='underline small-text'
                          onClick={() => handleToggleModal(true, row, types.INDIVIDUAL_TYPE, typesOfWork.RESULT_WORK)}
                        >
                          <span className={true && 'bold'}> Результат</span>&nbsp;
                          <img src={arrow} alt='*' width={7} />
                        </a>
                      </span>
                    </Container>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default ScheduleTable
