import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import styles from '../DocumentsModal.module.css'
import { EDIT } from '../../../../constants/modalTypes'
import SearchableSelect from '../../../Select'
import { useFormik } from 'formik'
import T from 'prop-types'
import * as Yup from 'yup'
import ModalWindow from '../../../ModalWindow'
import { Button } from 'antd'
import { DISPOSAL_DIRECTIONS, EXCLUSION_REASONS } from '../../../../constants/documents'

const MODAL_SAVE_TYPES = {
  EDIT: 'Сохранить документ',
  ADD: 'Создать документ',
}

const defaultValidationSchema = Yup.object().shape({
  documentnumber: Yup.string().required('Это поле необходимо заполнить'),
  documenttype: Yup.string().required('Выберите вид документа'),
  document_arrival_date: Yup.date().required('Выберите дату документа'),
  supplier: Yup.string().when('documenttype', {
    is: val => val !== '18', //Не акт списания
    then: Yup.string().nullable().required('Выберите источник'),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  library: Yup.string().nullable().required('Выберите библиотеку'),
  view: Yup.string().nullable().required('Выберите вид'),
})

const viewArray = [
  { label: 'Не выбрано', value: 0 },
  { label: 'Книги', value: 1 },
  { label: 'Брошюры', value: 2 },
  { label: 'Журналы', value: 3 },
  { label: 'Газеты', value: 4 },
]

const ModalHeader = ({
  modalData,
  modalType,
  institutionsList,
  types,
  libraries,
  saveDocument,
  setFormValidity,
  outsecrecy,
  documentType,
  setValue,
  fromMobLib,
  fromLostDoc,
  fromLostDocForTakeAct,
  disabled
}) => {

  const modalForm = useFormik({
    initialValues: modalData,
    validationSchema: defaultValidationSchema,
    onSubmit: values => saveDocument(values),
  })

  const [outSecrecy, setOutSecrecy] = useState(false)
  const { values, errors } = modalForm

  const validityCheck = () => {
    modalForm.validateForm().then(response => {
      !!Object.entries(response).length ? setFormValidity(false) : setFormValidity(true)
    })
  }

  useEffect(() => {
    validityCheck()
  }, [])

  useEffect(() => {
    setValue(values)
  }, [modalForm.values])

  useEffect(() => {
    if (modalForm.dirty) validityCheck()
  }, [modalForm.values])


  useEffect(() => {
    // Шифр
    if (modalForm.values['documenttype'] === '18') {
      modalForm.setFieldValue('supplier', null)
      modalForm.setFieldValue('suppliername', 'Не выбрано')
    }
  }, [modalForm.values['documenttype']])

  useEffect(() => {
    const arr = []
    if (types) {
      for (let key of types) {
        // Шифр
        if (key.id !== 20) {
          arr.push(key)
        }
      }
    }

    setOutSecrecy(arr)
  }, [])

  const setDocumentType = (e) => {
    const {name, value} = e.target
    // Шифр
    const newExcReasonValue = value == 18 ? 1 : null
    modalForm.setFieldValue(name, value)
    modalForm.setFieldValue('exclusion_reason', newExcReasonValue)
  }  

  return (
    <>
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            label='Номер документа*'
            name='documentnumber'
            value={values['documentnumber']}
            onChange={modalForm.handleChange}
            error={errors['documentnumber']}
            disabled={disabled}
            />
          <ModalInputWrapper
            label='Дата документа*'
            type='date'
            name='document_arrival_date'
            value={values['document_arrival_date']}
            onChange={modalForm.handleChange}
            disabled={disabled}
            />
          {(!fromMobLib && !fromLostDoc) &&
          <ModalInputWrapper label='Вид документа*' error={errors['documenttype']}>
            <select
              name='documenttype'
              value={values['documenttype']}
              onChange={setDocumentType}
              disabled={modalType === EDIT || disabled}
              className={styles.select}
            >
              <option value=''>Не выбрано</option>              
              {/*Шифр*/outsecrecy !== 3
                ? outSecrecy &&
                  outSecrecy.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))
                : types.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
            </select>
          </ModalInputWrapper>}

          {/*Шифр*/values['documenttype'] == 18 && (
            <ModalInputWrapper label='Причина исключения'>
              <select
                name='exclusion_reason'
                value={values['exclusion_reason']}
                onChange={modalForm.handleChange}
                disabled={disabled}
              >
                {EXCLUSION_REASONS.map(({id, name}) => <option value={id}>{name}</option>)}
              </select>
            </ModalInputWrapper>
          )}

          {/*Шифр*/values['documenttype'] == 19 && (
            outsecrecy == 4
              ?   <>
                    <ModalInputWrapper label='Направление выбытия'>
                      <select
                        name='disposal_direction'
                        value={values['disposal_direction']}
                        onChange={modalForm.handleChange}
                        disabled={disabled}
                      >
                        {DISPOSAL_DIRECTIONS.map(({id, name}) => <option value={id}>{name}</option>)}
                      </select>
                    </ModalInputWrapper>
                    <ModalInputWrapper
                      type='date'
                      name='disposal_date'
                      value={values['disposal_date']}
                      onChange={modalForm.handleChange}
                      label='Дата выбытия'
                      disabled={disabled}
                    />
                  </>
              :   <ModalInputWrapper
                    label='Организация, уничтожившая документы'
                    name='disposal_org'
                    value={values['disposal_org']}
                    onChange={modalForm.handleChange}
                    disabled={disabled}
                  />
          )}
          {/*Шифр*/(documentType === 18 || documentType === 1) && (
            <>
              <ModalInputWrapper
                label='Номер КСУ'
                type='number'
                name='document_serial_number'
                // value={values['document_serial_number']}
                disabled
                value={modalData.document_serial_number}
              />
              <ModalInputWrapper
                label='Дата записи ксу*'
                type='date'
                name='documentdate'
                value={values['documentdate']}
                onChange={modalForm.handleChange}
                error={errors['documentdate']}
                disabled={disabled}
              />
            </>
          )}
        </Col>
        <Col md={6}>
          <div className={styles.textarea}>
            <ModalInputWrapper
              label='Примечание'
              type='textarea'
              name='description'
              value={values['description']}
              onChange={modalForm.handleChange}
              disabled={disabled}
            />
          </div>

          {/* Шифр */ values['documenttype'] !== '18' && (
            <ModalInputWrapper label='Источник*' error={errors['supplier']}>
              <SearchableSelect
                placeholder='Поиск'
                id='supplier'
                name='supplier'
                provider={true}
                options={institutionsList}
                onChange={val => {
                  modalForm.setFieldValue('supplier', val.value)
                  modalForm.setFieldValue('suppliername', val.label)
                }}
                value={{ label: values['suppliername'], value: values['supplier'] }}
                disabled={disabled}
              />
            </ModalInputWrapper>
          )}
          <ModalInputWrapper label='Вид' error={errors['view']}>
            <select
              className={styles.select}
              name='view'
              value={values['view']}
              onChange={modalForm.handleChange}
              disabled={disabled}
            >
              {viewArray.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
          {libraries && (
            <ModalInputWrapper label='Библиотеки' error={errors['library']}>
              <select
                name='library'
                value={values['library']}
                onChange={modalForm.handleChange}
                disabled={modalType === EDIT || disabled}
                className={styles.select}
              >
                <option value=''>Не выбрано</option>
                {libraries.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          )}
        </Col>
        {!fromLostDocForTakeAct && 
        <Col md={12}>
          <div className={styles.bottomSection}>
            <Button disabled={disabled} type='primary' size='middle' onClick={modalForm.handleSubmit}>
              {MODAL_SAVE_TYPES[modalType]}
            </Button>
          </div>
        </Col>
        }
      </Row>
      {/*<ModalWindow*/}
      {/*    title={`Документ: сохранение`}*/}
      {/*    isOpen={openSaveModal}*/}
      {/*    onRequestClose={() => toggleSaveModal(!openSaveModal)}*/}
      {/*    width={550}*/}
      {/*>*/}
      {/*    <div className={styles.saveModal}>*/}
      {/*        <h4> Перед добавлением книги необходимо сохранить документ</h4>*/}
      {/*        <div className={styles.saveModalButtons}>*/}
      {/*            <Button type="primary" onClick={() => {*/}
      {/*                modalForm.handleSubmit();*/}
      {/*                toggleSaveModal(false);*/}
      {/*            }}>Сохранить</Button>*/}
      {/*            <Button onClick={() => {*/}
      {/*                toggleSaveModal(false);*/}
      {/*                setCurrentBookData(null);*/}
      {/*            }}>Отмена</Button>*/}
      {/*        </div>*/}

      {/*    </div>*/}
      {/*</ModalWindow>*/}
    </>
  )
}

ModalHeader.propTypes = {
  modalData: T.object.isRequired,
}

export default ModalHeader
