import { call, put, takeLatest } from "redux-saga/effects";
import { LIBRARIES_LIST_REQUEST, LIBRARY_FUNDS_REQUEST, START } from "../../../actions/action-types";
import {
  requestLibrariesList,
  requestLibraryFundsList
} from '../../../../api/documents/fundAllocation';
import {
  librariesListRequestSuccess,
  librariesListRequestError
} from '../../../actions/documents/fundAllocation/libraries';
import {
  libraryFundRequestSuccess,
  libraryFundRequestError
} from '../../../actions/documents/fundAllocation/libraryFund';


export default function* fundAllocationWatcher() {
  yield takeLatest(`${LIBRARIES_LIST_REQUEST}${START}`, librariesListWorker);
  yield takeLatest(`${LIBRARY_FUNDS_REQUEST}${START}`, libraryFundsListWorker);
}

function* librariesListWorker({ payload: idClient }) {
  try {
    const response = yield call(requestLibrariesList, idClient);
    yield put(librariesListRequestSuccess(response.data))
  } catch (e) {
    yield put(librariesListRequestError(e))
  }
}

function* libraryFundsListWorker({ payload: idLibrary }) {
  try {
    const response = yield call(requestLibraryFundsList, idLibrary);
    yield put(libraryFundRequestSuccess(response.data));
  } catch (e) {
    yield put(libraryFundRequestError(e))
  }
}
