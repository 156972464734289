import { BOOK_COMPRESS_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const bookCompressRequestStart = (data) => ({
  type: `${BOOK_COMPRESS_REQUEST}${START}`,
  payload: data
});

export const bookCompressRequestSuccess = (data) => ({
  type: `${BOOK_COMPRESS_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookCompressRequestError = (data) => ({
  type: `${BOOK_COMPRESS_REQUEST}${ERROR}`,
  payload: data
});
