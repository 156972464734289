import { EPUB_READER_BOOK_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const epubReaderBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${EPUB_READER_BOOK_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};