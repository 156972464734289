import {api} from "../../App";

export const getData = (file, distance) => {
    const formData = new FormData();
    formData.append("cover", file);

    return api.post(`/coversearch?distance=${distance}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};