import { call, put, takeLatest } from 'redux-saga/effects'
import { getGroups } from '../../../api/groups/groups'
import { ADMIN_GROUPS_REQUEST_ALL_GROUPS, START } from '../../actions/action-types'
import { adminGroupsRequestAllGroupsError, adminGroupsRequestAllGroupsSuccess } from '../../actions/adminGroups/groups'

export default function* adminGroupsWatcher() {
  yield takeLatest(`${ADMIN_GROUPS_REQUEST_ALL_GROUPS}${START}`, groupsWorker)
}

function* groupsWorker({payload}) {
  try {
    const response = yield call(getGroups, payload)
    yield put(adminGroupsRequestAllGroupsSuccess(response.data))
  } catch (error) {
    yield put(adminGroupsRequestAllGroupsError(error))
  }
}
