import { ERROR, RACK_DELETE_REQUEST, START, SUCCESS } from "../../action-types";

export const rackDeleteRequestStart = (roomId, rackId) => ({
  type: `${RACK_DELETE_REQUEST}${START}`,
  payload: { roomId, rackId }
});

export const rackDeleteRequestSuccess = (data) => ({
  type: `${RACK_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const rackDeleteRequestError = (data) => ({
  type: `${RACK_DELETE_REQUEST}${ERROR}`,
  payload: data
});
