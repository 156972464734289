import { ERROR, PERMISSIONS_BY_SERIES_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const permissionsBySeriesRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_SERIES_LIST_REQUEST}${START}`,
  payload: data
});

export const permissionsBySeriesRequestSuccess = (data) => ({
  type: `${PERMISSIONS_BY_SERIES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const permissionsBySeriesRequestError = (data) => ({
  type: `${PERMISSIONS_BY_SERIES_LIST_REQUEST}${ERROR}`,
  payload: data
});