import React from 'react';
import T from 'prop-types';

import ModalInputWrapper from "../../Modal/InputWrapper";
import { Button as ButtonAnt } from 'antd'

const SDPOptions = ({options, setOptions, saveOptions, combineOptionsSave}) => {

    const onChange = (e) => {
        setOptions({...options, [e.target.name]: e.target.value});
    };


    return (
        <div>
            <div className={'formContainer'}>
                <ModalInputWrapper
                    label={'Наименование номенклатуры дел, книг и журналов'}
                    name={'nomenclatureName'}
                    value={options.nomenclatureName}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    type={'date'}
                    label={'Дата подписания'}
                    name={'nomenclatureSignDate'}
                    value={options.nomenclatureSignDate}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Должность подписавшего'}
                    name={'nomenclatureSignPosition'}
                    value={options.nomenclatureSignPosition}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'ФИО подписавшего'}
                    name={'nomenclatureSignPerson'}
                    value={options.nomenclatureSignPerson}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    type={'date'}
                    label={'Дата утверждения'}
                    name={'nomenclatureApprovalDate'}
                    value={options.nomenclatureApprovalDate}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Должность утвердившего'}
                    name={'nomenclatureApprovalPosition'}
                    value={options.nomenclatureApprovalPosition}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'ФИО утвердившего'}
                    name={'nomenclatureApprovalPerson'}
                    value={options.nomenclatureApprovalPerson}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Должность председателя комиссии'}
                    name={'positioncommissionchairman'}
                    value={options.positioncommissionchairman}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'ФИО председателя комиссии'}
                    name={'namecommissionchairman'}
                    value={options.namecommissionchairman}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Должность члена комиссии'}
                    name={'positionmembercommission'}
                    value={options.positionmembercommission}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'ФИО члена комиссии'}
                    name={'namecommissionmember'}
                    value={options.namecommissionmember}
                    onChange={onChange}
                />
            </div>
            <div className={'formContainer'}>
                <ModalInputWrapper
                    label={'Наименование книги учета'}
                    name={'nomenclatureBookName'}
                    value={options.nomenclatureBookName}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Номер по номенклатуре'}
                    name={'nomenclatureNumber'}
                    value={options.nomenclatureNumber}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Дополнительные сведения'}
                    name={'nomenclatureExtra'}
                    value={options.nomenclatureExtra}
                    onChange={onChange}
                />

            </div>

            <div className={'d-flex justify-content-end'} style={{marginTop: '1rem'}}>
                <ButtonAnt primary onClick={() => combineOptionsSave()}>Сохранить</ButtonAnt>
            </div>
        </div>
    );
};

SDPOptions.propTypes = {
    options: T.object,
    setOptions: T.func,
    saveOptions: T.func,
};

export default SDPOptions;