import { AUTHOR_IMAGE_REQUEST, CLEAR_AUTHOR_IMAGE, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const authorImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${AUTHOR_IMAGE_REQUEST}${SUCCESS}`:
      return action.payload;

    case CLEAR_AUTHOR_IMAGE:
      return null;

    default: return state;
  }
};