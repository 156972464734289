import React, { useState, useEffect, useRef } from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { DebounceInput } from 'react-debounce-input'

import styles from './SendBookListItem.module.css'
import { Col, Row } from 'react-bootstrap'
import CoverPhotoPlaceholder from '../../../Covers/List/Item/CoverPhotoPlaceholder'
import { Link } from 'react-router-dom'
import CoverListItemInfo from '../../../Covers/List/Item/InfoBlock'
import { createInvNumberRequestStart } from '../../../../redux/actions/bookLoan/create'
import { api } from '../../../../App'
import { availableBooksActions } from '../../../../redux/factory/availableBooksModalReduxData'
import { createNotification } from '../../../notification/notification'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import { unreserveBookRequestStart } from '../../../../redux/actions/books/unreserveBook'
import ModalWindow from '../../../ModalWindow'
import BookCover from '../../../BookEdit/Tabs/IndexTab/BookCover'
import { resolveBookCoverUrl } from '../../../../utils/resolvePhotoUrl'
import { saveBookCover } from '../../../../redux/actions/bookData/data'
import { Button } from 'antd'
import moment from 'moment'

const mapStateToProps = ({ reservedBooks }) => ({
  reservedBooks,
})

const mapDispatchToProps = {
  createInvNumber: createInvNumberRequestStart,
  moveBook: availableBooksActions.moveBook,
  unreserveBook: unreserveBookRequestStart,
  uploadBookCover: saveBookCover,
}

const SendBookListItem = ({ data, createInvNumber, moveBook, userId, reservedBooks, unreserveBook, uploadBookCover }) => {
  
  const MAX_INSTANCEFIELD_NUMBER = 150

  const [instancesNumber, setInstancesNumber] = useState('1');
  const [instancesState, setInstancesState] = useState([]);
  const [coverModalOpen, setCoverModalOpen] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const invNumber = useRef()

  useEffect(() => {
    onChangeNumber()
  }, [instancesNumber])
  
  useEffect(() => {
    if (isFirstRender && reservedBooks && data && instancesState && instancesState.length) {
      instancesState.forEach((element, index) => {
        const reserveNumber = reservedBooks?.find(e => e?.title === data?.title)?.number
        updateInstance(reserveNumber, index);
      });
      setIsFirstRender(false);
    }
  }, [instancesState, reservedBooks, data, isFirstRender])

  useEffect(() => {
    setIsFirstRender(true);
  }, [instancesNumber]);

  const onChangeNumber = () => {
    let valueNumber = instancesNumber
    let inputs = []

    if (valueNumber <= MAX_INSTANCEFIELD_NUMBER) {
      for (let i = 0; i < valueNumber; i++) {
        let currentValue = instancesState[i] || {
          isConfirmed: false,
          instanceId: '',
          isDuplicated: false,
          responceId: null,
          returnDate: null,
          isValid: true,
        }
        inputs.push(currentValue)
      }
      setInstancesState(inputs)
    } else {
      createNotification('error', 'Недопустимое число экземпляров')
    }
  }

  const updateInstanceReturnDate = (date, index) => {
    const updatedInstances = [...instancesState]
    updatedInstances[index]['returnDate'] = date
    setInstancesState(updatedInstances)
  }

  const updateInstance = async (value, index) => {
      if (!value) return;
      const updatedInstances = [...instancesState]
      const bookid = Number(data.bookid)
      updatedInstances[index]['isConfirmed'] = false
      await api
        .get(`/book/instancebook/${bookid}?number=${value}`)
        .then(response => {
          updatedInstances[index]['instanceId'] = value
          updatedInstances[index]['isDuplicated'] = false
          updatedInstances[index]['access'] = response.data.access
          updatedInstances[index]['isValid'] = response.data.access
          updatedInstances[index]['id'] = response.data.res.id
          if (response.data.id) {
            updatedInstances[index]['isConfirmed'] = true
            updatedInstances[index]['responceId'] = response.data.res.id
          }

        })
        .catch(response => {
          createNotification('error', response.response.data.result);
          updatedInstances[index]['instanceId'] = value
          updatedInstances[index]['access'] = true
          updatedInstances[index]['isValid'] = false
          updatedInstances[index]['isDuplicated'] = false
          updatedInstances[index]['isConfirmed'] = false
        })
        setInstancesState(updatedInstances)
  }


  const updateAllInstances = value => {
    
    const bookid = Number(data.bookid);
    api.get(`/book/instancebook/${bookid}?number=${value}`).then(res => {
      setInstancesState(instancesState.map(instance => ({ ...instance, instanceId: value, isConfirmed: !!res.data.id })))
    })
  }
  const validateInstances = () => {
    let isInstancesValid = true
    const updatedInstances = [...instancesState]

    for (let i = 0; i < instancesState.length; i++) {
      let instanceId = updatedInstances[i]['instanceId']
      let returnDate = updatedInstances[i]['returnDate']
      updatedInstances[i]['isValid'] = true

      if (!instanceId || !returnDate) {
        isInstancesValid = false
        updatedInstances[i]['isValid'] = false
      }
    }
    
    setInstancesState(updatedInstances)
    return isInstancesValid
  }
  const onSubmitInstances = () => {
      if (!data.coverImage) {
        setCoverModalOpen(true)
    }

    let values = []
    let isSomeValuesDuplicated = false
    let isInstancesValid = validateInstances()
   
    if (moment(instancesState[0].returnDate) <= moment(new Date())) {
      createNotification('error', 'Предполагаемая дата возврата должна быть позже текущей')
    } else {    
      if (isInstancesValid) {
        const updatedInstances = [...instancesState]
        
        for (let i = 0; i < instancesState.length; i++) {
          let currentInstance = updatedInstances[i].instanceId
          if (values.indexOf(currentInstance) === -1) {
            values.push(currentInstance)
          } else {
            updatedInstances[i].isDuplicated = true
            isSomeValuesDuplicated = true
          }
        }
        setInstancesState(updatedInstances)
        
        if (!isSomeValuesDuplicated) {
          sendInstances()
          const message = 'Книга выдана';

          api.post('/add_message', { 
            book_id: +data.bookid,
            subject: message
          })
        }
      }
    }
  }
  
  const sendInstances = () => {
    const bookInReserve = findBookInReserve(data.bookid)
    if (bookInReserve) {
      unreserveBook(bookInReserve.id)
    }
    for (let i = 0; i < instancesState.length; i++) {
      (function (i) {
        setTimeout(function () {
          let bookData = {
            bookId: data.bookid,
            number: instancesState[i]['responceId'],
            userId: userId,
            returnDate: instancesState[i]['returnDate'],
            id: instancesState[i]['id']
          }
          if (instancesState[i].access) {
            moveBook({
              // Шифр
              typeFrom: 1,
              // Шифр
              placeFrom: 0,
              placeTo: bookData.userId,
              // Шифр
              typeTo: 2,
              returndate: bookData.returnDate,
              instanceId: bookData.id,
            })
          } else {
            const newInvNumber = instancesState[i].instanceId
            const payload = {...bookData, number: newInvNumber}
            createInvNumber(payload)
          }
        }, i * 700)
      })(i)
    }
    setInstancesState([])
    setInstancesNumber('')
  }

  const findBookInReserve = bookId => {
    return reservedBooks.find(item => item.bookid === bookId)
  }

  const autoFillInstancesNumber = event => {
    if (event.target.tagName !== 'INPUT' && !instancesNumber) {
      setInstancesNumber('1')
    }
  }

  const getLabelText = (data) => {
      const {instanceId, access, isConfirmed, isDuplicated, isValid } = data
      let labelText, secondClassName
      if (!instanceId) {
        labelText = ''
        secondClassName = ''
      } else if (!access) {
        labelText = 'Экземпляр забронирован'
        secondClassName = styles.instanceItemDeflected
      } else if (isDuplicated) {
        labelText = 'Дублирующее значение'
        secondClassName = styles.instanceItemDuplicated 
      } else if (!isValid) {
        labelText = 'Экземпляр не найден'
        secondClassName = styles.instanceItemDeflected
      } else {
        labelText = 'Экземпляр найден'
        secondClassName = styles.instanceItemConfirmed
      }
      return {labelText, secondClassName}
  }

  const getInstanceLabel = (instance) => {
    const {labelText, secondClassName} = getLabelText(instance)
    const classObject = classNames(styles.instanceNotification, secondClassName)
    return (
      <div className={classObject}>{labelText}</div>
    )
  }

  const sendButtonDisabled = (val) => !val.access || val.isDuplicated || !val.isValid || !val.returnDate

  return (
    <div className={styles.container} onClick={event => autoFillInstancesNumber(event)}>
      <ModalWindow
        title='Загрузить обложку'
        isOpen={coverModalOpen}
        width={'600px'}
        height={'500px'}
        onRequestClose={() => setCoverModalOpen(false)}
      >
        <Row>
          <Col md={5}>
            <BookCover
              bookId={data.bookid}
              onUpload={uploadBookCover}
              coverUrl={data.coverImage && resolveBookCoverUrl(data.coverImage, data.bookid)}
            />
          </Col>
          <Col md={7}>
            <h3>У этой книги отсутствует обложка</h3>
            <div>Вы можете загрузить ее прямо сейчас или продолжить без добавления обложки.</div>
          </Col>
        </Row>
      </ModalWindow>
      <Row>
        <Col md={2}>
          <CoverPhotoPlaceholder url={data.coverImage} bookId={data.bookid} alt={data.title} />
        </Col>
        <Col md={10}>
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <Link to={`/book/${data.bookid}`} className={styles.title}>
                {data.title}
              </Link>
              <div className={styles.value}>
                {data.authors && (typeof data.authors == 'string' ? data.authors : data.authors.map(item => item.name))}
              </div>
            </Col>
            <Col md={12}>
              <CoverListItemInfo label={'Год'} value={data.year} />
              <CoverListItemInfo label={'ISBN'} value={data.isbn} />
              <CoverListItemInfo
                label={'Размещение'}
                value={
                  data.clients && (typeof data.clients == 'string' ? data.clients || 'n/a' : data.clients.map(item => item.name))
                }
              />
              <CoverListItemInfo label={'Фонд'} value={'n/a'} />
              {data.covermatch !== undefined && <CoverListItemInfo label={'% совпадения'} value={data.covermatch} />}
            </Col>
            <Col md={12}>
              <Row>
                <Col md={3}>
                  <div className={styles.label}>Количество экземпляров:</div>
                </Col>
                <Col md={9}>
                  <DebounceInput
                    debounceTimeout={500}
                    type='number'
                    min='0'
                    value={instancesNumber}
                    onChange={e => setInstancesNumber(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Row>
                {instancesNumber > 1 && (
                  <Col md={{ span: 9, offset: 3 }}>
                    <ModalInputWrapper
                      label={'Номер всем'}
                      onChange={e => {
                        updateAllInstances(e.target.value)
                      }}
                    />
                    <ModalInputWrapper
                      label={'Дата всем'}
                      type={'date'}
                      onChange={e => {
                        setInstancesState(
                          instancesState.map(instance => ({
                            ...instance,
                            returnDate: e.target.value,
                          }))
                        )
                      }}
                    />
                  </Col>
                )}
                <Col md={{ span: 9, offset: 3 }}>
                  {instancesState.map((val, idx) => {
                    const instanceNumber = `instance${idx}`
                    return (
                      <>
                      <div
                        key={idx}
                        className={classNames(
                          'd-flex my-2',
                          styles.instanceItemOuter,
                          instancesState[idx]['isValid'] ? '' : styles.instanceItemOuterError
                        )}
                      >
                        <div className={classNames(styles.instanceItemNumber, 'd-flex align-items-center')}>{idx + 1}</div>
                        <div className={styles.instanceItemInput}>
                          <div className={styles.instanceItemLabel}>Инвентарный номер:</div>
                          <DebounceInput
                            debounceTimeout={500}
                            ref={invNumber}
                            forceNotifyOnBlur={true}
                            name={instanceNumber}
                            data-idx={idx}
                            value={reservedBooks && reservedBooks?.find(e => e?.title === data?.title)?.number}
                            onChange={e => updateInstance(e.target.value, idx)}
                            type='number'
                          />
                        </div>
                        <div className={styles.instanceItemInput}>
                          <div className={styles.instanceItemLabel}>Предполагаемая дата возврата:</div>
                          {moment(instancesState[0].returnDate) <= moment(new Date()) && <p className={styles.error}>укажите будущую дату</p>}
                          <input
                            type='date'
                            value={instancesState[idx].returnDate}
                            onChange={e => updateInstanceReturnDate(e.target.value, idx)}
                          />
                        </div>
                        <div className={styles.instanceItemInput}>
                          {getInstanceLabel(val)}
                        </div>
                      </div>
                      <>
                    {instancesState.length > 0 && moment(instancesState[0].returnDate) >= moment(new Date()) &&
                      <Button disabled={sendButtonDisabled(val)} onClick={onSubmitInstances}>Выдать экземпляры</Button>}</>             
                      </>
                    )

                  })

                  }
                  
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

SendBookListItem.propTypes = {
  data: T.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SendBookListItem)
