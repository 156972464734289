import { ROLE_ADD_NEW_MEMBER, ROLE_CLEAR_NEW_MEMBERS } from "../action-types";

export const roleAddNewMember = data => ({
  type: ROLE_ADD_NEW_MEMBER,
  payload: data
});

export const roleClearNewMembers = () => ({
  type: ROLE_CLEAR_NEW_MEMBERS
});
