import {
  DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST,
  SUCCESS, START, ERROR
} from "../../../actions/action-types";

const initialState = {};

export const documentBookDistributeReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${START}`:
      return state;

    case `${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    case `${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${ERROR}`:
      return action.payload;

    default: return state;
  }
};
