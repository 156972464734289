import { START, SUCCESS, ERROR, ADMIN_GROUPS_REQUEST_ALL_GROUPS } from '../../actions/action-types'

export const adminGroupsRequestAllGroupsStart = (payload) => ({
  type: `${ADMIN_GROUPS_REQUEST_ALL_GROUPS}${START}`,
  payload
})

export const adminGroupsRequestAllGroupsSuccess = payload => ({
  type: `${ADMIN_GROUPS_REQUEST_ALL_GROUPS}${SUCCESS}`,
  payload,
})

export const adminGroupsRequestAllGroupsError = payload => ({
  type: `${ADMIN_GROUPS_REQUEST_ALL_GROUPS}${ERROR}`,
  payload,
})

