import { HAVKINA_CALC_INDEX_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = "";

export const havkinaIndexReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${HAVKINA_CALC_INDEX_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};
