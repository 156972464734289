import jsPDF from "jspdf"
import addFont from "../../../../fonts/fonts"
import 'jspdf-autotable'



const orientation = 'portrait'

export const getReceiptData = async ({options, actData, clientInfo, libName, supplierName}) => {
  addFont(jsPDF.API)

  let lastY = 20

  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height) {
      doc.addPage('a4', orientation)
      newY = 10
    }
    lastY = newY
    return newY
  }

  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
  doc.setFont('Montserrat')

  doc.setFontSize(14).setFont(undefined, 'bold')
  doc.text(`ПРИХОДНЫЙ ОРДЕР № ${actData?.documentnumber}`, doc.internal.pageSize.width / 2, lastY, 'center')
  doc.text('НА ПРИЕМКУ МАТЕРИАЛЬНЫХ ЦЕННОСТЕЙ', doc.internal.pageSize.width / 2, getY(10), 'center')
  doc.text('(НЕФИНАНСОВЫХ АКТИВОВ)', doc.internal.pageSize.width / 2, getY(10), 'center')
  doc.setFontSize(10).setFont(undefined, 'normal')


  doc.text('Коды', 170, 45)
  doc.text('Форма № 23 по ОКУД    0504207', 124, 55)
  doc.text('по ОКПО', 146, 65)
  doc.text(`КПП        ${options?.kpp ? options?.kpp : ''}`, 150, 90)
  doc.text(`Телефон    ${options?.phone ? options?.phone : ''}`, 146, 100)
  doc.rect(165, 40, 30, 10)
  doc.rect(165, 50, 30, 8)
  doc.rect(165, 58, 30, 20)
  doc.rect(165, 78, 30, 8)
  doc.rect(165, 86, 30, 8)
  doc.rect(165, 94, 30, 8)

  doc.text('от "__" ________ 20__ г.', 60, getY(10))
  doc.text(clientInfo.shortname || '', 75, getY(19))
  doc.text('Учреждение получатель   __________________________________', 20, getY(1))
  doc.text(libName || actData?.libraryname || '', 83, getY(9))
  doc.text('Структурное подразделение   _____________________________', 20, getY(1))
  doc.text(doc.splitTextToSize(actData?.suppliername || supplierName || '', 110), 46, getY(9))
  doc.text('Поставщик   ________________________________________________', 20, getY(1))
  doc.text(options?.address, 36, getY(9))
  doc.text('Адрес   _____________________________________________________', 20, getY(1))
  doc.text(options?.inn ? options?.inn : '', 32, getY(9))
  doc.text('ИНН   _____________________________________________________', 20, getY(1))
  doc.text('Основание   ________________________________________________', 20, getY(10))
  
  doc.autoTable({
    html: '#receiptTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
  })
  
  doc.text('Дополнительные сведения:', doc.internal.pageSize.width / 2, getY(10, doc.autoTableEndPosY()), 'center')
  
  doc.text('Сдал  ________________________________________________________________', 65, getY(20))
  doc.setFontSize(8)
  doc.text('(должность)           (подпись)          (расшифровка подписи)', 80, getY(3))
  doc.setFontSize(10)
  
  doc.text('Ответственный исполнитель  ________________________________________________________________', 20, getY(20))
  doc.setFontSize(8)
  doc.text('(должность)           (подпись)          (расшифровка подписи)', 80, getY(3))
  doc.setFontSize(10)
  
  doc.text('Принял  ________________________________________________________________', 60, getY(20))
  doc.setFontSize(8)
  doc.text('(должность)           (подпись)          (расшифровка подписи)', 80, getY(3))
  doc.setFontSize(10)
  
  
  doc.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ', 20, getY(30))
  doc.text('Отметка бухгалтерии о принятии к учету и оприходовании материальных ценностей.', 20, getY(10))
  doc.text('Ответственный исполнитель  ____________________________   ________________    __________________________', 20, getY(10))
  doc.setFontSize(8)
  doc.text('(должность)                         (подпись)                      (расшифровка подписи)', 95, getY(5))
  doc.setFontSize(10)
  doc.text('"_____"_________________ 20___ г.', 20, getY(10))


  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}