import { PERMISSIONS_BY_CATEGORY_ADD_REQUEST, ERROR, START, SUCCESS } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const permissionsByCategoryAddRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_CATEGORY_ADD_REQUEST}${START}`,
  payload: data
});

export const permissionsByCategoryAddRequestSuccess = (data) => {
  createNotification('success', 'Категория успешно добавлена к роли');
  return {
    type: `${PERMISSIONS_BY_CATEGORY_ADD_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const permissionsByCategoryAddRequestError = (data) => {
  createNotification('error', 'Не удалось добавить категорию к роли');
  return {
    type: `${PERMISSIONS_BY_CATEGORY_ADD_REQUEST}${ERROR}`,
    payload: data
  };
};
