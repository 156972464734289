import React from 'react'
import T from 'prop-types'
import { Button } from 'antd'
import { createNotification } from '../../../notification/notification'

import { RESERVE_STATE } from '../../../../constants/bookState'
import ReadingListItemFiles from '../FileList'
import ReadingListUploadingFiles from '../UploadingList'
import { getBookReserveState } from '../../../../utils/getBookReserveState'
import ReadingListExportButton from '../../ExportButton'

import styles from './s.module.css'
import PopoverOuter from '../../../UtulityComponents/Popover'
import { api } from '../../../../App'

const ControlsPanel = ({ fromImportScene, data, reserveBook, unreserveBook, addBookScanOrder, addBookOrderHandler }) => {
  const isForbidden = data.rightofuse === 'Запрещено'

  const reserveState = getBookReserveState(data)

  const onReserve = () => {
    handlePostMessage(reserveState)
    if (reserveState === RESERVE_STATE.AVAILABLE) return reserveBook(data.availableinstances[0])
    if (reserveState === RESERVE_STATE.RESERVED) return unreserveBook(data.reservations[0])
  }

  const handlePostMessage = (type) => {
    const message = type == RESERVE_STATE.AVAILABLE ? 'Книга забронирована' : 'Книга снята с брони';

    api.post('/add_message', { 
      book_id: +data.bookid,
      subject: message
     })
  }

  const openEbookHandler = id => {
    const ebookWindow = window.open(`../public/files/ebook/${id}/`)
    ebookWindow.focus()
  }

  const onClickWrapper = handler => {
    if (isForbidden) {
      return () =>
        createNotification(
          'warning',
          'Запрет согласно положений профильного законодательства в области лицензирования, обеспечения авторских и смежных прав (IV части ГК РФ)'
        )
    }

    return handler
  }

  // console.log(data.availableinstances)
  // console.log(
  //      data.availableinstances ?
  //       data.availableinstances.find(item => (item.reservationsallowed !== null && (item.typeplace !== null && item.typeplace !== false)) ) : "Не подходит"
  //  )
  // console.log(data.availableinstances &&
  //         data.availableinstances.map(item => item.reservationsallowed !== null ? item : 'не подходит')
  // )

  

  return (
!fromImportScene && 
    <div className={styles.container}>
      <Button onClick={onClickWrapper(onReserve)} disabled={reserveState === RESERVE_STATE.UNAVAILABLE}>
        {reserveState === RESERVE_STATE.RESERVED ? 'Снять бронь' : 'Забронировать'}
      </Button>

      {!isForbidden && <ReadingListExportButton bookId={data.bookid} />}

      {data?.files?.length ? (
        <>
          {isForbidden ? (
            <>
              <Button onClick={onClickWrapper(null)}>
                Открыть
              </Button>
              <Button onClick={onClickWrapper(null)}>Выгрузить на ЭН</Button>
            </>
          ) : (
            <>
              <PopoverOuter
                placement='bottom'
                title={'Экспорт книги'}
                content={<ReadingListItemFiles fileList={data.files} bookId={data.bookid} />}
                disabled={!!data.archived}
                trigger='click'
              >
                <Button
                  onClick={onClickWrapper(null)}
                  disabled={data.archived}
                >
                  Открыть
                </Button>
              </PopoverOuter>
              <PopoverOuter
                placement='bottom'
                title={'Выгрузка книги'}
                content={<ReadingListUploadingFiles fileList={data.files} bookId={data.bookid} />}
                trigger='click'
              >
                <Button onClick={onClickWrapper(null)}>Выгрузить на ЭН</Button>
              </PopoverOuter>
            </>
          )}
        </>
      ) : (
        <>
          <Button onClick={onClickWrapper(() => addBookScanOrder(data.bookid, data.clients))}>Заказ на сканирование</Button>
          <Button onClick={onClickWrapper(() => addBookOrderHandler(data.bookid, data.clients_n))}>Заказать</Button>
          <Button disabled>Выгрузить на ЭН</Button>
        </>
      )}

      {data.electronFormat === 'HTML' && <Button onClick={onClickWrapper(() => openEbookHandler(data.id))}>Открыть ЭУ</Button>}
    </div>
  )
}

ControlsPanel.propTypes = {
  data: T.object,
}

export default ControlsPanel
