import jsPDF from "jspdf";
import T from "prop-types";
import React from "react";
import addFont from "../../../../fonts/fonts";
import PdfPrintPanel from "../../../PDFPrint/Panel";
// import { createAutotableConfig } from "../../../PDFPrint/utils";

const BookGivingStatsPdfPrint = ({ searchFormValue, librariesList }) => {
  // const monthsList = [
  //   "",
  //   " Январь",
  //   " Февраль",
  //   " Март",
  //   " Апрель",
  //   " Май",
  //   " Июнь",
  //   " Июль",
  //   " Август",
  //   " Сентябрь",
  //   " Октябрь",
  //   " Ноябрь",
  //   " Декабрь",
  // ];

  const libraryName = librariesList.find((e) => e.id == searchFormValue.library)?.name;
  const title = "Статистика книговыдачи";
  const orientation = "landscape";
  const createDocData = async () => {
    addFont(jsPDF.API);

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation });
    doc.setFont("Montserrat");
    doc.setFont( undefined, "bold");
    doc.setFontSize("20");

    doc.text(title, doc.internal.pageSize.width / 2, 12, null, null, "center");
    doc.setFontSize("14");
    doc.setFont( undefined, "normal");

    doc.text(`За период с ${searchFormValue.dateFrom} по ${searchFormValue.dateTo}`, 23, 25);

    doc.text(`Библиотека: ${libraryName || "все"}`, 23, 32);

    doc.autoTable({
      html: "#book-giving-stats table",
      styles: { font: "Montserrat", fontSize: 6 },
      headStyles: {
        halign: "center",
        valign: "middle",
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: "center",
        lineWidth: 0.25,
        lineColor: 200,
      },
      margin: {
        top: 38,
      },
    });

    doc.addPage("a4", orientation);
    // doc.addImage(domtoimage.toPng(LibrariesDiaryTable), "JPEG", 15, 15, 180, 89);
    return doc;
  };

  const toPdf = () => {
    createDocData().then((doc) => doc.save(`${title}.pdf`));
  };

  const toPrint = () => {
    createDocData().then((doc) => {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    });
  };

  return <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />;
};

BookGivingStatsPdfPrint.propTypes = {
  librariesList: T.array.isRequired,
  searchFormValue: T.object.isRequired,
};

export default BookGivingStatsPdfPrint;
