export const selectCustomStyles = {
  valueContainer: (providedStyles) => ({
    ...providedStyles,
    padding: "2px 3px",
    color: "#212529",
  }),
  option: (providedStyles, state) => ({
    ...providedStyles,
    backgroundColor: state.isFocused ? "#4c6fb6" : "#ffffff",
    color: state.isFocused ? "#ffffff" : "#212529",
  }),
  menuList: (providedStyles) => ({
    ...providedStyles,
    height: '250px',
  }),
};
