import React from 'react';
import T from 'prop-types';
import {Col, Row} from "react-bootstrap";
import styles from "../BooksOrderModal.module.css";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import {EDIT} from "../../../../constants/modalTypes";

const BooksOrderModalHeader = ({books, modalType, courses, data, setModalData}) => {

    const onChange = (name, value) => {
        setModalData({modalType, data: {...data, [name]: value}})
    };

    return (
        <Row>
            <Col md={12}>
                <div className={styles.modalLabel}>
                    <label>ФИО</label>
                    <div>{books.creatorname}</div>
                </div>
                <div className={styles.modalLabel}>
                    <label>Дата создания</label>
                    <div>{books.creationtime ? new Date(books.creationtime).toLocaleDateString() : new Date().toLocaleDateString()}</div>
                </div>
                <div className={styles.modalLabel}>
                    <label>Учебный курс</label>
                    <div>{books.cathedra}</div>
                </div>
            </Col>
            <Col md={6} className={'mt-2'}>
                <ModalInputWrapper label={"Учебная программа"}>
                    <select value={data.curriculum_course_id}
                            className={styles.select}
                            onChange={({target: {value}}) => onChange("curriculum_course_id", value)}
                            disabled={modalType === EDIT ? true : null}
                    >
                        <option value={""}>
                            Не выбрано
                        </option>
                        {courses && courses.map((item) =>
                            <option value={item.cid} key={item.cid}>{item.course}</option>
                        )}
                    </select>
                </ModalInputWrapper>
                {data.modalSubmitError &&
                <p className={styles.errorMessage}>Необходимо выбрать учебную программу</p>}
            </Col>
        </Row>
    );
};

BooksOrderModalHeader.propTypes = {
    books: T.object,
    courses: T.array,
    modalType: T.string,
};

export default BooksOrderModalHeader;