import {
  SUCCESS,
  START,
  BOOK_RESERVE_COUNT_REQUEST, BOOK_RESERVE_LIST_REQUEST
} from "../../../actions/action-types";

const initialState = [];

export const listReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${BOOK_RESERVE_COUNT_REQUEST}${START}`:
      return initialState;

    case `${BOOK_RESERVE_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
