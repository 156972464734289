import { ERROR, AUTHOR_IMAGE_REQUEST, START, SUCCESS } from "../action-types";

export const authorImageRequestStart = (id) => ({
  type: `${AUTHOR_IMAGE_REQUEST}${START}`,
  payload: id
});

export const authorImageRequestSuccess = (data) => ({
  type: `${AUTHOR_IMAGE_REQUEST}${SUCCESS}`,
  payload: data
});

export const authorImageRequestError = (error) => ({
  type: `${AUTHOR_IMAGE_REQUEST}${ERROR}`,
  payload: error
});


