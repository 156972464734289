import React from 'react'
import T from 'prop-types'
import { Button } from 'antd'
import ModalWindow from '../../ModalWindow'
import { Col, Row } from 'react-bootstrap'
import CoverPhotoPlaceholder from '../../Covers/List/Item/CoverPhotoPlaceholder'
import { resolveDateWithTimeZone } from '../../../utils/Dates/resolveDateWithTimeZone'
import { Link } from 'react-router-dom'
import styles from '../../UtulityComponents/SearchBooksItem/SearchBooksItem.module.css'

const MessageModal = ({ data, setData, deleteItem }) => {
  const { bookId, coverImage, title, message, ts, id } = data

  const messageDate = resolveDateWithTimeZone(new Date(ts).toLocaleString())

  return (
    <ModalWindow title='Просмотр сообщения' onRequestClose={() => setData(null)} isOpen={true} width='600px'>
      <Row>
        {bookId && (
          <Col md={3} lg={2}>
            <CoverPhotoPlaceholder bookId={bookId} alt={title} url={coverImage} />
          </Col>
        )}
        <Col>
          <Link to={`/book/${data.bookid}`} target='_blank' className={styles.titleLink}>
            <h3>{title}</h3>
          </Link>
          <div>
            <b className={'mr-1'}>Дата и время:</b>
            <span>{messageDate}</span>
          </div>
          <div>
            <b className={'mr-1'}>Сообщение:</b>
            <span>{message}</span>
          </div>
          <div className={'mt-2 d-flex justify-content-end'}>
            <Button type='primary' danger onClick={() => deleteItem(id)}>
              Удалить сообщение
            </Button>
          </div>
        </Col>
      </Row>
    </ModalWindow>
  )
}

MessageModal.propTypes = {
  data: T.object.isRequired,
}

export default MessageModal
