import { api } from "../../App";

export const getRoles = () => {
  return api.get("/admin/roles");
};

export const getRoleById = id => {
  return api.get(`/admin/roles/${id}`);
};

export const getRoleMembers = id => {
  return api.get(`/admin/roles/members/${id}`);
};

export const editRole = data => {
  return api.put(`/admin/roles/${data.pmid}`, data);
};

export const addRole = data => {
  return api.post("/admin/roles", data);
};

export const memberRoleAction = ({ roleId, memberId, actionType }) => {
  return api[actionType](`/admin/roles/members/${roleId}/${memberId}`);
};

export const deleteRole = id => {
  return api.delete(`/admin/roles/${id}`);
};
