import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import Pagination from '../../../../Pagination'
import FundMovingModal from './FundMovingModal'
import { documentBookListRequestStart } from '../../../../../redux/actions/documents/books/list'
import DocumentBooksTable from '../Components/BooksTable'
import DocumentsEditBookForm from '../Components/EditBookForm'
import SpinnerOuter from '../../../../UtulityComponents/SpinnerOuter'
import BookSearchForm from '../../../../Covers/SearchForm'
import { coversCountRequestStart } from '../../../../../redux/actions/covers/count'
import { setCoversFormData } from '../../../../../redux/actions/covers/form'
import { setCoversSearchData } from '../../../../../redux/actions/covers/search'
import SearchBooksItem from '../Components/SearchBooksItem'
import { libraryTypesList } from '../../../../../api/library/types'


const mapStateToProps = ({ covers, documents, options, bookEdit }) => ({
  books: documents.books,
  formData: covers.form,
  searchResults: covers.list,
  loader: covers.loader,
  loadSuccess: covers.loadSuccess,
  searchData: covers.search,
  count: covers.count,
  optionsList: options.data,
})

const mapDispatchToProps = {
  setFormData: setCoversFormData,
  setSearchData: setCoversSearchData,
  startSearch: coversCountRequestStart,
  requestBooks: documentBookListRequestStart,
}

const BooksFromDocuments = ({
  bookid,
  modalData,
  formData,
  setFormData,
  startSearch,
  searchData,
  setSearchData,
  count,
  ControlsButton,
  searchOpen,
  searchResults,
  books,
  requestBooks,
  currentBook,
  fundMovingModalOpen,
  setFundMovingModalOpen,
  documentType,
  setCurrentBook,
  onSelectFromSearch,
  fromInvBook,
  loader,
  NoResult,
  onBookDelete,
  onBookSave,
  optionsList,
  loadSuccess,
}) => {
  const view = modalData.data.view

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    startSearch()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  useEffect(() => {
    // Шифр
    if (view === 1) {
      setFormData({ ...formData, typeId: 1, idclient: optionsList.own_client_id })
    } else if (view === 3 || view === 4) {
      setFormData({ ...formData, typeId: 11, idclient: optionsList.own_client_id })
    } else {
      setFormData({ ...formData, typeId: 0, idclient: optionsList.own_client_id })
    }
  }, [optionsList, view])


  return (
    <Row>
      <Col md={24}>
        <div id='current'>
          <DocumentBooksTable
            data={books}
            modalData={modalData}
            handleSelect={setCurrentBook}
            selectedId={currentBook ? currentBook.id : null}
            type={documentType}
            fromInvBook={fromInvBook}
          />
          {currentBook && (
            <DocumentsEditBookForm
              data={currentBook}
              modalData={modalData}
              onSave={onBookSave}
              onDelete={currentBook.id ? onBookDelete : () => setCurrentBook(null)}
              setData={setCurrentBook}
              type={documentType}
              />
              )}
              </div>
              
              <ControlsButton />
              
              {searchOpen && (
          <div>
            <BookSearchForm
              data={formData}
              setData={setFormData}
              modalData={modalData}
              disableAlphabet={true}
              onSubmit={startSearch}
              invNumbers={true}
            />

            <PaginationComponent />

            <SpinnerOuter isLoading={loader} />

            {!!searchResults.length && loadSuccess && (
              <>
                <div>Результаты поиска</div>
                {!!searchResults.length ? (
                  searchResults.map((item, index) => <SearchBooksItem key={index} data={item} onClick={onSelectFromSearch} />)
                ) : (
                  <NoResult />
                )}
              </>
            )}

            <PaginationComponent />
          </div>
        )}
      </Col>

      {fundMovingModalOpen && (
        <FundMovingModal
          setClosed={() => setFundMovingModalOpen(false)}
          isOpen={fundMovingModalOpen}
          modalData={modalData}
          booksData={books}
          requestBooks={requestBooks}
        />
      )}
    </Row>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BooksFromDocuments)
