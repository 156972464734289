import {DISCIPLINE_FULLLIST_REQUEST, ERROR, START, SUCCESS} from "../action-types";


export const disciplineFullListRequestStart = () => ({
    type: `${DISCIPLINE_FULLLIST_REQUEST}${START}`
});

export const disciplineFullListRequestSuccess = (data) => ({
    type: `${DISCIPLINE_FULLLIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const disciplineFullListRequestError = (data) => ({
    type: `${DISCIPLINE_FULLLIST_REQUEST}${ERROR}`,
    payload: data
});

