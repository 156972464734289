import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import Button from '../../../Button'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import { Radio } from 'antd'
import { api } from '../../../../App'

const validationSchema = Yup.object().shape({
  dateFrom: Yup.date().required("Выберите дату начала"),
  dateTo: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("dateFrom"), "Дата завершения должна быть позже даты начала"),
});

const StaticInformationReportForm = ({ onSubmit }) => {
  const [xpClient, setXpClient] = useState([])

  useEffect(() => {
    // Шифр
    api.get('/xp_client').then(res => setXpClient([{ idclient: 0, name: 'все' }, ...res.data]))
  }, [])

  const searchForm = useFormik({
    initialValues: {
      dateFrom: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      mode: 1,
      // Шифр
      client: 0,
    },
    validationSchema,
    onSubmit: values => onSubmit(values),
  })


  return (
    <div className={'formContainer'}>
      <Row>
        <Col>
          <ModalInputWrapper
            label='C*'
            value={searchForm.values.dateFrom}
            name='dateFrom'
            error={searchForm.errors.dateFrom}
            onChange={searchForm.handleChange}
            type='date'
          />
        </Col>
        <Col>
          <ModalInputWrapper
            label='По*'
            value={searchForm.values.dateTo}
            name='dateTo'
            error={searchForm.errors.dateTo}
            onChange={searchForm.handleChange}
            type='date'
          />
        </Col>
        <Col>
          <ModalInputWrapper label='ID Клиента*'>
            <select name='client' value={searchForm.values.client} onChange={searchForm.handleChange}>
              {xpClient.map(e => (
                <option value={e.idclient} key={e.idclient}>
                  {e.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={12} lg={12}>
          <ModalInputWrapper label={'Режим выбора'}>
            <Radio.Group onChange={searchForm.handleChange} value={searchForm.values.mode} name='mode'>
              <Radio value={1}>по категориям</Radio>
              <Radio value={2}> по темам</Radio>
              <Radio value={3}>по направлениям обучения</Radio>
            </Radio.Group>
          </ModalInputWrapper>
        </Col>

        <Col md={'auto'} className={'text-right'}>
          <ModalInputWrapper label={''}>
            <Button label={'Найти'} onClick={searchForm.handleSubmit} />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  )
}

StaticInformationReportForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default StaticInformationReportForm
