import jsPDF from "jspdf"
import addFont from "../../../../fonts/fonts"

const orientation = 'landscape'
const title = 'Уничтожение материалов библиотечного фонда'


export const getDSPdata = async ({ data, actData }) => {
  addFont(jsPDF.API)

  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
  doc.setFont('Montserrat')

  let lastY = 20

  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height) {
      doc.addPage('a4', orientation)
      newY = 10
    }
    lastY = newY
    return newY
  }

  doc.setFontSize('14')
  doc.text(actData.documentnumber, doc.internal.pageSize.width / 2, 10, null, null, 'center')
  doc.text('АКТ № ________', doc.internal.pageSize.width / 2, 10, null, null, 'center')
  doc.text(title, doc.internal.pageSize.width / 2, 17, null, null, 'center')

  doc.autoTable({
    html: '#materialDestructionActPDFTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    columnStyles: {
      2: { cellWidth: 100 },
    },

    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 27,
  })

  doc.text('Председатель комиссии', 30, getY(15, doc.autoTableEndPosY()))
  doc.text('_______________________________________', 150, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 157, getY(3))
  doc.text('имя, отчество)', 157, getY(3))
  doc.setFontSize(10)
  
  doc.text('Члены комиссии:', 30, getY(15))
  doc.text('_______________________________________', 150, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 157, getY(3))
  doc.text('имя, отчество)', 157, getY(3))
  doc.setFontSize(10)
  
  doc.text('_______________________________________', 150, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 157, getY(3))
  doc.text('имя, отчество)', 157, getY(3))
  doc.setFontSize(10)
  
  doc.text('_______________________________________', 150, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 157, getY(3))
  doc.text('имя, отчество)', 157, getY(3))
  doc.setFontSize(10)

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}