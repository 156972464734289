import {call, put, takeLatest, select} from "redux-saga/effects";
import {START, UDK_LIST_COLLAPSE, UDK_LIST_REQUEST} from "../../actions/action-types";
import {udkListRequestError, udkListRequestSuccess} from "../../actions/udk/list";
import {udkList} from "../../../api/udk/list";
import {addToList, collapseList} from "../../../utils/udkAndBbkHelpers";

export default function* udkRequestWatcher() {
    yield takeLatest(`${UDK_LIST_REQUEST}${START}`, udkRequestWorker);
    yield takeLatest(UDK_LIST_COLLAPSE, udkCollapseListWorker);
}

function* udkRequestWorker({ payload: { id, search, rewrite } }) {
    const currentList = yield select(({udk}) => udk);
    try {
        const response = yield call(udkList, id, search);
        const result = rewrite ? response.data : addToList(currentList, id, response.data);
        yield put(udkListRequestSuccess(result));
    } catch (error) {
        yield put(udkListRequestError(error));
    }
}

function* udkCollapseListWorker({payload: id}) {
    const currentList = yield select(({udk}) => udk);
    const result = collapseList(currentList, id);
    yield put(udkListRequestSuccess(result));
}
