import { CATEGORIES_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const categoriesListRequestStart = () => ({
  type: `${CATEGORIES_LIST_REQUEST}${START}`
});

export const categoriesListRequestSuccess = (data) => ({
  type: `${CATEGORIES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const categoriesListRequestError = (data) => ({
  type: `${CATEGORIES_LIST_REQUEST}${ERROR}`,
  payload: data
});

