import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import { Button } from 'antd'
import InputWrapper from '../../../components/Modal/InputWrapper'

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const SecretDocBookForm = ({ onSubmit }) => {
  
  
  const searchForm = useFormik({
    initialValues: {
      from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
    validationSchema: defaultValidationSchema,
    onSubmit: values => onSubmit(values),
  })

  return (
    <div className={'formContainer'}>
      <Row>
        <Col>
          <InputWrapper
            label='C*'
            value={searchForm.values.from}
            name='from'
            error={searchForm.errors.from}
            onChange={searchForm.handleChange}
            type='date'
          />
        </Col>
        <Col>
          <InputWrapper
            label='По*'
            value={searchForm.values.to}
            name='to'
            error={searchForm.errors.to}
            onChange={searchForm.handleChange}
            type='date'
          />
        </Col>

        <Col md={'auto'} className={'text-right'}>
          <InputWrapper label={''}>
            <Button type='primary' onClick={searchForm.handleSubmit}>Найти</Button>
          </InputWrapper>
        </Col>
      </Row>
    </div>
  )
}

SecretDocBookForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default SecretDocBookForm
