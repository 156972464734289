import React, { useEffect } from 'react';
import styles from './BookLoanScene.module.css';
import LibraryCardBlock from "../../components/BookLoan/LibraryCardBlock";
import QRCodeBlock from "../../components/BookLoan/QRCodeBlock";
import { readerDataRequestStart } from "../../redux/actions/reader/getData";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  readerData: state.readerData,
  mid: state.auth.data.mid
});

const mapDispatchToProps = dispatch => ({
  requestData: (mid) => dispatch(readerDataRequestStart(mid))
});

const BookLoanScene = ({ mid, readerData, requestData }) => {
  useEffect(() => {
    requestData(mid);
  }, [mid, requestData]);

  return (
    <div className={styles.container}>
      <LibraryCardBlock data={readerData}/>
      <QRCodeBlock/>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps) (BookLoanScene);
