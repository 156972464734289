import { SUCCESS, DIRECTORY_LIST_REQUEST } from "../../../actions/action-types";

const initialState = [];

export const directoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${DIRECTORY_LIST_REQUEST}${SUCCESS}`:
      return action.payload;
      
    default:
      return state;
  }
};
