import { ERROR, LOST_DOC_POST_REQUEST, START, SUCCESS } from "../action-types";

export const postLostDocStart = (data) => ({
  type: `${LOST_DOC_POST_REQUEST}${START}`,
  payload: data,
});

export const postLostDocSuccess = (response) => ({
  type: `${LOST_DOC_POST_REQUEST}${SUCCESS}`,
  payload: response
});

export const postLostDocError = (err) => ({
  type: `${LOST_DOC_POST_REQUEST}${ERROR}`,
  payload: err
});
