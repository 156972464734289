import React from 'react';
import T from 'prop-types';
import classNames from 'classnames';
import {Space, Button} from "antd";
import ModalWindow from "../../ModalWindow";

import styles from './CatalogsModalWithForm.module.css'


const CatalogsModalWithForm = ({closeModal, submitHandler, title, children, width}) => {
    return (
        <ModalWindow
            title={title}
            isOpen={true}
            onRequestClose={closeModal}
            width={width}
        >
            { children }

            <div className={classNames('d-flex justify-content-end mt-2 pt-2', styles.controls)}>
                <Space>
                    <Button onClick={closeModal} type="primary" danger>Отмена</Button>
                    <Button onClick={submitHandler} type="primary">Сохранить</Button>
                </Space>

            </div>
        </ModalWindow>
    );
};

CatalogsModalWithForm.propTypes = {
    title: T.string.isRequired,
    children: T.node,
    closeModal: T.func.isRequired,
    submitHandler: T.func.isRequired,
};

export default CatalogsModalWithForm;
