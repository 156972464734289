import {ERROR, EXEMPT_TEST_DELETE_REQUEST, START, SUCCESS} from "../action-types";

export const exemptTestDeleteRequestStart = (id) => ({
    type: `${EXEMPT_TEST_DELETE_REQUEST}${START}`,
    payload: id
});

export const exemptTestDeleteRequestSuccess = (data) => ({
    type: `${EXEMPT_TEST_DELETE_REQUEST}${SUCCESS}`,
    payload: data
});

export const exemptTestDeleteRequestError = (error) => ({
    type: `${EXEMPT_TEST_DELETE_REQUEST}${ERROR}`,
    payload: error
});
