import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import { EDIT } from '../../../constants/modalTypes'
import ModalInputWrapper from '../../Modal/InputWrapper'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { api } from '../../../App'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import CatalogsModalWithForm from '../../Catalogs/ModalWithForm'
import { Row, Col, Image, Space, Upload, Button } from 'antd'
import { getBase64 } from '../../../utils/helpers/helpers'
import { UploadOutlined } from '@ant-design/icons'
import { IMAGE_PLUG } from '../../../constants/imagePlug'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Это обязательное поле').trim('Это обязательное поле').max(100, 'Недопустимая длинна названия (max 100)'),
  mimetype: Yup.string().notRequired(),
  duedate: Yup.number().required('Это обязательное поле').nullable().min(1, 'Число должно быть положительным').max(999, 'Число должно быть менее 999').typeError('Укажите число'),
})

const LibraryTypesModal = ({ onSubmit, modalData, closeModal }) => {
  const [image, setImage] = useState(IMAGE_PLUG)
  const [imageLoading, setImageLoading] = useState(false)
  const [inputFile, setInputFile] = useState(null)

  const { modalType, data } = modalData

  if (data.mimetype === null) data.mimetype = ''

  const modalForm = useFormik({
    initialValues: data,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => onSubmit(values),
  })

  useEffect(() => {
    if (data.imageexist) imageLoader()
  }, [data.imageexist])

  const submitHandler = () => {
    if (inputFile) imageUploader(inputFile)
    modalForm.handleSubmit()
  }

  const imageUploader = file => {
    setImageLoading(true)

    const formData = new FormData()
    formData.append('image', file)

    api
      .post(`/librarytype/${modalData.data.id}/image`, formData)
      .then(res => {
        setImageLoading(false)
        setImage(res.config.url)
      })
      .catch(err => {
        setImageLoading(false)
      })
  }

  const deleteImage = () => {
    setImageLoading(true)

    api
      .delete(`/librarytype/${modalData.data.id}/image`)
      .then(() => {
        setImageLoading(false)
        setImage(IMAGE_PLUG)
      })
      .catch(err => {
        setImageLoading(false)
      })
  }

  const imageLoader = () => {
    setImageLoading(true)

    api
      .get(`/librarytype/${modalData.data.id}/image`)
      .then(res => {
        setImage(res.config.url)
        setImageLoading(false)
      })
      .catch(e => {
        setImageLoading(false)
      })
  }

  const onImageChange = file => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      return
    }
    setInputFile(file)
    setImageLoading(true)

    getBase64(file).then(image => {
      setImageLoading(false)
      setImage(image)
    })
  }

  return (
    <CatalogsModalWithForm
      title={`Тип литературы: ${modalType === EDIT ? 'редактирование' : 'добавление'}`}
      closeModal={closeModal}
      submitHandler={submitHandler}
      width={720}
    >
      <Row gutter={16}>
        <Col>
          <Space direction='vertical'>
            {imageLoading ? <SpinnerOuter /> : <Image width={200} src={image} />}
            <Space>
              <Upload onChange={e => onImageChange(e.file.originFileObj)} showUploadList={false}>
                <Button icon={<UploadOutlined />}>Загрузить</Button>
              </Upload>
              <Button onClick={deleteImage} danger>
                Удалить
              </Button>
            </Space>
          </Space>
        </Col>
        <Col flex={1}>
          <div style={{ width: '100%' }}>
            <ModalInputWrapper
              label={'Название'}
              name={'name'}
              value={modalForm.values.name}
              onChange={modalForm.handleChange}
              error={modalForm.errors.name}
            />
            <ModalInputWrapper
              label={'ТИП ДАННЫХ'}
              name={'mimetype'}
              value={modalForm.values.mimetype}
              onChange={modalForm.handleChange}
              error={modalForm.errors.mimetype}
            />
            <ModalInputWrapper
              label={'Срок использования'}
              name={'duedate'}
              value={modalForm.values.duedate}
              onChange={modalForm.handleChange}
              onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
              error={modalForm.errors.duedate}
            />
         
          </div>
        </Col>
      </Row>
    </CatalogsModalWithForm>
  )
}

LibraryTypesModal.propTypes = {
  modalData: T.object,
  setModalData: T.func,
  onSubmit: T.func,
  closeModal: T.func,
}

export default LibraryTypesModal
