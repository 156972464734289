import React, { useMemo } from 'react'
import DataTableCol from '../../DataTable/Column'
import DataTable from '../../DataTable'
import styles from './InventaryBook.module.css'
import idGen from '../../../utils/generateID'

const InventoryBookTable = ({ setSort, sortBy, data, onCellClick }) => {
  const priceResolver = price => {
    if (!price) return 'Цена не указана'
    return price.toFixed(2) + ' руб'
  }

  const tableId = useMemo(idGen, [data])

  return (
    <div className={styles.container} id='InventoryBookTable'>
      {!!data.length && (
        <DataTable pseudoRows={2} sortBy={sortBy} onHeadClick={setSort} data={data} tableId={tableId} onCellClick={onCellClick}>
          <DataTableCol
            // colSpan="2"
            style={{width: '100px', display: 'block'}}
            rowWidth='100px'
            title='Дата записи'
            resolver={({ documentdate }) => new Date(documentdate).toLocaleDateString()}
          />
          <DataTableCol  title='Инвентарный номер' value='bookinstancenumber' />
          <DataTableCol  title='№ Экз.' value='copynumber' />
          <DataTableCol  title='Гриф секретности' value='secret_name' />
          <DataTableCol  title='Отметка о проверке фонда' value='inventory_date' />
          <DataTableCol
            title='Автор и Заглавие'
            resolver={({ title, authors }) => {
              authors = authors || []
              authors = authors.map(item => item.name)
              return `${title} ${authors.toString()}`
            }}
            
          />
          <DataTableCol  title='Место и год издания' resolver={({year, city}) => <>{city || ''} <br/> {year || ''}</>} />
          <DataTableCol  title='Цена'rowWidth='78px'  resolver={({ price }) => priceResolver(price)} />
          <DataTableCol  title='Цена с учетом коэфф.' resolver={({ coeff_price }) => priceResolver(coeff_price)} />
          <DataTableCol  title='№ записи в книге суммарного учета' value='' />
          <DataTableCol  title='Отдел' value='series' />
          <DataTableCol
            title='№ и дата документа приема'
            rowWidth='104px'
            resolver={({ documentnumber, document_arrival_date, document_type_name }) => (
              <div>
                <p>{document_type_name}</p> {new Date(document_arrival_date).toLocaleDateString()} №{documentnumber}
              </div>
            )}
            
          />
          <DataTableCol  title='Тип' value='-' />
          <DataTableCol
            
            title='Номер и дата первичного учетного документа о выбытии'
            resolver={({ document_off_name, document_off_number, document_off_date }) => (
              <div>
                <p>{document_off_name}</p>
                <p>{document_off_date && new Date(document_off_date).toLocaleDateString()}</p>
                <p>{document_off_number ? '№' + document_off_number : ''}</p>
              </div>
            )}
          />
          <DataTableCol  title='Примечание' value='-' />
        </DataTable>
      )}
    </div>
  )
}

export default InventoryBookTable
