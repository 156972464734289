import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'
import { coversCountRequestStart } from '../../redux/actions/covers/count'
import { setCoversFormData } from '../../redux/actions/covers/form'
import { setCoversSearchData } from '../../redux/actions/covers/search'
import Pagination from '../../components/Pagination'
import BookSearchForm from '../../components/Covers/SearchForm'
import ReadingList from '../../components/Reading/List'
import { reserveBookRequestStart } from '../../redux/actions/books/reserveBook'
import { unreserveBookRequestStart } from '../../redux/actions/books/unreserveBook'
import SearchByImageName from '../../components/SearchByImage/Form'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import ReadingSmallSearch from '../../components/Reading/SmallSearch'
import ReadingAdvancedSearch from '../../components/Reading/AdvancedSearch'
import { coversAdvancedSearchParamsRequestStart } from '../../redux/actions/covers/advancedSearch/params'
import { coversListAdvancedSearchRequestStart } from '../../redux/actions/covers/advancedSearch/advancedSearch'
import { bookScanOrderAddRequestStart } from '../../redux/actions/bookScanOrders/add'
import { libraryInstitutionsRequestStart } from '../../redux/actions/library/info/institutions'
import BookScanOrderModal from '../../components/BookScanOrders/Modal'
import styles from '../BookEdit/BookEditScene.module.css'
import { bookTransferAddRequestStart } from '../../redux/actions/bookTransfer/add'
import RequestToBookTransferModal from '../../components/Reading/RequestToBookTransferModal'
import { createNotification } from '../../components/notification/notification'
import { searchByImageActions } from '../../redux/actions/covers/searchByImage'

const mapStateToProps = ({ covers, options, library }) => ({
  list: covers.list,
  count: covers.count,
  formData: covers.form,
  searchData: covers.search,
  loader: covers.loader,
  loadSuccess: covers.loadSuccess,
  advancedSearchParams: covers.advancedSearchParams,
  ownClient: options?.data?.own_client_id,
  institutionsList: library.info.institutions,
})

const mapDispatchToProps = {
  requestList: coversCountRequestStart,
  requestListOfExtendedSearch: coversCountRequestStart,
  requestListOfAdvancedSearch: coversListAdvancedSearchRequestStart,
  requestListByImage: searchByImageActions.listRequestStart,
  setFormData: setCoversFormData,
  setSearchData: setCoversSearchData,
  reserveBook: reserveBookRequestStart,
  unreserveBook: unreserveBookRequestStart,
  requestAdvancedSearchParams: coversAdvancedSearchParamsRequestStart,
  addBookScanOrder: bookScanOrderAddRequestStart,
  requestInstitutions: libraryInstitutionsRequestStart,
  bookTransferAddRequest: bookTransferAddRequestStart,
}

export const advancedSearchFormRowTemplate = {
  fieldtitle: '',
  conditions: '',
  value: '',
}

const ReadingScene = ({
  count,
  loader,
  list,
  requestList,
  formData,
  setFormData,
  searchData,
  setSearchData,
  reserveBook,
  unreserveBook,
  loadSuccess,
  requestAdvancedSearchParams,
  advancedSearchParams,
  requestListOfAdvancedSearch,
  addBookScanOrder,
  requestInstitutions,
  institutionsList,
  ownClient,
  bookTransferAddRequest,
  requestListByImage,
}) => {
  const [searchType, setSearchType] = useState(1)
  const [advancedSearchData, setAdvancedSearchData] = useState([JSON.parse(JSON.stringify(advancedSearchFormRowTemplate))])
  const [modalData, setModalData] = useState(null)
  const [modalDataTransfer, setModalDataTransfer] = useState(null)
  const [isTableViewCompact, setTableViewCompact] = useState(false)

  useEffect(() => {
    !institutionsList.length && requestInstitutions()
  }, [])

  useEffect(() => {
    requestAdvancedSearchParams()
  }, [])

  useEffect(() => {
    setFormData({ ...formData, idclient: ownClient })
  }, [ownClient])

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })

    if (+searchType === 3) {
      requestListOfAdvancedSearch(advancedSearchData)
    } else if (+searchType === 2) {
      requestList(true)
    } else {
      requestList(false)
    }
  }

  const addBookOrderHandler = (bookId, bookIdClients) => {
    const bookArray = []
    bookIdClients.forEach(item => {
      bookArray.push(item.name)
    })

    if (bookArray.length === 0) {
      createNotification('error', 'В списке нет организаций')
    } else if (bookArray.length === 1) {
      const selectedClientId = institutionsList.find(item => item.idclient === bookIdClients[0].id)
      bookTransferAddRequest({ bookid: bookId, clientid: selectedClientId.idclient })
    } else if (bookArray.length > 1) {
      setModalDataTransfer({
        bookId: bookId,
        selectedId: null,
        bookClientsArray: bookArray.map(item => institutionsList.find(institution => institution.name === item.trim())),
      })
    }
  }

  const addBookScanOrderHandler = (bookId, bookClients) => {
    const bookClientsArray = (bookClients && bookClients.split(',')) || []

    //если в списке нет организаций
    if (bookClientsArray.length === 0) {
      addBookScanOrder({ bookId, locationId: ownClient })
    }
    //если в списке одна организация
    else if (bookClientsArray.length === 1) {
      const selectedClientId = institutionsList.find(item => item.name === bookClientsArray[0]).idclient
      addBookScanOrder({ bookId, locationId: selectedClientId })
    }
    //если в списке несколько организаций
    else if (bookClientsArray.length > 1) {
      const checkSelectedBookClientHasOwnClinet = () => {
        return bookClientsArray
          .map(item => institutionsList.find(institution => institution.name === item.trim()).idclient)
          .includes(ownClient)
      }
      //если списке есть своя организация то указать ее
      if (checkSelectedBookClientHasOwnClinet()) {
        addBookScanOrder({ bookId, locationId: ownClient })
      }
      //иначе пользователь должен выбрать местоположение из достпуных
      else {
        setModalData({
          bookId: bookId,
          selectedId: null,
          bookClientsArray: bookClientsArray.map(item => institutionsList.find(institution => institution.name === item.trim())),
        })
      }
    }
  }

  const closeModal = () => {
    setModalData(null)
  }
  const closeModalTransfer = () => {
    setModalDataTransfer(null)
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )
  
  const searchWithPageReset = () => {
    setSearchData({...searchData, page: 1});
    searchType==3 ? requestListOfAdvancedSearch(advancedSearchData) : requestList()
  }

  return (
    <SceneContainer title={'Электронный читальный зал'}>
      {modalData && (
        <BookScanOrderModal
          isOpen={true}
          closeModal={closeModal}
          modalData={modalData}
          setModalData={setModalData}
          onSubmit={addBookScanOrder}
        />
      )}
      {modalDataTransfer && (
        <RequestToBookTransferModal
          isOpen={true}
          closeModal={closeModalTransfer}
          modalData={modalDataTransfer}
          setModalData={setModalDataTransfer}
          onSubmit={bookTransferAddRequest}
        />
      )}
      <Tabs id={'readingSearchTabs'} activeKey={searchType} onSelect={k => setSearchType(k)}>
        <Tab.Pane eventKey='1' title='Обычный поиск' className={styles.tabContent}>
          <ReadingSmallSearch data={formData} setData={setFormData} noVerifyStatus={true} onSubmit={searchWithPageReset} />
        </Tab.Pane>
        <Tab.Pane eventKey='2' title='Расширенный поиск' className={styles.tabContent}>
          <BookSearchForm data={formData} setData={setFormData} onSubmit={searchWithPageReset} />
          <div className={'formContainer'}>
            <SearchByImageName onSubmit={requestListByImage} />
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey='3' title='Продвинутый поиск' className={styles.tabContent}>
          <ReadingAdvancedSearch
            searchParams={advancedSearchParams}
            onSubmit={searchWithPageReset}
            data={advancedSearchData}
            setData={setAdvancedSearchData}
          />
        </Tab.Pane>
      </Tabs>

      <PaginationComponent />

      {loader && (
        <SpinnerOuter isLoading={loader} />)}
      
      {list ? 
      <ReadingList
          loader={loader}
          data={list}
          reserveBook={reserveBook}
          unreserveBook={unreserveBook}
          loadSuccess={loadSuccess}
          addBookScanOrder={addBookScanOrderHandler}
          addBookOrderHandler={addBookOrderHandler}
          bookTransferAddRequest={bookTransferAddRequest}
          isTableViewCompact={isTableViewCompact}
          setTableViewCompact={setTableViewCompact}
          count={count}
        />
        :
        ''
      }

      {loader && (
        <SpinnerOuter isLoading={loader} />)}
      
      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadingScene)
