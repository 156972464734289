import jsPDF from 'jspdf'
import T from 'prop-types'
import React from 'react'
import addFont from '../../../../fonts/fonts'
import PdfPrintPanel from '../../../PDFPrint/Panel'

const LibrariesDiaryPdfPrint = ({ data, searchFormValue, librariesList }) => {
  const monthsList = [
    '',
    ' Январь',
    ' Февраль',
    ' Март',
    ' Апрель',
    ' Май',
    ' Июнь',
    ' Июль',
    ' Август',
    ' Сентябрь',
    ' Октябрь',
    ' Ноябрь',
    ' Декабрь',
  ]

  const libraryName = librariesList.find(e => e.id == searchFormValue.library)?.name
  const title = 'Отчеты / Дневник работы библиотеки'
  const orientation = 'landscape'

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
    doc.setFont('Montserrat')
    doc.setFont( undefined, 'bold')
    doc.setFontSize('16')

    doc.text('ДНЕВНИК РАБОТЫ БИБЛИОТЕКИ', doc.internal.pageSize.width / 2, 10, null, null, 'center')

    doc.setFont( undefined, 'normal')
    doc.setFontSize('13')

    doc.text(
      'Федеральное военное государственное образовательное учереждение',
      doc.internal.pageSize.width / 2,
      20,
      null,
      null,
      'center'
    )

    doc.text('высшего профессионального образования', doc.internal.pageSize.width / 2, 26, null, null, 'center')
    doc.text(`"${librariesList[0].orgname}"`, doc.internal.pageSize.width / 2, 32, null, null, 'center')
    doc.text('Министерства обороны Российской Федерации', doc.internal.pageSize.width / 2, 38, null, null, 'center')

    doc.setFontSize('14')

    doc.text('УЧЕТ ЧИТАТЕЛЕЙ, ВЫДАЧИ КНИГ И ЖУРНАЛОВ', doc.internal.pageSize.width / 2, 49, null, null, 'center')

    doc.text(
      `за${monthsList[searchFormValue.month]} ${searchFormValue.year} ${searchFormValue.month ? 'года' : 'год'}`,
      doc.internal.pageSize.width / 2,
      55,
      null,
      null,
      'center'
    )

    doc.text(`Библиотека: ${libraryName || 'все библиотеки'}`, 23, 65)
    doc.autoTable({
      html: data?.length > 31 ? '#libraries-diary-table-for-the-year table' : '#libraries-diary-table table',
      styles: { font: 'Montserrat', fontSize: 6 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      // margin: {
      //   top: 64,
      // },
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 70,
    })

    doc.addPage('a4', orientation)
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
}

LibrariesDiaryPdfPrint.propTypes = {
  librariesList: T.array.isRequired,
  searchFormValue: T.object.isRequired,
}

export default LibrariesDiaryPdfPrint
