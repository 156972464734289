import { INSTITUTIONS_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const institutionsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${INSTITUTIONS_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};