import React, {useEffect} from 'react';
import Alphabet from "../../components/LibraryAlphabet/Alphabet";
import {alphabetRangeRequestStart} from "../../redux/actions/library/alphabet/range";
import {connect} from "react-redux";
import LibraryAlphabetSearch from "../../components/LibraryAlphabet/Search";
import SuggestionsAlphabet from "../../components/LibraryAlphabet/SuggestionsAlphabet";
import {setAlphabetSearchData} from "../../redux/actions/library/alphabet/search";
import LibraryCarousel from "../../components/LibraryAlphabet/Carousel";
import {alphabetLettersRequestStart} from "../../redux/actions/library/alphabet/letters";
import {alphabetCountRequestStart} from "../../redux/actions/library/alphabet/count";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import {alphabetSingleItemRequestStart} from "../../redux/actions/library/alphabet/singleItem";
import {Row, Col, Button} from "antd";

const mapStateToProps = ({library}) => ({
    alphabetRange: library.alphabet.range,
    searchData: library.alphabet.search,
    count: library.alphabet.count,
    currentItem: library.alphabet.currentItem,
    letters: library.alphabet.letters
});

const mapDispatchToProps = {
    requestItems: alphabetCountRequestStart,
    requestRange: alphabetRangeRequestStart,
    setSearchData: setAlphabetSearchData,
    requestLetters: alphabetLettersRequestStart,
    requestSingleItem: alphabetSingleItemRequestStart
};

const LibraryAlphabetScene = ({
                                  requestRange,
                                  alphabetRange,
                                  searchData,
                                  setSearchData,
                                  requestItems,
                                  count,
                                  letters,
                                  requestLetters,
                                  requestSingleItem,
                                  currentItem
                              }) => {
    useEffect(() => {
        !letters && requestLetters();
    }, [letters, requestLetters]);

    useEffect(() => {
        requestItems();
    }, [requestItems]);


    return (
        <SceneContainer title={'Алфавитный каталог'}>
            <Row gutter={15}>
                <Col md={8}>
                    <Row>
                        <Col md={12}>
                            {letters &&
                            <Alphabet
                                onSelect={(char) => requestRange(char)}
                                items={letters}
                                span={12}
                            />
                            }
                        </Col>
                        <Col md={12}>
                            {!!alphabetRange.length &&
                            <Button onClick={() => {
                                setSearchData({
                                    ...searchData, letters: ""
                                });
                                requestItems();
                            }}>
                                Сброс
                            </Button>
                            }
                            {alphabetRange &&
                                <SuggestionsAlphabet
                                    items={alphabetRange}
                                    onSelect={(letters) => {
                                        setSearchData({
                                            ...searchData,
                                            letters
                                        });
                                        requestItems()
                                    }}/>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col md={16}>
                    <LibraryAlphabetSearch
                        data={searchData}
                        setData={setSearchData}
                        onSubmit={requestItems}
                        count={count}
                    />
                    {count > 0 &&
                    <LibraryCarousel itemsCount={count} currentItem={currentItem}
                                     requestSingleItem={requestSingleItem}/>
                    }

                </Col>
            </Row>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryAlphabetScene);
