import { ERROR, RESERVED_BOOKS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const reservedBooksListRequestStart = (mid) => ({
  type: `${RESERVED_BOOKS_LIST_REQUEST}${START}`,
  payload: mid
});

export const reservedBooksListRequestSuccess = (data) => ({
  type: `${RESERVED_BOOKS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const reservedBooksListRequestError = (data) => ({
  type: `${RESERVED_BOOKS_LIST_REQUEST}${ERROR}`,
  payload: data
});

