import React from "react";

const TableRow = props => {
  return (
    <tr>
      <td>1</td>
      <td style={{ textAlign: "left" }}>
        Теория и методология политической науки
      </td>
      <td>1-1</td>
      <td>1-4</td>
      <td> </td>
      <td> </td>
      <td> </td>
    </tr>
  );
};

export default TableRow;
