import { combineReducers } from "redux";
import { booksExportRequestReducer } from './export/booksExportRequestReducer';
import { booksExportListReducer } from './list/bookExportListReducer';
import { booksExportDeleteReducer } from './delete/booksExportDeleteReducer';

export const booksExportReducer = combineReducers({
  export: booksExportRequestReducer,
  list: booksExportListReducer,
  delete: booksExportDeleteReducer
});
