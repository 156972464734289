import React, { useEffect, useState } from 'react';
import styles from './FilesFormat.module.css';
import { api } from '../../../App';
import { Button } from 'antd'
import { createNotification } from '../../../components/notification/notification'

const FilesFormat = () => {
  
  const [fileFormat, setfileFormat] = useState([]);

//   Запрос на получение списка форматов
  const getFilesRotmats = async () => {
    try {
      const response = await api.get(`/menu_file_settings`);
      if (response.status == 200) {
        setfileFormat(response.data);
      }
    }
    catch (e) {
      createNotification('error', 'Ошибка');
    }
  }
  
//   Запрос на изменение списка форматов
  const handlePost = async () => {
    try {
        const response = await api.put(`/menu_file_settings`, fileFormat, {});
        if (response.status == 200) {
            createNotification('success', 'Успешно');
        }
      }
      catch (e) {
        createNotification('error', 'Ошибка');
      }
  }

  useEffect(() => {
    getFilesRotmats();
    }, [])

    // Изменение стейта форматов
    const handleChange = (name, propertyName, propertyValue) => {
        let copyFileFormat = [...fileFormat];
        
        const newFileFormat = copyFileFormat.map(el => {
            if (el.name == name) {
                
                const newFormats = el.value.formats.map(_el => {
                    
                    if (Object.keys(_el)[0] == propertyName) {
                        return {[Object.keys(_el)] : propertyValue};
                    } else {
                        return _el;
                    }
                })
                el.value.formats = newFormats;
                return el;
            } else {
                return el;
            }
        }) 

        setfileFormat(newFileFormat);
    }

    // Изменение макс. размера документов
    const handleInput = ( value ) => {
        let newVal = value.replace(",", ".");
        newVal = value.split('.')[1];
        if (newVal?.length > 1) return;
        const copyFileFormat = [...fileFormat];
        setfileFormat(copyFileFormat.map(el => {
            el.value.size = value.replace(",", "."); 
            return el;
        } ))
    }


    return (
    <>
        <div className={styles.container}>
        {
            fileFormat?.map(el => 
            <>
                <div key={el.name}>
                    <span className={styles.name}>{el.value.name}</span>
                    
                    <div className={styles.wrapperButtons}>
                        <div className={styles.inputWrapper}>

                            {
                                el.value.formats.map(_el => {
                                    const key = Object.keys(_el)[0];
                                    const value = Object.values(_el)[0];
                                    return (
                                        <div 
                                        className={`${value ? `${styles.inputBlockActive} ${styles.inputBlock}` : `${styles.inputBlock}`}`}
                                        onClick={() => handleChange(el.name, key, !value)}>
                                            <input type="checkbox" name={key} checked={value}/>
                                            <i className={`${value ? `${styles.iconActive}` : `${styles.iconHidden}`}`}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path></svg></i>
                                            <label htmlFor={key}>{key}</label>
                                        </div> 
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
                <div className={styles.sizeBlockWrapper}>
                    <div className={styles.sizeBlock}>
                    <label>Максимальный размер загружаемых файлов (Гб)</label>
                    <input 
                        value={el.value.size} 
                        onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()} 
                        onChange={(e) => handleInput(e.target.value)}/>
                    </div>
                </div>
            </>
            )
        }
        </div>
            
        <div className={styles.wrapperButton}>
            <Button onClick={() => handlePost()}>Сохранить</Button>
        </div>
        </>
  );
};

export default FilesFormat;
