import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../ModalWindow";
import classNames from "classnames";
import styles from "../../Catalogs/ModalWithForm/CatalogsModalWithForm.module.css";
import Button from "../../Button";
import ModalInputWrapper from "../../Modal/InputWrapper";

export const DisciplineModal = ({selectedItem, modalData, setModalData, closeModal, onSubmit}) => {

    return (
        <ModalWindow
            title={modalData.modalType === "ADD" ? 'Добавлить' : 'Редактировать'}
            isOpen={true}
            onRequestClose={closeModal}
        >
            <ModalInputWrapper
                label="Дисциплина"
                type="text"
                name="discipline"
                onChange={(e) => setModalData({...modalData, data: {cid: selectedItem.cid, disciplinename: e.target.value, status: 1}})}
                value={modalData.data["title"]}
            />
            <div className={classNames('d-flex justify-content-end mt-2 pt-2', styles.controls)}>
                <Button label="Отмена" onClick={closeModal} colorType="red"/>
                <Button label="Сохранить" onClick={onSubmit} colorType="green" style={'ml-2'}/>
            </div>
        </ModalWindow>
    );
};

DisciplineModal.propTypes = {

};
