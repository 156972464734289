import { ERROR, LITERS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const litersListRequestStart = (data) => ({
  type: `${LITERS_LIST_REQUEST}${START}`,
  payload: data
});

export const litersListRequestSuccess = (data) => ({
  type: `${LITERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const litersListRequestError = (data) => ({
  type: `${LITERS_LIST_REQUEST}${ERROR}`,
  payload: data
});