import { MESSAGE_DELETE_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const deleteMessageRequestStart = (id) => ({
  type: `${MESSAGE_DELETE_REQUEST}${START}`,
  payload: id
});

export const deleteMessageRequestSuccess = () => {
  createNotification(
    'success',
    `Успешно удалено`
  );

  return {
    type: `${MESSAGE_DELETE_REQUEST}${SUCCESS}`
  }
};

export const deleteMessageRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка удаления. Статус: ${error.response.status}`
  );

  return {
    type: `${MESSAGE_DELETE_REQUEST}${ERROR}`,
    payload: error
  };
};
