import React from 'react'
import T from 'prop-types'
import ModalWindow from '../../ModalWindow'
import { ADD } from '../../../constants/modalTypes'
import ModalInputWrapper from '../../Modal/InputWrapper'
import Button from '../../Button'
import LoadForm from '../../BookEdit/Tabs/Files/loadForm'

const BorrowingTestModal = ({ modalData, setModalData, closeModal, isOpen, modalErrors }) => {
  const { type, data, methods } = modalData

  const onChange = e => {
    setModalData({ ...modalData, data: { ...data, [e.target.name]: e.target.value } })
  }

  const onUploadFile = file => {
    setModalData({ ...modalData, data: { ...data, file } })
  }

  return (
    <ModalWindow
      title={`${type === ADD ? 'Создать новый запрос' : 'Редактировать запрос'}`}
      onRequestClose={closeModal}
      isOpen={isOpen}
      width={'500px'}
    >
      <ModalInputWrapper type='textarea' label='Комментарий' onChange={onChange} name='comment' value={data['comment']} />
      {data.status === 100 && <div className='error_container mb-1'>{data.message}</div>}
      {data.reportlink && (
        <div>
          Отчет антиплагиата:{' '}
          <a title='Отчет антиплагиата' href={data.reportlink} target={'_blank'}>
            {data.reportlink}
          </a>
        </div>
      )}
      {type === ADD ? <LoadForm modalData={modalData} onUpload={onUploadFile} /> : <span>Файл: {data.requestfilename}</span>}
      <div>{modalErrors.file.active && <div className='color_red'>{modalErrors.file.text}</div>}</div>
      <div className={'d-flex justify-content-end mt-3'}>
        <Button label={`${type === ADD ? 'Создать' : 'Сохранить'}`} onClick={() => methods.onSubmit(data)} />
      </div>
    </ModalWindow>
  )
}

BorrowingTestModal.propTypes = {
  modalData: T.object.isRequired,
  setModalData: T.func.isRequired,
  closeModal: T.func.isRequired,
  isOpen: T.bool,
  modalErrors: T.object,
}

BorrowingTestModal.defaultProps = {
  isOpen: true,
}

export default BorrowingTestModal
