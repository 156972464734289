import React from 'react'
import T from 'prop-types'
import { range } from '../../utils/numbers'
import classNames from 'classnames'
import styles from './Pagination.module.css'

const Pagination = ({ pageCount, current, setCurrent, count }) => {
  if (!pageCount) return null

  if (pageCount < current) {
    setCurrent(pageCount)
    return null
  }

  const pageNumbers = []
  if (current <= 5) {
    pageNumbers.push(...range(current, 1))
  } else {
    pageNumbers.push(...range(6, current - 5))
  }
  if (pageCount - current <= 5) {
    pageNumbers.push(...range(pageCount - current, current + 1))
  } else {
    pageNumbers.push(...range(5, current + 1))
  }

  return (
    <div className={`${styles.container} my-3`}>
      <div>Всего найдено {count} записей</div>
      <div className={styles.section}>
        Страница {current} из {pageCount}
      </div>
      <div className={classNames(styles.section, styles.button)} onClick={() => setCurrent(1)}>
        В начало
      </div>
      <div className={styles.section}>
        {pageNumbers.map((item, index) => (
          <div
            className={classNames(styles.page, styles.button, { [styles.current]: item === current })}
            key={index}
            onClick={() => setCurrent(item)}
          >
            {item}
          </div>
        ))}
        {pageNumbers[pageNumbers.length - 1] !== pageCount && <div className={styles.page}>...</div>}
      </div>
      <div className={classNames(styles.section, styles.button)} onClick={() => setCurrent(pageCount)}>
        В конец
      </div>
    </div>
  )
}

Pagination.propTypes = {
  pageCount: T.number.isRequired,
  current: T.number.isRequired,
  setCurrent: T.func.isRequired,
}

export default Pagination
