import React from 'react'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import styles from '../../Authors/Table/AuthorsTable.module.css'
import { ADD, EDIT } from '../../../constants/modalTypes'


const DisciplineTable = ({ data, setModal, initialFormFields, selectedItem, setSelectedItem, onDelete }) => {
  const openEditModal = () => {
    setModal({ modalType: EDIT, data: data.find(item => (item.cid == selectedItem.cid ? item.cid : [])) })
  }

  const openCreationModal = () =>
    setModal({
      modalType: ADD,
      data: initialFormFields,
    })

  return (
    <div className={styles.container}>
      <div className={styles.button} onClick={openCreationModal}>
        добавить
      </div>
      {selectedItem && (
        <>
          <div className={styles.button} onClick={openEditModal}>
            редактировать
          </div>
          <div className={styles.button} onClick={onDelete}>
            удалить
          </div>
        </>
      )}
      <DataTable data={data}>
        <DataTableCol title={'Название дисциплины'} colSpan={4} value='title' />
        <DataTableCol
          title='Выбрано'
          passClicks
          resolver={item => (
            <input
              type='checkbox'
              checked={item.cid === selectedItem.cid}
              onChange={() => {
                setSelectedItem(item)
              }}
            />
          )}
        />
      </DataTable>
    </div>
  )
}

export default DisciplineTable
