import { combineReducers } from "redux";
import { securityLogCountReducer } from "./count/securityLogCountReducer";
import { securityLogListReducer } from "./list/securityLogListReducer";
import { securityLogSearchReducer } from "./search/securityLogSearchReducer";
import { securityLogFormReducer } from "./formData/securityLogFormReducer";

export const securityLogReducer = combineReducers({
  count: securityLogCountReducer,
  list: securityLogListReducer,
  search: securityLogSearchReducer,
  form: securityLogFormReducer
});
