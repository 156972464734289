import { ERROR, TURNOUT_STATS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const turnoutStatsListRequestStart = () => ({
    type: `${TURNOUT_STATS_LIST_REQUEST}${START}`
});

export const turnoutStatsListRequestSuccess = (data) => ({
    type: `${TURNOUT_STATS_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const turnoutStatsListRequestError = (data) => ({
    type: `${TURNOUT_STATS_LIST_REQUEST}${ERROR}`,
    payload: data
});
