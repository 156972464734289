import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import classNames from 'classnames'
import styles from './BookSearchForm.module.css'
import ModalInputWrapper from '../../Modal/InputWrapper'
import { Col, Row } from 'react-bootstrap'
import { libraryInstitutionsRequestStart } from '../../../redux/actions/library/info/institutions'
import { connect } from 'react-redux'
import { nonNull } from '../../../utils/strings'
import { verifyStatusList } from '../../../constants/bookVerifyStatus'
import { collectionsListRequestStart } from '../../../redux/actions/collections/list'
import SearchableSelect from '../../Select'
import { categoriesToOptions, categoryToOption, disciplineToOptions } from '../../../utils/toSelectOption'
import { categoriesListRequestStart } from '../../../redux/actions/categories/list'
import { litersListRequestStart } from '../../../redux/actions/liters/list'
import CoversSearchFormControls from '../SearchFormControls'
import { INV_NUMBERS_FORM_STRUCTURE } from '../../../constants/invNumbers'
import { seriesListRequestStart } from '../../../redux/actions/series/list'
import { setDisciplineSearchData } from '../../../redux/actions/discipline/searchData'
import { api } from '../../../App'
import { bookSourceRequestStart } from '../../../redux/actions/booksource'
import { libraryTypesListRequestStart } from '../../../redux/actions/library/types/list'
import { TreeSelect } from 'antd'
import FindCodeComponent from './FindCodeComponent'
import { udkListCollapse, udkListRequestStart } from '../../../redux/actions/udk/list'
import { svniListCollapse, svniListRequestStart } from '../../../redux/actions/svni/list'
import { bbkListCollapse, bbkListRequestStart } from '../../../redux/actions/bbk/list'
import { SCIENCE_DEGREE_OPTIONS } from '../../../constants/scienceDegree'
import { getYearsList } from '../../../utils/helpers/helpers'
import moment from 'moment'

const emptyScienceWorkData = {
  work_status: '1',
  fio: '',
  job_title: '',
  work_place: '',
  science_grade: '',
  science_degree: '',
  science_status: '',
  guide: '',
  industry: '',
  science_fio: '',
  year: moment().year()
}

const mapStateToProps = ({
  library,
  collections,
  categories,
  liters,
  series,
  discipline,
  bookSource,
  options,
  historyLocation,
  udk,
  bbk,
  svni
}) => ({
  institutions: library.info.institutions,
  disciplineList: discipline.list,
  disciplineSearch: discipline.search,
  collections: collections.list,
  categoriesList: categories.list,
  libraryTypes: library.types.list,
  liters: liters.list,
  series: series.list,
  UDKList: udk,
  BBKList: bbk,
  SVNIList: svni,
  bookSource: bookSource,
  optionsList: options.data,
  historyLocation: historyLocation,
})

const mapDispatchToProps = {
  requestTypes: libraryTypesListRequestStart,
  requestInstitutions: libraryInstitutionsRequestStart,
  requestCollections: collectionsListRequestStart,
  requestCategories: categoriesListRequestStart,
  requestLiter: litersListRequestStart,
  requestSeries: seriesListRequestStart,
  setDisciplineSearch: setDisciplineSearchData,
  requestBookSource: bookSourceRequestStart,
  requestUDKList: udkListRequestStart,
  requestSVNIList: svniListRequestStart,
  requestBBKList: bbkListRequestStart,
  collapseUDKList: udkListCollapse,
  collapseBBKList: bbkListCollapse,
  collapseSVNIList: svniListCollapse
}

const BookSearchForm = ({
  data,
  setData,
  children,
  institutions,
  requestInstitutions,
  hideInstitutions,
  collections,
  requestCollections,
  noVerifyStatus,
  requestCategories,
  categoriesList,
  onSubmit,
  disableAlphabet,
  simplified,
  setDisciplineSearch,
  disciplineSearch,
  disableDefaultPanel = false,
  requestBookSource,
  bookSource,
  historyLocation,
  UDKList,
  BBKList,
  SVNIList,
  requestBBKList,
  requestSVNIList,
  requestUDKList,
  collapseBBKList,
  collapseUDKList,
  collapseSVNIList,
  optionsList,
  requestTypes,
  libraryTypes,
  invNumbers = false,
  fromLostDoc
}) => {
  
  const [guideList, setGuideList] = useState([])
  const [industryList, setIndustryList] = useState([])
  const [workStatusList, setWorkStatusList] = useState([])
  const [workPlaceList, setWorkPlaceList] = useState([])
  const [scienceGradeList, setScienceGradeList] = useState([])
  const [disciplineList, setDisciplineList] = useState(null)

  const scienceWorkMode = data.typeId == 13

  useEffect(() => {
    getListFunction('/grnti', setGuideList)
    getListFunction('/industries', setIndustryList)
    getListFunction('/book_work_status', setWorkStatusList)
    getListFunction('/book_work_place', setWorkPlaceList)
    getListFunction('/book_science_grade', setScienceGradeList)
    getListFunction('/discipline', setDisciplineList)
    requestTypes()
    requestInstitutions()
    requestBookSource()
    requestCategories()
    requestCollections()

    setInitialValues()    
  }, [])

  const setInitialValues = () => {
  }
  
  const getListFunction = async (link, setFunction) => {
    const response  = await api.get(link)
    setFunction(response.data)
  }

  const setSearchableSelectValue = (fieldName) => ({value}) => {
    setData({...data, [fieldName]: value})
  }
  
  const valueToOption = (fieldName, optionsList) => {
    if(!data[fieldName]) return { label: 'Не выбрано', value: null }
    const returnWithoutModify = ['science_status', 'science_degree'].includes(fieldName) 
    const listItem = optionsList.find(({id, idclient, value}) => (idclient || id || value) == data[fieldName])
    return returnWithoutModify ? listItem : categoryToOption(listItem)
  }
  
  const onChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const onChangeDoc = typeId => {
    const scienceType = typeId === 13
    
    const payload = {
      ...data,
      ...emptyScienceWorkData,
      year: scienceType ? moment().year() : '',
      work_status: scienceType ? '1' : '',
      typeId: typeId || ''
    }

    setData(payload)
  }

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const onDisciplineInputChange = name => {
    setDisciplineSearch({ ...disciplineSearch, query: name })
    if (!disciplineList) {
      getListFunction('/discipline', setDisciplineList)
    }
  }

  const normolizeKeyNames = libraryTypes.map(el => (
    {
      title: el.name, 
      value: Number(el.id), 
      parent: el.parent,
      amount: el.amount
    }
  ))

  const treeD = l => l
  .reduce ((a, c) => {
    c.children = l.filter (i => i.parent == c.value).length && l.filter (i => i.parent == c.value) 
    a.push (c)
    return a
  }, [])
  // Шифр
  .filter (i => i.parent == 0)

  if (noVerifyStatus && !simplified) {
    data.verifyStatus = 2
  }

  const clearForm = () => {
    setData(INV_NUMBERS_FORM_STRUCTURE)
  }

  return (
    <div className={classNames(styles.container)}>
      <Row>
        <Col md={12}>
          <ModalInputWrapper onChange={onChange} name='title' onKeyPress={onKeyPress} value={data.title} label='Заглавие' />
        </Col>
        {!simplified && (
          <>
            <Col md={6}>
              <ModalInputWrapper onChange={onChange} name='author' value={data.author} onKeyPress={onKeyPress} label='Автор' />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                onKeyPress={onKeyPress}
                name='collaborator'
                value={data.collaborator}
                label='Соавторы'
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                name='publisher'
                value={data.publisher}
                onKeyPress={onKeyPress}
                label='Издательство'
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper onChange={onChange} name='isbn' value={data.isbn} onKeyPress={onKeyPress} label='ISBN' />
            </Col>
            <Col md={6}>
              <ModalInputWrapper onChange={onChange} name='issn' value={data.issn} onKeyPress={onKeyPress} label='ISSN' />
            </Col>
            <Col md={6}>
              <FindCodeComponent
                type='udk'
                value={data.udk}
                onChange={onChange}
                directoryList={UDKList}
                requestDirectoryList={requestUDKList}
                collapseDirectoryList={collapseUDKList}
                />
            </Col>
            <Col md={6}>
              <FindCodeComponent
                type='bbk'
                value={data.bbk}
                onChange={onChange}
                directoryList={BBKList}
                requestDirectoryList={requestBBKList}
                collapseDirectoryList={collapseBBKList}
                />
            </Col>
            <Col md={6}>
              <FindCodeComponent
                type='svni'
                value={data.svni}
                onChange={onChange}
                directoryList={SVNIList}
                requestDirectoryList={requestSVNIList}
                collapseDirectoryList={collapseSVNIList}
                />
            </Col>
            <Col md={6}>
              <ModalInputWrapper onChange={onChange} name='year' value={data.year} onKeyPress={onKeyPress} label='Год' />
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Категория'>
                <SearchableSelect
                  placeholder='Поиск'
                  options={categoriesToOptions(categoriesList)}
                  value={data.category}
                  onChange={value => setData({ ...data, categoryid: (value || {}).value, category: value })}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Дисциплины'>
                <SearchableSelect
                  data={data}
                  setData={setData}
                  placeholder='Поиск'
                  onInputChange={onDisciplineInputChange}
                  options={disciplineToOptions(disciplineList)}
                  value={data.discipline}
                  onChange={value => setData({ ...data, cid: value.value, discipline: value })}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={12}>
              <ModalInputWrapper onChange={onChange} name='textsearch' value={data.textsearch} label='Полнотекстовый поиск' />
            </Col>
          </>
        )}
        {!noVerifyStatus && (
          <Col md={6}>
            <ModalInputWrapper label={'Статус'}>
              <select value={data.verifyStatus} name='verifyStatus' onChange={onChange}>
                <option value={''}>Не выбрано</option>
                {verifyStatusList.map((item, index) => (
                  <option value={item.value} key={`${item.name}_${index}`}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        )}
        {!hideInstitutions && (
          <Col md={6}>
            <ModalInputWrapper label={'Организация'}>
              <select name='idclient' onChange={onChange} value={data.idclient}>
                <option value=''>Не выбрано</option>
                {institutions.map((item, index) => {
                  return (
                    <option value={item.idclient} key={'ic' + index} selected={optionsList?.own_client_id === item.idclient}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </ModalInputWrapper>
          </Col>
        )}
        {!simplified && (
          <Col md={6}>
            <ModalInputWrapper label={'Коллекции'}>
              <select value={nonNull(data.collectionid)} name='collectionid' onChange={onChange}>
                <option value={''}>Не выбрано</option>
                {collections.map(item => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        )}
        {
          <Col md={6}>
            <ModalInputWrapper label={'Источник'}>
              <select value={nonNull(data.book_source)} name='book_source' onChange={onChange}>
                <option value={''} disabled>
                  Не выбрано
                </option>
                {bookSource.map(item => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        }
        {(historyLocation === '/inv_numbers' || historyLocation === '/books' || invNumbers) && (
          <Col md={6}>
            <ModalInputWrapper label={'Инвентарный номер'} type='text' name='number' onChange={onChange} value={data.number}/>
          </Col>
        )}

        <Col md={6}>
        <ModalInputWrapper label={'Тип документа'}>
          <TreeSelect
            // showSearch
            style={{
              width: '100%',
            }}
            value={data.typeId}
            dropdownStyle={{
              maxHeight: 400,
              overflow: 'auto',
            }}
            placeholder="Не выбрано"
            allowClear
            // treeDefaultExpandAll
            treeData={treeD(normolizeKeyNames)}
            onChange={onChangeDoc}
            />
          </ModalInputWrapper>
        </Col>

        {/* <Col md={6}>
          <ModalInputWrapper label={'Тип документа'}>
            <select name='typeId' onChange={onChange} value={data.typeId}>
              <option value=''>Не выбрано</option>
              {libraryTypes &&
                libraryTypes.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
        </Col> */}
        <Col md={6}>
          <ModalInputWrapper
            label='Ключевые слова'
            name='keywords'
            value={data.keywords}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </Col>
        {scienceWorkMode && (
          <>
            <Col md={6}>
              <ModalInputWrapper
                label='Название работы'
                name='job_title'
                value={data.job_title}
                onChange={onChange}
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                label='Автор работы'
                name='fio'
                value={data.fio}
                onChange={onChange}
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper label="Статус работы">
                <SearchableSelect
                  placeholder="Поиск"
                  options={categoriesToOptions(workStatusList)}
                  onChange={setSearchableSelectValue('work_status')}
                  value={valueToOption('work_status', workStatusList)}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Шифр и наименование специальности'>
                <SearchableSelect
                  placeholder="Поиск"
                  options={categoriesToOptions(guideList)}
                  onChange={setSearchableSelectValue('guide')}
                  value={valueToOption('guide', guideList)}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Искомая степень'>
                <SearchableSelect
                  placeholder="Поиск"
                  options={SCIENCE_DEGREE_OPTIONS}
                  onChange={setSearchableSelectValue('science_degree')}
                  value={valueToOption('science_degree', SCIENCE_DEGREE_OPTIONS)}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Искомая отрасль науки'>
                <SearchableSelect
                  placeholder="Поиск"
                  options={categoriesToOptions(industryList)}
                  onChange={setSearchableSelectValue('industry')}
                  value={valueToOption('industry', industryList)}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                label='Фамилия, имя, отчество научного руководителя или консультанта'
                name='science_fio'
                value={data.science_fio}
                onChange={onChange}
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Ученая степень научного руководителя или консультанта'>
                <SearchableSelect
                  placeholder="Поиск"
                  options={SCIENCE_DEGREE_OPTIONS}
                  onChange={setSearchableSelectValue('science_status')}
                  value={valueToOption('science_status', SCIENCE_DEGREE_OPTIONS)}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Ученое звание научного руководителя или консультанта'>
                <SearchableSelect
                  placeholder="Поиск"
                  options={categoriesToOptions(scienceGradeList)}
                  onChange={setSearchableSelectValue('science_grade')}
                  value={valueToOption('science_grade', scienceGradeList)}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Место написания работы'>
                <SearchableSelect
                  placeholder="Поиск"
                  options={categoriesToOptions(workPlaceList)}
                  onChange={setSearchableSelectValue('work_place')}
                  value={valueToOption('work_place', workPlaceList)}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Год написания работы'>
                <select name='year' value={data.year} onChange={onChange}>
                  <option value=''>Выбрать год</option>
                  {getYearsList().map(year => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </ModalInputWrapper>
            </Col>
          </>
        )}


        <Col md={12}>{children}</Col>
        <Col md={12}>
          {!disableDefaultPanel && (
            <CoversSearchFormControls disableAlphabet={disableAlphabet} onSubmit={onSubmit} onClear={clearForm} fromLostDoc={fromLostDoc}/>
          )}
        </Col>
      </Row>
    </div>
  )
}

BookSearchForm.propTypes = {
  data: T.object,
  setData: T.func,
  hideInstitutions: T.bool,
  onSubmit: T.func,
  disableAlphabet: T.bool,
  disableDefaultPanel: T.bool,
  simplified: T.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(BookSearchForm)
