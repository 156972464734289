import { SUCCESS, USERS_LIST_REQUEST } from "../../../actions/action-types";

const initialState = [];

export const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${USERS_LIST_REQUEST}${SUCCESS}`:
      return action.payload;
      
    default:
      return state;
  }
};
