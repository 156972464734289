import { call, put, takeLatest } from "redux-saga/effects";
import { AUTHOR_DELETE_IMAGE_REQUEST, START } from "../../../actions/action-types";
import { deleteAuthorImage } from "../../../../api/authors/authors";
import { authorDeleteImageRequestError, authorDeleteImageRequestSuccess } from "../../../actions/authors/deleteImage";
import { authorsCountRequestStart } from "../../../actions/authors/count";
import { createNotification } from "../../../../components/notification/notification";

export default function* authorDeleteImageWatcher() {
  yield takeLatest(`${AUTHOR_DELETE_IMAGE_REQUEST}${START}`, authorDeleteImageWorker)
}

function* authorDeleteImageWorker({payload: id}) {
  try {
    const response = yield call(deleteAuthorImage, id);
    yield put(authorDeleteImageRequestSuccess(response.data));
    yield put(authorsCountRequestStart());
    createNotification("success", "Изображение удалено");
  } catch (error) {
    yield put(authorDeleteImageRequestError(error));
    createNotification("error", "Ошибка");
  }
}