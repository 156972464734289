import {SET_BORROWING_TEST_FORM_DATA} from "../../../actions/action-types";

const initialState = {
  query: '',
  status: ''
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SET_BORROWING_TEST_FORM_DATA}`:
      return action.payload;

    default: return state;
  }
};
