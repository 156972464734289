import { ALPHABET_LETTERS_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const alphabetLettersRequestStart = () => ({
  type: `${ALPHABET_LETTERS_REQUEST}${START}`
});

export const alphabetLettersRequestSuccess = (data) => ({
  type: `${ALPHABET_LETTERS_REQUEST}${SUCCESS}`,
  payload: data
});

export const alphabetLettersRequestError = (data) => ({
  type: `${ALPHABET_LETTERS_REQUEST}${ERROR}`,
  payload: data
});
