import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Pagination from '../../components/Pagination'
import CheckDataLogTable from '../../components/CheckDataLog/Table'
import CheckDataLogForm from '../../components/CheckDataLog/Form'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { setTransferLogSearchData } from '../../redux/actions/adminLogs/transfer/searchData'
import { setTransferLogFormData } from '../../redux/actions/adminLogs/transfer/formData'
import { transferLogCountRequestStart } from '../../redux/actions/adminLogs/transfer/count'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import CheckDataLogModal from '../../components/CheckDataLog/Modal'
import { transferLogEventsRequestStart } from '../../redux/actions/adminLogs/transfer/events'
import { transferLogBooksRequestStart } from '../../redux/actions/adminLogs/transfer/books'

const mapStateToProps = ({ adminLogs }) => ({
  count: adminLogs.transfer.count,
  formData: adminLogs.transfer.form,
  list: adminLogs.transfer.list,
  loader: adminLogs.transfer.loader,
  isResponsed: adminLogs.transfer.responsed,
  searchData: adminLogs.transfer.search,
  booksModaData: adminLogs.transfer.modal.books,
  eventsModalData: adminLogs.transfer.modal.events,
})

const mapDispatchToProps = {
  requestList: transferLogCountRequestStart,
  setFormData: setTransferLogFormData,
  setSearchData: setTransferLogSearchData,
  requestEventsModalData: transferLogEventsRequestStart,
  requestBooksModalData: transferLogBooksRequestStart,
}

export const CHECK_DATA_LOG_MODAL_TYPES = {
  events: 'events',
  books: 'books',
}

const CheckDataLog = ({
  count,
  formData,
  list,
  loader,
  isResponsed,
  searchData,
  requestList,
  setFormData,
  setSearchData,
  requestEventsModalData,
  requestBooksModalData,
  booksModaData,
  eventsModalData,
}) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  const [modalType, setModalType] = useState(null)

  const setSort = sortBy => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC

    setSearchData({ ...searchData, sortDirection: sortDir, sortBy, page: 1 })
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  const openModal = (type, id) => {
    if (type === CHECK_DATA_LOG_MODAL_TYPES.books) {
      requestBooksModalData(id)
    }

    if (type === CHECK_DATA_LOG_MODAL_TYPES.events) {
      requestEventsModalData(id)
    }

    setModalType(type)
  }

  const closeModal = () => {
    setModalType(null)
  }

  return (
    <SceneContainer title='Проверка целостности данных при миграции'>
      <CheckDataLogForm data={formData} setData={setFormData} search={requestList} />

      {modalType && (
        <CheckDataLogModal booksData={booksModaData} eventsData={eventsModalData} modalType={modalType} closeModal={closeModal} />
      )}

      <PaginationComponent />
      {loader ? (
        <SpinnerOuter />
      ) : (
        <CheckDataLogTable
          data={list}
          sortBy={searchData.sortBy}
          setSort={setSort}
          openModal={openModal}
          isResponsed={isResponsed}
        />
      )}
      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckDataLog)
