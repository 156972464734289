import { SET_TESTS_SEARCH } from '../../../actions/action-types'
import { RESULTS_PER_PAGE } from '../../../../constants/pagination'
import moment from 'moment'

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  from: moment().add(-10, 'days').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  // student: null,
  gid: null,
  year: null,
}

export const testsSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTS_SEARCH:
      return action.payload

    default:
      return state
  }
}
