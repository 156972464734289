import {
    PDF_VIEWER_HIGHLIGHT_ADD,
    PDF_VIEWER_HIGHLIGHT_DELETE,
    PDF_VIEWER_HIGHLIGHT_SET
} from "../action-types";

export const pdfViewerSetHighlightsData = (highlights) => ({
    type: `${PDF_VIEWER_HIGHLIGHT_SET}`,
    payload: highlights
});

export const pdfViewerAddHighlight = (highlight) => ({
    type: `${PDF_VIEWER_HIGHLIGHT_ADD}`,
    payload: highlight
});

export const pdfViewerDeleteHighlight = (highlightId) => ({
    type: `${PDF_VIEWER_HIGHLIGHT_DELETE}`,
    payload: highlightId
});
