import { BORROWING_TEST_LIST_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const borrowingTestListRequestStart = () => ({
  type: `${BORROWING_TEST_LIST_REQUEST}${START}`,
});

export const borrowingTestListRequestSuccess = (response) => ({
  type: `${BORROWING_TEST_LIST_REQUEST}${SUCCESS}`,
  payload: response
});

export const borrowingTestListRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка загрузки. Статус: ${error.response.status}`
  );

  return {
    type: `${BORROWING_TEST_LIST_REQUEST}${ERROR}`,
    payload: error
  };
};
