import { ERROR, HEADER_IMAGES_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const layoutImagesListRequestStart = () => ({
  type: `${HEADER_IMAGES_LIST_REQUEST}${START}`
});

export const layoutImagesListRequestSuccess = (data) => ({
  type: `${HEADER_IMAGES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const layoutImagesListRequestError = (data) => ({
  type: `${HEADER_IMAGES_LIST_REQUEST}${ERROR}`,
  payload: data
});
