import {
    SUCCESS,
    START,
    SET_LOCATION_CHANGE,
    INV_NUMBERS_LIST_REQUEST
} from "../../../actions/action-types";

const initialState = false;

export const invNumbersLoadSuccessReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${INV_NUMBERS_LIST_REQUEST}${START}`:
            return false;

        case `${INV_NUMBERS_LIST_REQUEST}${SUCCESS}`:
            return true;

        case SET_LOCATION_CHANGE:
            return initialState;

        default:
            return state;
    }
};
