import { COVERS_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const coversListRequestStart = (data) => ({
  type: `${COVERS_LIST_REQUEST}${START}`,
  payload: data
});

export const coversListRequestSuccess = (data) => ({
  type: `${COVERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const coversListRequestError = (data) => ({
  type: `${COVERS_LIST_REQUEST}${ERROR}`,
  payload: data
});

