import { LIBRARIES_LIST_REQUEST, SUCCESS, START } from '../../../actions/action-types'

const initialState = []

export const documentFundAllocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LIBRARIES_LIST_REQUEST}${START}`:
      return []
    case `${LIBRARIES_LIST_REQUEST}${SUCCESS}`:
      return action.payload

    default:
      return state
  }
}
