import { COLLECTION_SAVE_DATA_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const collectionSaveDataRequestStart = (collectionId, data, collectionType) => ({
  type: `${COLLECTION_SAVE_DATA_REQUEST}${START}`,
  payload: { collectionId, data, collectionType }
});

export const collectionSaveDataRequestSuccess = (data) => ({
  type: `${COLLECTION_SAVE_DATA_REQUEST}${SUCCESS}`,
  payload: data
});

export const collectionSaveDataRequestError = (data) => ({
  type: `${COLLECTION_SAVE_DATA_REQUEST}${ERROR}`,
  payload: data
});