import { ALPHABET_LETTERS_REQUEST, SUCCESS } from "../../../../actions/action-types";

const initialState = null;

export const alphabetLettersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${ALPHABET_LETTERS_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};