export const INV_NUMBERS_FORM_STRUCTURE = {
  collaborator: '',
  issn: '',
  keywords: '',
  categoryid: '',
  typeId: '',
  idclient: '',
  book_source: '',
  OutSecrecyId: '',
  title: '',
  author: '',
  category: '',
  number: '',
  isbn: '',
  year: '',
  udk: '',
  bbk: '',
  discipline: '',
  publisher: '',
  textsearch: '',
  verifyStatus: '',
  // Шифр
  fund: 0,
  // Шифр
  library: 0,
}
