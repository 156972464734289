import { MEDIA_RESOURCE_ADD_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const mediaResourceAddRequestStart = ({ resourcename, resourceurl }) => ({
  type: `${MEDIA_RESOURCE_ADD_REQUEST}${START}`,
  payload: { resourcename, resourceurl }
});

export const mediaResourceAddRequestSuccess = (response) => {
  createNotification('success', 'Ресурс успешно добавлен');

  return {
    type: `${MEDIA_RESOURCE_ADD_REQUEST}${SUCCESS}`,
    payload: response
  }
};

export const mediaResourceAddRequestError = (error) => {
  createNotification('error', 'Ошибка добавления ресурса');

  return {
    type: `${MEDIA_RESOURCE_ADD_REQUEST}${ERROR}`,
    payload: error
  }
};
