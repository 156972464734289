import { combineReducers } from "redux";
import { havkinaListReducer } from "./list/havkinaListReducer";
import { havkinaCountReducer } from "./count/havkinaCountReducer";
import { havkinaSearchReducer } from "./search/havkinaSearchReducer";
import { havkinaIndexReducer } from "./index/havkinaIndexReducer";

export const havkinaReducer = combineReducers({
  list: havkinaListReducer,
  count: havkinaCountReducer,
  search: havkinaSearchReducer,
  index: havkinaIndexReducer
});
