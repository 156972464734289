import { REPORT_GET_REQUEST, 
  REPORT_GET_ALL_REQUEST, 
  // REPORT_GET_COUNT_REQUEST,
  SUCCESS, START, ERROR } from "../../../actions/action-types";

const initialState = false;

export const reportsLoaderReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${REPORT_GET_REQUEST}${START}`:
      return true;

    case `${REPORT_GET_REQUEST}${SUCCESS}`:
      return false;

    case `${REPORT_GET_REQUEST}${ERROR}`:
      return false;

    default: return state;
  }
};

export const reportsAllLoaderReducer = (state = initialState, action) => {
  switch (action.type) {

      case `${REPORT_GET_ALL_REQUEST}${START}`:
        return true;
  
      case `${REPORT_GET_ALL_REQUEST}${SUCCESS}`:
        return false;
  
      case `${REPORT_GET_ALL_REQUEST}${ERROR}`:
        return false;

    default: return state;
  }
};
