import {
    ERROR,
    START,
    SUCCESS,
    TRANSFER_LOG_COUNT_REQUEST, TRANSFER_LOG_LIST_REQUEST
} from "../../../../actions/action-types";

const initialState = false;

export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${TRANSFER_LOG_COUNT_REQUEST}${START}`:
            return true;

        case `${TRANSFER_LOG_LIST_REQUEST}${SUCCESS}`:
            return false;

        case `${TRANSFER_LOG_COUNT_REQUEST}${ERROR}`:
            return false;

        case `${TRANSFER_LOG_LIST_REQUEST}${ERROR}`:
            return false;

        default: return state;
    }
};