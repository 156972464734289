import React, {useState} from "react";
import {bookPlacesActions} from "../../../redux/factory/bookPlacesReduxData";
import {coversCountRequestStart} from "../../../redux/actions/covers/count";
import {setCoversFormData, resetCoversFormData} from "../../../redux/actions/covers/form";
import {connect} from "react-redux";
import Pagination from "../../../components/Pagination";
import {setCoversSearchData} from "../../../redux/actions/covers/search";
import CoversList from "../../../components/Covers/List";
import CoverItemModal from "../../../components/Covers/List/Item/CoverItemModal/CoverItemModal";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import CoversSearchForm from "../../../components/Covers/Form";


const mapStateToProps = ({covers, bookPlaces}) => ({
    list: covers.list,
    count: covers.count,
    formData: covers.form,
    searchData: covers.search,
    loader: covers.loader,
    places: bookPlaces
});

const mapDispatchToProps = {
    bookPlacesRequest: bookPlacesActions.bookPlacesRequestStart,
    requestList: coversCountRequestStart,
    setFormData: setCoversFormData,
    resetFormData: resetCoversFormData,
    setSearchData: setCoversSearchData,
     
};

const CoversScene = ({
                         places,
                         bookPlacesRequest,
                         count,
                         list,
                         requestList,
                         formData,
                         setFormData,
                         resetFormData,
                         searchData,
                         setSearchData,
                         loader
                     }) => {

    const [modalShow, setModalShow] = useState(false);
    const [modalId, setModalId] = useState(null);

    const setCurrentPage = page => {
        setSearchData({...searchData, page});
        requestList();
    };

    const PaginationComponent = () => (
        <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={setCurrentPage}
        />
    );

    const searchWithPageReset = () => {
        setSearchData({...searchData, page: 1})
        requestList()
      }

    return (
        <SceneContainer title={'Справочники / Обложки'}>
            <CoverItemModal
                show={modalShow}
                close={() => setModalShow(false)}
                places={places}
                request={bookPlacesRequest}
                modalId={modalId}
            />
            <CoversSearchForm data={formData} setData={setFormData} onSubmit={searchWithPageReset} onClear={resetFormData}/>

            <PaginationComponent/>
            {loader ?
                <SpinnerOuter/>
                :
                <CoversList
                    data={list}
                    setModalShow={setModalShow}
                    setModalId={setModalId}
                />
            }
            <PaginationComponent/>
        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CoversScene);
