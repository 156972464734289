import {
    ALPHABET_COUNT_REQUEST,
    ALPHABET_SINGLE_ITEM_REQUEST,
    ALPHABET_SINGLE_ITEM_SET_POSITION,
    START,
    SUCCESS
} from "../../../../actions/action-types";


const initialState = {
    position: 1,
    data: null
};

export const alphabetCurrentItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ALPHABET_COUNT_REQUEST}${START}`:
            return {
                position: 1,
                data: null
            };

        case ALPHABET_SINGLE_ITEM_SET_POSITION:
            return {
                ...state,
                position: action.payload
            }

        case `${ALPHABET_SINGLE_ITEM_REQUEST}${START}`:
            return {
                ...state,
                data: null
            }

        case `${ALPHABET_SINGLE_ITEM_REQUEST}${SUCCESS}`:
            return {
                ...state,
                data: action.payload
            }

        default:
            return state;
    }
};
