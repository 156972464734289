import React, { useEffect, useState } from 'react'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import { connect, useDispatch, useSelector} from 'react-redux'
import { librariesListRequestStart } from '../../../redux/actions/documents/fundAllocation/libraries'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import Pagination from '../../../components/Pagination'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import NotebookLostDocModal from '../../../components/Reports/NotebookLostDoc/Modal'
import NotebookLostDocTable from '../../../components/Reports/NotebookLostDoc/Table'
import { lostDocStart } from '../../../redux/actions/notebookLostDoc/getLostDoc'
import NotebookLostDocSearchForm from '../../../components/Reports/NotebookLostDoc/SearchForm'
import { documentSaveRequestStart } from '../../../redux/actions/documents/documents/save'
import moment from 'moment'
import { Button } from 'antd'
import { ADD, EDIT } from '../../../constants/modalTypes'
import { documentsReduxActions } from '../../../redux/actions/documents/documents/actions'
import { addLostDocStart } from '../../../redux/actions/notebookLostDoc/addLostDoc'
import LostDocPdfPrint from '../../../components/Reports/NotebookLostDoc/PdfPrint'
import { createLostDocumentClean, createLostDocumentRequestStart } from '../../../redux/actions/notebookLostDoc/create'
import { setLostDocSearchData } from '../../../redux/actions/notebookLostDoc/search'
import { RESULTS_PER_PAGE } from '../../../constants/pagination'
import DocumentsModal from '../../../components/Documents/Modal'
import { updateLostDocumentRequestStart } from '../../../redux/actions/notebookLostDoc/update'

const mapStateToProps = ({ options, documents, reports, lostBooks }) => ({
  clientData: options.data,
  libraries: documents.libraries,
  data: reports.report,
  loader: reports.loader,
  notebookLostDoc: lostBooks,
  list: documents.list,
  optionsData: options.data,
  latestNumber: documents.number,
  options: options.data,
  count: lostBooks.count,
  searchDataPag: reports.search,
  latestDocument: documents.current,
  searchData: lostBooks.search,
})

const mapDispatchToProps = {
  requestLibraries: librariesListRequestStart,
  requestReport: lostDocStart,
  requestAddReport: addLostDocStart,
  setSearchData: setLostDocSearchData,
  saveDocument: documentSaveRequestStart,
  deleteDocument: documentsReduxActions.deleteRequestStart,
  lostDocumentClean: createLostDocumentClean,
}

const NotebookLostDocScene = ({
  ownId,
  data,
  requestLibraries,
  clientData,
  libraries,
  requestReport,
  requestAddReport,
  loader,
  notebookLostDoc,
  latestDocument,
  setSearchData,
  lostDocumentClean,
  latestNumber,
  count,
}) => {
  const initialModalData = {
    documentname: '',
    documentnumber: latestNumber,
    documenttype: '',
    documentdate: moment().format('YYYY-MM-DD'),
    document_arrival_date: moment().format('YYYY-MM-DD'),
    supplier: null,
    suppliername: '',
    view: '',
  }

  const searchForm = useFormik({
    initialValues: {
      del_doc_id:'',
      income_doc_id: '',
      price: '',
      description: '',
      user_id: '',
      del_info: [],
      income_info: [],
    },
    // validationSchema: validationSchema,
    onSubmit:  (values,{resetForm}) => {
      // Если выбрано и цена и АКТ поставки УДАЛЯЮ ЦЕНУ
      values.price = values.income_doc_id ? values.price ="" : values.price
      requestAddReport(values)
      setSearchData(values)
      setOpenSearchForm(false)
      setBack('')
      resetForm()
      lostDocumentClean()
    }
})

  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [openSearchForm, setOpenSearchForm] =  useState(false)
  const [page, setPage] = useState(1)
  // TODO изменить логику откр -закр 
  const [back, setBack] =useState('')

  useEffect(()=>{
    setPage(1)
   },[
    searchForm.values
   ])
  useEffect(() => {
    clientData && requestLibraries(clientData.own_client_id)
  }, [requestLibraries, clientData])
  
  useEffect(() => {
    getData(page, searchForm.values)
  }, [page])

  useEffect(()=>{
    // Добавление в форму АКТОВ списания и поступления
    searchForm.values.del_doc_id = notebookLostDoc?.actsData?.write_off_act
    searchForm.values.income_doc_id = notebookLostDoc?.actsData?.receipt_act
  },[notebookLostDoc.actsData.receipt_act, notebookLostDoc.actsData.write_off_act])
  
  const setCurrentPage = (page) => {
    setPage(page)
  }

  const PaginationComponent = () => (
    <Pagination
      count={count}
      pageCount={Math.ceil(+count/RESULTS_PER_PAGE)}
      current={page}
      setCurrent={setCurrentPage}
    />
  )
  /**getLostDocsList = фильтр по таблице тетрадей */
  const getLostDocsList = (params) => {
    //TODO переделать))
    const ror = page > 1 ? 1 : 1
    // getData(page, params)
    getData(ror, params)
  }

  const getData = (page, values) => {
    const limit = RESULTS_PER_PAGE
    const offset = (page-1) * RESULTS_PER_PAGE
    const params = {...values, limit, offset}
    requestReport(params)
  }

   const closeModal = () => {
     setOpenSearchForm(false)
     setBack('')
     searchForm.handleReset()
     lostDocumentClean()
  }
  const dispatch = useDispatch()

  const onSaveModal = data => {
    const action = modalData.modalType === ADD
    ? createLostDocumentRequestStart
    : updateLostDocumentRequestStart
    dispatch(action(data))
  }

  const {latest, current} = useSelector(state => state.documents)
  const {all: allTypes} = useSelector(state => state.documentTypes)
  return (
    <SceneContainer title={'Тетрадь учета книг и других документов, принятых от читателей взамен утерянных'}>
      <div className={'d-flex justify-content-end'}>
        <LostDocPdfPrint searchFormValue={searchForm.values} librariesList={libraries} data={notebookLostDoc.list} />
      </div>

    
      {modalData.modalType &&
        <DocumentsModal
          modalData={modalData}
          setModalData={setModalData}
          types={allTypes}
          onSave={onSaveModal}
          current={current}

          fromLostDoc
        /> 
      }
      <NotebookLostDocSearchForm
        onSubmit={getLostDocsList}
        librariesList={libraries}
        modalData ={modalData}
      />

      <Button style={{marginBottom:"30px"}} type='primary' onClick={()=> setOpenSearchForm(true)}>Добавить</Button>
           
      <NotebookLostDocModal 
      isOpen={openSearchForm} 
      closeModal={closeModal} 
      formData={searchForm} 
      initialModalData={initialModalData} 
      setBack={setBack} 
      back={back} />

      <PaginationComponent/>
        {loader
          ? <SpinnerOuter isLoading={loader} />
          : (data[ownId] || notebookLostDoc.list) && 
              <div>
              <NotebookLostDocTable data={notebookLostDoc.list} />
              </div>
        }
      <PaginationComponent />

    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NotebookLostDocScene)
