import { ROLE_MEMBERS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const roleMembersListRequestStart = (id) => ({
  type: `${ROLE_MEMBERS_LIST_REQUEST}${START}`,
  payload: id
});

export const roleMembersListRequestSuccess = (data) => ({
  type: `${ROLE_MEMBERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const roleMembersListRequestError = (data) => ({
  type: `${ROLE_MEMBERS_LIST_REQUEST}${Error}`,
  payload: data
});

