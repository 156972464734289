import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import styles from './ReadingListExportButton.module.css'
import { resolveExportLink, resolveJSONLink, resolveRusmarcLink } from '../../../utils/resolveExportLinks'
import { downloadFile } from '../../../utils/helpers/helpers'
import PopoverOuter from '../../UtulityComponents/Popover'

const ReadingListExportButton = ({ bookId }) => {
  const PopoverContent = (
    <div>
      <span onClick={() => downloadFile(resolveRusmarcLink([bookId], 'rusmarc'), bookId, 'rusmarc')} className={styles.link}>
        RUSMARC
      </span>
      <span onClick={() => downloadFile(resolveJSONLink([bookId], 'json'), bookId, 'json')} className={styles.link}>
        JSON
      </span>
      {/* <span onClick={() => downloadFile(resolveExportLink(bookId), bookId, 'xml')} className={styles.link}>
        OAI-PMH
      </span> */}
    </div>
  )

  return (
    <PopoverOuter placement='bottom' title={'Экспорт книги'} content={PopoverContent} trigger='click'>
      <Button>Экспорт</Button>
    </PopoverOuter>
  )
}

ReadingListExportButton.propTypes = {
  bookId: PropTypes.string,
}

export default ReadingListExportButton
