import {SUCCESS, USER_ROLE_PERMISSIONS_REQUEST} from "../../../actions/action-types";

const initialState = [];

export const userRolePermissionsReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${USER_ROLE_PERMISSIONS_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};