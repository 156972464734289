import {ERROR, START, SUCCESS, ANSWER_STAT_COUNT_REQUEST} from "../action-types";

export const answerStatCountRequestStart = () => ({
    type: `${ANSWER_STAT_COUNT_REQUEST}${START}`
});

export const answerStatCountRequestSuccess = (count) => ({
    type: `${ANSWER_STAT_COUNT_REQUEST}${SUCCESS}`,
    payload: count
});

export const answerStatCountRequestError = (error) => ({
    type: `${ANSWER_STAT_COUNT_REQUEST}${ERROR}`,
    payload: error
});


