import {api} from '../../App';

export const searchLibraryMaterials = (formData, {maxResults, paginateFrom}, id, categoryid) => {
    const params = {
        maxResults,
        paginateFrom,
    };
    const searchBody = {
        ...formData,
        typeId: id,
        categoryid: categoryid.value
    };
    return api.post('/search', searchBody, {params});
};

export const countLibraryMaterials = (formData, id, categoryid) => {
    const params = {
        count: true,
    };

    const searchBody = {
        ...formData,
        typeId: id,
        categoryid: categoryid.value
    };

    return api.post('/search', searchBody, {params});
};
