import {combineReducers} from "redux";
import {ElectronicBookJsonImportReducer} from "./ElectronicBookJsonImportReducer/ElectronicBookJsonImportReducer";
import {ElectronicBookZipImportReducer} from "./ElectronicBookZipImportReducer/ElectronicBookZipImportReducer";
import {ElectronicBookFormReducer} from "./FormData/formDataReducer";

export const electronicBookImportReducer = combineReducers({
    jsonImport: ElectronicBookJsonImportReducer,
    zipURL: ElectronicBookZipImportReducer,
    formData: ElectronicBookFormReducer,

});