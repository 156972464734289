import {OWN_USER_DATA_REQUEST, SUCCESS} from "../../actions/action-types";

const initialState = null;

export const ownUserDataReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${OWN_USER_DATA_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};