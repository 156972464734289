import React, { useState } from 'react'
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../Modal/InputWrapper'
import Button from '../../Button'

// const MONTHS = [
//   { name: 'сентябрь', value: 9 },
//   { name: 'октябрь', value: 10 },
//   { name: 'ноябрь', value: 11 },
//   { name: 'декабрь', value: 12 },
//   { name: 'январь', value: 1 },
//   { name: 'февраль', value: 2 },
//   { name: 'март', value: 3 },
//   { name: 'апрель', value: 4 },
//   { name: 'май', value: 5 },
//   { name: 'июнь', value: 6 },
//   { name: 'июль', value: 7 },
//   { name: 'август', value: 8 },
// ]

const defineYear = yearsList => {
  /*{
      begdate: "01-08-2012"
      enddate: "31-07-2013"
      name: "2012-2013"
      xp_key: 25
      year_grade_types: ""
    }*/
  const today = moment().format('YYYY-MM-DD')
  let currentPeriod = 0

  yearsList.forEach(period => {
    const begDate = period.begdate.split('-').reverse().join('-')
    const endDate = period.enddate.split('-').reverse().join('-')

    if (moment(today).isBetween(begDate, endDate)) currentPeriod = period.xp_key
  })

  return currentPeriod
}

const SearchForm = ({ list, dataToState, startRequest, authDataMid, loadList }) => {
  const { year } = list
  const [selectedYear, setSelectedYear] = useState(defineYear(year.schoolYear))


  const getPerformance = () => {
    dataToState(`${authDataMid}/${selectedYear}`)
    startRequest(selectedYear)
  }

  return (
    <div className={'formContainer'}>
      <Row>
        <Col>
          <ModalInputWrapper label={'Учебный год'}>
            <select
              onChange={e => {
                loadList({ year: e.target.value, term: null })
                setSelectedYear(e.target.value)
              }}
              value={selectedYear}
            >
              {year.schoolYear.map(item => (
                <option value={item.xp_key} key={`schoolYear${item.xp_key}`}>
                  {item.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col>
          <ModalInputWrapper label=''>
            <Button onClick={getPerformance} label='Обновить' />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  )
}

export default SearchForm
