import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import AdminDirectoryModal from '../../components/AdminDerectory/Modal'
import AdminDirectoryTable from '../../components/AdminDerectory/Table'
import Pagination from '../../components/Pagination'
import { adminGroupsRequestAllGroupsStart } from '../../redux/actions/adminGroups/groups'
import { directoryDeleteRequestStart } from '../../redux/actions/adminDirectory/delete'
import { setUsersSearchData } from '../../redux/actions/adminUsers/search'
import styles from './AdminDirectory.module.css'
import { directorySaveRequestStart } from '../../redux/actions/adminDirectory/save'
import { directoryListRequestStart } from '../../redux/actions/adminDirectory/list'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'

const mapStateToProps = ({ adminUsers: users, adminGroups, directoryList }) => ({
  list: directoryList.list,
  count: users.count,
  searchData: users.search,
  groups: adminGroups.groups,
  isLoading: directoryList.save.isLoading,
})

const mapDispatchToProps = dispatch => ({
  requestUsers: () => dispatch(directoryListRequestStart()),
  saveUser: data => dispatch(directorySaveRequestStart(data)),
  deleteUser: mid => dispatch(directoryDeleteRequestStart(mid)),
  setSearchData: data => dispatch(setUsersSearchData(data)),
  requestAdminGroups: () => dispatch(adminGroupsRequestAllGroupsStart()),
})

const AdminUsersScene = ({
  list,
  requestUsers,
  deleteUser,
  saveUser,
  searchData,
  setSearchData,
  count,
  groups,
  requestAdminGroups,
  isLoading,
}) => {
  useEffect(() => {
    requestUsers()
  }, [requestUsers])

  useEffect(() => {
    requestAdminGroups()
  }, [])

  useEffect(() => {
    setModalData(modalData => {
      const { data } = modalData
      if (data) {
        return { ...modalData, data: { ...data } }
      }
      return modalData
    })
  }, [list])

  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedId, setSelectedId] = useState(null)

  const onSubmitModal = data => {
    saveUser(data)
  }

  useEffect(() => {
    // !isLoading && setModalData({ modalType: null, data: null })
  }, [isLoading])

  const setSort = (sortBy, sortDirection) => {
    setSearchData({
      ...searchData,
      sortBy,
      page: 1,
      sortDirection: sortDirection,
    })
    requestUsers()
  }

  const handleSelect = id => setSelectedId(selectedId === id ? null : id)

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestUsers()
  }

  const handleDelete = () => {
    if (selectedId) {
      deleteUser(selectedId);
      setSelectedId(null);
    }
  }

  return (
    <div className={styles.container}>
      {modalData.modalType && (
        <AdminDirectoryModal
          isLoading={isLoading}
          onSubmit={onSubmitModal}
          setModalData={setModalData}
          modalData={modalData}/>
      )}
      <Row>
        <Col md={6}>
          <div className={styles.title}>Администрирование / Справочники</div>
        </Col>
        {/* <Col md={6}>
          <Search
            value={searchData.query}
            // onChange={query => setSearchData({ ...searchData, query })}
            onSubmit={requestUsers}
            placeholder={'поиск'}
          />
        </Col> */}
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
        </Col>
        
        {!isLoading
        ?
        <Col md={12}>
        <AdminDirectoryTable
          data={list}
          setModal={setModalData}
          // sortBy={searchData.sortBy}
          // setSort={setSort}
          handleSelect={handleSelect}
          selectedId={selectedId}
          onDelete={() => handleDelete()}
          requestUsers={requestUsers}
        />
      </Col>
      :
      <SpinnerOuter/>
      }
        
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersScene)
