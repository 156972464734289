import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../../../ModalWindow";
import ModalInputWrapper from "../../../../Modal/InputWrapper";
import Button from "../../../../Button";

const BookEditFilesModal = ({data, setData, onSubmit, closeModal}) => {

  return (
    <ModalWindow isOpen={true} title={'Редактирование файла'} onRequestClose={closeModal} width={'400px'}>
      <ModalInputWrapper
        value={data.version}
        label={"Версия"}
        onChange={e => setData({...data, version: e.target.value})}
      />
      <Button  label={'Сохранить'} onClick={onSubmit}/>
    </ModalWindow>
  );
};

BookEditFilesModal.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  setData: PropTypes.func.isRequired,
};

export default BookEditFilesModal;