import { api } from '../../App';

export const getCollectionList = () => {
  return api.get('/collection');
};

export const addCollection = (data) => {
  return api.post('/collection', data);
};

export const editCollection = (data) => {
  return api.put(`/collection/${data.id}`, data)
};

export const deleteCollection = (id) => {
  return api.delete(`/collection/${id}`);
};