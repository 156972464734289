import { call, put, takeLatest } from "redux-saga/effects";
import { AUTHORS_DELETE_REQUEST, START } from "../../../actions/action-types";
import { authorDelete } from "../../../../api/authors/authors";
import { authorsDeleteRequestError, authorsDeleteRequestSuccess } from "../../../actions/authors/delete";
import { authorsCountRequestStart } from "../../../actions/authors/count";
import { createNotification } from "../../../../components/notification/notification";

export default function* deleteAuthorWatcher() {
  yield takeLatest(`${AUTHORS_DELETE_REQUEST}${START}`, deleteAuthorWorker)
}

function* deleteAuthorWorker({payload: mid}) {
  try {
    const response = yield call(authorDelete, mid);
    yield put(authorsDeleteRequestSuccess(response.data));
    yield put(authorsCountRequestStart());
    createNotification("success", "Документ успешно удален");
  } catch (error) {
    yield put(authorsDeleteRequestError(error))
    createNotification("error", "Ошибка");
  }
}