import React, { useState } from 'react';
import T from 'prop-types';
import ModalWindow from "../../../ModalWindow";
import InputWrapper from "../../../Modal/InputWrapper";

import {WEEK_DAYS} from "../index";
import Button from "../../../Button";
import ScheduleMaker from "./ScheduleMaker";


const ACTIVATION_TYPES = {
    schedule: 'schedule',
    interval: 'interval'
};

const SchedulerEditModal = ({ onRequestClose, modalData, saveItem, id}) => {

    const [ formData, setFormData ] = useState(modalData);
    const [ activationType, setActivationType ] = useState(
        modalData.interval ? ACTIVATION_TYPES.interval : ACTIVATION_TYPES.schedule
    );


    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const saveModal = () => {
        saveItem({
            day: formData.day,
            schedule: activationType === ACTIVATION_TYPES.schedule ? formData.schedule : null,
            interval: activationType === ACTIVATION_TYPES.interval ? formData.interval : null,
            id: formData.id,
            categoryId: id
        });
    };

    return (
        <ModalWindow title={'Редактирование элемента'} isOpen={true} onRequestClose={onRequestClose} width={800}>
            <InputWrapper>
                <select name="day"
                        value={formData.day}
                        onChange={onChange}
                >
                    {WEEK_DAYS.map(day =>
                        <option key={ day.number } value={ day.number }>{ day.name }</option>
                    )}
                </select>
            </InputWrapper>

            <label className="mb-0 mr-2">
                <input type="radio" value={ACTIVATION_TYPES.interval}
                       className="mr-1"
                       checked={activationType === ACTIVATION_TYPES.interval}
                       onChange={() => setActivationType(ACTIVATION_TYPES.interval)}/>
                Установить интервал
            </label>
            <label className="mb-0">
                <input type="radio" value={ACTIVATION_TYPES.schedule}
                       className="mr-1"
                       checked={activationType === ACTIVATION_TYPES.schedule}
                       onChange={() => setActivationType(ACTIVATION_TYPES.schedule)}/>
                Установить расписание
            </label>

            {activationType === ACTIVATION_TYPES.interval &&
                <InputWrapper
                    label={'Интервал (мин)'}
                    name={'interval'}
                    value={formData.interval}
                    type={'number'}
                    onChange={onChange}
                />
            }

            {activationType === ACTIVATION_TYPES.schedule &&
                <ScheduleMaker scheduleData={formData.schedule}
                               setScheduleData={schedule => setFormData({
                                   ...formData,
                                   schedule
                               })}/>
            }


            <div className={'d-flex justify-content-end mt-2'}>
                <Button label={'Отмена'} colorType={'red'} onClick={onRequestClose} style={'mr-2'}/>
                <Button label={'Сохранить'} colorType={'green'} onClick={saveModal}/>
            </div>

        </ModalWindow>
    );
};

SchedulerEditModal.propTypes = {
    onRequestClose: T.func,
    modalData: T.object,
    saveItem: T.func,
    id: T.any,
};

export default SchedulerEditModal;