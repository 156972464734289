import jsPDF from "jspdf"
import addFont from "../../../../fonts/fonts"
import 'jspdf-autotable'
import moment from "moment"



const orientation = 'portrait'

export const getMagazineData = async ({options, actData}) => {
  addFont(jsPDF.API)

  let lastY = 20

  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height) {
      doc.addPage('a4', orientation)
      newY = 10
    }
    lastY = newY
    return newY
  }

  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
  doc.setFont('Montserrat')

  doc.setFontSize(16).setFont(undefined, 'bold')
  doc.text(`АКТ № ${actData?.documentnumber}`, doc.internal.pageSize.width / 2, lastY, 'center')
  doc.text('О ПРИЕМЕ ДОКУМЕНТОВ В БИБЛИОТЕКУ', doc.internal.pageSize.width / 2, getY(10), 'center')
  doc.setFontSize(10).setFont(undefined, 'normal')


  doc.text('Коды', 170, 45)
  doc.text('Форма № 23 по ОКУД    6002106', 124, 55)
  doc.text('по ОКПО', 146, 65)
  doc.rect(165, 40, 20, 10)
  doc.rect(165, 50, 20, 8)
  doc.rect(165, 58, 20, 20)
  doc.rect(165, 78, 20, 8)

  doc.text(`от ${moment(actData?.documentdate).format('DD.MM.YYYY')}`, 80, getY(20))
  doc.text(actData?.libraryname || '', 42, getY(9))
  doc.text('___________________________________________________', 30, getY(1))
  doc.setFontSize(8)
  doc.text('(условное, а при отсутствии условного -', 40, getY(3))
  doc.text('действительное наименование воинской части) ', 40, getY(3))
  doc.setFontSize(10)
  doc.text('Структурное', 30, getY(5))
  doc.text(actData?.libraryname || '', 70, getY(4))
  doc.text('подразделение            ____________________________', 30, getY(1))
  doc.text('Материально', 30, getY(5))
  doc.text('ответственное лицо   ____________________________', 30, getY(5))
  
  doc.text('Комиссия в составе                              __________________________________________________', 30, getY(15))
  doc.setFontSize(8)
  doc.text('(должности, воинские звания, фамилии, ', 105, getY(3))
  doc.text('имена, отчества)', 115, getY(3))
  doc.setFontSize(10)
  
  doc.text('_______________________________________________________________________________________,', 30, getY(10))
  doc.text('назначенная приказом от "__" _____________________________ 20__ г. N _____,', 30, getY(7))
  doc.text('произвела прием в библиотеку', 30, getY(10))
  doc.text('_______________________________', 125, lastY)
  doc.setFontSize(8)
  doc.text('(вид документа и от кого получен)', 130, getY(3))
  doc.setFontSize(10)
  doc.text('в количестве ____ экземпляров на общую сумму      __________________________________', 30, getY(10))
  doc.setFontSize(8)
  doc.text('(цифрами и прописью)', 145, getY(3))
  doc.setFontSize(10)
  doc.text('Приложение: список книг (других документов).', 30, getY(5))
  
  doc.text('Председатель комиссии', 30, getY(15))
  doc.text('_______________________________________', 105, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('Члены комиссии:', 30, getY(15))
  doc.text('_______________________________________', 105, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('_______________________________________', 105, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('_______________________________________', 105, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  
  doc.addPage('a4', orientation)
  
  doc.text('Приложение', 190, getY(0, 20), 'right')
  doc.text('к акту N ___', 190, getY(8), 'right')
  doc.text('от "__" _______ 20__ г.', 190, getY(8), 'right')
  
  doc.text('Список книг (других документов)', 105, getY(12), 'center')

  doc.autoTable({
    html: '#magazineTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
  })

  doc.text('Председатель комиссии', 30, getY(15, doc.autoTableEndPosY()))
  doc.text('_______________________________________', 105, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('Члены комиссии', 30, getY(15))
  doc.text('_______________________________________', 105, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('_______________________________________', 105, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('_______________________________________', 105, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('Перечисленные материальные ценности на ответственное хранение приняты.', 30, getY(10))

  doc.text('Материально ответственное лицо', 30, getY(10))
  doc.text('_______________________________________', 105, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 112, getY(3))
  doc.text('имя, отчество)', 112, getY(3))
  doc.setFontSize(10)
  
  doc.text('"__" ____________ 20__ г.', 30, getY(3))
  
  doc.setFontSize(14).setFont(undefined, 'bold')
  doc.text('Пояснения к форме', 105, getY(15), 'center')
  doc.setFontSize(10).setFont(undefined, 'normal')

  doc.text(doc.splitTextToSize(`
    1. Акт применяется в случаях, когда поступление документов в библиотеку воинской части осуществляется
    без сопроводительных документов. В акте указывается, откуда поступили документы, общее их количество и
    стоимость. К акту прилагается список, в котором книги (другие документы) перечисляются по названиям с
    указанием цены за каждый экземпляр.
  `, 160), 30, getY(5))

  doc.text('2. Акт утверждается командиром воинской части.', 30, getY(35))
  
  doc.addPage('a4', orientation)
  
  doc.setFontSize(12)
  doc.text('УТВЕРЖДАЮ', 105, getY(0, 20), 'center')
  doc.text('______________________________________________________________', 40, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись, имя, отчество)', 50, getY(3))
  doc.setFontSize(10)
  doc.text('"__" ___________ 20__ г.', 40, getY(7))
  doc.text('М.П.', 40, getY(7))
  

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}