import { combineReducers } from "redux";
import { libraryInfoReducer } from "./libraryInfo/libraryInfoReducer";
import { alphabetReducer } from "./alphabet/alphabetReducer";
import {libraryTypesReducer} from "./types/libraryTypesListReducer";

export const libraryReducer = combineReducers({
  alphabet: alphabetReducer,
  info: libraryInfoReducer,
  types: libraryTypesReducer
});
