import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './BookEditCodeTable.module.css'
import ModalInputWrapper from '../../../../Modal/InputWrapper'
import EditCodesModal from '../../../Modals/EditCodes'
import BookEditCodeItem from './CodeItem'
import Button from '../../../../Button'
import CodesSearchModal from '../CodesSearchModal'
import { Col, Row } from 'react-bootstrap'
import { createNotification } from '../../../../notification/notification'

const BookEditCodeTable = ({ data, setData, type = 'SVNI', directoryList, requestDirectoryList, collapseDirectoryList }) => {
  
  const CODE_NAME = (() => {
    switch (type) {
      case 'udk': return 'УДК';
      case 'bbk': return 'ББК';
      case 'svni': return 'СВНИ';
      case 'isbn': return 'ISBN';
      default: return 'Неизвестный тип кода';
    }
  })()

  useEffect(() => {
    if (!directoryList.length) {
      // Шифр
      requestDirectoryList(0, null, false)
    }
  }, [])

  const [codeEditor, setCodeEditor] = useState('')
  const [isModalOpen, setModalOpen] = useState(true)
  const [modalData, setModalData] = useState(null)
  const [isbnError, setIsbnError] = useState(null);
  const [searchModalOpen, setSearchModalOpen] = useState(false)

  const onEditModalOpen = data => {
    setModalData(data)
    setModalOpen(true)
  }

  const onChangeCode = () => {
    editCode(modalData.index, modalData.value)
  }

  const setCode = (value) => {
      if (!/^(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)[\d-]+$/.test(value) && type==="isbn") {
        setIsbnError("isbn-номер может состоять из 10 или 13 цифр и знаков '-'");
      } else {setIsbnError(null)}
    setCodeEditor(value)
  }

  const onAddCode = () => {
    if (isbnError) {return}
    if (codeEditor) {
      let newCodeList = data[type] ? [...data[type]] : []
      newCodeList.push(codeEditor)
      setData({ ...data, [type]: newCodeList })
      setCodeEditor('')
    }
  }

  const editCode = (index, value) => {
    let newCodesList = [...data[type]]
    newCodesList[index] = value
    if (!/^(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)[\d-]+$/.test(value) && type==="isbn") {
      createNotification(
        "error",
        "Неверный формат isbn"
      )
    } else setData({ ...data, [type]: newCodesList })
  }

  return (
    <>
      <CodesSearchModal
        type={type}
        isOpen={searchModalOpen}
        setOpen={setSearchModalOpen}
        data={directoryList}
        collapseList={collapseDirectoryList}
        fetchSubList={requestDirectoryList}
        setCodeEditor={setCodeEditor}
        label={CODE_NAME}
      />
      {modalData && (
        <EditCodesModal
          isOpen={isModalOpen}
          modalData={modalData}
          type={type}
          setModalData={setModalData}
          onRequestClose={() => setModalOpen(false)}
          onSubmit={onChangeCode}
        />
      )}
      <div className={styles.container}>
        <Row>
          <Col>
            <div className={styles.addItemPanel}>
              <ModalInputWrapper
                label={CODE_NAME}
                value={codeEditor}
                onChange={({ target: { value } }) => {
                  setCode(value)
                }}
                error={isbnError}
                additionalButtonOnClick={onAddCode}
                additionalButtonText='Добавить'
              />
            </div>
          </Col>
          { !(type==='isbn') &&
          <Col md={'auto'}>
            <ModalInputWrapper label=''>
              <Button label={'Поиск кода в справочнике'} colorType={'green'} onClick={() => setSearchModalOpen(true)} />
            </ModalInputWrapper>
          </Col>}
        </Row>

        {(data[type] || [])?.map((item, index) => (
          <BookEditCodeItem
            key={index}
            item={item}
            index={index}
            type={type}
            data={data}
            setData={setData}
            openModal={onEditModalOpen}
          />
        ))}
      </div>
    </>
  )
}

BookEditCodeTable.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  directoryList: PropTypes.array,
  requestDirectoryList: PropTypes.func,
  collapseDirectoryList: PropTypes.func,
}

BookEditCodeTable.defaultProps = {
  directoryList: [],
  data: {},
}

export default BookEditCodeTable
