import {PERMISSIONS_BY_LITER_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const accessLitersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${PERMISSIONS_BY_LITER_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
