import React from 'react';
import T from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from "../../../Covers/SearchForm/BookSearchForm.module.css";

const EditBookForm = ({ children, formData, setFormData, currentBook, formError }) => {

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  };

  const onError = () => {
    if(formError && Object.keys(formError).length > 0) {
      return (
        <div>
          {Object.keys(formError).map((item) => {
            if(formError[item].length > 0) {
              return (
                <p className={styles.errorItem}><span>{formError[item]}</span></p>
              )
            } else {
              return '';
            }
          })}
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col md={12}>
          <h3>{currentBook ? 'Редактирование книги' : 'Добавление новой книги'}</h3>
        </Col>
        <Col md={12}>
          {formError && onError()}
        </Col>
        <Col md={12}>
          <ModalInputWrapper onChange={onChange}
                             name="title"
                             value={formData.title}
                             label="Название книги"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="author"
                             value={formData.author}
                             label="Авторы"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="publisher"
                             value={formData.publisher}
                             label="Издательство"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="year"
                             value={formData.year}
                             type="number"
                             label="Год"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="isbn"
                             value={formData.isbn}
                             label="ISBN"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="amount"
                             value={formData.amount}
                             label="Количество бумажных копий"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="digital_amount"
                             value={formData.digital_amount}
                             label="Количество электронных копий"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={12}>
          {children}
        </Col>
      </Row>
    </div>
  );
};

EditBookForm.propTypes = {
  formData: T.object,
  setFormData: T.func
};

export default EditBookForm;
