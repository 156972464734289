import { ERROR, QR_CODES_SHELVES_REQUEST, START, SUCCESS } from "../action-types";

export const qrCodesShelvesRequestStart = (data) => ({
  type: `${QR_CODES_SHELVES_REQUEST}${START}`,
  payload: data
});

export const qrCodesShelvesRequestSuccess = (data) => ({
  type: `${QR_CODES_SHELVES_REQUEST}${SUCCESS}`,
  payload: data
});

export const qrCodesShelvesRequestError = (data) => ({
  type: `${QR_CODES_SHELVES_REQUEST}${ERROR}`,
  payload: data
});

