import { ERROR, START, SUCCESS, USERS_DELETE_REQUEST } from "../action-types";

export const usersDeleteRequestStart = (mid) => ({
  type: `${USERS_DELETE_REQUEST}${START}`,
  payload: mid
});

export const usersDeleteRequestSuccess = (data) => ({
  type: `${USERS_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const usersDeleteRequestError = (data) => ({
  type: `${USERS_DELETE_REQUEST}${ERROR}`,
  payload: data
});