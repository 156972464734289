import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const FileLoader = ({ onUpload, loader, label='Загрузить файл', ...props }) => {
  
  const inputEl = useRef(null)

  return (
    <>
      <Button icon={<UploadOutlined />} disabled={props.disabled} loading={loader} onClick={() => inputEl.current.click()}>
        {label}
      </Button>
      <input ref={inputEl} type='file' onChange={e => onUpload(e)} style={{ display: 'none' }} {...props}/>
    </>
  )
}

FileLoader.propTypes = {
  onUpload: PropTypes.func.isRequired,
}

export default FileLoader
