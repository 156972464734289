import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import MediaResourceReaderList from "../../components/MediaResource/Reader/List";
import {mediaResourceReaderRequestStart} from "../../redux/actions/mediaResource/getForReader";

const mapStateToProps = ({mediaResource}) => ({
    list: mediaResource.forReader.list,
    error: mediaResource.forReader.error,
});

const mapDispatchToProps = {
    getListForReader: mediaResourceReaderRequestStart
};

const MediaResource = ({getListForReader, list}) => {
    useEffect(() => {
        getListForReader();
    }, [getListForReader]);

    return (
        <SceneContainer title="Медиа портал">
            <MediaResourceReaderList list={list}/>
        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaResource);