import { ERROR, AUTHORS_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const authorsCountRequestStart = () => ({
  type: `${AUTHORS_COUNT_REQUEST}${START}`
});

export const authorsCountRequestSuccess = (count) => ({
  type: `${AUTHORS_COUNT_REQUEST}${SUCCESS}`,
  payload: count
});

export const authorsCountRequestError = (error) => ({
  type: `${AUTHORS_COUNT_REQUEST}${ERROR}`,
  payload: error
});


