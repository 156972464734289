import React, { useRef, useEffect, useState } from 'react'
import T from 'prop-types'
import { EDIT } from '../../../constants/modalTypes'
import styles from '../../Authors/Modal/AuthorModal.module.css'
import { Col, OverlayTrigger, Row, Tab, Tabs } from 'react-bootstrap'
import ModalImagePopover from '../../Modal/ImagePopover'
import ModalImage from '../../Modal/ImageWrapper'
import { usersGetPhotoRequestStart } from '../../../redux/actions/adminUsers/photo/getPhoto'
import { usersSavePhotoRequestStart } from '../../../redux/actions/adminUsers/photo/savePhoto'
import { connect } from 'react-redux'
import { usersDeletePhotoRequestStart } from '../../../redux/actions/adminUsers/photo/deletePhoto'
import { changeUserPassword } from '../../../api/adminUsers/users'
import ModalWindow from '../../ModalWindow'
import AdminDerectoryModalForm from './Form'
import { setUserPhoto } from '../../../redux/actions/adminUsers/photo/setPhoto'
import { usersPhotoReset } from '../../../redux/actions/adminUsers/photo/resetPhoto'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import { api } from '../../../App'

const mapStateToProps = ({ adminUsers }) => ({
  imgUrl: adminUsers.photo,
})

const mapDispatchToProps = {
  requestPhoto: usersGetPhotoRequestStart,
  savePhoto: usersSavePhotoRequestStart,
  setPhoto: setUserPhoto,
  deletePhoto: usersDeletePhotoRequestStart,
  changePassword: changeUserPassword,
}

const AdminDerectoryModal = ({
  setModalData,
  onSubmit,
  modalData,
  imgUrl,
  requestPhoto,
  deletePhoto,
  setPhoto,
  groups,
  isLoading,
}) => {
  const { modalType, data } = modalData
  const isVisible = !!modalType
  const imageInput = useRef(null)
  const [userRoles, setUserRoles] = useState([])

  useEffect(() => {
    data.mid && api.get(`/people/roles/${data.mid}`).then(data => setUserRoles(data.data))
  }, [data])

  useEffect(() => {
    if (data && data.photosize && !imgUrl) {
      requestPhoto(data.mid)
    }
  }, [data, imgUrl, requestPhoto])

  const onDeleteImage = () => {
    deletePhoto(data.mid)
  }

  const onSaveImage = () => {
    imageInput.current.click()
  }

  const onLoadImage = event => {
    setPhoto(event.target.files[0])
  }

  const onSubmitModal = values => {
    onSubmit({ ...modalData, data: values })
  }

  const resolveUserPhoto = photo => {
    if (typeof photo === 'string') {
      return photo
    } else if (typeof photo === 'object') {
      return URL.createObjectURL(photo)
    }
  }

  return (
    <ModalWindow
      isOpen={isVisible}
      title={`Справочник: ${modalType === EDIT ? 'Редактирование' : 'Добавление'}`}
      onRequestClose={() => {
        setModalData({ modalType: null, data: null })
      }}
      // width={'600px'}
    >
      {/* {isLoading && <SpinnerTime />} */}
      <Row>
        <Col md={12}>
          <Tabs defaultActiveKey='index' id='AdminDerectoryModalTabs'>
            <Tab eventKey='index' title='Главная'>
              <AdminDerectoryModalForm
                groups={groups}
                modalType={modalType}
                modalData={modalData}
                onSubmit={onSubmitModal}
                setModalData={setModalData}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </ModalWindow>
  )
}

AdminDerectoryModal.propTypes = {
  modalData: T.object,
  setModalData: T.func,
  onSubmit: T.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDerectoryModal)
