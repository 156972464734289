import { api } from "../../App";

export const regionsList = () => {
  return api.get('/xp_subject');
};

export const regionsCount = () => {
  const params = {
    count: true
  };

  return api.get(`/xp_subject`, { params });
};