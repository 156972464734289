import {
  ERROR,
  START,
  SUCCESS,
  ADMIN_MODAL_USERS_COUNT_REQUEST
} from "../action-types";

export const adminModalUsersCountRequestStart = () => ({
  type: `${ADMIN_MODAL_USERS_COUNT_REQUEST}${START}`
});

export const adminModalUsersCountRequestSuccess = data => ({
  type: `${ADMIN_MODAL_USERS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const adminModalUsersCountRequestError = data => ({
  type: `${ADMIN_MODAL_USERS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
