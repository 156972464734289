import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import styles from './ElectronicBookImport.module.css';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import {electronicBookJsonSaveStart} from "../../redux/actions/ElectronicBookImport/saveJson";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import BookData from "../../components/ElectronicBookImport/BookData";
import {api} from "../../App";
import {createNotification} from "../../components/notification/notification";
import {electronicBookResetData} from "../../redux/actions/ElectronicBookImport/reset";
import {electronicBookZipSaveStart} from "../../redux/actions/ElectronicBookImport/saveZip";
import Button from "../../components/Button";
import {setElectronicBookImportFormData} from "../../redux/actions/ElectronicBookImport/setFormData";
import {Col, Row} from "react-bootstrap";
import SearchBooksItem from '../../components/UtulityComponents/SearchBooksItem';
import ControlsPanel from '../../components/Reading/List/ControlsPanel';

const mapStateToProps = ({electronicBook, options}) => ({
    jsonImport: electronicBook.jsonImport,
    infoDuplicate: electronicBook.jsonImport?.response?.data?.duplicates,
    zipURL: electronicBook.zipURL,
    formData: electronicBook.formData,
    own_client_id: options.data?.own_client_id,
    error: electronicBook.error,
});

const mapDispatchToProps = {
    saveFile: electronicBookJsonSaveStart,
    saveZip: electronicBookZipSaveStart,
    resetData: electronicBookResetData,
    setFormData: setElectronicBookImportFormData
};

const ElectronicBookImport = ({error, infoDuplicate, jsonImport, saveFile, saveZip, resetData, zipURL, formData, setFormData, own_client_id}) => {

    const [showData, setShowData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bookDeleteLoading, setBookDeleteLoading] = useState(false);
    const [urlLoading, setUrlLoading] = useState(false);
    const onChange = (name, value) => setFormData({...formData, [name]: value});
    const handleFileRead = ({target}) => onChange(target.name, target.files[0]);

    useEffect(() => {
        if (jsonImport.title) {
            setLoading(false);
            setShowData(true);
        }
    }, [jsonImport]);

    useEffect(() => {
        formData.id = own_client_id;
      }, [own_client_id])
      
    const zipInputOnChangeHandler = (fileData) => {
        setUrlLoading(true);
        const file = new FormData();
        file.append("file", fileData);
        saveZip(
            {
                file,
                id: jsonImport.id
            },
            () => setUrlLoading(false)
        )
    };

    const deleteNoteHandler = (id) => {
        setBookDeleteLoading(true);
        api.delete(`/book/${id}`).then(() => {
            createNotification('success', 'Библиографическая запись удалена');
            setBookDeleteLoading(false);
            setShowData(false);
            resetData();
        });
    };
    const closeNoteHandler = () => {
            setShowData(false);
            resetData();
        };
    

    return (
        <SceneContainer title="Загрузка электронного учебника">
            <Row>
          
                <Col md={6}>
                    {loading ?
                        <SpinnerOuter/>
                        :
                        <>
                            {showData ?
                                <BookData
                                    data={jsonImport}
                                    deleteNoteHandler={deleteNoteHandler}
                                    bookDeleteLoading={bookDeleteLoading}
                                    inputHandler={zipInputOnChangeHandler}
                                    href={zipURL}
                                    urlLoading={urlLoading}
                                    setUrlLoading={setUrlLoading}
                                />
                                :
                                <div>
                                    <div className={styles.sectionTitle}>
                                        Формат загружаемого файла:
                                    </div>
                                    <div className={styles.sectionContainer}>
                                        <div className={styles.inputContainer}>
                                            <input type="radio" checked={formData.format === "json"}
                                                   id={"json-radio"}
                                                   className={styles.radioInput}
                                                   value={"json"}
                                                   name={"format"}
                                                   onChange={({target}) => onChange(target.name, target.value)}/>
                                            <label htmlFor={"json-radio"}>JSON</label>
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input type="radio" checked={formData.format === "rusmarc"}
                                                   id={"rusmarc-radio"}
                                                   className={styles.radioInput}
                                                   value={"rusmarc"}
                                                   name={"format"}
                                                   onChange={({target}) => onChange(target.name, target.value)}/>
                                            <label htmlFor={"rusmarc-radio"}>RUSMARC</label>
                                        </div>
                                    </div>
                                    <div className={styles.sectionTitle}>
                                        Файл:
                                    </div>
                                    <div className={styles.sectionContainer}>
                                        <input type="file" onChange={handleFileRead} name="file"/>
                                    </div>
                                        {!infoDuplicate && 
                                        <>
                                        <Button label="Загрузить" onClick={saveFile} style={styles.button} disabled={!formData.file}/>
                                        </>
                                         }
                                         {!!infoDuplicate &&
                                            <Button label="Назад" onClick={closeNoteHandler} style={styles.button} />
                                        }

                                </div>

                            }
                        </>
                    }
                </Col>
                <Col>

                </Col>

            </Row>
            {!!infoDuplicate && 
                <SearchBooksItem data={infoDuplicate} >
                <ControlsPanel
                data={infoDuplicate}
                />
                </SearchBooksItem>
            }
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicBookImport);
