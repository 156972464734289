import { combineReducers } from "redux";
import { institutionsListReducer } from "./list/institutionsListReducer";
import { institutionsCountReducer } from "./count/institutionsCountReducer";
import { institutionsSearchReducer } from "./search/institutionsSearchReducer";
import { institutionsAllReducer } from "./all/institutionsAllReducer";
import { institutionsAllOrigReducer } from "./allOrig/institutionsAllOrigReducer";

export const institutionsReducer = combineReducers({
  count: institutionsCountReducer,
  list: institutionsListReducer,
  search: institutionsSearchReducer,
  all: institutionsAllReducer,
  all_full: institutionsAllOrigReducer
});
