import {
    TESTS_COUNT_REQUEST,
    SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const testsCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${TESTS_COUNT_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};