import {
    ELECTRONIC_COURSES_COUNT_REQUEST,
    ELECTRONIC_COURSES_LIST_REQUEST,
    ERROR, START,
    SUCCESS
} from "../../../actions/action-types";

const initialState = false;

export const electronicCoursesLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ELECTRONIC_COURSES_COUNT_REQUEST}${START}`:
            return true;

        case `${ELECTRONIC_COURSES_LIST_REQUEST}${SUCCESS}`:
            return false;

        case `${ELECTRONIC_COURSES_LIST_REQUEST}${ERROR}`:
            return false;

        default: return state;
    }
};
