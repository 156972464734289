import { DOCUMENT_BOOK_ORDER_COUNT_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = 0;

export const documentCountReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${DOCUMENT_BOOK_ORDER_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
