import React from 'react';
import T from 'prop-types';

import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";

const BooksTable = ({ data }) => {

    return (
        <DataTable data={data}>
            <DataTableCol title="Название" value="title"/>
            <DataTableCol title={'Авторы'} resolver={item => (item.authors && item.authors.length > 0 &&
                item.authors.map(author => author.name))}
            />
            <DataTableCol title={'Издательства'} resolver={item => (item.publishers && item.publishers.length > 0 &&
                item.publishers.map(publisher => publisher.name))}
            />
        </DataTable>
    );
};

BooksTable.propTypes = {
    data: T.array,
};

export default BooksTable;