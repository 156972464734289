import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { publishersCountRequestStart } from '../../../redux/actions/publishers/count'
import { setPublishersSearchData } from '../../../redux/actions/publishers/searchData'
import PublishersModal from '../../../components/Publishers/Modal'
import { savePublishersDataRequestStart } from '../../../redux/actions/publishers/save'
import Search from '../../../components/Search'
import Pagination from '../../../components/Pagination'
import PublishersTable from '../../../components/Publishers/Table'
import { publishersDeleteRequestStart } from '../../../redux/actions/publishers/delete'
import { publishersResetError } from '../../../redux/actions/publishers/resetError'
import MessageModal from '../../../components/MessageModal'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import { logout } from '../../../api/user/auth'

const mapStateToProps = ({ publishers }) => ({
  searchData: publishers.search,
  list: publishers.list,
  count: publishers.count,
  error: publishers.error,
})

const mapDispatchToProps = {
  requestPublishers: publishersCountRequestStart,
  setSearchData: setPublishersSearchData,
  savePublishersData: savePublishersDataRequestStart,
  deletePublisher: publishersDeleteRequestStart,
  resetError: publishersResetError,
}

const MODAL_FORM_FIELDS = {
  name: '',
  fullName: '',
  city: '',
  isbn: '',
}

const PublishersScene = ({
  count,
  searchData,
  list,
  setSearchData,
  requestPublishers,
  savePublishersData,
  deletePublisher,
  error,
  resetError,
}) => {
  useEffect(() => {
    requestPublishers()
  }, [requestPublishers])

  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedId, setSelectedId] = useState(null)
  const [selectedPublishers, setSelectedPublishers] = useState([])

  const onSubmitModal = values => {
    savePublishersData({ ...modalData, data: { ...modalData.data, ...values } })
    closeModal()
  }

  const handleSelect = id => setSelectedId(selectedId === id ? null : id)

  const onDelete = () => selectedId && deletePublisher(selectedId)
  // const deletePublishersHandler = () =>
  //   selectedPublishers.length &&
  //   selectedPublishers.forEach(e => {
  //     deletePublisher(e)
  //   })

  const closeModal = () => {
    setModalData({ modalType: null, data: null })
  }

  const searchWithPageReset = () => {
    setSearchData({...searchData, page: 1})
    requestPublishers()
  }

  return (
    <SceneContainer title='Справочники / Издательства'>
      <Row>
        <Col md={12}>
          <Search
            value={searchData.query}
            onChange={query => setSearchData({ ...searchData, query })}
            onSubmit={searchWithPageReset}
            placeholder={'поиск'}
          />
        </Col>
        <Col md={12}>
          <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={page => {
              setSearchData({ ...searchData, page })
              requestPublishers()
            }}
          />
        </Col>
        <Col md={12}>
          {error && <MessageModal onClick={resetError} message={error} />}
          {modalData.data && (
            <PublishersModal modalData={modalData} setModalData={setModalData} onSubmit={onSubmitModal} closeModal={closeModal} />
          )}
          <PublishersTable
            // setSelectedPublishers={setSelectedPublishers}
            // selectedPublishers={selectedPublishers}
            data={list}
            setModal={setModalData}
            selectedId={selectedId}
            handleSelect={handleSelect}
            initialFormFields={MODAL_FORM_FIELDS}
            onDelete={onDelete}
            // onDelete={deletePublishersHandler}
          />
        </Col>
        <Col md={12}>
          <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={page => {
              setSearchData({ ...searchData, page })
              requestPublishers()
            }}
          />
        </Col>
      </Row>
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishersScene)
