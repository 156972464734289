import React from 'react';
import T from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from '../../BooksOrder/BooksTable/DocumentBooksTable.module.css';
import DoublePrint from "../../PDFPrint";

const BooksOrderBooksTable = ({ data, handleSelect, selectedId, modalTitle }) => {
    return (
        <div className={styles.container}>
            <div className={'d-flex justify-content-between align-items-center'}>
                <h4>Книги</h4>
                <DoublePrint
                    tableData={data}
                    headers={[
                        {
                            title: 'Название',
                            value: 'title',
                        },
                        {
                            title: 'Кол-во',
                            value: 'amount',
                        },
                        {
                            title: 'Эл. кол-во',
                            value: 'digital_amount',
                        },
                        {
                            title: 'Авторы',
                            value: 'author',
                        },
                        {
                            title: 'Издательство',
                            value: "publisher",
                        }
                    ]}
                    title={modalTitle}
                    orientation={"landscape"}
                />
            </div>
            <DataTable data={data}>
                <DataTableCol title="Название" value="title" colSpan={3}/>
                <DataTableCol title="Кол-во" value="amount" colSpan={2}/>
                <DataTableCol title="Эл. кол-во" value="digital_amount" colSpan={2}/>
                <DataTableCol title="Авторы" value="author" colSpan={2}/>
                <DataTableCol title="Издательство" value="publisher" colSpan={2}/>
                <DataTableCol title="Год издания" resolver={
                    (item) => item.year ? item.year : "-"
                } colSpan={2}/>
                <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                  <input type="checkbox" checked={item.id === selectedId}
                         onChange={() => handleSelect(item)}/>}
                />
            </DataTable>
        </div>
    );
};

BooksOrderBooksTable.propTypes = {
    data: T.array,
    modalTitle: T.string,
};

export default BooksOrderBooksTable;
