import React from 'react'
import styles from './SearchBooksItem.module.css'
import { resolveBookCoverUrl } from '../../../utils/resolvePhotoUrl'
import { Col, Row } from 'react-bootstrap'

const ItemCatalogCard = ({ data, label, setModalVisible }) => {
  return (
    <>
      <div className={styles.label}>{label}:</div>
      {data.subscriptionCover ? (
        <img
          className={styles.imgContainer}
          src={resolveBookCoverUrl(data.subscriptionCover, data.bookid)}
          alt='scan'
          onClick={() => setModalVisible(true)}
        />
      ) : (
        'Отсутствует'
      )}
    </>
  )
}

export default ItemCatalogCard
