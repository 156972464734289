import {SERIES_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const seriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SERIES_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
