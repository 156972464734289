import React, {useEffect, useState} from 'react';
import {nonNull} from "../../../../../utils/strings";
import styles from "../BookEditIndexTab.module.css";

export const SupplierField = ({data, setData, source, secrecy}) => {

    const [sourceList, setSourceList] = useState([]);

    const onChange = ({target: {value, name}}) => {
        setData({...data, [name]: value});
    };

    const ifSourceList = () => {
        const emptyArray = [];
        
        switch (secrecy) {
            // Шифр
            case 1:
                source.map(item => {
                    (item.id !== 5 && item.id !== 6 && item.id !== 6566457535587671) &&
                    emptyArray.push(item);
                })
                break;
            // Шифр
            case 2:
                source.map(item => {
                    (item.id !== 5 && item.id !== 6566457535587671) &&
                    emptyArray.push(item);
                })
                break;
            // Шифр                
            case 3:
                source.map(item => {
                    (item.id !== 6 && item.id !== 5) &&
                    emptyArray.push(item);
                })
                break;
            // Шифр                
            case 613439417063584:
                source.map(item => {
                    (item.id !== 6 && item.id !== 5) &&
                    emptyArray.push(item);
                })
                break;
            // Шифр                
            case 4:
                source.map(item => {
                    (item.id !== 6 && item.id !== 6566457535587671) &&
                    emptyArray.push(item);
                })
                break;
            default:
                setSourceList(source)
                break;
        }
        setSourceList(emptyArray);
    }

    useEffect(() => {
        if (source.length) {
            ifSourceList();
        }
    }, [source])

    return (
        <select
            value={nonNull(data.book_source)}
            className={styles.select}
            name="book_source"
            onChange={onChange}
        >
            <option value={""}>Не выбрано</option>
            {sourceList.map(item => (
                <option value={item.id} key={item.id}>
                    {item.name}
                </option>))
            }
        </select>
    );
};

export default SupplierField;
