import { ERROR, ROOM_DELETE_REQUEST, START, SUCCESS } from "../../action-types";

export const roomDeleteRequestStart = (libraryId, roomId) => ({
  type: `${ROOM_DELETE_REQUEST}${START}`,
  payload: { libraryId, roomId }
});

export const roomDeleteRequestSuccess = (data) => ({
  type: `${ROOM_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const roomDeleteRequestError = (data) => ({
  type: `${ROOM_DELETE_REQUEST}${ERROR}`,
  payload: data
});