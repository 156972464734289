import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import ModalInputWrapper from "../../components/Modal/InputWrapper";
import {scheduleActions} from "../../redux/factory/scheduleReduxData";
import Button from "../../components/Button";
import {Col, Row} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {loadBookSupplyXML} from "../../api/bookSupply/bookSupply";
import {createNotification} from "../../components/notification/notification";
import { saveAs } from 'file-saver';

const mapStateToProps = ({ schedulePersonalReducer }) => ({
  yearsList: schedulePersonalReducer,
});

const mapDispatchToProps = {
  requestYears: scheduleActions.loadRequestStart,
};

const validationSchema = Yup.object().shape({
  year: Yup.string()
    .required('Выберите год')
});

const SPOIntegrationScene = ({ requestYears, yearsList }) => {
  const selectForm = useFormik({
    initialValues: {
      year: ''
    },
    validationSchema,
    onSubmit: values => onSubmitForm(values)
  });

  const onSubmitForm = (values) => {
    const { year } = values;

    loadBookSupplyXML(year)
      .then(response => {
        saveXML(response.data);
      })
      .catch(() =>
        createNotification(
          'error',
          'Ошибка загрузки'
        )
      )
  };

  const saveXML = (data) => {
    const file = new File([data], "выгрузка.xml", { type: "application/xml;charset=utf-8" });
    saveAs(file);
  };

  useEffect(() => {
    requestYears();
  }, [requestYears]);

  return (
    <SceneContainer title="Выгрузка в изделие 83т260">
      <div className="formContainer">
        <Row>
          <Col>
            <ModalInputWrapper
              label="Учебный год"
              error={selectForm.errors.year}
            >
              <select
                name={"year"}
                onChange={selectForm.handleChange}
                value={selectForm.values.year}
              >
                <option value="" disabled>
                  Выберите учебный год
                </option>
                {yearsList.year && yearsList.year.schoolYear &&
                yearsList.year.schoolYear.map(item => (
                  <option value={item.xp_key}>{item.name}</option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
          <Col md={"auto"}>
            <ModalInputWrapper>
              <Button label="Выгрузить" onClick={selectForm.handleSubmit}/>
            </ModalInputWrapper>
          </Col>
        </Row>
      </div>
    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SPOIntegrationScene);