import { api } from '../../App'

export const getGroups = (params) => {
  return api.get('/groupname', { params })
}

export const getGroupsById = id => {
  return api.get(`/groupname/${id}`)
}

export const editGroup = (id, { name, year, liter, owner_gid }) => {
  const params = {
    name,
    year,
    // liter,
    // owner_gid,
  }

  return api.put(`groupname/${id}`, params)
}

export const addGroup = ({ name, year, liter, owner_gid }) => {
  const params = {
    name,
    year,
    // liter,
    // owner_gid,
  }

  return api.post(`groupname`, params)
}

export const deleteGroup = id => {
  return api.delete(`groupname/${id}`)
}
