import React from 'react';
import PropTypes from 'prop-types';
import styles from './LibraryCard.module.css';
import {Col, Row} from "react-bootstrap";
import ImagePlaceholder from "../../ImagePlaceholder";
import {resolveUserPhoto} from "../../../utils/resolveUserPhoto";

const LibraryCard = ({isOwn, data}) => {
    return (
        <div className={styles.container}>
            {data && (
                <>
                    <div className={styles.head}>
                        {isOwn ? "Читательский билет" : `Читательский формуляр №${data.mid}`}
                    </div>
                    <Row className={styles.row} noGutters={true}>
                        <Col md={7}>
                            <div className={styles.infoContainer}>
                                <div className={styles.name}>{data.displayname}</div>
                                <div className={styles.role}>{data.position}</div>
                            </div>
                        </Col>
                        <Col md={5}>
                            {data.photosize ? (
                                <div
                                    className={styles.placeholder}
                                    style={{
                                        backgroundImage: "url(" + resolveUserPhoto(data.mid) + ")"
                                    }}
                                />
                            ) : (
                                <ImagePlaceholder/>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

LibraryCard.propTypes = {
    data: PropTypes.object
};

export default LibraryCard;
