import { call, put, takeLatest, select } from 'redux-saga/effects'
import { getList } from '../../../api/mobileLibrary'
import { MOBILE_LIBRARY_LIST_REQUEST, START } from '../../actions/action-types'
import { mobileLibraryListRequestSuccess } from '../../actions/mobileLibrary/library/list'

export default function* mobileListWatcher() {
  yield takeLatest(`${MOBILE_LIBRARY_LIST_REQUEST}${START}`, mobileListWorker)
}


function* mobileListWorker() {
  const { limit, offset } = yield select(({mobileLibrary}) => mobileLibrary.search)
  try {
    const response = yield call(getList, {limit, offset})
    yield put(mobileLibraryListRequestSuccess(response.data))
  } catch (error) {
  }
}