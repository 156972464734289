import { ERROR, BOOK_SECRET_DOC_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const bookSecretDocListRequestStart = (formData) => ({
    type: `${BOOK_SECRET_DOC_LIST_REQUEST}${START}`,
    payload: formData
});

export const bookSecretDocListRequestSuccess = (data) => ({
    type: `${BOOK_SECRET_DOC_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const bookSecretDocListRequestError = (error) => ({
    type: `${BOOK_SECRET_DOC_LIST_REQUEST}${ERROR}`,
    payload: error
});


