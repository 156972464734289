import {INVENTARY_BOOK_LOAD_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const inventaryBookListReducer = (state = initialState, action) => {

    switch (action.type) {

        case `${INVENTARY_BOOK_LOAD_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }

};
