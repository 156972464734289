import { AUTHOR_SAVE_IMAGE_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const authorSaveImageRequestStart = (file, id) => ({
  type: `${AUTHOR_SAVE_IMAGE_REQUEST}${START}`,
  payload: {
    file, id
  }
});

export const authorSaveImageRequestSuccess = (data) => ({
  type: `${AUTHOR_SAVE_IMAGE_REQUEST}${SUCCESS}`,
  payload: data
});

export const authorSaveImageRequestError = (data) => ({
  type: `${AUTHOR_SAVE_IMAGE_REQUEST}${ERROR}`,
  payload: data
});
