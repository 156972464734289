import React, { useState, useMemo } from 'react'
import styles from './BookEditIndexTab.module.css'
import Button from '../../../Button'
import classNames from 'classnames'
import ChooseImage from '../../../ChooseFile'
import BookCardImage from './BookCardImage'

const BookScans = ({ bookId, backCoverUrl, frontCoverUrl, onUpload, onClean, bookCardData }) => {
  const [selectedIndex, setSelectedIndex] = useState(1)

  const bookCardSideData = useMemo(() => bookCardData.filter(item => item.cardnum === selectedIndex), [
    selectedIndex,
    bookCardData,
  ])

  return (
    <div className={styles.scansContainer}>
      <div className={styles.scanImageContainer}>
        <BookCardImage
          url={selectedIndex === 1 ? frontCoverUrl : backCoverUrl}
          style={styles.scanImage}
          bookCardSideData={bookCardSideData}
        />
      </div>
      <div className={styles.scansInputGroup}>
        <label className={styles.label} htmlFor='front'>
          <input
            className={styles.radio}
            id='front'
            type='radio'
            checked={selectedIndex === 1}
            onClick={() => setSelectedIndex(1)}
          />
          Лицевая сторона
        </label>
        <label className={styles.label} htmlFor='back'>
          <input
            className={styles.radio}
            id='back'
            type='radio'
            onClick={() => setSelectedIndex(2)}
            checked={selectedIndex === 2}
          />
          Оборотная сторона
        </label>
      </div>
      <div className={styles.buttonsContainer}>
        <ChooseImage
          onChange={file => {
            onUpload({
              file,
              bookId,
              side: selectedIndex === 1 ? 'front' : 'back',
            })
          }}
        >
          <Button style={styles.uploadImageButton} label='Загрузить' />
        </ChooseImage>
        <Button
          onClick={() =>
            onClean({
              bookId,
              side: selectedIndex === 1 ? 'front' : 'back',
            })
          }
          style={classNames(styles.uploadImageButton, styles.cleanImageButton)}
          label='Очистить'
        />
      </div>
    </div>
  )
}

export default BookScans
