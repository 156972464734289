import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import BorrowingTestTable from '../../components/BorrowingTest/Table'
import { borrowingTestCountRequestStart } from '../../redux/actions/borrowingTest/count'
import BorrowingTestForm from '../../components/BorrowingTest/Form'
import { setBorrowingTestFormData } from '../../redux/actions/borrowingTest/form'
import Pagination from '../../components/Pagination'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { setBorrowingTestSearchData } from '../../redux/actions/borrowingTest/search'
import { borrowingTestDeleteRequestStart } from '../../redux/actions/borrowingTest/delete'
import { ADD, EDIT } from '../../constants/modalTypes'
import BorrowingTestModal from '../../components/BorrowingTest/Modal'
import BorrowingTestControlsPanel from '../../components/BorrowingTest/ControlsPanel'
import { borrowingTestAddRequestStart } from '../../redux/actions/borrowingTest/add'
import { borrowingTestEditRequestStart } from '../../redux/actions/borrowingTest/edit'
import { getAntiplagiatReport } from '../../api/borrowingTest/antiplagiat'
import { saveAs } from 'file-saver'

export const ANTI_PLAGIAT_STATUS_LIST = new Map([
  ['', 'Все'],
  [1, 'новый'],
  [2, 'обработка'],
  [3, 'выполнено'],
  [100, 'ошибка'],
])

const INITIAL_MODAL_DATA = {
  type: null,
  data: null,
  methods: null,
  errors: null,
}

const mapStateToProps = ({ borrowingTest }) => ({
  list: borrowingTest.list,
  count: borrowingTest.count,
  loader: borrowingTest.loader,
  searchData: borrowingTest.search,
  formData: borrowingTest.form,
  isEmpty: borrowingTest.empty,
  data: borrowingTest
})

const mapDispatchToProps = {
  requestList: borrowingTestCountRequestStart,
  setFormData: setBorrowingTestFormData,
  setSearchData: setBorrowingTestSearchData,
  deleteItem: borrowingTestDeleteRequestStart,
  addItem: borrowingTestAddRequestStart,
  editItem: borrowingTestEditRequestStart,
}

const BorrowingTest = ({
  list,
  data,
  requestList,
  count,
  formData,
  setFormData,
  loader,
  searchData,
  setSearchData,
  isEmpty,
  deleteItem,
  addItem,
  editItem,
}) => {
  const requestListSetInterval = useRef()
  useEffect(() => {
    requestList()

    requestListSetInterval.current = setInterval(() => {
      requestList()
    }, 6000)
    return () => {
      clearInterval(requestListSetInterval.current)
    }
  }, [])


  const [modalData, setModalData] = useState(INITIAL_MODAL_DATA)
  const [modalErrors, setModalErrors] = useState({
    file: {
      text: 'Необходимо загрузить файл!',
      active: false,
    },
  })

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const closeModal = () => {
    setModalData(INITIAL_MODAL_DATA)
  }

  const openCreationModal = () => {
    setModalData({
      type: ADD,
      data: {
        comment: '',
        file: null,
      },
      methods: {
        onSubmit({ comment, file }) {
          if (file) {
            addItem({ comment, file })
            return closeModal()
          }
          setModalErrors({ ...modalErrors, file: { ...modalErrors.file, active: true } })
        },
      },
    })
  }

  const openEditModal = item => {
    setModalData({
      type: EDIT,
      data: item,
      methods: {
        onSubmit(data) {
          editItem(data)
          return closeModal()
        },
      },
    })
  }

  const saveFile = itemId => {
    getAntiplagiatReport(itemId)
      .then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        saveAs(blob, 'report')
      })
      .catch(error => console.error(error))
  }


  return (
    <SceneContainer title='Проверка на заимствования'>
      <BorrowingTestForm formData={formData} setFormData={setFormData} onSubmit={requestList} />

      {modalData.data && (
        <BorrowingTestModal closeModal={closeModal} modalData={modalData} setModalData={setModalData} modalErrors={modalErrors} />
      )}

      <BorrowingTestControlsPanel openCreationModal={openCreationModal} />

      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        <>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
          <BorrowingTestTable
            data={list}
            deleteItem={deleteItem}
            isEmpty={isEmpty}
            openEditModal={openEditModal}
            saveFile={saveFile}
          />
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
        </>
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BorrowingTest)
