import { START, SUCCESS, ERROR } from '../../actions/action-types'
import { SORT_DIRECTION } from '../../../constants/sortDirections'
import { RESULTS_PER_PAGE } from '../../../constants/pagination'
import { getStateSecretBookList, getStateSecretBookCount } from '../../../api/stateSecretBook/stateSecretBookApi'
import moment from 'moment'
import { call, put, select, takeLatest } from '@redux-saga/core/effects'

const COUNT_REQUEST = 'STATE_SECRET_BOOK_COUNT_REQUEST'
const LIST_REQUEST = 'STATE_SECRET_BOOK_LIST_REQUEST'
const SET_SEARCH_DATA = 'SET_STATE_SECRET_BOOK_SEARCH_DATA'
const SET_FORM_DATA = 'SET_STATE_SECRET_BOOK_FORM_DATA'
const TOGGLE_LOADING = 'STATE_SECRET_BOOK_TOGGLE_LOADING'

const initialState = {
  count: 0,
  list: [],
  search: {
    page: 1,
    sortBy: 'username',
    sortDirection: SORT_DIRECTION.DESC,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0,
  },
  form: {
    from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    to: moment(new Date()).format('YYYY-MM-DD'),
    user: '',
  },
  loader: false,
}

export const stateSecretBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${COUNT_REQUEST}${SUCCESS}`:
      return {
        ...state,
        count: action.payload,
      }

    case `${LIST_REQUEST}${SUCCESS}`:
      return {
        ...state,
        list: action.payload,
      }

    case SET_SEARCH_DATA:
      return {
        ...state,
        search: action.payload,
      }

    case SET_FORM_DATA:
      return {
        ...state,
        form: action.payload,
      }

    case `${TOGGLE_LOADING}${START}`:
      return {
        ...state,
        loader: true,
      }

    case `${TOGGLE_LOADING}${SUCCESS}`:
      return {
        ...state,
        loader: false,
      }

    default:
      return state
  }
}

export const stateSecretBookCountRequestStart = () => ({
  type: `${COUNT_REQUEST}${START}`,
})

export const stateSecretBookCountRequestSuccess = data => ({
  type: `${COUNT_REQUEST}${SUCCESS}`,
  payload: data,
})

export const stateSecretBookCountRequestError = data => ({
  type: `${COUNT_REQUEST}${ERROR}`,
  payload: data,
})

export const setStateSecretBookFormData = data => ({
  type: SET_FORM_DATA,
  payload: data,
})

export const stateSecretBookListRequestStart = () => ({
  type: `${LIST_REQUEST}${START}`,
})

export const stateSecretBookListRequestSuccess = data => ({
  type: `${LIST_REQUEST}${SUCCESS}`,
  payload: data,
})

export const stateSecretBookListRequestError = data => ({
  type: `${LIST_REQUEST}${ERROR}`,
  payload: data,
})

export const setStateSecretBookSearchData = data => ({
  type: SET_SEARCH_DATA,
  payload: data,
})

export function* stateSecretBookLogWatcher() {
  yield takeLatest(`${COUNT_REQUEST}${START}`, countWorker)
  yield takeLatest(`${LIST_REQUEST}${START}`, listWorker)
}

function* countWorker() {
  const formData = yield select(({ stateSecretBook }) => stateSecretBook.form)
  const searchData = yield select(({ stateSecretBook }) => stateSecretBook.search)
  try {
    const response = yield call(getStateSecretBookCount, formData)
    const count = parseInt(response.data.count, 10)
    yield put(stateSecretBookCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setStateSecretBookSearchData({ ...searchData, pageCount }))
    yield put(stateSecretBookListRequestStart())
  } catch (error) {
    yield put(stateSecretBookCountRequestError(error))
  }
}

function* listWorker() {
  const formData = yield select(({ stateSecretBook }) => stateSecretBook.form)
  const searchData = yield select(({ stateSecretBook }) => stateSecretBook.search)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(getStateSecretBookList, searchData, formData)
    yield put(stateSecretBookListRequestSuccess(response.data))
  } catch (error) {
    yield put(stateSecretBookListRequestError(error))
  }
}
