import { PERMISSIONS_BY_LITER_COUNT_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const permissionsByLiterCountRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_LITER_COUNT_REQUEST}${START}`,
  payload: data
});

export const permissionsByLiterCountRequestSuccess = (data) => ({
  type: `${PERMISSIONS_BY_LITER_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const permissionsByLiterCountRequestError = (data) => ({
  type: `${PERMISSIONS_BY_LITER_COUNT_REQUEST}${ERROR}`,
  payload: data
});
