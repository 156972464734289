import jsPDF from 'jspdf'
import T from 'prop-types'
import React from 'react'
import addFont from '../../../fonts/fonts'
import PdfPrintPanel from '../../PDFPrint/Panel'

const ElectronicCoursesPDFPrint = ({ fromDate, toDate }) => {
  const title = 'Прохождение электронных курсов'
  const orientation = 'landscape'

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
    doc.setFont('Montserrat')

    doc.setFont( undefined, 'bold')
    doc.setFontSize('16')
    doc.text(title, doc.internal.pageSize.width / 2, 10, null, null, 'center')

    doc.setFont( undefined, 'normal')
    doc.setFontSize('12')
    doc.text(`c: ${fromDate} по: ${toDate}`, doc.internal.pageSize.width / 2, 20, null, null, 'center')

    doc.autoTable({
      html: '#electronic_courses-table table',
      styles: { font: 'Montserrat', fontSize: 6 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      // margin: {
      //   top: 64,
      // },
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 25,
    })

    doc.addPage('a4', orientation)
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
}

ElectronicCoursesPDFPrint.propTypes = {
  librariesList: T.array.isRequired,
  searchFormValue: T.object.isRequired,
}

export default ElectronicCoursesPDFPrint
