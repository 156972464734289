import React from 'react';
import T from 'prop-types';
import styles from './DocumentTypesTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import { DOCUMENT_TYPES_SORT } from "../../../constants/sort/documentTypesSort";

const DocumentTypesTable = ({ data, sortBy, setSort }) => {
  return (
    <div className={styles.container}>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
        <DataTableCol sortParams={[DOCUMENT_TYPES_SORT.NAME]} title="Название типа" value="name" colSpan={3}/>
      </DataTable>
    </div>
  );
};

DocumentTypesTable.propTypes = {
  data: T.array,
  sortBy: T.string,
  setSort: T.func,
};

DocumentTypesTable.defaultProps = {
  data: []
};

export default DocumentTypesTable;
