import React, {useState, useEffect} from 'react';
import T from 'prop-types';
import styles from './ExternalResourcesEditableItem.module.css';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";


const emptyExternalResource = {
    resourcename: "",
    resourceurl: ""
};

const ExternalResourcesEditableItem = ({data, onSave, onDelete}) => {
    const [localData, setLocalData] = useState(emptyExternalResource);
    useEffect(() => setLocalData(data || emptyExternalResource), [data]);

    const onChange = ({target}) => setLocalData({...localData, [target.name]: target.value});

    /**
     * url регул выр для форматирования 
     * urlOK проверка соот-я
     */
const url = new RegExp(/^((http|https|ftp):\/\/)?(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)?(\.[A-Z0-9][A-Z0-9_-]{1,15}$)?(\.[A-Z0-9][A-Z0-9_-]{1,15}$)+)/i)
const urlOK = url.test(localData.resourceurl)

    return (
        <div className={styles.container}>
            <Row>
                <Col md={4}>
                    <ModalInputWrapper label={"Название ресурса"}
                                       name="resourcename"
                                       value={localData.resourcename}
                                       onChange={onChange}
                    />
                </Col>
                <Col md={5}>
                    <ModalInputWrapper label={"Адрес ресурса"}
                                       name="resourceurl"
                                       value={localData.resourceurl}
                                       onChange={onChange}
                                       placeholder="http://exemple.ru"
                                       error={!urlOK}
                    />
                    
                    {!urlOK && <p className={styles.error}>адрес указан некорректно</p>}
                </Col>
                <Col md={3}>
                    <div className={styles.buttonContainer}>
                        <Button label="Сохранить"
                                style={styles.button} 
                                onClick={() => onSave(localData)}
                                disabled={!urlOK} 
                                />
                        <Button label="Удалить"
                                style={styles.button}
                                onClick={() => onDelete(localData.id)}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

ExternalResourcesEditableItem.propTypes = {
    data: T.object,
    onSave: T.func,
    onDelete: T.func
};

export default ExternalResourcesEditableItem;
