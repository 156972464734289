import React, { useEffect } from 'react'
import { setFundMovingSearchData } from '../../redux/actions/fundMoving/setSearchData'
import { connect } from 'react-redux'
import FundMovementSearchForm from '../../components/FundMovementBook/SearchForm'
import { fundMovingLoadStart } from '../../redux/actions/fundMoving/load'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { libraryListRequestStart } from '../../redux/actions/library/info/list'
import FundMovingTable from '../../components/FundMovementBook/Table'

const mapStateToProps = ({ fundMoving, library, options }) => ({
  list: fundMoving.list,
  loader: fundMoving.loader,
  searchData: fundMoving.search,
  librariesList: library.info.list,
  optionsData: options.data,
})

const mapDispatchToProps = {
  setSearchData: setFundMovingSearchData,
  requestList: fundMovingLoadStart,
  getLibrariesList: libraryListRequestStart,
}

const FundMovingScene = ({
  requestList,
  list,
  setSearchData,
  searchData,
  loader,
  getLibrariesList,
  librariesList,
  optionsData,
}) => {
  useEffect(() => {
    if (optionsData) {
      getLibrariesList(optionsData.own_client_id)
    }
  }, [getLibrariesList, optionsData])

  return (
    <SceneContainer title={'Книга движения фонда'}>
      <FundMovementSearchForm
        setSearchData={setSearchData}
        searchData={searchData}
        onSubmit={requestList}
        librariesList={librariesList}
      />

      {loader ? <SpinnerOuter isLoading={loader} /> : <FundMovingTable data={list} />}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FundMovingScene)
