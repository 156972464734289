import { api } from '../../App'

export const getDocumentBooksList = documentId => {
  //Дизайн не предусматривает пагинации
  return api.get(`/book2document/${documentId}`)
}

export const addBookToDocument = data => {
  return api.post(`/book2document/${data.documentid}`, data)
}

export const editBookToDocument = data => {
  return api.put(`/book2document/${data.id}`, data)
}

export const deleteBookToDocument = id => {
  return api.delete(`/book2document/${id}`)
}
