import {IMPORT_JSON_SAVE, IMPORT_BOOKS_RESET, SUCCESS, ERROR} from "../../../actions/action-types";

const initialState = null;

export const jsonReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${IMPORT_JSON_SAVE}${SUCCESS}`:
            return action.payload.ok[0];
        
        case `${IMPORT_JSON_SAVE}${ERROR}`:
            return action.payload;

        case `${IMPORT_BOOKS_RESET}`:
            return initialState;

        default: return state;
    }
};