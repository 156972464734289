import { INV_NUMBER_SAVE_REQUEST, ERROR, START, SUCCESS } from '../action-types'
import { createNotification } from '../../../components/notification/notification'

export const invNumberSaveRequestStart = data => ({
  type: `${INV_NUMBER_SAVE_REQUEST}${START}`,
  payload: data,
})

export const invNumberSaveRequestSuccess = data => {
  const { number } = data
  createNotification('success', `Инвентарный номер ${number} успешно добавлен в список`)

  return {
    type: `${INV_NUMBER_SAVE_REQUEST}${SUCCESS}`,
    payload: 'OK',
  }
}

export const invNumberSaveRequestError = data => {
  createNotification('error', 'Ошибка добавления инвентарного номера')

  return {
    type: `${INV_NUMBER_SAVE_REQUEST}${ERROR}`,
    payload: data,
  }
}
