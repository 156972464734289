import { AUTHOR_DELETE_IMAGE_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const authorDeleteImageRequestStart = (data) => ({
  type: `${AUTHOR_DELETE_IMAGE_REQUEST}${START}`,
  payload: data
});

export const authorDeleteImageRequestSuccess = (data) => ({
  type: `${AUTHOR_DELETE_IMAGE_REQUEST}${SUCCESS}`,
  payload: data
});

export const authorDeleteImageRequestError = (data) => ({
  type: `${AUTHOR_DELETE_IMAGE_REQUEST}${ERROR}`,
  payload: data
});
