import React from 'react';
import T from 'prop-types';
import DataTableCol from "../../../DataTable/Column";
import DataTable from "../../../DataTable";
import moment from "moment";
import styles from './Table.module.css'

const AccountingJournalTable = ({data}) => {

    /*  {
   "id": 904861,
   "mid": "2417",
   "bookid": "168848",
   "affectedid": null,
   "eventtime": "2019-10-01T11:53:57.247Z", --1
   "clientip": "188.130.155.155",  --2
   "logevent": 0,
   "write": false,
   "accessdenied": false, --6
   "errormessage": null,
   "payloadurl": "/api/book/:id/pdf",
   "payloadbody": "{}",
   "payloadarg": "{}",
   "method": "GET",
   "login": "icl",  --3
   "lastname": "ICL",  --4
   "firstname": " ",  --4
   "patronymic": " ",  --4
   "title": "Великая Отечественная война 1941-1945 годов. В 12 томах. Том 2.",  --5
   "authors": null --5
 }*/
    return (
        <DataTable data={data} >
            <DataTableCol title={"Дата"} resolver={item => moment(item.eventtime).format('DD.MM.YYYY')}/>
            <DataTableCol title={"IP адрес"} value="clientip"/>
            <DataTableCol title={"Логин"} value="login"/>
            <DataTableCol title={"ФИО"} colSpan={2} resolver={item => {
                return `${item.lastname} ${item.firstname} ${item.patronymic}`
            }}/>
            <DataTableCol title={"Книга"} colSpan={3} resolver={item => {
                return (<>
                        <div><strong>{item.title}</strong></div>
                        <span>{item.authors && item.authors.map(author => author.name)}</span>
                    </>
                )
            }}/>
            <DataTableCol title={`Успешность`} className={styles.tableCheckbox} resolver={item => {
                return (<input type="checkbox" checked={item.accessdenied} disabled/>)
            }}/>
        </DataTable>
    );
};

AccountingJournalTable.propTypes = {
    data: T.array,
};

export default AccountingJournalTable;