import { SET_ELECTRONIC_COURSES_SEARCH_DATA } from '../../../actions/action-types'
import { RESULTS_PER_PAGE } from '../../../../constants/pagination'
import moment from 'moment'

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  from: moment().add(-10, 'days').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  groupid: '',
  student: '',
}

export const electronicCoursesSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ELECTRONIC_COURSES_SEARCH_DATA:
      return action.payload

    default:
      return state
  }
}
