import React, { useEffect, useState } from "react";
import T from "prop-types";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { Col, Row } from "react-bootstrap";
import { libraryInstitutionsRequestStart } from "../../../redux/actions/library/info/institutions";
import { connect } from "react-redux";
import { nonNull } from "../../../utils/strings";
import { verifyStatusList } from "../../../constants/bookVerifyStatus";
import { collectionsListRequestStart } from "../../../redux/actions/collections/list";
import SearchableSelect from "../../Select";
import {
  categoriesToOptions,
  disciplineToOptions,
} from "../../../utils/toSelectOption";
import { categoriesListRequestStart } from "../../../redux/actions/categories/list";
import { litersListRequestStart } from "../../../redux/actions/liters/list";
import { INV_NUMBERS_FORM_STRUCTURE } from "../../../constants/invNumbers";
import { seriesListRequestStart } from "../../../redux/actions/series/list";
import { setDisciplineSearchData } from "../../../redux/actions/discipline/searchData";
import { api } from "../../../App";
import { bookSourceRequestStart } from "../../../redux/actions/booksource";
import { libraryTypesListRequestStart } from "../../../redux/actions/library/types/list";
import styles from "../../FundMovementBook/SearchForm/FundMovementSearchForm.module.css";



const mapStateToProps = ({
  library,
  collections,
  categories,
  liters,
  series,
  discipline,
  bookSource,
  options,
  historyLocation,
}) => ({
  institutions: library.info.institutions,
  disciplineList: discipline.list,
  disciplineSearch: discipline.search,
  collections: collections.list,
  categoriesList: categories.list,
  libraryTypes: library.types.list,
  liters: liters.list,
  series: series.list,
  bookSource: bookSource,
  optionsList: options.data,
  historyLocation: historyLocation,
});

const mapDispatchToProps = {
  requestTypes: libraryTypesListRequestStart,
  requestInstitutions: libraryInstitutionsRequestStart,
  requestCollections: collectionsListRequestStart,
  requestCategories: categoriesListRequestStart,
  requestLiter: litersListRequestStart,
  requestSeries: seriesListRequestStart,
  setDisciplineSearch: setDisciplineSearchData,
  requestBookSource: bookSourceRequestStart,
};

const InvNumbersSearchForm = ({
  data,
  setData,
  children,
  requestInstitutions,
  requestCollections,
  noVerifyStatus,
  requestCategories,
  categoriesList,
  onSubmit,
  simplified,
  setDisciplineSearch,
  disciplineSearch,
  requestBookSource,
  bookSource,
  historyLocation,
  requestTypes,
  libraryTypes,
  invNumbers = false,
  librariesList,
}) => {
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const [disciplineList, setDisciplineList] = useState(null);
  const [fundList, setFundList] = useState([]);
  const [grifList, setGrifList] = useState([
    // Шифр
    { name: "дсп", id: 2 },
    { name: "секретно", id: 3 },
    { name: "несекретно", id: 4 },
  ]);

  useEffect(() => {
    // Шифр
    if (data.library === "0") {
      setFundList([]);
    } else {
      api
        .get(`/library/${data.library}/fund`)
        .then((res) => {
          setFundList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Шифр
    setData({ ...data, fund: 0 });
  }, [data.library]);

  const getFullListDiscipline = () => {
    api.get("/discipline").then((response) => {
      setDisciplineList(response.data);
    });
  };

  const onDisciplineInputChange = (name) => {
    setDisciplineSearch({ ...disciplineSearch, query: name });
    if (!disciplineList) {
      getFullListDiscipline();
    }
  };

  /* 
  useEffect(() => {
    if (!data.cid) {
      setDisciplineList(null)
    }
  }, [data]) */

  useEffect(() => {
    requestTypes();
  }, [requestTypes]);

  useEffect(() => {
    requestInstitutions();
  }, [requestInstitutions]);

  useEffect(() => {
    requestBookSource();
  }, [requestBookSource]);

  useEffect(() => {
    requestCollections();
  }, [requestCollections]);

  useEffect(() => {
    requestCategories();
  }, [requestCategories]);

  if (noVerifyStatus && !simplified) {
    data.verifyStatus = 2;
  }

  return (
    <div className={"formContainer"}>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            onChange={onChange}
            name="title"
            onKeyPress={onKeyPress}
            value={data.title}
            label="Заглавие"
          />
        </Col>
        <>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              name="author"
              value={data.author}
              onKeyPress={onKeyPress}
              label="Автор"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              onKeyPress={onKeyPress}
              name="collaborator"
              value={data.collaborator}
              label="Соавторы"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              name="publisher"
              value={data.publisher}
              onKeyPress={onKeyPress}
              label="Издательство"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              name="isbn"
              value={data.isbn}
              onKeyPress={onKeyPress}
              label="ISBN"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              name="issn"
              value={data.issn}
              onKeyPress={onKeyPress}
              label="ISSN"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              name="udk"
              value={data.udk}
              onKeyPress={onKeyPress}
              label="УДК"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              name="bbk"
              value={data.bbk}
              onKeyPress={onKeyPress}
              label="ББК"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={onChange}
              name="year"
              value={data.year}
              onKeyPress={onKeyPress}
              label="Год"
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper label="Категория">
              <SearchableSelect
                placeholder="Поиск"
                options={categoriesToOptions(categoriesList)}
                onChange={(value) =>
                  setData({ ...data, categoryid: (value || {}).value })
                }
                value={data.categoryid ? undefined : null}
              />
            </ModalInputWrapper>
          </Col>
          <Col md={6}>
            <ModalInputWrapper label="Дисциплины">
              <SearchableSelect
                data={data}
                setData={setData}
                placeholder="Поиск"
                onInputChange={onDisciplineInputChange}
                options={disciplineToOptions(disciplineList)}
                value={data.cid ? undefined : null}
                onChange={(value) => setData({ ...data, cid: value.value })}
              />
            </ModalInputWrapper>
          </Col>
          <Col md={12}>
            <ModalInputWrapper
              onChange={onChange}
              name="textsearch"
              value={data.textsearch}
              label="Полнотекстовый поиск"
            />
          </Col>
        </>
        {!noVerifyStatus && (
          <Col md={6}>
            <ModalInputWrapper label={"Статус"}>
              <select
                value={data.verifyStatus}
                name="verifyStatus"
                onChange={onChange}
              >
                <option value={""}>Не выбрано</option>
                {verifyStatusList.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        )}

        {
          <Col md={6}>
            <ModalInputWrapper label={"Источник"}>
              <select
                value={nonNull(data.book_source)}
                name="book_source"
                onChange={onChange}
              >
                <option value={""} disabled>
                  Не выбрано
                </option>
                {bookSource.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        }
        {(historyLocation === "/inv_numbers" ||
          historyLocation === "/books" ||
          invNumbers) && (
          <Col md={6}>
            <ModalInputWrapper
              label={"Инвентарный номер"}
              type="text"
              name="number"
              value={data.number}
              onChange={onChange}
            />
          </Col>
        )}
        <Col md={6}>
          <ModalInputWrapper label={"Тип документа"}>
            <select name="typeId" onChange={onChange} value={data.typeId}>
              <option value="">Не выбрано</option>
              {libraryTypes &&
                libraryTypes.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            label="Ключевые слова"
            name="keywords"
            onChange={onChange}
            value={data.keywords}
            onKeyPress={onKeyPress}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper label={"Библиотека"}>
            <select
              name="library"
              onChange={onChange}
              value={data.library}
              className={styles.select}
            >
              <option value="0">Все</option>
              {librariesList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <ModalInputWrapper label={"Фонд"}>
            <select
              name="fund"
              onChange={onChange}
              value={data.fund}
              className={styles.select}
            >
              <option value="0">Все</option>
              {fundList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <ModalInputWrapper label={"Гриф"}>
            <select
              name="OutSecrecyId"
              onChange={onChange}
              value={data.OutSecrecyId}
              className={styles.select}
            >
              <option value="1">Не выбрано</option>
              {grifList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={12}>{children}</Col>
      </Row>
    </div>
  );
};

InvNumbersSearchForm.propTypes = {
  data: T.object,
  setData: T.func,
  onSubmit: T.func,
  disableAlphabet: T.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvNumbersSearchForm);
