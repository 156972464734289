import { SET_REPORTS_FORM_DATA } from "../../../actions/action-types";
import moment from "moment";

const initialState = {
  dateFrom: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD'),
  bzTitle: "",
  userid: ""
};

export const elUsageFormDataReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_REPORTS_FORM_DATA:
      return action.payload;

    default: return state;
  }
};
