import React, { useState, useEffect } from "react";
import T from "prop-types";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import { Button } from "antd";
import Select from "react-select";
import { selectCustomStyles } from "../../../Select/customStyles";
import { api } from "../../../../App";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  dateFrom: Yup.date().required("Выберите дату начала"),
  dateTo: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("dateFrom"), "Дата завершения должна быть позже даты начала"),
});

const ElectronicResourcesReportForm = ({ onSubmit, data, setData }) => {
  const [users, setUsers] = useState([]);
  const [current, setCurrent] = useState("");

  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      const payload = { ...values, userid: current }
      setData(payload);
      onSubmit(payload);
    },
  });

  const { values, errors } = modalForm;

  const userDataResolver = (data) =>
    data.map((item) => ({
      label: item.lastname + " " + item.firstname + " " + item.patronymic,
      value: item.mid,
    }));

  const onUsersInputChange = (name) => {
    const params = {
      paginateFrom: 0,
      search: name,
      orderBy: "lastname",
    };

    api.get("/people", { params }).then((res) => {
      setUsers(userDataResolver(res.data));
    });
  };

  return (
    <div className={"formContainer"}>
      <Row>
        <Col align="Left">
          <Row>
            <Col align="Left" md={6}>
              <ModalInputWrapper
                label="С*"
                type={"date"}
                name="dateFrom"
                value={values["dateFrom"]}
                error={errors["dateFrom"]}
                onChange={modalForm.handleChange}
              />
            </Col>
            <Col align="Left" md={6}>
              <ModalInputWrapper
                label="По*"
                type={"date"}
                name="dateTo"
                value={values["dateTo"]}
                error={errors["dateTo"]}
                onChange={modalForm.handleChange}
              />
            </Col>
            <Col align="Left" md={6}>
              <ModalInputWrapper label="Пользователь">
                <Select
                  isSearchable={true}
                  isClearable={true}
                  onInputChange={onUsersInputChange}
                  options={users}
                  onChange={(val) => setCurrent(val?.value)}
                  placeholder={"Введите пользователя..."}
                  value={values.user_id ? current?.label : ''}
                  noOptionsMessage={() => "Ничего не найдено"}
                  styles={selectCustomStyles}
                  name="userid"
                />
              </ModalInputWrapper>
            </Col>
            <Col align="Left" md={6}>
              <ModalInputWrapper
                label="Название книги*"
                name="bzTitle"
                value={values["bzTitle"]}
                onChange={modalForm.handleChange}
              />
            </Col>
          </Row>
        </Col>

        <Col md={"auto"} className={"text-right"}>
          <ModalInputWrapper label={""} align="Right">
            <Button type="primary" onClick={modalForm.handleSubmit}>
              Найти
            </Button>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

ElectronicResourcesReportForm.propTypes = {
  onSubmit: T.func,
  data: T.object,
  setData: T.func,
};

export default ElectronicResourcesReportForm;
