import React, { useEffect, useState } from 'react'
import ModalWindow from '../../ModalWindow'
import { EDIT } from '../../../constants/modalTypes'
import ModalForm from './Form'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import { api } from '../../../App'

// const ElectronicCoursesModal = ({ editItem, modalData, setModalData, users }) => {
//   const closeModal = () => {
//     setModalData({ modalType: null, data: null })
//   }

//   const submitForm = values => {
//     editItem({ data: values, id: modalData.data.id, callback: closeModal })
//   }

//   return (
//     <ModalWindow
//       title={modalData.modalType === EDIT ? `Редактирование электронного курса` : `Добавление электронного курса`}
//       isOpen={true}
//       onRequestClose={closeModal}
//     >
//       <ModalForm onSubmit={submitForm} users={users} modalData={modalData} />
//     </ModalWindow>
//   )
// }

export const RecommendationModal = ({ modalData, setModalData }) => {
  const [recommendations, setRecommendations] = useState([])

  const closeModal = () => {
    setModalData({ modalType: null, data: null })
  }

  useEffect(() => {
    api.get(`course/${modalData.data.id}/recommendation`).then(data => setRecommendations(data.data.recommendation_json))
  }, [])

  return (
    <ModalWindow title={`Рекомендации`} isOpen={true} onRequestClose={closeModal}>
      {recommendations ? (
        <DataTable data={recommendations} onCellClick={() => {}}>
          <DataTableCol title='Вопрос' value='question' />
          <DataTableCol title='Рекомендация' value='comment' />
        </DataTable>
      ) : (
        'Список пуст'
      )}
    </ModalWindow>
  )
}
