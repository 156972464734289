import { NEW_BOOKS_ORDER_COUNT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const newBooksCountRequestStart = () => ({
    type: `${NEW_BOOKS_ORDER_COUNT_REQUEST}${START}`
});

export const newBooksCountRequestSuccess = (data) => ({
    type: `${NEW_BOOKS_ORDER_COUNT_REQUEST}${SUCCESS}`,
    payload: data
});

export const newBooksCountRequestError = (data) => ({
    type: `${NEW_BOOKS_ORDER_COUNT_REQUEST}${ERROR}`,
    payload: data
});
