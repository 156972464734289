import { combineReducers } from 'redux'
import { auditLogCountReducer } from './count/auditLogCountReducer'
import { auditLogListReducer } from './list/auditLogListReducer'
import { auditLogSearchReducer } from './search/auditLogSearchReducer'
import { auditLogFormDataReducer } from './formData/auditLogFormDataReducer'
import { auditLogDatasetReducer } from './dataset/auditLogDatasetReducer'
import { auditLogLoaderReducer } from './loader/auditLogLoaderReducer'

export const auditLogReducer = combineReducers({
  count: auditLogCountReducer,
  list: auditLogListReducer,
  search: auditLogSearchReducer,
  form: auditLogFormDataReducer,
  dataset: auditLogDatasetReducer,
  loader: auditLogLoaderReducer,
})
