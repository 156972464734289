import { ERROR, LOST_DOC_GET_REQUEST, START, SUCCESS } from "../action-types";

export const lostDocStart = (payload) => ({
  type: `${LOST_DOC_GET_REQUEST}${START}`,
  payload,
});

export const lostDocSuccess = (payload) => ({
  type: `${LOST_DOC_GET_REQUEST}${SUCCESS}`,
  payload
});

export const lostDocError = ({formData}) => ({
  type: `${LOST_DOC_GET_REQUEST}${ERROR}`,
  payload: {formData}
});