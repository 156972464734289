import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import {bookTransferCountRequestStart} from "../../redux/actions/bookTransfer/count";
import {setBookTransferFormData} from "../../redux/actions/bookTransfer/form";
import {setBookTransferSearchData} from "../../redux/actions/bookTransfer/search";
import {bookTransferDeleteRequestStart} from "../../redux/actions/bookTransfer/delete";
import BookTransferSearchForm from "../../components/BookTransfer/SearchForm";
import Pagination from "../../components/Pagination";
import BookTransferTable from "../../components/BookTransfer/Table";

const mapStateToProps = ({ bookTransfer }) => ({
  list: bookTransfer.list,
  count: bookTransfer.count,
  loader: bookTransfer.loader,
  searchData: bookTransfer.search,
  formData: bookTransfer.form,
  isEmpty: bookTransfer.empty
});

const mapDispatchToProps = {
  requestList: bookTransferCountRequestStart,
  setFormData: setBookTransferFormData,
  setSearchData: setBookTransferSearchData,
  deleteItem: bookTransferDeleteRequestStart,
};

export const BOOK_TRANSFER_STATUS_LIST = new Map([
  ['', 'Все'],
  [1, 'создан'],
  [2, 'отправлен'],
  [3, 'получен библиотекой / в процессе'],
  [4, 'файл готов к скачиванию'],
  [5, 'файл скачан'],
  [100, 'ошибка'],
]);

const BookTransfer = ({
  list,
  requestList,
  count,
  formData,
  setFormData,
  loader,
  searchData,
  setSearchData,
  isEmpty,
  deleteItem,
}) => {
  useEffect(() => {
    requestList();
  }, [requestList]);

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList();
  };

  const saveFile = () => {

  };

  return (
    <SceneContainer title="Заказы на загрузку книг">

      <BookTransferSearchForm data={formData} setFormData={setFormData} startSearch={requestList} types={BOOK_TRANSFER_STATUS_LIST}/>

      {(loader && !isEmpty) ?
        <SpinnerOuter isLoading={loader}/>
        :
        <>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
          <BookTransferTable data={list} deleteItem={deleteItem} saveFile={saveFile}/>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </>
      }
    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookTransfer);