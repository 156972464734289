import { call, put, takeLatest, select } from "redux-saga/effects";
import { START, DIRECTORY_COUNT_REQUEST, DIRECTORY_LIST_REQUEST, DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING } from "../../../actions/action-types";
import { getUsersCount, getUsersList, getDirectory, deleteDirectory } from "../../../../api/adminUsers/users";
import { usersCountRequestError, usersCountRequestSuccess } from "../../../actions/adminUsers/count";
import { setDirectorySearchData } from "../../../actions/adminDirectory/search";

import {
  directoryListRequestSuccess,
  directoryListRequestStart,
  directoryListRequestError
} from '../../../actions/adminDirectory/list'

export default function* loadDirectoryWatcher() {
  yield takeLatest(`${DIRECTORY_COUNT_REQUEST}${START}`, usersCountWorker);
  yield takeLatest(`${DIRECTORY_LIST_REQUEST}${START}`, usersListWorker)
}

function* usersCountWorker() {
  const searchData = yield select(({ adminUsers }) => adminUsers.search);
  try {
    const response = yield call(getUsersCount, searchData.query);
    const count = parseInt(response.data.count, 10);
    yield put(directoryListRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setDirectorySearchData({ ...searchData, pageCount }));
    yield put(directoryListRequestStart());
  } catch (error) {
    yield put(usersCountRequestError(error));
  }
}

function* usersListWorker() {
  const searchData = yield select(({ adminUsers }) => adminUsers.search);
  const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
    const response = yield call(getDirectory);
    yield put(directoryListRequestSuccess(response.data));
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
  } catch (error) {
    yield put(directoryListRequestError(error));
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
  }
}