import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import { getSchedule } from "../../../../api/schedule/schedule";
import {
  scheduleRequestError,
  scheduleRequestSuccess
} from "../../../actions/schedule/list";
import { SCHEDULE_LIST_REQUEST, START } from "../../../actions/action-types";

export default function* scheduleListWatcher() {
  yield takeLatest(`${SCHEDULE_LIST_REQUEST}${START}`, scheduleListWorker);
}

function* scheduleListWorker() {
  const searchData = yield select(({ schedule }) => schedule.search);
  const authId = yield select(
    ({
      auth: {
        data: { mid }
      }
    }) => mid
  );
  try {
    const data = yield call(getSchedule, { id: authId, date: searchData });
    yield put(scheduleRequestSuccess((data.data || {}).schedule || []));
  } catch (e) {
    yield put(scheduleRequestError());
  }
}
