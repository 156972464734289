import { DOCUMENT_BOOK_SAVE_REQUEST, START, SUCCESS, ERROR } from '../../action-types'
import { createNotification } from '../../../../components/notification/notification'

export const documentBookSaveRequestStart = data => ({
  type: `${DOCUMENT_BOOK_SAVE_REQUEST}${START}`,
  payload: data,
})

export const documentBookSaveRequestSuccess = data => {
  createNotification('success', 'Книга успешно добавлена в документ')
  return {
    type: `${DOCUMENT_BOOK_SAVE_REQUEST}${SUCCESS}`,
    payload: data,
  }
}

export const documentBookSaveRequestError = data => ({
  type: `${DOCUMENT_BOOK_SAVE_REQUEST}${ERROR}`,
  payload: data,
})
