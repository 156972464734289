import React, { useEffect, useState } from 'react'
import Pagination from '../../components/Pagination'
import { setBookInventorySearchData } from '../../redux/actions/bookInventory/search'
import { connect } from 'react-redux'
import InventaryBookSearchForm from '../../components/InventaryBook/SearchForm'
import InventaryBookTable from '../../components/InventaryBook/InventoryTable'
import { InventoryBookTablePdfPrint } from '../../components/InventaryBook//PDFPrint/'
import { bookInventaryCountRequestStart } from '../../redux/actions/bookInventory/count'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import DocumentsModal from '../../components/Documents/Modal'
import { documentTypesAllRequestStart } from '../../redux/actions/documents/types/all'
import { documentSaveRequestStart } from '../../redux/actions/documents/documents/save'
import { libraryListRequestStart } from '../../redux/actions/library/info/list'
import { getDocumentData } from '../../api/documents/documents'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { institutionsAllListRequestStart } from '../../redux/actions/institutions/all'
import moment from 'moment'

const mapStateToProps = ({ inventaryBook, documentTypes, library, options, institutions }) => ({
  list: inventaryBook.list,
  loader: inventaryBook.loader,
  searchData: inventaryBook.searchData,
  count: inventaryBook.count,
  allTypes: documentTypes.all,
  librariesList: library.info.list,
  optionsData: options.data,
  orgList: institutions.all
})

const mapDispatchToProps = {
  setSearchData: setBookInventorySearchData,
  requestList: bookInventaryCountRequestStart,
  requestAllTypes: documentTypesAllRequestStart,
  saveDocument: documentSaveRequestStart,
  getLibrariesList: libraryListRequestStart,
  getOrgList: institutionsAllListRequestStart 
}

const BookInventory = ({
  searchData,
  setSearchData,
  loader,
  list,
  requestList,
  count,
  allTypes,
  requestAllTypes,
  saveDocument,
  librariesList,
  optionsData,
  getLibrariesList,
  orgList,
  getOrgList
}) => {

  useEffect(() => {
    !orgList.length && getOrgList()
  }, [])
  
  useEffect(() => {
    optionsData && getLibrariesList(optionsData.own_client_id)
  }, [getLibrariesList, optionsData])

  const [modalData, setModalData] = useState({ modalType: null, data: null })

  const onSaveModal = data => {
    saveDocument(data)
  }

  const onClickHandler = item => {
    getDocumentData(item.documentid).then(res => {
      setModalData({ modalType: 'EDIT', data: res.data })
    })
  }

  useEffect(() => {
    requestAllTypes()
  }, [requestAllTypes])

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const setSort = (sortBy, sortDirection) => {
    setSearchData({
      ...searchData,
      sortBy,
      page: 1,
      sortDirection: sortDirection,
    })
    requestList()
  }

  return (
    <SceneContainer title='Инвентарная книга'>
      <InventaryBookSearchForm
        librariesList={librariesList}
        data={searchData}
        setSearchData={setSearchData}
        startSearch={requestList}
      />
      <div className={'d-flex justify-content-end mb-2'}>
        {list.length > 0 &&
          <InventoryBookTablePdfPrint
            data={list}
            optionsData={optionsData}
            searchData={searchData}
            orgList={orgList}
          />
        }
      </div>
      {/* <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} /> */}

      {modalData.modalType && (
        <DocumentsModal
          types={allTypes}
          onSave={onSaveModal}
          setModalData={setModalData}
          modalData={modalData}
          fromInvBook
        />
      )}

      {loader ? (
        <SpinnerOuter />
      ) : (
        <InventaryBookTable
          setSort={setSort}
          sortBy={searchData.sortBy}
          data={list}
          setModal={setModalData}
          onCellClick={onClickHandler}
        />
      )}

      {/* <Pagination
        count={count}
        pageCount={searchData.pageCount}
        current={searchData.page}
        setCurrent={setCurrentPage}
        onSave={onSaveModal}
      /> */}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BookInventory)
