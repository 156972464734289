import {DOCUMENTS_SEARCH_COUNT, ERROR, START, SUCCESS} from "../../action-types";

export const documentsCountRequestStart = () => ({
    type: `${DOCUMENTS_SEARCH_COUNT}${START}`
})

export const documentsCountRequestSuccess = (data) => ({
    type: `${DOCUMENTS_SEARCH_COUNT}${SUCCESS}`,
    payload: data
})

export const documentsCountRequestError = (data) => ({
    type: `${DOCUMENTS_SEARCH_COUNT}${ERROR}`,
    payload: data
})