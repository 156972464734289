import {
    SUCCESS,
    START,
    SCHEDULER_CATEGORY_LIST_REQUEST, SCHEDULER_CATEGORY_COUNT_REQUEST
} from "../../../../actions/action-types";

const initialState = [];

export const listReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${SCHEDULER_CATEGORY_COUNT_REQUEST}${START}`:
            return initialState;

        case `${SCHEDULER_CATEGORY_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};
