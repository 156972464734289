import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import styles from './BookLoanItem.module.css'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../Button'
import CoverPhotoPlaceholder from '../../../Covers/List/Item/CoverPhotoPlaceholder'
import CoverListItemInfo from '../../../Covers/List/Item/InfoBlock'
import { api } from '../../../../App'
import { createNotification } from '../../../notification/notification'
import BookLoanItemModal from '../Modal'

const dateFormatString = 'DD.MM.YYYY hh:mm'
const dateFormatStringWithoutTime = 'DD.MM.YYYY'

const BookLoanItem = ({
  data,
  reserve,
  unreserveBook,
  onTurnIn,
  currentMid,
  onDelete,
  onEdit,
  isCompact,
  isLibrarian,
  setSearchBookModalData,
  setSearchBookModalVisible,
  outdatedBooks,
  prepareBook,
}) => {
  const [bookEditModalOpen, setBookEditModalOpen] = useState(false)
  const [bookDates, setBookDates] = useState({
    movedate: moment(data.movedate).format('YYYY-MM-DD'),
    returndate: data.returndate ? moment(data.returndate).format('YYYY-MM-DD') : null,
    handedout: data.handedout ? moment(data.handedout).format('YYYY-MM-DD') : null,
  })
  const [isEditModalError, setEditModalError] = useState(false)

  // Шифр
  const isIssueOfBook = !!(data.typeto === 2 && data.placeto === currentMid)
  const isReturnOfBook = !!(data.typefrom === 2 && data.placefrom === currentMid)

  const onSubmitEdit = () => {
    const isMovingDateAfterReturnDate = moment(bookDates.movedate).isAfter(bookDates.returndate)

    if (!isMovingDateAfterReturnDate) {
      onEdit({ id: data.id, data: bookDates })
      setBookEditModalOpen(false)
    }
    setEditModalError(isMovingDateAfterReturnDate)
  }

  const onSubmitDelete = () => {
    onDelete({ id: data.id, mid: currentMid })
  }

  const onSendBookModalDataSingle = data => {
    const id = data.bookid
    const arr = []

    api
      .get(`/book/${id}`)
      .then(res => {
        arr.push(res.data)
        setSearchBookModalData(arr)
        setSearchBookModalVisible(true)
      })
      .catch(err => console.log(err))
  }

  const handleUnreserveBook = (id) => {
    unreserveBook(id);

    const message = 'Книга снята с брони';

    api.post('/add_message', { 
      book_id: +data.bookid,
      subject: message
     })
  }

  const handleOnTurnIn = () => {
    onTurnIn();
    const message = 'Книга сдана';

    api.post('/add_message', { 
      book_id: +data.bookid,
      subject: message
     })
  }

  const setOpenLostDocForm = () => window.open(
    `/report/6739377652068421`
);


  return (
    <>
      <BookLoanItemModal
        bookEditModalOpen={bookEditModalOpen}
        setBookEditModalOpen={setBookEditModalOpen}
        isIssueOfBook={isIssueOfBook}
        bookDates={bookDates}
        setBookDates={setBookDates}
        isReturnOfBook={isReturnOfBook}
        isEditModalError={isEditModalError}
        onSubmitEdit={onSubmitEdit}
        data={data}
      />
      {isCompact ? (
        <tr>
          <td>
            {data.handedout && <>{data.handedout && moment(bookDates.handedout).format(dateFormatStringWithoutTime)}</>}
            {data.movedate && <>{data.movedate && moment(bookDates.movedate).format(dateFormatStringWithoutTime)}</>}
          </td>
          <td>{data.number}</td>
          <td>
            <div className={'text-left'}>
              <div>
                <b>{data.title}</b>
              </div>
              <div>{data.authors && data.authors.map(item => `${item.name} `)}</div>
            </div>
          </td>
          <td>
            {data.editable && isLibrarian && (
              <div className={'text-center'}>
                <button onClick={() => setBookEditModalOpen(true)} className={'tableButton d-block'}>
                  Редактировать
                </button>
                <button onClick={onSubmitDelete} className={'tableButton d-block'}>
                  Удалить
                </button>
              </div>
            )}
            {onTurnIn && (
              <Col md={12} className='text-right'>
              {isLibrarian && <Button onClick={setOpenLostDocForm} label='Создать отчет в журнале "Тетрадь утерянных документов"' style="ml-2"/>}

                <Button onClick={onTurnIn} label='Сдать' />
              </Col>
            )}
            {reserve && (
              <Col md={12} className='text-right'>
                <Button label='Книга готова' onClick={() => prepareBook(data.id)} />
                <Button onClick={() => handleUnreserveBook(data.id)} style={'mt-3'} label='Снять бронь' />
                {isLibrarian && (
                  <Button
                    label={'Выдать книгу'}
                    style={'mt-3'}
                    onClick={() =>
                      outdatedBooks.length > 0
                        ? createNotification('error', 'Читатель имеет задолженность', 'Ошибка выдачи книги')
                        : onSendBookModalDataSingle(data)
                    }
                  />
                )}
              </Col>
            )}
          </td>
        </tr>
      ) : (
        <div className={styles.container}>
          <Row>
            <Col md={2}>
              <CoverPhotoPlaceholder url={data.coverImage} bookId={data.bookid} alt={data.title} />
            </Col>
            <Col md={10}>
              <Row>
                <Col md={3}>
                  {isIssueOfBook && <div className={classNames(styles.label, styles.state, styles.labelIssue)}>выдача</div>}
                  {isReturnOfBook && <div className={classNames(styles.label, styles.state)}>возврат</div>}
                </Col>

                <Col md={9}>
                  <div className={classNames(styles.value, styles.title)}>{data.title}</div>
                </Col>
              </Row>
              {!!data.authors && (
                <CoverListItemInfo
                  label={'Авторы'}
                  value={Array.isArray(data.authors) ? data.authors.map(a => a.name || a).join(', ') : data.authors}
                />
              )}

              {!!data.year && <CoverListItemInfo label={'Год'} value={data.year} />}
              {!!data.publishers && (
                <CoverListItemInfo
                  label={'Издательство'}
                  value={data.publishers.map((item, index) => (
                    <div key={index}>{item.name}</div>
                  ))}
                />
              )}
              {!!data.isbn && <CoverListItemInfo label={'ISBN'} value={data.isbn} />}
              {!!data.number && <CoverListItemInfo label={'Инв.номер'} value={data.number} />}
              {!!data.pageNum && <CoverListItemInfo label={'Страниц'} value={data.pageNum} />}

              {isIssueOfBook && (
                <>
                  <CoverListItemInfo
                    label={'Дата выдачи'}
                    value={data.movedate ? moment(bookDates.movedate).format(dateFormatStringWithoutTime) : 'Не установлено'}
                  />
                </>
              )}

              {data.handedout && (
                <>
                  <CoverListItemInfo
                    label={'Дата выдачи'}
                    value={data.handedout ? moment(bookDates.handedout).format(dateFormatStringWithoutTime) : 'Не установлено'}
                  />
                </>
              )}

              {data.returndate && (
                <>
                  <CoverListItemInfo
                    label={'Планируемая дата возврата'}
                    value={data.returndate ? moment(bookDates.returndate).format(dateFormatStringWithoutTime) : 'Не установлено'}
                  />
                </>
              )}

              {isReturnOfBook && (
                <>
                  <CoverListItemInfo
                    label={'Дата возврата'}
                    value={data.movedate ? moment(bookDates.movedate).format(dateFormatStringWithoutTime) : 'Не установлено'}
                  />
                </>
              )}

              {data.editable && isLibrarian && (
                <div className='d-flex justify-content-end'>
                  <Button label={'Редактировать'} onClick={() => setBookEditModalOpen(true)} />
                  <Button label={'Удалить'} colorType={'red'} style={'ml-3'} onClick={onSubmitDelete} />
                </div>
              )}
            </Col>
            {onTurnIn && (
              <Col md={12} className='text-right'>
                <Button onClick={setOpenLostDocForm} label='Утеря БЗ' style="ml-2"/>
                <Button onClick={() => handleOnTurnIn()} label='Сдать' style="ml-2"/>
              </Col>
            )}
            {reserve && (
              <Col md={12} className='text-right'>
                <Button label='Книга готова' style='mr-3' onClick={() => prepareBook(data.id)} />
                <Button onClick={() => handleUnreserveBook(data.id)} label='Снять бронь' />
                {isLibrarian && (
                  <Button
                    label={'Выдать книгу'}
                    style={'ml-3'}
                    onClick={() =>
                      outdatedBooks.length > 0
                        ? createNotification('error', 'Читатель имеет задолженность', 'Ошибка выдачи книги')
                        : onSendBookModalDataSingle(data)
                    }
                  />
                )}
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  )
}

BookLoanItem.propTypes = {
  data: PropTypes.object,
  reserve: PropTypes.bool,
}

export default BookLoanItem
