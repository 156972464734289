import { api } from '../../App'

export const getCount = search => {
  const params = {
    count: true,
    ...search,
  }
  return api.get(`/course`, { params })
}

export const getCountLibrarian = search => {
  const params = {
    count: true,
    ...search,
  }
  // console.log(params)

  return api.get(`/course_librarian`, { params })
}

export const getList = search => {
  const params = {
    ...search,
  }

  return api.get(`/course`, { params })
}

export const getListLibrarian = search => {
  const params = {
    ...search,
  }

  return api.get(`/course_librarian`, { params })
}

export const getItem = id => {
  return api.get(`/course/${id}`)
}

export const createItem = data => {
  const params = {
    ...data,
  }

  return api.post(`/course`, params)
}

export const editItem = (data, id) => {
  const params = {
    ...data,
  }

  return api.put(`/course/${id}`, params)
}

export const deleteCourse = id => {
  return api.delete(`/course/${id}`)
}

export const uploadCourseFile = ({ id, file }) => {
  return api.put(`/course/${id}/file`, file)
}
