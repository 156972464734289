import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Row } from 'react-bootstrap'
import IndexRowItem from './Item'
import { libraryTypesListRequestStart } from '../../redux/actions/library/types/list'
import styles from './IndexScene.module.css'
import { libraryGridData } from './libraryTypes'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'

const mapStateToProps = state => ({
  auth: state.auth,
  options: state.options.data,
  libraryTypes: state.library.types.list,
})

const mapDispatchToProps = {
  requestLibraryTypes: libraryTypesListRequestStart,
}

const IndexScene = ({ auth, requestLibraryTypes, libraryTypes, options }) => {
  useEffect(() => {
    requestLibraryTypes()
  }, [requestLibraryTypes])

  const findNumericValue = (item, name) => {
    const value = libraryTypes.find(el => el.id === item.id)
    return value ? value[name] : '0'
  }

  return (
    <div className={styles.container}>
      {!libraryTypes.length && <SpinnerOuter />}

      {auth.data && !!libraryTypes.length && (
        <>
          <Row>
            {libraryGridData.map((item, index) => (
              <IndexRowItem
                libraryTypes={libraryTypes}
                key={index}
                destination={item.destination}
                title={item.title}
                subtitle={item.subtitle}
                background={item.image}
                id={item.id}
                isExternal={item.isExternal}
                amount={findNumericValue(item, 'amount')}
                newAmount={findNumericValue(item, 'newamount')}
              />
            ))}
          </Row>
          <div className={styles.footer}>{options && options.GOU_Name.join('\n')}</div>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexScene)
