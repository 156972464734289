import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { testsCountRequestStart } from '../../redux/actions/tests/count'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import TestsTable from '../../components/Tests/Table'
import Pagination from '../../components/Pagination'
import SearchForm from '../../components/Tests/Form'
import { setTestsSearchData } from '../../redux/actions/tests/search'
import TestsPDFPrint from '../../components/Tests/PDFPrint'
import { api } from '../../App'
import { Button } from 'antd'

const mapStateToProps = ({ tests }) => ({
  count: tests.count,
  list: tests.list,
  loader: tests.loader,
  searchData: tests.search,
})

const mapDispatchToProps = {
  requestList: testsCountRequestStart,
  setSearchData: setTestsSearchData,
}

const TestsScene = ({ count, list, loader, searchData, requestList, setSearchData }) => {
  const [groups, setGroups] = useState([])
  const [allList, setAllList] = useState([])

  const handleOnSubmit = () => {
    requestList()

    const params = ({
      from: setSearchData.from,
      to: setSearchData.to,
      gid: setSearchData.gid,
      year: setSearchData.year,
    } = searchData)

    api.get('/tests', { params }).then(data => setAllList(data.data))
  }

  useEffect(() => {
    requestList()
  }, [requestList])

  useEffect(() => {
    api.get('/groupname').then(res => {
      setGroups(res.data)
    })
  }, [])

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  return (
    <SceneContainer title={'Прохождение электронного тестирования'}>
      <SearchForm data={searchData} setData={setSearchData} onSubmit={handleOnSubmit} groups={groups} />

      {/* <Button type='primary'>ЖУРНАЛ ИЗЪЯТЫХ</Button> */}
      <PaginationComponent />
      <TestsPDFPrint
        orientation={'landscape'}
        title={'Прохождение электронного тестирования'}
        currentPage={searchData.page}
        resultsPerPage={searchData.maxResults}
        data={allList}
        fromDate={searchData.from}
        toDate={searchData.to}
        year={searchData.year}
        groupName={groups.find(e => e.gid == searchData.gid)?.name}
      />
      {loader ? (
        <SpinnerOuter />
      ) : (
        <TestsTable data={list} currentPage={searchData.page} resultsPerPage={searchData.maxResults} />
      )}
      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TestsScene)
