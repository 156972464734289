import {QR_CODES_REQUEST, QR_CODES_SHELVES_REQUEST, SUCCESS} from "../../actions/action-types";

const initialState = [];

export const qrCodesReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${QR_CODES_REQUEST}${SUCCESS}`:
      return action.payload;
    case `${QR_CODES_SHELVES_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};