import { call, put, takeLatest, select } from 'redux-saga/effects'
import { fetchTestsCount, fetchTestsList } from '../../../api/Tests'
import { testsCountRequestError, testsCountRequestSuccess } from '../../actions/tests/count'
import { setTestsSearchData } from '../../actions/tests/search'
import { testsListRequestError, testsListRequestStart, testsListRequestSuccess } from '../../actions/tests/list'
import { START, TESTS_COUNT_REQUEST, TESTS_LIST_REQUEST } from '../../actions/action-types'

export default function* testsWatcher() {
  yield takeLatest(`${TESTS_COUNT_REQUEST}${START}`, countWorker)
  yield takeLatest(`${TESTS_LIST_REQUEST}${START}`, listWorker)
}

function* countWorker() {
  const searchData = yield select(({ tests }) => tests.search)

  try {
    const response = yield call(fetchTestsCount, searchData)
    const count = parseInt(response.data.count, 10)
    yield put(testsCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setTestsSearchData({ ...searchData, pageCount }))
    yield put(testsListRequestStart())
  } catch (error) {
    yield put(testsCountRequestError(error))
  }
}

function* listWorker() {
  const searchData = yield select(({ tests }) => tests.search)
  const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)

  try {
    const response = yield call(fetchTestsList, searchData, paginateFrom)
    yield put(testsListRequestSuccess(response.data))
  } catch (error) {
    yield put(testsListRequestError(error))
  }
}
