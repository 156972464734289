import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import DocumentBooksTable from '../Components/BooksTable'
import DocumentsEditBookForm from '../Components/EditBookForm'
import SpinnerOuter from '../../../../UtulityComponents/SpinnerOuter'
import Pagination from '../../../../Pagination'
import { invNumbersCountRequestStart } from '../../../../../redux/actions/invNumbers/count'
import InvNumbersItem from '../../../../InvNumbers/Modal/SearchItem'
import BookSearchForm from '../../../../Covers/SearchForm'
import { setInvNumbersFormData } from '../../../../../redux/actions/invNumbers/form'
import { setInvNumbersSearchData } from '../../../../../redux/actions/invNumbers/search'

const mapStateToProps = ({ invNumbers, documents, options }) => ({
  formData: invNumbers.form,
  searchResults: invNumbers.list,
  // table
  books: documents.books,
  // pagination
  loader: invNumbers.loader,
  loadSuccess: invNumbers.loadSuccess,
  searchData: invNumbers.search,
  count: invNumbers.count,
  optionsList: options.data,
})

const mapDispatchToProps = {
  startSearch: invNumbersCountRequestStart,
  setFormData: setInvNumbersFormData,
  setSearchData: setInvNumbersSearchData,
}

const InstancesFromDocuments = ({
  modalData,
  ControlsButton,
  NoResult,
  setCurrentBook,
  currentBook,
  documentType,
  numbers,
  books,
  onBookDelete,
  onBookSave,
  searchOpen,
  formData,
  searchResults,
  onSelectFromSearch,
  searchData,
  loader,
  startSearch,
  setFormData,
  setSearchData,
  count,
  loadSuccess,
  optionsList,
  fromLostDoc
}) => {
  
  const view = modalData.data.view

  const newSearchResults = searchResults.filter(function (i) {if (!numbers.includes(i.instanceid)) {return i}})

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    startSearch()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  useEffect(() => {
    // Шифр
    if (view === 1) {
      setFormData({ ...formData, typeId: 1, idclient: optionsList.own_client_id })
    } else if (view === 3 || view === 4) {
      setFormData({ ...formData, typeId: 11, idclient: optionsList.own_client_id })
    } else {
      setFormData({ ...formData, typeId: 0, idclient: optionsList.own_client_id })
    }
  }, [optionsList, view])

  return (
    <Row>
      <Col md={24}>
        <div id='current'>
          <DocumentBooksTable
            data={books}
            modalData={modalData}
            handleSelect={setCurrentBook}
            selectedId={currentBook ? currentBook.id : null}
            type={documentType}
          />
          {currentBook && (
            <DocumentsEditBookForm
              data={currentBook}
              modalData={modalData}
              onSave={onBookSave}
              onDelete={currentBook.id ? onBookDelete : () => setCurrentBook(null)}
              setData={setCurrentBook}
              type={documentType}
            />
          )}
        </div>

        <ControlsButton />

        {searchOpen && (
          <div>
            <BookSearchForm
              data={formData}
              setData={setFormData}
              modalData={modalData}
              disableAlphabet={true}
              onSubmit={startSearch}
              invNumbers={true}
              fromLostDoc={fromLostDoc}
            />

            <PaginationComponent />

            <SpinnerOuter isLoading={loader} />

            {!!searchResults.length && loadSuccess && (
              <>
                <div>Результаты поиска</div>
                {!!newSearchResults.length ? (
                  newSearchResults.map((item, index) => <InvNumbersItem data={item} key={index} onClick={onSelectFromSearch} />)
                ) : (
                  <NoResult />
                )}
              </>
            )}

            <PaginationComponent />
          </div>
        )}
      </Col>
    </Row>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InstancesFromDocuments)
