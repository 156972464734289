import React from 'react'
import { Draggable } from "react-beautiful-dnd";
import styles from '../MenuEditor.module.css';

export const Drag = ({ type, id, index, dropDown, ...props }) => {
  
  const getStyle = () => {
    if (id === dropDown) {
      return { width: '100%',  display: 'flex', alignItems: 'start' }
    } else {
      return { width: '100%',  display: 'flex', alignItems: 'center' }
    }
  }

  const getStyleIcon = () => {
    if (id === dropDown) {
      return { marginTop:'1.4rem' }
    } else {
      return { marginTop: '0' }
    }
  }
    
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div className={styles.wrapper}>
            <div style={{ width: '100%' }}>
              <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
                <div style={getStyle()} className="drag-handle" {...provided.dragHandleProps}>
                  <i className={type == 'second' ? styles.buttonDragSecond : styles.buttonDrag} style={getStyleIcon()}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m12 19.24-4.95-4.95-1.41 1.42L12 22.07l6.36-6.36-1.41-1.42L12 19.24zM5.64 8.29l1.41 1.42L12 4.76l4.95 4.95 1.41-1.42L12 1.93 5.64 8.29z"></path></svg>
                  </i>
                  <div style={{ width: '100%' }}>
                    {props.children}</div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};
