import { COVERS_ADVANCED_SEARCH_LIST_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const coversListAdvancedSearchRequestStart = (data) => ({
  type: `${COVERS_ADVANCED_SEARCH_LIST_REQUEST}${START}`,
  payload: data
});

export const coversListAdvancedSearchRequestSuccess = (data) => ({
  type: `${COVERS_ADVANCED_SEARCH_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const coversListAdvancedSearchRequestError = (data) => ({
  type: `${COVERS_ADVANCED_SEARCH_LIST_REQUEST}${ERROR}`,
  payload: data
});

