import { ERROR, START, SUCCESS, USERS_LIST_REQUEST } from "../action-types";

export const usersListRequestStart = () => ({
  type: `${USERS_LIST_REQUEST}${START}`
});

export const usersListRequestSuccess = (data) => ({
  type: `${USERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const usersListRequestError = (data) => ({
  type: `${USERS_LIST_REQUEST}${ERROR}`,
  payload: data
});