import {BOOK_CARD_DATA_REQUEST, ERROR, START, SUCCESS} from "../action-types";


export const bookCardDataRequestStart = (bookId) => ({
  type: `${BOOK_CARD_DATA_REQUEST}${START}`,
  payload: bookId
});

export const bookCardDataRequestSuccess = (response) => ({
  type: `${BOOK_CARD_DATA_REQUEST}${SUCCESS}`,
  payload: response
});

export const bookCardDataRequestError = (error) => ({
  type: `${BOOK_CARD_DATA_REQUEST}${ERROR}`,
  payload: error
});