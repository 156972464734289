export const BOOKSCAN_SORT = {
    TITLE: "title",
    YEAR: "year",
    PAGENUM: "pagenum",
    TIMESTAMP: "timestamp",
    DUEDATE: "duedate",
    ISBN: "isbn",
    UPLOADER: "uploader",
    STATUS: "status"
};
