import {
  BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST, DOCUMENT_BOOK_CLEANUP,
  INV_NUMBERS_COUNT_REQUEST,
  SET_LOCATION_CHANGE,
  SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const invNumbersCountReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${INV_NUMBERS_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    case `${BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    case SET_LOCATION_CHANGE:
      return initialState;

    case DOCUMENT_BOOK_CLEANUP:
      return initialState;

    default: return state;
  }
};