import { api } from "../../App";

export const libraryTypesList = () => {
  return api.get('/librarytype');
};

export const setLibraryTypeImage = (id, file) => {
  const formData = new FormData();
  formData.append("image", file);

  return api.post(`/librarytype/${id}/image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const deleteLibrarytypeImage = (id) => {
  return api.delete(`/librarytype/${id}/image`);
};

export const getLibrarytypeImage = (id) => {
  return api.get(`/librarytype/${id}/image`);
};

export const editLibraryType = (data) => {
  return api.put(`/librarytype/${data.id}`, data);
};

export const addLibraryType = (data) => {
  return api.post('/librarytype', data);
};

export const deleteLibraryType = (id) => {
  return api.delete(`/librarytype/${id}`);
};
