import { ERROR, START, SUCCESS, UDK_LIST_COLLAPSE, UDK_LIST_REQUEST } from "../action-types";

export const udkListRequestStart = (id, search, rewrite) => ({
  type: `${UDK_LIST_REQUEST}${START}`,
  payload: {
    id, search, rewrite
  }
});

export const udkListRequestSuccess = (data) => ({
  type: `${UDK_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const udkListRequestError = (data) => ({
  type: `${UDK_LIST_REQUEST}${ERROR}`,
  payload: data
});

export const udkListCollapse = (id) => ({
  type: UDK_LIST_COLLAPSE,
  payload: id
});

