import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import InputWrapper from '../../Modal/InputWrapper'
import styles from './AdminGroupsModal.module.css'
import Modal from '../../Modal'
import { ADD } from '../../../constants/modalTypes'

const AdminGroupsModal = ({ setModalVisible, modalVisible, addGroup, editGroup }) => {
  const [courseState, setCourseState] = useState(modalVisible === ADD ? 1 : modalVisible.year)
  const [groupNameState, setGroupNameState] = useState(modalVisible === ADD ? 'Не выбрано' : modalVisible.name)

  const onSubmit = () => {
    modalVisible === ADD
      ? addGroup({ year: courseState, name: groupNameState })
      : editGroup(modalVisible.gid, { year: courseState, name: groupNameState })
    setModalVisible(false)
  }

  return (
    <Modal
      title={modalVisible === ADD ? 'Группа: добавление' : 'Группа: редактирование'}
      isVisible={modalVisible}
      contentClassName={styles.modalContent}
    >
      <Tabs defaultActiveKey='index' id={'AdminRolesModalTabs'}>
        <Tab eventKey='index' title='Главная' className={styles.tabContent}>
          <Row>
            <Col md={12}>
              <InputWrapper
                label={'Название группы*'}
                name='name'
                onChange={({ target: { value } }) => setGroupNameState(value)}
                value={groupNameState}
              />
            </Col>
            <Col md={12}>
              <InputWrapper label={'Курс'}>
                <select value={courseState} name='verifyStatus' onChange={e => setCourseState(e.target.value)}>
                  <option value={courseState}>{courseState}</option>
                  {new Array(4).fill().map((item, index) => (
                    <option value={index + 2} key={index + 2}>
                      {index + 2}
                    </option>
                  ))}
                </select>
              </InputWrapper>
            </Col>

            <Col md={4}>
              <div className={styles.bottomSection}>
                <Button className='mr-2' type='button' onClick={onSubmit}>
                  Сохранить
                </Button>
                <Button variant='danger' type='button' onClick={() => setModalVisible(false)}>
                  Отмена
                </Button>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Modal>
  )
}

export default AdminGroupsModal
