import {ELECTRONIC_BOOK_JSON_SAVE, ELECTRONIC_BOOK_RESET, SUCCESS,ERROR} from "../../../actions/action-types";

const initialState = {};

export const ElectronicBookJsonImportReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${ELECTRONIC_BOOK_JSON_SAVE}${SUCCESS}`:
            return action.payload.ok[0];
        
        case `${ELECTRONIC_BOOK_JSON_SAVE}${ERROR}`:
            return action.payload;

        case `${ELECTRONIC_BOOK_RESET}`:
            return initialState;

        default: return state;
    }
};