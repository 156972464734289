import { DOCUMENT_BOOK_CLEANUP, SET_COVERS_SEARCH_DATA, SET_LOCATION_CHANGE } from '../../../actions/action-types'
import { RESULTS_PER_PAGE } from '../../../../constants/pagination'

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
}

export const coversSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COVERS_SEARCH_DATA:
      return action.payload

    case SET_LOCATION_CHANGE:
      return initialState

    case DOCUMENT_BOOK_CLEANUP:
      return initialState

    default:
      return state
  }
}
