import React, { useEffect, useRef, useState } from 'react'
import T from 'prop-types'
import DataTableCol from '../../DataTable/Column'
import DataTable from '../../DataTable'
import styles from './DocumentBooksTable.module.css'
import RolePermissionChecker from '../../UtulityComponents/RolePermissionChecker'
import { api } from '../../../App'
import { createNotification } from '../../notification/notification'
import pencil from '../../../img/icons/pencilwrite.png'

const InventoryBooksTable = ({ requestBooks, documentid, data, selectedId, handleSelect }) => {

  const [changeAmount, setChangeAmount] = useState(false)
  const [keyState, setKeyState] = useState({
    Tab: false,
    Enter: false,
    Shift: false
  })

  const inputRef = useRef()

  useEffect(() => {
    document.body.addEventListener('keydown', keyPress)
    document.body.addEventListener('keyup', keyPress)
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('keydown', keyPress)
      document.body.removeEventListener('keyup', keyPress)
      document.body.removeEventListener('click', handleOutsideClick);
    }
  }, [changeAmount])
  
  useEffect(() => {
    keyStateChange()
  }, [keyState])
  

  const handleOutsideClick = async (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (inputRef.current && !path.includes(inputRef.current)) {
      const {id, amount} = changeAmount
      await addBook(amount, id)
      setChangeAmount(false)
    }
  };

  const keyStateChange = async () => {
    if (keyState['Enter']) {
      const {id, amount} = changeAmount
      await addBook(amount, id)
      selectNextPrev()
    } else if (keyState['Shift'] && keyState['Tab']) {
      selectNextPrev(true)
    } else if (keyState['Tab']) {
      selectNextPrev()
    }
  }

  const selectNextPrev = (prev) => {
    let index = data.findIndex(it => it.id === changeAmount.id)
    const newIndex = prev ? --index : ++index
    const selectCancel = newIndex === data.length || newIndex === -1
    const newChangeAmountState = selectCancel ? false : data[newIndex]
    setChangeAmount(newChangeAmountState)
  }

  const addBook = async (amount, id) => {
    try {
      await api.put(`/Inventory_spcf/${id}`, { amount })
      await requestBooks(documentid)
      createNotification('success', 'Кол-во факт изменено')
    } catch {
      createNotification('error', 'Кол-во факт не удалось изменить')
    }
  }

  const keyPress = (e) => {
    
    if (!changeAmount) return;
    const navKey = Object.keys(keyState).includes(e.key)
    if (!navKey) return;
    
    e.preventDefault()
    e.stopPropagation()
    setKeyState(prev => ({...prev, [e.key]: !prev[e.key]}))
  }

  const inputChange = (e) => {
    const newChangeObject = {...changeAmount}
    newChangeObject.amount = e.target.value
    setChangeAmount(newChangeObject)
  }

  const getFieldSum = (field) => data.reduce((accum, item) => accum + (parseInt(item[field]) || 0), 0)

  const otherRows = [
    <tr>
      <td colSpan={2}>Итого</td>
      <td colSpan={2}></td>
      <td colSpan={2}></td>
      <td colSpan={2}></td>
      <td colSpan={2}>{getFieldSum('amount_plan')}</td>
      <td colSpan={2}>{getFieldSum('price_coeff')}</td>
      <td colSpan={2}></td>
    </tr>
  ]

  return (
    <div className={styles.container}>
      <DataTable data={data} otherRows={otherRows}>
        <DataTableCol rowWidth='60px' title='#' resolver={item => item.column_index} colSpan={1} />
        <DataTableCol title='Название' value='title' colSpan={3} />
        <DataTableCol title='Инвентарный номер' value='number' colSpan={2} />
        <DataTableCol title='Местоположение' value='place_name' colSpan={2} />
        <DataTableCol rowWidth='60px' title='Кол-во' value='amount_plan' colSpan={2} />
        <DataTableCol title='Цена издания с учетом коэффициентов' value='price_coeff' colSpan={2} />
        <DataTableCol
          title='Кол-во факт'
          resolver={item =>
            changeAmount.id === item.id ? (
              <>
                <input
                  style={{ width: '20px', textAlign: 'center', outline: 'none' }}
                  value={changeAmount.amount}
                  onChange={inputChange}
                  ref={inputRef}
                  onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
                  autoFocus
                />
                <img
                  style={{ width: '20px', marginLeft: '5px' }}
                  src={pencil}
                  alt='Ввести вручную'
                />
              </>
            ) : (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => setChangeAmount(item)}
              >
                <span>{item.amount}</span>
                <img
                  style={{ width: '20px', marginLeft: '15px' }}
                  src={pencil}
                  alt='Ввести вручную'
                />
              </div>
            )
          }
          colSpan={2}
        />
        <DataTableCol
          passClicks
          resolver={item => (
            <RolePermissionChecker permissionName={'Инвентаризация'}>
              <input type='checkbox' checked={item.id === selectedId} onChange={() => handleSelect(item)} />
            </RolePermissionChecker>
          )}
        />
      </DataTable>

    </div>
  )
}

InventoryBooksTable.propTypes = {
  data: T.array,
}

export default InventoryBooksTable
