import React from 'react';
import T from 'prop-types';
import Button from "../../Button";


const BorrowingTestControlsPanel = ({ openCreationModal }) => {
  return (
    <div>
      <Button label="добавить" onClick={openCreationModal} colorType="green" />
    </div>
  );
};

BorrowingTestControlsPanel.propTypes = {
  openCreationModal: T.func.isRequired,
};

export default BorrowingTestControlsPanel;