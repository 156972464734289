import jsPDF from 'jspdf'
import React from 'react'
import addFont from '../../../fonts/fonts'
import { Button } from 'antd'
import moment from 'moment'

export const InventoryActPdfPrint = ({
  clientData,
  sampleSearchFrom,
  sampleSearchTo,
  sample,
  users,
  selectedUser,
  libraries,
  selectedLibrary,
  libraryFunds,
  selectedFund,
  modalData,
}) => {
  const monthsList = [
    '',
    ' Январь',
    ' Февраль',
    ' Март',
    ' Апрель',
    ' Май',
    ' Июнь',
    ' Июль',
    ' Август',
    ' Сентябрь',
    ' Октябрь',
    ' Ноябрь',
    ' Декабрь',
  ]

  const {lastname, firstname, patronymic} = users?.find(e => e.mid === selectedUser) || {}

  const changeFormatDate = date => {
    const day = moment(date).format('DD')
    const month = moment(date).format('M')
    const year = moment(date).format('YYYY')
    return `${day} ${monthsList[month]} ${year} г.`
  }

  const orientation = 'portrait'

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
    doc.setFont('Montserrat')
    doc.setFont( undefined, 'normal')
    doc.setFontSize('7')
    doc.setTextColor('gray')


    doc.setTextColor('#000000')
    doc.setFontSize('8')
    doc.text(`(в ред. Приказа Минфина России от 17.11.2017 № 194н)`, 125, 13)

    doc.setFontSize('11')
    doc.text(`Утверждаю`, 150, 20)

    doc.setFontSize('9')
    doc.text(
      `Руководитель
учреждения
`,
      120,
      26
    )

    doc.line(148, 27, 164, 27)
    doc.setFontSize('7')
    doc.text(`(подпись)`, 150, 29)

    doc.line(168, 27, 191, 27)
    doc.setFontSize('7')
    doc.text(`(расшифровка)`, 170, 29)

    doc.setFontSize('9')
    doc.text(`${changeFormatDate(modalData.date_form)}`, 137, 36)
    doc.line(136, 37, 166, 37)

    doc.setFont(undefined, 'bold')
    doc.setFontSize('12')
    doc.text(`АКТ №    ${modalData.name}`, 97, 44)
    doc.line(113, 45, 127, 45)
    doc.text(`о результатах инвентаризации`, 75, 49)
    
    doc.setFontSize('8')
    doc.setFont(undefined, 'normal')
    doc.text(`Форма по ОКУД`, 154, 56)
    doc.text(`Дата`, 171, 62)
    doc.text(`по ОКПО`, 165, 68)

    doc.line(180, 45, 205, 45)
    doc.setFontSize('8')
    doc.text(`Коды`, 188, 49)
    doc.line(180, 51, 205, 51)
    doc.text(``, 186, 56)
    doc.line(180, 57, 205, 57)
    doc.text(`${moment(modalData.date_form).format('DD.MM.YYYY')}`, 185, 62)
    doc.line(180, 63, 205, 63)
    doc.text(``, 187, 68)
    doc.line(180, 69, 205, 69)
    doc.line(180, 75, 205, 75)
    doc.line(180, 45, 180, 75)
    doc.line(205, 45, 205, 75)

    doc.text(`от ${changeFormatDate(modalData.date_form)}`, 95, 62)
    doc.line(95, 63, 125, 63)
    doc.text(`Учреждение:        ${clientData ? clientData.GOU_Name[1] : ''}`, 5, 68)
    doc.line(5, 69, 140, 69)
    doc.text(`Ответственное(-ые) лицо(-а):       ${lastname || ''} ${ firstname ? firstname[0].toUpperCase() : ''}. ${patronymic ? patronymic[0].toUpperCase() : ''}.`, 5, 74)
    doc.line(5, 75, 170, 75)
    doc.text(`Комиссия в составе`, 5, 81)
    doc.line(5, 82, 205, 82)
    doc.text(`(должность, фамилия, инициалы),`, 90, 84)

    doc.text(``, 5, 90)
    doc.line(5, 91, 205, 91)
    doc.line(5, 96, 205, 96)
    doc.line(5, 101, 205, 101)
    doc.line(5, 106, 205, 106)
    doc.line(5, 111, 205, 111)

    doc.text(
      `назначенная приказом (распоряжение)      от    ${changeFormatDate(modalData.date_form)} № 555, составила настоящий акт`,
      5,
      116
    )
    doc.line(5, 117, 205, 117)

    doc.text(
      `в том, что за период           с       ${changeFormatDate(modalData.datestart)}           по            ${changeFormatDate(
        modalData.dateend
      )}`,
      5,
      122
    )
    doc.line(5, 123, 205, 123)

    doc.text(`была проведена инвентаризация`, 5, 128)
    doc.line(5, 129, 205, 129)
    doc.text(` (нефинансовые активы, финансовые активы, расчеты)`, 85, 131)

    doc.text(`находящихся на ответственном хранении у`, 5, 137)
    doc.line(80, 141, 205, 141)
    doc.text(`(должность, фамилия, имя, отчество ответственного(-ых) лица(-ц))`, 85, 143)

    doc.text(
      `На основании инвентаризационных описей (сличительных ведомостей) №	111	     от     ${changeFormatDate(modalData.date_form)}`,
      5,
      148
    )
    doc.line(5, 149, 205, 149)

    doc.text(
      `№               от  "        "                                   20         г.,        №             от "         "                         20          г.,`,
      5,
      154
    )
    doc.line(5, 155, 205, 155)

    doc.text(
      `№               от  "        "                                   20         г.,        №             от "         "                         20          г.,`,
      5,
      160
    )
    doc.line(5, 161, 205, 161)

    doc.text(`установлено следующее:`, 5, 166)
    doc.line(5, 167, 205, 167)
    doc.line(5, 172, 205, 172)
    doc.line(5, 177, 205, 177)
    doc.line(5, 182, 205, 182)
    doc.line(5, 187, 205, 187)
    doc.line(5, 192, 205, 192)
    doc.line(5, 197, 205, 197)

    doc.text(
      `Ведомость расхождений по рузультатам инвентаризации № 111     от     ${changeFormatDate(
        modalData.date_form
      )} на  «   »  листах прилагается
(при наличии расхождений).`,
      5,
      203
    )
    doc.line(5, 204, 205, 204)
    doc.line(5, 207, 50, 207)

    doc.text(
      `Председатель
  комиссии
`,
      5,
      214
    )

    doc.text(``, 40, 212)
    doc.line(38, 216, 63, 216)
    doc.text('(должность)', 42, 218)

    doc.text('', 70, 215)
    doc.line(68, 216, 86, 216)
    doc.text('(подпись)', 70, 218)

    doc.text('', 102, 215)
    doc.line(90, 216, 130, 216)
    doc.text('(расшифровка подписи)', 92, 218)

    doc.text(`Члены комиссии:`, 5, 228)

    doc.text(``, 40, 228)
    doc.line(38, 229, 70, 229)
    doc.text('(должность)', 45, 231)

    doc.text('', 80, 228)
    doc.line(78, 229, 100, 229)
    doc.text('(подпись)', 82, 231)

    doc.text('', 110, 228)
    doc.line(104, 229, 142, 229)
    doc.text('(расшифровка подписи)', 105, 231)

    doc.text(``, 51, 243)
    doc.line(36, 244, 80, 244)
    doc.text('(должность)', 50, 246)

    doc.text('', 90, 243)
    doc.line(88, 244, 110, 244)
    doc.text('(подпись)', 90, 246)

    doc.text('', 125, 243)
    doc.line(116, 244, 154, 244)
    doc.text('(расшифровка подписи)', 117, 246)

    doc.text(``, 38, 256)
    doc.line(36, 257, 80, 257)
    doc.text('(должность)', 50, 259)

    doc.text('', 90, 256)
    doc.line(88, 257, 108, 257)
    doc.text('(подпись)', 90, 259)

    doc.text('', 125, 256)
    doc.line(116, 257, 154, 257)
    doc.text('(расшифровка подписи)', 117, 259)

    doc.line(36, 270, 80, 270)
    doc.text('(должность)', 50, 272)

    doc.line(88, 270, 108, 270)
    doc.text('(подпись)', 90, 272)

    doc.line(116, 270, 154, 270)
    doc.text('(расшифровка подписи)', 117, 272)

    doc.text(`${changeFormatDate(modalData.date_form)}`, 5, 280)
    doc.line(4, 281, 30, 281)

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getNumberOfPages())

    return doc
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return (
    <Button onClick={toPrint} type='primary' className='ml-2'>
      Акт инвентаризации
    </Button>
  )
}
