import {ERROR, START, SUCCESS, TRANSFER_LOG_BOOKS_REQUEST} from "../../action-types";


export const transferLogBooksRequestStart = (id) => ({
    type: `${TRANSFER_LOG_BOOKS_REQUEST}${START}`,
    payload: id
});

export const transferLogBooksRequestSuccess = (response) => ({
    type: `${TRANSFER_LOG_BOOKS_REQUEST}${SUCCESS}`,
    payload: response
});

export const transferLogBooksRequestError = (error) => ({
    type: `${TRANSFER_LOG_BOOKS_REQUEST}${ERROR}`,
    payload: error
});
