import { COVERS_ADVANCED_SEARCH_PARAMS_REQUEST, SUCCESS } from "../../../actions/action-types";


const initialState = null;

export const advancedSearchParamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${COVERS_ADVANCED_SEARCH_PARAMS_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};