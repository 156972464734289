import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import { ROLE_SAVE_REQUEST, START } from "../../../actions/action-types";
import { ADD } from "../../../../constants/modalTypes";
import { addRole, editRole } from "../../../../api/roles/roles";
import {
  roleSaveRequestError,
  roleSaveRequestSuccess
} from "../../../actions/roles/save";
import { rolesListRequestStart } from "../../../actions/roles/list";
import { memberRoleAction } from "../../../../api/roles/roles";

export default function* roleSaveWatcher() {
  yield takeLatest(`${ROLE_SAVE_REQUEST}${START}`, roleSaveWorker);
}

function* roleSaveWorker({ payload }) {
  const { modalType: type, data } = payload;

  function* saveNewMembersLoop(roleId, newMembers) {
    for (let member of newMembers) {
      yield call(memberRoleAction, {
        roleId,
        memberId: member.id,
        actionType: "put"
      });
    }
  }

  try {
    const response = yield call(type === ADD ? addRole : editRole, data);
    yield put(roleSaveRequestSuccess(response.data));

    const roleId = response.data.pmid;
    const newMembers = yield select(({ roles }) => roles.newMembers);

    yield saveNewMembersLoop(roleId, newMembers);

    yield put(rolesListRequestStart());
  } catch (error) {
    yield put(roleSaveRequestError(error));
  }
}
