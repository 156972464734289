import { combineReducers } from "redux";
import { newBooksOrderBooksReducer } from './books/newBooksOrderBooksReducer';
import { newBooksOrderCountReducer } from './count/newBooksOrderCountReducer';
import { newBooksOrderLatestReducer } from './latest/newBooksOrderLatestReducer';
import { newBooksOrderListReducer } from  './list/newBooksOrderListReducer';
import { newBooksOrderSearchReducer } from './search/newBooksOrderSearchReducer';

export const newBooksOrderReducer = combineReducers({
    books: newBooksOrderBooksReducer,
    count: newBooksOrderCountReducer,
    latest: newBooksOrderLatestReducer,
    list: newBooksOrderListReducer,
    search: newBooksOrderSearchReducer,
});
