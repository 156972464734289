import { PERMISSIONS_BY_LITER_EDIT_REQUEST, ERROR, START, SUCCESS } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const permissionsByLiterEditRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_LITER_EDIT_REQUEST}${START}`,
  payload: data
});

export const permissionsByLiterEditRequestSuccess = (data) => {
  createNotification('success', 'Доступ к литерам успешно отредактирован');
  return {
    type: `${PERMISSIONS_BY_LITER_EDIT_REQUEST}${SUCCESS}`,
    payload: data
  }
};

export const permissionsByLiterEditRequestError = (data) => {
  createNotification('error', 'Не удалось отредактировать доступ к литерам');
  return {
    type: `${PERMISSIONS_BY_LITER_EDIT_REQUEST}${ERROR}`,
    payload: data
  }
};
