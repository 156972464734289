import React, { useContext } from 'react'
import { Button } from 'antd'
import { createActDoc } from './actDoc'
import { createContractDoc } from './contractDoc'
import { createNoteDoc } from './noteDoc'
import { MobileLibContext } from '../../../../utils/context'
import { useSelector } from 'react-redux'
import styles from '../styles.module.css'

export default function PDFPrint () {
  
  const { libId, data, editMode } = useContext(MobileLibContext)
  const { documents } = useSelector(state => state.mobileLibrary)
  const { data: options } = useSelector(state => state.options)

  const lastSession = documents?.length ? documents[documents.length - 1] : {}
  const { book_info: bookInfo } = lastSession?.write_off_info?.length ? lastSession?.write_off_info[0] : {}
  
  const {GOU_Name, address} = options || {}

  const libraryNameAddress = (GOU_Name && address) ? `${GOU_Name[1] || ''} - ${address[0] || ''}` : ''
  const libraryShortName = GOU_Name ? GOU_Name[1] : ''

  const toPrint = (callback, title) => () => {
    callback({libraryNameAddress, libraryShortName})
      .then(doc => {
        doc.autoPrint()
        doc.output('dataurlnewwindow')
      })
      // .then(doc => doc.save(`${title}.pdf`))
  }
  

  const renderActDocRow = (bookInfo, index) => {
    const { amount, book, coefficient, price } = bookInfo
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{book}</td>
        <td></td>
        <td>{amount}</td>
        <td>{price}</td>
        <td>{coefficient}</td>
        <td></td>
      </tr>
    )
  }
  
  const actDocTable = (
    <table hidden id='actDocTable'>
      <thead>
        <tr>
          <th rowSpan={2}>№</th>
          <th rowSpan={2}>Автор, заглавие</th>
          <th rowSpan={2}>Место и год издания</th>
          <th rowSpan={2}>Количество экз.</th>
          <th colSpan={2}>Цена</th>
          <th rowSpan={2}>Коэффициент переоценки</th>
          <th rowSpan={2}>Стоимость</th>
        </tr>
        <tr>
          <th>руб.</th>
          <th>коп.</th>
        </tr>
      </thead>
      <tbody>
        {bookInfo?.map(renderActDocRow)}
      </tbody>
    </table>
  )

  const renderNoteDocRow = (bookInfo, index) => {
    const { amount, book, book_id, coefficient, price } = bookInfo
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{book_id}</td>
        <td>{book}</td>
        <td></td>
        <td>{price}</td>
        <td></td>
        <td>{coefficient}</td>
        <td></td>
      </tr>
    )
  }

  const noteDocTable = (
    <table hidden id='noteDocTable'>
      <thead>
        <tr>
          <th>Число и месяц</th>
          <th>Инвентарный номер</th>
          <th>Автор и заглавие</th>
          <th>Отдел</th>
          <th>Цена</th>
          <th>Срок возврата</th>
          <th>Расписка в получении</th>
          <th>Расписка в приеме</th>
        </tr>
      </thead>
      <tbody>
        {bookInfo?.map(renderNoteDocRow)}
      </tbody>
    </table>
  )

  return (
    <div className={styles.buttonsBlock}>
      <Button type='primary' danger onClick={toPrint(createNoteDoc, 'Печатная форма тетради')}>Печать тетради</Button>
      <Button type='primary' danger onClick={toPrint(createContractDoc, 'Типовой договор')}>Печать типового договора</Button>
      <Button type='primary' danger onClick={toPrint(createActDoc, 'Акт приема-передачи книг')}>Печать акта приема-передачи</Button>
      {actDocTable}
      {noteDocTable}
    </div>
  )
}
