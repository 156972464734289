import { ERROR, OPTIONS_LOGO_SAVE_REQUEST, START, SUCCESS } from "../../action-types";

export const optionsLogoSaveRequestStart = (data) => ({
  type: `${OPTIONS_LOGO_SAVE_REQUEST}${START}`,
  payload: data
});

export const optionsLogoSaveRequestSuccess = (data) => ({
  type: `${OPTIONS_LOGO_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const optionsLogoSaveRequestError = (data) => ({
  type: `${OPTIONS_LOGO_SAVE_REQUEST}${ERROR}`,
  payload: data
});