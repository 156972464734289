import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import styles from '../DocumentsModal.module.css'
import { ADD, EDIT } from '../../../../constants/modalTypes'
import { useFormik } from 'formik'
import T from 'prop-types'
import * as Yup from 'yup'
import { Button } from 'antd'
import RolePermissionChecker from '../../../UtulityComponents/RolePermissionChecker'
import { api } from '../../../../App'
import SearchableSelect from '../../../Select'
import { DiscrepancySheetPdfPrint } from '../../PDFPrint/InventoryDiscrepancySheetPdfPrint'
import { InventoryActPdfPrint } from '../../PDFPrint/InventoryActPdfPrint'
import Select from '../../../Select'
import { responsibleToOptions } from '../../../../utils/toSelectOption'
import CreateNewLibraryItemModal from '../submodals/CreateNewLibraryItemModal/CreateNewLibraryItemModal'
import { bookDataRequestStart as requestBookData } from '../../../../redux/actions/bookData/data'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

const MODAL_SAVE_TYPES = {
  EDIT: 'Сохранить документ',
  ADD: 'Создать документ',
}

const defaultValidationSchema = Yup.object().shape({
  name: Yup.string().required('Это поле необходимо заполнить'),
  date_form: Yup.date().required('Выберите дату документа'),
  datestart: Yup.date().required('Выберите дату начала'),
  dateend: Yup.date().required('Выберите дату завершения').min(Yup.ref('datestart'),
  "Дата завершения должна быть позже даты начала"),
})

const ModalHeader = ({
  list,
  modalData,
  setModalData,
  modalType,
  saveDocument,
  setFormValidity,
  getBooksList,
  libraries = [],
  clientData,
  libraryFunds = [],
  requestLibraryFunds,
  requestInvList,
  users = [],
}) => {
  
  const dispatch = useDispatch()
  const modalIdRef = useRef()  
  const bookData = useSelector(state => state.bookData)

  const [allModals, setAllModals] = useState()
  const [selectedLibrary, setSelectedLibrary] = useState(modalData.library)
  const [selectedFund, setSelectedFund] = useState(modalData.fund)
  const [selectedUser, setSelectedUser] = useState(modalData.responsible || users[0]?.mid)
  const [sample, setSample] = useState(modalData.volume === 2 ? 'UDK' : modalData.volume === 3 ? 'BBK' : 'full')
  const [sampleSearchFrom, setSampleSearchFrom] = useState(modalData?.volume_from ? JSON.parse(modalData?.volume_from) : undefined)
  const [sampleSearchTo, setSampleSearchTo] = useState(modalData?.volume_to ? JSON.parse(modalData?.volume_to): undefined)
  const [sampleSearchSelectData, setSampleSearchSelectData] = useState([])
  const [status, setStatus] = useState(modalData.status ? modalData.status : 1)
  const [showLibraryItemModal, setShowLibraryItemModal] = useState(false)

  useEffect(() => {
    selectedLibrary && requestLibraryFunds(selectedLibrary)
  }, [selectedLibrary])

  useEffect(() => {
    serachSample('');
  }, [])

  useEffect(() => {
    api.get('/inventory').then(data => setAllModals(data.data))
  }, [modalIdRef.current])

  useEffect(() => {
    if (modalType === ADD && modalIdRef.current) {
      setModalData({
        modalType: EDIT,
        data: allModals.find(e => e.name === modalIdRef.current),
      })
    }
  }, [allModals])

  const requestStatusInventory = async () => {
    await api.post(`/inventory/${modalData.id}/status`, { status: status + 1 }).then(response => setStatus(response.data.status))
    requestInvList()
    getBooksList()
  }

  const onCreateNewBook = () => {
    const params = {
      title: '',
      publisher: '',
      year: moment().year(),
    }

    api.post('/book', params).then(res => {
      dispatch(requestBookData(res.data.book.id))
      setShowLibraryItemModal(true)
    })
  }

  const setSearchOptions = ({data}) => setSampleSearchSelectData(data.map(({ id, udk, bbk, svni}) => ({ label: udk || bbk || svni, value: id })))

  const serachSample = value => {
      const params = { search: value || '', maxResults: 100 }
      switch (sample) {
        case 'UDK': return api
        .get('/udk/0', { params })
        .then(setSearchOptions)
        case 'BBK': return api
        .get('/bbk/0', { params })
        .then(setSearchOptions)
        case 'SVNI': return api
        .get('/svni/0', { params })
        .then(setSearchOptions)
        default: return;
      }
  } 

  if (libraries[0] && !selectedLibrary) setSelectedLibrary(libraries[0].id)
  if (libraryFunds[0] && !selectedFund) setSelectedFund(libraryFunds[0].id)
  
  const usersSelect = useMemo(
    () => (
      [...responsibleToOptions(users)]
    ),
    [users, selectedUser]
  )
  
  const resolveDate = date => moment(date).format('YYYY-MM-DD')
  

  const modalForm = useFormik({
    initialValues: {
      ...modalData,
      datestart: resolveDate(modalData.datestart),
      dateend: resolveDate(modalData.dateend),
      date_form: resolveDate(modalData.date_form)
    },
    validationSchema: defaultValidationSchema,
    onSubmit: values => {

      const volume_from = sampleSearchFrom || null
      const volume_to = sampleSearchTo || null
      if (modalType === ADD) {
        modalIdRef.current = values.name
        saveDocument({
          ...values,
          library: selectedLibrary,
          fund: selectedFund,
          responsible: selectedUser,
          volume: sample === 'full' ? 1 : sample === 'UDK' ? 2 : 3,
          volume_from,
          volume_to
        })
        
      } else if (modalType === EDIT) {
        modalIdRef.current = values.name
        const id = values.id || list.find(item => item.name === values.name).id
        saveDocument({
          ...values,
          id,
          library: selectedLibrary,
          fund: selectedFund,
          responsible: selectedUser,
          volume: sample === 'full' ? 1 : sample === 'UDK' ? 2 : 3,
          volume_from,
          volume_to
        })
      } else saveDocument(values)
    },
  })

  

  const validityCheck = () => {
    modalForm.validateForm().then(response => {
      modalType === ADD || !!Object.entries(response).length ? setFormValidity(false) : setFormValidity(true)
    })
  }

  // const resolveDate = date => new Date(date).toLocaleString('ru').split(',')[0].split('.').reverse().join('-')

  // useEffect(() => {
  //   if (modalForm.dirty) validityCheck()
  // }, [modalForm.values])

  const { values, errors } = modalForm


  return (
    <div>
      {showLibraryItemModal && (
        <CreateNewLibraryItemModal
          modalData={modalData}
          showLibraryItemModal={showLibraryItemModal}
          setShowLibraryItemModal={setShowLibraryItemModal}
          bookData={bookData}
        />
      )}
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            disabled={status === 2}
            label='Имя документа*'
            name='name'
            value={values['name']}
            onChange={modalForm.handleChange}
            error={errors['name']}
          />
          <ModalInputWrapper
            disabled={status === 2}
            label='Дата документа*'
            type='date'
            name='date_form'
            value={values['date_form']}
            onChange={modalForm.handleChange}
            error={errors['date_form']}
          />
          <ModalInputWrapper
            disabled={status === 2}
            label='Дата начала*'
            type='date'
            name='datestart'
            value={values['datestart']}
            onChange={modalForm.handleChange}
            error={errors['datestart']}
          />
          <ModalInputWrapper
            disabled={status === 2}
            label='Дата завершения*'
            type='date'
            name='dateend'
            value={values['dateend']}
            onChange={modalForm.handleChange}
            error={errors['dateend']}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper label='Библиотека'>
            <select name='' value={selectedLibrary} onChange={e => setSelectedLibrary(e.target.value)} disabled={status === 2}>
              {libraries.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Фонд'>
            <select name='' value={selectedFund} onChange={e => setSelectedFund(e.target.value)} disabled={status === 2}>
              {libraryFunds.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Ответственный'>
          <Select
              defaultValue={usersSelect[0]}
              placeholder={''}
              value={usersSelect?.find(el => el.value == selectedUser)}
              disabled={status === 2}
              onChange={value => setSelectedUser(value.value)}
              options={usersSelect}
            />
            </ModalInputWrapper>
          <div style={{ display: 'flex', alignItems: 'center', color: '#336fb9' }}>
            <input
              type='radio'
              onClick={() => setSample('full')}
              checked={sample === 'full'}
              style={{ marginRight: '5px' }}
              disabled={status === 2}
            />
            <span>Полная выборка</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', color: '#336fb9', marginBottom: '10px' }}>
            <input
              type='radio'
              onClick={() => setSample('UDK')}
              checked={sample !== 'full'}
              style={{ marginRight: '5px' }}
              disabled={status === 2}
            />
            <span>Сверка по отделам</span>
          </div>
          {sample !== 'full' && (
            <div style={{ paddingLeft: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', color: '#336fb9' }}>
                <input
                  type='radio'
                  onClick={() => setSample('UDK')}
                  checked={sample === 'UDK'}
                  style={{ marginRight: '5px' }}
                  disabled={status === 2}
                />
                <span style={{ marginRight: '10px' }}>УДК</span>
                <input
                  type='radio'
                  onClick={() => setSample('BBK')}
                  checked={sample === 'BBK'}
                  style={{ marginRight: '5px' }}
                  disabled={status === 2}
                />
                <span>ББК</span>
              </div>
              <Row>
                <Col md={6}>
                  <ModalInputWrapper label='с' disabled={status === 2}>
                    <SearchableSelect
                      // selectType={'FROM'}
                      // data={data}
                      placeholder='Поиск'
                      // setData={setSampleSearchFrom}
                      options={sampleSearchSelectData}
                      // isMulti
                      onChange={setSampleSearchFrom}
                      value={sampleSearchFrom}
                      onInputChange={value => serachSample(value, 'from')}
                    />
                     </ModalInputWrapper>
                </Col>
                <Col md={6}>
                  <ModalInputWrapper disabled={status === 2} label='по'>
                    <SearchableSelect
                      // selectType={'TO'}
                      // data={data}
                      placeholder='Поиск'
                      // setData={setSampleSearchTo}
                      options={sampleSearchSelectData}
                      // isMulti
                      onChange={setSampleSearchTo}
                      value={sampleSearchTo}
                      onInputChange={value => serachSample(value, 'from')}
                    />
                  </ModalInputWrapper>
                </Col>
              </Row>
            </div>
          )}
        </Col>
        {modalType === EDIT && (
          <Col md={12}>
            <div className={styles.bottomSection}>
              {status === 3 ? (
                <>
                  <DiscrepancySheetPdfPrint
                    clientData={clientData}
                    sampleSearchFrom={sampleSearchFrom}
                    sampleSearchTo={sampleSearchTo}
                    sample={sample}
                    users={users}
                    selectedUser={selectedUser}
                    libraries={libraries}
                    selectedLibrary={selectedLibrary}
                    libraryFunds={libraryFunds}
                    selectedFund={selectedFund}
                    modalData={modalData}
                  />
                  <InventoryActPdfPrint
                    clientData={clientData}
                    sampleSearchFrom={sampleSearchFrom}
                    sampleSearchTo={sampleSearchTo}
                    sample={sample}
                    users={users}
                    selectedUser={selectedUser}
                    libraries={libraries}
                    selectedLibrary={selectedLibrary}
                    libraryFunds={libraryFunds}
                    selectedFund={selectedFund}
                    modalData={modalData}
                  />
                </>
              ) : (
                <Button type='primary' onClick={requestStatusInventory}>
                  {status === 2 ? 'Завершить инвентаризацию' : 'Начать инвентаризацию'}
                </Button>
              )}
            </div>
          </Col>
        )}
        <Col md={12}>
          <div className={styles.bottomSection}>
            {status === 2 && <Button onClick={onCreateNewBook} type={'primary'}>Добавить новую БЗ</Button>}
            <RolePermissionChecker permissionName={'Режимы,Инвентаризация'}>
              <Button type={'primary'} onClick={modalForm.handleSubmit}>
                {MODAL_SAVE_TYPES[modalType]}
              </Button>
            </RolePermissionChecker>
          </div>
        </Col>
      </Row>
    </div>
  )
}

ModalHeader.propTypes = {
  modalData: T.object.isRequired,
}

export default ModalHeader
