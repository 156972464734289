import { combineReducers } from "redux";
import { fundAccountingListReducer } from "./list/fundAccountingListReducer";
import { fundAccountingLoaderReducer } from './loader/fundAccountingLoaderReducer';
import {fundAccountingSearchReducer} from "./search/fundAccountingSearchReducer";

export const fundAccountingReducer = combineReducers({
  list: fundAccountingListReducer,
  loader: fundAccountingLoaderReducer,
  search: fundAccountingSearchReducer
});
