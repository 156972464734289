import { MEDIA_RESOURCE_ADD_LOGO_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const mediaResourceAddLogoRequestStart = ({ resourceId, logo }) => ({
  type: `${MEDIA_RESOURCE_ADD_LOGO_REQUEST}${START}`,
  payload: { resourceId, logo }
});

export const mediaResourceAddLogoRequestSuccess = (response) => ({
  type: `${MEDIA_RESOURCE_ADD_LOGO_REQUEST}${SUCCESS}`,
  payload: response
});

export const mediaResourceAddLogoRequestError = (error) => ({
  type: `${MEDIA_RESOURCE_ADD_LOGO_REQUEST}${ERROR}`,
  payload: error
});
