import { call, put, takeLatest, select } from "redux-saga/effects";
import { LIBRARY_LIST_REQUEST, SET_LIBRARY_PAGE_COUNT, START } from "../../../../actions/action-types";
import { libraryList, libraryCount } from "../../../../../api/library/library";
import { libraryListRequestError, libraryListRequestSuccess } from "../../../../actions/library/info/list";
import { RESULTS_PER_PAGE } from "../../../../../constants/pagination";

export default function* libraryListWatcher() {
  yield takeLatest(`${LIBRARY_LIST_REQUEST}${START}`, libraryListWorker)
}

function* libraryListWorker({ payload: idclient }) {
  const searchData = yield select(({library}) => library.info.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(libraryList, {...searchData, idclient});
    yield put(libraryListRequestSuccess(response.data));
    const countResponse = yield call(libraryCount)
    const pageCount = Math.ceil(parseInt(countResponse.data.count)/RESULTS_PER_PAGE)
    yield put({type: SET_LIBRARY_PAGE_COUNT, payload: pageCount})
  } catch (error) {
    yield put(libraryListRequestError(error));
  }
}