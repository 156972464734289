import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../../ModalWindow";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from "../../../BookEdit/Tabs/IndexTab/BookEditIndexTab.module.css";
import {verifyStatusList} from "../../../../constants/bookVerifyStatus";
import {rightOfUseList} from "../../../../constants/bookRightOfUse";
import Button from "../../../Button";


const BookBatchEditingModal = ({ isOpen, setClosed, outsecrecyList, updateChangesOptions, onSubmit }) => {

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    updateChangesOptions(name, value)
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onRequestClose={setClosed}
      title={'Пакетное редактирование книг'}
      width={'550px'}
      height={'400px'}
    >
      <div>
        <ModalInputWrapper label="Гриф">

          <select
            name={'eerOutSecrecyId'}
            onChange={onChange}
            className={styles.select}
          >
            <option value={""} selected disabled>-- выберите значение из списка --</option>
            {outsecrecyList.map((item, index) =>
              <option key={index} value={item.id}>{item.name}</option>
            )}
          </select>
        </ModalInputWrapper>

        <ModalInputWrapper label={"Статус"}>
          <select
            className={styles.select}
            name="verifyStatus"
            onChange={onChange}
          >
            <option value={""} selected disabled>-- выберите значение из списка --</option>
            {verifyStatusList.map((item, index) => (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </ModalInputWrapper>

        <ModalInputWrapper
          label={"Право использования"}
        >
          <select
            className={styles.select}
            name="rightofuse"
            onChange={onChange}
          >
            <option value={""} selected disabled>-- выберите значение из списка --</option>
            {rightOfUseList.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </ModalInputWrapper>

        <div>
          <Button label={'Отправить'} onClick={onSubmit}/>
        </div>
      </div>
    </ModalWindow>
  );
};

BookBatchEditingModal.propTypes = {
  isOpen: PropTypes.bool,
  outsecrecyList: PropTypes.array.isRequired
};

export default BookBatchEditingModal;