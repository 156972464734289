import { NEW_BOOKS_ORDER_DELETE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const documentDeleteRequestStart = (data) => ({
    type: `${NEW_BOOKS_ORDER_DELETE_REQUEST}${START}`,
    payload: data
});

export const documentDeleteRequestSuccess = (data) => {
    createNotification('success', 'Документ успешно удален');
    return {
        type: `${NEW_BOOKS_ORDER_DELETE_REQUEST}${SUCCESS}`,
        payload: data
    };
};

export const documentDeleteRequestError = (data) => {
    createNotification('error', 'Не удалось удалить документ');
    return {
        type: `${NEW_BOOKS_ORDER_DELETE_REQUEST}${ERROR}`,
        payload: data
    }
};
