import {ERROR, INVENTORY_COUNT_REQUEST, INVENTORY_LIST_REQUEST, START, SUCCESS} from "../../../actions/action-types";

const initialState = false;

export const inventoryLoaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${INVENTORY_COUNT_REQUEST}${START}`:
            return true;

        case `${INVENTORY_COUNT_REQUEST}${ERROR}`:
            return false;

        case `${INVENTORY_LIST_REQUEST}${SUCCESS}`:
            return false;

        case `${INVENTORY_LIST_REQUEST}${ERROR}`:
            return false;

        default:
            return state;
    }
};
