import { api } from "../../App";

export const getMilitaryRank = () => api.get("/military_rank");


export const priceCoeff = {
  
  get: () => api.get("/document/coeff/price"),

  post: (data) => api.post("/document/coeff/price", data)

}