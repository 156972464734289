import React, { useState, useEffect } from "react";
import T from "prop-types";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import styles from "./InventaryBookSearchForm.module.css";
import { api } from "../../../App";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const InventaryBookSearchForm = ({
  data,
  setSearchData,
  startSearch,
  librariesList,
}) => {
  const [fundList, setFundList] = useState([]);



  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setSearchData(values);
      startSearch();
    },
  });

  const { values, errors } = modalForm;

  useEffect(() => {
    // Шифр
    if (values.in_library_id == 0) return setFundList([]);
    api
      .get(`/library/${values.in_library_id}/fund`)
      .then((res) => {
        setFundList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [values.in_library_id]);

  return (
    <div className={styles.container}>
      <Row>
        <Col md={3}>
          <ModalInputWrapper
            name="from"
            onChange={modalForm.handleChange}
            type={"date"}
            value={values["from"]}
            error={errors["from"]}
            label={"С"}
          />
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            name="to"
            onChange={modalForm.handleChange}
            value={values["to"]}
            error={errors["to"]}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={"Библиотека"}>
            <select
              name="in_library_id"
              onChange={modalForm.handleChange}
              value={values["in_library_id"]}
              className={styles.select}
            >
              <option value="0">Все</option>
              {librariesList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={"Фонд"}>
            <select
              name="fund"
              onChange={modalForm.handleChange}
              value={values["fund"]}
              className={styles.select}
            >
              <option value="0">Все</option>
              {fundList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={2}>
          <ModalInputWrapper label={""}>
            <Button
              label={"Найти"}
              style={styles.button}
              onClick={modalForm.handleSubmit}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

InventaryBookSearchForm.propTypes = {
  data: T.object,
  setSearchData: T.func,
  startSearch: T.func,
  types: T.array,
};

export default InventaryBookSearchForm;
