import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import { AUTHORS_SAVE_REQUEST, START } from "../../../actions/action-types";
import { ADD } from "../../../../constants/modalTypes";
import { authorAdd, authorEdit } from "../../../../api/authors/authors";
import { saveAuthorRequestError, saveAuthorRequestSuccess } from "../../../actions/authors/save";
import { authorsCountRequestStart } from "../../../actions/authors/count";
import { authorSaveImageRequestStart } from "../../../actions/authors/saveImage";
import { createNotification } from "../../../../components/notification/notification";

export default function* saveAuthorWatcher() {
  yield takeLatest(`${AUTHORS_SAVE_REQUEST}${START}`, saveAuthorWorker)
}

function* saveAuthorWorker({payload: { data, modalType: type }, callback}) {
  try {
    const response = yield call(type === ADD ? authorAdd : authorEdit, data);
    yield put(saveAuthorRequestSuccess(response.data));
    yield call(callback);
    const file = yield select(({authors}) => authors.setImage);
    if (file) yield put(authorSaveImageRequestStart(file, response.data.id));
    yield put(authorsCountRequestStart());
    if (type == ADD) {
      createNotification("success", "Документ успешно добавлен");
    } else {
      createNotification("success", "Документ успешно изменен");
    }
    } catch (error) {
    yield put(saveAuthorRequestError(error));
    error?.response?.data?.result && createNotification('error', error?.response?.data?.result);
  }
}