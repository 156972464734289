import { call, put, takeLatest, select } from 'redux-saga/effects'
import { IMPORT_BOOKS_REQUEST, START } from '../../actions/action-types'
import { importBooks, importBooksZip } from '../../../api/import/import'
import { importBooksRequestError, importBooksRequestSuccess } from '../../actions/import/import'
import { createNotification } from '../../../components/notification/notification'

export default function* importBooksWatcher() {
  yield takeLatest(`${IMPORT_BOOKS_REQUEST}${START}`, importBooksWorker)
}

function* importBooksWorker({resolution}) {
  const { file, format, id } = yield select(({ importBooks }) => importBooks.form)
  const params = {format, clientId: id || ''}
  try {
    const response = yield call(format === 'zip' ? importBooksZip : importBooks, file, params, resolution)
    const { ok, skipped, updated, duplicates } = response.data
    const addedBooks = [...ok, ...updated]
    addedBooks.legth && createNotification('success', 'Успешно добавлено')
    yield put(importBooksRequestSuccess(addedBooks || null))
    yield put(importBooksRequestError(duplicates || null))
  } catch ({response}) {
    response.status === 403 && createNotification('error', 'Неверный формат файла')
    const { message, result, ok, duplicates } = response.data || {}
    yield put(importBooksRequestSuccess(ok || null))
    yield put(importBooksRequestError(duplicates || null))
    const messageText = message || result
    messageText && createNotification('error', `${messageText} Совпадают поля: название, год, авторы, издательства, место издания.` )
  }
}
