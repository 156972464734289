import { api } from "../../App";
import {
  START,
  SUCCESS,
  BOOK_PLACES_REQUEST
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";

const getBookPlaces = bookId => {
  return api.get(`/bookstate?bookid=${bookId}`);
};

export const bookPlacesActions = {
  ...createSimpleActionsForReducer("bookPlaces", BOOK_PLACES_REQUEST)
};

export const bookPlacesReducer = createSimpleReducer(
  [],
  `${BOOK_PLACES_REQUEST}${SUCCESS}`
);

export function* bookPlacesSagaWatcher() {
  yield takeLatest(`${BOOK_PLACES_REQUEST}${START}`, loadBookPlacesSaga);
}

function* loadBookPlacesSaga({ payload }) {
  try {
    const response = yield call(getBookPlaces, payload);
    yield put(bookPlacesActions.bookPlacesRequestSuccess(response.data));
  } catch (e) {
    yield put(bookPlacesActions.bookPlacesRequestError());
  }
}
