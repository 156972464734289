import { api } from "../../App";

export const getLitersList = ({ name, ...otherParams }) => {
  const params = {
    name,
    ...otherParams
  };

  return api.get('/liter', {params})
};

export const getLitersCount = ({ name, ...otherParams }) => {
  const params = {
    count: true,
    name,
    ...otherParams
  };


  return api.get('/liter', {params})
};

export const deleteLitersItem = (itemId) => {
  return api.delete(`/liter/${itemId}`);
};

export const addLitersItem = ({ name }) => {
  return api.post(`/liter`, {name});
};

export const editLiterItem = ({ name, item }) => {
  return api.put(`/liter/${item}`, {name});
};