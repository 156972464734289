import React from 'react'
import T from 'prop-types'
import PdfPrintPanel from '../../PDFPrint/Panel'
import addFont from '../../../fonts/fonts'
import jsPDF from 'jspdf'
import moment from 'moment'

const TestsPDFPrint = ({ fromDate, toDate, year, groupName, data, orientation, title, currentPage, resultsPerPage }) => {
  const headers = [
    {
      value: (item, index) => (currentPage - 1) * resultsPerPage + index + 1,
      title: '№ п/п',
    },
    {
      value: 'title',
      title: 'Курс',
    },
    {
      value: ({ status }) => (status === 'DONE' ? 'Завершен' : 'В ходе выполнения'),
      title: 'Статус',
    },
    {
      value: ({ starttime }) => (starttime ? moment(starttime).format('DD.MM.YYYY') : 'Не указано'),
      title: 'Дата начала изучения',
    },
    {
      value: ({ starttime }) => (starttime ? moment(starttime).format('DD.MM.YYYY') : 'Не указано'),
      title: 'Дата завершения изучения',
    },
    {
      value: ({ period }) => `${period.hours || 0}ч. ${period.minutes || 0}м. ${period.seconds || 0}сек.`,
      title: 'Продолжительность изучения',
    },
    {
      value: 'rating',
      title: 'Результат',
    },
  ]
  const createData = tableData => {
    let result = []
    tableData.forEach((item, index) => {
      const generatedItem = []

      headers.forEach(({ value }) => {
        generatedItem.push(typeof value === 'function' ? value(item, index) : item[value] || '-')
      })

      result.push(generatedItem)
    })

    return result
  }

  const createHeaders = () => headers.map(header => header.title)

  const createPdfDoc = tableData => {
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    doc.setFont('Montserrat')
    doc.setFontSize('16')
    doc.text(title, doc.internal.pageSize.width / 2, 15, null, null, 'center')

    doc.setFontSize('12')
    doc.text(`Группа: ${groupName || 'Все'}`, 15, 30)
    doc.text(`Курс: ${year || 'Все'}`, 15, 37)
    doc.text(`c: ${moment(fromDate).format('DD.MM.YYYY')} по: ${moment(toDate).format('DD.MM.YYYY')}`, 15, 44)

    doc.autoTable({
      head: [createHeaders()],
      body: createData(tableData),
      styles: { font: 'Montserrat', fontSize: 10 },
      margin: {
        top: 25,
      },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      columnStyles: {
        // 0: { halign: 'left' },
        1: { halign: 'left' },
        // 2: { cellWidth: 15 },
        // 3: { cellWidth: 15 },
        // 4: { cellWidth: 15 },
        // 5: { halign: 'left', valign: 'left'},
        // 6: { cellWidth: 15 },
      },
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
    })

    return doc
  }

  const toPdf = () => {
    const doc = createPdfDoc(data)
    doc.save(`${title}.pdf`)
  }

  const toPrint = () => {
    const doc = createPdfDoc(data)
    doc.autoPrint()
    doc.output('dataurlnewwindow')
  }

  return (
    <div className={'d-flex justify-content-end mb-1'}>
      <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
    </div>
  )
}

TestsPDFPrint.propTypes = {
  title: T.string,
  orientation: T.oneOf(['landscape', 'portrait']),
}

export default TestsPDFPrint
