import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import {
  ADMIN_MODAL_DIRECTORY_COUNT_REQUEST,
  START,
  SUCCESS
} from "../../actions/action-types";
import { getUsersDirectory, getUsersCount, getDirectory, deleteDirectory } from "../../../api/adminUsers/users";
import {
  adminModalUsersCountRequestError,
  adminModalUsersCountRequestSuccess
} from "../../actions/adminModalUsers/count";
import { setAdminModalSearchData } from "../../actions/adminModalUsers/searchData";
import {
  adminModalUsersRequestError,
  adminModalUsersRequestStart,
  adminModalUsersRequestSuccess
} from "../../actions/adminModalDirectory/list";

export default function* loadAdminModalUsersWatcher() {
  yield takeLatest(
    `${ADMIN_MODAL_DIRECTORY_COUNT_REQUEST}${START}`,
    AdminModalUsersCountWorker
  );
  yield takeLatest(
    `${ADMIN_MODAL_DIRECTORY_COUNT_REQUEST}${SUCCESS}`,
    AdminModalUsersListWorker
  );
}

function* AdminModalUsersCountWorker() {
  const searchData = yield select(
    ({ adminModalUsers }) => adminModalUsers.search
  );
  try {
    const response = yield call(getUsersCount, searchData.query);
    const count = parseInt(response.data.count, 10);
    yield put(adminModalUsersCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setAdminModalSearchData({ ...searchData, pageCount }));
  } catch (error) {
    yield put(adminModalUsersCountRequestError(error));
  }
}

function* AdminModalUsersListWorker() {
  yield put(adminModalUsersRequestStart());
  const searchData = yield select(
    ({ adminModalUsers }) => adminModalUsers.search
  );
  const paginateFrom = Math.max(
    searchData.maxResults * (searchData.page - 1),
    0
  );
  try {
    const response = yield call(
      getDirectory,
      deleteDirectory,
      searchData.sortBy,
      searchData.maxResults,
      paginateFrom,
      searchData.query,
      searchData.sortDirection
    );
    yield put(adminModalUsersRequestSuccess(response.data));
  } catch (error) {
    yield put(adminModalUsersRequestError(error));
  }
}
