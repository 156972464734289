import React, { useEffect } from 'react';
import styles from './ExternalResourcesScene.module.css'
import { externalResourcesRequestStart } from "../../redux/actions/externalResources/list";
import { connect } from "react-redux";
import { resolveExternalResource } from "../../utils/resolveExternalResource";

const mapStateToProps = ({ externalResources }) => ({
  list: externalResources.list
});

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(externalResourcesRequestStart())
});

const ExternalResourcesScene = ({ list, requestList }) => {
  useEffect(() => {
    requestList();
  }, [requestList]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Внешние информационные ресурсы
      </div>
      <ul>
        {list.map((item, index) => (
          <li key={index}>
            <a href={resolveExternalResource(item.id)} target="_blank" rel="noopener noreferrer" className={styles.link}>
              {item.resourcename}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalResourcesScene);
