import {
  LIBRARY_ADD_POINT,
  LIBRARY_ADD_POINT_CLEAR
} from "../../../../actions/action-types";

const initialState = [];

export const libraryAddPointReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIBRARY_ADD_POINT:
      return [...state, action.payload];

    case LIBRARY_ADD_POINT_CLEAR:
      return initialState;

    default:
      return state;
  }
};
