import React from 'react'
import T from 'prop-types'
import styles from './LibraryTypesTable.module.css'
import { ADD, EDIT } from '../../../constants/modalTypes'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import { Button, Space } from 'antd'

const LibraryTypesTable = ({ initialFormFields, data, setModal, selectedId, handleSelect, onDelete }) => {
  const openEditModal = item => setModal({ modalType: EDIT, data: item })

  const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields })

  return (
    <div className={styles.container}>
      <div className='mb-3'>
        <Space>
          <Button type='primary' onClick={openCreationModal}>
            добавить
          </Button>
          <Button type='primary' onClick={onDelete} danger>
            удалить
          </Button>
        </Space>
      </div>
      <DataTable data={data} onCellClick={openEditModal}>
        <DataTableCol title='Типы обрабатываемой литературы' value='name' colSpan={6} className={styles.leftAlign} />
        <DataTableCol title='Количество в библиотеке' value='amount' />
        <DataTableCol
          title='Выбрано'
          passClicks
          resolver={item => <input type='checkbox' checked={item.id === selectedId} onChange={() => handleSelect(item.id)} />}
        />
      </DataTable>
    </div>
  )
}

LibraryTypesTable.propTypes = {
  data: T.array,
  setModal: T.func,
  selectedId: T.any,
  handleSelect: T.func,
  onDelete: T.func,
  initialFormFields: T.object.isRequired,
}

export default LibraryTypesTable
