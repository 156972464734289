import React from 'react'
import T from 'prop-types'
import styles from './ElectronicCoursesTable.module.css'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import { ADD, EDIT } from '../../../constants/modalTypes'
import classNames from 'classnames'
import { ELECTRONIC_COURSES_STATUS_LIST } from '../../../scenes/ElectronicCourses/statusList'

const ElectronicCoursesTable = ({
  data,
  sortBy,
  setSort,
  addFile,
  handleSelect,
  selectedId,
  isLibrarian,
  onItemDelete,
  setModalData,
  authData,
}) => {
  const openModal = (item, type) => setModalData({ modalType: type, data: item })

  const tableData = data.map((item, index) => {
    item.index = index + 1
    return item
  })

  const anchorClickHandler = id => {
    const fileWindow = window.open(`../api/course/${id}/file`)
    fileWindow.focus()
  }

  const inputOnChangeHandler = (e, id) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    addFile({ id, file: formData })
  }

  const LibrarianControls = () => (
    <div className={styles.controls}>
      <div
        onClick={() =>
          setModalData({
            modalType: ADD,
            data: null,
          })
        }
      >
        добавить
      </div>
      <div onClick={onItemDelete}>удалить</div>
    </div>
  )

  const getFullName = item => {
    const lastname = item.lastname || ''
    const firstname = item.firstname || ''
    const patronymic = item.patronymic || ''

    return `${lastname} ${firstname} ${patronymic}`
  }

  return (
    <div className={styles.container} id='electronic_courses-table'>
      {/* {isLibrarian ? <LibrarianControls /> : null} */}
      <DataTable data={tableData} sortBy={sortBy} onHeadClick={setSort} onCellClick={() => {}}>
        <DataTableCol title='№ п/п' value='index' colSpan={1} />
        <DataTableCol title='Название' value='title' colSpan={2} />
        <DataTableCol
          sortParams={'student'}
          title='ФИО'
          className={classNames('table-cell', { 'd-none': !isLibrarian })}
          colSpan={2}
          resolver={item => isLibrarian && getFullName(item)}
        />
        <DataTableCol
          sortParams={'groupid'}
          title='Группа'
          value='group'
          colSpan={2}
          className={classNames('table-cell', { 'd-none': !isLibrarian })}
        />
        <DataTableCol title='Статус' resolver={({ status }) => ELECTRONIC_COURSES_STATUS_LIST[status]} colSpan={2} />
        <DataTableCol
          title='Дата начала изучения'
          resolver={({ startdate }) => new Date(startdate).toLocaleString()}
          colSpan={2}
        />
        <DataTableCol
          title='Дата завершения изучения'
          resolver={({ enddate }) => (enddate ? new Date(enddate).toLocaleString() : 'Не указано')}
          colSpan={2}
        />
        <DataTableCol title='Продолжительность изучения' value='duration' colSpan={2} />
        <DataTableCol title='Результат' value='courseresult' colSpan={2} />
        <DataTableCol
          title='Рекомендации'
          resolver={item => <div onClick={() => openModal(item, 'recommendation')}>рекомендация</div>}
          colSpan={2}
        />
        <DataTableCol
          title='Тесты'
          resolver={item => <div onClick={() => openModal(item, 'tests')}>результаты</div>}
          colSpan={2}
        />
        {/* <DataTableCol
          title='Результат'
          colSpan={2}
          resolver={item => {
            return (
              <div className={styles.fileUploader}>
                {isLibrarian ? (
                  <>
                    <label htmlFor='file'>Загрузить файл</label>
                    <input type='file' id={'file'} onChange={e => inputOnChangeHandler(e, item.id)} />
                  </>
                ) : null}
                {item.filename ? (
                  <a className={styles.anchor} onClick={() => anchorClickHandler(item.id)}>
                    Ссылка на курс
                  </a>
                ) : (
                  <p style={{ margin: 0 }}>-</p>
                )}
              </div>
            )
          }}
        /> */}
        <DataTableCol
          title='Выбрано'
          colSpan={1}
          passClicks
          resolver={item => <input type={'checkbox'} checked={item.id === selectedId} onChange={() => handleSelect(item.id)} />}
        />
      </DataTable>
    </div>
  )
}

ElectronicCoursesTable.propTypes = {
  data: T.array,
  sortBy: T.string,
  setSort: T.func,
}

export default ElectronicCoursesTable
