import React, { useEffect } from "react";
import styles from "../Aggregation/AggregationReportScene.module.css";
import ReportInfoBlock from "../../../components/Reports/InfoBlock";
import Chart from "chart.js";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import { reportGetRequestStart } from "../../../redux/actions/reports/get";
import { connect } from "react-redux";
import domtoimage from "dom-to-image";
import CoversScanPdfPrint from "../../../components/Reports/CoversScan/PdfPrint";

const mapStateToProps = ({ reports }) => ({
  data: reports.report,
});

const mapDispatchToProps = {
  requestReport: reportGetRequestStart,
};

const CoversScanReportScene = ({ data, requestReport, ownId }) => {
  let bookScanPieChart, canvas;
  let dataArray = data[ownId];

  useEffect(() => {
    requestReport({
      id: ownId,
      formData: null,
    });
  }, []);

  useEffect(() => {
    if (!dataArray) return;
    dataArray.sort(function (a, b) {
      if (a.amount > b.amount) {
        return 1;
      }
      if (a.amount <= b.amount) {
        return -1;
      }
    });
    bookScanPieChart = new Chart(canvas.getContext("2d"), {
      type: "pie",
      data: {
        datasets: [
          {
            data: [dataArray[0].amount, dataArray[1].amount],
            backgroundColor: ["rgb(98, 209, 15)", "rgb(64, 15, 255)"],
          },
        ],
        labels: [dataArray[0].name, dataArray[1].name],
      },
    });
  }, [dataArray]);

  const getImageFromCanvas = () => {
    return domtoimage.toPng(canvas);
  };

  return (
    <SceneContainer title={" План сканирования обложек"}>
      <div className={"d-flex justify-content-end"}>
        <CoversScanPdfPrint
          tableData={dataArray}
          getImageFromCanvas={getImageFromCanvas}
        />
      </div>
      <div>
        {dataArray &&
          dataArray.map((item, index) => (
            <ReportInfoBlock
              key={index}
              label={item.name}
              value={item.amount}
            />
          ))}
      </div>

      <div className={styles.chartContainer}>
        <canvas ref={(el) => (canvas = el)} />
      </div>
    </SceneContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoversScanReportScene);
