import { ERROR, START, SUCCESS, DIRECTORY_DELETE_REQUEST } from "../action-types";

export const directoryDeleteRequestStart = (mid) => ({
  type: `${DIRECTORY_DELETE_REQUEST}${START}`,
  payload: mid
});

export const directoryDeleteRequestSuccess = (data) => ({
  type: `${DIRECTORY_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const directoryDeleteRequestError = (data) => ({
  type: `${DIRECTORY_DELETE_REQUEST}${ERROR}`,
  payload: data
});