import { INV_NUMBER_CREATE_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import { createNotification } from '../../../components/notification/notification';

export const createInvNumberRequestStart = (data) => ({
  type: `${INV_NUMBER_CREATE_REQUEST}${START}`,
  payload: data
});

export const createInvNumberRequestSuccess = (data) => {
  createNotification('success', 'Инвентарный номер успешно создан');
 return {
   type: `${INV_NUMBER_CREATE_REQUEST}${SUCCESS}`,
   payload: data
 };
};

export const createInvNumberRequestError = (data) => {
  createNotification('error', 'Ошибка создания инвентарного номера');
  return {
    type: `${INV_NUMBER_CREATE_REQUEST}${ERROR}`,
    payload: data
  };
};
