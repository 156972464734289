import {combineReducers} from "redux";
import {searchReducer} from "./search/schedulerCategorySearchReducer";
import {countReducer} from "./count/schedulerCategoryCountReducer";
import {listReducer} from "./list/schedulerCategoryListReducer";
import {loaderReducer} from "./loader/schedulerCategoryLoaderReducer";

export const schedulerCategoryReducer = combineReducers({
    search: searchReducer,
    count: countReducer,
    list: listReducer,
    loader: loaderReducer
});