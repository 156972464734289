import React from "react";
import globalStyles from "../../BookDescriptionScene.module.css";

const ScanImages = ({ images, onClick }) => {
  return images.map(image => {
    if (!image) return null;
    return (
      <img
        onClick={() => onClick(image)}
        src={image}
        className={globalStyles.scanImage}
        alt="Скан"
      />
    );
  });
};

export default ScanImages;
