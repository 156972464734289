import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import {MOBILE_LIBRARY_LIST_REQUEST, SET_MOBILE_LIBRARY_SEARCH_DATA, START, SUCCESS, ERROR} from "../../../actions/action-types";



const initialState = {
    limit: RESULTS_PER_PAGE,
    offset: 0,
    pageCount: 0,
    page: 1
}



export const mobileLibrarySearchReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case `${MOBILE_LIBRARY_LIST_REQUEST}${SUCCESS}`:
            return {
                ...state,
                pageCount: Math.ceil(action.cnt / state.limit)
            }
        
        case `${SET_MOBILE_LIBRARY_SEARCH_DATA}`:
            return {...action.payload}
        
        default: return state;
    }
};