import {
  getBookFileList,
  uploadBookFile,
  editBookFile,
  deleteBookFiles,
} from '../../../api/book/files';
import {
  BOOK_FILES_GET_REQUEST,
  BOOK_FILES_UPLOAD_REQUEST,
  BOOK_FILES_EDIT_REQUEST,
  BOOK_FILES_DELETE_REQUEST,
  SET_BOOK_FILES_SORT_DATA,
  START,
} from '../../actions/action-types';
import {
  call,
  put, select,
  takeLatest
} from 'redux-saga/effects';
import {createSimpleActionsForReducer} from "../../actions/actionsFactory";
import {createSimpleReducer, createSimpleReducerWithLoader} from "../../reducers/reducersFactrory";
import {SORT_DIRECTION} from "../../../constants/sortDirections";
import { createNotification } from '../../../components/notification/notification';


const bookFilesSortDataInitialState = {
  sortDirection: SORT_DIRECTION.ASC,
  orderBy: 'filename'
};

/*  ACTIONS  */
export const bookEditFilesActions = {
  ...createSimpleActionsForReducer("get", BOOK_FILES_GET_REQUEST),
  ...createSimpleActionsForReducer("upload", BOOK_FILES_UPLOAD_REQUEST),
  ...createSimpleActionsForReducer("edit", BOOK_FILES_EDIT_REQUEST),
  ...createSimpleActionsForReducer("delete", BOOK_FILES_DELETE_REQUEST),
};

export const setBookEditFilesSortData = (data) => ({
  type: SET_BOOK_FILES_SORT_DATA,
  payload: data
});

/*  REDUCER  */
export const bookEditFilesReducer = createSimpleReducerWithLoader([], BOOK_FILES_GET_REQUEST);
export const bookEditSortReducer = createSimpleReducer(bookFilesSortDataInitialState, SET_BOOK_FILES_SORT_DATA);

/*  SAGA  */

export function* bookEditFilesWatcher() {
  yield takeLatest(`${BOOK_FILES_GET_REQUEST}${START}`, bookEditFilesListWorker);
  yield takeLatest(`${BOOK_FILES_UPLOAD_REQUEST}${START}`, bookEditFilesUploadWorker);
  yield takeLatest(`${BOOK_FILES_DELETE_REQUEST}${START}`, bookEditFilesDeleteWorker);
  yield takeLatest(`${BOOK_FILES_EDIT_REQUEST}${START}`, bookEditFilesEditWorker);
}

function* bookEditFilesListWorker({ payload: bookId }) {
  const sortData = yield select(({ bookEdit }) => bookEdit.sortData);
  try {
    const response = yield call(getBookFileList, bookId, sortData);
    yield put(bookEditFilesActions.getRequestSuccess(response.data));
  } catch (e) {
    yield put(bookEditFilesActions.getRequestError(e));
  }
}

function* bookEditFilesUploadWorker({ payload }) {
  try {
    yield call(uploadBookFile, payload);
    yield put(bookEditFilesActions.getRequestStart(payload.bookId));
  } catch (e) {
    createNotification('error', e.response.data.result)
    yield put(bookEditFilesActions.uploadRequestError(e));
  }
}

function* bookEditFilesDeleteWorker({ payload }) {
  try {
    yield call(deleteBookFiles, payload.bookId, payload.fileId);
    yield put(bookEditFilesActions.getRequestStart(payload.bookId));
  } catch (e) {
    yield put(bookEditFilesActions.deleteRequestError(e));
  }
}

function* bookEditFilesEditWorker({ payload }) {
  try {
    yield call(editBookFile, payload.bookId, payload.fileId, payload.data);
    yield put(bookEditFilesActions.getRequestStart(payload.bookId));
  } catch (e) {
    yield put(bookEditFilesActions.deleteRequestError(e));
  }
}
