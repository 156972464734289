import { SET_FUND_MOVING_SEARCH_DATA } from '../../../actions/action-types'
import moment from 'moment'

const initialState = {
  from: moment().startOf('year').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  // Шифр
  in_library_id: 0,
  in_fund_id: 0,
}

export const fundMovingSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FUND_MOVING_SEARCH_DATA:
      return action.payload

    default:
      return state
  }
}
