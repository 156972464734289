import React from 'react';
import T from 'prop-types';
import styles from './LocationShelf.module.css';
import Button from "../../Button";
import { shelfDeleteRequestStart } from "../../../redux/actions/location/shelves/delete";
import { connect } from "react-redux";
import { EDIT } from "../../../constants/modalTypes";
import { LOCATION_MODAL } from "../../../constants/locationModal";

const mapDispatchToProps = dispatch => ({
  deleteShelf: (rackId, shelfId) => dispatch(shelfDeleteRequestStart(rackId, shelfId))
});

const LocationShelf = ({ data, deleteShelf, setModalData, selectedIds, setSelectedIds}) => {
  const onShelfEdit = () => {
    setModalData({
      modalType: EDIT,
      locationType: LOCATION_MODAL.SHELF,
      parentId: data.shelfid,
      data: data
    })
  };

  const handleSelect = (id) =>
    setSelectedIds((selectedIds) => {
      const idIndex = selectedIds.indexOf(id);
      if (idIndex !== -1)
        selectedIds.splice(idIndex, 1);
      else
        selectedIds.push(id);
      return [...selectedIds];
    });

  return (
    <div className={styles.container}>
      <div className={styles.title}>{data.name}</div>
      <div>
        <Button label="-" style={styles.button} onClick={() => deleteShelf(data.shelfid, data.id)}/>
        <Button label="Редактировать" style={styles.button} onClick={onShelfEdit}/>
        <input type="checkbox" checked={selectedIds.includes(data.id)}
               onChange={() => handleSelect(data.id)}/>
      </div>
    </div>
  );
};

LocationShelf.propTypes = {
  data: T.object,
  setModalData: T.func
};

export default connect(null, mapDispatchToProps)(LocationShelf);
