import { all } from 'redux-saga/effects';
import libraryTypesListSaga from "./list/libraryTypesListSaga";
import libraryTypesSaveSaga from "./save/libraryTypesSaveSaga";
import libraryTypesDeleteSaga from "./delete/libraryTypesDeleteSaga";

export default function* libraryTypesSaga() {
  yield all([
    libraryTypesListSaga(),
    libraryTypesSaveSaga(),
    libraryTypesDeleteSaga()
  ]);
}