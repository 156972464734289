import { call, put, takeLatest, select } from 'redux-saga/effects'
import { SEARCH_AUDIT_LIST_REQUEST, SEARCH_AUDIT_COUNT_REQUEST, START } from '../../actions/action-types'
import { getSearchAuditCount, getSearchAuditList } from '../../../api/searchAudit/searchAudit'
import { searchAuditCountRequestError, searchAuditCountRequestSuccess } from '../../actions/searchAudit/count'
import { searchAuditSearchData } from '../../actions/searchAudit/searchData'
import {
  searchAuditListRequestSuccess,
  searchAuditListRequestStart,
  searchAuditListRequestError,
} from '../../actions/searchAudit/list'

export default function* searchAuditWatcher() {
  yield takeLatest(`${SEARCH_AUDIT_COUNT_REQUEST}${START}`, searchAuditCountWorker)
  yield takeLatest(`${SEARCH_AUDIT_LIST_REQUEST}${START}`, searchAuditListWorker)
}

function* searchAuditCountWorker() {
  // const userId = yield select(({ auth }) => auth.data.mid)
  const formData = yield select(({ searchAudit }) => searchAudit.form)
  // formData.mid = userId
  const searchData = yield select(({ searchAudit }) => searchAudit.search)
  try {
    const response = yield call(getSearchAuditCount, formData)
    const count = parseInt(response.data.count, 10)
    yield put(searchAuditCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(searchAuditSearchData({ ...searchData, pageCount }))
    yield put(searchAuditListRequestStart())
  } catch (error) {
    yield put(searchAuditCountRequestError(error))
  }
}

function* searchAuditListWorker() {
  // const userId = yield select(({ auth }) => auth.data.mid)
  const formData = yield select(({ searchAudit }) => searchAudit.form)
  // formData.mid = userId
  const searchData = yield select(({ searchAudit }) => searchAudit.search)

  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(getSearchAuditList, searchData, formData)
    yield put(searchAuditListRequestSuccess(response.data))
  } catch (error) {
    yield put(searchAuditListRequestError(error))
  }
}
