import jsPDF from "jspdf"
import addFont from "../../../../fonts/fonts"
import 'jspdf-autotable'



const orientation = 'portrait'

export const getVarianceReceiptData = async ({options, actData, clientInfo, libName, supplierName}) => {
  addFont(jsPDF.API)

  let lastY = 20

  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height) {
      doc.addPage('a4', orientation)
      newY = 10
    }
    lastY = newY
    return newY
  }

  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
  doc.setFont('Montserrat')

  doc.setFontSize(14).setFont(undefined, 'bold')
  doc.text(`АКТ № ${actData.documentnumber}`, doc.internal.pageSize.width / 2, lastY, 'center')
  doc.text('ПРИЕМКИ МАТЕРИАЛОВ', doc.internal.pageSize.width / 2, getY(10), 'center')
  doc.text('(материальных ценностей)', doc.internal.pageSize.width / 2, getY(10), 'center')
  doc.setFontSize(10).setFont(undefined, 'normal')


  doc.text('Коды', 170, 45)
  doc.text('Форма № 23 по ОКУД    0504220', 124, 55)
  doc.text('по ОКПО', 146, 65)
  doc.rect(165, 40, 20, 10)
  doc.rect(165, 50, 20, 8)
  doc.rect(165, 58, 20, 20)
  doc.rect(165, 78, 20, 8)
  doc.text('ИНН', 146, 83)
  doc.text(options.inn, 166, 83)

  doc.text('от "__" ________ 20__ г.', 60, getY(10))
  doc.text(clientInfo.shortname || '', 75, getY(19))
  doc.text('Учреждение-получатель   _____________________________________', 20, getY(1))
  doc.text(libName || actData.libraryname || '', 83, getY(9))
  doc.text('Структурное подразделение   ________________________________', 20, getY(1))
  doc.text(options.address, 40, getY(9))
  doc.text('Адрес   ________________________________________________________', 20, getY(1))
  
  doc.text('Отправитель   _________________________________________________', 20, getY(20))
  doc.text('Адрес   ________________________________________________________', 20, getY(10))
  
  doc.text(actData?.suppliername || supplierName || '', 49, getY(19))
  doc.text('Поставщик   ___________________________________________________', 20, getY(1))
  doc.text('Адрес   ________________________________________________________', 20, getY(10))
  
  doc.text('Страховая компания   _________________________________________', 20, getY(20))
  doc.text('Адрес   ________________________________________________________', 20, getY(10))
  
  doc.text('Комиссия, назначенная приказом (распоряжением) от "__"_____________20__г.', 30, getY(25))
  doc.text('произвела осмотр прибывшего груза "__"_____________ 20__ г.  и установила', 30, getY(5))
  doc.text('следующее:', 30, getY(5))
  
  doc.text('1. Сведения о результатах осмотра прибывшего груза', doc.internal.pageSize.width / 2, getY(15), 'center')
  
  doc.autoTable({
    html: '#varianceFirstTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
  })
  
  doc.text('2. Сведения о грузе по сопроводительным транспортным документам', doc.internal.pageSize.width / 2, getY(15, doc.autoTableEndPosY()), 'center')
  
  doc.autoTable({
    html: '#varianceSecondTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
  })

  doc.text('3. Сведения о дате (ДД.ММ.ГГГГ) и времени (ЧЧ.ММ) событий, связанных с приемкой груза', doc.internal.pageSize.width / 2, getY(15, doc.autoTableEndPosY()), 'center')
  
  doc.autoTable({
    html: '#varianceThirdTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
  })

  doc.text('4. Условия хранения материальных ценностей на складе получателя', doc.internal.pageSize.width / 2, getY(15, doc.autoTableEndPosY()), 'center')
  doc.text('5. Состояние тары и упаковки в момент осмотра материальных ценностей', doc.internal.pageSize.width / 2, getY(50), 'center')
  doc.text('6. Объем (количество) недостающих материальных ценностей определен', doc.internal.pageSize.width / 2, getY(50), 'center')
  doc.text('(взвешиванием, счетом мест, обмером и т.п.)', doc.internal.pageSize.width / 2, getY(5), 'center')
  doc.text('7. Дополнительные сведения', doc.internal.pageSize.width / 2, getY(50), 'center')
  doc.text('8. Бухгалтерская запись для приема к учету', doc.internal.pageSize.width / 2, getY(50), 'center')
  
  doc.autoTable({
    html: '#varianceFourthTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
  })
  
  doc.text('9. Результат приемки груза', doc.internal.pageSize.width / 2, getY(15, doc.autoTableEndPosY()), 'center')
  
  doc.autoTable({
    html: '#varianceFifthTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(10),
  })

  doc.text('Заключение комиссии', 30, getY(15, doc.autoTableEndPosY()))
  doc.text('Перечень прилгаемых документов', 30, getY(50))
  
  doc.text('С правилами приемки материальных ценностей по количеству, качеству и ', 30, getY(50))
  doc.text('комплектности члены комиссии ознакомлены ', 30, getY(5))
  
  doc.text('и предупреждены об ответственности за подписание акта, содержащего', 30, getY(10))
  doc.text('недостоверные сведения.', 30, getY(5))
  
  doc.text('Председатель комиссии', 30, getY(25))
  doc.text('_____________________________________________________', 85, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 95, getY(3))
  doc.text('имя, отчество)', 95, getY(3))
  doc.setFontSize(10)
  
  doc.text('Члены комиссии:', 30, getY(15))
  doc.text('_____________________________________________________', 85, lastY)
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 95, getY(3))
  doc.text('имя, отчество)', 95, getY(3))
  doc.setFontSize(10)
  
  doc.text('_____________________________________________________', 85, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 95, getY(3))
  doc.text('имя, отчество)', 95, getY(3))
  doc.setFontSize(10)
  
  doc.text('_____________________________________________________', 85, getY(10))
  doc.setFontSize(8)
  doc.text('(должность, воинское звание, подпись,', 95, getY(3))
  doc.text('имя, отчество)', 95, getY(3))
  doc.setFontSize(10)
  
  doc.text('"_____"_________________ 20___ г.', 30, getY(20))

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}