import { ERROR, DISCIPLINE_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const disciplineListRequestStart = () => ({
    type: `${DISCIPLINE_LIST_REQUEST}${START}`
});

export const disciplineListRequestSuccess = (data) => ({
    type: `${DISCIPLINE_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const disciplineListRequestError = (error) => ({
    type: `${DISCIPLINE_LIST_REQUEST}${ERROR}`,
    payload: error
});

