import React from 'react'
import styles from './StateSecretBookTable.module.css'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import moment from 'moment'

export const StateSecretBookTable = ({ data, sortBy, setSort, getReaderData }) => {
  return (
    <div className={styles.container}>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={getReaderData} width="max-content" maxWidth="1370px">
        <DataTableCol title='№ п/п' resolver={item => item.column_index} />
        <DataTableCol title='ФИО' value='person' className={styles.leftAlign} />
        <DataTableCol title='Наименование сведений, составляющих гос. тайн' value='title' className={styles.leftAlign} />
        <DataTableCol title='Порядковый (инвентарный) номер издания, чертежа' value='number' className={styles.leftAlign} />
        <DataTableCol title='Номер экземпляра ' value='copynumber' className={styles.bookContainer} />
        <DataTableCol title='Гриф секретности ' value='secret_name' />
        <DataTableCol title='Дата ознакомления ' resolver={item => moment(item.movedate).format('DD.MM.YYYY')} />
        <DataTableCol title='Примечание ' value='' />
      </DataTable>
    </div>
  )
}
