import React from 'react';
import {Link} from "react-router-dom";
import T from "prop-types";
import styles from './BibliographyBook.module.css';

const Book = ({ book }) => {
    const { name } = book;

    return (
    <Link to={`book/${book.library_bookid}`} target={"_blank"}>
        <div className={styles.LitRow}>
            <div className={styles.LitRow_titleCell}>{name}</div>
        </div>
    </Link>
    );
};

Book.propTypes = {
    book: T.object.isRequired,
};

export default Book;