import {SUCCESS, RESET_MOBILE_LIBRARY_DATA, MOBILE_LIBRARY_DOCUMENTS_REQUEST} from "../../../actions/action-types";



const initialState = []



export const mobileLibraryDocumentsReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${MOBILE_LIBRARY_DOCUMENTS_REQUEST}${SUCCESS}`:
            return action.payload
        
        case `${RESET_MOBILE_LIBRARY_DATA}`:
            return initialState
        
        default: return state;
    }
};