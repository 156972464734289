import { SET_LATEST_NEW_BOOK_ORDER } from "../../../actions/action-types";

const initialState = null;

export const newBooksOrderLatestReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_LATEST_NEW_BOOK_ORDER:
            return action.payload;

        default: return state;
    }
};
