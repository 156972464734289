import React from "react";
import T from "prop-types";

import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from "./SummaryControlSearchForm.module.css";
import { Row, Col, Button, Space } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const FundAccountingSearchForm = ({
  searchData,
  setSearchData,
  onSubmit,
  librariesList,
  yearEnd,
}) => {
  const modalForm = useFormik({
    initialValues: searchData,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setSearchData(values);
      onSubmit();
    },
  });

  const { values, errors } = modalForm;

  return (
    <div className={"formContainer"}>
      <Row gutter={15}>
        <Col md={6}>
          <ModalInputWrapper
            value={values["from"]}
            error={errors["from"]}
            name="from"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"С"}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            value={values["to"]}
            error={errors["to"]}
            name="to"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper label={"Библиотека"}>
            <select
              name="libraryid"
              onChange={modalForm.handleChange}
              value={values["libraryid"]}
              className={styles.select}
            >
              <option value="0">Все библиотеки</option>
              {librariesList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col>
          <Space>
            <ModalInputWrapper label={""}>
              <Button onClick={modalForm.handleSubmit} type="primary">
                Составить
              </Button>
            </ModalInputWrapper>
            {yearEnd}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

FundAccountingSearchForm.propTypes = {
  data: T.object,
  setSearchData: T.func,
  startSearch: T.func,
  types: T.array,
  librariesList: T.array,
  yearEnd: T.node,
};

export default FundAccountingSearchForm;
