import { call, put, takeLatest } from "redux-saga/effects";
import { START, SYSTEM_VERSION_REQUEST } from "../../actions/action-types";
import { getSystemVersion } from "../../../api/systemInfo/info";
import { systemVersionRequestError, systemVersionRequestSuccess } from "../../actions/version/systemVersion";

export default function* systemVersionWatcher() {
  yield takeLatest(`${SYSTEM_VERSION_REQUEST}${START}`, systemVersionWorker)
}

function* systemVersionWorker() {
  try {
    const response = yield call(getSystemVersion);
    yield put(systemVersionRequestSuccess(response.data));
  } catch (error) {
    yield put(systemVersionRequestError(error));
  }
}
