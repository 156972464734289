import React from "react";
import T from "prop-types";
import styles from "../../FundAccounting/SearchForm/SummaryControlSearchForm.module.css";
import { Col, Row } from "react-bootstrap";
import { saveAs } from "file-saver";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { Button, Space } from "antd";
import { STATUS_LIST } from "../../../scenes/BookScanPlan";
import o2x from "object-to-xml";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const SearchForm = ({
  formData,
  setFormData,
  onSubmit,
  data,
  selectedData,
  setSelectedData
}) => {
  const modalForm = useFormik({
    initialValues: formData,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setFormData(values);
      onSubmit();
    },
  });

  const { values, errors } = modalForm;


  const downloadFile = () => {
    const ifData = selectedData ? selectedData : data;
    if (ifData) {
      const obj = {};
      obj.data = selectedData ? selectedData : data.data;
      let fileData = o2x(obj);
      fileData = "<document>" + fileData + "</document>";

      const blob = new Blob([fileData], {
        type: "application/xml;charset=utf-8",
      });
      saveAs(blob, `План оцифровки бумажных носителей.xml`);
    }
  };

  const handleSearch = () => {
    modalForm.handleSubmit();
    setSelectedData([]);
  } 

  return (
    <div className={styles.container}>
      <Row style={{flexWrap: 'wrap'}}>
        <Col md={3} xl={2} lg={3}>
          <ModalInputWrapper
            value={values['title']}
            name="title"
            onChange={modalForm.handleChange}
            type={"text"}
            label={"Заглавие"}
          />
        </Col>
        <Col md={3} xl={2} lg={2}>
          <ModalInputWrapper label={"Статус"}>
            <select
              name="status"
              onChange={modalForm.handleChange}
              value={values['status']}
              className={styles.select}
            >
              <option value="">Все</option>
              {Array.from(STATUS_LIST.entries()).map((item, index) => (
                <option key={index} value={item[0]}>
                  {item[1]}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={3} xl={2} lg={2}>
          <ModalInputWrapper
            value={values['from']}
            error={errors['from']}
            name="from"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"С"}
          />
        </Col>
        <Col md={3} xl={2} lg={2}>
          <ModalInputWrapper
            value={values['to']}
            error={errors['to']}
            name="to"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col md={6} xl={4} lg={4}>
          <Space className={styles.space}>
            <Button type="primary" onClick={handleSearch}>
              Составить
            </Button>
            <Button type="primary" onClick={downloadFile}>
              Выгрузка в формат ХМРР
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

SearchForm.propTypes = {
  formData: T.object,
  setFormData: T.func,
  onSubmit: T.func,
};

export default SearchForm;
