import { api } from '../../App'

export const getFundAccounting = ({ from, to, libraryid }) => {
  const params = {
    libraryid,
    // Шифр
    fundid: 0,
    from,
    to,
  }

  return api.get(`/fund_accounting`, { params })
}

export const bookYearEnd = ({ from, to, libraryid }) => {
  const params = {
    libraryid,
    from,
    to,
  }

  return api.get('/books_year_end', { params })
}
