import React, { useState, useEffect, useMemo } from "react";
import styles from "./StateSecretBookForm.module.css";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import { api } from "../../../App";
import * as Yup from "yup";
import { useFormik } from "formik";
import SearchableSelect from '../../Select'
import InputWrapper from "../../Modal/InputWrapper";
import { createNotification } from "../../notification/notification";
import PdfPrintPanel from "../../PDFPrint/Panel";

import addFont from '../../../fonts/fonts'
import jsPDF from 'jspdf'
import moment from "moment";

const title = 'Книга учета осведомленности в сведениях, составляющих государственную тайну'

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

export const StateSecretBookForm = ({ data, setData, search, dataset, list }) => {
  
  const [users, setUsers] = useState([]);
  const [outsecrecy, setOutsecrecy] = useState([]);
  const [militaryRank, setMilitaryRank] = useState([]);
  const [admission, setAdmission] = useState([]);
  const [username, setUsername] = useState([])

  useEffect(() => {
    getData('/military_rank', setMilitaryRank);
    getData('/admission_form', setAdmission);
    getData('/outsecrecy', setOutsecrecy);
    getPeoples()
  }, [])
  
  useEffect(() => { modalForm.setFieldValue('username', username.label) }, [username])

  const getData = (route, callBack) => {
    api.get(route).then(({ data }) => callBack(data));
  }

  const getPeoples = (search) => {
    const params = { search }
    api.get("./people", { params }).then((data) => setUsers(data.data));
  }

  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values);
      search();
    },
  });

  const { values, errors } = modalForm;

  const normolizeUser = ({displayname, mid}) => ({label: displayname, value: mid})

  const selectPeple = useMemo(
    () => (
      <ModalInputWrapper label="Имя пользователя">
        <SearchableSelect
            name="username"
            placeholder='Поиск'
            provider={true}
            options={users?.map(normolizeUser)}
            onChange={setUsername}
            onInputChange={getPeoples}
            value={username}
            className={styles.searchInput}
          />
      </ModalInputWrapper>
    ),
    [users, values, modalForm.handleChange]
  );

  const createDocData = async () => {
    addFont(jsPDF.API);
    let id = (modalForm.values?.username && modalForm.values?.username != '' && modalForm.values?.username != 'Не выбрано') ? true : false;
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'L' });

    doc.setFont('Montserrat');
    doc.setFontSize('16');
    doc.text('Книга учета осведомленности в сведениях,', doc.internal.pageSize.width / 2, 15, null, null, 'center');
    doc.text('составляющих государственную тайну', doc.internal.pageSize.width / 2, 25, null, null, 'center');
    doc.setFontSize('10');
    if (id == true) {
      doc.text(`${list[0]?.position || ''}`, doc.internal.pageSize.width / 2, 37, null, null, 'center');
      doc.text(`(должность)`, doc.internal.pageSize.width / 2, 45, null, null, 'center');
      doc.text(`${list[0].guards || ''} ${militaryRank && militaryRank.length > 0 && list[0].designation && militaryRank?.find(el => el.id_mil_rank == list[0].designation).title || ''} ${list[0]?.justice || ''} ${list[0]?.reserve || ''}, ${list[0].person || ''}` , 20, 55);
      doc.text(`(воинское звание, фамилия, имя, отвество)`, doc.internal.pageSize.width / 2, 62, null, null, 'center');
      doc.text(`${admission && admission.length > 0 && list[0].admission && admission?.find(el => el.id == list[0].admission)?.security_form || ''}`, 265, 75, 'right');
      doc.text(`(форма допуска)`, 275, 82, 'right');
    }

    let space = id ? 100 : 30;

    doc.autoTable({
      html: '#table',
      styles: { font: 'Montserrat', fontSize: 10 },
      margin: {
        top: 25,
      },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : space,
    })

    return doc
  }

  const handlePdf = () => {
    if (list.length == 0) {
      createNotification('error', 'Список пуст')
    } else {
      toPrint()
    }
  }
  const toPdf = () => {
    if (list.length == 0) {
      createNotification('error', 'Список пуст')
    } else {
      createDocData(list).then(doc => doc.save(`${title}.pdf`))
    }
  }

  const toPrint = () => {
    createDocData(list).then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  const table = (
    <table hidden id='table'>
      <thead>
        <tr>
        <th>N п/п	</th>
        <th>Дата (период) ознакомления</th>
        <th>Учетный номер и дата документа или предписания</th>
        <th>Наименование или краткое содержание документа, изделия, работы</th>
        <th>Гриф секретности</th>
        <th>Порядковый (инвентарный) номер издания, чертежа</th>
        <th>Номер экземпляра</th>
        <th>Примечание</th>
        </tr>
      </thead>
      <tbody>
        {list.map((el, index) =>
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{moment(el.movedate).format('DD.MM.YYYY')}</td>
            <td>{`${el.account_number ? `(${el.account_number})` : ''} ${el.prescription_date ? moment(el.prescription_date).format('DD.MM.YYYY') : ''}`}</td>
            <td>{el.title}</td>
            <td>{el.secret_name}</td>
            <td>{el.number}</td>
            <td>{el.copynumber}</td>
            <td>{''}</td>
          </tr>  
        )}
      </tbody>
    </table>
  )

  return (
    <div className={styles.container}>
      {table}
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="from"
            value={values["from"]}
            error={errors["from"]}
            label="С (дата)"
            type="date"
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="to"
            value={values["to"]}
            error={errors["to"]}
            label="До (дата)"
            type="date"
          />
        </Col>
        <Col md={6}>{selectPeple}</Col>
        <Col md={6}>
          <ModalInputWrapper label="Гриф секретности">
              <select
                className={styles.select}
                onChange={modalForm.handleChange}
                name={"secret_name"}
                value={values["secret_name"]}
                >
                <option value={""}>Все</option>
                {outsecrecy?.map(item => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <InputWrapper 
          name={"number"}
          label="Инвентарный номер" 
          value={values["number"]} 
          onChange={modalForm.handleChange}
          />
        </Col>
        <Col md={6}>
          <InputWrapper 
          name={"title"}
          label="Наименование сведения" 
          value={values["title"]} 
          onChange={modalForm.handleChange}
          />
        </Col>
        <Col md={12}>
          {!!dataset && (
            <ModalInputWrapper label="Датасет">
              <select
                className={styles.select}
                onChange={modalForm.handleChange}
                name={"dataset"}
                value={values["dataset"]}
              >
                <option value={""}>Не выбрано</option>
                {dataset.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          )}
        </Col>
        <Col md={12} className={styles.buttons}>
          <PdfPrintPanel printPdf={handlePdf} loadPdf={toPdf} />
          <Button label={"Найти"} onClick={modalForm.handleSubmit} />
        </Col>
      </Row>
    </div>
  );
};
