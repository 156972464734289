import React, { useState } from 'react'
import T from 'prop-types'
import NotFoundCover from '../../../../../img/catalog-cover.jpg'
import OpenPhotoModal from '../../../../OpenPhotoModal'
import classNames from 'classnames'
import styles from './BookCardImage.module.css'
import { ICONS } from '../../../../../utils/projectIcons'

const CARD_VIEW_MODES = {
  TEXT: 'text',
  IMAGE: 'image',
}

const BookCardImage = ({ url, style, notFoundImage, bookCardSideData }) => {
  const [viewMode, setViewMode] = useState(CARD_VIEW_MODES.IMAGE)
  const [rotate, setRotate] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)

  const switchViewMode = () => {
    const { TEXT: text, IMAGE: image } = CARD_VIEW_MODES

    setViewMode(viewMode === text ? image : text)
  }

  const onRotate = () => {
    setRotate(rotate + 90)
  }
  return (
    <div className={styles.container}>
      <OpenPhotoModal src={url || NotFoundCover} visible={modalVisible} onClose={() => setModalVisible(false)} />

      {viewMode === CARD_VIEW_MODES.TEXT ? (
        <div className={styles.textCard} style={{ transform: `rotate(${rotate}deg)`, margin: '22% 0 20% 0', width: '100%' }}>
          {bookCardSideData.map(item => (
            <p key={item.id.toString()} className={styles.textCardString}>
              {item.fragment}
            </p>
          ))}
        </div>
      ) : (
        <img
          id={'test' + Math.floor(Math.random() * 10)}
          alt='Скан'
          className={style}
          src={url || notFoundImage || NotFoundCover}
          onClick={() => setModalVisible(true)}
          style={{ transform: `rotate(${rotate}deg)`, margin: '22% 0 20% 0', width: '100%' }}
        />
      )}

      <div className={styles.controls}>
        <button className={classNames('iconButton bg_accent')} onClick={onRotate} title='Повернуть'>
          <img src={ICONS.ROTATE} alt='' />
        </button>
        <button className={classNames('iconButton bg_green')} onClick={switchViewMode} title='Показать данные OCR'>
          <img src={ICONS.VIEW} alt='' />
        </button>
      </div>
    </div>
  )
}

BookCardImage.propTypes = {
  url: T.string,
  style: T.object,
  bookCardSideData: T.array,
}

export default BookCardImage
