import { api } from "../../App";

export const getHavkinaList = ({ maxResults, paginateFrom, search }) => {
  const params = {
    maxResults,
    paginateFrom,
    search
  };

  return api.get('/havkina', {params});
};

export const getHavkinaCount = (search) => {
  const params = {
    count: true,
    search
  };

  return api.get('/havkina', {params});
};

export const getHavkinaIndex = (author) => {
  const params = {
    author
  };

  return api.get('/havkina/calcindex', { params });
};
