import { ERROR, OPTIONS_GET_REQUEST, START, SUCCESS } from "../action-types";

export const optionsGetRequestStart = () => ({
  type: `${OPTIONS_GET_REQUEST}${START}`
});

export const optionsGetRequestSuccess = (data) => ({
  type: `${OPTIONS_GET_REQUEST}${SUCCESS}`,
  payload: data
});

export const optionsGetRequestError = (data) => ({
  type: `${OPTIONS_GET_REQUEST}${ERROR}`,
  payload: data
});
