import {combineReducers} from "redux";
import {bookScanOrdersCountReducer} from "./count/bookScanOrdersCountReducer";
import {bookScanOrdersFormReducer} from "./form/bookScanOrdersFormReducer";
import {bookScanOrdersListReducer} from "./list/bookScanOrdersListReducer";
import {bookScanOrdersLoaderReducer} from "./loader/bookScanOrdersLoaderReducer";
import {bookScanOrdersSearchReducer} from "./search/bookScanOrdersSearchReducer";

export const bookScanOrdersReducer = combineReducers({
  count: bookScanOrdersCountReducer,
  form: bookScanOrdersFormReducer,
  list: bookScanOrdersListReducer,
  loader: bookScanOrdersLoaderReducer,
  search: bookScanOrdersSearchReducer
});