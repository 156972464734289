import React from 'react'
import { configureStore } from './redux/store'
import { Provider } from 'react-redux'
import ruRu from 'antd/lib/locale-provider/ru_RU'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { Routes } from './routing/Routes'
import { configureApi } from './api'
import './css/bootstrap.min.css'
import 'antd/dist/antd.css'
import './css/global.css'
import { ConfigProvider } from 'antd'

const store = configureStore()

export const api = configureApi(store)

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider locale={ruRu}>
        <Routes />
        <NotificationContainer />
      </ConfigProvider>
    </Provider>
  )
}

export default App
