import React, { useEffect, useRef, useState } from "react";
import { Viewer as PSViewer } from "photo-sphere-viewer";
import { VideoPlugin } from "photo-sphere-viewer/dist/plugins/video";
import { EquirectangularVideoAdapter } from "photo-sphere-viewer/dist/adapters/equirectangular-video";
import "photo-sphere-viewer/dist/plugins/video.css";
import { BASE_URL_WITHOUT_API } from "../../../../../constants/api";
import { getFileFormat } from "../../../../../utils/helpers/helpers";

const Panoram = ({ file, bookId }) => {
  const viewerRef = useRef(null);
  const fileFormat = getFileFormat(file.filename);

  const [viewerInst, setViewerInst] = useState(null);

  useEffect(() => {
    if (viewerRef.current !== null) {
      updatePanoram();
    }
  }, [file, bookId]);

  const updatePanoram = () => {
    if (viewerInst !== null) {
      viewerInst.destroy();
    }

    let _viewerInst = null;
    switch (fileFormat.toUpperCase()) {
      case "MP4":
      case "WEBM":
      case "3GP":
      case "MPEG":
      case "AVI":
      case "SWF":
      case "WMV":
        _viewerInst = new PSViewer({
          container: viewerRef.current,
          adapter: EquirectangularVideoAdapter,
          panorama: {
            source: `${BASE_URL_WITHOUT_API}/public/files/library/${bookId}/${file.id}/${file.filename}`,
          },
          plugins: [VideoPlugin],
        });
        break;
      case "PNG":
      case "JPG":
      case "JPEG":
        _viewerInst = new PSViewer({
          container: viewerRef.current,
          panorama: `${BASE_URL_WITHOUT_API}/public/files/library/${bookId}/${file.id}/${file.filename}`,
        });
        break;
      default:
        _viewerInst = null;
        break;
    }
    setViewerInst(_viewerInst);
  };


  return (
    <div
      ref={viewerRef}
      style={{
        backgroundColor: "red",
        width: "100%",
        height: "600px",
      }}
    ></div>
  );
};

export default Panoram;
