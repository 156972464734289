import jsPDF from 'jspdf'
import moment from 'moment'
import React from 'react'
import addFont from '../../../fonts/fonts'
import PdfPrintPanel from '../../PDFPrint/Panel'
import { getDocumentList } from '../../../api/documents/documents'
import { createNotification } from '../../notification/notification'


const columns = [
  { header: '№', dataKey: 'num' },
  { header: 'Вид документа', dataKey: 'type' },
  { header: 'Номер документа', dataKey: 'documentnumber' },
  { header: 'Сумма документа', dataKey: 'sum' },
  { header: 'Дата документа', dataKey: 'documentdate' },
  { header: 'Дата записи КСУ', dataKey: 'document_arrival_date' },
  { header: 'ИНН', dataKey: 'inn' },
  { header: 'КПП', dataKey: 'kpp' },
  { header: 'Телефон', dataKey: 'phone' },
]

export const WriteOfActPDFPDFPrint = ({
  searchData,
  selectedIds,
  doctypes,
  institutionsList
}) => {
  const title = 'Первичные документы'
  const orientation = 'portrait'

  const createDocData = async () => {
    
    const filters = {
      from: moment(searchData.from).format('DD.MM.YYYY'),
      to: moment(searchData.to).format('DD.MM.YYYY'),
      doctype: searchData.documenttype 
        ? doctypes.find(i => i.id === parseInt(searchData.documenttype)).name
        : 'Все',
      supplier: searchData.supplier
        ? institutionsList.find(i => i.value === searchData.supplier).label
        : 'Не указано'
    }

    const data = await getDocumentList({
      ...searchData,
      maxResults: undefined,
      paginateFrom: undefined
    })

    let dataForPrint

    if (data.data) {
      dataForPrint = selectedIds.length
        ? data.data.filter(item => selectedIds.includes(item.id))
        : data.data
    } else {
      createNotification('error', 'Данные отсутствуют')
      return
    }

    const _dataForPrint = dataForPrint.map((item, i) => ({
      ...item, 
      num: i + 1,
      sum: item.sum || 'n/a',
      documentdate: moment(item.documentdate).format('DD.MM.YYYY'),
      document_arrival_date: moment(item.document_arrival_date).format('DD.MM.YYYY')
    }))
    
    addFont(jsPDF.API)
    const doc = new jsPDF({ orientation: orientation })
    
    doc.setFont("Montserrat")

    doc.text(`За период с ${filters.from} по ${filters.to}`, 15, 15);
    doc.text(`Вид документа: ${filters.doctype}`, 15, 25);
    doc.text(`Источник: ${filters.supplier}`, 15, 35);

    doc.autoTable({
      body: _dataForPrint,
      columns: columns, 
      styles: { font: 'Montserrat', fontSize: 7 },
      startY: doc.pageCount > 1? doc.autoTableEndPosY() + 10 : 50,
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      columnStyles: {
      }
    })


    doc.addPage('a4', orientation)
    doc.deletePage(doc.getNumberOfPages())
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
}
