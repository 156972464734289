import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  reportGetRequestStart,
  reportGetAllRequestStart,
} from "../../../redux/actions/reports/get";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import ElectronicResourcesUsageTable from "../../../components/Reports/ElectronicResourcesUsage/Table";
import ElectronicResourcesReportForm from "../../../components/Reports/ElectronicResourcesUsage/Form";
import { setReportsSearchData } from "../../../redux/actions/reports/searchData";
import { setReportsFormData } from "../../../redux/actions/reports/formData";
import { Col } from "react-bootstrap";
import Pagination from "../../../components/Pagination";

const mapStateToProps = ({ reports }) => ({
  data: reports.report,
  dataAll: reports.reportAll,
  loader: reports.loader,
  loaderAll: reports.loaderAll,
  searchData: reports.search,
  form: reports.form,
});

const mapDispatchToProps = (dispatch) => ({
  requestReport: (id, formData) =>
    dispatch(reportGetRequestStart(id, formData)),
  requestReportAll: (id, formData) =>
    dispatch(reportGetAllRequestStart(id, formData)),
  setSearchData: (data) => dispatch(setReportsSearchData(data)),
  setFormData: (data) => dispatch(setReportsFormData(data)),
});

const ElectronicResourcesUsageReport = ({
  ownId,
  data,
  dataAll,
  requestReport,
  requestReportAll,
  loader,
  loaderAll,
  searchData,
  setSearchData,
  form,
  setFormData,
}) => {
  
  const requestData = (data) => {
    requestReport({
      id: ownId,
      formData: data,
    });
    requestReportAll({
      id: ownId,
      formData: data,
    });
  };

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestReport({
      id: ownId,
      formData: form,
    });
  };

  return (
    <SceneContainer title="Отчеты / Статистика использования электронных ресурсов">
      
      <ElectronicResourcesReportForm
        onSubmit={requestData}
        data={form}
        setData={setFormData}
      />

      <Col md={12}>
        {loader ? (
          <SpinnerOuter isLoading={loader} />
        ) : (
          (data[ownId] &&
            dataAll[ownId]) &&
            <div>
              <Pagination
                count={searchData.count}
                pageCount={searchData.pageCount}
                current={searchData.page}
                setCurrent={setCurrentPage}
              />
              <ElectronicResourcesUsageTable
                data={data[ownId]}
                dataAll={dataAll[ownId]}
              />
              <Pagination
                count={searchData.count}
                pageCount={searchData.pageCount}
                current={searchData.page}
                setCurrent={setCurrentPage}
              />
            </div>
          
        )}
      </Col>
    </SceneContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ElectronicResourcesUsageReport);
