import { SET_ELECTRONIC_BOOK_FORM_DATA } from "../../../actions/action-types";

const initialState = {
    format: "rusmarc",
    file: null
};

export const ElectronicBookFormReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_ELECTRONIC_BOOK_FORM_DATA:
            return action.payload;

        default: return state;
    }
};