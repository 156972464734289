import { SCHEDULER_CATEGORY_COUNT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const schedulerCategoriesCountRequestStart = (id) => ({
    type: `${SCHEDULER_CATEGORY_COUNT_REQUEST}${START}`,
    payload: id
});

export const schedulerCategoriesCountRequestSuccess = (data) => ({
    type: `${SCHEDULER_CATEGORY_COUNT_REQUEST}${SUCCESS}`,
    payload: data
});

export const schedulerCategoriesCountRequestError = (error) => ({
    type: `${SCHEDULER_CATEGORY_COUNT_REQUEST}${ERROR}`,
    payload: error
});
