import React, {useEffect} from "react";
import {connect} from "react-redux";
import {setDuplicateSearchData} from "../../redux/actions/duplicate/search";
import {duplicateActions} from "../../redux/factory/duplicateReduxData";
import Pagination from "../../components/Pagination";
import Items from "../../components/Duplicate/Items";
import styles from "./DuplicateScene.module.css";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({duplicate}) => ({
    searchData: duplicate.search,
    list: duplicate.list,
    count: duplicate.count,
    books: duplicate.books
});

const matchDispatchToProps = {
    setSearchData: setDuplicateSearchData,
    requestList: duplicateActions.countRequestStart,
    requestBook: duplicateActions.booksRequestStart,
    requestBooksClose: duplicateActions.booksRequestSuccess,
    mergeBooks: duplicateActions.mergeRequestStart,
};

const Duplicate = ({
                       count,
                       searchData,
                       setSearchData,
                       requestList,
                       list = [],
                       requestBook,
                       books,
                       requestBooksClose,
                       mergeBooks
                   }) => {

    useEffect(() => {
        requestList();
    }, [requestList]);

    const setCurrentPage = page => {
        setSearchData({...searchData, page});
        requestList();
    };

    const PaginationItem = () => (
        <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={setCurrentPage}
        />
    );

    return (
        <SceneContainer title={'Исключение дублетных записей'}>
            <PaginationItem/>

            {searchData.loader && (
                <div className={styles.loader}>
                    <SpinnerOuter isLoading={true}/>
                    <div className={styles.loader_text}>
                        Загрузка данной страницы займёт время. Пожалуйста ожидайте.
                    </div>
                </div>
            )}
            <Items
                disabled={searchData.loader}
                data={list}
                requestBook={requestBook}
                books={books}
                requestBooksClose={requestBooksClose}
                mergeBooks={mergeBooks}
            />

            <PaginationItem/>
        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(Duplicate);
