import { NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const orderBooksDeleteRequestStart = (data) => ({
    type: `${NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST}${START}`,
    payload: data
});

export const orderBooksDeleteRequestSuccess = (data) => ({
    type: `${NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST}${SUCCESS}`,
    payload: data
});

export const orderBooksDeleteRequestError = (data) => ({
    type: `${NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST}${ERROR}`,
    payload: data
});
