import { call, put, takeLatest } from "redux-saga/effects";
import { LIBRARY_TYPES_LIST_REQUEST, START } from "../../../../actions/action-types";
import { libraryTypesListRequestError, libraryTypesListRequestSuccess } from "../../../../actions/library/types/list";
import { libraryTypesList } from "../../../../../api/library/types";

export default function* libraryTypesListWatcher() {
  yield takeLatest(`${LIBRARY_TYPES_LIST_REQUEST}${START}`, libraryTypesListWorker)
}

function* libraryTypesListWorker() {
  try {
    const response = yield call(libraryTypesList);
    yield put(libraryTypesListRequestSuccess(response.data));
  } catch (error) {
    yield put(libraryTypesListRequestError(error))
  }
}