import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bookScanOrderCountRequestStart } from '../../redux/actions/bookScanOrders/count'
import { bookScanOrderDeleteRequestStart } from '../../redux/actions/bookScanOrders/delete'
import { setBookScanOrderFormData } from '../../redux/actions/bookScanOrders/form'
import { setBookScanOrderSearchData } from '../../redux/actions/bookScanOrders/search'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import Pagination from '../../components/Pagination'
import BookScanOrdersForm from '../../components/BookScanOrders/Form'
import BookScanOrdersList from '../../components/BookScanOrders/List'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { api } from '../../App'

const mapStateToProps = ({ bookScanOrders }) => ({
  list: bookScanOrders.list,
  count: bookScanOrders.count,
  loader: bookScanOrders.loader,
  formData: bookScanOrders.form,
  searchData: bookScanOrders.search,
})

const mapDispatchToProps = {
  requestList: bookScanOrderCountRequestStart,
  deleteItem: bookScanOrderDeleteRequestStart,
  setFormData: setBookScanOrderFormData,
  setSearchData: setBookScanOrderSearchData,
}

const BookScanOrdersScene = ({
  list,
  count,
  loader,
  formData,
  searchData,
  requestList,
  deleteItem,
  setFormData,
  setSearchData,
}) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  const [typeOrder, setTypeOrder] = useState(null)

  useEffect(() => {
    api
      .get('/ordertype')
      .then(res => setTypeOrder(res.data))
      .catch(err => console.log(err))
  }, [])

  const setSort = (sorting) => {
    if (!sorting) return;

    const newSearchData = {...searchData, page: 1}

    const changeToDesc = Object.keys(searchData).includes('sortBy') && Object.values(searchData).includes(sorting) 

    delete newSearchData[changeToDesc ? 'sortBy' : 'sortByD']
    newSearchData[changeToDesc ? 'sortByD' : 'sortBy'] = sorting
    
    setSearchData(newSearchData)
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  return (
    <SceneContainer title={'Заказанные документы'}>
      <BookScanOrdersForm onSubmit={requestList} setFormData={setFormData} formData={formData} typeOrder={typeOrder} />

      <PaginationComponent />

      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        <BookScanOrdersList
          list={list}
          deleteItem={deleteItem}
          typeOrder={typeOrder}
          sortBy={searchData.sortBy}
          setSort={setSort}
        />
      )}

      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BookScanOrdersScene)
