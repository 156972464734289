import React, {useState} from "react";
import styles from "./Items.module.css";
import Item from "../Item";

const Items = ({ data, requestBook, books, requestBooksClose, mergeBooks, disabled }) => {
  const [collapseId, setCollapseId] = useState(false);

  return (
    <div className={styles.items}>
      {data.map((item, index) => (
        <Item
          key={index}
          mergeBooks={mergeBooks}
          item={item}
          disabled={disabled}
          requestBook={requestBook}
          books={books}
          collapseId={collapseId}
          setCollapseId={setCollapseId}
          index={data.indexOf(item)}
          requestBooksClose={requestBooksClose}
        />
      ))}
    </div>
  );
};

export default Items;
