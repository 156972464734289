import { SET_PERMISSIONS_BY_CATEGORY_SEARCH_DATA } from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { ACCESS_CATEGORIES_SORT } from "../../../../constants/sort/accessCategoriesSort";

const initialState = {
  page: 1,
  orderBy: ACCESS_CATEGORIES_SORT.NAME,
  sortDirection: SORT_DIRECTION.ASC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const accessCategorySearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PERMISSIONS_BY_CATEGORY_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};
