import React, { useState } from 'react'
import T from 'prop-types'
import styles from './AdminUsersTable.module.css'
import { ADD, EDIT } from '../../../constants/modalTypes'
import DataTableCol from '../../DataTable/Column'
import DataTable from '../../DataTable'
import { api } from '../../../App'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import { createNotification } from '../../notification/notification'
import { Button } from 'antd'

const AdminUsersTable = ({ data, setModal, sortBy, setSort, selectedId, handleSelect, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false)

  const openModal = item => {
    setModal({
      modalType: EDIT,
      data: item,
    })
  }

  const connectionWithMO = async () => {
    try {
      await setIsLoading(true)
      const response = await api.get('/hlms/api/users')
      await setIsLoading(false)
      createNotification('success', `Связано ${response.data.length} пользователя`)
    } catch {
      await setIsLoading(false)
      createNotification('error', 'Не удалось связать пользователей')
    }
  }

  return (
    <div className={styles.container}>
      <Button
        type='primary'
        className={styles.button}
        onClick={() =>
          setModal({
            modalType: ADD,
            data: {
              lastname: '',
              firstname: '',
              patronymic: '',
              birthdate: '',
              rfid: '',
              login: '',
              password: '',
            },
          })
        }
      >
        Добавить
      </Button>
      <Button
        type='danger' className={styles.button} onClick={onDelete}>
        Удалить
      </Button>
      <Button
        type='primary' className={styles.button} onClick={connectionWithMO}>
        Связать с "Образованием МО
      </Button>
      {isLoading && <SpinnerOuter />}
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openModal}>
        <DataTableCol title='ФИО' value='displayname' className={styles.leftAlign} colSpan={4} />
        <DataTableCol title='Логин' value='login' />
        <DataTableCol title='Образование МО' resolver={item => <input type='checkbox' checked={item.hlms_mid > 0} />} />
        <DataTableCol
          title='Выбрано'
          passClicks
          resolver={item => <input type='checkbox' checked={item.mid === selectedId} onChange={() => handleSelect(item.mid)} />}
        />
      </DataTable>
    </div>
  )
}

AdminUsersTable.propTypes = {
  data: T.array,
  setModal: T.func,
  sortBy: T.string,
  setSort: T.func,
  selectedId: T.number,
  handleSelect: T.func,
  onDelete: T.func,
}

AdminUsersTable.defaultProps = {
  data: [],
}

export default AdminUsersTable
