import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import styles from './AdditionalDataTab.module.css'
import SearchableSelect from '../../../Select'
import { api } from '../../../../App'
import { categoriesToOptions, categoryToOption, institutionsToOptions } from '../../../../utils/toSelectOption'
import { SELECT_TYPE } from '../../../../constants/selectType'
import { SCIENCE_DEGREE_OPTIONS } from '../../../../constants/scienceDegree'
import { getYearsList } from '../../../../utils/helpers/helpers'


const AdditionalDataTab = ({ data, setData, formErrors }) => {
  
  const [guideList, setGuideList] = useState([])
  const [industryList, setIndustryList] = useState([])
  const [workStatusList, setWorkStatusList] = useState([])
  const [workPlaceList, setWorkPlaceList] = useState([])
  const [scienceGradeList, setScienceGradeList] = useState([])
  const [organizationsList, setOrganizationsList] = useState([])

  useEffect(() => {
    getListFunction('/grnti', setGuideList)
    getListFunction('/industries', setIndustryList)
    getListFunction('/book_work_status', setWorkStatusList)
    getListFunction('/book_work_place', setWorkPlaceList)
    getListFunction('/book_science_grade', setScienceGradeList)
    getListFunction('/xp_client', setOrganizationsList)
  }, [])
  
  const getListFunction = async (link, setFunction) => {
    const response  = await api.get(link)
    setFunction(response.data)
  }

  const onChange = ({ target: { value, name } }) => {
    setData({ ...data, [name]: value })
  }

  const setSearchableSelectValue = (fieldName) => ({value}) => {
    setData({...data, [fieldName]: value})
  }
  
  const valueToOption = (fieldName, optionsList) => {
    if(!data[fieldName]) return { label: 'Не выбрано', value: null }
    const returnWithoutModify = ['science_status', 'science_degree'].includes(fieldName) 
    const listItem = optionsList.find(({id, idclient, value}) => (idclient || id || value) == data[fieldName])
    return returnWithoutModify ? listItem : categoryToOption(listItem)
  }
  
  const addNewSelectItem = (value, fieldName) => {
    let url, callback
    switch (fieldName) {
      case 'work_status':
        url = '/book_work_status'
        callback = setWorkStatusList
        break;
      case 'work_place':
        url = '/book_work_place'
        callback = setWorkPlaceList
        break;
      case 'science_grade':
        url = '/book_science_grade'
        callback = setScienceGradeList
        break;
      default:
        break;
    }
    url && value.length > 2 &&
      addNewValue(url, value, callback)
  }
  
  const addNewValue = async (url, value, callback) => {
    await api.post(url, {name: value})
    getListFunction(url, callback)
  }

  return (
    <div>
      <div className={styles.title}>Область заглавия и сведений об ответственности</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Параллельное название'
            name='titleParallelName'
            value={data.titleParallelName}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Последующие сведения об ответственности'
            name='titleInfo'
            value={data.titleInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Сведения о переводе с другого языка'
            name='translation'
            value={data.translation}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        {/*<Col md={6}>
            <ModalInputWrapper
                label="Литературный жанр"
                name="genresId"
                value={data.genresId}
                onChange={onChange}
            />
        </Col>*/}
        <Col md={12}>
          <ModalInputWrapper label='Тип материала' name='contenttypesid' value={data.contenttypesid} onChange={onChange} />
        </Col>
      </Row>
      <div className={styles.title}>Область издания</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label='Сведения об издании' name='pubInfo' value={data.pubInfo} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Параллельные сведения об издании'
            name='pubParallelInfo'
            value={data.pubParallelInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Сведения об ответственности, относящиеся к изданию'
            name='pubResponsibility'
            value={data.pubResponsibility}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Дополнительные сведения об издании'
            name='pubAdditionalInfo'
            value={data.pubAdditionalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Сведения об ответственности, относящиеся к дополнительным сведениям об издании'
            name='pubAdditionalInfoResponsibility'
            value={data.pubAdditionalInfoResponsibility}
            onChange={onChange}
          />
        </Col>
      </Row>
      
      <div className={styles.title}>Карточка учета диссертаций, научных трудов</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper error={formErrors.organization} label='Организация*'>
            <SearchableSelect
              placeholder="Поиск"
              options={institutionsToOptions(organizationsList)}
              onChange={setSearchableSelectValue('organization')}
              value={valueToOption('organization', organizationsList)}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <ModalInputWrapper
            label='Фамилия, имя, отчество автора*'
            name='fio'
            error={formErrors.fio}
            value={data.fio}
            onChange={onChange}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper error={formErrors.work_status} label="Статус работы*">
            <SearchableSelect
              selectType={SELECT_TYPE.SCIENCE_WORKS}
              onEnterCallback={addNewSelectItem}
              name='work_status'
              placeholder="Поиск"
              options={categoriesToOptions(workStatusList)}
              onChange={setSearchableSelectValue('work_status')}
              value={valueToOption('work_status', workStatusList)}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Название работы*'
            name='job_title'
            error={formErrors.job_title}
            value={data.job_title}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label='Шифр и наименование специальности'>
            <SearchableSelect
              placeholder="Поиск"
              options={categoriesToOptions(guideList)}
              onChange={setSearchableSelectValue('guide')}
              value={valueToOption('guide', guideList)}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ModalInputWrapper label='Искомая степень'>
            <SearchableSelect
              placeholder="Поиск"
              options={SCIENCE_DEGREE_OPTIONS}
              onChange={setSearchableSelectValue('science_degree')}
              value={valueToOption('science_degree', SCIENCE_DEGREE_OPTIONS)}
            />
          </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <ModalInputWrapper label='Искомая отрасль науки'>
            <SearchableSelect
              placeholder="Поиск"
              options={categoriesToOptions(industryList)}
              onChange={setSearchableSelectValue('industry')}
              value={valueToOption('industry', industryList)}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Фамилия, имя, отчество научного руководителя или консультанта'
            name='science_fio'
            value={data.science_fio}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ModalInputWrapper label='Ученая степень научного руководителя или консультанта'>
            <SearchableSelect
              placeholder="Поиск"
              options={SCIENCE_DEGREE_OPTIONS}
              onChange={setSearchableSelectValue('science_status')}
              value={valueToOption('science_status', SCIENCE_DEGREE_OPTIONS)}
            />
          </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <ModalInputWrapper label='Ученое звание научного руководителя или консультанта'>
            <SearchableSelect
              selectType={SELECT_TYPE.SCIENCE_WORKS}
              onEnterCallback={addNewSelectItem}
              name='science_grade'
              placeholder="Поиск"
              options={categoriesToOptions(scienceGradeList)}
              onChange={setSearchableSelectValue('science_grade')}
              value={valueToOption('science_grade', scienceGradeList)}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <ModalInputWrapper label='Место написания работы'>
            <SearchableSelect
              selectType={SELECT_TYPE.SCIENCE_WORKS}
              onEnterCallback={addNewSelectItem}
              name='work_place'
              placeholder="Поиск"
              options={categoriesToOptions(workPlaceList)}
              onChange={setSearchableSelectValue('work_place')}
              value={valueToOption('work_place', workPlaceList)}
            />
          </ModalInputWrapper>
        </Col>
        <Col md={4}>
          <ModalInputWrapper error={formErrors.year} label='Год написания работы*'>
            <select name='year' value={data.year} onChange={onChange}>
              <option value=''>Выбрать год</option>
              {getYearsList().map(year => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>

      <div className={styles.title}>Область выходных данных</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Сведения о функции издателя, распространителя'
            name='pubPurpose'
            value={data.pubPurpose}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='МЕСТО ВЫПУСКА (ИЗДАНИЯ) РЕСУРСА'
            name='pubManufacturePlace'
            value={data.pubManufacturePlace}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='ИМЯ ИЗДАТЕЛЯ РЕСУРСА'
            name='pubManufactureName'
            value={data.pubManufactureName}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label='АДРЕС ИЗДАТЕЛЯ' name='publishersaddress' value={data.publishersaddress} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='ТЕЛЕФОН ИЗДАТЕЛЯ'
            name='publishersphonenumber'
            value={data.publishersphonenumber}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Дата изготовления'
            type='date'
            name='pubManufactureDate'
            value={moment(data.pubManufactureDate).format('YYYY-MM-DD')}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Область физической характеристики</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Специфическое обозначение материала и объем'
            name='physMaterial'
            value={data.physMaterial}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Другие сведения о физической характеристике'
            name='physAdditionalInfo'
            value={data.physAdditionalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Сведения о сопроводительном материале'
            name='physAdditionalMaterial'
            value={data.physAdditionalMaterial}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ModalInputWrapper label='Размеры' name='physSizes' value={data.physSizes} onChange={onChange} />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            label='Количество формул'
            name='statFormulaCount'
            value={data.statFormulaCount}
            onChange={onChange}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label='Количество таблиц' name='statTableCount' value={data.statTableCount} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ModalInputWrapper
            label='Количество интерактивных изображений'
            name='statAnimationsCount'
            value={data.statAnimationsCount}
            onChange={onChange}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label='Количество тестов' name='statTestCount' value={data.statTestCount} onChange={onChange} />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            label='Количество интерактивных тестов'
            name='statAnimationTestCount'
            value={data.statAnimationTestCount}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Международный формат (типографский формат)'
            name='typographFormat'
            value={data.typographFormat}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Область серии</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Параллельная серия или подсерии'
            name='seriesParallelName'
            value={data.seriesParallelName}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Сведения, относящиеся к заглавию серии или подсерии'
            name='seriesInfo'
            value={data.seriesInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Сведения об ответственности, относящиеся к серии или подсерии'
            name='seriesResponsibility'
            value={data.seriesResponsibility}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ModalInputWrapper label='ISSN' name='seriesISSN' value={data.seriesISSN} onChange={onChange} />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            label='Номер выпуска серии или подсерии'
            name='seriesReleaseNum'
            value={data.seriesReleaseNum}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Область стандартного номера (и его альтернативы) и условий доступности</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label='Ключевое заглавие' name='keyTitle' value={data.keyTitle} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            label='Стандартный номер (или его альтернатива)'
            name='stdNum'
            value={data.stdNum}
            onChange={onChange}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            label='Условия доступности и(или) цена'
            name='priceOrAccessConditions'
            value={data.priceOrAccessConditions}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Дополнительные сведения к элементам области'
            name='additionalInfo'
            value={data.additionalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label='Специфические сведения'
            name='spesificalInfo'
            value={data.spesificalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
    </div>
  )
}

export default AdditionalDataTab
