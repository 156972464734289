import { api } from '../../App'

export const fetchTestsCount = searchData => {
  const params = {
    count: true,
    ...searchData,
  }

  return api.get('/tests', { params })
}

export const fetchTestsList = (searchData, paginateFrom) => {
  const params = {
    maxResults: 15,
    paginateFrom,
    ...searchData,
  }

  return api.get('/tests', { params })
}

export const uploadTestsFile = ({ id, file }) => {
  return api.put(`/tests/${id}/file`, file)
}
