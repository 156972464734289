import { institutionsToOptions } from "../../../../utils/toSelectOption";
import { INSTITUTIONS_ALL_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const institutionsAllReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${INSTITUTIONS_ALL_LIST_REQUEST}${SUCCESS}`: {
      return institutionsToOptions(action.payload);
    }

    default: return state;
  }
};
