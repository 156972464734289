import { all } from 'redux-saga/effects';
import militaryRankSaga from './militaryRank';
import priceCoeffSaga from './priceCoeff';


export default function* commonSaga() {
  yield all([
    militaryRankSaga(),
    priceCoeffSaga()
  ]);
}