import { call, put, takeLatest, select } from "redux-saga/effects";
import {getTeacherCount, getTeacherList} from "../../../api/teachers/teachers";
import {START, TEACHERS_COUNT_REQUEST} from "../../actions/action-types";
import {teachersCountRequestError, teachersCountRequestSuccess} from "../../actions/teachers/count";
import {
  teachersListRequestError,
  teachersListRequestStart,
  teachersListRequestSuccess
} from "../../actions/teachers/list";
import {teachersSearchRequest} from "../../actions/teachers/search";

export default function* teachersWatcher() {
  yield takeLatest(`${TEACHERS_COUNT_REQUEST}${START}`, teachersRequestCountWorker);
  yield takeLatest(`${TEACHERS_COUNT_REQUEST}${START}`, teachersRequestListWorker);
}

function* teachersRequestCountWorker() {
  const searchData = yield select(({ teachers }) => teachers.search);
  try {
    const response = yield call(getTeacherCount, searchData.query);
    const count = response.data[0].count;
    yield put(teachersCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(teachersSearchRequest({ ...searchData, pageCount}));
  } catch (error) {
    yield put(teachersCountRequestError(error));
  }
}

function* teachersRequestListWorker() {
  yield put(teachersListRequestStart());
  const searchData = yield select(({ teachers }) => teachers.search);
  const paginateFrom = searchData.maxResults ? Math.max(searchData.maxResults * (searchData.page - 1), 0) : 1;
  try {
    const response = yield call(getTeacherList, searchData.maxResults, paginateFrom, searchData.query);
    yield put(teachersListRequestSuccess(response.data));
  } catch (error) {
    yield put(teachersListRequestError(error));
  }
}