import { combineReducers } from 'redux'
import { turnoutStatsCountReducer } from './count/turnoutStatsCountReducer'
import { turnoutStatsListReducer } from './list/turnoutStatsListReducer'
import { turnoutStatsSearchReducer } from './search/turnoutStatsSearchReducer'
import { turnoutStatsFormReducer } from './formData/turnoutStatsFormReducer'

export const turnoutStatsReducer = combineReducers({
  count: turnoutStatsCountReducer,
  list: turnoutStatsListReducer,
  search: turnoutStatsSearchReducer,
  form: turnoutStatsFormReducer,
})
