import {DOCUMENTS_SEARCH_LIST, ERROR, START, SUCCESS} from "../../action-types";

export const documentsListRequestStart = (data) => ({
    type: `${DOCUMENTS_SEARCH_LIST}${START}`,
    payload: data
})

export const documentsListRequestSuccess = (data) => ({
    type: `${DOCUMENTS_SEARCH_LIST}${SUCCESS}`,
    payload: data
})

export const documentsListRequestError = (data) => ({
    type: `${DOCUMENTS_SEARCH_LIST}${ERROR}`,
    payload: data
})