import {BIBLIOGRAPHY_LIST_REQUEST, START} from "../../actions/action-types";
import {loadBibliographyList} from "../../../api/bibliography/bibliography";
import {takeLatest, call, put} from "@redux-saga/core/effects";
import {bibliographyListRequestError, bibliographyListRequestSuccess} from "../../actions/bibliography/list";


export default function* bibliographyWatcher() {
    yield takeLatest(`${BIBLIOGRAPHY_LIST_REQUEST}${START}`, listWorker);
}

function* listWorker({ payload: periodId }) {
    try {
        const response = yield call(loadBibliographyList, periodId);
        yield put(bibliographyListRequestSuccess(response.data.milspecs));
    } catch(error) {
        yield put(bibliographyListRequestError(error));
    }
}
