import { createNotification } from "../../../../components/notification/notification";
import {ERROR, LIBRARY_RESPONSIBLE_CREATE_REQUEST, START, SUCCESS} from "../../action-types";

export const createResponsibleRequestStart = (payload) => ({
    type: `${LIBRARY_RESPONSIBLE_CREATE_REQUEST}${START}`,
    payload
});

export const createResponsibleRequestSuccess = (payload) => {
    createNotification('success', 'Ответственный создан успешно')
    return {
        type: `${LIBRARY_RESPONSIBLE_CREATE_REQUEST}${SUCCESS}`,
        payload
    }
};

export const createResponsibleRequestError = (payload) => {
    createNotification('success', 'Ошибка создания ответственного')
    return {
        type: `${LIBRARY_RESPONSIBLE_CREATE_REQUEST}${ERROR}`,
        payload
    }
};