import { call, put, takeLatest, select } from 'redux-saga/effects'
import { getTurnoutStatsCount, getTurnoutStatsForUser } from '../../../api/turnoutStats/stats'
import { TURNOUT_STATS_LIST_REQUEST, TURNOUT_STATS_COUNT_REQUEST, START } from '../../actions/action-types'

import { turnoutStatsCountRequestError, turnoutStatsCountRequestSuccess } from '../../actions/turnoutStats/count'
import {
  turnoutStatsListRequestError,
  turnoutStatsListRequestStart,
  turnoutStatsListRequestSuccess,
} from '../../actions/turnoutStats/list'
import { setTurnoutStatsSearchData } from '../../actions/turnoutStats/searchData'

export default function* turnoutStatsWatcher() {
  yield takeLatest(`${TURNOUT_STATS_LIST_REQUEST}${START}`, listWorker)
  yield takeLatest(`${TURNOUT_STATS_COUNT_REQUEST}${START}`, countWorker)
}

function* countWorker() {
  const formData = yield select(({ turnoutStats }) => turnoutStats.form)
  const searchData = yield select(({ turnoutStats }) => turnoutStats.search)
  try {
    const response = yield call(getTurnoutStatsCount, formData)
    const count = parseInt(response.data.count, 10)
    yield put(turnoutStatsCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setTurnoutStatsSearchData({ ...searchData, pageCount }))
    yield put(turnoutStatsListRequestStart())
  } catch (error) {
    yield put(turnoutStatsCountRequestError(error))
  }
}

function* listWorker() {
  const formData = yield select(({ turnoutStats }) => turnoutStats.form)
  const searchData = yield select(({ turnoutStats }) => turnoutStats.search)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)

  try {
    const response = yield call(getTurnoutStatsForUser, searchData, formData )
    yield put(turnoutStatsListRequestSuccess(response.data))
  } catch (e) {
    yield put(turnoutStatsListRequestError(e))
  }
}
