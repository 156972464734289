import {DOCUMENT_INSTANCE_LIST_REQUEST, START, SUCCESS, ERROR} from "../../action-types";

export const documentInstancesListRequestStart = (documentId) => ({
    type: `${DOCUMENT_INSTANCE_LIST_REQUEST}${START}`,
    payload: documentId
});

export const documentInstancesListRequestSuccess = (data) => ({
    type: `${DOCUMENT_INSTANCE_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const documentInstancesListRequestError = (data) => ({
    type: `${DOCUMENT_INSTANCE_LIST_REQUEST}${ERROR}`,
    payload: data
});
