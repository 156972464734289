import { ERROR, GUK_TAB_DATA_REQUEST, START, SUCCESS } from "../action-types";

export const gukTabDataRequestStart = () => ({
  type: `${GUK_TAB_DATA_REQUEST}${START}`
});

export const gukTabDataRequestSuccess = (data) => ({
  type: `${GUK_TAB_DATA_REQUEST}${SUCCESS}`,
  payload: data
});

export const gukTabDataRequestError = (data) => ({
  type: `${GUK_TAB_DATA_REQUEST}${ERROR}`,
  payload: data
});