import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import styles from './GUK.module.css'
import SearchableSelect from '../../../Select'
import {
  authorsToOptions,
  categoriesToOptions,
  disciplineToOptions,
  optionsToAuthors,
  optionsToCategories,
  publishersToOptions,
} from '../../../../utils/toSelectOption'
import { setDisciplineSearchData } from '../../../../redux/actions/discipline/searchData'
import { connect } from 'react-redux'
import { api } from '../../../../App'
import { SELECT_TYPE } from '../../../../constants/selectType'
import SpecialPurpose from '../../Field/SelectPurpose'
import { setAuthorsSearchData } from '../../../../redux/actions/authors/searchData'
import { authorsCountRequestStart } from '../../../../redux/actions/authors/count'

const mapStateToProps = ({ discipline, authors }) => ({
  disciplineSearch: discipline.search,
  authorsList: authors.list,
  authorsSearch: authors.search,
})

const mapDispatchToProps = {
  setDisciplineSearch: setDisciplineSearchData,
  setAuthorSearch: setAuthorsSearchData,
  requestAuthors: authorsCountRequestStart,
}

const options_eerKindId = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Электронный документ (ЭД)' },
  { value: 2, label: 'Электронное издание (ЭИ)' },
]

const options_eerTechPeriodId = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Непериодическое электронное издание' },
  { value: 2, label: 'Периодическое сериальное электронное издание' },
  { value: 3, label: 'Продолжающееся сериальное электронное издание' },
  { value: 4, label: 'Продолжающееся электронное издание' },
  { value: 5, label: 'Обновляемое сериальное электронное издание' },
]
const options_eerTechStructId = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Однотомное электронное издание' },
  { value: 2, label: 'Многотомное электронное издание' },
  { value: 3, label: 'Электронная серия' },
]
const options_eerOutPlagiaryId = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Проверено' },
  { value: 2, label: 'Не проверено' },
]
const options_OS = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Windows' },
  { value: 2, label: 'Linux' },
  { value: 3, label: 'Mac OS' },
  { value: 4, label: 'Windows / Linux / Mac OS' },
]
const options_VideoSystem = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'SVGA' },
  { value: 2, label: 'HD' },
  { value: 3, label: 'Видеокарта с ускорителем' },
]
const options_AudioSystem = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Не требуется' },
  { value: 2, label: '2.0' },
  { value: 3, label: '5.1' },
]
const categoryGukArray = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Основная литература' },
  { value: 2, label: 'Дополнительная литература' },
  { value: 3, label: 'Прочая литература' },
]
const typeOfEducation = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Общее дошкольное образование' },
  { value: 2, label: 'Начальное общее образование' },
  { value: 3, label: 'Основное общее образование' },
  { value: 4, label: 'Среднее общее образование' },
  { value: 5, label: 'Среднее профессиональное образование' },
  { value: 6, label: 'Высшее образование - бакалавриат' },
  { value: 7, label: 'Высшее образование - специалитет' },
  { value: 8, label: 'Высшее образование - магистратура' },
  { value: 9, label: 'Высшее образование - подготовка кадров высшей квалификации' },
  { value: 10, label: 'Дополнительное образование детей и взрослых' },
  { value: 11, label: 'Дополнительное профессиональное образоване' },
]

const techCommunics = [
  { name: 'Не выбрано', id: 0 },
  { name: 'детерминированный (предопределенным автором)', id: 1 },
  { name: 'интерактивный', id: 2 },
]

const GUKTab = ({
  data,
  setData,
  selectData,
  setDisciplineSearch,
  disciplineSearch,
  setAuthorSearch,
  requestAuthors,
  authorsSearch,
  authorsList,
}) => {
  const selectors = selectData.data
  const [disciplineList, setDisciplineList] = useState(null)
  const [FGOSSpecializations, setFGOSSpecializations] = useState([])
  const [FGOSTrainingDirections, setFGOSTrainingDirections] = useState([])
  const [users, setUsers] = useState([])

  useEffect(() => {
    api.get('/people').then(r => setUsers(r.data))
  }, [])

  useEffect(() => {
    getFGOSSpecializations()
  }, [])

  useEffect(() => {
    getFGOSTrainingDirections()
  }, [])

  const onChange = ({ target: { value, name } }) => {
    setData({ ...data, [name]: value })
  }

  function getFGOSSpecializations() {
    api.get('/fgos_specializations').then(res => {
      setFGOSSpecializations(res.data)
    })
  }

  function getFGOSTrainingDirections() {
    api.get('/library_fgos_training_directions').then(res => {
      setFGOSTrainingDirections(res.data)
    })
  }

  function getFullListDiscipline(query) {
    const params = {query}
    api.get('/discipline', {params}).then(response => {
      const filteredResp = response.data.filter(el => el.title);
      const params = [...filteredResp]
      setDisciplineList(params)
    })
  }

  const onDisciplineInputChange = name => {
    setDisciplineSearch({ ...disciplineSearch, query: name })
    getFullListDiscipline(name)
  }

  const onAuthorInputChange = name => {
    setAuthorSearch({ ...authorsSearch, query: name })
    requestAuthors()
  }

  return (
    <div>
      <Row>
        <Col md={12}>
          <div className={styles.title}>Сведения о виде электронного образовательного ресурса (ЭОР)</div>
          <ModalInputWrapper label='Вид ЭОР'>
            <select name='eerKindId' value={data.eerKindId} onChange={onChange}>
              {options_eerKindId &&
                options_eerKindId.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          {/*<ModalInputWrapper label="Тип ЭОР">*/}
          {/*    <select*/}
          {/*        name="eerTypeId"*/}
          {/*        value={data.eerTypeId}*/}
          {/*        onChange={onChange}*/}
          {/*    >*/}
          {/*        {options_eerTypeId && options_eerTypeId.map((item, index) => (*/}
          {/*            <option value={item.value} key={`eerTypeId${index}`}>{item.label}</option>*/}
          {/*        ))}*/}
          {/*    </select>*/}
          {/*</ModalInputWrapper>*/}
          <ModalInputWrapper label='По новизне публикации'>
            <select name='err_nov_publication_id' onChange={onChange} value={data.err_nov_publication_id}>
              <option value=''>Не выбрано</option>
              {selectors.novPublication &&
                selectors.novPublication.map((item, index) => (
                  <option value={item.id} key={`errNovPublicationId${index}`}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='По природе основной информации'>
            <select onChange={onChange} value={data.err_nature_basic_information_id} name='err_nature_basic_information_id'>
              <option value=''>Не выбрано</option>
              {selectors.natureBasicInformation &&
                selectors.natureBasicInformation.map((item, index) => (
                  <option value={item.id} key={`natureBasicInformation${index}`}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='По повторности выпуска'>
            <select onChange={onChange} value={data.err_repetition_release_id} name='err_repetition_release_id'>
              <option value=''>Не выбрано</option>
              {selectors.repetitionRelease &&
                selectors.repetitionRelease.map((item, index) => (
                  <option value={item.id} key={`repetitionRelease${index}`}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='По информационно-технологической конструкции'>
            <select
              onChange={onChange}
              value={data.err_information_technology_design_id}
              name='err_information_technology_design_id'
            >
              <option value=''>Не выбрано</option>
              {selectors.informationTechnologyDesign &&
                selectors.informationTechnologyDesign.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='По способу установки'>
            <select onChange={onChange} value={data.err_installation_method_id} name='err_installation_method_id'>
              <option value=''>Не выбрано</option>
              {selectors.installationMethod &&
                selectors.installationMethod.map((item, index) => (
                  <option value={item.id} key={`installationMethodId${index}`}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Информация об изменениях ЭОР'>
            <select onChange={onChange} value={data.err_information_changes_aor_id} name='err_information_changes_aor_id'>
              <option value=''>Не выбрано</option>
              {selectors.informationChangesAor &&
                selectors.informationChangesAor.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          <div className={styles.title}>Сведения о назначении ЭОР</div>
          <SpecialPurpose data={data} setData={setData} selectors={selectors} onChange={onChange} />
          <ModalInputWrapper
            label='Целевое назначение по элементам ОПОП или ДПП'
            name='targetassignmentopopordppelements'
            value={data.targetassignmentopopordppelements}
            onChange={onChange}
          />
          <ModalInputWrapper label='Кафедра' name='eerCathedra' value={data.eerCathedra} onChange={onChange} />
          <ModalInputWrapper label='Курс' name='eerClass' value={data.eerClass} onChange={onChange} />
          <div className={styles.title}>Сведения о содержании ЭОР</div>
          <ModalInputWrapper label='КЕМ ПРИСВОЕН СТАТУС ЭОР'>
            <select onChange={onChange} value={data.whohasthestatuseor} name='whohasthestatuseor'>
              <option value=''>Не выбрано</option>
              {users.map((item, index) => (
                <option value={item.mid} key={index}>
                  {`${item.firstname} ${item.lastname} ${item.patronymic}`}
                </option>
              ))}
            </select>

          </ModalInputWrapper>
          <ModalInputWrapper
            label='КОГДА ПРИСВОЕН СТАТУС ЭОР'
            type='date'
            name='dateeorstatus'
            value={moment(data.dateeorstatus).format('YYYY-MM-DD')}
            onChange={onChange}
          />
          <ModalInputWrapper label='Литературный жанр'>
            <SearchableSelect
              data={selectors.genres}
              setData={setData}
              placeholder='Выберите из списка'
              options={categoriesToOptions(selectors.genres)}
              isMulti={true}
              onChange={value => setData({ ...data, eer_genre: optionsToCategories(value) })}
              value={publishersToOptions(data.eer_genre)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper label='Язык оригинала' name='translation' value={data.translation} onChange={onChange} />
          <ModalInputWrapper label='ПЕРЕВОДЧИКИ'>
            <SearchableSelect
              selectType={SELECT_TYPE.AUTHORS}
              data={data}
              setData={setData}
              placeholder='Поиск'
              onInputChange={onAuthorInputChange}
              options={authorsToOptions(authorsList)}
              isMulti
              onChange={value => setData({ ...data, interpreters: optionsToAuthors(value) })}
              value={authorsToOptions(data.interpreters)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper label='ЛИЦА ПРИНИМАВШИЕ УЧАСТИЕ В СОЗДАНИИ (ФИО СОСТАВИТЕЛЕЙ, ОТВЕТСТВЕННОГО (НАУЧНОГО)РЕДАКТОРА, ИЛЛЮСТРАТОРА, ПРОГРАММИСТА И ДРУГИХ ЛИЦ)'>
            <SearchableSelect
              selectType={SELECT_TYPE.AUTHORS}
              data={data}
              setData={setData}
              placeholder='Поиск'
              onInputChange={onAuthorInputChange}
              options={authorsToOptions(authorsList)}
              isMulti
              onChange={value => setData({ ...data, personsparticipatedcreation: optionsToAuthors(value) })}
              value={authorsToOptions(data.personsparticipatedcreation)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper label='СОСТАВ РЕДКОЛЛЕГИИ'>
            <SearchableSelect
              selectType={SELECT_TYPE.AUTHORS}
              data={data}
              setData={setData}
              placeholder='Поиск'
              onInputChange={onAuthorInputChange}
              options={authorsToOptions(authorsList)}
              isMulti
              onChange={value => setData({ ...data, editorialboardmembers: optionsToAuthors(value) })}
              value={authorsToOptions(data.editorialboardmembers)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label='Программное обеспечение создания ЭОР'
            name='err_creation_software_aor'
            value={data.err_creation_software_aor}
            onChange={onChange}
          />
          <ModalInputWrapper
            label='Сведения о технической подготовке материалов для ЭОР (Только для литературных)'
            name='err_information_technical_preparation'
            value={data.err_information_technical_preparation}
            onChange={onChange}
          />
          <ModalInputWrapper label='Наличие медиа' name='eerMedia' value={data.eerMedia} onChange={onChange} />
          <ModalInputWrapper label='Приложения' name='eerAppendix' value={data.eerAppendix} onChange={onChange} />
          <ModalInputWrapper label='СТАТУС РЕСУРСА (ГРИФ УТВЕРЖДЕНИЯ)'>
            <select name='eerOutStatusId' onChange={onChange} value={data.eerOutStatusId}>
              {selectors.outStatus.map(item => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <div className={styles.title}>Место ЭОР в образовательном процессе</div>
          <ModalInputWrapper label='Вид и уровень образования'>
            <select
              className={styles.select}
              name='err_professional_education_levels_id'
              value={data.err_professional_education_levels_id}
              onChange={onChange}
            >
              {typeOfEducation &&
                typeOfEducation.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Форма получения образования и форма обучения'>
            <select name='eer_purpose_education_form' value={data.eer_purpose_education_form} onChange={onChange}>
              {selectors.purposeEducationForms.map(item => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='ФОРМА РЕАЛИЗАЦИИ ОБРАЗОВАТЕЛЬНОЙ ПРОГРАММЫ'>
            <select name='eer_purpose_implementation' value={data.eer_purpose_implementation} onChange={onChange}>
              {selectors.purposeImplementations.map(item => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Целевая аудитория'>
            <SearchableSelect
              data={selectors.purposeReaders}
              setData={setData}
              placeholder='Выберите из списка'
              options={categoriesToOptions(selectors.purposeReaders)}
              isMulti={true}
              onChange={value => setData({ ...data, eer_purpose_reader: optionsToCategories(value) })}
              value={publishersToOptions(data.eer_purpose_reader)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper label='Характер взаимодействия пользователя и ЭОР'>
            <select name='eerTechCommunicId' value={data.eerTechCommunicId} onChange={onChange}>
              {techCommunics &&
                techCommunics.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </ModalInputWrapper>

          <ModalInputWrapper label='Специальность по ФГОС ВО.'>
            <select name='fgosspecializations' value={data.fgosspecializations} onChange={onChange}>
              <option value='0'>Не выбрано</option>
              {FGOSSpecializations.map(item => (
                <option value={item.specialization_id}>{item.specialization}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='НАПРАВЛЕНИЕ (ПРОФИЛЬ) ПОДГОТОВКИ ФГОС ВО'>
            <select name='fgostrainingdirections' value={data.fgostrainingdirections} onChange={onChange}>
              <option value='0'>Не выбрано</option>
              {FGOSTrainingDirections.map(item => (
                <option value={item.training_direct_id}>{item.training_direct}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Дисциплины (модуль)'>
            <SearchableSelect
              selectType={SELECT_TYPE.DISCIPLINE}
              data={data}
              setData={setData}
              placeholder={'Поиск'}
              onInputChange={onDisciplineInputChange}
              options={disciplineToOptions(disciplineList)}
              onChange={value => setData({ ...data, cid: value })}
              value={data.cid?.value ? data.cid : [{ label: data.disciplinename, value: data.cid }]}
            />
          </ModalInputWrapper>
          <ModalInputWrapper label='Группа литературы, к которой относится ЭОР'>
            <select className={styles.select} name='categoryguk' value={data.categoryguk} onChange={onChange}>
              {categoryGukArray.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper
            label='Тема (раздел) дисциплины'
            name='theme_discipline'
            value={data.theme_discipline}
            onChange={onChange}
          />
          <ModalInputWrapper label='Учебный вопрос' name='training_question' value={data.training_question} onChange={onChange} />
          <div className={styles.title}>Технологические характеристики ЭОР</div>
          <ModalInputWrapper label='Технология распространения'>
            <select name='eer_tech_distribution' value={data.eer_tech_distribution} onChange={onChange}>
              {selectors.techdisributions.map(item => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Периодичность издания'>
            <select className={styles.select} name='eerTechPeriodId' value={data.eerTechPeriodId} onChange={onChange}>
              {options_eerTechPeriodId.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Структура ЭОР'>
            <select className={styles.select} name='eerTechStructId' value={data.eerTechStructId} onChange={onChange}>
              {options_eerTechStructId && options_eerTechStructId.map(item => <option value={item.value}>{item.label}</option>)}
            </select>
          </ModalInputWrapper>
          <div className={styles.title}>Выпускные данные</div>
          <ModalInputWrapper
            label='Продолжительность звуковых и видеофрагментов, мин.'
            name='eerOutDurationMin'
            value={data.eerOutDurationMin}
            onChange={onChange}
            type='number'
          />
          <Row>
            <Col md={6}>
              <ModalInputWrapper label='Комплектация ресурса (тип)' name='resourceComplectation'
            value={data.resourceComplectation}
            onChange={onChange}/>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label='Единицы измерения' name='unitsOfMeasure'
            value={data.unitsOfMeasure}
            onChange={onChange}/>
            </Col>
          </Row>
          <ModalInputWrapper
            label='Регистрационный номер и регистрирующий орган'
            name='eerOutRegistration'
            value={data.eerOutRegistration}
            onChange={onChange}
          />
          <ModalInputWrapper label='Вид носителя'>
            <SearchableSelect
              data={selectors.outCarriers}
              setData={setData}
              placeholder='Выберите из списка'
              options={categoriesToOptions(selectors.outCarriers)}
              isMulti={false}
              onChange={value => setData({ ...data, eerOutCarrierId: value })}
              value={data.eerOutCarrierId?.value ? data.eerOutCarrierId : [{ label: selectors.outCarriers[data.eerOutCarrierId-1]?.name, value: data.eerOutCarrierId }]}
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label='Номер государственной регистрации'
            name='eerOutGovernmentRegNum'
            value={data.eerOutGovernmentRegNum}
            onChange={onChange}
          />
          <ModalInputWrapper label='Штрих-коды' name='eerOutBarCodes' value={data.eerOutBarCodes} onChange={onChange} />
          <ModalInputWrapper
            label='Знак охраны авторского права'
            name='eerOutCopyrightNotice'
            value={data.eerOutCopyrightNotice}
            onChange={onChange}
          />
          <ModalInputWrapper label='Результат проверки на заимствование (антиплагиат)'>
            <select className={styles.select} name='eerOutPlagiaryId' value={data.eerOutPlagiaryId} onChange={onChange}>
              {options_eerOutPlagiaryId && options_eerOutPlagiaryId.map(item => <option value={item.value}>{item.label}</option>)}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper
            label='НАИМЕНОВАНИЕ ИЗГОТОВИТЕЛЯ РЕСУРСА'
            type='text'
            onChange={onChange}
            name='nameresourcemanufacturer'
            value={data.nameresourcemanufacturer}
          />
          <ModalInputWrapper
            label='АДРЕС ИЗГОТОВИТЕЛЯ'
            type='text'
            onChange={onChange}
            name='addressmanufacturer'
            value={data.addressmanufacturer}
          />
          <ModalInputWrapper
            label='ТЕЛЕФОН ИЗГОТОВИТЕЛЯ'
            type='text'
            onChange={onChange}
            name='manufacturersphonenumber'
            value={data.manufacturersphonenumber}
          />
          <ModalInputWrapper
            label='Тираж, экземпляров'
            type='number'
            name='err_edition'
            value={Math.abs(data.err_edition) || null}
            onChange={onChange}
          />
          <div className={styles.title}>Минимальные системные требования</div>
          <ModalInputWrapper label='Процессор тип' name='processor_type' value={data.processor_type} onChange={onChange} />
          <ModalInputWrapper
            label='Процессор тактовая частота'
            name='processor_clock_speed'
            value={data.processor_clock_speed}
            onChange={onChange}
          />
          <ModalInputWrapper label='ОБЪЕМ ДИСКОВОЙ ПАМЯТИ, Мбайт' name='disk_space' value={data.disk_space} onChange={onChange} />
          <ModalInputWrapper label='ОБЪЕМ ОПЕРАТИВНОЙ ПАМЯТИ, Мбайт' name='ram_size' value={data.ram_size} onChange={onChange} />
          <ModalInputWrapper label='Требования к операционной системе'>
            <select className={styles.select} name='nameos' value={data.nameos} onChange={onChange}>
              {options_OS.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Требования к видеосистеме'>
            <select className={styles.select} name='namevs' value={data.namevs} onChange={onChange}>
              {options_VideoSystem.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label='Требования к акустической системе'>
            <select className={styles.select} name='namess' value={data.namess} onChange={onChange}>
              {options_AudioSystem.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper
            label='Необходимое дополнительное программное обеспечение (не входящее в состав электронного издания) и оборудование'
            name='eerReqAdditSoftware'
            value={data.eerReqAdditSoftware}
            onChange={onChange}
          />
          <ModalInputWrapper
            label='ТРЕБОВАНИЕ К ПЕРИФЕРИЙНОМУ ОБОРУДОВАНИЮ'
            name='periphery_equipment'
            value={data.periphery_equipment}
            onChange={onChange}
          />
          <ModalInputWrapper
            label='ТРЕБОВАНИЕ К ТИПУ БРАУЗЕРА'
            name='browser_type'
            value={data.browser_type}
            onChange={onChange}
          />
          <ModalInputWrapper
            label='ТРЕБОВАНИЕ К ВЕРСИИ БРАУЗЕРА'
            name='browser_version'
            value={data.browser_version}
            onChange={onChange}
          />
          <ModalInputWrapper
            label='ТРЕБОВАНИЕ К СКОРОСТИ ПОДКЛЮЧЕНИЯ К ИНФОРМАЦИОННО-ТЕЛЕКОММУНИКАЦИОННЫМ СЕТЯМ'
            name='speed_connection'
            value={data.speed_connection}
            onChange={onChange}
          />
          <ModalInputWrapper
            label='ТРЕБОВАНИЕ К ДОПОЛНИТЕЛЬНЫМ НАДСТРОЙКАМ К БРАУЗЕРУ'
            name='browser_addin'
            value={data.browser_addin}
            onChange={onChange}
          />
          <ModalInputWrapper
            label='Требования к компьютеру, указывается: производительность в мегагерцах (МГц),
                        объем оперативной памяти в мегабайтах (Мб), необходимое место на диске в мегабайтах (Мб)'
            name='eerReqComputer'
            value={data.eerReqComputer}
            onChange={onChange}
          />
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GUKTab)
