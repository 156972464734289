import { ERROR, SECURITY_LOG_COUNT_REQUEST, START, SUCCESS } from "../../action-types";

export const securityLogCountRequestStart = () => ({
  type: `${SECURITY_LOG_COUNT_REQUEST}${START}`
});

export const securityLogCountRequestSuccess = (data) => ({
  type: `${SECURITY_LOG_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const securityLogCountRequestError = (data) => ({
  type: `${SECURITY_LOG_COUNT_REQUEST}${ERROR}`,
  payload: data
});
