import { ERROR, OPTIONS_MENU_SAVE_REQUEST, START, SUCCESS } from "../../action-types";

export const optionsMenuSaveRequestStart = (data) => ({
  type: `${OPTIONS_MENU_SAVE_REQUEST}${START}`,
  payload: data
});

export const optionsMenuSaveRequestSuccess = (data) => ({
  type: `${OPTIONS_MENU_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const optionsMenuSaveRequestError = (data) => ({
  type: `${OPTIONS_MENU_SAVE_REQUEST}${ERROR}`,
  payload: data
});