import {MOBILE_LIBRARY_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";



const initialState = 0



export const mobileLibraryCountReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case `${MOBILE_LIBRARY_LIST_REQUEST}${SUCCESS}`:
            return action.cnt

        default: return state;
    }
};