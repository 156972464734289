import {api} from "../../App";

export const getCountDocuments = (formData) => {

    const params = {
        count: true
    }

    const _formData = {
        ...formData
    }

    return api.post('/search', _formData,{params})
}


export const searchDocuments = (maxResults, paginateFrom) => {

    const params = {
        maxResults, paginateFrom
    }

    return api.post('/search', null,{params})
}
