import jsPDF from 'jspdf'
import T from 'prop-types'
import React from 'react'
import addFont from '../../../../fonts/fonts'
import PdfPrintPanel from '../../../PDFPrint/Panel'


const LostDocPdfPrint = ({ data, searchFormValue, librariesList }) => {

  const title = 'Отчеты / Дневник работы библиотеки'
  const orientation = 'landscape'

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
    doc.setFont('Montserrat')
    doc.setFont( undefined, 'bold')
    doc.setFontSize('15')
    
    doc.text('ТЕТРАДЬ УЧЕТА ДОКУМЕНТОВ, ПРИНЯТЫХ ОТ ПОЛЬЗОВАТЕЛЕЙ БИБЛИОТЕКИ ВЗАМЕН УТЕРЯННЫХ', doc.internal.pageSize.width / 2, 12, null, null, 'center')
    doc.setFontSize('16')

    doc.setFont( undefined, 'normal')
    doc.setFontSize('13')

    doc.text(
      'Федеральное военное государственное образовательное учереждение',
      doc.internal.pageSize.width / 2,
      20,
      null,
      null,
      'center'
    )

    doc.text('высшего профессионального образования', doc.internal.pageSize.width / 2, 26, null, null, 'center')
    doc.text(`"${librariesList[0].orgname}"`, doc.internal.pageSize.width / 2, 32, null, null, 'center')
    doc.text('Министерства обороны Российской Федерации', doc.internal.pageSize.width / 2, 38, null, null, 'center')
    doc.setFontSize('14')
    // doc.text('ТЕТРАДЬ УЧЕТА ДОКУМЕНТОВ, ПРИНЯТЫХ ОТ ПОЛЬЗОВАТЕЛЕЙ БИБЛИОТЕКИ ВЗАМЕН УТЕРЯННЫХ', doc.internal.pageSize.width / 2, 49, null, null, 'center')
    doc.autoTable({
      // body:data,
      html:'#writeOfActPDFPDFTable',
      styles: { font: 'Montserrat', fontSize: 6 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
    })

    doc.addPage('a4', orientation)
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
}

LostDocPdfPrint.propTypes = {
  librariesList: T.array.isRequired,
  searchFormValue: T.object.isRequired,
}

export default LostDocPdfPrint
