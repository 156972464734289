import React from 'react';
import T from 'prop-types';
import styles from './UDKList.module.css';
import UDKListItem from "./Item";

const UDKList = ({data, fetchSubList, collapseList, onSelect, selectMode}) => {

    const createSublist = (data, level) => {
        return data.map((item, index) =>
            <React.Fragment key={index}>
                <UDKListItem
                    fetchSubList={fetchSubList}
                    data={item}
                    level={level}
                    collapseList={collapseList}
                    onSelect={onSelect}
                    selectMode={selectMode}
                />
                {item.subList && createSublist(item.subList, level + 1)}
            </React.Fragment>)
    };

    return (
        <div className={styles.container}>
            <table>
                <thead>
                <tr>
                    <th colSpan={6}>Код раздела</th>
                    <th colSpan={10}>Наименование (содержание) раздела</th>
                    <th colSpan={10}>Примечание</th>
                    {onSelect && <th colSpan={3}></th>}
                </tr>
                </thead>
                <tbody>
                    {createSublist(data, 1)}
                </tbody>
            </table>
        </div>
    );
};

UDKList.propTypes = {
    data: T.array.isRequired,
    fetchSubList: T.func.isRequired,
    collapseList: T.func.isRequired,
    onSelect: T.func
};

export default UDKList;
