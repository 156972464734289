import {DOCUMENT_INSTANCE_ADD_REQUEST, START, SUCCESS, ERROR} from "../../action-types";
import { createNotification } from '../../../../components/notification/notification';


export const documentInstancesAddRequestStart = (data) => ({
    type: `${DOCUMENT_INSTANCE_ADD_REQUEST}${START}`,
    payload: data
});

export const documentInstancesAddRequestSuccess = (data) => {
    if(data.result === "ERROR") {
        createNotification('error', 'Списание этого инвентарного номера уже было проведено');
    } else {
        createNotification('success', 'Книга успешно добавлена в документ');
    }
    return ({
        type: `${DOCUMENT_INSTANCE_ADD_REQUEST}${SUCCESS}`,
        payload: data
    });
};

export const documentInstancesAddRequestError = (data) => ({
    type: `${DOCUMENT_INSTANCE_ADD_REQUEST}${ERROR}`,
    payload: data
});
