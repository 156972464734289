import {call, put, takeLatest} from "redux-saga/effects";
import {OWN_USER_DATA_REQUEST, READER_DATA_REQUEST, START} from "../../actions/action-types";
import {getReaderData} from "../../../api/reader/reader";
import {readerDataRequestError, readerDataRequestSuccess} from "../../actions/reader/getData";
import {ownUserDataRequestError, ownUserDataRequestSuccess} from "../../actions/ownUserData/getData";

export default function* readerDataWatcher() {
    yield takeLatest(`${READER_DATA_REQUEST}${START}`, readerDataWorker)
    yield takeLatest(`${OWN_USER_DATA_REQUEST}${START}`, ownUserDataWorker)
}

function* readerDataWorker({payload: mid}) {
    try {
        const response = yield call(getReaderData, mid);
        yield put(readerDataRequestSuccess(response.data));
    } catch (error) {
        yield put(readerDataRequestError(error));
    }
}

function* ownUserDataWorker({payload: mid}) {
    try {
        const response = yield call(getReaderData, mid);
        yield put(ownUserDataRequestSuccess(response.data));
    } catch (error) {
        yield put(ownUserDataRequestError(error));
    }
}