import {combineReducers} from "redux";
import {alphabetRangeReducer} from "./range/alphabetRangeReducer";
import {alphabetSearchReducer} from "./search/alphabetSearchReducer";
import {alphabetLettersReducer} from "./letters/alphabetLettersReducer";
import {alphabetCountReducer} from "./count/alphabetCountReducer";
import {alphabetCurrentItemReducer} from "./currentItem/alphabetCurrentItemReducer";

export const alphabetReducer = combineReducers({
    count: alphabetCountReducer,
    currentItem: alphabetCurrentItemReducer,
    range: alphabetRangeReducer,
    search: alphabetSearchReducer,
    letters: alphabetLettersReducer
});
