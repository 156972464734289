import React from 'react'
import { useSelector } from 'react-redux'
import styles from './InventoryDiscrepancyPDFTable.module.css'

export const InventoryDiscrepancyPDFTable = ({ books }) => {
  return (
    <table className={styles.table} id='InventoryDiscrepancyPDFTable'>
      <thead>
        <tr>
          <td rowSpan='5'>№ п.п</td>
          <td rowSpan='5'>Название объекта нефинансового актива</td>
          <td rowSpan='5'>Номер (код) объекта учета (инвентарный или иной)</td>
          <td rowSpan='5'>Единица измерения</td>
          <td colSpan='2'>По данным бухгалтерского учета</td>
          <td colSpan='9'>Результаты инвентаризации</td>
        </tr>
        <tr>
          <td rowSpan='4'>номер (код) счета</td>
          <td rowSpan='4'>балансовая стоимость на единицу, руб.</td>
          <td rowSpan='4'>цена (оценочная стоимость), руб.</td>
          <td colSpan='6'>отклонения</td>
          <td rowSpan='2' colSpan='2'>
            не соответствует условиям актива
          </td>
        </tr>
        <tr>
          <td colSpan='4'>недостача</td>
          <td colSpan='2'>излишки</td>
        </tr>
        <tr>
          <td colSpan='2'>всего</td>
          <td colSpan='2'>в том числе в пределах норм естественной убыли</td>
          <td rowSpan='2'>количество</td>
          <td rowSpan='2'>сумма, руб.</td>
          <td rowSpan='2'>количество</td>
          <td rowSpan='2'>сумма, руб.</td>
        </tr>
        <tr>
          <td>количество</td>
          <td>сумма, руб.</td>
          <td>количество</td>
          <td>сумма, руб.</td>
        </tr>
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
          <td>7</td>
          <td>8</td>
          <td>9</td>
          <td>10</td>
          <td>11</td>
          <td>12</td>
          <td>13</td>
          <td>14</td>
          <td>15</td>
        </tr>
      </thead>
      <tbody>
        {books
          .filter(e => e.amount !== e.amount_plan)
          .map((e, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{e.title}</td>
              <td>{e.number}</td>
              <td>шт.</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{e.amount_plan - e.amount > 0 ? e.amount_plan - e.amount : ''}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{e.amount - e.amount_plan > 0 ? e.amount - e.amount_plan : ''}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
        <tr>
          <td></td>
          <td>ИТОГО</td>
          <td></td>
          <td>шт.</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{books.filter(e => e.amount_plan - e.amount > 0).reduce((a, b) => a + (b.amount_plan - b.amount), 0)}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{books.filter(e => e.amount - e.amount_plan > 0).reduce((a, b) => a + (b.amount - b.amount_plan), 0)}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  )
}
