import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import styles from "./FundMovementSearchForm.module.css";
import { api } from "../../../App";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const FundMovementSearchForm = ({
  searchData,
  setSearchData,
  onSubmit,
  librariesList,
}) => {
  const [fundList, setFundList] = useState([]);

  const modalForm = useFormik({
    initialValues: searchData,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setSearchData(values);
      onSubmit();
    },
  });

  const { values, errors } = modalForm;

  useEffect(() => {
    // Шифр
    if (searchData.in_library_id == "0") {
      setFundList([]);
    } else {
      api
        .get(`/library/${searchData.in_library_id}/fund`)
        .then((res) => {
          setFundList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Шифр
    setSearchData({ ...searchData, in_fund_id: 0 });
  }, [searchData.in_library_id]);



  return (
    <div className={styles.container}>
      <Row>
        <Col md={3}>
          <ModalInputWrapper
            value={values["from"]}
            error={errors["from"]}
            name="from"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"С"}
          />
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            value={values["to"]}
            error={errors["to"]}
            name="to"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={"Библиотека"}>
            <select
              name="in_library_id"
              onChange={modalForm.handleChange}
              value={values["in_library_id"]}
              className={styles.select}
            >
              <option value="0">Все</option>
              {librariesList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={"Фонд"}>
            <select
              name="in_fund_id"
              onChange={modalForm.handleChange}
              value={values["in_fund_id"]}
              className={styles.select}
            >
              <option value="0">Все</option>
              {fundList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={2}>
          <ModalInputWrapper label={""}>
            <Button label={"Составить"} onClick={modalForm.handleSubmit} />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

FundMovementSearchForm.propTypes = {
  data: T.object,
  setSearchData: T.func,
  startSearch: T.func,
  types: T.array,
  librariesList: T.array,
};

export default FundMovementSearchForm;
