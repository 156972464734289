import React, { useEffect, useState } from 'react'
import ModalWindow from '../../ModalWindow'
import { EDIT } from '../../../constants/modalTypes'
import ModalForm from './Form'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import { api } from '../../../App'

export const TestsModal = ({ modalData, setModalData }) => {
  const [tests, setTests] = useState([])

  const closeModal = () => {
    setModalData({ modalType: null, data: null })
  }

  useEffect(() => {
    api.get(`course/${modalData.data.id}/passedtests`).then(data => setTests(data.data.passedtest_json))
  }, [])

  return (
    <ModalWindow title={'Тесты'} isOpen={true} onRequestClose={closeModal}>
      {tests ? (
        <DataTable data={tests} onCellClick={() => {}}>
          <DataTableCol title='Тест' value='testname' />
          <DataTableCol title='Оценка' value='score' />
        </DataTable>
      ) : (
        'Список пуст'
      )}
    </ModalWindow>
  )
}
