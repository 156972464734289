import {combineReducers} from "redux";
import {countReducer} from "./count/countReducer";
import {formReducer} from "./form/formReducer";
import {listReducer} from "./list/listReducer";
import {loaderReducer} from "./loader/loaderReducer";
import {responsedReducer} from "./responsed/responsedReducer";
import {searchReducer} from "./search/searchReducer";
import {modalReducer} from "./modal/modalReducer";



export const transferLog = combineReducers({
    count: countReducer,
    form: formReducer,
    list: listReducer,
    loader: loaderReducer,
    responsed: responsedReducer,
    search: searchReducer,
    modal: modalReducer
});