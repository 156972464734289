import React from 'react'
import T from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../Modal/InputWrapper'
import styles from './CategoryPermissionsSearchForm.module.css'
import Button from '../../Button'
import Select from 'react-select'
import { categoriesToOptions, rolesToOptions } from '../../../utils/toSelectOption'
import { accessIsSetByUserStatusList } from '../../../constants/adminCategoriesPermissions/accessIsSetByUserStatusList'

const CategoryPermissionsSearchForm = ({ roles, formData, setFormData, onSubmit, categories }) => {
  const roleSelectOptions = [{ label: 'Все роли', value: '' }, ...rolesToOptions(roles)]
  const categorySelectOptions = [{ label: 'Все категории', value: '' }, ...categoriesToOptions(categories)]

  return (
    <div className={styles.container}>
      <Row>
        <Col md={6} lg={4}>
          <ModalInputWrapper label={'Роль'}>
            <Select
              defaultValue={roleSelectOptions[0]}
              placeholder={'Выберите роль'}
              onChange={value => setFormData({ ...formData, roleid: value.value })}
              options={roleSelectOptions}
            />
          </ModalInputWrapper>
        </Col>
        <Col md={6} lg={4}>
          <ModalInputWrapper label={'Фильтр'}>
            <Select
              defaultValue={formData.set}
              placeholder={'Выберите значение фильтрации'}
              onChange={value => setFormData({ ...formData, set: value.value })}
              options={accessIsSetByUserStatusList}
            />
          </ModalInputWrapper>
        </Col>
        <Col md={6} lg={4}>
          <ModalInputWrapper label={'Категории'}>
            <Select
              defaultValue={categorySelectOptions[0]}
              placeholder={'Выберите категорию'}
              onChange={value => setFormData({ ...formData, categoryid: value.value })}
              options={categorySelectOptions}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={2} className={'d-flex align-items-center'}>
          <Button label={'Найти'} onClick={onSubmit} />
        </Col>
      </Row>
    </div>
  )
}

CategoryPermissionsSearchForm.propTypes = {
  roles: T.array.isRequired,
  formData: T.object.isRequired,
  setFormData: T.func.isRequired,
  onSubmit: T.func.isRequired,
  categories: T.array.isRequired,
}

export default CategoryPermissionsSearchForm
