import {SET_INV_NUMBERS_FORM_DATA, SET_LOCATION_CHANGE} from "../../../actions/action-types";
import { INV_NUMBERS_FORM_STRUCTURE } from "../../../../constants/invNumbers";

const initialState = INV_NUMBERS_FORM_STRUCTURE;

export const invNumbersFormReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_INV_NUMBERS_FORM_DATA:
      return action.payload;

    case SET_LOCATION_CHANGE:
      return initialState;

    default: return state;
  }
};
