import React from 'react';
import T from 'prop-types';
import styles from './BookReserve.module.css';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import CoverPhotoPlaceholder from "../../Covers/List/Item/CoverPhotoPlaceholder";


const BookReserveTable = ({list, unreserveBook}) => {
    return (
        <div className="mt-2 mb-2">
            <DataTable data={list}>
                <DataTableCol colSpan={2} title="Читатель" value="displayname"/>
                <DataTableCol colSpan={1} title={''}
                              style={{width: '100px'}}
                              className={styles.imgWrap}
                              resolver={item => <CoverPhotoPlaceholder url={item.coverImage} bookId={item.bookid}
                                                                       alt={item.title}/>}/>
                <DataTableCol colSpan={3} title="Название"
                              resolver={item => `${item.title}, ${item.year}г. ${item.pageNum || 0}стр.`}/>
                <DataTableCol colSpan={2} title="Авторы" resolver={item => (
                    item.authors ? item.authors.map(author => author.name) : '-'
                )}/>
                <DataTableCol colSpan={2} title="Издательства" resolver={item => (
                    item.publishers ? item.publishers.map(publisher => publisher.name) : '-'
                )}/>
                <DataTableCol title="" resolver={item =>
                    <div className={'tableButton'} onClick={() => unreserveBook(item.id)}>Отменить бронь</div>
                }/>
            </DataTable>
        </div>
    );
};

BookReserveTable.propTypes = {
    list: T.array,
    unreserveBook: T.func
};

BookReserveTable.defaultProps = {
    list: []
};

export default BookReserveTable;