import React from "react";
import T from "prop-types";
import { Col, Row } from "react-bootstrap";
import InputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const BooksOrderSearchForm = ({ data, setSearchData, startSearch }) => {
  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setSearchData(values);
      startSearch();
    },
  });

  const { values, errors } = modalForm;

  return (
    <div className="formContainer">
      <Row>
        <Col md={4}>
          <InputWrapper
            name="from"
            onChange={modalForm.handleChange}
            type="date"
            label="С"
            value={values["from"]}
            error={errors["from"]}
          />
        </Col>
        <Col md={4}>
          <InputWrapper
            name="to"
            onChange={modalForm.handleChange}
            type="date"
            label="По"
            value={values["to"]}
            error={errors["to"]}
          />
        </Col>

        <Col md={2}>
          <InputWrapper label="">
            <Button label="Найти" onClick={modalForm.handleSubmit} />
          </InputWrapper>
        </Col>
      </Row>
    </div>
  );
};

BooksOrderSearchForm.propTypes = {
  data: T.object,
  setSearchData: T.func,
  startSearch: T.func,
  types: T.array,
};

export default BooksOrderSearchForm;
