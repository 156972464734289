import {
    call,
    put,
    takeLatest,
    select
} from "redux-saga/effects";
import {
    addSchedulerItem,
    deleteSchedulerItem,
    editSchedulerItem,
    getSchedulerCategoryCount,
    getSchedulerCategoryList,
} from "../../../api/sheduler/sheduler";
import {schedulerEditRequestError, schedulerEditRequestSuccess} from "../../actions/scheduler/categories/edit";
import {
    SCHEDULER_CATEGORY_ADD_REQUEST,
    SCHEDULER_CATEGORY_COUNT_REQUEST,
    SCHEDULER_CATEGORY_DELETE_REQUEST, SCHEDULER_CATEGORY_EDIT_REQUEST,
    SCHEDULER_CATEGORY_LIST_REQUEST,
    START
} from "../../actions/action-types";

import {
    schedulerCategoriesCountRequestError, schedulerCategoriesCountRequestStart,
    schedulerCategoriesCountRequestSuccess
} from "../../actions/scheduler/categories/count";
import {setSchedulerCategoriesSearchData} from "../../actions/scheduler/categories/search";
import {
    schedulerCategoriesListRequestError,
    schedulerCategoriesListRequestStart,
    schedulerCategoriesListRequestSuccess
} from "../../actions/scheduler/categories/list";
import {schedulerDeleteRequestError, schedulerDeleteRequestSuccess} from "../../actions/scheduler/categories/delete";
import {schedulerAddRequestError, schedulerAddRequestSuccess} from "../../actions/scheduler/categories/add";


export default function* schedulerMetadataWatcher() {
    yield takeLatest(`${SCHEDULER_CATEGORY_COUNT_REQUEST}${START}`, categoryCountWorker);
    yield takeLatest(`${SCHEDULER_CATEGORY_LIST_REQUEST}${START}`, categoryListWorker);
    yield takeLatest(`${SCHEDULER_CATEGORY_EDIT_REQUEST}${START}`, editWorker);
    yield takeLatest(`${SCHEDULER_CATEGORY_DELETE_REQUEST}${START}`, deleteWorker);
    yield takeLatest(`${SCHEDULER_CATEGORY_ADD_REQUEST}${START}`, addWorker);
}

function* categoryCountWorker({ payload: id }) {

    const searchData = yield select(({scheduler}) => scheduler.categories.search);

    try {
        const response = yield call(getSchedulerCategoryCount, id);
        const count = parseInt(response.data.count, 10);
        yield put(schedulerCategoriesCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setSchedulerCategoriesSearchData({...searchData, pageCount}));
        if (count > 0) {
            yield put(schedulerCategoriesListRequestStart(id))
        } else {
            yield put(schedulerCategoriesListRequestSuccess([]))
        }
    } catch(error) {
        yield put(schedulerCategoriesCountRequestError(error));
    }
}

function* categoryListWorker({ payload: id }) {
    const searchData = yield select(({scheduler}) => scheduler.categories.search);
    const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getSchedulerCategoryList, id, searchData.maxResults, paginateFrom);
        yield put(schedulerCategoriesListRequestSuccess(response.data));
    } catch(error) {

        yield put(schedulerCategoriesListRequestError(error));
    }
}

function* editWorker({payload: data, callback}) {
    try {
        const response = yield call(editSchedulerItem, data);
        yield put(schedulerEditRequestSuccess(response.data));
        if(callback) {
            yield call(callback);
        }
        yield put(schedulerCategoriesCountRequestStart(data.categoryId));
    } catch (error) {
        yield put(schedulerEditRequestError(error));
    }
}

function* deleteWorker({payload: { itemId, categoryId }}) {
    try {
        yield call(deleteSchedulerItem, itemId);
        yield put(schedulerDeleteRequestSuccess());
        yield put(schedulerCategoriesCountRequestStart(categoryId));
    } catch (error) {
        yield put(schedulerDeleteRequestError(error));
    }
}

function* addWorker({payload: data, callback}) {
    try {
        const response = yield call(addSchedulerItem, data);
        yield put(schedulerAddRequestSuccess(response.data));

        if(callback) {
            yield call(callback);
        }

        yield put(schedulerCategoriesCountRequestStart(data.id));
    } catch (error) {
        yield put(schedulerAddRequestError(error));
    }
}