import React, { useState, useEffect } from 'react';
import {api} from "../../../App";


export const useCategoryOrganization = () => {

    const [state, setState] = useState([]);

    useEffect(() => {
        api.get('/xp_category_organization')
            .then(res => {
                setState(res.data);
            })
    }, []);

    return state;
};