import { call, put, select, takeLatest } from 'redux-saga/effects'
import { LIBRARY_DOCUMENT_CREATE_REQUEST, LIBRARY_DOCUMENT_UPDATE_REQUEST, MOBILE_LIBRARY_DOCUMENTS_REQUEST, SET_CURRENT_DOCUMENT, START } from '../../actions/action-types'
import { createDocument, getDocuments, updateDocument } from '../../../api/mobileLibrary'
import { addDocument, editDocument } from '../../../api/documents/documents'
import { currentMobileLibraryRequestStart } from '../../actions/mobileLibrary/library/current'
import { getDocumentsRequestStart, getDocumentsRequestSuccess } from '../../actions/mobileLibrary/documents/list'



export default function* libDocumentsWatcher() {
  yield takeLatest(`${MOBILE_LIBRARY_DOCUMENTS_REQUEST}${START}`, getDcoumentsWorker)
  yield takeLatest(`${LIBRARY_DOCUMENT_CREATE_REQUEST}${START}`, createDocumentWorker)
  yield takeLatest(`${LIBRARY_DOCUMENT_UPDATE_REQUEST}${START}`, updateDocumentWorker)
}

function* getDcoumentsWorker ({ libId }) {
  try {
    const response = yield call(getDocuments, libId)
    yield put(getDocumentsRequestSuccess(response.data || []))
  } catch (error) {
  }
}

function* createDocumentWorker ({payload}) {
  try {

    const {id: libId} = yield select(({ mobileLibrary }) => mobileLibrary.current) || {}
    const docList = yield select(({ mobileLibrary }) => mobileLibrary.documents) || []
    const {write_off_doc_id, acceptance_doc_id, id: docId} = docList?.length ? docList[docList.length - 1] : {}
    const acceptanceMode = !!docList?.length && !acceptance_doc_id;

    const addResponse = yield call(addDocument, payload)

    const bindPayload = {
      mobile_library_id: libId,
      write_off_doc_id: acceptanceMode ? write_off_doc_id : addResponse.data.id,
      acceptance_doc_id: acceptanceMode ? addResponse.data.id : null
    }

    yield put({type: SET_CURRENT_DOCUMENT, payload: addResponse.data})
    yield acceptanceMode
      ? call(updateDocument, docId, bindPayload)
      : call(createDocument, bindPayload)

    yield put(getDocumentsRequestStart(libId))

  } catch (error) {
  }
}

function* updateDocumentWorker ({payload}) {
  try {
    
    yield call(editDocument, payload)
    const libId = yield select(({ mobileLibrary }) => mobileLibrary.current?.id)
    yield put(getDocumentsRequestStart(libId))

  } catch (error) {
  }
}