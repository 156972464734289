import {START, EVENT_REPORT_REQUEST, SUCCESS, ERROR} from "../../actions/action-types";


export const eventReportRequestStart = (payload) => ({
  type: `${EVENT_REPORT_REQUEST}${START}`,
  payload
})

export const eventReportRequestSuccess = (payload) => ({
  type: `${EVENT_REPORT_REQUEST}${SUCCESS}`,
  payload
})

export const eventReportRequestError = (error) => ({
  type: `${EVENT_REPORT_REQUEST}${ERROR}`,
  payload: error
})