import React from 'react';
import T from 'prop-types';
import { Col, Row, Button } from "antd";
import ModalInputWrapper from "../../Modal/InputWrapper";

const DocumentsSearchForm = ({ data, setSearchData, startSearch }) => {
    const onChange = ({ target: { name, value } }) => setSearchData({ ...data, [name]: value });

    return (
        <div className={"formContainer"}>
            <Row>
                <Col span={20}>
                    <ModalInputWrapper
                        label={'Поиск по имени документа'}
                        onChange={onChange}
                        name={"query"}
                        value={data.query}
                    />
                </Col>
                <Col span={4}>
                    <ModalInputWrapper label={''}>
                        <Button type="primary" onClick={startSearch} className={'ml-3'}>Найти</Button>
                    </ModalInputWrapper>
                </Col>
            </Row>
        </div>
    );
};

DocumentsSearchForm.propTypes = {
    data: T.object,
    setSearchData: T.func,
    startSearch: T.func,
    types: T.array
};

export default DocumentsSearchForm;
