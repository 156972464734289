import { api } from "../../App";

export const exportBooks = (bookIds) => {
  return api.post(`/bookexportfile`, bookIds.map(el => ({id: el})));
};

export const getBookExports = () => {
  return api.get(`/bookexportfile`);
};

export const deleteBookExport = (itemId) => {
  return api.delete(`/bookexportfile/${itemId}`);
};
