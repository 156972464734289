import { api } from '../../App';

export const reserveBook = (instanceId, mid) => {
  const request = {
    mid,
    bookinstanceid: instanceId
  };

  return api.post('/bookreserve', request);
};

export const unReserveBook = (instanceId) => {
  return api.delete(`/bookreserve/${instanceId}`);
};