import { SET_BOOK_SECRET_DOC_SEARCH_DATA } from "../../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";

const initialState = {
  page: 1,
  // sortBy: "eventtime",
  // sortDirection: SORT_DIRECTION.DESC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  count: 0
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_BOOK_SECRET_DOC_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};
