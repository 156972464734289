import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import styles from './AdminDirectoryTable.module.css'
import { ADD, EDIT } from '../../../constants/modalTypes'
import DataTableCol from '../../DataTable/Column'
import DataTable from '../../DataTable'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { CloseCircleFilled, DeleteFilled, DownloadOutlined, FileAddOutlined } from '@ant-design/icons'
import { api } from '../../../App'
import { createNotification } from '../../notification/notification'
import { BASE_URL, BASE_URL_WITHOUT_API } from '../../../constants/api'
import ModalWindow from '../../ModalWindow'


const AdminDirectoryTable = ({ data, setModal, sortBy, setSort, selectedId, handleSelect, onDelete, requestUsers }) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [_data, setData] = useState([]);
  const [importModal, setImportModal] = useState(null);
  const [file, setFile] = useState(null);
  const [delimiter, setDelimiter] = useState('');
  const [truncate, setTruncate] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [clearModal, setClearModal] = useState(false);
  const [clearId, setClearId] = useState({});

  useEffect(() => {
    data && data.length > 0 && _data.length == 0 && setData([...data])
  }, [data])
  
  const openModal = item => {
    setModal({
      modalType: EDIT,
      data: item,
    })
  }

  const handleClear = (data) => {
    // очистка полей справочника

      api.delete(`/guide/${clearId.sql_name}/truncate`).then(response => {
        if (response.status == 200) {
          createNotification('success', 'Успешно');
          requestUsers();
        } else {
          createNotification('error', 'Ошибка');
        }
      })
  }

  const handleImport = async () => {
    // импорт полей из файла
    
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await api.post(`/guide/${importModal.sql_name}/csvimport?delimiter=${delimiter ? delimiter : null}&truncate=${truncate}`, formData);
        if (response.status == 200) {
          setFile(null);
          setImportModal(null);
          createNotification('success', 'Успешно');
          requestUsers();
        }
      }
      catch (e) {
        createNotification('error', 'Ошибка')
      }
    };

  const popupImport = (item) => {
    setImportModal(item);
  }

  const handleDownload = (item) => {
    let a = document.createElement('a');
    a.href=`${BASE_URL}/guide/${item.sql_name}/csvtemplate`;
    a.target = '_blank';
    a.click();
  }

  const handleDelete = () => {
    onDelete();
  }
  const handleClear_ = (id) => {
    setClearId(id);
    setClearModal(true);
  }

  const handleCancel = () => {
    setClearId({});
    setClearModal(false);
  }

  return (
    <>
    <ModalWindow title={'Удаление справочника'} isOpen={delModal} onRequestClose={() => setDelModal(false)}>
        <h2>Вы уверены, что хотите удалить справочник?</h2>

        <div className={'d-flex justify-content-end'}>
          <Button onClick={handleDelete} style={{margin: '0 1rem'}}>Да</Button>
          <Button onClick={() => setDelModal(false)} color='red' style={{margin: '0 1rem'}}>Отмена</Button>
        </div>
      </ModalWindow>
      <ModalWindow title={'Очистка справочника'} isOpen={clearModal} onRequestClose={() => setClearModal(false)}>
        <h2>Вы уверены, что хотите очистить справочник?</h2>

        <div className={'d-flex justify-content-end'}>
          <Button onClick={handleClear} style={{margin: '0 1rem'}}>Да</Button>
          <Button onClick={() => handleCancel()} color='red' style={{margin: '0 1rem'}}>Отмена</Button>
        </div>
      </ModalWindow>
      {
        importModal ? (
          <div className={styles.popupImportBg}>
              <div className={styles.popupImport}>
                <CloseCircleFilled className={styles.popupImportClose} onClick={() => setImportModal(null)}/>
                <div className={styles.popupImportTitle}>
                  Импорт полей
                </div>
                <span style={{display: 'block'}}>Файл должен быть в кодировке UTF-8</span>
                <input 
                  type='file' 
                  className={styles.popupImportInput}
                  onChange={(e) => setFile(e.target.files[0])}/>
                  <div className={styles.delimiter}>
                    <span>Свой разделитель (по умолчанию TAB):</span> <input type='text' onChange={(e) => setDelimiter(e.target.value)} className={styles.popupImportInput} value={delimiter}/>
                  </div>
                  <div className={styles.delimiter}>
                    <span>Очистить справочник перед импортом? </span> <input type='checkbox' onChange={(e) => setTruncate(e.target.checked)} className={styles.popupImportInput} value={truncate}/>
                  </div>
                <Button type='primary' onClick={handleImport} className={styles.popupImportButton}>Сохранить</Button>
              </div>
          </div>
        ) : <></>
      }
      <div className={styles.container}>
        <Space size={[10, 16]} style={{margin: '1rem 0'}}>
          <Button 
          type='primary'
            onClick={() =>
              setModal({
                modalType: ADD,
                data: {
                  date: '',
                  doc_name: '',
                  name: '',
                  number: '',
                  columns: []
                },
              })
            }>
          добавить
          </Button>

          <Button 
          type='danger'
          onClick={() => selectedId && setDelModal(true)}>
            удалить
          </Button>

        </Space>
        {isLoading && <SpinnerOuter />}
        
        <DataTable 
        data={data} 
        onCellClick={openModal}
        >
          <DataTableCol 
          title='Название' 
          value='name' 
          className={styles.leftAlign} 
          rowWidth='80%'/>
          <DataTableCol 
          title='Действия' 
          value={'_'} 
          className={styles.leftAlign} 
          rowWidth='10%'
          passClicks
          resolver={item => 
          <div className={styles.actions}>
          
          <Button 
            title='Импорт из файла'
            type='primary' 
            onClick={() => popupImport(item)}
            className={styles.button_icon}
            ><FileAddOutlined /></Button>
            <Button 
            title='Скачать шаблон'
            type='primary' 
            onClick={() => handleDownload(item)}
            className={styles.button_icon}
            ><DownloadOutlined /></Button>
            <Button 
            title='Очистить'
            type='danger' 
            onClick={() => handleClear_(item)}
            className={styles.button_icon}
            ><DeleteFilled /></Button>
          </div>
          }
          />
          <DataTableCol 
          title='Перейти' 
          value={'sql_name'} 
          className={styles.leftAlign} 
          rowWidth='5%'
          passClicks
          resolver={item => <Link to={`directory/${item.sql_name}`}>{item.sql_name}</Link>}
          />
          <DataTableCol
            title='Выбрано'
            rowWidth="5%"
            passClicks
            resolver={item => <input type='checkbox' checked={item.sql_name === selectedId} onChange={() => handleSelect(item.sql_name)} />}
          />
        </DataTable>
      </div>
    </>
  )
}

AdminDirectoryTable.propTypes = {
  data: T.array,
  setModal: T.func,
  sortBy: T.string,
  setSort: T.func,
  selectedId: T.number,
  handleSelect: T.func,
  onDelete: T.func,
}

AdminDirectoryTable.defaultProps = {
  data: [],
}

export default AdminDirectoryTable
