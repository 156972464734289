import { ERROR, LIBRARY_SAVE_FUND_REQUEST, START, SUCCESS } from "../../../action-types";

export const librarySaveFundRequestStart = (data, libraryId) => ({
  type: `${LIBRARY_SAVE_FUND_REQUEST}${START}`,
  payload: { data, libraryId }
});

export const librarySaveFundRequestSuccess = (data) => ({
  type: `${LIBRARY_SAVE_FUND_REQUEST}${SUCCESS}`,
  payload: data
});

export const librarySaveFundRequestError = (data) => ({
  type: `${LIBRARY_SAVE_FUND_REQUEST}${ERROR}`,
  payload: data
});
