import { ERROR, PDF_VIEWER_BOOKMARK_REQUEST, START, SUCCESS } from "../action-types";

export const pdfViewerBookmarkRequestStart = (bookId, fileId) => ({
    type: `${PDF_VIEWER_BOOKMARK_REQUEST}${START}`,
    payload: {bookId, fileId}
});

export const pdfViewerBookmarkRequestSuccess = (data) => ({
    type: `${PDF_VIEWER_BOOKMARK_REQUEST}${SUCCESS}`,
    payload: data
});

export const pdfViewerBookmarkRequestError = (data) => ({
    type: `${PDF_VIEWER_BOOKMARK_REQUEST}${ERROR}`,
    payload: data
});