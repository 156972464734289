import React from "react";
import { resolveBookCoverUrl } from "../../../utils/resolvePhotoUrl";
import styles from "./Image.module.css";

const Image = ({ item }) => {
  return (
    <div>
      {item.coverImage ? (
        <img
          className={styles.img}
          src={resolveBookCoverUrl(item.coverImage, item.id)}
        />
      ) : (
        "Отсутствует"
      )}
    </div>
  );
};

export default Image;
