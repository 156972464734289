import React, { useState } from "react";
import T from "prop-types";
import classNames from "classnames";
import styles from "./LibraryCardBlock.module.css";
import {Col, Row} from "react-bootstrap";
import LibraryCard from "../LibraryCard";
import {NavLink} from "react-router-dom";
import Button from "../../../components/Button";

const LibraryCardBlock = ({isOwn, data, onEditClick, isLibrarian, mid}) => {

    const printFormular = () => window.open(
        `/formular_print/${mid}`
    );

    const printLibraryCardPass = () => window.open(
        `/library_card_pass_print/${mid}`
    );

    return (
        <div className={styles.container}>
            <Row>
                <Col md={5}>
                    <LibraryCard isOwn={isOwn} data={data}/>
                </Col>
                <Col md={7} className={styles.heightInherit}>
                    <div className={classNames(styles.infoSection, 'd-flex justify-content-between flex-column')}>
                        <div className={styles.infoBlock}>{data && data.faculty}</div>
                        <div className={styles.infoBlock}>{data && data.cathedra}</div>
                        <div className={styles.infoBlock}>
                            Специальность: {data && data.specialisation}
                        </div>
                        <div className={styles.infoBlock}>
                            Группа: {data && data.groupname}
                        </div>
                        <div className="d-flex">
                            {!isOwn && <Button onClick={printFormular} label="Печать формуляра"/>}
                            {isLibrarian && <Button onClick={printLibraryCardPass} label="Печать карты" style="ml-2"/>}
                        </div>
                    </div>
                    <div className={styles.buttonSection}>
                        <NavLink
                            className={styles.button}
                            to={`/book_open_history/${mid}`}
                        >
                            <div
                                className={classNames(styles.buttonIcon, styles.iconHistory)}
                            />
                            <span>История</span>
                        </NavLink>

                        <NavLink
                            to={"/reading"}
                            className={classNames(styles.button)}
                        >
                            <div
                                className={classNames(styles.buttonIcon, styles.iconSearch)}
                            />
                            <span>Поиск</span>
                        </NavLink>
                        <div
                            onClick={onEditClick}
                            className={styles.button}
                        >
                            <div
                                className={classNames(styles.buttonIcon, styles.iconEdit)}
                            />
                            <span>Изменить</span>
                        </div>
                        <NavLink
                            className={styles.button}
                            to="/"
                        >
                            <div
                                className={classNames(styles.buttonIcon, styles.iconClose)}
                            />
                            <span>Закрыть читательский билет</span>
                        </NavLink>

                    </div>
                </Col>
            </Row>
        </div>
    );
};

LibraryCardBlock.propTypes = {
    data: T.object.isRequired,
    onEditClick: T.func.isRequired,
    isOwn: T.bool
};

export default LibraryCardBlock;
