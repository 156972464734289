import React, { useState } from 'react'
import T from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import styles from '../../../scenes/Catalogs/Authors/AuthorsScene.module.css'
import Search from '../../Search'

const SELECT_CLEAR = 'clear'

const AuthorsSearchForm = ({ onSubmit, searchData, setSearchData, institutions, libraryList }) => {
  const [selectedInstitution, setSelectedInstitution] = useState('')
  const [selectedLibrary, setSelectedLibrary] = useState('')
  
  // const libraryListToOptions = libraryList => {
  //   if (!selectedInstitution)
  //     return libraryList.map(({ name, id }) => {
  //       return {
  //         label: name,
  //         value: id,
  //       }
  //     })

  //   return libraryList.reduce((acc, { name, id, idclient }) => {
  //     if (idclient === selectedInstitution) {
  //       return [
  //         ...acc,
  //         {
  //           label: name,
  //           value: id,
  //         },
  //       ]
  //     }
  //     return acc
  //   }, [])
  // }

  const onChangeInstitution = (e, { action }) => {
    if (action === SELECT_CLEAR) {
      setSelectedInstitution('')
      setSearchData({ ...searchData, selectedInstitution })
    } else {
      setSelectedInstitution(e.value)
    }
  }

  // const onChangeLibrary = (e, { action }) => {
  //   if (action === SELECT_CLEAR) {
  //     setSelectedLibrary('')
  //     setSearchData({ ...searchData, selectedLibrary })
  //   } else {
  //     setSelectedLibrary(e.value)
  //   }
  // }

  const filterSearch = () => {
    setSearchData({ ...searchData, selectedInstitution, selectedLibrary, page: 1 })
    onSubmit()
  }

  return (
    <Row>
      <Col md={12}>
        <Select
          className={styles.select}
          placeholder={'Организация'}
          isClearable={true}
          options={institutions}
          onChange={onChangeInstitution}
        />
      </Col>
      {/* <Col md={6}>
        <Select
          className={styles.select}
          placeholder={'Библиотека'}
          isClearable={true}
          options={libraryListToOptions(libraryList)}
          onChange={onChangeLibrary}
        />
      </Col> */}
      <Col md={12}>
        <Search
          value={searchData.query}
          onChange={query => setSearchData({ ...searchData, query })}
          onSubmit={filterSearch}
          placeholder={'поиск'}
        />
      </Col>
    </Row>
  )
}

AuthorsSearchForm.propTypes = {
  institutions: T.array.isRequired,
  libraryList: T.array.isRequired,
  searchData: T.object.isRequired,
  setSearchData: T.func.isRequired,
  onSubmit: T.func.isRequired,
}

export default AuthorsSearchForm
