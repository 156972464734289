import {IMPORT_RUSMARC_SAVE,IMPORT_BOOKS_RESET, SUCCESS} from "../../../actions/action-types";

const initialState = null;

export const rusMarcReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${IMPORT_RUSMARC_SAVE}${SUCCESS}`:
            return action.payload;

        case `${IMPORT_BOOKS_RESET}`:
            return initialState;

        default:
            return state;
    }
};