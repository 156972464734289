import { call, put, takeLatest } from "redux-saga/effects";
import { START, USERS_SAVE_PHOTO_REQUEST } from "../../../actions/action-types";
import { usersSavePhotoRequestError, usersSavePhotoRequestSuccess } from "../../../actions/adminUsers/photo/savePhoto";
import { saveUserPhoto } from "../../../../api/adminUsers/users";
import { usersCountRequestStart } from "../../../actions/adminUsers/count";

export default function* saveUserPhotoWatcher() {
  yield takeLatest(`${USERS_SAVE_PHOTO_REQUEST}${START}`, saveUserPhotoWorker)
}

function* saveUserPhotoWorker({ payload: { file, mid } }) {
  try {
    const response = yield call(saveUserPhoto, file, mid);
    yield put(usersSavePhotoRequestSuccess(response.data));
    yield put(usersCountRequestStart());
  } catch (error) {
    yield put(usersSavePhotoRequestError(error));
  }
}