import {combineReducers} from "redux";
import {countReducer} from "./count/countReducer";
import {formReducer} from "./formData/formReducer";
import {listReducer} from "./list/listReducer";
import {loaderReducer} from "./loader/loaderReducer";
import {searchReducer} from "./searchData/searchReducer";


export const coversScanPlanReducer = combineReducers({
    count: countReducer,
    form: formReducer,
    list: listReducer,
    loader: loaderReducer,
    search: searchReducer,
})