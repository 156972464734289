import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../ModalWindow";
import InputWrapper from "../../Modal/InputWrapper";
import {Button} from "antd";

const InvNumbersLocationModal = ({data, closeModal}) => {
    return (
        <ModalWindow
            title={`Месторасположение`}
            isOpen={true}
            onRequestClose={closeModal}
        >

            <InputWrapper
                label="Библиотека"
                value={data.library}
                disabled
            />

            <InputWrapper
                label="Зал"
            >
                <select name="">

                </select>
            </InputWrapper>

            <InputWrapper
                label="Стеллаж"
            >
                <select name="">

                </select>
            </InputWrapper>

            <InputWrapper
                label="Зал"
            >
                <select name="">

                </select>
            </InputWrapper>

            <Button type="primary" danger>Отмена</Button>
            <Button type="primary">Сохранить</Button>
        </ModalWindow>
    );
};

InvNumbersLocationModal.propTypes = {};

export default InvNumbersLocationModal;