import {ERROR, START, SUCCESS, TEACHERS_COUNT_REQUEST} from "../action-types";

export const teachersCountRequestStart = () => ({
    type: `${TEACHERS_COUNT_REQUEST}${START}`
});

export const teachersCountRequestSuccess = data => ({
    type: `${TEACHERS_COUNT_REQUEST}${SUCCESS}`,
    payload: data
});

export const teachersCountRequestError = error => ({
    type: `${TEACHERS_COUNT_REQUEST}${ERROR}`,
    payload: error
})