import {ERROR, OWN_USER_DATA_REQUEST, START, SUCCESS} from "../action-types";

export const ownUserDataRequestStart = (data) => ({
    type: `${OWN_USER_DATA_REQUEST}${START}`,
    payload: data
});

export const ownUserDataRequestSuccess = (data) => ({
    type: `${OWN_USER_DATA_REQUEST}${SUCCESS}`,
    payload: data
});

export const ownUserDataRequestError = (data) => ({
    type: `${OWN_USER_DATA_REQUEST}${ERROR}`,
    payload: data
});

