
import {BOOK_OPEN_HISTORY_LIST, ERROR, START, SUCCESS} from "../../../actions/action-types";


const initialState = false;

export const bookOpenHistoryLoaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${BOOK_OPEN_HISTORY_LIST}${START}`:
            return true;

        case `${BOOK_OPEN_HISTORY_LIST}${SUCCESS}`:
            return false;

        case `${BOOK_OPEN_HISTORY_LIST}${ERROR}`:
            return false;

        default: return state;
    }
};
