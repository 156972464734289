import React from 'react';
import T from 'prop-types';
import {resolveMediaResLink, resolveMediaResRef} from "../../../../utils/resolveMediaResLink";
import styles from './ListItem.module.css';
import {Col} from "react-bootstrap";
import classNames from 'classnames';


const ListItem = ({data}) => {
  const {logoexist, resourcename, logo, id} = data;

  return (
    <Col md={4}>
      <a href={resolveMediaResRef(id)} target="_blank" rel="noopener noreferrer"  className={classNames(styles.card, 'd-flex flex-column align-items-center')}>
        {logoexist ?
          <img src={ resolveMediaResLink(logo) } alt=""/>
          :
          <div className={styles.photoPlaceholder}/>
        }

        { resourcename }
      </a>
    </Col>
  );
};

ListItem.propTypes = {
  data: T.object
};

export default ListItem;