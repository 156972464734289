import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  BORROWING_TEST_COUNT_REQUEST,
  BORROWING_TEST_LIST_REQUEST,
  BORROWING_TEST_DELETE_REQUEST,
  START, BORROWING_TEST_ADD_REQUEST, BORROWING_TEST_EDIT_REQUEST, SUCCESS
} from "../../actions/action-types";
import {
  getAntiplagiatCheckRequestCount,
  getAntiplagiatCheckRequestList,
  deleteAntiplagiatCheckRequestItem,
  addAntiplagiatCheckRequestItem,
  editAntiplagiatCheckRequestItem,
} from "../../../api/borrowingTest/antiplagiat";
import {
  borrowingTestCountRequestError,
  borrowingTestCountRequestStart,
  borrowingTestCountRequestSuccess
} from "../../actions/borrowingTest/count";
import {
  borrowingTestListRequestError,
  borrowingTestListRequestStart,
  borrowingTestListRequestSuccess
} from "../../actions/borrowingTest/list";
import {setBorrowingTestSearchData} from "../../actions/borrowingTest/search";
import {borrowingTestDeleteRequestError, borrowingTestDeleteRequestSuccess} from "../../actions/borrowingTest/delete";
import {borrowingTestAddRequestError, borrowingTestAddRequestSuccess} from "../../actions/borrowingTest/add";
import {borrowingTestEditRequestError, borrowingTestEditRequestSuccess} from "../../actions/borrowingTest/edit";



export default function* borrowingTestWatcher() {
  yield takeLatest(`${BORROWING_TEST_COUNT_REQUEST}${START}`, countWorker);
  yield takeLatest(`${BORROWING_TEST_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${BORROWING_TEST_DELETE_REQUEST}${START}`, deleteWorker);
  yield takeLatest(`${BORROWING_TEST_ADD_REQUEST}${START}`, addWorker);
  yield takeLatest(`${BORROWING_TEST_EDIT_REQUEST}${START}`, editWorker);

}

function* countWorker() {
  const searchData = yield select(({borrowingTest}) => borrowingTest.search);
  const formData = yield select(({borrowingTest}) => borrowingTest.form);
  try {
    const response = yield call(getAntiplagiatCheckRequestCount, formData);
    const count = parseInt(response.data.count, 10);
    yield put(borrowingTestCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setBorrowingTestSearchData({ ...searchData, pageCount}));
    if(count > 0) {
      yield put(borrowingTestListRequestStart());
    }
  } catch (error) {
  }
  yield put(borrowingTestCountRequestError(SUCCESS));
}

function* listWorker() {
  const searchData = yield select(({borrowingTest}) => borrowingTest.search);
  const formData = yield select(({borrowingTest}) => borrowingTest.form);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getAntiplagiatCheckRequestList, {...formData, ...searchData});
    yield put(borrowingTestListRequestSuccess(response.data));
      } catch (error) {
      yield put(borrowingTestListRequestError(error));
    }
}

function* deleteWorker({ payload: itemId }) {
    try {
        yield call(deleteAntiplagiatCheckRequestItem, itemId);
        yield put(borrowingTestDeleteRequestSuccess());
        yield put(borrowingTestCountRequestStart());
    } catch(error) {
        yield put(borrowingTestDeleteRequestError(error));
    }
}

function* addWorker ({ payload: { comment, file } }) {
    try {
        yield call(addAntiplagiatCheckRequestItem, {comment, file});
        yield put(borrowingTestAddRequestSuccess());
        yield put(borrowingTestCountRequestStart());
    } catch(error) {
        yield put(borrowingTestAddRequestError(error));
    }
}

function* editWorker({ payload: {comment, itemId} }) {
    try {
        yield call(editAntiplagiatCheckRequestItem, {comment, itemId});
        yield put(borrowingTestEditRequestSuccess());
        yield put(borrowingTestCountRequestStart());
    } catch(error) {
        yield put(borrowingTestEditRequestError(error));
    }
}
