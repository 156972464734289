import {
    PDF_VIEWER_BOOKMARK_REQUEST, PDF_VIEWER_HIGHLIGHT_ADD, PDF_VIEWER_HIGHLIGHT_DELETE, PDF_VIEWER_HIGHLIGHT_SET,
    SUCCESS
} from "../../../actions/action-types";

const initialState = [];


export const pdfViewerHighlightsReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${PDF_VIEWER_BOOKMARK_REQUEST}${SUCCESS}`:
            return action.payload;

        case `${PDF_VIEWER_HIGHLIGHT_SET}`:
            return action.payload;

        case `${PDF_VIEWER_HIGHLIGHT_ADD}`:
            return [...state, action.payload];

        case `${PDF_VIEWER_HIGHLIGHT_DELETE}`:
            return state.filter(item => item.id !== action.payload);

        default:
            return state;
    }
};
