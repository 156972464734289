import {combineReducers} from "redux";
import { seriesCountReducer } from './count/seriesCountReducer';
import { seriesListReducer } from './list/seriesListReducer';
import { seriesLoaderReducer } from './loader/seriesLoaderReducer';
import { seriesFormReducer } from './form/seriesFormReducer';
import { seriesSearchReducer } from './search/seriesSearchReducer';

export const seriesReducer = combineReducers({
  count: seriesCountReducer,
  list: seriesListReducer,
  loader: seriesLoaderReducer,
  form: seriesFormReducer,
  search: seriesSearchReducer
});