import { BOOK_TRANSFER_COUNT_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const bookTransferCountRequestStart = () => ({
  type: `${BOOK_TRANSFER_COUNT_REQUEST}${START}`
});

export const bookTransferCountRequestSuccess = (data) => ({
  type: `${BOOK_TRANSFER_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookTransferCountRequestError = (error) => ({
  type: `${BOOK_TRANSFER_COUNT_REQUEST}${ERROR}`,
  payload: error
});
