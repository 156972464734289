import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import InputWrapper from '../../../Modal/InputWrapper'
import { Button } from 'antd'
import T from 'prop-types'
import { EDIT } from '../../../../constants/modalTypes'
import moment from 'moment'
import styles from './From.module.css'



const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const creatingValidationSchema = Yup.object().shape({
  lastname: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле'),
  firstname: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле'),
  patronymic: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле'),
  birthdate: Yup.string().required('Необходимо заполнить поле'),
  rfid: Yup.string().max(255, 'Максимальная длинна поля 255 символов'),
  login: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле'),
  password: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле')
  .matches(passwordRules, "Пожалуйста выберите более сложный пароль"), // min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
  guards:  Yup.string().required("Заполните").nullable(), 
  justice:  Yup.string().required("Заполните").nullable(), 
  designation: Yup.string().required("Заполните").nullable(), 
  reserve:  Yup.string().required("Заполните").nullable(), 
  admission:  Yup.string().required("Заполните").nullable(), 
  position: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле').nullable(),
})

const editingValidationSchema = Yup.object().shape({
  lastname: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле'),
  firstname: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле'),
  patronymic: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле'),
  birthdate: Yup.string().required('Необходимо заполнить поле').nullable(),
  guards: Yup.string().required("Заполните").nullable(), 
  justice: Yup.string().required("Заполните").nullable(), 
  designation: Yup.string().required("Заполните").nullable(), 
  reserve: Yup.string().required("Заполните").nullable(),
  admission: Yup.string().required("Заполните").nullable(),
  position: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле').nullable(),
  // rfid: Yup.string().max(255, 'Максимальная длинна поля 255 символов').nullable(),
  password: Yup.string().max(255, 'Максимальная длинна поля 255 символов').required('Необходимо заполнить поле')
  .matches(passwordRules, "Пожалуйста выберите более сложный пароль"), // min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
})

  const guards_arr = [
    {name: '', value: '0'},
    {name: '-', value: '-'},
    {name: 'гвардии', value: 'гвардии'},
  ]
 
  const justice_arr = [
    {name: '', value: '0'},
    {name: '-', value: '-'},
    {name: 'юстиции', value: 'юстиции'},
    {name: 'мед. службы', value: 'мед. службы'},
  ]
  
  const reserve_arr = [
    {name: '', value: '0'},
    {name: '-', value: '-'},
    {name: 'запаса', value: 'запаса'},
    {name: 'в отставке', value: 'в отставке'},
  ]

const AdminUsersModalForm = ({ modalData, onSubmit, onlyInfo, modalType, saveUser, groups, militaryRank, admission }) => {
  
  const [groupsState, setGroupsState] = useState(groups?.find(e => e.name == modalData.data.groupname)?.gid);
  
  const CONNECTION_PORT = 'ws://localhost:18988/cardreader'

  const formik = useFormik({
    initialValues: {
      ...modalData.data,
      birthdate: moment(modalData.data.birthdate||undefined).format('YYYY-MM-DD')
    },
    validateOnChange: false,
    validationSchema: modalType === EDIT ? editingValidationSchema : creatingValidationSchema,
    onSubmit: values => {
      onSubmit({
        ...values,
        gid: groupsState || null
      })
    }
  })

  useEffect(() => {
    const connection = new WebSocket(CONNECTION_PORT)

    connection.onmessage = function (event) {
      const eventData = JSON.parse(event.data)
      formik.setFieldValue('rfid', eventData.cardnumber)
    }

    return () => {
      connection.close(1000, 'React компонент демонтирован')
    }
  }, [])

  return (
    <div>
      <InputWrapper
        label={'Фамилия*'}
        value={formik.values.lastname}
        name={'lastname'}
        onChange={formik.handleChange}
        error={formik.errors.lastname}
      />
      <InputWrapper
        label={'Имя*'}
        value={formik.values.firstname}
        name={'firstname'}
        onChange={formik.handleChange}
        error={formik.errors.firstname}
      />
      <InputWrapper
        label={'Отчетство*'}
        value={formik.values.patronymic}
        name={'patronymic'}
        onChange={formik.handleChange}
        error={formik.errors.patronymic}
      />
      <InputWrapper
        label={'Дата рождения*'}
        value={formik.values.birthdate}
        name={'birthdate'}
        onChange={formik.handleChange}
        error={formik.errors.birthdate}
        type='date'
      />
      <InputWrapper label={'Должность*'} error={formik.errors.position}>
        <textarea 
          value={formik.values.position}
          onChange={formik.handleChange} 
          name={'position'}
          className={styles.textarea}
          />
      </InputWrapper>
      <InputWrapper label={'Воинское звание*'}>

        <div className={styles.wrapperDiv}>
        <div style={{margin: '0 .5rem .5rem 0'}}>
        <InputWrapper error={formik.errors.guards}>
          <select value={formik.values.guards} name='guards' onChange={formik.handleChange}>
              {guards_arr?.map((item) => (
                <option value={item.id} key={item.id}>{item.name}</option>
              ))}
          </select>
        </InputWrapper>
        </div>
        <div style={{margin: '0 .5rem .5rem'}}>
        <InputWrapper error={formik.errors.designation}>
          <select value={formik.values.designation} name='designation' onChange={formik.handleChange}>
              {[{title: '', id: '0'},{title: '-', id: '-'}, ...militaryRank]?.map((item) => (
                <option value={item.id_mil_rank} key={item.id_mil_rank}>{item.title}</option>
              ))}
          </select>
        </InputWrapper>
        </div>
        <div style={{margin: '0 .5rem .5rem'}}>
        <InputWrapper error={formik.errors.justice}>
          <select value={formik.values.justice} name='justice' onChange={formik.handleChange}>
              {justice_arr?.map((item) => (
                <option value={item.id} key={item.id}>{item.name}</option>
              ))}
          </select>
        </InputWrapper>
        </div>
        <div style={{margin: '0 0 .5rem .5rem'}}>  
        <InputWrapper error={formik.errors.reserve}>
          <select value={formik.values.reserve} name='reserve' onChange={formik.handleChange}>
              {reserve_arr?.map((item) => (
                <option value={item.id} key={item.id}>{item.name}</option>
              ))}
          </select>
        </InputWrapper>
        </div>
        </div>
      </InputWrapper>
      <InputWrapper label={'Форма допуска*'} error={formik.errors.admission}>
        <select value={formik.values.admission} name='admission' onChange={formik.handleChange}>
          {[{id: '', security_form: '', maximun_access: ''}, ...admission]?.map((item) => (
            <option value={item.id} key={item.id}>
              {item.id != '' ? `${item.security_form} (${item.maximun_access})` : ''}
              </option>
          ))}
        </select>
      </InputWrapper>

      <InputWrapper
        label={'RFID'}
        value={formik.values.rfid}
        name={'rfid'}
        onChange={formik.handleChange}
        error={formik.errors.rfid}
      />
      <InputWrapper label={'Группы'}>
        <select value={groupsState} name='verifyStatus' onChange={e => setGroupsState(e.target.value)}>
          <option selected='selected' value={groups?.find(e => e.name == modalData.data.groupname)?.gid}>
            {groups?.find(e => e.name == modalData.data.groupname)?.name}
          </option>
          {groups?.map((item, index) => (
            <option value={item.gid} key={index}>
              {item.name}
            </option>
          ))}
        </select>
      </InputWrapper>
      {!onlyInfo && (
        <>
          <InputWrapper
            label={'Логин*'}
            value={formik.values.login}
            name={'login'}
            onChange={formik.handleChange}
            error={formik.errors.login}
          />
          <InputWrapper
            label={'Пароль*'}
            value={formik.values.password}
            name={'password'}
            onChange={formik.handleChange}
            error={formik.errors.password}
          />
          <p className={styles.passDescription}>пароль должен быть не менее 8 символов, содержать буквы в верхнем и нижнем регистре, содержать цифры</p>
        </>
      )}
      <div className={'d-flex justify-content-end'}>
        <Button type={'primary'} onClick={formik.handleSubmit}>Сохранить</Button>
      </div>
    </div>
  )
}

AdminUsersModalForm.propTypes = {
  modalData: T.object.isRequired,
  onSubmit: T.func.isRequired,
}

export default AdminUsersModalForm
