import { api } from "../../App";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import { START, SUCCESS } from "../actions/action-types";
import {
  call,
  put,
  takeLatest,
  takeEvery,
  select
} from "redux-saga/effects";
import { combineReducers } from "redux";
import { onHandsBooksActions } from "./onHandsBooksReduxData";
import {createNotification} from "../../components/notification/notification";

const getAllBooks = formData => {
  return api.post("/searchinstancestate", formData);
};

const moveBook = body => {
  return api.post("/bookmove", { ...body });
};

const BOOKS_REQUEST = `AVAILABLE_BOOKS_REQUEST`;
const MOVE_BOOK_REQUEST = `MOVE_BOOK_REQUEST`;
const SET_FORM_DATA = `SET_FORM_DATA`;

export const availableBooksActions = {
  ...createSimpleActionsForReducer("list", BOOKS_REQUEST),
  moveBook: payload => ({
    type: MOVE_BOOK_REQUEST,
    payload
  }),
  setFormData: payload => ({
    type: SET_FORM_DATA,
    payload
  })
};

export const availableBooksReducers = combineReducers({
  list: createSimpleReducer([], `${BOOKS_REQUEST}${SUCCESS}`),
  form: createSimpleReducer(
    {
      title: "",
      author: "",
      category: "",
      isbn: "",
      year: "",
      udk: "",
      bbk: "",
      discipline: "",
      publisher: "",
      textsearch: "",
      verifyStatus: "",
      idclient: ""
    },
    `${SET_FORM_DATA}`
  )
});

function* loadAvailableBooksSaga() {
  try {
    const formData = yield select(({ availableBooks }) => availableBooks.form);
    const response = yield call(getAllBooks, formData);
    yield put(availableBooksActions.listRequestSuccess(response.data));
  } catch (e) {
    yield put(availableBooksActions.listRequestError());
  }
}

function* moveBookSaga({
  payload: { typeFrom, placeFrom, placeTo, typeTo, instanceId, mid, returndate }
}) {
  try {
    yield call(moveBook, {
      typefrom: Number(typeFrom),
      placefrom: Number(placeFrom),
      typeto: Number(typeTo),
      placeto: Number(placeTo),
      movedate: new Date().toISOString(),
      bookinstanceid: instanceId,
      returndate: returndate,
      amount: 1
    });
    yield put(onHandsBooksActions.listRequestStart(mid));
    // Шифр
    if(!placeFrom || placeFrom === "0") {
        createNotification('success', 'Экземпляр книги успешно выдан')
    } else {
        createNotification('success', 'Экземпляр книги успешно сдан в библиотеку')
    }


  } catch (e) {
    console.log(e);
  }
}

export function* availableBooksSagaWatcher() {
  yield takeLatest(`${BOOKS_REQUEST}${START}`, loadAvailableBooksSaga);
  yield takeEvery(`${MOVE_BOOK_REQUEST}`, moveBookSaga);
}
