import { SET_BOOK_COVER} from "../../../actions/action-types";

const initialState = null;

export const bookEditCoverReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOK_COVER:
      return action.payload;

    default: return state;
  }
}
