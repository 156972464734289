import React, { useEffect, useState } from 'react'
import { ADD, EDIT } from '../../../constants/modalTypes'
import { api } from '../../../App'

import { connect } from 'react-redux'
import { inventoryBookListRequestStart, inventoryBooksCleanup } from '../../../redux/actions/inventory/books/list'
import { institutionsAllListRequestStart } from '../../../redux/actions/institutions/all'
import { librariesListRequestStart } from '../../../redux/actions/inventory/fundAllocation/libraries'
import { bookDataRequestStart } from '../../../redux/actions/bookData/data'

import ModalHeader from './DocumentModalHeader'
import ModalWindow from '../../ModalWindow'
import { onHandsBooksActions } from '../../../redux/factory/onHandsBooksReduxData'
import InventoryEdit from './InventoryEdit'
import { requestLibraryFunds } from '../../../redux/reducers/inventory/fundAllocation/libraryFundReducer'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import { inventoryCountRequestStart } from '../../../redux/actions/inventory/documents/count'

const mapStateToProps = ({ inventory, institutions, options }) => ({
  latestNumber: inventory.number,
  institutionsList: institutions.all,
  libraries: inventory.libraries,
  libraryFunds: inventory.libraryFunds,
  clientData: options.data,
  bookData: inventory.books,
})

const mapDispatchToProps = {
  requestLibraryFunds,
  requestBooks: inventoryBookListRequestStart,
  requestInstitutions: institutionsAllListRequestStart,
  requestLibraries: librariesListRequestStart,
  booksCleanup: inventoryBooksCleanup,
  requestData: bookDataRequestStart,
  requestOnHandsBooks: onHandsBooksActions.listRequestStart,
  requestInvList: inventoryCountRequestStart
}

const InventoryModal = ({
  list,
  modalData,
  setModalData,
  requestBooks,
  latestNumber,
  booksCleanup,
  onSave,
  saveInventory,
  requestLibraries,
  clientData,
  history,
  institutionsList,
  requestInstitutions,
  libraries,
  requestInvList,
  libraryFunds,
  requestLibraryFunds,
}) => {
  const { modalType, data } = modalData
  const documentId = data ? data.id : null

  const [searchOpen, setSearchOpen] = useState(false)
  const [currentBook, setCurrentBook] = useState(null)
  const [formValidity, setFormValidity] = useState(false)
  const [documentid, setDocumentid] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    api
      .get('/people')
      .then(data => setUsers(data.data))
      .catch(e => setUsers([]))
    // return () => {
    //   setUsers([])
    // }
  }, [])

  useEffect(() => {
    if (modalType === EDIT) setFormValidity(true)
  }, [modalType])

  useEffect(() => {
    if (modalData.data) setDocumentid(modalData.data.id)
  }, [modalData])

  useEffect(() => {
    getBooksList()
  }, [modalData])

  useEffect(() => {
    requestInstitutions()
  }, [requestInstitutions])

  useEffect(() => {
    modalType === ADD && setCurrentBook(null)
  }, [modalType])

  useEffect(() => {
    clientData && requestLibraries(clientData.own_client_id)
  }, [requestLibraries, clientData])

  const getBooksList = () => {
    if (!documentId || modalType !== EDIT) return;
    requestBooks(documentId)
  }

  if (modalType === ADD && !data) {
    setModalData({
      modalType,
      data: {
        documentname: '',
        documentnumber: latestNumber,
        documenttype: '',
        documentdate: new Date().toISOString().split('T')[0],
        supplier: '',
      },
    })
  }

  const onCreateNewBook = () => {
    const params = {
      title: '',
      publisher: '',
      year: 0,
    }
    api.post('/book', params).then(response => {
      history.push(`/book/${response.data.book.id}/edit?newbook`)
    })
  }

  const onAddBook = () => {
    setSearchOpen(!searchOpen)
  }

  const onSelectFromSearch = ({ title, instanceid }) => {
    setCurrentBook({
      book_instance: instanceid,
      title,
      amount: 1,
      price: 0,
      documentid: documentId,
    })

    setSearchOpen(false)
  }

  const closeModal = () => {
    booksCleanup()
    setModalData({ modalType: null, data: null })
    setSearchOpen(false)
    setCurrentBook(null)
  }

  const saveDocument = data => {
    const { id } = data
    const params = {
      id,
      ...data,
    }
    onSave(params)
  }

  return (
    <ModalWindow
      title={`Документ: ${modalType === EDIT ? 'редактирование' : 'добавление'}`}
      isOpen={true}
      onRequestClose={closeModal}
    >
      {data && (
        <>
          {users ? (
            <ModalHeader
              list={list}
              modalData={data}
              setModalData={setModalData}
              modalType={modalType}
              saveDocument={saveDocument}
              setFormValidity={setFormValidity}
              institutionsList={institutionsList}
              clientData={clientData}
              libraries={libraries}
              libraryFunds={libraryFunds}
              requestLibraryFunds={requestLibraryFunds}
              requestInvList={requestInvList}
              getBooksList={getBooksList}
              users={users}
            />
          ) : (
            <SpinnerOuter />
          )}

          <InventoryEdit
            formValidity={formValidity}
            modalData={modalData}
            setCurrentBook={setCurrentBook}
            currentBook={currentBook}
            saveInventory={saveInventory}
            onAddBook={onAddBook}
            searchOpen={searchOpen}
            requestBooks={requestBooks}
            onSelectFromSearch={onSelectFromSearch}
            onCreateNewBook={onCreateNewBook}
            documentid={documentid}
          />
        </>
      )}
    </ModalWindow>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryModal)
