import { all } from 'redux-saga/effects';
import roomsSaga from "./rooms/roomsSaga";
import racksSaga from "./racks/racksSaga";
import shelvesSaga from "./shelves/shelvesSaga";

export default function* locationSaga() {
  yield all([
    roomsSaga(),
    racksSaga(),
    shelvesSaga()
  ]);
}