
export const resolveExportLink = (bookId) =>
  `/oai/book/${bookId}`;

export const resolveRusmarcLink = (bookId) =>
    `/bookexport?ids=${bookId}&format=rusmarc`;

export const resolveJSONLink = (bookId) =>
    `/bookexport?ids=${bookId}&format=json`;

