import {SET_TRANSFER_LOG_SEARCH_DATA} from "../../../../actions/action-types";
import {SORT_DIRECTION} from "../../../../../constants/sortDirections";
import {RESULTS_PER_PAGE} from "../../../../../constants/pagination";
import {ADMIN_LOG_CHECK_SORT} from "../../../../../constants/sort/adminLogCheckSort";


const initialState = {
    page: 1,
    sortBy: ADMIN_LOG_CHECK_SORT.NAME,
    sortDirection: SORT_DIRECTION.DESC,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0
};

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_TRANSFER_LOG_SEARCH_DATA:
            return action.payload;

        default: return state;
    }
};
