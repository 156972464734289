import { call, put, takeLatest } from "redux-saga/effects";
import { OPTIONS_GET_REQUEST, START } from "../../../actions/action-types";
import { getOptions } from "../../../../api/options/options";
import { optionsGetRequestError, optionsGetRequestSuccess } from "../../../actions/options/get";

export default function* getOptionsWatcher() {
  yield takeLatest(`${OPTIONS_GET_REQUEST}${START}`, getOptionsWorker)
}

function* getOptionsWorker() {
  try {
    const response = yield call(getOptions);
    yield put(optionsGetRequestSuccess(response.data));
  } catch (error) {
    yield put(optionsGetRequestError(error));
  }
}