import { ERROR, BOOK_SECRET_DOC_COUNT_REQUEST, START, SUCCESS } from '../action-types'

export const bookSecretDocCountRequestStart = formData => ({
  type: `${BOOK_SECRET_DOC_COUNT_REQUEST}${START}`,
  payload: formData,
})

export const bookSecretDocCountRequestSuccess = data => ({
  type: `${BOOK_SECRET_DOC_COUNT_REQUEST}${SUCCESS}`,
  payload: data,
})

export const bookSecretDocCountRequestError = error => ({
  type: `${BOOK_SECRET_DOC_COUNT_REQUEST}${ERROR}`,
  payload: error,
})
