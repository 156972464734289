import { SET_LIBRARY_PAGE_COUNT, SET_LIBRARY_SEARCH_DATA } from "../../../../actions/action-types";
import { LIBRARY_SORT } from "../../../../../constants/sort/librarySort";
import { RESULTS_PER_PAGE } from "../../../../../constants/pagination";

const initialState = {
  sortBy: LIBRARY_SORT.NAME,
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const librarySearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LIBRARY_SEARCH_DATA:
      return action.payload;
    
    case SET_LIBRARY_PAGE_COUNT:
      return {...state, pageCount: action.payload};

    default:
      return state;
  }
};
