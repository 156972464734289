import React from 'react'
import styles from './AdminMenuScene.module.css'
import { Link, Redirect } from 'react-router-dom'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { useSelector } from 'react-redux'

const AdminMenuScene = () => {
  const authData = useSelector(({ auth }) => auth.data)

  if (!authData.role_permissions.find(e => e.RasdelName === 'Настройка,"Настройка прав доступов"')) return <Redirect to='/' />
  return (
    <SceneContainer title={'Администрирование'}>
      <ul>
        <li>
          <Link to={'/admin/roles'} className={styles.menuLink}>
            Роли
          </Link>
        </li>
        <li>
          <Link to={'/admin/users'} className={styles.menuLink}>
            Пользователи
          </Link>
        </li>
        <li>
          <Link to={'/admin/groups'} className={styles.menuLink}>
            Группы
          </Link>
        </li>
        <li>
          <Link to={'/admin/permissions'} className={styles.menuLink}>
            Настройка прав доступа
          </Link>
        </li>
        <li>
          <Link to={'/admin/categories_permissions'} className={styles.menuLink}>
            Настройка прав доступа по категориям
          </Link>
        </li>
        <li>
          <Link to={'/admin/liters_permissions'} className={styles.menuLink}>
            Настройка прав доступа по литерам
          </Link>
        </li>
        <li>
          <Link to={'/admin/series_permissions'} className={styles.menuLink}>
            Настройка прав доступа по сериям
          </Link>
        </li>
        <li>
          <Link to={'/admin/log/audit'} className={styles.menuLink}>
            Контроль доступа к ресурсам
          </Link>
        </li>
        <li>
          <Link to={'/admin/log/journal'} className={styles.menuLink}>
            Подсистема журналирования
          </Link>
        </li>
        <li>
          <Link to={'/admin/log/check'} className={styles.menuLink}>
            Проверка целостности данных при миграции
          </Link>
        </li>
        <li>
          <Link to={'/admin/scheduler'} className={styles.menuLink}>
            Редактирование расписания миграции метаданных
          </Link>
        </li>
        <li>
          <Link to={'/admin/directory'} className={styles.menuLink}>
            Добавить справочник
          </Link>
        </li>
        <li>
          <Link to={'/admin/system'} className={styles.menuLink}>
            О системе
          </Link>
        </li>
      </ul>
    </SceneContainer>
  )
}

export default AdminMenuScene
