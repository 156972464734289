import { api } from "../../App";

export const getCategoryList = (query) => {

  const params = {
    search: query,
    noPagination: true,
  };

  return api.get(`/category`, {params});
};