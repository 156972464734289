import { ERROR, ROOMS_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const roomsListRequestStart = (data) => ({
  type: `${ROOMS_LIST_REQUEST}${START}`,
  payload: data
});

export const roomsListRequestSuccess = (data) => ({
  type: `${ROOMS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const roomsListRequestError = (data) => ({
  type: `${ROOMS_LIST_REQUEST}${ERROR}`,
  payload: data
});
