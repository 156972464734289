import React, { useEffect, useState } from 'react'
import DataTable from '../../../DataTable'
import DataTableCol from '../../../DataTable/Column'
import { connect } from 'react-redux'
import { getBookFile } from '../../../../api/book/files'
import { bookEditFilesActions, setBookEditFilesSortData } from '../../../../redux/factory/BookEditFiles/bookEditFilesReduxData'
import blankFile from '../../../../img/icons/file-formats/blank.png'
import djvuFile from '../../../../img/icons/file-formats/djvu-format.png'
import docFile from '../../../../img/icons/file-formats/doc-format.png'
import jpgFile from '../../../../img/icons/file-formats/jpg-format.png'
import pdfFile from '../../../../img/icons/file-formats/pdf-format.png'
import pngFile from '../../../../img/icons/file-formats/png-format.png'
import txtFile from '../../../../img/icons/file-formats/txt-format.png'
import xlsFile from '../../../../img/icons/file-formats/xls-format.png'
import zipFile from '../../../../img/icons/file-formats/zip.png'
import moment from 'moment'
import LoadForm from './loadForm'
import styles from './BookEditFiles.module.css'
import T from 'prop-types'
import BookEditFilesModal from './Modal'
import { Button, Tag, Tooltip } from 'antd'
import { downloadFileTwo, downloadFile, getFileFormat, openFile } from '../../../../utils/helpers/helpers'
import { api } from '../../../../App'
import { createNotification } from '../../../notification/notification'
import { BASE_URL_WITHOUT_API } from '../../../../constants/api'
import SpinnerOuter from '../../../UtulityComponents/SpinnerOuter'
import { saveAs } from 'file-saver'

import AntiplagiatModal from '../../../Antiplagiat/Modal/Index'

const mapStateToProps = ({ bookEdit }) => ({
  data: bookEdit.files.data,
  fileLoader: bookEdit.fileLoader,
  bookData: bookEdit.data,
  files: bookEdit.files,
  loader: bookEdit.files.loading,
  uploadLoader: bookEdit.uploadLoader,
})

const mapDispatchToProps = {
  onRequest: bookEditFilesActions.getRequestStart,
  onUpload: bookEditFilesActions.uploadRequestStart,
  onEdit: bookEditFilesActions.editRequestStart,
  onDelete: bookEditFilesActions.deleteRequestStart,
  setSortData: setBookEditFilesSortData,
}

const getIcon = filename => {
  let fileFormat = filename.split('.').reverse()[0]

  switch (fileFormat) {
    case 'djvu':
      return djvuFile
    case 'doc':
      return docFile
    case 'docx':
      return docFile
    case 'rtf':
      return docFile
    case 'jpg':
      return jpgFile
    case 'jpeg':
      return jpgFile
    case 'pdf':
      return pdfFile
    case 'png':
      return pngFile
    case 'txt':
      return txtFile
    case 'xls':
      return xlsFile
    case 'xlsx':
      return xlsFile
    case 'csv':
      return xlsFile
    case 'tgz':
    case 'zip':
    case 'rar':
      return zipFile
    default:
      return blankFile
  }
}

const BookEditFilesTab = ({ bookId, data, onRequest, onUpload, onEdit, onDelete, loader, bookData, uploadLoader }) => {
  useEffect(() => {
    onRequest(Number(bookId))
  }, [onRequest])

  const [modalData, setModalData] = useState(null);
  const [docId, setDocId] = useState(null);

  const archiveData = bookData?.archived
    ? [
        {
          id: '1242112', // любое
          filename: bookData?.archivelink,
          filedate: null,
          visible: true,
        },
      ]
    : []

  const isCompressed = bookData?.archived ? archiveData : data

  const uploadFile = file => {
    onUpload({
      bookId: bookId,
      file: file,
      body: {
        filedate: new Date().toISOString(),
      },
    })
  }

  const setVisibility = item => {
    const revertedVisibility = !item.visible
    const editedData = {
      ...item,
      visible: revertedVisibility,
    }

    editItem(editedData)
  }

  const editItem = item => {
    onEdit({
      bookId,
      fileId: item.id,
      data: item,
    })
  }

  const closeModal = () => {
    setModalData(null)
  }

  const submitModal = () => {
    editItem(modalData)
    closeModal()
  }

  const handleMove = (id) => {
    setDocId(id);
  }

  const FileCollectionButton = ({ item }) => {
    const fileFormat = getFileFormat(item.filename)

    switch (fileFormat.toUpperCase()) {
      case 'PDF':
      case 'TXT':
      case 'HTML':
      case 'HTM':
      case 'DOCX':
      case 'RTF':
      case 'ODT':
      case 'PPTX':
        switch (item.antiplagiat_collection_status) {
          case 0:
            return (
              <Button type='primary' onClick={() => handleMove(item.id)}>
                Отправить в коллекцию
              </Button>
            )
          case 1:
            return <Tag color='blue'>Новый</Tag>
          case 2:
            return <Tag color='cyan'>В работе</Tag>
          case 3:
            return <Tag color='green'>Готово</Tag>
          case 100:
            return (
              <Tooltip placement='topLeft' title={item.message} arrowPointAtCenter>
                <Tag color='red'>Ошибка</Tag>
              </Tooltip>
            )
          default: return;
        }
      default: return null;
    }
  }

  const downloadFile = (link, bookId, fileId, fileName, format) =>
  api
    .get(link, {
      responseType: format === 'json' ? 'json' : 'arraybuffer',
    })
    .then(response => {
      let fileData = response.data

      if (format === 'json') fileData = JSON.stringify(fileData)

      const blob = new Blob([fileData], {
        type: response.headers['content-type'],
      })

      saveAs(blob, format ? `${fileName}.${format}` : fileName)
    })
    .catch(err => {
      let errorText = 'Ошибка загрузки файла'
      if (err.response?.status === 403) {
        errorText = 'Ошибка загрузки файла. Нет доступа.'
      }
      createNotification('error', errorText)
    })

  return (
    <div>
      {
        docId && 
        <AntiplagiatModal
        bookId={bookId}
        bookData={bookData}
        docId={docId}
        setModalData={setModalData} 
        onRequest={onRequest}
        // moveFileToAntiplagiatCollection={moveFileToAntiplagiatCollection}
        modalData={
          {
              "documentId": docId,
              "attributes": [
                {
                  "name": "id_page",
                  "value": bookId
                },
                {
                  "name": "Author",
                  "value": ""
                },
                {
                  "name": "Description",
                  "value": ""
                },
                {
                  "name": "Note",
                  "value": ""
                },
                {
                  "name": "Publication",
                  "value": ""
                },
                {
                  "name": "Title",
                  "value": ""
                },
                {
                  "name": "Year",
                  "value": ""
                },
                {
                  "name": "Page_count",
                  "value": ""
                },
              ]
            }
        } 
        closeModal={setDocId}
        />
      }
      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        <>
          <LoadForm modalData={modalData} onUpload={uploadFile} loader={uploadLoader} />

          {modalData && (
            <BookEditFilesModal data={modalData} onSubmit={submitModal} closeModal={closeModal} setData={setModalData} />
          )}
          {isCompressed && (
            <DataTable data={isCompressed} onDoubleClick={item => setModalData(item)}>
              <DataTableCol
                title={'Тип'}
                rowWidth="60px"
                resolver={item => <img className={styles.fileIcon} src={getIcon(item.filename)} alt='' />}
              />
              <DataTableCol
                title={'Название'}
                colSpan={3}
                resolver={item =>
                  <div 
                  className={styles.loadLink}
                  onClick={() => 
                    downloadFile(`${BASE_URL_WITHOUT_API}/public/files/library/${bookId}/${item.id}/${item.filename}`, 
                    bookId, item.id, item.filename)}>{item.filename}</div>
                }
              />
              <DataTableCol
                title={'Дата загрузки'}
                colSpan={1}
                resolver={item => (item.filedate ? moment(item.filedate).format('DD.MM.YYYY') : '-')}
              />
              <DataTableCol title={'Версия'} colSpan={1} value={'version'} />
              <DataTableCol
                title={'Виден'}
                rowWidth="60px"
                resolver={item => <input type='checkbox' checked={item.visible} onChange={() => setVisibility(item)} />}
              />
              <DataTableCol colSpan={2} title='Коллекция антиплагиата' resolver={item => <FileCollectionButton item={item} />} />
              <DataTableCol
                colSpan={1}
                title={''}
                resolver={item => (
                  <Button
                    type='primary'
                    onClick={() => onDelete({ bookId, fileId: item.id })}
                    onDoubleClick={e => e.stopPropagation()}
                    danger
                  >
                    Удалить
                  </Button>
                )}
              />
            </DataTable>
          )}
        </>
      )}
    </div>
  )
}

BookEditFilesTab.propTypes = {
  bookId: T.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(BookEditFilesTab)
