import {NEW_BOOKS_ORDER_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const newBooksOrderBooksReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${NEW_BOOKS_ORDER_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
}
