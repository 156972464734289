import { combineReducers } from "redux";
import { documentCountReducer } from "./count/documentCountReducer";
import { documentListReducer } from "./list/documentListReducer";
import { documentSearchReducer } from "./search/documentSearchReducer";
import { documentBooksReducer } from "./books/documentBooksReducer";
import { documentNumberReducer } from "./number/documentNumberReducer";
import { documentInventaryReducer } from './Inventary/documentInventaryReducer';
import { documentFundAllocationReducer } from './fundAllocation/documentFundAllocationReducer';
import { libraryFundAllocationReducer } from './fundAllocation/libraryFundAllicationReducer';
import { documentBookDistributeReducer } from './bookDistribute/documentBookDistributeReducer';
import {documentLatestReducer} from "../inventory/latest/documentLatestReducer";
import {documentLoaderReducer} from "./loader/documentLoaderReducer";
import {currentBookReducer} from "./currentBook/currentBookReducer";
import { bookIdReducer } from "./bookId/bookIdReducer";

export const documentReducer = combineReducers({
  books: documentBooksReducer,
  bookDistribute: documentBookDistributeReducer,
  count: documentCountReducer,
  list: documentListReducer,
  latest: documentLatestReducer,
  loader: documentLoaderReducer,
  search: documentSearchReducer,
  inventory: documentInventaryReducer,
  number: documentNumberReducer,
  libraries: documentFundAllocationReducer,
  libraryFunds: libraryFundAllocationReducer,
  current: currentBookReducer,
  bookId: bookIdReducer
});
