import { FUND_MOVING_LOAD_REQUEST, SUCCESS, ERROR, START } from "../../../actions/action-types";

const initialState = false;

export const fundMovingLoaderReducer = (state = initialState, action) => {

  switch (action.type) {
    case `${FUND_MOVING_LOAD_REQUEST}${START}`:
      return true;

    case `${FUND_MOVING_LOAD_REQUEST}${SUCCESS}`:
      return false;

    case `${FUND_MOVING_LOAD_REQUEST}${ERROR}`:
      return false;

    default: return state;
  }

};
