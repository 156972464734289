import { all } from 'redux-saga/effects';
import loadUsersSaga from "./load/loadUsersSaga";
import deleteUserSaga from "./delete/deleteUserSaga";
import saveUserSaga from "./save/saveUserSaga";
import getUserPhotoSaga from "./photo/getUserPhotoSaga";
import deleteUserPhotoSaga from "./photo/deleteUserPhotoSaga";
import saveUserPhotoSaga from "./photo/saveUserPhotoSaga";
import changeUserPasswordSaga from "./changePassword/changeUserPasswordSaga";

export default function* adminUsersSaga() {
  yield all([
    loadUsersSaga(),
    deleteUserSaga(),
    saveUserSaga(),
    getUserPhotoSaga(),
    deleteUserPhotoSaga(),
    saveUserPhotoSaga(),
    changeUserPasswordSaga()
  ]);
}