import React, {useState, useEffect} from 'react';
import T from 'prop-types';
import styles from './LibraryMaterialsSearchForm.module.css';
import ModalInputWrapper from "../../Modal/InputWrapper";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import SearchableSelect from "../../Select";
import {categoriesToOptions, categoryToOption, institutionsToOptions} from "../../../utils/toSelectOption";
import {categoriesListRequestStart} from "../../../redux/actions/categories/list";
import { api } from '../../../App';
import { SCIENCE_DEGREE_OPTIONS } from '../../../constants/scienceDegree';

const mapStateToProps = ({categories}) => ({
    categoriesList: categories.list
});

const mapDispatchToProps = {
    requestCategories: categoriesListRequestStart
};

const LibraryMaterialsSearchForm = ({
    data,
    setData,
    noVerifyStatus,
    children,
    requestCategories,
    scienceWorksMode,
    categoriesList,
    onSubmit
}) => {

    const [guideList, setGuideList] = useState([])
    const [industryList, setIndustryList] = useState([])
    const [workStatusList, setWorkStatusList] = useState([])
    const [workPlaceList, setWorkPlaceList] = useState([])
    const [scienceGradeList, setScienceGradeList] = useState([])
    const [organizationsList, setOrganizationsList] = useState([])
  
    useEffect(() => {
      getListFunction('/grnti', setGuideList)
      getListFunction('/industries', setIndustryList)
      getListFunction('/book_work_status', setWorkStatusList)
      getListFunction('/book_work_place', setWorkPlaceList)
      getListFunction('/book_science_grade', setScienceGradeList)
      getListFunction('/xp_client', setOrganizationsList)
      requestCategories();
    }, [])
  
    useEffect(() => {
      scienceWorksMode && setData({...data, work_status: '1'})
    }, [scienceWorksMode])


    const getListFunction = async (link, setFunction) => {
      const response  = await api.get(link)
      setFunction(response.data)
    }
  
    const setSearchableSelectValue = (fieldName) => ({value}) => {
      setData({...data, [fieldName]: value})
    }
    
    const valueToOption = (fieldName, optionsList) => {
        if(!data[fieldName]) return { label: 'Не выбрано', value: null }
        const returnWithoutModify = ['science_status', 'science_degree'].includes(fieldName) 
        const listItem = optionsList.find(({id, idclient, value}) => (idclient || id || value) == data[fieldName])
        return returnWithoutModify ? listItem : categoryToOption(listItem)
    }

    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    if (noVerifyStatus) {
        data.verifyStatus = 2;
    }

    return (
        <div className={styles.container}>
            <Row>
                <Col md={12}>
                    <ModalInputWrapper onChange={onChange}
                                       onKeyPress={onKeyPress}
                                       name="title"
                                       value={data.title}
                                       label="Название"/>
                </Col>
                <Col md={6}>
                    <ModalInputWrapper onChange={onChange}
                                       onKeyPress={onKeyPress}
                                       name="author"
                                       value={data.author}
                                       label="Автор"/>
                </Col>
                <Col md={6}>
                    <ModalInputWrapper onChange={onChange}
                                       onKeyPress={onKeyPress}
                                       name="year"
                                       value={data.year}
                                       label="Год"/>
                </Col>
                {!scienceWorksMode &&
                    <Col md={12}>
                        <ModalInputWrapper label="Категория">
                            <SearchableSelect
                                placeholder="Поиск"
                                options={categoriesToOptions(categoriesList)}
                                value={data.categoryid}
                                onChange={value =>
                                    setData({...data, categoryid: value})
                                }
                            />
                        </ModalInputWrapper>
                    </Col>
                }
                {scienceWorksMode && (
                    <>
                        <Col md={6}>
                            <ModalInputWrapper label='Организация'>
                                <SearchableSelect
                                    placeholder="Поиск"
                                    options={institutionsToOptions(organizationsList)}
                                    onChange={setSearchableSelectValue('organization')}
                                    value={valueToOption('organization', organizationsList)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper label="Статус работы">
                                <SearchableSelect
                                placeholder="Поиск"
                                options={categoriesToOptions(workStatusList)}
                                onChange={setSearchableSelectValue('work_status')}
                                value={valueToOption('work_status', workStatusList)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper label='Шифр и наименование специальности'>
                                <SearchableSelect
                                    placeholder="Поиск"
                                    options={categoriesToOptions(guideList)}
                                    onChange={setSearchableSelectValue('guide')}
                                    value={valueToOption('guide', guideList)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper label='Искомая степень'>
                                <SearchableSelect
                                placeholder="Поиск"
                                options={SCIENCE_DEGREE_OPTIONS}
                                onChange={setSearchableSelectValue('science_degree')}
                                value={valueToOption('science_degree', SCIENCE_DEGREE_OPTIONS)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper label='Искомая отрасль науки'>
                                <SearchableSelect
                                    placeholder="Поиск"
                                    options={categoriesToOptions(industryList)}
                                    onChange={setSearchableSelectValue('industry')}
                                    value={valueToOption('industry', industryList)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper
                                label='Фамилия, имя, отчество научного руководителя или консультанта'
                                name='science_fio'
                                value={data.science_fio}
                                onChange={onChange}
                            />
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper label='Ученая степень научного руководителя или консультанта'>
                                <SearchableSelect
                                placeholder="Поиск"
                                options={SCIENCE_DEGREE_OPTIONS}
                                onChange={setSearchableSelectValue('science_status')}
                                value={valueToOption('science_status', SCIENCE_DEGREE_OPTIONS)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper label='Ученое звание научного руководителя или консультанта'>
                                <SearchableSelect
                                    placeholder="Поиск"
                                    options={categoriesToOptions(scienceGradeList)}
                                    onChange={setSearchableSelectValue('science_grade')}
                                    value={valueToOption('science_grade', scienceGradeList)}
                                    />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper label='Место написания работы'>
                                <SearchableSelect
                                    placeholder="Поиск"
                                    options={categoriesToOptions(workPlaceList)}
                                    onChange={setSearchableSelectValue('work_place')}
                                    value={valueToOption('work_place', workPlaceList)}
                                />
                            </ModalInputWrapper>
                        </Col>
                    </>
                )}
                <Col md={12}>
                    {children}
                </Col>
            </Row>
        </div>
    );
};

LibraryMaterialsSearchForm.propTypes = {
    data: T.object,
    setData: T.func,
    hideInstitutions: T.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryMaterialsSearchForm);
