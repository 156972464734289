import {
  ERROR,
  SCHEDULE_LIST_REQUEST,
  START,
  SUCCESS
} from "../action-types";

export const scheduleRequestStart = () => ({
  type: `${SCHEDULE_LIST_REQUEST}${START}`
});

export const scheduleRequestSuccess = data => ({
  type: `${SCHEDULE_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const scheduleRequestError = data => ({
  type: `${SCHEDULE_LIST_REQUEST}${ERROR}`,
  payload: data
});
