import { all } from 'redux-saga/effects';
import collectionsListSaga from "./list/collectionsListSaga";
import collectionsSaveSaga from "./save/collectionsSaveSaga";
import collectionsDeleteSaga from "./delete/collectionsDeleteSaga";
import collectionsDataSaga from "./data/collectionsDataSaga";

export default function* collectionsSaga() {
  yield all([
    collectionsListSaga(),
    collectionsSaveSaga(),
    collectionsDeleteSaga(),
    collectionsDataSaga()
  ]);
}