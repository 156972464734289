import {AUDIT_LOG_COUNT_REQUEST, AUDIT_LOG_LIST_REQUEST, START, SUCCESS} from "../../../actions/action-types";

const initialState = false;

export const auditLogLoaderReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${AUDIT_LOG_COUNT_REQUEST}${START}`:
      return true;

    case `${AUDIT_LOG_LIST_REQUEST}${SUCCESS}`:
      return false;

    default: return state;
  }
};