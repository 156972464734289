import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bookTransferAddRequestStart } from '../../../../redux/actions/bookTransfer/add'
import { institutionsAllListRequestStart } from '../../../../redux/actions/institutions/all'
import { setInstitutionsSearchData } from '../../../../redux/actions/institutions/search'
import { optionsToInstitutions } from '../../../../utils/toSelectOption'
import Button from '../../../Button'
import SearchableSelect from '../../../Select'
import OrganizationItem from './OrganizationItem'
import styles from './Organizations.module.css'

const mapStateToProps = ({ institutions, options }) => ({
  institutionsSearch: institutions.search,
  institutionsList: institutions.all,
  options,
})

const mapDispatchToProps = {
  requestInstitutions: institutionsAllListRequestStart,
  setInstitutionsSearch: setInstitutionsSearchData,
  bookTransferAddRequest: bookTransferAddRequestStart,
}

const Organizations = ({
  options,
  data,
  setInstitutionsSearch,
  institutionsSearch,
  requestInstitutions,
  institutionsList,
  setData,
  bookTransferAddRequest,
}) => {
  const [selectedOrg, setSelectedOrg] = useState(null)

  const onInstitutionsInputChange = name => {
    setInstitutionsSearch({ ...institutionsSearch, query: name })
    requestInstitutions()
  }
  const addRequestToBookTransfer = clientid => {
    const params = {
      clientid,
      bookid: data.bookid,
    }

    bookTransferAddRequest(params)
  }

  return (
    <div>
      <div className={'formContainer d-flex'}>
        <SearchableSelect
          placeholder='Поиск'
          onInputChange={onInstitutionsInputChange}
          provider={true}
          options={institutionsList}
          onChange={setSelectedOrg}
          value={selectedOrg}
          className={styles.searchInput}
        />
        <Button
          onClick={() => {
            if (!selectedOrg) return
            setData({
              ...data,
              clients: [...optionsToInstitutions([selectedOrg]), ...(data.clients || [])],
            })
            setSelectedOrg(null)
          }}
          label='Добавить'
        />
      </div>

      <div className={styles.organizationsContainer}>
        {data.clients &&
          data.clients.map((client, index) => (
            <OrganizationItem
              onDelete={id => {
                setData({
                  ...data,
                  clients: (data.clients || []).filter(({ id: itemId }) => id !== itemId),
                })
              }}
              onTransferRequest={addRequestToBookTransfer}
              isTransferPossible={!data.files && client.filesexist}
              key={index}
              item={client}
            />
          ))}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Organizations)
