import React from 'react'
import T from 'prop-types'
import styles from './InvNumbersModalSearchItem.module.css'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import CoverListItemInfo from '../../../Covers/List/Item/InfoBlock'

const InvNumbersItem = ({ data, onClick }) => {
  return (
    <a href='#current' className={styles.current}>
      <div
        className={classNames(styles.container)}
        onClick={() => {
          onClick({ ...data, bookid: data.id, invnumber: data.number, fundto: data.fundto, bookinstanceid: data.instanceid })
        }}
      >
        <Row>
          <Col md={{ span: 9, offset: 3 }}>
            <div className={styles.title_link}>{data.title}</div>
          </Col>
          <Col md={12}>
            <CoverListItemInfo label={'Автор'} value={data.authors} />
            <CoverListItemInfo label={'Год'} value={data.year} />
            <CoverListItemInfo label={'Инвентарный номер'} value={data.number} />
          </Col>
        </Row>
      </div>
    </a>
  )
}

InvNumbersItem.propTypes = {
  data: T.object,
  selected: T.bool,
}

export default InvNumbersItem
