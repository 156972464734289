import { BOOK_SAVE_REQUEST, ERROR, START, SUCCESS } from '../action-types'

export const bookSaveRequestStart = (data, withoutPopup) => ({
  type: `${BOOK_SAVE_REQUEST}${START}`,
  payload: data,
  withoutPopup,
})

export const bookSaveRequestSuccess = data => {
  return {
    type: `${BOOK_SAVE_REQUEST}${SUCCESS}`,
    payload: data,
  }
}

export const bookSaveRequestError = data => ({
  type: `${BOOK_SAVE_REQUEST}${ERROR}`,
  payload: data,
})
