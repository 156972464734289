import jsPDF from 'jspdf'
import addFont from '../../../../fonts/fonts'




export const createActDoc = async ({libraryShortName}) => {

  const orientation = "portrait";

  addFont(jsPDF.API);
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation });
  doc.setFont("Montserrat");
  
  doc.setFontSize(13);
  doc.text('ФОРМА АКТА ПРИЕМА-ПЕРЕДАЧИ ДОКУМЕНТОВ', 105, 12, 'center')
  doc.text('ИЗ ОДНОЙ БИБЛИОТЕКИ В ДРУГУЮ В ПОРЯДКЕ КНИГООБМЕНА', 105, 20, 'center')
  doc.setFontSize(10);

  doc.text(
    doc.splitTextToSize('Рассмотрено на комиссии по сохранности фондов Протокол № __________ от "__" __________ 20___г.', 50),
    40,
    30
  )
  
  doc.text('УТВЕРЖДАЮ', 125, 30)
  doc.text('_______________________________', 125, 35)
  doc.setFontSize(6).text('(подпись лица, утвердившего акт)', 135, 37).setFontSize(10);
  doc.text('"__" __________ 20___г.', 125, 43)

  doc.text('Акт № _______', 105, 60, 'center')
  doc.text('"__" __________ 20___г.', 105, 65, 'center')
  
  doc.text(libraryShortName, 95, 74)
  doc.text('Составлен настоящий акт в том, что ________________________________________________________', 20, 75)
  doc.text('передала в  _________________________________________________________________________________', 20, 80)
  doc.text('приняла документы, отобранные представителем библиотеки ___________________________', 20, 85)
  doc.text('______________________________________________________________________________________________', 20, 90)
  doc.text('на основании доверенности № ______ от ________________________________', 20, 95)
  doc.text('в количестве _______________________________________________________________________________', 20, 100)
  doc.text('книг _________________________ экз., АВД _______________________________экз.,', 20, 105)
  doc.text('журанлов  _________________________ экз., газет _________________________ комплектов.', 20, 110)
  doc.text('Общей стоимостью ______________________________________________', 20, 115)
  doc.text('в том числе стоимость документов, состоящих на бухгалтерском учете', 20, 120)
  doc.text('______________________________________________________________________________________________', 20, 125)
  
  doc.text('Согласно прилагаемому списку документы сдал _________________________', 20, 135)
  doc.text('принял _________________________', 102, 150)
  doc.text('Список к Акту № ______', 105, 165, 'center')

  doc.autoTable({
    html: '#actDocTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    columnStyles: {
      // 2: { cellWidth: 70 },
    },

    startY: 175
  })

  doc.addPage("a4", orientation);
  doc.deletePage(doc.getNumberOfPages());
  return doc;
};
