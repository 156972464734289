import { RACKS_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = {};

export const racksReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case `${RACKS_LIST_REQUEST}${SUCCESS}`:
      const { roomId, data } = action.payload;
      return {...state, [roomId]: data};

    default: return state;
  }
};