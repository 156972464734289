import { SET_EXTERNAL_RESOURCES_SAVE_STATUS } from "../../../actions/action-types";

const initialState = null;

export const externalResourcesSaveStatusReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_EXTERNAL_RESOURCES_SAVE_STATUS:
      return action.payload;

    default: return state;
  }
};
