import {AUTHORS_LIST_REQUEST, AUTHORS_COUNT_REQUEST, ERROR, START, SUCCESS} from "../../../actions/action-types";

const initialState = false;

export const authorsLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${AUTHORS_COUNT_REQUEST}${START}`:
      return true;
    case `${AUTHORS_LIST_REQUEST}${SUCCESS}`:
      return false;
    case `${AUTHORS_LIST_REQUEST}${ERROR}`:
      return false;

    default: return state;
  }
};