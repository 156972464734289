import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './InvNumbersTable.module.css'
import DataTableCol from '../../DataTable/Column'
import DataTable from '../../DataTable'
import { EDIT } from '../../../constants/modalTypes'

const InvNumbersTable = ({ setSort, sortBy, data, selectedIds, handleSelect, setModalData }) => {
  const openModal = item => setModalData({ modalType: EDIT, data: item })

  return (
    <div className={styles.container}>
      <DataTable sortBy={sortBy} data={data} onHeadClick={setSort} onCellClick={openModal}>
        <DataTableCol title='Название' value='title' className={classNames(styles.leftAlign, styles.blueColumn)} colSpan={5} />
        <DataTableCol title='Автор' value='authors' className={classNames(styles.leftAlign, styles.blueColumn)} colSpan={4} />
        <DataTableCol title='Год' value='year' className={classNames(styles.leftAlign, styles.blueColumn)} />
        <DataTableCol title='Инвентарный номер' value='number' colSpan={2} />
        <DataTableCol title='№ Экземпляра' value='copynumber' colSpan={2} />
        <DataTableCol title='Гриф секретности' value='eerOutSecrecy' colSpan={2} />
        <DataTableCol title='Местоположение' value='placename' colSpan={2} />
        <DataTableCol title='Цена' value='price' colSpan={2} />
        <DataTableCol
          title='Выбрано'
          passClicks
          resolver={item => (
            <input
              type='checkbox'
              checked={selectedIds.includes(item.instanceid)}
              onChange={() => handleSelect(item.instanceid)}
            />
          )}
        />
      </DataTable>
    </div>
  )
}

InvNumbersTable.propTypes = {
  data: PropTypes.array,
  selectedIds: PropTypes.array,
  handleSelect: PropTypes.func,
  setModalData: PropTypes.func,
}

InvNumbersTable.defaultProps = {
  data: [],
}

export default InvNumbersTable
