import { SEARCH_AUDIT_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const searchAuditCountRequestStart = () => ({
  type: `${SEARCH_AUDIT_COUNT_REQUEST}${START}`
});

export const searchAuditCountRequestSuccess = (data) => ({
  type: `${SEARCH_AUDIT_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const searchAuditCountRequestError = (data) => ({
  type: `${SEARCH_AUDIT_COUNT_REQUEST}${ERROR}`,
  payload: data
});
