import {
  LIBRARY_ADD_FOUND,
  LIBRARY_ADD_FOUND_CLEAR
} from "../../../../actions/action-types";

const initialState = [];

export const libraryAddFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIBRARY_ADD_FOUND:
      return [...state, action.payload];

    case LIBRARY_ADD_FOUND_CLEAR:
      return initialState;

    default:
      return state;
  }
};
