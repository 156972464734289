import { ERROR, START, SUCCESS, PRICE_COEFF_REQUEST } from "../../action-types";

export const priceCoeffRequestStart = () => ({
  type: `${PRICE_COEFF_REQUEST}${START}`
});

export const priceCoeffRequestSuccess = (data) => ({
  type: `${PRICE_COEFF_REQUEST}${SUCCESS}`,
  payload: data
});

export const priceCoeffRequestError = (data) => ({
  type: `${PRICE_COEFF_REQUEST}${ERROR}`,
  payload: data
});