import {
  COVERS_ADVANCED_SEARCH_LIST_REQUEST,
  COVERS_COUNT_REQUEST,
  COVERS_LIST_REQUEST,
  COVERS_READING_SEARCH_REQUEST,
  COVERS_READING_LIST_REQUEST,
  COVERS_LOADING_FALSE,
  START,
  SUCCESS,
  ERROR,
  SEARCH_BY_IMAGE_REQUEST,
} from '../../../actions/action-types'

const initialState = false

export const coversLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case COVERS_LOADING_FALSE:
      return false

    case `${COVERS_COUNT_REQUEST}${START}`:
      return true

    case `${COVERS_COUNT_REQUEST}${ERROR}`:
      return false

    case `${COVERS_LIST_REQUEST}${SUCCESS}`:
      return false

    case `${COVERS_READING_SEARCH_REQUEST}${START}`:
      return true

    case `${SEARCH_BY_IMAGE_REQUEST}${START}`:
      return true

    case `${SEARCH_BY_IMAGE_REQUEST}${SUCCESS}`:
      return false

    case `${COVERS_READING_LIST_REQUEST}${SUCCESS}`:
      return false

    case `${COVERS_ADVANCED_SEARCH_LIST_REQUEST}${START}`:
      return true

    default:
      return state
  }
}
