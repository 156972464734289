import { ERROR, EXTERNAL_RESOURCES_DELETE_REQUEST, START, SUCCESS } from "../action-types";

export const externalResourcesDeleteRequestStart = (id) => ({
  type: `${EXTERNAL_RESOURCES_DELETE_REQUEST}${START}`,
  payload: id
});

export const externalResourcesDeleteRequestSuccess = (data) => ({
  type: `${EXTERNAL_RESOURCES_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const externalResourcesDeleteRequestError = (data) => ({
  type: `${EXTERNAL_RESOURCES_DELETE_REQUEST}${ERROR}`,
  payload: data
});
