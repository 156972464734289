import { all } from 'redux-saga/effects';
import externalResourcesListSaga from "./list/externalResourcesListSaga";
import externalResourcesEditSaga from "./edit/externalResourcesEditSaga";

export default function* externalResourcesSaga() {
  yield all([
    externalResourcesListSaga(),
    externalResourcesEditSaga()
  ]);
}
