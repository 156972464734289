import { COVERS_ADVANCED_SEARCH_PARAMS_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const coversAdvancedSearchParamsRequestStart = () => ({
  type: `${COVERS_ADVANCED_SEARCH_PARAMS_REQUEST}${START}`
});

export const coversAdvancedSearchParamsRequestSuccess = (data) => ({
  type: `${COVERS_ADVANCED_SEARCH_PARAMS_REQUEST}${SUCCESS}`,
  payload: data
});

export const coversAdvancedSearchParamsRequestError = (data) => ({
  type: `${COVERS_ADVANCED_SEARCH_PARAMS_REQUEST}${ERROR}`,
  payload: data
});

