import { ERROR, DISCIPLINE_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const disciplineCountRequestStart = () => ({
    type: `${DISCIPLINE_COUNT_REQUEST}${START}`
});

export const disciplineCountRequestSuccess = (data) => ({
    type: `${DISCIPLINE_COUNT_REQUEST}${SUCCESS}`,
    payload: data
});

export const disciplineCountRequestError = (error) => ({
    type: `${DISCIPLINE_COUNT_REQUEST}${ERROR}`,
    payload: error
});

