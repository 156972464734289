import { combineReducers } from 'redux'
import { authReducer } from './auth/authReducer'
import { publishersReducer } from './publishers/publishersReducer'
import { udkListReducer } from './udk/udkListReducer'
import { authorsReducer } from './authors/authorsReducer'
import { libraryReducer } from './library/libraryReducer'
import { institutionsReducer } from './institutions/institutionsReducer'
import { readerSearchReducer } from './readerSearch/readerSearchReducer'
import { regionsReducer } from './regions/regionsReducer'
import { bbkListReducer } from './bbk/bbkListReducer'
import { svniListReducer } from './svni/svniListReducer'
import { rubricatorReducer } from './rubricator/rubricatorReducer'
import { coversReducer } from './covers/coversReducer'
import { invNumbersReducer } from './invNumbers/invNumbersReducer'
import { bookDataReducer } from './bookEdit/data/bookDataReducer'
import { rolesReducer } from './roles/rolesReducer'
import { optionsReducer } from './options/optionsReducer'
import { usersReducer } from './adminUsers/usersReducer'
import { locationReducer } from './location/locationReducer'
import { collectionsReducer } from './collections/collectionsReducer'
import { permissionsReducer } from './permissions/permissionsReducer'
import { externalResourcesReducer } from './externalResources/externalResourcesReducer'
import { pdfViewerReducer } from './pdfViewer/pdfViewerReducer'
import { reservedBooksListReducer } from './reservedBooks/reservedBooksListReducer'
import { auditLogReducer } from './auditLog/auditLogReducer'
import { readerDataReducer } from './readerData/readerDataReducer'
import { qrCodesReducer } from './qrCodes/qrCodesReducer'
import { reportsReducer } from './reports/reportsReducer'
import { importBooksReducer } from './import/importBooksReducer'
import { havkinaReducer } from './havkina/havkinaReducer'
import { documentTypesReducer } from './documentTypes/documentTypesReducer'
import { documentReducer } from './documents/documentReducer'
import { inventoryReducer } from './inventory/inventoryReducer'
import { booksOrderReducer } from './booksOrder/booksOrderReducer'
import { newBooksOrderReducer } from './newBooksOrder/newBooksOrderReducer'
import { systemVersionReducer } from './systemVersion/systemVersionReducer'
import { adminModalUsersReducer } from './adminModalUsers/reducer'
import { directoryReducer } from './adminDirectory/directoryReducer'
import { scheduleReducer } from './schedule/scheduleReducer'
import { googleBooksReducers } from '../factory/googleBooksReduxData'
import { availableBooksReducers } from '../factory/availableBooksModalReduxData'
import { onHandsBooksReducers } from '../factory/onHandsBooksReduxData'
import { bookPlacesReducer } from '../factory/bookPlacesReduxData'
import { schedulePersonalReducer } from '../factory/scheduleReduxData'
import { curriculumListReducer } from './curriculum/curriculumListReducer'
import { secrecyReducer } from './outsecrecy/secrecyReducer'
import { booksEditReducer } from './bookEdit/bookEditReducer'
import { inventaryBookReducer } from './inventaryBook/inventaryBookReducer'
import { fundMovingReducer } from './fundMoving/fundMovingReducer'
import { fundAccountingReducer } from './fundAccounting/fundAccountingReducer'
import { studentProjectsReducers } from '../factory/studentProjectsReduxData'
import { duplicateReducer } from './duplicate/duplicateReducer'
import { menuList_Reducer } from './menuList/menuListReducer'
import { remcomplectReducer } from './remcomplect/remcomplectReducer'
import { turnoutStatsReducer } from './turnoutStats/turnoutStatsReducer'
import { searchAuditReducer } from './searchAudit/searchAuditReducer'
import { bookScanPlanReducer } from '../factory/bookScanPlanReduxData'
import { booksExportReducer } from './booksExport/booksExportReducer'
import { bigDataReducer } from '../factory/BigData/bigDataReduxData'
import { expertOpinionReducers } from '../factory/ExpertOpinionReduxData'
import { getBookChangeHistoryReducers } from '../factory/bookHistoryChangeReduxData'
import { bookNotesReducers } from '../factory/bookNotesReduxData'
import { pagesReducers } from '../factory/PagesReduxData'
import { categoriesReducer } from './categories/categoriesReducer'
import { accessCategoryReducer } from './accessCategory/accessCategoryReducer'
import { litersReducer } from './liters/litersReducer'
import { seriesReducer } from './series/seriesReducer'
import { accessSeriesReducer } from './accessSeries/accessSeriesReducer'
import { accessLitersReducer } from './accessLiters/accessLitersReducer'
import { bookScanOrdersReducer } from './bookScanOrders/bookScanOrdersReducer'
import { mediaResourceReducer } from './mediaResource/mediaResourceReducer'
import { bookSupplyReducer } from './bookSupply/bookSupplyReducer'
import { messageReducer } from './message/messageReducer'
import { borrowingTestReducer } from './borrowingTest/borrowingTestReducer'
import { historyLocationReducer } from './historyLocation/historyLocationReducer'
import { bookTransferReducer } from './bookTransfer/bookTransferReducer'
import { schedulerReducer } from './scheduler/schedulerReducer'
import { bookreserveReducer } from './bookreserve/bookreserveReducer'
import { creditsReducer } from './credits/creditsReducer'
import { bookCardReducer } from './bookCard/bookCardReducer'
import { electronicCoursesReducer } from './electronicCourses/electronicCoursesReducer'
import { electronicBookImportReducer } from './ElectronicBookImport/ElectronicBookImportReducer'
import { bookSourceReducer } from './bookSource/bookSourceReducer'
import { epubReaderReducer } from './epubReader/epubReaderReducer'
import { testsReducer } from './tests/testsReducer'
import { adminLogsReducer } from './adminLogs/adminLogsReducer'
import { BibliographyRecuder } from './Bibliography/BibliographyReducer'
import { userRolePermissionsReducer } from './user/rolePermissions/userRolePermissionsReducer'
import { disciplineReducer } from './discipline/disciplineReducer'
import { teachersReducer } from './teachers/teachersReducer'
import { bookOpenHistoryReducer } from './bookOpenHistory/bookOpenHistoryReducer'
import { bookSecretDocReducer } from './booksecretdoc/bookSecretDocReducer'
import { answerStatReducer } from './answerstat/answerstatReducer'
import { exemptTestReducer } from './exempttest/exemptTestReducer'
import { libraryMaterialsReducer } from './libraryMaterials/libraryMaterialsReducer'
import { coversScanPlanReducer } from './coversScanPlan/coversScanPlanReducer'
import { ownUserDataReducer } from './ownUserData/ownUserDataReducer'
import { adminGroupsReducer } from './adminGroups/adminGroups'
import { currentPerfomanceReducer } from '../factory/currentPerfomanceReduxData'
import { performancesReducer } from '../factory/performancesReduxData'
import { performanceReducer } from '../factory/perfomanceReduxData'
import { stateSecretBookReducer } from './stateSecretBook/stateSecretBookReducer'
import { mobileLibraryReducer } from './mobileLibrary'
import { commonReducer } from './common'
import {lostBookReducer} from './notebookLostDoc/index'

export const rootReducer = combineReducers({
  lostBooks: lostBookReducer,
  stateSecretBook: stateSecretBookReducer,
  searchAudit: searchAuditReducer,
  performance: performanceReducer,
  performances: performancesReducer,
  currentPerfomance: currentPerfomanceReducer,
  adminGroups: adminGroupsReducer,
  accessCategories: accessCategoryReducer,
  accessLiters: accessLitersReducer,
  accessSeries: accessSeriesReducer,
  adminUsers: usersReducer,
  directoryList: directoryReducer,
  adminLogs: adminLogsReducer,
  auditLog: auditLogReducer,
  answerStat: answerStatReducer,
  auth: authReducer,
  authors: authorsReducer,
  bbk: bbkListReducer,
  svni: svniListReducer,
  bigData: bigDataReducer,
  bookCard: bookCardReducer,
  bookData: bookDataReducer,
  bookEdit: booksEditReducer,
  bookChangeHistory: getBookChangeHistoryReducers,
  bookNotes: bookNotesReducers,
  bookOpenHistory: bookOpenHistoryReducer,
  bookSecretDoc: bookSecretDocReducer,
  bookScanPlan: bookScanPlanReducer,
  bookSource: bookSourceReducer,
  bookSupply: bookSupplyReducer,
  bibliography: BibliographyRecuder,
  booksExport: booksExportReducer,
  booksOrder: booksOrderReducer,
  bookreserve: bookreserveReducer,
  bookScanOrders: bookScanOrdersReducer,
  bookTransfer: bookTransferReducer,
  borrowingTest: borrowingTestReducer,
  categories: categoriesReducer,
  collections: collectionsReducer,
  covers: coversReducer,
  coversScanPlan: coversScanPlanReducer,
  credits: creditsReducer,
  menuList: menuList_Reducer,
  curriculum: curriculumListReducer,
  documents: documentReducer,
  documentTypes: documentTypesReducer,
  discipline: disciplineReducer,
  electronicBook: electronicBookImportReducer,
  electronicCourses: electronicCoursesReducer,
  epubReader: epubReaderReducer,
  exemptTest: exemptTestReducer,
  externalResources: externalResourcesReducer,
  fundMoving: fundMovingReducer,
  fundAccounting: fundAccountingReducer,
  havkina: havkinaReducer,
  historyLocation: historyLocationReducer,
  importBooks: importBooksReducer,
  institutions: institutionsReducer,
  inventory: inventoryReducer,
  invNumbers: invNumbersReducer,
  inventaryBook: inventaryBookReducer,
  library: libraryReducer,
  location: locationReducer,
  liters: litersReducer,
  mediaResource: mediaResourceReducer,
  message: messageReducer,
  options: optionsReducer,
  outsecrecy: secrecyReducer,
  ownUserData: ownUserDataReducer,
  pdfViewer: pdfViewerReducer,
  permissions: permissionsReducer,
  publishers: publishersReducer,
  qrCodes: qrCodesReducer,
  readerData: readerDataReducer,
  readerSearch: readerSearchReducer,
  regions: regionsReducer,
  reports: reportsReducer,
  reservedBooks: reservedBooksListReducer,
  roles: rolesReducer,
  rubricator: rubricatorReducer,
  scheduler: schedulerReducer,
  systemVersion: systemVersionReducer,
  adminModalUsers: adminModalUsersReducer,
  newBooksOrder: newBooksOrderReducer,
  teachers: teachersReducer,
  tests: testsReducer,
  turnoutStats: turnoutStatsReducer,
  schedule: scheduleReducer,
  series: seriesReducer,
  googleBooks: googleBooksReducers,
  availableBooks: availableBooksReducers,
  onHandsBooks: onHandsBooksReducers,
  schedulePersonalReducer: schedulePersonalReducer,
  udk: udkListReducer,
  bookPlaces: bookPlacesReducer,
  studentProjects: studentProjectsReducers,
  duplicate: duplicateReducer,
  remcomplect: remcomplectReducer,
  libraryMaterials: libraryMaterialsReducer,
  expertOpinion: expertOpinionReducers,
  pages: pagesReducers,
  userRolePermissions: userRolePermissionsReducer,
  mobileLibrary: mobileLibraryReducer,
  common: commonReducer
})
