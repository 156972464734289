import { COVERS_READING_SEARCH_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const coversReadingSearchRequestStart = (isSearchAdvanced) => ({
  type: `${COVERS_READING_SEARCH_REQUEST}${START}`,
  payload: isSearchAdvanced
});

export const coversReadingSearchRequestSuccess = (data) => ({
  type: `${COVERS_READING_SEARCH_REQUEST}${SUCCESS}`,
  payload: data
});

export const coversReadingSearchRequestError = (data) => ({
  type: `${COVERS_READING_SEARCH_REQUEST}${ERROR}`,
  payload: data
});
