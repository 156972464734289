import {
    ERROR,
    START,
    SUCCESS,
    TRANSFER_LOG_EVENTS_REQUEST
} from "../../../../../actions/action-types";


const initialState = {
    data: [],
    loader: false
};

export const modalEventsReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${TRANSFER_LOG_EVENTS_REQUEST}${START}`:
            return {
                data: state.data,
                loader: true
            };

        case `${TRANSFER_LOG_EVENTS_REQUEST}${SUCCESS}`:
            return {
                data: action.payload,
                loader: false
            };

        case `${TRANSFER_LOG_EVENTS_REQUEST}${ERROR}`:
            return {
                data: action.payload,
                loader: false
            };

        default: return state;
    }
};