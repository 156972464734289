import { api } from "../../App";

export const getAccessCategoryList = ({ roleid, ...otherParams }) => {
  const params = {
    roleid: roleid,
    ...otherParams
  };

  return api.get('/accesscategory', {params})
};

export const getAccessCategoryCount = ({ roleid, ...otherParams }) => {
  const params = {
    count: true,
    roleid: roleid,
    ...otherParams
  };

  return api.get('/accesscategory', {params})
};

