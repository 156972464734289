import { LIBRARY_DOCUMENT_UPDATE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const updateLibDocumentRequestStart = (data) => ({
  type: `${LIBRARY_DOCUMENT_UPDATE_REQUEST}${START}`,
  payload: data
});

export const updateLibDocumentRequestSuccess = (response) => ({
  type: `${LIBRARY_DOCUMENT_UPDATE_REQUEST}${SUCCESS}`,
  payload: response
});

export const updateLibDocumentRequestError = (error) => ({
  type: `${LIBRARY_DOCUMENT_UPDATE_REQUEST}${ERROR}`,
  payload: error
});
