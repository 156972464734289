import { call, put, takeLatest } from "redux-saga/effects";
import { START, DIRECTORY_DELETE_REQUEST, DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING } from "../../../actions/action-types";
import { deleteDirectory } from "../../../../api/adminUsers/users";
import { directoryDeleteRequestError, directoryDeleteRequestSuccess } from "../../../actions/adminDirectory/delete";
import { directoryListRequestStart } from "../../../actions/adminDirectory/list";
import { createNotification } from '../../../../components/notification/notification'

export default function* deleteDirectoryWatcher() {
  yield takeLatest(`${DIRECTORY_DELETE_REQUEST}${START}`, deleteDirectoryWorker)
}

function* deleteDirectoryWorker({payload: id}) {
    try {
      yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
    const response = yield call(deleteDirectory, id);
    yield put(directoryDeleteRequestSuccess(response.data));
    yield put(directoryListRequestStart());
    yield createNotification('success', 'Успешно');
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
  } catch (error) {
    yield put(directoryDeleteRequestError(error))
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
  }
}