import { call, put, takeLatest, select } from "redux-saga/effects";
import {
    START, TRANSFER_LOG_BOOKS_REQUEST, TRANSFER_LOG_COUNT_REQUEST, TRANSFER_LOG_EVENTS_REQUEST,
    TRANSFER_LOG_LIST_REQUEST
} from "../../../actions/action-types";
import {
    getAdminLogsTransferCount,
    getAdminLogsTransferList,
} from "../../../../api/adminLogs/transfer/transfer";
import {transferLogCountRequestError, transferLogCountRequestSuccess} from "../../../actions/adminLogs/transfer/count";
import {setTransferLogSearchData} from "../../../actions/adminLogs/transfer/searchData";
import {
    transferLogListRequestError,
    transferLogListRequestStart,
    transferLogListRequestSuccess
} from "../../../actions/adminLogs/transfer/list";
import {
    transferLogEventsRequestError,
    transferLogEventsRequestSuccess
} from "../../../actions/adminLogs/transfer/events";
import {transferLogBooksRequestError, transferLogBooksRequestSuccess} from "../../../actions/adminLogs/transfer/books";
import {getAdminLogsTransferBooksList} from "../../../../api/adminLogs/transfer/books";
import {getAdminLogsTransferEventsList} from "../../../../api/adminLogs/transfer/events";




export default function* transferLogWatcher() {
    yield takeLatest(`${TRANSFER_LOG_COUNT_REQUEST}${START}`, countWorker);
    yield takeLatest(`${TRANSFER_LOG_LIST_REQUEST}${START}`, listWorker);
    yield takeLatest(`${TRANSFER_LOG_BOOKS_REQUEST}${START}`, booksWorker);
    yield takeLatest(`${TRANSFER_LOG_EVENTS_REQUEST}${START}`, eventsWorker);
}

function* countWorker() {
    const formData = yield select(({ adminLogs }) => adminLogs.transfer.form);
    const searchData = yield select(({ adminLogs }) => adminLogs.transfer.search);
    try {
        const response = yield call(getAdminLogsTransferCount, formData);
        const count = parseInt(response.data.count, 10);
        yield put(transferLogCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setTransferLogSearchData({...searchData, pageCount}));
        yield put(transferLogListRequestStart());
    } catch (error) {
        yield put(transferLogCountRequestError(error));
    }

}

function* listWorker() {
    const formData = yield select(({ adminLogs }) => adminLogs.transfer.form);
    const searchData = yield select(({ adminLogs }) => adminLogs.transfer.search);
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getAdminLogsTransferList, searchData, formData);
        yield put(transferLogListRequestSuccess(response.data));
    } catch (error) {
        yield put(transferLogListRequestError(error));
    }
}

function* booksWorker({ payload: id }) {
    try {
        const response = yield call(getAdminLogsTransferBooksList, id);
        yield put(transferLogBooksRequestSuccess(response.data));
    } catch(error) {
        yield put(transferLogBooksRequestError());
    }
}

function* eventsWorker({ payload: id }) {
    try {
        const response = yield call(getAdminLogsTransferEventsList, id);
        yield put(transferLogEventsRequestSuccess(response.data));
    } catch(error) {
        yield put(transferLogEventsRequestError(error));
    }
}