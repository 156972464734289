import { CURRICULUM_LIST_REQUEST, START, SUCCESS, ERROR } from "../../actions/action-types";

export const curriculumListRequestStart = () => ({
    type: `${CURRICULUM_LIST_REQUEST}${START}`
});

export const curriculumListRequestSuccess = (data) => ({
    type: `${CURRICULUM_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const curriculumListRequestError = (data) => ({
    type: `${CURRICULUM_LIST_REQUEST}${ERROR}`,
    payload: data
});
