import { call, put, takeLatest } from "redux-saga/effects";
import {DISCIPLINE_DELETE_START, START} from "../../../actions/action-types";
import {disciplineCountRequestStart} from "../../../actions/discipline/count";
import {disciplineDeleteRequestError, disciplineDeleteRequestSuccess} from "../../../actions/discipline/delete";
import {disciplineDelete} from "../../../../api/discipline/discipline";

export default function* deleteAuthorWatcher() {
    yield takeLatest(`${DISCIPLINE_DELETE_START}${START}`, deleteDisciplineWatcher)
}

function* deleteDisciplineWatcher({payload: id}) {
    try {
        const response = yield call(disciplineDelete, id);
        yield put(disciplineDeleteRequestSuccess(response.data));
        yield put(disciplineCountRequestStart());
    } catch (error) {
        yield put(disciplineDeleteRequestError(error))
    }
}