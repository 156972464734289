

export const DISPOSAL_DIRECTIONS = [
  { id: '', name: 'Не выбрано' },
  { id: '1', name: 'На склад' },
  { id: '2', name: 'В пункт приема вторсырья' },
  { id: '3', name: 'В другие библиотеки или организации' }
]

export const EXCLUSION_REASONS = [
  { id: '', name: 'Не выбрано' },
  { id: '1', name: 'Утрата' },
  { id: '2', name: 'Ветхость' },
  { id: '3', name: 'Дефектность' },
  { id: '4', name: 'Устарелость по содержанию' },
  { id: '5', name: 'Непрофильность' }
]
