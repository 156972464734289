import React from 'react';
import T from 'prop-types';
import { Typography } from 'antd';
import cn from 'classnames'

import styles from './ReportInfoBlock.module.css';

const ReportInfoBlock = ({label, value, resolver, strong, title}) => {

    const { Text } = Typography;

    const displayValue = () => {
        const bytesValue = typeof value === 'string' && (value.includes('bytes') || value.includes('MB'))
        if (resolver) return resolver(value);
        if (value === undefined) return "n/a";
        if (bytesValue) return value.split(' ')[0];
        if (Array.isArray(value)) return value.join(' | ');
        return value
    }

    // const displayValue = resolver ? resolver(value) : (value === undefined ? "n/a" : value);

    const containerClassNames = cn(
        styles.container, {
        [styles.jccenter]: title,
        [styles.title]: strong && !title
    });

    return (
        <div className={containerClassNames}>
            <Text strong={strong}>{label}</Text>
            {!title && <Text className={styles.secondText} strong={strong}>{displayValue()}</Text>}
        </div>
    );
};

ReportInfoBlock.propTypes = {
    label: T.string,
    value: T.any,
    resolver: T.func,
    strong: T.bool,
};

export default ReportInfoBlock;
