import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import domtoimage from 'dom-to-image';
import {readerDataRequestStart} from "../../../../redux/actions/reader/getData";
import styles from './LibraryCardPass.module.css';
import {Col, Row} from "react-bootstrap";
import FrontSide from "./FrontSide";
import BackSide from "./BackSide";
import Button from "../../../Button";

const mapStateToProps = ({ readerData }) => ({
  readerData: readerData
});

const mapDispatchToProps = {
  getData: readerDataRequestStart
};

const CARD_SIDES = {
  FRONT: {
    label: 'Лицевая сторона',
    component: FrontSide
  },
  BACK: {
    label: 'Обратная сторона',
    component: BackSide
  },
};

const LibraryCardPass = ({ match, getData, readerData }) => {
  const PARSED_CARD_SIDES = Object.entries(CARD_SIDES);
  const [visibleCardSide, setVisibleCardSide] = useState(PARSED_CARD_SIDES[0][0]);
  const TagName = CARD_SIDES[visibleCardSide].component;
  const card_side = useRef(null);

  const onChange = (event) => {
    setVisibleCardSide(event.target.value);
  };

  useEffect(() => {
    getData(match.params.mid);
  }, []);


  const saveImage = () => {
    domtoimage.toPng(card_side.current, {
      width: 1006,
      height: 640
    })
      .then((dataUrl) => {
        const img = new Image();
              img.src = dataUrl;
        const w = window.open("");
              w.document.write(img.outerHTML);
        setTimeout(() => {
          w.print();
        }, 1000)
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  return (
    <div>

      <div className={styles.controls}>
        <div className="container">
          <Row className="align-items-center">
            <Col>
              <h2>Печать карты читателя</h2>
            </Col>
            {PARSED_CARD_SIDES.map(([key, value]) =>
              <Col md="auto">
                <label className="mb-0">
                  <input type="radio" value={key}
                         className="mr-1"
                         checked={visibleCardSide === key}
                         onChange={onChange}/>
                  { value.label }
                </label>
              </Col>
            )}
            <Col md={'auto'}>
              <Button label="Печать" onClick={saveImage}/>
            </Col>
          </Row>
        </div>
      </div>

      {readerData &&
      <TagName data={readerData} refItem={card_side}/>
      }
    </div>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LibraryCardPass);