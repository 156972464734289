import React from 'react';
import T from 'prop-types';
import styles from './CollectionsModal.module.css';
import {EDIT} from "../../../constants/modalTypes";
import {Tab, Tabs} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import StaticCollectionForm from "../StaticForm";
import DynamicCollectionForm from "../DynamicForm";
import CatalogsModalWithForm from "../../Catalogs/ModalWithForm";
import * as Yup from "yup";
import {useFormik} from "formik";


const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Это обязательное поле')
        .trim('Это обязательное поле')
        .max(200, 'Недопустимая длина названия (макс 200)')
});

const CollectionsModal = ({onCloseModal, onSubmit, modalData}) => {
    const {modalType, data} = modalData;

    const modalForm = useFormik({
        initialValues: {
            name: data.name,
            ctype: data.ctype,
        },
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: values => onSubmit(values)
    });


    return (
        <CatalogsModalWithForm title={`Коллекция: ${modalType === EDIT ? "редактирование" : "добавление"}`}
                               width={650}
                               submitHandler={modalForm.handleSubmit}
                               closeModal={onCloseModal}>
            <Tabs id={'collectionModalTabs'} defaultActiveKey={"index"}>
                <Tab eventKey="index" title="Главная">
                    <div className={styles.tabContent}>
                        <ModalInputWrapper
                            label={"Название"}
                            value={modalForm.values.name}
                            name="name"
                            error={modalForm.errors.name}
                            onChange={modalForm.handleChange}
                        />
                        <ModalInputWrapper label={"Тип"}>
                            <select
                                value={modalForm.values.ctype}
                                name="ctype"
                                disabled={modalType === EDIT}
                                onChange={modalForm.handleChange}
                            >
                                <option value={0}>Статическая</option>
                                <option value={1}>Динамическая</option>
                            </select>
                        </ModalInputWrapper>
                    </div>
                </Tab>
                {modalType === EDIT &&
                <Tab eventKey="details" title={"Подробнее"}>
                    <div className={styles.tabContent}>
                        {data.ctype === 0 ? <StaticCollectionForm collectionId={data.id}/> :
                            <DynamicCollectionForm collectionId={data.id}/>}
                    </div>
                </Tab>
                }
            </Tabs>
        </CatalogsModalWithForm>
    );
};

CollectionsModal.propTypes = {
    onSubmit: T.func,
    onCloseModal: T.func,
    resetCollectionData: T.func
};

export default CollectionsModal;
