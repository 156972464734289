import { PERMISSIONS_BY_CATEGORY_COUNT_REQUEST, ERROR, START, SUCCESS } from '../../action-types'

export const permissionsByCategoryCountRequestStart = data => ({
  type: `${PERMISSIONS_BY_CATEGORY_COUNT_REQUEST}${START}`,
  payload: data,
})

export const permissionsByCategoryCountRequestSuccess = data => ({
  type: `${PERMISSIONS_BY_CATEGORY_COUNT_REQUEST}${SUCCESS}`,
  payload: data,
})

export const permissionsByCategoryCountRequestError = data => ({
  type: `${PERMISSIONS_BY_CATEGORY_COUNT_REQUEST}${ERROR}`,
  payload: data,
})
