import { call, put, takeLatest, select } from "redux-saga/effects";
import { BBK_LIST_COLLAPSE, BBK_LIST_REQUEST, START } from "../../actions/action-types";
import { bbkList } from "../../../api/bbk/list";
import { addToList, collapseList } from "../../../utils/udkAndBbkHelpers";
import { bbkListRequestError, bbkListRequestSuccess } from "../../actions/bbk/list";

export default function* bbkRequestWatcher() {
  yield takeLatest(`${BBK_LIST_REQUEST}${START}`, bbkRequestWorker);
  yield takeLatest(BBK_LIST_COLLAPSE, bbkListCollapseWorker);
}

function* bbkRequestWorker({ payload: { id, search, rewrite } }) {
  const currentList = yield select(({ bbk }) => bbk);
  try {
    const response = yield call(bbkList, id, search);
    const result = rewrite ? response.data : addToList(currentList, id, response.data);
    yield put(bbkListRequestSuccess(result));
  } catch (error) {
    yield put(bbkListRequestError(error));
  }
}

function* bbkListCollapseWorker({payload: id}) {
  const currentList = yield select(({ bbk }) => bbk);
  const result = collapseList(currentList, id);
  yield put(bbkListRequestSuccess(result));
}