import {
  LIBRARY_ADD_POINT,
  LIBRARY_ADD_POINT_CLEAR
} from "../../../action-types";

export const libraryAddPoint = data => ({
  type: LIBRARY_ADD_POINT,
  payload: data
});

export const libraryAddPointClear = () => ({
  type: LIBRARY_ADD_POINT_CLEAR
});
