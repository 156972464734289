import { PERMISSIONS_BY_CATEGORY_EDIT_REQUEST, ERROR, START, SUCCESS } from '../../action-types'
import { createNotification } from '../../../../components/notification/notification'

export const permissionsByCategoryEditRequestStart = data => ({
  type: `${PERMISSIONS_BY_CATEGORY_EDIT_REQUEST}${START}`,
  payload: data,
})

export const permissionsByCategoryEditRequestSuccess = data => {
  createNotification('success', 'Доступ к категории успешно отредактирован')
  return {
    type: `${PERMISSIONS_BY_CATEGORY_EDIT_REQUEST}${SUCCESS}`,
    payload: data,
  }
}

export const permissionsByCategoryEditRequestError = data => {
  createNotification('error', 'Не удалось отредактировать доступ к категории')
  return {
    type: `${PERMISSIONS_BY_CATEGORY_EDIT_REQUEST}${ERROR}`,
    payload: data,
  }
}
