import { call, put, takeLatest } from 'redux-saga/effects'
import { LIBRARY_INSTITUTIONS_REQUEST, START } from '../../../../actions/action-types'
import { institutionsListAll } from '../../../../../api/institutions/institutions'
import { libraryInstitutionsRequestError, libraryInstitutionsRequestSuccess } from '../../../../actions/library/info/institutions'

export default function* libraryInstitutionsWatcher() {
  yield takeLatest(`${LIBRARY_INSTITUTIONS_REQUEST}${START}`, libraryInstitutionsWorker)
}

function* libraryInstitutionsWorker() {
  try {
    const response = yield call(institutionsListAll)
    yield put(libraryInstitutionsRequestSuccess(response.data))
  } catch (error) {
    yield put(libraryInstitutionsRequestError(error))
  }
}
