import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import classNames from 'classnames'
import styles from './AdminPermissionsTable.module.css'
import { Col, Popover, Row } from 'react-bootstrap'
import PopoverOuter from '../../UtulityComponents/Popover'

const AdminPermissionsTable = ({ data, onPermissionChange }) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    setSelected(selected => (selected ? data.find(item => item.xp_key === selected.xp_key) : null))
  }, [data])

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <Row noGutters>
          <Col md={2}>
            <div className={styles.headItem}>Раздел</div>
          </Col>
          <Col md={8}>
            <div className={styles.headItem}>Режим</div>
          </Col>
          <Col md={2}>
            <div className={styles.headItem}>Доступ</div>
          </Col>
        </Row>
      </div>
      <div className={styles.content}>
        <Row noGutters>
          <Col md={2} className={styles.parentSection}>
            {data.map((item, index) => (
              <div className={classNames(styles.item, styles.parentItem)} key={index} onClick={() => setSelected(item)}>
                {item.name}
              </div>
            ))}
          </Col>
          <Col md={4}>
            {selected &&
              selected.children &&
              selected.children.map((item, index) => (
                <div className={styles.item} key={index}>
                  {item.name}
                </div>
              ))}
          </Col>
          <Col md={4}>
            {selected &&
              selected.children &&
              selected.children.map((item, index) => (
                <PopoverOuter
                  content={<div dangerouslySetInnerHTML={{ __html: item.comment ? item.comment : 'На данный момент комментарий отсутствует' }} />}
                  trigger='click'
                >
                  <div className={styles.popoverItem} key={index}>
                    Комментарий
                  </div>
                </PopoverOuter>
              ))}
          </Col>
          <Col md={2}>
            {selected &&
              selected.children &&
              selected.children.map((item, index) => (
                <div className={classNames(styles.item, styles.checkboxContainer)} key={index}>
                  <select
                    onChange={({ target: { value } }) => onPermissionChange(item.xp_key, value)}
                    value={item.value}
                  >
                    <option value='Нет'>Нет</option>
                    <option value='Чтение'>Чтение</option>
                    <option value='Полный'>Полный</option>
                  </select>
                </div>
              ))}
          </Col>
        </Row>
      </div>
    </div>
  )
}

AdminPermissionsTable.propTypes = {
  data: T.array,
  onPermissionChange: T.func,
}

export default AdminPermissionsTable
