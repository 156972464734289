import { api } from "../../App";
import {
  START,
  SUCCESS,
  PAGES_SAVE_REQUEST,
  PAGES_LIST_REQUEST,
  PAGES_DEL_REQUEST,
  PAGES_EDIT_REQUEST
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import { combineReducers } from "redux";

const savePage = ({ file, id }) => {
  const formData = new FormData();
  formData.append("scanimage", file);

  return api.post(`bookpage/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const getPage = id => {
  return api.get(`bookpage/${id}`);
};

const delPage = id => {
  return api.delete(`bookpage/${id}`);
};

const editPage = (id, data) => {
  return api.put(`bookpage/${id}`, data);
};

export const pagesActions = {
  ...createSimpleActionsForReducer("savePage", PAGES_SAVE_REQUEST),
  ...createSimpleActionsForReducer("delete", PAGES_DEL_REQUEST),
  ...createSimpleActionsForReducer("list", PAGES_LIST_REQUEST),
  ...createSimpleActionsForReducer("edit", PAGES_EDIT_REQUEST)
};

export const pagesReducers = combineReducers({
  list: createSimpleReducer([], `${PAGES_LIST_REQUEST}${SUCCESS}`)
});

export function* pagesWatcher() {
  yield takeLatest(`${PAGES_SAVE_REQUEST}${START}`, savePageSaga);
  yield takeLatest(`${PAGES_DEL_REQUEST}${START}`, delPageSaga);
  yield takeLatest(`${PAGES_LIST_REQUEST}${START}`, listSaga);
  yield takeLatest(`${PAGES_EDIT_REQUEST}${START}`, editSaga);
}

function* delPageSaga(data) {
  try {
    yield call(delPage, data.payload.selectedId);
    yield put(pagesActions.listRequestStart(data.payload.bookId));
  } catch (e) {
    console.log(e);
  }
}

function* savePageSaga(data) {
  try {
    const response = yield call(savePage, data.payload);
    yield put(pagesActions.listRequestStart(response.data.bookId));
  } catch (e) {
    console.log(e);
  }
}

function* editSaga({ payload }) {
  try {
    const response = yield call(editPage, payload.id, payload.data);
    yield put(pagesActions.listRequestStart(response.data.bookId));
  } catch (e) {
    console.log(e);
  }
}

function* listSaga(data) {
  try {
    const response = yield call(getPage, data.payload);
    yield put(pagesActions.listRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}
