import {LIBRARIES_LIST_REQUEST, START, SUCCESS, ERROR} from "../../action-types";

export const librariesListRequestStart = (clientId) => ({
  type: `${LIBRARIES_LIST_REQUEST}${START}`,
  payload: clientId
});

export const librariesListRequestSuccess = (data) => ({
  type: `${LIBRARIES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const librariesListRequestError = (data) => ({
  type: `${LIBRARIES_LIST_REQUEST}${ERROR}`,
  payload: data
});
