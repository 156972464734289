import {
  all,
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import {
  GUK_TAB_DATA_REQUEST,
  START
} from "../../actions/action-types";
import {
  gukTabDataRequestSuccess,
  gukTabDataRequestError
} from '../../actions/gukTab/getData';
import {
  getPurpose,
  getTechdisributions,
  getPurposeEducationLevels,
  getPurposeEducationForms,
  getPurposeImplementations,
  getPurposeReaders,
  getGenres,
  getTechperiods,
  getTechCommunics,
  getOutCarriers,
  getOutStatus,
  getNovPublication,
  getNatureBasicInformation,
  getRepetitionRelease,
  getInformationTechnologyDesign,
  getInstallationMethod,
  getGeneralEducationLevels,
  getProfessionalEducationLevels,
  getSubspeciesContinuingEducation,
  getOkso,
  getInformationChangesAor
} from '../../../api/book/GUK';


export default function* saveBookWatcher() {
  yield takeLatest(`${GUK_TAB_DATA_REQUEST}${START}`, gukTableDataWorker);
}

function* gukTableDataWorker() {
    try {
        const [purpose, techdisributions, purposeEducationLevels, purposeEducationForms,
               purposeImplementations, purposeReaders, genres, techperiods, techCommunics, outCarriers, outStatus,
               novPublication, natureBasicInformation, repetitionRelease, informationTechnologyDesign,
               installationMethod, generalEducationLevels, professionalEducationLevels, subspeciesContinuingEducation,
               okso, informationChangesAor]
        = yield all([
            call(getPurpose),
            call(getTechdisributions),
            call(getPurposeEducationLevels),
            call(getPurposeEducationForms),
            call(getPurposeImplementations),
            call(getPurposeReaders),
            call(getGenres),
            call(getTechperiods),
            call(getTechCommunics),
            call(getOutCarriers),
            call(getOutStatus),
            call(getNovPublication),
            call(getNatureBasicInformation),
            call(getRepetitionRelease),
            call(getInformationTechnologyDesign),
            call(getInstallationMethod),
            call(getGeneralEducationLevels),
            call(getProfessionalEducationLevels),
            call(getSubspeciesContinuingEducation),
            call(getOkso),
            call(getInformationChangesAor)
          ]);

        const requestCompleteData = {
          purpose: purpose.data,
          techdisributions: techdisributions.data,
          purposeEducationLevels: purposeEducationLevels.data,
          purposeEducationForms: purposeEducationForms.data,
          purposeImplementations: purposeImplementations.data,
          purposeReaders: purposeReaders.data,
          genres: genres.data,
          techperiods: techperiods.data,
          techCommunics: techCommunics.data,
          outCarriers: outCarriers.data,
          outStatus: outStatus.data,
          novPublication: novPublication.data,
          natureBasicInformation: natureBasicInformation.data,
          repetitionRelease: repetitionRelease.data,
          informationTechnologyDesign: informationTechnologyDesign.data,
          installationMethod: installationMethod.data,
          generalEducationLevels: generalEducationLevels.data,
          professionalEducationLevels: professionalEducationLevels.data,
          subspeciesContinuingEducation: subspeciesContinuingEducation.data,
          okso: okso.data,
          informationChangesAor: informationChangesAor.data,
        };
        yield put(gukTabDataRequestSuccess(requestCompleteData));
    } catch(error) {
        yield put(gukTabDataRequestError());
    }
}