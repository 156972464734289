import { api } from '../../App'
import { saveAs } from 'file-saver'
import { createNotification } from '../../components/notification/notification'
import axios from 'axios'
import { BASE_URL_WITHOUT_API } from '../../constants/api'

export const getFileFormat = fileName => {
  const lastIndexDot = fileName.lastIndexOf('.')

  if (~lastIndexDot) {
    return fileName.slice(lastIndexDot + 1)
  }

  return ''
}

export const downloadFile = (link, filename, format) =>
  api
    .get(link, {
      responseType: format === 'json' ? 'json' : 'arraybuffer',
    })
    .then(response => {
      let fileData = response.data

      if (format === 'json') fileData = JSON.stringify(fileData)

      const blob = new Blob([fileData], {
        type: response.headers['content-type'],
      })

      saveAs(blob, format ? `${filename}.${format}` : filename)
    })
    .catch(err => {
      let errorText = 'Ошибка загрузки файла'
      if (err.response?.status === 403) {
        errorText = 'Ошибка загрузки файла. Нет доступа.'
      }
      createNotification('error', errorText)
    })

export const downloadFileTwo = (link, filename, format) =>
  api
    .get(link)
    .then(response =>
      axios.get(`${BASE_URL_WITHOUT_API}${response.data.Location}`, {
        responseType: format === 'json' ? 'json' : 'arraybuffer',
      })
    )
    .then(response => {
      let fileData = response.data

      if (format === 'json') fileData = JSON.stringify(fileData)

      const blob = new Blob([fileData], {
        type: response.headers['content-type'],
      })

      saveAs(blob, format ? `${filename}.${format}` : filename)
    })
    .catch(err => {
      let errorText = 'Ошибка загрузки файла'
      if (err.response?.status === 403) {
        errorText = 'Ошибка загрузки файла. Нет доступа.'
      }
      createNotification('error', errorText)
    })

export const openFile = (link, filename, format) =>
  api.get(link).then(response => `${BASE_URL_WITHOUT_API}${response.data.Location}`)

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function splitSumToRubAndCop(sum) {
  if (!sum) return 0
  let splittedSum = Number(sum).toFixed(2).split('.')
  return splittedSum[0] + 'руб.' + splittedSum[1] + 'коп.'
}

/*
 * функция разбивает массив на подмассивы по заданному количеству элементов
 * splitArrayIntoChunks([1,2,3,4,5,6], 3) === [[1,2,3], [4,5,6]]
 * */
export function splitArrayIntoChunks(arr, chunkSize = 2) {
  let R = []
  for (let i = 0; i < arr.length; i += chunkSize) R.push(arr.slice(i, i + chunkSize))
  return R
}

export const getFullName = item => {
  const lastname = item.lastname || ''
  const firstname = item.firstname || ''
  const patronymic = item.patronymic || ''

  return `${lastname} ${firstname} ${patronymic}`
}

export const positionForAll = (menuList = []) => {
  const newMenuList = JSON.parse(JSON.stringify(menuList))
  newMenuList.forEach((menuItem, index) => {
    menuItem.position = index + 1
    menuItem.items?.length && (
      menuItem.items = positionForAll(menuItem.items)
    )
  })
  return newMenuList
}

export const getYearsList = () => {
  let res = []
  for (let i = 2000; i <= 2050; i++) {
    res.push(i)
  }
  return res
}