import React, {useEffect, useState} from 'react';
import styles from './SvniScene.module.css';
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {svniListCollapse, svniListRequestStart} from "../../../redux/actions/svni/list";
import SVNIList from "../../../components/SVNI/List";
import { Button } from 'antd';
import ModalWindow from '../../../components/ModalWindow';
import { api } from '../../../App';
import Rows from '../../../components/AdminDerectory/Modal/Form/Rows';
import { pack } from '../../../components/AdminDerectory/Modal/Form/Pack';
import { toLine } from '../../../components/AdminDerectory/Modal/Form/toLine';
import { createNotification } from '../../../components/notification/notification';



const mapStateToProps = state => ({
    list: state.svni
});

const mapDispatchToProps = dispatch => ({
    requestList: (id, search, rewrite) => dispatch(svniListRequestStart(id, search, rewrite)),
    collapseList: (id) => dispatch(svniListCollapse(id))
});

const SVNIScene = ({list, requestList, collapseList}) => {
    
    const currentURL = window.location.href.split('/')[window.location.href.split('/').length-1];
    const [firstRender, setFirstRender] = useState(true)
    const [isVisible, setIsVisible] = useState(false);
    const [listData, setListData] = useState({});
    const [isDragOver, setIsDragOver] = useState(null);
    const [isShow, setIsShow] = useState([]);
    const [isDrag, setIsDrag] = useState(null);
    const [isDrop, setIsDrop] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [editCopy, setEditCopy] = useState(null);
    const [fullList, setList] = useState([]);
    const [deleteList, setDeleteList] = useState([]);
    const [error, setError] = useState([]);
    const [levels, setLevel] = useState(0); 

    const handleSelect = (ids, level) => {
        setLevel(level)
        if (selectedId == ids) {
            setSelectedId(null);
        } else {
            setSelectedId(ids);
        }
    }

    const handleStartDrag = (item) => {
        setIsDrag(item)
    }

    useEffect(() => {
        firstRender && requestList(0)
        setFirstRender(false)
        return () => {
            firstRender && requestList(0)
        }
    }, []);

    const handleFinishDrag = (item) => {
        setIsDrop(item);
        getNewRow(item);
        setIsDragOver(null);
    }

    const handleDragEnter = (lowRow) => {
        if (lowRow.id === isDrag.id) {
            clearRows(lowRow.id, isDrag.id)
            return
        } else {
            // getNewRow(lowRow.id,  isDrag.id)
        }

        if (['topRowChoice', 'bottomRowChoice'].includes(lowRow.id)) 
            return;

        const checkInclude = toLine(pack(fullList, isDrag.id)).find(el => el.id == lowRow.id);

        if (!checkInclude) {
            addChoiceRows(lowRow)
        }
    }

    const getNewRow = (newRow) => {
        const array = fullList;
        const index = array.findIndex(el => el.id == newRow.id);
        let temporaryList = null;
        let fullNewRow = null;

        if (newRow.id == 'topRowChoice') {
            temporaryList = array.filter((row, i) => !['bottomRowChoice'].includes(row.id) || i == index + 1);
        } else if (newRow.id == 'bottomRowChoice') {
            temporaryList = array.filter((row, i) => !['topRowChoice'].includes(row.id) || i == index - 1);
        } else {
            temporaryList = array.filter(row => !['topRowChoice', 'bottomRowChoice'].includes(row.id));
        }

        fullNewRow = handleMove(isDrag, newRow, temporaryList, newRow);

        if (fullNewRow && (isDrag.id != newRow.id)) {
            setList(fullNewRow.filter(el => !['topRowChoice', 'bottomRowChoice'].includes(el.id)));
        } else {
            setList(array.filter(el => !['topRowChoice', 'bottomRowChoice'].includes(el.id)));
        }
    }

    const addChoiceRows = (lowRow) => {
        setIsDragOver(lowRow)
        const clearedList = fullList
            .filter(row => !['topRowChoice', 'bottomRowChoice'].includes(row.id))
        const temporaryList = clearedList.reduce((acc, row) => {
            if (lowRow.id === row.id) {
                let value = listData.columns;

                const newList = value?.reduce((acc, item) => {
                    acc.id = String(Date.now() + Math.floor(Math.random() * (10000 - 1) + 1)).substring(5, 12)
                    acc[item.name] = '';
                    acc.isNew = true;
                    // Шифр
                    acc.parent = selectedId || 0;
                    return acc
                }, {})

                return [
                    ...acc, {
                        ...newList,
                        // name: "Сверху",
                        type: "CHARACTER VARYING",
                        is_null: true,
                        parent: lowRow.parent,
                        id: 'topRowChoice',
                    },
                    row, {
                        ...newList,
                        // name: "Снизу",
                        type: "CHARACTER VARYING",
                        is_null: true,
                        parent: lowRow.parent,
                        id: 'bottomRowChoice'
                    }
                ]
            } else {
                return [
                    ...acc,
                    row
                ]
            }
        }, []);

        setList(temporaryList);
        setIsDragOver(null)
    }

    const clearRows = (newId, dragId) => {
        if (dragId != isDrop
            ?.id) {} else {
            const temporaryList = fullList
                .filter(row => !['topRowChoice', 'bottomRowChoice'].includes(row.id));
            setList(temporaryList);
            setIsDragOver(null);
        }
    }

    const handleMove = (from, to, list = fullList, newRow) => { //перемещение пунктов таблицы
        const checkInclude = toLine(pack(fullList, from.id)).find(el => el.id == newRow.id);
        let value = pack(list);
        value = toLine(value);
        value = value.filter(el => el.id != from.id);
        value = value.reduce((acc, item) => {
          if (item.id == to.id) {
                if (item.id == newRow.id && item.id == 'topRowChoice') {
                    const err = fullList.filter(el => el.parent === to.parent);
                    return [
                      ...acc, 
                            {...from,
                              parent: to.parent,
                              err: err.length > 0 ? true : false  
                            },
                    ];
                }
                if (item.id == newRow.id && item.id == 'bottomRowChoice') {  
                  const err = fullList.filter(el => el.parent === to.parent);
                  return [
                        ...acc, 
                            {...from,
                              parent: to.parent,
                              err: err.length > 0 ? true : false  
                            },
                    ];
                } 
                else {
                  const err = fullList.filter(el => (el.parent === to.parent) 
                //   && (el.code == from.code)
                  )  
                  return [
                        ...acc,
                        item, {
                            ...from,
                            parent: to.id, 
                            err: err.length > 0 ? true : false
                        }
                    ];
                }
            } 
            else {
              // const err = fullList.filter(el => el.parent === to.parent).find(el => el.code == from.code);
                return [
                    ...acc,
                    {...item}
                ];
            }
        }, [])

        value = value.map(el => delete el.columns && el);
        if (!checkInclude) {
            return value
        } else 
            return list
    }

    useEffect(() => {
          api.get(`/guide/${currentURL}`).then(data => setListData(data.data));
      }, [])

    useEffect(() => {
          api.get(`/guide/${currentURL}/items`).then(data => {
            const newList = data.data.map(el => ({...el, err: true}));
            setList(newList);
        });
      }, [])


    const handleAdd = () => {
        api.get(`/guide/${currentURL}`).then(data => setListData(data.data));
        setIsVisible(true);
    }

    const handleCancelEdit = () => {
        const value = fullList
            .map(el => {
                if (el.id == selectedId) {
                    return editCopy;
                } else {
                    return el
                }
            })
        setEditCopy(null);
        setIsVisible(false)
    }

    const handleDeleteColumns = () => { // удаление поля справочника
        if (levels) {
            if (levels > 10) {
                createNotification('error', 'Максимальный уровень вложенности справочника 10')
            } else {
                let value = fullList;
                setDeleteList(prev => [...prev, ...toLine([...pack(fullList, selectedId), fullList.find(el => el.id == selectedId)])]);
                value = value.filter(el => el.id != selectedId);
                value = toLine(pack(value));    
                setList(value);
                setSelectedId(null);
                setLevel(null);
            }
        } else {
            createNotification('error', 'Выберите поле для удаления')
        }
    }

    const handleAddColumns = () => { // добавление нового поля справочника
        if (levels === 10) {
            return createNotification('error', 'Максимальный уровень вложенности справочника 10')
        } 
        let value = listData.columns;
        const newList = value?.reduce((acc, item) => {
            acc.id = String(Date.now() + Math.floor(Math.random() * (10000 - 1) + 1)).substring(5, 12)
            acc[item.name] = value.find(el => el.name == item.name).type == 'BOOLEAN' ? 'false' : '';
            acc.isNew = true;
            // Шифр
            acc.parent = selectedId || 0;
            return acc
        }, {})
        setList(prev => ([...prev, newList]));
    }
    
    const handleSaveColumns = () => {
        const checkError = document.getElementById('inputColumnErr');
        const checkError2 = document.getElementById('listItemErr');
        if (checkError2) {
            return createNotification('error', 'Дублированние поля Код')
        } else if (error || checkError) {
            return createNotification('error', 'Не все поля заполнены, либо заполнены некорректно')
        } else {

            //DELETE LIST ITEMS
            if (deleteList.length > 0) {
                const cleanDeleteList = deleteList.map(el => {
                    delete el.err;
                    return el
                })
                api.delete(`/guide/${currentURL}/delete_items`, {
                    data: cleanDeleteList
                }).then(res => {
                    if (res.status == 200) {
                        createNotification('success', 'Удалено');
                    } else {
                        createNotification('error', 'Что-то пошло не так');
                    }
                })
                setDeleteList([])
            }


            //UPDATE LIST ITEMS
            const updateList = [...fullList].map(el => {
                delete el.isEdit;
                delete el.err;
                return el
            });
            if (updateList.length > 0) {
                const cleanList = updateList.map(el => {
                    delete el.isEdit;
                    delete el.err;
                    return el;
                });
                api.put(`/guide/${currentURL}/update_items`, cleanList).then(data => {
                    if (data.status === 200) {
                        createNotification('success', 'Изменено');
                    } else {
                        createNotification('error', 'Что-то пошло не так');
                    }
                })
            }
            
            
            //CREATE LIST ITEMS
            const postList = [...fullList].filter(el => el.isNew == true).map(el => {
                delete el.isEdit;
                delete el.isNew;
                delete el.err;
                return el
            });
            if (postList.length > 0) {
                const cleanList = postList.map(el => {
                    delete el.isNew;
                    return el;
                });

                api.post(`/guide/${currentURL}/create_item`, cleanList).then(data => {
                    if (data.status === 201) {
                        // setListData(data.data);
                        createNotification('success', 'Создано');
                    } else {
                        createNotification('error', 'Что-то пошло не так');
                    }
                })
            }
        }
    }

    return (
        <div className={styles.container}>

        <ModalWindow
            width={'1200px'}
            isOpen={isVisible}
            title={`Поле справочника: Редактирование`}
            onRequestClose={() => handleCancelEdit()}>

                <div className={styles.buttons}>
                    <div style={{margin: '.5rem'}}><Button type={'primary'} onClick={() => handleAddColumns()}>Добавить</Button></div>
                    <div style={{margin: '.5rem'}}><Button type={'danger'}  onClick={() => handleDeleteColumns()}>Удалить</Button></div>
                </div>

                <div className={styles.container}>
                    <table style={{width: '100%'}}>
                        <thead>
                        <td 
                        colSpan={1}
                        >Код</td>
                        {fullList[0] && Object.keys(fullList[0]).map((key, index) => {
                        if (['id', 'parent', 'columns', 'Код', 'err', 'len', 'type', 'is_null', 'description', 'isNew', 'isEdit'].includes(key) ) {
                            return 
                        } else {
                            return <td colSpan={key=='Название' ? 10 : 4} key={index} name={key}>{key}</td>
                        }
                    })}
                        <td colSpan={1} style={{width: '100px'}}>Выбрать</td>
                        </thead>
                        <tbody id='table'>
                        <Rows 
                            isDragOver={isDragOver} 
                            isDrag={isDrag} 
                            handleSelect={handleSelect} 
                            selectedId={selectedId} 
                            data={fullList}
                            handleStartDrag={handleStartDrag}
                            handleFinishDrag={handleFinishDrag}
                            handleDragEnter={handleDragEnter}
                            isShow={isShow}
                            setIsShow={setIsShow}
                            setList={setList}
                            listData={listData}
                            error={error}
                            setError={setError}
                            levels={levels}
                            setLevel={setLevel}
                        />
                        </tbody>
                    </table>
                </div>

                <div className={styles.buttons}>
                    <div style={{margin: '.5rem'}}><Button type={'primary'} onClick={() => handleSaveColumns()}>Сохранить</Button></div>
                    <div style={{margin: '.5rem'}}><Button type={'primary'}  onClick={() => handleCancelEdit()}>Отменить</Button></div>
                </div>

        </ModalWindow>

            <Row>
                <Col md={10}>
                    <div className={styles.title}>Справочники / {listData.name}</div>
                </Col>
                <Col md={2}>
                <div className={styles.buttonBlock}>
                    <Button 
                        type="primary" 
                        onClick={() => handleAdd()}
                        >Редактировать</Button>
                </div>
                </Col>

                <Col md={12}>
                    {!!fullList.length && <SVNIList data={fullList} listData={listData} fetchSubList={requestList} collapseList={collapseList}/>}
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SVNIScene);
