import { combineReducers } from "redux";
import { rubricatorListReducer } from "./list/rubricatorListReducer";
import { rubricatorSearchReducer } from "./search/rubricatorSearchReducer";
import { rubricatorCountReducer } from "./count/rubricatorCountReducer";
import { rubricatorParentSearchReducer } from "./parentSearch/rubricatorParentSearchReducer";
import { rubricatorParentItemReducer } from "./item/rubricatorParentItemReducer";

export const rubricatorReducer = combineReducers({
  count: rubricatorCountReducer,
  list: rubricatorListReducer,
  search: rubricatorSearchReducer,
  parentSearch: rubricatorParentSearchReducer,
  parentItem: rubricatorParentItemReducer
});
