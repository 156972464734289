import { ERROR, ROLES_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const rolesListRequestStart = () => ({
  type: `${ROLES_LIST_REQUEST}${START}`
});

export const rolesListRequestSuccess = (data) => ({
  type: `${ROLES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const rolesListRequestError = (data) => ({
  type: `${ROLES_LIST_REQUEST}${ERROR}`,
  payload: data
});