import { PERMISSIONS_BY_LITER_ADD_REQUEST, ERROR, START, SUCCESS } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const permissionsByLiterAddRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_LITER_ADD_REQUEST}${START}`,
  payload: data
});

export const permissionsByLiterAddRequestSuccess = (data) => {
  createNotification('success', 'Категория успешно добавлена к роли');
  return {
    type: `${PERMISSIONS_BY_LITER_ADD_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const permissionsByLiterAddRequestError = (data) => {
  createNotification('error', 'Не удалось добавить категорию к роли');
  return {
    type: `${PERMISSIONS_BY_LITER_ADD_REQUEST}${ERROR}`,
    payload: data
  };
};
