import React from 'react';
import T from 'prop-types';
import styles from './BBKList.module.css';
import BBKListItem from "./Item";

const BBKList = ({ data, fetchSubList, collapseList, onSelect, selectMode }) => {

  const createSublist = (data, level) => {
    return data.map((item, index) =>
      <React.Fragment key={index}>
        <BBKListItem
          fetchSubList={fetchSubList}
          data={item}
          level={level}
          collapseList={collapseList}
          onSelect={onSelect}
          selectMode={selectMode}
        />
        {item.subList && createSublist(item.subList, level + 1)}
      </React.Fragment>)
  };

  return (
    <div className={styles.container}>
      <table>
        <thead>
        <tr>
          <th colSpan={6}>Код ББК</th>
          <th colSpan={10}>Описание</th>
          <th colSpan={10}>Примечание</th>
          {onSelect && <th colSpan={3}></th>}
        </tr>
        </thead>
        <tbody>
          {createSublist(data, 1)}
        </tbody>
      </table>
    </div>
  );
};

BBKList.propTypes = {
  data: T.array.isRequired,
  fetchSubList: T.func.isRequired,
  collapseList: T.func.isRequired
};

export default BBKList;
