import React from 'react';
import T from 'prop-types';
import ModalWindow from "../../ModalWindow";
import BooksTable from "./BooksTable";
import EventsTable from "./EventsTable";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";

import {CHECK_DATA_LOG_MODAL_TYPES} from "../../../scenes/CheckDataLog";

const CheckDataLogModal = ({booksData, eventsData, closeModal, modalType}) => {

    const {loader, data} = (modalType === CHECK_DATA_LOG_MODAL_TYPES.events)
        ? eventsData
        : booksData;


    const CurrentTypeTable = () =>
        modalType === CHECK_DATA_LOG_MODAL_TYPES.events
            ? <EventsTable data={data}/>
            : <BooksTable data={data}/>;


    return (
        <ModalWindow onRequestClose={closeModal} isOpen={true} title={"Проверка целостности данных при миграции"}>

            {loader
                ? <SpinnerOuter/>
                : <CurrentTypeTable/>
            }

        </ModalWindow>
    );
};

CheckDataLogModal.propTypes = {
    booksData: T.object,
    eventsData: T.object,
    closeModal: T.func,
};

export default CheckDataLogModal;