import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { reportEventGetRequestStart } from '../../../redux/actions/reports/get'
import StaticInformationReportForm from '../../../components/Reports/Event/Form'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import DataTable from '../../../components/DataTable'
import DataTableCol from '../../../components/DataTable/Column'
import { api } from '../../../App'
import ModalWindow from '../../../components/ModalWindow'
import { Button } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../../components/Modal/InputWrapper'
import { createNotification } from '../../../components/notification/notification'
import Select from 'react-select'
import { useSelector } from 'react-redux'

const mapStateToProps = ({ reports, auth, options }) => ({
  data: reports.report,
  loader: reports.loader,
  mid: auth.data.mid,
  idclient: options.data.own_client_id
})

const mapDispatchToProps = {
  requestReport: reportEventGetRequestStart,
}

const EventReportScene = ({ ownId, data, loader, requestReport, mid, idclient }) => {

  const [sendBookModalVisible, setSendBookModalVisible] = useState(false)
  const [xpClient, setXpClient] = useState([])
  const [dataLoc, setData] = useState([]);
  const [library, setLibrary] = useState([]);
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState('');
  const [localLib, setlocalLib] = useState([])
  const [postSearch, setPostSearch] = useState({})
  
  const user = useSelector((state) => state?.options?.data);

  const validationSchema = Yup.object().shape({
    from_date: Yup.date().required("Выберите дату начала"),
    to_date: Yup.date()
      .required("Выберите дату завершения")
      .min(Yup.ref("from_date"), "Дата завершения должна быть позже даты начала"),
    library_id: Yup.string().required('Поле является обязательным'),
    people_count: Yup.string().required('Поле является обязательным'),
    event_name: Yup.string().required('Поле является обязательным'),
    user_id: Yup.string().required('Поле является обязательным'),
  });

  const defInitNew = {
    from_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    // mode: 1,
    // client: 0,
    event_name: "",
    description: "",
    people_count: "",
    library_id: "",
    user_id: ""
  }

  const initEdit = {
    ...dataLoc?.find(el => el.id == edit),
  }

  const newForm = useFormik({
    initialValues: edit ? initEdit : defInitNew,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => edit ? onEdit(values) : onSubmit(values),
  })

  const onSubmit = () => {
    const newVal = newForm.values;
    if ((newVal?.people_count)?.toString()?.length <= 10) {
      api.post('/events_journal', newVal).then(res => {
        if (res.data.result == 'ERROR') {
          createNotification('error', `Ошибка`);
          setSendBookModalVisible(false);
          newForm.resetForm();
        } else {
          const params = users?.find(el => el?.mid == newVal?.user_id);
          api.post('/events_journal_creators', {
            events_journal_id: res?.data?.events_journal?.id,
            user_id: newVal?.user_id,
            person: params?.displayname}
            ).then(res => {
              createNotification('success', `Успешно`);
              // getList();
              const x = Object.assign(newForm.values);
              setPostSearch(x);
              setSendBookModalVisible(false);
              newForm.resetForm();
            })
          }
        })
    } else {
      createNotification('error', 'Максимальная длина поля "Количество обслуженных (чел)" 10 символов')
    }
  }

  const requestData = formData => {
    requestReport({
      id: ownId,
      formData,
    })
  }


  useEffect(() => {
    api.get('/xp_client').then(res => setXpClient([{ idclient: 0, name: 'все' }, ...res.data]))
    getList();
  }, [])

  useEffect(() => {
    const params = { idclient }
    api.get('/library', {params}).then(res => setLibrary([{ id: 0, name: 'Не выбрано' }, ...res.data]))
  }, [])

  useEffect(() => {
    api.get('/people').then(res => setUsers([{mid: 0, displayname: 'Не выбрано'}, ...res.data]) 
      )
  }, [])

  const getList = () => {
    api.get('/events_journal').then(res => {
      if (res.status != 200) {
        createNotification('error', `Ошибка`);
      }
    })
  }

  const handlePopup = () => {
    setSendBookModalVisible(true);
  }

  const onChangeWho = ( e ) => {
    newForm.setFieldValue('user_id', e.value)
  }

  const onChangeLibrary = ( e ) => {
    newForm.setFieldValue('library_id', e.value)
  }

  const handlePopupClose = () => {
    setSendBookModalVisible(false)
    setEdit('')
    newForm.resetForm()
  }

  useEffect(() => {
    setlocalLib(
      library.map(el => {
        let x = ''
        if (el.id == 0) {
          x = {...el, id: 'all', name: 'Все'}
        } else {
          x = el
        }
        return x
      })
    )
  }, [library])

  const onEdit = ( item ) => {
    if (item.id == edit) {
      const params = {
        id: item.id,
        user_id: Number(item.idclient),
        library_id: item.library_id,
        from_date: item.from_date,
        to_date: item.to_date, 
        event_name: item.event_name,
        people_count: item.people_count,
        description: item.description,
        person: item.person
      }
      
      if ((item?.people_count)?.toString()?.length <= 10) {
        api.put(`/events_journal/${item.id}`, { ...params }).then(res => 
          {
            createNotification('success', `Успешно`)
            setPostSearch(item)
            setEdit('');
          }
            )
        
      } else {
        createNotification('error', 'Максимальная длина поля "Количество обслуженных (чел)" 10 символов')
      }
    }
  }

  const error = (fieldName) => newForm.touched[fieldName] && newForm.errors[fieldName]

  return (
    <SceneContainer title={'Отчеты / Проведенные мероприятия'}>
      <StaticInformationReportForm 
      onSubmit={requestData} 
      newForm={newForm}
      dataLoc={dataLoc} 
      library={library} 
      setData={setData} 
      users={users} 
      setSendBookModalVisible={setSendBookModalVisible}
      edit={edit}
      setEdit={setEdit}
      localLib={localLib}
      setlocalLib={setlocalLib}
      user={user}
      postSearch={postSearch}
      setPostSearch={setPostSearch}
      />

      <Button style={{marginRight:'1rem'}} onClick={() => handlePopup()}>Добавить</Button>
      {
      ((sendBookModalVisible && users && users.length > 0) || (edit)) ?
        <ModalWindow

          isOpen={sendBookModalVisible || edit}
          onRequestClose={() => handlePopupClose()}
          // userId={mid}
          title={edit ? 'Редактирование мероприятия' : 'Создание мероприятия'}
          simple={false}
          mid={mid}
          width={1000}>

            <div className={'formContainer'}>
              <Row>
                <Col md={3}>
                  <ModalInputWrapper
                    label='C*'
                    value={moment(newForm.values.from_date).format('YYYY-MM-DD')}
                    name='from_date'
                    error={error('from_date')}
                    onChange={newForm.handleChange}
                    onBlur={newForm.handleBlur}
                    type='date'
                  />
                </Col>
                <Col md={3}>
                  <ModalInputWrapper
                    label='По*'
                    value={moment(newForm.values.to_date).format('YYYY-MM-DD')}
                    name='to_date'
                    error={error('to_date')}
                    onBlur={newForm.handleBlur}
                    onChange={newForm.handleChange}
                    type='date'
                  />
                </Col>

                <Col md={3}>
                <ModalInputWrapper
                  error={error('library_id')}
                  label='Библиотека*'
                >
                  <Select
                    options={library?.map(el => ({value: el.id, label: el.name}))}
                    onChange={onChangeLibrary}
                    value={{
                      value: library?.find(el => el.id == newForm.values.library_id)?.id,
                      label: library?.find(el => el.id == newForm.values.library_id)?.name
                    }}
                    onBlur={e => newForm.handleBlur({target: {name: 'library_id'}})}
                  />
                </ModalInputWrapper>
                </Col>
                <Col md={3}>
                  <ModalInputWrapper
                    label="Количество обслуженных (чел)*"
                    type="number"
                    value={newForm.values.people_count}
                    name="people_count"
                    onChange={newForm.handleChange}
                    error={error('people_count')}
                    onBlur={newForm.handleBlur}
                  />
                </Col>

                <Col md={6}>
                    <ModalInputWrapper
                      label={"Наименование мероприятия*"}
                      type="text"
                      value={newForm.values.event_name}
                      name="event_name"
                      onChange={newForm.handleChange}
                      error={error('event_name')}
                      onBlur={newForm.handleBlur}
                    />
                  </Col>

                  <Col md={6}>
                  <ModalInputWrapper
                    label='Кто проводил*'
                    error={error('user_id')}
                  >
                    <Select
                      options={users?.map(el => ({value: el.mid, label: el.displayname}))}
                      value={{
                        value: users?.find(el => el.mid == newForm.values.user_id)?.mid,
                        label: users?.find(el => el.mid == newForm.values.user_id)?.displayname
                      }}
                      onChange={onChangeWho}
                      onBlur={e => newForm.handleBlur({target: {name: 'user_id'}})}
                      />
                  </ModalInputWrapper>
                  </Col>
                <Col md={6}>
                  <ModalInputWrapper
                      label={"Примечание"}
                      type="textarea"
                      value={newForm.values.description}
                      name="description"
                      onChange={newForm.handleChange}>
                  </ModalInputWrapper>
                </Col>
              </Row>
            </div>

            <Button onClick={newForm.handleSubmit}>{edit ? 'Сохранить' : 'Создать'}</Button>
            </ModalWindow>

      : <></> }

      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        data[ownId] &&
        !!data[ownId].length && (
          <>
            <DataTable data={data[ownId]} onCellClick={() => {}}>
              <DataTableCol colSpan={5} title={'ЭИР по категориям'} value='category' />
              <DataTableCol colSpan={3} title={'Новые поступления'} value='new_books' />
              <DataTableCol colSpan={3} title={'В читальном зале'} value='in_hall' />
              <DataTableCol colSpan={3} title={'Доступны к чтению'} value='ready' />
              <DataTableCol colSpan={3} title={'Заказы на сканирование'} value='scan_orders' />
              <DataTableCol colSpan={3} title={'Объем оцифрованных изданий'} value='scan_complete' />
            </DataTable>
          </>
        )
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EventReportScene)
