import {COUNT, DOCUMENTS, SUCCESS} from "../../../actions/action-types";

const initialState = 0;

export const documentCountReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${DOCUMENTS}${COUNT}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
