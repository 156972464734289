import {SVNI_LIST_REQUEST, SUCCESS} from "../../actions/action-types";

const initialState = [];

export const svniListReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${SVNI_LIST_REQUEST}${SUCCESS}`:
            return [...action.payload];

        default: return state;
    }
};