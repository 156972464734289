import {
    SUCCESS, START, ERROR, BOOK_SECRET_DOC_LIST_REQUEST, BOOK_SECRET_DOC_COUNT_REQUEST
} from "../../../actions/action-types";

const initialState = false;

export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${BOOK_SECRET_DOC_COUNT_REQUEST}${START}`:
            return true;

        case `${BOOK_SECRET_DOC_LIST_REQUEST}${SUCCESS}`:
            return false;

        case `${BOOK_SECRET_DOC_LIST_REQUEST}${ERROR}`:
            return false;

        default:
            return state;
    }
};

