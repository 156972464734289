import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { api } from "../../../App";
import { getFullName } from "../../../utils/helpers/helpers";
import InputWrapper from "../../Modal/InputWrapper";
import { useFormik } from 'formik';
import * as Yup from "yup"



const defaultValidationSchema = Yup.object().shape({
  from: Yup.date(),
  to: Yup.date()
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});



const ElectronicCoursesSearchForm = ({ data, setData, onSubmit, authData }) => {
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);

  
  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values)
      onSubmit()
    },
  });
  
  const { values, errors } = modalForm;
  
  useEffect(() => {
    api.get("/groupname").then((res) => {
      setGroups(res.data);
    });
    api.get("/people").then((res) => {
      setUsers(res.data);
    });
  }, []);


  return (
    <Row>
      <Col md={3}>
        <InputWrapper
          name="from"
          value={values.from}
          error={errors.from}
          onChange={modalForm.handleChange}
          type={"date"}
          label={"С"}
        ></InputWrapper>
      </Col>
      <Col md={3}>
        <InputWrapper
          name="to"
          value={values.to}
          error={errors.to}
          onChange={modalForm.handleChange}
          type={"date"}
          label={"По"}
        ></InputWrapper>
      </Col>
      {authData.login === "nnz" && (
        <>
          <Col md={3}>
            <InputWrapper label={"Группа"}>
              <select name="groupid" onChange={modalForm.handleChange} value={values.groupid}>
                <option value={null}>Все</option>
                {groups.map((group) => (
                  <option value={group.gid} key={group.gid}>
                    {group.name}
                  </option>
                ))}
              </select>
            </InputWrapper>
          </Col>
          <Col md={3}>
            <InputWrapper label={"ФИО"}>
              <select name="student" onChange={modalForm.handleChange} value={values.student}>
                <option value={null}>Все</option>
                {users.map((user) => (
                  <option value={user.mid} key={user.mid}>
                    {getFullName(user)}
                  </option>
                ))}
              </select>
            </InputWrapper>
          </Col>
        </>
      )}

      <Col sm={"auto"}>
        <InputWrapper label={""}>
          <Button onClick={modalForm.handleSubmit} type="primary">
            Найти
          </Button>
        </InputWrapper>
      </Col>
    </Row>
  );
};

ElectronicCoursesSearchForm.propTypes = {};

export default ElectronicCoursesSearchForm;
