import {combineReducers} from "redux";
import {searchAuditCountReducer} from "./count/searchAuditCountReducer";
import {searchAuditListReducer} from "./list/searchAuditListReducer";
import {searchAuditSearchReducer} from "./search/searchAuditSearchReducer";
import {searchAuditFormDataReducer} from "./formData/searchAuditFormDataReducer";

export const searchAuditReducer = combineReducers({
    count: searchAuditCountReducer,
    list: searchAuditListReducer,
    search: searchAuditSearchReducer,
    form: searchAuditFormDataReducer,
});
