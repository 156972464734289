import axios from 'axios'
import { BASE_URL } from '../constants/api'
import { resetAuthData } from '../redux/actions/user/auth'
import { createNotification } from '../components/notification/notification'

export const configureApi = store => {
  const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
  })

  instance.interceptors.response.use(
    response => {
      if (
        response.data.error &&
        response.data.error.code === 401 &&
        response.request.responseURL.indexOf('https://biblio.nintegra.ru/api/hlms/api') === -1
      ) {
        store.dispatch(resetAuthData())
      } else if (
        response.data.error &&
        response.data.error.code === 503 &&
        response.request.responseURL.indexOf('https://biblio.nintegra.ru/api/hlms/api') === -1 &&
        response.request.responseURL.indexOf('https://biblio.nintegra.ru/api/test')
      ) {
        createNotification('error', `Сервис временно недоступен`)
      }
      return response
    },
    error => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.request.responseURL.indexOf('https://biblio.nintegra.ru/api/hlms/api') === -1
      ) {
        store.dispatch(resetAuthData())
      } else if (
        error.response &&
        error.response.status === 503 &&
        error.response.request.responseURL.indexOf('https://biblio.nintegra.ru/api/hlms/api') === -1 &&
        error.response.request.responseURL.indexOf('https://biblio.nintegra.ru/api/test')
      ) {
        createNotification('error', `Сервис временно недоступен`)
      }
      return Promise.reject(error)
    }
  )

  return instance
}
