import React, {useEffect, useState, useCallback} from 'react';
import {connect} from "react-redux";
import {
    PdfLoader,
    PdfHighlighter,
    Tip,
    Highlight,
    Popup,
    AreaHighlight
} from "react-pdf-highlighter";
import {resolveFileApiLink} from "../../utils/resolvePdfLink";
import {BASE_URL_WITHOUT_API} from "../../constants/api";

import {pdfViewerBookRequestStart} from "../../redux/actions/pdfViewer/book";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import Sidebar from "./Sidebar";
import {pdfViewerBookmarkRequestStart} from "../../redux/actions/pdfViewer/getHighlights";

import {pdfViewerSetFileData} from "../../redux/actions/pdfViewer/setFileData";
import {
    pdfViewerAddHighlight,
    pdfViewerDeleteHighlight,
    pdfViewerSetHighlightsData
} from "../../redux/actions/pdfViewer/setHighlights";
import {ICONS} from "../../utils/projectIcons";
import {Result, Button} from 'antd';
import {Link} from "react-router-dom";

import styles from "./PDFViewerScene.module.css";
import {BASE_URL} from '../../constants/api'
import moment from 'moment';


const mapStateToProps = ({pdfViewer}) => ({
    highlights: pdfViewer.highlights,
    error: pdfViewer.error
});

const mapDispatchToProps = {
    requestBook: pdfViewerBookRequestStart,
    requestBookmarks: pdfViewerBookmarkRequestStart,
    setHighlight: pdfViewerSetHighlightsData,
    addHighlight: pdfViewerAddHighlight,
    deleteHighlight: pdfViewerDeleteHighlight,
    setFileData: pdfViewerSetFileData
};


const PDFViewerScene = ({requestBook, match, requestBookmarks, highlights, addHighlight, setHighlight, deleteHighlight, setFileData, error}) => {

    const [bookUrl, setBookUrl] = useState('');
    const [scrollHandler, setScrollHandler] = useState({handler: () => {}});
    const [selectedHighlight, setSelectedHighlight] = useState("");
    const [sidebarShow, setSidebarShow] = useState(true);

    const {bookId, fileId} = match.params;

    useEffect(() => {
        requestBookmarks(bookId, fileId);
        setFileData(bookId, fileId);
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', logData)
        return () => {
            document.removeEventListener('visibilitychange', logData)
        }
    }, [])
    
    const logData = () => {
        if (document.visibilityState === 'hidden') {
            const url = `${BASE_URL}/book/${bookId}/file/${fileId}/close`
            const t = moment().valueOf()
            const payload = JSON.stringify({t})
            navigator.sendBeacon(url, payload)
        }
    }

    useEffect(() => {
        requestBook(bookId)
    }, [requestBook, bookId]);

    useEffect(() => {
        resolveFileApiLink(bookId, fileId)
            .then(response => setBookUrl(`${BASE_URL_WITHOUT_API}/${response.data.url}`))
    }, []);

    const scrollToHighlightFromHash = useCallback(
        (highlights) => {

            const highlight = getHighlightById(parseIdFromHash(), highlights);

            if (highlight) {
                scrollHandler.handler(highlight);
            }
        }, [highlights, scrollHandler]
    );

    useEffect(() => {
        const listener = () => {
            scrollToHighlightFromHash(highlights)
        };

        window.addEventListener(
            "hashchange",
            listener,
            false
        );

        return () => {
            window.removeEventListener(
                "hashchange",
                listener,
                false
            )
        }
    }, [scrollToHighlightFromHash]);

    const HighlightPopup = ({comment}) =>
        comment.text ? (
            <div className="Highlight__popup">
                {comment.emoji} {comment.text}
            </div>
        ) : null;

    const getHighlightById = (id, highlights) => {
        return highlights.find(highlight => highlight.id === id)
    };

    const resetHash = () => {
        document.location.hash = "";
    };

    const resetHighlights = () => {
        const confirm = window.confirm('Вы действительно хотите очистить список заметок?');
        if (confirm)
            setHighlight([]);
    };

    const getNextId = () => String(Math.random()).slice(2);


    const updateHighlight = (highlightId, position, content) => {
        setHighlight(
            highlights.map(h => {
                return h.id === highlightId
                    ? {
                        ...h,
                        position: {...h.position, ...position},
                        content: {...h.content, ...content}
                    }
                    : h;
            })
        );
    };

    const parseIdFromHash = () =>
        document.location.hash.slice("#highlight-".length);

    if (error) {
        if (error.status === 403) {
            return (
                <Result
                    status="error"
                    title="Ошибка загрузки"
                    subTitle="Доступ запрещен"
                    extra={<Link to={'/'}><Button type="primary">На главную</Button></Link>}
                />
            )
        } else {
            return (
                <Result
                    status="error"
                    title="Ошибка загрузки"
                    subTitle="Неизвестная ошибка"
                    extra={<Link to={'/'}><Button type="primary">На главную</Button></Link>}
                />
            )
        }
    }

    if (!bookUrl) {
        return <SpinnerOuter/>
    }

    return (
        <div className={'d-flex'} style={{height: "100vh"}}>

            {sidebarShow &&
            <Sidebar
                highlights={highlights}
                resetHighlights={resetHighlights}
                deleteItem={(highlight) => deleteHighlight(highlight.id)}
            />
            }
            <div
                style={{
                    height: "100vh",
                    flex: 1,
                    position: "relative",
                    backgroundColor: "#6d6d6d"
                }}
            >
                <button
                    className={styles.switchSidebar}
                    onClick={() => setSidebarShow(!sidebarShow)}
                >
                    <img src={sidebarShow ? ICONS.LEFT_ARROW : ICONS.RIGHT_ARROW} alt=""/>
                </button>
                <PdfLoader url={bookUrl} beforeLoad={<SpinnerOuter/>}>
                    {pdfDocument => (
                        <PdfHighlighter
                            pdfDocument={pdfDocument}
                            selectedId={selectedHighlight}
                            enableAreaSelection={event => event.altKey}
                            onScrollChange={resetHash}
                            scrollRef={scrollTo => {
                                setScrollHandler({handler: scrollTo});
                                scrollToHighlightFromHash(highlights);
                            }}

                            /*-----------*/
                            onSelectionFinished={(
                                position,
                                content,
                                hideTipAndSelection,
                                transformSelection
                            ) => (
                                <Tip
                                    onOpen={transformSelection}
                                    onConfirm={comment => {
                                        addHighlight({content, position, comment, id: getNextId()});
                                        hideTipAndSelection();
                                    }}
                                />
                            )}

                            /*-----------*/
                            highlightTransform={(
                                highlight,
                                index,
                                setTip,
                                hideTip,
                                viewportToScaled,
                                screenshot,
                                isScrolledTo
                            ) => {

                                const isTextHighlight = !Boolean(
                                    highlight.content && highlight.content.image && highlight.comment && highlight.position
                                );

                                const component = isTextHighlight ? (
                                    <Highlight
                                        isScrolledTo={isScrolledTo}
                                        position={highlight.position}
                                        comment={highlight.comment}
                                        setSelectedHighlight={setSelectedHighlight}
                                    />
                                ) : (
                                    <AreaHighlight
                                        highlight={highlight}
                                        selectedHighlight={selectedHighlight}
                                        setSelectedHighlight={setSelectedHighlight}
                                        onChange={boundingRect => {
                                            updateHighlight(
                                                highlight.id,
                                                {boundingRect: viewportToScaled(boundingRect)},
                                                {image: screenshot(boundingRect)}
                                            );
                                        }}
                                    />
                                );

                                return (
                                    <Popup
                                        popupContent={<HighlightPopup {...highlight} />}
                                        onMouseOver={popupContent => {
                                            setTip(highlight, () => popupContent)
                                        }}
                                        onMouseOut={hideTip}
                                        key={index}
                                        children={component}
                                    />
                                );
                            }}

                            /* ------- */
                            highlights={highlights}
                        />
                    )}
                </PdfLoader>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewerScene);