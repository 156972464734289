import { call, put, takeLatest } from "redux-saga/effects";
import { EXTERNAL_RESOURCES_REQUEST, START } from "../../../actions/action-types";
import { externalResourcesList } from "../../../../api/externalResources/externalResources";
import {
  externalResourcesRequestError,
  externalResourcesRequestSuccess
} from "../../../actions/externalResources/list";

export default function* externalResourcesListSagaWatcher() {
  yield takeLatest(`${EXTERNAL_RESOURCES_REQUEST}${START}`, externalResourcesListSagaWorker)
}

function* externalResourcesListSagaWorker() {
  try {
    const response = yield call(externalResourcesList);
    yield put(externalResourcesRequestSuccess(response.data));
  } catch (error) {
    yield put(externalResourcesRequestError(error));
  }
}
