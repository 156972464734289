import {combineReducers} from "redux";
import { accessCategoryFormReducer } from './form/accessCategoryFormReducer';
import { accessCategoryListReducer } from './list/accessCategoryListReducer';
import { accessCategoryCountReducer } from './count/accessCategoryCountReducer';
import { accessCategorySearchReducer } from './search/accessCategorySearchReducer';
import { accessCategoryLoaderReducer } from './loader/accessCategoryLoaderReducer';

export const accessCategoryReducer = combineReducers({
  count: accessCategoryCountReducer,
  list: accessCategoryListReducer,
  form: accessCategoryFormReducer,
  search: accessCategorySearchReducer,
  loader: accessCategoryLoaderReducer,
});