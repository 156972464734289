import { call, put, takeLatest } from "redux-saga/effects";
import { SHELF_DELETE_REQUEST, SHELF_SAVE_REQUEST, SHELVES_LIST_REQUEST, START } from "../../../actions/action-types";
import {
  shelvesListRequestError,
  shelvesListRequestStart,
  shelvesListRequestSuccess
} from "../../../actions/location/shelves/list";
import { addShelf, deleteShelf, editShelf, getShelvesList } from "../../../../api/location/shelves";
import { shelfSaveRequestError, shelfSaveRequestSuccess } from "../../../actions/location/shelves/save";
import { shelfDeleteRequestError, shelfDeleteRequestSuccess } from "../../../actions/location/shelves/delete";

export default function* shelvesListWatcher() {
  yield takeLatest(`${SHELVES_LIST_REQUEST}${START}`, shelvesListWorker);
  yield takeLatest(`${SHELF_SAVE_REQUEST}${START}`, shelfSaveWorker);
  yield takeLatest(`${SHELF_DELETE_REQUEST}${START}`, shelfDeleteWorker);
}

function* shelvesListWorker({ payload: rackId }) {
  try {
    const response = yield call(getShelvesList, rackId);
    yield put(shelvesListRequestSuccess(rackId, response.data));
  } catch (error) {
    yield put(shelvesListRequestError(error));
  }
}

function* shelfSaveWorker({payload: { rackId, data }}) {
  try {
    const response = yield call(data.id ? editShelf : addShelf, rackId, data);
    yield put(shelfSaveRequestSuccess(response.data));
    yield put(shelvesListRequestStart(rackId));
  } catch (error) {
    yield put(shelfSaveRequestError(error));
  }
}

function* shelfDeleteWorker({ payload: { rackId, shelfId }}) {
  try {
    const response = yield call(deleteShelf, rackId, shelfId);
    yield put(shelfDeleteRequestSuccess(response.data));
    yield put(shelvesListRequestStart(rackId));
  } catch (error) {
    yield put(shelfDeleteRequestError(error));
  }
}