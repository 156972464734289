import React, { useState } from 'react'
import T from 'prop-types'
import styles from '../../../Covers/List/Item/CoversListItem.module.css'
import classNames from 'classnames'
import moment from 'moment'
import { checkFileReadable } from '../../../../utils/checkFileReadable'
import { BASE_URL_WITHOUT_API } from '../../../../constants/api'
import { getFileFormat } from '../../../../utils/helpers/helpers'
import ModalWindow from '../../../ModalWindow'
import { Button } from 'antd'

const ReadingListItemFiles = ({ fileList, bookId, archived, archivelink }) => {
  
  const [modalData, setModalData] = useState(null)
  
  if (!fileList && !archivelink) {
    return <div>Нет файлов</div>
  }
  
  const popupType = (type) => {
    const pure_type = getFileFormat(type).toUpperCase();
      switch (pure_type) {
        case 'MP4':
        case 'WEBM':
        case '3GP':
        case 'MPEG':
        case 'AVI':
        case 'SWF':
        case 'WMV':
          return true;
        default:
          return null
    }
  }

  const closeModal = () => {
    const id = modalData.id
    const video = document.getElementById(`myVideo${modalData?.id}`)
    video.pause()
    setModalData(null)
  }

  return (
    <div className={classNames(styles.filesOpen)}>
      <div className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
        <div>
          <b>Название</b>
        </div>
        <div>
          <b>Дата загрузки</b>
        </div>
      </div>
      {archived ? (
        <div className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
          <div>{archivelink}</div>
          <div></div>
          <div>
            <a

              href={`${BASE_URL_WITHOUT_API}/book/${bookId}/${archivelink}`}
              target='_blank'
              className={styles.button}
            >
              Скачать
            </a>
          </div>
        </div>
      ) : (
        fileList.map((item, index) => (
          <div key={`fileListItem${index}`} className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
            <div>{item.filename}</div>
            <div>{moment(item.filedate).format('DD.MM.YYYY')}</div>
            
            {popupType(item.filename)
              ? <Button onClick={() => setModalData(item)}>Смотреть видео</Button>
              : <div>{checkFileReadable(item, bookId)}</div>
            }
            
          </div>
        ))
      )}

      <ModalWindow
        title={`Просмотр видео`}
        isOpen={!!modalData}
        onRequestClose={closeModal}
        width='1050px'
        zIndex={10000}
      >
        <video
          width='1000'
          src={`${BASE_URL_WITHOUT_API}/api/book/${bookId}/file/${modalData?.id}`}
          // onPlaying={savePlayingToOpenHistory}
          id={`myVideo${modalData?.id}`}
          controls
          autoPlay
        />
      </ModalWindow>
    </div>
  )
}

ReadingListItemFiles.propTypes = {
  fileList: T.array,
  bookId: T.string,
  archived: T.bool,
  archivelink: T.string,
}

export default ReadingListItemFiles
