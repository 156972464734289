import { BOOK_ORDER_LIST_REQUEST, START, SUCCESS, ERROR } from '../../action-types'

export const documentBookListRequestStart = documentId => ({
  type: `${BOOK_ORDER_LIST_REQUEST}${START}`,
  payload: documentId,
})

export const documentBookListRequestSuccess = data => ({
  type: `${BOOK_ORDER_LIST_REQUEST}${SUCCESS}`,
  payload: data,
})

export const documentBookListRequestError = data => ({
  type: `${BOOK_ORDER_LIST_REQUEST}${ERROR}`,
  payload: data,
})
