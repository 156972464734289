import React, { useRef } from 'react';
import T from 'prop-types';
import styles from "../AuthorModal.module.css";
import {OverlayTrigger} from "react-bootstrap";
import ModalImagePopover from "../../../Modal/ImagePopover";
import ModalImage from "../../../Modal/ImageWrapper";

const ImageContainer = ({ onDeleteImage, onLoadImage, temporaryUrl, imgUrl, photosize }) => {
  const imageInput = useRef(null);

  const onSaveImage = () => {
    imageInput.current.click();
  };

  return (
    <div className={styles.imageContainer}>
      <OverlayTrigger
        trigger="click"
        placement="right"
        overlay={props => (
          <ModalImagePopover
            deleteImage={onDeleteImage}
            uploadImage={onSaveImage}
            {...props}
          />
        )}
      >
        {temporaryUrl || photosize ? (
          <ModalImage
            url={
              temporaryUrl
                ? URL.createObjectURL(temporaryUrl)
                : imgUrl
            }
          />
        ) : (
          <ModalImage placeholder />
        )}
      </OverlayTrigger>
      <input
        type="file"
        className={styles.fileInput}
        ref={imageInput}
        onChange={onLoadImage}
        accept="image/*"
      />
    </div>
  );
};

ImageContainer.propTypes = {
  onDeleteImage: T.func.isRequired,
  onLoadImage: T.func.isRequired,
  temporaryUrl: T.string,
  imgUrl: T.string,
  photosize: T.any
};

export default ImageContainer;