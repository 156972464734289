import { AUTH_REQUEST, RESET_AUTH_DATA, START, SUCCESS, ERROR, CAPTCHA_GET, CAPTCHA_SET } from '../action-types'

export const authRequestStart = (username, password, captcha, captcha_id) => ({
  type: `${AUTH_REQUEST}${START}`,
  payload: { username, password, captcha, captcha_id },
})

export const authRequestSuccess = data => ({
  type: `${AUTH_REQUEST}${SUCCESS}`,
  payload: data,
})

export const authRequestError = data => ({
  type: `${AUTH_REQUEST}${ERROR}`,
  payload: data,
})

export const getCaptcha = () => ({
  type: `${CAPTCHA_GET}`,
})

export const setCaptcha = (data) => ({
  type: `${CAPTCHA_SET}`,
  payload: data,
})

export const resetAuthData = () => ({
  type: RESET_AUTH_DATA,
})
