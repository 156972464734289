import { combineReducers } from "redux";
import { authorsCountReducer } from "./count/authorsCountReducer";
import { authorsListReducer } from "./list/authorsListReducer";
import { authorsSearchDataReducer } from "./search/authorsSearchDataReducer";
import { authorImageReducer } from "./image/authorImageReducer";
import { setAuthorImageReducer } from "./image/setAuthorImageReducer";
import {authorsLoaderReducer} from "./loader/authorsLoaderReducer";

export const authorsReducer = combineReducers({
  count: authorsCountReducer,
  list: authorsListReducer,
  loader: authorsLoaderReducer,
  search: authorsSearchDataReducer,
  image: authorImageReducer,
  setImage: setAuthorImageReducer
});
