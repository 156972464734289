import { DOCUMENT_TYPES_ALL_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const documentTypesAllRequestStart = () => ({
  type: `${DOCUMENT_TYPES_ALL_REQUEST}${START}`
});

export const documentTypesAllRequestSuccess = (data) => ({
  type: `${DOCUMENT_TYPES_ALL_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentTypesAllRequestError = (data) => ({
  type: `${DOCUMENT_TYPES_ALL_REQUEST}${ERROR}`,
  payload: data
});
