import { call, put, takeLeading } from "redux-saga/effects";
import { RUBRICATOR_ITEM_REQUEST, START } from "../../../actions/action-types";
import { rubricatorItemRequestError, rubricatorItemRequestSuccess } from "../../../actions/rubricator/item";
import { rubricatorItem } from "../../../../api/rubricator/rubricator";

export default function* rubricatorParentItemWatcher() {
  yield takeLeading(`${RUBRICATOR_ITEM_REQUEST}${START}`, rubricatorParentItemWorker)
}

function* rubricatorParentItemWorker({ payload: id }) {
  try {
    const response = yield call(rubricatorItem, id);
    yield put(rubricatorItemRequestSuccess(response.data));
  } catch (error) {
    yield put(rubricatorItemRequestError(error));
  }
}