import { BOOK_OPEN_HISTORY_LIST, START, SUCCESS, ERROR } from "../action-types";

export const bookOpenHistoryListRequestStart = (mid) => ({
    type: `${BOOK_OPEN_HISTORY_LIST}${START}`,
    payload: mid
});

export const bookOpenHistoryListRequestSuccess = (response) => ({
    type: `${BOOK_OPEN_HISTORY_LIST}${SUCCESS}`,
    payload: response
});

export const bookOpenHistoryListRequestError = (error) => ({
    type: `${BOOK_OPEN_HISTORY_LIST}${ERROR}`,
    payload: error
});
