import { SET_SEARCH_AUDIT_SEARCH_DATA, SET_SEARCH_AUDIT_MID } from '../action-types'

export const searchAuditSearchData = data => {
  return {
    type: SET_SEARCH_AUDIT_SEARCH_DATA,
    payload: data,
  }
}

export const searchAuditSetUserMid = userMid => {
  return {
    type: SET_SEARCH_AUDIT_MID,
    payload: userMid,
  }
}
