import React, {useState, useEffect} from 'react';
import T from 'prop-types';
import QRCode from "qrcode.react";
import styles from "../LibraryCardPass.module.css";
import {getImageData} from "../../../../../utils/resolveUserPhoto";


const frontHeader = require('../../../../../img/reader_pass_front_header.png');
const frontBackground = require('../../../../../img/mo_reader_pass.png');

const FrontSide = ({data, refItem}) => {
    const {lastname, firstname, patronymic, displayname, mid} = data;
    const [userAvatar, setUserAvatar] = useState('');


    useEffect(() => {
        getImageData(mid)
            .then((data) => {

                const b64Data = btoa(
                    new Uint8Array(data.data).reduce(
                        (dataArray, byte) => {
                            return dataArray + String.fromCharCode(byte);
                        },
                        ''
                    )
                );
                const userAvatarData = `data:image/png;base64,${b64Data}`;
                setUserAvatar(userAvatarData);
            });
    }, []);

    return (
        <div className={styles.container} ref={refItem}>
            <div className={styles.front_header}>
                <img src={frontHeader} alt="" className={styles.front_header_background}/>
                <div className={styles.front_header_title}>Министерство обороны Российской Федерации</div>
                <div className={styles.front_header_label}>Изделие 83т505</div>
            </div>
            <div
                className={`${styles.main_content} d-flex justify-content-between`}
                style={{
                    backgroundImage: "url(" + frontBackground + ")"
                }}
            >
                <div className={styles.front_intitution}>
                    ВОЕННО-КОСМИЧЕСКАЯ АКАДЕМИЯ ИМЕНИ А.Ф.МОЖАЙСКОГО
                </div>
                <div>
                    <div className={styles.front_holderName}>
                        <div>{lastname}</div>
                        <div>{firstname}</div>
                        <div>{patronymic}</div>
                    </div>
                    <div className={styles.front_qrCode}>
                        <QRCode value={`${mid};${displayname}`} renderAs={'svg'} width={134} height={134}/>
                    </div>
                </div>
                <div>
                    <div className="d-flex justify-content-end">
                        <div className={styles.photo}
                             style={{
                                 backgroundImage: "url(" + userAvatar + ")"
                             }}
                        >
                        </div>
                    </div>
                    <div className={styles.front_passNumber}>
                        Читательский билет №<span>{mid}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

FrontSide.propTypes = {
    data: T.object
};

export default FrontSide;