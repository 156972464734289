import { ERROR, RUBRICATOR_DELETE_REQUEST, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const rubricatorDeleteRequestStart = (data) => ({
  type: `${RUBRICATOR_DELETE_REQUEST}${START}`,
  payload: data
});

export const rubricatorDeleteRequestSuccess = (data) => {
  createNotification(
    'success',
    `Удалено`
  );
  return {
  type: `${RUBRICATOR_DELETE_REQUEST}${SUCCESS}`,
  payload: data
  };
};


export const rubricatorDeleteRequestError = (error) => {
  createNotification(
    'error',
    (error.response.data.result) ? error.response.data.result : `Ошибка удаления` 
  );
    console.log(error)
  return {
    type: `${RUBRICATOR_DELETE_REQUEST}${ERROR}`,
    payload: error
  };
};
