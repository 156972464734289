import { ERROR, INSTITUTIONS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const institutionsListRequestStart = () => ({
  type: `${INSTITUTIONS_LIST_REQUEST}${START}`
});

export const institutionsListRequestSuccess = (data) => ({
  type: `${INSTITUTIONS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const institutionsListRequestError = (data) => ({
  type: `${INSTITUTIONS_LIST_REQUEST}${ERROR}`,
  payload: data
});

