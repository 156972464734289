import { call, put, select, takeLatest } from 'redux-saga/effects'
import { LOST_DOC_DOCUMENT_CREATE_REQUEST, LOST_DOC_DOCUMENT_UPDATE_REQUEST, LOST_DOC_DOCUMENTS_REQUEST, LOST_DOC_GET_REQUEST, START } from '../../actions/action-types'
import { lostDocSuccess, lostDocStart } from '../../actions/notebookLostDoc/getLostDoc';
import {  getLostDoc } from '../../../api/notebookLostDoc/notebookLostDoc'
import { addDocument, editDocument } from '../../../api/documents/documents'
import {  createLostDocumentRequestSuccess } from '../../actions/notebookLostDoc/create';
import { documentsListRequestSuccess } from '../../actions/documents/list/action';
import { documentSaveRequestSuccess } from '../../actions/documents/documents/save';
// import { documentsReduxActions } from '../../actions/documents/documents/actions';
import { setLatestDocument } from '../../actions/documents/documents/latestDocument';
import { getDocumentBooksList } from '../../../api/documents/bookToDocument';
import { documentBookListRequestSuccess } from '../../actions/documents/books/list';
import { documentsReduxActions } from '../../actions/documents/documents/actions';


export default function* lostDocDocumentsWatcher() {
  // yield takeLatest(`${LOST_DOC_GET_REQUEST}${START}`, getWorker)
  yield takeLatest(`${LOST_DOC_DOCUMENTS_REQUEST}${START}`, getDocumentsWorker)
  yield takeLatest(`${LOST_DOC_DOCUMENT_CREATE_REQUEST}${START}`, createDocumentWorker)
  yield takeLatest(`${LOST_DOC_DOCUMENT_UPDATE_REQUEST}${START}`, updateDocumentWorker)

}

function* getDocumentsWorker ({ payload: params  }) {
  try {
    const response = yield call(getLostDoc, params)
    yield put(lostDocSuccess(response.data || []))
  } catch (error) {
  }
}

function* createDocumentWorker ({payload:data}) {
  try {
    const docList = yield select(({ lostBooks }) => lostBooks.actsData ) || []
    const {write_off_act, receipt_act} = docList;
    const acceptanceMode = write_off_act !== null && !receipt_act;
    // const addResponse = yield call(addDocument, payload)
    const addResponse= yield call(data.id ? editDocument : addDocument, data)
    const bindPayload = {
      write_off_act: acceptanceMode ? write_off_act : addResponse.data.id,
      receipt_act: acceptanceMode ? addResponse.data.id : null  
    }
    // response2 - для ПДФ таблички
    const response2 = yield call(getDocumentBooksList, addResponse.data.id )
    console.log(response2)
    // yield put(documentsReduxActions.countRequestStart())
    yield put(documentSaveRequestSuccess(addResponse.data))
    yield put(setLatestDocument(addResponse.data))
    yield put(createLostDocumentRequestSuccess(bindPayload))
    yield put(documentBookListRequestSuccess(response2.data))
    yield put(lostDocStart())
  } catch (error) {
    console.log(error)
  }
}

function* updateDocumentWorker ({payload}) {
    try {
      yield call(editDocument, payload)
      yield put(documentsListRequestSuccess(payload))
    yield put(lostDocStart())


    } catch (error) {
        console.log(error)
    }
  }