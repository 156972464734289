import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import styles from './DataTableCol.module.css'
import T from 'prop-types'

const TIME_WITHOUT_FORMATING = 'eventtime'

const DataTableCol = ({
  isHeader,
  title,
  value,
  item,
  className,
  colSpan,
  resolver,
  onClick,
  sortBy,
  passClicks,
  sortParams,
  rowWidth,
  hideChecks,
  setModalData,
  column,
  type,
  key,
  fontSize,
}) => {
  const isActive = sortParams ? sortParams[0] === sortBy : sortBy && sortBy === value

  if (passClicks && hideChecks) return null

  const handleClick = () => {
    if (onClick && !passClicks) {
      onClick(item);
    } else if (type == 'anti') {
      setModalData(item);
    }
  }

  return isHeader ? (
    <th
      className={classNames(className, { [styles.isActive]: isActive })}
      colSpan={colSpan}
      onClick={() => onClick && !passClicks && onClick(...(sortParams ? sortParams : [value]))}
      style={{ width: rowWidth, fontSize: fontSize }}
      key={key}
    >
      {title}
    </th>
  ) : (
    <td
      className={classNames(className, { [styles.isActive]: isActive })}
      colSpan={colSpan}
      onClick={() => handleClick()}
      style={{ fontSize: fontSize }}
      key={key}
    >
      {resolver
        ? resolver({ ...item, column_index: column })
        : value === TIME_WITHOUT_FORMATING
        ? moment(item[value]).format('DD.MM.YYYY H:mm:ss')
        : item[value]}
    </td>
  )
}

DataTableCol.propTypes = {
  title: T.string,
  value: T.string,
  colSpan: T.number,
  resolver: T.func,
  passClicks: T.bool,
  sortParams: T.array,

  //Всё, что ниже, ставится автоматически
  isHeader: T.bool,
  item: T.any,
  className: T.string,
  onClick: T.func,
  column: T.number,
}

DataTableCol.defaultProps = {
  colSpan: 1,
}

export default DataTableCol
