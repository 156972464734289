import { call, put, takeLatest } from "redux-saga/effects";
import {
  BOOK_SUPPLY_LIST_REQUEST,
  START
} from "../../actions/action-types";
import {loadBookSupplyList} from "../../../api/bookSupply/bookSupply";
import {bookSupplyListRequestError, bookSupplyListRequestSuccess} from "../../actions/bookSupply/list";


export default function* bookSupplyWatcher() {
  yield takeLatest(`${BOOK_SUPPLY_LIST_REQUEST}${START}`, listWorker);
}

function* listWorker({ payload: periodId }) {
    try {
        const response = yield call(loadBookSupplyList, periodId);
        yield put(bookSupplyListRequestSuccess(response.data));
    } catch(error) {
        yield put(bookSupplyListRequestError(error));
    }
}