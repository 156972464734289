import { api } from '../../App'

export const invNumbersList = (formData, { maxResults, paginateFrom, sortBy }) => {
  const params = {
    maxResults,
    paginateFrom,
    sortBy,
  }

  return api.post('/searchinstance', formData, { params })
}

export const invNumbersCount = formData => {
  const params = {
    count: true,
  }

  return api.post('/searchinstance', formData, { params })
}

export const addInvNumber = (bookId, number, copynumber) => {
  return api.post(`/book/instance/${bookId}`, { number, copynumber })
}

export const editInvNumber = (instanceId, number, rfidcode, copynumber, edit) => {
  return api.put(`/book/instance/${instanceId}${edit?'?edit=true': ''}`, { number, rfidcode, copynumber })
}

export const deleteInvNumber = number => {
  return api.delete(`/book/instance/${number}`)
}
