import {BOOK_SECRET_DOC_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const listReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${BOOK_SECRET_DOC_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};