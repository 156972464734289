import React from 'react';
import T from 'prop-types';
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";
import {ACCESS_CATEGORIES_SORT} from '../../../../constants/sort/accessCategoriesSort';


const SeriesPermissionsDataTable = ({data, sortBy, setSort, onChange}) => {
    return (
        <div className={'mt-2'}>
            <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
                <DataTableCol sortParams={[ACCESS_CATEGORIES_SORT.NAME]} title="Название серии" value="series"
                              colSpan={3}/>
                <DataTableCol sortParams={[ACCESS_CATEGORIES_SORT.GROUP]} title="роль/ группа доступа" value="groupname" colSpan={4}/>
                <DataTableCol sortParams={[ACCESS_CATEGORIES_SORT.ACCESS]} title="Значение" resolver={(item) =>
                    <select value={item.access} onChange={(e) => onChange(e, item)}>
                        <option value="">Нет</option>
                        <option value="Чтение">Чтение</option>
                    </select>
                } colSpan={1}/>
            </DataTable>
        </div>
    );
};

SeriesPermissionsDataTable.propTypes = {
    data: T.array.isRequired,
    sortBy: T.func.isRequired,
    setSort: T.func.isRequired,
    onChange: T.func.isRequired,
};

export default SeriesPermissionsDataTable;
