import { api } from "../../App";
import {
  START,
  SUCCESS,
  EXPERT_OPINION_SAVE_REQUEST,
  EXPERT_OPINION_LIST_REQUEST,
  EXPERT_OPINION_DEL_REQUEST,
  EXPERT_OPINION_EDIT_REQUEST
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import { combineReducers } from "redux";

const saveExpertopinion = ({ file, id }) => {
  const formData = new FormData();
  formData.append("expertopinion", file);

  return api.post(`bookexpertopinion/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const getExpertOpinion = id => {
  return api.get(`bookexpertopinion/${id}`);
};

const delExpertOpinion = id => {
  return api.delete(`bookexpertopinion/${id}`);
};

const editExpertOpinion = (id, data) => {
  return api.put(`bookexpertopinion/${id}`, data);
};

export const expertOpinionActions = {
  ...createSimpleActionsForReducer(
    "saveExpertopinion",
    EXPERT_OPINION_SAVE_REQUEST
  ),
  ...createSimpleActionsForReducer("delete", EXPERT_OPINION_DEL_REQUEST),
  ...createSimpleActionsForReducer("list", EXPERT_OPINION_LIST_REQUEST),
  ...createSimpleActionsForReducer("edit", EXPERT_OPINION_EDIT_REQUEST)
};

export const expertOpinionReducers = combineReducers({
  list: createSimpleReducer([], `${EXPERT_OPINION_LIST_REQUEST}${SUCCESS}`)
});

export function* expertOpinionWatcher() {
  yield takeLatest(`${EXPERT_OPINION_SAVE_REQUEST}${START}`, saveImgSaga);
  yield takeLatest(`${EXPERT_OPINION_DEL_REQUEST}${START}`, delImgSaga);
  yield takeLatest(`${EXPERT_OPINION_LIST_REQUEST}${START}`, listSaga);
  yield takeLatest(`${EXPERT_OPINION_EDIT_REQUEST}${START}`, editSaga);
}

function* delImgSaga(data) {
  try {
    yield call(delExpertOpinion, data.payload.selectedId);
    yield put(expertOpinionActions.listRequestStart(data.payload.bookId));
  } catch (e) {
    console.log(e);
  }
}

function* saveImgSaga(data) {
  try {
    const response = yield call(saveExpertopinion, data.payload);
    yield put(expertOpinionActions.listRequestStart(response.data.bookId));
  } catch (e) {
    console.log(e);
  }
}

function* editSaga({ payload }) {
  try {
    const response = yield call(editExpertOpinion, payload.id, payload.data);
    yield put(expertOpinionActions.listRequestStart(response.data.bookId));
  } catch (e) {
    console.log(e);
  }
}

function* listSaga(data) {
  try {
    const response = yield call(getExpertOpinion, data.payload);
    yield put(expertOpinionActions.listRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}
