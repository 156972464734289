import React from 'react'
import T from 'prop-types'
import styles from './LibraryTypesTree.module.css'
import { ADD, EDIT } from '../../../constants/modalTypes'
import { Button, Divider, Space } from 'antd'
import { Table, Radio } from 'antd';

const LibraryTypesTree = ({ initialFormFields, data, setModal, handleSelect, onDelete, selectedId }) => {
  
  const openEditModal = item => setModal({ modalType: EDIT, data: item })

  const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields })

  const normolizeKeyNames = data.map(el => (
    {
      name: <div onClick={() => openEditModal(data.filter(_el => _el.id === el.id)[0])}>{el.name} ({el.amount})</div>, 
      key: Number(el.id), 
      parent: el.parent,
      amount: el.amount
    }
    ))

  const

  treeD = l => l
    .reduce ((a, c) => {
      c.children = l.filter (i => i.parent == c.key).length && l.filter (i => i.parent == c.key) 
      a.push (c)
      return a
    }, [])
    // Шифр
    .filter (i => i.parent == 0)

    const columns = [
      {
        title: 'Типы обрабатываемой литературы',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Количество в библиотеке',
        dataIndex: 'amount',
        key: 'amount',
        width: '20%',
      },
    ];


    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        handleSelect(selectedRowKeys)
      },
    };

  return (
    <div className={styles.container}>
      <div className='mb-3'>
        <Space>
          <Button type='primary' onClick={openCreationModal}>
            добавить
          </Button>
          <Button type='primary' onClick={onDelete} danger>
            удалить
          </Button>
        </Space>
      </div>
        <Table 
          columns={columns} 
          dataSource={treeD(normolizeKeyNames)}
          pagination={false}
          rowSelection={{ type: 'radio', ...rowSelection }} 
          
          />
    </div>
  )
}

LibraryTypesTree.propTypes = {
  data: T.array,
  setModal: T.func,
  selectedId: T.any,
  handleSelect: T.func,
  onDelete: T.func,
  initialFormFields: T.object.isRequired,
}

export default LibraryTypesTree
