import { ERROR, START, SUCCESS, DIRECTORY_SAVE_REQUEST } from '../action-types'

export const directorySaveRequestStart = data => ({
  type: `${DIRECTORY_SAVE_REQUEST}${START}`,
  payload: data,
})

export const directorySaveRequestSuccess = data => ({
  type: `${DIRECTORY_SAVE_REQUEST}${SUCCESS}`,
  payload: data,
})

export const directorySaveRequestError = data => ({
  type: `${DIRECTORY_SAVE_REQUEST}${ERROR}`,
  payload: data,
})
