import React from 'react';
import PropTypes from 'prop-types';
import styles from './Sidebar.module.css'
import classNames from 'classnames';
import Button from "../../../components/Button";
import {ICONS} from "../../../utils/projectIcons";


const Sidebar = ({ highlights, resetHighlights, deleteItem }) => {

    const updateHash = highlight => {
        document.location.hash = `highlight-${highlight.id}`;
    };


    return (
        <div className={styles.sidebar} style={{ width: "25vw" }}>
            <div className={styles.description}>
                <h3 style={{ marginBottom: ".5rem" }}>Ваши заметки</h3>

                <small>
                    Для создания заметки выделите текст или зажмите Alt и выделите область мышью
                </small>
            </div>

            <ul className={styles.highlights}>
                {highlights.map((highlight, index) =>  (
                    <li
                        key={index}
                        className={styles.highlight}
                        onClick={() => {
                            updateHash(highlight);
                        }}
                    >
                        <div className={'d-flex justify-content-between'}>
                            <strong>{highlight.comment.text}</strong>
                            <button
                                title="Удалить элемент"
                                className={classNames("bg_red iconButton")}
                                onClick={() => deleteItem(highlight)}
                            >
                                <img src={ICONS.CANCEL} alt=""/>
                            </button>
                        </div>
                        <div>
                            {highlight.content.text &&
                            <blockquote style={{ marginTop: "0.5rem" }}>
                                {`${highlight.content.text.slice(0, 90).trim()}…`}
                            </blockquote>
                            }
                            {highlight.content.image &&
                            <div
                                className={styles.highlight__image}
                                style={{ marginTop: "0.5rem" }}
                            >
                                <img src={highlight.content.image} alt={"Screenshot"} />
                            </div>
                            }
                        </div>
                        <div className="highlight__location">
                            Страница {highlight.position.pageNumber}
                        </div>
                    </li>
                ))}
            </ul>
            {!!highlights.length &&
            <div className={'d-flex justify-content-end'}>
                <Button label="Очистить заметки" onClick={resetHighlights} />
            </div>
            }
        </div>
    );
};

Sidebar.propTypes = {};

export default Sidebar;