import { MILITARY_RANK_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const militaryRankRequestStart = () => ({
  type: `${MILITARY_RANK_REQUEST}${START}`
});

export const militaryRankRequestSuccess = (data) => ({
  type: `${MILITARY_RANK_REQUEST}${SUCCESS}`,
  payload: data
});

export const militaryRankRequestError = (data) => ({
  type: `${MILITARY_RANK_REQUEST}${ERROR}`,
  payload: data
});