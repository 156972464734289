import { api } from '../../App'
import { SORT_DIRECTION } from '../../constants/sortDirections'

export const institutionsList = ({ sortBy, maxResults, paginateFrom, query, sortDirection }) => {
  const params = {
    maxResults,
    paginateFrom,
    search: query,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
  }

  return api.get('/xp_client', { params })
}

export const institutionsCount = search => {
  const params = {
    count: true,
    search,
  }

  return api.get('/xp_client', { params })
}

export const institutionsListAll = search => {
  return api.get('/xp_client', { params: { search } })
}

export const institutionAdd = data => {
  return api.post('/xp_client/', data)
}

export const institutionEdit = data => {
  return api.put(`/xp_client/${data.idclient}`, data)
}

export const institutionDelete = id => {
  return api.delete(`/xp_client/${id}`)
}
