import React from 'react';
import T from 'prop-types';
import {ADD, EDIT} from "../../../constants/modalTypes";
import styles from "./AuthorsTable.module.css";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import Button from '../../Button';


const AuthorsTable = ({initialFormFields, data, setModal, sortBy, setSort, selectedItem, handleSelect, onDelete}) => {
    const openEditModal = (item) => {
        setModal({modalType: EDIT, data: item})
    };

    const openCreationModal = () => setModal({modalType: ADD, data: initialFormFields});

    return (
        <div className={styles.container}>
            <Button colorType={'blue'} onClick={openCreationModal} label='Добавить'/>
            <Button colorType={'red'} onClick={onDelete} label='Удалить' style={'ml-2'}/>
            <DataTable
                data={data}
                sortBy={sortBy}
                onHeadClick={setSort}
                onCellClick={openEditModal}
            >
                <DataTableCol
                    title="ФИО Автора"
                    value="displayname"
                    className={styles.leftAlign}
                    colSpan={4}
                />
                <DataTableCol title="Авторский знак" value="authorsign"/>
                <DataTableCol title="Книг на учёте" value="authornumbooks"/>
                <DataTableCol
                    title="Выбрано"
                    passClicks
                    resolver={item => (
                        <input
                            type="checkbox"
                            checked={item.id === selectedItem.id}
                            onChange={() => handleSelect(item)}
                        />
                    )}
                />
            </DataTable>
        </div>
    );
};

AuthorsTable.propTypes = {
    data: T.array,
    setModal: T.func,
    sortBy: T.string,
    setSort: T.func,
    selectedId: T.string,
    handleSelect: T.func,
    onDelete: T.func,
    initialFormFields: T.object
};

AuthorsTable.defaultProps = {
    data: []
};

export default AuthorsTable;
