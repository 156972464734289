import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  INV_NUMBER_SAVE_REQUEST,
  INV_NUMBER_DELETE_REQUEST,
  INV_NUMBERS_COUNT_REQUEST,
  INV_NUMBERS_LIST_REQUEST,
  START,
} from '../../actions/action-types'
import { addInvNumber, deleteInvNumber, editInvNumber, invNumbersCount, invNumbersList } from '../../../api/invNumbers/invNumbers'
import {
  invNumbersCountRequestError,
  invNumbersCountRequestStart,
  invNumbersCountRequestSuccess,
} from '../../actions/invNumbers/count'
import { setInvNumbersSearchData } from '../../actions/invNumbers/search'
import {
  invNumbersListRequestError,
  invNumbersListRequestStart,
  invNumbersListRequestSuccess,
} from '../../actions/invNumbers/list'
import { invNumberSaveRequestError, invNumberSaveRequestSuccess } from '../../actions/invNumbers/add'
import { invNumberDeleteRequestError, invNumberDeleteRequestSuccess } from '../../actions/invNumbers/delete'
import { ADD } from '../../../constants/modalTypes'

export default function* invNumbersWatcher() {
  yield takeLatest(`${INV_NUMBERS_COUNT_REQUEST}${START}`, invNumbersCountWorker)
  yield takeLatest(`${INV_NUMBERS_LIST_REQUEST}${START}`, invNumbersListWorker)
  yield takeLatest(`${INV_NUMBER_SAVE_REQUEST}${START}`, invNumberSaveWorker)
  yield takeLatest(`${INV_NUMBER_DELETE_REQUEST}${START}`, invNumberDeleteWorker)
}

function* invNumbersCountWorker() {
  const formData = yield select(({ invNumbers }) => invNumbers.form)
  const searchData = yield select(({ invNumbers }) => invNumbers.search)

  try {
    const response = yield call(invNumbersCount, formData)
    const count = parseInt(response.data.count, 10)
    yield put(invNumbersCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setInvNumbersSearchData({ ...searchData, pageCount }))
    yield put(invNumbersListRequestStart())
  } catch (error) {
    yield put(invNumbersCountRequestError(error))
  }
}

function* invNumbersListWorker() {
  const formData = yield select(({ invNumbers }) => invNumbers.form)
  const searchData = yield select(({ invNumbers }) => invNumbers.search)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)

  try {
    const response = yield call(invNumbersList, formData, searchData)
    yield put(invNumbersListRequestSuccess(response.data))
  } catch (error) {
    yield put(invNumbersListRequestError(error))
  }
}

function* invNumberSaveWorker({ payload: { data, modalType } }) {
  try {
    if (modalType === ADD) yield call(addInvNumber, data.bookId, data.number)
    else yield call(editInvNumber, data.instanceid, data.number, data.rfidcode, data.copynumber)
    yield put(invNumberSaveRequestSuccess(data))
    yield put(invNumbersCountRequestStart())
  } catch (error) {
    yield put(invNumberSaveRequestError(error))
  }
}

function* invNumberDeleteWorker({ payload: number }) {
  try {
    const response = yield call(deleteInvNumber, number)
    // yield put(invNumberDeleteRequestSuccess(response.data))
    yield put(invNumberDeleteRequestSuccess(response.data))

    if (!response.data.ERROR) {
      yield put(invNumbersCountRequestStart())
    }
  } catch (error) {
    yield put(invNumberDeleteRequestError(error))
  }
}
