import { RESULTS_PER_PAGE } from '../../../../constants/pagination'
import { SET_EXEMPT_TEST_SEARCH_DATA } from '../../../actions/action-types'
import moment from 'moment'

const initialState = {
  query: '',
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  from: moment().add(-10, 'days').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
}

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXEMPT_TEST_SEARCH_DATA:
      return { ...action.payload }

    default:
      return state
  }
}
