import {combineReducers} from "redux";
import {pdfViewerBookReducer} from "./book/pdfViewerBookReducer";
import {pdfViewerHighlightsReducer} from "./highlights/pdfViewerHighlightsReducer";
import {pdfViewerFileDataReducer} from "./fileData/pdfViewerFileDataReducer";
import {pdfViewerErrorReducer} from "./error/pdfVirwerErrorReducer";

export const pdfViewerReducer = combineReducers({
    book: pdfViewerBookReducer,
    highlights: pdfViewerHighlightsReducer,
    fileData: pdfViewerFileDataReducer,
    error: pdfViewerErrorReducer
});
