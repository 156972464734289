import React, { useEffect } from 'react'
import { api } from '../../App'

const NewBooksScene = ({ history }) => {
  const onCreateNewBook = () => {
    const params = {
      title: '',
      publisher: '',
      year: 0,
    }
    api.post('/book', params).then(response => {
      history.push(`/book/${response.data.book.id}/edit?newbook`)
    })
  }
  useEffect(onCreateNewBook)

  return <div></div>
}

export default NewBooksScene
