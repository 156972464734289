import React from 'react'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import Button from '../../../components/Button'
import styles from './AdminTable.module.css'
import styled from 'styled-components'
import { ADD, EDIT } from '../../../constants/modalTypes'

const ButtonE = (styled.button = `
  background: red;
  width: 100px;
  height: 40px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
`)

export const AdminGroupsTable = ({ data, selectedItem, setSelectedItem, deleteGroup, setModalVisible }) => {
  
  return (
    <>
      {/* <ButtonE>jhadfsadfjasdf</ButtonE> */}
      <Button label='Добавить' colorType='green' style='ml-2' onClick={() => setModalVisible(ADD)} />
      {selectedItem && (
        <Button
          label={'Удалить'}
          colorType={'red'}
          style={'ml-2'}
          onClick={() => {
            deleteGroup(selectedItem.gid)
          }}
        />
      )}
      <DataTable data={data} onCellClick={setModalVisible}>
        <DataTableCol title={'Название группы'} value='name' />
        <DataTableCol title={'Курс'} value='year' />
        <DataTableCol
          title='Выбрано'
          resolver={item => (
            <input type='checkbox' checked={item.gid === selectedItem?.gid} onChange={() => setSelectedItem(item)} />
          )}
        />
      </DataTable>
    </>
  )
}
