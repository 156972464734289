import {
  OUTSECRECY_LIST_REQUEST,
  SUCCESS
} from "../../../actions/action-types";

const initialState = [];

export const outsecrecyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${OUTSECRECY_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};
