import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import { litersCatalogCountRequestStart } from '../../../redux/actions/liters/count';
import { setLitersCatalogSearchData } from '../../../redux/actions/liters/search';
import { setLitersFormData } from '../../../redux/actions/liters/form';
import { litersCatalogAddRequestStart } from '../../../redux/actions/liters/add';
import { litersCatalogEditRequestStart } from '../../../redux/actions/liters/edit';
import { litersCatalogDeleteRequestStart } from '../../../redux/actions/liters/delete';
import LitersForm from "../../../components/Liters/Form";
import LitersList from "../../../components/Liters/List";
import LitersModal from "../../../components/Liters/Modal";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import Pagination from "../../../components/Pagination";
import Button from "../../../components/Button";
import { ADD, EDIT } from "../../../constants/modalTypes";

const mapStateToProps = ({ liters }) => ({
  formData: liters.form,
  list: liters.list,
  loader: liters.loader,
  count: liters.count,
  searchData: liters.search
});

const mapDispatchToProps = {
  requestList: litersCatalogCountRequestStart,
  setSearchData: setLitersCatalogSearchData,
  setFormData: setLitersFormData,
  addItem: litersCatalogAddRequestStart,
  editItem: litersCatalogEditRequestStart,
  deleteItem: litersCatalogDeleteRequestStart,
};


const LitersScene = ({
  requestList,
  setSearchData,
  setFormData,
  addItem,
  editItem,
  deleteItem,
  formData,
  list,
  loader,
  count,
  searchData,
}) => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    requestList();
  }, [requestList]);

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList();
  };

  const onSelectItem = (itemId) => {
    selectedItem === itemId
      ? setSelectedItem(null)
      : setSelectedItem(itemId);
  };

  const onAddItem = () => {
    setModalData({
      type: ADD,
      name: ''
    });
  };

  const isSelectedItemExist = () => {
    const findedItem = list.find(el => el.id === selectedItem);
    return !!findedItem;
  };

  const onDeleteItem = () => {
    if(selectedItem) {
      return deleteItem(selectedItem);
    }
    return window.alert('Не выделен элемент')
  };

  const onEditItem = () => {
    let itemInfo = list.find(el => el.id === selectedItem);

    setModalData({
      type: EDIT,
      name: itemInfo.name,
      item: selectedItem
    });
  };

  const closeModal = () => {
    setModalData(null);
  };

  const onSubmitModal = () => {
    switch (modalData.type) {
      case ADD:
        addItem(modalData);
        break;
      case EDIT:
        editItem(modalData);
        break;
      default:
        break;
    }

    closeModal();
  };

  return (
    <SceneContainer title={'Справочники / Литера'}>
      <LitersForm formData={formData} setFormData={setFormData} onSubmit={requestList}/>

      <div>
        <Button
          label={'Добавить'}
          colorType={'green'}
          onClick={onAddItem}
        />
        {isSelectedItemExist() && <>
          <Button
            label={'Редактировать'}
            onClick={onEditItem}
            style={'ml-2'}
          />
          <Button
            label={'Удалить'}
            colorType={'red'}
            onClick={onDeleteItem}
            style={'ml-2'}
          />
        </>}
      </div>

      {loader
        ? <SpinnerOuter isLoading={loader}/>
        : <>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>

          {modalData &&
            <LitersModal
              isOpen={!!modalData}
              closeModal={closeModal}
              data={modalData}
              setData={setModalData}
              onSubmit={onSubmitModal}
            />
          }

          <LitersList
            list={list}
            onSelectItem={onSelectItem}
            selectedItem={selectedItem}
          />

          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </>
      }


    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LitersScene);