import {LIBRARY_TYPES_LIST_REQUEST, SUCCESS} from "../../../../actions/action-types";


const initialState = [];

export const libraryTypesListReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${LIBRARY_TYPES_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};