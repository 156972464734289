import React from 'react'
import T from 'prop-types'
import ModalWindow from '../../ModalWindow'
import Button from '../../Button'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../Modal/InputWrapper'
import styles from './RequestToBookTransferModal.css'

const RequestToBookTransferModal = ({ isOpen, closeModal, modalData, setModalData, onSubmit }) => {
  const submitHandler = () => {
    const bookid = modalData.bookId
    const clientid = modalData.selectedId

    onSubmit({
      bookid,
      clientid,
    })
    closeModal()
  }

  return (
    <ModalWindow
      title={'Выберите организацию для заказа книги'}
      isOpen={isOpen}
      onRequestClose={closeModal}
      width={'500px'}
      height={'300px'}
    >
      <Row>
        <Col sm={12}>
          <ModalInputWrapper label={'Выберите учебную организацию'}>
            <select className={styles.select} onChange={e => setModalData({ ...modalData, selectedId: e.target.value })}>
              <option value=''>Выберите учебную организацию</option>
              {modalData.bookClientsArray.map((item, index) => (
                <option key={index} value={item.idclient}>
                  {item.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col sm={12} className={'d-flex justify-content-end'}>
          <Button label={'Заказать'} onClick={submitHandler} disabled={!modalData.selectedId} />
        </Col>
      </Row>
    </ModalWindow>
  )
}

RequestToBookTransferModal.propTypes = {
  isOpen: T.bool,
  closeModal: T.func,
  modalData: T.object,
  setModalData: T.func,
  onSubmit: T.func,
}

export default RequestToBookTransferModal
