import logo_zvezda from '../img/tv/tv_zvezda.png'
import logo_1 from '../img/tv/tv_1.png';
import logo_rus1 from '../img/tv/tv_rus1.png'
import logo_rus24 from '../img/tv/tv_rus24.png'
import logo_rt from '../img/tv/tv_rt.png';


export const tvChannels = [
    {
        name: "Звезда",
        streamURL: 'http://cbf84cff.iptvspy.net/iptv/LQ8ALQMU9F8FB4/544/index.m3u8',
        logo: logo_zvezda,
        active: false
    },
    {
        name: "Первый канал",
        streamURL: 'http://cbf84cff.iptvspy.net/iptv/LQ8ALQMU9F8FB4/204/index.m3u8',
        logo: logo_1,
        active: false
    },
    {
        name: "Россия",
        streamURL: 'http://cbf84cff.iptvspy.net/iptv/LQ8ALQMU9F8FB4/205/index.m3u8',
        logo: logo_rus1,
        active: false
    },
    {
        name: "Россия 24",
        streamURL: 'http://cbf84cff.iptvspy.net/iptv/LQ8ALQMU9F8FB4/507/index.m3u8',
        logo: logo_rus24,
        active: false
    },
    {
        name: "Russia Today",
        streamURL: 'http://cbf84cff.iptvspy.net/iptv/LQ8ALQMU9F8FB4/840/index.m3u8',
        logo: logo_rt,
        active: false
    }
];