import {SUCCESS, TRANSFER_LOG_LIST_REQUEST} from "../../../../actions/action-types";

const initialState = [];

export const listReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${TRANSFER_LOG_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};