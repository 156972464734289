import React from 'react';
import {connect} from "react-redux";
import moment from "moment";

import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import {reportGetRequestStart} from "../../../redux/actions/reports/get";
import AccountingJournalReportForm from "../../../components/Reports/AccountingJournal/Form";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import PDFPrint from "../../../components/PDFPrint";
import AccountingJournalTable from "../../../components/Reports/AccountingJournal/Table";


const mapStateToProps = ({reports}) => ({
    data: reports.report,
    loader: reports.loader
});

const mapDispatchToProps = {
    requestReport: reportGetRequestStart
};

const AccountingJournalReportScene = ({ownId, data, requestReport, loader}) => {

    const requestData = (formData) => {
        requestReport({
            id: ownId,
            formData
        });
    };

    return (
        <SceneContainer title="Отчеты / Журнал учета машинных носителей информации">
            <AccountingJournalReportForm onSubmit={requestData}/>

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                data[ownId] && !!data[ownId].length && <>
                    <div className={'d-flex justify-content-end mb-2'}>
                        <PDFPrint
                            tableData={data[ownId]}
                            title={"Журнал учета машинных носителей информации"}
                            orientation={"portrait"}
                            headers={[
                                {
                                    value: item => moment(item.eventtime).format('DD.MM.YYYY'),
                                    title: "Дата"
                                },{
                                    value: 'clientip',
                                    title: "IP адрес"
                                },{
                                    value: 'login',
                                    title: "Логин"
                                },{
                                    value: item => `${item.lastname} ${item.firstname} ${item.patronymic}`,
                                    title: "ФИО"
                                },{
                                    value: 'title',
                                    title: "Название"
                                },
                            ]}
                        />
                    </div>
                    <AccountingJournalTable data={data[ownId]}/>
                </>
            }

        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountingJournalReportScene);