import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {Table} from "antd";


const BookGivingStatsTable = ({data}) => {

    const {Column} = Table;

    return (
        <Table
            dataSource={data}
            title={() => "СТАТИСТИКА КНИГОВЫДАЧИ"}
            pagination={false}
            scroll={{ x: '100%' }}
            id='book-giving-stats'
        >
            <Column
                title="Дата"
                dataIndex="date"
                render={(text, record) => moment(text).format('DD.MM.YYYY')}
            />
            <Column title="Посещений" dataIndex="visitors" key="visitors"/>
            <Column title="Новых читателей" dataIndex="newreaders" key="newreaders"/>
            <Column title="Выдано экз." dataIndex="toreader" key="toreader"/>
            <Column title="Сдано экз." dataIndex="fromreader" key="fromreader"/>
            {data[0] && data[0].bookbyfunds.map((item, index) =>
                <Column
                    title={item.fundname}
                    dataindex="bookbyfunds"
                    render={(text, record) => record.bookbyfunds[index].count}
                />
            )}
        </Table>
    );
};

BookGivingStatsTable.propTypes = {
    data: PropTypes.array,
};

export default BookGivingStatsTable;