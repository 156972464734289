export const verifyStatusList = [
  {
    name: "Новая",
    value: 1,
  },
  {
    name: "Действующая",
    value: 2,
  },
  {
    name: "Удалена",
    value: 3,
  },
  {
    name: "Архив",
    value: 4,
  },
  {
    name: "Изъята",
    value: 5,
  },
  {
    name: "С обложкой",
    value: 6,
  }
];
