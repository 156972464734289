import {combineReducers} from "redux";
import {invNumbersCountReducer} from "./count/invNumbersCountReducer";
import {invNumbersListReducer} from "./list/invNumbersListReducer";
import {invNumbersFormReducer} from "./form/invNumbersFormReducer";
import {invNumbersSearchReducer} from "./search/invNumbersSearchReducer";
import {invNumbersLoaderReducer} from './loader/invNumbersLoaderReducer';
import {invNumbersLoadSuccessReducer} from "./loadSuccess/invNumbersLoadSuccessReducer";

export const invNumbersReducer = combineReducers({
    count: invNumbersCountReducer,
    list: invNumbersListReducer,
    form: invNumbersFormReducer,
    search: invNumbersSearchReducer,
    loader: invNumbersLoaderReducer,
    loadSuccess: invNumbersLoadSuccessReducer
});
