import { SET_DOCUMENT_TYPES_SEARCH_DATA } from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { DOCUMENT_TYPES_SORT } from "../../../../constants/sort/documentTypesSort";

const initialState = {
  query: "",
  page: 1,
  sortBy: DOCUMENT_TYPES_SORT.NAME,
  sortDirection: SORT_DIRECTION.ASC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const documentTypesSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_DOCUMENT_TYPES_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};
