import React, { useEffect, useState } from "react";
import "./EnvelopePrint.css";
import qs from "qs";
import QRCode from "qrcode.react";
import morf from "../../img/MORF.png";
import { connect } from "react-redux";
import { qrCodesRequestStart } from "../../redux/actions/qrcodes/list";
import { invNumbersCountRequestStart } from "../../redux/actions/invNumbers/count";
import { optionsLogoGetRequestStart } from "../../redux/actions/options/logo/get";

const mapStateToProps = state => ({
  qrCodes: state.qrCodes,
  list: state.invNumbers.list,
  name: state.options,
  logoUrl: state.options.logo
});

const matchDispatchToProps = dispatch => ({
  requestCodes: ids => dispatch(qrCodesRequestStart(ids)),
  requestList: () => dispatch(invNumbersCountRequestStart()),
  requestLogo: () => dispatch(optionsLogoGetRequestStart())
});

const EnvelopePrint = ({
  match,
  requestCodes,
  qrCodes,
  list,
  requestList,
  name,
  requestLogo,
  logoUrl
}) => {
  const [showQr, setShowQr] = useState(false);
  const [dataObj, setDataObj] = useState(null);

  const getDataObj = () => {
    setDataObj( 
      list.find(item => item.instanceid.toString() === match.params.id)
    );
  };
  useEffect(() => {
    requestLogo();
  }, [requestLogo]);
  useEffect(() => {
    requestList();
  }, [requestList]);
  useEffect(() => {
    !!list.length && getDataObj();
  }, [list]);
  useEffect(() => {
    requestCodes([match.params.id]);
  }, []);
  useEffect(() => {
    !!qrCodes.length > 0 && qrCodes[0].qrcode && setShowQr(true);
  }, [qrCodes]);
  useEffect(() => {
    showQr && dataObj && logoUrl && window.print();
  }, [showQr, dataObj, logoUrl]);

  return (
    <div className="envelope">
      <div className="envelopeTop">
        <div className="envelopeTopImg">
          <div className="envelopeTopImgLogo">
            {logoUrl && <img src={logoUrl} alt="logo" />}
          </div>
          <div className="envelopeTopImgGerb">
            <img src={morf} alt="gerb" />
          </div>
          <div className="envelopeTopImgQr">
            <div className="envelopeTopImgQrCode">
              {(showQr && (
                <QRCode
                  value={qrCodes[0].qrcode}
                  renderAs={"svg"}
                  className="envelopeTopImgQrCodeImg"
                />
              )) || <div className="envelopeTopImgQrCodeImg"></div>}
            </div>
            <div className="envelopeTopImgQrInv">
              {dataObj && <div>{dataObj.number}</div>}
            </div>
          </div>
        </div>
        <div className="envelopeTopName">
          {name && name.data && name.data.GOU_Name[1]}
        </div>
        <div className="envelopeTopTitle">
          {dataObj && <div>{dataObj.title}</div>}
        </div>
        <div className="envelopeTopAutors">
          {dataObj && <div>{dataObj.authors}</div>}
        </div>
      </div>
      <div className="envelopeBottomBox">
        <div className="envelopeBottomLeft"></div>
        <div className="envelopeBottom">
          <div className="envelopeBottomAlert">ВОЗВРАТИТЕ КНИГУ НЕ ПОЗЖЕ</div>
          <div className="envelopeBottomText">обозначенного здесь срока</div>
          <div className="envelopeBottomTable">
            <table>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </table>
          </div>
          <div className="envelopeBottomMin">Министерство обороны РФ</div>
        </div>
        <div className="envelopeBottomRight"></div>
      </div>
      <div className="envelopeBoxBottom"></div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(EnvelopePrint);
