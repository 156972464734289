import { call, put, takeLatest, select } from 'redux-saga/effects'
import { START, USERS_SAVE_REQUEST } from '../../../actions/action-types'
import { ADD, EDIT } from '../../../../constants/modalTypes'
import { addUser, changeUserPassword, editUser } from '../../../../api/adminUsers/users'
import { usersSaveRequestError, usersSaveRequestSuccess } from '../../../actions/adminUsers/save'
import { usersCountRequestStart } from '../../../actions/adminUsers/count'
import { usersSavePhotoRequestStart } from '../../../actions/adminUsers/photo/savePhoto'
import { usersPhotoReset } from '../../../actions/adminUsers/photo/resetPhoto'
import { createNotification } from '../../../../components/notification/notification'
import { DUPLICATE_USER_FOUND } from '../../../../constants/peopleErrorText'
import { USERS_SAVE_REQUEST_TOGGLE_LOADING } from '../../../actions/action-types'

export default function* saveUserWatcher() {
  yield takeLatest(`${USERS_SAVE_REQUEST}${START}`, saveUserWorker)
}

function* saveUserWorker({ payload }, callBack) {
  const { modalType: type, data, cb } = payload
  const userImage = yield select(({ adminUsers }) => adminUsers.photo)
  try {
    yield put({ type: USERS_SAVE_REQUEST_TOGGLE_LOADING })
    const response = yield call(type === ADD ? addUser : editUser, data)

    if (type === EDIT && data.password) {
      yield call(changeUserPassword, data.mid, data.password)
    }
    yield put(usersSaveRequestSuccess(response.data))
    yield put({ type: USERS_SAVE_REQUEST_TOGGLE_LOADING })

    yield call(createNotification, 'success', 'Пользователь успешно сохранен')
    yield put(usersPhotoReset())
    if (userImage) {
      yield put(usersSavePhotoRequestStart(userImage, response.data.mid))
    }
    yield put(usersCountRequestStart())
    if (cb) {
      yield call(cb)
    }
  } catch (error) {
    yield put(usersSaveRequestError(error))
    if (error.response.data.error === DUPLICATE_USER_FOUND) {
      yield call(createNotification, 'error', 'Такой пользователь уже существует')
    } else {
      yield call(createNotification, 'error', 'Пользователь не сохранен')
    }
  }
}
