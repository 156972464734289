import { BOOK_SUPPLY_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const bookSupplyBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${BOOK_SUPPLY_LIST_REQUEST}${SUCCESS}`:
            return action.payload["books"];

        default: return state;
    }
};