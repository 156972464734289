import React, { useState, useEffect, useMemo } from "react";
import T from "prop-types";
import styles from "./AuditLogForm.module.css";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import { api } from "../../../App";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const AuditLogForm = ({ data, setData, search, dataset }) => {
  
  const [users, setUsers] = useState([]);

  useEffect(() => {
    api.get("./people").then((data) => setUsers(data.data));
  }, []);


  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values)
      search()
    },
  });

  const { values, errors } = modalForm;

  const selectPeple = useMemo(
    () => (
      <ModalInputWrapper label="Имя пользователя">
        <select name="user" onChange={modalForm.handleChange} value={values["user"]}>
          <option value="">все</option>
          {users.map((item) => {
            return (
              <option value={item.mid} key={item.mid}>
                {item.displayname}
              </option>
            );
          })}
        </select>
      </ModalInputWrapper>
    ),
    [users, values, modalForm.handleChange]
  );

  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="from"
            value={values["from"]}
            label="С (дата)"
            type="date"
            error={errors["from"]}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="to"
            value={values["to"]}
            label="До (дата)"
            type="date"
            error={errors["to"]}
          />
        </Col>
        <Col md={12}>{selectPeple}</Col>

        <>
          <Col md={12}>
            <ModalInputWrapper
              onChange={modalForm.handleChange}
              name="clientip"
              value={values["clientip"]}
              label="IP-адрес"
              error={errors["clientip"]}
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={modalForm.handleChange}
              name="username"
              value={values["username"]}
              label="Логин"
              error={errors["username"]}
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={modalForm.handleChange}
              name="mid"
              value={values["mid"]}
              label="ID пользователя"
              error={errors["mid"]}
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper label="Запись">
              <select
                className={styles.select}
                onChange={modalForm.handleChange}
                name={"write"}
                value={values["write"]}
              >
                <option value={""}>Не выбрано</option>
                <option value={true}>Да</option>
                <option value={false}>Нет</option>
              </select>
            </ModalInputWrapper>
          </Col>
          <Col md={6}>
            <ModalInputWrapper label="Отказано в доступе">
              <select
                className={styles.select}
                onChange={modalForm.handleChange}
                name={"accessdenied"}
                value={values["accessdenied"]}
              >
                <option value={""}>Не выбрано</option>
                <option value={true}>Да</option>
                <option value={false}>Нет</option>
              </select>
            </ModalInputWrapper>
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={modalForm.handleChange}
              name="bookid"
              value={values["bookid"]}
              label="ID книги"
              error={errors["bookid"]}
            />
          </Col>
          <Col md={6}>
            <ModalInputWrapper
              onChange={modalForm.handleChange}
              name="affectedid"
              value={values["affectedid"]}
              label="ID сущности"
              error={errors["affectedid"]}
            />
          </Col>
        </>

        <Col md={12}>
          {!!dataset && (
            <ModalInputWrapper label="Датасет">
              <select
                className={styles.select}
                onChange={modalForm.handleChange}
                name={"dataset"}
                value={values["dataset"]}
              >
                <option value={""}>Не выбрано</option>
                {dataset.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          )}
        </Col>
        <Col md={12} className={"text-right"}>
          <Button label={"Найти"} onClick={modalForm.handleSubmit} />
        </Col>
      </Row>
    </div>
  );
};

AuditLogForm.propTypes = {
  data: T.object,
  setData: T.func,
  search: T.func,
  dataset: T.array,
};

export default AuditLogForm;
