import React, { useEffect } from 'react'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { useDispatch, useSelector } from 'react-redux'
import { mobileLibraryListRequestStart as listRequestStart, setMobileLibrarySearchData as setSearchData } from '../../redux/actions/mobileLibrary/library/list'
import Pagination from '../../components/Pagination'
import LibrariesTable from '../../components/MobileLibrary/list'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'



export default function MobileLybraryList () {
  
  const dispatch = useDispatch()
  const {search: searchData, count, loader, list} = useSelector(state => state.mobileLibrary)
  const history = useHistory()

  const {page, pageCount, limit} = searchData  

  useEffect(() => {
    dispatch(listRequestStart())
  }, [page])
  
  const setCurrentPage = page => {
    const offset = (page-1) * limit
    dispatch(setSearchData({...searchData, page, offset}))
  }

  const PaginationComponent = () => (
    <Pagination
      count={count}
      pageCount={pageCount}
      current={page}
      setCurrent={setCurrentPage}
    />
  )

  const libClickHandler = (libId) => {
    history.push(`/mobile_library/${libId}`)
  }

  return (
    <SceneContainer title='Передвижные библиотеки'>
      
      <Button type='primary' onClick={() => libClickHandler('add')} >Добавить</Button>

      <PaginationComponent/>
        {loader
          ? <SpinnerOuter isLoading={loader}/>
          : <LibrariesTable data={list} libClickHandler={libClickHandler} />
        }
      <PaginationComponent/>
    
    </SceneContainer>
  )
}
