import { SET_BOOK_ORDER_SEARCH_DATA } from '../../../actions/action-types'
import { SORT_DIRECTION } from '../../../../constants/sortDirections'
import { RESULTS_PER_PAGE } from '../../../../constants/pagination'
import { DOCUMENT_SORT } from '../../../../constants/sort/documentSort'

const initialState = {
  query: '',
  page: 1,
  documenttype: '',
  from: new Date(),
  to: new Date(),
  sortBy: DOCUMENT_SORT.NAME,
  sortDirection: SORT_DIRECTION.ASC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
}

export const booksOrderSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOK_ORDER_SEARCH_DATA:
      return action.payload

    default:
      return state
  }
}
