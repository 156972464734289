import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import Pagination from "../../components/Pagination";
import {bookreserveCountRequestStart} from "../../redux/actions/bookreserve/count";
import {setBookreserveSearchData} from "../../redux/actions/bookreserve/search";
import BookReserveTable from "../../components/BookReserve/Table";
import {unreserveBookRequestStart} from "../../redux/actions/books/unreserveBook";


const mapStateToProps = ({bookreserve}) => ({
    list: bookreserve.list,
    count: bookreserve.count,
    loader: bookreserve.loader,
    searchData: bookreserve.search,
    isEmpty: bookreserve.empty
});

const mapDispatchToProps = {
    requestList: bookreserveCountRequestStart,
    setSearchData: setBookreserveSearchData,
    unreserveBook: unreserveBookRequestStart,
};

const BookReserve = ({list, requestList, count, loader, searchData, setSearchData, isEmpty, unreserveBook}) => {
    useEffect(() => {
        requestList();
    }, [requestList]);

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList();
    };

    return (
        <SceneContainer title="Заказы на выдачу книг">

            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage}/>

            {(loader && !isEmpty) ?
                <SpinnerOuter isLoading={loader}/>
                :
                <BookReserveTable list={list} unreserveBook={unreserveBook}/>
            }

            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage}/>
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookReserve);