import { START, SUCCESS, ERROR, ADMIN_GROUPS_EDIT_GROUP } from '../../actions/action-types'

export const adminGroupsEditGroupStart = (id, payload) => ({
  type: `${ADMIN_GROUPS_EDIT_GROUP}${START}`,
  id,
  payload,
})

export const adminGroupsEditGroupSuccess = payload => ({
  type: `${ADMIN_GROUPS_EDIT_GROUP}${SUCCESS}`,
  payload,
})

export const adminGroupsEditGroupError = payload => ({
  type: `${ADMIN_GROUPS_EDIT_GROUP}${ERROR}`,
  payload,
})
