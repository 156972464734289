import { call, put, takeLatest, select } from 'redux-saga/effects'
import { AUTHORS_COUNT_REQUEST, START, SUCCESS } from '../../../actions/action-types'
import { authorsCount, authorsList } from '../../../../api/authors/authors'
import { authorsCountRequestError, authorsCountRequestSuccess } from '../../../actions/authors/count'
import { setAuthorsSearchData } from '../../../actions/authors/searchData'
import { authorsListRequestError, authorsListRequestStart, authorsListRequestSuccess } from '../../../actions/authors/list'

export default function* loadAuthorsWatcher() {
  yield takeLatest(`${AUTHORS_COUNT_REQUEST}${START}`, authorsCountWorker)
  yield takeLatest(`${AUTHORS_COUNT_REQUEST}${SUCCESS}`, authorsListWorker)
}

function* authorsCountWorker() {
  const searchData = yield select(({ authors }) => authors.search)
  try {
    const response = yield call(authorsCount, searchData.query, searchData.selectedInstitution, searchData.selectedLibrary)
    const count = parseInt(response.data.count, 10)
    yield put(authorsCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setAuthorsSearchData({ ...searchData, pageCount }))
  } catch (error) {
    yield put(authorsCountRequestError(error))
  }
}

function* authorsListWorker() {
  yield put(authorsListRequestStart())
  const searchData = yield select(({ authors }) => authors.search)
  const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(
      authorsList,
      searchData.sortBy,
      searchData.maxResults,
      paginateFrom,
      searchData.query,
      searchData.sortDirection,
      searchData.selectedInstitution,
      searchData.selectedLibrary
    )
    yield put(authorsListRequestSuccess(response.data))
  } catch (error) {
    yield put(authorsListRequestError(error))
  }
}
