import {ELECTRONIC_COURSES_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const electronicCoursesListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ELECTRONIC_COURSES_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};