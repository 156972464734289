import classNames from 'classnames'
import T from 'prop-types'
import React from 'react'
import Input from '../../Input'
import styles from './ModalInputWrapper.module.css'

const InputWrapper = ({
  inputRef,
  isModal,
  label,
  type,
  value,
  onChange,
  name,
  children,
  error,
  additionalButtonOnClick,
  additionalButtonText,
  ...otherProps
}) => (
  <div className={styles.container}>
    <div className={styles.label}>{label}</div>
    <div className={classNames(styles.inputContainer, error && styles.inputOnError)}>
      {children ? (
        children
      ) : (
        <>
          <Input
            ref={inputRef}
            isModal={isModal}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            readOnly={value && !onChange}
            style={styles.input}
            {...otherProps}
          />
        </>
      )}
      {!!error && <span>{error}</span>}
    </div>
    {additionalButtonOnClick && (
      <button className={styles.additionalButton} onClick={additionalButtonOnClick}>
        {additionalButtonText}
      </button>
    )}
  </div>
)

InputWrapper.propTypes = {
  label: T.string,
  value: T.any,
  onChange: T.func,
  type: T.string,
  name: T.string,
  additionalButtonOnClick: T.func,
  additionalButtonText: T.string,
}

InputWrapper.defaultProps = {
  type: 'text',
}

export default InputWrapper
