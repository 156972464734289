import {
    SET_TRANSFER_LOG_FORM_DATA,
} from "../../../../actions/action-types";
import moment from "moment";



const initialState = {
    from: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
};

export const formReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_TRANSFER_LOG_FORM_DATA:
            return action.payload;

        default: return state;
    }
};