import {api} from "../../App";
import {createSimpleActionsForReducer} from "../actions/actionsFactory";
import {createSimpleReducer} from "../reducers/reducersFactrory";
import {START, SUCCESS} from "../actions/action-types";
import {
    call,
    put,
    takeLatest
} from "redux-saga/effects";
import {combineReducers} from "redux";
import {createNotification} from "../../components/notification/notification";

const getAllBooks = params => {
    return api.get("/bookstate?userid=" + params.userId + "&orderBy=number", params);
};
const deleteBookFromHistory = (dataId) => {
    return api.delete(`/bookmove/${dataId}`);
};
const editBookFromHistory = (dataId, data) => {
    return api.put(`/bookmove/${dataId}`, data);
};
const getAllBooksHistory = (userId) => {
    return api.get("/bookhistory?orderByD=movedate&userid=" + userId);
};
export const getBookInstanceByBookTag = (rfidcode) => {
    return api.post(`/searchinstance`, {
        rfidcode
    });
};


const BOOKS_REQUEST = `ON_HANDS_BOOKS_REQUEST`;
const USER_HISTORY_REQUEST = `USER_HISTORY_REQUEST`;
const DELETE_BOOK_FROM_HISTORY = `DELETE_BOOK_FROM_HISTORY`;
const EDIT_BOOK_FROM_HISTORY = `EDIT_BOOK_FROM_HISTORY`;

export const onHandsBooksActions = {
    ...createSimpleActionsForReducer("list", BOOKS_REQUEST),
    ...createSimpleActionsForReducer("history", USER_HISTORY_REQUEST,),
    ...createSimpleActionsForReducer("delete", DELETE_BOOK_FROM_HISTORY),
    ...createSimpleActionsForReducer("edit", EDIT_BOOK_FROM_HISTORY),
};

export const onHandsBooksReducers = combineReducers({
    list: createSimpleReducer([], `${BOOKS_REQUEST}${SUCCESS}`),
    history: createSimpleReducer([], `${USER_HISTORY_REQUEST}${SUCCESS}`)
});

function* loadOnHandsBooksSaga({payload}) {
    try {
        const response = yield call(getAllBooks, {userId: payload});
        const historyResponse = yield call(getAllBooksHistory, payload);
        yield put(onHandsBooksActions.listRequestSuccess(response.data));
        yield put(onHandsBooksActions.historyRequestSuccess(historyResponse.data));
    } catch (e) {
        yield put(onHandsBooksActions.listRequestError());
    }
}

function* deleteBookFromHistorySaga({payload}) {
    try {
        yield call(deleteBookFromHistory, payload.id);
        yield put(onHandsBooksActions.listRequestStart(payload.mid));
        createNotification('success', 'Книга успешно удалена из истории');
    } catch (e) {
        const errorText =  e.response.data.result ;

        createNotification(
            'warning',
            errorText === "ERROR: Exists child records"
            ? 'Не удалось удалить запись, есть зависимая запись'
            : 'Не удалось удалить запись'
        );
        yield put(onHandsBooksActions.deleteRequestError());
    }
}

function* editBookFromHistorySaga({payload}) {
    try {
        editBookFromHistory(payload.id, payload.data);

    } catch (e) {
        console.log(e);
    }
}

export function* onHandsBooksSagaWatcher() {
    yield takeLatest(`${BOOKS_REQUEST}${START}`, loadOnHandsBooksSaga);
    yield takeLatest(`${DELETE_BOOK_FROM_HISTORY}${START}`, deleteBookFromHistorySaga);
    yield takeLatest(`${EDIT_BOOK_FROM_HISTORY}${START}`, editBookFromHistorySaga);
}
