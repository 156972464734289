import { OPTIONS_GET_REQUEST, SET_OPTIONS_DATA, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const optionsDataReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${OPTIONS_GET_REQUEST}${SUCCESS}`:
      return action.payload;

    case SET_OPTIONS_DATA:
      return action.payload;

    default: return state;
  }
};