import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import styles from './LibraryMaterialsListItem.module.css'
import { saveAs } from 'file-saver'
import { createNotification } from '../../../notification/notification'
import { downloadFileTwo, downloadFile } from '../../../../utils/helpers/helpers'
import { Col, Row } from 'react-bootstrap'
import CoverPhotoPlaceholder from '../../../Covers/List/Item/CoverPhotoPlaceholder'
import CoverListItemInfo from '../../../Covers/List/Item/InfoBlock'
import { getBookFile } from '../../../../api/book/files'
import { BASE_URL_WITHOUT_API, BASE_URL } from '../../../../constants/api'
import { api } from '../../../../App'
import { getFileFormat } from '../../../../utils/helpers/helpers'
import { Link } from 'react-router-dom'
import { Button, Space, List, Spin } from 'antd'
import ModalWindow from '../../../ModalWindow'
import PopoverOuter from '../../../UtulityComponents/Popover'
import Panoram from './Panoram/Panoram'


const LibraryMaterialsListItem = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [fileName, setFileName] = useState('')
  const [audioLink, setAudioLink] = useState('')


  const bookId = data.bookid
  // Шифр
  const isItem3dModel = data.typeId === 10

  useEffect(() => {
    if (data?.files) {
      setFileName(data.files[0].filename)
    }
  }, [])

  const openImage = link => {
    api.get(link).then(res => {
      const location = res.data.Location
      const win = window.open(`${BASE_URL_WITHOUT_API}${location}`, '_blank')
      win.focus()
    })
  }

  const savePlayingToOpenHistory = file => {
    const fileId = file.id
    // временно закомментировано
    // api.post(`/book/${bookId}/file/${fileId}`)
    // временно закомментировано
  }

  // const downloadFile = file => {
  //   const fileId = file.id

  //   getBookFile(bookId, fileId)
  //     .then(response => {
  //       const location = `${BASE_URL_WITHOUT_API}${response.data.Location}`
  //       saveAs(location, file.filename)
  //     })
  //     .catch(error => {
  //       createNotification('error', 'Ошибка загрузки файла')
  //       console.log(error)
  //     })
  // }

  const openEbookHandler = id => {
    const ebookWindow = window.open(`../public/files/ebook/${id}/`)
    ebookWindow.focus()
  }

  const OpenFileComponent = ({ file }) => {
    const fileFormat = getFileFormat(file.filename)
    const fileId = file.id
    // Шифр
    if (data.typeId===16) {
      return <Button onClick={() => setModalData(file)}>Просмотр панорамы</Button>
    } else {
      switch (fileFormat.toUpperCase()) {
        case 'PDF':
          return (
            <Link to={`/pdfViewer/${bookId}/${fileId}`} target='_blank'>
              <Button>Открыть</Button>
            </Link>
          )
        case 'EPUB':
          return (
            <Link to={`/epub_reader/${bookId}/${fileId}`} target='_blank'>
              <Button>Открыть</Button>
            </Link>
          )
        case 'DJVU':
          return (
            <Link to={`/djvu_reader/${bookId}/${fileId}`} target='_blank'>
              <Button>Открыть</Button>
            </Link>
          )
        case 'ZIP':
          return (
            isItem3dModel && (
              <a
                onClick={savePlayingToOpenHistory}
                href={`${BASE_URL_WITHOUT_API}/modelViewer?id=${bookId}&filename=${fileName
                  .split('.')
                  .slice(0, fileName.split('.').length - 1)
                  .join('.')}`}
                target='_blank'
              >
                Просмотр модели
              </a>
            )
          )
        case 'DAE':
        case 'OBJ':
        case '3DS':
        case 'WRL':
        case 'WRM':
        case 'FBX':
          return (
            <a
              onClick={savePlayingToOpenHistory}
              href={`${BASE_URL_WITHOUT_API}/modellibrary?id=${bookId}&fileid=${fileId}&filename=${fileName}`}
              target='_blank'
            >
              Просмотр модели
            </a>
          )
        case 'WAV':
        case 'MP3':
          return (
            <>
            <Space className={'align-items-center'}>
              {audioLink ? <audio controls src={audioLink} onPlaying={savePlayingToOpenHistory} /> : <></>}
            </Space>
            </>
          )
        case 'MP4':
        case 'WEBM':
        case '3GP':
        case 'MPEG':
        case 'AVI':
        case 'SWF':
        case 'WMV':
          return <Button onClick={() => setModalData(file)}>Cмотреть видео</Button>
        case 'PNG':
        case 'JPG':
        case 'JPEG':
          return <Button onClick={() => openImage(`/book/${bookId}/file/${fileId}`)}>Открыть</Button>
  
        default:
          return null
      }
    }
  }

  const getFileLink = (link = '', fileName = '') => {
    if (link && link.length && fileName && fileName.length) {
    api.get(`${link}`).then(
        res => {
          const url = res.data.url;
          let href = document.createElement('a');
              href.setAttribute('href', `${BASE_URL_WITHOUT_API}/${url}`);
              href.setAttribute('target', '_blank');
              href.setAttribute('download', `${fileName}`)
              href.click();
              href.remove();
        }
     ).catch(error => {
       createNotification('error', 'Ошибка загрузки файла')
     })
    }
     setIsLoading(false);
  }

  const handleDownlod = (item) => {
    setIsLoading(true);
    getFileLink(`/book/${bookId}/file/${item.id}`, `${item?.filename}`);
  }

  const getFileLink2 = async (link = '', fileName = '') => {
    if (link && link.length && fileName && fileName.length) {
      try {
        const filename = await api.get(`${link}`)
        return filename.data.url;      
      } catch (e) {
        createNotification('error', 'Ошибка загрузки файла');
      }
    }
  }

  const handleDownlod2 = async (item) => {
    const link = await getFileLink2(`/book/${bookId}/file/${item.id}`, `${item?.filename}`);
    setAudioLink(`https://biblio.nintegra.ru/${link}`, 'link');
  }

  const PopoverContent = !data.files ? (
    <div>Файлов нет</div>
  ) : (
    <List
      header={null}
      footer={null}
      dataSource={data.files}
      renderItem={item => {
        handleDownlod2(item)
        return (
          <List.Item>
            <div className={'d-flex justify-content-between'}>
              <div style={{ width: '250px', wordBreak: 'break-all' }}>{item.filename}</div>
              <Space>
                {isLoading ? (
                  <Spin />
                ) : (
                  <Button
                    onClick={() => {handleDownlod(item)}}
                    type='link'
                  >
                    Скачать
                  </Button>
                )}
                <OpenFileComponent file={item} />
              </Space>
            </div>
          </List.Item>
        )
      }}
    />
  )
  // TREE для книги 
const tree = `${data.tom === null ? "n/a" : data.tom } / ${data.release=== null ? "n/a" : data.release} / ${data.part=== null?  "n/a" : data.part}`

return (
    <div className={styles.container}>
      <Row>
        <Col md={2}>
          <CoverPhotoPlaceholder url={data.coverImage} bookId={data.bookid} alt={data.title} />
        </Col>
        <Col md={10}>
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <h4>{data.title}</h4>
              <div className={styles.value}>{data.authors}</div>
            </Col>
            <Col md={12}>
              <CoverListItemInfo label={'ISSN'} value={ data.seriesISSN || 'n/a'} />
              <CoverListItemInfo label={'Год'} value={data.year || '-'} />
              <CoverListItemInfo label={'Выпуск / Номер / Часть'} value={tree} />
              <CoverListItemInfo label={'Размещение'} value={data.clients || 'n/a'} />
              {data.covermatch !== undefined && <CoverListItemInfo label={'% совпадения'} value={data.covermatch} />}
            </Col>
            <Col md={{ span: 9, offset: 3 }} className={'d-flex justify-content-end'}>
              {data.electronFormat === 'HTML' ? (
                <Button onClick={() => openEbookHandler(data.id)}>Открыть</Button>
              ) : (
                <PopoverOuter placement='bottomRight' title={'Файлы'} trigger='click' content={PopoverContent}>
                  <Button>Файлы</Button>
                </PopoverOuter>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalWindow
        // Шифр
        title={`Просмотр ${data.typeId===16 ? "панорамы" : "видео" }`}
        isOpen={!!modalData}
        onRequestClose={() => setModalData(null)}
        width='1050px'
        zIndex={10000}
      >
        <div>
          {/* Шифр */ data.typeId===16 ?
          (modalData &&
          <Panoram
            bookId={bookId}
            file={modalData}
          />) : <video
            width='100%'
            src={`${BASE_URL_WITHOUT_API}/api/book/${bookId}/file/${modalData?.id}`}
            onPlaying={savePlayingToOpenHistory}
            controls
            autoPlay
          />}
        </div>
      </ModalWindow>
    </div>
  )
}

LibraryMaterialsListItem.propTypes = {
  data: T.object,
}

export default LibraryMaterialsListItem
