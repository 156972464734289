import React, {useState} from 'react';
import {Collapse} from "react-bootstrap";
import styles from "./BibliographySpecialization.module.css";
import BibliographyDiscipline from "../Discipline";

const BibliographySpecialization = ({data}) => {
    const { name, disciplines } = data;

    const [spec, setSpec] = useState(false);

    const toggleSpec = e => {
        if (e.target.id === "spec") {
            setSpec(!spec);
        }
    };

    return (
        <div onClick={toggleSpec}>
            <div className={styles.tableSpecRow}>
                <div id="spec" className={styles.titleCell}>
                    { name }
                </div>
            </div>
            <Collapse in={spec}>
                <div className={'collapse-content'}>
                    {disciplines.map((item, index) => (
                        <BibliographyDiscipline
                            key={index}
                            data={item}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

export default BibliographySpecialization;