import {
    COVERS_SCAN_COUNT_REQUEST, SET_LOCATION_CHANGE,
    SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const countReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${COVERS_SCAN_COUNT_REQUEST}${SUCCESS}`:
            return action.payload;

        case SET_LOCATION_CHANGE:
            return initialState;

        default:
            return state;
    }
};