import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { searchAuditCountRequestStart } from '../../redux/actions/searchAudit/count'
import { setSearchAuditFormData } from '../../redux/actions/searchAudit/formData'
import { searchAuditSearchData } from '../../redux/actions/searchAudit/searchData'
import Pagination from '../../components/Pagination'
import SearchAuditForm from '../../components/SearchAudit/Form'
import SearchAuditTable from '../../components/SearchAudit/Table'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'

const mapStateToProps = ({ searchAudit }) => ({
  list: searchAudit.list,
  count: searchAudit.count,
  formData: searchAudit.form,
  searchData: searchAudit.search,
  datasetList: searchAudit.dataset,
})

const mapDispatchToProps = {
  requestList: searchAuditCountRequestStart,
  setFormData: setSearchAuditFormData,
  setSearchData: searchAuditSearchData,
}

const SearchAuditScene = ({ list, requestList, formData, setFormData, searchData, setSearchData, count }) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  const setSort = (sortBy, sortDirection = SORT_DIRECTION.ASC) => {
    setSearchData({ ...searchData, sortBy, sortDirection: sortDirection })
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  return (
    <SceneContainer title={'Поисковые запросы'}>
      <SearchAuditForm data={formData} setData={setFormData} search={requestList} />
      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
      <SearchAuditTable data={list} sortBy={searchData.sortBy} setSort={setSort} />
      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchAuditScene)
