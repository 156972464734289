import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button as ButtonAnt, Checkbox } from 'antd'

import  { optionsDiaryTypesRequestStart } from '../../../redux/actions/options/diaryTypes/get'
import  { optionsDiaryTypesUpdateStart } from '../../../redux/actions/options/diaryTypes/save'
import  { libraryTypesListRequestStart } from '../../../redux/actions/library/types/list'

import styles from './styles.module.css'
import { createNotification } from '../../notification/notification'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'

function LibraryDiary ({
    typesList,
    _currentTypes,
    loading,
    getDiaryTypes,
    updateDiaryTypes,
    getTypesList
}) {
  
  const [currentTypes, setCurrentTypes] = useState([])

  useEffect(() => {
    getDiaryTypes()
    !typesList.length && getTypesList()
  }, [])
  
  useEffect(() => {
    _currentTypes && setCurrentTypes(_currentTypes)
  }, [_currentTypes])
  
  const handleTypeClick = (typeValue) => () => {
    const types = [...currentTypes]
    const index = types.indexOf(typeValue)
    if (index === -1) {
      types.push(typeValue)
    } else {
      types.splice(index, 1)
    }
    setCurrentTypes(types)
  }

  const renderLi = ({name, translate_name}) => {
    const checked = currentTypes.includes(translate_name)
    return (
      <li
        key={translate_name}
        onClick={handleTypeClick(translate_name)}
        className={styles.typeItem}
      >
        <Checkbox checked={checked}/>
        <span>{name}</span>
      </li>
    )
  }

  const saveChanges = () => {
    
    if (currentTypes.length !== 6) {
      createNotification('error', 'Необходимо выбрать 6 типов')
      return
    }

    updateDiaryTypes(currentTypes)
  }

  const filteredTypesList = typesList.filter(type => type.translate_name)

  return (
    <div className={styles.container}>
        
        <h5>Типы документов (для отчета "Дневник работы библиотеки")</h5>
        {
          loading
            ? <SpinnerOuter/>
            : <ul>{filteredTypesList.map(renderLi)}</ul>
        }
        <div className={'d-flex justify-content-end'} style={{marginTop: '1rem'}}>
            <ButtonAnt primary onClick={saveChanges}>Сохранить</ButtonAnt>
        </div>

    </div>
  )
}



const mapStateToProps = ({ options, library }) => ({
    _currentTypes: options.diaryTypes,
    typesList: library.types.list,
    loading: library.types.loader
})

const mapDispatchToProps = {
    getDiaryTypes: optionsDiaryTypesRequestStart,
    updateDiaryTypes: optionsDiaryTypesUpdateStart,
    getTypesList: libraryTypesListRequestStart
}


export default connect(mapStateToProps, mapDispatchToProps)(LibraryDiary)