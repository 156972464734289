import { RESET_COVERS_FORM_DATA, SET_COVERS_FORM_DATA } from '../action-types'

export const setCoversFormData = data => ({
  type: SET_COVERS_FORM_DATA,
  payload: data,
})

export const resetCoversFormData = () => ({
  type: RESET_COVERS_FORM_DATA,
})

