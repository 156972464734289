import { call, put, takeLatest } from "redux-saga/effects";
import {BOOK_CARD_DATA_REQUEST, START} from "../../actions/action-types";
import {bookCardDataRequestError, bookCardDataRequestSuccess} from "../../actions/bookData/bookCard";
import {getBookCardTextData} from "../../../api/book/bookCard";

export default function* bookCardWatcher() {
  yield takeLatest(`${BOOK_CARD_DATA_REQUEST}${START}`, dataWorker)
}

function* dataWorker({payload: bookId}) {
  try {
    const response = yield call(getBookCardTextData, bookId);
    yield put(bookCardDataRequestSuccess(response.data));
  } catch(error) {
    yield put(bookCardDataRequestError(error));
  }
}