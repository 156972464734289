import React from 'react'
import T from 'prop-types'

const CatalogCardBody = ({ data, authors }) => {
  const { title, year, bbk, udk, publishers, pageNum, annotation, isbn, authorSign, pubinstanceamount } = data || {}

  return (
    <div className='catalogCard'>
      <div className={'d-flex'}>
        <div className='authorSign'>{authorSign}</div>
        <div className='topRow'>
          <div className='authData'>
            <div className='auth'>{authors ? authors.map(item => <>{`${item.name} `}</>) : () => null}</div>
            <div>
              <i>
                {title}, {year}г.
              </i>
              -{pageNum && <i>{pageNum} с.</i>}
              {pubinstanceamount && <i>{pubinstanceamount} экз.</i>}
              <p className='annotation'>{annotation}</p>
              <p>ISBN {isbn}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='botRow'>
        <div className='bbkSign'>
          <div>
            изд-во:
            {publishers ? publishers.map(item => <>{`${item.name} `}</>) : () => null}
          </div>
        </div>
        <div>
          <div className='bbks'>
            <div>
              <b>ББК</b>
            </div>
            <div>{bbk}</div>
          </div>
          <div className='bbks'>
            <div>
              <b>УДК</b>
            </div>
            <div>{udk}</div>
          </div>
        </div>
        <div className='hole' />
      </div>
    </div>
  )
}

CatalogCardBody.propTypes = {
  data: T.object,
  authors: T.array,
}

export default CatalogCardBody
