import { LOST_DOC_GET_PDF_REQUEST, SUCCESS } from '../../../actions/action-types'

const initialState = []

export const notebookLostDocPdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOST_DOC_GET_PDF_REQUEST}${SUCCESS}`:
      return action.payload

    default:
      return state;
  }
}