import { MEDIA_RESOURCE_DELETE_LOGO_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const mediaResourceDeleteLogoRequestStart = (resourceId) => ({
  type: `${MEDIA_RESOURCE_DELETE_LOGO_REQUEST}${START}`,
  payload: resourceId
});

export const mediaResourceDeleteLogoRequestSuccess = (response) => ({
  type: `${MEDIA_RESOURCE_DELETE_LOGO_REQUEST}${SUCCESS}`,
  payload: response
});

export const mediaResourceDeleteLogoRequestError = (error) => ({
  type: `${MEDIA_RESOURCE_DELETE_LOGO_REQUEST}${ERROR}`,
  payload: error
});
