import { COVERS_SCAN_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const coversScanPlanListRequestStart = () => ({
    type: `${COVERS_SCAN_LIST_REQUEST}${START}`,
});

export const coversScanPlanListRequestSuccess = (data) => ({
    type: `${COVERS_SCAN_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const coversScanPlanListRequestError = (data) => ({
    type: `${COVERS_SCAN_LIST_REQUEST}${ERROR}`,
    payload: data
});

