import React from 'react'
import T from 'prop-types'
import styles from './BooksList.module.css'
import SearchBooksItem from '../../UtulityComponents/SearchBooksItem'
import { Link } from 'react-router-dom'
import { Checkbox, Space, Button, Divider } from 'antd'

const BooksList = ({
  history,
  data,
  termOfUse,
  createBook,
  onExportBooks,
  setBatchModalOpen,
  handleSelect,
  selectedIds,
  loadSuccess,
  count
}) => {
  return (
    <div>
      <Space>
        <Button type='primary' onClick={createBook}>
          Добавить
        </Button>
        <Button type='primary' onClick={onExportBooks}>
          Выгрузить
        </Button>
        <Button type='primary' onClick={() => history.push('/import')}>
          Загрузить
        </Button>
        {!!selectedIds.length && (
          <Button type='primary' onClick={() => setBatchModalOpen(true)}>
            Пакетное редактирование
          </Button>
        )}
      </Space>
      <div className={styles.container}>
        {loadSuccess && count === 0 ? (
          <div className='emptyNotify'>По Вашему запросу ничего не найдено</div>
        ) : (
          data.map((item, index) => (
            <SearchBooksItem
              key={index}
              data={item}
              termOfUse={termOfUse}
              onSelect={handleSelect}
              isChecked={selectedIds.includes(item.bookid)}
            >
              <Space>
                {/* <Checkbox>Все вкладки</Checkbox>
                <Button>Копировать</Button> */}
                <Divider type='vertical' />

                <Link to={`/book/${item.bookid}/edit`} target='_blank'>
                  <Button type='primary'>Редактирование</Button>
                </Link>
              </Space>
            </SearchBooksItem>
          ))
        )}
      </div>
    </div>
  )
}

BooksList.propTypes = {
  data: T.array,
  termOfUse: T.number,
  updateBookId: T.func,
  batchModalData: T.object,
}

export default BooksList
