import { BOOK_SAVE_REQUEST, SUCCESS } from '../../../actions/action-types'

const initialState = null

export const bookIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_SAVE_REQUEST}${SUCCESS}`:
      return action.payload

    default:
      return state
  }
}
