import { ERROR, READER_DATA_REQUEST, START, SUCCESS } from "../action-types";

export const readerDataRequestStart = (data) => ({
  type: `${READER_DATA_REQUEST}${START}`,
  payload: data
});

export const readerDataRequestSuccess = (data) => ({
  type: `${READER_DATA_REQUEST}${SUCCESS}`,
  payload: data
});

export const readerDataRequestError = (data) => ({
  type: `${READER_DATA_REQUEST}${ERROR}`,
  payload: data
});

