import { createSimpleActionsForReducer } from '../actions/actionsFactory'
import { START, SUCCESS } from '../actions/action-types'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { api } from '../../App'

export const types = {
  INDIVIDUAL_TYPE: 'user',
  GROUP_TYPE: 'group',
}

export const typesOfWork = {
  HOME_WORK: '',
  RESULT_WORK: 'result',
}

const PERFORMANCES_REQUEST = 'PERFORMANCES_REQUEST'
const PERFORMANCES_DATA_TO_STATE = 'PERFORMANCES_DATA_TO_STATE'
const PERFORMANCES_LOAD_REQUEST = 'PERFORMANCES_LOAD_REQUEST'
const PERFORMANCES_TERM_REQUEST = 'PERFORMANCES_FACULTY_REQUEST'
const PERFORMANCES_DISCIPLINE_REQUEST = 'PERFORMANCES_CURS_REQUEST'
const PERFORMANCES_YEAR_REQUEST = 'PERFORMANCES_YEAR_REQUEST'
const USER_DATA_REQUEST = 'USER_DATA_REQUEST'
const PERFORMANCES_FILES_REQUEST = 'PERFORMANCES_FILES_REQUEST'
const TASKS_FETCH_COMPLETED = 'TASKS_FETCH_COMPLETED'

const getYearApi = () => {
  return api.get('/hlms/api/year/scy')
}

const getTermApi = yearId => {
  return api.get(`/hlms/api/year/term/${yearId}`)
}

const getProgressApi = ({ userId, termId }) => {
  return api.get(`/hlms/api/progress/${userId}/${termId}`)
}

const getPerformancesApi = data => {
  return api.get(`/hlms/api/progress/${data}`)
}

const getFileApi = (sheId, instance, type, typeOfWork = typesOfWork.HOME_WORK, userId) => {
  return api.get(
    `/hlms/api/homework/${type}${typeOfWork}${
      type === types.GROUP_TYPE && typeOfWork === typesOfWork.HOME_WORK ? '/' : `/${userId}/`
    }${sheId}/${instance}`
  )
}

export const performancesActions = {
  ...createSimpleActionsForReducer('request', `${PERFORMANCES_REQUEST}`),
  ...createSimpleActionsForReducer('load', `${PERFORMANCES_LOAD_REQUEST}`),
  ...createSimpleActionsForReducer('term', `${PERFORMANCES_TERM_REQUEST}`),
  ...createSimpleActionsForReducer('discipline', `${PERFORMANCES_DISCIPLINE_REQUEST}`),
  ...createSimpleActionsForReducer('year', `${PERFORMANCES_YEAR_REQUEST}`),
  ...createSimpleActionsForReducer('user', `${USER_DATA_REQUEST}`),
  ...createSimpleActionsForReducer('files', `${PERFORMANCES_FILES_REQUEST}`),
  ...createSimpleActionsForReducer('tasks', `${TASKS_FETCH_COMPLETED}`),
  ...createSimpleActionsForReducer('progress', `${TASKS_FETCH_COMPLETED}`),
}

export const requestAction = data => ({
  type: PERFORMANCES_DATA_TO_STATE,
  data,
})

const initialState = {
  loader: false,
}

export const performancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${TASKS_FETCH_COMPLETED}${SUCCESS}`:
      return {
        ...state,
        modal: action.payload,
      }
    case `${PERFORMANCES_REQUEST}${START}`:
      return {
        ...state,
        loader: true,
      }
    case `${PERFORMANCES_TERM_REQUEST}${SUCCESS}`:
      return {
        ...state,
        term: action.payload,
      }
    case `${PERFORMANCES_DISCIPLINE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        discipline: action.payload,
      }
    case `${PERFORMANCES_YEAR_REQUEST}${SUCCESS}`:
      return {
        ...state,
        year: action.payload,
      }
    case `${PERFORMANCES_REQUEST}${SUCCESS}`:
      return {
        ...state,
        loader: false,
        response: action.payload,
      }
    case `${USER_DATA_REQUEST}${SUCCESS}`:
      return {
        ...state,
        userData: action.payload,
      }
    case PERFORMANCES_DATA_TO_STATE:
      return {
        ...state,
        user: action.data,
      }

    default:
      return state
  }
}

function* performancesLoadSaga({ payload }) {
  try {
    // console.log(payload)
    const resYear = yield call(getYearApi)
    // const resTerm = yield call(
    //   getTermApi,
    //   payload?.year ? payload?.year : resYear.data.schoolYear[resYear.data.schoolYear.length - 1].xp_key
    // )
    // const resDiscipline = yield call(
    //   getDisciplineApi,
    //   payload?.term ? payload?.term : resTerm.data.terms[resTerm.data.terms.length - 1].trmid
    // )

    yield put(performancesActions.yearRequestSuccess(resYear.data))
    // yield put(performancesActions.termRequestSuccess(resTerm.data))
    // yield put(performancesActions.disciplineRequestSuccess(resDiscipline.data))
  } catch (e) {
    console.log(e)
  }
}

function* performancesRequestSaga({ payload }) {
  const data = yield select(({ performances }) => performances.user)
  // const yearId = yield select(({ performances }) => performances.user)getTermApi
  try {
    const term = yield call(getTermApi, payload)
    const res = yield call(getPerformancesApi, data)
    yield put(performancesActions.termRequestSuccess(term.data))
    yield put(performancesActions.requestRequestSuccess(res))
  } catch (e) {
    console.log(e)
  }
}

function* performancesRequestFilesSaga({ payload: { sheid, instance, type, typesOfWork } }) {
  const userId = yield select(({ auth }) => auth.data.mid)

  try {
    const res = yield call(getFileApi, sheid, instance, type, typesOfWork, userId)
    yield put(performancesActions.tasksRequestSuccess(res.data))
  } catch (e) {
    console.log(e)
  }
}

export function* performancesSagaWatcher() {
  yield takeLatest(`${PERFORMANCES_LOAD_REQUEST}${START}`, performancesLoadSaga)
  yield takeLatest(`${PERFORMANCES_REQUEST}${START}`, performancesRequestSaga)
  yield takeLatest(`${PERFORMANCES_FILES_REQUEST}${START}`, performancesRequestFilesSaga)
}
