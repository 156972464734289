import { ERROR, START, SUCCESS, UNRESERVE_BOOK_REQUEST } from "../action-types";

export const unreserveBookRequestStart = (instanceId, source) => ({
  type: `${UNRESERVE_BOOK_REQUEST}${START}`,
  payload: { instanceId, source }
});

export const unreserveBookRequestSuccess = (data) => ({
    type: `${UNRESERVE_BOOK_REQUEST}${SUCCESS}`,
    payload: data
});

export const unreserveBookRequestError = (data) => ({
  type: `${UNRESERVE_BOOK_REQUEST}${ERROR}`,
  payload: data
});
