import { createNotification } from "../../../../components/notification/notification";
import {ERROR, MOBILE_LIBRARY_CREATE_REQUEST, START, SUCCESS} from "../../action-types";

export const createMobileLibraryRequestStart = (payload) => ({
    type: `${MOBILE_LIBRARY_CREATE_REQUEST}${START}`,
    payload
});

export const createMobileLibraryRequestSuccess = (payload) => {
    createNotification('success', 'Библиотека создана успешно')
    return {
        type: `${MOBILE_LIBRARY_CREATE_REQUEST}${SUCCESS}`,
        payload
    }
};

export const createMobileLibraryRequestError = (payload) => {
    createNotification('error', 'Ошибка создания библиотеки')
    return {
        type: `${MOBILE_LIBRARY_CREATE_REQUEST}${ERROR}`,
        payload
    }
};