import { LOST_DOC_DOCUMENT_UPDATE_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const updateLostDocumentRequestStart = (data) => ({
  type: `${LOST_DOC_DOCUMENT_UPDATE_REQUEST}${START}`,
  payload: data
});

export const updateLostDocumentRequestSuccess = (response) => ({
  type: `${LOST_DOC_DOCUMENT_UPDATE_REQUEST}${SUCCESS}`,
  payload: response
});

export const updateLostDocumentRequestError = (error) => ({
  type: `${LOST_DOC_DOCUMENT_UPDATE_REQUEST}${ERROR}`,
  payload: error
});
