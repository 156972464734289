import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import Discipline from "../Discipline/Discipline";
import styles from "./Specialization.module.css";

const Specialization = ({ data }) => {
  const { name, course_all, course_five, course_four, course_three, course_two, course_one, disciplines} = data;

  const [spec, setSpec] = useState(false);

  const toggleSpec = e => {
    if (e.target.id === "spec") {
      setSpec(!spec);
    }
  };

  return (
    <div onClick={toggleSpec}>
      <div className={styles.tableSpecRow}>
        <div id="spec" className={styles.titleCell}>
          { name }
        </div>
        <div className={styles.cell}>{ course_one }</div>
        <div className={styles.cell}>{ course_two }</div>
        <div className={styles.cell}>{ course_three }</div>
        <div className={styles.cell}>{ course_four }</div>
        <div className={styles.cell}>{ course_five }</div>
        <div className={styles.cell}>{ course_all }</div>
      </div>
      <Collapse in={spec}>
          <div className={'collapse-content'}>
              {disciplines.map((item, index) => (
                  <Discipline
                      key={index}
                      data={item}
                  />
              ))}
          </div>
      </Collapse>
    </div>
  );
};

export default Specialization;
