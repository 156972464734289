import React from 'react';
import T from 'prop-types';
import styles from './BookLoanSpecialtyTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const BookLoanSpecialtyTable = ({data}) => {
  return (
    <div className={styles.container}>
      <DataTable data={data}>
        <DataTableCol title="№" value="index" className={styles.blueColumn}/>
        <DataTableCol title="Название издания" value="title" colSpan={5} className={styles.blueColumn}/>
        <DataTableCol title="Авторы" value="authors" colSpan={2}/>
        <DataTableCol title="Год" value="year"/>
        <DataTableCol title="Тип" value="type"/>
        <DataTableCol title="Выбрать" value="selection" resolver={() => <input type="checkbox"/>}/>
      </DataTable>
    </div>
  );
};

BookLoanSpecialtyTable.propTypes = {
  data: T.array
};

export default BookLoanSpecialtyTable;
