import { MEDIA_RESOURCE_GET_FULL_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const mediaResourceFullRequestStart = () => ({
  type: `${MEDIA_RESOURCE_GET_FULL_REQUEST}${START}`
});

export const mediaResourceFullRequestSuccess = (response) => ({
  type: `${MEDIA_RESOURCE_GET_FULL_REQUEST}${SUCCESS}`,
  payload: response
});

export const mediaResourceFullRequestError = (error) => ({
  type: `${MEDIA_RESOURCE_GET_FULL_REQUEST}${ERROR}`,
  payload: error
});
