import React, { useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import InstancesFromDocuments from "./InstancesFromDocuments";
import { Space, Button } from "antd";
import PrintDestroyAct from "../../../Inventory/Modal/PrintDestroyAct";
import styles from "./DocumentsEdit.module.css";
import { documentBookDeleteRequestStart } from "../../../../redux/actions/documents/books/delete";
import { documentBookSaveRequestStart } from "../../../../redux/actions/documents/books/save";
import { createPriceCoeffRequestStart } from "../../../../redux/actions/common/priceCoeff/create";
import BooksFromDocuments from "./BooksFromDocument";

const mapStateToProps = ({ documents }) => ({
  books: documents.books,
});

const mapDispatchToProps = {
  deleteBook: documentBookDeleteRequestStart,
  saveBook: documentBookSaveRequestStart,
  createCoeff: createPriceCoeffRequestStart
};

const DocumentsEdit = ({
  modalData,
  setModalData,
  documentType,
  onAddBook,
  formValidity,
  searchOpen,
  onCreateNewBook,
  books,
  clientData,
  currentBook,
  createCoeff,
  setCurrentBook,
  documentId,
  onSelectFromSearch,
  saveBook,
  deleteBook,
  fromInvBook,
  coeffs,
  disabled,
  fromLostDocForTakeAct,
  fromLostDoc

}) => {

  const [fundMovingModalOpen, setFundMovingModalOpen] = useState(false);
  const [numbers, setNumbers] = useState([])

  const ControlsButton = () => (
    <Space>
      {!fromLostDocForTakeAct && 
        <Button style={{marginBottom: '1rem'}} onClick={onAddBook} disabled={disabled || !formValidity}>
        {!searchOpen ? "Добавить книгу" : "Отменить "}
        </Button>
      }
      

      {/* Шифр */documentType === 1 && !!books.length && (
        <Button style={{marginBottom: '1rem'}} type="primary" onClick={() => setFundMovingModalOpen(true)}>
          Распределить по фондам
        </Button>
      )}

      {/* Шифр 18 = акт списания, 1 = приход от поставщика*/ [1, 12, 18, 19, 20].some((e) => e == documentType) && (
        <Button
          style={{marginBottom: '1rem'}}
          type="primary"
          onClick={onCreateNewBook}
          disabled={!formValidity}
        >
          Добавить новую БЗ
        </Button>
      )}

      {/* Шифр */ documentType === 20 && !!books.length && (
        <PrintDestroyAct books={books} clientData={clientData} />
      )}
    </Space>
  );

  const NoResult = () => (
    <div className={classNames("text-center", styles.textNotification)}>
      Ничего не найдено
      <span onClick={onCreateNewBook} className={styles.textLink}>
        Cоздать новую библиографическую запись
      </span>
    </div>
  );

  const onBookDelete = (data) => {
    const newArr = [...numbers]
    const index = newArr.indexOf(data.invnumber)
    newArr.splice(index, 1)    
    setNumbers(newArr)
    deleteBook(data);
    setCurrentBook(null);
  };

  const onBookSave = (data) => {
    setNumbers([...numbers, data.bookinstanceid])
    saveBook(data);
    setCurrentBook(null);
    documentType == 18 && createCoefficient(data)
  };
  
  const createCoefficient = ({year, coefficient}) => {
    if (!year || !coefficient || !coeffs.length) return;
    const haveThisYear = coeffs.find(coeff => coeff.year == year)
    !haveThisYear && createCoeff({year, value: coefficient})
  }

  return (
    <>
      {/* Шифр */ documentType && documentType === 1 ? (
        <BooksFromDocuments
          modalData={modalData}
          setModalData={setModalData}
          ControlsButton={ControlsButton}
          NoResult={NoResult}
          searchOpen={searchOpen}
          currentBook={currentBook}
          setCurrentBook={setCurrentBook}
          setFundMovingModalOpen={setFundMovingModalOpen}
          fundMovingModalOpen={fundMovingModalOpen}
          documentType={documentType}
          documentId={documentId}
          onSelectFromSearch={onSelectFromSearch}
          onBookDelete={onBookDelete}
          onBookSave={onBookSave}
          fromInvBook={fromInvBook}

        />
      ) : (
        <InstancesFromDocuments
          modalData={modalData}
          setModalData={setModalData}
          ControlsButton={ControlsButton}
          NoResult={NoResult}
          numbers={numbers}
          searchOpen={searchOpen}
          currentBook={currentBook}
          setCurrentBook={setCurrentBook}
          documentType={documentType}
          documentId={documentId}
          onBookDelete={onBookDelete}
          onBookSave={onBookSave}
          onSelectFromSearch={onSelectFromSearch}
          fromLostDoc={fromLostDoc}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsEdit);
