import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  HEADER_IMAGES_LIST_REQUEST,
  LAYOUT_OPTIONS_GET_REQUEST,
  LAYOUT_OPTIONS_SAVE_REQUEST,
  START
} from "../../../actions/action-types";
import {
  getLayoutOptions,
  putLayoutOptions,
  getHeaderImageList
} from "../../../../api/options/layout";
import {
  layoutOptionsGetRequestSuccess,
  layoutOptionsGetRequestError
} from '../../../actions/options/layout/get';
import {
  layoutOptionsSaveRequestSuccess,
  layoutOptionsSaveRequestError
} from '../../../actions/options/layout/save';
import {
  layoutImagesListRequestSuccess,
  layoutImagesListRequestError
} from '../../../actions/options/layout/layoutImages';




export default function* optionsSaveWatcher() {
  yield takeLatest(`${LAYOUT_OPTIONS_GET_REQUEST}${START}`, layoutOptionsGetWorker);
  yield takeLatest(`${LAYOUT_OPTIONS_SAVE_REQUEST}${START}`, layoutOptionsSaveWorker);
  yield takeLatest(`${HEADER_IMAGES_LIST_REQUEST}${START}`, headerImagesListWorker);
}

function* layoutOptionsSaveWorker() {
  const data = yield select(({options}) => options.layout);
  try {
    const response = yield call(putLayoutOptions, data);
    yield put(layoutOptionsSaveRequestSuccess(response.data));
  } catch (error) {
    yield put(layoutOptionsSaveRequestError(error));
  }
}

function* layoutOptionsGetWorker () {
    try {
        const response = yield call(getLayoutOptions);
        yield put(layoutOptionsGetRequestSuccess(response.data));
    } catch(error) {
        yield put(layoutOptionsGetRequestError(error));
    }
}

function* headerImagesListWorker () {
    try {
        const response = yield call(getHeaderImageList);
        yield put(layoutImagesListRequestSuccess(response.data));
    } catch(error) {
        yield put(layoutImagesListRequestError(error));
    }
}
