import { all } from 'redux-saga/effects';
import loadPublishersSaga from "./load/loadPublishersSaga";
import savePublishersSaga from "./save/savePublishersSaga";
import deletePublishersSaga from "./delete/deletePublishersSaga";

export default function* () {
  yield all([
    loadPublishersSaga(),
    savePublishersSaga(),
    deletePublishersSaga()
  ]);
}