import { call, put, takeLatest } from "redux-saga/effects";
import { START, USERS_DELETE_PHOTO_REQUEST } from "../../../actions/action-types";
import {
  usersDeletePhotoRequestError,
  usersDeletePhotoRequestSuccess
} from "../../../actions/adminUsers/photo/deletePhoto";
import { deleteUserPhoto } from "../../../../api/adminUsers/users";
import { usersCountRequestStart } from "../../../actions/adminUsers/count";

export default function* deleteUserPhotoWatcher() {
  yield takeLatest(`${USERS_DELETE_PHOTO_REQUEST}${START}`, deleteUserPhotoWorker)
}

function* deleteUserPhotoWorker({payload: mid}) {
  try {
    const response = yield call(deleteUserPhoto, mid);
    yield put(usersDeletePhotoRequestSuccess(response.data));
    yield put(usersCountRequestStart());
  } catch(error) {
    yield put(usersDeletePhotoRequestError())
  }
}