import React from 'react'
import PropTypes from 'prop-types'
import SearchBooksItem from '../../UtulityComponents/SearchBooksItem'
import ControlsPanel from '../List/ControlsPanel'

const ReadingListItem = ({ data, reserveBook, unreserveBook, addBookScanOrder, addBookOrderHandler }) => {
  return (
    <SearchBooksItem data={data}>
      <ControlsPanel
        data={data}
        reserveBook={reserveBook}
        unreserveBook={unreserveBook}
        addBookScanOrder={addBookScanOrder}
        addBookOrderHandler={addBookOrderHandler}
      />
    </SearchBooksItem>
  )
}

ReadingListItem.propTypes = {
  data: PropTypes.object,
  reserveBook: PropTypes.func.isRequired,
  unreserveBook: PropTypes.func.isRequired,
  addBookScanOrder: PropTypes.func.isRequired,
  addBookOrderHandler: PropTypes.func.isRequired,
}

export default ReadingListItem
