export const transformBookData = data => {
  return {
    ...data,
    clients: data.clients.map(item => parseInt(item.id, 10)),
    authors: data.authors.map(item => parseInt(item.id, 10)),
    collaborators: data.collaborators.map(item => parseInt(item.id, 10)),
    interpreters: data.interpreters.map(item => parseInt(item.id, 10)),
    editorialboardmembers: data.editorialboardmembers.map(item => parseInt(item.id, 10)),
    personsparticipatedcreation: data.personsparticipatedcreation.map(item => parseInt(item.id, 10)),
    categories: data.categories.map(item => parseInt(item.id, 10)),
    publishers: data.publishers.map(item => parseInt(item.id, 10)),
    pageNum: data.pageNum,
    eer_purpose: data.eer_purpose ? parseInt(data.eer_purpose) : null,
    eer_purpose_subgroup: data.eer_purpose_subgroup ? parseInt(data.eer_purpose_subgroup) : null,
    eer_genre: data.eer_genre.map(item => parseInt(item.id)),
    eer_purpose_education_form: parseInt(data.eer_purpose_education_form),
    eer_purpose_reader: !!data.eer_purpose_reader ? data.eer_purpose_reader.map(item => parseInt(item.id)) : null,
    eerTechCommunicId: data.eerTechCommunicId ? parseInt(data.eerTechCommunicId) : null,
    eer_tech_distribution: parseInt(data.eer_tech_distribution),
    eerOutCarrierId: data.eerOutCarrierId ? parseInt(data.eerOutCarrierId.value) : null,
    // Шифр
    seriesid: data.seriesid === '0' ? null : data.seriesid,
    literaid: data.literaid === '0' ? null : data.literaid,
    cid: data.cid?.value,
  }
}
