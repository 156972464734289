import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import {
  getLitersList,
  getLitersCount,
  deleteLitersItem,
  addLitersItem,
  editLiterItem
} from '../../../api/liters/liters';
import {
  LITERS_ADD_REQUEST,
  LITERS_DELETE_REQUEST,
  LITERS_COUNT_REQUEST,
  LITERS_EDIT_REQUEST,
  LITERS_LIST_REQUEST,
  START
} from '../../actions/action-types';
import {
  litersCatalogCountRequestStart,
  litersCatalogCountRequestSuccess,
  litersCatalogCountRequestError,
} from '../../actions/liters/count';
import {
  litersListRequestStart,
  litersListRequestSuccess,
  litersListRequestError
} from '../../actions/liters/list';
import {
  setLitersCatalogSearchData
} from '../../actions/liters/search';
import {
  litersCatalogDeleteRequestSuccess,
  litersCatalogDeleteRequestError
} from '../../actions/liters/delete';
import {
  litersCatalogAddRequestSuccess,
  litersCatalogAddRequestError
} from '../../actions/liters/add';
import {litersCatalogEditRequestError, litersCatalogEditRequestSuccess} from "../../actions/liters/edit";


export default function* litersWatcher() {
  yield takeLatest(`${LITERS_COUNT_REQUEST}${START}`,countWorker);
  yield takeLatest(`${LITERS_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${LITERS_DELETE_REQUEST}${START}`, deleteWorker);
  yield takeLatest(`${LITERS_ADD_REQUEST}${START}`, addWorker);
  yield takeLatest(`${LITERS_EDIT_REQUEST}${START}`, editWorker);
}

function* countWorker() {
  const formData = yield select(({liters}) => liters.form);
  const searchData = yield select(({liters}) => liters.search);
    try {
      const response = yield call(getLitersCount, {...formData, ...searchData});
      const count = parseInt(response.data.count, 10);
      yield put(litersCatalogCountRequestSuccess(count));
      const pageCount = Math.ceil(count / searchData.maxResults);
      yield put(setLitersCatalogSearchData({...searchData, pageCount }));
      yield put(litersListRequestStart());
    } catch(error) {
      yield put(litersCatalogCountRequestError(error));
    }
}

function* listWorker() {
  const formData = yield select(({liters}) => liters.form);
  const searchData = yield select(({liters}) => liters.search);

    try {
        const response = yield call(getLitersList, {...formData, ...searchData});
        yield put(litersListRequestSuccess(response.data));
    } catch(error) {
        yield put(litersListRequestError(error));
    }
}

function* deleteWorker({ payload: itemId }) {
    try {
        yield call(deleteLitersItem, itemId);
        yield put(litersCatalogDeleteRequestSuccess());
        yield put(litersCatalogCountRequestStart());
    } catch(error) {
        yield put(litersCatalogDeleteRequestError(error.response.data));
    }
}

function* addWorker({ payload: data }) {
    try {
      yield call(addLitersItem, data);
      yield put(litersCatalogAddRequestSuccess());
      yield put(litersCatalogCountRequestStart());
    } catch(error) {
      yield put(litersCatalogAddRequestError(error));
    }
}

function* editWorker({ payload: data }) {
    try {
      yield call(editLiterItem, data);
      yield put(litersCatalogEditRequestSuccess());
      yield put(litersCatalogCountRequestStart());
    } catch(error) {
      yield put(litersCatalogEditRequestError(error));
    }
}