import { ERROR, OPTIONS_SAVE_REQUEST, START, SUCCESS } from "../action-types";

export const optionsSaveRequestStart = () => ({
  type: `${OPTIONS_SAVE_REQUEST}${START}`
});

export const optionsSaveRequestSuccess = (data) => ({
  type: `${OPTIONS_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const optionsSaveRequestError = (data) => ({
  type: `${OPTIONS_SAVE_REQUEST}${ERROR}`,
  payload: data
});