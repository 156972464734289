import {GUK_TAB_DATA_REQUEST, START, SUCCESS, ERROR} from "../../../actions/action-types";

const initialState = {
  isLoading: false,
  data: {
    purpose: [],
    techdisributions: [],
    purposeEducationLevels: [],
    purposeEducationForms: [],
    purposeImplementations: [],
    purposeReaders: [],
    jenres: [],
    techperiods: [],
    techCommunics: [],
    outCarriers: [],
    outStatus: [],
  }
};

export const bookEditGukTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GUK_TAB_DATA_REQUEST}${START}`:
      return {
        ...state,
        isLoading: true
      };
    case `${GUK_TAB_DATA_REQUEST}${SUCCESS}`:
      return {
        isLoading: false,
        data: action.payload,
      };
    case `${GUK_TAB_DATA_REQUEST}${ERROR}`:
      return {
        ...state,
        isLoading: false
      };


    default: return state;
  }
};