import { ERROR, RUBRICATOR_PARENT_SEARCH_REQUEST, START, SUCCESS } from "../action-types";

export const rubricatorParentSearchRequestStart = (data) => ({
  type: `${RUBRICATOR_PARENT_SEARCH_REQUEST}${START}`,
  payload: data
});

export const rubricatorParentSearchRequestSuccess = (data) => ({
  type: `${RUBRICATOR_PARENT_SEARCH_REQUEST}${SUCCESS}`,
  payload: data
});

export const rubricatorParentSearchRequestError = (data) => ({
  type: `${RUBRICATOR_PARENT_SEARCH_REQUEST}${ERROR}`,
  payload: data
});
