import {MOBILE_LIBRARY_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";



const initialState = null



export const mobileLibraryListReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${MOBILE_LIBRARY_LIST_REQUEST}${SUCCESS}`:
            return action.mobile_library

        default: return state;
    }
};