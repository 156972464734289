import {SET_BOOK_SCAN_ORDER_FORM_DATA} from "../../../actions/action-types";


const initialState = {
  title: "",
};

export const bookScanOrdersFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOK_SCAN_ORDER_FORM_DATA:
      return action.payload;

    default: return state;
  }
};
