import { combineReducers } from 'redux'
import { electronicCoursesSearchReducer } from './search/searchReducer'
import { electronicCoursesCountReducer } from './count/countReducer'
import { electronicCoursesListReducer } from './list/listReducer'
import { electronicCoursesLoaderReducer } from './loader/loaderReducer'

export const electronicCoursesReducer = combineReducers({
  searchData: electronicCoursesSearchReducer,
  count: electronicCoursesCountReducer,
  list: electronicCoursesListReducer,
  loader: electronicCoursesLoaderReducer,
})
