import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import {TEACHERS_SEARCH_REQUEST} from "../../../actions/action-types";

const initialState = {
    query: "",
    page: 1,
    sortBy: "fullName",
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0
};

export const teacherSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEACHERS_SEARCH_REQUEST:
            return { ...action.payload };

        default:
            return state;
    }
};
