import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import styles from '../../BooksOrder/Modal/BooksOrderModal.module.css';
import {ADD, EDIT} from "../../../constants/modalTypes";
import {Col, Row} from "react-bootstrap";

import {documentBookListRequestStart} from "../../../redux/actions/newBooksOrder/list/list";
import {connect} from "react-redux";
import BooksOrderTable from "../OrderTable";
import {setCoversFormData} from "../../../redux/actions/covers/form";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import MessageModal from "../../MessageModal";
import EditBookForm from "./EditBookForm";
import {orderBooksEditRequestStart} from "../../../redux/actions/newBooksOrder/orderItem/edit";
import {orderBooksDeleteRequestStart} from "../../../redux/actions/newBooksOrder/orderItem/delete";
import ModalWindow from '../../ModalWindow';

const mapStateToProps = (state) => ({
    books: state.newBooksOrder.books,
    latestNumber: state.documents.number,
});

const mapDispatchToProps = {
    requestBooks: documentBookListRequestStart,
    setFormData: setCoversFormData,
    editOrderItem: orderBooksEditRequestStart,
    deleteOrderItem: orderBooksDeleteRequestStart,
};

const defaultFormData = {
    title: '',
    author: '',
    publisher: '',
    year: null,
    isbn: '',
    amount: 0,
    digital_amount: 0,
};
const defaultFormError = {
    amount: '',
    title: '',
    year: '',
    author: '',
    publisher: ''
};

const NewBooksOrderModal = ({modalData, setModalData, requestBooks, books, onSubmit, courses, editOrderItem, deleteOrderItem}) => {
    const {modalType, data} = modalData;
    const documentId = data ? data.id : null;
    const isVisible = !!modalType;
    const booksArray = books.books || [];
    const [currentBook, setCurrentBook] = useState(null);
    const [saveModal, setSaveModal] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [formError, setFormError] = useState(defaultFormError);


    useEffect(() => {
        (documentId && modalType === EDIT) && requestBooks(documentId);
    }, [modalType, requestBooks, documentId]);

    useEffect(() => {
        modalType === ADD && setCurrentBook(null);
    }, [modalType]);

    if (modalType === ADD && !data) {
        setModalData({
            modalType,
            data: {
                documentname: "",
                documenttype: "",
                documentdate: new Date().toISOString().split("T")[0],
                source: ""
            }
        })
    }

    const onSelectFromTable = (data) => {
        setCurrentBook({
            ...data,
            documentid: documentId
        });
        setFormData({
            ...data,
            documentid: documentId
        });
        setFormError(defaultFormError);
    };

    const onSwitchForm = () => {
        if (modalType === EDIT) {
            setCurrentBook(null);
            setFormData(defaultFormData);
        } else {
            setSaveModal(true);
        }

    };

    const validateForm = () => {
        let isFormValid = true;
        if (
            (!formData.amount && !formData.digital_amount)
            ||
            !formData.title
            ||
            !formData.year
            ||
            !formData.author
            ||
            !formData.publisher
        ) {
            isFormValid = false;
        }
        setFormError({
            ...formError,
            amount: !formData.amount && !formData.digital_amount ? 'Необходимо указать количество хотя бы одного вида поступления' : '',
            title: !formData.title ? 'Значение названия не может быть пустым' : '',
            year: !formData.year ? 'Необходимо указать год издания' : '',
            author: !formData.author ? 'Необходимо указать автора' : '',
            publisher: !formData.publisher ? 'Необходимо указать издательство' : '',
        });

        return isFormValid;
    };

    const onBookAdd = () => {
        if (validateForm()) {
            editOrderItem({bookData: formData, document: documentId});
            setCurrentBook(null);
            setFormData(defaultFormData);
        }
    };

    const onBookDelete = () => {
        deleteOrderItem({bookId: currentBook.id, document: documentId});
        setCurrentBook(null);
        setFormData(defaultFormData);
        setFormError(defaultFormError);
    };

    const checkedOnSubmit = () => {
        if (modalData.data.curriculum_course_id) {
            onSubmit(modalData);
            setSaveModal(false);
        } else {
            setModalData({
                modalType, data: {...data, ['modalSubmitError']: true}
            });
        }
    };

    const onChange = (name, value) => {
        setModalData({modalType, data: {...data, [name]: value}})
    };

    const onCloseModal = () => {
        setModalData({modalType: null, data: null});
        setCurrentBook(null);
        setFormData(defaultFormData);
        setFormError(defaultFormError);
    };

    const modalTitle = `Заказ литературы от ${books.creationtime ? new Date(books.creationtime).toLocaleDateString() : new Date().toLocaleDateString()}`;

    return (
        <ModalWindow
            isOpen={isVisible}
            onRequestClose={onCloseModal}
            title={modalTitle}
        >
            {saveModal && modalType === ADD &&
            <MessageModal message={"Для добавления книг вы должны сохранить документ."} className={styles.messageModal}>
                <div className={styles.modalButtonContainer}>
                    <Button onClick={checkedOnSubmit} label={"Сохранить"} style={styles.modalButton}/>
                    <Button onClick={() => setSaveModal(false)} label={"Отмена"} style={styles.modalButton}/>
                </div>
            </MessageModal>}
            {data && <>
                <Row>
                    <Col md={12}>
                        <div className={styles.modalLabel}>
                            <label>ФИО</label>
                            <div>{books.creatorname}</div>
                        </div>
                        <div className={styles.modalLabel}>
                            <label>Дата создания</label>
                            <div>{books.creationtime ? new Date(books.creationtime).toLocaleDateString() : new Date().toLocaleDateString()}</div>
                        </div>
                        <div className={styles.modalLabel}>
                            <label>Дисциплина / учебный курс</label>
                            <div>{books.cathedra}</div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <ModalInputWrapper label={"Учебная программа"}>
                            <select value={data.curriculum_course_id}
                                    className={styles.select}
                                    onChange={({target: {value}}) => onChange("curriculum_course_id", value)}
                                    disabled={modalType === EDIT ? true : null}
                            >
                                <option value={""}>
                                    Не выбрано
                                </option>
                                {courses && courses.map((item) =>
                                    <option value={item.cid} key={item.cid}>{item.course}</option>
                                )}
                            </select>
                        </ModalInputWrapper>
                        {data.modalSubmitError &&
                            <p className={styles.errorMessage}>Необходимо выбрать учебную программу</p>
                        }
                    </Col>
                    <Col md={12}>
                        <div className={styles.booksSection}>
                            {modalType === EDIT &&
                            <BooksOrderTable
                                data={booksArray}
                                handleSelect={onSelectFromTable}
                                selectedId={currentBook ? currentBook.id : null}
                                modalTitle={modalTitle}
                            />
                            }
                        </div>
                    </Col>
                    <Col md={12}>
                        <Button label={"Добавить книгу"}
                                onClick={onSwitchForm}
                                style={'mr-2'}
                        />
                        {currentBook &&
                        <Button label={"Удалить"}
                                colorType={'red'}
                                onClick={onBookDelete}/>
                        }
                        {modalType === EDIT &&
                        <EditBookForm formData={formData} setFormData={setFormData} formError={formError}
                                      currentBook={currentBook}>
                            <div className={'text-right'}>
                                <Button onClick={onBookAdd} label={currentBook ? "Редактировать" : "Добавить"}/>
                            </div>
                        </EditBookForm>
                        }
                    </Col>
                </Row>
                <div className={styles.bottomSection}>
                    {modalType === ADD &&
                    <Button onClick={checkedOnSubmit} label={'Создать'}/>
                    }
                </div>
            </>}
        </ModalWindow>
    );
};

NewBooksOrderModal.propTypes = {
    modalData: T.object,
    setModalData: T.func,
    onSubmit: T.func,
    types: T.array,
    onSave: T.func
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBooksOrderModal);
