import { api } from "../../App";

export const getBookReserveCount = (mid= 0 ) => {
  const params = {
    count: true,
    mid
  };

  return api.get(`/bookreserve`, { params })
};

export const getBookReserveList = (mid = 0, {maxResults, paginateFrom, orderBy, sortDirection}) => {
  const params = {
    maxResults,
    paginateFrom,
    orderBy,
    sortDirection,
    mid
  };

  return api.get(`/bookreserve`, { params })
};