import React from 'react';
import T from 'prop-types';
import styles from '../../BookSupply/Title/BookSupplyTitle.module.css';
import classNames from "classnames";

const BibliographyTitle= ({yearsList, onSelectChange}) => {
    return (
        <div className={styles.head}>
            <p className={styles.title}>
                Список литературы на
            </p>
            <select
                onChange={onSelectChange}
                className={classNames('mr-2', 'ml-2')}
            >
                <option selected disabled>
                    Выберите учебный год
                </option>
                {yearsList.year && yearsList.year.schoolYear &&
                yearsList.year.schoolYear.map(item => (
                    <option value={item.xp_key} key={item.xp_key}>{item.name}</option>
                ))}
            </select>
        </div>
    );
};

BibliographyTitle.propTypes = {
    onSelectChange: T.func.isRequired,
    yearsList: T.object,
};

export default BibliographyTitle;