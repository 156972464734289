import { DOCUMENT_TYPES_SAVE_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const documentTypesSaveRequestStart = (data) => ({
  type: `${DOCUMENT_TYPES_SAVE_REQUEST}${START}`,
  payload: data
});

export const documentTypesSaveRequestSuccess = (data) => ({
  type: `${DOCUMENT_TYPES_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentTypesSaveRequestError = (data) => ({
  type: `${DOCUMENT_TYPES_SAVE_REQUEST}${ERROR}`,
  payload: data
});
