import { AUDIT_LOG_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const auditLogListRequestStart = () => ({
  type: `${AUDIT_LOG_LIST_REQUEST}${START}`
});

export const auditLogListRequestSuccess = (data) => ({
  type: `${AUDIT_LOG_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const auditLogListRequestError = (data) => ({
  type: `${AUDIT_LOG_LIST_REQUEST}${ERROR}`,
  payload: data
});
