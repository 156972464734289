import { call, put, takeLatest, select } from "redux-saga/effects";
import {ELECTRONIC_BOOK_JSON_SAVE, ELECTRONIC_BOOK_ZIP_SAVE, START} from "../../actions/action-types";
import {saveJson, saveZip} from "../../../api/electronicBookImport/api";
import {
    electronicBookJsonSaveError,
    electronicBookJsonSaveSuccess
} from "../../actions/ElectronicBookImport/saveJson";
import {electronicBookZipSaveError, electronicBookZipSaveSuccess} from "../../actions/ElectronicBookImport/saveZip";

export default function* ElectronicBookImportWatcher() {
    yield takeLatest(`${ELECTRONIC_BOOK_JSON_SAVE}${START}`, electronicBookJsonSaveWorker);
    yield takeLatest(`${ELECTRONIC_BOOK_ZIP_SAVE}${START}`, electronicBookZipSaveWorker);
}

function* electronicBookJsonSaveWorker() {
    const { file, format, id, re} = yield select(({ electronicBook }) => electronicBook.formData);
    try {
        const response = yield call(saveJson, file, format, id, re);
        yield put(electronicBookJsonSaveSuccess(response.data));
        
    }   
    catch (error) {
        yield put(electronicBookJsonSaveError(error))
        }
}

function* electronicBookZipSaveWorker( { payload: data, callback } ) {
    try {
        const response = yield call(saveZip, data);
        yield put(electronicBookZipSaveSuccess(`../public/files/ebook/${response.data.id}`));
        callback();
    }   catch (error) {
        yield put(electronicBookZipSaveError(error))
    }
}
// function* electronicBookZipSaveWorker({ payload: data, callback }) {
// console.log(data)
//     try {
//         const { file, id} = yield select(({ electronicBook }) => electronicBook.formData);
//         const response = yield call(saveZip,  file,  id);
//         // response приходит ERROR 'no such id'
//         console.log(`../public/files/ebook/${id}`)
//         yield put(electronicBookZipSaveSuccess(`../public/files/ebook/${id}`));
//     }   catch (error) {
//         console.log(error.response.data)
//         yield put(electronicBookZipSaveError(error))
//     }
// }


