import {PERMISSIONS_BY_CATEGORY_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const accessCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${PERMISSIONS_BY_CATEGORY_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
