import React from 'react';
import T from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import { useFormik } from 'formik';
import * as Yup from "yup"




const defaultValidationSchema = Yup.object().shape({
  from: Yup.date(),
  to: Yup.date()
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});



const TurnoutStatsForm = ({ data, setData, search }) => {
  
  
  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values)
      search()
    },
  });
  
  const { values, errors } = modalForm;

  return (
    <div className={"formContainer"}>
      <Row>
        <Col>
          <ModalInputWrapper onChange={modalForm.handleChange}
                             name="from"
                             error={errors.from}
                             value={values.from}
                             label="С (дата)"
                             type="date"/>
        </Col>
        <Col>
          <ModalInputWrapper onChange={modalForm.handleChange}
                             name="to"
                             error={errors.to}
                             value={values.to}
                             label="До (дата)"
                             type="date"/>
        </Col>

        <Col md={"auto"} className={"text-right"}>
          <ModalInputWrapper label={''}>
            <Button label={"Найти"} onClick={modalForm.handleSubmit}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

TurnoutStatsForm.propTypes = {
  data: T.object,
  setData: T.func,
  search: T.func
};

export default TurnoutStatsForm;
