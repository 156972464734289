import {SET_LOCATION_CHANGE} from "../../actions/action-types";

const initialState = '';


export const historyLocationReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LOCATION_CHANGE:
      return action.payload;

    default: return state;
  }
};
