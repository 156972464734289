import React, { useState, useEffect } from 'react'
import { menuStructure } from '../../../constants/menuStructure'
import styles from './Menu.module.css'
import MenuItem from './Item'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { messageNotificationRequestStart } from '../../../redux/actions/message/notifications'
import { getMenuListStart } from '../../../redux/actions/menuList/menuList'
import { Button } from "antd";
import { CloseOutlined } from '@ant-design/icons'

const mapStateToProps = state => ({
  layoutOptions: state.options.layout,
  messageNotification: state.message.notification,
  menu_list: state.menuList.menu.list,
  mid: state.auth.data.mid,
  secret: state.options.data?.outsecrecy,
  isteacher: state.auth.data.isteacher,
  islibrarian: state.auth.data.islibrarian
})

const mapDispatchToProps = {
  requestMessageNotification: messageNotificationRequestStart,
  getMenuListStart: getMenuListStart
}

const MenuOuter = styled.div`
  background-color: ${props => props.options.menu.background};
`

const Menu = ({ layoutOptions, requestMessageNotification, messageNotification, getMenuListStart, menu_list, mid, secret, isteacher, islibrarian
}) => {
  
  const [isShow, setIsShow] = useState(false);
  const [menuList, setmenuList] = useState([]);

  const getNormalList = (values) => {
    // Шифр
    return values.filter(el => el.parent_id == 0).map(el => ({...el, dropdown: values.filter(_el => _el.parent_id == el.id)}))
  }

  useEffect(() => {
    menu_list && setmenuList(getNormalList(menu_list));
  }, [menu_list])
  
  useEffect(() => {
    requestMessageNotification();
    getMenuListStart();
  }, [])

  return (
    <>
    <div className={styles.buttonShow}><Button onClick={() => setIsShow(!isShow)}>МЕНЮ{isShow ?  <i className={styles.closeIcon}> <CloseOutlined /></i> : ''}</Button></div>
    <MenuOuter options={layoutOptions} className={styles.container}>
      {menuList.map((item, index) => (
        <MenuItem 
          key={`${index.title}_${index}`} 
          mid={mid} 
          isteacher={isteacher} 
          islibrarian={islibrarian} 
          secret={secret} 
          layoutOptions={layoutOptions} 
          {...item} 
          messageNotification={parseInt(messageNotification)} 
          isShow={isShow} 
          setIsShow={setIsShow}/>
        ))}
    </MenuOuter>
  </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
