import {
  ROLE_ADD_NEW_MEMBER,
  ROLE_CLEAR_NEW_MEMBERS
} from "../../../actions/action-types";

const initialState = [];

export const roleNewMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_ADD_NEW_MEMBER:
      return [...state, action.payload];

    case ROLE_CLEAR_NEW_MEMBERS:
      return initialState;

    default:
      return state;
  }
};
