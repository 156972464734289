import { getFileFormat } from './helpers/helpers'
import { Link } from 'react-router-dom'
import { BASE_URL, BASE_URL_WITHOUT_API } from '../constants/api'
import React from 'react'
import { Button } from 'antd'
import { api } from '../App'
import { litersCatalogAddRequestError } from '../redux/actions/liters/add'
import { createNotification } from '../components/notification/notification'

export const checkFileReadable = (file, bookId) => {
  
  let fileFormat = getFileFormat(file.filename)
  
  const openImage = link => {
    
    api.get(link).then(response => {
      
      const link = response.data.url
      const fullLink = `${process.env.REACT_APP_URL || ''}/${link}`
      const imageLink = document.createElement('a')
      imageLink.setAttribute("href", fullLink)
      imageLink.setAttribute('target','_blank')
      imageLink.click()
      
    })
    .catch(() => createNotification('error', 'Ошибка при загрузке файла...'))
  }

  // const openText = filePath => {
  //   api.get(filePath).then(res => {
  //     const location = `${BASE_URL_WITHOUT_API}${res.data.Location}`
  //     // const win = window.open(`${BASE_URL_WITHOUT_API}${location}`, '_blank')
  //     // win.focus()
  //     let link = document.createElement('a')
  //     link.href = location
  //     link.download = location.substr(location.lastIndexOf('/') + 1)
  //     link.click()
  //   })
  // }

  const downloadFile = (link) => {
    api
    .get(link)
    .then(response => {
      const url = `${process.env.REACT_APP_URL || ''}/${response.data.url}`
      var a = document.createElement("a");
      a.setAttribute('href', url)
      a.setAttribute('download', `${file.filename}`)
      a.click();
    })
    .catch(() => createNotification('error', 'Ошибка при загрузке файла...'))
  }

  switch (fileFormat.toUpperCase()) {
    case 'PDF':
      return (
        <Link to={`/pdfViewer/${bookId}/${file.id}`} target='_blank'>
          <Button>Открыть</Button>
        </Link>
      )
    case 'EPUB':
      return (
        <Link to={`/epub_reader/${bookId}/${file.id}`} target='_blank'>
          <Button>Открыть</Button>
        </Link>
      )
    case 'DJVU':
      return (
        <Link to={`/djvu_reader/${bookId}/${file.id}`} target='_blank'>
          <Button>Открыть</Button>
        </Link>
      )
    case 'PNG':
    case 'JPG':
    case 'JPEG':
      return <Button onClick={() => openImage(`/book/${bookId}/file/${file.id}`)}>Открыть</Button>
    default:
      return <Button onClick={() => downloadFile(`/book/${bookId}/file/${file.id}`)}>Загрузить</Button>
  }
}
