import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DataTableCol from '../../../DataTable/Column'
import DataTable from '../../../DataTable'
import styles from './LostDocTable.module.css'
import { ADD, EDIT } from '../../../../constants/modalTypes';
import DocumentsModal from '../../../Documents/Modal'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDocument } from '../../../../redux/actions/documents/current';
import { getDocumentData } from '../../../../api/documents/documents';
import moment from 'moment';


const NotebookLostDocTable = (
    {data},
    ) => {
      const [modalData, setModalData] = useState({ modalType: null, data: null })
      const {latest, current} = useSelector(state => state.documents)
      const {pdf} = useSelector(state => state.lostBooks)
      const {search} = useSelector(state => state.lostBooks)
      const {all: allTypes} = useSelector(state => state.documentTypes)
      const dispatch = useDispatch()

      useEffect(() => {
        setModalData(modalData =>
          modalData.modalType === ADD
            ? { modalType: EDIT, data: latest }
            : modalData
        )
      }, [latest])

    const priceResolver = price => {
        if (!price) return ''
        return price + ' руб '
      }

    const takeActForLostDoc =  item => {
      getDocumentData(item).then(res=>{
        setModalData({ modalType: 'EDIT', data: res.data })
        dispatch(setCurrentDocument(res.data))
      })
      }

    return (
        <div className={styles.container} >
          <DataTable
          data={data}
          title={() => "Список утерянных книг"}
          pagination={false}
          bordered
          id='lost-doc'
          
          >
            <DataTableCol
            style={{width: '100px', display: 'block'}}
            rowWidth='100px'
            title='Дата записи'
            resolver={ ({created} ) =>  created ? moment(created).format("DD.MM.yyyy")  : "Дата не указана"}
            />
            <DataTableCol title="Пользователь" value="username" />
            <DataTableCol title='Инв № утерянной книги' resolver={({del_info}) => del_info.map((el) => <> {el.number || 'отсутствует'}<br/></>)} />
            <DataTableCol title="Название утерянной книги" resolver={({del_info}) => del_info.map((el) => <> {el.title || ''}<br/></>)} />
            <DataTableCol title="Инв № книги на замену" resolver={({income_info}) => income_info.map((el) => <> {el.number || 'отсутствует'}<br/></>)} />
            <DataTableCol title="Название книги на замену" resolver={({income_info}) => income_info.map((el) => <> {el.title || 'отсутствует'}<br/></>)} />

            <DataTableCol title="Сумма"  resolver={({ income_info, price }) =>  income_info.map((el)=> price ? priceResolver(price) : priceResolver(el.sum))} />
            <DataTableCol title="Акт списания" resolver={({del_doc_id})=> <p onClick={()=> takeActForLostDoc(del_doc_id)}>{del_doc_id}</p> }/>
            <DataTableCol title="Приход от поставщика" resolver={({income_doc_id})=> <p onClick={()=> takeActForLostDoc(income_doc_id)}>{income_doc_id}</p> }/>
            <DataTableCol title="Примечание" value="description" key="description"/>

          </DataTable>
         
          {modalData.modalType && (
            <DocumentsModal
            modalData={modalData}
            setModalData={setModalData}
            types={allTypes}
            current={current}
            fromLostDocForTakeAct
            />
          )}

    

            <table id='writeOfActPDFPDFTable' hidden>
                <thead>
                    <tr>
                        <th rowSpan={2}>№ п/п</th>
                        <th rowSpan={2}>ФИО пользователя</th>
                        <th rowSpan={2}>Номер формуляра</th>
                        <th colSpan={5}>Сведения об утерянных документах  </th>
                        <th colSpan={4}>Сведения о принятых документах</th>
                        <th colSpan={2}>Расписка</th>
                        <th rowSpan={2}>Примечание</th>
                    </tr>
                    <tr>
                        <th>инв №</th>
                        <th>автор, название</th>
                        <th>отдел</th>
                        <th>год издания</th>
                        <th>цена</th>

                        <th>автор, название</th>
                        <th>отдел</th>
                        <th>год издания</th>
                        <th>цена</th>

                        <th>пользователя</th>
                        <th>заведующего библиотекой</th>
                            
                    </tr>
                 
                </thead>
                <tbody>
                {pdf?.map((element, index) => {
                  console.log(element.del_info)
                const { username, price, user_id} = element
                //TODO department = '', year='', priceDel='' поля для бэка если они есть
                const {number: numberDel, title: titleDel, departmentDel = '', yearDelBook='', priceDel='', sum: sumDel } = element.del_info[0]
                const {title: titleInc, departmentInc = '', yearIncBook='', priceInc='', sum: sumIn } = element.income_info[0]
                const rowSpan = element.del_info.length

             
                                   
                return (
                  element.del_info.map((el, i) => {
                    const first3visible = !i
                    return (
                      <>
                        <tr>
                        {first3visible && <td rowSpan={rowSpan}>{index + 1}</td>}
                        {first3visible && <td rowSpan={rowSpan}>{username}</td>}
                        {first3visible && <td rowSpan={rowSpan}>{user_id}</td>}
                    

                          <td>{el.number}</td>
                          <td>{el.title}</td>
                          <td>{el.department}</td>
                          <td>{yearDelBook}</td>
                          <td>{el.sum}</td>

                      

                        
                        <td>{titleInc}</td>
                        <td>{departmentInc}</td>
                        <td>{yearIncBook}</td>
                        <td>{sumIn || price}</td>
                        </tr>
                        </>
                    )
                  }
                    )
                )

                })}
                <tr>
                <td></td>
                <td colSpan={5}>ИТОГО:</td>
                <td>{}</td>
                <td>{data.reduce((a, i) => a + ( +i.del_info[0].sum ), 0) || 0}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{data.reduce((a, i) => a + ( +i.income_info[0].sum || +i.price ), 0) || 0}</td>
                </tr>
            </tbody>
            </table>
            
         </div>
        
    );
};

NotebookLostDocTable.propTypes = {
    data: PropTypes.array,
};

export default NotebookLostDocTable;