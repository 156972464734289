import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { exemptTestCountRequestStart } from '../../redux/actions/exempttests/count'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import Table from '../../components/ExemptTests/Table'
import Pagination from '../../components/Pagination'
import { setExemptTestSearchData } from '../../redux/actions/exempttests/search'
import Modal from '../../components/ExemptTests/Modal'
import { Button, Space } from 'antd'
import { ADD, EDIT } from '../../constants/modalTypes'
import { exemptTestAddRequestStart } from '../../redux/actions/exempttests/create'
import { exemptTestEditRequestStart } from '../../redux/actions/exempttests/edit'
import { exemptTestDeleteRequestStart } from '../../redux/actions/exempttests/delete'
import { ExemptTestsPDFPrint } from '../../components/ExemptTests/PDFPrint'
import Form from '../../components/ExemptTests/Form'
import { api } from '../../App'
import moment from 'moment'

const mapStateToProps = ({ exemptTest }) => ({
  count: exemptTest.count,
  list: exemptTest.list,
  loader: exemptTest.loader,
  searchData: exemptTest.search,
})

const mapDispatchToProps = {
  requestList: exemptTestCountRequestStart,
  setSearchData: setExemptTestSearchData,
  addRequest: exemptTestAddRequestStart,
  editRequest: exemptTestEditRequestStart,
  deleteRequest: exemptTestDeleteRequestStart,
}

const ExemptTests = ({ requestList, count, list, loader, searchData, setSearchData, addRequest, editRequest, deleteRequest }) => {
  useEffect(() => {
    requestList()
  }, [])

  const [groups, setGroups] = useState([])

  useEffect(() => {
    api.get('/groupname').then(res => {
      setGroups(res.data)
    })
  }, [])

  const [modalData, setModalData] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  const closeModal = () => {
    setModalData(null)
  }

  const addItem = () => {
    setModalData({
      type: ADD,
      data: {},
    })
  }

  const editItem = item => {
    setModalData({
      type: EDIT,
      data: item,
    })
  }

  const deleteItem = () => {
    if (selectedValue) {
      deleteRequest(selectedValue)
      setSelectedValue(null)
    }
  }

  const selectItem = id => {
    let s = id === selectedValue ? null : id
    setSelectedValue(s)
  }

  const submitModal = values => {
    if (modalData.type === ADD) {
      addRequest(values)
    } else if (modalData.type === EDIT) {
      editRequest(values)
    }
    closeModal()
  }

  const formattedDate = (date) => moment(date).format('DD.MM.YYYY')

  return (
    <SceneContainer title='Журнал изъятых тестов'>
      {modalData && <Modal modalData={modalData} onSubmit={submitModal} closeModal={closeModal} />}

      <Form data={searchData} setData={setSearchData} onSubmit={requestList} />

      <PaginationComponent />

      <Space className={'mb-3'}>
        <Button type='primary' onClick={addItem}>
          Добавить
        </Button>
        <Button type='primary' onClick={deleteItem} danger disabled={!selectedValue}>
          Удалить
        </Button>
      </Space>

      <ExemptTestsPDFPrint
        orientation={'portrait'}
        title={'Журнал изъятых тестов'}
        currentPage={searchData.page}
        resultsPerPage={searchData.maxResults}
        data={list}
        fromDate={formattedDate(searchData.from)}
        toDate={formattedDate(searchData.to)}
      />

      {loader ? (
        <SpinnerOuter />
      ) : (
        <Table
          data={list}
          onCellClick={item => editItem(item)}
          editItem={editItem}
          selectedValue={selectedValue}
          selectItem={selectItem}
        />
      )}

      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ExemptTests)
