import { ERROR, LIBRARY_TYPES_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const libraryTypesListRequestStart = () => ({
  type: `${LIBRARY_TYPES_LIST_REQUEST}${START}`
});

export const libraryTypesListRequestSuccess = (data) => ({
  type: `${LIBRARY_TYPES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryTypesListRequestError = (error) => ({
  type: `${LIBRARY_TYPES_LIST_REQUEST}${ERROR}`,
  payload: error
});
