import {
    call,
    put,
    takeLatest,
    select
} from "redux-saga/effects";
import {
    SCHEDULER_LIST_REQUEST,
    SCHEDULER_COUNT_REQUEST,
    START, SCHEDULER_ENABLED_REQUEST,
} from "../../actions/action-types";
import {
    getSchedulerList,
    getSchedulerListCount, setEnabledSchedulerItem
} from "../../../api/sheduler/sheduler";
import {
    schedulerCountRequestError, schedulerCountRequestStart,
    schedulerCountRequestSuccess
} from "../../actions/scheduler/count";

import {
    schedulerListRequestError,
    schedulerListRequestStart,
    schedulerListRequestSuccess
} from "../../actions/scheduler/list";
import {setSchedulerSearchData} from "../../actions/scheduler/search";
import {schedulerEditRequestError, schedulerEditRequestSuccess} from "../../actions/scheduler/categories/edit";


export default function* schedulerListWatcher() {
    yield takeLatest(`${SCHEDULER_COUNT_REQUEST}${START}`, countWorker);
    yield takeLatest(`${SCHEDULER_LIST_REQUEST}${START}`, listWorker);
    yield takeLatest(`${SCHEDULER_ENABLED_REQUEST}${START}`, enableWorker);

}

function* countWorker() {
    const formData = yield select(({message}) => message.form);
    const searchData = yield select(({message}) => message.search);

    try {
        const response = yield call(getSchedulerListCount, formData);
        const count = parseInt(response.data.count, 10);
        yield put(schedulerCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setSchedulerSearchData({...searchData, pageCount}));
        if (count > 0) {
            yield put(schedulerListRequestStart());
        }
    } catch (error) {
        yield put(schedulerCountRequestError(error));
    }
}

function* listWorker() {
    const formData = yield select(({message}) => message.form);
    const searchData = yield select(({message}) => message.search);
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getSchedulerList, {...formData, ...searchData});
        yield put(schedulerListRequestSuccess(response.data));
    } catch (error) {
        yield put(schedulerListRequestError(error));
    }
}

function* enableWorker({ payload: id, enabled }) {
    try {
        yield call(setEnabledSchedulerItem, id, enabled);
        yield put(schedulerEditRequestSuccess());
        yield put(schedulerCountRequestStart());
    } catch(error) {
        yield put(schedulerEditRequestError(error));
    }
}