import React, { useState } from 'react';
import T from 'prop-types';
import ModalWindow from "../../ModalWindow";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { ADD, EDIT } from "../../../constants/modalTypes";
import Button from "../../Button";

const LitersModal = ({ isOpen, closeModal, data, setData, onSubmit }) => {
  const [formErrors, setFormErrors] = useState({
    name: ''
  });

  const checkOnSubmit = () => {
    if(!data.name) {
      setFormErrors({ ...formErrors, name: 'Поле не может быть пустым' })
    } else {
      setFormErrors({ ...formErrors, name: '' });
      onSubmit();
    }
  };

  return (
    <ModalWindow
      title={`Литер - ${data.type === ADD ? 'добавление' : 'редактирование'}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
      width={'600px'}
      height={'300px'}
    >
      <div>
        <ModalInputWrapper
          label={'Название'}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          value={data.name}
          error={formErrors.name}
        />
      </div>
      <div className={'d-flex justify-content-end'}>
        <Button
          label={'Сохранить'}
          onClick={checkOnSubmit}
        />
      </div>
    </ModalWindow>
  );
};

LitersModal.propTypes = {
  isOpen: T.bool.isRequired,
  closeModal: T.func.isRequired,
  data: T.object,
  setData: T.func,
  onSubmit: T.func.isRequired,
};

export default LitersModal;