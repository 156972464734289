import {
  HEADER_IMAGES_LIST_REQUEST,
  SUCCESS
} from '../../../actions/action-types';

const initialState = {
  "logo": [],
  "backgroundimage": []
};

export const layoutImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${HEADER_IMAGES_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};
