import {
  COVERS_LIST_REQUEST,
  SUCCESS,
  START,
  SET_LOCATION_CHANGE,
  SEARCH_BY_IMAGE_REQUEST,
  COVERS_LOADING_FALSE,
} from '../../../actions/action-types'

const initialState = false

export const coversLoadSuccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case COVERS_LOADING_FALSE:
      return true

    case `${COVERS_LIST_REQUEST}${SUCCESS}`:
      return true

    case `${COVERS_LIST_REQUEST}${SUCCESS}`:
      return true

    case `${SEARCH_BY_IMAGE_REQUEST}${START}`:
      return false

    case `${SEARCH_BY_IMAGE_REQUEST}${SUCCESS}`:
      return true

    case SET_LOCATION_CHANGE:
      return initialState

    default:
      return state
  }
}
