import { call, put, takeLatest, select } from "redux-saga/effects";
import { OPTIONS_SAVE_REQUEST, START } from "../../../actions/action-types";
import { saveOptions } from "../../../../api/options/options";
import { optionsSaveRequestError, optionsSaveRequestSuccess } from "../../../actions/options/save";

export default function* optionsSaveWatcher() {
  yield takeLatest(`${OPTIONS_SAVE_REQUEST}${START}`, optionsSaveWorker)
}

function* optionsSaveWorker() {
  const data = yield select(({options}) => options.data);
  try {
    const response = yield call(saveOptions, data);
    yield put(optionsSaveRequestSuccess(response.data));
  } catch (error) {
    yield put(optionsSaveRequestError(error));
  }
}