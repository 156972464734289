import React from 'react'
// import { FaFile } from 'react-icons/fa';
// import { IoMdClose } from "react-icons/io";
import styles from './FileLabel.module.css'
import { CloseOutlined, FileOutlined } from '@ant-design/icons'


export default function FileLabel({
    fileName,
    imageMode,
    fileLink,
    onDelete,
    onClick,
    key
}) {
  
  
  const deleteFile = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete()
  }

  const fileClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onClick) {
      onClick()
    } else if (fileLink) {
      const link = document.createElement('a')
      link.setAttribute('href', fileLink)
      link.setAttribute('target', 'blank')
      link.setAttribute('rel', 'noopener noreferrer')
      link.setAttribute('download', fileName)
      link.click()
    }
  }

  return (
    <div
      key={key || fileName}
      onClick={fileClick}
      className={styles.fileBlock}
    >
      {onDelete && <CloseOutlined className={styles.deleteIcon} onClick={deleteFile}/>}
      {imageMode
        ? <div><img src={fileLink} alt="o_O" /></div>
        : <i><FileOutlined className={styles.fileIcon}/></i>}
      <span>{fileName}</span>
    </div>
  )
}
