import React from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import InputWrapper from '../../../Modal/InputWrapper'
import T from 'prop-types'
import Button from '../../../Button'
import {EDIT} from '../../../../constants/modalTypes'
import moment from 'moment'
import {Col, Row} from 'react-bootstrap'
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from './AdminDirectoryModal.module.css'
import {createNotification} from '../../../notification/notification'
import {useState} from 'react'
import ModalWindow from '../../../ModalWindow'
import {pack} from './Pack'
import {toLine} from './toLine'
import { useEffect } from 'react'

const creatingValidationSchema = Yup
    .object()
    .shape({
        name: Yup
            .string()
            .max(255, 'Максимальная длинна поля 255 символов')
            .required('Заполните поле'),
        number: Yup
            .number()
            .max(99999999, 'Максимальная длинна поля 8 символов')
            .required('Заполните поле'),
        doc_name: Yup
            .string()
            .max(255, 'Максимальная длинна поля 255 символов')
            .required('Заполните поле'),
        description: Yup
            .string()
            .max(255, 'Максимальная длинна поля 255 символов')
            .required('Заполните поле'),
        date: Yup
            .string()
            .required('Заполните поле'),
    })

const editingValidationSchema = Yup
    .object()
    .shape({
        name: Yup
            .string()
            .max(255, 'Максимальная длинна поля 255 символов')
            .required('Заполните поле'),
        number: Yup
            .number()
            .max(99999999, 'Максимальная длинна поля 8 символов')
            .required('Заполните поле'),
        doc_name: Yup
            .string()
            .max(255, 'Максимальная длинна поля 255 символов')
            .required('Заполните поле'),
        description: Yup
            .string()
            .max(255, 'Максимальная длинна поля 255 символов')
            .required('Заполните поле'),
        date: Yup
            .string()
            .required('Заполните поле'),
    })

const fieldArrayIsNull = [
    {
        name: "Обязательный",
        value: 'true'
    }, {
        name: "Необязательный",
        value: 'false'
    }
]

const fieldArrayType = [
    {
        name: "Текстовое",
        value: "CHARACTER VARYING"
    }, {
        name: "Число",
        value: "INT"
    }, {
        name: "Булевый",
        value: "BOOLEAN"
    }
]

const defaultValues = ['Код', 'Название', 'Описание']

const AdminDerectoryModalForm = ({
    modalData,
    onSubmit,
    modalType,
    saveUser,
    groups,
    setModalData
}) => {

    const [selectedId,
        setSelectedId] = useState(null);
    const [editCopy,
        setEditCopy] = useState(null);
    const [isVisible,
        setIsVisible] = useState(false);
    const [isDrag,
        setIsDrag] = useState(null);
    const [isDrop,
        setIsDrop] = useState(null);
    const [isDragOver,
        setIsDragOver] = useState(null);
    const [isShow,
        setIsShow] = useState([]);
    const [search,
        setSearch] = useState('');

    const handleSelect = (ids) => {
        if (selectedId == ids) {
            setSelectedId(null);
        } else {
            setSelectedId(ids);
        }
    }

    const getModal = (modalData) => {
        let value = modalData
            ?.data;
        value.parent_id = "0";
        if (modalData && modalData
            ?.modalType != "ADD") {
            value.columns = modalData
                .data
                .columns
                .map(el => ({
                    ...el,
                    // parent: 0,
                    // code: '',
                    // id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 1)
                }));
        } else {
            value.columns = modalData
                .data
                .columns
                .map(el => ({
                    ...el,
                    // parent: 0,
                    // code: '',
                    // id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 1)
                }));
                value.columns = [{
                    id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 1),
                    len: 255,
                    name: "parent",
                    type: "CHARACTER VARYING",
                    is_null: true,
                    // Шифр
                    parent: 0,
                    description: 'parent',
                },
                {
                    id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 2),
                    len: 3,
                    name: "Код",
                    type: "CHARACTER VARYING",
                    // Шифр
                    parent: 0,
                    is_null: true,
                    description: "Код",
                    default_value: false
                },
                {
                    id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 2),
                    len: "20",
                    name: "Название",
                    type: "CHARACTER VARYING",
                    // Шифр
                    parent: 0,
                    is_null: true,
                    description: "Название",
                    default_value: false
                },
                {
                    id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 3),
                    len: "255",
                    name: "Описание",
                    type: "CHARACTER VARYING",
                    // Шифр
                    parent: 0,
                    is_null: "false",
                    description: "Описание",
                    default_value: false
                } ]   
        }
        return value;
    }

    const formik = useFormik({
        initialValues: getModal(modalData),
        validateOnChange: false,
        validationSchema: modalType === EDIT
            ? editingValidationSchema
            : creatingValidationSchema,
        onSubmit: values => {
            onSubmit({
                ...values
            })
        }
    })

    const handleAddColumns = () => { // добавление нового поля справочника
        let value = formik.values.columns;

        if (selectedId == null) {
            value = [
                [
                    ...value, {
                        name: "",
                        type: "CHARACTER VARYING",
                        is_null: true,
                        // Шифр
                        parent: 0,
                        // code: '',
                        id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 1),
                        default_value: false,
                    }
                ]
            ]
        } else {
            value = [
                [
                    ...value, {
                        name: "",
                        type: "CHARACTER VARYING",
                        is_null: true,
                        parent: selectedId,
                        // code: '',
                        id: Date.now() + Math.floor(Math.random() * (10000 - 1) + 1),
                        default_value: false,
                    }
                ]
            ]
        }
        formik.setFieldValue('columns', ...value)
    }

    const handleEditColumns = (elem) => { //редактирование полей справочника
        setSelectedId(elem.id)
        if (!isVisible && elem.id) {
            setIsVisible(true)
        }
        const value = formik
            .values
            .columns
            .find(el => el.id == elem.id);
        setEditCopy(value);
    }

    const handleCancelEdit = () => {
        const value = formik
            .values
            .columns
            .map(el => {
                if (el.id == selectedId) {
                    return editCopy;
                } else {
                    return el
                }
            })
        formik.setFieldValue('columns', value);
        setEditCopy(null);
        setIsVisible(false);
        setSelectedId(null);
    }
    
    const handleSubmitClick = () => {
        if (formik.values.columns.find(el => el.err)) {
            return createNotification('error', 'Есть Поля справочника с одинаковым полем "Код".');
        }
        // if (formik.values.columns.find(el => el.code.trim() === '')) {
        //     return createNotification('error', 'Есть Поле справочника с пустым полем "Код".');
        // }
        if (formik.values.columns.find(el => el.name.trim() === '')) {
            return createNotification('error', 'Есть Поле справочника с пустым полем "Наименование".');
        }
        
        if (formik.values.columns.filter(el => el.type != 'delete').length < 1) {
            createNotification('error', 'Создайте хотя бы одно поле справочника');
        } else {
            if (formik.values.columns.filter(el => el.name && el.description && ((el.type == 'CHARACTER VARYING' && el.len) || (el.type != 'CHARACTER VARYING'))).length == formik.values.columns.length) {
                formik.handleSubmit();
            } else {
                createNotification('error', 'Поля "Наименование", "Описание" и "Длина" обязательные.');
            }
        }
    }

    const handleInput = (e, id, parent) => {
        const defValue = formik.values.columns;
        const newValue = [...defValue].map(el => el.id == id
            ? ({
                ...el,
                [e.name]: e.value
            })
            : el);

        // if (e.name == 'code' && formik.values.columns.find(el => el.parent == parent).code == e.value) {
        //     setErrors(prev => ({
        //         ...prev,
        //         code: 'Такой код уже существует'
        //     }))
        // } else if (e.name == 'code' && e.value.length < 1) {
        //     setErrors(prev => ({
        //         ...prev,
        //         code: 'Код не может быть пустым'
        //     }))
        // } else if (e.name == 'code' && formik.values.columns.find(el => el.parent == parent).code != e.value) {
        //     setErrors(prev => ({
        //         ...prev,
        //         code: ''
        //     }))
        // }

        if (e.name == 'type' && e.value != 'CHARACTER VARYING') {
            const newValueType = newValue.map(el => el.id == id
                ? ({...el,len: ''})
                : el);
            formik.setFieldValue('columns', newValueType);
        } else {
            formik.setFieldValue('columns', newValue);
        }
    }

    const handleDeleteColumns = () => { // удаление поля справочника
        let value = formik.values.columns;
        value = value.map(el => el.id == selectedId ? ({...el, to_delete: true}) : el);
        // value = toLine(pack(value, value.parent));    
        // value = value.filter(el => el.id != selectedId);
        formik.setFieldValue('columns', value);
        setSelectedId(null);
    }

    const clearRows = (newId, dragId) => {
        if (dragId != isDrop
            ?.id) {} else {
            const temporaryList = formik
                .values
                .columns
                .filter(row => !['topRowChoice', 'bottomRowChoice'].includes(row.id))
            formik.setFieldValue('columns', temporaryList);
            setIsDragOver(null)
        }
    }

    const getNewRow = (newRow) => {
        const array = formik.values.columns;
        const index = array.findIndex(el => el.id == newRow.id);
        let temporaryList = null;
        let fullNewRow = null;

        if (newRow.id == 'topRowChoice') {
            temporaryList = array.filter((row, i) => !['bottomRowChoice'].includes(row.id) || i == index + 1);
        } else if (newRow.id == 'bottomRowChoice') {
            temporaryList = array.filter((row, i) => !['topRowChoice'].includes(row.id) || i == index - 1);
        } else {
            temporaryList = array.filter(row => !['topRowChoice', 'bottomRowChoice'].includes(row.id));
        }

        fullNewRow = handleMove(isDrag, newRow, temporaryList, newRow);

        if (fullNewRow && (isDrag.id != newRow.id)) {
            formik.setFieldValue('columns', fullNewRow.filter(el => !['topRowChoice', 'bottomRowChoice'].includes(el.id)));
        } else {
            formik.setFieldValue('columns', array.filter(el => !['topRowChoice', 'bottomRowChoice'].includes(el.id)));
        }
    }

    const addChoiceRows = (lowRow) => {
        setIsDragOver(lowRow)
        const clearedList = formik
            .values
            .columns
            .filter(row => !['topRowChoice', 'bottomRowChoice'].includes(row.id))
        const temporaryList = clearedList.reduce((acc, row) => {
            if (lowRow.id === row.id) {
                return [
                    ...acc, {
                        name: "Сверху",
                        type: "CHARACTER VARYING",
                        is_null: true,
                        parent: lowRow.parent,
                        // code: '',
                        id: 'topRowChoice',
                        default_value: false
                    },
                    row, {
                        name: "Снизу",
                        type: "CHARACTER VARYING",
                        is_null: true,
                        parent: lowRow.parent,
                        // code: '',
                        id: 'bottomRowChoice',
                        default_value: false
                    }
                ]
            } else {
                return [
                    ...acc,
                    row
                ]
            }
        }, []);

        formik.setFieldValue('columns', temporaryList);
        setIsDragOver(null)
    }

    const handleMove = (from, to, list = formik.values.columns, newRow) => { //перемещение пунктов таблицы
        const checkInclude = toLine(pack(formik.values.columns, from.id)).find(el => el.id == newRow.id);
        let value = pack(list);
        value = toLine(value);
        value = value.filter(el => el.id != from.id);
        value = value.reduce((acc, item) => {
          if (item.id == to.id) {
                if (item.id == newRow.id && item.id == 'topRowChoice') {
                    const err = formik.values.columns.filter(el => el.parent === to.parent);
                    return [
                      ...acc, 
                            {...from,
                              parent: to.parent,
                              err: err.length > 0 ? true : false  
                            },
                    ];
                }
                if (item.id == newRow.id && item.id == 'bottomRowChoice') {  
                  const err = formik.values.columns.filter(el => el.parent === to.parent);
                  return [
                        ...acc, 
                            {...from,
                              parent: to.parent,
                              err: err.length > 0 ? true : false  
                            },
                    ];
                } 
                else {
                  const err = formik.values.columns.filter(el => (el.parent === to.parent) 
                //   && (el.code == from.code)
                  )  
                  return [
                        ...acc,
                        item, {
                            ...from,
                            parent: to.id, 
                            err: err.length > 0 ? true : false
                        }
                    ];
                }
            } 
            else {
              // const err = formik.values.columns.filter(el => el.parent === to.parent).find(el => el.code == from.code);
                return [
                    ...acc,
                    {...item}
                ];
            }
        }, [])

        value = value.map(el => delete el.columns && el);
        if (!checkInclude) {
            return value
        } else 
            return list
    }

    const handleStartDrag = (item) => {
        setIsDrag(item)
    }

    const handleDragEnter = (lowRow) => {
        if (lowRow.id === isDrag.id) {
            clearRows(lowRow.id, isDrag.id)
            return
        } else {
            // getNewRow(lowRow.id,  isDrag.id)
        }

        if (['topRowChoice', 'bottomRowChoice'].includes(lowRow.id)) 
            return;

        const checkInclude = toLine(pack(formik.values.columns, isDrag.id)).find(el => el.id == lowRow.id);

        if (!checkInclude) {
            addChoiceRows(lowRow)
        }
    }

    const handleFinishDrag = (item) => {
        setIsDrop(item);
        getNewRow(item);
        setIsDragOver(null);
    }

    const handleSavePopup = (val) => {
        if (val.name.trim() == '') {
            return createNotification('error', 'Необходимо указать наименование справочника');}
        if (val.type == 'CHARACTER VARYING' && !val.len) {
            return createNotification('error', 'Необходимо указать длину справочника');}

        const values = formik.values.columns.map(el => val.id === el.id ? ({...el, err: false}) : (el))
        formik.setFieldValue('columns', values);
        setIsVisible(false);
        setSelectedId(null);
      }
    

    const handleSearch = (e) => {
        setSearch(e.target.value);
        return(
            formik.setFieldValue(
                'columns', modalData.data.columns
            )
        )
    }

    useEffect(() => {
        if (search) {
            const filteredList = formik.values.columns.filter(el => 
                // el.code.toLowerCase().includes(search.toLowerCase()) || 
                el.name.toLowerCase().includes(search.toLowerCase()) || el.description.toLowerCase().includes(search.toLowerCase()));
            formik.setFieldValue('columns', filteredList);
        }
    }, [search])
    

    return (
      <div>
        <div style={{marginTop: '1rem'}}>
        <InputWrapper
          label={'Наименование справочника'}
          value={formik.values.name}
          name='name'
          onChange={formik.handleChange}
          error={formik.errors.name}
        //   onKeyPress={(event) => /[^A-zА-яЁё ]/.test(event.key) && event.preventDefault()}
        />
        </div>
        <InputWrapper
          label={'Описание справочника'}
          value={formik.values.description}
          name='description'
          onChange={formik.handleChange}
          error={formik.errors.description}
        />
        <h6 className={styles.title}>Нормативный документ</h6>
          <Row >
          <Col md={9}>
          <InputWrapper
          label={'Номер документа'}
          value={formik.values.number}
          name='number'
          type="number"
          onKeyPress={
                      (event) => !/[.0-9]/.test(event.key) && event.preventDefault()
                   || (event).target.value.length > 7 && event.preventDefault() 
            }
          onChange={(e) => formik.setFieldValue('number', e.target.value)}
          error={formik.errors.number}
          />
          </Col>
        <Col md={3}>
        <InputWrapper
          label={'Дата документа'}
          value={moment(formik.values.date).format('YYYY-MM-DD')}
          name={'date'}
          onChange={formik.handleChange}
          error={formik.errors.date}
          type='date'
        />
        </Col>
        </Row>
          
          <InputWrapper
          label={'Наименование документа'}
          value={formik.values.doc_name}
          name={'doc_name'}
          onChange={formik.handleChange}
          error={formik.errors.doc_name}
        />
  
        <h6 className={styles.title}>Поля справочника</h6>

        {/* <InputWrapper
          label={'поиск по коду и наименованию'}
          value={search}
          name={'search'}
          onChange={(e) => handleSearch(e)}
        /> */}

        <div>
            <div style={{marginTop: '1rem', display: 'flex'}}>
            <div style={{margin: '.5rem'}}><Button label={'Добавить'} type={'button'} onClick={() => handleAddColumns()} /></div>
            <div style={{margin: '.5rem'}}><Button label={'Удалить'} type={'button'} onClick={() => handleDeleteColumns()} /></div>
        </div>
        <div className={styles.container} >
              <table style={{width: '100%'}}>
                  <thead>
                  <tr>
                      <th colSpan={4}>Наименование</th>
                      <th colSpan={10}>Описание</th>
                      <th colSpan={3}>Выбрано</th>
                  </tr>
                  </thead>
                  <tbody>
                      {formik.values.columns ?
                      formik.values.columns
                      .filter(el => el.to_delete !== true)
                      .filter(el => el.name != 'parent')
                      .map((item) => 
                      <tr className={styles.row}>
                      <td colSpan={4} onClick={() => handleEditColumns(item)}>{item.name}{defaultValues.find(name => name == item.name) ? '*' : ''} </td>
                      <td colSpan={10} onClick={() => handleEditColumns(item)}>{item.description} </td>
                      <td colSpan={3} className={styles.icons}>
                          <input 
                            className={styles.input} 
                            onClick={() => handleSelect(item.id)} 
                            type='checkbox' 
                            checked={item.id == selectedId ? selectedId : false} 
                            disabled={defaultValues.includes(item.name) ? true : false}
                            />
                      </td>
                      </tr>
                      )
                      : <></>}
                  </tbody>
              </table>
          </div>
          <ModalWindow
        isOpen={isVisible}
        title={`Поле справочника: Редактирование`}
        onRequestClose={() => handleCancelEdit()}
        >
        { 
          formik.values.columns?.filter(el => el?.id == selectedId).map((el) => 
            <React.Fragment key={el.id+'_'+el.parent}>
            <Row key={el.id} className={styles.atrr}>
            <Col md={3}>
              <ModalInputWrapper
                  label={"Наименование"}
                  type="text"
                  value={el.name}
                  name="name"
                  disabled={defaultValues.includes(el.name) ? true : false}
                  onChange={({target}) => handleInput(target, el.id)}
              />
                <ModalInputWrapper
                  label={"Обязательный"}>
                    <select
                      name={"is_null"}
                      value={el?.is_null}
                      onChange={({target}) => handleInput(target, el.id)}
                      >
                      {fieldArrayIsNull.map((item) =>
                          <option key={item.name} value={item.value}>{item.name}</option>
                      )}
                    </select>
                </ModalInputWrapper>
            </Col>
            
            <Col md={2}>
                <ModalInputWrapper
                  label={"Тип поля"}>
                    <select
                      disabled={defaultValues.includes(el.name) ? true : false}
                      name={"type"}
                      onChange={({target}) => handleInput(target, el.id)}
                      value={el?.type}
                      >
                        {fieldArrayType.map((item, index) =>
                            <option key={'fd' + index} value={item.value}>{item.name}</option>
                        )}
                    </select>
                </ModalInputWrapper>
            
              <ModalInputWrapper
                  label="Длина поля"
                  type="number"
                  value={el.len}
                  disabled={el.type != 'CHARACTER VARYING'}
                  name="len"
                  onChange={({target}) => handleInput(target, el.id)}>
              </ModalInputWrapper>
            </Col>
  
            <Col md={6}>
              <ModalInputWrapper
                  label={"Описание"}
                  type="textarea"
                  value={el.description}
                  name="description"
                  onChange={({target}) => handleInput(target, el.id)}>
              </ModalInputWrapper>
            </Col>
        </Row>    
        <div style={{display:'flex'}}>
        <div style={{margin:'.5rem'}}><Button label={'Сохранить'} type={'button'} 
        // disabled={errors.code ? true : false} 
        onClick={() => handleSavePopup(el)} /></div>
        <div style={{margin:'.5rem'}}><Button label={'Отменить'} type={'button'} onClick={() => handleCancelEdit()} /></div></div>
        
        </React.Fragment>
          )
        }
        </ModalWindow>
        </div>
        
        <div className={'d-flex justify-content-end'}>
        <div style={{margin:'0 .5rem 0 0'}}>
          <Button label={'Отменить'} type={'button'} onClick={() => setModalData({ modalType: null, data: null })} colorType={'red'} />
        </div>
          <Button label={'Сохранить'} type={'button'} onClick={() => handleSubmitClick()} />
        </div>
      </div>
    )

}

AdminDerectoryModalForm.propTypes = {
    modalData: T.object.isRequired,
    onSubmit: T.func.isRequired
}

export default AdminDerectoryModalForm