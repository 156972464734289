import { LIBRARY_MATERIALS_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const libraryMaterialsListRequestStart = (id) => ({
  type: `${LIBRARY_MATERIALS_LIST_REQUEST}${START}`,
  payload: id
});

export const libraryMaterialsListRequestSuccess = (data) => ({
  type: `${LIBRARY_MATERIALS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryMaterialsListRequestError = (data) => ({
  type: `${LIBRARY_MATERIALS_LIST_REQUEST}${ERROR}`,
  payload: data
});

