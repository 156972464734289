import { api } from "../../App";

export const getLiter2roleCount = ({ roleid, ...otherParams }) => {
  const params = {
    count: true,
    roleid: roleid,
    ...otherParams
  };

  return api.get(`/liter2role`, {params});
};

export const getLiter2role = ({ roleid, ...otherParams }) => {
  const params = {
    roleid: roleid,
    ...otherParams
  };

  return api.get(`/liter2role`, {params});
};

export const addLiter2role = ({ role, literid, access }) => {
  return api.post(`/liter2role`, {role, literid, access});
};

export const editLiter2role = ({ role, literid, access, itemid }) => {
  return api.put(`/liter2role/${itemid}`, {role, literid, access});
};

export const editLiter2roleGroup = (payload) => {
  return api.put(`/liter2role_all`, payload);
};