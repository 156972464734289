import { ERROR, LIBRARY_FUND_LIST_REQUEST, START, SUCCESS } from "../../../action-types";

export const libraryFundListRequestStart = (libraryId) => ({
  type: `${LIBRARY_FUND_LIST_REQUEST}${START}`,
  payload: libraryId
});

export const libraryFundListRequestSuccess = (data) => ({
  type: `${LIBRARY_FUND_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryFundListRequestError = (error) => ({
  type: `${LIBRARY_FUND_LIST_REQUEST}${ERROR}`,
  payload: error
});
