import { MEDIA_RESOURCE_GET_FOR_READER_REQUEST, SUCCESS } from "../../../../actions/action-types";

const initialState = [];

export const listReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${MEDIA_RESOURCE_GET_FOR_READER_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};