import { RUBRICATOR_ITEM_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const rubricatorParentItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${RUBRICATOR_ITEM_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};