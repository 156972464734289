import React from 'react';
import T from 'prop-types';
import {ADD, EDIT} from "../../../constants/modalTypes";
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import {LOCATION_MODAL} from "../../../constants/locationModal";
import CatalogsModalWithForm from "../../Catalogs/ModalWithForm";
import * as Yup from "yup";
import {useFormik} from "formik";



const validationSchema = Yup.object().shape({
    name: Yup.string()
        .max(500, 'Максимальная длинна поля 500 символов')
        .trim('Это поле обязательно для заполнения')
        .required('Это поле обязательно для заполнения'),
    code: Yup.string()
        .max(50, 'Максимальная длинна поля 50 символов')
        .trim('Это поле обязательно для заполнения')
        .required('Это поле обязательно для заполнения'),
});

const LocationModal = ({modalData, setModalData, saveRoom, saveRack, saveShelf, resetModal}) => {
    const {modalType, data, locationType, parentId} = modalData;

    const formData = useFormik({
        initialValues: {
            name: data?.name || "",
            code: data?.code || "",
            reservationsallowed: data?.reservationsallowed || false
        },
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: values => {
            const saveEntity = chooseEntityFunc(locationType);
            saveEntity(parentId, {...modalData.data, ...values});
            return resetModal();
        }
    });

    if (modalType === ADD && !data) {
        setModalData({
            ...modalData,
            data: {
                name: "",
                code: "",
            }
        })
    }


    const chooseEntityFunc = (type) => {
        switch (type) {
            case LOCATION_MODAL.RACK:
                return saveRack;
            case LOCATION_MODAL.ROOM:
                return saveRoom;
            case LOCATION_MODAL.SHELF:
                return saveShelf;
            default:
                return null;
        }
    };


    return (
        <CatalogsModalWithForm
            title={`${locationType}: ${modalType === EDIT ? "Редактирование" : "Добавление"}`}
            submitHandler={formData.handleSubmit}
            closeModal={() => setModalData({modalType: null, data: null})}
        >
            {data &&
            <Row>
                <Col md={12}>
                    <ModalInputWrapper label="Название*"
                                       name="name"
                                       value={formData.values.name}
                                       error={formData.errors.name}
                                       onChange={formData.handleChange}/>
                    <ModalInputWrapper label="Код*"
                                       name="code"
                                       value={formData.values.code}
                                       error={formData.errors.code}
                                       onChange={formData.handleChange}/>
                    <ModalInputWrapper label={'Разрешено бронирование'}>
                        <input type="checkbox"
                               name="reservationsallowed"
                               checked={formData.values.reservationsallowed}
                               onChange={formData.handleChange}
                        />
                    </ModalInputWrapper>
                </Col>

            </Row>
            }
        </CatalogsModalWithForm>
    );
};

LocationModal.propTypes = {
    modalData: T.object,
    setModalData: T.func,
    saveRoom: T.func,
    saveRack: T.func,
    saveShelf: T.func,
    resetModal: T.func
};

export default LocationModal;
