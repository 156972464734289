import React from 'react';
import ModalInputWrapper from "../../../Modal/InputWrapper";
import T from "prop-types";
import styles from "../../Tabs/GUK/GUK.module.css";

const SpecialPurpose = ({data, onChange, selectors}) => {

    const purposeArray = {

        "не выбрано": [
            {label: "Не выбрано", value: 0}
        ],

        "официальный электронный ресурс": [
            {label: "Не выбрано", value: 0},
            {label: "Да", value: 1},
            {label: "Нет", value: 2}
        ],

        "научный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "Автореферат диссертации", value: 0},
            {label: "Диссертация", value: 1},
            {label: "Статья", value: 2},
            {label: "Монография", value: 3},
            {label: "Сборник научных трудов", value: 4},
            {label: "Материал и тезисы докладов военно-научной конференции", value: 5},
            {label: "Препринт", value: 6},
            {label: "Обзор", value: 7},
            {label: "Иное", value: 8},
        ],

        "научно-популярный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "нет", value: 1},
            {label: "иное", value: 2},
        ],

        "практический электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "инструктивно-методическое пособие", value:1},
            {label: "практическое руководство", value: 2},
            {label: "памятка", value: 3},
            {label: "промышленный каталог", value: 4},
            {label: "номенклатурный каталог", value: 5},
            {label: "иное", value: 6},
        ],

        "нормативный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "национальный стандарт", value: 1},
            {label: "образовательный стандарт", value: 2},
            {label: "инструкция (указания, рекомендации)", value: 3},
            {label: "нормативный акт", value: 4},
            {label: "иное", value: 5},
        ],

        "учебный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "задачник", value: 1},
            {label: "лабораторный практикум", value: 2},
            {label: "учебник", value: 3},
            {label: "учебное пособие", value: 4},
            {label: "конспект (текст) лекций", value: 5},
            {label: "тест", value: 6},
            {label: "контрольные вопросы", value: 7},
            {label: "учебный курс", value: 8},
            {label: "иное", value: 9},
        ],

        "справочный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "геоинформационная/картографическая система", value: 1},
            {label: "база данных", value: 2},
            {label: "каталог", value: 3},
            {label: "проспект", value: 4},
            {label: "словарь", value: 5},
            {label: "справочник", value: 6},
            {label: "энциклопедия", value: 7},
            {label: "иное", value: 8},
        ],

        "электронный ресурс для досуга": [
            {label: "не выбрано", value: 0},
            {label: "нет", value: 1},
            {label: "иное", value: 2},
        ],

        "рекламный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "нет", value: 1},
            {label: "иное", value: 2},
        ],

        "художественный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "нет", value: 1},
            {label: "иное", value: 2},
        ],

        "методический электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "учебное (методическое) пособие", value: 1},
            {label: "методические указания", value: 2},
            {label: "учебный план", value: 3},
            {label: "учебная программа", value: 4},
            {label: "тематический план", value: 5},
            {label: "план занятий", value: 6},
            {label: "иное", value: 7},
        ],
   
        "иллюстративный и демонстрационный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "атлас", value: 1},
            {label: "карта", value: 2},
            {label: "карта-схема", value: 3},
            {label: "альбом", value: 4},
            {label: "иллюстрация", value: 5},
            {label: "репродукция", value: 6},
            {label: "плакат", value: 7},
            {label: "открытка", value: 8},
            {label: "рисунок", value: 9},
            {label: "фотография", value: 10},
            {label: "чертеж", value: 11},
            {label: "схема", value: 12},
            {label: "учебное наглядное пособие", value: 13},
            {label: "иное", value: 14},
        ],

        "информационный электронный ресурс": [
            {label: "не выбрано", value: 0},
            {label: "нет", value: 1},
            {label: "иное", value: 2},
        ],        

        "социально-политический электронный ресурс": [
            {label: "материалы общественно-значимой тематики", value: 0},
            {label: "материалы агитационно-пропагандистского характера", value: 1},
            {label: "материалы предназначенное для широкого круга читателей", value: 2}
        ]
    }

    const pureposeSubName = selectors?.purpose?.find(item => item.id == data.eer_purpose)?.name;
    return (
        <>
            <ModalInputWrapper label="Целевое назначение ЭОР">
                <select
                    className={styles.select}
                    onChange={onChange}
                    name="eer_purpose"
                    value={data.pureposeSubName}
                    defaultValue={data.eer_purpose}
                >
                    {selectors.purpose.map((item, index) =>
                        <option value={item.id} key={index}>{item.name}</option>
                    )}
                </select>
            </ModalInputWrapper>
            <ModalInputWrapper label="Подгруппа целевого назначения">
                <select
                    className={styles.select}
                    onChange={onChange}
                    disabled={typeof data.eer_purpose === "object"}
                    name="eer_purpose_subgroup"
                    value={data.eer_purpose_subgroup}
                >
                    {purposeArray[pureposeSubName] &&
                    purposeArray[pureposeSubName].map(item =>
                        <option value={item.value} key={item.value}>{item.label}</option>
                    )
                    }
                </select>
            </ModalInputWrapper>
        </>
    );
};


SpecialPurpose.propTypes = {
    data: T.object,
    setData: T.func,
    selectors: T.array,
    onChange: T.func
};

export default SpecialPurpose;