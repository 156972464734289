import { api } from "../../App";

export const disciplineList = (maxResults, search, paginateFrom, formData) => {
    const params = {
        maxResults,
        search,
        paginateFrom,
        formData
    };
    return api.get(
        `/discipline?search=${params.formData}&maxResults=${params.maxResults}&paginateFrom=${params.paginateFrom}`
    );

};

export const disciplineCount = (search, formData) => {
    const params = {
        count: true,
        search,
        formData
    };
    return api.get(
        `/discipline?search=${formData}&count=${params.count}`
    );
};


export const disciplineDelete = (id) => {
    return api.delete(`/discipline/${id}`)
}

export const disciplineAdd = (data) => {
    const params = {
        status: 1,
        title: data.disciplinename,
        alias: data.disciplinename, // короткое название
        xp_split: "" // не удалять, захардкожено
    }

    return api.post('/discipline', params);
};

export const disciplineEdit = (data) => {
    const params = {
        title: data.disciplinename
    }
    return api.put(`/discipline/${data.cid}`, params);
};