import { call, put, takeLatest, select } from "redux-saga/effects";
import { GET_MENU_LIST, OPTIONS_MENU_SAVE_REQUEST, START } from "../../../actions/action-types";
import { saveMenu } from "../../../../api/options/menu";
import { optionsMenuSaveRequestSuccess, optionsMenuSaveRequestError, optionsMenuSaveRequestStart } from "../../../actions/options/menu/save";
import {createNotification} from "../../../../components/notification/notification";
import { setMenuList } from "../../../actions/menuList/menuList";
import { api } from "../../../../App";

export default function* menuSaveWatcher() {
  yield takeLatest(`${OPTIONS_MENU_SAVE_REQUEST}${START}`, optionsSaveWorker)
}

function* optionsSaveWorker(payload) {
  try {
    const response = yield call(saveMenu, payload.payload);
    yield put(optionsMenuSaveRequestSuccess(response.data));
    createNotification('success', response.data.message);
    try {
      const response = yield api.get(`/menu_settings`).then(data => data);
      yield put(setMenuList(response.data))
    } catch (e) {
      console.log(e)
    }
    
  } catch (error) {
    yield put(optionsMenuSaveRequestError(error));
    createNotification('error', 'Ошибка');
  }
}