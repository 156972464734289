import {api} from "../../App";

export const getOptions = () => {
    return api.get('/options');
};

export const saveOptions = (data) => {
    const params = {
        ...data,
        timelimitforselectingnewrecordsdays: Math.min(+data.timelimitforselectingnewrecordsdays, -data.timelimitforselectingnewrecordsdays)
    };

    return api.put('/options', params);
};