import { ERROR, START, SUCCESS, USERS_SAVE_PHOTO_REQUEST } from "../../action-types";

export const usersSavePhotoRequestStart = (file, mid) => ({
  type: `${USERS_SAVE_PHOTO_REQUEST}${START}`,
  payload: { file, mid }
});

export const usersSavePhotoRequestSuccess = (data) => ({
  type: `${USERS_SAVE_PHOTO_REQUEST}${SUCCESS}`,
  payload: data
});

export const usersSavePhotoRequestError = (data) => ({
  type: `${USERS_SAVE_PHOTO_REQUEST}${ERROR}`,
  payload: data
});