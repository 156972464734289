import { ERROR, PDF_VIEWER_BOOK_REQUEST, START, SUCCESS } from "../action-types";

export const pdfViewerBookRequestStart = (data) => ({
  type: `${PDF_VIEWER_BOOK_REQUEST}${START}`,
  payload: data
});

export const pdfViewerBookRequestSuccess = (data) => ({
  type: `${PDF_VIEWER_BOOK_REQUEST}${SUCCESS}`,
  payload: data
});

export const pdfViewerBookRequestError = (data) => ({
  type: `${PDF_VIEWER_BOOK_REQUEST}${ERROR}`,
  payload: data
});