import {IMPORT_ZIPURL_SAVE, IMPORT_BOOKS_RESET, SUCCESS} from "../../../actions/action-types";

const initialState = {};

export const zipURLReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${IMPORT_ZIPURL_SAVE}${SUCCESS}`:
            return action.payload;

        case `${IMPORT_BOOKS_RESET}`:
            return initialState;

        default:
            return state;
    }
};