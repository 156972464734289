import { api } from "../../App";

export const getList = (params) => api.get('/mobile_library', {params})

export const getCurrentLib = (libId) => api.get(`/mobile_library/${libId}`)

export const updateLib = (libId, data) => api.put(`/mobile_library/${libId}`, data)

export const createLib = (data) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => formData.append(key, data[key]))
  return api.post(`/mobile_library`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const addFile = (libId, file) => {
  const formData = new FormData()
  formData.append('contract_scan', file)
  return api.post(`/mobile_library/${libId}/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const deleteFile = (fileId) => api.delete(`/mobile_library/file/${fileId}`)

export const getResponsible = (mobile_library_id) => api.get(`/mobile_library_responsible`, {params: {mobile_library_id}})

export const updateResponsible = (respId, data) => api.put(`/mobile_library_responsible/${respId}`, data)

export const createResponsible = (data) => api.post(`/mobile_library_responsible`, data)

export const getDocuments = (mobile_library_id) => api.get(`/mobile_library_document`, {params: {mobile_library_id}})

export const createDocument = (data) => api.post(`/mobile_library_document`, data)

export const updateDocument = (docId, data) => api.put(`/mobile_library_document/${docId}`, data)


