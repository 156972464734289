import { ERROR, INV_NUMBER_DELETE_REQUEST, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const invNumberDeleteRequestStart = (number) => ({
  type: `${INV_NUMBER_DELETE_REQUEST}${START}`,
  payload: number
});

export const invNumberDeleteRequestSuccess = (data) => {
  data.ERROR ?
    createNotification('warning', data.ERROR)
    :
    createNotification('success', 'Успешно удалено');

  return {
    type: `${INV_NUMBER_DELETE_REQUEST}${SUCCESS}`,
    payload: data
  }
};

export const invNumberDeleteRequestError = (error) => {
  const { message } = error.response.data
  createNotification('error', message || 'Ошибка удаления');

  return {
    type: `${INV_NUMBER_DELETE_REQUEST}${ERROR}`,
    payload: error
  }
};