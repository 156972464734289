import { DOCUMENT_TYPES_COUNT_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const documentTypesCountRequestStart = () => ({
  type: `${DOCUMENT_TYPES_COUNT_REQUEST}${START}`
});

export const documentTypesCountRequestSuccess = (data) => ({
  type: `${DOCUMENT_TYPES_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentTypesCountRequestError = (data) => ({
  type: `${DOCUMENT_TYPES_COUNT_REQUEST}${ERROR}`,
  payload: data
});
