import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import Button from '../../../Button'
import styles from '../../../FundMovementBook/SearchForm/FundMovementSearchForm.module.css'






const BookGivingStatsReportForm = ({ librariesList, formData }) => {
  return (
    <div className={'formContainer'}>
      <Row>
        <Col>
          <ModalInputWrapper
            label='C*'
            value={formData.values.dateFrom}
            name='dateFrom'
            error={formData.errors.dateFrom}
            onChange={formData.handleChange}
            type='date'
          />
        </Col>
        <Col>
          <ModalInputWrapper
            label='По*'
            value={formData.values.dateTo}
            name='dateTo'
            error={formData.errors.dateTo}
            onChange={formData.handleChange}
            type='date'
          />
        </Col>
        <Col>
          <ModalInputWrapper label={'Библиотека'} error={formData.errors.library}>
            <select name='library' onChange={formData.handleChange} value={formData.values.library} className={styles.select}>
              <option value='0'>Все</option>
              {librariesList.map(item => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>

        <Col md={'auto'} className={'text-right'}>
          <ModalInputWrapper label={''}>
            <Button label={'Найти'} onClick={formData.handleSubmit} />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  )
}

BookGivingStatsReportForm.propTypes = {
  formData: PropTypes.object,
  librariesList: PropTypes.array,
}

export default BookGivingStatsReportForm
