import { EXTERNAL_RESOURCES_FULL_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const externalResourcesFullListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${EXTERNAL_RESOURCES_FULL_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};
