import React from 'react'
import T from 'prop-types'
import styles from './CoverListItemInfo.module.css'
import { Col, Row } from 'react-bootstrap'

const CoverListItemInfo = ({ label, value, type = '' }) => {
  return value ? (
    <Row>
      <Col md={3}>
        <div className={styles.label}>{label}:</div>
      </Col>
      <Col md={9}>
        {
          type == 'ta' ?
          <textarea disabled style={{width:'100%', height: '100px', resize: 'none'}} className={styles.value}>{value}</textarea>
          :
          <div className={styles.value}>{value}</div>
        }
      </Col>
    </Row>
  ) : null
}

CoverListItemInfo.propTypes = {
  label: T.string,
  value: T.any,
}

export default CoverListItemInfo
