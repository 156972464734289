import { ERROR, REGIONS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const regionsListRequestStart = (page) => ({
  type: `${REGIONS_LIST_REQUEST}${START}`,
  payload: page
});

export const regionsListRequestSuccess = (data) => ({
  type: `${REGIONS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const regionsListRequestError = (data) => ({
  type: `${REGIONS_LIST_REQUEST}${ERROR}`,
  payload: data
});
