import { ERROR, ROLE_DELETE_REQUEST, START, SUCCESS } from "../action-types";

export const roleDeleteRequestStart = (id) => ({
  type: `${ROLE_DELETE_REQUEST}${START}`,
  payload: id
});

export const roleDeleteRequestSuccess = (data) => ({
  type: `${ROLE_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const roleDeleteRequestError = (data) => ({
  type: `${ROLE_DELETE_REQUEST}${ERROR}`,
  payload: data
});