import jsPDF from 'jspdf'
import React from 'react'
import addFont from '../../../fonts/fonts'
import 'jspdf-autotable'
import styles from '../../PDFPrint/s.module.css'
import { CSVLink } from 'react-csv'
import printIcon from '../../../img/print.png'
import moment from 'moment'


export const InventoryBookTablePdfPrint = ({ data, optionsData, searchData, orgList }) => {

  const csvHeaders = [
    {label: 'Дата записи', key: 'instance_date'},
    {label: 'Инвентарный номер', key: 'bookinstancenumber'},
    {label: '№ Экз.', key: 'copynumber'},
    {label: 'Гриф секретности', key: 'secret_name'},
    {label: 'Отметка о проверке фонда', key: 'inventory_date'},
    {label: 'Автор и Заглавие', key: 'author_title'},
    {label: 'Место и год издания', key: 'year'},
    {label: 'Цена', key: 'price'},
    {label: '№ записи в книге суммарного учет', key: ''},
    {label: 'Отдел', key: 'series'},
    {label: '№ и дата документа приема', key: 'number_date_doc'},
    {label: 'Тип', key: ''},
    {label: 'Номер и дата первичного учетного документа о выбытии', key: 'number_date_docoff'},
    {label: 'Примечание', key: ''}
  ]
 
  
  const csvData = data.map((item) => {
    const {documentdate, bookinstancenumber, copynumber, secret_name, inventory_date, title, authors, year, city, price, series, document_type_name, document_arrival_date, documentnumber, document_off_name, document_off_date, document_off_number} = item
    const instance_date = new Date(documentdate).toLocaleDateString()
    const _authors = authors || []
    const __authors = _authors.map(item => item.name) 
    const author_title = `${title} ${__authors.toString()}`
    const year_city = `${city || ''} ${year || ''}`
    const number_date_doc = `${document_type_name || ''} ${new Date(document_arrival_date).toLocaleDateString() || ''} №${documentnumber || ''}`
    const number_date_docoff = `${document_off_name || ''} ${new Date(document_off_date).toLocaleDateString() || ''} ${document_off_number || ''}`
    return {
      instance_date,
      bookinstancenumber,
      copynumber,
      secret_name,
      inventory_date,
      author_title,
      year_city,
      price,
      series,
      number_date_doc,
      number_date_docoff
    }
  })

 
  const title = 'Инвентарная книга'
  const orientation = 'portrait'

  
  const createDocData = async () => {
    addFont(jsPDF.API)
    
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
    
    
    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    
    const numbers = data.map(item => item.bookinstancenumber).sort()
    const libraryName = orgList.find(({value}) => value == optionsData.own_client_id)?.label || 'Неизвестная организация'
    
    const startDate = data[0].document_arrival_date
    const formattedStartDate = moment(startDate).format('DD MMMM YYYY')
    
    doc.setFont('Montserrat')
    
    doc.setFont( undefined, 'normal').setFontSize('10').setFont(undefined, 'bold').text('Инвентарная книга учета библиотечного фонда (Форма № 11 по ОКУД 6002103)', pageWidth/2, 30, 'center')
    

    doc.setFont(undefined, 'normal').text(doc.splitTextToSize(libraryName, 100), 28, 64)
    doc.line(25, 67, 135, 67)
    doc.setFontSize('8').text(doc.splitTextToSize('(условное, а при отсутствии условного - действительное наименование воинской части)', 110), 25, 70)
    doc.setFontSize('10').text('Структурное подразделение ________________________________', 25, 85)
    
    doc.text('Коды', 170, 45)
    doc.text('Форма № 11 по ОКУД      6002103', 124, 55)
    doc.text('по ОКПО', 146, 65)
    doc.rect(165, 40, 20, 10)
    doc.rect(165, 50, 20, 8)
    doc.rect(165, 58, 20, 20)
    doc.rect(165, 78, 20, 8)
    
    doc.text('ИНВЕНТАРНАЯ КНИГА', pageWidth/2, 100, 'center')
    doc.text('УЧЕТА БИБЛИОТЕЧНОГО ФОНДА', pageWidth/2, 105, 'center')
    
    doc.text(`Начата ${formattedStartDate} года`, 130, 115)
    doc.text('Окончена "___" _________ 20___г.', 124.5, 120)
    

    doc.autoTable({
      html: '#InventoryBookTable table',
      styles: { font: 'Montserrat', fontSize: 5 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        textColor: '#ffffff',
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        textColor: '#000000',
      },
      startY: doc.pageCount > 1
        ? doc.autoTableEndPosY() + 20
        : 150
    })
    const numbersFiltered = numbers.filter(num => ((num != '') && (num != null)));
    doc.text(`Число листов: ${doc.getNumberOfPages()}`, 10, doc.lastAutoTable.finalY + 12)
    doc.text(`В этой инвентарной книге записаны документы с ${numbersFiltered[0]} по ${numbersFiltered[numbersFiltered.length-1]}`, 10, doc.lastAutoTable.finalY + 17)

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getNumberOfPages())
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      window.open(doc.output('datauristring'));
      // doc.output('dataurlnewwindow')
    })
  }

  return (
    <div className={styles.print} style={{alignItems: 'center'}}>
      <span onClick={toPdf}>PDF</span>
      {/* <span onClick={toPrint}>Печать</span> */}
      <button onClick={toPrint} style={{display: 'flex', alignItems: 'center'}}>
        <img src={printIcon} alt={'Печать'} title={'Печать'}/>
      </button>

      <CSVLink className={styles.csvButton} data={csvData} headers={csvHeaders}>CSV</CSVLink>
    </div>
  )
}
