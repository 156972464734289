import {ERROR, EXEMPT_TEST_EDIT_REQUEST, START, SUCCESS} from "../action-types";

export const exemptTestEditRequestStart = (data) => ({
    type: `${EXEMPT_TEST_EDIT_REQUEST}${START}`,
    payload: data
});

export const exemptTestEditRequestSuccess = (data) => ({
    type: `${EXEMPT_TEST_EDIT_REQUEST}${SUCCESS}`,
    payload: data
});

export const exemptTestEditRequestError = (error) => ({
    type: `${EXEMPT_TEST_EDIT_REQUEST}${ERROR}`,
    payload: error
});
