import { ERROR, LIBRARY_LIST_REQUEST, RESET_CURRENT_LIBRARY_DATA, START, SUCCESS } from "../../action-types";

export const libraryListRequestStart = (idclient) => ({
  type: `${LIBRARY_LIST_REQUEST}${START}`,
  payload: idclient
});

export const libraryListRequestSuccess = (data) => ({
  type: `${LIBRARY_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryListRequestError = (error) => ({
  type: `${LIBRARY_LIST_REQUEST}${ERROR}`,
  payload: error
});

export const resetCurrentLibraryData = () => ({
  type: RESET_CURRENT_LIBRARY_DATA
});
