import {ERROR, EXEMPT_TEST_COUNT_REQUEST, START, SUCCESS} from "../action-types";

export const exemptTestCountRequestStart = () => ({
    type: `${EXEMPT_TEST_COUNT_REQUEST}${START}`
});

export const exemptTestCountRequestSuccess = (count) => ({
    type: `${EXEMPT_TEST_COUNT_REQUEST}${SUCCESS}`,
    payload: count
});

export const exemptTestCountRequestError = (error) => ({
    type: `${EXEMPT_TEST_COUNT_REQUEST}${ERROR}`,
    payload: error
});
