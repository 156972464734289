import { BOOK_DECOMPRESS_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const bookDecompressRequestStart = (data) => ({
  type: `${BOOK_DECOMPRESS_REQUEST}${START}`,
  payload: data
});

export const bookDecompressRequestSuccess = (data) => ({
  type: `${BOOK_DECOMPRESS_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookDecompressRequestError = (data) => ({
  type: `${BOOK_DECOMPRESS_REQUEST}${ERROR}`,
  payload: data
});
