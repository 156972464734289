import { call, put, takeLatest, select } from 'redux-saga/effects'
import { INSTITUTIONS_ALL_LIST_REQUEST, INSTITUTIONS_COUNT_REQUEST, START, SUCCESS } from '../../../actions/action-types'
import { institutionsCount, institutionsList, institutionsListAll } from '../../../../api/institutions/institutions'
import { institutionsCountRequestError, institutionsCountRequestSuccess } from '../../../actions/institutions/count'
import { setInstitutionsSearchData } from '../../../actions/institutions/search'
import {
  institutionsListRequestError,
  institutionsListRequestStart,
  institutionsListRequestSuccess,
} from '../../../actions/institutions/list'
import { institutionsAllListRequestError, institutionsAllListRequestSuccess } from '../../../actions/institutions/all'
import { institutionsToOptions } from '../../../../utils/toSelectOption'

export default function* loadInstitutionsWatcher() {
  yield takeLatest(`${INSTITUTIONS_COUNT_REQUEST}${START}`, institutionsCountWorker)
  yield takeLatest(`${INSTITUTIONS_COUNT_REQUEST}${SUCCESS}`, institutionsListWorker)
  yield takeLatest(`${INSTITUTIONS_ALL_LIST_REQUEST}${START}`, institutionsAllWorker)
}

function* institutionsCountWorker() {
  const searchData = yield select(({ institutions }) => institutions.search)
  try {
    const response = yield call(institutionsCount, searchData.query)
    const count = parseInt(response.data.count, 10)
    yield put(institutionsCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setInstitutionsSearchData({ ...searchData, pageCount }))
  } catch (error) {
    yield put(institutionsCountRequestError(error))
  }
}

function* institutionsListWorker() {
  yield put(institutionsListRequestStart())
  const searchData = yield select(({ institutions }) => institutions.search)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(institutionsList, searchData)
    yield put(institutionsListRequestSuccess(response.data))
  } catch (error) {
    yield put(institutionsListRequestError(error))
  }
}

function* institutionsAllWorker() {
  const search = yield select(({ institutions }) => institutions.search.query)
  try {
    const response = yield call(institutionsListAll, search)
    yield put(institutionsAllListRequestSuccess(response.data))
  } catch (error) {
    yield put(institutionsAllListRequestError(error))
  }
}
