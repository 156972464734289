import { combineReducers } from "redux";
import { categoriesListReducer } from "./list/categoriesListReducer";
import { categoriesLoaderReducer } from './loader/categoriesLoaderReducer';
import { categoriesSearchDataReducer } from './search/categoriesSearchReducer';

export const categoriesReducer = combineReducers({
  list: categoriesListReducer,
  loader: categoriesLoaderReducer,
  search: categoriesSearchDataReducer,
});
