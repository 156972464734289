import { ERROR, INV_NUMBERS_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const invNumbersCountRequestStart = () => ({
  type: `${INV_NUMBERS_COUNT_REQUEST}${START}`
});

export const invNumbersCountRequestSuccess = (data) => ({
  type: `${INV_NUMBERS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const invNumbersCountRequestError = (data) => ({
  type: `${INV_NUMBERS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
