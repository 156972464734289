import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Select, Spin} from "antd";


const AsyncSelect = ({
                         valueName,
                         labelName,
                         placeholder,
                         searchOptions,
                         onChange,
                         value,
                         ...props
                     }) => {


    const labelInValue = Array.isArray(value) && typeof value[0] === "object";
    const {Option} = Select;

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    function fetchOptions(val) {
        const searchOptionsStart = searchOptions(val);
        setLoading(true);

        if (searchOptionsStart instanceof Promise) {
            searchOptionsStart
                .then(res => {
                    setLoading(false);
                    if (!Array.isArray(res.data)) {
                        throw new Error('Результат загрузки options должен возвращать массив');
                    }
                    setOptions(res.data);
                })
                .catch(err => console.log(err))
        } else {
            setLoading(false);
            throw new Error('Функция для загрузки options должа возвращать Promise')
        }
    }

    function onChangeHandler(val) {
        let updVal = val;
        if (labelInValue) {
            updVal = val.map(i => ({
                [labelName]: i.label,
                [valueName]: i.value
            }))
        }
        onChange(updVal);
    }

    function onSearch(val) {
        fetchOptions(val);
    }

    function onBlur() {
        setOptions([]);
    }

    function onFocus() {
        fetchOptions('');
    }

    return (
        <Select
            showSearch
            labelInValue={labelInValue}
            style={{width: '100%'}}
            placeholder={placeholder}
            onSearch={onSearch}
            onChange={onChangeHandler}
            onFocus={onFocus}
            onBlur={onBlur}
            value={labelInValue ? value.map(i => ({label: i[labelName], value: i[valueName]})) : value}
            filterOption={false}
            notFoundContent={loading ? <Spin size="small" /> : null}
            {...props}
        >
            {options.map((option, index) =>
                <Option key={index} value={option[valueName]}>
                    {option[labelName]}
                </Option>
            )}
        </Select>
    );
};

AsyncSelect.propTypes = {
    //название поля из массива options, которое будет value селекта
    valueName: PropTypes.string.isRequired,
    //название поля из массива options, которое будет отображаться в позиции
    labelName: PropTypes.string.isRequired,

    placeholder: PropTypes.string,
    searchOptions: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
};

AsyncSelect.defaultProps = {
    placeholder: ""
}

export default AsyncSelect;
