import { api } from "../../App";
import {
  START,
  SUCCESS,
  GET_BOOK_CHANGE_HISTORY
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import { combineReducers } from "redux";

const getBookChangeHistoryList = bookId =>
  api.get(`/bookchangehistory/${bookId}`);

export const bookChangeHistoryActions = {
  ...createSimpleActionsForReducer("list", GET_BOOK_CHANGE_HISTORY)
};

export const getBookChangeHistoryReducers = combineReducers({
  list: createSimpleReducer([], `${GET_BOOK_CHANGE_HISTORY}${SUCCESS}`)
});

export function* bookChangeHistoryWatcher() {
  yield takeLatest(`${GET_BOOK_CHANGE_HISTORY}${START}`, getBookHistory);
}

function* getBookHistory({ payload }) {
  try {
    const response = yield call(getBookChangeHistoryList, payload);
    yield put(bookChangeHistoryActions.listRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}
