export const accessIsSetByUserStatusList = [{
    value: undefined,
    label: 'Показать все значения'
}, {
    value: true,
    label: 'показать только значения "чтение"'
}, {
    value: false,
    label: 'показать только значения "нет"'
}];
