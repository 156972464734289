import { MEDIA_RESOURCE_EDIT_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const mediaResourceEditRequestStart = (resourceId, { resourcename, resourceurl }) => ({
  type: `${MEDIA_RESOURCE_EDIT_REQUEST}${START}`,
  payload: {resourceId, data: { resourcename, resourceurl }}
});

export const mediaResourceEditRequestSuccess = (response) => {
  createNotification('success', 'Ресурс успешно отредактирован');

  return {
    type: `${MEDIA_RESOURCE_EDIT_REQUEST}${SUCCESS}`,
    payload: response
  }
};

export const mediaResourceEditRequestError = (error) => {
  createNotification('error', 'Ошибка редактирования ресурса');

  return {
    type: `${MEDIA_RESOURCE_EDIT_REQUEST}${ERROR}`,
    payload: error
  }
};
