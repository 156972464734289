import {api} from "../../App";

export const getInventoryBooksList = (documentId) => {
    return api.get(`/Inventory_spcf/${documentId}`);
};

export const addBookToInventory = ({inventory, book_instance, amount}) => {
    const params = {
        inventory,
        book_instance,
        amount
    };

    return api.post('/Inventory_spcf', params);
};

export const editBookToInventory = ({documentid, book_instance, amount, id}) => {
    const params = {
        inventory: documentid,
        amount,
        book_instance,
    };

    return api.put(`/Inventory_spcf/${id}`, params);
};

export const deleteBookToInventory = (id) => {
    return api.delete(`/Inventory_spcf/${id}`);
};
