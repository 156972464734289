import {
    disciplineListRequestError,
    disciplineListRequestSuccess
} from "../../../actions/discipline/list";

import {put, takeLatest, call, select} from "redux-saga/effects";
import {
    DISCIPLINE_COUNT_REQUEST,
    START,
    SUCCESS
} from "../../../actions/action-types";
import {disciplineCount, disciplineList} from "../../../../api/discipline/discipline";
import {
    disciplineCountRequestError,
    disciplineCountRequestStart,
    disciplineCountRequestSuccess
} from "../../../actions/discipline/count";
import {setDisciplineSearchData} from "../../../actions/discipline/searchData";


export default function* loadDisciplineWatcher() {
    yield takeLatest(`${DISCIPLINE_COUNT_REQUEST}${START}`, disciplineCountWorker);
    yield takeLatest(`${DISCIPLINE_COUNT_REQUEST}${SUCCESS}`, disciplineListWorker);
}

function* disciplineCountWorker() {
    const searchData = yield select(({discipline}) => discipline.search);
    const formData = yield select(({discipline}) => discipline.form);
    try {
        const response = yield call(disciplineCount, searchData.query, formData.name);
        const count = parseInt(response.data.count, 10);
        yield put(disciplineCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setDisciplineSearchData({...searchData, pageCount}));
    } catch (error) {
        yield put(disciplineCountRequestError(error));
    }
}

function* disciplineListWorker() {
    const formData = yield select(({discipline}) => discipline.form);
    const searchData = yield select(({discipline}) => discipline.search);
    const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(disciplineList, searchData.maxResults, searchData.query, paginateFrom, formData.name);
        yield put(disciplineListRequestSuccess(response.data));
    } catch (error) {
        yield put(disciplineListRequestError(error));
    }
}





