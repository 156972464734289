import { api } from "../../App";
import {SORT_DIRECTION} from "../../constants/sortDirections";

export const getAntiplagiatCheckRequestCount = ({ query, status }) => {
  const params = {
    count: true,
    search: query,
    status
  };

  return api.get(`/antiplagiat`, {params});
};

export const getAntiplagiatCheckRequestList = ({ maxResults, paginateFrom, sortBy, sortDirection, query, status }) => {
  const params = {
    maxResults,
    paginateFrom,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
    search: query,
    status
  };

  return api.get(`/antiplagiat`, {params});
};

export const deleteAntiplagiatCheckRequestItem = (itemId) => {
  return api.delete(`/antiplagiat/${itemId}`)
};

export const addAntiplagiatCheckRequestItem = ({ comment, file }) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };
  const formData = new FormData();

  formData.append("file", file);

  return api.post(`/antiplagiat?comment=${comment}`, formData, config);
};

export const editAntiplagiatCheckRequestItem = ({ comment, itemId }) => {
  return api.put(`/antiplagiat/${itemId}`, {comment});
};

export const getAntiplagiatReport = (itemId) => {
  return api.get(`/antiplagiat/${itemId}/report`)
};