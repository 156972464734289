import { ERROR, READER_SEARCH_REQUEST, SET_READER_SEARCH_DATA, START, SUCCESS } from "../action-types";

export const setReaderSearchData = (data) => ({
  type: SET_READER_SEARCH_DATA,
  payload: data
});

export const readerSearchRequestStart = () => ({
  type: `${READER_SEARCH_REQUEST}${START}`
});

export const readerSearchRequestSuccess = (data) => ({
  type: `${READER_SEARCH_REQUEST}${SUCCESS}`,
  payload: data
});

export const readerSearchRequestError = (error) => ({
  type: `${READER_SEARCH_REQUEST}${ERROR}`,
  payload: error
});


