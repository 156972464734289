import React, {useEffect} from "react";
import styles from "./CoverItemModal.module.css";
import ModalWindow from "../../../../ModalWindow";

const CoverItemModal = ({show, close, places, request, modalId}) => {
    useEffect(() => {
        modalId && request(modalId);
    }, [modalId]);

    return (
        <ModalWindow
            title={`Места хранения`}
            isOpen={show}
            onRequestClose={close}
            width={'500px'}
        >
            {!!places.length ?
                places.map(item => (
                    <div className={styles.line}>
                        <div>Инв.номер: {item.number}</div>
                        <div>Место хранения: {item.placename}</div>
                    </div>
                ))
                :
                <div className={'emptyNotify'}>Ничего не найдено</div>
            }
        </ModalWindow>
    );
};

export default CoverItemModal;
