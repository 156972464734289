import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import styles from './ExternalResourcesEditScene.module.css';
import { externalResourcesFullListRequestStart } from "../../redux/actions/externalResources/fullList";
import { externalResourcesSaveRequestStart } from "../../redux/actions/externalResources/save";
import { externalResourcesDeleteRequestStart } from "../../redux/actions/externalResources/delete";
import ExternalResourcesEditableItem from "../../components/ExternalResources/EditableItem";
import Button from "../../components/Button";
import { setExternalResourcesSaveStatus } from "../../redux/actions/externalResources/status";
import ExternalResourcesEditModal from "../../components/Modal/ExternalResourcesEditModal"
import SceneContainer from "../../components/UtulityComponents/SceneContainer";


const mapStateToProps = ({ externalResources }) => ({
  list: externalResources.fullList,
  saveStatus: externalResources.saveStatus
});

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(externalResourcesFullListRequestStart()),
  saveResource: (data) => dispatch(externalResourcesSaveRequestStart(data)),
  deleteResource: (id) => dispatch(externalResourcesDeleteRequestStart(id)),
  setSaveStatus: (status) => dispatch(setExternalResourcesSaveStatus(status))
});

const ExternalResourcesEditScene = ({ list, requestList, saveResource, deleteResource, saveStatus, setSaveStatus }) => {
  useEffect(() => {
    requestList()
  }, [requestList]);


  useEffect(() => {
    saveStatus && setSaveStatus(null);
  }, [saveStatus, setSaveStatus]);

  const [emptyItem, setEmptyItem] = useState(false);

  useEffect(() => setEmptyItem(false), [list]);

  return (
    <SceneContainer title="Редактирование внешних ресурсов">
      <div className={styles.statusContainer}>
        {saveStatus && <ExternalResourcesEditModal />}
      </div>
      {!emptyItem && <Button onClick={() => setEmptyItem(true)}
                             style={styles.button}
                             label={"Добавить"}/>}
      <div className={styles.listContainer}>
        {emptyItem && <ExternalResourcesEditableItem data={null}
                                                     onSave={saveResource}
                                                     onDelete={() => setEmptyItem(false)}/>}
        {list.map((item, index) =>
          <ExternalResourcesEditableItem data={item}
                                         onSave={saveResource}
                                         onDelete={deleteResource}
                                         key={index}/>)}
      </div>
    </SceneContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalResourcesEditScene);
