import React from 'react';
import T from 'prop-types';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import Button from "../../../Button";

const AddItemForm = ({ onSubmit, formData, setFormData }) => {
  const onChange = ({ target }) => setFormData({ ...formData, [target.name]: target.value });

  return (
    <div>
      <Row>
        <Col>
          <ModalInputWrapper
            label={"Название ресурса"}
            name="resourcename"
            value={formData.resourcename}
            onChange={onChange}
          />
        </Col>
        <Col>
          <ModalInputWrapper
            label={"Адрес ресурса"}
            name="resourceurl"
            value={formData.resourceurl}
            onChange={onChange}
          />
        </Col>
        <Col md={'auto'}>
          <ModalInputWrapper label={''}>
            <Button
              colorType={'green'}
              label={'Добавить новый'}
              onClick={onSubmit}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

AddItemForm.propTypes = {
  onSubmit: T.func.isRequired,
  formData: T.object.isRequired,
  setFormData: T.func.isRequired
};

export default AddItemForm;