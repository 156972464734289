import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Table
} from "react-bootstrap";
import styles from "./Modal.module.css";
import moment from "moment";
import classnames from "classnames";

const StudentProjectsModal = ({
  modal,
  setModal,
  project,
  projectRequest,
  saveProject
}) => {
  const { status, id } = modal;
  const [activeTab, setActiveTab] = useState("1");
  const [result, setResult] = useState("");

  useEffect(() => {
    status && projectRequest(id);
  }, []);

  useEffect(() => {
    setResult(project.result);
  }, [project]);

  const onChangeResult = e => {
    setResult(e.target.value);
  };

  const onSaveClick = () => {
    saveProject({ id: project.id, data: { result } });
    toggle();
  };

  const toggle = () => {
    setModal({
      status: !status,
      id: undefined
    });
  };

  return (
    <Modal isOpen={status} toggle={toggle} size="lg">
      <ModalBody>
        <div>
          <div>Вид работы: {project && project.type}</div>
          <div>Дисциплина: {project && project.coursetitle}</div>
          <div>Учащийся: {project && project.studentname}</div>
          <div>
            Срок выполнения:{" "}
            {project && moment(project.duedate).format("DD.MM.YYYY")}
          </div>
        </div>
        <div>
          <Nav tabs>
            <NavItem>
              <Nav.Link
                onClick={() => setActiveTab("1")}
                className={classnames({ active: activeTab === "1" })}
              >
                Задание
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                onClick={() => setActiveTab("2")}
                className={classnames({ active: activeTab === "2" })}
              >
                Работа
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                onClick={() => setActiveTab("3")}
                className={classnames({ active: activeTab === "3" })}
              >
                Рецензия
              </Nav.Link>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className={styles.contentPane}>
              <div>
                Текст
                <textarea
                  className={styles.task}
                  value={project && project.task}
                />
              </div>
            </TabPane>
            <TabPane tabId="2" className={styles.contentPane}>
              <div>
                Текст
                <textarea
                  className={styles.task}
                  value={result}
                  onChange={onChangeResult}
                />
              </div>
            </TabPane>
            <TabPane tabId="3">
              <Table bordered className={styles.table}>
                <thead>
                  <tr>
                    <th>Отзыв</th>
                    <th>Файл задания</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>test</td>
                    <td>test</td>
                  </tr>
                </tbody>
              </Table>
            </TabPane>
          </TabContent>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Закрыть
        </Button>
        <Button color="primary" onClick={onSaveClick}>
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StudentProjectsModal;
