import React, { useState, useEffect, useMemo } from "react";
import styles from "./NotesTab.module.css";
import Select from '../../../Select'

const typesToOptions = types =>
  types.map(item => ({ value: item.id, label: item.name }));

const NotesTab = ({
  getTypes,
  types,
  getList,
  list,
  bookId,
  addNote,
  delNote
}) => {
  // Шифр
  const [noteTypeId, setNoteTypeId] = useState(3183754349870026);
  const [note, setNote] = useState("");

  useEffect(() => {
    getTypes();
  }, []);
  useEffect(() => {
    getList(bookId);
  }, [bookId]);

  const typesSelect = useMemo(
    () => (
      [...typesToOptions(types)]
    ),
    [types, noteTypeId]
  )

  return (
    <div>
      <div className={styles.controlPanel}>
        <div className={styles.wrapSelect}>
        <Select
          defaultValue={typesSelect[0]}
          placeholder={''}
          value={typesSelect?.find(el => el.value == noteTypeId)}
          onChange={value => setNoteTypeId(value.value)}
          options={typesSelect}
        />
        </div>
        
        <label className={styles.label}>
          Примечание:
          <input
            className={styles.input}
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </label>
        <button
          className={styles.button}
          onClick={() => {
            addNote(bookId, { noteTypeId, note });
            setNote("");
          }}
        >
          Добавить
        </button>
      </div>
      <div className={styles.notesList}>
        {list.map((item, index) => (
          <div key={`listItem${index}`} className={styles.note}>
            <span className={styles.noteSpan}>
              <div
                className={styles.buttondel}
                onClick={() => delNote({ id: item.id, bookId: bookId })}
              >
                Х
              </div>
              [{item.notetype}]
            </span>{" "}
            {item.note}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotesTab;
