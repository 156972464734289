import { call, put, takeLatest, select } from 'redux-saga/effects'
import { AUTH_REQUEST, LOGOUT_REQUEST, START, CAPTCHA_GET } from '../../actions/action-types'
import { authRequestError, authRequestSuccess, resetAuthData, setCaptcha } from '../../actions/user/auth'
import { authorize, logout, captcha } from '../../../api/user/auth'
import { logoutRequestError, logoutRequestSuccess } from '../../actions/user/logout'
import { searchAuditSetUserMid } from '../../actions/searchAudit/searchData'

export default function* authWatcher() {
  yield takeLatest(`${AUTH_REQUEST}${START}`, authWorker)
  yield takeLatest(`${LOGOUT_REQUEST}${START}`, logoutWorker)
  yield takeLatest(`${CAPTCHA_GET}`, captchaWorker)
}

function* authWorker(action) {
  const { username, password, captcha, captcha_id } = action.payload
  try {
    const response = yield call(authorize, username, password, captcha, captcha_id)
    yield put(authRequestSuccess(response.data))
    yield put(searchAuditSetUserMid(response.data.mid))
  } catch (error) {
    yield put(authRequestError(error.response.status))
  }
}

function* captchaWorker() {
  try {
    const response = yield call(captcha)
    yield put(setCaptcha(response.data))
  } catch (error) {
    yield put(authRequestError(error.response.status))
  }
}

function* logoutWorker() {
  try {
    const response = yield call(logout)
    yield put(logoutRequestSuccess(response.data))
    yield put(resetAuthData())
  } catch (error) {
    yield put(logoutRequestError(error))
  }
}