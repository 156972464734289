import { ERROR, OPTIONS_LOGO_GET_REQUEST, START, SUCCESS } from "../../action-types";

export const optionsLogoGetRequestStart = () => ({
  type: `${OPTIONS_LOGO_GET_REQUEST}${START}`
});

export const optionsLogoGetRequestSuccess = (data) => ({
  type: `${OPTIONS_LOGO_GET_REQUEST}${SUCCESS}`,
  payload: data
});

export const optionsLogoGetRequestError = (data) => ({
  type: `${OPTIONS_LOGO_GET_REQUEST}${ERROR}`,
  payload: data
});