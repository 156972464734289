import { call, put, takeLatest, select } from "redux-saga/effects";
import { START, USERS_COUNT_REQUEST, USERS_LIST_REQUEST } from "../../../actions/action-types";
import { getUsersCount, getUsersList } from "../../../../api/adminUsers/users";
import { usersCountRequestError, usersCountRequestSuccess } from "../../../actions/adminUsers/count";
import { setUsersSearchData } from "../../../actions/adminUsers/search";
import {
  usersListRequestError,
  usersListRequestStart,
  usersListRequestSuccess
} from "../../../actions/adminUsers/list";

export default function* loadUsersWatcher() {
  yield takeLatest(`${USERS_COUNT_REQUEST}${START}`, usersCountWorker);
  yield takeLatest(`${USERS_LIST_REQUEST}${START}`, usersListWorker)
}

function* usersCountWorker() {
  const searchData = yield select(({ adminUsers }) => adminUsers.search);
  try {
    const response = yield call(getUsersCount, searchData.query);
    const count = parseInt(response.data.count, 10);
    yield put(usersCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setUsersSearchData({ ...searchData, pageCount }));
    yield put(usersListRequestStart());
  } catch (error) {
    yield put(usersCountRequestError(error));
  }
}

function* usersListWorker() {
  const searchData = yield select(({ adminUsers }) => adminUsers.search);
  const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getUsersList, searchData.sortBy, searchData.maxResults, paginateFrom, searchData.query, searchData.sortDirection);
    yield put(usersListRequestSuccess(response.data));
  } catch (error) {
    yield put(usersListRequestError(error));
  }
}