import { ERROR, LIBRARY_INSTITUTIONS_REQUEST, START, SUCCESS } from "../../action-types";

export const libraryInstitutionsRequestStart = () => ({
  type: `${LIBRARY_INSTITUTIONS_REQUEST}${START}`
});

export const libraryInstitutionsRequestSuccess = (data) => ({
  type: `${LIBRARY_INSTITUTIONS_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryInstitutionsRequestError = (data) => ({
  type: `${LIBRARY_INSTITUTIONS_REQUEST}${ERROR}`,
  payload: data
});