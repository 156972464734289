import {ERROR, EXEMPT_TEST_ADD_REQUEST, START, SUCCESS} from "../action-types";

export const exemptTestAddRequestStart = (data) => ({
    type: `${EXEMPT_TEST_ADD_REQUEST}${START}`,
    payload: data
});

export const exemptTestAddRequestSuccess = (data) => ({
    type: `${EXEMPT_TEST_ADD_REQUEST}${SUCCESS}`,
    payload: data
});

export const exemptTestAddRequestError = (error) => ({
    type: `${EXEMPT_TEST_ADD_REQUEST}${ERROR}`,
    payload: error
});
