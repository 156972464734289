import {SUCCESS, MOBILE_LIBRARY_RESPONSIBLE_REQUEST, RESET_MOBILE_LIBRARY_DATA} from "../../../actions/action-types";



const initialState = []



export const mobileLibraryResponsibleReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${MOBILE_LIBRARY_RESPONSIBLE_REQUEST}${SUCCESS}`:
            return action.payload
        
        case `${RESET_MOBILE_LIBRARY_DATA}`:
            return initialState
        
        default: return state;
    }
};