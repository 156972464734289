import {
  ERROR,
  LIBRARY_SAVE_REQUEST,
  START,
  SUCCESS,
} from "../../action-types";
import {createNotification} from "../../../../components/notification/notification"

export const librarySaveRequestStart = (data, callback) => ({
  type: `${LIBRARY_SAVE_REQUEST}${START}`,
  payload: data,
  callback,
});

export const librarySaveRequestSuccess = (data) => ({
  type: `${LIBRARY_SAVE_REQUEST}${SUCCESS}`,
  payload: data,
});

export const librarySaveRequestError = (data) => {
  if (data.response.status === 500) {
    const { data: res } = data.response

    createNotification('error', res.ERROR)
  } else alert("error", "Ошибка создания");
  return {
    type: `${LIBRARY_SAVE_REQUEST}${ERROR}`,
    payload: data,
  };
};
