import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import {
  OUTSECRECY_COUNT_REQUEST,
  START,
  SUCCESS
} from "../../../actions/action-types";
import {
  outsecrecyCount,
  outsecrecyList
} from "../../../../api/outsecrecy/outsecrecy";
import {
  outsecrecyCountRequestError,
  outsecrecyCountRequestSuccess
} from "../../../actions/outsecrecy/count";
import { setOutsecrecySearchData } from "../../../actions/outsecrecy/searchData";
import {
  outsecrecyListRequestError,
  outsecrecyListRequestStart,
  outsecrecyListRequestSuccess
} from "../../../actions/outsecrecy/list";

export default function* outsecrecyRequestWatcher() {
  yield takeLatest(
    `${OUTSECRECY_COUNT_REQUEST}${START}`,
    outsecrecyRequestCountWorker
  );
  yield takeLatest(
    `${OUTSECRECY_COUNT_REQUEST}${SUCCESS}`,
    outsecrecyRequestListWorker
  );
}

function* outsecrecyRequestCountWorker() {
  const searchData = yield select(({ outsecrecy }) => outsecrecy.search);
  try {
    const response = yield call(outsecrecyCount, searchData.query);
    const count = parseInt(response.data.count, 10);
    yield put(outsecrecyCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setOutsecrecySearchData({ ...searchData, pageCount }));
  } catch (error) {
    yield put(outsecrecyCountRequestError(error));
  }
}

function* outsecrecyRequestListWorker() {
  yield put(outsecrecyListRequestStart());
  const searchData = yield select(({ outsecrecy }) => outsecrecy.search);
  const paginateFrom = Math.max(
    searchData.maxResults * (searchData.page - 1),
    0
  );
  try {
    const response = yield call(
      outsecrecyList,
      searchData.sortBy,
      searchData.maxResults,
      paginateFrom,
      searchData.query
    );
    yield put(outsecrecyListRequestSuccess(response.data));
  } catch (error) {
    yield put(outsecrecyListRequestError(error));
  }
}
