import React, { useEffect, useState } from 'react';
import qs from 'qs';
import styles from './QRCodesScene.module.css';
import { qrCodesShelvesRequestStart } from "../../redux/actions/qrcodes/listShelves";
import { connect } from "react-redux";
import QRCode from "qrcode.react";

const mapStateToProps = state => ({
  qrCodes: state.qrCodes
});

const mapDispatchToProps = dispatch => ({
  requestCodes: (ids) => dispatch(qrCodesShelvesRequestStart(ids))
});

const QRCodesSehlvesScene = ({ qrCodes, requestCodes, location: { search } }) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    if (search) {
      requestCodes(qs.parse(search.substr(1)).ids);
    } else {
      setError(true);
    }
  }, [search, requestCodes]);


  return error ?
    <div className={styles.error}>
      Произошла ошибка. Пожалуйста, повторите выбор экземпляров для печати.
    </div>
    :
    <div className={styles.container}>
      {qrCodes.map((item, index) =>
          <div className={styles.codeContainer} key={item + index}>
            <QRCode value={`${item.id}; ${item.qr_type_place}`} className={styles.qrCode} renderAs={'svg'}/>
            <div className={styles.label}>
              {item.qr_name_place}
            </div>
          </div>
        )}
    </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodesSehlvesScene);
