import {
  CREDITS_LIST_REQUEST,
  SUCCESS,
  START,
  ERROR
} from "../../../actions/action-types";

const initialState = false;

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREDITS_LIST_REQUEST}${START}`:
      return true;

    case `${CREDITS_LIST_REQUEST}${SUCCESS}`:
      return false;

    case `${CREDITS_LIST_REQUEST}${ERROR}`:
      return false;


    default: return state;
  }
};
