import { ERROR, INSTITUTIONS_ALL_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const institutionsAllListRequestStart = () => ({
  type: `${INSTITUTIONS_ALL_LIST_REQUEST}${START}`
});

export const institutionsAllListRequestSuccess = (data) => ({
  type: `${INSTITUTIONS_ALL_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const institutionsAllListRequestError = (data) => ({
  type: `${INSTITUTIONS_ALL_LIST_REQUEST}${ERROR}`,
  payload: data
});
