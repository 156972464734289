import { ERROR, INV_NUMBERS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const invNumbersListRequestStart = () => ({
  type: `${INV_NUMBERS_LIST_REQUEST}${START}`
});

export const invNumbersListRequestSuccess = (data) => ({
  type: `${INV_NUMBERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const invNumbersListRequestError = (data) => ({
  type: `${INV_NUMBERS_LIST_REQUEST}${ERROR}`,
  payload: data
});