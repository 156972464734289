import {call, put, takeLatest} from "redux-saga/effects";
import {RESERVED_BOOKS_LIST_REQUEST, START} from "../../actions/action-types";
import {getReservedList} from "../../../api/book/reservedList";
import {reservedBooksListRequestError, reservedBooksListRequestSuccess} from "../../actions/books/reservedList";

export default function* reservedBooksListWatcher() {
    yield takeLatest(`${RESERVED_BOOKS_LIST_REQUEST}${START}`, reservedBooksListWorker)
}

function* reservedBooksListWorker({payload: mid}) {
    try {
        const response = yield call(getReservedList, mid);
        yield put(reservedBooksListRequestSuccess(response.data));
    } catch (error) {
        yield put(reservedBooksListRequestError(error));
    }
}