import React, { useEffect } from "react";
import styles from "./ExternalSearch.module.css";
import { googleBooksActions } from "../../redux/factory/googleBooksReduxData";
import { connect } from "react-redux";
import Pagination from "../../components/Pagination";
import ListItem from "../../components/ExternalSearch/ListItem";
import Search from "../../components/Search";

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(googleBooksActions.listRequestStart()),
  setSearchData: data =>
    dispatch(googleBooksActions.setGoogleBooksSearchData(data))
});

const mapStateToProps = ({ googleBooks: { search, list, count } }) => ({
  search,
  list,
  count
});

const ExternalSearch = ({ requestList, search, list, setSearchData, count }) => {
  useEffect(() => {
    requestList();
  }, []);

  const setCurrentSearchData = current => {
    setSearchData({ ...search, page: current });
    requestList();
  };

  const setQuery = query => {
    setSearchData({ ...search, query });
  };

  const onSearchSubmit = () => {
    setSearchData({...search, page: 1});
    requestList();
  };

  return (
    <div className={styles.container}>
      <Search onSubmit={onSearchSubmit} onChange={setQuery} value={search.query} />
      <Pagination
        count={count}
        current={search.page}
        pageCount={search.pageCount}
        setCurrent={setCurrentSearchData}
      />
      {list.map(b => {
        const info = b.volumeInfo || {};
        return (
          <ListItem
            name={info.title}
            link={info.infoLink}
            authors={info.authors}
            imageUrl={(info.imageLinks || {}).thumbnail}
            isbns={info.industryIdentifiers}
          />
        );
      })}
      <Pagination
        current={search.page}
        pageCount={search.pageCount}
        setCurrent={setCurrentSearchData}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalSearch);
