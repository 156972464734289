import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { SET_PUBLISHERS_SEARCH_DATA } from "../../../actions/action-types";

const initialState = {
  query: "",
  page: 1,
  sortBy: "fullName",
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const publishersSearchDataReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PUBLISHERS_SEARCH_DATA:
      return { ...action.payload };

    default:
      return state;
  }
};