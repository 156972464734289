import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import Button from '../../../Button'
import styles from '../../../FundMovementBook/SearchForm/FundMovementSearchForm.module.css'
import ModalWindow from '../../../ModalWindow'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { documentTypesAllRequestStart } from '../../../../redux/actions/documents/types/all'
import DocumentsModal from '../../../Documents/Modal'
import { ADD, EDIT } from '../../../../constants/modalTypes'
import { setCurrentDocument } from '../../../../redux/actions/documents/current'
import { createLostDocumentRequestStart } from '../../../../redux/actions/notebookLostDoc/create'
import { updateLostDocumentRequestStart } from '../../../../redux/actions/notebookLostDoc/update'
import Select from '../../../Select'
import { api } from '../../../../App'
import { selectCustomStyles } from "../../../Select/customStyles";
import { bookInvNumbersListRequestStart } from '../../../../redux/actions/bookEdit/invNumbersTab/list'


const NotebookLostDocModal = ({ isOpen, closeModal, formData, initialModalData, setBack, back,}) => {

  const {latest, current} = useSelector(state => state.documents)
  const {all: allTypes} = useSelector(state => state.documentTypes)
  const dispatch = useDispatch()

  const [modalData, setModalData] = useState({ modalType: null, data: null })

  const [users, setUsers] = useState([]);
  const [currentVal, setCurrentVal] = useState(""); 

  useEffect(() => {
    !allTypes?.length && dispatch(documentTypesAllRequestStart())
  }, [])

  useEffect(() => {
    setModalData(modalData =>
      modalData.modalType === ADD
        ? { modalType: EDIT, data: latest }
        : modalData
    )
  }, [latest])

  
  // для поиска пользователя
  useEffect(()=>{
    onUsersInputChange()
    bookInvNumbersListRequestStart()
  },[isOpen])

  const onSaveModal = data => {
    const action = modalData.modalType === ADD
    ? createLostDocumentRequestStart
    : updateLostDocumentRequestStart
    dispatch(action(data))
  }
  const openModal = (item) => () => {
    setModalData({
      modalType: EDIT,
      data: { ...item, document_arrival_date: moment(item.document_arrival_date).format('YYYY-MM-DD') },
    })
    dispatch(setCurrentDocument(item))
  }

  const openCreationModal = (num) => {
    const documenttype = num;
    const data = {...initialModalData, documenttype}
    setModalData({ modalType: ADD, data})

    dispatch(setCurrentDocument(initialModalData))
  }

  const userDataResolver = (data) =>
  data.map((item) => ({
    label: item.lastname + " " + item.firstname + " " + item.patronymic,
    value: item.mid,
  }));
   

  const onUsersInputChange = (name) => {
  const params = {
    paginateFrom: 0,
    search: name,
    orderBy: "lastname",
  };
  
  api.get("/people", { params }).then((res) => {
    setUsers(userDataResolver(res.data));
  });
};

const { values, setFieldValue, handleChange, handleSubmit } = formData
  const disabled = !(values.user_id && values.del_doc_id && (values.income_doc_id || values.price))
  const disAct = !!values.del_doc_id 
  const disActIn =!!values.income_doc_id
  const actOutDone = values.del_doc_id ? `АКТ СПИСАНИЯ ДОБАВЛЕН` : `Добавить акт списания`
  const actInDone = values.income_doc_id ? `АКТ ПОСТУПЛЕНИЯ ДОБАВЛЕН` : `Добавить акт поступления`

  return (
    <>
   
    <ModalWindow
      title='Создание документа'
      isOpen={isOpen}
      onRequestClose={closeModal}
      width={600}
    >

    <div className={'formContainer'}>

      {modalData.modalType &&
        <DocumentsModal
          modalData={modalData}
          setModalData={setModalData}
          types={allTypes}
          onSave={onSaveModal}
          current={current}
          fromLostDoc
        /> 
      }
        <Col>
        <ModalInputWrapper label="Пользователь">
        <Select
          isSearchable={true}
          isClearable={true}
          onInputChange={onUsersInputChange}
          options={users}
          onChange={val => setFieldValue('user_id', val.value) }
          placeholder={"Введите пользователя..."}
          value={values.user_id ? currentVal?.label : ""}
          name="user_id"
          noOptionsMessage={() => "Ничего не найдено"}
          styles={selectCustomStyles}
         />
      </ModalInputWrapper>
        </Col>
        <Col>
                <Button label={actOutDone} onClick={()=>openCreationModal(18)} disabled={disAct}/>
        </Col>
        <br/>
        { back ==='' && !!values.del_doc_id &&
          <div style={{display:"flex", marginLeft:"15px", marginBottom:"10px"}}>
            <div style={{marginRight:"30px"}}>
            <Button  label='Возврат деньгами' onClick={()=> setBack(true)}/>
            </div>
            <div>
            <Button label='Книга взамен' onClick={()=> setBack(false)}/>
            </div>
            </div>
          }
          { back &&
            <Col>
              <Button label="Вернуться" onClick={()=>setBack("")}/>
              <ModalInputWrapper
              label={"Цена"}
              type="text"
              value={values.price}
              name="price"
              onChange={handleChange}
              />
            </Col>
          }
          {back === false &&
            <>
            <Col style={{marginBottom:'10px'}}>
            {values.income_doc_id === null && 
              <Button label="Вернуться" onClick={()=>setBack("")} />
            }
            </Col>
            <Col>
            <Button  label={actInDone} onClick={() => openCreationModal(1)} disabled={disActIn}/>
            </Col>
            </>
          }


      <Col>
      <ModalInputWrapper
        label={"Примечание"}
        value={values.description}
        type="text"
        name="description"
        onChange={handleChange}
      />
      </Col>  

        <Col md={'auto'} className={'text-right'}>
          <ModalInputWrapper label={''}>
            <Button type="submit" label={'Создать'} disabled={disabled} onClick={handleSubmit} />
          </ModalInputWrapper>
        </Col>
    </div>
    </ModalWindow>
    </>
  )
}


export default NotebookLostDocModal
