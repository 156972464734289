import { BOOK_FILE_REQUEST, START, SUCCESS, ERROR } from "../../../actions/action-types";

const initialState = {
  isLoading: false,
  bookFiles: []
};

export const bookEditFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_FILE_REQUEST}${START}`:
      return {
        ...state,
        isLoading: true
      };
    case `${BOOK_FILE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        bookFiles: action.payload
      };
    case `${BOOK_FILE_REQUEST}${ERROR}`:
      return {
        ...state,
        isLoading: false
      };
    default: return state;
  }
};
