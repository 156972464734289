import React from 'react';
import PropTypes from 'prop-types';
import {EDIT} from "../../../constants/modalTypes";
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Button, Space} from 'antd';
import ModalWindow from "../../ModalWindow";


const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Это обязательное поле')
        .trim('Это обязательное поле')
        .max(300, 'Недопустимая длина названия (макс 300)'),
    inn: Yup.string()
        .nullable()
        .max(300, 'Недопустимая длина названия (макс 30)'),
    region: Yup.string()
        .required('Выберите регион')
        .nullable(true),
    organization: Yup.string()
        .required('Выберите организацию')
        .nullable(true),
    transferhost: Yup.string()
        .required('Это обязательное поле')    
        .nullable(true)
        .max(200, 'Недопустимая длина названия (макс 200)'),
    transferport: Yup.number()
        .nullable(true)
        .notRequired()
        .max(65535, 'Число порта не может быть выше 65535'),
    transferinbox: Yup.string()
        .required('Это обязательное поле')    
        .nullable(true)
        .max(200, 'Недопустимая длина названия (макс 200)'),

});

const InstitutionsModal = ({closeModal, onSubmit, ranks, modalData, regionList, categoryOrganizationList}) => {
    const {modalType, data} = modalData;

    const modalForm = useFormik({
        initialValues: data,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: values => onSubmit(values)
    });

    return (
        <ModalWindow
            title={`Организация: ${modalType === EDIT ? "редактирование" : "добавление"}`}
            onRequestClose={closeModal}
            isOpen={true}
            width={500}
        >
            <Row>
                <Col md={12}>
                <ModalInputWrapper
                    label="Название организации"
                    type="textarea"
                    name="name"
                    value={modalForm.values.name}
                    error={modalForm.errors.name}
                    onChange={modalForm.handleChange}/>
                <ModalInputWrapper
                    label="Сокращенное название организации"
                    name="shortname"
                    value={modalForm.values.shortname}
                    error={modalForm.errors.shortname}
                    onChange={modalForm.handleChange}/>
                <ModalInputWrapper
                    label="Регион"
                    error={modalForm.errors.region}>
                    <select
                        name="region"
                        value={modalForm.values.region}
                        onChange={modalForm.handleChange}>
                        <option value="">Не выбрано</option>
                        {regionList.map((item, index) =>
                            <option value={item.idsubject} key={index}>{item.subject}</option>
                        )}
                    </select>
                </ModalInputWrapper>
                <ModalInputWrapper 
                    label="Категория организации" 
                    error={modalForm.errors.organization}>
                    <select
                        name="organization"
                        value={modalForm.values.organization}
                        onChange={modalForm.handleChange}
                    >
                        <option value="">Не выбрано</option>
                        {categoryOrganizationList.map((item) =>
                            <option value={item.id} key={item.id}>{item.name}</option>
                        )}
                    </select>
                </ModalInputWrapper>
                <ModalInputWrapper
                    label="Адрес сервера"
                    name="transferhost"
                    type="text"
                    value={modalForm.values.transferhost}
                    error={modalForm.errors.transferhost}
                    onChange={modalForm.handleChange}
                />
                <ModalInputWrapper
                    label="Порт сервера"
                    name="transferport"
                    type="number"
                    value={modalForm.values.transferport}
                    error={modalForm.errors.transferport}
                    onChange={modalForm.handleChange}
                />
                <ModalInputWrapper
                    label="Входящие"
                    name="transferinbox"
                    value={modalForm.values.transferinbox || ''}
                    error={modalForm.errors.transferinbox}
                    onChange={modalForm.handleChange}
                />
                <ModalInputWrapper
                    label="ИНН"
                    name="inn"
                    value={modalForm.values.inn || ''}
                    error={modalForm.errors.inn}
                    onChange={modalForm.handleChange}
                />
                <ModalInputWrapper
                    label="КПП"
                    name="kpp"
                    value={modalForm.values.kpp || ''}
                    error={modalForm.errors.kpp}
                    onChange={modalForm.handleChange}
                />
                <ModalInputWrapper
                    label="Телефон"
                    name="phone"
                    value={modalForm.values.phone || ''}
                    error={modalForm.errors.phone}
                    onChange={modalForm.handleChange}
                />
                <ModalInputWrapper
                    label="Должность руководителя"
                    name="ceoposition"
                    value={modalForm.values.ceoposition || ''}
                    error={modalForm.errors.ceoposition}
                    onChange={modalForm.handleChange}
                />
                <ModalInputWrapper
                    label="Звание руководителя"
                    error={modalForm.errors.ceorank}
                >
                    <select
                        name="ceorank"
                        value={modalForm.values.ceorank || ''}
                        onChange={modalForm.handleChange}
                    >
                        <option value="">Не выбрано</option>
                        {ranks.map(({title, id_mil_rank}) => <option value={id_mil_rank} key={id_mil_rank}>{title}</option>)}
                    </select>
                </ModalInputWrapper>
                <ModalInputWrapper
                    label="ФИО руководителя"
                    name="ceoname"
                    value={modalForm.values.ceoname || ''}
                    error={modalForm.errors.ceoname}
                    onChange={modalForm.handleChange}
                />
                <Space className={'mt-2 d-flex justify-content-end'}>
                    <Button type="primary" onClick={modalForm.handleSubmit}>Сохранить</Button>
                    <Button type="primary" onClick={closeModal} danger>Отмена</Button>
                </Space>
                </Col>
            </Row>
        </ModalWindow>
    );
};

InstitutionsModal.propTypes = {
    modalData: PropTypes.object,
    setModalData: PropTypes.func,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func
};

export default InstitutionsModal;
