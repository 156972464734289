import {ELECTRONIC_BOOK_ZIP_SAVE, SUCCESS} from "../../../actions/action-types";

const initialState = null;

export const ElectronicBookZipImportReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${ELECTRONIC_BOOK_ZIP_SAVE}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};