import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  BOOK_SCAN_ORDER_ADD_REQUEST,
  BOOK_SCAN_ORDER_COUNT_REQUEST,
  BOOK_SCAN_ORDER_DELETE_REQUEST,
  BOOK_SCAN_ORDER_LIST_REQUEST,
  START,
} from "../../actions/action-types";
import {
  getBookScanOrdersCount,
  getBookScanOrders,
  createBookScanOrder,
  deleteBookScanOrder,
} from "../../../api/bookScanOrders/bookScanOrder";
import { bookScanOrderAddRequestSuccess, bookScanOrderAddRequestError } from "../../actions/bookScanOrders/add";
import {
  bookScanOrderCountRequestStart,
  bookScanOrderCountRequestSuccess,
  bookScanOrderCountRequestError,
} from "../../actions/bookScanOrders/count";
import {
  bookScanOrderListRequestStart,
  bookScanOrderListRequestSuccess,
  bookScanOrderListRequestError,
} from "../../actions/bookScanOrders/list";
import { setBookScanOrderSearchData } from "../../actions/bookScanOrders/search";
import { bookScanOrderDeleteRequestSuccess, bookScanOrderDeleteRequestError } from "../../actions/bookScanOrders/delete";
import { unReserveBook } from "../../../api/book/reserveBook";
import { deleteBookTransferItem } from "../../../api/bookTransfer/bookTransfer";

export default function* bookScanOrderWatcher() {
  yield takeLatest(`${BOOK_SCAN_ORDER_ADD_REQUEST}${START}`, addWorker);
  yield takeLatest(`${BOOK_SCAN_ORDER_COUNT_REQUEST}${START}`, countWorker);
  yield takeLatest(`${BOOK_SCAN_ORDER_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${BOOK_SCAN_ORDER_DELETE_REQUEST}${START}`, deleteWorker);
}

function* countWorker() {
  const formData = yield select(({ bookScanOrders }) => bookScanOrders.form);
  const searchData = yield select(({ bookScanOrders }) => bookScanOrders.search);
  try {
    const response = yield call(getBookScanOrdersCount, { ...formData, ...searchData });
    const count = parseInt(response.data.count, 10);
    yield put(bookScanOrderCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setBookScanOrderSearchData({ ...searchData, pageCount }));
    yield put(bookScanOrderListRequestStart());
  } catch (error) {
    yield put(bookScanOrderCountRequestError());
  }
}

function* listWorker() {
  const formData = yield select(({ bookScanOrders }) => bookScanOrders.form);
  const searchData = yield select(({ bookScanOrders }) => bookScanOrders.search);
  const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  const status = 2

  try {
    const response = yield call(getBookScanOrders, { ...formData, ...searchData, paginateFrom, status  });
    yield put(bookScanOrderListRequestSuccess(response.data));
  } catch (error) {
    yield put(bookScanOrderListRequestError(error));
  }
}

function* addWorker({ payload: { bookId, locationId } }) {
  try {
    const response = yield call(createBookScanOrder, { bookId, locationId });
    yield put(bookScanOrderAddRequestSuccess(response.data));
  } catch (error) {
    yield put(bookScanOrderAddRequestError(error));
  }
}

function* deleteWorker({ payload: { id, ordertype } }) {
  try {
    // Шифр
    if (ordertype === 1) {
      yield call(unReserveBook, id);
      // Шифр
    } else if (ordertype === 2) {
      yield call(deleteBookScanOrder, id);
    } else {
      yield call(deleteBookTransferItem, id);
    }
    yield put(bookScanOrderDeleteRequestSuccess(ordertype));
    yield put(bookScanOrderCountRequestStart());
  } catch (error) {
    yield put(bookScanOrderDeleteRequestError(error));
  }
}
