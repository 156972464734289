import { api } from '../../App'
import { SORT_DIRECTION } from '../../constants/sortDirections'

export const getTurnoutStatsForUser = ({ sortBy, maxResults, paginateFrom, sortDirection }, search) => {
  const params = {
    maxResults,
    paginateFrom,
    ...search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
  }

  return api.get('/admin/logs/security', { params })
}

export const getTurnoutStatsCount = search => {
  const params = {
    count: true,
    ...search,
  }

  return api.get('/admin/logs/security', { params })
}
