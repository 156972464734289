import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { SET_AUTHORS_SEARCH_DATA } from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import { AUTHOR_SORT } from "../../../../constants/sort/authorSort";

const initialState = {
  query: "",
  page: 1,
  sortBy: AUTHOR_SORT.NAME,
  sortDirection: SORT_DIRECTION.ASC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  selectedInstitution: "",
  selectedLibrary: ""
};

export const authorsSearchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHORS_SEARCH_DATA:
      return { ...action.payload };

    default:
      return state;
  }
};
