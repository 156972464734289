import React from 'react';
import PropTypes from 'prop-types';
import styles from './BookDescriptionInfoWrapper.module.css';
import { Col, Row } from "react-bootstrap";

const BookDescriptionInfoWrapper = ({label, value}) => {
  return (
    <div className={styles.container}>
      <Row>
        <Col md={4}>
          <div className={styles.label}>
            {label}:
          </div>
        </Col>
        <Col md={8}>
          <div className={styles.value}>
            {value}
          </div>
        </Col>
      </Row>
    </div>
  );
};

BookDescriptionInfoWrapper.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
};

export default BookDescriptionInfoWrapper;
