import { CREDITS_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const creditsListRequestStart = (studentId) => ({
  type: `${CREDITS_LIST_REQUEST}${START}`,
  payload: studentId
});

export const creditsListRequestSuccess = (data) => ({
  type: `${CREDITS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const creditsListRequestError = (data) => ({
  type: `${CREDITS_LIST_REQUEST}${ERROR}`,
  payload: data
});

