import { LITERS_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const litersCatalogCountRequestStart = (data) => ({
  type: `${LITERS_COUNT_REQUEST}${START}`,
  payload: data
});

export const litersCatalogCountRequestSuccess = (data) => ({
  type: `${LITERS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const litersCatalogCountRequestError = (data) => ({
  type: `${LITERS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
