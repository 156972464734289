import React, { useState } from "react";
import T from "prop-types";
import styles from "./LibraryFundsTab.module.css";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import { nonNull } from "../../../../utils/strings";
import Button from "../../../Button";
import { librarySaveFundRequestStart } from "../../../../redux/actions/library/info/fund/save";
import { libraryDeleteFundRequestStart } from "../../../../redux/actions/library/info/fund/delete";
import { ADD } from "../../../../constants/modalTypes";
import { libraryAddFund } from "../../../../redux/actions/library/info/add/funds";

const mapStateToProps = ({ library }) => ({
  addFunds: library.info.addFunds
});

const mapDispatchToProps = dispatch => ({
  saveFund: (data, libraryId) =>
    dispatch(librarySaveFundRequestStart(data, libraryId)),
  deleteFund: (fundId, libraryId) =>
    dispatch(libraryDeleteFundRequestStart(fundId, libraryId)),
  libraryAddFund: data => dispatch(libraryAddFund(data))
});

const LibraryFundsTab = ({
  data,
  saveFund,
  deleteFund,
  libraryId,
  modalType,
  libraryAddFund,
  addFunds
}) => {
  const emptyFormItem = {
    name: ""
  };

  const [formItem, setFormItem] = useState(null);

  const handleSelect = item => {
    setFormItem(formItem && formItem.id === item.id ? null : item);
  };

  const onSave = () => {
    if (modalType !== ADD) {
      saveFund(formItem, libraryId);
      setFormItem(null);
    } else {
      libraryAddFund(formItem);
      setFormItem(null);
    }
  };

  const onDelete = () => {
    formItem && formItem.id && deleteFund(formItem.id, libraryId);
    setFormItem(null);
  };

  const onChange = ({ target: { value } }) => {
    if (value.length>500) {
      setFormItem((prev) =>({ ...prev, error: "Превышено количество символов!" }))
    } else {
      setFormItem((prev) => ({ ...prev, name: value }))
    }
  };

  const addFundsMap = (item, index) => (
    <div className={classNames(styles.item)} key={index}>
      {item.name}
    </div>
  );

  const dataMap = (item, index) => (
    <div
      className={classNames(styles.item, {
        [styles.selected]: formItem && formItem.id === item.id
      })}
      key={index}
      onClick={() => handleSelect(item)}
    >
      {item.name}
    </div>
  );

  return (
    <div className={styles.container}>
      <Row>
        <Col md={7}>
          <div className={styles.list}>
            {modalType !== ADD ? data.map(dataMap) : addFunds.map(addFundsMap)}
          </div>
        </Col>
        <Col md={5}>
          {formItem && (
            <div className={styles.formContainer}>
              <ModalInputWrapper
                label={"Название"}
                labelSpan={12}
                inputSpan={12}
                value={nonNull(formItem.name)}
                onChange={onChange}
                error={nonNull(formItem.error)}
              />
              <Button
                label={formItem.id ? "Изменить" : "Добавить"}
                onClick={onSave}
              />
            </div>
          )}
        </Col>
        <Col md={5}>
          <div className={styles.buttonsContainer}>
            <div
              className={styles.listButton}
              onClick={() => setFormItem(emptyFormItem)}
            >
              Добавить
            </div>
            {modalType !== ADD && (
              <div className={styles.listButton} onClick={onDelete}>
                Удалить
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

LibraryFundsTab.propTypes = {
  data: T.array,
  libraryId: T.any
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LibraryFundsTab);
