import React, { useEffect, useState } from 'react'
import { invNumbersCountRequestStart } from '../../../redux/actions/invNumbers/count'
import { setInvNumbersFormData } from '../../../redux/actions/invNumbers/form'
import { setInvNumbersSearchData } from '../../../redux/actions/invNumbers/search'
import Pagination from '../../../components/Pagination'
import { connect } from 'react-redux'
import InvNumbersTable from '../../../components/InvNumbers/Table'
import InvSearchFormControls from '../../../components/InvNumbers/SearchFormControls'
import { INV_NUMBERS_FORM_STRUCTURE } from '../../../constants/invNumbers'
import InvNumbersModal from '../../../components/InvNumbers/Modal'
import { invNumberSaveRequestStart } from '../../../redux/actions/invNumbers/add'
import { invNumberDeleteRequestStart } from '../../../redux/actions/invNumbers/delete'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import { librariesListRequestStart } from '../../../redux/actions/documents/fundAllocation/libraries'
import InvNumbersSearchForm from '../../../components/InvNumbers/Form'
import LocationModal from '../../../components/InvNumbers/LocationModal'

const mapStateToProps = ({ invNumbers, options, documents }) => ({
  list: invNumbers.list,
  count: invNumbers.count,
  loader: invNumbers.loader,
  formData: invNumbers.form,
  searchData: invNumbers.search,
  clientData: options.data,
  libraries: documents.libraries,
})

const mapDispatchToProps = {
  requestList: invNumbersCountRequestStart,
  setFormData: setInvNumbersFormData,
  setSearchData: setInvNumbersSearchData,
  addInvNumber: invNumberSaveRequestStart,
  deleteInvNumber: invNumberDeleteRequestStart,
  requestLibraries: librariesListRequestStart,
}

const initialModalData = { modalType: null, data: null }

const InvNumbersScene = ({
  clientData,
  count,
  list,
  loader,
  requestList,
  formData,
  setFormData,
  searchData,
  setSearchData,
  addInvNumber,
  deleteInvNumber,
  requestLibraries,
  libraries,
}) => {
  const [selectedIds, setSelectedIds] = useState([])
  const [modalData, setModalData] = useState(initialModalData)
  const [locationModalData, setLocationModalData] = useState(null)

  useEffect(() => {
    clientData && requestLibraries(clientData.own_client_id)
  }, [requestLibraries, clientData])

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const setSort = (sortBy, sortDirection) => {
    setSearchData({
      ...searchData,
      sortBy,
      page: 1,
      sortDirection: sortDirection,
    })
    requestList()
  }

  const handleSelect = id =>
    setSelectedIds(
    // selectedIds => {
    //   const idIndex = selectedIds.indexOf(id)
    //   if (idIndex !== -1) selectedIds.splice(idIndex, 1)
    //   else selectedIds.push(id)
    //   return [...selectedIds]
    // })
      selectedIds.includes(id) ? [] : [id]
    )

  const handleFormClear = () => {
    setFormData(INV_NUMBERS_FORM_STRUCTURE)
  }

  const onSubmitModal = modalData => {
    addInvNumber(modalData)
    setModalData({ modalType: null, data: null })
  }

  const onDelete = () => {
    selectedIds.length === 1 && deleteInvNumber(selectedIds[0])
    setSelectedIds([])
  }

  const closeModal = () => {
    setModalData(initialModalData)
  }

  const closeLocationModal = () => {
    setLocationModalData(null)
  }

  const searchWithPageReset = () => {
    setSearchData({...searchData, page: 1})
    requestList()
  }

  return (
    <SceneContainer title={'Справочники / Инвентарные номера'}>
      {locationModalData && <LocationModal closeModal={closeLocationModal} data={locationModalData} />}

      {modalData.data && (
        <InvNumbersModal
          isModal
          setModalData={setModalData}
          modalData={modalData}
          onSubmit={onSubmitModal}
          closeModal={closeModal}
        />
      )}

      <InvNumbersSearchForm data={formData} setData={setFormData} librariesList={libraries}>
        <InvSearchFormControls
          formData={formData}
          onSubmit={searchWithPageReset}
          onDelete={onDelete}
          onClear={handleFormClear}
          setLocationModalData={setLocationModalData}
          selectedIds={selectedIds}
          setModal={setModalData}
        />
      </InvNumbersSearchForm>

      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        <InvNumbersTable
          setSort={setSort}
          sortBy={searchData.sortBy}
          data={list}
          selectedIds={selectedIds}
          setModalData={setModalData}
          handleSelect={handleSelect}
        />
      )}
      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InvNumbersScene)
