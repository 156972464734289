import { FUND_MOVING_LOAD_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const fundMovingLoadStart = () => ({
  type: `${FUND_MOVING_LOAD_REQUEST}${START}`,

});

export const fundMovingLoadSuccess = (data) => ({
  type: `${FUND_MOVING_LOAD_REQUEST}${SUCCESS}`,
  payload: data
});

export const fundMovingLoadError = (data) => ({
  type: `${FUND_MOVING_LOAD_REQUEST}${ERROR}`,
  payload: data
});
