import { INV_NUMBERS_COUNT_REQUEST, INV_NUMBERS_LIST_REQUEST, START, SUCCESS } from "../../../actions/action-types";

const initialState = false;

export const invNumbersLoaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${INV_NUMBERS_COUNT_REQUEST}${START}`:
            return true;

        case `${INV_NUMBERS_LIST_REQUEST}${SUCCESS}`:
            return false;

        default: return state;
    }
}
