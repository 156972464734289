import { loadBigData } from '../../../api/BigData/load';
import { BIG_DATA_REQUEST, START } from '../../actions/action-types';
import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import {createSimpleActionsForReducer} from "../../actions/actionsFactory";
import {createSimpleReducerWithLoader} from "../../reducers/reducersFactrory";

/*  ACTIONS  */
export const bigDataActions = createSimpleActionsForReducer(
  "bigData",
  BIG_DATA_REQUEST
);

/*  REDUCER  */
export const bigDataReducer = createSimpleReducerWithLoader(
  [],
  BIG_DATA_REQUEST
);

/*  SAGA  */
export function* bigDataSagaWatcher() {
  yield takeLatest(`${BIG_DATA_REQUEST}${START}`, bigDataSaga);
}

function* bigDataSaga({ payload }) {
  try {
    const response = yield call(loadBigData, payload);

    yield put(bigDataActions.bigDataRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}
