import {
  RESET_ADMIN_MODAL_USERS,
  SET_ADMIN_MODAL_SEARCH_DATA
} from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import { USERS_SORT } from "../../../../constants/sort/usersSort";

const initialState = {
  query: "",
  page: 1,
  sortBy: USERS_SORT.LAST_NAME,
  sortDirection: SORT_DIRECTION.ASC,
  maxResults: 10,
  pageCount: 0
};

export const adminModalUsersSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_MODAL_SEARCH_DATA:
      return action.payload;
    case RESET_ADMIN_MODAL_USERS:
      return { ...initialState };
    default:
      return state;
  }
};
