import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import AdminUsersModal from '../../components/AdminUsers/Modal'
import AdminUsersTable from '../../components/AdminUsers/Table'
import Pagination from '../../components/Pagination'
import Search from '../../components/Search'
import { adminGroupsRequestAllGroupsStart } from '../../redux/actions/adminGroups/groups'
import { usersCountRequestStart } from '../../redux/actions/adminUsers/count'
import { usersDeleteRequestStart } from '../../redux/actions/adminUsers/delete'
import { usersSaveRequestStart } from '../../redux/actions/adminUsers/save'
import { setUsersSearchData } from '../../redux/actions/adminUsers/search'
import styles from './AdminUsersScene.module.css'
// import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'

const mapStateToProps = ({ adminUsers: users, adminGroups }) => ({
  list: users.list,
  count: users.count,
  searchData: users.search,
  groups: adminGroups.groups,
  isLoading: users.save.isLoading,
})

const mapDispatchToProps = dispatch => ({
  requestUsers: () => dispatch(usersCountRequestStart()),
  saveUser: data => dispatch(usersSaveRequestStart(data)),
  deleteUser: mid => dispatch(usersDeleteRequestStart(mid)),
  setSearchData: data => dispatch(setUsersSearchData(data)),
  requestAdminGroups: () => dispatch(adminGroupsRequestAllGroupsStart()),
})

const AdminUsersScene = ({
  list,
  requestUsers,
  deleteUser,
  saveUser,
  searchData,
  setSearchData,
  count,
  groups,
  requestAdminGroups,
  isLoading,
}) => {
  useEffect(() => {
    requestUsers()
  }, [requestUsers])

  useEffect(() => {
    requestAdminGroups()
  }, [])

  useEffect(() => {
    setModalData(modalData => {
      const { data } = modalData
      if (data) {
        data.photosize = list.find(item => item.mid === data.mid).photosize
        return { ...modalData, data: { ...data } }
      }
      return modalData
    })
  }, [list])

  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedId, setSelectedId] = useState(null)

  const onSubmitModal = data => {
    saveUser(data)
    // setModalData({ modalType: null, data: null })
  }

  useEffect(() => {
    !isLoading && setModalData({ modalType: null, data: null })
  }, [isLoading])

  const setSort = (sortBy, sortDirection) => {
    setSearchData({
      ...searchData,
      sortBy,
      page: 1,
      sortDirection: sortDirection,
    })
    requestUsers()
  }

  const handleSelect = id => setSelectedId(selectedId === id ? null : id)

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestUsers()
  }

  return (
    <div className={styles.container}>
      {modalData.modalType && (
        <AdminUsersModal
          isLoading={isLoading}
          onSubmit={onSubmitModal}
          setModalData={setModalData}
          modalData={modalData}
          groups={groups}
        />
      )}
      <Row>
        <Col md={6}>
          <div className={styles.title}>Администрирование / Пользователи</div>
        </Col>
        <Col md={6}>
          <Search
            value={searchData.query}
            onChange={query => setSearchData({ ...searchData, query })}
            onSubmit={requestUsers}
            placeholder={'поиск'}
          />
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
        </Col>
        <Col md={12}>
          <AdminUsersTable
            data={list}
            setModal={setModalData}
            sortBy={searchData.sortBy}
            setSort={setSort}
            handleSelect={handleSelect}
            selectedId={selectedId}
            onDelete={() => deleteUser(selectedId)}
          />
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersScene)
