import {MESSAGE_NOTIFY_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = 0;

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${MESSAGE_NOTIFY_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
