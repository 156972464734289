import { ERROR, HAVKINA_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const havkinaCountRequestStart = () => ({
  type: `${HAVKINA_COUNT_REQUEST}${START}`
});

export const havkinaCountRequestSuccess = (data) => ({
  type: `${HAVKINA_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const havkinaCountRequestError = (data) => ({
  type: `${HAVKINA_COUNT_REQUEST}${ERROR}`,
  payload: data
});
