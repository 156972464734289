import React, { useCallback, useEffect, useState } from "react";
import T from "prop-types";
import moment from "moment";
import styles from "./BookEditIndexTab.module.css";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import { nonNull } from "../../../../utils/strings";
import { verifyStatusList } from "../../../../constants/bookVerifyStatus";
import { extractReasons } from "../../../../constants/extractReasons";
import { rightOfUseList } from "../../../../constants/bookRightOfUse";
import { authorsCountRequestStart } from "../../../../redux/actions/authors/count";
import { setAuthorsSearchData } from "../../../../redux/actions/authors/searchData";
import { setLitersCatalogSearchData } from "../../../../redux/actions/liters/search";
import { connect } from "react-redux";
import SearchableSelect from "../../../Select";
import {
  authorsToOptions,
  categoriesToOptions,
  optionsToAuthors,
  optionsToCategories,
  optionsToPublishers,
  outsecrecyToOptions,
  publishersToOptions,
  litersToOptions,
  optionsToLiters
} from "../../../../utils/toSelectOption";
import { publishersCountRequestStart } from "../../../../redux/actions/publishers/count";
import { setPublishersSearchData } from "../../../../redux/actions/publishers/searchData";
import { institutionsCountRequestStart } from "../../../../redux/actions/institutions/count";
import { setInstitutionsSearchData } from "../../../../redux/actions/institutions/search";
import BookScans from "./BookScans";
import { resolveBookCoverUrl } from "../../../../utils/resolvePhotoUrl";
import {
  cleanBookCover,
  cleanBookScan,
  saveBookCover,
  saveBookScan,
  savePdf,
  deletePdfRequest,
} from "../../../../redux/actions/bookData/data";
import BookCover from "./BookCover";
import { outsecrecyCountRequestStart } from "../../../../redux/actions/outsecrecy/count";
import { setOutsecrecySearchData } from "../../../../redux/actions/outsecrecy/searchData";
import UDKCodes from "./UDKCodes";
import {
  udkListCollapse,
  udkListRequestStart,
} from "../../../../redux/actions/udk/list";
import {
  bbkListCollapse,
  bbkListRequestStart,
} from "../../../../redux/actions/bbk/list";
import {
  svniListCollapse,
  svniListRequestStart,
} from "../../../../redux/actions/svni/list";
import { categoriesListRequestStart } from "../../../../redux/actions/categories/list";
import { setCategoriesSearchData } from "../../../../redux/actions/categories/searchData";
import { libraryTypesListRequestStart } from "../../../../redux/actions/library/types/list";
import { bookCardDataRequestStart } from "../../../../redux/actions/bookData/bookCard";
import Input from "../../../ElectronicBookImport/Input";
import { electronicBookZipSaveStart } from "../../../../redux/actions/ElectronicBookImport/saveZip";
import { createNotification } from "../../../notification/notification";
import { generateSign } from "../../../../api/book/edit";
import { bookSourceRequestStart } from "../../../../redux/actions/booksource";
import { setDisciplineSearchData } from "../../../../redux/actions/discipline/searchData";
import { disciplineFullListRequestStart } from "../../../../redux/actions/discipline/fulllist";
import { litersCatalogCountRequestStart } from "../../../../redux/actions/liters/count";
import { seriesCatalogCountRequestStart } from "../../../../redux/actions/series/count";
import { setBookCover } from "../../../../redux/actions/bookEdit/bookCover/bookCover";
import { SELECT_TYPE } from "../../../../constants/selectType";
import SupplierField from "./SupplierField";
import { Button, Checkbox } from "antd";
import CatalogCardBody from "../../../../scenes/BookEdit/CatalogCardBody";
import { api } from '../../../../App'
import AsyncSelect from '../../../UtulityComponents/AsyncSelect'
import { TreeSelect } from 'antd'
import { litersListRequestStart } from "../../../../redux/actions/liters/list";
import { seriesListRequestStart } from "../../../../redux/actions/series/list";

const SEIZED_ID = "5";
const BY_SUBSCRIPTION_VALUE = "По подписке";

const mapStateToProps = ({
  authors,
  bookCard,
  bookSource,
  categories,
  publishers,
  library,
  institutions,
  outsecrecy,
  udk,
  bbk,
  svni,
  discipline,
  liters,
  series,
  bookEdit,
  options,
}) => ({
  authorsList: authors.list,
  authorsSearch: authors.search,
  litersSearch: liters.search,
  disciplineList: discipline.list,
  disciplineSearch: discipline.search,
  categoriesList: categories.list,
  categoriesSearch: categories.search,
  categoriesListLoading: categories.loader,
  libraryTypes: library.types.list,
  publishersSearch: publishers.search,
  publishersList: publishers.list,
  institutionsSearch: institutions.search,
  institutionsList: institutions.list,
  outsecrecySearch: outsecrecy.search,
  outsecrecyList: outsecrecy.list,
  UDKlist: udk,
  BBKlist: bbk,
  SVNIlist: svni,
  bookCardData: bookCard,
  bookSource: bookSource,
  bookCover: bookEdit.cover,
  litersList: liters.list,
  seriesList: series.list,
  optionsList: options.data,
  outsecrecy: options.data?.outsecrecy,
});

const mapDispatchToProps = {
  requestAuthors: authorsCountRequestStart,
  requestLibraryTypes: libraryTypesListRequestStart,
  requestBookCardData: bookCardDataRequestStart,
  setAuthorSearch: setAuthorsSearchData,
  setLitersSearch: setLitersCatalogSearchData,
  litersListRequestStart: litersListRequestStart,
  seriesListRequestStart: seriesListRequestStart,
  requestDiscipline: disciplineFullListRequestStart,
  setDisciplineSearch: setDisciplineSearchData,
  requestCategories: categoriesListRequestStart,
  setCategoriesSearch: setCategoriesSearchData,
  requestPublishers: publishersCountRequestStart,
  requestOutsecrecy: outsecrecyCountRequestStart,
  requestLiters: litersCatalogCountRequestStart,
  setOutsecrecySearch: setOutsecrecySearchData,
  setPublishersSearch: setPublishersSearchData,
  requestInstitutions: institutionsCountRequestStart,
  setInstitutionsSearch: setInstitutionsSearchData,
  uploadBookScan: saveBookScan,
  uploadPdf: savePdf,
  deletePdf: deletePdfRequest,
  cleanBookScan: cleanBookScan,
  uploadBookCover: saveBookCover,
  cleanBookCover: cleanBookCover,
  requestUDKList: udkListRequestStart,
  collapseUDKList: udkListCollapse,
  requestBBKList: bbkListRequestStart,
  collapseBBKList: bbkListCollapse,
  requestSVNIList: svniListRequestStart,
  collapseSVNIList: svniListCollapse,
  saveZip: electronicBookZipSaveStart,
  requestBookSourceList: bookSourceRequestStart,
  requestSeries: seriesCatalogCountRequestStart,
  setBookCover,
};

const BookEditIndexTab = ({
  data,
  setData,
  authorsSearch,
  litersSearch,
  setLitersSearch,
  litersListRequestStart,
  seriesListRequestStart,
  authorsList,
  requestLiters,
  categoriesList,
  categoriesSearch,
  requestAuthors,
  requestCategories,
  setAuthorSearch,
  setCategoriesSearch,
  publishersSearch,
  publishersList,
  requestPublishers,
  setPublishersSearch,
  uploadBookScan,
  cleanBookScan,
  cleanBookCover,
  setOutsecrecySearch,
  outsecrecySearch,
  outsecrecyList,
  requestOutsecrecy,
  UDKlist,
  requestUDKList,
  collapseUDKList,
  BBKlist,
  requestBBKList,
  collapseBBKList,
  SVNIlist,
  requestSVNIList,
  collapseSVNIList,
  libraryTypes,
  requestLibraryTypes,
  requestBookCardData,
  bookCardData,
  saveZip,
  requestBookSourceList,
  bookSource,
  litersList,
  requestSeries,
  seriesList,
  uploadBookCover,
  optionsList,
  outsecrecy,
  formErrors,
}) => {
  
  const scanFormat = [
    { id: 0, format: "Не выбрано" },
    { id: 1, format: "А0" },
    { id: 2, format: "А1" },
    { id: 3, format: "А2" },
    { id: 4, format: "А3" },
    { id: 5, format: "А4" },
    { id: 6, format: "А5" },
  ];

  const [bliographicDescription, setBliographicDescription] = useState("");
  const [authorSignCBState, setAuthorSignCBState] = useState(false);

  useEffect(() => {
    data?.title && data?.authors && data?.authors.length>0 && createibliographicDescription();
  }, [])

  useEffect(() => {
    if (data.typeId !== 13) return;
    
    const fio =  data.fio || data.authors[0]?.name
    const job_title = data.job_title || data.title
    const science_degree = data.science_degree || 'Кандидат наук'
    const work_status = data.work_status || 1
    const year = data.year || moment().year()

    setData({...data, fio, job_title, science_degree, work_status, year})
  }, [data.typeId])

  const onChange = ({ target: { value, name } }) => {
    const yearOverRange = name === 'year' && value > moment().year()
    const newValue = yearOverRange ? moment().year() : value
    setData({ ...data, [name]: newValue }); 
  };

  const authorSignCBClick = () => {
    setAuthorSignCBState(prev => !prev)
  }

  const createibliographicDescription = () => {

    const {
      authors,
      title,
      year,
      bbk,
      udk,
      publishers,
      pageNum,
      annotation,
      isbn,
      authorSign,
      pubinstanceamount,
    } = data;

    const firstAuthor = authors[0].name;
    const anotherAuthors = authors
      .slice(1, authors.length - 1)
      .map((item) => item.name + ", ")
      .join("");
    const allPublishers = publishers.map((item) => item.name + ", ").join("");
    const allBBK = bbk.map((e) => e + ", ").join("");
    const allUDK = udk.map((e) => e + ", ").join("");

    setBliographicDescription(`${firstAuthor}, ${title} / ${anotherAuthors} ${year}г.${
      pageNum && "-" + pageNum + " c."
    } ${pubinstanceamount ? pubinstanceamount + " экз." : ""} ${annotation}
ISBN ${isbn}
изд-во: ${allPublishers}
ББК: ${allBBK}    УДК: ${allUDK}`);
  };

  const onChangeDoc = e => {
    setData({ ...data, typeId: e || null })
  }

  const normolizeKeyNames = libraryTypes.map(el => (
    {
      title: el.name, 
      value: Number(el.id), 
      parent: el.parent,
      amount: el.amount
    }
    ))

  const

  treeD = l => l
    .reduce ((a, c) => {
      c.children = l.filter (i => i.parent == c.value).length && l.filter (i => i.parent == c.value) 
      a.push (c)
      return a
    }, [])
    // Шифр
    .filter (i => i.parent == 0)

  useEffect(() => {
    requestLibraryTypes();
  }, [requestLibraryTypes]);

  useEffect(() => {
    requestBookSourceList();
  }, [requestBookSourceList]);

  useEffect(() => {
    requestOutsecrecy();
  }, []);

  useEffect(() => {
    requestBookCardData(data.bookid);
  }, [requestBookCardData]);

  useEffect(() => {
    requestLiters();
  }, [requestLiters]);

  useEffect(() => {
    requestSeries();
  }, [requestSeries]);

  const generateSignHandler = () => {
    
    const requestString = data.authors?.length <= 3 ? data.authors[0].name : data.title;
    const requestQuery = requestString.split(" ")[0];
    const params = {extended: authorSignCBState};

    generateSign(requestString, params)
      .then((res) => {
        const newAuthorSign = !!res.data.length ? res.data[0].index : "";
        setData({ ...data, authorSign: newAuthorSign });
        if (!newAuthorSign) {
          createNotification("warning", "Не удалось определить авторский знак");
        }
      })
      .catch((err) => console.log(err));
  };
  
  const onAuthorInputChange = (name) => {
    setAuthorSearch({ ...authorsSearch, query: name });
    requestAuthors();
  };

  const onCategoriesInputChange = (name) => {
    setCategoriesSearch({ ...categoriesSearch, query: name });
    requestCategories();
  };

  const onOutsecrecyInputChange = (name) => {
    setOutsecrecySearch({ ...outsecrecySearch, query: name });
    requestOutsecrecy();
  };

  const onPublisherInputChange = (name) => {
    setPublishersSearch({ ...publishersSearch, query: name });
    requestPublishers();
  };

  const onUploadBookCover = (formData) => {
    uploadBookCover(formData);
  };

  const zipInputOnChangeHandler = (fileData) => {
    const file = new FormData();

    file.append("file", fileData);
    saveZip({
      file,
      id: data.bookid,
    });
  };

  const getCurrentOutsecrecyValue = useCallback(
    () => outsecrecyToOptions(outsecrecyList)?.find(({ value }) => +value == (data.eerOutSecrecyId)) || { label: "Не выбрано", value: null },
    [outsecrecyList, data.eerOutSecrecyId, outsecrecy]
  );

  const searchAuthors = useCallback(val => api.get(`/author?search=${val}&xp_client=&libraryid=&maxResults=2000&paginateFrom=0`), []);

  const addNewSelectItem = async (value, name) => {
      await api.post(`/${name}`, {name: value});
      if(name == 'liter') {
        litersListRequestStart();}
      if(name == 'series') {
      seriesListRequestStart();}
  }

  const litersToOption = (value, name) => {
    const arr = name == "liter" ? litersList : seriesList;
    const curVal = arr.find(el => el.id == value);
    return {
      label: curVal?.name,
      value: curVal?.id
    }
  }

  return (
    <div className={styles.container}>
      <Row>
        <Col sm={12} md={4} lg={3}>
          <BookCover
            bookId={data.bookid}
            onUpload={onUploadBookCover}
            onClean={cleanBookCover}
            coverUrl={
              data.coverImage &&
              resolveBookCoverUrl(data.coverImage, data.bookid)
            }
          />
          <BookScans
            isCard={true}
            onUpload={uploadBookScan}
            onClean={cleanBookScan}
            bookId={data.bookid}
            bookCardData={bookCardData}
            backCoverUrl={
              data.subscriptionBackCover &&
              resolveBookCoverUrl(data.subscriptionBackCover, data.bookid)
            }
            frontCoverUrl={
              data.subscriptionCover &&
              resolveBookCoverUrl(data.subscriptionCover, data.bookid)
            }
          />
          {data.typeId === "12" && ( /* Шифр */
            <div style={{ marginTop: "1rem" }}>
              <Input
                inputHandler={zipInputOnChangeHandler}
                label={"Загрузить архив"}
              />
            </div>
          )}
        </Col>
        <Col sm={12} md={8} lg={9}>
          <ModalInputWrapper
            label="Заглавие*"
            value={data.title}
            name="title"
            onChange={onChange}
            error={formErrors.title}
          />
          <ModalInputWrapper label="Авторы">
            <SearchableSelect
              selectType={SELECT_TYPE.AUTHORS}
              data={data}
              setData={setData}
              placeholder="Поиск"
              onInputChange={onAuthorInputChange}
              options={authorsToOptions(authorsList)}
              isMulti
              onChange={(value) =>
                setData({ ...data, authors: optionsToAuthors(value) })
              }
              value={authorsToOptions(data.authors)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper label="Cоавторы">
            <SearchableSelect
              selectType={SELECT_TYPE.AUTHORS}
              data={data}
              setData={setData}
              placeholder="Поиск"
              onInputChange={onAuthorInputChange}
              options={authorsToOptions(authorsList)}
              isMulti
              onChange={(value) =>
                setData({ ...data, collaborators: optionsToAuthors(value) })
              }
              value={authorsToOptions(data.collaborators)}
            />
          </ModalInputWrapper>
          <Row className={styles.authorSignRow}>
            <ModalInputWrapper
              label="Авторский знак"
              value={data.authorSign}
              name={"authorSign"}
              onChange={onChange}
              additionalButtonOnClick={generateSignHandler}
              additionalButtonText={"Определить"}
            />
              <Checkbox
                checked={authorSignCBState}
                onChange={authorSignCBClick}
              >
              <span>трехзначный код</span>
              </Checkbox>
          </Row>
          <ModalInputWrapper label="Издательства">
            <SearchableSelect
              selectType={SELECT_TYPE.PUBLISHERS}
              placeholder="Поиск"
              onInputChange={onPublisherInputChange}
              options={publishersToOptions(publishersList)}
              isMulti={true}
              onChange={(value) =>
                setData({ ...data, publishers: optionsToPublishers(value) })
              }
              value={publishersToOptions(data.publishers)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label="Год издания"
            value={data.year}
            name={"year"}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Кол-во страниц"
            value={data.pageNum}
            onChange={onChange}
            name={"pageNum"}
          />
          <Row>
            <Col md={3} sm={6}>
              <ModalInputWrapper
              label="Том"
              value={data.tom}
              onChange={onChange}
              name={"tom"}
              type='number'
              />
            </Col>
            <Col md={3} sm={6}>
              <ModalInputWrapper
              label="Всего томов"
              value={data.tom_count}
              onChange={onChange}
              name={"tom_count"}
              type='number'
              />
            </Col>
            <Col md={3} sm={6}>
              <ModalInputWrapper
              label="Выпуск"
              value={data.release}
              onChange={onChange}
              name={"release"}
              type='text'
              />
            </Col>
            <Col md={3} sm={6}>
              <ModalInputWrapper
              label="Часть"
              value={data.part}
              onChange={onChange}
              name={"part"}
              type='text'
              />
            </Col>
          </Row>
          {/* <ModalInputWrapper
            label='ISBN'
            value={data.isbn.join(',')}
            onChange={({ target: { value } }) => setData({ ...data, isbn: value.split(',') })}
          /> */}
          <UDKCodes
            data={data}
            setData={setData}
            type={"isbn"}
            directoryList={UDKlist}
            requestDirectoryList={requestUDKList}
            collapseDirectoryList={collapseUDKList}
          />
          <ModalInputWrapper label={'Тип документа'}>
            <TreeSelect
              // showSearch
              style={{
                width: '100%',
              }}
              value={data.typeId}
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              placeholder="Не выбрано"
              // allowClear
              // treeDefaultExpandAll
              treeData={treeD(normolizeKeyNames)}
              onChange={(e) => onChangeDoc(e)}
              />
          </ModalInputWrapper>

          <ModalInputWrapper label="Категории (предметные рубрики)">
            <SearchableSelect
              selectType={SELECT_TYPE.CATEGORY}
              data={data}
              setData={setData}
              placeholder="Поиск"
              onInputChange={onCategoriesInputChange}
              options={categoriesToOptions(categoriesList)}
              isMulti={true}
              onChange={(value) =>
                setData({ ...data, categories: optionsToCategories(value) })
              }
              value={categoriesToOptions(data.categories)}
            />
          </ModalInputWrapper>
          <ModalInputWrapper
            label="Аннотация"
            value={data.annotation}
            name={"annotation"}
            onChange={onChange}
            type={"textarea"}
          />
          <UDKCodes
            data={data}
            setData={setData}
            type={"udk"}
            directoryList={UDKlist}
            requestDirectoryList={requestUDKList}
            collapseDirectoryList={collapseUDKList}
          />
          <UDKCodes
            data={data}
            setData={setData}
            type={"bbk"}
            directoryList={BBKlist}
            requestDirectoryList={requestBBKList}
            collapseDirectoryList={collapseBBKList}
          />
          <UDKCodes
            data={data}
            setData={setData}
            type={"svni"}
            directoryList={SVNIlist}
            requestDirectoryList={requestSVNIList}
            collapseDirectoryList={collapseSVNIList}
          />
          <ModalInputWrapper
            label="Сведения, относящиеся к заглавию"
            value={data.addition}
            name={"addition"}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Ключевые слова"
            value={nonNull(data.keywords)}
            name={"keywords"}
            onChange={onChange}
          />

          <ModalInputWrapper label={"Гриф*"} error={formErrors.eerOutSecrecyId}>
            <select
              disabled={outsecrecy == 1}
              value={data.eerOutSecrecyId}
              className={styles.select}
              name="eerOutSecrecyId"
              onChange={onChange}
              >
              <option value={""}>Не выбрано</option>
              {outsecrecyToOptions(outsecrecyList).map((item, index) => (
                <option value={item.value} key={index}>
                  {item.label}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label={"Статус*"} error={formErrors.verifyStatus}>
            <select
              value={data.verifyStatus}
              className={styles.select}
              name="verifyStatus"
              onChange={onChange}
            >
              <option value={""}>Не выбрано</option>
              {verifyStatusList.map((item, index) => (
                <option value={item.value} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper label={"Источник*"} error={formErrors.book_source}>
            <SupplierField
              data={data}
              setData={setData}
              source={bookSource}
              secrecy={outsecrecy}
            />
          </ModalInputWrapper>
          {!!data?.verifyStatus && data?.verifyStatus.toString() === SEIZED_ID && (
            <ModalInputWrapper label={"Причина"}>
              <select
                value={nonNull(data.extractreason)}
                className={styles.select}
                name="extractreason"
                onChange={onChange}
              >
                <option value={""}>Не выбрано</option>
                {extractReasons.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          )}
          <ModalInputWrapper
            label={"Право использования*"}
            error={formErrors.rightofuse}
          >
            <select
              value={nonNull(data.rightofuse)}
              className={styles.select}
              name="rightofuse"
              onChange={onChange}
            >
              {rightOfUseList.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
          {data.rightofuse === BY_SUBSCRIPTION_VALUE && (
            <ModalInputWrapper
              label="Срок окончания подписки"
              value={moment(data.subscriptionenddate).format("YYYY-MM-DD")}
              name={"subscriptionenddate"}
              onChange={onChange}
              type="date"
            />
          )}

          <ModalInputWrapper label='Литера'>
            <SearchableSelect
              selectType={SELECT_TYPE.LITERS}
              onEnterCallback={e => addNewSelectItem(e, 'liter')}
              name='literaid'
              placeholder="Поиск"
              options={categoriesToOptions(litersList)}
              onChange={(value) => 
                setData({ ...data, literaid: value.value })
              }
              value={litersToOption(data.literaid, 'liter')}
            />
          </ModalInputWrapper>

          <ModalInputWrapper label='Серия'>
            <SearchableSelect
              selectType={SELECT_TYPE.SERIES}
              onEnterCallback={e => addNewSelectItem(e, 'series')}
              name='series'
              placeholder="Поиск"
              options={categoriesToOptions(seriesList)}
              onChange={(value) => 
                setData({ ...data, series: value.value })
              }
              value={litersToOption(data.series, 'series')}
            />
          </ModalInputWrapper>
        
          {/* <ModalInputWrapper label={"Серия"}>
            <select
              value={data.seriesid}
              className={styles.select}
              name="seriesid"
              onChange={onChange}
            >
              <option value={0}>Не выбрано</option>
              {seriesList.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </ModalInputWrapper> */}
          <div className="d-flex">
            <div style={{ width: "86%" }}>
              <ModalInputWrapper
                label={"Библиографическое описание"}
                name="bibliodescr"
                value={bliographicDescription}
                onChange={onChange}
                type={"textarea"}
              />
            </div>
            <Button
              style={{ marginTop: "85px", marginLeft: "auto" }}
              type="primary"
              onClick={createibliographicDescription}
            >
              Сформировать
            </Button>
          </div>
          {/* <CatalogCardBody data={data} authors={data.authors} /> */}
          <ModalInputWrapper label={"Формат сканирования"}>
            <select
              className={styles.select}
              value={data.pageformat}
              name="pageformat"
              onChange={onChange}
            >
              {scanFormat.map((item, index) => (
                <option value={item.format} key={`scanFormat${index}`}>
                  {item.format}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

BookEditIndexTab.propTypes = {
  data: T.object,
  setData: T.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookEditIndexTab);
