import React from "react";
import T from "prop-types";
import styles from "./AccessLogForm.module.css";
import { Col, Row } from "react-bootstrap";
import InputWrapper from "../../Modal/InputWrapper";
import { SECURITY_LOG_TYPE } from "../../../constants/securityLogTypes";
import Button from "../../Button";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const SecurityLogForm = ({ data, setData, search }) => {
  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values);
      search();
    },
  });

  const { values, errors } = modalForm;

  return (
    <div className={"formContainer"}>
      <Row>
        <Col md={4}>
          <InputWrapper
            onChange={modalForm.handleChange}
            name="from"
            value={values["from"]}
            error={errors["from"]}
            label="С (дата)"
            type="date"
          />
        </Col>
        <Col md={4}>
          <InputWrapper
            onChange={modalForm.handleChange}
            name="to"
            value={values["to"]}
            error={errors["to"]}
            label="До (дата)"
            type="date"
          />
        </Col>
        <Col md={4}>
          <InputWrapper
            onChange={modalForm.handleChange}
            name="fromip"
            value={values["fromip"]}
            label="IP-адрес"
          />
        </Col>
        <Col md={4}>
          <InputWrapper
            onChange={modalForm.handleChange}
            name="username"
            value={values["username"]}
            label="Логин"
          />
        </Col>
        <Col md={5}>
          <InputWrapper label="Тип">
            <select
              className={styles.select}
              onChange={modalForm.handleChange}
              name={"issue_type"}
              value={values["issue_type"]}
            >
              <option value={""}>Не выбрано</option>
              <option value={1}>{SECURITY_LOG_TYPE[1]}</option>
              <option value={2}>{SECURITY_LOG_TYPE[2]}</option>
              <option value={3}>{SECURITY_LOG_TYPE[3]}</option>
              <option value={4}>{SECURITY_LOG_TYPE[4]}</option>
            </select>
          </InputWrapper>
        </Col>
        <Col md={3} className={"text-right"}>
          <InputWrapper label={""}>
            <Button label={"Найти"} onClick={modalForm.handleSubmit} />
          </InputWrapper>
        </Col>
      </Row>
    </div>
  );
};

SecurityLogForm.propTypes = {
  data: T.object,
  setData: T.func,
  search: T.func,
};

export default SecurityLogForm;
