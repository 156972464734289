import { call, put, takeLatest } from 'redux-saga/effects'
import { editGroup } from '../../../api/groups/groups'
import { ADMIN_GROUPS_EDIT_GROUP, START } from '../../actions/action-types'
import { adminGroupsEditGroupError, adminGroupsEditGroupSuccess } from '../../actions/adminGroups/editGroup'
import { adminGroupsRequestAllGroupsStart } from '../../actions/adminGroups/groups'

export default function* adminGroupsEditGroupsWatcher() {
  yield takeLatest(`${ADMIN_GROUPS_EDIT_GROUP}${START}`, editGroupWorker)
}

function* editGroupWorker({ id, payload }) {
  try {
    const response = yield call(editGroup, id, payload)
    // yield put(adminGroupsEditGroupSuccess(response.data))
    yield put(adminGroupsRequestAllGroupsStart())
  } catch (error) {
    yield put(adminGroupsEditGroupError(error))
  }
}
