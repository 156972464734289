import jsPDF from "jspdf";
import addFont from "../../../../fonts/fonts";
import html2canvas from "html2canvas";
import moment from "moment";
import { htmlpdf } from "../../../Inventory/Modal/PrintDestroyAct/document";


export const getSecretData = async ({ data: books, clientData }) => {

const title = "Акт уничтожения носителей сведений, составляющих государственную тайну";
const leftIndent = 15;

addFont(jsPDF.API);

const booksData = books.map(
  (item, index) =>
    `<tr>
            <td>${index + 1}</td>
            <td>${item.title}</td>
            <td>${item.documentid}</td>
            <td>
            ${
              item.eerOutSecrecyId
                ? item.eerOutSecrecyId === 1
                  ? "Дсп"
                  : item.eerOutSecrecyId === 2
                  ? "Секретно"
                  : item.eerOutSecrecyId === 3
                  ? "Несекретно"
                  : "Не выбрано"
                : "Не выбрано"
            }
            </td>
            <td>1</td>
            <td>-</td>
            <td>${item.pageNum ? item.pageNum : "Не указано"}</td>
        </tr>`
);

const doCanvas = async () => {
  const report = document.createElement("div");
  const tbody = document.createElement("tbody");

  const orgName = clientData.GOU_Name; // 1 - название организации
  const nomenclatureName = clientData.nomenclatureApprovalPosition; // 2 - звание
  const nomenclatureApprovalPerson = clientData.nomenclatureApprovalPerson; // 3 - ФИО
  const positioncommissionchairman = clientData.positioncommissionchairman; // 5 - воинское звание председателя комиссии
  const namecommissionchairman = clientData.namecommissionchairman; // 6 - ФИО (председатель комиссии)
  const positionmembercommission = clientData.positionmembercommission; // 7 - воинское звание председателя комиссии
  const namecommissionmember = clientData.namecommissionmember; // 8 - ФИО (председатель комиссии)
  const nomenclatureApprovalDate = clientData.nomenclatureApprovalDate; // 9 - Дата разрешения уничтожения
  const nomenclatureSignDate = clientData.nomenclatureSignDate; // 10 - Дата составления акта
  const commission = `${positioncommissionchairman},  ${namecommissionchairman}, ${positionmembercommission}, ${namecommissionmember}`; // 4 - состав комиссии

  report.innerHTML = htmlpdf;
  document.body.appendChild(report);

  const approvalDate = moment(nomenclatureApprovalDate)
    .format("DD MMMM YYYY")
    .split(" "); // 01 апреля 2020
  const approvalDateString = `«_${approvalDate[0]}_»____${approvalDate[1]}____${approvalDate[2]}г.`; //  «_1_»___апреля___2020г.

  const signDate = moment(nomenclatureSignDate)
    .format("DD MMMM YYYY")
    .split(" ");
  const signDateString = `«_${signDate[0]}_»____${signDate[1]}____${signDate[2]}г.`;

  if (books) {
    tbody.innerHTML = booksData.join("");
    document.querySelector("#bookTable").appendChild(tbody);
  }

  if (clientData) {
    document.querySelector("#orgName").innerHTML = orgName;
    document.querySelector("#nomenclatureName").innerHTML = nomenclatureName;
    document.querySelector("#nomenclatureApprovalPerson").innerHTML =
      nomenclatureApprovalPerson;
    document.querySelector("#commission").innerHTML = commission;
    document.querySelector("#positioncommissionchairman").innerHTML =
      positioncommissionchairman;
    document.querySelector("#namecommissionchairman").innerHTML =
      namecommissionchairman;
    document.querySelector("#positionmembercommission").innerHTML =
      positionmembercommission;
    document.querySelector("#namecommissionmember").innerHTML =
      namecommissionmember;
    document.querySelector("#nomenclatureApprovalDate").innerHTML =
      approvalDateString;
    document.querySelector("#nomenclatureSignDate").innerHTML = signDateString;
  }

  //document.body.appendChild(document.getElementById("report"));

  const canvas = await html2canvas(document.getElementById("report"), {
    scrollY: 0,
    scrollX: 0,
  });

  return canvas;

  // html2canvas(document.getElementById("report"), {
  //     scrollY: 0,
  //     scrollX: 0
  // }).then(canvas => {
  //     doPDF(canvas);
  //     report.remove();
  // });
};

  const imgWidth = 210;
  const canvas = await doCanvas();
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  const imgData = canvas.toDataURL("image/png");
  const doc = new jsPDF("p", "mm");
  const pageHeight = 295;

  let heightLeft = imgHeight;
  let position = 0;

  doc.setFont("Montserrat");
  doc.text(title, leftIndent, 15);

  doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  doc.save(`${title}.pdf`);
};
