import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../../ModalWindow";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from "../Item/BookLoanItem.module.css";
import Button from "../../../Button";

const BookLoanItemModal = ({
                               bookEditModalOpen,
                               setBookEditModalOpen,
                               isIssueOfBook,
                               bookDates,
                               setBookDates,
                               isReturnOfBook,
                               isEditModalError,
                               onSubmitEdit,
                               data
                           }) => {
    const onChange = (e) => {
        setBookDates({
            ...bookDates,
            [e.target.name]: e.target.value
        })
    };

    return (
        <ModalWindow
            isOpen={bookEditModalOpen}
            onRequestClose={() => setBookEditModalOpen(false)}
            title={"Редактировать книгу"}
            width={'500px'}
            height={'350px'}
        >
            {isIssueOfBook && <>
                <ModalInputWrapper
                    name="movedate"
                    value={bookDates.movedate}
                    onChange={onChange}
                    type={"date"}
                    label={"Дата выдачи"}
                />
                <ModalInputWrapper
                    name="returndate"
                    value={bookDates.returndate}
                    onChange={onChange}
                    type={"date"}
                    label={"Планируемая дата возврата"}
                />
            </>}
            {isReturnOfBook && <>
                <ModalInputWrapper
                    name="movedate"
                    value={bookDates.movedate}
                    onChange={onChange}
                    type={"date"}
                    label={"Дата возврата"}
                />
            </>}
            {isEditModalError && <div className={styles.formError}>
                Ошибка! Неверно указаны даты выдачи.
            </div>}
            <div className="d-flex justify-content-end mt-3">
                <Button
                    label={'Сохранить'}
                    onClick={() => onSubmitEdit(data.id)}
                />
            </div>
        </ModalWindow>
    );
};

BookLoanItemModal.propTypes = {

};

export default BookLoanItemModal;