import {DOCUMENT_BOOK_CLEANUP, SET_CURRENT_DOCUMENT} from "../../../actions/action-types";

const initialState = false;

export const currentBookReducer = (state = initialState, action) => {
  switch (action.type) {

    case `DOCUMENT_SAVE_REQUEST_SUCCESS`:
      return action.payload;

    case SET_CURRENT_DOCUMENT:
      return action.payload;

    case `${DOCUMENT_BOOK_CLEANUP}`:
      return initialState;

    default: return state;
  }
};