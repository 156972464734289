import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './ReportsListScene.module.css'
import { reportsListRequestStart } from '../../../redux/actions/reports/list'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'

const mapStateToProps = ({ reports }) => ({
  list: reports.list,
})

const mapDispatchToProps = {
  requestList: reportsListRequestStart,
}

const ReportsListScene = ({ list, requestList }) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  return (
    <SceneContainer title={'Отчёты'}>
      <ul>
        {list &&
          list.map((item, index) => (
            <li className={styles.report} key={index}>
              <Link to={`/report/${item.id}`}>{item.name}</Link>
            </li>
          ))}
      </ul>
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListScene)
