import React from 'react'
import PropTypes from 'prop-types'
import styles from './TableView.module.css'
import BookLoanItem from '../List/Item'

const TableView = ({
  mid,
  data,
  reserve,
  unreserveBook,
  onTurnOut,
  onDelete,
  onEdit,
  isCompact,
  isLibrarian,
  setSearchBookModalData,
  setSearchBookModalVisible,
  outdatedBooks,
  prepareBook,
}) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <td>Дата выдачи</td>
          <td>Инв.номер</td>
          <td>Автор и заглавие книги</td>
          <td>Сдать</td>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <BookLoanItem
            currentMid={mid}
            onTurnIn={onTurnOut && (() => onTurnOut && onTurnOut(item.bookinstanceid))}
            data={item}
            key={index}
            onDelete={onDelete}
            onEdit={onEdit}
            reserve={reserve}
            unreserveBook={unreserveBook}
            isCompact={isCompact}
            isLibrarian={isLibrarian}
            setSearchBookModalData={setSearchBookModalData}
            setSearchBookModalVisible={setSearchBookModalVisible}
            outdatedBooks={outdatedBooks}
            prepareBook={prepareBook}
          />
        ))}
      </tbody>
    </table>
  )
}

TableView.propTypes = {
  data: PropTypes.array,
  reserve: PropTypes.bool,
  isLibrarian: PropTypes.bool,
}

export default TableView
