import { call, put, takeLatest } from 'redux-saga/effects'
import { LOST_DOC_GET_REQUEST, LOST_DOC_ADD_REQUEST,LOST_DOC_GET_PDF_REQUEST, START} from '../../actions/action-types';
import {getLostDoc, addLostDoc, getLostDocCount} from "../../../api/notebookLostDoc/notebookLostDoc"
import { lostDocError, lostDocSuccess, lostDocStart } from '../../actions/notebookLostDoc/getLostDoc';
import { postLostDocSuccess } from '../../actions/notebookLostDoc/postLostDoc';
import { addLostDocSuccess } from '../../actions/notebookLostDoc/addLostDoc';
import { lostDocumentCountRequestSuccess } from '../../actions/notebookLostDoc/count';
import { documentNumberRequestStart } from '../../actions/documents/documents/number';
import { lostDocPdfSuccess } from '../../actions/notebookLostDoc/pdf';

export default function* lostDocWatcher() {
    yield takeLatest(`${LOST_DOC_GET_REQUEST}${START}`, lostWorker)
    yield takeLatest(`${LOST_DOC_GET_REQUEST}${START}`, lostPdfWorker)
    yield takeLatest(`${LOST_DOC_ADD_REQUEST}${START}`, addWorker)

function* lostWorker({payload}) {

    try{
        const countResponse = yield call(getLostDocCount, payload);
        const response = yield call(getLostDoc, payload);
        yield put(documentNumberRequestStart())
        yield put(lostDocSuccess(response.data))
        yield put(lostDocumentCountRequestSuccess(countResponse.data.number))
    } catch (err) {
        yield put (lostDocError(err));
    }
}
function* lostPdfWorker({payload}) {
const takeArrForPdf = {...payload, limit:null, offset: null}
    try{
        const responseForPdf = yield call(getLostDoc, takeArrForPdf);
        yield put(lostDocPdfSuccess(responseForPdf.data))
    } catch (err) {
        yield put (lostDocPdfSuccess(err));
    }
}

function* addWorker({ payload:data}) {
    try {
      const response = yield call(addLostDoc, data)
      yield put(addLostDocSuccess(response.data))
      yield put(lostDocStart(response.data))
    } catch (err) {
      yield put(postLostDocSuccess(err))
    }
  }
}