import {
  LITERS_COUNT_REQUEST, START,
  SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const litersCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LITERS_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};