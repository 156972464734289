import React from 'react'
import T from 'prop-types'
import styles from './ReadingList.module.css'
import ReadingListItem from '../Item'
import classNames from 'classnames'
import { ICONS } from '../../../utils/projectIcons'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import { Link } from 'react-router-dom'
import ControlsPanel from './ControlsPanel'

const ReadingList = ({
  data,
  reserveBook,
  unreserveBook,
  loadSuccess,
  addBookScanOrder,
  addBookOrderHandler,
  isTableViewCompact,
  setTableViewCompact,
  count
}) => (
  <div className={styles.container}>
    {(loadSuccess && data.length)  === 0 || count  == 0 ? (
      <div className='emptyNotify'>По Вашему запросу ничего не найдено</div>
    ) : (
      <>
        {data.length > 0 && (
          <div className={'d-flex justify-content-end mb-3'}>
            <button
              onClick={() => setTableViewCompact(true)}
              className={classNames(styles.viewButton, isTableViewCompact && styles.viewButtonActive)}
              title='Отображать таблицей'
            >
              <img src={ICONS.MENU} alt='' />
            </button>
            <button
              onClick={() => setTableViewCompact(false)}
              className={classNames(styles.viewButton, !isTableViewCompact && styles.viewButtonActive)}
              title='Отображать списком'
            >
              <img src={ICONS.LIST} alt='' />
            </button>
          </div>
        )}
        {isTableViewCompact ? (
          <DataTable data={data}>
            <DataTableCol
              title={'Автор и заглавие'}
              resolver={item => (
                <>
                  <Link to={`/book/${item.bookid}`} target={'_blank'}>
                    {item.title}
                  </Link>
                  <small className={'ml-2'}>{item.authors}</small>
                </>
              )}
            />
            <DataTableCol title={'Год'} value={'year'} />
            <DataTableCol title={'Размещение'} value={'clients'} />
            <DataTableCol title={'Фонд'} value={'fund'} />
            <DataTableCol
              title={''}
              resolver={item => (
                <ControlsPanel
                  data={item}
                  reserveBook={reserveBook}
                  unreserveBook={unreserveBook}
                  addBookScanOrder={addBookScanOrder}
                  addBookOrderHandler={addBookOrderHandler}
                />
              )}
            />
          </DataTable>
        ) : (
          data.map((item, index) => (
            <ReadingListItem
              data={item}
              key={index}
              addBookScanOrder={addBookScanOrder}
              addBookOrderHandler={addBookOrderHandler}
              reserveBook={reserveBook}
              unreserveBook={unreserveBook}
            />
          ))
        )}
      </>
    )}
  </div>
)

ReadingList.propTypes = {
  data: T.array,
  reserveBook: T.func,
  unreserveBook: T.func,
  loadSuccess: T.bool,
}

export default ReadingList
