import {disciplineListReducer} from "./list/disciplineListReducer";
import {combineReducers} from "redux";
import {disciplineCountReducer} from "./count/disciplineCountReducer";
import {disciplineSearchDataReducer} from "./search/disciplineSearchDataReducer";
import {disciplineFormReducer} from "./form/disciplineFormReducer";

export const disciplineReducer = combineReducers({
    count: disciplineCountReducer,
    list: disciplineListReducer,
    search: disciplineSearchDataReducer,
    form: disciplineFormReducer
})




