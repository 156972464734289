import { LOST_DOC_DOCUMENT_CREATE_REQUEST,LOST_DOC_DOCUMENT_CLEAN, START, SUCCESS, ERROR } from "../action-types";

export const createLostDocumentRequestStart = (data) => ({
  type: `${LOST_DOC_DOCUMENT_CREATE_REQUEST}${START}`,
  payload: data
});

export const createLostDocumentRequestSuccess = (response) => ({
  type: `${LOST_DOC_DOCUMENT_CREATE_REQUEST}${SUCCESS}`,
  payload: response
});

export const createLostDocumentRequestError = (error) => ({
  type: `${LOST_DOC_DOCUMENT_CREATE_REQUEST}${ERROR}`,
  payload: error
});

export const createLostDocumentClean = () => ({
  type: `${LOST_DOC_DOCUMENT_CLEAN}`
});