import { ERROR, RUBRICATOR_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const rubricatorListRequestStart = () => ({
  type: `${RUBRICATOR_LIST_REQUEST}${START}`
});

export const rubricatorListRequestSuccess = (data) => ({
  type: `${RUBRICATOR_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const rubricatorListRequestError = (data) => ({
  type: `${RUBRICATOR_LIST_REQUEST}${ERROR}`,
  payload: data
});
