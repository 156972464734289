import {ERROR, BOOK_PREPARE_REQUEST, START, SUCCESS} from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const prepareBookRequestStart = (id) => ({
    type: `${BOOK_PREPARE_REQUEST}${START}`,
    payload: id
})

export const prepareBookRequestSuccess = (data) => {
    createNotification('success', 'Уведомление успешно сформировано')
    return {
        type: `${BOOK_PREPARE_REQUEST}${SUCCESS}`,
        payload: data
    }
}

export const prepareBookRequestError = (data) => {
    createNotification('success', 'Уведомление не сформировано')
    return {
        type: `${BOOK_PREPARE_REQUEST}${ERROR}`,
        payload: data
    }
}