import { all } from 'redux-saga/effects';
import documentsSaga from "./document/newBooksOrderSaga";
import documentBooksSaga from "./books/newBooksOrderSaga";

export default function* newBooksOrderSaga() {
    yield all([
        documentsSaga(),
        documentBooksSaga()
    ]);
}
