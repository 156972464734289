import {api} from '../../App';

export const getExternalResourcesFullList = () => {
  return api.get('/resource/full');
};

export const addExternalResource = (data) => {
  return api.post('/resource', data);
};

export const editExternalResource = (data) => {
  return api.put(`/resource/${data.id}`, data);
};

export const deleteExternalResource = (id) => {
  return api.delete(`/resource/${id}`);
};
