import {
  ERROR,
  REPORT_GET_REQUEST,
  REPORT_GET_ALL_REQUEST,
  // REPORT_GET_COUNT_REQUEST,
  START,
  SUCCESS,
} from '../action-types'

export const reportGetRequestStart = ({ id, formData }) => ({
  type: `${REPORT_GET_REQUEST}${START}`,
  payload: { id, formData },
})

export const reportEventGetRequestStart = ({ id, formData }) => ({
  type: `${REPORT_GET_REQUEST}${START}`,
  payload: { id, formData },
})

export const reportGetRequestSuccess = ({ id, data }) => ({
  type: `${REPORT_GET_REQUEST}${SUCCESS}`,
  payload: { id, data },
})

export const reportGetRequestError = data => ({
  type: `${REPORT_GET_REQUEST}${ERROR}`,
  payload: data,
})

export const reportGetAllRequestStart = ({ id, formData }) => ({
  type: `${REPORT_GET_ALL_REQUEST}${START}`,
  payload: { id, formData },
})

export const reportGetAllRequestSuccess = ({ id, data }) => ({
  type: `${REPORT_GET_ALL_REQUEST}${SUCCESS}`,
  payload: { id, data },
})

export const reportGetAllRequestError = data => ({
  type: `${REPORT_GET_ALL_REQUEST}${ERROR}`,
  payload: data,
})
