import {SUCCESS, TEACHERS_COUNT_REQUEST} from "../../../actions/action-types";

const initialState = [];

export const teachersCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${TEACHERS_COUNT_REQUEST}${SUCCESS}`:
            return action.payload;
        default:
            return state;
    }
}