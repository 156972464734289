import React, { useRef, useState } from 'react'
import { useOnClickOutside } from '../../../../utils/hooks'
import { Button } from 'antd'

import PdfPrintPanel from '../../../PDFPrint/Panel'
import { getAdaptiveData } from './getAdaptiveData'
import { getData } from './getData'
import styles from '../DocumentsModal.module.css'
import moment from 'moment'
import { DISPOSAL_DIRECTIONS } from '../../../../constants/documents'



export const WriteOfActPDFPDFPrint = ({data, options, actData, clientInfo, ranks, libName, supplierName}) => {
  
  const [choice, setChoice] = useState('')

  const booksCount = data.reduce((a, i) => a + i.amount, 0)
  
  const payload = {
    books: data,
    data: options,
    booksCount,
    actData,
    clientInfo,
    supplierName,
    libName,
    ranks
  }

  const booksByDirection = data.reduce((acc, book) => {
    const disposalId = book.disposal_direction || 0
    acc[disposalId] = acc[disposalId] ? [...acc[disposalId], book] : [book]
    return acc
  }, {})


  const popUpRef = useRef(null)
  useOnClickOutside(popUpRef, () => setChoice(''))

  const onLoadClick = () => {
    setChoice('load')
  }
  
  const onPrintClick = () => {
    setChoice('print')
  }
  
  const onChoiceButtonClick = (adaptive) => () => {
    const createDataCB = adaptive ? getAdaptiveData : getData
    const title = `Акт о списании ${moment().format('DD.MM.YYYY')}`
    choice === 'print' ? toPrint(createDataCB) : toPdf(createDataCB, title)
    setChoice('')
  }

  const toPdf = (createDataCB, title) => {
    createDataCB(payload).then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = (createDataCB) => {
    createDataCB(payload).then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }


  const writeOfActPDFPDFTable = (
    <table id='writeOfActPDFPDFTable' hidden>
      <thead>
        <tr>
          <th>
            &#8470;
            <br />
            п/п
          </th>
          <th>
            Инв. &#8470;
            <br />
            выбывших изданий
          </th>
          <th>Автор, название</th>
          <th>Год издания</th>
          <th>Цена</th>
          <th>Цена с учетом коэффициентов</th>
          <th>
            Кол-во
            <br />
            экз.
          </th>
          <th>Общая сумма (с учетом коэффициентов)</th>
          <th>Отдел</th>
        </tr>
        <tr>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6</th>
          <th>7</th>
          <th>8</th>
          <th>9</th>
        </tr>
        <tr>
          <th colSpan={9}>УЧЕБНЫЙ ФОНД</th>
        </tr>
      </thead>
      <tbody>
        {data.map((element, index) => {
          const { invnumber, authors, price, title, year, sum, udk, amount, coefficient } = element
          const titleAuthors = `${title}, ${authors}`
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{invnumber}</td>
              <td>{titleAuthors}</td>
              <td>{year}</td>
              <td>{price}</td>
              <td>{price * coefficient}</td>
              <td>{amount}</td>
              <td>{sum}</td>
              <td>{udk}</td>
            </tr>
          )
        })}
        <tr>
          <td></td>
          <td colSpan={5}>ИТОГО:</td>
          <td>{booksCount}</td>
          <td>{data.reduce((a, i) => a + i.sum * i.coefficient, 0)}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  )

  const adaptiveFirstTable = (
    <table hidden id='adaptiveFirstTable'>
      <thead>
        <tr>
          <th rowSpan={2}>Направление выбытия документов</th>
          <th colSpan={3}>Документ</th>
        </tr>
        <tr>
          <th>наименование</th>
          <th>номер</th>
          <th>дата</th>
        </tr>
      </thead>
      <tbody>
        {DISPOSAL_DIRECTIONS.map(({id, name}) => {
          const result = booksByDirection[id || 0]?.reduce((acc, book) => {
            acc.names.push(book.title)
            acc.numbers.push(book.invnumber)
            return acc
          }, {names: [], numbers: [], dates: []})
          const bookNames = result?.names?.map(book => <>{book}<br/></>)
          const bookNumbers = result?.numbers?.map(number => <>{number}<br/></>)
          return (
            <tr>
              <td>{name}</td>
              <td>{bookNames}</td>
              <td>{bookNumbers}</td>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )

  const adaptiveSecondTable = (
    <table hidden id='adaptiveSecondTable'>
      <thead>
        <tr>
          <th>№ п/п</th>
          <th>Инвентарный номер</th>
          <th>Наименование документа и его основные характеристики</th>
          <th>Наименование единицы измерения</th>
          <th>Количество</th>
          <th>Цена, руб</th>
          <th>Коэффициент переоценки</th>
          <th>Сумма, руб</th>
        </tr>
      </thead>
      <tbody>
        {data.map((element, index) => {
          const { invnumber, authors, title, amount, price, price_coeff, sum } = element
          const titleAuthors = `${title}, ${authors}`
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{invnumber}</td>
              <td>{titleAuthors}</td>
              <td>шт.</td>
              <td>{amount}</td>
              <td>{price}</td>
              <td>{price_coeff}</td>
              <td>{sum}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )

  return (
    <div style={{position: 'relative'}} className='ml-auto'>
      {adaptiveFirstTable}
      {adaptiveSecondTable}
      {writeOfActPDFPDFTable}
      <PdfPrintPanel printPdf={onPrintClick} loadPdf={onLoadClick} />
      {!!choice &&
        <div className={styles.choiceBlock} ref={popUpRef}>
          <Button onClick={onChoiceButtonClick()}>Форма ОКУД 0504144</Button>
          <Button onClick={onChoiceButtonClick('adaptive')}>Форма ОКУД 0504144 адаптированная</Button>
        </div>
      }
    </div>
  )
}