import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";


const SchedulerList = ({data, setModalData, setEnabled}) => {
    return (
        <DataTable data={data} onCellClick={item => setModalData(item)}>
            <DataTableCol title={'Название'} value={'schedulename'} colSpan={2}/>
            <DataTableCol title={'Комментарий'} value={'schedulecomment'} colSpan={2}/>
            <DataTableCol title={'Статус'} value={'statusname'}/>
            <DataTableCol title={'Включен'} passClicks resolver={item =>
                <input type="checkbox" checked={item.enabled}
                       onChange={() => setEnabled(item.id, !item.enabled)}/>
            }/>
        </DataTable>
    );
};

SchedulerList.propTypes = {
    data: PropTypes.array,
    setModalData: PropTypes.func,
};

export default SchedulerList;
