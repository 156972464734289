import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {schedulerCountRequestStart} from "../../redux/actions/scheduler/count";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SchedulerList from "../../components/Scheduler/List";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import {setSchedulerSearchData} from "../../redux/actions/scheduler/search";
import Pagination from "../../components/Pagination";
import SchedulerModal from "../../components/Scheduler/Modal";
import {schedulerEnableRequestStart} from "../../redux/actions/scheduler/enable";



const mapStateToProps = ({scheduler}) => ({
    list: scheduler.editMetadata.list,
    count: scheduler.editMetadata.count,
    loader: scheduler.editMetadata.loader,
    searchData: scheduler.editMetadata.searchData
});

const mapDispatchToProps = {
    requestCategoriesList: schedulerCountRequestStart,
    setSearchData: setSchedulerSearchData,
    setEnabled: schedulerEnableRequestStart
};

const SchedulerScene = ({requestCategoriesList, list, count, loader, searchData, setSearchData, setEnabled}) => {

    useEffect(() => {
        requestCategoriesList();
    }, [requestCategoriesList]);


    const [modalData, setModalData] = useState(null);

    const PaginationComponent = () => (
        <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={(page) => {
                setSearchData({...searchData, page});
                requestCategoriesList();
            }}
        />
    );

    const closeModal = () => {
        setModalData(null)
    };



    return (
        <SceneContainer
            title={'Редактирование расписания миграции метаданных'}
        >
            {modalData &&
                <SchedulerModal
                    closeModal={closeModal}
                    modalData={modalData}
                    id={modalData.id}
                />
            }

            <PaginationComponent/>

            {loader ?
                <SpinnerOuter/>
                :
                <SchedulerList data={list} setModalData={setModalData} setEnabled={setEnabled}/>
            }

            <PaginationComponent/>
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchedulerScene);
