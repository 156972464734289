import {BOOK_OPEN_HISTORY_LIST, ERROR, START, SUCCESS} from "../../../actions/action-types";


const initialState = [];

export const bookOpenHistoryListReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${BOOK_OPEN_HISTORY_LIST}${START}`:
            return state;

        case `${BOOK_OPEN_HISTORY_LIST}${SUCCESS}`:
            return action.payload;

        case `${BOOK_OPEN_HISTORY_LIST}${ERROR}`:
            return action.payload;

        default: return state;
    }
};
