import { ERROR, LIBRARY_DELETE_FUND_REQUEST, START, SUCCESS } from "../../../action-types";

export const libraryDeleteFundRequestStart = (fundId, libraryId) => ({
  type: `${LIBRARY_DELETE_FUND_REQUEST}${START}`,
  payload: { fundId, libraryId }
});

export const libraryDeleteFundRequestSuccess = (data) => ({
  type: `${LIBRARY_DELETE_FUND_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryDeleteFundRequestError = (data) => ({
  type: `${LIBRARY_DELETE_FUND_REQUEST}${ERROR}`,
  payload: data
});