import React from 'react'
import T from 'prop-types'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import moment from 'moment'
import { Button } from 'antd'
import classNames from 'classnames'
import styles from '../../Inventory/Modal/DocumentsModal.module.css'

const STATUS_LIST = [
  {
    title: 'Новый',
    value: 1,
  },
  {
    title: 'Готово',
    value: 2,
  },
]

const BookScanOrdersList = ({ list, deleteItem, typeOrder, setSort, sortBy }) => {
  

  const findStatus = item => {
    const statusName = STATUS_LIST.find(status => status.value === item.status)
    return statusName ? statusName.title : '-'
  }

  const NoSearchResults = () => <div className={classNames('text-center', styles.textNotification)}>Ничего не найдено</div>

  return (
    <div>
      {!!list.length ? (
        <DataTable data={list} sortBy={sortBy} onHeadClick={setSort}>
          <DataTableCol title='Название' colSpan={2} value='title' sortParams={['title']} />
          <DataTableCol
            title='Время создания'
            colSpan={1}
            sortParams={['timestamp']}
            resolver={item => moment(item.timestamp).format('DD.MM.YYYY')}
          />
          <DataTableCol title='Статус' colSpan={1} sortParams={['status']} resolver={findStatus} />
          <DataTableCol
            title='Тип заказа'
            resolver={({ ordertype }) => {
              const order = typeOrder && typeOrder.find(item => item.id === ordertype)
              return order ? order.ordertype : ''
            }}
            colSpan={1}
          />
          <DataTableCol
            title='Срок бронирования'
            resolver={({ reservationperiod }) => moment(reservationperiod).format('DD.MM.YYYY')}
          />
          <DataTableCol
            title='Действие'
            colSpan={1}
            resolver={item => (
              <Button type='primary' onClick={() => deleteItem(item)} danger={/* Шифр */ item.ordertype === 1 ? false : true}>
                {/* Шифр */ item.ordertype === 1 ? 'Снять бронь' : 'Удалить'}
              </Button>
            )}
          />
        </DataTable>
      ) : (
        <NoSearchResults />
      )}
    </div>
  )
}

BookScanOrdersList.propTypes = {
  list: T.array.isRequired,
  deleteItem: T.func,
}

export default BookScanOrdersList
