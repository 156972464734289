import React, {useState} from 'react';
import styles from './BookData.module.css';
import Button from "../../Button";
import Input from "../Input";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import { createNotification } from '../../notification/notification';

const BookData = ({data, deleteNoteHandler, bookDeleteLoading, inputHandler, href, urlLoading, setUrlLoading, setShowData}) => {

    const [isCorrect, setIsCorrect] = useState(false);

    const handleSave = () => {
        // setShowData(false); 
        setIsCorrect(true);
        // createNotification('success', 'Сохранено');
    }

    const LineElement = ({label, content, anchor}) => (
        <div className={styles.line}>
            {anchor ?
                <Button
                    onClick={() => anchorClickHandler(content)}
                    label={label}
                />
                :
                <>
                    <p>{label}:</p>
                    <p>{content}</p>
                </>
            }
        </div>
    );

    const anchorClickHandler = (url) => {
        const fileWindow = window.open(url);
        fileWindow.focus();
    };

    return (
        <div className={styles.container}>
            {data ?
                <>
                    <LineElement label={'Название'} content={data.title}/>
                    <LineElement label={'Издательство'} content={data.publisher}/>
                    <LineElement label={'Аннотация'} content={data.annotation}/>
                    <LineElement label={'Год'} content={data.year}/>
                    {href || urlLoading ?
                        urlLoading ?
                            <SpinnerOuter isLoading/>
                            :
                            <LineElement label={'Открыть'} content={href} anchor/>
                        : null}
                    {isCorrect ?
                        <div>
                            <Input inputHandler={inputHandler} label={'Загрузить архив'}/>
                        </div>
                        :
                        <div className={styles.buttonsContainer}>
                            <Button
                                colorType={'red'}
                                label={'Отменить'}
                                onClick={() => deleteNoteHandler(data.id_id || data.id)}
                                spinner={bookDeleteLoading}
                            />
                            <Button
                                label={'Сохранить'}
                                onClick={() => handleSave()}
                            />
                        </div>
                    }
                </>
                : null}
        </div>
    );
};

export default BookData;
