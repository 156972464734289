import React, { useState } from "react";
import NotFoundCover from "../../../../img/catalog-cover.jpg";
import OpenPhotoModal from "../../../OpenPhotoModal";
import styles from "./BookEditIndexTab.module.css";

const ScanImage = ({ url, style, notFoundImage}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className={styles.container}>
      <OpenPhotoModal
        src={url || notFoundImage}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />

      <img
        alt="Обложка"
        className={style}
        src={url || notFoundImage}
        onClick={() => setModalVisible(true)}
      />

    </div>
  );
};

export default ScanImage;
