import {combineReducers} from "redux";
import {countReducer} from "./count/countReducer";
import {listReducer} from "./list/listReducer";
import {loaderReducer} from "./loader/loaderReducer";
import {searchReducer} from "./search/searchReducer";

export const schedulerEditMetadataReducer = combineReducers({
    count: countReducer,
    list: listReducer,
    loader: loaderReducer,
    searchData: searchReducer
});