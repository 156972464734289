import { ERROR, INSTITUTIONS_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const institutionsCountRequestStart = () => ({
  type: `${INSTITUTIONS_COUNT_REQUEST}${START}`
});

export const institutionsCountRequestSuccess = (data) => ({
  type: `${INSTITUTIONS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const institutionsCountRequestError = (data) => ({
  type: `${INSTITUTIONS_COUNT_REQUEST}${ERROR}`,
  payload: data
});

