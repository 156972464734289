import {api} from "../../App";
import {SORT_DIRECTION} from "../../constants/sortDirections";

export const getSearchAuditList = ({sortBy, maxResults, paginateFrom, sortDirection}, search) => {
    const params = {
        maxResults,
        paginateFrom,
        ...search,
        [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
    };

    return api.get('/admin/logs/audit', {params});
};


export const getSearchAuditCount = (search) => {
    const params = {
        count: true,
        ...search
    };

    return api.get('/admin/logs/audit', {params});
};
