import { FUND_MOVING_LOAD_REQUEST, SUCCESS, ERROR, START } from "../../../actions/action-types";

const initialState = [];

export const fundMovingListReducer = (state = initialState, action) => {

  switch (action.type) {
    case `${FUND_MOVING_LOAD_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }

};
