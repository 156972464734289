import { INVENTORY_BOOK_CLEANUP, INVENTORY_BOOK_LIST_REQUEST, SUCCESS } from '../../../actions/action-types'

const initialState = []

export const documentBooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${INVENTORY_BOOK_LIST_REQUEST}${SUCCESS}`:
      return action.payload

    case `${INVENTORY_BOOK_CLEANUP}`:
      return []

    default:
      return state
  }
}
