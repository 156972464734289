import React from 'react'
import styles from './SpinnerOuter.module.css'
import { Spin } from 'antd'

const SpinnerOuter = ({ isLoading }) => {
  if (isLoading === undefined) isLoading = true
  return (
    <>
      {isLoading && (
        <div className={styles.spinnerContainer}>
          <Spin />
        </div>
      )}
    </>
  )
}

export default SpinnerOuter
