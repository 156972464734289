import React, {useState, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import {categoriesListRequestStart} from '../../../redux/actions/categories/list';
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import CategoriesList from "../../../components/Categories/List";
import styles from './CategoriesScene.module.css';

const mapStateToProps = ({categories}) => ({
    list: categories.list,
    loader: categories.loader
});

const mapDispatchToProps = {
    requestList: categoriesListRequestStart
};

const CategoriesScene = ({list, loader, requestList}) => {

    useEffect(() => {
        requestList();
    }, [requestList]);

    const scrollToElement = (ref) => {
        window.scrollTo(0, ref.current.offsetTop)
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    };

    const categorizeList = (list) => {

        if (!!list.length) {
            // Шифр
            let categories = list.filter(item => item.parentId === "0");
            let childCategories = list.filter(item => item.parentId !== "0");
            let subcategoriesIds = childCategories.map(item => item.id);
            let categoriesByChars = [];



            childCategories.forEach(item => {
                if (subcategoriesIds.includes(item.parentId)) {
                    childCategories.find(el => el.id === item.parentId).subcategories.push(item);
                }
            });

            childCategories.forEach(item => {
                if (!subcategoriesIds.includes(item.parentId)) {

                    let filtered = categories.find(el => el.id === item.parentId);

                    if(filtered) {
                        filtered.subcategories.push(item)
                    }
                }
            });

            categories.forEach(item => {
                const ref = React.createRef();
                let itemNameFirstChar = item.name.charAt(0).toLowerCase();
                let categoriesByCharLastItem = categoriesByChars[categoriesByChars.length - 1];
                let categoriesCurrentChar = categoriesByCharLastItem ? categoriesByCharLastItem.char : null;

                if (itemNameFirstChar === categoriesCurrentChar) {
                    categoriesByCharLastItem.categories.push(item);
                } else {
                    categoriesByChars.push({
                        ref: ref,
                        char: itemNameFirstChar,
                        categories: [item]
                    });
                }
            });

            return categoriesByChars;
        }

        return [];
    };

    const categorizedList = useMemo(() => categorizeList(list), [list]);


    return (
        <SceneContainer title={'Справочники / Категории'}>

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <>
                    <button className={styles.toTop} title={'Наверх'} onClick={scrollToTop}>↑</button>

                    <div className={styles.charsPanel}>
                        {categorizedList.map((item, index) =>
                            <button
                                key={`categoryCharBtn_${index}`}
                                onClick={() => scrollToElement(item.ref)}
                                className={styles.charButton}
                            >
                                {item.char.toUpperCase()}
                            </button>
                        )}
                    </div>

                    {categorizedList.map((item, index) =>
                        <div className={styles.listItem} key={`categoryCharEl_${index}`} ref={item.ref}>
                            <div className={styles.charItem}>{item.char.toUpperCase()}</div>
                            <CategoriesList list={item.categories}/>
                        </div>
                    )}
                </>
            }
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoriesScene);