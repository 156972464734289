import { api } from '../../App';

export const getMediaResourcesFull = () => {
  return api.get(`/mediaresource/full`);
};

export const getMediaResources = () => {
  return api.get(`/mediaresource`);
};

export const addMediaResource = ({ resourcename, resourceurl }) => {
  const params = {
    resourcename,
    resourceurl
  };

  return api.post(`/mediaresource`, params);
};

export const addMediaResourceLogo = (resourceId, logo) => {
  const formData = new FormData();
  const headers = [
    { 'Content-Type': 'multipart/form-data' }
  ];

  formData.append("logo", logo);

  return api.post(`/mediaresource/${resourceId}/logo`, formData, { headers });
};

export const editMediaResource = (resourceId, { resourcename, resourceurl }) => {
  const params = {
    resourcename,
    resourceurl
  };

  return api.put(`/mediaresource/${resourceId}`, params)
};

export const deleteMediaResource = (resourceId) => {
  return api.delete(`/mediaresource/${resourceId}`)
};

export const deleteMediaResourceLogo = (resourceId) => {
  return api.delete(`/mediaresource/${resourceId}/logo`);
};