import React, { useCallback } from 'react'
import AggregationReportScene from '../Reports/Aggregation'
import BookScanReportScene from '../Reports/BookScan'
import { Button } from 'antd'
import CoversScanReportScene from '../Reports/CoversScan'
import LibrariesDiaryReportScene from '../Reports/LibrariesDiary'
import BookGivingStatsReportScene from '../Reports/BookGivingStats'
import AccountingJournalReportScene from '../Reports/AccountingJournal'
import ElectronicResourcesUsageReport from '../Reports/ElectronicResourcesUsage'
import StaticInformationReportScene from '../Reports/StaticInformation'
import Event from '../Reports/Event'
import NotebookLostDoc from '../Reports/NotebookLostDoc'

const ReportContentScene = ({ match, history }) => {
  const id = match.params.id

  // useEffect(() => {
  //   id && requestReport(id);
  // }, [id, requestReport]);

  const goBack = () => {
    history.push('/reports')
  }
  // Шифр
  const ReportSceneSwitch = useCallback(() => {
    switch (id) {
      case '1':
        return <AggregationReportScene ownId={id} />
        
      case '11':
        return <LibrariesDiaryReportScene ownId={id} />

      case '12':
        return <AccountingJournalReportScene ownId={id} />

      case '13':
        return <ElectronicResourcesUsageReport ownId={id} />

      case '14':
        return <BookGivingStatsReportScene ownId={id} />

      case '15':
        return <BookScanReportScene ownId={id} />

      case '16':
        return <CoversScanReportScene ownId={id} />

      case '19':
        return <StaticInformationReportScene ownId={id} />

      case '21':
        return <NotebookLostDoc ownId={id}/>

      case '20':
        return <Event ownId={id} />

      default:
        return <div>Такого отчета еще не существует</div>
    }
  }, [id])

  return (
    <div>
      <div className={'mt-3'}>
        <Button type='primary' onClick={goBack}>
          ← Назад
        </Button>
      </div>

      <ReportSceneSwitch />
    </div>
  )
}

export default ReportContentScene
