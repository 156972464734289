import React from 'react';
import T from 'prop-types';
import classNames from 'classnames';
import styles from './ImagePlaceholder.module.css';

const ImagePlaceholder = ({className}) => {
  return (
    <div className={classNames(styles.placeholder, className)}/>
  );
};

ImagePlaceholder.propTypes = {
  className: T.string
};

export default ImagePlaceholder;
