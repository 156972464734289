import React from "react";
import T from "prop-types";
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import {connect} from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import {Tab} from "react-bootstrap";
import {useFormik} from "formik";
import * as Yup from 'yup';
import CatalogsModalWithForm from "../../Catalogs/ModalWithForm";
import {api} from "../../../App";
import { createNotification } from "../../notification/notification";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
};

const validationSchema = Yup.object().shape({

});

const AntiplagiatModal = ({
                         modalData,
                         closeModal,
                         setCurrentPage,
                         page,
                         onRequest,
                         bookId,
                         docId,
                         type,
                         bookData
                     }) => {

    const modData = modalData?.attributes?.reduce((object, attr) => ({...object, [attr.name]:attr.value}), {})

    const modalForm = useFormik({
        initialValues:
            type != 'edit' ? 
            {
            ...modData,
            Title: bookData?.title,
            Author: bookData?.authors?.map(el => el.name).join(','),
            Publication: bookData?.publishers?.map(el => el?.name),
            Year: bookData?.year?.toString(),
            Description: `${`${bookData?.authors?.[0]?.name ||  'Автор не задан'}, ${bookData?.title || bookData?.[0]?.title || ''} / ${bookData?.anotherAuthors || ''} ${bookData?.year || bookData?.year?.[0] || ''} ${(bookData?.pageNum || '-') && ("-" +  bookData?.pageNum || '-' + " c.") && ''} ${bookData?.pubinstanceamount ? bookData?.pubinstanceamount + " экз." : ""} ${bookData?.annotation ? bookData?.annotation : '' }
             ISBN ${bookData?.isbn || '-'}
             изд-во: ${bookData?.allPublishers || bookData?.publishers?.[0]?.name ||  ''}
             ББК: ${bookData?.bbk || '-'} 
             УДК: ${bookData?.udk || '-'}`
            }`,
            Page_count: bookData?.pageNum?.toString()
            } : {...modData},
        validationSchema,
        validateOnBlur: false,
        onSubmit: values => onSubmit(values)
    });

    const moveFileToAntiplagiatCollection = values => {
        api
          .post(`/antiplagiat_collection`, {
            bookfileid: docId,
            attributes: 
                values
          })
          .then(res => {
            createNotification('success', 'Добавлено в список антиплагиата');
            onRequest(Number(bookId));    
            closeModal();
          })
          .catch(err => {
            createNotification('error', 'Ошибка добавления')
          })
      }

      const handleEditAntiplagiat = values => {
        api
          .put(`/antiplagiat_attributes/${modalData.documentId}`, values)
          .then(() => {
            createNotification('success', 'Успешно');
            setCurrentPage(page);
            closeModal();
          })
          .catch(() => {
            createNotification('error', 'Ошибка')
          })
      }

    const onSubmit = async (values) => {
        if (type == 'edit') {
            handleEditAntiplagiat(values);
        } else {
            moveFileToAntiplagiatCollection(values);
        }
      }

    return (
        <CatalogsModalWithForm
            title={`Антиплагиат: Редактирование`}
            closeModal={() => {
                closeModal();
            }}
            submitHandler={modalForm.handleSubmit}
        >
            <Tabs defaultActiveKey="edit">
                <Tab eventKey="edit" title="Редактирование">
                    {modalForm && (
                        <Row>
                            <Col md={9}>
                                <Row>
                                    <Col md={12}>
                                        <ModalInputWrapper
                                            label="Заглавие"
                                            name="Title"
                                            type="input"
                                            value={modalForm.values.Title}
                                            // error={modalForm.errors.description}
                                            onChange={modalForm.handleChange}
                                        />
                                        <ModalInputWrapper
                                            label="Автор"
                                            name="Author"
                                            type="input"
                                            value={modalForm.values.Author}
                                            // error={modalForm.errors.description}
                                            onChange={modalForm.handleChange}
                                        />
                                        <ModalInputWrapper
                                            label="Год"
                                            name="Year"
                                            type="input"
                                            value={modalForm.values.Year}
                                            // error={modalForm.errors.description}
                                            onChange={modalForm.handleChange}
                                        />
                                        <ModalInputWrapper
                                            label="Кол-во страниц"
                                            name="Page_count"
                                            type="input"
                                            value={modalForm.values.Page_count}
                                            // error={modalForm.errors.description}
                                            onChange={modalForm.handleChange}
                                        />
                                        <ModalInputWrapper
                                            label="Издательство"
                                            name="Publication"
                                            type="input"
                                            value={modalForm.values.Publication}
                                            // error={modalForm.errors.description}
                                            onChange={modalForm.handleChange}
                                        />
                                        <ModalInputWrapper
                                            label="Библиографическое описание"
                                            name="Description"
                                            type="textarea"
                                            value={modalForm.values.Description}
                                            // error={modalForm.errors.description}
                                            onChange={modalForm.handleChange}
                                        />
                                        <ModalInputWrapper
                                            label="Примечание для файла коллекции"
                                            name="Note"
                                            type="textarea"
                                            value={modalForm.values.Note}
                                            // error={modalForm.errors.description}
                                            onChange={modalForm.handleChange}
                                        />
                                    </Col>
                                    
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Tab>
            </Tabs>
        </CatalogsModalWithForm>
    );
};

AntiplagiatModal.propTypes = {
    modalData: T.object,
    setModalData: T.func,
    onSubmit: T.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AntiplagiatModal);
