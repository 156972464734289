import {ERROR, ELECTRONIC_COURSES_ITEM_DELETE, START, SUCCESS} from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const electronicCoursesItemDeleteStart = (id) => ({
    type: `${ELECTRONIC_COURSES_ITEM_DELETE}${START}`,
    payload: id
});

export const electronicCoursesItemDeleteSuccess = (data) => {
    createNotification('success', 'Курс успешно удален');
    return {
        type: `${ELECTRONIC_COURSES_ITEM_DELETE}${SUCCESS}`,
        payload: data
    };
};

export const electronicCoursesItemDeleteError = (data) => {
    createNotification('error', 'Ошибка удаления курса');
    return {
        type: `${ELECTRONIC_COURSES_ITEM_DELETE}${ERROR}`,
        payload: data
    }
};

