import { api } from '../../App';

export const getRoomsList = (libraryId) => {
  return api.get(`/location/room/${libraryId}`)
};

export const addRoom = (libraryId, data) => {
  return api.post(`/location/room/${libraryId}`, data);
};

export const editRoom = (libraryId, data) => {
  return api.put(`/location/room/${libraryId}/${data.id}`, data);
};

export const deleteRoom = (libraryId, roomId) => {
  return api.delete(`/location/room/${libraryId}/${roomId}`)
};