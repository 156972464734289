import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import {connect} from "react-redux";
import Pagination from "../../Pagination";
import {setSchedulerCategoriesSearchData} from "../../../redux/actions/scheduler/categories/search";
import {schedulerCategoriesCountRequestStart} from "../../../redux/actions/scheduler/categories/count";
import ModalWindow from "../../ModalWindow";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import SchedulerModalTable from "./Table";
import SchedulerEditModal from "./EditModal";
import {schedulerEditRequestStart} from "../../../redux/actions/scheduler/categories/edit";
import {schedulerDeleteRequestStart} from "../../../redux/actions/scheduler/categories/delete";
import {schedulerAddRequestStart} from "../../../redux/actions/scheduler/categories/add";
import {createNotification} from "../../notification/notification";


const mapStateToProps = ({scheduler}) => ({
    searchData: scheduler.categories.search,
    count: scheduler.categories.count,
    list: scheduler.categories.list,
    loader: scheduler.categories.loader,
});

const mapDispatchToProps = {
    setSearchData: setSchedulerCategoriesSearchData,
    requestList: schedulerCategoriesCountRequestStart,
    saveItem: schedulerEditRequestStart,
    deleteItem: schedulerDeleteRequestStart,
    addItem: schedulerAddRequestStart
};

export const WEEK_DAYS = [
    {
        name: 'Все',
        number: 0
    },
    {
        name: 'Понедельник',
        number: 1
    },
    {
        name: 'Вторник',
        number: 2
    },
    {
        name: 'Среда',
        number: 3
    },
    {
        name: 'Четверг',
        number: 4
    },
    {
        name: 'Пятница',
        number: 5
    },
    {
        name: 'Суббота',
        number: 6
    },
    {
        name: 'Воскресенье',
        number: 7
    }
];

const SchedulerModal = ({
                            closeModal,
                            modalData,
                            deleteItem,
                            addItem,
                            searchData,
                            setSearchData,
                            requestList,
                            count,
                            list,
                            loader,
                            saveItem,
                            id
                        }) => {

    const {schedulename, id: categoryId} = modalData;

    const [editModal, setEditModal] = useState(null);
    const [selectedId, setSelectedId] = useState(null);


    useEffect(() => {
        requestList(categoryId);
    }, [requestList]);

    const closeEditModal = () => {
        setEditModal(null)
    };

    const PaginationComponent = () => (
        <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={(page) => {
                setSearchData({...searchData, page});
                requestList(id)
            }}
        />
    );

    const handleSelect = (id) => {
        setSelectedId(selectedId === id ? null : id)
    };

    const onDeleteItem = (itemId) => {
        if (!itemId) {
            return createNotification('warning', 'Не выбран документ');
        }

        deleteItem({itemId, categoryId});
        setSelectedId(null);
    };

    const onAddItem = () => {
        addItem({
            id: categoryId
        })
    };

    const onSaveItem = itemData => {
        saveItem(itemData, closeEditModal)
    };

    return (
        <ModalWindow
            title={`Редактирование: ${schedulename}`}
            isOpen={true}
            onRequestClose={closeModal}
        >
            {editModal &&
            <SchedulerEditModal onRequestClose={closeEditModal} modalData={editModal} saveItem={onSaveItem}
                                id={categoryId}/>
            }
            <PaginationComponent/>
            {loader ?
                <SpinnerOuter/>
                :
                <SchedulerModalTable data={list} setModal={setEditModal} handleSelect={handleSelect}
                                     selectedId={selectedId} deleteItem={onDeleteItem} addItem={onAddItem}/>
            }
            <PaginationComponent/>
        </ModalWindow>
    );
};

SchedulerModal.propTypes = {
    closeModal: T.func,
    setModalData: T.func,
    modalData: T.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchedulerModal);