import { ERROR, START, SUCCESS } from '../action-types'

export const createSimpleActionsForReducer = (prefix, actionType) => {
  return {
    [`${prefix}RequestStart`]: payload => ({
      type: `${actionType}${START}`,
      payload,
    }),
    [`${prefix}RequestSuccess`]: payload => ({
      type: `${actionType}${SUCCESS}`,
      payload,
    }),
    [`${prefix}RequestError`]: payload => ({
      type: `${actionType}${ERROR}`,
      payload,
    }),
  }
}

export const createActionWithPayload = type => payload => ({
  type,
  payload,
})
