import { ERROR, SHELF_SAVE_REQUEST, START, SUCCESS } from "../../action-types";

export const shelfSaveRequestStart = (rackId, data) => ({
  type: `${SHELF_SAVE_REQUEST}${START}`,
  payload: { rackId, data }
});

export const shelfSaveRequestSuccess = (data) => ({
  type: `${SHELF_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const shelfSaveRequestError = (data) => ({
  type: `${SHELF_SAVE_REQUEST}${ERROR}`,
  payload: data
});

