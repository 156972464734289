import {
  ERROR,
  OUTSECRECY_COUNT_REQUEST,
  START,
  SUCCESS
} from "../action-types";

export const outsecrecyCountRequestStart = () => ({
  type: `${OUTSECRECY_COUNT_REQUEST}${START}`
});

export const outsecrecyCountRequestSuccess = count => ({
  type: `${OUTSECRECY_COUNT_REQUEST}${SUCCESS}`,
  payload: count
});

export const outsecrecyCountRequestError = error => ({
  type: `${OUTSECRECY_COUNT_REQUEST}${ERROR}`,
  payload: error
});
