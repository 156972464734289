import { all } from 'redux-saga/effects';
import institutionsLoadSaga from "./load/institutionsLoadSaga";
import institutionsSaveSaga from "./save/institutionsSaveSaga";
import institutionsDeleteSaga from "./delete/institutionsDeleteSaga";

export default function* institutionsSaga() {
  yield all([
    institutionsLoadSaga(),
    institutionsSaveSaga(),
    institutionsDeleteSaga()
  ]);
}