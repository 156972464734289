import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

const ModalWindow = ({ children, isOpen, title, onRequestClose, width, ...props }) => {
  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={onRequestClose}
      width={width || "1000px"}
      footer={null}
      maskClosable={false}
      {...props}
    >
      {children}
    </Modal>
  );
};

ModalWindow.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onRequestClose: PropTypes.func,
};

export default ModalWindow;
