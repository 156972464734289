import React from "react";
import T from "prop-types";
import styles from "./AccessLogForm.module.css";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import * as Yup from "yup";
import { useFormik } from "formik";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const SecurityLogForm = ({ data, setData, search }) => {
  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values);
      search();
    },
  });

  const { values, errors } = modalForm;

  return (
    <div className={"formContainer"}>
      <Row>
        <Col md={4}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="from"
            value={values["from"]}
            error={errors["from"]}
            label="С (дата)"
            type="date"
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="to"
            value={values["to"]}
            error={errors["to"]}
            label="До (дата)"
            type="date"
          />
        </Col>
        <Col md={2} className={styles.search}>
          <Button label={"Найти"} onClick={modalForm.handleSubmit} />
        </Col>
      </Row>
    </div>
  );
};

SecurityLogForm.propTypes = {
  data: T.object,
  setData: T.func,
  search: T.func,
};

export default SecurityLogForm;
