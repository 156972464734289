import React from "react";
import styles from "./scheduleTable.module.css";
import { Table, Container, Row, Col } from "react-bootstrap";

const ScheduleTable = ({ res }) => {
  const keys = Object.keys(res.data);
  const objToArr = () => {
    const keys = Object.keys(res.data);
    const list = [];
    keys.forEach(item => {
      list.push(res.data[item]);
    });

    const dataList = [];
    list.forEach(item => {
      const day = [];
      const elementKeys = Object.keys(item);
      elementKeys.forEach(sItem => {
        day.push(item[sItem]);
      });
      dataList.push(day);
    });
    return dataList;
  };

  const dataList = objToArr();

  if (dataList == 0) return(<div></div>);

  return (
    <Table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.title}>Дата</th>
          <th className={styles.title}>Часы занятий</th>
          <th className={styles.title}>
            <Container>
              <Row>
                  <Col>{dataList[0][0][0][0].educationyear}</Col>
              </Row>
              <Row className={styles.groupsList}>
                {dataList[0][0].map(item => <Col>{item[0].groupname} группа</Col>)}
              </Row>
            </Container>
          </th>
        </tr>
      </thead>
      <tbody>
        {dataList.map((item,i )=> {
          return (
            <tr>
              <td>
                  <Container>
                    <Row><Col>{item[0][0][0].d}</Col></Row>
                    <Row><Col>{item[0][0][0].lessondate}</Col></Row>
                  </Container>
              </td>
              <td><Container>{item.map(sItem => <Row><Col>{sItem[0][0].p}</Col></Row>)}</Container></td>
              <td><Container>{item.map(sItem => <Row className={styles.lessonsList}>{sItem.map( tItem => <Col> {tItem[0].cell} </Col> )}</Row>)}</Container></td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ScheduleTable;
