import { CURRICULUM_LIST_REQUEST, SUCCESS } from "../../actions/action-types";

const initialState = [];

export const curriculumListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CURRICULUM_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
