import React, { useEffect } from "react";
import T from "prop-types";
import { EDIT } from "../../../constants/modalTypes";
import { Col, Row } from "react-bootstrap";
import Modal from "../../Modal";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { rubricatorParentSearchRequestStart } from "../../../redux/actions/rubricator/parentSearch";
import { connect } from "react-redux";
import { parentSearchSelector } from "../../../redux/selectors/rubricator/parentSearchSelector";
import { parentItemSelector } from "../../../redux/selectors/rubricator/parentItemSelector";
import { rubricatorItemRequestStart } from "../../../redux/actions/rubricator/item";
import SearchableSelect from "../../Select";
import { rubricatorItemRequestSuccess } from "../../../redux/actions/rubricator/item.js";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Button from "../../Button";


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Это обязательное поле')
    .max(300, 'Недопустимая длинна названия (max 300)'),
});

const mapStateToProps = ({ rubricator }) => ({
  parentSearchOptions: parentSearchSelector(rubricator.parentSearch),
  parentItem: parentItemSelector(rubricator.parentItem)
});

const mapDispatchToProps = {
  getOptions: rubricatorParentSearchRequestStart,
  getParent: rubricatorItemRequestStart,
  clearParent: rubricatorItemRequestSuccess
};

const RubricatorModal = ({
  onSubmit,
  modalData,
  parentSearchOptions,
  getOptions,
  parentItem,
  getParent,
  clearParent,
  closeModal
}) => {
  const { modalType, data } = modalData;
  const { parentId } = data;

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  useEffect(() => {
    parentId &&
      (!parentItem || parentItem.value !== parentId) &&
      getParent(parentId);
  }, [parentId, parentItem, getParent]);

  const modalForm = useFormik({
    initialValues: data,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => onSubmit(values)
  });

  const onCancel = () => {
    closeModal();
    clearParent(null);
  };

  return (
    <Modal
      title={`Категория: ${
        modalType === EDIT ? "редактирование" : "добавление"
      }`}
      isVisible={true}
    >
      {data && (
        <Row>
          <Col md={12}>
            <ModalInputWrapper
              label="Название"
              value={modalForm.values.name}
              name="name"
              error={modalForm.errors.name}
              onChange={modalForm.handleChange}
            />
            <ModalInputWrapper label="Родительская категория" >
            <SearchableSelect
              placeholder="Поиск"
              name="parentId"
              onInputChange={getOptions}
              options={parentSearchOptions}
              onChange={(option) => modalForm.setFieldValue("parentId", parseInt(option.value, 10))}
              value={parentSearchOptions.find(({ value }) => value === modalForm.values["parentId"])}
            />
            </ModalInputWrapper>
          </Col>
          <Col className={'mt-2 d-flex justify-content-end'}>
              <Button label="Сохранить" onClick={modalForm.handleSubmit} colorType="green" style={'mr-1'}/>
              <Button label="Отмена" onClick={onCancel} colorType="red"/>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

RubricatorModal.propTypes = {
  setModalData: T.func,
  onSubmit: T.func,
  modalData: T.object,
  closeModal: T.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RubricatorModal);
