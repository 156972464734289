import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import Button from "../../../Button";
import * as Yup from "yup";
import {useFormik} from "formik";
import moment from "moment";


const validationSchema = Yup.object().shape({
    dateFrom: Yup.date().required("Выберите дату начала"),
    dateTo: Yup.date()
      .required("Выберите дату завершения")
      .min(Yup.ref("dateFrom"), "Дата завершения должна быть позже даты начала"),
  });

const AccountingJournalReportForm = ({onSubmit}) => {

    const searchForm = useFormik({
        initialValues: {
            dateFrom: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            dateTo: moment().format('YYYY-MM-DD')
        },
        validationSchema,
        onSubmit: values => onSubmit(values)
    });


    return (
        <div className={"formContainer"}>
            <Row>
                <Col md={4} lg={3}>
                    <ModalInputWrapper
                        label="C*"
                        value={searchForm.values.dateFrom}
                        name="dateFrom"
                        error={searchForm.errors.dateFrom}
                        onChange={searchForm.handleChange}
                        type="date"
                    />
                </Col>
                <Col md={4} lg={3}>
                    <ModalInputWrapper
                        label="По*"
                        value={searchForm.values.dateTo}
                        name="dateTo"
                        error={searchForm.errors.dateTo}
                        onChange={searchForm.handleChange}
                        type="date"
                    />
                </Col>

                <Col md={"auto"} className={"text-right"}>
                    <ModalInputWrapper label={''}>
                        <Button label={"Найти"} onClick={searchForm.handleSubmit}/>
                    </ModalInputWrapper>
                </Col>
            </Row>
        </div>
    );
};

AccountingJournalReportForm.propTypes = {
    onSubmit: PropTypes.func
};

export default AccountingJournalReportForm;
