import { call, put, takeLatest } from "redux-saga/effects";
import {
    CURRICULUM_LIST_REQUEST,
    START
} from "../../actions/action-types";
import {
    getCurriculumCoursesList
} from "../../../api/curriculum/curriculumCourses";
import {
    curriculumListRequestSuccess,
    curriculumListRequestError
} from "../../actions/curriculum/list";

export default function* curriculumWatcher() {
    yield takeLatest(`${CURRICULUM_LIST_REQUEST}${START}`, curriculumListWorker);
}

function* curriculumListWorker() {
    try {
        const response = yield call(getCurriculumCoursesList);
        yield put(curriculumListRequestSuccess(response));
    } catch (error) {
        yield put(curriculumListRequestError(error));
    }
}
