import {COUNT, DOCUMENTS, LIST, START, SUCCESS} from "../../../actions/action-types";

const initialState = false

export const documentLoaderReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${DOCUMENTS}${COUNT}${START}`:
      return true;

    case `${DOCUMENTS}${LIST}${SUCCESS}`:
      return false;

    default: return state;
  }
};