import { DOCUMENT_TYPES_DELETE_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const documentTypesDeleteRequestStart = (data) => ({
  type: `${DOCUMENT_TYPES_DELETE_REQUEST}${START}`,
  payload: data
});

export const documentTypesDeleteRequestSuccess = (data) => ({
  type: `${DOCUMENT_TYPES_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentTypesDeleteRequestError = (data) => ({
  type: `${DOCUMENT_TYPES_DELETE_REQUEST}${ERROR}`,
  payload: data
});
