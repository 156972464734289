import { LIBRARY_INSTITUTIONS_REQUEST, SUCCESS } from "../../../../actions/action-types";

const initialState = [];

export const libraryInstitutionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LIBRARY_INSTITUTIONS_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};