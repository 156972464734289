import React from 'react'
import DataTableCol from '../../DataTable/Column'
import DataTable from '../../DataTable'
import moment from 'moment'
import { STATUS_LIST } from '../../../scenes/BookScanPlan'
import CoverPhotoPlaceholder from '../../Covers/List/Item/CoverPhotoPlaceholder'
import { BOOKSCAN_SORT } from '../../../constants/sort/bookscan'
import classNames from 'classnames'

const List = ({ selectedData, data = [], openModal, sortBy, setSort, handleSelectData }) => {
  return (
    <DataTable data={data} onCellClick={openModal} sortBy={sortBy} onHeadClick={setSort}>
      <DataTableCol title={'№'} value={'rnum'} />
      <DataTableCol
        title={''}
        resolver={item => <CoverPhotoPlaceholder url={item.coverImage} bookId={item.bookId} alt={item.title} />}
      />
      <DataTableCol colSpan={2} title={'Название'} value={'title'} sortParams={[BOOKSCAN_SORT.TITLE]} />
      <DataTableCol title={'Авторы'} resolver={item => item.authors && item.authors.map(author => author.name)} />
      <DataTableCol
        title={'Издательства'}
        resolver={item => item.publishers && item.publishers.map(publisher => publisher.name)}
      />
      <DataTableCol title={'Год'} value={'year'} sortParams={[BOOKSCAN_SORT.YEAR]} />
      <DataTableCol title={'ISBN'} value={'isbn'} sortParams={[BOOKSCAN_SORT.ISBN]} />
      <DataTableCol title={'Страниц'} value={'pageNum'} sortParams={[BOOKSCAN_SORT.PAGENUM]} />
      <DataTableCol
        title={'Дата'}
        resolver={item => item.timestamp && moment(item.timestamp).format('DD.MM.YYYY')}
        sortParams={[BOOKSCAN_SORT.TIMESTAMP]}
        value={'timestamp'}
      />
      <DataTableCol
        title={'Статус'}
        resolver={item => item.status && STATUS_LIST.get(item.status)}
        sortParams={[BOOKSCAN_SORT.STATUS]}
      />
      <DataTableCol title={'Создатель'} value={'uploader'} sortParams={[BOOKSCAN_SORT.UPLOADER]} />
      <DataTableCol
        title={'Срок исполнения'}
        value={'duedate'}
        sortParams={[BOOKSCAN_SORT.DUEDATE]}
        resolver={({ duedate }) => (
          <span
            className={classNames({
              color_red: moment(duedate).isBefore(moment().add(-1, 'days')),
            })}
          >
            {duedate && moment(duedate).format('DD.MM.YYYY')}{' '}
          </span>
        )}
      />
      <DataTableCol
        title={'Выбрано'}
        resolver={item => (
          <input type='checkbox' checked={selectedData.find(e => e.id === item.id)} onChange={() => handleSelectData(item)} />
        )}
      />
    </DataTable>
  )
}

export default List
