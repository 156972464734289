import { BOOKINVENTORY_COUNT_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const bookInventaryCountRequestStart = () => ({
  type: `${BOOKINVENTORY_COUNT_REQUEST}${START}`
});

export const bookInventaryCountRequestSuccess = (data) => ({
  type: `${BOOKINVENTORY_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookInventaryCountRequestError = (data) => ({
  type: `${BOOKINVENTORY_COUNT_REQUEST}${ERROR}`,
  payload: data
});
