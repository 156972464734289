import { ERROR, AUTHORS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const authorsListRequestStart = () => ({
  type: `${AUTHORS_LIST_REQUEST}${START}`
});

export const authorsListRequestSuccess = (data) => ({
  type: `${AUTHORS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const authorsListRequestError = (error) => ({
  type: `${AUTHORS_LIST_REQUEST}${ERROR}`,
  payload: error
});

