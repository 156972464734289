import { START, SUCCESS, ERROR, LOST_DOC_COUNT_REQUEST } from "../action-types";

export const lostDocumentCountRequestStart = (data) => ({
  type: `${LOST_DOC_COUNT_REQUEST}${START}`,
  payload: data
});

export const lostDocumentCountRequestSuccess = (response) => ({
  type: `${LOST_DOC_COUNT_REQUEST}${SUCCESS}`,
  payload: response
});

export const lostDocumentCountRequestError = (error) => ({
  type: `${LOST_DOC_COUNT_REQUEST}${ERROR}`,
  payload: error
});