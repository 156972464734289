import React, {useEffect, useRef, useState} from 'react';
import styles from './UdkScene.module.css';
import { connect } from "react-redux";
import { udkListCollapse, udkListRequestStart } from "../../../redux/actions/udk/list";
import { Col, Row } from "react-bootstrap";
import UDKList from "../../../components/UDK/List";
import Search from "../../../components/Search";

const mapStateToProps = state => ({
    list: state.udk
});

const mapDispatchToProps = dispatch => ({
    requestList: (id, search, rewrite) => dispatch(udkListRequestStart(id, search, rewrite)),
    collapseList: (id) => dispatch(udkListCollapse(id))
});

const UDKScene = ({ list, requestList, collapseList }) => {

    const [ search, setSearch ] = useState("");
    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {
        // Шифр
        firstRender && requestList(0)
        setFirstRender(false)
    }, []);


    return (
        <div className={styles.container}>
            <Row>
                <Col md={6}>
                    <div className={styles.title}>
                        Справочники / УДК
                    </div>
                </Col>
                <Col md={6}>
                    <Search
                        label={"Введите код УДК или ключевое слово для поиска"}
                        placeholder='Разрешенные символы: а-я 0-9 . /'
                        onChange={setSearch}
                        // Шифр
                        onSubmit={() => requestList(0, search, true)}
                        value={search}
                        inputClassName={styles.searchInput}
                        type="text"
                    />
                </Col>
                <Col md={12}>
                    {!!list.length && <UDKList data={list} fetchSubList={requestList} collapseList={collapseList}/>}
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UDKScene);
