import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import {Button} from "antd";
import styles from './DynamicCollectionForm.module.css';
import {collectionGetDataRequestStart} from "../../../redux/actions/collections/data/getData";
import {connect} from "react-redux";
import {COLLECTION_TYPE} from "../../../constants/collectionTypes";
import DynamicCollectionFormItem from "./Item";
import {collectionSaveDataRequestStart} from "../../../redux/actions/collections/data/saveData";
import {collectionDeleteDataRequestStart} from "../../../redux/actions/collections/data/deleteData";


const mapStateToProps = ({collections}) => ({
    data: collections.data
});

const mapDispatchToProps = dispatch => ({
    requestData: (collectionId, collectionType) => dispatch(collectionGetDataRequestStart(collectionId, collectionType)),
    saveData: (collectionId, data, collectionType) => dispatch(collectionSaveDataRequestStart(collectionId, data, collectionType)),
    deleteData: (collectionId, dataId, collectionType) => dispatch(collectionDeleteDataRequestStart(collectionId, dataId, collectionType))
});

const DynamicCollectionForm = ({collectionId, data, requestData, saveData, deleteData}) => {
    const [showEmpty, setShowEmpty] = useState(false);

    useEffect(() => {
        !data && requestData(collectionId, COLLECTION_TYPE.DYNAMIC)
    }, [data, collectionId, requestData]);

    useEffect(() => setShowEmpty(false), [data]);

    const onSave = (data) => {
        saveData(collectionId, data, COLLECTION_TYPE.DYNAMIC);
    };

    const onDelete = (dataId) => {
        deleteData(collectionId, dataId, COLLECTION_TYPE.DYNAMIC);
    };

    return (
        <div className={styles.container}>
            {data && data.map((item, index) => (
                <DynamicCollectionFormItem data={item} key={index} onSave={onSave} onDelete={onDelete}/>
            ))}
            {showEmpty && <DynamicCollectionFormItem onSave={onSave}/>}
            <Button onClick={() => setShowEmpty(true)} type="primary">Добавить</Button>
        </div>
    );
};

DynamicCollectionForm.propTypes = {
    collectionId: T.number
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicCollectionForm);
