import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ModalWindow from '../../ModalWindow'
import BookSearchForm from '../../Covers/SearchForm'
import { setCoversFormData } from '../../../redux/actions/covers/form'
import { coversCountRequestStart } from '../../../redux/actions/covers/count'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import Pagination from '../../Pagination'
import SendBookList from '../SendBookList'
import { setCoversSearchData } from '../../../redux/actions/covers/search'
import { optionsGetRequestStart } from '../../../redux/actions/options/get'

const mapStateToProps = ({ covers, options, reservedBooks }) => ({
  list: covers.list,
  count: covers.count,
  formData: covers.form,
  searchData: covers.search,
  loader: covers.loader,
  options: options.data,
  reservedBooks: reservedBooks,
})

const mapDispatchToProps = {
  requestList: coversCountRequestStart,
  setFormData: setCoversFormData,
  setSearchData: setCoversSearchData,
  requestOptions: optionsGetRequestStart,
}

const SendBookModal = ({
  isOpen,
  onClose,
  title,
  options,
  requestOptions,
  count,
  list,
  formData,
  setFormData,
  requestList,
  loader,
  userId,
  searchData,
  setSearchData,
  checkOutCurrentBook,
}) => {
  useEffect(() => {
    requestOptions()
  }, [requestOptions])

  const handleFormSubmit = () => {
    setFormData({
      ...formData,
      idclient: options.own_client_id,
    })
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  return (
    <ModalWindow isOpen={isOpen} onRequestClose={onClose} title={title} width={1200}>
      {!!checkOutCurrentBook && <SendBookList data={checkOutCurrentBook} userId={userId} />}
      {!!checkOutCurrentBook === false && (
        <BookSearchForm
          data={formData}
          onSubmit={handleFormSubmit}
          setData={setFormData}
          noVerifyStatus={true}
          hideInstitutions={true}
          disableAlphabet={true}
        />
      )}

      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />

      {!!checkOutCurrentBook === false && loader ? <SpinnerOuter /> : <SendBookList data={list} userId={userId} />}

      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
    </ModalWindow>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SendBookModal)
