import {SUCCESS, TURNOUT_STATS_LIST_REQUEST} from "../../../actions/action-types";

const initialState = [];

export const turnoutStatsListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${TURNOUT_STATS_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
