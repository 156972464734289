import React from "react";
import T from 'prop-types';
import styles from "./ReaderSearchResults.module.css";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImagePlaceholder from "../ImagePlaceholder";

const UserSearchCard = ({
                            name,
                            link,
                            photo,
                            photoExists,
                            position
                        }) => {
    return (
        <Link
            to={link}
            className={styles.item}
        >
            <Row>
                <Col md={10}>
                    <div className={styles.infoContainer}>
                        {name}
                        <br />
                        {position}
                    </div>
                </Col>
                <Col md={2}>
                    {photoExists ? (
                        <img
                            alt={name}
                            className={styles.image}
                            src={photo}
                        />
                    ) : (
                        <ImagePlaceholder className={styles.image} />
                    )}
                </Col>
            </Row>
        </Link>
    );
};

UserSearchCard.propTypes = {
    name: T.string,
    link: T.string,
    photo: T.string,
    photoExists: T.string,
    position: T.string
};

export default UserSearchCard;
