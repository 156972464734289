import React from 'react';
import T from 'prop-types';
import styles from './SVNIList.module.css';
import { pack } from '../../AdminDerectory/Modal/Form/Pack';
import { useState } from 'react';
import { useEffect } from 'react';
import { api } from '../../../App';

const SVNIList = ({data = [], fetchSubList, collapseList, onSelect, selectMode, isButton, listData}) => {

    const [isShow, setIsShow] = useState([]);
    const [list, setList] = useState([]);

    const getInputType = (key) => {
        const type = list?.columns?.find(el => el.name == key)?.type;
        if (type && type == 'CHARACTER VARYING') {
            return 'text';
        } else if (type && type == 'INT') {
            return 'number';
        } else if (type && type == 'BOOLEAN') {
            return 'checkbox';
        }
    }

    useEffect(() => {
        const newisActive = data.map(el => isShow?.find(_el => _el.id == el.id)?.isShow ? ({id: el.id, isShow: true}) : ({id: el.id, isShow: false}));
        setIsShow(newisActive);
    }, [data?.length])

    useEffect(() => {
        if (data?.length) api.get('/guide/svni').then(data => setList(data.data))
    }, [])

    const onClick = (e, item) => {
        handleShow(item.id);
        if(e.target.tagName !== 'BUTTON') {
          data.subList ? collapseList(data.id) : fetchSubList(data.id);
        }
      };

      const handleShow = (id) => {
        if (isShow.find(el => el
            ?.id == id && el 
                ?.isShow == true)) {
            setIsShow(isShow.map(el => el.id == id
                ? {
                    id: el.id,
                    isShow: false
                }
                : el))
        } else {
            setIsShow(isShow.map(el => el.id == id
                ? {
                    id: el.id,
                    isShow: true
                }
                : el))
        }
    }

    const createSublist = (data, level) => {
        return data.map((item, index) => {
        if (isShow.find(el => el
            ?.id == item.parent && el.isShow == false)) {
            return;
        } else {
            return <React.Fragment key={index}>
                <tr 
                    onClick={(e) => onClick(e, item)} className={styles.listItem}
                    >
                    {Array(level - 1).fill(1).map((num, index) => (
                        <td colSpan={1} key={index}> </td>
                    ))}
                    <td colSpan={1}>{item['Код']}</td>
                    {Object.keys(item).map((key, index) => {
                        if (['id', 'parent', 'columns', 'Код', 'err', 'len', 'type', 'is_null', 'description', 'isNew', 'isEdit', 'тест'].includes(key) ) {
                            return 
                        } else {
                            return <td colSpan={key == 'Название' ? 12 - level : 3} 
                            key={index} name={key}>
                                {
                                    getInputType(key) == 'checkbox' ?
                                    <input 
                                        checked={item[key]} 
                                        name={key}
                                        disabled
                                        type='checkbox' 
                                        className={styles.input} 
                                        />
                                    :
                                    item[key]
                                }
                            </td>
                        }})}
                        {onSelect && isButton &&
                    <td colSpan={2}>
                    <button 
                    className={styles.button}
                    onClick={() => onSelect(item['Код'])}>Добавить</button>
                    </td>
                }
                </tr>
                {item.columns && createSublist(item.columns, level + 1)}
            </React.Fragment>
            }
        })
    };

    return (
        <div className={styles.container}>
            <table>
            <thead>
                <td 
                colSpan={1}
                >Код</td>
                {data[0] && Object.keys(data[0]).map((key, index) => {
                if (['id', 'parent', 'columns', 'Код', 'err', 'len', 'type', 'is_null', 'description', 'isNew', 'isEdit', 'тест'].includes(key) ) {
                    return 
                } else {
                    return <td style={{padding: '0 .5rem'}} colSpan={key=='Название' ? 11 : 3} key={index} name={key}>{key}</td>
                }})}
                {isButton && <td style={{padding: '0 .5rem'}} colSpan={2}>Выбрать</td>}
            </thead>
                <tbody>
                    {// Шифр
                        (data && data.length > 0 && !data?.find(el => el.parent == 0)) ?
                        createSublist(pack(data.map(el => ({...el, parent: '0'}))), 1)   
                        :
                        createSublist(pack(data), 1)}</tbody>
            </table>
        </div>
    );
};

SVNIList.propTypes = {
    data: T.array.isRequired,
    fetchSubList: T.func.isRequired,
    collapseList: T.func.isRequired,
    onSelect: T.func
};

export default SVNIList;
