import React from 'react'
import T from 'prop-types'
import PdfPrintPanel from '../../PDFPrint/Panel'
import addFont from '../../../fonts/fonts'
import jsPDF from 'jspdf'
import moment from 'moment'

export const ExemptTestsPDFPrint = ({ fromDate, toDate, data, orientation, title }) => {
  const headers = [
    {
      value: 'title',
      title: 'Название теста',
    },
    {
      value: ({ date }) => (date ? moment(date).format('DD.MM.YYYY') : 'Не указано'),
      title: 'Дата изъятия',
    },
    {
      value: 'reason',
      title: 'Причина изъятия',
    },
  ]


  const createData = tableData => {
    let result = []

    tableData.forEach((item, index) => {
      const generatedItem = []

      headers.forEach(({ value }) => {
        generatedItem.push(typeof value === 'function' ? value(item, index) : item[value] || '-')
      })

      result.push(generatedItem)
    })

    return result
  }

  const createHeaders = () => headers.map(header => header.title)

  const createPdfDoc = tableData => {
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    doc.setFont('Montserrat')
    doc.setFontSize('16')
    doc.text(title, doc.internal.pageSize.width / 2, 15, null, null, 'center')

    doc.setFontSize('12')
    doc.text(`c ${fromDate} по ${toDate}`, doc.internal.pageSize.width / 2, 25, null, null, 'center')

    doc.autoTable({
      head: [createHeaders()],
      body: createData(tableData),
      styles: { font: 'Montserrat', fontSize: 10 },
      margin: {
        top: 25,
      },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 30,
    })

    return doc
  }

  const toPdf = () => {
    const doc = createPdfDoc(data)
    doc.save(`${title}.pdf`)
  }

  const toPrint = () => {
    const doc = createPdfDoc(data)
    doc.autoPrint()
    doc.output('dataurlnewwindow')
  }

  return (
    <div className={'d-flex justify-content-end mb-1'}>
      <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
    </div>
  )
}

ExemptTestsPDFPrint.propTypes = {
  title: T.string,
  orientation: T.oneOf(['landscape', 'portrait']),
}
