import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import SecretDocBookForm from './Form'
import SecretDocBookTable from './Table'
import { bookSecretDocCountRequestStart } from '../../redux/actions/booksecretdoc/count'

const mapStateToProps = ({ bookSecretDoc, options }) => ({
  data: bookSecretDoc.list,
  loader: bookSecretDoc.loader,
  options: options.data,
})

const mapDispatchToProps = {
  requestList: bookSecretDocCountRequestStart,
}

const SecretDocBook = ({ data, loader, requestList, options }) => {
  const requestData = formData => {
    requestList(formData)
  }

  return (
    <SceneContainer title={'Книга учета секретных документов'}>
      <SecretDocBookForm onSubmit={requestData} />
      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        !!data.length && (
          <>
            <div className={'mb-2'}>
              <div>
                {options?.nomenclatureName} <br />
                <b>{options?.GOU_Name.join('\n')}</b> <br />
                Номер по номенклатуре: {options?.nomenclatureNumber}
              </div>
              <div style={{overflowY: 'auto', width: '100%'}}><SecretDocBookTable data={data} /></div>
            </div>
          </>
        )
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SecretDocBook)
