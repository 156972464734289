import React from 'react';
import T from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import { ADD, EDIT } from "../../../constants/modalTypes";
import styles from "./LibraryTable.module.css";

const LibraryTable = ({ initialFormFields, data, setModal, sortBy, setSort, onDelete, selectedId, handleSelect }) => {
  const openEditModal = (item) => setModal({ modalType: EDIT, data: item });

  const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields });


  return (
    <React.Fragment>
      <div className={styles.button} onClick={openCreationModal}>
        добавить
      </div>
      <div className={styles.button} onClick={onDelete}>
        удалить
      </div>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openEditModal}>
        <DataTableCol title="Организация" value="orgname" colSpan={4}/>
        <DataTableCol title="Название библиотеки" value="name" colSpan={2}/>
        <DataTableCol title="Расположение" value="address" resolver={() => "n/a"}/>
        <DataTableCol title="Книг на учёте" value="librarynumbooks" resolver={() => "n/a"}/>
        <DataTableCol title="Выбрано" passClicks resolver={(item) =>
          <input type="checkbox" checked={item.id === selectedId}
                 onChange={() => handleSelect(item.id)}/>}
        />
      </DataTable>
    </React.Fragment>
  );
};

LibraryTable.propTypes = {
  data: T.array,
  setModal: T.func,
  sortBy: T.string,
  setSort: T.func,
  selectedId: T.number,
  handleSelect: T.func,
  onDelete: T.func,
  initialFormFields: T.object.isRequired
};

LibraryTable.defaultProps = {
  data: []
};

export default LibraryTable;
