import { createNotification } from "../../../components/notification/notification";
import { ERROR, DISCIPLINE_SAVE_START, START, SUCCESS } from "../action-types";

export const saveDisciplineRequestStart = (data, callback) => ({
    type: `${DISCIPLINE_SAVE_START}${START}`,
    payload: data, callback
});

export const saveDisciplineRequestSuccess = (data) => ({
    type: `${DISCIPLINE_SAVE_START}${SUCCESS}`,
    payload: data
});

export const saveDisciplineRequestError = (error) => {
    const message = error?.response?.data?.result || 'Не удалось добавить дисциплину'
    createNotification('error', message)
    return {
        type: `${DISCIPLINE_SAVE_START}${ERROR}`,
        payload: error
    }
};

