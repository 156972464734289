import { BOOK_RESERVE_COUNT_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const bookreserveCountRequestStart = () => ({
  type: `${BOOK_RESERVE_COUNT_REQUEST}${START}`
});

export const bookreserveCountRequestSuccess = (data) => ({
  type: `${BOOK_RESERVE_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookreserveCountRequestError = (error) => ({
  type: `${BOOK_RESERVE_COUNT_REQUEST}${ERROR}`,
  payload: error
});
