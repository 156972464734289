import { SUCCESS, USERS_GET_PHOTO_REQUEST, SET_USERS_PHOTO } from "../../../actions/action-types";

const initialState = null;

export const usersPhotoReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${USERS_GET_PHOTO_REQUEST}${SUCCESS}`:
      return action.payload;
    case `${SET_USERS_PHOTO}`:
      return action.payload;

    default: return state;
  }
};