import {SET_FUND_ACCOUNTING_SEARCH_DATA} from '../../../actions/action-types';
import moment from "moment";


const initialState = {
  from: moment().startOf('year').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  // Шифр
  libraryid: 0
};

export const fundAccountingSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_FUND_ACCOUNTING_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};
