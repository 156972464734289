import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import InputWrapper from '../../../Modal/InputWrapper'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import Button from '../../../Button'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getYearsList } from '../../../../utils/helpers/helpers'

const LibrariesDiaryForm = ({ librariesList, searchForm }) => {
  const monthsList = [
    { name: 'Все', value: 0 },
    { name: 'Январь', value: 1 },
    { name: 'Февраль', value: 2 },
    { name: 'Март', value: 3 },
    { name: 'Апрель', value: 4 },
    { name: 'Май', value: 5 },
    { name: 'Июнь', value: 6 },
    { name: 'Июль', value: 7 },
    { name: 'Август', value: 8 },
    { name: 'Сентябрь', value: 9 },
    { name: 'Октябрь', value: 10 },
    { name: 'Ноябрь', value: 11 },
    { name: 'Декабрь', value: 12 },
  ]

  const history = useHistory()

  const openFieldsSettings = () => {
      history.push({
        pathname: '/options',
        state: 'libraryDiary'
      })
  }

  return (
    <div className={'formContainer'}>
      <Row>
        <Col md={4} lg={3}>
          <InputWrapper label={'Год'} error={searchForm.errors.year}>
            <select name='year' value={searchForm.values.year} onChange={searchForm.handleChange}>
              {getYearsList().map(year => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </InputWrapper>
        </Col>
        <Col md={4} lg={3}>
          <InputWrapper label={'Месяц'} error={searchForm.errors.month}>
            <select name='month' value={searchForm.values.month} onChange={searchForm.handleChange}>
              {monthsList.map(month => (
                <option value={month.value} key={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </InputWrapper>
        </Col>
        <Col md={4} lg={3}>
          <InputWrapper label={'Библиотека'} error={searchForm.errors.library}>
            <select name='library' value={searchForm.values.library} onChange={searchForm.handleChange}>
              <option value='0'>Все библиотеки</option>
              {librariesList.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </InputWrapper>
        </Col>
        <Col md={'auto'} className={'text-right'}>
          <ModalInputWrapper label={''}>
            <Button label={'Найти'} onClick={searchForm.handleSubmit} />
          </ModalInputWrapper>
        </Col>
        <Col md={'auto'} className={'text-right'}>
          <ModalInputWrapper label={''}>
            <Button label={'Настройки полей'} onClick={openFieldsSettings} />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  )
}

LibrariesDiaryForm.propTypes = {
  onSubmit: PropTypes.func,
  librariesList: PropTypes.array,
}

export default LibrariesDiaryForm
