import { createNotification } from "../../../../components/notification/notification";
import {ERROR, MOBILE_LIBRARY_RESPONSIBLE_REQUEST, START, SUCCESS} from "../../action-types";

export const getResponsibleRequestStart = (libId) => {
    return {
        type: `${MOBILE_LIBRARY_RESPONSIBLE_REQUEST}${START}`,
        libId
    }
};

export const getResponsibleRequestSuccess = (payload) => {
    return {
        type: `${MOBILE_LIBRARY_RESPONSIBLE_REQUEST}${SUCCESS}`,
        payload
    }
};

export const getResponsibleRequestError = (payload) => {
    return {
        type: `${MOBILE_LIBRARY_RESPONSIBLE_REQUEST}${ERROR}`,
        payload
    }
};