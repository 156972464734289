import React from 'react'
import PropTypes from 'prop-types'
import ModalWindow from '../../ModalWindow'
import { EDIT } from '../../../constants/modalTypes'
import InputWrapper from '../../Modal/InputWrapper'
import * as Yup from 'yup'
import { Button } from 'antd'
import { useFormik } from 'formik'
import moment from 'moment'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Это обязательное поле').max(1024, 'Недопустимая длинна'),
  date: Yup.string().required('Это обязательное поле'),
  reason: Yup.string().required('Это обязательное поле').max(1024, 'Недопустимая длинна'),
})

const ExemptTestsModal = ({ modalData, closeModal, onSubmit }) => {
  const { modalType, data } = modalData

  const modalForm = useFormik({
    initialValues: {
      id: data?.id || 0,
      title: data?.title || '',
      date: (data && moment(data.date).format('YYYY-MM-DD')) || moment('YYYY-MM-DD'),
      reason: data?.reason || '',
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
  })

  return (
    <ModalWindow
      title={`Документ: ${modalType === EDIT ? 'редактирование' : 'добавление'}`}
      isOpen={true}
      onRequestClose={closeModal}
      width={1000}
    >
      <InputWrapper
        label='Название теста'
        name='title'
        value={modalForm.values.title}
        onChange={modalForm.handleChange}
        error={modalForm.errors.title}
      />
      <InputWrapper
        type='date'
        label='Дата изъятия'
        name='date'
        value={modalForm.values.date}
        onChange={modalForm.handleChange}
        error={modalForm.errors.date}
      />
      <InputWrapper
        label='Причина изъятия'
        name='reason'
        value={modalForm.values.reason}
        onChange={modalForm.handleChange}
        error={modalForm.errors.reason}
      />

      <Button type='primary' onClick={modalForm.handleSubmit}>
        Сохранить
      </Button>
    </ModalWindow>
  )
}

ExemptTestsModal.propTypes = {
  modalData: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

export default ExemptTestsModal
