import {
    BOOK_COMPRESS_REQUEST,
    BOOK_DECOMPRESS_REQUEST,
    START,
    SUCCESS
} from "../../../actions/action-types";

const initialState = false;

export const bookEditFileLoaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${BOOK_COMPRESS_REQUEST}${START}`:
            return true;

        case `${BOOK_COMPRESS_REQUEST}${SUCCESS}`:
            return false;

        case `${BOOK_DECOMPRESS_REQUEST}${START}`:
            return true;

        case `${BOOK_DECOMPRESS_REQUEST}${SUCCESS}`:
            return false;

        default:
            return state;
    }
};