import {  BOOK_EXPORTS_LOAD, SUCCESS, START, ERROR } from "../../../actions/action-types";

const initialState = [];

export const booksExportListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${BOOK_EXPORTS_LOAD}${START}`:
      return state;

    case `${BOOK_EXPORTS_LOAD}${SUCCESS}`:
      return action.payload;

    case `${BOOK_EXPORTS_LOAD}${ERROR}`:
      return action.payload;

    default: return state;
  }
};
