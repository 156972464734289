import {MOBILE_LIBRARY_LIST_REQUEST, START, SUCCESS, ERROR, CURRENT_MOBILE_LIBRARY_REQUEST} from "../../../actions/action-types";



const initialState = false



export const mobileLibraryLoaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${MOBILE_LIBRARY_LIST_REQUEST}${START}`:
        case `${CURRENT_MOBILE_LIBRARY_REQUEST}${START}`:
            return true
            
        case `${MOBILE_LIBRARY_LIST_REQUEST}${SUCCESS}`:
        case `${CURRENT_MOBILE_LIBRARY_REQUEST}${SUCCESS}`:
                return false
                
        case `${MOBILE_LIBRARY_LIST_REQUEST}${ERROR}`:
        case `${CURRENT_MOBILE_LIBRARY_REQUEST}${ERROR}`:
            return false

        default: return state;
    }
};