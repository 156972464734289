import React from 'react';
import PropTypes from 'prop-types';
import styles from "../LibraryCardPass.module.css";



const background = require('../../../../../img/mo_reader_pass.png');
const footer = require('../../../../../img/reader_pass_back_footer.png');

const BackSide = ({ refItem }) => {
  return (
    <div className={`${styles.container} ${styles.back_side} d-flex flex-column justify-content-between`}
         style={{
           backgroundImage: "url(" + background + ")"
         }}
         ref={refItem}
    >
      <div className={styles.back_content}>
        <div className={styles.back_title}>МИНИСТЕРСТВО ОБОРОНЫ РОССИЙСКОЙ ФЕДЕРАЦИИ</div>

        <p>Данный читательский билет предназначен для авторизации в автоматизированной библиотечной информационной системе военной образовательной организации.</p>
        <p>Читательский билет запрещается передавать другим лицам.</p>
        <p>Читательский билет дает право пользования разделами электронной бибилиотеки в соответствии с уровнем доступа.</p>
      </div>
      <div
        className={styles.back_footer}
        style={{
          backgroundImage: "url(" + footer + ")"
        }}
      >
        <div>СПО “Электронная библиотека”</div>
      </div>
    </div>
  );
};

BackSide.propTypes = {
  data: PropTypes.object
};

export default BackSide;