import { call, put, takeLatest, select } from "redux-saga/effects";
import { LIBRARY_SAVE_REQUEST, START } from "../../../../actions/action-types";
import {
  librarySaveRequestError,
  librarySaveRequestSuccess,
} from "../../../../actions/library/info/save";
import { ADD } from "../../../../../constants/modalTypes";
import {
  libraryListRequestStart,
  resetCurrentLibraryData,
} from "../../../../actions/library/info/list";
import { libraryAdd, libraryEdit } from "../../../../../api/library/library";
import {
  addLibraryFund,
  addLibraryPoint,
} from "../../../../../api/library/library";

export default function* librarySaveWatcher() {
  yield takeLatest(`${LIBRARY_SAVE_REQUEST}${START}`, librarySaveWorker);
}

function* librarySaveWorker({ payload: { data, modalType: type }, callback }) {

  function* addNewFunds(funds, libraryId) {
    for (let fund of funds) {
      yield call(addLibraryFund, libraryId, fund);
    }
  }

  function* addNewPoints(points, libraryId) {
    for (let point of points) {
      yield call(addLibraryPoint, libraryId, point);
    }
  }

  try {
    let response;
    if (type === ADD) {
      response = yield call(libraryAdd, data);
    } else {
      response = yield call(libraryEdit, data);
    }
    yield put(librarySaveRequestSuccess(response.data));
    if (callback) yield call(callback);
    const libraryId = response.data.id;
    const addFunds = yield select(({ library }) => library.info.addFunds);
    const addPoints = yield select(({ library }) => library.info.addPoints);

    yield addNewFunds(addFunds, libraryId);
    yield addNewPoints(addPoints, libraryId);

    yield put(libraryListRequestStart());
    yield put(resetCurrentLibraryData());
  } catch (error) {
    yield put(librarySaveRequestError(error));
  }
}
