import { api } from '../../App';

export const uploadCover = (bookId, image) => {
  const formData = new FormData();

  formData.append('cover', image);

  const headers = [
    {
      'Content-Type': 'multipart/form-data',
    }
  ];

  return api.put(`/bookcover/${bookId}`, formData, { headers });
};

export const scanCover = (bookId, image) => {
  const formData = new FormData();
  formData.append('cover', image, 'aFileName.jpg');
  const headers = [
    { 'Content-Type': 'multipart/form-data' }
  ];

  return api.put(`/bookcoverscan/${bookId}`, formData, { headers });
};
