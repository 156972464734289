import React, { useEffect } from 'react';
import styles from './SystemInfoScene.module.css';
import { systemVersionRequestStart } from "../../redux/actions/version/systemVersion";
import { connect } from "react-redux";
import ModalInputWrapper from "../../components/Modal/InputWrapper";
import { APP_VERSION } from "../../constants/version";

const mapStateToProps = ({ systemVersion }) => ({
  data: systemVersion
});

const mapDispatchToProps = dispatch => ({
  getData: () => dispatch(systemVersionRequestStart())
});

const SystemInfoScene = ({ data, getData }) => {
  useEffect(() => {
    getData()
  }, [getData]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        О системе
      </div>
      {data &&
      <div className={styles.dataContainer}>
        <ModalInputWrapper label={"Версия API"}
                           value={data.date}/>
        <ModalInputWrapper label={"Версия БД"}
                           value={data.version}/>
        <ModalInputWrapper label={"Git tag"}
                           value={data.tag}/>
        <ModalInputWrapper label={"Версия Node.js"}
                           value={data.node}/>
        <ModalInputWrapper label={"Хост"}
                           value={data.hostname}/>
        <ModalInputWrapper label={"Версия React"}
                           value={React.version}/>
        <ModalInputWrapper label={"Версия React-приложения"}
                           value={APP_VERSION}/>

      </div>}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemInfoScene);
