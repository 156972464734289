import { combineReducers } from "redux";
import { externalResourcesListReducer } from "./list/externalResourcesListReducer";
import { externalResourcesFullListReducer } from "./fullList/externalResourcesFullListReducer";
import { externalResourcesSaveStatusReducer } from "./saveStatus/externalResourcesSaveStatusReducer";

export const externalResourcesReducer = combineReducers({
  list: externalResourcesListReducer,
  fullList: externalResourcesFullListReducer,
  saveStatus: externalResourcesSaveStatusReducer
});
