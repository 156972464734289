import {SEARCH_AUDIT_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const searchAuditListReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${SEARCH_AUDIT_LIST_REQUEST}${SUCCESS}`:
            return action.payload.map(item => ({...item, payloadbody: JSON.parse(item.payloadbody)}));

        default:
            return state;
    }
};
