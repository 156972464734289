import { api } from "../../App";
export const publishersCount = (search) => {
  const params = {
    count: true,
    search
  };

  return api.get('/publisher', { params });
};

export const publishersList = (orderBy, maxResults, paginateFrom, search) => {
  const params = {
    orderBy: 'fullName',
    maxResults,
    paginateFrom,
    search
  };

  return api.get('/publisher', { params });
};

export const publishersAdd = (data) => {
  const { name, fullName, city, isbn } = data;
  const body = {
    name, fullName, city, isbn
  };

  return api.post('/publisher', body);
};

export const publishersEdit = (data) => {
  const { name, fullName, city, isbn, status, id } = data;
  const body = {
    name, fullName, city, isbn, status
  };

  return api.put(`/publisher/${id}`, body);
};

export const publishersDelete = (id) => {
  return api.delete(`/publisher/${id}`);
};
