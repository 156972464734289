import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  DOCUMENT_BOOK_DELETE_REQUEST,
  DOCUMENT_BOOK_LIST_REQUEST,
  DOCUMENT_BOOK_SAVE_REQUEST,
  START,
} from '../../../actions/action-types'
import {
  addBookToDocument,
  deleteBookToDocument,
  editBookToDocument,
  getDocumentBooksList,
} from '../../../../api/documents/bookToDocument'

import {
  documentBookListRequestError,
  documentBookListRequestStart,
  documentBookListRequestSuccess,
} from '../../../actions/documents/books/list'
import { documentBookSaveRequestError, documentBookSaveRequestSuccess } from '../../../actions/documents/books/save'
import { documentBookDeleteRequestError, documentBookDeleteRequestSuccess } from '../../../actions/documents/books/delete'

export default function* documentBooksWatcher() {
  yield takeLatest(`${DOCUMENT_BOOK_LIST_REQUEST}${START}`, documentBooksListWorker)
  yield takeLatest(`${DOCUMENT_BOOK_SAVE_REQUEST}${START}`, documentBooksSaveWorker)
  yield takeLatest(`${DOCUMENT_BOOK_DELETE_REQUEST}${START}`, documentBooksDeleteWorker)
}

function* documentBooksListWorker({ payload: documentId }) {
  try {
    const response = yield call(getDocumentBooksList, documentId)
    yield put(documentBookListRequestSuccess(response.data))
  } catch (error) {
    yield put(documentBookListRequestError(error))
  }
}

function* documentBooksSaveWorker({ payload: dataType18 }) {
  const data = JSON.parse(JSON.stringify(dataType18))
  delete data.fundto
  delete data.bookinstanceid
  const documentType = parseInt(yield select(({ documents }) => documents.current.documenttype))
  // Шифр
  const ifData = documentType === 18 ? dataType18 : data

  try {
    const response = yield call(dataType18.id ? editBookToDocument : addBookToDocument, ifData)
    yield put(documentBookSaveRequestSuccess(response.data))
    yield put(documentBookListRequestStart(data.documentid))
  } catch (error) {
    yield put(documentBookSaveRequestError(error))
  }
}

function* documentBooksDeleteWorker({ payload: data }) {
  try {
    const response = yield call(deleteBookToDocument, data.id)
    yield put(documentBookDeleteRequestSuccess(response.data))
    yield put(documentBookListRequestStart(data.documentid))
  } catch (error) {
    yield put(documentBookDeleteRequestError(error))
  }
}
