import { COVERS_SCAN_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const coversScanPlanCountRequestStart = (data) => ({
    type: `${COVERS_SCAN_COUNT_REQUEST}${START}`,
    payload: data
});

export const coversScanPlanCountRequestSuccess = (data) => ({
    type: `${COVERS_SCAN_COUNT_REQUEST}${SUCCESS}`,
    payload: data
});

export const coversScanPlanCountRequestError = (data) => ({
    type: `${COVERS_SCAN_COUNT_REQUEST}${ERROR}`,
    payload: data
});
