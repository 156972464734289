
import {ERROR, LIBRARY_TYPES_LIST_REQUEST, START, SUCCESS} from "../../../../actions/action-types";

const initialState = false;

export const libraryTypesLoaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${LIBRARY_TYPES_LIST_REQUEST}${START}`:
            return true;

        case `${LIBRARY_TYPES_LIST_REQUEST}${SUCCESS}`:
            return false;

        case `${LIBRARY_TYPES_LIST_REQUEST}${ERROR}`:
            return false;

        default: return state;
    }
};