import { combineReducers } from 'redux'
import { testsSearchReducer } from './search/testsSearchReducer'
import { testsLoaderReducer } from './loader/testsLoaderReducer'
import { testsListReducer } from './list/testsListReducer'
import { testsCountReducer } from './count/testsCountReducer'

export const testsReducer = combineReducers({
  count: testsCountReducer,
  list: testsListReducer,
  loader: testsLoaderReducer,
  search: testsSearchReducer,
})
