import { call, put, takeLatest } from "redux-saga/effects";
import {
  COLLECTION_DELETE_DATA_REQUEST,
  COLLECTION_GET_DATA_REQUEST,
  COLLECTION_SAVE_DATA_REQUEST,
  START
} from "../../../actions/action-types";
import {
  collectionGetDataRequestError, collectionGetDataRequestStart,
  collectionGetDataRequestSuccess
} from "../../../actions/collections/data/getData";
import { COLLECTION_TYPE } from "../../../../constants/collectionTypes";
import {
  addStaticCollectionData,
  deleteStaticCollectionData,
  getStaticCollectionData
} from "../../../../api/collections/static";
import {
  addDynamicCollectionData, deleteDynamicCollectionData,
  editDynamicCollectionData,
  getDynamicCollectionData
} from "../../../../api/collections/dynamic";
import {
  collectionSaveDataRequestError,
  collectionSaveDataRequestSuccess
} from "../../../actions/collections/data/saveData";
import {
  collectionDeleteDataRequestError,
  collectionDeleteDataRequestSuccess
} from "../../../actions/collections/data/deleteData";

export default function* collectionDataWatcher() {
  yield takeLatest(`${COLLECTION_GET_DATA_REQUEST}${START}`, collectionGetDataWorker);
  yield takeLatest(`${COLLECTION_SAVE_DATA_REQUEST}${START}`, collectionSaveDataWorker);
  yield takeLatest(`${COLLECTION_DELETE_DATA_REQUEST}${START}`, collectionDeleteDataWorker);
}

function* collectionGetDataWorker({ payload: { collectionId, collectionType } }) {
  try {
    const response = yield call(collectionType === COLLECTION_TYPE.STATIC ?
      getStaticCollectionData : getDynamicCollectionData, collectionId);
    yield put(collectionGetDataRequestSuccess(response.data));
  } catch (error) {
    yield put(collectionGetDataRequestError(error))
  }
}

function* collectionSaveDataWorker({ payload: { collectionId, data, collectionType } }) {
  try {
    let response;
    if (collectionType === COLLECTION_TYPE.DYNAMIC)
      response = yield call(data.id !== undefined ? editDynamicCollectionData : addDynamicCollectionData, collectionId, data);
    else
      response = yield call(addStaticCollectionData, collectionId, data);

    yield put(collectionSaveDataRequestSuccess(response.data));
    yield put(collectionGetDataRequestStart(collectionId, collectionType));
  } catch (error) {
    yield put(collectionSaveDataRequestError(error));
  }
}

function* collectionDeleteDataWorker({ payload: { collectionId, dataId, collectionType } }) {
  try {
    const response = yield call(collectionType === COLLECTION_TYPE.DYNAMIC ? deleteDynamicCollectionData : deleteStaticCollectionData,
      collectionId, dataId);
    yield put(collectionDeleteDataRequestSuccess(response.data));
    yield put(collectionGetDataRequestStart(collectionId, collectionType));
  } catch (error) {
    yield put(collectionDeleteDataRequestError(error));
  }
}