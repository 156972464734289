import { SUCCESS, SYSTEM_VERSION_REQUEST } from "../../actions/action-types";

const initialState = null;

export const systemVersionReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${SYSTEM_VERSION_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
