import { CHECK_AUTH_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const checkAuthRequestStart = () => ({
  type: `${CHECK_AUTH_REQUEST}${START}`
});

export const checkAuthRequestSuccess = (data) => ({
  type: `${CHECK_AUTH_REQUEST}${SUCCESS}`,
  payload: data
});

export const checkAuthRequestError = (data) => ({
  type: `${CHECK_AUTH_REQUEST}${ERROR}`,
  payload: data
});