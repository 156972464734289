import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import styles from "../../../scenes/BookDescription/BookDescriptionScene.module.css";
import BookDescriptionImage from "../Image";
import BookDescriptionInfoWrapper from "../InfoWrapper";
import ScanImages from "../../../scenes/BookDescription/Components/ScanImages";
import OpenPhotoModal from "../../OpenPhotoModal";
import {Button, Space} from "antd";
import ReadingListItemFiles from "../../Reading/List/FileList";
import {resolveBookCoverUrl} from "../../../utils/resolvePhotoUrl";
import PopoverOuter from "../../UtulityComponents/Popover";


const getScanImagesUrls = (data, bookId) => {
    const images = [];

    data.subscriptionCover &&
        images.push(resolveBookCoverUrl(data.subscriptionCover, bookId));

    data.subscriptionBackCover &&
        images.push(resolveBookCoverUrl(data.subscriptionBackCover, bookId));

    return images;
};

const BookDescriptionContent = ({data, bookId, isLibrarian}) => {
    const [selectedImage, setSelectedImage] = useState("");
    const [imageModalVisible, setImageModalVisible] = useState(false);

// Шифр
const secr = [
    {
        "id": "1",
        "name": "<Не выбрано>"
    },
    {
        "id": "2",
        "name": "дсп"
    },
    {
        "id": "3",
        "name": "секретно"
    },
    {
        "id": "4",
        "name": "несекретно"
    }
];

  const cutList = (list) => {
    let cutList = '';
    if (list.split(',').length > 2) {
      cutList = list.split(',').slice(1, 3).join('');
      cutList = cutList + ' и др.'
    } else {
      cutList = list;
    }
    return cutList;
  } 

  const getMasCut = (list) => {
    let cutList = '';
    if (list.length > 2) {
      cutList = data?.availableinstances.map((val, index) => index).join(', ').split(', ').slice(1, 3).join(', ');
      cutList = cutList + ' и др.'
    } else if (list.length > 0 && list.length < 3) {
      cutList = data?.availableinstances.map((val, index) => index).join(', ').split(', ').slice(1, 3).join(', ');
    } 
    return cutList; 
  }

  const openDisable = data.archived
  const secrId = secr.find(el => el.id === data?.eerOutSecrecyId)?.name || 'Не выбрано'

    return (
        <Row>
            <Col md={12} className={'d-flex justify-content-between mb-3'}>
                {/*{history &&*/}
                {/*<span className={styles.navButton} onClick={history.goBack}>*/}
                {/*  Назад*/}
                {/*</span>*/}
                {/*}*/}
                <div className={styles.title}>{data.title}</div>
                <Space>
                    {isLibrarian &&
                    <a href={`/book/${bookId}/edit`}>
                        <Button>
                            Редактировать
                        </Button>
                    </a>
                    }
                    {data?.pdfFile?.length || data?.files?.length ?
                        <PopoverOuter
                            trigger={'click'}
                            title="Файлы книги"
                            placement="bottomRight"
                            disabled={openDisable}
                            content={
                                <ReadingListItemFiles fileList={data.files} bookId={data.bookid}/>
                            }
                        >
                            <Button disabled={openDisable}>Открыть</Button>
                        </PopoverOuter>
                        :
                        <Button disabled>Нет файлов</Button>
                    }
                </Space>
            </Col>
            <Col md={5}>
                <BookDescriptionImage
                    url={data.coverImage}
                    bookId={data.bookid}
                    alt={data.title}
                />
            </Col>
            <Col md={7}>
                <div className={styles.topInfoContainer}>
                    <BookDescriptionInfoWrapper
                        value={data.authors.map(item => item.name).join(", ")}
                        label={"Авторы"}
                    />
                    <BookDescriptionInfoWrapper
                        value={data.publishers.map(item => item.name).join(", ")}
                        label={"Издательство"}
                    />
                    <BookDescriptionInfoWrapper
                        value={data.seriesname || "Нет"}
                        label={"Серия"}/>
                    <BookDescriptionInfoWrapper
                        value={data.litername || "Нет"}
                        label={"Литера"}/>
                    <BookDescriptionInfoWrapper
                        value={data.year || "n/a"}
                        label={"Год"}
                    />
                    <BookDescriptionInfoWrapper
                        value={(data.disciplinename == null || data.disciplinename == "") ? "Нет" : data.disciplinename}
                        label={"Дисциплина (модуль)"}/>
                    <BookDescriptionInfoWrapper
                        value={data.isbn.join(",")}
                        label={"ISBN"}
                    />
                    <BookDescriptionInfoWrapper
                        value={data.pageNum}
                        label={"Кол-во страниц"}
                    />
                    <BookDescriptionInfoWrapper
                        value={secrId}
                        label={"Гриф"}
                    />
                    <BookDescriptionInfoWrapper
                        value={data?.number ? cutList(data?.number) : ''}
                        label={"Инвентарные номера"}
                    />
                    <BookDescriptionInfoWrapper
                        value={data?.availableinstances ? getMasCut(data?.availableinstances) : ''}
                        label={"Номера экземпляров"}
                    />
                    {data.annotation &&
                    <Col md={12}>
                        <div className={styles.descLabel}>Аннотация:</div>
                        <div className={styles.description}>{data.annotation}</div>
                    </Col>
                    }
                </div>
                {data.verifyStatus === 5 && data.removalData && (
                    <Col md={12}>
                        <div className={styles.seized}>
                            <h3>Книга изъята</h3>
                            <p>
                                <span>Номер документа: </span>
                                {data.removalData.documentnumber}
                            </p>
                            <p>
                                <span>Дата документа: </span>
                                {new Date(
                                    data.removalData.documentdate
                                ).toLocaleDateString()}
                            </p>
                            <p>
                                <span>ФИО: </span>
                                {data.removalData.documentcreator}
                            </p>
                        </div>
                    </Col>
                )}
            </Col>


            {(!!data.subscriptionCover || !!data.subscriptionBackCover) && (
                <Col md={12}>
                    <Row className={styles.scanImagesContainer}>
                        <ScanImages
                            images={getScanImagesUrls(data, bookId)}
                            onClick={image => {
                                setImageModalVisible(true);
                                setSelectedImage(image);
                            }}
                        />
                        <OpenPhotoModal
                            visible={imageModalVisible}
                            onClose={() => setImageModalVisible(false)}
                            src={selectedImage}
                        />
                    </Row>
                </Col>
            )}
        </Row>
    );
};

BookDescriptionContent.propTypes = {
    data: PropTypes.object,
    isLibrarian: PropTypes.bool,
    bookId: PropTypes.string,
};

export default BookDescriptionContent;
