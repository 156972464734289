import { api } from "../../App";
import {
  GET_MENU_LIST,
  START
} from "../actions/action-types";
import {
  put,
  takeLatest
} from "redux-saga/effects";
import { setMenuList } from "../actions/menuList/menuList";

export function* menuListWatcher() {
  yield takeLatest(`${GET_MENU_LIST}${START}`, menuListW)
}

function* menuListW() {
  try {
    const response = yield api.get(`/menu_settings`).then(data => data);
    yield put(setMenuList(response.data))
  } catch (e) {
    console.log(e)
  }
}