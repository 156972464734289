import React from 'react'
import T from 'prop-types'
import { ADD, EDIT } from '../../../constants/modalTypes'
import styles from './DocumentsTable.module.css'
import { Button, Space } from 'antd'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import { DOCUMENT_SORT } from '../../../constants/sort/documentSort'
import moment from 'moment'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'

const DocumentsTable = ({
  data,
  setModal,
  sortBy,
  setSort,
  selectedIds,
  handleSelect,
  onDelete,
  initialModal,
  loader,
  paginationComponent,
  setCurrentDocument,
}) => {
  const openModal = item => {
    setModal({
      modalType: EDIT,
      data: { ...item, document_arrival_date: moment(item.document_arrival_date).format('YYYY-MM-DD') },
    })
    setCurrentDocument(item)
  }

  const openCreationModal = () => {
    setModal({ modalType: ADD, data: initialModal })
    setCurrentDocument(initialModal)
  }

  const getFormattedDAte = (date) => date ? moment(date).format('DD.MM.YYYY') : null

  return (
    <div className={styles.container}>
      <div className='mb-3 d-flex'>
        <Space>
          <Button type='primary' onClick={openCreationModal}>
            добавить
          </Button>
          <Button type='primary' onClick={onDelete} danger>
            удалить
          </Button>
        </Space>
      </div>

      {paginationComponent}

      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openModal} id='documentsTable'>
          <DataTableCol title='Вид документа' value='type' colSpan={3} />
          <DataTableCol sortParams={[DOCUMENT_SORT.NUMBER]} title='Номер документа' value='documentnumber' colSpan={2} />
          <DataTableCol
            sortParams={[DOCUMENT_SORT.SUM]}
            title='Сумма документа'
            resolver={({ sum }) => (sum ? sum : 'n/a')}
            colSpan={2}
          />
          <DataTableCol
            sortParams={[DOCUMENT_SORT.DATE]}
            title='Дата документа'
            resolver={({ document_arrival_date }) => getFormattedDAte(document_arrival_date)}
            colSpan={2}
          />
          <DataTableCol
            sortParams={[DOCUMENT_SORT.DATE]}
            title='Дата записи КСУ'
            resolver={({ documentdate }) => getFormattedDAte(documentdate)}
            colSpan={2}
          />
          <DataTableCol
            title='Выбрано'
            colSpan={1}
            passClicks
            resolver={item => <input type='checkbox' checked={selectedIds.includes(item.id)} onChange={() => handleSelect(item.id)} />}
          />
        </DataTable>
      )}
    </div>
  )
}

DocumentsTable.propTypes = {
  data: T.array,
  setModal: T.func,
  sortBy: T.string,
  setSort: T.func,
  selectedId: T.any,
  handleSelect: T.func,
  onDelete: T.func,
}

DocumentsTable.defaultProps = {
  data: [],
}

export default DocumentsTable
