import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { api } from '../../App'
// import ElectronicCoursesForm from '../../components/ElectronicCourses/Form'
import { RecommendationModal } from '../../components/ElectronicCourses/Modal/RecommendationModal'
import ElectronicCoursesSearchForm from '../../components/ElectronicCourses/SearchForm'
import ElectronicCoursesTable from '../../components/ElectronicCourses/Table'
import ElectronicCoursesPDFPrint from '../../components/ElectronicCourses/PDFPrint'
import { createNotification } from '../../components/notification/notification'
import Pagination from '../../components/Pagination'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { setSecurityLogFormData } from '../../redux/actions/adminLogs/securityLog/formData'
import { setSecurityLogSearchData } from '../../redux/actions/adminLogs/securityLog/searchData'
import { electronicCoursesCountRequestStart } from '../../redux/actions/electronicCourses/count'
import { electronicCoursesItemDeleteStart } from '../../redux/actions/electronicCourses/delete'
import { electronicCoursesItemEditStart } from '../../redux/actions/electronicCourses/edit'
import { electronicCoursesFileAddStart } from '../../redux/actions/electronicCourses/file'
import { electronicCoursesItemSaveStart } from '../../redux/actions/electronicCourses/save'
import { TestsModal } from '../../components/ElectronicCourses/Modal/TestsModal'

const mapStateToProps = ({ electronicCourses, auth }) => ({
  list: electronicCourses.list,
  searchData: electronicCourses.searchData,
  count: electronicCourses.count,
  authData: auth.data,
  loader: electronicCourses.loader,
})

const mapDispatchToProps = {
  requestList: electronicCoursesCountRequestStart,
  setFormData: setSecurityLogFormData,
  setSearchData: setSecurityLogSearchData,
  deleteItem: electronicCoursesItemDeleteStart,
  addItem: electronicCoursesItemSaveStart,
  addFile: electronicCoursesFileAddStart,
  editItem: electronicCoursesItemEditStart,
}

const ElectronicCourses = ({
  count,
  list,
  requestList,
  searchData,
  setSearchData,
  deleteItem,
  addItem,
  addFile,
  authData,
  loader,
  editItem,
}) => {
  const [selectedId, setSelectedId] = useState(null)
  const [modalData, setModalData] = useState({
    modalType: null,
    data: null,
  })
  const [users, setUsers] = useState([])

  useEffect(() => {
    requestList()
  }, [requestList])

  useEffect(() => {
    const params = {
      search: '',
      orderBy: 'lastname',
    }

    api.get('/people', { params }).then(res => {
      setUsers(userDataResolver(res.data))
    })
  }, [])

  const userDataResolver = data =>
    data.map(item => ({
      label: `${item.lastname} ${item.firstname} ${item.patronymic}`,
      value: item.mid,
    }))

  const handleSelect = id => setSelectedId(selectedId === id ? null : id)

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const setSort = (sortBy, sortDirection) => {
    setSearchData({
      ...searchData,
      sortBy,
      page: 1,
      sortDirection: sortDirection,
    })
    requestList()
  }

  const onItemDelete = () => {
    if (selectedId) {
      deleteItem(selectedId)
    } else {
      createNotification('error', 'Ошибка удаления, курс не выбран')
    }
  }

  return (
    <SceneContainer title='Прохождение электронных курсов'>
      {modalData.modalType === 'recommendation' && <RecommendationModal modalData={modalData} setModalData={setModalData} />}
      {modalData.modalType === 'tests' && <TestsModal modalData={modalData} setModalData={setModalData} />}
      {/* <ElectronicCoursesForm users={users} data={searchData} setData={setSearchData} onSubmit={requestList} /> */}

      <ElectronicCoursesSearchForm data={searchData} setData={setSearchData} onSubmit={requestList} authData={authData} />
      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />

      <div className={'d-flex justify-content-end'}>
        <ElectronicCoursesPDFPrint fromDate={searchData.from} toDate={searchData.to} />
      </div>

      {loader ? (
        <SpinnerOuter />
      ) : (
        <ElectronicCoursesTable
          isLibrarian={authData.islibrarian}
          addFile={addFile}
          addItem={addItem}
          onItemDelete={onItemDelete}
          selectedId={selectedId}
          handleSelect={handleSelect}
          data={list}
          sortBy={searchData.sortBy}
          setSort={setSort}
          modalData={modalData}
          setModalData={setModalData}
        />
      )}
      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicCourses)
