export const createHeaders = (headers) => headers.map(header => header.title);


export const createAutotableConfig = (headers, table, showHead) => ({
    head: [headers],
    body: table,
    styles: {font: "Montserrat", fontSize: 10},
    margin: {
        top: 25
    },
    showHead: showHead ? showHead : 'everyPage',
    headStyles: {
        halign: "center",
        valign: "middle",
        lineWidth: 0.25,
        lineColor: 200
    },
    bodyStyles: {
        halign: "center",
        lineWidth: 0.25,
        lineColor: 200
    },
});