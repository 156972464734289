import { SET_AUDIT_LOG_FORM_DATA } from '../../../actions/action-types'
import moment from 'moment'

const initialState = {
  from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  to: moment(new Date()).format('YYYY-MM-DD'),
  user: '',
}

export const auditLogFormDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIT_LOG_FORM_DATA:
      return action.payload

    default:
      return state
  }
}
