import {BIBLIOGRAPHY_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const BibliographyListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${BIBLIOGRAPHY_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};
