import { combineReducers } from "redux";
import { fundMovingListReducer } from "./list/fundMovingListReducer";
import { fundMovingSearchReducer } from './search/search';
import { fundMovingLoaderReducer } from './loader/fundMovingLoaderReducer';

export const fundMovingReducer = combineReducers({
  list: fundMovingListReducer,
  loader: fundMovingLoaderReducer,
  search: fundMovingSearchReducer,
});

