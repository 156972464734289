import { ERROR, LIBRARY_DELETE_REQUEST, START, SUCCESS } from "../../action-types";

export const libraryDeleteRequestStart = (data) => ({
  type: `${LIBRARY_DELETE_REQUEST}${START}`,
  payload: data
});

export const libraryDeleteRequestSuccess = (data) => ({
  type: `${LIBRARY_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryDeleteRequestError = (data) => ({
  type: `${LIBRARY_DELETE_REQUEST}${ERROR}`,
  payload: data
});