import { call, put, takeLatest } from "redux-saga/effects";
import {
  EXTERNAL_RESOURCES_DELETE_REQUEST,
  EXTERNAL_RESOURCES_FULL_REQUEST,
  EXTERNAL_RESOURCES_SAVE_REQUEST,
  START
} from "../../../actions/action-types";
import {
  addExternalResource, deleteExternalResource,
  editExternalResource,
  getExternalResourcesFullList
} from "../../../../api/externalResources/edit";
import {
  externalResourcesFullListRequestError, externalResourcesFullListRequestStart,
  externalResourcesFullListRequestSuccess
} from "../../../actions/externalResources/fullList";
import {
  externalResourcesSaveRequestError,
  externalResourcesSaveRequestSuccess
} from "../../../actions/externalResources/save";
import {
  externalResourcesDeleteRequestError,
  externalResourcesDeleteRequestSuccess
} from "../../../actions/externalResources/delete";
import { setExternalResourcesSaveStatus } from "../../../actions/externalResources/status";
import { SAVE_STATUS } from "../../../../constants/saveStatus";

export default function* externalResourcesEditWatcher() {
  yield takeLatest(`${EXTERNAL_RESOURCES_FULL_REQUEST}${START}`, externalResourcesFullListWorker);
  yield takeLatest(`${EXTERNAL_RESOURCES_SAVE_REQUEST}${START}`, externalResourcesSaveWorker);
  yield takeLatest(`${EXTERNAL_RESOURCES_DELETE_REQUEST}${START}`, externalResourcesDeleteWorker);
}

function* externalResourcesFullListWorker() {
  try {
    const response = yield call(getExternalResourcesFullList);
    yield put(externalResourcesFullListRequestSuccess(response.data));
  } catch (error) {
    yield put(externalResourcesFullListRequestError(error));
  }
}

function* externalResourcesSaveWorker({ payload: data }) {
  try {
    const response = yield call(data.id ? editExternalResource : addExternalResource, data);
    yield put(externalResourcesSaveRequestSuccess(response.data));
    yield put(setExternalResourcesSaveStatus(SAVE_STATUS.SUCCESS));
    yield put(externalResourcesFullListRequestStart());
  } catch (error) {
    yield put(externalResourcesSaveRequestError(error));
    yield put(setExternalResourcesSaveStatus(SAVE_STATUS.ERROR));
  }
}

function* externalResourcesDeleteWorker({ payload: id }) {
  try {
    const response = yield call(deleteExternalResource, id);
    yield put(externalResourcesDeleteRequestSuccess(response.data));
    yield put(externalResourcesFullListRequestStart());
  } catch (error) {
    yield put(externalResourcesDeleteRequestError(error));
  }
}
