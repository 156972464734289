import {all} from "@redux-saga/core/effects";
import loadDisciplineSaga from "./load/loadDisciplineSaga";
import deleteDisciplineSaga from "./delete/deleteDisciplineSaga";
import saveDisciplineSaga from "./save/saveDisciplineSaga";

export default function* disciplineSaga() {
    yield all([
        loadDisciplineSaga(),
        deleteDisciplineSaga(),
        saveDisciplineSaga()
    ])
}