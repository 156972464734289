import React, { useState } from 'react';
import T from 'prop-types';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from "./AdminPermissionsSearchForm.module.css";
import Button from "../../Button";
import Select from "react-select";
import {rolesToOptions} from "../../../utils/toSelectOption";
import { accessIsSetByUserStatusList } from '../../../constants/adminCategoriesPermissions/accessIsSetByUserStatusList';


const PermissionsSearchForm = ({ roles, formData, setFormData, onSubmit }) => {

    const roleSelectOptions = [{label: 'Все роли', value: ""}, ...rolesToOptions(roles)];

    return (
        <div className={styles.container}>
            <Row>
                <Col md={6} xl={4}>
                    <ModalInputWrapper label={"Роль"}>
                        <Select
                            defaultValue={roleSelectOptions[0]}
                            placeholder={'Выберите роль'}
                            onChange={value => setFormData({ ...formData, roleid: value.value })}
                            options={roleSelectOptions}
                        />
                    </ModalInputWrapper>
                </Col>
                <Col md={6} xl={4}>
                    <ModalInputWrapper label={"Фильтр"}>
                        <Select
                            defaultValue={formData.set}
                            placeholder={'Выберите значение фильтрации'}
                            onChange={value => setFormData({ ...formData, set: value.value })}
                            options={accessIsSetByUserStatusList}
                        />
                    </ModalInputWrapper>
                </Col>
                <Col md={2} className={'d-flex align-items-center'}>
                    <Button label={"Найти"} onClick={onSubmit}/>
                </Col>
            </Row>
        </div>
    );
};

PermissionsSearchForm.propTypes = {
    roles: T.array.isRequired,
    formData: T.object.isRequired,
    setFormData: T.func.isRequired,
    onSubmit: T.func.isRequired,
};

export default PermissionsSearchForm;