import { SORT_DIRECTION } from '../../constants/sortDirections'
import { api } from '../../App'

export const getAuditLogList = ({ sortBy, maxResults, paginateFrom, sortDirection }, search) => {
  const params = {
    maxResults,
    paginateFrom,
    ...search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
  }

  return api.get('/admin/logs/audit', { params })
}

export const getAuditLogCount = search => {
  const params = {
    count: true,
    ...search,
  }

  return api.get('/admin/logs/audit', { params })
}

export const getAuditDatasets = () => {
  return api.get('/admin/logs/auditset')
}
