import React from 'react'
import T from 'prop-types'
import LibraryMaterialsListItem from './ListItem'

const LibraryMaterialsList = ({ data }) => {
  return data.map((item, index) => <LibraryMaterialsListItem data={item} key={index} />)
}

LibraryMaterialsList.propTypes = {
  data: T.array,
}

export default LibraryMaterialsList
