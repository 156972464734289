import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  setBookScanPlanSearchData,
  bookScanPlanActions,
  setBookScanPlanFormData,
  bookScanOrderEditRequestStart,
} from '../../redux/factory/bookScanPlanReduxData'
import Pagination from '../../components/Pagination'
import List from '../../components/BookScanPlan/List'
import { cleanBookCover, saveBookCover, savePdf } from '../../redux/actions/bookData/data'
import SearchForm from '../../components/BookScanPlan/SearchForm'
import Modal from '../../components/BookScanPlan/Modal'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import { api } from '../../App'
import { Button, Space } from 'antd'

const mapStateToProps = ({ bookScanPlan, options }) => ({
  searchData: bookScanPlan.search,
  formData: bookScanPlan.form,
  count: bookScanPlan.count,
  list: bookScanPlan.list,
  options: options.data,
})

const matchDispatchToProps = {
  uploadPdf: savePdf,
  setSearchData: setBookScanPlanSearchData,
  setFormData: setBookScanPlanFormData,
  requestList: bookScanPlanActions.countRequestStart,
  editBookScanOrder: bookScanOrderEditRequestStart,
  uploadBookCover: saveBookCover,
  cleanBookCover: cleanBookCover,
}

export const STATUS_LIST = new Map([
  [1, 'новый'],
  [2, 'в работе'],
  [3, 'завершено'],
  [4, 'отменено'],
])

const BookScanPlan = ({
  searchData,
  setSearchData,
  count,
  requestList,
  list,
  uploadPdf,
  formData,
  setFormData,
  editBookScanOrder,
  uploadBookCover,
  cleanBookCover,
  options,
}) => {
  const [modalData, setModalData] = useState(null);
  const [dataXml, setDataXml] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const handleSelectData = data => setSelectedData([...selectedData, data]);

  const dueCol = useRef(null);

  const onUploadXML = () => {
    api
      .get('/bookscanorderxml')
      .then(res => {
        setDataXml(res)
      })
      .catch(err => console.log(err))
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const openModal = item => {
    setModalData(item)
  }

  const closeModal = () => {
    setModalData(null)
  }

  const editDataModal = () => {
    const params = {
      orderId: modalData.id,
      status: Number(modalData.status),
      duedate: modalData.duedate,
      reason: modalData.reason,
    }

    editBookScanOrder(params)
  }

  const onUploadBookCover = ({ file, bookId }) => {
    uploadBookCover({ file, bookId })
    requestList()
    setModalData({ ...modalData, coverImage: file.name })
  }

  const onCleanBookCover = ({ bookId }) => {
    cleanBookCover({ bookId })
    requestList()
    setModalData({ ...modalData, coverImage: null })
  }

  const setSort = sortBy => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC

    setSearchData({ ...searchData, sortDirection: sortDir, sortBy, page: 1 })
    requestList()
  }

  useEffect(() => {
    onUploadXML()
  }, [])

  const handleGet = () => {
    api
      .get(`/bookscanorder?${formData?.title ? `title=${formData?.title}&`: ''}orderBy=documentname&maxResults=2000&paginateFrom=0&from=${formData?.from}&to=${formData?.to}${Number(formData?.status) == 0 ? '' : `&status=${Number(formData?.status)}`}`)
      .then(res => {
        setSelectedData(res.data)
      })
      .catch(err => console.log(err))
  }

  return (
    <SceneContainer title={'План оцифровки бумажных носителей'}>
      <SearchForm
        data={dataXml}
        formData={formData}
        setFormData={setFormData}
        onSubmit={requestList}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />

      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
      <Space>
        <Button type='primary' onClick={handleGet}>
          Выбрать все
        </Button>
        <Button type='primary' onClick={() => setSelectedData([])}>
          Снять все
        </Button>
      </Space>
      {!!list.length && (
        <div style={{width: '100%', overflow: 'auto'}}>
        <List
          selectedData={selectedData}
          data={list}
          sortBy={searchData.sortBy}
          setSort={setSort}
          uploadPdf={uploadPdf}
          openModal={openModal}
          setModalData={setModalData}
          modalData={modalData}
          handleSelectData={handleSelectData}
        />
        </div>
      )}
      <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />

      {modalData && (
          <Modal
          data={modalData}
          setData={setModalData}
          setClosed={closeModal}
          editStatus={editDataModal}
          cleanBookCover={onCleanBookCover}
          onUploadBookCover={onUploadBookCover}
          options={options}
        />
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, matchDispatchToProps)(BookScanPlan)
