import React, { useEffect, useState, useMemo } from 'react'
import { libraryTypesListRequestStart } from '../../../redux/actions/library/types/list'
import { libraryTypeSaveRequestStart } from '../../../redux/actions/library/types/save'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import LibraryTypesTable from '../../../components/LibraryTypes/Table'
import LibraryTypesTree from '../../../components/LibraryTypes/Tree'
import LibraryTypesModal from '../../../components/LibraryTypes/Modal'
import { libraryTypeDeleteRequestStart } from '../../../redux/actions/library/types/delete'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import 'antd/dist/antd.css';
import { Tree } from 'antd';


const mapStateToProps = ({ library: { types } }) => ({
  list: types.list,
  loader: types.loader,
})

const mapDispatchToProps = {
  requestTypes: libraryTypesListRequestStart,
  saveType: libraryTypeSaveRequestStart,
  deleteType: libraryTypeDeleteRequestStart,
}

const MODAL_FORM_FIELDS = {
  name: '',
  mimetype: '',
  duedate: '',
}

const LibraryTypesScene = ({ list, requestTypes, saveType, deleteType, loader }) => {
  useEffect(() => {
    requestTypes()
  }, [requestTypes])

  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedId, setSelectedId] = useState(null)

  const onSubmitModal = values => {
    saveType({ ...modalData, data: { ...modalData.data, ...values } })
    setModalData({ modalType: null, data: null })
  }

  const handleSelect = id => setSelectedId(selectedId === id ? null : id)

  const onDelete = () => selectedId && deleteType(selectedId)

  const closeModal = () => {
    setModalData({ modalType: null, data: null })
  }

  return (
    <SceneContainer title='Справочники / Типы обрабатываемой литературы'>
      {modalData.data && (
        <LibraryTypesModal onSubmit={onSubmitModal} modalData={modalData} setModalData={setModalData} closeModal={closeModal} />
      )}
      {loader ? (
        <SpinnerOuter isLoading />
      ) : (
        
        <LibraryTypesTree
          data={list}
          setModal={setModalData}
          selectedId={selectedId}
          handleSelect={handleSelect}
          onDelete={onDelete}
          initialFormFields={MODAL_FORM_FIELDS}
        />  
      )}

    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryTypesScene)
