import { SET_ALPHABET_SEARCH_DATA } from "../../../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../../../constants/pagination";

const initialState = {
  page: 1,
  author: "",
  title: "",
  letters: "",
  emptyCards: false,

  pageCount: 1,
  maxResults: RESULTS_PER_PAGE
};

export const alphabetSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_ALPHABET_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};