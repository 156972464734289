import {ERROR, PDF_VIEWER_BOOK_REQUEST, START} from "../../../actions/action-types";

const initialState = null;

export const pdfViewerErrorReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${PDF_VIEWER_BOOK_REQUEST}${ERROR}`:
            return action.payload;

        case `${PDF_VIEWER_BOOK_REQUEST}${START}`:
            return null;

        default:
            return state;
    }
};