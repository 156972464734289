import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  ELECTRONIC_COURSES_COUNT_REQUEST,
  ELECTRONIC_COURSES_ITEM_DELETE,
  ELECTRONIC_COURSES_ITEM_EDIT,
  ELECTRONIC_COURSES_ITEM_FILE_ADD,
  ELECTRONIC_COURSES_ITEM_SAVE,
  ELECTRONIC_COURSES_LIST_REQUEST,
  START,
} from '../../actions/action-types'
import {
  electronicCoursesCountRequestError,
  electronicCoursesCountRequestStart,
  electronicCoursesCountRequestSuccess,
} from '../../actions/electronicCourses/count'

import {
  electronicCoursesListRequestError,
  electronicCoursesListRequestStart,
  electronicCoursesListRequestSuccess,
} from '../../actions/electronicCourses/list'
import {
  createItem,
  deleteCourse,
  editItem,
  getCount,
  getCountLibrarian,
  getList,
  getListLibrarian,
  uploadCourseFile,
} from '../../../api/electronicCourses/api'
import { electronicCoursesItemDeleteError, electronicCoursesItemDeleteSuccess } from '../../actions/electronicCourses/delete'
import { setElectronicCoursesSearchData } from '../../actions/electronicCourses/searchData'
import { electronicCoursesItemSaveError, electronicCoursesItemSaveSuccess } from '../../actions/electronicCourses/save'
import { electronicCoursesFileAddError, electronicCoursesFileAddSuccess } from '../../actions/electronicCourses/file'
import { electronicCoursesItemEditError, electronicCoursesItemEditSuccess } from '../../actions/electronicCourses/edit'

export default function* courseWatcher() {
  yield takeLatest(`${ELECTRONIC_COURSES_COUNT_REQUEST}${START}`, courseCountWorker)
  yield takeLatest(`${ELECTRONIC_COURSES_LIST_REQUEST}${START}`, courseListWorker)
  yield takeLatest(`${ELECTRONIC_COURSES_ITEM_DELETE}${START}`, courseDeleteWorker)
  yield takeLatest(`${ELECTRONIC_COURSES_ITEM_SAVE}${START}`, courseAddWorker)
  yield takeLatest(`${ELECTRONIC_COURSES_ITEM_EDIT}${START}`, courseEditWorker)
  yield takeLatest(`${ELECTRONIC_COURSES_ITEM_FILE_ADD}${START}`, courseFileAddWorker)
}

function* courseCountWorker() {
  const searchData = yield select(({ electronicCourses }) => electronicCourses.searchData)
  const isLibrarian = yield select(({ auth }) => auth.data.islibrarian)
  try {
    const response = yield call(isLibrarian ? getCountLibrarian : getCount, searchData)
    const count = parseInt(response.data.count, 10)
    yield put(electronicCoursesCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setElectronicCoursesSearchData({ ...searchData, pageCount }))
    yield put(electronicCoursesListRequestStart())
  } catch (error) {
    yield put(electronicCoursesCountRequestError(error))
  }
}

function* courseListWorker() {
  const searchData = yield select(({ electronicCourses }) => electronicCourses.searchData)
  const isLibrarian = yield select(({ auth }) => auth.data.islibrarian)
  try {
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
    const response = yield call(isLibrarian ? getListLibrarian : getList, searchData)
    yield put(electronicCoursesListRequestSuccess(response.data))
  } catch (error) {
    yield put(electronicCoursesListRequestError(error))
  }
}

function* courseAddWorker({ payload: data, callback }) {
  try {
    const response = yield call(createItem, data)
    yield put(electronicCoursesItemSaveSuccess(response.data))
    yield call(callback)
    yield put(electronicCoursesCountRequestStart())
  } catch (error) {
    yield put(electronicCoursesItemSaveError(error))
  }
}

function* courseDeleteWorker({ payload: id }) {
  try {
    const response = yield call(deleteCourse, id)
    yield put(electronicCoursesItemDeleteSuccess(response.data))
    yield put(electronicCoursesCountRequestStart())
  } catch (error) {
    yield put(electronicCoursesItemDeleteError(error))
  }
}

function* courseEditWorker({ payload: { data, id, callback } }) {
  try {
    yield call(editItem, data, id)
    yield put(electronicCoursesItemEditSuccess())
    yield call(callback)
    yield put(electronicCoursesCountRequestStart())
  } catch (error) {
    yield put(electronicCoursesItemEditError(error))
  }
}

function* courseFileAddWorker({ payload: data }) {
  try {
    const response = yield call(uploadCourseFile, data)
    yield put(electronicCoursesFileAddSuccess(response.data))
    yield put(electronicCoursesCountRequestStart())
  } catch (error) {
    yield put(electronicCoursesFileAddError(error))
  }
}
