import React from 'react'
import T from 'prop-types'
import classNames from 'classnames'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import { resolveDateWithTimeZone } from '../../../utils/Dates/resolveDateWithTimeZone'
import styles from './BorrowingTestTable.module.css'
import { ANTI_PLAGIAT_STATUS_LIST } from '../../../scenes/BorrowingTest'
import { ICONS } from '../../../utils/projectIcons'

const BorrowingTestTable = ({ saveFile, data, deleteItem, isEmpty, openEditModal }) => {
  return (
    <div className={'mt-3 mb-3'}>
      {isEmpty ? (
        <div className='emptyNotify'>Ничего не найдено</div>
      ) : (
        <DataTable data={data} onCellClick={item => openEditModal(item)}>
          <DataTableCol title='Дата создания' resolver={item => new Date(item.createdts).toLocaleString()} />
          <DataTableCol title='Файл' value='requestfilename' />
          <DataTableCol title='Комментарий' value='comment' />
          <DataTableCol
            title='Последнее обновление'
            resolver={item => {
              const date = item.lastupdatets ? item.lastupdatets : item.createdts
              return resolveDateWithTimeZone(new Date(date).toLocaleString())
            }}
          />
          <DataTableCol
            title='Статус'
            resolver={item => (
              <span className={classNames(styles.status, styles[`status${item.status}`])}>
                {ANTI_PLAGIAT_STATUS_LIST.get(item.status)}
              </span>
            )}
          />
          <DataTableCol
            title='Дата ответа'
            resolver={item => (item.replyts ? resolveDateWithTimeZone(new Date(item.replyts).toLocaleString()) : '-')}
          />

          <DataTableCol
            title=''
            passClicks={true}
            resolver={item => (
              <div className='d-flex'>
                <button title='Удалить' className={classNames('bg_red', styles.iconButton)} onClick={() => deleteItem(item.id)}>
                  <img src={ICONS.TRASH} alt='' />
                </button>
                {item.status === 3 && (
                  <button
                    title='Скачать отчет'
                    className={classNames('bg_accent', styles.iconButton)}
                    onClick={() => saveFile(item.id)}
                  >
                    <img src={ICONS.DOWNLOAD} alt='' />
                  </button>
                )}
                {item.reportlink && (
                  <a
                    title='Отчет антиплагиата'
                    href={item.reportlink}
                    className={classNames('bg_accent', styles.iconButton)}
                    target={'_blank'}
                  >
                    <img src={ICONS.LINK_ARROW} alt='' />
                  </a>
                )}
              </div>
            )}
          />
        </DataTable>
      )}
    </div>
  )
}

BorrowingTestTable.propTypes = {
  data: T.array,
  deleteItem: T.func,
  isEmpty: T.bool,
  openEditModal: T.func,
}

BorrowingTestTable.defaultProps = {
  data: [],
}

export default BorrowingTestTable
