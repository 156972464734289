import { SET_CATEGORIES_SEARCH_DATA } from "../../../actions/action-types";

const initialState = {
  query: ""
};

export const categoriesSearchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES_SEARCH_DATA:
      return { ...action.payload };

    default:
      return state;
  }
};
