import React, { useEffect, useState } from 'react'
import { performanceActions, requestAction } from '../../redux/factory/perfomanceReduxData'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import styles from './Perfomance.module.css'
import { Table, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const mapStateToProps = ({ performances, auth, performance }) => ({
  list: performances,
  loader: performances.loader,
  authDataMid: auth.data.mid,
  modalData: performances.modal,
  term: performances.term,
  progressDetail: performance.progressDetail,
  course: performance.course,
})

const matchDispatchToProps = {
  loadList: performanceActions.loadRequestStart,
  dataToState: requestAction,
  startRequest: performanceActions.requestRequestStart,
  filesRequest: performanceActions.filesRequestStart,
}

const Perfomance = ({
  loader,
  list,
  loadList,
  dataToState,
  startRequest,
  authDataMid,
  filesRequest,
  modalData,
  term,
  progressDetail,
  course,
}) => {
  const [selectedCurs, setSelectedCurs] = useState(null)
  const params = useParams()

  useEffect(() => {
    const { semesterId, courseId } = params
    loadList({ userId: authDataMid, semesterId, courseId })
  }, [])
  return (
    <SceneContainer title={'УСПЕВАЕМОСТЬ'}>
      {loader && <SpinnerOuter isLoading={loader} />}
      <div className={styles.description}>
        <p className={styles.nameLessons}>
          {course && course.course[0].title} <span>I семестр 2017-2018 учебного года</span>
        </p>
        <p>
          Преподаватель: <span className={styles.prepod}>Самсонов Игорь Александрович</span>
        </p>
        <p>
          Проведено уроков:
          <span className={styles.prepod}> 42</span>
        </p>
      </div>
      <Table style={{ cursor: 'pointer' }} className={styles.table}>
        <thead>
          <tr>
            <th className={styles.title}>Дата</th>
            <th className={styles.title}>
              Тип <br />
              занятия
            </th>
            <th className={styles.title}>Тема занятия</th>
            <th className={styles.title}>Домашнее задание</th>
            <th className={styles.title}>Оценки</th>
            <th className={styles.title}>Комментарии</th>
          </tr>
        </thead>
        <tbody>
          {progressDetail?.progressDetail?.map((detail, i) => {
            return (
              <tr>
                <td>
                  <Container>{detail.lessonfulldate_txt}</Container>
                </td>
                <td>
                  <Container>{detail.lessontype}</Container>
                </td>
                <td>
                  <Container> {detail.otitle}</Container>
                </td>
                <td>
                  <Container> {detail.homeworktext}</Container>
                </td>
                <td>
                  <Container> {detail.grade}</Container>
                </td>
                <td>
                  <Container> {detail.gradecomment}</Container>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </SceneContainer>
  )
}

export default connect(mapStateToProps, matchDispatchToProps)(Perfomance)
