import { all } from 'redux-saga/effects';
import loadAuthorsSaga from "./load/loadAuthorsSaga";
import saveAuthorSaga from "./save/saveAuthorSaga";
import authorLoadImageSaga from "./image/authorLoadImageSaga";
import deleteAuthorSaga from "./delete/deleteAuthorSaga";
import authorSaveImageSaga from "./image/authorSaveImageSaga";
import authorDeleteImageSaga from "./image/authorDeleteImageSaga";

export default function* authorsSaga() {
  yield all([
    loadAuthorsSaga(),
    saveAuthorSaga(),
    deleteAuthorSaga(),
    authorLoadImageSaga(),
    authorSaveImageSaga(),
    authorDeleteImageSaga()
  ]);
}