import {DOCUMENTS, LIST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const documentListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${DOCUMENTS}${LIST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
