import { BBK_LIST_COLLAPSE, BBK_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const bbkListRequestStart = (id, search, rewrite) => ({
  type: `${BBK_LIST_REQUEST}${START}`,
  payload: {
    id, search, rewrite
  }
});

export const bbkListRequestSuccess = (data) => ({
  type: `${BBK_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const bbkListRequestError = (data) => ({
  type: `${BBK_LIST_REQUEST}${ERROR}`,
  payload: data
});

export const bbkListCollapse = (id) => ({
  type: BBK_LIST_COLLAPSE,
  payload: id
});
