import { PRICE_COEFF_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const priceCoeffReducer = (state = initialState, action) => {
  switch (action.type) {

      case `${PRICE_COEFF_REQUEST}${SUCCESS}`:
          return action.payload;

      default:
          return state;
  }
};