import React from "react";
import "./BookSupplyStandartsScene.modele.css";
import BookSupplyStandartsTable
    from "../../components/BookSupplyStandarts/BookSupplyStandartsTable/BookSupplyStandartsTable";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const BookSupplyStandarts = props => {

    return (
        <SceneContainer title={'Нормы книгообеспеченности'}>
            <div className="book_supply_standarts_selects">
                <div className="book_supply_standarts_select">
                    <label htmlFor="firstSelect" className="book_supply_standarts_label">
                        Специальность
                    </label>
                    <select id="firstSelect">
                        <option>
                            Применение и эксплуатация автоматизированных систем специального
                            назначения
                        </option>
                    </select>
                </div>
                <div className="book_supply_standarts_select">
                    <label htmlFor="secondSelect" className="book_supply_standarts_label">
                        Курс
                    </label>
                    <select id="secondSelect">
                        <option>1 курс (2019-2020 уч. год)</option>
                    </select>
                </div>
            </div>
            <div className="book_supply_">
                <BookSupplyStandartsTable/>
            </div>
        </SceneContainer>
    );
};

export default BookSupplyStandarts;
