import React, { useEffect } from "react";
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";

const BookHistoryChangeTab = ({ bookId, getBookChangeHistory, list }) => {
  useEffect(() => {
    getBookChangeHistory(bookId)
  }, [bookId]);

  return (
    <div className={'mt-3 mb-3'}>
      <DataTable data={list}>
        <DataTableCol title="ID" value="id" />
        <DataTableCol title="Пользователь" value="modifiedbyfio" />
        <DataTableCol title="Действие" value="method" />
        <DataTableCol title="Дата" value="eventtime" />
      </DataTable>
    </div>
  );
};

export default BookHistoryChangeTab;
