import { call, put, takeLatest } from "redux-saga/effects";
import { INSTITUTIONS_DELETE_REQUEST, START } from "../../../actions/action-types";
import {
  institutionsDeleteRequestError,
  institutionsDeleteRequestSuccess
} from "../../../actions/institutions/delete";
import { institutionDelete } from "../../../../api/institutions/institutions";
import { institutionsCountRequestStart } from "../../../actions/institutions/count";
import {createNotification} from "../../../../components/notification/notification";

export default function* institutionsDeleteWatcher() {
  yield takeLatest(`${INSTITUTIONS_DELETE_REQUEST}${START}`, institutionsDeleteWorker)
}

function* institutionsDeleteWorker({ payload: id }) {
  try {
    const response = yield call(institutionDelete, id);
    yield put(institutionsDeleteRequestSuccess(response.data));
    yield put(institutionsCountRequestStart())
    createNotification('success', 'Запись успешно удалена')
  } catch (error) {
    yield put(institutionsDeleteRequestError(error))
  }
}