import { EXPORT_BOOKS_REQUEST, SUCCESS, START, ERROR, RESET } from "../../../actions/action-types";

const initialState = {
  isLoading: false,
  isCompleted: false,
  isError: false,
};

export const booksExportRequestReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${EXPORT_BOOKS_REQUEST}${START}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${EXPORT_BOOKS_REQUEST}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        isCompleted: true
      };

    case `${EXPORT_BOOKS_REQUEST}${ERROR}`:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case `${EXPORT_BOOKS_REQUEST}${RESET}`:
      return initialState;
    default: return state;
  }
};
