import { createNotification } from "../../../../components/notification/notification";
import {ERROR, LIBRARY_RESPONSIBLE_UPDATE_REQUEST, START, SUCCESS} from "../../action-types";

export const updateResponsibleRequestStart = (payload) => ({
    type: `${LIBRARY_RESPONSIBLE_UPDATE_REQUEST}${START}`,
    payload
});

export const updateResponsibleRequestSuccess = (payload) => {
    createNotification('success', 'Ответственный изменен успешно')
    return {
        type: `${LIBRARY_RESPONSIBLE_UPDATE_REQUEST}${SUCCESS}`,
        payload
    }
};

export const updateResponsibleRequestError = (payload) => {
    createNotification('success', 'Ошибка изменения ответственного')
    return {
        type: `${LIBRARY_RESPONSIBLE_UPDATE_REQUEST}${ERROR}`,
        payload
    }
};