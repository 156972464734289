import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { resolveUserPhoto } from '../../../../utils/resolveUserPhoto'
import classNames from 'classnames'
import styles from './ReaderHeader.module.css'
import { Col, Row } from 'react-bootstrap'
import { logoutRequestStart } from '../../../../redux/actions/user/logout'
import { BASE_URL_WITHOUT_API } from '../../../../constants/api'
import { Link } from 'react-router-dom'
import { ownUserDataRequestStart } from '../../../../redux/actions/ownUserData/getData'

const mapStateToProps = ({ auth, options, ownUserData }) => ({
  authData: auth.data,
  options: options.data,
  layoutOptions: options.layout,
  ownUserData,
})

const mapDispatchToProps = {
  logOut: logoutRequestStart,
  requestReaderData: ownUserDataRequestStart,
}

const ReaderHeader = ({ authData, logOut, layoutOptions, ownUserData, requestReaderData, options }) => {
  const displayName = `${authData.lastname} ${authData.firstname} ${authData.patronymic}`
  let userAvatar

  useEffect(() => {
    requestReaderData(authData.mid)
  }, [requestReaderData])

  if (authData) {
    userAvatar = resolveUserPhoto(authData.mid)
  }

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${BASE_URL_WITHOUT_API}${layoutOptions.header.image})`,
        backgroundColor: layoutOptions.header.background,
      }}
    >
      <div className={styles.number}>{/*Изделие 83т505*/}</div>
      <Row className={'align-items-center'}>
        <Col xl={4} className={'d-none d-xl-block'}>
          <div className={classNames(styles.userContainer, 'd-flex align-items-center')}>
            <div className={styles.userPhoto} style={{ backgroundImage: `url("${userAvatar}")` }} />
            <div className={styles.userInfo}>
              <span>{displayName}</span>
              <span>{ownUserData && ownUserData.groupname && `${ownUserData.faculty}, группа: ${ownUserData.groupname}`}</span>
              <div className={styles.headerBtn}>
                {authData.islibrarian && (
                  <>
                    {authData.role_permissions.find(e => e.RasdelName === 'Настройка,"Общие настройки"') && (
                      <Link to={'/options'} className={styles.link}>
                        настройки
                      </Link>
                    )}
                    {authData.role_permissions.find(e => e.RasdelName === 'Настройка,"Настройка прав доступов"') && (
                      <Link to={'/admin/menu'} className={styles.link}>
                        администрирование
                      </Link>
                    )}
                  </>
                )}
                <div onClick={logOut} className={styles.logout}>
                  выйти
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4}>
          <div className={styles.smallDesktopUserInfo}>
            <div className={classNames(styles.userPhoto, 'd-xl-none')} style={{ backgroundImage: `url("${userAvatar}")` }} />
            <div className={classNames(styles.ministrySection, 'text-center')}>
              <div className={styles.ministryIcon} />
              <div className={styles.ministry}>Министерство обороны Российской Федерации</div>
              <div className={styles.library}>Единая электронная библиотека</div>
              <div className={styles.guo}>{options ? options.GOU_Name[1] : ''}</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReaderHeader)
