import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Table } from 'react-bootstrap'
import { Button } from 'antd'
import DocumentsModal from '../../Documents/Modal'
import { useContext } from 'react'
import { MobileLibContext } from '../../../utils/context'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { ADD, EDIT } from '../../../constants/modalTypes'
import { setCurrentDocument } from '../../../redux/actions/documents/current'
import { documentTypesAllRequestStart } from '../../../redux/actions/documents/types/all'
import { createLibDocumentRequestStart } from '../../../redux/actions/mobileLibrary/documents/create'
import { updateLibDocumentRequestStart } from '../../../redux/actions/mobileLibrary/documents/update'
import { getDocumentsRequestStart } from '../../../redux/actions/mobileLibrary/documents/list'
import cn from 'classnames'

export default function Documents() {
  
  const { disabled, libId, timeOut } = useContext(MobileLibContext)
  const { documents } = useSelector(state => state.mobileLibrary)
  const {latest, number, current} = useSelector(state => state.documents)
  const {all: allTypes} = useSelector(state => state.documentTypes)
  const dispatch = useDispatch()

  const { acceptance_doc_id } = documents?.length ? documents[documents.length - 1] : {}
  const acceptanceMode = !!documents?.length && !acceptance_doc_id;

  const [modalData, setModalData] = useState({ modalType: null, data: null })

  const initialModalData = {
    documentname: '',
    documentnumber: number,
    documenttype: '',
    documentdate: moment().format('YYYY-MM-DD'),
    document_arrival_date: moment().format('YYYY-MM-DD'),
    supplier: null,
    suppliername: '',
    view: '',
  }

  useEffect(() => {
    !allTypes?.length && dispatch(documentTypesAllRequestStart())
    dispatch(getDocumentsRequestStart(libId))
  }, [])

  useEffect(() => {
    setModalData(modalData =>
      modalData.modalType === ADD
        ? { modalType: EDIT, data: latest }
        : modalData
    )
  }, [latest])

  const onSaveModal = data => {
    const action = modalData.modalType === ADD
    ? createLibDocumentRequestStart
    : updateLibDocumentRequestStart
    dispatch(action(data))
  }

  const openModal = (item) => () => {
    setModalData({
      modalType: EDIT,
      data: { ...item, document_arrival_date: moment(item.document_arrival_date).format('YYYY-MM-DD') },
    })
    dispatch(setCurrentDocument(item))
  }

  const openCreationModal = () => {
    const documenttype = acceptanceMode ? 1 : 18
    const data = {...initialModalData, documenttype} 
    setModalData({ modalType: ADD, data })
    dispatch(setCurrentDocument(initialModalData))
  }

  const prettyDate = date => date ? moment(date).format('DD.MM.YYYY HH:mm') : null

  const renderRow = ({write_off_info, acceptance_info}, index) => {
    const writeOffDoc = write_off_info[0]
    const acceptanceDoc = acceptance_info[0]
    const writeOffDate = prettyDate(writeOffDoc?.creationtime)
    const acceptanceDate = prettyDate(acceptanceDoc?.creationtime)
    const active = index === documents.length - 1 && acceptanceMode
    return (
      <tr className={cn({[styles.activeRow]: active})}>
        <td>{index + 1}</td>
        <td onClick={openModal(writeOffDoc)}>{writeOffDate}</td>
        <td onClick={openModal(acceptanceDoc)}>{acceptanceDate}</td>
      </tr>
    )
  }

  const buttonTitle = acceptanceMode ? 'Принять документы обратно' : 'Выбрать документы для передачи'

  const buttonDisabled = disabled || (timeOut && !acceptanceMode)

  return (
    <div className={styles.block}>
      <p className={styles.blockTitle}>Документы:</p>
      <Table className={`${styles.respTable} ${styles.docTable}`}>
        <thead>
          <tr>
            <th>№</th>
            <th>Акт списания</th>
            <th>Акт поступления</th>
          </tr>
        </thead>
        <tbody>
          {documents?.map(renderRow)}
        </tbody>
      </Table>
      <Button onClick={openCreationModal} disabled={buttonDisabled} type='primary'>
        {buttonTitle}
      </Button>
      {modalData.modalType && (
        <DocumentsModal
          modalData={modalData}
          setModalData={setModalData}
          types={allTypes}
          onSave={onSaveModal}
          current={current}
          disabled={buttonDisabled}
          fromMobLib
        />
      )}
    </div>
  )
}
