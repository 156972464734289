import { call, put, takeLatest } from "redux-saga/effects";
import { ALPHABET_LETTERS_REQUEST, START } from "../../../../actions/action-types";
import { alphabetLetters } from "../../../../../api/library/alphabet";
import {
  alphabetLettersRequestError,
  alphabetLettersRequestSuccess
} from "../../../../actions/library/alphabet/letters";

export default function* alphabetLettersWatcher() {
  yield takeLatest(`${ALPHABET_LETTERS_REQUEST}${START}`, alphabetLettersWorker)
}

function* alphabetLettersWorker() {
  try {
    const response = yield call(alphabetLetters, 'rus');
    const charArray = response.data.map(({char}) => char);
    yield put(alphabetLettersRequestSuccess(charArray));
  } catch (error) {
    yield put(alphabetLettersRequestError(error))
  }
}