import React from 'react'
import ModalWindow from '../../ModalWindow'
import SendBookList from '../SendBookList'

const SendBookModalSingle = ({ isOpen, onClose, title, userId, searchBookModalData }) => {
  return (
    <ModalWindow isOpen={isOpen} onRequestClose={onClose} title={title}>
      <SendBookList data={searchBookModalData} userId={userId} />
    </ModalWindow>
  )
}

export default SendBookModalSingle
