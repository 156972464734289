import { api } from '../../App';

export const getStaticCollectionData = (id) => {
  return api.get(`/collection/collection/${id}`);
};

export const addStaticCollectionData = (collectionId, dataId) => {
  return api.put(`/collection/${collectionId}/${dataId}`)
};

export const deleteStaticCollectionData = (collectionId, dataId) => {
  return api.delete(`/collection/${collectionId}/${dataId}`);
};