import { DOCUMENT_TYPES_LIST_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const documentTypesListRequestStart = () => ({
  type: `${DOCUMENT_TYPES_LIST_REQUEST}${START}`
});

export const documentTypesListRequestSuccess = (data) => ({
  type: `${DOCUMENT_TYPES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentTypesListRequestError = (data) => ({
  type: `${DOCUMENT_TYPES_LIST_REQUEST}${ERROR}`,
  payload: data
});
