import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Pagination from '../../components/Pagination'
import AuditLogTable from '../../components/AuditLog/Table'
import {
  stateSecretBookCountRequestStart,
  setStateSecretBookFormData,
  setStateSecretBookSearchData,
} from '../../redux/reducers/stateSecretBook/stateSecretBookReducer'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { StateSecretBookTable } from '../../components/StateSecretBook/Table'
import { StateSecretBookForm } from '../../components/StateSecretBook/Form'
import AdminUsersModal from '../../components/AdminUsers/Modal'
import { EDIT } from '../../constants/modalTypes'
import { readerDataRequestStart } from '../../redux/actions/reader/getData'
import { usersSaveRequestStart } from '../../redux/actions/adminUsers/save'
import { ContactsOutlined } from '@ant-design/icons'


const mapStateToProps = ({ stateSecretBook, readerData }) => ({
  count: stateSecretBook.count,
  list: stateSecretBook.list,
  searchData: stateSecretBook.search,
  formData: stateSecretBook.form,
  loader: stateSecretBook.loader,
  readerData
})

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(stateSecretBookCountRequestStart()),
  setFormData: data => dispatch(setStateSecretBookFormData(data)),
  setSearchData: data => dispatch(setStateSecretBookSearchData(data)),
  saveUser: data => dispatch(usersSaveRequestStart(data)),
  requestReaderData: data => dispatch(readerDataRequestStart(data))
})

const StateSecretBook = ({
  list,
  requestList,
  readerData,
  requestReaderData,
  formData,
  setFormData,
  searchData,
  setSearchData,
  count,
  loader,
  saveUser
}) => {
  
  const [dir, toggleDir] = useState(true)
  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (document.location.pathname === '/state_secret_book') {
      setFormData({ ...formData, dataset: '/api/book/:id' })
    }
  }, [])

  useEffect(() => {
    requestList()
  }, [requestList])

  useEffect(() => {
    if (firstRender || !readerData) return setFirstRender(false)
    setModalData({ modalType: EDIT, data: readerData})
  }, [readerData])

  const setSort = sortBy => {
    const direction = dir ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
    setSearchData({ ...searchData, sortBy, sortDirection: direction })
    toggleDir(!dir)
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const onSubmitModal = data => {
    saveUser({...data, cb: requestList})
    setModalData({ modalType: null, data: null })
  }


  const getReaderData = ({mid}) => {
    requestReaderData(mid)
  }

  return (
    <SceneContainer title='Книга учета осведомленности в сведениях, составляющих государственную тайну'>
      <Row>
        <Col md={12}>
          <StateSecretBookForm secretBook={true} data={formData} setData={setFormData} search={requestList} dataset={false} list={list}/>
        </Col>
        
        {loader ? (
          <SpinnerOuter isLoading={loader} />
        ) : (
          <>
            <Col md={12}>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
            </Col>
            <Col md={12}>
              <StateSecretBookTable getReaderData={getReaderData} data={list} sortBy={searchData.sortBy} setSort={setSort} />
            </Col>
            <Col md={12}>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
            </Col>
          </>
        )}

        {modalData.modalType && (
          <AdminUsersModal
            // isLoading={isLoading}
            onSubmit={onSubmitModal}
            setModalData={setModalData}
            modalData={modalData}
            onlyInfo
          />
        )}

      </Row>
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StateSecretBook)
