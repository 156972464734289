import React, { useState, useEffect } from 'react'
import styles from './AuthScene.module.css'
import { Col, Row } from 'react-bootstrap'
import AuthForm from '../../components/Auth/Form'
import { connect } from 'react-redux'
import { authRequestStart, resetAuthData, getCaptcha, setCaptcha } from '../../redux/actions/user/auth'
import Button from '../../components/Button'

const mapStateToProps = (state) => ({
  error: state.auth.error,
  authData: state.auth.data,
  options: state.options.data,
  authCaptcha: state.auth.captcha,
})

const mapDispatchToProps = (dispatch) => ({
  requestAuth: ({ username, password, captcha, captcha_id }) =>
    dispatch(authRequestStart(username, password, captcha, captcha_id)),
  requestCaptcha: () => dispatch(getCaptcha()),
  resetError: () => dispatch(resetAuthData()),
})

const AuthScene = ({ requestAuth, requestCaptcha, error, resetError, authData, authCaptcha, history, options }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    captcha: '',
    captcha_id: '',
    captcha_text: '',
  })

  useEffect(() => {
    if (authData) {
      history.push('/')
    }
  })

  const reset = () => {
    onRefreshCaptcha()
    resetError()
  }

  const onSubmit = (e) => {
    e.preventDefault()
    formData.username && formData.password && formData.captcha && requestAuth(formData)
  }
  const onRefreshCaptcha = () => {
    formData.captcha_id = ''
    requestCaptcha()
    if (authCaptcha !== undefined) {
      formData.captcha_id = authCaptcha.id
      formData.captcha_text = authCaptcha.data
    }
  }

  return (
    <div className={styles.container}>
      <Row noGutters>
        <Col md={{ span: 6, offset: 3 }}>
          <AuthForm data={formData} setData={setFormData} onSubmit={onSubmit} onRefreshCaptcha={onRefreshCaptcha} />
          {error === 403 && (
            <div className={styles.errorContainer}>
              <div className={styles.errorModal}>
                <div className={styles.text}>Неверное сочетание имени пользователя и пароля!</div>
                <Button onClick={reset} label={'ОК'} />
              </div>
            </div>
          )}
          {error === 429 && (
            <div className={styles.errorContainer}>
              <div className={styles.errorModal}>
                <div className={styles.text}>Слишком много неверных попыток входа. Доступ запрещен на 1 мин</div>
                <Button onClick={reset} label={'ОК'} />
              </div>
            </div>
          )}
          {options && options.outsecrecy === 4 && (
            <div>
              <a href="https://foto.pamyat-naroda.ru/" className={styles.imgLink} target="_blank" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthScene)
