import { all } from 'redux-saga/effects';
import libraryInfoSaga from "./info/libraryInfoSaga";
import alphabetSaga from "./alphabet/alphabetSaga";
import libraryTypesSaga from "./types/libraryTypesSaga";

export default function* librarySaga() {
  yield all([
    alphabetSaga(),
    libraryInfoSaga(),
    libraryTypesSaga()
  ]);
}