import { call, put, takeLatest, select } from "redux-saga/effects";
import { OPTIONS_DIARY_TYPES_REQUEST, OPTIONS_DIARY_TYPES_UPDATE, START } from "../../../actions/action-types";
import { getDiaryTypes, saveDiaryTypes } from "../../../../api/options/diaryTypes";
import { optionsDiaryTypesRequestError, optionsDiaryTypesRequestSuccess } from "../../../actions/options/diaryTypes/get";
import { optionsDiaryTypesUpdateError, optionsDiaryTypesUpdateSuccess } from "../../../actions/options/diaryTypes/save";
import { createNotification } from "../../../../components/notification/notification";

export default function* diaryTypesWatcher() {
  yield takeLatest(`${OPTIONS_DIARY_TYPES_REQUEST}${START}`, getDiaryTypesWorker)
  yield takeLatest(`${OPTIONS_DIARY_TYPES_UPDATE}${START}`, updateDiaryTypesWorker)
}

function* getDiaryTypesWorker() {
  try {
    const response = yield call(getDiaryTypes);
    yield put(optionsDiaryTypesRequestSuccess(response.data.accessed_values));
  } catch (error) {
    yield put(optionsDiaryTypesRequestError(error));
  }
}

function* updateDiaryTypesWorker({ payload }) {
  try {
    const response = yield call(saveDiaryTypes, payload);
    yield put(optionsDiaryTypesRequestSuccess(response.data.res.accessed_values));
    createNotification('success', 'Типы обновлены успешно')
  } catch (error) {
    yield put(optionsDiaryTypesUpdateError(error));
    createNotification('error', 'Ошибка обновления типов')
  }
}

