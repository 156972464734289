import { ERROR, EXTERNAL_RESOURCES_SAVE_REQUEST, START, SUCCESS } from "../action-types";

export const externalResourcesSaveRequestStart = (data) => ({
  type: `${EXTERNAL_RESOURCES_SAVE_REQUEST}${START}`,
  payload: data
});

export const externalResourcesSaveRequestSuccess = (data) => ({
  type: `${EXTERNAL_RESOURCES_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const externalResourcesSaveRequestError = (data) => ({
  type: `${EXTERNAL_RESOURCES_SAVE_REQUEST}${ERROR}`,
  payload: data
});
