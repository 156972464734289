import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './IndexRowItem.module.css'
import { Col } from 'react-bootstrap'
import { getLibrarytypeImage } from '../../../api/library/types'
import { IMAGE_PLUG } from '../../../constants/imagePlug'

const IndexRowItem = ({ libraryTypes, destination, title, subtitle, background, amount, isExternal, newAmount, id }) => {
  newAmount = Number(newAmount)
  const [image, setImage] = useState(IMAGE_PLUG)

  useEffect(() => {
    getLibrarytypeImage(id)
      .then(res => {
        setImage(res.config.url)
      })
      .catch(e => {
        setImage(background)
      })
  }, [])

  const linkBody = (
    <div className={styles.link} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
    </div>
  )

  return (
    <Col xl={3} lg={4} md={6}>
      {isExternal ? (
        <a className={styles.outer} href={destination} rel='noreferrer noopener' target='_blank'>
          {linkBody}
          <span className={styles.counter}>{subtitle}</span>
        </a>
      ) : (
        <Link to={destination} className={styles.outer}>
          {!!newAmount && (
            <Link
              to={destination === '/reading' ? 'librarymaterials/1?newitems=true' : `${destination}?newitems=true`}
              className={styles.novsLabel}
            >
              {/* Шифр */ id == 1 ? `+${libraryTypes.reduce((a, i) => a + +i.newamount, 0)}` : `+${newAmount}`}
            </Link>
          )}
          {linkBody}
          <span className={styles.counter}>
            {/* Шифр */ id == 1 ? libraryTypes.reduce((a, i) => a + +i.amount, 0) : amount} {subtitle}
          </span>
        </Link>
      )}
    </Col>
  )
}

IndexRowItem.propTypes = {
  destination: T.string,
  title: T.string,
  amount: T.number,
  image: T.string,
}

export default IndexRowItem
