import { SORT_DIRECTION } from "../../constants/sortDirections";
import { api } from "../../App";

export const getSecurityLogList = ({sortBy, maxResults, paginateFrom, sortDirection}, search) => {
  const params = {
    maxResults,
    paginateFrom,
    ...search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
  };

  return api.get('/admin/logs/security', { params });
};

export const getSecurityLogCount = (search) => {
  const params = {
    count: true,
    ...search
  };

  return api.get('/admin/logs/security', { params });
};