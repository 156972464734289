import { ERROR, PUBLISHERS_DELETE_REQUEST, START, SUCCESS } from "../action-types";

export const publishersDeleteRequestStart = (data) => ({
  type: `${PUBLISHERS_DELETE_REQUEST}${START}`,
  payload: data
});

export const publishersDeleteRequestSuccess = (data) => ({
  type: `${PUBLISHERS_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const publishersDeleteRequestError = (data, log) => ({
  type: `${PUBLISHERS_DELETE_REQUEST}${ERROR}`,
  payload: { data, log }
});