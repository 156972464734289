import { call, put, takeLatest } from "redux-saga/effects";
import { ROLES_LIST_REQUEST, START } from "../../../actions/action-types";
import { rolesListRequestError, rolesListRequestSuccess } from "../../../actions/roles/list";
import { getRoles } from "../../../../api/roles/roles";

export default function* rolesListWatcher() {
  yield takeLatest(`${ROLES_LIST_REQUEST}${START}`, rolesListWorker)
}

function* rolesListWorker() {
  try {
    const response = yield call(getRoles);
    yield put(rolesListRequestSuccess(response.data));
  } catch (error) {
    yield put(rolesListRequestError(error));
  }
}