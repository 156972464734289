import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {rolesListRequestStart} from "../../../redux/actions/roles/list";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import {setPermissionsBySeriesFormData} from "../../../redux/actions/permissions/bySeries/form";
import {setPermissionsBySeriesSearchData} from "../../../redux/actions/permissions/bySeries/search";
import {permissionsBySeriesEditRequestStart} from "../../../redux/actions/permissions/bySeries/edit";
import {permissionsBySeriesGroupChangesStart} from "../../../redux/actions/permissions/bySeries/groupChange";
import {SORT_DIRECTION} from "../../../constants/sortDirections";
import Pagination from "../../../components/Pagination";
import {permissionsBySeriesCountRequestStart} from "../../../redux/actions/permissions/bySeries/count";
import {permissionsBySeriesAddRequestStart} from "../../../redux/actions/permissions/bySeries/add";
import SeriesPermissionsDataTable from "../../../components/AdminPermissions/SeriesPermissions/DataTable";
import PermissionsSearchForm from "../../../components/AdminPermissions/SearchForm";
import Button from '../../../components/Button';


const mapStateToProps = ({roles, accessSeries}) => ({
    roles: roles.list,
    list: accessSeries.list,
    count: accessSeries.count,
    loader: accessSeries.loader,
    formData: accessSeries.form,
    searchData: accessSeries.search,
});

const mapDispatchToProps = {
    requestList: permissionsBySeriesCountRequestStart,
    requestRoles: rolesListRequestStart,
    setFormData: setPermissionsBySeriesFormData,
    setSearchData: setPermissionsBySeriesSearchData,
    setAccessStatus: permissionsBySeriesEditRequestStart,
    addCategoryToRole: permissionsBySeriesAddRequestStart,
    startGroupChange: permissionsBySeriesGroupChangesStart
};

const SeriesPermissionsScene = (
    {
        roles,
        requestRoles,
        list,
        requestList,
        formData,
        setFormData,
        count,
        loader,
        searchData,
        setSearchData,
        setAccessStatus,
        startGroupChange,
        addCategoryToRole
    }
) => {
    useEffect(() => {
        !roles.length && requestRoles();
    }, [roles, requestRoles]);

    const setSort = (sortBy) => {
        let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ?
            SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;

        setSearchData({
            ...searchData,
            sortDirection: sortDir,
            'orderBy': sortDir === SORT_DIRECTION.ASC ? sortBy : null,
            'orderByD': sortDir === SORT_DIRECTION.DESC ? sortBy : null,
            page: 1
        });
        requestList();
    };

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList();
    };

    const onChangeAccessStatus = (event, series) => {
        const {id, category, role} = series
        const {value} = event.target
        const params = {
            role: role,
            access: value || null,
            seriesid: category,
            itemid: id
        };
        id ? setAccessStatus(params) : addCategoryToRole(params)
    };

    return (
        <SceneContainer title={'Администрирование / Настройка прав доступа по сериям'}>
            <PermissionsSearchForm
                onSubmit={requestList}
                formData={formData}
                setFormData={setFormData}
                roles={roles}
            />
            {loader
                ? <SpinnerOuter isLoading={loader}/>
                : !!list.length &&
                    <>
                        <Pagination
                            count={count}
                            pageCount={searchData.pageCount}
                            current={searchData.page}
                            setCurrent={setCurrentPage}
                        />
                            <Button label="Установить 'Чтение' на все серии" colorType='red' onClick={startGroupChange} />
                            <SeriesPermissionsDataTable
                                data={list}
                                setSort={setSort}
                                sortBy={searchData.sortBy}
                                onChange={onChangeAccessStatus}
                            />
                        <Pagination
                            count={count}
                            pageCount={searchData.pageCount}
                            current={searchData.page}
                            setCurrent={setCurrentPage}
                        />
                    </>
            }

        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SeriesPermissionsScene);
