import {ERROR, START, SUCCESS, TRANSFER_LOG_COUNT_REQUEST} from "../../action-types";


export const transferLogCountRequestStart = () => ({
  type: `${TRANSFER_LOG_COUNT_REQUEST}${START}`
});

export const transferLogCountRequestSuccess = (response) => ({
  type: `${TRANSFER_LOG_COUNT_REQUEST}${SUCCESS}`,
  payload: response
});

export const transferLogCountRequestError = (error) => ({
  type: `${TRANSFER_LOG_COUNT_REQUEST}${ERROR}`,
  payload: error
});
