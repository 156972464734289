import { BOOK_SCAN_ORDER_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const bookScanOrderCountRequestStart = (data) => ({
  type: `${BOOK_SCAN_ORDER_COUNT_REQUEST}${START}`,
  payload: data
});

export const bookScanOrderCountRequestSuccess = (data) => ({
  type: `${BOOK_SCAN_ORDER_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookScanOrderCountRequestError = (data) => ({
  type: `${BOOK_SCAN_ORDER_COUNT_REQUEST}${ERROR}`,
  payload: data
});
