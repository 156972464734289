import React from 'react';
import T from 'prop-types';
import styles from './DocumentsEditBookForm.module.css';
import ModalInputWrapper from "../../Modal/InputWrapper";
import { Col, Row } from "react-bootstrap";
import Button from "../../Button";

const DocumentsEditBookForm = ({ data, setData, onSave, onDelete }) => {
  const onChange = ({ target: { name, value } }) => setData({ ...data, [name]: value });

  return (
    <div className={styles.container}>
      <Row>
        <Col md={12}>
          <div className={styles.title}>
            "{data.title}":
          </div>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             label="Физических копий"
                             value={data.amount}
                             name="amount"
                             type="number"/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             label="Электронных копий"
                             value={data.digital_amount || 0}
                             name="digital_amount"
                             type="number"/>
        </Col>
        <Col md={12} className={"text-right"}>
          <Button onClick={() => setData(null)}
                  style={styles.button}
                  label={"Отменить"}/>
          <Button onClick={() => onSave(data)}
                  style={styles.button}
                  label={"Сохранить"}/>
          <Button onClick={() => onDelete(data)}
                  style={styles.button}
                  label={"Удалить"}/>
        </Col>
      </Row>
    </div>
  );
};

DocumentsEditBookForm.propTypes = {
  data: T.object,
  setData: T.func,
  onSave: T.func,
  onDelete: T.func
};

export default DocumentsEditBookForm;
