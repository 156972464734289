import { TESTS_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const testsListRequestStart = (data) => ({
    type: `${TESTS_LIST_REQUEST}${START}`,
    payload: data
});

export const testsListRequestSuccess = (data) => ({
    type: `${TESTS_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const testsListRequestError = (data) => ({
    type: `${TESTS_LIST_REQUEST}${ERROR}`,
    payload: data
});
