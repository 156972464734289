import { call, put, takeLatest } from "redux-saga/effects";
import { REGIONS_COUNT_REQUEST, START } from "../../../actions/action-types";
import { regionsCount } from "../../../../api/regions/regions";
import { regionsCountRequestError, regionsCountRequestSuccess } from "../../../actions/regions/count";

export default function* regionsCountWatcher() {
  yield takeLatest(`${REGIONS_COUNT_REQUEST}${START}`, regionsCountWorker)
}

function* regionsCountWorker() {
  try {
    const response = yield call(regionsCount);
    yield put(regionsCountRequestSuccess(parseInt(response.data, 10)));
  } catch (error) {
    yield put(regionsCountRequestError(error));
  }
}