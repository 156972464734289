import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import classNames from 'classnames';
import {Collapse} from "react-bootstrap";
import {formatDate} from "../../index";

import styles from './s.module.css';


const CollapsedBlock = ({ month, year, onClick, selectedDate }) => {

    const [open, setOpen] = useState(true);

    const isSelectedDateEqualItemDate = (year, month, day) => {
        return  year === selectedDate?.year
            && month === selectedDate?.month
            && day === selectedDate?.day
    };

    return (
        <div>
            <div onClick={() => setOpen(!open)} className={classNames(styles.month, {
                [styles.rotateBottom]: open,
                [styles.rotateLeft]: !open,
            })}>
                {moment(month[0], 'M').format('MMMM')}
            </div>
            <Collapse in={open}>
                <ul className={classNames(styles.list)}>
                    {Object.entries(month[1]).reverse().map(day =>
                        <li
                            onClick={() => onClick(year[0], month[0], day[0])}
                            className={classNames(styles.item, {
                                [styles.active]: isSelectedDateEqualItemDate(year[0], month[0], day[0])
                            })}
                        >
                            {formatDate(`${year[0]}-${month[0]}-${day[0]}`)}
                        </li>
                    )}
                </ul>
            </Collapse>
        </div>
    );
};

CollapsedBlock.propTypes = {
    onClick: PropTypes.func,
};

export default CollapsedBlock;