import React from 'react'
import PropTypes from 'prop-types'
import styles from './DocumentBooksTable.module.css'
import { Table } from 'antd'
import moment from 'moment'

const DocumentBooksTable = ({ data, selectedId, handleSelect, modalData, type, fromInvBook }) => {
  const { Column } = Table
  const view = modalData.data.view
  // const type = modalData.data.type

  const getFieldSum = (field) => data.reduce((accum, item) => accum + (parseInt(item[field]) || 0), 0)

  const getSummaryRow = () => {
    const colspan = (view === 3 || view === 4) ? 2 : 1
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={colspan}>Итого</Table.Summary.Cell>
        <Table.Summary.Cell>{getFieldSum('amount')}</Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
        <Table.Summary.Cell>{getFieldSum('afterprice')}</Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }


  const getInvBookRow = () => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={3}>Итого</Table.Summary.Cell>
        <Table.Summary.Cell>{getFieldSum('amount')}</Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
        <Table.Summary.Cell>{getFieldSum('price_coeff')}</Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }

  return (
    <div className={styles.container}>
      {
        fromInvBook
          ? <Table summary={getInvBookRow} dataSource={data} title={false} bordered={false} pagination={false} locale={{ emptyText: 'Нет данных' }}>
              <Column title='Название' dataIndex='title' />
              <Column title='Год издания' dataIndex='year' />
              <Column title='Инвентарный номер' dataIndex='libnumber' />
              <Column title='Кол-во' dataIndex='amount' />
              <Column title='Цена' dataIndex='price' />
              <Column title='Цена, с учетом коэфф.' dataIndex='price_coeff' />
              <Column
                title='Выбрано'
                align='center'
                render={item => <input type='checkbox' checked={item.id === selectedId} onChange={() => handleSelect(item)} />}
              />
            </Table>
            //Шифр
          : type === 1 ? (
              <Table dataSource={data} title={false} bordered={false} pagination={false} locale={{ emptyText: 'Нет данных' }} summary={getSummaryRow}>
                <Column title='Название' dataIndex='title' />
                {(view === 3 || view === 4) && (
                  <>
                    <Column
                      title='Годовой комплект'
                      align='center'
                      render={data => <input type='checkbox' name='annualset' checked={data.annualset} disabled />}
                    />
                    {/*Шифр*/type !== 1 && <Column title='Номер' dataIndex='invnumber' />}
                    {view === 3 && (
                      <Column
                        title='Выпуск (месяц)'
                        dataIndex={data.librelease}
                        render={item => (item.librelease ? moment(item.librelease).format('MMMM YYYY') : '-')}
                      />
                    )}
                    {view === 4 && (
                      <Column
                        title='Выпуск (дата)'
                        dataIndex={data.librelease}
                        render={item => (item.librelease ? moment(item.librelease).format('MM-DD-YYYY') : '-')}
                      />
                    )}
                  </>
                )}
                <Column title='Кол-во' dataIndex='amount' />
                <Column title='Цена' dataIndex='afterprice' />
                <Column title='Цена с учетом коэф' dataIndex='afterprice' />
                <Column title='Сумма' dataIndex='sum' />
                <Column
                  title='Распределение проведено'
                  align='center'
                  render={item =>
                    item.distributionconducted ? <span style={{ color: 'green' }}>Да</span> : <span style={{ color: 'red' }}>Нет</span>
                  }
                />
                <Column
                  title='Выбрано'
                  align='center'
                  render={item => <input type='checkbox' checked={item.id === selectedId} onChange={() => handleSelect(item)} />}
                />
              </Table>
              //Шифр
      ) : type === 18 ? (
        <Table dataSource={data} title={false} bordered={false} pagination={false} locale={{ emptyText: 'Нет данных' }}>
          <Column title='Название' dataIndex='title' />
          <Column title='Инвентарный номер' dataIndex='libnumber' />
          <Column title='Кол-во' dataIndex='amount' />
          <Column title='Цена с учетом коэф' dataIndex='afterprice' />
          <Column title='Сумма' dataIndex='sum' />
          <Column
            title='Выбрано'
            align='center'
            render={item => <input type='checkbox' checked={item.id === selectedId} onChange={() => handleSelect(item)} />}
          />
        </Table>
      ) : (
        <Table dataSource={data} title={false} bordered={false} pagination={false} locale={{ emptyText: 'Нет данных' }}>
          <Column title='Название' dataIndex='title' />
          <Column title='Инвентарный номер' dataIndex='libnumber' />
          <Column title='Кол-во' dataIndex='amount' />
          <Column
            title='Выбрано'
            align='center'
            render={item => <input type='checkbox' checked={item.id === selectedId} onChange={() => handleSelect(item)} />}
          />
        </Table>
      )}
    </div>
  )
}

DocumentBooksTable.propTypes = {
  data: PropTypes.array,
}

export default DocumentBooksTable
