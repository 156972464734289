import { api } from "../../App";

export const getSeriesList = ({ name, ...otherParams }) => {
  const params = {
    name,
    ...otherParams
  };

  return api.get('/series', {params})
};

export const getSeriesCount = ({ name, ...otherParams }) => {
  const params = {
    count: true,
    name,
    ...otherParams
  };


  return api.get('/series', {params})
};

export const deleteSeriesItem = (itemId) => {
  return api.delete(`/series/${itemId}`);
};

export const addSeriesItem = ({ name }) => {
  return api.post(`/series`, {name});
};

export const editSeriesItem = ({ name, item }) => {
  return api.put(`/series/${item}`, {name});
};