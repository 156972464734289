import { ERROR, EXPORT_BOOKS_REQUEST, START, SUCCESS, RESET } from "../action-types";

export const exportBooksRequestStart = (booksIds) => ({
  type: `${EXPORT_BOOKS_REQUEST}${START}`,
  payload: booksIds
});

export const exportBooksRequestSuccess = (data) => ({
  type: `${EXPORT_BOOKS_REQUEST}${SUCCESS}`,
  payload: data
});

export const exportBooksRequestError = (data) => ({
  type: `${EXPORT_BOOKS_REQUEST}${ERROR}`,
  payload: data
});

export const exportBooksClearReducer = () => ({
  type: `${EXPORT_BOOKS_REQUEST}${RESET}`,
});
