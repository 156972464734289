import {
    COVERS_ADVANCED_SEARCH_LIST_REQUEST,

    COVERS_READING_SEARCH_REQUEST,
    COVERS_READING_LIST_REQUEST,
    START,
    SUCCESS, SEARCH_BY_IMAGE_REQUEST, COVERS_SCAN_COUNT_REQUEST, COVERS_SCAN_LIST_REQUEST
} from "../../../actions/action-types";

const initialState = false;

export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${COVERS_SCAN_COUNT_REQUEST}${START}`:
        case `${COVERS_READING_SEARCH_REQUEST}${START}`:
        case `${SEARCH_BY_IMAGE_REQUEST}${START}`:
        case `${COVERS_ADVANCED_SEARCH_LIST_REQUEST}${START}`:
            return true;

        case `${COVERS_SCAN_LIST_REQUEST}${SUCCESS}`:
        case `${SEARCH_BY_IMAGE_REQUEST}${SUCCESS}`:
        case `${COVERS_READING_LIST_REQUEST}${SUCCESS}`:
            return false;


        default:
            return state;
    }
};
