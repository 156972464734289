import { call, put, takeLatest } from "redux-saga/effects";
import { PERMISSIONS_SAVE_REQUEST, START } from "../../../actions/action-types";
import { saveRolePermission } from "../../../../api/permissions/permissions";
import { permissionsSaveRequestError, permissionsSaveRequestSuccess } from "../../../actions/permissions/save";
import { permissionsByRoleRequestStart } from "../../../actions/permissions/byRole";

export default function* permissionsSaveWatcher() {
  yield takeLatest(`${PERMISSIONS_SAVE_REQUEST}${START}`, permissionsSaveWorker)
}

function* permissionsSaveWorker({ payload: { roleId, section, value }}) {
  const request = {
    rasdel: section,
    rasdelvalue: value
  };
  try {
    const response = yield call(saveRolePermission, roleId, request);
    yield put(permissionsSaveRequestSuccess(response));
    yield put(permissionsByRoleRequestStart(roleId));
  } catch (error) {
    yield put(permissionsSaveRequestError(error));
  }
}