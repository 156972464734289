import React, {useEffect, useState} from 'react';
import styles from './LocationScene.module.css';
import {libraryListRequestStart} from "../../redux/actions/library/info/list";
import {connect} from "react-redux";
import {roomsListRequestStart} from "../../redux/actions/location/rooms/list";
import {racksListRequestStart} from "../../redux/actions/location/racks/list";
import {shelvesListRequestStart} from "../../redux/actions/location/shelves/list";
import LocationDataContainer from "../../components/Location/DataContainer";
import {LOCATION_MODAL} from "../../constants/locationModal";
import {ADD} from "../../constants/modalTypes";
import LocationModal from "../../components/Location/Modal";
import EditorModal from "../../components/Location/EditorModal";
import {roomSaveRequestStart} from "../../redux/actions/location/rooms/save";
import {roomDeleteRequestStart} from "../../redux/actions/location/rooms/delete";
import {rackSaveRequestStart} from "../../redux/actions/location/racks/save";
import {rackDeleteRequestStart} from "../../redux/actions/location/racks/delete";
import {shelfSaveRequestStart} from "../../redux/actions/location/shelves/save";
import {shelfDeleteRequestStart} from "../../redux/actions/location/shelves/delete";
import Button from "../../components/Button";
import {Link} from "react-router-dom";
import classNames from "classnames";
import qs from 'qs';

import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import ModalInputWrapper from "../../components/Modal/InputWrapper";

const mapStateToProps = state => ({
    libraries: state.library.info.list,
    rooms: state.location.rooms,
    racks: state.location.racks,
    shelves: state.location.shelves
});

const mapDispatchToProps = {
    requestLibraries: libraryListRequestStart,
    requestRooms: roomsListRequestStart,
    requestRacks: racksListRequestStart,
    requestShelves: shelvesListRequestStart,
    saveRoom: roomSaveRequestStart,
    deleteRoom: roomDeleteRequestStart,
    saveRack: rackSaveRequestStart,
    deleteRack: rackDeleteRequestStart,
    saveShelf: shelfSaveRequestStart,
    deleteShelf: shelfDeleteRequestStart
};

const initialModalData = {modalType: null, locationType: null, data: null};
const initialEditorData = {locationType: null, roomId: null};

const LocationScene = ({
                           libraries, requestLibraries, rooms, requestRooms,
                           racks, requestRacks, shelves, requestShelves, saveRoom,
                           saveRack, saveShelf,
                       }) => {

    const [libraryId, setLibraryId] = useState(undefined);
    const [modalData, setModalData] = useState(initialModalData);
    const [editorData, setEditorData] = useState(initialEditorData);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        !libraries.length && requestLibraries();
    });

    useEffect(() => {
        libraryId && requestRooms(libraryId);
    }, [libraryId, requestRooms]);

    const resetModal = () => setModalData(initialModalData);


    return (
        <SceneContainer title={'Справочники / Адресное хранение'}>
            <ModalInputWrapper label={'Выберите библиотеку'}>
                <select className={styles.select} value={libraryId}
                        onChange={({target: {value}}) => setLibraryId(value)}>
                    <option value="" selected disabled>Выберите библиотеку</option>
                    {libraries.map((item, index) => (
                        <option value={item.id} key={index}>{item.name}</option>
                    ))}
                </select>
            </ModalInputWrapper>


            {libraryId &&
            <Button
                label={'Добавить зал'}
                colorType={'green'}
                onClick={() => setModalData({
                    modalType: ADD,
                    parentId: libraryId,
                    locationType: LOCATION_MODAL.ROOM,
                    data: null
                })}
            />
            }
            {!!selectedIds.length &&
            <Link
                target="_blank"
                to={{
                    pathname: "/qr_codes_shelves",
                    search: qs.stringify({ids: selectedIds}, {encode: false})
                }}
                className={classNames(styles.button, {
                    [styles.disabled]: !selectedIds.length
                })}
            >
                Печать QR кодов
            </Link>
            }

            {!!modalData.modalType &&
            <LocationModal
                saveRoom={saveRoom}
                saveRack={saveRack}
                saveShelf={saveShelf}
                resetModal={resetModal}
                setModalData={setModalData}
                modalData={modalData}
            />
            }
            {!!editorData.locationType &&
            <EditorModal setEditorData={setEditorData}
                         modalData={editorData}/>
            }

            <LocationDataContainer rooms={rooms}
                                   libraryId={libraryId}
                                   setModalData={setModalData}
                                   racks={racks}
                                   requestRacks={requestRacks}
                                   shelves={shelves}
                                   requestShelves={requestShelves}
                                   resetModal={resetModal}
                                   setSelectedIds={setSelectedIds}
                                   selectedIds={selectedIds}
                                   setEditorData={setEditorData}/>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationScene);
