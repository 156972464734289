import { createNotification } from "../../../../components/notification/notification";
import {ERROR, MOBILE_LIBRARY_UPDATE_REQUEST, START, SUCCESS} from "../../action-types";

export const updateMobileLibraryRequestStart = (payload) => ({
    type: `${MOBILE_LIBRARY_UPDATE_REQUEST}${START}`,
    payload
});

export const updateMobileLibraryRequestSuccess = (payload) => {
    createNotification('success', 'Данные библиотеки успешно обновлены')
    return {
        type: `${MOBILE_LIBRARY_UPDATE_REQUEST}${SUCCESS}`,
        payload
    }
};

export const updateMobileLibraryRequestError = (payload) => {
    createNotification('error', 'Ошибка обновления данных библиотеки')
    return {
        type: `${MOBILE_LIBRARY_UPDATE_REQUEST}${ERROR}`,
        payload
    }
};