import { put, call, takeLatest, select } from "redux-saga/effects";
import { READER_SEARCH_REQUEST, START } from "../../actions/action-types";
import { readerSearchRequestError, readerSearchRequestSuccess } from "../../actions/readerSearch/search";
import { getUsersList } from "../../../api/adminUsers/users";
import { RESULTS_PER_PAGE } from "../../../constants/pagination";
import { USERS_SORT } from "../../../constants/sort/usersSort";
import { SORT_DIRECTION } from "../../../constants/sortDirections";

export default function* readerSearchWatcher() {
  yield takeLatest(`${READER_SEARCH_REQUEST}${START}`, readerSearchWorker)
}

function* readerSearchWorker() {
  const query = yield select(({ readerSearch }) => readerSearch.search);
  try {
    const response = yield call(getUsersList, USERS_SORT.LAST_NAME, RESULTS_PER_PAGE, 0, query, SORT_DIRECTION.DESC);
    yield put(readerSearchRequestSuccess(response.data));
  } catch (error) {
    yield put(readerSearchRequestError(error));
  }
}
