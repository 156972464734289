import { DOCUMENT_BOOK_ORDER_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const documentListRequestStart = () => ({
    type: `${DOCUMENT_BOOK_ORDER_REQUEST}${START}`
});

export const documentListRequestSuccess = (data) => ({
    type: `${DOCUMENT_BOOK_ORDER_REQUEST}${SUCCESS}`,
    payload: data
});

export const documentListRequestError = (data) => ({
    type: `${DOCUMENT_BOOK_ORDER_REQUEST}${ERROR}`,
    payload: data
});
