import { ERROR, START, SUCCESS, SVNI_LIST_COLLAPSE, SVNI_LIST_REQUEST } from "../action-types";

export const svniListRequestStart = (id, search, rewrite) => ({
  type: `${SVNI_LIST_REQUEST}${START}`,
  payload: {
    id, search, rewrite
  }
});

export const svniListRequestSuccess = (data) => ({
  type: `${SVNI_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const svniListRequestError = (data) => ({
  type: `${SVNI_LIST_REQUEST}${ERROR}`,
  payload: data
});

export const svniListCollapse = (id) => ({
  type: SVNI_LIST_COLLAPSE,
  payload: id
});

