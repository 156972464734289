import {SCHEDULER_CATEGORY_COUNT_REQUEST, SUCCESS} from "../../../../actions/action-types";

const initialState = 0;

export const countReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${SCHEDULER_CATEGORY_COUNT_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};
