import React, { useEffect, useMemo, useState } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux'
import styles from './MenuEditor.module.css';
import { Button } from 'antd'
import { NestedListComponent } from './NestedListComponent';
import { createNotification } from '../../notification/notification';
import { api } from '../../../App';

const mapStateToProps = state => ({
    menu_list: state.menuList.menu.list,
  })

const mapDispatchToProps = {
}

const MenuEditor = ({options, setOptions, saveOptions, menu_list = [], menuList, setmenuList, saveMenu}) => {

    const [categories, setCategories] = useState([]);
    const [files, setFiles] = useState([]);
    const [role, setRole] = useState([]);

    useMemo(() => {
        menu_list && setmenuList(menu_list);
    }, [menu_list])

    useEffect(() => {
      if (role?.length == 0) {
        getRoles();
      }
    }, [role])
    
    const getRoles = async () => {
        try {
          const response = await api.get(`/admin/roles`);
          if (response.status == 200) {
            setRole(response.data);
          }
        }
        catch (e) {
        }
      }

    //Обработчик ввода данных
    const onChange = (e, nameInput) => {
        const { name, value } = e.target;
        const newList = categories.map(el => getList(el)).flat();
        newList.find(el => el.id == name)[nameInput] = value;
        setmenuList([...newList]);    
    };

    
    //Добавление пункта меню
    const handleAddMenu = (menu, isFile) => {
        const newList = categories.map(el => getList(el)).flat();
        const newObj = {
            editable: true,
            id: Number (new Date().getTime().toString().slice(-10)),
            login: null,
            parent_id: menu.id,
            password: null,
            target_blank: isFile ? true : false, 
            title: "",
            url: "",
            visible: true,
            add: true,
            accessed_roles: null
        };
    
        const index = menuList.findIndex(el => el.id == menu.id)
        newList.splice(index + 1, 0, newObj);
        setmenuList(newList);
    }

    //Удаление пункта меню первого порядка
    const handleDeleteFirst = (id) => {
        const itemIndex = menuList.findIndex(el => el.id == id);
        const newItem = {...menuList[itemIndex], add: false}
        const newList = [...menuList]
        newList.splice(itemIndex, 1, newItem);
        setmenuList(newList);
    }



    //Удаление пункта меню второго порядка
    const handleDeleteSecond = (id, url) => {
        
        handleDeleteFirst(id)

        const newFileList = [...files].filter(el => `${el.url}` != url);
        setFiles(newFileList);
    }

    //Примедение массива к нулевой вложенности
    const getList = (el) => {
        const newValue = {...el};
        if (el.items && el.items.length > 0) {
            return [newValue, ...el.items]
        } else {
            return newValue
        }
    }

     //   Запрос на отправку файлов
     const handlePost = async (el) => {
        const fd = new FormData();
        fd.append("file", el.file);
        
        try {
            const response = await api.post(`/menu_file_settings/files`, fd, {});
            if (response.status == 200) {
                createNotification('success', 'Успешно');
            }
        }
        catch (e) {
            createNotification('error', 'Ошибка');
        }
    }

    //Вызов Сохранения
    const handleSave = () => {
        files.map(el => handlePost(el));        
        const newList = categories.map(el => getList(el)).flat();
        const categoriesWithNewPositions = newList.map((item, index) => ({...item, position: index + 1}))
        saveMenu(categoriesWithNewPositions);
    }   

    return (
        <div>
            <div className={styles.wrapperButton}>
                <div className={styles.mainButtonWrapper}>
                    <Button onClick={() => handleAddMenu({id:0})}>Создать пункт 1-го уровня</Button>
                    <Button onClick={() => handleAddMenu({id:0}, true)}>Создать ссылку на файл</Button>
                </div>
                <Button onClick={() => handleSave()}>Сохранить</Button>
            </div>
            
            <NestedListComponent 
                role={role}
                list={ menuList } 
                setmenuList= {setmenuList}
                onChange={onChange}
                handleAddMenu={handleAddMenu}
                handleDeleteFirst={handleDeleteFirst}
                handleDeleteSecond={handleDeleteSecond}
                categories={categories}
                setCategories={setCategories}
                files={files}
                setFiles={setFiles}
            />
           
        </div>
    );
};

MenuEditor.propTypes = {
    options: T.object,
    setOptions: T.func,
    saveOptions: T.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuEditor)