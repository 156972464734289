import jsPDF from 'jspdf'
import addFont from '../../../../fonts/fonts'


export const createContractDoc = async ({libraryNameAddress}) => {
  
  const orientation = "portrait";

  addFont(jsPDF.API);
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation });
  doc.setFont("Montserrat");
  
  // doc.setFont( undefined, 'bold')
  doc.setFontSize(18);
  doc.text('ТИПОВОЙ ДОГОВОР', 105, 15, 'center')
  doc.setFontSize(10);
  
  doc.text('на организацию передвижной военной библиотеки', 105, 25, 'center')



  doc.text('Подписавшие настоящий договор военная библиотека______________________________________', 20, 40)
  doc.text('_________________________________________________________________________________________________', 20, 45)
  doc.text(libraryNameAddress.substring(0, 30), 125, 39)
  doc.text(libraryNameAddress.substring(30), 20, 44)


  doc.setFontSize(6).text('(наименование, адрес)', 95, 47).setFontSize(10);
  doc.text('в лице заведующего библиотекой ____________________________________________________________', 20, 50)
  doc.setFontSize(6).text('(фамилия имя отчество)', 120, 52).setFontSize(10);
  doc.text('с одной стороны, и ____________________________________________________________________________', 20, 55)
  doc.setFontSize(6).text('(наименование воинской части)', 110, 57).setFontSize(10);
  doc.text('в лице _________________________________________________________________________________________', 20, 60)
  doc.setFontSize(6).text('(воинское звание, фамилия, имя, отчество, должность)', 95, 62).setFontSize(10);
  doc.text('с другой, в целях улучшения библиотечного обслуживания личного состава ________________', 20, 65)
  doc.text('_________________________________________________________________________________________________', 20, 70)
  doc.setFontSize(6).text('(наименование воинской части)', 95, 72).setFontSize(10);
  doc.text('берут на себя следующие обязательства:', 20, 75)
  
  doc.text(
    doc.splitTextToSize('1. Библиотека комплектует и выдает воинской части передвижную библиотеку с количеством ______ экз. документов и обязуется производить регулярный обмен документов, оказывать помощь в работе библиотекарю передвижной библиотеки.', 170),
    20,
    85
  )

  doc.text('2. Воинская часть _____________________________________________________________________________', 20, 100)
  doc.text('берет на себя ответственность за сохранность полученных документов и обязуется:', 20, 105)
  doc.text('а) выделить гр. _______________________________________________________________________________,', 20, 110)
  doc.setFontSize(6).text('(фамилия имя отчество)', 95, 112).setFontSize(10);
  doc.text(
    doc.splitTextToSize('на которого возлагаются обязанность получения и обмена документов в военной библиотеке и работа с пользователями передвижной библиотеки;', 170),
    20,
    115
  )
  
  doc.text(
    doc.splitTextToSize('б) обеспечить передвижную библиотеку для работы помещением и необходимым оборудованием;', 170),
    20,
    125
  )
  
  doc.text(
    doc.splitTextToSize('в) проверять работу библиотекаря передвижной библиотеки и оказывать ему содействие в организации библиотечного обслуживания личного состава воинской части.', 170),
    20,
    135
  )
  
  doc.text('3. Срок действия настоящего договора _______________________________________________________', 20, 150)
  doc.text('со дня его подписания.', 20, 155)
  
  doc.text(
    doc.splitTextToSize('4. При смене руководства военной библиотеки или воинской части договор сохраняет свою силу.', 170),
    20,
    165
  )
  
  doc.text('Заведующий военной библиотекой                  ________________________________________', 20, 250)
  doc.text('Уполномоченный воинской части                  ________________________________________', 20, 265)
  doc.text('"___" __________ 20___г.', 20, 280)


  doc.addPage("a4", orientation);
  doc.deletePage(doc.getNumberOfPages());
  return doc;
};
