import {BIBLIOGRAPHY_LIST_REQUEST, ERROR, START, SUCCESS} from "../../../actions/action-types";

const initialState = false;

export const bibliographyLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${BIBLIOGRAPHY_LIST_REQUEST}${START}`:
            return true;
        case `${BIBLIOGRAPHY_LIST_REQUEST}${SUCCESS}`:
            return false;
        case `${BIBLIOGRAPHY_LIST_REQUEST}${ERROR}`:
            return false;

        default: return state;
    }
};