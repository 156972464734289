import { READER_SEARCH_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const readerSearchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${READER_SEARCH_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};