import { SERIES_EDIT_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const seriesCatalogEditRequestStart = (data) => ({
  type: `${SERIES_EDIT_REQUEST}${START}`,
  payload: data
});

export const seriesCatalogEditRequestSuccess = (data) => {
  createNotification('success', 'Серия успешно отредактирована');
  return {
    type: `${SERIES_EDIT_REQUEST}${SUCCESS}`,
    payload: data
  }
};

export const seriesCatalogEditRequestError = (data) => {
  createNotification('error', 'Не удалось отредактировать серию');
  return {
    type: `${SERIES_EDIT_REQUEST}${ERROR}`,
    payload: data
  }
};
