import React, { useEffect, useCallback, useState } from 'react'
import styles from './OptionsScene.module.css'
import { Tab, Tabs } from 'react-bootstrap'
import { optionsGetRequestStart } from '../../redux/actions/options/get'
import { connect } from 'react-redux'
import { libraryInstitutionsRequestStart } from '../../redux/actions/library/info/institutions'
import { setOptionsData } from '../../redux/actions/options/setData'
import { optionsSaveRequestStart } from '../../redux/actions/options/save'
import { optionsLogoGetRequestStart } from '../../redux/actions/options/logo/get'
import { optionsLogoSaveRequestStart } from '../../redux/actions/options/logo/save'
import { optionsMenuSaveRequestStart } from '../../redux/actions/options/menu/save'
import { outsecrecyToOptions } from '../../utils/toSelectOption'
import { setLayoutOptionsData } from '../../redux/actions/options/layout/setFormData'
import { layoutOptionsSaveRequestStart } from '../../redux/actions/options/layout/save'
import { outsecrecyCountRequestStart } from '../../redux/actions/outsecrecy/count'
import { layoutImagesListRequestStart } from '../../redux/actions/options/layout/layoutImages'
import MainOptions from '../../components/Options/MainOptions'
import LayoutOptions from '../../components/Options/LayoutOptions'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SDPOptions from '../../components/Options/SDPOptions'
import SDPT645 from '../../components/Options/SDPT645'
import MenuEditor from '../../components/Options/MenuEditor'
import FilesFormat from '../../components/Options/FilesFormat'
import { api } from '../../App'
import { Redirect } from 'react-router'
import LibraryDiary from '../../components/Options/LibraryDiary'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const mapStateToProps = ({ options, library, outsecrecy, auth }) => ({
  options: options.data,
  saveStatus: options.status,
  institutions: library.info.institutions,
  logoUrl: options.logo,
  outsecrecyList: outsecrecy.list,
  layoutOptions: options.layout,
  headerImages: options.headerImages,
  authData: auth.data,
})

const mapDispatchToProps = {
  requestOptions: optionsGetRequestStart,
  setOptions: setOptionsData,
  saveOptions: optionsSaveRequestStart,
  requestInstitutions: libraryInstitutionsRequestStart,
  requestLogo: optionsLogoGetRequestStart,
  saveLogo: optionsLogoSaveRequestStart,
  saveMenu: optionsMenuSaveRequestStart,
  requestOutsecrecy: outsecrecyCountRequestStart,
  setLayoutOptions: setLayoutOptionsData,
  saveLayoutOptions: layoutOptionsSaveRequestStart,
  requestHeaderImages: layoutImagesListRequestStart,
}

const OptionsScene = ({
  authData,
  options,
  requestOptions,
  institutions,
  requestInstitutions,
  requestLogo,
  saveLogo,
  saveMenu,
  logoUrl,
  setOptions,
  saveOptions,
  saveStatus,
  outsecrecyList,
  requestOutsecrecy,
  layoutOptions,
  saveLayoutOptions,
  setLayoutOptions,
  requestHeaderImages,
  headerImages,
}) => {
  const [scanRate, setScanrate] = useState(null)
  const [menuList, setmenuList] = useState([]);

  const { state: defaulTab } = useLocation()


  useEffect(() => {
    requestOptions()
  }, [requestOptions])

  useEffect(() => {
    requestInstitutions()
  }, [requestInstitutions])

  useEffect(() => {
    requestLogo()
  }, [requestLogo])

  useEffect(() => {
    requestOutsecrecy()
  }, [requestOutsecrecy])

  useEffect(() => {
    requestHeaderImages()
  }, [requestHeaderImages])

  const onLoadImage = e => {
    saveLogo(e.target.files[0])
  }

  const outsecrecyOptions = useCallback(() => {
    return outsecrecyToOptions(outsecrecyList)
  }, [outsecrecyList])

  const combineOptionsSave = () => {
    saveLayoutOptions()
    saveOptions()
    onPlannedScanRate()
  }

  const onPlannedScanRate = () => {
    const params = {
      days: scanRate,
    }
    api.put('/bookscanorderduedate/', params)
  }

  if (!authData.role_permissions.find(e => e.RasdelName === 'Настройка,"Общие настройки"')) return <Redirect to='/' />
  return (
    <SceneContainer title={'Настройки'}>
      {options && (
        <div className={styles.dataContainer}>
          <Tabs defaultActiveKey={defaulTab || 'index'} id={'OptionsTabs'}>
            <Tab eventKey='index' title='Основные'>
              <MainOptions
                options={options}
                setOptions={setOptions}
                institutions={institutions}
                outsecrecyOptions={outsecrecyOptions}
                logoUrl={logoUrl}
                onLoadImage={onLoadImage}
                saveStatus={saveStatus}
                setScanrate={setScanrate}
                scanRate={scanRate}
                combineOptionsSave={combineOptionsSave}
              />
            </Tab>
            <Tab eventKey='layout' title='Внешний вид'>
              <LayoutOptions
                options={layoutOptions}
                saveOptions={saveLayoutOptions}
                setOptionsData={setLayoutOptions}
                headerImages={headerImages}
                combineOptionsSave={combineOptionsSave}
              />
            </Tab>
            {/* Шифр */ options && Number(options.outsecrecy) === 3 && (
              <Tab eventKey='sdp' title='Сведения о Номенклатуре дел, книг и журналов СДП'>
                <SDPOptions 
                  options={options} 
                  setOptions={setOptions} 
                  saveOptions={saveOptions}
                  combineOptionsSave={combineOptionsSave} />
              </Tab>
            )}
            <Tab eventKey='sdpT645' title='Интеграция с изделием 83т645'>
              <SDPT645 
                options={options} 
                setOptions={setOptions} 
                saveOptions={saveOptions}
                combineOptionsSave={combineOptionsSave} />
            </Tab>
            <Tab eventKey='menu' title='Настройка меню'>
              <MenuEditor options={options} saveMenu={saveMenu} setOptions={setOptions} saveOptions={saveOptions} menuList={menuList} setmenuList={setmenuList}/>
            </Tab>
            <Tab eventKey='filesFormat' title='Загружаемые документы'>
              <FilesFormat options={options} saveMenu={saveMenu} setOptions={setOptions} saveOptions={saveOptions}/>
            </Tab>
            <Tab eventKey='libraryDiary' title='Дневник работы библиотеки'>
              <LibraryDiary/>
            </Tab>
          </Tabs>
          
        </div>
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsScene)
