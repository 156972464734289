import React from 'react';
import T from 'prop-types';
import {Table, Typography} from "antd";
import moment from "moment";
import {splitSumToRubAndCop} from "../../../utils/helpers/helpers";

const FundMovingTable = ({data}) => {

    const {Column, ColumnGroup} = Table;
    const {Text} = Typography;

    const DOC_MOVING_TYPES = {
        COMING: "Поступило",
        OUT: "Выбыло"
    }


    return (
        <Table
            dataSource={data}
            title={() => "Книга движения фонда"}
            size="small"
            scroll={{ y: 500 }}
            summary={pageData => {
                const data = {
                    coming: {
                        sum: 0,
                        links: 0,
                        items: 0
                    },
                    out: {
                        sum: 0,
                        links: 0,
                        items: 0
                    }
                }

                pageData.forEach(row => {
                   const movingType = row.doc_moving === DOC_MOVING_TYPES.COMING ? 'coming' : 'out';

                   data[movingType].sum += row.summ;
                   data[movingType].links += row.link_count;
                   data[movingType].items += row.book_count;
                });

                return (
                    <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3}>
                            <Text strong>Итого:</Text>
                        </Table.Summary.Cell>

                        {/*Поступило*/}
                        <Table.Summary.Cell>
                            <Text strong>{splitSumToRubAndCop(data.coming.sum)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                            <Text strong>{data.coming.links}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                            <Text strong>{data.coming.items}</Text>
                        </Table.Summary.Cell>

                        {/*Выбыло*/}
                        <Table.Summary.Cell>
                            <Text strong>{splitSumToRubAndCop(data.out.sum)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                            <Text strong>{data.out.links}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                            <Text strong>{data.out.items}</Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                )
            }}
            pagination={false}
            bordered
        >
            <Column title="Дата КСУ"
                    dataIndex="date_ksu"
                    render={(text, record) => moment(text).format('DD.MM.YYYY')}
            />
            <Column title="Номер КСУ" dataIndex="num_ksu" key="num_ksu"/>
            <Column title="Номер, дата документа"
                    dataIndex="child_type"
                    render={(text, record) => {
                        const docDate = record.document_arrival_date;
                        const docNum = record.documentnumber;
                        return "№ " + docNum + " от " + moment(docDate).format('DD.MM.YYYY')
                    }}
            />
            <ColumnGroup title="Поступило">
                <Column title="Сумма" dataIndex="doc_moving" render={(text, record) => {
                    if (text === DOC_MOVING_TYPES.COMING) {
                        return splitSumToRubAndCop(record.summ)
                    }
                }}/>
                <Column title="Названий" dataIndex="doc_moving" render={(text, record) => {
                    if (text === DOC_MOVING_TYPES.COMING) {
                        return record.link_count
                    }
                }}/>
                <Column title="Экземпляров" dataIndex="doc_moving" render={(text, record) => {
                    if (text === DOC_MOVING_TYPES.COMING) {
                        return record.book_count
                    }
                }}/>
            </ColumnGroup>
            <ColumnGroup title="Выбыло">
                <Column title="Сумма" dataIndex="doc_moving" render={(text, record) => {
                    if (text === DOC_MOVING_TYPES.OUT) {
                        return splitSumToRubAndCop(record.summ)
                    }
                }}/>
                <Column title="Названий" dataIndex="doc_moving" render={(text, record) => {
                    if (text === DOC_MOVING_TYPES.OUT) {
                        return record.link_count
                    }
                }}/>
                <Column title="Экземпляров" dataIndex="doc_moving" render={(text, record) => {
                    if (text === DOC_MOVING_TYPES.OUT) {
                        return record.book_count
                    }
                }}/>
            </ColumnGroup>
        </Table>
    );
};

FundMovingTable.propTypes = {
    data: T.array
};

export default FundMovingTable;
