import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { ReactReader } from 'react-reader'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { epubReaderBookRequestStart } from '../../redux/actions/epubReader/book'
import { resolveFileApiLink } from '../../utils/resolvePdfLink'
import { BASE_URL_WITHOUT_API } from '../../constants/api'

const mapStateToProps = ({ epubReader }) => ({
  book: epubReader.book,
})

const mapDispatchToProps = {
  requestBook: epubReaderBookRequestStart,
}

const storage = global.localStorage || null

const EPUBReader = ({ match, requestBook, book }) => {
  const { bookId, fileId } = match.params
  const bookTitle = (book && book.title) || ''

  const [bookUrl, setBookUrl] = useState('')

  useEffect(() => {
    requestBook(bookId)
  }, [requestBook, bookId])

  useEffect(() => {
    resolveFileApiLink(bookId, fileId).then(response => setBookUrl(`${BASE_URL_WITHOUT_API}${response.data.url}`))
  }, [])

  const [location, setLocation] = useState(storage && storage.getItem('epub-location') ? storage.getItem('epub-location') : 2)

  const onLocationChanged = location => {
    setLocation(location)
  }

  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <ReactReader
        url={bookUrl}
        title={bookTitle}
        loadingView={<SpinnerOuter />}
        location={location}
        locationChanged={onLocationChanged}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EPUBReader)
