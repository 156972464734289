import React from 'react';
import T from 'prop-types';
import classNames from 'classnames';
import styles from './MessageModal.module.css';
import Button from "../Button";

const MessageModal = ({ message, onClick, children, className }) => {
  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.modal}>
        <div className={styles.text}>
          {message}
        </div>
        {children ? children :
          <Button onClick={onClick} label={"ОК"}/>
        }
      </div>
    </div>
  );
};

MessageModal.propTypes = {
  message: T.string,
  onClick: T.func,
  className: T.string
};

export default MessageModal;
