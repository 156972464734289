import React, { useEffect } from 'react'
import T from 'prop-types'
import { ADD, EDIT } from '../../../constants/modalTypes'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import ModalInputWrapper from '../../Modal/InputWrapper'
import { nonNull } from '../../../utils/strings'
import styles from './AdminRolesModal.module.css'
import Modal from '../../Modal'
import RoleMembersList from '../MembersList'

const AdminRolesModal = ({
  setModalData,
  onSubmit,
  modalData,
  members,
  requestMembers,
  onSaveRoleMember,
  onDeleteRoleMember,
  roleClearNewMembers,
  newMembers,
}) => {
  const { modalType, data } = modalData
  const pmid = data ? data.pmid : null
  const isVisible = !!modalType

  if (modalType === ADD && !data) {
    setModalData({
      modalType,
      data: {
        name: '',
      },
    })
  }

  useEffect(() => {
    modalType === EDIT && requestMembers(pmid)
  }, [modalType, pmid, requestMembers])

  const onChange = (name, value) => {
    setModalData({ modalType, data: { ...data, [name]: value } })
  }

  const checkedOnSubmit = () => {
    data.name ? onSubmit(modalData) : setModalData({ ...modalData, error: true })
  }

  return (
    <Modal
      title={`Роль: ${modalType === EDIT ? 'редактирование' : 'добавление'}`}
      isVisible={isVisible}
      contentClassName={styles.modalContent}
    >
      <Tabs defaultActiveKey='index' id={'AdminRolesModalTabs'}>
        <Tab eventKey='index' title='Главная' className={styles.tabContent}>
          {data && (
            <Row>
              <Col md={12}>
                <ModalInputWrapper
                  label={'Название'}
                  value={nonNull(data.name)}
                  onChange={({ target: { value } }) => onChange('name', value)}
                />
              </Col>
              <Col md={8}>
                {modalData.error && <div className={styles.errorMessage}>Вы должны заполнить хотя бы одно поле!</div>}
              </Col>
              <Col md={4}>
                <div className={styles.bottomSection}>
                  <div className={styles.button} onClick={checkedOnSubmit}>
                    Сохранить
                  </div>
                  <div className={styles.button} onClick={() => setModalData({ modalType: null, data: null })}>
                    Отмена
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Tab>
        <Tab eventKey='members' title='Состав'>
          <RoleMembersList
            newMembers={newMembers}
            modalType={modalType}
            data={members}
            roleClearNewMembers={roleClearNewMembers}
            onDeleteRoleMember={onDeleteRoleMember}
            onSaveRoleMember={onSaveRoleMember}
            onSubmit={checkedOnSubmit}
            onClose={() => setModalData({ modalType: null, data: null })}
          />
        </Tab>
      </Tabs>
    </Modal>
  )
}

AdminRolesModal.propTypes = {
  modalData: T.object,
  setModalData: T.func,
  onSubmit: T.func,
  requestMembers: T.func,
  onSaveRoleMember: T.func,
}

export default AdminRolesModal
