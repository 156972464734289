import {
    LIBRARY_MATERIALS_LIST_REQUEST,
    LIBRARY_INSTITUTIONS_REQUEST,
    SUCCESS,
    START, SET_LOCATION_CHANGE
} from "../../../actions/action-types";


const initialState = [];

export const libraryMaterialsListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${LIBRARY_MATERIALS_LIST_REQUEST}${SUCCESS}`:
            return action.payload;
        case `${LIBRARY_INSTITUTIONS_REQUEST}${START}`:
            return initialState;
        case SET_LOCATION_CHANGE:
            return initialState;
        default:
            return state;
    }
};
