import { combineReducers } from 'redux'
import { importBooksErrorReducer } from './error/importBooksErrorReducer'
import { importBooksFormReducer } from './form/importBooksFormReducer'
import { importBooksDataReducer } from './data/importBooksDataReducer'
import { importBooksLoaderReducer } from './loader/importBooksLoaderReducer'

import { jsonReducer } from './json/jsonReducer'
import { zipURLReducer } from './zipURL/zipURLReducer'
import { rusMarcReducer } from './rusMarc/rusMarcReducer'

export const importBooksReducer = combineReducers({
  data: importBooksDataReducer,
  error: importBooksErrorReducer,
  form: importBooksFormReducer,
  loader: importBooksLoaderReducer,
  json: jsonReducer,
  zipURL: zipURLReducer,
  rusMarc:rusMarcReducer,
})
