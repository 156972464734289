import { ERROR, PERMISSIONS_SAVE_REQUEST, START, SUCCESS } from "../action-types";

export const permissionsSaveRequestStart = (roleId, section, value) => ({
  type: `${PERMISSIONS_SAVE_REQUEST}${START}`,
  payload: { roleId, section, value }
});

export const permissionsSaveRequestSuccess = (data) => ({
  type: `${PERMISSIONS_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const permissionsSaveRequestError = (data) => ({
  type: `${PERMISSIONS_SAVE_REQUEST}${ERROR}`,
  payload: data
});
