import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import { splitArrayIntoChunks, splitSumToRubAndCop } from '../../../utils/helpers/helpers'
import moment from 'moment'

const BookYearEndTable = ({ data, loader }) => {
  const { Column, ColumnGroup } = Table

  const transformFunds = data => {
    let _data = data
      .filter(item => item.type_order > 14)
      .sort((a, b) => {
        if (a.type_order > b.type_order) {
          return 1
        }
        if (a.type_order < b.type_order) {
          return -1
        }
        return 0
      })

    return splitArrayIntoChunks(_data)
  }

  const transformData = useMemo(() => {
    return [
      {
        sum_all: data.find(item => item.type_order === 1),
        instance_all: data.find(item => item.type_order === 2),
        books: data.find(item => item.type_order === 11),
        brochures: data.find(item => item.type_order === 12),
        magazines: data.find(item => item.type_order === 13),
        papers: data.find(item => item.type_order === 14),
        funds: transformFunds(data) || [],
      },
    ]
  }, [data])

  if (loader) {
    return <SpinnerOuter />
  }
  if (!data.length) {
    return null
  }
  return (
    <div style={{width: '100%', overflow:'auto'}}>
      <Table dataSource={transformData} title={() => 'Закрытие года'} pagination={false} size='small' bordered>
      <Column title='' dataIndex='sum_all' render={row => `Состоит на 01.01.${moment(row.date_to).format('YYYY')} г.`} />
      <ColumnGroup title='всего поступило / выбыло'>
        <Column title=' на сумму, руб.' dataIndex='sum_all' render={row => splitSumToRubAndCop(row.summation)} />
        <Column title='экземпл.' dataIndex='instance_all' render={row => row.summation} />
      </ColumnGroup>
      <ColumnGroup title='в том числе по видам документов'>
        <Column title='Книги' dataIndex='books' render={row => row.summation} />
        <Column title='Брошюры' dataIndex='brochures' render={row => row.summation} />
        <Column title='Журналы' dataIndex='magazines' render={row => row.summation} />
        <Column title='Газеты (год.компл.)' dataIndex='papers' render={row => row.summation} />
      </ColumnGroup>
      {transformData[0]?.funds.map((item, index) => (
        <ColumnGroup title={item[0].type_name} key={index}>
          <Column title='на сумму, руб.' dataIndex='funds' render={row => splitSumToRubAndCop(row[index][0].summation)} />
          <Column title='всего экземпляров' dataIndex='funds' render={row => row[index][1].summation} />
        </ColumnGroup>
      ))}
    </Table>
    </div>
  )
}

BookYearEndTable.propTypes = {
  data: PropTypes.array,
}

export default BookYearEndTable
