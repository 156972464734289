import { ERROR, LIBRARY_SAVE_POINT_REQUEST, START, SUCCESS } from "../../../action-types";

export const librarySavePointRequestStart = (data, libraryId) => ({
  type: `${LIBRARY_SAVE_POINT_REQUEST}${START}`,
  payload: {
    data, libraryId
  }
});

export const librarySavePointRequestSuccess = (data) => ({
  type: `${LIBRARY_SAVE_POINT_REQUEST}${SUCCESS}`,
  payload: data
});

export const librarySavePointRequestError = (data) => ({
  type: `${LIBRARY_SAVE_POINT_REQUEST}${ERROR}`,
  payload: data
});
