import React, { useState } from 'react'
import T from 'prop-types'
import styles from '../../../Covers/List/Item/CoversListItem.module.css'
import classNames from 'classnames'
import moment from 'moment'
import { downloadFileTwo, downloadFile } from '../../../../utils/helpers/helpers'
import { Spin } from 'antd'
import { saveAs } from 'file-saver'
import { api } from '../../../../App'
import { BASE_URL_WITHOUT_API } from '../../../../constants/api'
import { createNotification } from '../../../notification/notification'
import { getBookFile } from '../../../../api/book/files'

const ReadingListUploadingFiles = ({ fileList, bookId }) => {
  const [isLoading, setIsLoading] = useState(false)


  return (
    <div className={styles.filesOpen}>
      <div className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
        <div>
          <b>Название</b>
        </div>
        <div>
          <b>Дата загрузки</b>
        </div>
      </div>
      {fileList.map((item, index) => (
        <div key={`fileListItem${index}`} className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
          <div>{item.filename}</div>
          <div>{moment(item.filedate).format('DD.MM.YYYY')}</div>
          <div>
            {
            isLoading ? (
              <Spin />
            ) : (
              <span
                onClick={() => {
                  setIsLoading(true)
                    downloadFile(`/book/${bookId}/file/${item.id}`, `${item.filename}`).then(() => setIsLoading(false))
                }}
                className={'tableButton'}
              >
                Скачать
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

ReadingListUploadingFiles.propTypes = {
  fileList: T.array,
  bookId: T.string,
}

export default ReadingListUploadingFiles
