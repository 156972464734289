import {SET_SERIES_FORM_DATA} from "../../../actions/action-types";


const initialState = {
  name: "",
};

export const seriesFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERIES_FORM_DATA:
      return action.payload;

    default: return state;
  }
};
