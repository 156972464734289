import { all } from 'redux-saga/effects';
import documentsSaga from "./document/documentsSaga";
import documentBooksSaga from "./books/documentBooksSaga";
import fundAllocationSaga from './fundAllocation/fundAllocationSaga';
import documentBookDistributeSaga from './bookDistribute/documentBookDistributeSaga';
import documentsSearchWorker from "./search/documentSearchSaga";

export default function* documentSaga() {
  yield all([
    documentsSaga(),
    documentsSearchWorker(),
    documentBooksSaga(),
    fundAllocationSaga(),
    documentBookDistributeSaga()
  ]);
}
