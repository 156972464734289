import {call, put, takeLatest, select} from "redux-saga/effects";
import {
    DOCUMENT_TYPES_ALL_REQUEST,
    DOCUMENT_TYPES_COUNT_REQUEST, DOCUMENT_TYPES_DELETE_REQUEST,
    DOCUMENT_TYPES_LIST_REQUEST,
    DOCUMENT_TYPES_SAVE_REQUEST,
    START
} from "../../actions/action-types";
import {
    documentTypesCountRequestError,
    documentTypesCountRequestSuccess
} from "../../actions/documents/types/count";
import {
    documentTypesListRequestError,
    documentTypesListRequestStart,
    documentTypesListRequestSuccess
} from "../../actions/documents/types/list";
import {documentTypesSaveRequestError} from "../../actions/documents/types/save";
import {
    documentTypesDeleteRequestError,
    documentTypesDeleteRequestSuccess
} from "../../actions/documents/types/delete";
import {
    addDocumentType, deleteDocumentType,
    editDocumentType,
    getDocumentTypesCount,
    getDocumentTypesList
} from "../../../api/documents/types";
import {setDocumentTypesSearchData} from "../../actions/documents/types/search";
import {documentSaveRequestSuccess} from "../../actions/documents/documents/save";
import {documentTypesAllRequestError, documentTypesAllRequestSuccess} from "../../actions/documents/types/all";

export default function* documentTypesWatcher() {
    yield takeLatest(`${DOCUMENT_TYPES_COUNT_REQUEST}${START}`, documentTypesCountWorker);
    yield takeLatest(`${DOCUMENT_TYPES_LIST_REQUEST}${START}`, documentTypesListWorker);
    yield takeLatest(`${DOCUMENT_TYPES_SAVE_REQUEST}${START}`, documentTypesSaveWorker);
    yield takeLatest(`${DOCUMENT_TYPES_DELETE_REQUEST}${START}`, documentTypesDeleteWorker);
    yield takeLatest(`${DOCUMENT_TYPES_ALL_REQUEST}${START}`, documentTypesAllWorker);
}

function* documentTypesCountWorker() {
    const searchData = yield select(({documentTypes}) => documentTypes.search);
    try {
        const response = yield call(getDocumentTypesCount, searchData.query);
        const count = parseInt(response.data.count, 10);
        yield put(documentTypesCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setDocumentTypesSearchData({...searchData, pageCount}));
        yield put(documentTypesListRequestStart());
    } catch (error) {
        yield put(documentTypesCountRequestError(error))
    }
}

function* documentTypesListWorker() {
    const searchData = yield select(({documentTypes}) => documentTypes.search);
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getDocumentTypesList, searchData);
        yield put(documentTypesListRequestSuccess(response.data));
    } catch (error) {
        yield put(documentTypesListRequestError(error));
    }
}

function* documentTypesSaveWorker({payload: data}) {
    try {
        const response = yield call(data.id ? editDocumentType : addDocumentType, data);
        yield put(documentSaveRequestSuccess(response.data));
        yield put(documentTypesListRequestStart())
    } catch (error) {
        yield put(documentTypesSaveRequestError(error))
    }
}

function* documentTypesDeleteWorker({payload: id}) {
    try {
        const response = yield call(deleteDocumentType, id);
        yield put(documentTypesDeleteRequestSuccess(response.data));
        yield put(documentTypesListRequestStart());
    } catch (error) {
        yield put(documentTypesDeleteRequestError(error));
    }
}

function* documentTypesAllWorker() {
    const request = {
        paginateFrom: 0
    };
    try {
        const response = yield call(getDocumentTypesList, request);
        yield put(documentTypesAllRequestSuccess(response.data));
    } catch (error) {
        yield put(documentTypesAllRequestError(error));
    }
}
