import {PDF_VIEWER_BOOK_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = null;

export const pdfViewerBookReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${PDF_VIEWER_BOOK_REQUEST}${SUCCESS}`:
            return action.payload;


        default:
            return state;
    }
};