import { LOST_DOC_GET_REQUEST, SUCCESS } from '../../../actions/action-types'

const initialState = []

export const notebookLostDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOST_DOC_GET_REQUEST}${SUCCESS}`:
      return action.payload

    default:
      return state;
  }
}

