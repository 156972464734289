import { call, put, takeLatest } from "redux-saga/effects";
import {
  bookFileRequestSuccess,
  bookFileRequestError,
} from "../../actions/bookData/data";
import {
  getBookFile
} from "../../../api/book/bookFile";
import {BOOK_FILE_REQUEST, START} from "../../actions/action-types";

export default function* bookFileWatcher() {
  yield takeLatest(`${BOOK_FILE_REQUEST}${START}`, getBookFileWorker);
}

function* getBookFileWorker({ payload: bookId }) {
  try {
    const response = yield call(getBookFile, bookId);
    yield put(bookFileRequestSuccess(response.data));
  } catch (e) {
    yield put(bookFileRequestError(e));
  }
}
