import { LOST_DOC_COUNT_REQUEST, SET_LOST_DOC_SEARCH_DATA, SUCCESS } from '../../../actions/action-types'
import { RESULTS_PER_PAGE } from '../../../../constants/pagination'

const initialState = {
  limit: RESULTS_PER_PAGE,
  offset: 0,
  pageCount: 0,
  page: 1
}

export const lostDocSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOST_DOC_COUNT_REQUEST}${SUCCESS}`:
      return {
          ...state,
          pageCount: Math.ceil(+action.payload / state.limit)
      }
  
    case `${SET_LOST_DOC_SEARCH_DATA}`:
      return {...action.payload}
      

    default:
      return state
  }
}
