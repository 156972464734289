import { api } from "../../App";
import {SORT_DIRECTION} from "../../constants/sortDirections";

export const getBookTransferRequestCount = ({ search, ...other }) => {
  const params = {
    count: true,
    search,
    ...other
  };

  return api.get(`/booktransferrequest`, { params });
};

export const getBookTransferRequestList = ({ sortBy, maxResults, paginateFrom, sortDirection, search, documenttype, from, to }) => {
  const params = {
    maxResults,
    paginateFrom,
    documenttype,
    from,
    to,
    search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
  };

  return api.get(`/booktransferrequest`, { params });
};

export const addBookTransferItem = ({ bookid, clientid }) => {
  const params = {
    bookid,
    clientid
  };

  return api.post(`/booktransferrequest`, params);
};

export const deleteBookTransferItem = (itemId) => {
  return api.delete(`/booktransferrequest/${itemId}`)
};