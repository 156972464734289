import React from 'react';
import T from 'prop-types';
import styles from './RubricatorTable.module.css';
import { ADD, EDIT } from "../../../constants/modalTypes";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const RubricatorTable = ({ data, setModal, selectedId, handleSelect, onDelete, initialFormFields }) => {

  const openEditModal = (item) => setModal({ modalType: EDIT, data: item });
  const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields });

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={openCreationModal}>
          добавить
        </div>
        <div className={styles.button} onClick={onDelete}>
          удалить
        </div>
      </div>
      <DataTable data={data} onCellClick={openEditModal}>
        <DataTableCol title="Категория" value="name" colSpan={6} className={styles.leftAlign}/>
        <DataTableCol title="Количество книг" value="books" colSpan={1}/>
        <DataTableCol title="Выбрано" passClicks resolver={(item) =>
          <input type="checkbox" checked={item.id === selectedId}
                 onChange={() => handleSelect(item.id)}/>}
        />
      </DataTable>
    </div>
  );
};

RubricatorTable.propTypes = {
  data: T.array,
  setModal: T.func,
  initialFormFields: T.object.isRequired,
  selectedId: T.string,
  handleSelect: T.func,
  onDelete: T.func
};

RubricatorTable.defaultProps = {
  data: []
};

export default RubricatorTable;
