export const AgregationTableRowsOrg = [
    {
        label: "Название библиотеки",
        value: 'libraryname'
    },{
        label: "Статистические показатели библиотечного фонда, каталогов, картотек, справочно-библиографических данных",
        value: ''
    },{
        label: "Количество фондов",
        value: 'fundcount'
    },{
        label: "Количество книгохранилищ",
        value: 'bookstorecount'
    },{
        label: "Количество стеллажей",
        value: 'bookshelfcount'
    },{
        label: "Количество полок",
        value: 'shelfcount'
    },{
        label: "Количество названий всех видов документов и форм их представления",
        value: 'recordscount'
    },{
        label: "Количество БЗ с обложками",
        value: 'coverrecordscount'
    },{
        label: "Количество названий электронных документов",
        value: 'digitrecordcount'
    },{
        label: "Количество программ обучения",
        value: 'eduprogramcount'
    },{
        label: "Количество программ без связки с книгами в электронной библиотеке",
        value: 'eduprogramwithoutbookscount'
    },{
        label: "Количество дисциплин для обеспечения литературой",
        value: 'coursecount'
    },{
        label: "% обеспеченности бумажными изданиями основной литературы с учетом нормы ФГОС",
        value: 'mainbookFGOSpercent'
    },{
        label: "% обеспеченности бумажными изданиями основной литературы по требованиям МО РФ",
        value: 'mainbookMOpercent'
    },{
        label: "% обеспеченности бумажными изданиями дополнительной литературы с учетом нормы ФГОС",
        value: 'eduprogramwithoutbookscount'
    },{
        label: "% обеспеченности бумажными изданиями дополнительной литературы по требованиям МО РФ",
        value: 'extrabookMOpercent'
    },{
        label: "% обеспеченности электронными изданиями основной литературы",
        value: 'digitmainbookpercent'
    },{
        label: "% обеспеченности электронными изданиями дополнительной литературы",
        value: 'digitextrabookpercent'
    },{
        label: "Количество библиографических записей применяемых для обучения по основным образовательным направлениям",
        value: 'edurecordcount'
    },{
        label: "Количество библиографических записей применяемых для обучения по основным образовательным направлениям с обложками",
        value: 'educoverrecordcount'
    },{
        label: "Количество библиографических записей применяемых для обучения по основным образовательным направлениям с электронными экземпляром",
        value: 'edudigitrecordcount'
    },{
        label: "Количество входов пользователей",
        value: 'logincount'
    },{
        label: "Количество запросов на получение бумажных экземпляров",
        value: 'bookrequestcount'
    },{
        label: "Количество выданных бумажных экземпляров книг",
        value: 'booktakecount'
    },{
        label: "Количество выданных электронных книг",
        value: 'digitbooktakecount'
    },{
        label: "Количество гигабайт, занимаемых электронными документами на электронных накопителях библиотеки",
        value: 'allbooksize'
    },{
        label: "Количество гигабайт, занимаемых заархивированными (сжатыми) электронными документами на электронных накопителях библиотеки",
        value: 'zipbooksize'
    },{
        label: "Количество условных единиц учета по сумме всех видов документов и форм их представления",
        value: 'allinstancebzcount'
    },{
        label: "Выбывшие из фонда",
        value: 'outfond'
    },{
        label: "Машиночитаемые библиографические записи (количество)",
        value: 'machinelearning'
    },{
        label: "Машиночитаемые библиографические записи добавлены",
        value: 'supplierarrival'
    },{
        label: "Созданные библиотекой",
        value: 'allcreatedbooks'
    },{
        label: "Отредактированные машиночитаемые библиографические записи",
        value: 'modifiedbooks'
    },{
        label: "Машиночитаемые библиографические записи, выгруженные в другие системы",
        value: 'othersystemsbook'
    },{
        label: "Количество пользователей всего",
        value: 'allsystemusers'
    },{
        label: "Количество созданных пользователей",
        value: 'allsystemusers'
    },{
        label: "В том числе, количество сотрудников учебного заведения",
        value: 'allsystemusers'
    },{
        label: "В том числе, количество учащихся учебного заведения",
        value: 'allsystemusers'
    },{
        label: "В том числе «внешние» пользователи (сотрудники и учащиеся других учебных заведений, гражданские лица)",
        value: 'allsystemusers'
    },{
        label: "Посещения библиотеки в целом, по ее структурным подразделениям, зафиксированные в контрольном листке или иной форме библиотечной регистрации",
        value: 'userslogins'
    },
    {
        label: 'Обращения в библиотеку посредством средств коммуникации (телефон, почта, факс, телеграф, электронная почта)',
        value: 'otherlinkscount' 
    },
    {
        label: 'Обращения к удаленным лицензионным ресурсам, предоставляемые библиотекой',
        value: 'otherlinkscount' 
    },
    {
        label: 'Количество документов, размещённых в ЭБ МО РФ',
        value: 'scaneddocscount' 
    },
    {
        label: "Обращения к электронным каталогам и справочно-библиографическим базам данных",
        value: 'guidescount'
    },{
        label: "Обращения к электронным ресурсам, размещенным на веб-сайтах библиотеки",
        value: 'otherlinkscount'
    },{
        label: "Посещения библиотечных веб-сайтов всех уровней, имеющих отдельные счетчики",
        value: 'alllinkscount'
    },{
        label: "Статистические показатели сканирования и оцифровки учебных (научных) материалов, текстовых документов/книг",
        value: ''
    },{
        label: 'Количество названий электронных документов в удаленных сетевых ресурсах, к которым библиотекой утрачено право доступа',
        value: 'lost_docs_count'
    },{
        label: "Количество поданных заявок на сканирование/оцифровку",
        value: 'scanorders'
    },{
        label: "Количество выполненных заявок на сканирование/оцифровку",
        value: 'donescanorders'
    },{
        label: "Количество отсканированных документов",
        value: 'scaneddocscount'
    },{
        label: "Количество страниц отсканированных и оцифрованных документов (всего)",
        value: 'scaneddocscount_all'
    },{
        label: "Количество страниц отсканированных и оцифрованных документов (A0)",
        value: 'scaneddocscount_a0'
    },{
        label: "Количество страниц отсканированных и оцифрованных документов (А1)",
        value: 'scaneddocscount_a1'
    },{
        label: "Количество страниц отсканированных и оцифрованных документов (А2)",
        value: 'scaneddocscount_a2'
    },{
        label: "Количество страниц отсканированных и оцифрованных документов (А3)",
        value: 'scaneddocscount_a3'
    },{
        label: "Количество страниц отсканированных и оцифрованных документов (А4)",
        value: 'scaneddocscount_a4'
    },{
        label: "Количество страниц отсканированных и оцифрованных документов (А5)",
        value: 'scaneddocscount_a5'
    },{
        label: "Среднее время сканирования документа",
        value: 'averagetimescan'
    },{
        label: "Количественное и процентное соотношение плановых и фактических показателей сканирования/оцифровки",
        value: 'planscan'
    },{
        label: "Фактические показатели сканирования и оцифровки",
        value: 'factscan'
    },{
        label: "Процентное соотношение плановых и фактических показателей сканирования/оцифровки",
        value: 'scanpercent'
    },{
        label: "Статистические показатели разработки ЭОР",
        value: ''
    },{
        label: "Количество разработанных ЭОР (по исполнителям, предметным областям и т.д.)",
        value: 'importdocs'
    },{
        label: "Показатели объемно-содержательного состава ЭОР (количество и объём разделов, количество и объём графических и/или мультимедийных объектов, количество вопросов в тестах и т.д.)",
        value: 'allfilessize'
    },{
        label: "Количество ЭОР размещённых в ЭБ МО РФ (общее и процентное соотношение к числу разработанных)",
        value: 'relationimportdocs'
    },{
        label: "Cредний (в пересчёте на один ЭОР) объём разработанных ЭОР (Гб/ЭОР)",
        value: 'averagefilessize'
    },{
        label: 'Количество выбывших из фонда условных единиц учета по сумме всех видов документов и форм их представления (исключения за заданный период)',
        value: 'outfond'
    }
];

export const AgregationTableRowsLibs = [
    {
        label: "Название библиотеки",
        value: 'libraryname'
    },{
        label: "Количество фондов",
        value: 'fundcount'
    },{
        label: "Количество книгохранилищ",
        value: 'bookstorecount'
    },{
        label: "Количество стеллажей",
        value: 'bookshelfcount'
    },{
        label: "Количество полок",
        value: 'shelfcount'
    },
    // {
    //     label: "Количество выданных бумажных экземпляров книг",
    //     value: 'booktakecount'
    // }
];
