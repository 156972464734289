import { api } from '../../App';

export const getDynamicCollectionData = (collectionId) => {
  return api.get(`/collection/dynamic/${collectionId}`);
};

export const addDynamicCollectionData = (collectionId, data) => {
  return api.post(`/collection/dynamic/${collectionId}`, data)
};

export const editDynamicCollectionData = (collectionId, data) => {
  return api.put(`/collection/dynamic/${collectionId}/${data.id}`, data);
};

export const deleteDynamicCollectionData = (collectionId, dataId) => {
  return api.delete(`/collection/dynamic/${collectionId}/${dataId}`)
};