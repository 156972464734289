import {BOOK_SOURCE_REQUEST, SUCCESS} from "../../actions/action-types";

const initialState = [];

export const bookSourceReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${BOOK_SOURCE_REQUEST}${SUCCESS}`:
            return [...action.payload];

        default: return state;
    }
};