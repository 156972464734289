import { MILITARY_RANK_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const militaryRankReducer = (state = initialState, action) => {
  switch (action.type) {

      case `${MILITARY_RANK_REQUEST}${SUCCESS}`:
          return action.payload;

      default:
          return state;
  }
};