import {
  ERROR,
  ADMIN_MODAL_USERS_REQUEST,
  START,
  SUCCESS
} from "../action-types";

export const adminModalUsersRequestStart = () => ({
  type: `${ADMIN_MODAL_USERS_REQUEST}${START}`
});

export const adminModalUsersRequestSuccess = data => ({
  type: `${ADMIN_MODAL_USERS_REQUEST}${SUCCESS}`,
  payload: data
});

export const adminModalUsersRequestError = error => ({
  type: `${ADMIN_MODAL_USERS_REQUEST}${ERROR}`,
  payload: error
});
