import {api} from "../../App";
import {SORT_DIRECTION} from "../../constants/sortDirections";

export const getSchedulerListCount = ({search, ...other}) => {
    const params = {
        count: true,
        search,
        ...other
    };

    return api.get(`/scheduler`, {params});
};

export const getSchedulerList = ({sortBy, maxResults, paginateFrom, sortDirection, search}) => {
    const params = {
        maxResults,
        paginateFrom,
        search,
        [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
    };

    return api.get(`/scheduler`, {params});
};


export const getSchedulerCategoryCount = (id) => {
    const params = {
        count: true
    };

    return api.get(`/scheduler/${id}`, {params});
};

export const getSchedulerCategoryList = (id, maxResults, paginateFrom) => {
    const params = {
        maxResults,
        paginateFrom
    }
    return api.get(`/scheduler/${id}`, {params});
};

export const addSchedulerItem = ({id, day = 1, schedule, interval}) => {
    const params = {
        day,
        schedule,
        interval
    };

    return api.post(`/scheduler/${id}`, {params});
};

export const setEnabledSchedulerItem = (id, enabled) => {
    const body = {
        enabled
    };

    return api.put(`/scheduler/enabled/${id}`, body);
};

export const editSchedulerItem = ({day, schedule, interval, id}) => {
    const body = {
        day,
        schedule,
        interval
    };

    return api.put(`/scheduler/${id}`, body);
};

export const deleteSchedulerItem = (id) => {
    return api.delete(`/scheduler/${id}`);
};