import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import LibrariesDiaryForm from '../../../components/Reports/LibrariesDiary/Form'
import LibrariesDiaryPdfPrint from '../../../components/Reports/LibrariesDiary/PdfPrint'
import LibrariesDiaryTable from '../../../components/Reports/LibrariesDiary/Table'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import { libraryListRequestStart } from '../../../redux/actions/library/info/list'
import { libraryTypesListRequestStart } from '../../../redux/actions/library/types/list'
import { reportGetRequestStart } from '../../../redux/actions/reports/get'
import { setReportsSearchData } from '../../../redux/actions/reports/searchData'
import { eventReportRequestStart } from '../../../redux/actions/reports/eventsReport'

const mapStateToProps = ({ reports, library, options }) => ({
  data: reports.report,
  loader: reports.loader,
  searchData: reports.search,
  librariesList: library.info.list,
  typesList: library.types.list,
  optionsData: options.data,
  eventReportData: reports.report.eventsReport
})

const mapDispatchToProps = {
  requestReport: reportGetRequestStart,
  getLibrariesList: libraryListRequestStart,
  setSearchData: setReportsSearchData,
  getTypesList: libraryTypesListRequestStart,
  getEventsReport: eventReportRequestStart,
}

const validationSchema = Yup.object().shape({
  year: Yup.string().required('Выберите год'),
  month: Yup.string().required('Выберите месяц'),
  library: Yup.string().required('Выберите библиотеку'),
})

const LibrariesDiaryReportScene = ({
  ownId,
  data,
  typesList,
  getTypesList,
  requestReport,
  loader,
  librariesList,
  getLibrariesList,
  optionsData,
  searchData,
  setSearchData,
  eventReportData,
  getEventsReport
}) => {
  const [selectedMonth, setSelectedMonth] = useState(0)
  const [months, setMonths] = useState([
    { id: 1, selected: true },
    { id: 2, selected: false },
    { id: 3, selected: false },
    { id: 4, selected: false },
    { id: 5, selected: false },
    { id: 6, selected: false },
    { id: 7, selected: false },
    { id: 8, selected: false },
    { id: 9, selected: false },
    { id: 10, selected: false },
    { id: 11, selected: false },
    { id: 12, selected: false },
  ])


  useEffect(() => {
    !typesList.length && getTypesList()
  }, [])
  

  useEffect(() => {
    if (optionsData) {
      getLibrariesList(optionsData.own_client_id)
    }
  }, [getLibrariesList, optionsData])

  const submitForm = (values) => {
    const {month, year, library} = values
    const maxResults = month == 0 ? 366 : moment(parseInt(month)).daysInMonth()
    const {from_date, to_date} = getAtartEndOfPeriod(year, month)
    const library_id = parseInt(library) ? library : undefined
    setSearchData({...searchData, page: 1, maxResults})
    requestData(values)
    getEventsReport({library_id, from_date, to_date})
  }

  const getAtartEndOfPeriod = (year, month) => {
    const lastDayOfMonth = month == 0 ? 31 : moment({year, month: month-1}).daysInMonth()
    const from_date = moment({year, month: month == 0 ? 0 : month-1, day: 1}).format('YYYY-MM-DD')
    const to_date = moment({year, month: month == 0 ? 11 : month-1, day: lastDayOfMonth}).format('YYYY-MM-DD')

    return {from_date, to_date}
  }

  const searchForm = useFormik({
    initialValues: {
      year: moment().format('YYYY'),
      month: 0,
      // Шифр
      library: 0,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: submitForm
  })

  function requestData(formData) {
    requestReport({
      id: ownId,
      formData,
    })
  }

  const MonthsPagination = () => (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      {months.map(e => (
        <button
          style={{ background: 'none', border: 'none', outline: 'none', color: e.selected ? 'red' : 'gray', fontSize: '16px' }}
          onClick={_ => {
            setSelectedMonth(e.id-1)
            setMonths(prev => prev.map(month => ({ id: month.id, selected: month.id === e.id ? true : false })))
          }}
        >
          {e.id}
        </button>
      ))}
    </div>
  )

  const filteredData = data[ownId]?.length > 31 
    ? data[ownId].filter(date => moment(date.date).month() === selectedMonth)
    : data[ownId]

  return (
    <SceneContainer title={'Отчеты / Дневник работы библиотеки'}>
      <div className={'d-flex justify-content-end'}>
        <LibrariesDiaryPdfPrint data={data[ownId]} librariesList={librariesList} searchFormValue={searchForm.values} />
      </div>
      <LibrariesDiaryForm librariesList={librariesList} searchForm={searchForm} />
        {data[ownId]?.length > 31 && <MonthsPagination />}
        {data[ownId]?.length > 31 && (
        <div id='libraries-diary-table-for-the-year' style={{ display: 'none' }}>
          <LibrariesDiaryTable
            data={filteredData}
            dateFormat='D MMMM'
            typesList={typesList}
          />
        </div>
      )}
      {loader ?
        <SpinnerOuter isLoading={loader} />
        : <></>
      }

      {data[ownId]?.length && !loader &&
        <LibrariesDiaryTable
          data={filteredData}
          dateFormat='DD.MM.YYYY'
          typesList={typesList}
          eventReportData={eventReportData}
          librariesList={librariesList}
        />
      }

    </SceneContainer>
  )
}

LibrariesDiaryReportScene.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(LibrariesDiaryReportScene)
