import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  DOCUMENT_NUMBER_REQUEST,
  INVENTORY_COUNT_REQUEST,
  INVENTORY_DELETE_REQUEST,
  INVENTORY_LIST_REQUEST,
  INVENTORY_SAVE_REQUEST,
  START,
} from '../../../actions/action-types'
import {
  addDocument,
  deleteInventory,
  editDocument,
  getDocumentNumber,
  getInventoryCount,
  getInventoryList,
} from '../../../../api/inventory/documents'
import { documentSaveRequestError } from '../../../actions/documents/documents/save'
import { setLatestInventory } from '../../../actions/inventory/documents/latestDocument'
import { documentNumberRequestError, documentNumberRequestSuccess } from '../../../actions/documents/documents/number'
import {
  inventoryCountRequestError,
  inventoryCountRequestStart,
  inventoryCountRequestSuccess,
} from '../../../actions/inventory/documents/count'
import { inventorySaveRequestSuccess } from '../../../actions/inventory/documents/save'
import { inventoryDeleteRequestError, inventoryDeleteRequestSuccess } from '../../../actions/inventory/documents/delete'
import {
  inventoryListRequestError,
  inventoryListRequestStart,
  inventoryListRequestSuccess,
} from '../../../actions/inventory/documents/list'
import { setInventorySearchData } from '../../../actions/inventory/documents/search'

export default function* documentWatcher() {
  yield takeLatest(`${INVENTORY_COUNT_REQUEST}${START}`, inventoryCountWorker)
  yield takeLatest(`${INVENTORY_LIST_REQUEST}${START}`, documentListWorker)
  yield takeLatest(`${INVENTORY_SAVE_REQUEST}${START}`, documentSaveWorker)
  // yield takeLatest(`${DOCUMENT_NUMBER_REQUEST}${START}`, documentNumberWorker)
  yield takeLatest(`${INVENTORY_DELETE_REQUEST}${START}`, documentDeleteWorker)
}

function* inventoryCountWorker() {
  const searchData = yield select(({ inventory }) => inventory.search)
  try {
    const response = yield call(getInventoryCount, searchData.query)
    const count = parseInt(response.data.count, 10)
    yield put(inventoryCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setInventorySearchData({ ...searchData, pageCount }))
    yield put(inventoryListRequestStart())
  } catch (error) {
    yield put(inventoryCountRequestError(error))
  }
}

function* documentListWorker() {
  const searchData = yield select(({ inventory }) => inventory.search)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(getInventoryList, searchData)
    yield put(inventoryListRequestSuccess(response.data))
    // yield put(documentNumberRequestStart());
  } catch (error) {
    yield put(inventoryListRequestError(error))
  }
}

function* documentSaveWorker({ payload: data }) {
  try {
    const response = yield call(data.id ? editDocument : addDocument, data)
    yield put(inventorySaveRequestSuccess(response.data))
    yield put(setLatestInventory(response.data))
    yield put(inventoryCountRequestStart())
  } catch (error) {
    yield put(documentSaveRequestError(error))
  }
}

// function* documentNumberWorker() {
//   try {
//     const response = yield call(getDocumentNumber)
//     yield put(documentNumberRequestSuccess(response.data.nextdocumentnumber))
//   } catch (error) {
//     yield put(documentNumberRequestError(error))
//   }
// }

function* documentDeleteWorker({ payload: id }) {
  try {
    const response = yield call(deleteInventory, id)
    yield put(inventoryDeleteRequestSuccess(response.data))
    yield put(inventoryCountRequestStart())
  } catch (error) {
    yield put(inventoryDeleteRequestError(error))
  }
}
