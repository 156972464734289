import {ERROR, START, SUCCESS, TEACHERS_LIST_REQUEST} from "../action-types";

export const teachersListRequestStart = () => ({
  type: `${TEACHERS_LIST_REQUEST}${START}`
});

export const teachersListRequestSuccess = data => ({
  type: `${TEACHERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const teachersListRequestError = error => ({
  type: `${TEACHERS_LIST_REQUEST}${ERROR}`,
  payload: error
})