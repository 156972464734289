import { call, put, takeLatest, select } from 'redux-saga/effects'
import { addInvNumber, deleteInvNumber, editInvNumber, invNumbersCount, invNumbersList } from '../../../api/invNumbers/invNumbers'
import { setInvNumbersSearchData } from '../../actions/invNumbers/search'
import {
  BOOK_EDIT_INV_NUMBERS_LIST_REQUEST,
  BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST,
  START,
  BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST,
  BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST,
} from '../../actions/action-types'
import {
  bookInvNumbersCountRequestError,
  bookInvNumbersCountRequestStart,
  bookInvNumbersCountRequestSuccess,
} from '../../actions/bookEdit/invNumbersTab/count'
import {
  bookInvNumbersListRequestError,
  bookInvNumbersListRequestStart,
  bookInvNumbersListRequestSuccess,
} from '../../actions/bookEdit/invNumbersTab/list'
import { ADD } from '../../../constants/modalTypes'
import { bookInvNumberSaveRequestError, bookInvNumberSaveRequestSuccess } from '../../actions/bookEdit/invNumbersTab/add'
import { bookInvNumberDeleteRequestError, bookInvNumberDeleteRequestSuccess } from '../../actions/bookEdit/invNumbersTab/delete'


export default function* bookInvNumbersListWatcher() {
  yield takeLatest(`${BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST}${START}`, countWorker)
  yield takeLatest(`${BOOK_EDIT_INV_NUMBERS_LIST_REQUEST}${START}`, listWorker)
  yield takeLatest(`${BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST}${START}`, saveWorker)
  yield takeLatest(`${BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST}${START}`, deleteWorker)
}

function* countWorker() {
  const formData = yield select(({ invNumbers }) => invNumbers.form)
  const searchData = yield select(({ invNumbers }) => invNumbers.search)
  const bookid = yield select(({ bookData }) => bookData && bookData.id)

  try {
    const response = yield call(invNumbersCount, { ...formData, bookid })
    const count = parseInt(response.data.count, 10)
    yield put(bookInvNumbersCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setInvNumbersSearchData({ ...searchData, pageCount }))
    yield put(bookInvNumbersListRequestStart())
  } catch (error) {
    yield put(bookInvNumbersCountRequestError(error))
  }
}

function* listWorker() {
  const formData = yield select(({ invNumbers }) => invNumbers.form)
  const searchData = yield select(({ invNumbers }) => invNumbers.search)
  const bookid = yield select(({ bookData }) => bookData && bookData.id)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(invNumbersList, { ...formData, bookid }, searchData)
    yield put(bookInvNumbersListRequestSuccess(response.data))
  } catch (error) {
    yield put(bookInvNumbersListRequestError(error))
  }
}

function* saveWorker({ payload: { data, modalType } }) {
  try {
    let response
    if (modalType === ADD) response = yield call(addInvNumber, data.bookId, data.number, data.copynumber)
    else response = yield call(editInvNumber, data.instanceid, data.number, data.rfidcode, data.copynumber, data.edit)
    yield put(bookInvNumberSaveRequestSuccess(data))
    yield put(bookInvNumbersCountRequestStart())
  } catch (error) {
    yield put(bookInvNumberSaveRequestError(error))
  }
}

function* deleteWorker({ payload: number }) {
  try {
    const response = yield call(deleteInvNumber, number)
    yield put(bookInvNumberDeleteRequestSuccess(response.data))
    yield put(bookInvNumbersCountRequestStart())
  } catch (error) {
    yield put(bookInvNumberDeleteRequestError(error))
  }
}
