import { api } from '../../App'

export const authorize = (username, password, captcha, captcha_id) => {
  const data = {
    username,
    password,
    captcha,
    captcha_id,
  }
  return api.post(`/login`, data)
}

export const captcha = () => {
  return api.get('/captcha')
}

export const checkAuth = () => {
  return api.get('/test')
}

export const logout = () => {
  return api.get('/logout')
}
