import { SET_BOOKINVENTORY_SEARCH_DATA } from '../../../actions/action-types'
import { SORT_DIRECTION } from '../../../../constants/sortDirections'
import { RESULTS_PER_PAGE } from '../../../../constants/pagination'
import { DOCUMENT_SORT } from '../../../../constants/sort/documentSort'
import moment from 'moment'

const initialState = {
  query: '',
  // page: 1,
  documentType: '',
  // from: moment().add(-1, 'months').format('YYYY-MM-DD'),
  from: moment().format('2021-01-01'),
  to: moment().format('YYYY-MM-DD'),
  sortBy: 'bookinstancenumber',
  sortDirection: SORT_DIRECTION.ASC,
  // maxResults: RESULTS_PER_PAGE,
  // pageCount: 0
  // Шифр
  in_library_id: 0,
}

export const inventaryBookSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKINVENTORY_SEARCH_DATA:
      return action.payload

    default:
      return state
  }
}
