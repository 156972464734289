import { api } from "../../App";

export const libraryList = ({sortBy, idclient, paginateFrom, maxResults}) => {
  const params = {
    sortBy,
    idclient,
    paginateFrom,
    maxResults
  };

  return api.get("/library", { params });
};

export const libraryCount = (search) => {
  const params = {
    count: true,
    search,
  };

  return api.get("/library", { params });
};

export const libraryAdd = (data) => {
  return api.post('/library/', data);
};

export const libraryEdit = (data) => {
  return api.put(`/library/${data.id}`, data);
};

export const libraryDelete = (id) => {
  return api.delete(`/library/${id}`);
};

export const getLibraryFunds = (id) => {
  return api.get(`/library/${id}/fund`);
};

export const editLibraryFund = (libraryId, data) => {
  return api.put(`/library/${libraryId}/fund/${data.id}`, data);
};

export const addLibraryFund = (libraryId, data) => {
  return api.post(`/library/${libraryId}/fund`, data);
};

export const deleteLibraryFund = (libraryId, fundId) => {
  return api.delete(`/library/${libraryId}/fund/${fundId}`);
};

export const getLibraryPoints = (id) => {
  return api.get(`/library/${id}/point`);
};

export const editLibraryPoint = (libraryId, data) => {
  return api.put(`/library/${libraryId}/point/${data.id}`, data);
};

export const addLibraryPoint = (libraryId, data) => {
  return api.post(`/library/${libraryId}/point`, data);
};

export const deleteLibraryPoint = (libraryId, pointId) => {
  return api.delete(`/library/${libraryId}/point/${pointId}`);
};
