import { MEDIA_RESOURCE_DELETE_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const mediaResourceDeleteRequestStart = (resourceId) => ({
  type: `${MEDIA_RESOURCE_DELETE_REQUEST}${START}`,
  payload: resourceId
});

export const mediaResourceDeleteRequestSuccess = (response) => {
  createNotification('success', 'Ресурс успешно удален');

  return {
    type: `${MEDIA_RESOURCE_DELETE_REQUEST}${SUCCESS}`,
    payload: response
  }
};

export const mediaResourceDeleteRequestError = (error) => {
  createNotification('error', 'Ошибка удаления ресурса');

  return {
    type: `${MEDIA_RESOURCE_DELETE_REQUEST}${ERROR}`,
    payload: error
  }
};
