import React, {useEffect, useState} from 'react';
import styles from './BbkScene.module.css';
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Search from "../../../components/Search";
import {bbkListCollapse, bbkListRequestStart} from "../../../redux/actions/bbk/list";
import BBKList from "../../../components/BBK/List";

const mapStateToProps = state => ({
    list: state.bbk
});

const mapDispatchToProps = dispatch => ({
    requestList: (id, search, rewrite) => dispatch(bbkListRequestStart(id, search, rewrite)),
    collapseList: (id) => dispatch(bbkListCollapse(id))
});

const BBKScene = ({list, requestList, collapseList}) => {
    
    const [search, setSearch] = useState("");
    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {
        // Шифр
        firstRender && requestList(0)
        setFirstRender(false)
    }, []);


    return (
        <div className={styles.container}>
            <Row>
                <Col md={6}>
                    <div className={styles.title}>
                        Справочники / ББК
                    </div>
                </Col>
                <Col md={6}>
                    <Search
                        label={"Введите код ББК или ключевое слово для поиска"}
                        placeholder='Разрешенные символы: а-я 0-9 . /'
                        onChange={setSearch}
                        // Шифр
                        onSubmit={() => requestList(0, search, true)}
                        value={search}
                        inputClassName={styles.searchInput}
                        type="text"
                    />
                </Col>
                <Col md={12}>
                    {!!list.length && <BBKList data={list} fetchSubList={requestList} collapseList={collapseList}/>}
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BBKScene);
