import React from 'react';
import T from 'prop-types';
import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from './BookScanOrdersForm.module.css'
import {Col, Row} from "react-bootstrap";
import {Button} from 'antd';

const BookScanOrdersForm = ({formData, setFormData, onSubmit, typeOrder}) => {

    return (
        <div className={styles.container}>
            <Row>
                <Col md>
                    <ModalInputWrapper
                        label={'Название'}
                        name={'title'}
                        value={formData.title}
                        onChange={e => setFormData({...formData, title: e.target.value})}
                    />
                    <ModalInputWrapper label={'Тип заказа'}>
                        <select
                            name={'ordertype'}
                            value={formData.ordertype}
                            onChange={(e) => setFormData({...formData, ordertype: e.target.value})}
                        >
                                <option value={""}>Не выбрано</option>
                            {typeOrder &&
                                typeOrder.map((item, index) =>
                                <option value={item.id} key={'typeOrder' + index}>{item.ordertype}</option>
                            )}
                        </select>
                    </ModalInputWrapper>
                </Col>
                <Col md={'auto'}>
                    <ModalInputWrapper>
                        <Button type="primary" onClick={onSubmit}>Поиск</Button>
                    </ModalInputWrapper>
                </Col>
            </Row>

        </div>
    );
};

BookScanOrdersForm.propTypes = {
    formData: T.object.isRequired,
    setFormData: T.func.isRequired,
    onSubmit: T.func.isRequired
};

export default BookScanOrdersForm;