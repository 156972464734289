import jsPDF from 'jspdf'
import React from 'react'
import addFont from '../../../fonts/fonts'
import PdfPrintPanel from '../../PDFPrint/Panel'

export const AnswerStatPdfPrint = ({ data, searchFormValue, librariesList }) => {
  const title = 'Статистика ответов на вопросы'
  const orientation = 'landscape'

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
    doc.setFont('Montserrat')
    doc.setFont( undefined, 'bold')
    doc.setFontSize('16')

    doc.text(title, 20, 20)

    doc.autoTable({
      html: '#AnswerStat table',
      styles: { font: 'Montserrat', fontSize: 6 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      columnStyles: {
        0: { cellWidth: 10 },
        // 1: { cellWidth: 15 },
        // 2: { cellWidth: 15 },
        // 3: { cellWidth: 15 },
        // 4: { cellWidth: 15 },
        // 5: { cellWidth: 15 },
        // 6: { cellWidth: 15 },
      },
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 30,
    })

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getNumberOfPages())

    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
}
