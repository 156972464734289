import React from "react";
import T from "prop-types";
import styles from "./ReaderSearchResults.module.css";
import {resolveUserPhoto} from "../../../utils/resolveUserPhoto";
import UserSearchCard from "../../UserSearchCard";

const ReaderSearchResults = ({data}) => {
    return (
        <div className={styles.container}>
            {data.map((item, index) => (
                <UserSearchCard
                    key={index}
                    name={item.displayname}
                    link={`/library_card/${item.mid}`}
                    photo={resolveUserPhoto(item.mid)}
                    photoExists={item.photosize !== 0}
                    position={item.position}
                />
            ))}
        </div>
    );
};

ReaderSearchResults.propTypes = {
    data: T.array
};

export default ReaderSearchResults;
