import {
  LIBRARY_ADD_FOUND,
  LIBRARY_ADD_FOUND_CLEAR
} from "../../../action-types";

export const libraryAddFund = data => ({
  type: LIBRARY_ADD_FOUND,
  payload: data
});

export const libraryAddFundClear = () => ({
  type: LIBRARY_ADD_FOUND_CLEAR
});
