import { combineReducers } from "redux";
import { notebookLostDocReducer } from "./notebookLostDocReducer/notebookLostDocReducer";
import { actsReducer } from "./actsReducer/actsReducer";
import { countLostDocReducer } from "./count/countLostDocReducer";
import { lostDocSearchReducer } from "./search/lostDocSearchReducer";
import { notebookLostDocPdfReducer } from "./pdf/lostDocPdfReducer";
import { documentLatestReducer } from "../inventory/latest/documentLatestReducer";

export const lostBookReducer = combineReducers({
    list: notebookLostDocReducer,
    actsData: actsReducer,
    count: countLostDocReducer,
    search: lostDocSearchReducer,
    pdf: notebookLostDocPdfReducer,
    latest: documentLatestReducer,
});
