import {DOCUMENT_BOOK_LIST_REQUEST, START, SUCCESS, ERROR, DOCUMENT_BOOK_CLEANUP} from "../../action-types";

export const documentBookListRequestStart = (documentId) => ({
  type: `${DOCUMENT_BOOK_LIST_REQUEST}${START}`,
  payload: documentId
});

export const documentBookListRequestSuccess = (data) => ({
  type: `${DOCUMENT_BOOK_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentBookListRequestError = (data) => ({
  type: `${DOCUMENT_BOOK_LIST_REQUEST}${ERROR}`,
  payload: data
});

export const documentBooksCleanup = () => ({
  type: `${DOCUMENT_BOOK_CLEANUP}`
});
