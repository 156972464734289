import { SET_MENU_LIST } from "../../actions/action-types";

const initialState = {
  list: []
};

export const menuListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_LIST:
      return {...state, list: action.payload.rows};

    default:
      return state;
  }
};
