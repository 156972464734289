import React from 'react'
import PropTypes from 'prop-types'
import { Table, Space, Typography } from 'antd'
import DataTable from '../../../components/DataTable'
import DataTableCol from '../../../components/DataTable/Column'
import moment from 'moment'

const SecretDocBookTable =  ({ data }) => {
  const { Column, ColumnGroup } = Table
  const { Text } = Typography
  return (
    <DataTable data={data}>
      <DataTableCol
        title='Порядковый (инвентарный) номер издания (чертежа)'
        value='bookinstance_number'
        colSpan={10}
        fontSize={'12px'}
      />
      <DataTableCol
        title='Дата и входящий номер сопроводительного письма (накладной), инвентарный номер'
        resolver={item => '№' + item.document_number + ' ' + moment(item.document_date).format('DD.MM.YYYY')}
        colSpan={10}
        fontSize={'12px'}
      />

      <DataTableCol title='Откуда поступило' value='supplier_name' colSpan={10} fontSize={'12px'} />
      <DataTableCol title='Название издания (чертежа)' value='document_title' colSpan={10} fontSize={'12px'} />
      <DataTableCol title='Издатель, год издания' value='publisher_title' colSpan={10} fontSize={'12px'} />
      <DataTableCol title='Гриф секретности' value='secret' colSpan={10} fontSize={'12px'} />
      <DataTableCol title='Номер экземпляра' value='copynumber' colSpan={10} fontSize={'12px'} />
      <DataTableCol title='Количество экземпляров' value='instance_count' colSpan={7} fontSize={'12px'} />
      <DataTableCol title='Количество листов в каждом экземпляре' value='instance_sheet_count' colSpan={10} fontSize={'12px'} />
      <DataTableCol title='Кому отправлено' value='departure_name' colSpan={10} fontSize={'12px'} />
      <DataTableCol
        title='Дата и номер сопроводительного письма (накладной), реестра'
        value='doc_serial_number'
        colSpan={10}
        fontSize={'12px'}
      />
      <DataTableCol title='Количество экземпляров' value='doc_serial_number' colSpan={10} fontSize={'12px'} />

      <DataTableCol
        title='Отметка об уничтожении или переучете документа'
        value='destruction_date'
        colSpan={10}
        fontSize={'12px'}
      />
      <DataTableCol
        title='Подпись лица, утвердившего расчет рассылки секретного издания (чертежа)'
        value='secret_sign'
        colSpan={10}
        fontSize={'12px'}
      />
    </DataTable>
  )
}

SecretDocBookTable.propTypes = {}

export default SecretDocBookTable
