import React, { useEffect, useRef, useState } from 'react'
import Button from "../../../components/Button";
import styles from '../ImportScene.module.css'
import ModalInputWrapper from '../../../components/Modal/InputWrapper'
import { useOnClickOutside } from '../../../utils/hooks';
import cn from 'classnames'


const RES_OPTIONS = [
  {name: 'Пропустить', value: '0'},
  {name: 'Корректировка', value: '1'},
  {name: 'Заменить', value: '2'}
]

export default function DupliccatesList ({ data, alrearyAdded, startImport }) {
  
  
  const [resolution, setResolution] = useState(null)
  const [visible, setVisible] = useState(null)
  
  const popupRef = useRef(null)
  
  useEffect(() => {
    setResolution(data?.map(({id, title, year}) => ({id, title, year, resolution: '0'})))
  }, [data])
  

  const changeStatus = (index) => (e) => {
    const {name, value} = e.target
    setNewResolution({index, name, value})
    setVisible(value === '1' ? index : null)
    value !== '1' && cancelEditParam()
  }
  
  const setNewResolution = ({index, name, value}) => {
    const newResolution = [...resolution]
    const newItem = {...resolution[index], [name]: value}
    newResolution.splice(index, 1, newItem)
    setResolution(newResolution)
  }
  
  const uploadHandler = () => {
    const addedResolution = alrearyAdded?.map(({id, title, year}) => ({id, title, year, resolution: '0'})) || []
    const payload = [...addedResolution, ...resolution]
    startImport(JSON.stringify(payload))
  }
  
  const cancelEditParam = () => {
    if (!visible) return;
    const newResolution = [...resolution]
    const {publisher_info, ...restData} = data[visible]
    const oldItem = {...restData, resolution: '0'}
    newResolution.splice(visible, 1, oldItem)
    setResolution(newResolution)
    closePopup()
  }

  useOnClickOutside(popupRef, cancelEditParam)


  const closePopup = () => {
    setVisible(null)
  }

  const renderBookResolution = (book, index) => {
    const {resolution, title, year} = book
    const popupSaveDisabled = title === data[index].title && year === data[index].year
    const ref = index === visible ? popupRef : null
    const { author_info, publisher_info } = data[index]
    const authors = author_info?.map(({author_name}) => author_name).join(', ')
    const publishers = publisher_info?.map(({publisher_name}) => publisher_name).join(', ')
    return (
      <li className={styles.duplicateBook}>
        <span>{index+1}</span>
        <div>

          <div className={styles.duplBookParam}>
            <b>Название:</b>
            {title}
          </div>

          <div className={styles.duplBookParam}>
            <b>Авторы:</b>
            {authors}
          </div>

          <div className={styles.duplBookParam}>
            <b>Год:</b>
            {year}
          </div>

          <div className={styles.duplBookParam}>
            <b>Издательства:</b>
            {publishers}
          </div>

        </div>
        <div ref={ref} className={styles.editParamsWrapper}>
          <ModalInputWrapper>
            <select
              onChange={changeStatus(index)}
              value={resolution}
              name='resolution'
              >
              {RES_OPTIONS.map(({name, value}) => <option value={value}>{name}</option>)}
            </select>
          </ModalInputWrapper>
          <EditParamPopup
              data={book}
              index={index}
              popupSaveDisabled={popupSaveDisabled}
              visible={visible === index}
              setNewResolution={setNewResolution}
              cancelEditParam={cancelEditParam}
              saveEditParam={closePopup}
            />
        </div>
      </li>
    )
  }

  if (resolution?.length !== data?.length) return null;

  return (
    <div className={styles.duplicatesWrap}>
      <p>Дубликаты:</p>
      <ul>
        {resolution?.map(renderBookResolution)}
      </ul>
      <Button label='Сохранить' onClick={uploadHandler} />
    </div>
  )
}

const EditParamPopup = ({
  data,
  index,
  setNewResolution,
  popupSaveDisabled,
  cancelEditParam,
  saveEditParam,
  visible
}) => {
  

  const onChange = (e) => {
    const {name, value} = e.target
    setNewResolution({index, name, value})
  }

  return (
    <div className={cn(styles.editParamsBlock, {[styles.editParamsBlockVisible]: visible})}>
      <h5>Изменить параметры:</h5>
      <ModalInputWrapper
        label='Название'
        name='title'
        value={data.title}
        onChange={onChange}
        />
      <ModalInputWrapper
        label='Год'
        name='year'
        type='number'
        value={data.year}
        onChange={onChange}
      />
      <div className={styles.editParamsButtons}>
        <Button label='Сохранить' onClick={saveEditParam} disabled={popupSaveDisabled}/>
        <Button label='Отмена' onClick={cancelEditParam}/>
      </div>
    </div>
)
}
