import { SET_RUBRICATOR_SEARCH_DATA } from "../../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { RUBRICATOR_SORT } from "../../../../constants/sort/rubricatorSort";

const initialState = {
  query: "",
  page: 1,
  sortBy: RUBRICATOR_SORT.NAME,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const rubricatorSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_RUBRICATOR_SEARCH_DATA:
      return action.payload;

    default: return state;

  }
};
