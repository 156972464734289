import { NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const orderBooksEditRequestStart = (data) => ({
    type: `${NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST}${START}`,
    payload: data
});

export const orderBooksEditRequestSuccess = (data) => {
    createNotification('success', 'Запрос успешно изменен');
    return {
        type: `${NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST}${SUCCESS}`,
        payload: data
    };
};

export const orderBooksEditRequestError = (data) => {
    createNotification('error', 'Не удалось изменить запрос, ошибка сервера');
    return {
        type: `${NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST}${ERROR}`,
          payload: data
    };
};
