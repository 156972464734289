import React, { useState } from 'react'
import styles from './CurrentPerfomanceTable.module.css'
import { Table, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Modal from '../../Modal'
import arrow from '../../../img/icons/arrow-up-icon.png'
import { types, typesOfWork } from '../../../redux/factory/currentPerfomanceReduxData'
import { TasksFiles } from '../TasksFile'
import { ResultsFilesForm } from '../ResultsFilesForm'
import { Link, useHistory } from 'react-router-dom'

export const PerformancesTable = ({ res, isTableVisible, filesRequest, modalData, term }) => {
  const history = useHistory()

  const [state, setState] = useState({
    tabIndex: 0,
    isModalVisible: false,
    modalTarget: null,
    type: null,
    row: null,
  })

  const handleToggleModal = (isModalVisible, row = null, type = null, typesOfWork = null) => {
    setState({ isModalVisible, row, type, modalTarget: typesOfWork })
    if (row) {
      const { sheid, instance } = row
      filesRequest({ sheid, instance, type, typesOfWork })
    }
  }

  const findNextTermData = ({ cid, termnum }) => {
    const progress = res.find(item => item.cid === cid && item.termnum !== termnum)
    return (progress && progress.grade) || ''
  }

  const secondValue = (firstTerm, { grade, termnum }) => (firstTerm !== termnum ? grade : '')
  return (
    <>
      <Modal
        title={`Роль: ${state.type === 'EDIT' ? 'редактирование' : 'добавление'}`}
        isVisible={state.isModalVisible}
        // contentClassName={styles.modalContent}
      >
        {state.modalTarget === typesOfWork.HOME_WORK && (
          <TasksFiles modalData={modalData} row={state.row} type={state.type} download={'download'} />
        )}
        {state.modalTarget === typesOfWork.RESULT_WORK && (
          <ResultsFilesForm
            modalData={modalData}
            row={state.row}
            type={state.type}
            uploadFile={'uploadFile'}
            deleteFile={'deleteFile'}
            download={'download'}
          />
        )}
        <Button variant='danger' size='sm' onClick={() => setState({ ...state, isModalVisible: false })}>
          Close
        </Button>
      </Modal>

      {isTableVisible && (
        <Table style={{ cursor: 'pointer' }} className={styles.table}>
          <thead>
            <tr>
              <th className={styles.title}>Дисциплина</th>
              <th className={styles.title}>1 семестр</th>
              <th className={styles.title}>2 семестр</th>
            </tr>
          </thead>
          <tbody>
            {res.map((row, i) => {
              return (
                <tr>
                  <td>
                    <Container>{row.course}</Container>
                  </td>
                  <td onClick={() => history.push(`/performances/${term.terms[0].trmid}/${row.cid}`)}>
                    <Container>{res[0].termnum === row.termnum ? row.grade : ''}</Container>
                  </td>
                  <td onClick={() => history.push(`/performances/${term.terms[1].trmid}/${row.cid}`)}>
                    <Container> {findNextTermData(row) || res[0].termnum !== row.termnum ? row.grade : ''}</Container>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
    </>
  )
}
