import React from 'react'
import { EDIT } from '../../../constants/modalTypes'
import styles from '../../Documents/Table/DocumentsTable.module.css'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import moment from 'moment'
import RolePermissionChecker from '../../UtulityComponents/RolePermissionChecker'

const DocumentsTable = ({ data, setModal, sortBy, setSort, selectedId, handleSelect }) => {
  const openModal = item => {
    setModal({ modalType: EDIT, data: item })
  }
  const STATUS = {
    1: 'новая',
    2: 'в работе',
    3: 'завершено',
  }
  return (
    <div className={styles.container}>
      {!!data.length ? (
        <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openModal}>
          <DataTableCol title='Имя документа' value='name' colSpan={3} />
          <DataTableCol title='Дата документа' colSpan={2} resolver={({ date_form }) => moment(date_form).format('DD.MM.YYYY')} />
          <DataTableCol title='Дата начала' colSpan={2} resolver={({ datestart }) => moment(datestart).format('DD.MM.YYYY')} />
          <DataTableCol title='Дата завершения' colSpan={2} resolver={({ dateend }) => moment(dateend).format('DD.MM.YYYY')} />
          <DataTableCol title='Статус' colSpan={2} resolver={({ status }) => STATUS[status]} />

          <DataTableCol
            passClicks
            resolver={item => (
              <RolePermissionChecker permissionName={'Режимы,Инвентаризация'}>
                <input
                  id={item.id}
                  name={item.id}
                  type='checkbox'
                  checked={item.id === selectedId}
                  onChange={() => handleSelect(item.id)}
                />
              </RolePermissionChecker>
            )}
          />
        </DataTable>
      ) : (
        <div className={styles.notFound}>Записи отсутствуют</div>
      )}
    </div>
  )
}

export default DocumentsTable
