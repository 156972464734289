import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import {creditsListRequestStart} from "../../redux/actions/credits/list";
import {Tab, Tabs} from "react-bootstrap";
import CreditsTable from "../../components/Credits/Table";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";

const mapStateToProps = ({ credits, readerData }) => ({
  list: credits.list,
  loader: credits.loader,
  readerData
});

const mapDispatchToProps = {
  requestList: creditsListRequestStart
};

const CreditsScene = ({ requestList, list = {}, readerData, loader }) => {

  useEffect(() => {
    if(readerData) {
      const studentId = readerData.mid;
      requestList(studentId);
    }
  }, [requestList, readerData]);

  const listInArray = Object.entries(list);
  const listFirstElementName = !!listInArray.length && listInArray[0][0];


  return (
    <SceneContainer title="Электронная зачетка">

      {loader ?
        <SpinnerOuter isLoading={loader}/>
        :
        <Tabs defaultActiveKey={listFirstElementName} className={'mt-4'}>
          {listInArray.map(([name, value], index) =>
            <Tab.Pane eventKey={ name } title={ name } key={ `tab-${index}` } className={"mt-2"}>
              <CreditsTable data={value}/>
            </Tab.Pane>
          )}
        </Tabs>
      }

    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditsScene);