import React from 'react'
import T from 'prop-types'
import { EDIT } from '../../../constants/modalTypes'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../Modal/InputWrapper'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CatalogsModalWithForm from '../../Catalogs/ModalWithForm'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Это обязательное поле').trim('Это обязательное поле').max(200, 'Недопустимая длина названия (макс 200)'),
  fullName: Yup.string().required('Это обязательное поле').trim('Это обязательное поле').max(200, 'Недопустимая длина названия (макс 200)'),
  city: Yup.string().required('Это обязательное поле').trim('Это обязательное поле').max(200, 'Недопустимая длина названия (макс 200)'),
  
})

const PublishersModal = ({ onSubmit, modalData, closeModal }) => {
  const { modalType, data } = modalData

  const modalForm = useFormik({
    initialValues: {
      name: data.name,
      fullName: data.fullName,
      city: data.city,
      isbn: data.isbn,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => onSubmit(values),
  })

  return (
    <CatalogsModalWithForm
      title={`Издательство: ${modalType === EDIT ? 'Редактирование' : 'Добавление'}`}
      submitHandler={modalForm.handleSubmit}
      closeModal={closeModal}
    >
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            maxlength={200}
            label={'Название'}
            value={modalForm.values.name}
            name='name'
            error={modalForm.errors.name}
            onChange={modalForm.handleChange}
          />
          <ModalInputWrapper
            maxlength={200}
            label={'Полное название'}
            value={modalForm.values.fullName}
            name='fullName'
            error={modalForm.errors.fullName}
            onChange={modalForm.handleChange}
          />
        </Col>
        <Col md={8}>
          <ModalInputWrapper
            maxlength={200}
            label={'Город'}
            value={modalForm.values.city}
            name='city'
            error={modalForm.errors.city}
            onChange={modalForm.handleChange}
          />
        </Col>
      </Row>
    </CatalogsModalWithForm>
  )
}

PublishersModal.propTypes = {
  modalData: T.object.isRequired,
  onSubmit: T.func,
  closeModal: T.func,
}

export default PublishersModal
