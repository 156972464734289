import { call, put, takeLatest, select } from "redux-saga/effects";
import {
    EXEMPT_TEST_ADD_REQUEST,
    EXEMPT_TEST_COUNT_REQUEST, EXEMPT_TEST_DELETE_REQUEST, EXEMPT_TEST_EDIT_REQUEST,
    EXEMPT_TEST_LIST_REQUEST,
    START,
} from "../../actions/action-types";

import {
    createExemptTest,
    deleteExemptTest,
    editExemptTest,
    getExemptTests,
    getExemptTestsCount
} from "../../../api/exempttests";
import {
    exemptTestCountRequestError,
    exemptTestCountRequestStart,
    exemptTestCountRequestSuccess
} from "../../actions/exempttests/count";
import {setExemptTestSearchData} from "../../actions/exempttests/search";
import {
    exemptTestListRequestError,
    exemptTestListRequestStart,
    exemptTestListRequestSuccess
} from "../../actions/exempttests/list";
import {exemptTestAddRequestError, exemptTestAddRequestSuccess} from "../../actions/exempttests/create";
import {createNotification} from "../../../components/notification/notification";
import {exemptTestEditRequestError, exemptTestEditRequestSuccess} from "../../actions/exempttests/edit";
import {exemptTestDeleteRequestError, exemptTestDeleteRequestSuccess} from "../../actions/exempttests/delete";



export default function* exemptTestWatcher() {
    yield takeLatest(`${EXEMPT_TEST_COUNT_REQUEST}${START}`, countWorker);
    yield takeLatest(`${EXEMPT_TEST_LIST_REQUEST}${START}`, listWorker);
    yield takeLatest(`${EXEMPT_TEST_ADD_REQUEST}${START}`, addWorker);
    yield takeLatest(`${EXEMPT_TEST_EDIT_REQUEST}${START}`, editWorker);
    yield takeLatest(`${EXEMPT_TEST_DELETE_REQUEST}${START}`, deleteWorker);
}

function* countWorker() {
    const searchData = yield select(({ exemptTest }) => exemptTest.search);
    try {
        const response = yield call(getExemptTestsCount, searchData);
        const count = parseInt(response.data.count, 10);
        yield put(exemptTestCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setExemptTestSearchData({ ...searchData, pageCount }));
        yield put(exemptTestListRequestStart());
    } catch (error) {
        yield put(exemptTestCountRequestError(error));
    }
}

function* listWorker() {
    const searchData = yield select(({ exemptTest }) => exemptTest.search);
    const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getExemptTests, {...searchData, paginateFrom});
        yield put(exemptTestListRequestSuccess(response.data));
    } catch (error) {
        yield put(exemptTestListRequestError(error));
    }
}

function* addWorker({ payload: data }) {
    try {
        const response = yield call(createExemptTest, data);
        yield put(exemptTestAddRequestSuccess(response.data));
        yield call(createNotification, 'success', 'Успешно сохранено');
        yield put(exemptTestCountRequestStart());
    } catch(error) {
        yield put(exemptTestAddRequestError(error));
    }
}

function* editWorker({ payload: data }) {
    try {
        const response = yield call(editExemptTest, data);
        yield put(exemptTestEditRequestSuccess(response.data));
        yield call(createNotification, 'success', 'Успешно сохранено');
        yield put(exemptTestCountRequestStart());
    } catch(error) {
        yield put(exemptTestEditRequestError(error));
    }
}

function* deleteWorker({ payload: id }) {
    try {
        yield call(deleteExemptTest, id);
        yield put(exemptTestDeleteRequestSuccess());
        yield call(createNotification, 'success', 'Успешно удалено');
        yield put(exemptTestCountRequestStart());
    } catch(error) {
        yield put(exemptTestDeleteRequestError());
    }
}
