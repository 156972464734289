import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { addBook, editBook } from '../../../../api/book/edit'
import { api } from '../../../../App'
import { authorsCountRequestStart } from '../../../../redux/actions/authors/count'
import { setAuthorsSearchData } from '../../../../redux/actions/authors/searchData'
import { bookSourceRequestStart } from '../../../../redux/actions/booksource'
import { documentBookSaveRequestStart } from '../../../../redux/actions/documents/books/save'
import { publishersCountRequestStart } from '../../../../redux/actions/publishers/count'
import { setPublishersSearchData } from '../../../../redux/actions/publishers/searchData'
import { authorsToOptions, optionsToAuthors, optionsToPublishers, publishersToOptions } from '../../../../utils/toSelectOption'
import SupplierField from '../../../BookEdit/Tabs/IndexTab/SupplierField'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import ModalWindow from '../../../ModalWindow'
import { createNotification } from '../../../notification/notification'
import SearchableSelect from '../../../Select'
import styles from '../DocumentsModal.module.css'

const mapStateToProps = ({ authors, publishers, bookSource, options }) => ({
  authorsList: authors.list,
  authorsSearch: authors.search,
  publishersList: publishers.list,
  bookSource: bookSource,
  optionsList: options.data,
})

const mapDispatchToProps = {
  requestAuthors: authorsCountRequestStart,
  setAuthorSearch: setAuthorsSearchData,
  setPublishersSearch: setPublishersSearchData,
  saveBook: documentBookSaveRequestStart,
  requestPublishers: publishersCountRequestStart,
  requestBookSourceList: bookSourceRequestStart,
  requestSearchPublishers: publishersCountRequestStart,
}

const CreateNewLibraryItemModal = ({
  authorsList,
  authorsSearch,
  publishersList,
  showLibraryItemModal,
  setShowLibraryItemModal,
  requestPublishers,
  setPublishersSearch,
  requestAuthors,
  setAuthorSearch,
  bookData,
  onSelectFromSearch,
  modalData,
  bookSource,
  optionsList,
  requestBookSourceList,
}) => {
  const [data, setData] = useState(bookData)
  const view = modalData.data.view
  const type = parseInt(modalData.data.documenttype)

  useEffect(() => {
    setData(bookData)
  }, [bookData])

  useEffect(() => {
    requestBookSourceList()
  }, [requestBookSourceList])

  useEffect(() => {
    requestAuthors()
    requestPublishers()
  }, [])

  const onSave = () => {
    if (data !== null) {
      if (!!data.title) {
        const requestBody = {
          title: data.title,
          year: Number(data.year),
          authors: data.authors.map(item => item.id),
          publishers: data.publishers.map(item => item.id),
          book_source: data.book_source,
          clients: [optionsList.own_client_id],
        }
        addBook(data)
        editBook({ ...requestBody, bookid: +data.bookid + 1 })
        createNotification('success', 'Документ успешно сохранен')
        onSelectFromSearch({ bookid: +data.bookid + 1, title: data.title })
        setShowLibraryItemModal(false)
      } else {
        createNotification('error', 'Пожалуйста, заполните поле "Название" ')
      }
    }
  }


  const checkedOnSave = () => {
    const checkRequestBody = {
      title: data.title,
      year: Number(data.year) || 0,
      pageNum: Number(data.pageNum) || 0,
      authorIds: data.authors.map(item => item.id),
      publisherIds: data.publishers.map(item => item.id),
      extractreason: Number(data.extractreason) || 0,
      subscriptionenddate: data.subscriptionenddate,
      book_source: data.book,
    }
    api.post(`/bookduplicate`, checkRequestBody).then(response => {
      if ((response.data.length === 1 && Number(response.data[0].id) === Number(data.id)) || response.data.length === 0) {
        onSave()
      } else {
        createNotification('error', 'Такая книга уже существует. Дубликаты запрещены.')
      }
    })
  }

  const onChange = ({ target: { value, name } }) => {
    setData({ ...data, [name]: value })
  }

  const onAuthorInputChange = name => {
    setAuthorSearch({ ...authorsSearch, query: name })
    requestAuthors()
  }

  const onPublisherInputChange = query => {
    setPublishersSearch({ query: query, maxResults: false })
    requestPublishers()
  }

  return (
    <ModalWindow
      title={`Добавить БЗ`}
      isOpen={showLibraryItemModal}
      onRequestClose={() => setShowLibraryItemModal(false)}
      width={600}
    >
      {!!data ? (
        <div className={`${styles.saveModal} ${styles.modalAddBZ}`}>
          <ModalInputWrapper label='Издательства'>
            <SearchableSelect
              placeholder='Поиск'
              publishers={true}
              onInputChange={onPublisherInputChange}
              options={publishersToOptions(publishersList)}
              isMulti={true}
              onChange={value => setData({ ...data, publishers: optionsToPublishers(value) })}
              value={publishersToOptions(data.publishers)}
            />
          </ModalInputWrapper>
          {type === 1 && (
            <ModalInputWrapper label='Источник'>
              <SupplierField data={data} setData={setData} source={bookSource} secrecy={optionsList.outsecrecy} />
            </ModalInputWrapper>
          )}
          {view !== 3 && view !== 4 && (
            <ModalInputWrapper label='Авторы'>
              <SearchableSelect
                authosr={true}
                data={data}
                setData={setData}
                placeholder='Поиск'
                onInputChange={onAuthorInputChange}
                options={authorsToOptions(authorsList)}
                isMulti={true}
                onChange={value => setData({ ...data, authors: optionsToAuthors(value) })}
                value={authorsToOptions(data.authors)}
              />
            </ModalInputWrapper>
          )}
          <ModalInputWrapper label='Заглавие' value={data.title} name='title' onChange={onChange} />
          <ModalInputWrapper label='Год издания' value={data.year} name={'year'} onChange={onChange} />
          <div className={styles.addBZbutton}>
            <Button type='primary' onClick={checkedOnSave}>
              Сохранить
            </Button>
          </div>
        </div>
      ) : null}
    </ModalWindow>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewLibraryItemModal)
