import { SET_READER_SEARCH_DATA } from "../../../actions/action-types";

const initialState = "";

export const readerSearchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_READER_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};