import { LITERS_EDIT_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const litersCatalogEditRequestStart = (data) => ({
  type: `${LITERS_EDIT_REQUEST}${START}`,
  payload: data
});

export const litersCatalogEditRequestSuccess = (data) => {
  createNotification('success', 'Литер успешно отредактирован');
  return {
    type: `${LITERS_EDIT_REQUEST}${SUCCESS}`,
    payload: data
  }
};

export const litersCatalogEditRequestError = (data) => {
  createNotification('error', 'Не удалось отредактировать литер');
  return {
    type: `${LITERS_EDIT_REQUEST}${ERROR}`,
    payload: data
  }
};
