import React, {useEffect} from "react";
import {connect} from "react-redux";
import styles from "./Teachers.module.css";
import {BASE_URL} from "../../constants/api";
import {Col, Row} from "react-bootstrap";
import TeacherCard from "../../components/Teachers/Card";
import Pagination from "../../components/Pagination";
import Search from "../../components/Search";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import {teachersSearchRequest} from "../../redux/actions/teachers/search";
import {teachersCountRequestStart} from "../../redux/actions/teachers/count";

const mapStateToProps = ({teachers}) => ({
    data: teachers.list,
    count: teachers.count,
    searchData: teachers.search
});

const mapDispatchToProps = {
    requestTeacher: teachersCountRequestStart,
    setTeachersSearchData: teachersSearchRequest
};

const resolvePhoto = item => `${BASE_URL}/hlms/api/users/${item.id}/photo`;
const getName = item => `${item.lastname} ${item.firstname} ${item.patronymic}`;

const Teachers = ({data, count, searchData, requestTeacher, setTeachersSearchData}) => {

    const PaginationComponent = () => (
        <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={setCurrentPage}
        />
    );

    const setCurrentPage = page => {
        setTeachersSearchData({...searchData, page});
        requestTeacher();
    };

    useEffect(() => {
        requestTeacher();
    }, []);

    useEffect(() => {
        requestTeacher()
    }, [setTeachersSearchData]);



    return (
        <SceneContainer title='Профессорско-преподавательский состав'>
            {data && !!data.length &&
            <Row>
                <Col md={12}>
                    <Search value={searchData.query}
                            onChange={query => {
                                setTeachersSearchData({...searchData, query})
                            }}
                            onSubmit={requestTeacher}
                            placeholder={"поиск"}
                    />
                </Col>
                <Col md={{span: 8, offset: 2}}>
                    <PaginationComponent/>
                    <div className={styles.card_list}>
                        {data.map((element, index) => {
                            return (
                                <TeacherCard
                                    key={index}
                                    data={element}
                                    email={element.email}
                                    employername={element.employername}
                                    contact={element.contact}
                                    photo={resolvePhoto(element)}
                                    photoExists={!!element.photosize}
                                    name={getName(element)}
                                />
                            );
                        })}
                    </div>
                    <PaginationComponent/>
                </Col>
            </Row>
            }

        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Teachers);
