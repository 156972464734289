import { combineReducers } from 'redux'
import { publishersCountReducer } from './count/publishersCountReducer'
import { publishersListReducer } from './list/publishersListReducer'
import { publishersSearchDataReducer } from './search/publishersSearchDataReducer'
import { publishersErrorReducer } from './error/publishersErrorReducer'

export const publishersReducer = combineReducers({
  count: publishersCountReducer,
  list: publishersListReducer,
  search: publishersSearchDataReducer,
  error: publishersErrorReducer,
})
