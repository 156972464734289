import {combineReducers} from "redux";
import {countReducer} from "./count/countReducer";
import {searchReducer} from "./search/searchReducer";
import {listReducer} from "./list/listReducer";
import {loaderReducer} from "./loader/loaderReducer";


export const exemptTestReducer = combineReducers({
   count: countReducer,
   list: listReducer,
   loader: loaderReducer,
   search:  searchReducer
});