import { ERROR, RUBRICATOR_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const rubricatorCountRequestStart = () => ({
  type: `${RUBRICATOR_COUNT_REQUEST}${START}`
});

export const rubricatorCountRequestSuccess = (data) => ({
  type: `${RUBRICATOR_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const rubricatorCountRequestError = (data) => ({
  type: `${RUBRICATOR_COUNT_REQUEST}${ERROR}`,
  payload: data
});
