import { createSimpleActionsForReducer } from '../actions/actionsFactory'
import { START, SUCCESS } from '../actions/action-types'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { api } from '../../App'

export const types = {
  INDIVIDUAL_TYPE: 'user',
  GROUP_TYPE: 'group',
}

export const typesOfWork = {
  HOME_WORK: '',
  RESULT_WORK: 'result',
}

const PERFORMANCE_REQUEST = 'PERFORMANCE_REQUEST'
const PERFORMANCE_DATA_TO_STATE = 'PERFORMANCE_DATA_TO_STATE'
const PERFORMANCE_LOAD_REQUEST = 'PERFORMANCE_LOAD_REQUEST'
const PERFORMANCE_TERM_REQUEST = 'PERFORMANCE_FACULTY_REQUEST'
const PERFORMANCE_DISCIPLINE_REQUEST = 'PERFORMANCE_CURS_REQUEST'
const PERFORMANCE_YEAR_REQUEST = 'PERFORMANCE_YEAR_REQUEST'
const USER_DATA_REQUEST = 'USER_DATA_REQUEST'
const PERFORMANCE_FILES_REQUEST = 'PERFORMANCE_FILES_REQUEST'
const TASKS_FETCH_COMPLETED = 'TASKS_FETCH_COMPLETED'
const PERFORMANCE_PROGRESS_DETAIL_REQUEST = 'PERFORMANCE_PROGRESS_DETAIL_REQUEST'
const PERFORMANCE_COURSE_REQUEST = 'PERFORMANCE_COURSE_REQUEST'

const getCouseApi = courseId => {
  return api.get(`/hlms/api/course/${courseId}`)
}

const getProgressApi = ({ userId, semesterId, courseId }) => {
  return api.get(`/hlms/api/progress_detail/${userId}/${semesterId}/${courseId}`)
}

export const performanceActions = {
  ...createSimpleActionsForReducer('request', `${PERFORMANCE_REQUEST}`),
  ...createSimpleActionsForReducer('load', `${PERFORMANCE_LOAD_REQUEST}`),
  ...createSimpleActionsForReducer('term', `${PERFORMANCE_TERM_REQUEST}`),
  ...createSimpleActionsForReducer('discipline', `${PERFORMANCE_DISCIPLINE_REQUEST}`),
  ...createSimpleActionsForReducer('year', `${PERFORMANCE_YEAR_REQUEST}`),
  ...createSimpleActionsForReducer('user', `${USER_DATA_REQUEST}`),
  ...createSimpleActionsForReducer('files', `${PERFORMANCE_FILES_REQUEST}`),
  ...createSimpleActionsForReducer('tasks', `${TASKS_FETCH_COMPLETED}`),
  ...createSimpleActionsForReducer('progressDetail', `${PERFORMANCE_PROGRESS_DETAIL_REQUEST}`),
  ...createSimpleActionsForReducer('course', `${PERFORMANCE_COURSE_REQUEST}`),
}

export const requestAction = data => ({
  type: PERFORMANCE_DATA_TO_STATE,
  data,
})

const initialState = {
  loader: false,
}

export const performanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${PERFORMANCE_COURSE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        course: action.payload,
      }
    case `${PERFORMANCE_PROGRESS_DETAIL_REQUEST}${SUCCESS}`:
      return {
        ...state,
        progressDetail: action.payload,
      }
    case `${TASKS_FETCH_COMPLETED}${SUCCESS}`:
      return {
        ...state,
        modal: action.payload,
      }
    case `${PERFORMANCE_REQUEST}${START}`:
      return {
        ...state,
        loader: true,
      }
    case `${PERFORMANCE_TERM_REQUEST}${SUCCESS}`:
      return {
        ...state,
        term: action.payload,
      }
    case `${PERFORMANCE_DISCIPLINE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        discipline: action.payload,
      }
    case `${PERFORMANCE_YEAR_REQUEST}${SUCCESS}`:
      return {
        ...state,
        year: action.payload,
      }
    case `${PERFORMANCE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        loader: false,
        response: action.payload,
      }
    case `${USER_DATA_REQUEST}${SUCCESS}`:
      return {
        ...state,
        userData: action.payload,
      }
    case PERFORMANCE_DATA_TO_STATE:
      return {
        ...state,
        user: action.data,
      }

    default:
      return state
  }
}

function* performanceLoadSaga({ payload: { userId, semesterId, courseId } }) {
  try {
    // console.log(payload)
    const resCourse = yield call(getCouseApi, courseId)
    const resProgress = yield call(getProgressApi, {userId, semesterId, courseId})

    yield put(performanceActions.progressDetailRequestSuccess(resProgress.data))
    yield put(performanceActions.courseRequestSuccess(resCourse.data))

    // const resTerm = yield call(
    //   getTermApi,
    //   payload?.year ? payload?.year : resYear.data.schoolYear[resYear.data.schoolYear.length - 1].xp_key
    // )
    // const resDiscipline = yield call(
    //   getDisciplineApi,
    //   payload?.term ? payload?.term : resTerm.data.terms[resTerm.data.terms.length - 1].trmid
    // )

    // yield put(performancesActions.yearRequestSuccess(resYear.data))
    // yield put(performancesActions.termRequestSuccess(resTerm.data))
    // yield put(performancesActions.disciplineRequestSuccess(resDiscipline.data))
  } catch (e) {
    console.log(e)
  }
}

// function* performanceRequestSaga({ payload }) {
//   const data = yield select(({ performance }) => performance.user)
//   // const yearId = yield select(({ performance }) => performance.user)getTermApi
//   try {
//     const term = yield call(getTermApi, payload)
//     const res = yield call(getPerformancesApi, data)
//     yield put(performancesActions.termRequestSuccess(term.data))
//     yield put(performancesActions.requestRequestSuccess(res))
//   } catch (e) {
//     console.log(e)
//   }
// }

// function* performanceRequestFilesSaga({ payload: { sheid, instance, type, typesOfWork } }) {
//   const userId = yield select(({ auth }) => auth.data.mid)

//   try {
//     const res = yield call(getFileApi, sheid, instance, type, typesOfWork, userId)
//     yield put(performancesActions.tasksRequestSuccess(res.data))
//     console.log(1111)
//   } catch (e) {
//     console.log(e)
//   }
// }

export function* performanceSagaWatcher() {
  yield takeLatest(`${PERFORMANCE_LOAD_REQUEST}${START}`, performanceLoadSaga)
  // yield takeLatest(`${PERFORMANCE_REQUEST}${START}`, performanceRequestSaga)
  // yield takeLatest(`${PERFORMANCE_FILES_REQUEST}${START}`, performanceRequestFilesSaga)
}
