import {
    call,
    put,
    takeLatest,
} from "redux-saga/effects";
import {DISCIPLINE_SAVE_START, START} from "../../../actions/action-types";
import {saveDisciplineRequestError, saveDisciplineRequestSuccess} from "../../../actions/discipline/save";
import {disciplineAdd, disciplineEdit} from "../../../../api/discipline/discipline";

export default function* saveDisciplineWatcher() {
    yield takeLatest(`${DISCIPLINE_SAVE_START}${START}`, saveDisciplineWorker)
}

function* saveDisciplineWorker({payload: { data, modalType: type }, callback}) {
    try {
        const response = yield call(type === "ADD" ? disciplineAdd : disciplineEdit, data);
        yield put(saveDisciplineRequestSuccess(response.data));
        yield call(callback);
    } catch (error) {
        yield put(saveDisciplineRequestError(error))
    }
}