import { BOOK_SUPPLY_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const bookSupplyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_SUPPLY_LIST_REQUEST}${SUCCESS}`:
      return action.payload["milspecs"];

    default: return state;
  }
};