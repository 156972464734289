import {MESSAGE_LIST_REQUEST, MESSAGE_COUNT_REQUEST, SUCCESS, START} from "../../../actions/action-types";

const initialState = [];

export const listReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${MESSAGE_COUNT_REQUEST}${START}`:
      return initialState;

    case `${MESSAGE_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
