import React from "react";
import T from 'prop-types';
import jsPDF from "jspdf";
import 'jspdf-autotable';

import addFont from "../../fonts/fonts";
import PdfPrintPanel from "./Panel";
import {createHeaders} from "./utils";


const PDFPrint = ({headers, tableData, title, orientation}) => {

    /*
    * Headers type - [{ value, title }, ...]
    * */

    if(!tableData) {
        return  null;
    }

    const createData = () => {
        let result = [];

        tableData.forEach(item => {
            const generatedItem = [];

            headers.forEach(({value}) => {
                generatedItem.push(typeof value === 'function'
                    ? value(item)
                    : (item[value] || '-'));
            });

            result.push(generatedItem);
        });

        return result;
    };


    addFont(jsPDF.API);
    const doc = new jsPDF({putOnlyUsedFonts: true, orientation: orientation});
    doc.setFont("Montserrat");
    if(title) {
        doc.text(title, 15, 15);
    }
    doc.autoTable({
        head: [createHeaders(headers)],
        body: createData(),
        styles: {font: "Montserrat", fontSize: 10},
        margin: {
            top: 25
        },
        headStyles: {
            halign: "left",
            valign: "left",
            lineWidth: 0.25,
            lineColor: 200
        },
        bodyStyles: {
            halign: "left",
            lineWidth: 0.25,
            lineColor: 200
        },
    });


    const toPdf = () => {
        doc.save(`${title}.pdf`);
    };

    const toPrint = () => {
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    };

    return (
        <PdfPrintPanel loadPdf={toPdf} printPdf={toPrint}/>
    );
};

PDFPrint.propTypes = {
    headers: T.arrayOf(T.object).isRequired,
    tableData: T.array.isRequired,
    title: T.string,
    orientation: T.oneOf(['landscape', 'portrait'])
};

export default PDFPrint;