import {ERROR, SCHEDULER_CATEGORY_LIST_REQUEST, START, SUCCESS} from "../../action-types";

export const schedulerCategoriesListRequestStart = (id) => ({
    type: `${SCHEDULER_CATEGORY_LIST_REQUEST}${START}`,
    payload: id
});

export const schedulerCategoriesListRequestSuccess = (data) => ({
    type: `${SCHEDULER_CATEGORY_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const schedulerCategoriesListRequestError = (error) => ({
    type: `${SCHEDULER_CATEGORY_LIST_REQUEST}${ERROR}`,
    payload: error
});
