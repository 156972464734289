import { BIBLIOGRAPHY_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const bibliographyListRequestStart = (periodId) => ({
    type: `${BIBLIOGRAPHY_LIST_REQUEST}${START}`,
    payload: periodId
});

export const bibliographyListRequestSuccess = (data) => ({
    type: `${BIBLIOGRAPHY_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const bibliographyListRequestError = (data) => ({
    type: `${BIBLIOGRAPHY_LIST_REQUEST}${ERROR}`,
    payload: data
});

