import React, {useEffect, useState} from 'react'
import styles from './AdminDirectoryModal.module.css'
import classNames from 'classnames';
import {pack} from './Pack';
import { toLine } from './toLine';

const Rows = ({
    data = [],
    handleSelect,
    selectedId,
    isDrag,
    isDragOver,
    handleStartDrag,
    handleFinishDrag,
    handleDragEnter,
    isShow = [],
    setIsShow,
    setList,
    listData,
    setError,
    levels,
    setLevel,
}) => {

    const coloredStyle = (level) => classNames({
        [styles.firstLevel]: level == 1,
        [styles.secondLevel]: level == 2
    });

    useEffect(() => {
        const newisActive = data.map(el => isShow?.find(_el => _el.id == el.id)?.isShow ? ({id: el.id, isShow: true}) : ({id: el.id, isShow: false}));
        setIsShow(newisActive);
    }, [data?.length])

    const getColorRow = (item) => {
    if (!['topRowChoice', 'bottomRowChoice'].includes(item.id) && data.filter(el => el.parent == item.parent).filter(el => el['Код'] == item['Код']).length > 1) {
        setError(true);
        return 'listItemErr';
      } else 
    if (['topRowChoice', 'bottomRowChoice'].includes(item.id)) {
        return 'listItemAdd';
    } else
    if (item.id == isDrag?.id) {
        return 'listItemDrag';
    } else {
        setError(false);
        return 'listItem';
    }
    }


    const handleShow = (id) => {
        if (isShow.find(el => el
            ?.id == id && el 
                ?.isShow == true)) {
            setIsShow(isShow.map(el => el.id == id
                ? {
                    id: el.id,
                    isShow: false
                }
                : el))
        } else {
            setIsShow(isShow.map(el => el.id == id
                ? {
                    id: el.id,
                    isShow: true
                }
                : el))
        }
    }

    const getInputType = (key) => {
        const type = listData?.columns?.find(el => el.name == key)?.type;
        if (type && type == 'CHARACTER VARYING') {
            return 'text';
        } else if (type && type == 'INT') {
            return 'number';
        } else if (type && type == 'BOOLEAN') {
            return 'checkbox';
        }
    }

    const getInputLength = (key) => {
        const len = listData?.columns?.find(el => el.name == key && el.type == 'CHARACTER VARYING').len;
        return len ? len : 255;
    }

    const handleInput = (e, item) => {
        const _len = listData.columns.find(el => el.name == e.target.name).len;
        const newValueCode = e.target.value.substr(0, _len);
        const newValue = e.target.value.replace(/^\.|[^\d\.]/g, '');
        // .replace(/\D/, '').replace(/[^+\d]/g, '').substr(0, _len);
        const _data = 
            [...data]
                .map(el => el.id == item.id ? 
                    ({...el, isEdit: true, [e.target.name]: e.target.name == 'Код' ? newValue : newValueCode}) 
                    : el);
        setList(_data);
    }

    const handleCheckInput = (e, item, level) => {
        const _data = [...data].map(el => el.id == item.id ? ({...el, [e.target.name]: e.target.checked ? true : false}) : el);
        setList(_data);
    }
    const checkErr = (key, item) => {
        let isRequired = false;
        if (String((listData?.columns?.find(el => el.name == key)?.is_null) === 'true' && listData?.columns?.find(el => el.name == key)?.type != 'BOOLEAN') && item[key]?.length == 0) {
            isRequired = false;
        } else
        if (String((listData?.columns?.find(el => el.name == key)?.is_null) === 'true' && listData?.columns?.find(el => el.name == key)?.type != 'BOOLEAN') && item[key]?.length > 0) {
            isRequired = true;
        } else
        if (((listData?.columns?.find(el => el.name == key)?.is_null == 'true') && (listData?.columns?.find(el => el.name == key)?.type == 'BOOLEAN')) && (item[key] == false)) {
            isRequired = false;
        } else
        if (((listData?.columns?.find(el => el.name == key)?.is_null == 'true') && (listData?.columns?.find(el => el.name == key)?.type == 'BOOLEAN')) && (item[key] == true)) {
            isRequired = true;
        } else
        if (((listData?.columns?.find(el => el.name == key)?.is_null == 'false') && (listData?.columns?.find(el => el.name == key)?.type == 'BOOLEAN'))) {
            isRequired = true;
        }
        if ((listData?.columns?.find(el => el.name == key)?.is_null == 'false')) {
            isRequired = true;
        } 
        if (!isRequired) {
            setError(false);
        } else {
            setError(true);
        }
        setError(!isRequired);
        return isRequired ? 'inputColumn' : 'inputColumnErr';
    }

    const createSublist = (data, level
        , code = ''
        ) => { //вывод полей справочника
        return data.map((item, index) => {
          let codes = code + (item['Код'] ? (level != 1 ? '.' : '') + item['Код'] : 'XXX');
            if (isShow.find(el => el
                ?.id == item.parent && el.isShow == false)) {
                return;
            } else {
                return <React.Fragment
                key={item.id + '_' + index}>            
                    <tr 
                    draggable={true}
                    onDragStart={() => handleStartDrag(item)}
                    onDragEnd={() => handleFinishDrag(item)}
                    onDragOver={(e) => e.preventDefault()}
                    // onDragLeave={() => handleDragLeave(item)}
                    onDrop={() => handleFinishDrag(item)}
                    onDragEnter={() => handleDragEnter(item)}
                    // style={{background: `${isDrag && isDrag?.id == item.id ? 'grey' : ''}`,
                    //         background: `${isDragOver && isDragOver.id == item.id ? 'green' : ''}`
                    // }}
                    id={getColorRow(item)}
                    className={styles[getColorRow(item)]}
                    >
                    
                    {Array(level - 1).fill(1).map((num, index) => (<td colSpan={1} key={index}> </td>))}
                    <td colSpan={1} className={styles.udkNumber}>
                    <input 
                        required    
                        colSpan={1} 
                        value={item['Код']} 
                        name={'Код'} 
                        // maxLength="999" 
                        onKeyPress={(e) => (e.target.value.length > listData?.columns.find(el=>el.name=='Код').len - 1 ? e.preventDefault() : e.target.value)}
                        onChange={(e) => handleInput(e, item)} 
                        style={{width: '35px', margin: '0 auto'}}
                        id={checkErr('Код', item)}
                        className={styles[checkErr('Код', item)]}
                        />
                        </td>
                    
                    {Object.keys(item).map((key, index) => {
                        if (['id', 'parent', 'columns', 'Код', 'err', 'len', 'type', 'is_null', 'description', 'isNew', 'isEdit'].includes(key) ) {
                            return 
                        } else {
                            return <td colSpan={key == 'Название' ? 11 - level : 4} className={coloredStyle(level)} key={index} name={key}>
                                {   
                                    getInputType(key) == 'checkbox' ?
                                    <input 
                                        checked={((String(item[key]) == 'true') ? true : false)} 
                                        // defaultChecked={'false'}
                                        name={key}
                                        type='checkbox'  
                                        id={checkErr(key, item)}
                                        className={styles[checkErr(key, item)]} 
                                        onClick={(e) => handleCheckInput(e, item)}
                                        />
                                    :
                                    <input 
                                        value={item[key]} 
                                        name={key} 
                                        onKeyPress={(e) => (e.target.value.length > (getInputLength(key) - 1) ? e.preventDefault() : e.target.value)}
                                        type={getInputType(key)}
                                        id={checkErr(key, item)}
                                        className={styles[checkErr(key, item)]} 
                                        onChange={(e) => handleInput(e, item)}
                                        />
                                }
                                    </td>
                        }
                    })}     
                    <td colSpan={1} className={styles.icons} style={{width: '100px'}}>
                        <input className={styles.input} onClick={() => handleSelect(item.id, level)} type='checkbox' checked={item.id == selectedId ? !!selectedId : false} />
                        {(item.columns && item.columns.length) ? 
                        <svg 
                          className={styles[isShow.find(el => (el.id == item.id) && !el.isShow) ? 'iconRevert' : 'icon']} 
                          onClick={() => handleShow(item.id)} 
                          stroke="currentColor" 
                          fill="currentColor" 
                          strokeWidth="0" 
                          viewBox="0 0 24 24" 
                          height="1em" 
                          width="1em" 
                          xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                        </svg> 
                        : <></>}
                    </td>
                    </tr>
                    {(item.columns && level < 10) ?  
                    createSublist(item.columns, level + 1, codes ) : <></>}

                </React.Fragment>
            }
        })
    };
    return ( <>{createSublist(pack(data), 1)}</> )
}

export default Rows