import React from 'react';
import T from 'prop-types';
import styles from './BookLoanSpecialtyControl.module.css';
import { Col, Row } from "react-bootstrap";

const BookLoanSpecialtyControl = ({ showCovers, setShowCovers }) => {
  return (
    <div className={styles.container}>
      <Row>
        <Col md={4}>
          <div className={styles.title}>
            Книги по специальности
          </div>
        </Col>
        <Col md={3}>
          <select defaultValue={0} className={styles.select}>
            <option value={0}>Дополнительная литература</option>
          </select>
        </Col>
        <Col md={{ offset: 1, span: 4 }}>
          <div className={styles.buttonsContainer}>
            <div className={styles.button}>
              Подобрать (1)
            </div>
            <div className={styles.button}>
              Подобрать все (12)
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className={styles.checkboxContainer}>
            <label>
              <input type="checkbox"
                     checked={showCovers}
                     onChange={(e) => setShowCovers(e.target.checked)}/> показать обложки
            </label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

BookLoanSpecialtyControl.propTypes = {
  showCovers: T.bool,
  setShowCovers: T.func
};

export default BookLoanSpecialtyControl;
