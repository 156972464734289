import jsPDF from 'jspdf'
import React from 'react'
import addFont from '../../../fonts/fonts'
import { Button } from 'antd'
import moment from 'moment'
import 'jspdf-autotable'

export const DiscrepancySheetPdfPrint = ({
  clientData,
  sampleSearchFrom,
  sampleSearchTo,
  sample,
  users,
  selectedUser,
  libraries,
  selectedLibrary,
  libraryFunds,
  selectedFund,
  modalData,
}) => {
  const monthsList = [
    '',
    ' Январь',
    ' Февраль',
    ' Март',
    ' Апрель',
    ' Май',
    ' Июнь',
    ' Июль',
    ' Август',
    ' Сентябрь',
    ' Октябрь',
    ' Ноябрь',
    ' Декабрь',
  ]

  const changeFormatDate = date => {
    const day = moment(date).format('DD')
    const month = moment(date).format('M')
    const year = moment(date).format('YYYY')
    return `${day} ${monthsList[month]} ${year} г.`
  }

  const title = 'Ведомость расхождений инвентаризации №' + modalData.name
  const orientation = 'landscape'

  const responsible = users?.find(e => e.mid === selectedUser)
  const responsibleString = responsible ? `${responsible.lastname} ${responsible.firstname[0].toUpperCase()}. ${responsible.patronymic[0].toUpperCase()}.` : ''

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
    doc.setFont('Montserrat')
    doc.setFont( undefined, 'bold')
    doc.setFontSize('8')

    doc.text(title, 100, 5)

    doc.setFont( undefined, 'normal')
    doc.setFontSize('7')

    doc.text(`(к Инвентаризационной описи (сличительной ведомости) №  ${modalData.name}`, 50, 10)
    doc.text(`по объектам нефинансовых активов на   ${changeFormatDate(modalData.date_form)})`, 135, 10)

    doc.setDrawColor('#000000')
    doc.line(129, 11, 134, 11)
    doc.line(187, 11, 212, 11)

    doc.text(`от ${changeFormatDate(modalData.date_form)}`, 130, 25)
    doc.line(129, 26, 156, 26)

    doc.text(
      `Наименование учреждения (органа, организующего исполнение
  бюджета; органа, осуществляющего кассовое обслуживание)`,
      20,
      30
    )
    doc.text(`Структурное подразделение`, 20, 40)
    doc.text(`Ответственное(-ые) лицо(-а)`, 20, 45)
    doc.text(`Место проведения инвентаризации`, 20, 50)
    doc.text(`${clientData ? clientData.GOU_Name[1] : ''}`, 110, 35)
    doc.line(110, 36, 210, 36)
    doc.text(`${libraries.find(e => e.id === selectedLibrary).name}`, 110, 40)
    doc.line(110, 41, 210, 41)
    doc.text(responsibleString, 110, 45)
    doc.line(110, 46, 210, 46)
    doc.line(110, 51, 210, 51)

    doc.text(`в ред. Приказа Минфина России от 17.11.2017 № 194н`, 225, 5)

    doc.text(`КОДЫ`, 260, 10)
    doc.line(240, 11, 290, 11)
    doc.text(`Форма по ОКУД`, 218, 15)
    doc.text(`Дата`, 218, 25)
    doc.text(`по ОКПО`, 218, 35)
    doc.text(``, 260, 15)
    doc.line(240, 16, 290, 16)
    doc.text(`${moment(modalData.date_form).format('DD.MM.YYYY')}`, 260, 25)
    doc.line(240, 26, 290, 26)
    doc.text(``, 260, 35)
    doc.line(240, 36, 290, 36)
    doc.line(240, 41, 290, 41)
    doc.line(240, 46, 290, 46)
    doc.line(240, 51, 290, 51)
    doc.line(240, 11, 240, 51)
    doc.line(290, 11, 290, 51)

    doc.autoTable({
      html: '#InventoryDiscrepancyPDFTable',
      styles: { font: 'Montserrat', fontSize: 6 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        textColor: '#000',
        fillColor: 'fff',
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        textColor: '#000',
      },

      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 55,
    })
    doc.text(`Председатель комиссии`, 15, doc.autoTableEndPosY() + 5)

    doc.text(``, 50, doc.autoTableEndPosY() + 5)
    doc.setDrawColor('#000000')
    doc.line(49, doc.autoTableEndPosY() + 6, 87, doc.autoTableEndPosY() + 6)
    doc.text(`(должность)`, 60, doc.autoTableEndPosY() + 8)

    doc.text(``, 95, doc.autoTableEndPosY() + 5)
    doc.line(90, doc.autoTableEndPosY() + 6, 110, doc.autoTableEndPosY() + 6)
    doc.text(`(подпись)`, 94, doc.autoTableEndPosY() + 8)

    doc.text(``, 115, doc.autoTableEndPosY() + 5)
    doc.line(112, doc.autoTableEndPosY() + 6, 135, doc.autoTableEndPosY() + 6)
    doc.text(`(расшифровка)`, 113, doc.autoTableEndPosY() + 8)

    doc.text(`Члены комиссии`, 15, doc.autoTableEndPosY() + 20)

    doc.text(``, 55, doc.autoTableEndPosY() + 20)
    doc.line(49, doc.autoTableEndPosY() + 21, 87, doc.autoTableEndPosY() + 21)
    doc.text(`(должность)`, 60, doc.autoTableEndPosY() + 23)

    doc.text(``, 93, doc.autoTableEndPosY() + 20)
    doc.line(90, doc.autoTableEndPosY() + 21, 110, doc.autoTableEndPosY() + 21)
    doc.text(`(подпись)`, 94, doc.autoTableEndPosY() + 23)

    doc.text(``, 114, doc.autoTableEndPosY() + 20)
    doc.line(112, doc.autoTableEndPosY() + 21, 135, doc.autoTableEndPosY() + 21)
    doc.text(`(расшифровка)`, 113, doc.autoTableEndPosY() + 23)

    doc.text(``, 60, doc.autoTableEndPosY() + 35)
    doc.line(49, doc.autoTableEndPosY() + 39, 87, doc.autoTableEndPosY() + 39)
    doc.text(`(должность)`, 60, doc.autoTableEndPosY() + 41)

    doc.text(``, 95, doc.autoTableEndPosY() + 38)
    doc.line(90, doc.autoTableEndPosY() + 39, 110, doc.autoTableEndPosY() + 39)
    doc.text(`(подпись)`, 94, doc.autoTableEndPosY() + 41)

    doc.text(``, 115, doc.autoTableEndPosY() + 38)
    doc.line(112, doc.autoTableEndPosY() + 39, 135, doc.autoTableEndPosY() + 39)
    doc.text(`(расшифровка)`, 113, doc.autoTableEndPosY() + 41)

    doc.text(``, 51, doc.autoTableEndPosY() + 50)
    doc.line(49, doc.autoTableEndPosY() + 51, 87, doc.autoTableEndPosY() + 51)
    doc.text(`(должность)`, 60, doc.autoTableEndPosY() + 53)

    doc.text(``, 94, doc.autoTableEndPosY() + 50)
    doc.line(90, doc.autoTableEndPosY() + 51, 110, doc.autoTableEndPosY() + 51)
    doc.text(`(подпись)`, 94, doc.autoTableEndPosY() + 53)

    doc.text(``, 114, doc.autoTableEndPosY() + 50)
    doc.line(112, doc.autoTableEndPosY() + 51, 135, doc.autoTableEndPosY() + 51)
    doc.text(`(расшифровка)`, 113, doc.autoTableEndPosY() + 53)

    doc.text(`${changeFormatDate(modalData.date_form)}`, 15, doc.autoTableEndPosY() + 60)
    doc.line(14, doc.autoTableEndPosY() + 61, 38, doc.autoTableEndPosY() + 61)

    doc.setFont( undefined, 'bold')
    doc.setFontSize('8')
    doc.text(`Отметка бухгалтерии о принятии к учету настоящей ведомости`, 190, doc.autoTableEndPosY() + 20)

    doc.setFont( undefined, 'normal')
    doc.setFontSize('7')
    doc.text(`Исполнитель`, 180, doc.autoTableEndPosY() + 30)

    doc.text(``, 200, doc.autoTableEndPosY() + 30)
    doc.line(198, doc.autoTableEndPosY() + 31, 215, doc.autoTableEndPosY() + 31)
    doc.text(`(должность)`, 199, doc.autoTableEndPosY() + 33)

    doc.text(``, 220, doc.autoTableEndPosY() + 30)
    doc.line(218, doc.autoTableEndPosY() + 31, 233, doc.autoTableEndPosY() + 31)
    doc.text(`(подпись)`, 219, doc.autoTableEndPosY() + 33)

    doc.text(``, 240, doc.autoTableEndPosY() + 30)
    doc.line(238, doc.autoTableEndPosY() + 31, 259, doc.autoTableEndPosY() + 31)
    doc.text(`(расшифровка)`, 239, doc.autoTableEndPosY() + 33)

    doc.text(`${changeFormatDate(modalData.date_form)}`, 180, doc.autoTableEndPosY() + 40)
    doc.line(179, doc.autoTableEndPosY() + 41, 203, doc.autoTableEndPosY() + 41)

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getNumberOfPages())
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return (
    <Button onClick={toPdf} type='primary'>
      Ведомость расхождений
    </Button>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------
// doc.setFontSize('13')
// doc.setFont( undefined, 'normal')
// doc.text('ОРГАНИЗАЦИЯ:', 10, 25)

// doc.setFont( undefined, 'bold')

// doc.text('Федеральное военное государственное образовательное учреждение высшего ', 50, 25)
// doc.text('профессионального образования "Военно-космческая академия имени А.Ф. ', 10, 30)
// doc.text('Можайского" Министерства обороны Российской Федерации', 10, 35)

// doc.setFont( undefined, 'normal')
// doc.text('АКТ (номер):', 10, 42)
// doc.setFont( undefined, 'bold')
// doc.text(`${modalData.id}`, 41, 42)

// doc.setFont( undefined, 'normal')
// doc.text(`Дата начала инвентаризации:`, 10, 49)
// doc.setFont( undefined, 'bold')
// doc.text(`${moment(modalData.datestart).format('DD.MM.YYYY')}`, 84, 49)

// doc.setFont( undefined, 'normal')
// doc.text(`Дата окончания инвентаризации:`, 10, 56)
// doc.setFont( undefined, 'bold')
// doc.text(`${moment(modalData.dateend).format('DD.MM.YYYY')}`, 92, 56)

// doc.setFont( undefined, 'normal')
// doc.text('Ведомость расхождений по результатам инвентаризации №', 10, 63)

// doc.setFont( undefined, 'normal')
// doc.text(`Библиотека:`, 10, 110)
// doc.setFont( undefined, 'bold')
// doc.text(`${libraries.find(e => e.id === selectedLibrary).name}`, 40, 110)

// doc.setFont( undefined, 'normal')
// doc.text(`ФОНД: `, 10, 77)
// doc.setFont( undefined, 'bold')
// doc.text(`${libraryFunds.find(e => e.id === selectedFund).name}`, 28, 77)

// doc.setFont( undefined, 'normal')
// doc.text(`Ответственное(-ые) лицо(-а):`, 10, 84)
// doc.setFont( undefined, 'bold')
// doc.text(` ${users.find(e => e.mid === selectedUser).lastname}`, 80, 84)

// doc.setFont( undefined, 'normal')
// sample === 'full' && doc.text('Полная сверка', 10, 91)

// if (sample === 'UDK') {
//   doc.setFont( undefined, 'normal')
//   doc.text(`Сверка фонда по отделам УДК с`, 10, 91)
//   doc.setFont( undefined, 'bold')
//   doc.text(`${sampleSearchFrom || sampleSearchFrom[0].label}`, 90, 91)
//   doc.setFont( undefined, 'normal')
//   doc.text(`по`, 105, 91)
//   doc.setFont( undefined, 'bold')
//   doc.text(`${sampleSearchTo || sampleSearchTo[0].label}`, 115, 91)
// }

// if (sample === 'BBK') {
//   doc.setFont( undefined, 'normal')
//   doc.text(`Сверка фонда по отделам ББК с`, 10, 91)
//   doc.setFont( undefined, 'bold')
//   doc.text(`${sampleSearchFrom || sampleSearchFrom[0].label}`, 90, 91)
//   doc.setFont( undefined, 'normal')
//   doc.text(`по`, 105, 91)
//   doc.setFont( undefined, 'bold')
//   doc.text(`${sampleSearchTo || sampleSearchTo[0].label}`, 115, 91)
// }
// -----------------------------------------------------------------------------------------------------------------------------------------------
