import { ERROR, PUBLISHERS_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const publishersListRequestStart = () => ({
  type: `${PUBLISHERS_LIST_REQUEST}${START}`
});

export const publishersListRequestSuccess = (data) => ({
  type: `${PUBLISHERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const publishersListRequestError = (error) => ({
  type: `${PUBLISHERS_LIST_REQUEST}${ERROR}`,
  payload: error
});

