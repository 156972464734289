import { BOOK_SCAN_ORDER_DELETE_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import { createNotification } from "../../../components/notification/notification";

export const bookScanOrderDeleteRequestStart = (data) => {
  return {
    type: `${BOOK_SCAN_ORDER_DELETE_REQUEST}${START}`,
    payload: data,
  };
};

export const bookScanOrderDeleteRequestSuccess = (ordertype) => {
  // Шифр
  if (ordertype !== 1) {
    createNotification("success", "Заказ на сканирование книг удалён");
  } else {
    createNotification("success", "Заказ на бронирование удалён");
  }
  return {
    type: `${BOOK_SCAN_ORDER_DELETE_REQUEST}${SUCCESS}`,
    payload: ordertype,
  };
};

export const bookScanOrderDeleteRequestError = (error) => {
  error.result ? createNotification("error", `${error.result}`) : createNotification("error", "Действие выполнить не удалось");
  return {
    type: `${BOOK_SCAN_ORDER_DELETE_REQUEST}${ERROR}`,
    payload: error,
  };
};
