import { ERROR, LIBRARY_DELETE_POINT_REQUEST, START, SUCCESS } from "../../../action-types";

export const libraryDeletePointRequestStart = (pointId, libraryId) => ({
  type: `${LIBRARY_DELETE_POINT_REQUEST}${START}`,
  payload: { pointId, libraryId }
});

export const libraryDeletePointRequestSuccess = (data) => ({
  type: `${LIBRARY_DELETE_POINT_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryDeletePointRequestError = (data) => ({
  type: `${LIBRARY_DELETE_POINT_REQUEST}${ERROR}`,
  payload: data
});