import { combineReducers } from "redux";
import { coversCountReducer } from "./count/coversCountReducer";
import { coversListReducer } from "./list/coversListReducer";
import { coversFormReducer } from "./form/coversFormReducer";
import { coversSearchReducer } from "./search/coversSearchReducer";
import { coversLoaderReducer } from "./loader/coversLoaderReducer";
import { coversLoadSuccessReducer } from './loadSuccess/coversLoadSuccessReducer';
import { coversFileReducer } from './file/coversFileReducer';
import { advancedSearchParamsReducer } from './advancedSearchParams/params';

export const coversReducer = combineReducers({
  count: coversCountReducer,
  list: coversListReducer,
  form: coversFormReducer,
  file: coversFileReducer,
  search: coversSearchReducer,
  loader: coversLoaderReducer,
  loadSuccess: coversLoadSuccessReducer,
  advancedSearchParams: advancedSearchParamsReducer,
});
