import { BASE_URL_WITHOUT_API } from './api'

export const menuStructure = [
  {
    title: 'Главная',
    link: '/',
    access: {
      reader: true,
      librarian: true,
    },
  },
  {
    title: 'Комплектование',
    link: null,
    access: {
      reader: false,
      librarian: true,
    },
    dropdown: [
      {
        title: 'Первичные документы',
        link: '/documents',
      },
      {
        title: 'Исключение дублетных записей',
        link: '/duplicate',
      },
      {
        title: 'Рекомплектование',
        link: '/remcomplect',
      },
      {
        title: 'Книгообеспеченность',
        link: '/booksupply',
      },
      {
        title: 'Нормы книгообеспеченности',
        link: '/booksupplystandarts',
      },
      {
        title: 'Инвентарная книга',
        link: '/bookinventory',
      },
      {
        title: 'Книга движения фонда',
        link: '/fund_moving',
      },
      {
        title: 'Книга суммарного учета',
        link: '/fund_accounting',
      },
      {
        title: 'Заказы на подбор литературы',
        link: '/bookorders',
      },
      {
        title: 'Заказы на поступление литературы',
        link: '/newbookorders',
      },
      {
        title: 'Инвентаризация',
        link: '/inventory',
      },
      {
        title: 'Книга учета секретных документов',
        // Шифр
        link: ({ options }) => (options.data && options.data.outsecrecy_name === 'секретно' ? '/booksecretdoc' : null),
      },
    ],
  },
  {
    title: 'Каталогизация',
    link: null,
    access: {
      reader: false,
      librarian: true,
    },
    dropdown: [
      {
        title: 'Алфавитный каталог',
        link: '/library/alphabet',
      },
      {
        title: 'Авторский знак (таблицы Хавкиной)',
        link: '/havkina',
      },
      {
        title: 'УДК',
        link: '/udk',
      },
      {
        title: 'Импорт',
        link: '/import',
      },
      {
        title: 'Загрузка электронного учебника',
        link: '/electronic_book_import',
      },
      {
        title: 'ББК',
        link: '/bbk',
      },
      {
        title: 'СВНИ',
        link: '/svni',
      },
      {
        title: 'Рубрикатор',
        link: '/rubricator',
      },
      {
        title: 'Выгрузка в изделие 83т260',
        link: `/spo_intergartion`,
      },
    ],
  },
  {
    title: 'Отчётность',
    link: null,
    access: {
      reader: false,
      librarian: true,
    },
    dropdown: [
      {
        title: 'Отчёты',
        link: '/reports',
      },
      {
        title: 'Средство анализа больших данных',
        link: '/bigdata',
      },
      {
        title: 'Книга учета осведомленности в сведениях, составляющих государственную тайну',
        // Шифр
        link: ({ options }) => (options.data && options.data.outsecrecy_name === 'секретно' ? '/state_secret_book' : null),
      },
    ],
  },
  {
    title: 'Справочники',
    link: null,
    access: {
      reader: false,
      librarian: true,
    },
    dropdown: [
      {
        title: 'Авторы',
        link: '/authors',
      },
      {
        title: 'Издательства',
        link: '/publishers',
      },
      {
        title: 'Библиографические записи',
        link: '/books',
      },
      {
        title: 'Инвентарные номера',
        link: '/inv_numbers',
      },
      {
        title: 'УДК',
        link: '/udk',
      },
      {
        title: 'ББК',
        link: '/bbk',
      },
      {
        title: 'СВНИ',
        link: '/svni',
      },
          {
        title: 'Организации',
        link: '/institutions',
      },
      {
        title: 'Библиотеки',
        link: '/library',
      },
      {
        title: 'Адресное хранение',
        link: '/location',
      },
      {
        title: 'Типы обрабатываемой литературы',
        link: '/library/types',
      },
      {
        title: 'Виды документов',
        link: '/document_types',
      },
      {
        title: 'Обложки',
        link: '/covers',
      },
      {
        title: 'Коллекции',
        link: '/collections',
      },
      {
        title: 'Категории (Предметные рубрики)',
        link: '/categories',
      },
      {
        title: 'Серии',
        link: '/series',
      },
      {
        title: 'Литера',
        link: '/leters',
      },
      {
        title: 'Дисциплины',
        link: '/discipline',
      },
      {
        title: 'Антиплагиат коллекция',
        link: '/antiplagiat_collection',
      },
      {
        title: 'Экспорт БЗ',
        link: '/booksexport',
      }
    ],
  },
  {
    title: 'Сканирование',
    link: null,
    access: {
      reader: false,
      librarian: true,
    },
    dropdown: [
      {
        title: 'План оцифровки бумажных носителей',
        link: '/bookscan',
      },
      {
        title: 'План сканирования обложек',
        link: '/coversScanPlan',
      },
    ],
  },
  {
    title: 'Книговыдача',
    link: '',
    access: {
      reader: false,
      librarian: true,
    },
    dropdown: [
      {
        title: 'Алфавитный указатель',
        link: '/library/alphabet',
      },
      {
        title: 'Читательский формуляр',
        link: '/library/reader_search',
      },
      {
        title: 'Заказы на выдачу книг',
        link: '/bookreserve',
      },
      {
        title: 'Передвижные библиотеки',
        link: '/mobile_library'
      }
    ],
  },

  {
    title: 'Электронные ресурсы',
    link: null,
    dropdown: [
      {
        title: 'Внешние информационные ресурсы',
        link: ({ options }) => {
          if (options.data) {
            // Шифр
            if (options.data.outsecrecy_name === 'секретно') return null
            // Шифр
            if (options.data.outsecrecy === 2) return null
            return '/external'
          }
        },
      },
      {
        title: 'Электронный читальный зал',
        link: '/reading',
      },
      {
        title: 'Поиск ресурсов в сторонней АБИС',
        link: ({ options }) => {
          if (options.data) {
            // Шифр
            if (options.data.outsecrecy_name === 'секретно') return null
            // Шифр
            if (options.data.outsecrecy === 2) return null
            return '/external_resources'
          }
        },
      },
      {
        title: 'Поиск по картинке',
        link: '/search_by_image',
      },
      {
        title: 'Интеграция с изделием 83т645',
      },
      {
        title: 'Профессорско-преподавательский состав',
        link: ({ options }) => options.data && options.data.PasportPPSLink,
        isSubItem: true,
      },
      {
        title: 'Расписание',
        link: ({ options }) => options.data && options.data.PasportScheduleLink,
        isSubItem: true,
      },
    ],
  },
  {
    title: 'Книги',
    link: '/reading',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Документы',
    link: '/librarymaterials/7',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Презентации',
    link: '/librarymaterials/4',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Аудио',
    link: '/librarymaterials/6',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Видео',
    link: '/librarymaterials/5',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: '3D модели',
    link: '/librarymaterials/10',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Карты',
    link: '/librarymaterials/14',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Периодика',
    link: '/librarymaterials/11',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Научные работы',
    link: '/librarymaterials/13',
    access: {
      reader: true,
      librarian: false,
    },
  },
  {
    title: 'Руководство пользователя',
    link: `${BASE_URL_WITHOUT_API}/usermanual.pdf`,
    access: {
      reader: true,
      librarian: true,
    },
  },
  {
    
    title: 'Личный кабинет',
    access: {
      reader: true,
      librarian: true,
    },
    dropdown: [
      {
        title: 'Профессорско-преподавательский состав',
        link: '/teachers',
      },
      {
        title: 'Расписание',
      },
      {
        title: 'Расписание занятий',
        link: '/schedule',
        isSubItem: true,
      },
      {
        title: 'ТЕКУЩАЯ УСПЕВАЕМОСТЬ - ЖУРНАЛ ЗАНЯТИЙ',
        link: '/current_perfomance',
        isSubItem: true,
      },
      {
        title: 'Промежуточная аттестация',
        link: '/performances',
        isSubItem: true,
      },
      {
        title: 'Личное расписание',
        link: '/schedule_on_date',
        isSubItem: true,
      },

      {
        title: 'Результаты',
      },
      {
        title: 'Статистика посещаемости',
        link: '/turnout_stats',
        isSubItem: true,
      },
      {
        title: 'Прохождение электронных курсов',
        link: '/electronic_courses',
        isSubItem: true,
      },
      {
        title: 'Прохождение электронного тестирования',
        link: '/tests',
        isSubItem: true,
      },
      {
        title: 'Проверка на заимствования',
        link: '/borrowing_test',
        isSubItem: true,
      },

      {
        title: 'Задания',
      },
      {
        title: 'Индивидуальные задания',
        link: '/student_projects',
        isSubItem: true,
      },
      {
        title: 'Заказанные документы',
        link: '/book_scan_orders',
      },
      {
        title: 'Уведомления',
        link: '/message',
      },
      {
        title: 'Медиапортал',
        link: '/media_resource',
      },
      {
        title: 'Сообщения',
        link: `${BASE_URL_WITHOUT_API}/converse`,
      },
      {
        title: 'Поисковые запросы',
        link: '/search_audit',
      },
      {
        //
        title: 'Читательский формуляр',
        link: ({ auth }) => `/library_card/${auth.data.mid}`,
      },
      {
        title: 'Статистика ответов на вопросы',
        link: ({ auth }) => (auth.data && auth.data.isteacher === true ? '/answerstat' : null),
      },
      {
        title: 'Журнал изъятых тестов',
        link: ({ auth }) => (auth.data && auth.data.islibrarian === true ? '/exempttests' : null),
      },
    ],
  },
]
