import { call, put, takeLatest, select } from 'redux-saga/effects'
import { START, BOOK_SECRET_DOC_COUNT_REQUEST } from '../../actions/action-types'
import { getBookSecretDocCount, getBookSecretDocList } from '../../../api/booksecretdoc'
import {
  bookSecretDocListRequestError,
  bookSecretDocListRequestStart,
  bookSecretDocListRequestSuccess,
} from '../../actions/booksecretdoc/list'

export default function* bookSecretDocWatcher() {
  yield takeLatest(`${BOOK_SECRET_DOC_COUNT_REQUEST}${START}`, listWorker)
}

function* listWorker({ payload: formData }) {
  try {
    const response = yield call(getBookSecretDocList, formData)
    yield put(bookSecretDocListRequestSuccess(response.data))
  } catch (error) {
    yield put(bookSecretDocListRequestError(error))
  }
}
