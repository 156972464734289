import { OPTIONS_LOGO_GET_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const optionsLogoReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${OPTIONS_LOGO_GET_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
