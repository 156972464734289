import {ERROR, SCHEDULER_ENABLED_REQUEST, START, SUCCESS} from "../action-types";


export const schedulerEnableRequestStart = (id, enabled) => ({
    type: `${SCHEDULER_ENABLED_REQUEST}${START}`,
    payload: id, enabled
});

export const schedulerEnableRequestSuccess = () => ({
    type: `${SCHEDULER_ENABLED_REQUEST}${SUCCESS}`,
});

export const schedulerEnableRequestError = (error) => ({
    type: `${SCHEDULER_ENABLED_REQUEST}${ERROR}`,
    payload: error
});
