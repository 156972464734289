import React, { useRef, useEffect, useState } from 'react'
import T from 'prop-types'
import { EDIT } from '../../../constants/modalTypes'
import styles from '../../Authors/Modal/AuthorModal.module.css'
import { Col, OverlayTrigger, Row, Tab, Tabs } from 'react-bootstrap'
import ModalImagePopover from '../../Modal/ImagePopover'
import ModalImage from '../../Modal/ImageWrapper'
import { usersGetPhotoRequestStart } from '../../../redux/actions/adminUsers/photo/getPhoto'
import { usersSavePhotoRequestStart } from '../../../redux/actions/adminUsers/photo/savePhoto'
import { connect } from 'react-redux'
import { usersDeletePhotoRequestStart } from '../../../redux/actions/adminUsers/photo/deletePhoto'
import { changeUserPassword } from '../../../api/adminUsers/users'
import ModalWindow from '../../ModalWindow'
import AdminUsersModalForm from './Form'
import { setUserPhoto } from '../../../redux/actions/adminUsers/photo/setPhoto'
import { usersPhotoReset } from '../../../redux/actions/adminUsers/photo/resetPhoto'
import SpinnerOuter from '../../UtulityComponents/SpinnerOuter'
import { api } from '../../../App'
import Prescription from './Prescription'

const mapStateToProps = ({ adminUsers }) => ({
  imgUrl: adminUsers.photo,
})

const mapDispatchToProps = {
  requestPhoto: usersGetPhotoRequestStart,
  savePhoto: usersSavePhotoRequestStart,
  setPhoto: setUserPhoto,
  deletePhoto: usersDeletePhotoRequestStart,
  changePassword: changeUserPassword,
  resetPhoto: usersPhotoReset,
}

const AdminUsersModal = ({
  setModalData,
  onlyInfo,
  onSubmit,
  modalData,
  imgUrl,
  requestPhoto,
  deletePhoto,
  setPhoto,
  resetPhoto,
  groups,
  isLoading,
}) => {
  const { modalType, data } = modalData;
  const isVisible = !!modalType;
  const imageInput = useRef(null);
  const [userRoles, setUserRoles] = useState([]);
  const [militaryRank, setMilitaryRank] = useState([]);
  const [admission, setAdmission] = useState([]);

  useEffect(() => {
    data.mid && api.get(`/people/roles/${data.mid}`).then(data => setUserRoles(data.data))
  }, [data])

  useEffect(() => {
    if (data && data.photosize && !imgUrl) {
      requestPhoto(data.mid)
    }
  }, [data, imgUrl, requestPhoto])

  const onDeleteImage = () => {
    deletePhoto(data.mid)
  }

  const onSaveImage = () => {
    imageInput.current.click()
  }

  const onLoadImage = event => {
    setPhoto(event.target.files[0])
  }

  const onSubmitModal = values => {
    onSubmit({ ...modalData, data: values })
  }

  const resolveUserPhoto = photo => {
    if (typeof photo === 'string') {
      return photo
    } else if (typeof photo === 'object') {
      return URL.createObjectURL(photo)
    }
  }

  useEffect(() => {
    !militaryRank.length && api.get(`/military_rank`).then(res => setMilitaryRank(res.data));
  }, [militaryRank])
  
  useEffect(() => {
    !admission.length && api.get(`/admission_form`).then(res => setAdmission(res.data));
  }, [admission])
  
  return (
    <ModalWindow
      isOpen={isVisible}
      title={`Пользователь: ${modalType === EDIT ? 'Редактирование' : 'Добавление'}`}
      onRequestClose={() => {
        setModalData({ modalType: null, data: null })
        resetPhoto()
      }}
      width={1100}
    >
      {/* {isLoading && <SpinnerTime />} */}
      <Row>
        <Col md={3}>
          <div className={styles.imageContainer}>
            {!imgUrl && data.photosize > 0 ? (
              <SpinnerOuter isLoading />
            ) : (
              <OverlayTrigger
                trigger='click'
                placement='right'
                overlay={props => <ModalImagePopover deleteImage={onDeleteImage} uploadImage={onSaveImage} {...props} />}
              >
                {imgUrl ? <ModalImage url={resolveUserPhoto(imgUrl)} /> : <ModalImage placeholder />}
              </OverlayTrigger>
            )}
            <input type='file' className={styles.fileInput} ref={imageInput} onChange={onLoadImage} accept='image/*' />
          </div>
        </Col>
        <Col md={9}>
          <Tabs defaultActiveKey='index' id='AdminUsersModalTabs'>
            <Tab eventKey='index' title='Главная'>
              <AdminUsersModalForm
                admission={admission}
                militaryRank={militaryRank}
                groups={groups}
                modalType={modalType}
                modalData={modalData}
                onSubmit={onSubmitModal}
                onlyInfo={onlyInfo}
              />
            </Tab>
            {data.mid ? 
            <Tab eventKey='Prescriptions' title='Предписания'>
              <Prescription mid={data.mid}/>
            </Tab>
            :
            <></>}
            <Tab eventKey='members' title='Роли пользователя'>
              {userRoles.map(e => (
                <div className='mb-1'>{e.name}</div>
              ))}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </ModalWindow>
  )
}

AdminUsersModal.propTypes = {
  modalData: T.object,
  setModalData: T.func,
  onlyInfo: T.bool,
  onSubmit: T.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersModal)
