import React, { useState, useEffect } from 'react'
import styles from './Prescription.module.css'
import {Button} from 'antd'
import moment from 'moment'
import * as Yup from 'yup'
import { api } from '../../../../App'
import ModalWindow from '../../../ModalWindow'
import { createNotification } from '../../../notification/notification'

const Prescription = ({mid}) => {
  
    const [list, setList] = useState([]);
    const [editList, setEditList] = useState({});
    const [type, setType] = useState('');
    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        getList();
    }, [])
    
    const getList = () => {
        if (mid) {
            api.get('/prescription?people_id=' + mid).then((data) => {
                setList(data.data);
            })
        }
    }

    const handleAdd = () => {
        setEditList({ account_number: '', prescription_date: new Date(), people_id: mid });
        setType('add');
    }

    const handleChange = (value, id) => {
        const newList = list.map((item) => {
            if (item.people_id == id) {
                return {
                    ...item,
                    [value.target.name]: value.target.value
                }
            }
            return item
        })        
        setList(newList);
    }
    
    const handleChangeEdit = (value) => {
        setEditList(({...editList, [value.target.name]: value.target.value}));
    }

    const handleModalEdit = (item) => {
        setType('edit')
        setEditList(item);
    }

    const handleCancelEdit = () => {
        setEditList({});
    }

    const handleSave = (item) => {
        if (type === 'add') {
            api.post('/prescription', item).then((data) => {
                if (data.status == 201) {
                    createNotification('success', 'Успешно');
                } else {
                    createNotification('error', 'Ошибка');
                }
                setEditList({});
                getList();
                setType('');
            })
        } else {
            api.put(`/prescription/${item.id}`, item).then((data) => {
                if (data.status == 200) {
                    createNotification('success', 'Успешно');
                } else {
                    createNotification('error', 'Ошибка');
                }
                setEditList({});
                getList();
                setType('');
            })
        }
    }
    
    const handleDelete = (id) => {
        setIsDelete(id);
    }

    const deleteItem = (id) => {
        api.delete(`/prescription/${id}`).then((data) => {
            if (data.status == 200) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
            setEditList({});
            getList();
            setIsDelete(false);
        })
    }

    return (
    <div className={styles.wrapper}>
    <ModalWindow width={'300px'} isOpen={isDelete} title={`Удалить запись?`} onRequestClose={() => setIsDelete(false)}>
        <div className={'d-flex justify-content-between'} style={{margin: '.5rem 0'}}>
            <Button type='danger' onClick={() => deleteItem(isDelete)}>Удалить</Button>
            <Button type='primary' onClick={() => setIsDelete(false)}>Отменить</Button>
        </div>
    </ModalWindow>
    <ModalWindow isOpen={!!editList?.people_id} title={`Предписание: ${type === 'add' ? 'Создание' : 'Редактирование'}`} onRequestClose={() => handleCancelEdit()}>
        <div className={styles.inputs}>
            <div className={styles.inputPopup}>
                <input
                    className={styles.inputPopup}
                    placeholder='Учетный номер'
                    name='account_number'
                    value={editList.account_number}
                    onChange={(e) => handleChangeEdit(e)}
                />
            </div>
            <div>
                <input
                    placeholder='Дата'
                    name='prescription_date'
                    value={moment(editList.prescription_date).format('YYYY-MM-DD')}
                    onChange={(e) => handleChangeEdit(e)}
                    type='date'
                />
            </div>
        </div>
        <div className={'d-flex justify-content-between'} style={{margin: '.5rem 0'}}>
            <Button type='primary' onClick={() => handleSave(editList)}>Сохранить</Button>
            <Button type='danger' onClick={handleCancelEdit}>Отменить</Button>
        </div>
    </ModalWindow>

        <div className={'d-flex justify-content-end'} style={{margin: '.5rem 0'}}>
            <Button onClick={handleAdd} type='primary'>Создать</Button>
        </div>

        <div>

        </div>

        {list.length > 0 ?
        <table className={styles.table}>
            <thead>
                    <tr>
                    <th>Учетный номер</th>
                    <th>Дата</th>
                    <th colSpan={2}>Действия</th>
                </tr>
            </thead>
            <tbody>
                {list?.map(item => {
                return (
                    <tr key={item.id}>
                        <td>
                            <input
                                disabled
                                name='account_number'
                                value={item.account_number}
                                onChange={(e) => handleChange(e, item.people_id)}
                            />
                        </td>
                        <td>
                            <input
                                disabled
                                name='prescription_date'
                                value={moment(item.prescription_date).format('YYYY-MM-DD')}
                                onChange={(e) => handleChange(e, item.people_id)}
                                type='date'
                            />
                        </td>
                        <td>
                            <Button type="primary" onClick={() => handleModalEdit(item)}>Редактировать</Button>
                        </td>
                        <td>
                            <Button type="danger" onClick={() => handleDelete(item.id)}>Удалить</Button>
                        </td>
                    </tr>
                )})}
            </tbody>
        </table>
        : <b>Список пуст</b>}

        {/* <div className={'d-flex justify-content-end'}>
            {list?.map((item, index) => {
            return (
                <div key={index} className={styles.item}>
                    <div className={styles.name}>{item.name}</div>
                    <div className={styles.date}>{moment(item.date).format('DD.MM.YYYY')}</div>
                </div>
            )})}
        </div> */}
    </div>
  )
}

export default Prescription