import {BOOK_FILES_GET_REQUEST, BOOK_FILES_UPLOAD_REQUEST, START, SUCCESS, ERROR} from "../../../actions/action-types";

const initialState = false;

export const uploadLoaderReducer = (state = initialState, action) => {
  switch (action.type) {

      case `${BOOK_FILES_UPLOAD_REQUEST}${START}`:
          return true;

      case `${BOOK_FILES_GET_REQUEST}${SUCCESS}`:
          return false;
      
      case `${BOOK_FILES_UPLOAD_REQUEST}${ERROR}`:
          return false;

    default: return state;
  }
};