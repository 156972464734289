import React from 'react';
import T from 'prop-types';
import PdfPrintPanel from "../../../PDFPrint/Panel";
import addFont from "../../../../fonts/fonts";
import jsPDF from "jspdf";
import {createAutotableConfig} from "../../../PDFPrint/utils";

const CoversScanPdfPrint = ({ tableData, getImageFromCanvas }) => {

    if(!tableData) return null;

    const title = "План сканирования обложек";
    const orientation = 'portrait';

    const createData = () => tableData.map(row => [row.name, row.amount]);


    const createDocData = async () => {
        addFont(jsPDF.API);
        const doc = new jsPDF({putOnlyUsedFonts: true, orientation: orientation});
        doc.setFont("Montserrat");

        doc.text(title, 15, 15);

        doc.autoTable(
            createAutotableConfig(
                ['', ''],
                createData(),
                'never'
            ));

        return new Promise((resolve, reject) => {
            getImageFromCanvas()
                .then(imageData => {
                    doc.addPage('a4', orientation);
                    doc.addImage(imageData, 'JPEG', 15, 15, 180, 89);
                    resolve(doc);
                })
                .catch(err => reject(err))
        });
    };

    const toPdf = () => {
        createDocData()
            .then(doc => doc.save(`${title}.pdf`))
    };

    const toPrint = () => {
        createDocData()
            .then(doc => {
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            })
    };

    return (
        <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf}/>
    );
};

CoversScanPdfPrint.propTypes = {
    tableData: T.array.isRequired,
    getImageFromCanvas: T.func,
};

export default CoversScanPdfPrint;