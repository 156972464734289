import { combineReducers } from "redux";
import { racksReducer } from "./racks/racksReducer";
import { roomsReducer } from "./rooms/roomsReducer";
import { shelvesReducer } from "./shelves/shelvesReducer";

export const locationReducer = combineReducers({
  racks: racksReducer,
  rooms: roomsReducer,
  shelves: shelvesReducer
});
