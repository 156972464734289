import { all } from 'redux-saga/effects';
import documentsSaga from "./document/booksOrderSaga";
import documentBooksSaga from "./books/documentBooksSaga";

export default function* booksOrderSaga() {
    yield all([
        documentsSaga(),
        documentBooksSaga()
    ]);
}
