import { call, put, takeLatest } from "redux-saga/effects";
import { LIBRARY_DELETE_REQUEST, START } from "../../../../actions/action-types";
import { libraryDeleteRequestError, libraryDeleteRequestSuccess } from "../../../../actions/library/info/delete";
import { libraryDelete } from "../../../../../api/library/library";
import { libraryListRequestStart } from "../../../../actions/library/info/list";
import {createNotification} from "../../../../../components/notification/notification";

export default function* libraryDeleteWatcher() {
  yield takeLatest(`${LIBRARY_DELETE_REQUEST}${START}`, libraryDeleteWorker)
}

function* libraryDeleteWorker({payload: id}) {
  try {
    const response = yield call(libraryDelete, id);
    if(response.data === "OK"){
      yield put(libraryDeleteRequestSuccess(response.data));
      yield put(libraryListRequestStart());
      createNotification('success', 'Запись успешно удалена')
    }else{
      createNotification('error', 'Запись невозможно удалить')
    }
  } catch (error) {
    yield put(libraryDeleteRequestError(error));
  }
}