import { ERROR, LOGOUT_REQUEST, START, SUCCESS } from "../action-types";

export const logoutRequestStart = () => ({
  type: `${LOGOUT_REQUEST}${START}`
});

export const logoutRequestSuccess = (data) => ({
  type: `${LOGOUT_REQUEST}${SUCCESS}`,
  payload: data
});

export const logoutRequestError = (data) => ({
  type: `${LOGOUT_REQUEST}${ERROR}`,
  payload: data
});