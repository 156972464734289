import { COVERS_BATCH_EDITING_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const coversBatchEditingRequestStart = (data) => ({
  type: `${COVERS_BATCH_EDITING_REQUEST}${START}`,
  payload: data
});

export const coversBatchEditingRequestSuccess = (data) => {
  createNotification('success', 'Пакетное редактирование сохранено');
  return {
    type: `${COVERS_BATCH_EDITING_REQUEST}${SUCCESS}`,
    payload: data
  }
};

export const coversBatchEditingRequestError = (data) => {
  createNotification('error', 'Не удалось осуществить пакетное редактирование');
  return {
    type: `${COVERS_BATCH_EDITING_REQUEST}${ERROR}`,
    payload: data
  }
};
