import React from 'react'
import T from 'prop-types'
import styles from './SummaryControlTable.module.css'
import moment from 'moment'
import { Table, Space, Typography } from 'antd'
import { splitSumToRubAndCop } from '../../../utils/helpers/helpers'

const FundAccountingTable = ({ data }) => {
  const { Column, ColumnGroup } = Table
  const { Text } = Typography

  const ITEM_TYPES = {
    BOOKS: 'Книги',
    BROCHURES: 'Брошюры',
    MAGAZINES: 'Журналы',
    NEWSPAPERS: 'Газеты',
  }

  const calcSum = (arr, prop) => {
    return arr.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue[prop])
    }, 0)
  }

  const calcSumWithCondition = (arr, prop, condition) => {
    return arr.reduce((accumulator, currentValue) => {
      if (condition(currentValue)) {
        return accumulator + Number(currentValue[prop])
      } else {
        return accumulator + 0
      }
    }, 0)
  }

  const part_1_summary = data => {
    let totalSum = 0
    let totalLinkCount = 0
    let totalBookCount = 0
    let totalItems = {
      books: 0,
      brochures: 0,
      magazines: 0,
      newspapers: 0,
    }

    data.forEach(row => {
      totalSum += row.summ
      totalLinkCount += row.link_count
      totalBookCount += row.book_count

      switch (row.book_kind) {
        case ITEM_TYPES.BOOKS:
          totalItems.books += row.book_count
          break
        case ITEM_TYPES.BROCHURES:
          totalItems.brochures += row.book_count
          break
        case ITEM_TYPES.MAGAZINES:
          totalItems.magazines += row.book_count
          break
        case ITEM_TYPES.NEWSPAPERS:
          totalItems.newspapers += row.book_count
          break
      }
    })

    return { totalSum, totalLinkCount, totalBookCount, totalItems }
  }

  const part_2_summary = data => {}

  const transform_part_3 = data => {
    let result = []

    if (!data.part_1_list_1.length) {
      return result
    }

    result.push({
      title: `Состоит на 01.01.${moment(data.part_1_list_1[0].doc_date).format('YYYY')}`,
    })
    result.push({
      title: `Поступило за ${moment(data.part_1_list_1[0].doc_date).format('YYYY')} год`,
      ...part_1_summary(data.part_1_list_1),
    })
    result.push({
      title: `Выбыло за ${moment(data.part_1_list_1[0].doc_date).format('YYYY')} год`,
      ...part_1_summary(data.part_2_list_1),
    })

    return result
  }

  return (
    <Space direction='vertical'>
      {/*ЧАСТЬ 1*/}

      {!!data.part_1_list_1?.length && (
        <div className={styles.tableWrap}>
          <Table
          dataSource={data.part_1_list_1}
          title={() => 'Часть 1. Поступление документов в фонд. Лист 1'}
          pagination={false}
          className={styles.table}
          scroll={{ y: 500 }}
          summary={pageData => {
            let { totalSum, totalLinkCount, totalBookCount, totalItems } = part_1_summary(pageData)
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={4}>
                  <Text strong>Итого:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{splitSumToRubAndCop(totalSum)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalLinkCount}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalBookCount}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalItems.books}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalItems.brochures}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalItems.magazines}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalItems.newspapers}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }}
          /* size='small' */
          bordered
        >
          <ColumnGroup title='ПОСТУПИЛО' width={1300}>
            <Column title='Дата записи' dataIndex='doc_date' render={(text, record) => moment(text).format('DD.MM.YYYY')} />
            <Column title='Номер п.п.' dataIndex='doc_serial_number' key='doc_serial_number' />
            <Column title='Источник поступления' dataIndex='supplier_name' key='supplier_name' width={300} />
            <Column title='Номер сопровод. документа' dataIndex='doc_num' key='doc_num' />
            <Column title='на сумму' dataIndex='summ' render={text => splitSumToRubAndCop(text)} />

            <ColumnGroup title='Всего'>
              <Column title='названий' dataIndex='link_count' key='link_count' />
              <Column title='экземпл.' dataIndex='book_count' key='book_count' />
            </ColumnGroup>
            <ColumnGroup title='В том числе по видам документов'>
              <Column
                title='книги'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.BOOKS) {
                    return record.book_count
                  }
                }}
              />
              <Column
                title='брошюры'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.BROCHURES) {
                    return record.book_count
                  }
                }}
              />
              <Column
                title='журналы'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.MAGAZINES) {
                    return record.book_count
                  }
                }}
              />
              <Column
                title='газеты(год.компл.)'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.NEWSPAPERS) {
                    return record.book_count
                  }
                }}
              />
            </ColumnGroup>
          </ColumnGroup>
        </Table>
        </div>
      )}

      {!!data.part_1_list_2?.length && (
        <div className={styles.tableWrap}>
          <Table
          dataSource={data.part_1_list_2}
          title={() => 'Часть 1. Поступление документов в фонд. Лист 2'}
          className={styles.table}
          size='small'
          scroll={{ y: 500 }}
          pagination={false}
          bordered
        >
          <Column title='Номер п.п.' dataIndex='doc_serial_number' key='doc_serial_number' />
          {data.funds.map(i => (
            <Table key={i.fund_name} dataSource={data.part_1_list_2} title={() => i} size='small' bordered>
              <Column
                title='на сумму'
                dataIndex='funds'
                render={(text, record) => {
                  let res = ''
                  Object.keys(text).forEach(k => {
                    if (k === i) {
                      res = splitSumToRubAndCop(text[k].summ)
                    }
                  })
                  return res
                }}
              />
              <Column
                title='всего экземпл.'
                dataIndex='funds'
                render={(text, record) => {
                  let res = ''
                  Object.keys(text).forEach(k => {
                    if (k === i) {
                      res = text[k].book_count
                    }
                  })
                  return res
                }}
              />
            </Table>
          ))}
        </Table>
        </div>
      )}

      {/*ЧАСТЬ 2*/}

      {!!data.part_2_list_1?.length && (
        <div className={styles.tableWrap}>
          <Table
          dataSource={data.part_2_list_1}
          className={styles.table}
          title={() => 'Часть 2. Выбытие документов из фонда. Лист 1'}
          scroll={{ y: 500 }}
          summary={pageData => {
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text strong>Итого:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{splitSumToRubAndCop(calcSum(pageData, 'summ'))}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{calcSum(pageData, 'link_count')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{calcSum(pageData, 'book_count')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{calcSumWithCondition(pageData, 'book_count', item => item.book_kind === ITEM_TYPES.BOOKS)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>
                    {calcSumWithCondition(pageData, 'book_count', item => item.book_kind === ITEM_TYPES.BROCHURES)}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>
                    {calcSumWithCondition(pageData, 'book_count', item => item.book_kind === ITEM_TYPES.MAGAZINES)}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>
                    {calcSumWithCondition(pageData, 'book_count', item => item.book_kind === ITEM_TYPES.NEWSPAPERS)}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong></Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong></Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong></Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong></Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }}
          pagination={false}
          size='small'
          bordered
        >
          <ColumnGroup title='ВЫБЫЛО'>
            <Column title='Дата записи' dataIndex='doc_date' render={(text, record) => moment(text).format('DD.MM.YYYY')} />
            <Column title='Номер п.п.' dataIndex='doc_serial_number' key='doc_serial_number' />
            <Column
              title='Номер, дата, акт выбытия'
              dataIndex='supplier_name'
              render={(text, record) => `${record.doc_num} от ${moment(record.doc_date).format('DD.MM.YYYY')}`}
              width={300}
            />
            <Column title='на сумму' dataIndex='summ' render={text => splitSumToRubAndCop(text)} />

            <ColumnGroup title='Всего'>
              <Column title='названий' dataIndex='link_count' key='link_count' />
              <Column title='экземпл.' dataIndex='book_count' key='book_count' />
            </ColumnGroup>
            <ColumnGroup title='В том числе по видам документов'>
              <Column
                title='книги'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.BOOKS) {
                    return record.book_count
                  }
                }}
              />
              <Column
                title='брошюры'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.BROCHURES) {
                    return record.book_count
                  }
                }}
              />
              <Column
                title='журналы'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.MAGAZINES) {
                    return record.book_count
                  }
                }}
              />
              <Column
                title='газеты(год.компл.)'
                dataIndex='book_kind'
                render={(text, record) => {
                  if (text === ITEM_TYPES.NEWSPAPERS) {
                    return record.book_count
                  }
                }}
              />
            </ColumnGroup>
          </ColumnGroup>
          <ColumnGroup title='По причинам выбытия'>
            <Column title={() => <div className={styles.vertical_cell}>Ветхость</div>} dataIndex='firstName' key='firstName' />
            <Column title={() => <div className={styles.vertical_cell}>Устарелость</div>} dataIndex='firstName' key='firstName' />
            <Column title={() => <div className={styles.vertical_cell}>Утрата</div>} dataIndex='firstName' key='firstName' />
            <Column
              title={() => <div className={styles.vertical_cell}>Другие причины</div>}
              dataIndex='firstName'
              key='firstName'
            />
          </ColumnGroup>
        </Table>
        </div>
      )}

      {!!data.part_2_list_2?.length && (
        <div className={styles.tableWrap}>
          <Table
          dataSource={data.part_2_list_2}
          title={() => 'Часть 2. Выбытие документов из фонда. Лист 2'}
          className={styles.table}
          size='small'
          scroll={{ y: 500 }}
          pagination={false}
          bordered
        >
          <Column title='Номер п.п.' dataIndex='doc_serial_number' key='doc_serial_number' />
          {data.funds.map(i => (
            <Table dataSource={data.part_2_list_2} title={() => i} size='small' bordered>
              <Column
                title='на сумму'
                dataIndex='funds'
                render={(text, record) => {
                  let res = ''
                  Object.keys(text).forEach(k => {
                    if (k === i) {
                      res = splitSumToRubAndCop(text[k].summ)
                    }
                  })

                  return res
                }}
              />
              <Column
                title='всего экземпл.'
                dataIndex='funds'
                render={(text, record) => {
                  let res = ''
                  Object.keys(text).forEach(k => {
                    if (k === i) {
                      res = text[k].book_count
                    }
                  })
                  return res
                }}
              />
            </Table>
          ))}
        </Table>
        </div>
      )}

      {/*ЧАСТЬ 3*/}

      <div className={styles.tableWrap}>
      <Table
        dataSource={transform_part_3(data)}
        title={() => 'Часть 3. Итоги движения фонда. Лист 1'}
        size='small'
        className={styles.table}
        scroll={{ y: 500 }}
        pagination={false}
        bordered
      >
        <Column title='' dataIndex='title' key='title' />
        <ColumnGroup title='всего поступило / выбыло'>
          <Column title='на сумму' dataIndex='totalSum' render={text => splitSumToRubAndCop(text)} />
        </ColumnGroup>
        <ColumnGroup title='В том числе по видам документов'>
          <Column title='книги' dataIndex='totalItems' render={text => text?.books} />
          <Column title='брошюры' dataIndex='totalItems' render={text => text?.brochures} />
          <Column title='журналы' dataIndex='totalItems' render={text => text?.magazines} />
          <Column title='газеты(год.компл.)' dataIndex='totalItems' render={text => text?.newspapers} />
        </ColumnGroup>
      </Table>
      </div>

      <div className={styles.tableWrap}>
      <Table
        dataSource={data.part_2_list_2}
        title={() => 'Часть 3. Итоги движения фонда. Лист 2'}
        className={styles.table}
        size='small'
        scroll={{ y: 500 }}
        pagination={false}
        bordered
      >
        <ColumnGroup title='Из общего количества документов (экз.)'>
          {data.funds.map(i => (
            <Table dataSource={data.part_2_list_2} title={() => i} size='small' bordered>
              <Column
                title='на сумму'
                dataIndex='funds'
                render={(text, record) => {
                  let res = ''
                  Object.keys(text).forEach(k => {
                    if (k === i) {
                      res = splitSumToRubAndCop(text[k].summ)
                    }
                  })
                  return res
                }}
              />
              <Column
                title='всего экземпл.'
                dataIndex='funds'
                render={(text, record) => {
                  let res = ''
                  Object.keys(text).forEach(k => {
                    if (k === i) {
                      res = text[k].book_count
                    }
                  })
                  return res
                }}
              />
            </Table>
          ))}
        </ColumnGroup>
      </Table>
      </div>
    </Space>
  )
}

FundAccountingTable.propTypes = {
  data: T.object,
}

export default FundAccountingTable
