import { ALPHABET_COUNT_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const alphabetCountRequestStart = () => ({
  type: `${ALPHABET_COUNT_REQUEST}${START}`
});

export const alphabetCountRequestSuccess = (data) => ({
  type: `${ALPHABET_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const alphabetCountRequestError = (data) => ({
  type: `${ALPHABET_COUNT_REQUEST}${ERROR}`,
  payload: data
});
