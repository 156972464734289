import { api } from '../../App'

export const countCovers = (formData, clientId) => {
  const params = {
    count: true,
  }

  const _formData = {
    ...formData,
    verifyStatus: parseInt(formData.verifyStatus),
    idclient: clientId ? clientId : parseInt(formData.idclient),
    categoryid: parseInt(formData.categoryid),
    collectionid: parseInt(formData.collectionid),
  }

  return api.post('/search', _formData, { params })
}

export const searchCovers = (formData, { maxResults, paginateFrom }, clientId) => {
  const params = {
    maxResults,
    paginateFrom,
  }

  const _formData = {
    ...formData,
    verifyStatus: parseInt(formData.verifyStatus),
    idclient: clientId ? clientId : parseInt(formData.idclient),
    categoryid: parseInt(formData.categoryid),
    collectionid: parseInt(formData.collectionid),
  }

  return api.post('/search', _formData, { params })
}

export const countSimpleCoversSearch = (searchString = '') => {
  const params = {
    count: true,
  }

  return api.post(`/search?search=${searchString}`, null, { params })
}

export const simpleCoversSearch = (searchString = '', { maxResults, paginateFrom }) => {
  const params = {
    maxResults,
    paginateFrom,
  }

  return api.post(`/search?search=${searchString}`, null, { params })
}
