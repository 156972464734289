import React, { useRef } from 'react'
import styles from './ChooseFile.module.css'

const ChooseImage = ({ onChange, children }) => {
  const ref = useRef(null)

  return (
    <span onClick={() => ref.current.click()}>
      {children}
      <input
        accept='image/*'
        onChange={e => {
          onChange(e.target.files[0])
          e.target.value = null
        }}
        className={styles.nativeInput}
        ref={ref}
        type='file'
        id='file'
        name='file'
      />
    </span>
  )
}

ChooseImage.defaultProps = {
  onChange: () => null,
}

export default ChooseImage
