import React from 'react'
// import { reduxForm } from 'redux-form'
import remove from '../../../img/icons/close.svg'
import { types, typesOfWork } from '../../../redux/factory/currentPerfomanceReduxData'
// import FileField from '../shared/FileField'
// import getFileIcon from '../../utils/getFileIcon'

export const ResultsFilesForm = props => {
  const handleDownloadFile = file => () => {
    const { download, type, row } = props
    const { sheid, instance } = row
    download(file.file, sheid, instance, type, typesOfWork.RESULT_WORK)
  }

  const handleRemoveFile = e => {
    const fileToRemove = e.target.getAttribute('data-file')
    if (window.confirm('Вы подтверждаете удаление?')) {
      const {
        deleteFile,
        type,
        row: { sheid, instance },
      } = props
      deleteFile(fileToRemove, sheid, instance, type, typesOfWork.RESULT_WORK)
    } else {
      return false
    }
  }

  const handleUploadFile = values => {
    const { uploadFile, type, row } = props
    const { sheid, instance } = row
    const { file } = values
    file && uploadFile(file[0], sheid, instance, type, typesOfWork.RESULT_WORK)
  }

  const renderFile = (file, index) => {
    return (
      <tr key={index}>
        <td className='td-center' width='20%'>
          {/* <img alt={'icon'} src={getFileIcon(file.file)} /> */}
        </td>
        <td width='75%'>
          <a role='button' onClick={handleDownloadFile(file)}>
            {file.file}
          </a>
        </td>
        <td className='td-right' width='5%'>
          <img src={remove} alt='*' data-file={file.file} onClick={handleRemoveFile} />
        </td>
      </tr>
    )
  }

  const { handleCloseModal, handleSubmit, modalData } = props

  return (
    <div className='tasks-files'>
      <div className='caption'>Файлы результата работы</div>
      <table>
        <tbody>{modalData?.map(renderFile)}</tbody>
      </table>
      <div className='buttons_panel'>
        <div className='left__panel'>
          {/* <FileField name='file' label='Добавить файл в портфолио:' /> */}
          {/* <a role='button' onClick={handleSubmit(handleUploadFile)}> */}
            Загрузить файл
          {/* </a> */}
        </div>
        <div className='right__panel'>
          <a role='button' onClick={handleCloseModal}>
            Закрыть
          </a>
        </div>
      </div>
    </div>
  )
}

// const ResultsFilesForm = reduxForm({ form: 'tasks-files-results' })(ResultsFiles)
