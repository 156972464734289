import { api } from "../../App";

export const rubricatorCount = (search) => {
  const params = {
    count: true,
    search
  };

  return api.get('/category', { params });
};

export const rubricatorList = (orderBy, maxResults, paginateFrom, search) => {
  const params = {
    orderBy,
    maxResults,
    paginateFrom,
    search
  };

  return api.get('/category', { params });
};

export const rubricatorItem = (id) => {
  return api.get(`/category/${id}`)
};

export const rubricatorAdd = (data) => {
  const { name, parentId } = data;
  const body = {
    name, parentId
  };

  return api.post('/category', body);
};

export const rubricatorEdit = (data) => {
  const { name, id, parentId } = data;
  const body = {
    name,
    parentId: parseInt(parentId, 10)
  };

  return api.put(`/category/${id}`, body);
};

export const rubricatorDelete = (id) => {
  return api.delete(`/category/${id}`);
};