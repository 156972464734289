import React, {useEffect} from "react";
import styles from "./ScheduleOnDate.module.css";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import {scheduleRequestStart} from "../../redux/actions/schedule/list";
import {connect} from "react-redux";
import {setScheduleSearchData} from "../../redux/actions/schedule/searchData";
import classNames from "classnames";
import Input from "../../components/Input";

moment.locale('ru', {
    months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
    weekdaysMin: 'Пн_Вт_Ср_Чт_Пт_Сб_Вс'.split('_'),
});


const mapStateToProps = ({schedule: {list, search}}) => ({
    list,
    search
});

const mapDispatchToProps = dispatch => ({
    requestList: () => dispatch(scheduleRequestStart()),
    setDate: date => dispatch(setScheduleSearchData(date))
});

const ScheduleOnDate = ({list, requestList, search, setDate}) => {
    useEffect(() => {
        requestList();
    }, []);


    return (
        <div>
            <div id="test" className={styles.datePickerWrap}>
                <div className={styles.caption}>
                    {" "}
                    Индивидуальное расписание занятий на_
                </div>
                <Input type={'date'} value={search}
                       className={styles.datePicker}
                       onChange={e => {
                           setDate(e.target.value);
                           requestList();
                       }}
                />
            </div>
            <div className={styles.tableWrapper}>
                <table className={classNames(styles.tableTitle)}>
                    <tr>
                        <th>Часы занятий</th>
                        <th>Дисциплина</th>
                        <th>Аудитория</th>
                        <th>Преподаватель</th>
                        <th>Задание</th>
                    </tr>
                    <tbody>
                    {list &&
                    list.map((elem, i) => (
                        <tr key={i}>
                            <td>
                                {elem.period}
                                <br/>
                                {elem.start} - {elem.stop}
                            </td>
                            <td>{elem.course}</td>
                            <td>{elem.rooms}</td>
                            <td>{elem.teachers}</td>
                            <td>{elem.homework}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleOnDate);
