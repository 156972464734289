import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  DOCUMENTS,
  LIST,
  DOCUMENT_NUMBER_REQUEST,
  DOCUMENT_SAVE_REQUEST,
  START,
  COUNT,
  DELETE,
  DOCUMENT_GET_BOOK,
} from '../../../actions/action-types'
import {
  addDocument,
  deleteDocument,
  editDocument,
  getDocumentCount,
  getDocumentData,
  getDocumentList,
  getDocumentNumber,
} from '../../../../api/documents/documents'
import { setDocumentSearchData } from '../../../actions/documents/documents/search'
import { documentSaveRequestError, documentSaveRequestSuccess } from '../../../actions/documents/documents/save'
import {
  documentNumberRequestError,
  documentNumberRequestStart,
  documentNumberRequestSuccess,
} from '../../../actions/documents/documents/number'
import { documentsReduxActions } from '../../../actions/documents/documents/actions'
import { createNotification } from '../../../../components/notification/notification'
import { getBookError, getBookSuccess } from '../../../actions/documents/documents/getBook'
import { setLatestDocument } from '../../../actions/documents/documents/latestDocument'

export default function* documentWatcher() {
  yield takeLatest(`${DOCUMENTS}${COUNT}${START}`, countWorker)
  yield takeLatest(`${DOCUMENTS}${LIST}${START}`, listWorker)
  yield takeLatest(`${DOCUMENT_SAVE_REQUEST}${START}`, saveWorker)
  yield takeLatest(`${DOCUMENT_NUMBER_REQUEST}${START}`, numberWorker)
  yield takeLatest(`${DOCUMENTS}${DELETE}${START}`, deleteWorker)
  yield takeLatest(`${DOCUMENT_GET_BOOK}${START}`, getBook)
}

function* countWorker() {
  const searchData = yield select(({ documents }) => documents.search)
  try {
    const response = yield call(getDocumentCount, searchData)
    const count = parseInt(response.data.count, 10)
    yield put(documentsReduxActions.countRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setDocumentSearchData({ ...searchData, pageCount }))
    yield put(documentsReduxActions.listRequestStart())
  } catch (error) {
    yield put(documentsReduxActions.countRequestError(error))
  }
}

function* listWorker() {
  const searchData = yield select(({ documents }) => documents.search)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(getDocumentList, searchData)
    yield put(documentsReduxActions.listRequestSuccess(response.data))
    yield put(documentNumberRequestStart())
  } catch (error) {
    yield put(documentsReduxActions.listRequestError(error))
  }
}

function* saveWorker({ payload: data }) {
  try {
    const response = yield call(data.id ? editDocument : addDocument, data)
    yield put(documentSaveRequestSuccess(response.data))
    yield put(setLatestDocument(response.data))
    yield put(documentsReduxActions.countRequestStart())
  } catch (error) {
    yield put(documentSaveRequestError(error))
  }
}

function* numberWorker() {
  try {
    const response = yield call((getDocumentNumber))
    yield put(documentNumberRequestSuccess(response.data.nextdocumentnumber))
  } catch (error) {
    yield put(documentNumberRequestError(error))
  }
}

function* deleteWorker({ payload: id }) {
  try {
    const response = yield call(deleteDocument, id)
    yield put(documentsReduxActions.deleteRequestSuccess(response.data))
    yield call(createNotification, 'success', 'Документ успешно удален')
    yield put(documentsReduxActions.countRequestStart());
  } catch (error) {
    yield call(createNotification, 'error', 'Не удалось удалить документ')
    yield put(documentsReduxActions.deleteRequestError(error))
  }
}

function* getBook({ payload: id }) {
  try {
    const response = yield call(getDocumentData, id)
    yield put(getBookSuccess(response.data))
  } catch (e) {
    yield put(getBookError(e))
  }
}
