import { SORT_DIRECTION } from '../../constants/sortDirections'
import { api } from '../../App'

export const getStateSecretBookList = ({ sortBy, maxResults, paginateFrom, sortDirection }, search) => {
  const params = {
    maxResults,
    paginateFrom,
    ...search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
  }
  if (params.username == 'Не выбрано') params.username = '';
  if (params.secret_name == 'Не выбрано') params.secret_name = '';
  return api.get('/awarenessbook', { params })
}

export const getStateSecretBookCount = search => {
  const params = {
    count: true,
    ...search,
  }
  if (params.username == 'Не выбрано') params.username = '';
  if (params.secret_name == 'Не выбрано') params.secret_name = '';
  return api.get('/awarenessbook', { params })
}
