import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import VideoPlayer from "../../components/Tv/videoPlayer";
import {tvChannels} from "../../constants/tvChannel";
import styles from "./Tv.module.css";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const TvScene = ({}) => {

    const [currentChannel, setCurrentChannel] = useState('');

    useEffect(() => {
        const checkOnlineState = () => !navigator.onLine && alert('нет доступа к сети');

        window.addEventListener('online',  checkOnlineState);

        checkOnlineState();
    }, [navigator.onLine])

    return (
        <SceneContainer title="Цифровое телевидение">
            <div className={styles.tv_list}>
                {tvChannels.map((item, index) =>
                    <div key={'tvchannel' + index}
                         className={classNames(styles.tv_item, item.streamURL === currentChannel.streamURL && "tv_active")}
                         onClick={() => setCurrentChannel({...item, active: true})}
                         style={{backgroundImage: `url(${item.logo})`}}
                    />
                )}
            </div>
            {!!currentChannel &&
                <VideoPlayer sources={typeof currentChannel !== 'string' ? currentChannel.streamURL : ''}/>
            }
        </SceneContainer>
    )
}

export default TvScene;