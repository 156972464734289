import { Button } from 'antd'
import React, { useState } from 'react'
import arrowICon from '../../../img/icons/arrow.png'

export const OrderMenu = ({ requestList }) => {
  const [order, setOrder] = useState({ orderBy: 'subscriptionenddate' })

  const setOrderHandler = orderName => {
    requestList(order.orderBy === orderName ? { orderByD: orderName } : { orderBy: orderName })
    setOrder(order.orderBy === orderName ? { orderByD: orderName } : { orderBy: orderName })
  }

  return (
    <div style={{ display: 'flex', marginTop: '10px', alignItems: 'flex-end', flexWrap: 'wrap' }}>
      <h6 style={{ marginRight: '20px' }}>Сортировка</h6>

      <div
        style={{ width: '260px', display: 'flex', justifyContent: 'space-between', marginRight: '20px', alignItems: 'center' }}
      >
        <Button onClick={() => setOrderHandler('subscriptionenddate')}>по сроку окончания подписки</Button>
        {order.orderBy === 'subscriptionenddate' ? (
          <img src={arrowICon} style={{ transform: 'rotate(180deg)', width: '20px', height: '20px' }} />
        ) : order.orderByD === 'subscriptionenddate' ? (
          <img src={arrowICon} style={{ width: '20px', height: '20px' }} />
        ) : (
          ''
        )}
      </div>

      <div
        style={{ width: '190px', display: 'flex', justifyContent: 'space-between', margin: '20px 10px 0 0', alignItems: 'center' }}
      >
        <Button onClick={() => setOrderHandler('title')}>по названию книги</Button>
        {order.orderBy === 'title' ? (
          <img src={arrowICon} style={{ transform: 'rotate(180deg)', width: '20px', height: '20px' }} />
        ) : order.orderByD === 'title' ? (
          <img src={arrowICon} style={{ width: '20px', height: '20px' }} />
        ) : (
          ''
        )}
      </div>

      <div style={{ width: '110px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={() => setOrderHandler('year')}>по году</Button>
        {order.orderBy === 'year' ? (
          <img src={arrowICon} style={{ transform: 'rotate(180deg)', width: '20px', height: '20px', margin: '10px 0 0 0' }} />
        ) : order.orderByD === 'year' ? (
          <img src={arrowICon} style={{ width: '20px', height: '20px' }} />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
