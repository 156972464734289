import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {scheduleActions} from "../../redux/factory/scheduleReduxData";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import {bibliographyListRequestStart} from "../../redux/actions/bibliography/list";
import BibliographyTitle from "../../components/Bibliography/Title";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import BibliographyTable from "../../components/Bibliography/Table";


const mapStateToProps = ({schedulePersonalReducer, bibliography}) => ({
    yearsList: schedulePersonalReducer,
    list: bibliography.list,
    loader: bibliography.loader
});

const matchDispatchToProps = {
    requestList: bibliographyListRequestStart,
    requestYears: scheduleActions.loadRequestStart,
};

const BibliographyScene = ({requestList, requestYears, list, yearsList, loader}) => {

    useEffect(() => {
        requestYears();
    }, [requestYears]);

    const onSelectChange = (e) => {
        requestList(e.target.value);
    };

    return (
        <SceneContainer>
            <BibliographyTitle onSelectChange={onSelectChange} yearsList={yearsList}/>

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <BibliographyTable milspecs={list}/>
            }

        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(BibliographyScene);