import React from 'react'
import T from 'prop-types'
import styles from './AccessLogTable.module.css'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import moment from 'moment'

import { CHECK_DATA_LOG_MODAL_TYPES } from '../../../scenes/CheckDataLog'
import { ADMIN_LOG_CHECK_SORT } from '../../../constants/sort/adminLogCheckSort'

const CheckDataLogTable = ({ data, sortBy, setSort, openModal }) => {
  return (
    <div className={styles.container}>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
        <DataTableCol
          title={'Дата начала'}
          sortParams={[ADMIN_LOG_CHECK_SORT.STARTED]}
          resolver={({ tsstarted }) =>
            tsstarted && moment(tsstarted).format('DD.MM.YYYY') + ' ' + tsstarted.split('T')[1].slice(0, -8)
          }
          colSpan={2}
        />
        <DataTableCol
          title={'Дата окончания'}
          sortParams={[ADMIN_LOG_CHECK_SORT.ENDED]}
          resolver={({ tsended }) => tsended && moment(tsended).format('DD.MM.YYYY') + ' ' + tsended.split('T')[1].slice(0, -8)}
          colSpan={2}
        />
        <DataTableCol title={'Размер'} value={'size'} />
        <DataTableCol title={'Статус'} value={'statusname'} sortParams={[ADMIN_LOG_CHECK_SORT.STATUS]} />
        <DataTableCol title={'Контрольная сумма'} value={'checksum'} />
        <DataTableCol title={'Библиотека'} value={'name'} sortParams={[ADMIN_LOG_CHECK_SORT.NAME]} colSpan={2} />
        <DataTableCol
          title={''}
          resolver={item => (
            <div>
              <div className={'tableButton'} onClick={() => openModal(CHECK_DATA_LOG_MODAL_TYPES.books, item.id)}>
                Файлы
              </div>
              <div className={'tableButton'} onClick={() => openModal(CHECK_DATA_LOG_MODAL_TYPES.events, item.id)}>
                События
              </div>
            </div>
          )}
        />
      </DataTable>
    </div>
  )
}

CheckDataLogTable.propTypes = {
  data: T.array,
  sortBy: T.string,
  setSort: T.func,
  openModal: T.func,
}

export default CheckDataLogTable
