import {bookEditCoverReducer} from "./bookCover/bookEditCoverReducer";
import {bookEditFileReducer} from "./bookFile/bookEditFileReducer";
import {bookEditFilesReducer, bookEditSortReducer} from '../../factory/BookEditFiles/bookEditFilesReduxData';
import {bookEditGukTabReducer} from './gukTab/bookEditGukTabReducer';
import {combineReducers} from "redux";
import {bookDataReducer} from "./data/bookDataReducer";
import {bookEditFileLoaderReducer} from "./fileLoader/bookEditFileLoaderReducer";
import {uploadLoaderReducer} from "./uploadLoader/uploadLoaderReducer";

export const booksEditReducer = combineReducers({
    cover: bookEditCoverReducer,
    fileLoader: bookEditFileLoaderReducer,
    uploadLoader: uploadLoaderReducer,
    data: bookDataReducer,
    fileVersions: bookEditFileReducer,
    files: bookEditFilesReducer,
    sortData: bookEditSortReducer,
    gukTab: bookEditGukTabReducer,
});
