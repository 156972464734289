import React, { useEffect, useState } from 'react'
import { PerformancesTable } from '../../components/Performances/Table'
import { performancesActions, requestAction } from '../../redux/factory/performancesReduxData'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import SearchForm from '../../components/Performances/SearchForm'

const mapStateToProps = ({ performances, auth }) => ({
  list: performances,
  loader: performances.loader,
  authDataMid: auth.data.mid,
  modalData: performances.modal,
  term: performances.term,
})

const matchDispatchToProps = {
  loadList: performancesActions.loadRequestStart,
  dataToState: requestAction,
  startRequest: performancesActions.requestRequestStart,
  filesRequest: performancesActions.filesRequestStart,
}

const Perfomances = ({ loader, list, loadList, dataToState, startRequest, authDataMid, filesRequest, modalData, term }) => {
  const [selectedCurs, setSelectedCurs] = useState(null)
  useEffect(() => {
    loadList()
  }, [])

  return (
    <SceneContainer title={'ПРОМЕЖУТОЧНАЯ АТТЕСТАЦИЯ'}>
      {list.year && (
        <SearchForm
          loadList={loadList}
          list={list}
          dataToState={dataToState}
          startRequest={startRequest}
          authDataMid={authDataMid}
        />
      )}

      {loader && <SpinnerOuter isLoading={loader} />}

      <PerformancesTable
        term={term}
        res={list.response?.data}
        isTableVisible={list.response}
        filesRequest={filesRequest}
        modalData={modalData}
      />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, matchDispatchToProps)(Perfomances)
