import { ERROR, START, SUCCESS, SYSTEM_VERSION_REQUEST } from "../action-types";

export const systemVersionRequestStart = () => ({
  type: `${SYSTEM_VERSION_REQUEST}${START}`
});

export const systemVersionRequestSuccess = (data) => ({
  type: `${SYSTEM_VERSION_REQUEST}${SUCCESS}`,
  payload: data
});

export const systemVersionRequestError = (data) => ({
  type: `${SYSTEM_VERSION_REQUEST}${ERROR}`,
  payload: data
});
