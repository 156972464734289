import {SORT_DIRECTION} from "../../../constants/sortDirections";
import {api} from "../../../App";

export const getAdminLogsTransferCount = (formData) => {
    const params = {
        count: true,
        ...formData
    };

    return api.get('/admin/logs/transfer', { params });
};

export const getAdminLogsTransferList = (searchData, formData) => {
    const {sortDirection, sortBy, paginateFrom, pageCount, maxResults, page} = searchData;

    const params = {
        ...formData,
        paginateFrom,
        pageCount,
        maxResults,
        page,
        [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
    };

    return api.get('/admin/logs/transfer', { params });
};