import { call, put, takeLatest } from 'redux-saga/effects'
import { addGroup } from '../../../api/groups/groups'
import { ADMIN_GROUPS_ADD_GROUP, START } from '../../actions/action-types'
import { adminGroupsDeleteGroupError, adminGroupsDeleteGroupSuccess } from '../../actions/adminGroups/deleteGroup'
import { adminGroupsRequestAllGroupsStart } from '../../actions/adminGroups/groups'

export default function* adminGroupsAddGroupsWatcher() {
  yield takeLatest(`${ADMIN_GROUPS_ADD_GROUP}${START}`, addGroupWorker)
}

function* addGroupWorker({ payload }) {
  try {
    const response = yield call(addGroup, payload)
    // yield put(adminGroupsAddGroupsSuccess(response.data))
    yield put(adminGroupsRequestAllGroupsStart())
    yield
  } catch (error) {
    yield put(adminGroupsDeleteGroupError(error))
  }
}
