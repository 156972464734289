import React, { useState, useEffect } from 'react';
import styles from './BookLoanSpecialtyScene.module.css';
import LibraryCardBlock from "../../components/BookLoan/LibraryCardBlock";
import BookLoanSpecialtyControl from "../../components/BookLoanSpecialty/Control";
import BookLoanSpecialtyTable from "../../components/BookLoanSpecialty/Table";
import { SPECIALITY_BOOKS_TEST_DATA } from "../../constants/specialtyBooksTestData";
import BookLoanSpecialityList from "../../components/BookLoanSpecialty/CoversList";
import { readerDataRequestStart } from "../../redux/actions/reader/getData";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  readerData: state.readerData,
  mid: state.auth.data.mid
});

const mapDispatchToProps = dispatch => ({
  requestData: (mid) => dispatch(readerDataRequestStart(mid))
});

const BookLoanSpecialtyScene = ({ mid, readerData, requestData }) => {
  const [showCovers, setShowCovers] = useState(false);

  useEffect(() => {
    requestData(mid);
  }, [mid, requestData]);

  return (
    <>
      <LibraryCardBlock data={readerData}/>
      <div className={styles.container}>
        <BookLoanSpecialtyControl showCovers={showCovers} setShowCovers={setShowCovers}/>
        {showCovers ? <BookLoanSpecialityList data={SPECIALITY_BOOKS_TEST_DATA}/> :
          <BookLoanSpecialtyTable data={SPECIALITY_BOOKS_TEST_DATA}/>}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps) (BookLoanSpecialtyScene);
