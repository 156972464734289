import {
    USER_ROLE_PERMISSIONS_REQUEST, START, SUCCESS, ERROR
} from "../action-types";

export const UserRolePermissionsRequestStart = () => ({
    type: `${USER_ROLE_PERMISSIONS_REQUEST}${START}`
});

export const UserRolePermissionsRequestSuccess = (data) => ({
    type: `${USER_ROLE_PERMISSIONS_REQUEST}${SUCCESS}`,
    payload: data
});

export const UserRolePermissionsRequestError = (data) => ({
    type: `${USER_ROLE_PERMISSIONS_REQUEST}${ERROR}`,
    payload: data
});


