import React, { useLayoutEffect, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Pagination from '../../components/Pagination'
import LibraryMaterialsSearchForm from '../../components/LibraryMaterials/SearchForm'
import Button from '../../components/Button'
import { libraryMaterialsCountRequestStart } from '../../redux/actions/LibraryMaterials/count'
import { setLibraryMaterialsFormData } from '../../redux/actions/LibraryMaterials/form'
import { setLibraryMaterialsSearchData } from '../../redux/actions/LibraryMaterials/search'
import LibraryMaterialsList from '../../components/LibraryMaterials/List'
import { libMaterialsInitialState } from '../../redux/reducers/libraryMaterials/form/libraryMaterialsFormReducer'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { libraryTypesListRequestStart } from '../../redux/actions/library/types/list'
import ReadingList from '../../components/Reading/List'
import { reserveBookRequestStart } from '../../redux/actions/books/reserveBook'
import { unreserveBookRequestStart } from '../../redux/actions/books/unreserveBook'
import { bookScanOrderAddRequestStart } from '../../redux/actions/bookScanOrders/add'
import { Row} from 'antd'
import { Breadcrumb } from 'antd';
import IndexRowItem from '../Cards/Item'

const mapStateToProps = ({ libraryMaterials, library, historyLocation }) => ({
  list: libraryMaterials.list,
  count: libraryMaterials.count,
  formData: libraryMaterials.form,
  searchData: libraryMaterials.search,
  loader: libraryMaterials.loader,
  libraryTypes: library.types.list,
  historyLocation: historyLocation,
})

const mapDispatchToProps = {
  requestList: libraryMaterialsCountRequestStart,
  setFormData: setLibraryMaterialsFormData,
  setSearchData: setLibraryMaterialsSearchData,
  requestLibraryTypes: libraryTypesListRequestStart,
  reserveBook: reserveBookRequestStart,
  unreserveBook: unreserveBookRequestStart,
  addBookScanOrder: bookScanOrderAddRequestStart,
}

const LibraryMaterials = ({
  addBookScanOrder,
  reserveBook,
  unreserveBook,
  count,
  loader,
  list,
  requestList,
  formData,
  setFormData,
  searchData,
  setSearchData,
  match,
  history,
  libraryTypes,
  requestLibraryTypes,
  historyLocation,
}) => {
  const [isTableViewCompact, setTableViewCompact] = useState(false)
  const [loadSuccess, setLoadSuccess] = useState(false)
  const [startList, setStartList] = useState([])
  // Шифр
  const [breadcrumbList, setbreadCrumbList] = useState([{name: 'Документы РГО', id: 17}])
  const [id, setId] = useState(null)
  const [listPages, setListPages] = useState(list)
  const [pageCount, setPageCount] = useState([])
  

  useEffect(() => {
    match?.params?.id && setId(match?.params?.id);
  }, [match?.params?.id])

  useEffect(() => {
    setListPages(list)
  }, [list])
  
  
  useEffect(() => {
    if (libraryTypes) {
      libraryTypes.sort((user1, user2) => user1.id > user2.id ? 1 : -1);
      setStartList(libraryTypes.filter(el => el.parent == id));
    }
  
  }, [libraryTypes, id])  

  const title = libraryTypes.find(item => item.id == id)?.name || ''

  useLayoutEffect(() => {
    !libraryTypes.length && requestLibraryTypes()
  }, [requestLibraryTypes])

  useLayoutEffect(() => {
    if (~history.location.search.indexOf('newitems=true')) {
      setFormData({ ...formData, latest: true })
      setSearchData({ ...searchData, latest: true })
      requestList(id)
    }
  }, [historyLocation])

  const onSubmit = (val=id) => {
    setPageCount(searchData?.pageCount)
    requestList(val)
    setLoadSuccess(true)
    setSearchData({ ...searchData, latest: true })
  }

  const clearForm = () => {
    setFormData(libMaterialsInitialState)
  }

  const PaginationComponent = () => (
    <Pagination
      count={count}
      pageCount={searchData?.pageCount}
      current={searchData.page}
      setCurrent={page => {
        setSearchData({ ...searchData, page })
        requestList(match.params.id)
      }}
    />
  )

    const handleBread = (id) => {
      // setListPages([])
      setSearchData({
        page: 1,
        maxResults: 15,
        pageCount: 0
      })
      setListPages([])
      setLoadSuccess(false)
      setId(id);
      let index = breadcrumbList.map((el, i) => el.id == id && i).filter(el => el !== false)[0];
      let newBread = breadcrumbList.slice(0, index + 1);
      setbreadCrumbList(prev => newBread);
      let newList = libraryTypes.filter(el => el.parent == Number(id));
      if (newList.length > 0) {
        setStartList(newList);
      }
    }

  return (
    <>
    {/* Шифр */match?.params?.id == 17 &&
      <>
        <div className="container" style={{marginTop: '1rem'}}>
          {!!startList.length && (
            <>
              <Row>
                {startList.map((item, index) => (
                  <IndexRowItem
                    libraryTypes={libraryTypes}
                    key={index}
                    destination={'#'}
                    title={item.name}
                    subtitle={'документов'}
                    background={item.mimetype}
                    id={item.id}
                    isExternal={false}
                    amount={Number(item.amount)}
                    newAmount={Number(item.newamount)}
                    setStartList={setStartList}
                    setbreadCrumbList={setbreadCrumbList}
                    setFormData={setFormData}
                    formData={formData}
                    onSubmit={onSubmit}
                    setId={setId}
                    setListPages={setListPages}
                    setLoadSuccess={setLoadSuccess}
                    setSearchData={setSearchData}
                  />
                
                ))}
              </Row>
            </>
          )}
        </div>
      </>
    }

      <SceneContainer title={/* Шифр */ match?.params?.id != 17 ? title : ''}>
      {/* Шифр */ match?.params?.id == 17 && 
      <Breadcrumb style={{fontWeight: '600',color: '#0d0d01',fontSize: '20px', marginBottom: '1rem!important'}}>
        {breadcrumbList.map(el => <Breadcrumb.Item style={{cursor:'pointer'}} key={el.id} onClick={() => handleBread(el.id)}>{el.name}</Breadcrumb.Item>)}
      </Breadcrumb>}


        <LibraryMaterialsSearchForm
          /* Шифр */
          scienceWorksMode={id==13}
          data={formData}
          setData={setFormData}
          onSubmit={() => onSubmit(id)}
        >
          <div className={'d-flex justify-content-end'}>
            <Button label={'Очистить'} onClick={clearForm} />
            <Button label={'Найти'} colorType={'green'} style={'ml-2'} onClick={() => onSubmit(id)} />
          </div>
        </LibraryMaterialsSearchForm>
        <PaginationComponent />
        {loader ? (
          <SpinnerOuter />
        ) : loadSuccess && !listPages.length ? (
          <div className='emptyNotify'>По Вашему запросу ничего не найдено</div>
        ) : /* Шифр */ id == 1 ? (
          <ReadingList
            addBookScanOrder={addBookScanOrder}
            unreserveBook={unreserveBook}
            reserveBook={reserveBook}
            setTableViewCompact={setTableViewCompact}
            isTableViewCompact={isTableViewCompact}
            data={listPages}
          />
        ) : (
          <LibraryMaterialsList data={listPages} />
        )}
        <PaginationComponent />
      </SceneContainer>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryMaterials)
