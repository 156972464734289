import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import ReadingListItemFiles from "../../../../components/Reading/List/FileList";
import classNames from "classnames";

import styles from './s.module.css';
import PopoverOuter from "../../../../components/UtulityComponents/Popover";

const ListItem = ({item}) => {

    return (
        <li className={classNames(styles.item, 'mb-2')}>
            <div className={'d-flex'}>
                <PopoverOuter
                    trigger="click"
                    placement="bottom"
                    title="Файлы книги"
                    content={
                        <ReadingListItemFiles
                            fileList={item.files}
                            bookId={item.bookid}
                        />
                    }>
                    <span className={styles.time}>{moment(item.eventtime).format('hh:mm')}</span>
                    <span>
                        <span className={'pr-1'}>
                            {item.authors?.map(author =>
                                author.name
                            ).join(',')}
                        </span>
                        {item.title}
                    </span>
                </PopoverOuter>

            </div>
        </li>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};

export default ListItem;
