import { ERROR, PERMISSIONS_BY_LITER_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const permissionsByLiterRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_LITER_LIST_REQUEST}${START}`,
  payload: data
});

export const permissionsByLiterRequestSuccess = (data) => ({
  type: `${PERMISSIONS_BY_LITER_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const permissionsByLiterRequestError = (data) => ({
  type: `${PERMISSIONS_BY_LITER_LIST_REQUEST}${ERROR}`,
  payload: data
});