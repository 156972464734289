import React, {useLayoutEffect, useMemo, useEffect} from "react";
import styles from "./BookDescriptionScene.module.css";
import {bookDataRequestStart} from "../../redux/actions/bookData/data";
import {connect} from "react-redux";
import BookDescriptionContent from "../../components/BookDescription/Content";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import {Alert} from "antd";


const mapStateToProps = ({bookData, auth, options}) => ({
    data: bookData,
    auth: auth,
    optionsList: options.data,
});

const mapDispatchToProps = {
    requestData: bookDataRequestStart
};


const BookDescriptionScene = ({data, match, requestData, auth, optionsList}) => {

    const bookId = match.params.id;
    const isLibrarian = auth?.data?.islibrarian;

    const isAccessible = useMemo(
        () => {
            if(!data || !optionsList) {
                return false;
            }
            const bookOutsecrecy = Number(data.eerOutSecrecyId);

            return getOutsecrecyGradationLevel(optionsList.outsecrecy) >= getOutsecrecyGradationLevel(bookOutsecrecy)

        },
        [data, optionsList]
    );

    useLayoutEffect(() => {
        requestData(bookId);
    }, []);


    function getOutsecrecyGradationLevel(outsecrecy) {
        // [1 = Не выбрано, 2 = дсп, 3 = секретно, 4 = несекретно] outsecrecy
        switch (outsecrecy) {
            case 1:
                return 1
            case 4:
                return 2
            case 2:
                return 3
            case 3:
                return 4
            default:
                return 0;
        }
    }

    useEffect(() => {
        if(!!data) {
            return <SpinnerOuter/>
        }
    }, [data])


    return (
        <div className={styles.container}>
            {(data && optionsList && !isAccessible) && <Alert message="Доступ запрещен" type="error" showIcon />}
            {(data && isAccessible) && <BookDescriptionContent data={data} isLibrarian={isLibrarian} bookId={bookId}/>}
            {!data && <SpinnerOuter/>}
        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookDescriptionScene);
