import { BOOK_DATA_REQUEST, SUCCESS, SET_BOOK_DATA, SET_LOCATION_CHANGE } from '../../../actions/action-types'

const initialState = null

export const bookDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_DATA_REQUEST}${SUCCESS}`:
      return {
        ...action.payload,
        ...state,
        bookid: action.payload.bookid,
        subscriptionBackCover: action.payload.subscriptionBackCover,
        subscriptionCover: action.payload.subscriptionCover,
        archived: action.payload.archived
      }

    case `${SET_BOOK_DATA}`:
      return action.payload

    case SET_LOCATION_CHANGE:
      return null

    default:
      return state
  }
}
