import { all } from 'redux-saga/effects';
import alphabetItemsSaga from "./items/alphabetItemsSaga";
import alphabetRangeSaga from "./range/alphabetRangeSaga";
import alphabetLettersSaga from "./letters/alphabetLettersSaga";

export default function* alphabetSaga() {
  yield all([
    alphabetRangeSaga(),
    alphabetItemsSaga(),
    alphabetLettersSaga()
  ]);
}