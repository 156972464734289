import React from 'react';
import T from 'prop-types';
import styles from './LocationRack.module.css';
import LocationShelf from "../Shelf";
import Button from "../../Button";
import { ADD, EDIT } from "../../../constants/modalTypes";
import { LOCATION_MODAL } from "../../../constants/locationModal";
import { rackDeleteRequestStart } from "../../../redux/actions/location/racks/delete";
import { connect } from "react-redux";

const mapDispatchToProps = dispatch => ({
  deleteRack: (roomId, rackId) => dispatch(rackDeleteRequestStart(roomId, rackId))
});

const LocationRack = ({ data, shelves, requestShelves, setModalData, deleteRack, setSelectedIds, selectedIds }) => {

  const openRack = (id) => {
    !shelves[id] && requestShelves(id);
  };

  const onRackEdit = () => {
    setModalData({
      modalType: EDIT,
      locationType: LOCATION_MODAL.RACK,
      parentId: data.roomid,
      data: data
    })
  };

  const onNewShelf = () => {
    setModalData({
      modalType: ADD,
      locationType: LOCATION_MODAL.SHELF,
      parentId: data.id,
      data: null
    })
  };

  return (
    <div className={styles.container}>
      <details onClick={() => openRack(data.id)}>
        <summary className={styles.summary}>
          <div className={styles.title}>{data.name}</div>
          <div>
            <Button label="+" style={styles.button} onClick={onNewShelf}/>
            <Button label="-" style={styles.button} onClick={() => deleteRack(data.roomid, data.id)}/>
            <Button label="Редактировать" style={styles.button} onClick={onRackEdit}/>
          </div>
        </summary>
        <div className={styles.shelvesContainer}>
          {shelves[data.id] &&
          shelves[data.id].map((item, index) =>
            <LocationShelf key={index} data={item}
                           setModalData={setModalData}
                           setSelectedIds={setSelectedIds}
                           selectedIds={selectedIds}
            />)}
        </div>
      </details>
    </div>
  );
};

LocationRack.propTypes = {
  data: T.object,
  shelves: T.object,
  requestShelves: T.func,
  setModalData: T.func
};

export default connect(null, mapDispatchToProps) (LocationRack);
