import React, { useContext, useEffect } from 'react'
import InputWrapper from '../../Modal/InputWrapper'
import FileLabel from '../../FileLabel/FileLabel'
import { BASE_URL_WITHOUT_API } from '../../../constants/api'
import FileLoader from '../../UtulityComponents/FileLoader'
import styles from './styles.module.css'
import { MobileLibContext } from '../../../utils/context'
import { updateMobileLibraryRequestStart } from '../../../redux/actions/mobileLibrary/library/update'
import { createMobileLibraryRequestStart } from '../../../redux/actions/mobileLibrary/library/create'

import { Col, Row } from 'react-bootstrap'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import * as Yup from 'yup'
import { createNotification } from '../../notification/notification'
import moment from 'moment'
import { institutionsAllListRequestStart } from '../../../redux/actions/institutions/all'
import { regionsListRequestStart } from '../../../redux/actions/regions/list'


const validationSchema = Yup.object().shape({
  name: Yup.string().required('Обязательное поле'),
  created_at: Yup.string().required('Обязательное поле'),
  closed_at: Yup.string().nullable(),
  contract_number: Yup.number().required('Обязательное поле').max(99999999, 'Максимальное значение 999999'),
  number: Yup.number().required('Обязательное поле').max(99999999, 'Максимальное значение 999999')
})

export default function Form() {
  
  const dispatch = useDispatch()
  const { libId, data, editMode, timeOut: formDisabled } = useContext(MobileLibContext)
  const { all_full: orgList } = useSelector(state=> state.institutions)
  const { list: regionList } = useSelector(state=> state.regions)

  useEffect(() => {
    !orgList?.length && dispatch(institutionsAllListRequestStart())
    !regionList?.length && dispatch(regionsListRequestStart())
  }, [])
  

  const submitForm = ({closed_at, ...values}) => {
    if (!values.mobile_library_files?.length) return createNotification('error', 'Файл является обязательным для заполнения');
    const data_ = closed_at ? {...values, closed_at} : {...values, closed_at: null};  
    const action = editMode
      ? updateMobileLibraryRequestStart
      : createMobileLibraryRequestStart 
    dispatch(action(data_))
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: submitForm
  })

  const renderFile = (file) => {
    const {name, path, id, mimetype, type} = file
    const fileLink = id ? `${BASE_URL_WITHOUT_API}${path}${name}` : URL.createObjectURL(file)
    const imageMode = mimetype?.includes('image') || type?.includes('image')

    return (
      <FileLabel
        fileName={name}
        fileLink={fileLink}
        imageMode={imageMode}
      />
    )
  }

  const onFileInput = (e) => {
    const { name, files } = e.target
    if (!files.length) return
    setFieldValue(name, Object.values([files[0]]))
  }

  const error = (name) => touched[name] && errors[name]

  const renderOptions = (item) => {
    const label = item.name || item.subject
    const value = item.idclient || item.idsubject
    return <option value={value} key={label}>{label}</option>
  }

  const placeValue = orgList?.find(({idclient}) => idclient == values.org_id)?.subject || ''

  return (
    <>
      <Row>
        <Col md={12} lg={12}>
          <InputWrapper
            label='Название библиотеки*'
            value={values.name}
            name='name'
            error={error('name')}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={formDisabled}
            />
        </Col>
        <Col md={6} lg={6}>
          <InputWrapper
            label='Дата создания*'
            type='date'
            value={values.created_at}
            name='created_at'
            error={error('created_at')}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={formDisabled}
            />
        </Col>
        <Col md={6} lg={6}>
          <InputWrapper
            label='Дата закрытия'
            value={values.closed_at}
            type='date'
            name='closed_at'
            error={error('closed_at')}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={formDisabled}
            />
        </Col>
        <Col md={6} lg={6}>
          <InputWrapper
            label='Номер документа*'
            type='number'
            value={values.contract_number}
            name='contract_number'
            onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
            error={error('contract_number')}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={formDisabled}
            />
        </Col>
        <Col md={6} lg={6}>
          <InputWrapper
            label='Номер библиотеки*'
            type='number'
            value={values.number}
            name='number'
            onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
            error={error('number')}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={formDisabled}
            />
        </Col>
        <Col className={styles.fileBlock}>
          <p>Загрузить договор*</p>
          {values.mobile_library_files?.map(renderFile)}
          <FileLoader
            onUpload={onFileInput}
            label={!!values.mobile_library_files?.length ? 'Заменить файл' : 'Загрузить файл'}
            name='mobile_library_files'
            accept='application/pdf, image/*'
            disabled={formDisabled}
          />
        </Col>
        <Col className='mt-3' md={12} lg={12}>
          <h5>Войсковая часть (подразделение)</h5>
        </Col>
        <Col md={6} lg={6}>
          <InputWrapper error={error('org_id')} label='Наименование'>
            <select
              value={values.org_id}
              name='org_id'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={formDisabled}
            >
              <option>Не выбрано</option>
              {orgList.map(renderOptions)}
            </select>
          </InputWrapper>
        </Col>
        <Col md={6} lg={6}>
          <InputWrapper
            label='Адрес'
            value={placeValue}
            disabled
          />
        </Col>
      </Row>
      <Row className={styles.buttonRow}>
        {/* <Col> */}
          <Button disabled={formDisabled} type='primary' onClick={handleSubmit}>
            {libId ? 'Сохранить' : 'Создать'}
          </Button>
        {/* </Col> */}
      </Row>
    </>
  )
}
