import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currentMobileLibraryRequestStart, resetLibraryData } from '../../redux/actions/mobileLibrary/library/current'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Form, Responsible, Documents, PDFPrint } from '../../components/MobileLibrary/page'
import { MobileLibContext } from '../../utils/context'
import moment from 'moment'



export default function MobileLybraryPage () {
  
  const dispatch = useDispatch()
  const { current: data, loader } = useSelector(state => state.mobileLibrary)
  const { libId } = useParams()
  

  const editMode = !!parseInt(libId)
  const editFromAddMode = !!data.id

  const pageTitle = editMode ? `Редактирование '${data?.name || ''}'` : 'Добавление передвижной библиотеки'

  useEffect(() => {
    editMode && dispatch(currentMobileLibraryRequestStart(libId))
    return () => {
      dispatch(resetLibraryData())
    }
  }, [])

  const contextValue = {
    data,
    editMode,
    disabled: !editMode && !editFromAddMode,
    libId: parseInt(libId) || data.id,
    timeOut: !!data.closed_at && moment() > moment(data.closed_at)
  }

  return (
    <MobileLibContext.Provider value={contextValue}>
      
      <SceneContainer title={pageTitle}>
          {loader
            ? <SpinnerOuter/>
            : <>
                <PDFPrint/>
                <Form/>
                <Responsible/>
                <Documents/>
              </>
          }
      </SceneContainer>

    </MobileLibContext.Provider>
  )
}
