import {INVENTORY_LIST_REQUEST, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const inventoryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${INVENTORY_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};
