import { GET_MENU_LIST, START, SUCCESS, ERROR, SET_MENU_LIST } from "../action-types";

export const getMenuListStart = () => ({
  type: `${GET_MENU_LIST}${START}`
});

export const getMenuListSuccess = (data) => ({
  type: `${GET_MENU_LIST}${SUCCESS}`,
  payload: data
});

export const getMenuListError = (error) => ({
  type: `${GET_MENU_LIST}${ERROR}`,
  payload: error
});

export const setMenuList = (data) => ({
  type: SET_MENU_LIST,
  payload: data
});


