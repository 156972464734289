import { call, put, takeLatest, takeEvery, delay } from 'redux-saga/effects'
import {
  BOOK_CLEAN_COVER,
  BOOK_CLEAN_SCAN,
  BOOK_COMPRESS_REQUEST,
  BOOK_DECOMPRESS_REQUEST,
  BOOK_SAVE_REQUEST,
  BOOK_SCAN_SAVE,
  BOOK_PDF_SAVE,
  BOOK_PDF_DELETE,
  START,
  BOOK_COVER_SAVE,
} from '../../actions/action-types'
import { bookSaveRequestError, bookSaveRequestSuccess } from '../../actions/books/save'
import {
  cleanCover,
  cleanScan,
  compressBook,
  decompressBook,
  editBook,
  saveCover,
  saveScan,
  savePdf,
  deletePdf,
} from '../../../api/book/edit'
import { bookCompressRequestError, bookCompressRequestSuccess } from '../../actions/books/compressBook'
import { bookDecompressRequestSuccess, bookDecompressRequestError } from '../../actions/books/decompressBook'
import { saveBookCoverSuccess, saveBookCoverError } from '../../actions/bookData/data'
import { bookDataRequestStart } from '../../actions/bookData/data'
import { createNotification } from '../../../components/notification/notification'
import { availableBooksActions } from '../../factory/availableBooksModalReduxData'
import { bookScanPlanActions } from '../../factory/bookScanPlanReduxData'

export default function* saveBookWatcher() {
  yield takeLatest(`${BOOK_SAVE_REQUEST}${START}`, saveBookWorker)
  yield takeLatest(`${BOOK_COMPRESS_REQUEST}${START}`, compressBookWorker)
  yield takeLatest(`${BOOK_SCAN_SAVE}`, saveBookScanSaga)
  yield takeLatest(`${BOOK_CLEAN_SCAN}`, cleanBookScanSaga)
  yield takeEvery(`${BOOK_CLEAN_COVER}`, cleanBookCoverSaga)
  yield takeLatest(`${BOOK_COVER_SAVE}${START}`, saveBookCoverSaga)
  yield takeLatest(`${BOOK_PDF_SAVE}`, savePdfSaga)
  yield takeLatest(`${BOOK_PDF_DELETE}`, deletePdfSaga)
  yield takeLatest(`${BOOK_DECOMPRESS_REQUEST}${START}`, decompressBookWorker)
}

function* saveBookCoverSaga({ payload: { file, bookId } }) {
  try {
    yield call(saveCover, { bookId, file })
    yield put(saveBookCoverSuccess({ file, bookId }))
    yield call(createNotification, 'success', 'Обложка успешно загружена')
    yield put(availableBooksActions.listRequestStart())
  } catch (e) {
    console.log(e)
    yield put(saveBookCoverError(e))
  }
}

function* cleanBookCoverSaga({ payload: { bookId } }) {
  try {
    yield call(cleanCover, { bookId })
    yield delay(1000)
    yield put(bookDataRequestStart(bookId))
  } catch (e) {
    console.log(e)
  }
}

function* saveBookScanSaga({ payload: { file, bookId, side } }) {
  try {
    yield call(saveScan, { bookId, side, file })
    yield put(bookDataRequestStart(bookId))
  } catch (e) {
    console.log(e)
  }
}

function* savePdfSaga({ payload: { bookId, file, body } }) {
  try {
    yield call(savePdf, { bookId, file, body })
    yield put(bookDataRequestStart(bookId))
    yield put(bookScanPlanActions.countRequestStart())
  } catch (e) {
    console.log(e)
  }
}

function* deletePdfSaga({ payload: { bookId, fileId } }) {
  try {
    yield call(deletePdf, { bookId, fileId })
    yield put(bookDataRequestStart(bookId))
  } catch (error) {
    console.log(error)
  }
}

function* cleanBookScanSaga({ payload: { bookId, side } }) {
  try {
    yield call(cleanScan, { bookId, side })
    yield put(bookDataRequestStart(bookId))
  } catch (e) {
    console.log(e)
  }
}

function* saveBookWorker({ payload: data, withoutPopup }) {
  try {
    const response = yield call(editBook, data)
    if (!withoutPopup) {
      createNotification('success', 'Документ успешно сохранен')
    }
    yield put(bookDataRequestStart(response.data.book.id))
  } catch (error) {
    yield put(bookSaveRequestError(error))
  }
}

function* compressBookWorker({ payload: bookId }) {
  try {
    const response = yield call(compressBook, bookId)
    yield put(bookCompressRequestSuccess(response.data))
    yield delay(400)
    yield put(bookDataRequestStart(bookId))
  } catch (error) {
    yield put(bookCompressRequestError(error))
  }
}

function* decompressBookWorker({ payload: bookId }) {
  try {
    const response = yield call(decompressBook, bookId)
    yield put(bookDecompressRequestSuccess(response.data))
    yield delay(400)
    yield put(bookDataRequestStart(bookId))
  } catch (error) {
    yield put(bookDecompressRequestError(error))
  }
}
