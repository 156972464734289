import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import LitersForm from "../../../components/Liters/Form";
import Button from "../../../components/Button";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import Pagination from "../../../components/Pagination";
import LitersModal from "../../../components/Liters/Modal";
import LitersList from "../../../components/Liters/List";
import {seriesCatalogCountRequestStart} from "../../../redux/actions/series/count";
import {setSeriesCatalogSearchData} from "../../../redux/actions/series/search";
import {setSeriesFormData} from "../../../redux/actions/series/form";
import {seriesCatalogAddRequestStart} from "../../../redux/actions/series/add";
import {seriesCatalogEditRequestStart} from "../../../redux/actions/series/edit";
import {seriesCatalogDeleteRequestStart} from "../../../redux/actions/series/delete";
import {ADD, EDIT} from "../../../constants/modalTypes";

const mapStateToProps = ({ series }) => ({
  formData: series.form,
  list: series.list,
  loader: series.loader,
  count: series.count,
  searchData: series.search
});

const mapDispatchToProps = {
  requestList: seriesCatalogCountRequestStart,
  setSearchData: setSeriesCatalogSearchData,
  setFormData: setSeriesFormData,
  addItem: seriesCatalogAddRequestStart,
  editItem: seriesCatalogEditRequestStart,
  deleteItem: seriesCatalogDeleteRequestStart,
};

const SeriesScene = ({
  requestList,
  setSearchData,
  setFormData,
  addItem,
  editItem,
  deleteItem,
  formData,
  list,
  loader,
  count,
  searchData,
}) => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    requestList();
  }, [requestList]);

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList();
  };

  const onSelectItem = (itemId) => {
    selectedItem === itemId
      ? setSelectedItem(null)
      : setSelectedItem(itemId);
  };

  const onAddItem = () => {
    setModalData({
      type: ADD,
      name: ''
    });
  };

  const isSelectedItemExist = () => {
    const findedItem = list.find(el => el.id === selectedItem);
    return !!findedItem;
  };

  const onDeleteItem = () => {
    if(selectedItem) {
      return deleteItem(selectedItem);
    }
    return window.alert('Не выделен элемент')
  };

  const onEditItem = () => {
    let itemInfo = list.find(el => el.id === selectedItem);

    setModalData({
      type: EDIT,
      name: itemInfo.name,
      item: selectedItem
    });
  };

  const closeModal = () => {
    setModalData(null);
  };

  const onSubmitModal = () => {
    switch (modalData.type) {
      case ADD:
        addItem(modalData);
        break;
      case EDIT:
        editItem(modalData);
        break;
      default:
        break;
    }

    closeModal();
  };

  return (
    <SceneContainer title={'Справочники / Серии'}>
      <LitersForm formData={formData} setFormData={setFormData} onSubmit={requestList}/>

      <div>
        <Button
          label={'Добавить'}
          colorType={'green'}
          onClick={onAddItem}
        />
        {isSelectedItemExist() && <>
          <Button
            label={'Редактировать'}
            onClick={onEditItem}
            style={'ml-2'}
          />
          <Button
            label={'Удалить'}
            colorType={'red'}
            onClick={onDeleteItem}
            style={'ml-2'}
          />
        </>}
      </div>

      {loader
        ? <SpinnerOuter isLoading={loader}/>
        : <>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>

          {modalData &&
          <LitersModal
            isOpen={!!modalData}
            closeModal={closeModal}
            data={modalData}
            setData={setModalData}
            onSubmit={onSubmitModal}
          />
          }

          <LitersList
            list={list}
            onSelectItem={onSelectItem}
            selectedItem={selectedItem}
          />

          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </>
      }

    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeriesScene);