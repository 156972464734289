import { call, put, takeLatest } from "redux-saga/effects";
import { INSTITUTIONS_SAVE_REQUEST, START } from "../../../actions/action-types";
import { ADD } from "../../../../constants/modalTypes";
import { institutionAdd, institutionEdit } from "../../../../api/institutions/institutions";
import { institutionsSaveRequestError, institutionsSaveRequestSuccess } from "../../../actions/institutions/save";
import { institutionsCountRequestStart } from "../../../actions/institutions/count";
import {institutionsAllListRequestStart} from "../../../actions/institutions/all";

export default function* institutionsSaveWatcher() {
  yield takeLatest(`${INSTITUTIONS_SAVE_REQUEST}${START}`, institutionsSaveWorker)
}

function* institutionsSaveWorker({ payload: { data, modalType: type }, callback }) {
  try {
    let response = yield call(type === ADD ? institutionAdd : institutionEdit, data);

    yield put(institutionsSaveRequestSuccess(response.data));

    if(callback)
      yield call(callback);

    yield put(institutionsCountRequestStart());
    yield put(institutionsAllListRequestStart());
  } catch (error) {
    yield put(institutionsSaveRequestError(error))
  }
}