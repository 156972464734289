import React from 'react';
import T from 'prop-types';
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import {Col, Row} from "react-bootstrap";
import styles from './LitersForm.module.css';

const LitersForm = ({ formData, setFormData, onSubmit }) => {
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col
          md
        >
          <ModalInputWrapper
            label={'Поиск'}
            name={'name'}
            onChange={onChange}
          />
        </Col>
        <Col
          md={'auto'}
        >
          <ModalInputWrapper>
            <Button
              label={'Найти'}
              onClick={onSubmit}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

LitersForm.propTypes = {
  formData: T.object.isRequired,
  setFormData: T.func.isRequired,
  onSubmit: T.func.isRequired
};

export default LitersForm;