import { api } from "../../App";
import { SORT_DIRECTION } from "../../constants/sortDirections";

export const getDocumentTypesList = ({ sortBy, maxResults, paginateFrom, sortDirection, query }) => {
  const params = {
    maxResults,
    paginateFrom,
    search: query,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
  };

  return api.get('/documenttype', { params });
};

export const getDocumentTypesCount = (search) => {
  const params = {
    count: true,
    search
  };

  return api.get('/documenttype', { params });
};

export const addDocumentType = (data) => {
  return api.post('/documenttype', data);
};

export const editDocumentType = (data) => {
  return api.put(`/documenttype/${data.id}`, data);
};

export const deleteDocumentType = (id) => {
  return api.delete(`/documenttype/${id}`);
};
