import {
  SET_AUTHOR_IMAGE,
  CLEAR_AUTHOR_IMAGE
} from "../../../actions/action-types";

const initialState = null;

export const setAuthorImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHOR_IMAGE:
      return action.payload;

    default:
      return state;
  }
};
