import {RESULTS_PER_PAGE} from "../../../../constants/pagination";
import {SET_DISCIPLINE_SEARCH_DATA} from "../../../actions/action-types";


const initialState = {
    query: "",
    page: 1,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0,
    selectedInstitution: "",
    selectedLibrary: ""
};

export const disciplineSearchDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISCIPLINE_SEARCH_DATA:
            return { ...action.payload };

        default:
            return state;
    }
}