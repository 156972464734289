import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import {
  mediaResourceFullRequestStart,
} from "../../../redux/actions/mediaResource/getFull";
import MediaResourceEditorList from "../../../components/MediaResource/Editor/List";
import {mediaResourceEditRequestStart} from "../../../redux/actions/mediaResource/edit";
import {mediaResourceDeleteRequestStart} from "../../../redux/actions/mediaResource/delete";
import {mediaResourceDeleteLogoRequestStart} from "../../../redux/actions/mediaResource/deleteLogo";
import {mediaResourceAddRequestStart} from "../../../redux/actions/mediaResource/add";
import AddItemForm from "../../../components/MediaResource/Editor/Form";
import {mediaResourceAddLogoRequestStart} from "../../../redux/actions/mediaResource/addLogo";

const mapStateToProps = ({mediaResource}) => ({
  list: mediaResource.full.list
});

const mapDispatchToProps = {
  getList: mediaResourceFullRequestStart,
  editItem: mediaResourceEditRequestStart,
  deleteItem: mediaResourceDeleteRequestStart,
  deleteLogo: mediaResourceDeleteLogoRequestStart,
  addItem: mediaResourceAddRequestStart,
  addLogo: mediaResourceAddLogoRequestStart
};

const emptyMediaResource = {
  resourcename: "",
  resourceurl: ""
};

const MediaResourceEdit = ({ getList, list, editItem, deleteItem, deleteLogo, addItem, addLogo }) => {
  const [addItemFormData, setAddItemFormData] = useState(emptyMediaResource);

  useEffect(() => {
    getList();
  }, [getList]);

  const onSubmit = () => {
    addItem(addItemFormData);
    setAddItemFormData(emptyMediaResource);
  };

  const onUploadLogo = (resourceId, logo) => {
    const params = {
      resourceId,
      logo
    };

    addLogo(params);
  };

  return (
    <SceneContainer title="Настройка медиа портала">

      <AddItemForm formData={addItemFormData} onSubmit={onSubmit} setFormData={setAddItemFormData}/>

      <MediaResourceEditorList
        list={list}
        deleteItem={deleteItem}
        editItem={editItem}
        deleteLogo={deleteLogo}
        onUploadLogo={onUploadLogo}
      />
    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaResourceEdit);