import { ERROR, BOOK_EXPORTS_LOAD, START, SUCCESS, RESET } from "../action-types";

export const bookExportsLoadRequestStart = () => ({
  type: `${BOOK_EXPORTS_LOAD}${START}`
});

export const bookExportsLoadRequestSuccess = (data) => ({
  type: `${BOOK_EXPORTS_LOAD}${SUCCESS}`,
  payload: data
});

export const bookExportsLoadRequestError = (data) => ({
  type: `${BOOK_EXPORTS_LOAD}${ERROR}`,
  payload: data
});
