import {SET_BORROWING_TEST_SEARCH_DATA} from "../../../actions/action-types";
import {SORT_DIRECTION} from "../../../../constants/sortDirections";
import {RESULTS_PER_PAGE} from "../../../../constants/pagination";
import {BORROWING_TEST_SORT} from "../../../../constants/sort/borrowingTestSort";

const initialState = {
  sortBy: BORROWING_TEST_SORT.CREATE_DATE,
  sortDirection: SORT_DIRECTION.ASC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  page: 1,
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SET_BORROWING_TEST_SEARCH_DATA}`:
      return action.payload;

    default: return state;
  }
};
