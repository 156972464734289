import React, {useEffect, useState} from 'react';
import qs from 'qs';
import styles from './QRCodesScene.module.css';
import {qrCodesRequestStart} from "../../redux/actions/qrcodes/list";
import {connect} from "react-redux";
import QRCode from "qrcode.react";

const mapStateToProps = state => ({
    qrCodes: state.qrCodes
});

const mapDispatchToProps = dispatch => ({
    requestCodes: (ids) => dispatch(qrCodesRequestStart(ids))
});

const QRCodesScene = ({qrCodes, requestCodes, location: {search}}) => {
    const [error, setError] = useState(false);
    useEffect(() => {
        if (search) {
            requestCodes(qs.parse(search.substr(1)).ids);
        } else {
            setError(true);
        }
    }, [search, requestCodes]);


    return error ?
        <div className={styles.error}>
            Произошла ошибка. Пожалуйста, повторите выбор экземпляров для печати.
        </div>
        :
        <div className={styles.container}>
            {qrCodes.map((item) =>
                Array(3).fill(item).map((item, index) =>
                    <div className={styles.codeContainer} key={item + index}>
                        <QRCode value={item.qrcode} className={styles.qrCode} renderAs={'svg'}/>
                        <div className={styles.label}>
                            {item.number}
                        </div>
                    </div>
                ))}
        </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodesScene);
