import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import {
  PERMISSIONS_BY_SERIES_ADD_REQUEST,
  PERMISSIONS_BY_SERIES_COUNT_REQUEST,
  PERMISSIONS_BY_SERIES_EDIT_REQUEST,
  PERMISSIONS_BY_SERIES_GROUP_EDIT,
  PERMISSIONS_BY_SERIES_LIST_REQUEST,
  START
} from "../../../actions/action-types";
import {
  getSeries2roleCount,
  getSeries2role,
  addSeries2role,
  editSeries2role,
  editSeries2roleGroup
} from "../../../../api/series/series2role";
import {
  permissionsBySeriesCountRequestStart,
  permissionsBySeriesCountRequestSuccess,
  permissionsBySeriesCountRequestError
} from '../../../actions/permissions/bySeries/count';
import {
  permissionsBySeriesRequestStart,
  permissionsBySeriesRequestSuccess,
  permissionsBySeriesRequestError
} from '../../../actions/permissions/bySeries/list';
import {
  permissionsBySeriesAddRequestSuccess,
  permissionsBySeriesAddRequestError
} from '../../../actions/permissions/bySeries/add';
import {
  permissionsBySeriesEditRequestSuccess,
  permissionsBySeriesEditRequestError
} from '../../../actions/permissions/bySeries/edit';
import {setPermissionsBySeriesSearchData} from "../../../actions/permissions/bySeries/search";
import { createNotification } from "../../../../components/notification/notification";




export default function* permissionsBySeriesWatcher() {
  yield takeLatest(`${PERMISSIONS_BY_SERIES_COUNT_REQUEST}${START}`, countWorker);
  yield takeLatest(`${PERMISSIONS_BY_SERIES_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${PERMISSIONS_BY_SERIES_ADD_REQUEST}${START}`, addWorker);
  yield takeLatest(`${PERMISSIONS_BY_SERIES_EDIT_REQUEST}${START}`, editWorker);
  yield takeLatest(`${PERMISSIONS_BY_SERIES_GROUP_EDIT}${START}`, permissionsBySeriesGroupChangesWorker)
}

function* countWorker() {
  const formData = yield select(({accessSeries}) => accessSeries.form);
  const searchData = yield select(({accessSeries}) => accessSeries.search);
  try {
    const response = yield call(getSeries2roleCount, {
      roleid: formData.roleid,
      set: formData.set
    });
    const count = parseInt(response.data.count, 10);
    yield put(permissionsBySeriesCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setPermissionsBySeriesSearchData({...searchData, pageCount }));
    yield put(permissionsBySeriesRequestStart());
  } catch (error) {
    yield put(permissionsBySeriesCountRequestError());
  }
}

function* listWorker() {
  const formData = yield select(({accessSeries}) => accessSeries.form);
  const searchData = yield select(({accessSeries}) => accessSeries.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);

  try {
    const response = yield call(getSeries2role, {
      roleid: formData.roleid,
      set: formData.set,
      ...searchData
    });
    yield put(permissionsBySeriesRequestSuccess(response.data));
  } catch (error) {
    yield put(permissionsBySeriesRequestError(error));
  }
}

function* addWorker({ payload: { role, seriesid, access } }) {
  try {
    yield call(addSeries2role, { role, seriesid, access });
    yield put(permissionsBySeriesAddRequestSuccess());
    yield put(permissionsBySeriesCountRequestStart());
  } catch(error) {
    yield put(permissionsBySeriesAddRequestError());
  }
}

function* editWorker({ payload: { role, seriesid, access, itemid } }) {
  try {
    yield call(editSeries2role, { role, seriesid, access, itemid });
    yield put(permissionsBySeriesEditRequestSuccess());
    yield put(permissionsBySeriesCountRequestStart());
  } catch(error) {
    yield put(permissionsBySeriesEditRequestError());
  }
}

function* permissionsBySeriesGroupChangesWorker () {
  try {
    const payload = {"access": "Чтение"}
    yield call(editSeries2roleGroup, payload)
    yield put(permissionsBySeriesCountRequestStart())
    createNotification('success', 'Успешное выполнение группового изменения прав')
  } catch (error) {
    createNotification('error', 'Ошибка группового изменения прав')
  }
}