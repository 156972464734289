import { ERROR, SERIES_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const seriesListRequestStart = (data) => ({
  type: `${SERIES_LIST_REQUEST}${START}`,
  payload: data
});

export const seriesListRequestSuccess = (data) => ({
  type: `${SERIES_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const seriesListRequestError = (data) => ({
  type: `${SERIES_LIST_REQUEST}${ERROR}`,
  payload: data
});