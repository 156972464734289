import React, {useState} from "react";
import T from "prop-types";
import styles from "./DocumentsSearchForm.module.css";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { Button } from "antd";
import SearchableSelect from "../../Select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date().required("Выберите дату начала"),
  to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});

const DocumentsSearchForm = ({
  data,
  setSearchData,
  startSearch,
  types,
  institutionsList,
}) => {
  
  const [supplier, setSupplier] = useState("")


  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setSearchData({...values, supplier: supplier.value});
      startSearch();
    },
  });

  const { values, errors } = modalForm;

  return (
    <div className={"formContainer"}>
      <Row>
        <Col md={11}>
          <Row>
            <Col md={4}>
              <ModalInputWrapper label={"Вид документа"}>
                <select
                  value={values["documenttype"]}
                  className={styles.select}
                  name="documenttype"
                  onChange={modalForm.handleChange}
                >
                  <option value={""}>Не выбрано</option>
                  {types.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </ModalInputWrapper>
            </Col>
            <Col md={4}>
              <ModalInputWrapper
                value={values["from"]}
                error={errors["from"]}
                name="from"
                onChange={modalForm.handleChange}
                type={"date"}
                label={"С"}
              />
            </Col>
            <Col md={4}>
              <ModalInputWrapper
                value={values["to"]}
                error={errors["to"]}
                name="to"
                onChange={modalForm.handleChange}
                type={"date"}
                label={"По"}
              />
            </Col>
            <Col md={4}>
              <ModalInputWrapper label="Источник">
                <SearchableSelect
                  placeholder="Поиск"
                  id="supplier"
                  name="supplier"
                  provider={true}
                  options={institutionsList}
                  onChange={(val) => {
                    setSupplier(val);
                  }}
                  value={supplier}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={4}>
              <ModalInputWrapper
                label={"Поиск по примечанию"}
                onChange={modalForm.handleChange}
                name={"query"}
                value={values["query"]}
              />
            </Col>
            <Col md={4}>
              <ModalInputWrapper
                label={"Поиск по номеру"}
                onChange={modalForm.handleChange}
                name={"documentnumber"}
                value={values["documentnumber"]}
              />
            </Col>
          </Row>
        </Col>
        <Col >
          <ModalInputWrapper label={""}>
            <Button type={"primary"} onClick={modalForm.handleSubmit}>
              Найти
            </Button>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

DocumentsSearchForm.propTypes = {
  data: T.object,
  setSearchData: T.func,
  startSearch: T.func,
  types: T.array,
};

export default DocumentsSearchForm;
