import {api} from '../../App';

export const getTeacherList = (maxResults, paginateFrom, search) => {
    const params = {
        maxResults,
        paginateFrom,
    };

    return search ?
        api.get(`/hlms/api/users/teachers/${search}`, {params}) :
        api.get(`/hlms/api/users/teachers`, {params})
};


export const getTeacherCount = (search) => {
    const params = {
        count: true
    };

    return search ?
        api.get(`/hlms/api/users/teachers/${search}`, {params}) :
        api.get(`/hlms/api/users/teachers`, {params})
};