import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SearchBooksItem.module.css'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Checkbox } from 'antd'
import CoverPhotoPlaceholder from '../../../../../Covers/List/Item/CoverPhotoPlaceholder'
import OpenPhotoModal from '../../../../../OpenPhotoModal'
import { resolveBookCoverUrl } from '../../../../../../utils/resolvePhotoUrl'
import { statusList } from '../../../../../../constants/statusList'
import ItemCatalogCard from '../../../../../UtulityComponents/SearchBooksItem/ItemCatalogCard'
import CoverListItemInfo from '../../../../../Covers/List/Item/InfoBlock'

const SearchBooksItem = ({ data, termOfUse, onSelect, isChecked, onClick, children }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const outdated = termOfUse && data.year + termOfUse < new Date().getFullYear()

  return (
    <a href='#current'>
      <div onClick={onClick ? () => onClick(data) : null} className={classNames(styles.container, isChecked && styles.checked)}>
        <Row>
          <Col md={3} lg={2}>
            <CoverPhotoPlaceholder url={data.coverImage} bookId={data.bookid} alt={data.title} />
            <div className={styles.status}>{statusList[data.verifyStatus]}</div>
            <OpenPhotoModal
              src={data.subscriptionCover && resolveBookCoverUrl(data.subscriptionCover, data.bookid)}
              visible={modalVisible}
              onClose={() => setModalVisible(false)}
            />
            <ItemCatalogCard label={'Каталожная карточка'} data={data} setModalVisible={setModalVisible} />
          </Col>
          <Col>
            <Row>
              <Col md={{ span: 9, offset: 3 }}>
                {/* <Link to={`/book/${data.bookid}`} className={styles.title}>
                  {data.title}
                </Link> */}
                <div className={styles.title}>{data.title}</div>
                <div className={styles.value}>{data.authors}</div>
              </Col>
              <Col md={12}>
                <CoverListItemInfo label={'Год'} value={data.year} />
                <CoverListItemInfo label={'ISBN'} value={data.isbn} />
                <CoverListItemInfo label={'Размещение'} value={data.clients || 'n/a'} />
                <CoverListItemInfo label={'Фонд'} value={data.fund || 'n/a'} />
                <CoverListItemInfo label={'Страниц'} value={data.pageNum} />
                <CoverListItemInfo label={'Издательство'} value={data.publicationPlace || data.publishers} />
              </Col>
              {outdated && (
                <Col md={{ span: 5, offset: 3 }}>
                  <div className={'color-red'}>Превышен срок использования</div>
                </Col>
              )}
            </Row>
          </Col>
          {onSelect && (
            <Col md={1} lg={1}>
              <Checkbox onChange={() => onSelect(data.bookid)} checked={isChecked}></Checkbox>
            </Col>
          )}
          {children && (
            <Col md={12} className={'text-right'}>
              {children}
            </Col>
          )}
        </Row>
      </div>
    </a>
  )
}

SearchBooksItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
}

export default SearchBooksItem
