import { BOOK_RESERVE_LIST_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const bookreserveListRequestStart = () => ({
  type: `${BOOK_RESERVE_LIST_REQUEST}${START}`,
});

export const bookreserveListRequestSuccess = (response) => ({
  type: `${BOOK_RESERVE_LIST_REQUEST}${SUCCESS}`,
  payload: response
});

export const bookreserveListRequestError = (error) => ({
  type: `${BOOK_RESERVE_LIST_REQUEST}${ERROR}`,
  payload: error
});
