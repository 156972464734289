import { DOCUMENT_BOOK_ORDER_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const booksOrderListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${DOCUMENT_BOOK_ORDER_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
