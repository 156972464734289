import { COLLECTIONS_DELETE_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const collectionsDeleteRequestStart = (id) => ({
  type: `${COLLECTIONS_DELETE_REQUEST}${START}`,
  payload: id
});

export const collectionsDeleteRequestSuccess = (data) => ({
  type: `${COLLECTIONS_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const collectionsDeleteRequestError = (data) => ({
  type: `${COLLECTIONS_DELETE_REQUEST}${ERROR}`,
  payload: data
});