import {BOOK_CARD_DATA_REQUEST, SUCCESS} from "../../actions/action-types";

const initialState = [];

export const bookCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_CARD_DATA_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};