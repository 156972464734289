import { INVENTARY_BOOK_LOAD_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const inventaryBookLoadStart = () => ({
  type: `${INVENTARY_BOOK_LOAD_REQUEST}${START}`,

});

export const inventaryBookLoadSuccess = (data) => ({
  type: `${INVENTARY_BOOK_LOAD_REQUEST}${SUCCESS}`,
  payload: data
});

export const inventaryBookLoadError = (data) => ({
  type: `${INVENTARY_BOOK_LOAD_REQUEST}${ERROR}`,
  payload: data
});
