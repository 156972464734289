import React from 'react'
// import { typesOfWork } from '../../../redux/factory/currentPerfomanceReduxData'
// import getFileIcon from '../../utils/getFileIcon'

export const TasksFiles = ({ modalData, download, type, row }) => {
  const handleDownloadFile = file => {
    // download(file.file, row.sheid, row.instance, type, typesOfWork.HOME_WORK)
  }

  const renderFile = (file, index) => {
    return (
      <tr key={index}>
        <td className='td-center' width='20%'>
          {/* <img alt={'icon'} src={getFileIcon(file.file)} /> */}
        </td>
        <td width='80%'>
          <a role='button' onClick={() => handleDownloadFile(file)}>
            {file.file}
          </a>
        </td>
      </tr>
    )
  }

  return (
    <div className='tasks-files'>
      <div className='caption'>Файлы задания</div>
      <table>
        <tbody>{modalData?.map(renderFile)}</tbody>
      </table>
      <div className='buttons_panel'>
        <div className='left__panel'>&nbsp;</div>
      </div>
    </div>
  )
}
