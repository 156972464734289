import React, { useEffect, useState } from 'react'
import CurrentPerfomanceTable from '../../components/CurrentPerfomance/Table'
import { currentPerfomanceActions, requestAction } from '../../redux/factory/currentPerfomanceReduxData'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import SearchForm from '../../components/CurrentPerfomance/SearchForm'

const mapStateToProps = ({ currentPerfomance, auth }) => ({
  list: currentPerfomance,
  loader: currentPerfomance.loader,
  authDataMid: auth.data.mid,
  modalData: currentPerfomance.modal,
})

const matchDispatchToProps = {
  loadList: currentPerfomanceActions.loadRequestStart,
  dataToState: requestAction,
  startRequest: currentPerfomanceActions.requestRequestStart,
  filesRequest: currentPerfomanceActions.filesRequestStart,
}

const CurrentPerfomance = ({ loader, list, loadList, dataToState, startRequest, authDataMid, filesRequest, modalData }) => {
  useEffect(() => {
    loadList()
  }, [])

  return (
    <SceneContainer title={'ТЕКУЩАЯ УСПЕВАЕМОСТЬ - ЖУРНАЛ ЗАНЯТИЙ'}>
      {list.discipline && (
        <SearchForm
          loadList={loadList}
          list={list}
          dataToState={dataToState}
          startRequest={startRequest}
          authDataMid={authDataMid}
        />
      )}

      {loader && <SpinnerOuter isLoading={loader} />}

      <CurrentPerfomanceTable
        res={list.response?.data}
        isTableVisible={list.response}
        filesRequest={filesRequest}
        modalData={modalData}
      />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, matchDispatchToProps)(CurrentPerfomance)
