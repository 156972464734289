import React from 'react';
import classNames from 'classnames';
import styles from './LibraryCarouselItem.module.css';
import T from 'prop-types';
import {Col, Row} from "react-bootstrap";
import {resolveBookCoverUrl} from "../../../../utils/resolvePhotoUrl";

const LibraryCarouselItem = ({data, index}) => {

    if (!data.subscriptionCover) {
        return (
            <div className={styles.outerContainer}>
                <div className={classNames(styles.container, styles.textContainer)}>
                    <Row>
                        <Col md={2}>
                            {data.authorSign}
                        </Col>
                        <Col md={{span: 6, offset: 1}} className={styles.authors}>
                            {data.authors}
                        </Col>
                        <Col md={{span: 1, offset: 2}}>
                            {index}
                        </Col>
                        <Col md={{span: 10, offset: 2}}>
                            <div className={styles.infoText}>
                                {data.title} {data.addition && `: ${data.addition}`} {data.publishers && `: ${data.publishers}`} {data.year}.
                            </div>
                            {data.isbn && <div className={styles.infoText}>
                                ISBN: {data.isbn}
                            </div>}
                        </Col>
                    </Row>
                    <div className={styles.bottomRowContainer}>
                        <Col md={{span: 2, offset: 9}}>
                            {data.udk}
                            <br/>
                            {data.bbk}
                        </Col>
                    </div>
                    <div className={styles.circle}/>
                </div>
            </div>
        );
    }
    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                <img src={resolveBookCoverUrl(data.subscriptionCover, data.bookId)}
                     alt={data.title}
                     className={styles.image}
                />
            </div>
        </div>
    );
};

LibraryCarouselItem.propTypes = {
    data: T.object
};

export default LibraryCarouselItem;
