import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  BOOK_PREPARE_REQUEST,
  INV_NUMBER_CREATE_REQUEST,
  START,
} from "../../actions/action-types";
import {
  addInvNumber
} from "../../../api/invNumbers/invNumbers";
import {
  createInvNumberRequestSuccess,
  createInvNumberRequestError
} from "../../actions/bookLoan/create";
import { availableBooksActions } from '../../factory/availableBooksModalReduxData';
import {prepareBookRequestError, prepareBookRequestSuccess} from "../../actions/bookLoan/prepare";
import {prepareBook} from "../../../api/bookLoan/prepareBook";


export default function* bookLoanWatcher() {
  yield takeLatest(`${INV_NUMBER_CREATE_REQUEST}${START}`, createInvNumberWorker);
  yield takeLatest(`${BOOK_PREPARE_REQUEST}${START}`, prepareBookWorker)
}

function* createInvNumberWorker({ payload: data }) {
  try {
    const response = yield call(addInvNumber, data.bookId, data.number);

    yield put(availableBooksActions.moveBook({
      // Шифр
      typeFrom: 1,
      placeFrom: null,
      placeTo: data.userId,
      // Шифр
      typeTo: 2,
      returndate: data.returnDate,
      instanceId: response.data.id,
      mid: data.userId,
    }));
    yield put(createInvNumberRequestSuccess(response.data));
  } catch (e) {
    yield put(createInvNumberRequestError(e));
  }
}

function* prepareBookWorker({payload: id}) {
  const mid = yield select(({readerData}) => readerData.mid)
  const status = 2;
  try {
    yield call(prepareBook, id, mid, status)
    yield put(prepareBookRequestSuccess());
  } catch (e) {
    yield put(prepareBookRequestError(e));
  }
}
