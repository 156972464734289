import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  BOOK_TRANSFER_COUNT_REQUEST,
  BOOK_TRANSFER_LIST_REQUEST,
  BOOK_TRANSFER_DELETE_REQUEST,
  BOOK_TRANSFER_ADD_REQUEST,
  START,
} from "../../actions/action-types";
import {
  getBookTransferRequestCount,
  getBookTransferRequestList,
  deleteBookTransferItem,
  addBookTransferItem
} from "../../../api/bookTransfer/bookTransfer";
import {
  bookTransferCountRequestError,
  bookTransferCountRequestStart,
  bookTransferCountRequestSuccess
} from "../../actions/bookTransfer/count";
import {setBookTransferSearchData} from "../../actions/bookTransfer/search";
import {
  bookTransferListRequestError,
  bookTransferListRequestStart,
  bookTransferListRequestSuccess
} from "../../actions/bookTransfer/list";
import {bookTransferDeleteRequestError, bookTransferDeleteRequestSuccess} from "../../actions/bookTransfer/delete";
import {bookTransferAddRequestError, bookTransferAddRequestSuccess} from "../../actions/bookTransfer/add";


export default function* bookTransferWatcher() {
  yield takeLatest(`${BOOK_TRANSFER_COUNT_REQUEST}${START}`, countWorker);
  yield takeLatest(`${BOOK_TRANSFER_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${BOOK_TRANSFER_DELETE_REQUEST}${START}`, deleteWorker);
  yield takeLatest(`${BOOK_TRANSFER_ADD_REQUEST}${START}`, addWorker);

}

function* countWorker() {
  const searchData = yield select(({bookTransfer}) => bookTransfer.search);
  const formData = yield select(({bookTransfer}) => bookTransfer.form);
  try {
    const response = yield call(getBookTransferRequestCount, formData);
    const count = parseInt(response.data.count, 10);
    yield put(bookTransferCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setBookTransferSearchData({ ...searchData, pageCount}));
    if(count > 0) {
      yield put(bookTransferListRequestStart());
    }
  } catch (error) {
    yield put(bookTransferCountRequestError(error));
  }
}

function* listWorker() {
  const searchData = yield select(({bookTransfer}) => bookTransfer.search);
  const formData = yield select(({bookTransfer}) => bookTransfer.form);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getBookTransferRequestList, {...formData, ...searchData});
    yield put(bookTransferListRequestSuccess(response.data));
  } catch (error) {
    yield put(bookTransferListRequestError(error));
  }
}

function* deleteWorker({payload: bookId}) {
  try {
    yield call(deleteBookTransferItem, bookId);
    yield put(bookTransferDeleteRequestSuccess());
    yield put(bookTransferCountRequestStart());
  } catch (error) {
    yield put(bookTransferDeleteRequestError(error));
  }
}

function* addWorker({payload: { bookid, clientid }}) {
  try {
    const response = yield call(addBookTransferItem, { bookid, clientid });
    yield put(bookTransferAddRequestSuccess(response));
    yield put(bookTransferCountRequestStart());

  } catch (error) {
    yield put(bookTransferAddRequestError(error));
  }
}