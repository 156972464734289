import React from 'react'
import moment from 'moment'
import T from 'prop-types'
import DataTableCol from '../../DataTable/Column'
import DataTable from '../../DataTable'
import styles from './BookExportList.module.css'
import { resolveDateWithTimeZone } from '../../../utils/Dates/resolveDateWithTimeZone'

const BookExportList = ({ data, deleteItem }) => {
  return (
    <div>
      <DataTable data={data}>
        <DataTableCol title='Дата создания' resolver={item => resolveDateWithTimeZone(new Date(item.created).toLocaleString())} />
        <DataTableCol
          title='Дата завершения'
          resolver={item => {
            return item.finished ? resolveDateWithTimeZone(new Date(item.finished).toLocaleString()) : 'В работе'
          }}
        />
        <DataTableCol title='Количество книг' value='books' />
        <DataTableCol
          title='Ссылка на файл'
          resolver={item => (
            <a href={`/api/bookexportfile/${item.id}/result/${item.resultfile}`} download>
              {item.resultfile}
            </a>
          )}
        />
        <DataTableCol
          title=''
          resolver={item => (
            <span className={styles.delete} onClick={() => deleteItem(item.id)}>
              Удалить
            </span>
          )}
        />
      </DataTable>
    </div>
  )
}

BookExportList.propTypes = {
  data: T.array,
  deleteItem: T.func,
}

export default BookExportList
