import { DOCUMENT_BOOK_ORDER_COUNT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const documentCountRequestStart = () => ({
  type: `${DOCUMENT_BOOK_ORDER_COUNT_REQUEST}${START}`
});

export const documentCountRequestSuccess = (data) => ({
  type: `${DOCUMENT_BOOK_ORDER_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentCountRequestError = (data) => ({
  type: `${DOCUMENT_BOOK_ORDER_COUNT_REQUEST}${ERROR}`,
  payload: data
});
