import { call, put, takeLatest, select } from "redux-saga/effects";
import { PUBLISHERS_COUNT_REQUEST, START, SUCCESS } from "../../../actions/action-types";
import { publishersCount, publishersList } from "../../../../api/publishers/publishers";
import { publishersCountRequestError, publishersCountRequestSuccess } from "../../../actions/publishers/count";
import { setPublishersSearchData } from "../../../actions/publishers/searchData";
import {
  publishersListRequestError,
  publishersListRequestStart,
  publishersListRequestSuccess
} from "../../../actions/publishers/list";

export default function* publishersRequestWatcher() {
  yield takeLatest(`${PUBLISHERS_COUNT_REQUEST}${START}`, publishersRequestCountWorker);
  yield takeLatest(`${PUBLISHERS_COUNT_REQUEST}${SUCCESS}`, publishersRequestListWorker);
}

function* publishersRequestCountWorker() {
  const searchData = yield select(({ publishers }) => publishers.search);

  try {
    const response = yield call(publishersCount, searchData.query);
    const count = parseInt(response.data.count, 10);
    yield put(publishersCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setPublishersSearchData({ ...searchData, pageCount }));
    yield put(publishersListRequestStart());
  } catch (error) {
    yield put(publishersCountRequestError(error));
  }

}

function* publishersRequestListWorker() {
  const searchData = yield select(({ publishers }) => publishers.search);
  const paginateFrom = searchData.maxResults ? Math.max(searchData.maxResults * (searchData.page - 1), 0) : null;

  try {
    const response = yield call(publishersList, searchData.sortBy, 15, paginateFrom, searchData.query);
    yield put(publishersListRequestSuccess(response.data));
  } catch (error) {
    yield put(publishersListRequestError(error));
  }

}