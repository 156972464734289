import React from 'react';
import T from 'prop-types';
import PdfPrintPanel from "../../PDFPrint/Panel";
import jsPDF from "jspdf";
import addFont from "../../../fonts/fonts";

const BookSupplyPDFPrint = ({title, data}) => {

    const orientation = 'landscape';
    const leftIndent = 15;

    let currentYpos = 30;

    const createTableLegend = () => {
        doc.setTextColor(25);
        doc.setFontSize(11);
        doc.text("1 курс", 137, currentYpos);
        doc.text("2 курс", 162, currentYpos);
        doc.text("3 курс", 187, currentYpos);
        doc.text("4 курс", 212, currentYpos);
        doc.text("5 курс", 237, currentYpos);
        doc.text("общие", 262, currentYpos);
        currentYpos += 5;
    };

    const createTableRow = (level, title, item) => {
        if(isNaN(level)) return;

        let { course_all, course_five, course_four, course_one, course_three, course_two } = item;
        course_all = course_all.toString();
        course_five = course_five.toString();
        course_four = course_four.toString();
        course_one = course_one.toString();
        course_three = course_three.toString();
        course_two = course_two.toString();

        const course_all_dim = doc.getTextDimensions(course_all);
        const course_five_dim = doc.getTextDimensions(course_five);
        const course_four_dim = doc.getTextDimensions(course_four);
        const course_one_dim = doc.getTextDimensions(course_one);
        const course_three_dim = doc.getTextDimensions(course_three);
        const course_two_dim = doc.getTextDimensions(course_two);


        const levelParams = new Map([
            [0, {
                cellFillColor: [48, 72, 117],
                cellTextColor: [255, 255, 255],
            }],
            [1, {
                cellFillColor: [74, 112, 182],
                cellTextColor: [255, 255, 255],
            }],
            [2, {
                cellFillColor: [224, 232, 255],
                cellTextColor: [60, 60, 60],
            }],
            [3, {
                cellFillColor: [255, 255, 255],
                cellTextColor: [60, 60, 60],
            }],
        ]);

        const cellFillColor = levelParams.get(level).cellFillColor;
        const cellTextColor = levelParams.get(level).cellTextColor;
        const levelIndent = leftIndent + (level * 2);
        const cellHigh = 10;
        const cellWidth = 25;

        doc.setDrawColor(66, 66, 66);
        doc.setFillColor(255, 255, 255);

        //Добавляю таблицу
        doc.rect(levelIndent, currentYpos, (130 - levelIndent), 10, "FD");
        doc.setFillColor(cellFillColor[0], cellFillColor[1], cellFillColor[2]);
        doc.rect(130, currentYpos, cellWidth, cellHigh, "FD");
        doc.rect(155, currentYpos, cellWidth, cellHigh, "FD");
        doc.rect(180, currentYpos, cellWidth, cellHigh, "FD");
        doc.rect(205, currentYpos, cellWidth, cellHigh, "FD");
        doc.rect(230, currentYpos, cellWidth, cellHigh, "FD");
        doc.rect(255, currentYpos, cellWidth, cellHigh, "FD");

        doc.setFontSize(9);
        //Добавляю ячейку заголовок
        if(title) {
            doc.setTextColor(60, 60, 60);
            if(title.length > 55) {
                doc.text(title.slice(0, 55 - title.length) + '\n' + title.slice(55 - title.length), levelIndent + 2, currentYpos + 4);
            } else {
                doc.text(title, levelIndent + 2, currentYpos + 6);
            }
        }

        //Добавляю значения ячеек
        doc.setTextColor(cellTextColor[0], cellTextColor[1], cellTextColor[2]);

        doc.text(course_one, (130 + cellWidth / 2) - course_one_dim.w / 2, currentYpos + 6);
        doc.text(course_two, (155 + cellWidth / 2) - course_two_dim.w / 2, currentYpos + 6);
        doc.text(course_three, (180 + cellWidth / 2) - course_three_dim.w / 2, currentYpos + 6);
        doc.text(course_four, (205 + cellWidth / 2) - course_four_dim.w / 2, currentYpos + 6);
        doc.text(course_five, (230 + cellWidth / 2) - course_five_dim.w / 2, currentYpos + 6);
        doc.text(course_all, (255 + cellWidth / 2) - course_all_dim.w / 2, currentYpos + 6);


        if(currentYpos > 180) {
            currentYpos = 15;
            doc.addPage('a4', orientation);
            createTableLegend();
        } else {
            currentYpos += cellHigh;
        }

    };

    addFont(jsPDF.API);
    const doc = new jsPDF({putOnlyUsedFonts: true, orientation: orientation});
    doc.setFont("Montserrat");

    doc.setTextColor(55, 111, 185);
    doc.text(title, leftIndent, 15);

    createTableLegend();


    data.forEach(item => {
        createTableRow(0, item.name, item);

        item.disciplines.forEach(discipline => {
            createTableRow(1, 'Дисциплина: ' + discipline.name, discipline);

            discipline.razdels.forEach(razdel => {
                createTableRow(2, razdel.name, razdel);

                razdel.books.forEach(book => {
                    createTableRow(3, book.name, book);
                });
            });
        });
    });


    const toPdf = () => {
        doc.save(`${title}.pdf`);
    };

    const toPrint = () => {
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    };

    return (
        <PdfPrintPanel loadPdf={toPdf} printPdf={toPrint}/>
    );
};

BookSupplyPDFPrint.propTypes = {
    title: T.string.isRequired,
};

export default BookSupplyPDFPrint;