import {call, put, takeLatest} from "redux-saga/effects";
import {CREATE_PRICE_COEFF_REQUEST, PRICE_COEFF_REQUEST, START} from "../../../actions/action-types";
import { priceCoeff } from "../../../../api/common";
import { priceCoeffRequestError, priceCoeffRequestStart, priceCoeffRequestSuccess } from "../../../actions/common/priceCoeff/list";
import { createPriceCoeffRequestError } from "../../../actions/common/priceCoeff/create";

export default function* priceCoeffWatcher () {
    yield takeLatest(`${PRICE_COEFF_REQUEST}${START}`, getPriceCoeffs)
    yield takeLatest(`${CREATE_PRICE_COEFF_REQUEST}${START}`, createPriceCoeff)
}

function* getPriceCoeffs () {
  try {
      const response = yield call(priceCoeff.get);
      yield put(priceCoeffRequestSuccess(response.data))
  } catch (error) {
      yield put(priceCoeffRequestError(error))
  }
}

function* createPriceCoeff ({ payload }) {
  try {
      yield call(priceCoeff.post, payload);
      yield put(priceCoeffRequestStart())
  } catch (error) {
      yield put(createPriceCoeffRequestError(error))
  }
}