import React, { useState } from 'react';
import T from 'prop-types';
import ModalWindow from "../../../../ModalWindow";
import UDKList from "../../../../UDK/List";
import BBKList from "../../../../BBK/List";
import Search from '../../../../Search';
import SVNIList from '../../../../SVNI/List';

const CodesSearchModal = ({type, isOpen, setOpen, data, fetchSubList, collapseList, label, setCodeEditor}) => {
    
    const [search, setSearch] = useState('')

    const onSelect = (item) => {
        setCodeEditor(item);
        setOpen(false);
    };

    return (
        <ModalWindow
            title={'Поиск кода в справочнике'}
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            >
            <Search
                label={`Введите код ${label} или ключевое слово для поиска`}
                placeholder={`Разрешенные символы: а-я 0-9 . /`}
                onChange={setSearch}
                onSubmit={() => fetchSubList(0, search, true)}
                value={search}
                type="text"
            />
            {type === 'udk' &&
            <UDKList
                data={data}
                fetchSubList={fetchSubList}
                collapseList={collapseList}
                onSelect={onSelect}
            />
            }
            {type === 'bbk' &&
            <BBKList
                data={data}
                fetchSubList={fetchSubList}
                collapseList={collapseList}
                onSelect={onSelect}
            />
            }
            {type === 'svni' &&
            <SVNIList
                data={data}
                fetchSubList={fetchSubList}
                collapseList={collapseList}
                onSelect={onSelect}
                isButton={true}
            />
            }

        </ModalWindow>
    );
};

CodesSearchModal.propTypes = {
    type: T.string,
    isOpen: T.bool.isRequired,
    setCodeEditor: T.func.isRequired
};

export default CodesSearchModal;
