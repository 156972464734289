import React, {useState} from 'react';
import T from 'prop-types';
import classNames from 'classnames';
import styles from "../../../scenes/SearchByImage/SearchByImage.module.css";
import ChooseImage from "../../ChooseFile";
import Button from "../../Button";
import {createNotification} from "../../notification/notification";
import ImageDropUploader from "../ImageDropUploader";
import InputWrapper from "../../Modal/InputWrapper";

const SearchByImageForm = ({onSubmit}) => {

    const IMAGE_FORMATS = ['JPG', 'JPEG', 'PNG'];
    const DISTANCE_VALUES = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

    const [image, setImage] = useState(null);
    const [distance, setDistance] = useState(60);

    const uploadImage = file => {
        let fileFormat = file.name.split('.').slice(-1)[0].toUpperCase();
        if (IMAGE_FORMATS.includes(fileFormat)) {
            setImage(file);
            onSubmit({file, distance});
        } else {
            createNotification('error', 'Недопустимый формат');
        }
    };

    const onChangeSelect = (e) => {
        const distance = +e.target.value;
        setDistance(distance);
        if(image) {
            onSubmit({file: image, distance});
        }
    };

    return (
        <div>
            <ImageDropUploader
                image={image}
                uploadImage={uploadImage}
            />

            <div className={classNames(styles.controls, 'd-flex justify-content-between align-items-center')}>
                <div className={styles.picName}>
                    {!!image && image.name && <>Исходная картинка: <b>{image.name}</b></>}
                </div>

                <div className={'d-flex align-items-center'}>
                    <InputWrapper label="Степень схожести">
                        <select
                            name="distance" value={distance}
                            onChange={onChangeSelect}
                            style={{width: '300px'}}
                        >
                            {DISTANCE_VALUES.map(value =>
                                <option value={value}>{value}%</option>
                            )}
                        </select>
                    </InputWrapper>

                    <div className={classNames('d-flex ml-2')}>
                        <ChooseImage
                            onChange={file => {
                                uploadImage(file);
                            }}
                            className={styles.chooseImage}
                        >
                            <Button label="Загрузить картинку"/>
                        </ChooseImage>
                        {image &&
                        <Button colorType={'red'}
                                label={'Удалить картинку'}
                                onClick={() => setImage(null)}
                                style={'ml-2'}/>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
};

SearchByImageForm.propTypes = {
    onSubmit: T.func.isRequired,
};

export default SearchByImageForm;