import { api } from '../../App'

export const getBookSecretDocCount = formData => {
  const params = {
    count: true,
  }
  return api.post(`/booksecretdoc`, formData, { params })
}

export const getBookSecretDocList = formData => {
  const params = {
    ...formData,
  }

  return api.get(`/booksecretdoc`, { params })
}
