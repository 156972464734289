import { ERROR, ROOM_SAVE_REQUEST, START, SUCCESS } from "../../action-types";

export const roomSaveRequestStart = (libraryId, data) => ({
  type: `${ROOM_SAVE_REQUEST}${START}`,
  payload: {libraryId, data}
});

export const roomSaveRequestSuccess = (data) => ({
  type: `${ROOM_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const roomSaveRequestError = (data) => ({
  type: `${ROOM_SAVE_REQUEST}${ERROR}`,
  payload: data
});