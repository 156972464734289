import React, { useEffect } from 'react'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import BookGivingStatsReportForm from '../../../components/Reports/BookGivingStats/Form'
import BookGivingStatsTable from '../../../components/Reports/BookGivingStats/Table'
import { reportGetRequestStart } from '../../../redux/actions/reports/get'
import { setReportsSearchData } from '../../../redux/actions/reports/searchData'
import { connect } from 'react-redux'
import { librariesListRequestStart } from '../../../redux/actions/documents/fundAllocation/libraries'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import Pagination from '../../../components/Pagination'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import BookGivingStatsPdfPrint from '../../../components/Reports/BookGivingStats/PdfPrint'

const mapStateToProps = ({ options, documents, reports }) => ({
  clientData: options.data,
  libraries: documents.libraries,
  data: reports.report,
  loader: reports.loader,
  searchData: reports.search,
})

const mapDispatchToProps = {
  requestLibraries: librariesListRequestStart,
  requestReport: reportGetRequestStart,
  setSearchData: setReportsSearchData,
}

const validationSchema = Yup.object().shape({
  dateFrom: Yup.date().required("Выберите дату начала"),
  dateTo: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("dateFrom"), "Дата завершения должна быть позже даты начала"),
  library: Yup.string(),
})

const BookGivingStatsReportScene = ({
  ownId,
  data,
  requestLibraries,
  clientData,
  libraries,
  requestReport,
  loader,
  setSearchData,
  searchData,
}) => {
  const searchForm = useFormik({
    initialValues: {
      dateFrom: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      library: '0', // Шифр
    },
    validationSchema: validationSchema,
    onSubmit: values => requestData(values),
  })

  useEffect(() => {
    clientData && requestLibraries(clientData.own_client_id)
  }, [requestLibraries, clientData])

  const requestData = formData => {
    requestReport({
      id: ownId,
      formData,
    })
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestReport({
      id: ownId,
      formData: searchForm.values,
    })
  }

  return (
    <SceneContainer title={'Статистика книговыдачи'}>
      <div className={'d-flex justify-content-end'}>
        <BookGivingStatsPdfPrint searchFormValue={searchForm.values} librariesList={libraries} />
      </div>
      <BookGivingStatsReportForm formData={searchForm} librariesList={libraries} />

      

      {loader ? <SpinnerOuter isLoading={loader} /> : data[ownId] && 
      <div>
      <Pagination
        count={searchData.count}
        pageCount={searchData.pageCount}
        current={searchData.page}
        setCurrent={setCurrentPage}
      />
      <BookGivingStatsTable data={data[ownId]} />
      <Pagination
        count={searchData.count}
        pageCount={searchData.pageCount}
        current={searchData.page}
        setCurrent={setCurrentPage}
      />
      </div>
      }

      
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BookGivingStatsReportScene)
