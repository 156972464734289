import { ERROR, SHELVES_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const shelvesListRequestStart = (data) => ({
  type: `${SHELVES_LIST_REQUEST}${START}`,
  payload: data
});

export const shelvesListRequestSuccess = (rackId, data) => ({
  type: `${SHELVES_LIST_REQUEST}${SUCCESS}`,
  payload: {rackId, data}
});

export const shelvesListRequestError = (data) => ({
  type: `${SHELVES_LIST_REQUEST}${ERROR}`,
  payload: data
});