import {
  MEDIA_RESOURCE_GET_FOR_READER_REQUEST, ERROR, START
} from "../../../../actions/action-types";

const initialState = null;

export const errorReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${MEDIA_RESOURCE_GET_FOR_READER_REQUEST}${START}`:
      return state;
    case `${MEDIA_RESOURCE_GET_FOR_READER_REQUEST}${ERROR}`:
      return action.payload;

    default: return state;
  }
};