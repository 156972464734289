import {
  ERROR,
  ROLE_MEMBER_DELETE_REQUEST,
  START,
  SUCCESS
} from "../action-types";

export const roleMemberDeleteRequestStart = data => ({
  type: `${ROLE_MEMBER_DELETE_REQUEST}${START}`,
  payload: data
});

export const roleMemberDeleteRequestSuccess = data => ({
  type: `${ROLE_MEMBER_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const roleMemberDeleteRequestError = data => ({
  type: `${ROLE_MEMBER_DELETE_REQUEST}${ERROR}`,
  payload: data
});
