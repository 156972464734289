import {START, SUCCESS, ERROR, SCHEDULER_CATEGORY_DELETE_REQUEST} from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const schedulerDeleteRequestStart = ({ itemId, categoryId }) => ({
  type: `${SCHEDULER_CATEGORY_DELETE_REQUEST}${START}`,
  payload: { itemId, categoryId }
});

export const schedulerDeleteRequestSuccess = () => {
  createNotification(
    'success',
    `Успешно удалено`
  );

  return {
    type: `${SCHEDULER_CATEGORY_DELETE_REQUEST}${SUCCESS}`
  }
};

export const schedulerDeleteRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка удаления. Статус: ${error.response.status}`
  );

  return {
    type: `${SCHEDULER_CATEGORY_DELETE_REQUEST}${ERROR}`,
    payload: error
  };
};
