import React from 'react'
import T from 'prop-types'
import classNames from 'classnames'
import styles from './SceneContainer.module.css'

const SceneContainer = ({ title, children }) => (
  <div className={styles.container}>
    {title && <div className={classNames(styles.title, 'mb-3')}>{title}</div>}
    {children}
  </div>
)

SceneContainer.propTypes = {
  title: T.string,
}

export default SceneContainer
