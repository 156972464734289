import React, {useEffect, useState} from 'react';
import styles from './JsonInput.module.css';

const Input = ({ inputHandler, label }) => {

    return (
            <div className={styles.container}>
                <input id={'input'} type={'file'} onChange={e => inputHandler(e.target.files[0])} />
                <label htmlFor={'input'}> {label} </label>
            </div>
    );
};

export default Input;
