import { DOCUMENT_NUMBER_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const documentNumberRequestStart = () => ({
  type: `${DOCUMENT_NUMBER_REQUEST}${START}`
});

export const documentNumberRequestSuccess = (data) => ({
  type: `${DOCUMENT_NUMBER_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentNumberRequestError = (data) => ({
  type: `${DOCUMENT_NUMBER_REQUEST}${ERROR}`,
  payload: data
});
