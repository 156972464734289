import React from "react";
import styles from "./Organizations.module.css";
import Button from "../../../Button";

const OrganizationItem = ({ item, onDelete = () => null, isTransferPossible, onTransferRequest }) => {



  return (
    <div className={styles.organizationItemContainer}>
      <div className={styles.name}>{item.name}</div>
      {isTransferPossible &&
        <Button
          onClick={() => onTransferRequest(item.id)}
          label="Заказать"
          style="bg_accent mr-1"
        />
      }
      <Button
        onClick={() => onDelete(item.id)}
        label="Удалить"
        style="bg_red"
      />
    </div>
  );
};

export default OrganizationItem;
