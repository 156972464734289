import { combineReducers } from "redux";
import {bookSupplyLoaderReducer} from "./loader/bookSupplyLoaderReducer";
import {bookSupplyListReducer} from "./list/bookSupplyListReducer";
import {bookSupplyBooksReducer} from "./books/bookSupplyBooksReducers";


export const bookSupplyReducer = combineReducers({
  list: bookSupplyListReducer,
  books: bookSupplyBooksReducer,
  loader: bookSupplyLoaderReducer,
});
