import { BOOK_TRANSFER_ADD_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const bookTransferAddRequestStart = ({ bookid, clientid }) => ({
  type: `${BOOK_TRANSFER_ADD_REQUEST}${START}`,
  payload: { bookid, clientid }
});

export const bookTransferAddRequestSuccess = (response) => {
  createNotification(
    'success',
    `Запрос успешно создан`
  );

  return {
    type: `${BOOK_TRANSFER_ADD_REQUEST}${SUCCESS}`,
    payload: response
  };
};

export const bookTransferAddRequestError = (error) => {
  createNotification(
    'error',
    error.response.data.result
  );

  return {
    type: `${BOOK_TRANSFER_ADD_REQUEST}${ERROR}`,
    payload: error
  };
};
