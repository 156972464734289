import { ERROR, RESERVE_BOOK_REQUEST, START, SUCCESS } from "../action-types";

export const reserveBookRequestStart = (bookId) => ({
  type: `${RESERVE_BOOK_REQUEST}${START}`,
  payload: bookId
});

export const reserveBookRequestSuccess = (data) => ({
  type: `${RESERVE_BOOK_REQUEST}${SUCCESS}`,
  payload: data
});

export const reserveBookRequestError = (data) => ({
  type: `${RESERVE_BOOK_REQUEST}${ERROR}`,
  payload: data
});