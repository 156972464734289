import { combineReducers } from "redux";
import { libraryPointReducer } from "./point/libraryPointReducer";
import { libraryFundReducer } from "./fund/libraryFundReducer";
import { libraryListReducer } from "./list/libraryListReducer";
import { librarySearchReducer } from "./search/librarySearchReducer";
import { libraryInstitutionsReducer } from "./institutions/libraryInstitutionsReducer";
import { libraryAddFundReducer } from "./add/funds";
import { libraryAddPointReducer } from "./add/points";

export const libraryInfoReducer = combineReducers({
  list: libraryListReducer,
  points: libraryPointReducer,
  funds: libraryFundReducer,
  search: librarySearchReducer,
  institutions: libraryInstitutionsReducer,
  addFunds: libraryAddFundReducer,
  addPoints: libraryAddPointReducer
});
