import { api } from '../../App';
import {SORT_DIRECTION} from "../../constants/sortDirections";

export const getMessageCount = (formData) => {
  const params = {
    count: true,
    ...formData
  };

  return api.get(`/message`, { params });
};

export const getMessageList = (formData, {maxResults, paginateFrom, sortDirection, orderBy}) => {
  const params = {
    maxResults,
    paginateFrom,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD'] : orderBy,
    ...formData
  };

  return api.get(`/message`, { params });
};

export const editMessage = (id) => {
  const params = JSON.stringify([id]);
  return api.put(`/message?messageId=${params}`);
};

export const deleteMessage = (id) => {
  const params = JSON.stringify([id]);
  return api.delete(`/message?messageId=${params}`);
};

export const getMessageNotificationsCount = () => {
  return api.get(`/message?notifications`);
};