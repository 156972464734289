import React, { useEffect, useState } from 'react'
import { securityLogCountRequestStart } from '../../redux/actions/adminLogs/securityLog/count'
import { setSecurityLogFormData } from '../../redux/actions/adminLogs/securityLog/formData'
import { setSecurityLogSearchData } from '../../redux/actions/adminLogs/securityLog/searchData'
import { connect } from 'react-redux'
import Pagination from '../../components/Pagination'
import SecurityLogTable from '../../components/SecurityLog/Table'
import SecurityLogForm from '../../components/SecurityLog/Form'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'

const mapStateToProps = ({ adminLogs }) => ({
  list: adminLogs.security.list,
  count: adminLogs.security.count,
  formData: adminLogs.security.form,
  searchData: adminLogs.security.search,
})

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(securityLogCountRequestStart()),
  setFormData: data => dispatch(setSecurityLogFormData(data)),
  setSearchData: data => dispatch(setSecurityLogSearchData(data)),
})

const SecurityAccessLogScene = ({ count, list, requestList, formData, setFormData, searchData, setSearchData }) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  const [dir, toggleDir] = useState(true)

  const setSort = sortBy => {
    const direction = dir ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
    setSearchData({ ...searchData, sortBy, sortDirection: direction })
    toggleDir(!dir)
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  const searchWithPageReset = () => {
    setSearchData({...searchData, page: 1})
    requestList()
  }

  return (
    <SceneContainer title={'Подсистема журналирования'}>
      <SecurityLogForm data={formData} setData={setFormData} search={searchWithPageReset} />

      <PaginationComponent />
      <SecurityLogTable data={list} sortBy={searchData.sortBy} setSort={setSort} />
      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityAccessLogScene)
