import React from 'react';
import T from 'prop-types';
import {ADD, EDIT} from "../../../constants/modalTypes";
import styles from "./InstitutionsTable.module.css";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import {Button, Space} from "antd";
import {INSTITUTIONS_SORT} from "../../../constants/sort/institutionsSort";

const InstitutionsTable = ({initialFormFields, data, setModal, sortBy, setSort, selectedId, handleSelect, onDelete}) => {
    const openEditModal = (item) => setModal({modalType: EDIT, data: item});
    const openCreationModal = () => setModal({modalType: ADD, data: initialFormFields});

    return (
        <div className={styles.container}>
            <Space className={'mb-3'}>
                <Button type="primary" onClick={openCreationModal}>
                    добавить
                </Button>
                <Button type="primary" onClick={onDelete} danger>
                    удалить
                </Button>
            </Space>
            <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openEditModal}>
                <DataTableCol sortParams={[INSTITUTIONS_SORT.NAME]} title="Название организации" value="name" colSpan={4}
                              className={styles.leftAlign}/>
                <DataTableCol sortParams={[INSTITUTIONS_SORT.ID]} title="ID" value="idclient" />
                <DataTableCol title="Регион" value="subject"/>
                <DataTableCol title="Книг на учёте" value="books"/>
                <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                    <input type="checkbox" checked={item.idclient === selectedId}
                           onChange={() => handleSelect(item.idclient)}/>}
                />
            </DataTable>
        </div>
    );
};

InstitutionsTable.propTypes = {
    data: T.array,
    setModal: T.func,
    sortBy: T.string,
    setSort: T.func,
    selectedId: T.any,
    handleSelect: T.func,
    onDelete: T.func
};

InstitutionsTable.defaultProps = {
    data: []
};

export default InstitutionsTable;
