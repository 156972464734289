import { ERROR, ELECTRONIC_COURSES_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const electronicCoursesListRequestStart = () => ({
    type: `${ELECTRONIC_COURSES_LIST_REQUEST}${START}`
});

export const electronicCoursesListRequestSuccess = (data) => ({
    type: `${ELECTRONIC_COURSES_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const electronicCoursesListRequestError = (data) => ({
    type: `${ELECTRONIC_COURSES_LIST_REQUEST}${ERROR}`,
    payload: data
});

