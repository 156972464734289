import { COLLECTION_GET_DATA_REQUEST, COLLECTION_RESET_DATA, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const collectionsDataReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${COLLECTION_GET_DATA_REQUEST}${SUCCESS}`:
      return action.payload;

    case COLLECTION_RESET_DATA:
      return null;

    default: return state;
  }
};