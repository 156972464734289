import {DOCUMENT_GET_BOOK, ERROR, START, SUCCESS} from "../../action-types";

export const getBookStart = (data) => ({
  type: `${DOCUMENT_GET_BOOK}${START}`,
  payload: data
});

export const getBookSuccess = (data) => ({
  type: `${DOCUMENT_GET_BOOK}${SUCCESS}`,
  payload: data
});

export const getBookError = (data) => ({
  type: `${DOCUMENT_GET_BOOK}${ERROR}`,
  payload: data
});