import { ERROR, HAVKINA_CALC_INDEX_REQUEST, START, SUCCESS } from "../action-types";

export const havkinaCalcIndexRequestStart = (data) => ({
  type: `${HAVKINA_CALC_INDEX_REQUEST}${START}`,
  payload: data
});

export const havkinaCalcIndexRequestSuccess = (data) => ({
  type: `${HAVKINA_CALC_INDEX_REQUEST}${SUCCESS}`,
  payload: data
});

export const havkinaCalcIndexRequestError = (data) => ({
  type: `${HAVKINA_CALC_INDEX_REQUEST}${ERROR}`,
  payload: data
});
