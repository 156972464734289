import { ERROR, BOOK_EXPORTS_DELETE, START, SUCCESS, RESET } from "../action-types";

export const bookExportsDeleteRequestStart = (itemId) => ({
  type: `${BOOK_EXPORTS_DELETE}${START}`,
  payload: itemId
});

export const bookExportsDeleteRequestSuccess = (data) => ({
  type: `${BOOK_EXPORTS_DELETE}${SUCCESS}`,
  payload: data
});

export const bookExportsDeleteRequestError = (data) => ({
  type: `${BOOK_EXPORTS_DELETE}${ERROR}`,
  payload: data
});

export const bookExportsDeleteRequestReset = () => ({
  type: `${BOOK_EXPORTS_DELETE}${RESET}`,
});
