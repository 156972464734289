import { call, put, takeLatest, select } from 'redux-saga/effects'
import { getAccessCategoryCount, getAccessCategoryList } from '../../../../api/accessCategory/list'
import { addAccessCategory } from '../../../../api/accessCategory/add'
import { editAccessCategory } from '../../../../api/accessCategory/edit'
import { groupEditAccessCategory } from '../../../../api/accessCategory/groupChange'
import {
  PERMISSIONS_BY_CATEGORY_ADD_REQUEST,
  PERMISSIONS_BY_CATEGORY_COUNT_REQUEST,
  PERMISSIONS_BY_CATEGORY_EDIT_REQUEST,
  PERMISSIONS_BY_CATEGORY_GROUP_EDIT,
  PERMISSIONS_BY_CATEGORY_REQUEST,
  START,
} from '../../../actions/action-types'
import {
  permissionsByCategoryCountRequestStart,
  permissionsByCategoryCountRequestSuccess,
  permissionsByCategoryCountRequestError,
} from '../../../actions/permissions/byCategories/count'
import {
  permissionsByCategoryRequestStart,
  permissionsByCategoryRequestSuccess,
  permissionsByCategoryRequestError,
} from '../../../actions/permissions/byCategories/list'
import { setPermissionsByCategorySearchData } from '../../../actions/permissions/byCategories/search'
import {
  permissionsByCategoryAddRequestSuccess,
  permissionsByCategoryAddRequestError,
} from '../../../actions/permissions/byCategories/add'
import {
  permissionsByCategoryEditRequestSuccess,
  permissionsByCategoryEditRequestError,
} from '../../../actions/permissions/byCategories/edit'
import { createNotification } from '../../../../components/notification/notification'

export default function* permissionsByCategoriesWatcher() {
  yield takeLatest(`${PERMISSIONS_BY_CATEGORY_COUNT_REQUEST}${START}`, permissionsByCategoriesCountWorker)
  yield takeLatest(`${PERMISSIONS_BY_CATEGORY_REQUEST}${START}`, permissionsByCategoriesListWorker)
  yield takeLatest(`${PERMISSIONS_BY_CATEGORY_ADD_REQUEST}${START}`, permissionsByCategoriesAddWorker)
  yield takeLatest(`${PERMISSIONS_BY_CATEGORY_EDIT_REQUEST}${START}`, permissionsByCategoriesEditWorker)
  yield takeLatest(`${PERMISSIONS_BY_CATEGORY_GROUP_EDIT}${START}`, permissionsByCategoriesGroupChangesWorker)
}

function* permissionsByCategoriesCountWorker() {
  const formData = yield select(({ accessCategories }) => accessCategories.form)
  const searchData = yield select(({ accessCategories }) => accessCategories.search)

  try {
    const response = yield call(getAccessCategoryCount, formData)
    const count = parseInt(response.data.count, 10)
    yield put(permissionsByCategoryCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setPermissionsByCategorySearchData({ ...searchData, pageCount }))
    yield put(permissionsByCategoryRequestStart())
  } catch (error) {
    yield put(permissionsByCategoryCountRequestError())
  }
}

function* permissionsByCategoriesListWorker() {
  const formData = yield select(({ accessCategories }) => accessCategories.form)
  const searchData = yield select(({ accessCategories }) => accessCategories.search)
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)

  try {
    const response = yield call(getAccessCategoryList, {
      ...formData,
      ...searchData,
    })

    yield put(permissionsByCategoryRequestSuccess(response.data))
  } catch (error) {
    yield put(permissionsByCategoryRequestError(error))
  }
}

function* permissionsByCategoriesAddWorker({ payload: { role, access, category } }) {
  const formRole = yield select(({ accessCategories }) => accessCategories.form.roleid)
  try {
    yield call(addAccessCategory, { role: role ? role : formRole, access, category })
    yield put(permissionsByCategoryAddRequestSuccess())
    yield put(permissionsByCategoryCountRequestStart())
  } catch (error) {
    yield put(permissionsByCategoryAddRequestError())
  }
}

function* permissionsByCategoriesEditWorker({ payload: { role, access, category, categoryId } }) {


  try {
    yield call(editAccessCategory, { role, access, category, categoryId })
    yield put(permissionsByCategoryEditRequestSuccess())
    yield put(permissionsByCategoryCountRequestStart())
  } catch (error) {
    yield put(permissionsByCategoryEditRequestError())
  }
}


function* permissionsByCategoriesGroupChangesWorker ({payload: {access}}) {
  try {
    yield call(groupEditAccessCategory, {access})
    yield put(permissionsByCategoryCountRequestStart())
    createNotification('success', 'Успешное выполнение группового изменения прав')
  } catch (error) {
    createNotification('error', 'Ошибка группового изменения прав')
  }
}