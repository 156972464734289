import { BOOK_TRANSFER_DELETE_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const bookTransferDeleteRequestStart = (itemId) => ({
  type: `${BOOK_TRANSFER_DELETE_REQUEST}${START}`,
  payload: itemId
});

export const bookTransferDeleteRequestSuccess = () => {
  createNotification(
    'success',
    `Успешно удалено`
  );

  return {
    type: `${BOOK_TRANSFER_DELETE_REQUEST}${SUCCESS}`
  }
};

export const bookTransferDeleteRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка удаления. Статус: ${error.response.status}`
  );

  return {
    type: `${BOOK_TRANSFER_DELETE_REQUEST}${ERROR}`,
    payload: error
  };
};
