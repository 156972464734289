import { combineReducers } from "redux";
import { documentCountReducer } from "../booksOrder/count/documentCountReducer";
import { booksOrderListReducer } from "./list/booksOrderListReducer";
import { booksOrderSearchReducer } from "./search/documentSearchReducer";
import { booksOrderBooksReducer } from "./books/booksOrderBooksReducer";
import { documentNumberReducer } from "../documents/number/documentNumberReducer";
import { booksOrderLatestReducer } from "./latest/booksOrderLatestReducer";

export const booksOrderReducer = combineReducers({
  count: documentCountReducer,
  list: booksOrderListReducer,
  search: booksOrderSearchReducer,
  books: booksOrderBooksReducer,
  number: documentNumberReducer,
  latest: booksOrderLatestReducer,
});
