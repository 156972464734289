import { api } from "../../App";

export const getDocumentInstancesList = (documentId) => {
    return api.get(`/instance2document/${documentId}`);
}

export const addInstanceToDocument = (data) => {
    return api.post(`/instance2document/${data.documentId}`, data);
}

export const deleteInstanceToDocument = (id) => {
    return api.delete(`/instance2document/${id}`);
};
