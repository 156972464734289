import { createNotification } from "../../../components/notification/notification";
import { DISCIPLINE_DELETE_START, ERROR, START, SUCCESS } from "../action-types";

export const disciplineDeleteRequestStart = (id) => ({
    type: `${DISCIPLINE_DELETE_START}${START}`,
    payload: id
});

export const disciplineDeleteRequestSuccess = (data) => ({
    type: `${DISCIPLINE_DELETE_START}${SUCCESS}`,
    payload: data
});

export const disciplineDeleteRequestError = (data) => {
    const { result } = data.response?.data
    createNotification('error', result || 'Ошибка удаления дисциплины')
    return {
        type: `${DISCIPLINE_DELETE_START}${ERROR}`,
        payload: data
    }
}
