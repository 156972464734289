import { BOOK_SUPPLY_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const bookSupplyListRequestStart = (periodId) => ({
  type: `${BOOK_SUPPLY_LIST_REQUEST}${START}`,
  payload: periodId
});

export const bookSupplyListRequestSuccess = (data) => ({
  type: `${BOOK_SUPPLY_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookSupplyListRequestError = (data) => ({
  type: `${BOOK_SUPPLY_LIST_REQUEST}${ERROR}`,
  payload: data
});

