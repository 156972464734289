import React, {useEffect, useRef, useState} from 'react';
import T from 'prop-types';
import styles from './MediaResourceEditorListItem.module.css'
import {Col, OverlayTrigger, Row} from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import Button from "../../../Button";
import ModalImagePopover from "../../../Modal/ImagePopover";
import { resolveMediaResLink } from "../../../../utils/resolveMediaResLink";

const emptyExternalResource = {
  resourcename: "",
  resourceurl: "",
  logoexist: false,
  logo: null
};

const MediaResourceEditorListItem = ({ data, deleteItem, editItem, deleteLogo, onUploadLogo }) => {

  const [localData, setLocalData] = useState(emptyExternalResource);
  const imageInput = useRef(null);

  useEffect(() => setLocalData(data || emptyExternalResource), [data]);

  const onChange = ({ target }) => setLocalData({ ...localData, [target.name]: target.value });

  const onSaveImage = () => {
    imageInput.current.click();
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col md={"auto"}>
          <div className={styles.logoOuter}>
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={props => (
                <ModalImagePopover
                  deleteImage={() => deleteLogo(data.id)}
                  uploadImage={onSaveImage}
                  {...props}
                />
              )}
            >
              {localData.logoexist ? (
                <img src={resolveMediaResLink(localData.logo)} alt={localData.resourcename} />
              ) : (
                <div className={styles.logoPlaceholder}/>
              )}
            </OverlayTrigger>
            <input
              type="file"
              ref={imageInput}
              className={'d-none'}
              onChange={event => onUploadLogo(data.id, event.target.files[0])}
              accept="image/*"
            />
          </div>
        </Col>
        <Col>
          <ModalInputWrapper
            label={"Название ресурса"}
            name="resourcename"
            value={localData.resourcename}
            onChange={onChange}
          />
        </Col>
        <Col>
          <ModalInputWrapper
            label={"Адрес ресурса"}
            name="resourceurl"
            value={localData.resourceurl}
            onChange={onChange}
          />
        </Col>
        <Col md={'auto'}>
          <div>
            <ModalInputWrapper label={''}>
              <Button label="Сохранить"
                      onClick={() => editItem(data.id, localData)}/>
            </ModalInputWrapper>
          </div>
        </Col>
        <Col md={'auto'}>
          <ModalInputWrapper label={''}>
            <Button label="Удалить"
                    colorType={'red'}
                    onClick={() => deleteItem(localData.id)}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

MediaResourceEditorListItem.propTypes = {
  data: T.object.isRequired,
  deleteItem: T.func,
  editItem: T.func,
  onUploadLogo: T.func
};

export default MediaResourceEditorListItem;