import React, { useEffect } from 'react'
import T from 'prop-types'
import ModalInputWrapper from '../../Modal/InputWrapper'
import CoversSearchFormControls from '../../Covers/SearchFormControls'

const ReadingSmallSearch = ({ data, setData, onSubmit }) => {
  const onSubmitHandler = async () => {
    await setData({
      title: data.title,
      idclient: '',
    })
    onSubmit()
  }

  const onChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const clearForm = () => {
    setData({ ...data, title: '' })
  }

  return (
    <div>
      <ModalInputWrapper onChange={onChange} onKeyPress={onKeyPress} name='title' value={data.title} label='Поиск' />
      <CoversSearchFormControls onSubmit={onSubmitHandler} onClear={clearForm} />
    </div>
  )
}

ReadingSmallSearch.propTypes = {
  setData: T.func,
  data: T.object,
}

export default ReadingSmallSearch
