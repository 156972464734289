import { api } from '../../App';

export const getShelvesList = (rackId) => {
  return api.get(`/location/shelf/${rackId}`);
};

export const addShelf = (rackId, data) => {
  return api.post(`/location/shelf/${rackId}`, data);
};

export const editShelf = (rackId, data) => {
  return api.put(`/location/shelf/${rackId}/${data.id}`, data);
};

export const deleteShelf = (rackId, shelfId) => {
  return api.delete(`/location/shelf/${rackId}/${shelfId}`);
};