import { createNotification } from "../../../components/notification/notification";
import { ERROR, LOST_DOC_ADD_REQUEST, START, SUCCESS } from "../action-types";

export const addLostDocStart = (payload) => ({
  type: `${LOST_DOC_ADD_REQUEST}${START}`,
  payload,
});

export const addLostDocSuccess = (payload) => {
  createNotification('success', 'Библиографическая запись успешно создана');
  return {
    type: `${LOST_DOC_ADD_REQUEST}${SUCCESS}`,
    payload
  }
};

export const addLostDocError = ({formData}) => ({
  type: `${LOST_DOC_ADD_REQUEST}${ERROR}`,
  payload: {formData}
});