import { combineReducers } from "redux";
import { rolesListReducer } from "./list/rolesListReducer";
import { roleMembersReducer } from "./members/roleMembersReducer";
import { roleNewMembersReducer } from "./newMembers/roleNewMembersReducer";

export const rolesReducer = combineReducers({
  list: rolesListReducer,
  members: roleMembersReducer,
  newMembers: roleNewMembersReducer
});
