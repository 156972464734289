import { ERROR, SHELF_DELETE_REQUEST, START, SUCCESS } from "../../action-types";

export const shelfDeleteRequestStart = (rackId, shelfId) => ({
  type: `${SHELF_DELETE_REQUEST}${START}`,
  payload: { rackId, shelfId }
});

export const shelfDeleteRequestSuccess = (data) => ({
  type: `${SHELF_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const shelfDeleteRequestError = (data) => ({
  type: `${SHELF_DELETE_REQUEST}${ERROR}`,
  payload: data
});
