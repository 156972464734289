import { ERROR, BOOK_SOURCE_REQUEST, START, SUCCESS } from "../action-types";

export const bookSourceRequestStart = () => ({
    type: `${BOOK_SOURCE_REQUEST}${START}`
});

export const bookSourceRequestSuccess = (data) => ({
    type: `${BOOK_SOURCE_REQUEST}${SUCCESS}`,
    payload: data
});

export const bookSourceRequestError = (error) => ({
    type: `${BOOK_SOURCE_REQUEST}${ERROR}`,
    payload: error
});


