import React from 'react'
import { Table } from 'antd'
import styles from './styles.module.css'

export default function LibrariesTable({ data, libClickHandler }) {
  
  const onRowClick = (record, index) => ({ onClick: () => libClickHandler(record.id) })
  
  return (
    <Table
      dataSource={data}
      title={false}
      bordered={false}
      pagination={false}
      locale={{ emptyText: 'Нет данных' }}
      onRow={onRowClick}
      className={styles.table}
    >
        <Table.Column title='Название' dataIndex='name' />
        <Table.Column title='Номер' dataIndex='contract_number' />
        <Table.Column title='Дата создания' dataIndex='created_at' />
    </Table>
  )
}
