import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import styles from './StaticCollectionForm.module.css';
import {collectionGetDataRequestStart} from "../../../redux/actions/collections/data/getData";
import {connect} from "react-redux";
import {COLLECTION_TYPE} from "../../../constants/collectionTypes";
import {collectionSaveDataRequestStart} from "../../../redux/actions/collections/data/saveData";
import {collectionDeleteDataRequestStart} from "../../../redux/actions/collections/data/deleteData";
import StaticCollectionFormItem from "./Item";
import BookSearchForm from "../../Covers/SearchForm";
import {setCoversFormData} from "../../../redux/actions/covers/form";
import Button from "../../Button";
import {coversCountRequestStart} from "../../../redux/actions/covers/count";
import {Col, Row} from "react-bootstrap";
import Pagination from "../../Pagination";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import {setCoversSearchData} from "../../../redux/actions/covers/search";

const mapStateToProps = (state) => ({
    data: state.collections.data,
    formData: state.covers.form,
    covers: state.covers,
    searchResults: state.covers.list,
    searchData: state.covers.search,
    loader: state.covers.loader
});

const mapDispatchToProps = {
    requestData: collectionGetDataRequestStart,
    addData: collectionSaveDataRequestStart,
    deleteData: collectionDeleteDataRequestStart,
    setFormData: setCoversFormData,
    search: coversCountRequestStart,
    setSearchData: setCoversSearchData,
    requestList: coversCountRequestStart
};

const StaticCollectionForm = ({
                                  collectionId, data, requestData, addData, deleteData, formData,
                                  setFormData, search, searchResults, searchData, loader, covers,
                                  setSearchData, requestList
                              }) => {
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        !data && requestData(collectionId, COLLECTION_TYPE.STATIC)
    }, [data, collectionId, requestData]);

    useEffect(() => setShowForm(false), [data]);

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList()
    };

    const PaginationComponent = () => (
        <Pagination count={covers.count} pageCount={searchData.pageCount} current={searchData.page}
                    setCurrent={setCurrentPage}/>
    );

    const onSave = (data) => {
        addData(collectionId, data, COLLECTION_TYPE.STATIC);
    };

    const onDelete = (dataId) => {
        deleteData(collectionId, dataId, COLLECTION_TYPE.STATIC);
    };

    return (
        <div className={styles.container}>
            <Button label={showForm ? "Скрыть" : "Поиск"} onClick={() => setShowForm(!showForm)}/>
            {showForm &&
            <div className={styles.searchContainer}>
                <BookSearchForm data={formData} setData={setFormData} disableDefaultPanel={true}>
                    <Row>
                        <Col md={{span: 3, offset: 9}} className={"text-right"}>
                            <Button onClick={() => search()} label={"Найти"}/>
                        </Col>
                    </Row>
                </BookSearchForm>
                <div className={styles.sectionTitle}>
                    Результаты:
                </div>
                <div className={styles.searchResultsContainer}>
                    <PaginationComponent/>
                    {loader ?
                        <SpinnerOuter/> :
                        searchResults.map((item, index) =>
                            <StaticCollectionFormItem data={item} key={index} add={true}
                                                      onClick={() => onSave(item.bookid)}
                            />
                        )
                    }
                    <PaginationComponent/>
                </div>
            </div>}
            <div className={styles.sectionTitle}>
                Книги в коллекции:
            </div>
            {data && data.map((item, index) => (
                <StaticCollectionFormItem data={item} key={index} onClick={() => onDelete(item.bookid)}/>
            ))}
        </div>
    );
};

StaticCollectionForm.propTypes = {
    collectionId: T.number
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticCollectionForm);
