import { combineReducers } from "redux";
import { outsecrecyCountReducer } from "./count/outsecrecyCountReducer";
import { outsecrecyListReducer } from "./list/outsecrecyListReducer";
import { outsecrecySearchDataReducer } from "./search/outsecrecySearchDataReducer";

export const secrecyReducer = combineReducers({
  count: outsecrecyCountReducer,
  list: outsecrecyListReducer,
  search: outsecrecySearchDataReducer
});
