import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import {Checkbox} from "antd";
import moment from "moment";

const ExemptTestsTable = ({data, onCellClick, selectedValue, selectItem}) => {

    return (
        <DataTable data={data} onCellClick={onCellClick}>
            <DataTableCol title="Название теста" value="title"/>
            <DataTableCol title="Дата изъятия" resolver={({date}) =>
                moment(date).format('DD.MM.YYYY')
            }/>
            <DataTableCol title="Причина изъятия" value="reason"/>
            <DataTableCol title="Выбрано" resolver={({id}) =>
                <Checkbox checked={selectedValue === id} onChange={() => selectItem(id)}/>
            }/>
        </DataTable>
    );
};

ExemptTestsTable.propTypes = {
    data: PropTypes.array,
    onCellClick: PropTypes.func,
};

export default ExemptTestsTable;