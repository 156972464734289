import {
    INVENTARY_BOOK_LOAD_REQUEST,
    SUCCESS,
    ERROR,
    START,
    BOOKINVENTORY_COUNT_REQUEST
} from "../../../actions/action-types";

const initialState = false;

export const inventaryBookLoaderReducer = (state = initialState, action) => {

    switch (action.type) {
        case `${BOOKINVENTORY_COUNT_REQUEST}${START}`:
            return true;

        case `${INVENTARY_BOOK_LOAD_REQUEST}${SUCCESS}`:
            return false;

        case `${INVENTARY_BOOK_LOAD_REQUEST}${ERROR}`:
            return false;

        default: return state;
    }
};
