import { combineReducers } from "redux";
import { reportReducer, reportAllReducer} from "./aggregation/reportReducer";
import { reportsListReducer } from "./list/reportsListReducer";
import {reportsLoaderReducer, reportsAllLoaderReducer} from "./loader/reportLoaderReducer";
import { reportsSearchReducer } from "./search/reportSearchReducer";
import { elUsageFormDataReducer } from "./formData/electUsageFormDataReducer";

export const reportsReducer = combineReducers({
  report: reportReducer,
  reportAll: reportAllReducer, 
  list: reportsListReducer,
  loader: reportsLoaderReducer,
  loaderAll: reportsAllLoaderReducer,
  search: reportsSearchReducer,
  form: elUsageFormDataReducer,
});
