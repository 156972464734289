import { api } from "../../App";

export const getLayoutOptions = () => {
  return api.get(`options/css`);
};

export const putLayoutOptions = (data) => {
  return api.put(`options/css`, data);
};

export const getHeaderImageList = () => {
  return api.get(`options/css/images`);
};
