import React, {useState} from "react";
import {Modal} from 'react-bootstrap'

const ExternalResourcesEditModal = () => {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    return(
        <Modal show={show} onHide={handleClose} centered size="sm">
            <Modal.Header closeButton style={{borderRadius: "5px"}}>
                Сохранено
            </Modal.Header>
        </Modal>
    );
}

export default ExternalResourcesEditModal;