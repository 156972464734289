import jsPDF from 'jspdf'
import addFont from '../../../../fonts/fonts'



export const createNoteDoc = async () => {
  
  const orientation = "portrait";

  addFont(jsPDF.API);
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation });
  doc.setFont("Montserrat");
  
  doc.setFontSize(13);


  doc.text('Войсковая часть (подразделение) ______________________________________', 20, 40)
  doc.text('__________________________________________________________________________', 20, 45)
  
  doc.text('Командир войсковой части (подразделения) __________________________', 20, 90)
  doc.text('__________________________________________________________________________', 20, 95)
  
  doc.text('Работник передвижной библиотеки ___________________________________', 20, 140)
  doc.text('__________________________________________________________________________', 20, 145)
  
  doc.text('Дни и часы работы передвижной библиотеки _________________________', 20, 190)
  doc.text('__________________________________________________________________________', 20, 195)
  
  

  doc.addPage("a4", orientation);
  
  doc.autoTable({
    html: '#noteDocTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    columnStyles: {
      // 2: { cellWidth: 70 },
    },
    
    startY: 20
  })
  
  doc.addPage("a4", orientation);
  doc.deletePage(doc.getNumberOfPages());
  return doc;
};
