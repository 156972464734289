import { call, put, takeLatest, select } from 'redux-saga/effects'
import { START, DIRECTORY_SAVE_REQUEST, DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING } from '../../../actions/action-types'
import { ADD, EDIT } from '../../../../constants/modalTypes'
import { addDirectory, editDirectory } from '../../../../api/adminUsers/users'
import { directoryListRequestStart } from "../../../actions/adminDirectory/list";
import { directorySaveRequestError, directorySaveRequestSuccess } from '../../../actions/adminDirectory/save'
import { createNotification } from '../../../../components/notification/notification'
import { DUPLICATE_USER_FOUND } from '../../../../constants/peopleErrorText'

export default function* saveDirectoryWatcher() {
  yield takeLatest(`${DIRECTORY_SAVE_REQUEST}${START}`, saveDirectoryWorker)
}

function* saveDirectoryWorker({ payload }, callBack) {
  const { modalType: type, data, cb } = payload
  try {
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
    const response = yield call(type === ADD ? addDirectory : editDirectory, data)
    yield put(directorySaveRequestSuccess(response.data))
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })

    yield call(createNotification, 'success', 'Справочник успешно сохранен')
    yield put(directoryListRequestStart());
  } catch (error) {
    yield put(directorySaveRequestError(error))
    yield put({ type: DIRECTORY_SAVE_REQUEST_TOGGLE_LOADING })
    yield call(createNotification, 'error', error.response.data.result)
  }
}
