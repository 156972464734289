import {DISCIPLINE_FORM_REQUEST} from "../../../actions/action-types";

const initialState = {
    name: ""
};

export const disciplineFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISCIPLINE_FORM_REQUEST:
            return { ...action.payload }

        default:
            return state;
    }
}