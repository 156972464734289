import {
  BOOK_SCAN_ORDER_COUNT_REQUEST,
  SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const bookScanOrdersCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_SCAN_ORDER_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};