import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";
import moment from "moment";
import {Col, Row} from "react-bootstrap";
import Chart from 'chart.js';
import {saveAs} from 'file-saver';
import PDFPrint from "../../../../components/PDFPrint";
import {Popover, Button, Space} from 'antd';


const ElectronicResourcesUsageTable = ({
                                           data,
                                           dataAll
                                       }) => {


    const [visible, setVisible] = useState(false);

    const handleVisibleChange = () => {
        setVisible(!visible);
    }

    let reportPieChart, canvas;
    let dataArray = data;

    useEffect(() => {
        if (!dataArray || dataArray.length == 0) return;
        reportPieChart = new Chart(canvas.getContext('2d'), {
            type: "bar",
            data: {
                datasets: [{
                    data: [dataArray[0].bookcount, dataArray[0].requestcount],
                    backgroundColor: ['rgb(15, 72, 217)', 'rgb(217, 15, 96)'],
                    label: 'Соотношение количества запросов к книгам'
                }],
                labels: ["Количество книг", "Количество запросов"],
                maintainAspectRatio: false
            }
        });
    }, [dataArray]);

    const [open, setOpen] = useState(false);

    const OBJtoXML = (obj) => {
        var xml = '';
        for (var prop in obj) {
            xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
            if (obj[prop] instanceof Array) {
                for (var array in obj[prop]) {
                    xml += "<" + prop + ">";
                    xml += OBJtoXML(new Object(obj[prop][array]));
                    xml += "</" + prop + ">";
                }
            } else if (typeof obj[prop] == "object") {
                xml += OBJtoXML(new Object(obj[prop]));
            } else {
                xml += obj[prop];
            }
            xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
        }
        return xml.replace(/<\/?[0-9]{1,}>/g, '');
    };

    const downloadFile = (format) => {
        if (!dataAll) return;
        if (format === 'json') {
            let fileData = JSON.stringify(dataAll);
            const blob = new Blob([fileData], {
                type: 'application/json'
            });
            saveAs(blob, `Статистика использования электронных ресурсов.${format}`)
        }
        if (format === 'xml') {
            let fileData = OBJtoXML(dataAll);
            const blob = new Blob([fileData], {
                type: 'application/xml;charset=utf-8'
            });
            saveAs(blob, `Статистика использования электронных ресурсов.${format}`)
        }
    };


    return (
        <div>
            <Row>
                <Space className={'d-flex justify-content-end w-100'}>
                    <Popover
                        content={<>
                            <div className={'d-flex flex-column align-items-center'}>
                                <span className={'pointer'} onClick={() => downloadFile('xls')}>XLS</span>
                                <span className={'pointer'} onClick={() => downloadFile('json')}>JSON</span>
                                <span className={'pointer'} onClick={() => downloadFile('xml')}>XML</span>
                            </div>
                        </>}
                        title={false}
                        trigger="click"
                        placement="bottom"
                        onVisibleChange={handleVisibleChange}
                    >
                        <Button type="primary">Экспорт</Button>
                    </Popover>
                    <PDFPrint
                        tableData={dataAll}
                        headers={[
                            {
                                title: 'Дата',
                                value: item => moment(item.eventtime).format('hh:mm:ss DD.MM.YYYY'),
                            },
                            {
                                title: 'Время изучения документа',
                                value: item => ((item.worktime.hours ? item.worktime.hours : 0) * 60 + (item.worktime.minutes ? item.worktime.minutes : 0) + ' мин.')
                            },
                            {
                                title: 'Документ',
                                value: 'title'
                            },
                            {
                                title: 'ФИО',
                                value: item => `${item.lastname} ${item.firstname} ${item.patronymic}`,
                            },
                        ]}
                        title={'Статистика использования электронных ресурсов'}
                        orientation={"landscape"}
                    />
                </Space>
            </Row>
            <Row>
                <Col sm={12}>
                    <DataTable data={data}>
                        <DataTableCol
                            title={"Дата"}
                            resolver={item => moment(item.eventtime).format('hh:mm:ss DD.MM.YYYY')}
                            rowWidth={"10%"}/>
                        <DataTableCol
                            title="Время изучения документа"
                            resolver={item => (
                                <span>
                                    {item.worktime && (item.worktime.hours ? item.worktime.hours : 0) * 60 + (item.worktime.minutes ? item.worktime.minutes : 0)} мин.
                                </span>
                            )}
                            rowWidth={"10%"}
                        />
                        <DataTableCol
                            title={"Документ"}
                            resolver={item => (
                                <>
                                    <div><strong>{item.title}</strong></div>
                                    <span>{item.authors && item.authors.map(author => author.name)}</span>
                                </>
                            )}
                            rowWidth={"30%"}
                        />
                        <DataTableCol
                            title={"Причина отказа"}
                            resolver={item => item.accessdenied ? item.accessdenied : "Не указано"}
                            rowWidth={"20%"}
                        />
                        <DataTableCol
                            title={"ФИО"}
                            resolver={item => `${item.lastname} ${item.firstname} ${item.patronymic}`}
                            rowWidth={"20%"}
                        />
                    </DataTable>
                </Col>
                <Col sm={4}>
                    <div>
                        <canvas
                            ref={el => canvas = el}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

ElectronicResourcesUsageTable.propTypes = {
    data: T.array.isRequired,
    dataAll: T.array.isRequired
};

export default ElectronicResourcesUsageTable;