import { TESTS_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const testsCountRequestStart = (data) => ({
    type: `${TESTS_COUNT_REQUEST}${START}`,
    payload: data
});

export const testsCountRequestSuccess = (data) => ({
    type: `${TESTS_COUNT_REQUEST}${SUCCESS}`,
    payload: data
});

export const testsCountRequestError = (data) => ({
    type: `${TESTS_COUNT_REQUEST}${ERROR}`,
    payload: data
});
