import { DOCUMENT_BOOK_ORDER_SAVE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import { createNotification } from '../../../../components/notification/notification';

export const documentSaveRequestStart = (data) => ({
    type: `${DOCUMENT_BOOK_ORDER_SAVE_REQUEST}${START}`,
    payload: data
});

export const documentSaveRequestSuccess = (data) => ({
    type: `${DOCUMENT_BOOK_ORDER_SAVE_REQUEST}${SUCCESS}`,
    payload: data
});

export const documentSaveRequestError = (data) => {
    createNotification('error', 'Не удалось сохранить документ');
    return ({
        type: `${DOCUMENT_BOOK_ORDER_SAVE_REQUEST}${ERROR}`,
        payload: data
    })
};
