import React, { useState, useEffect } from 'react'
import BookSearchForm from '../../../components/Covers/SearchForm'
import { coversCountRequestStart } from '../../../redux/actions/covers/count'
import { setCoversFormData } from '../../../redux/actions/covers/form'
import { setCoversSearchData } from '../../../redux/actions/covers/search'
import { exportBooksRequestStart, exportBooksClearReducer } from '../../../redux/actions/bookExport/exportBooks'
import Pagination from '../../../components/Pagination'
import BooksList from '../../../components/Books/List'
import { connect } from 'react-redux'
import { optionsGetRequestStart } from '../../../redux/actions/options/get'
import { api } from '../../../App'
import { createNotification } from '../../../components/notification/notification'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import BookBatchEditingModal from '../../../components/Books/Modal/BatchEditing'
import { outsecrecyCountRequestStart } from '../../../redux/actions/outsecrecy/count'
import { coversBatchEditingRequestStart } from '../../../redux/actions/covers/batchEditing'

const mapStateToProps = ({ covers, options, booksExport, outsecrecy }) => ({
  list: covers.list,
  count: covers.count,
  formData: covers.form,
  searchData: covers.search,
  loader: covers.loader,
  options: options.data,
  exports: booksExport.export,
  outsecrecyList: outsecrecy.list,
  loadSuccess: covers.loadSuccess,
})

const mapDispatchToProps = {
  requestOptions: optionsGetRequestStart,
  requestList: coversCountRequestStart,
  setFormData: setCoversFormData,
  setSearchData: setCoversSearchData,
  exportBooks: exportBooksRequestStart,
  exportBooksClearReducer: exportBooksClearReducer,
  requestOutsecrecy: outsecrecyCountRequestStart,
  startBatchEdititng: coversBatchEditingRequestStart,
}

const BooksScene = ({
  count,
  loader,
  list,
  requestList,
  formData,
  setFormData,
  searchData,
  setSearchData,
  requestOptions,
  options,
  history,
  exportBooks,
  exports,
  exportBooksClearReducer,
  requestOutsecrecy,
  outsecrecyList,
  startBatchEdititng,
  loadSuccess,
}) => {
  useEffect(() => {
    requestOutsecrecy()
  }, [requestOutsecrecy])

  useEffect(() => {
    requestOptions()
  }, [requestOptions])

  useEffect(() => {
    if (options) {
      setFormData({ ...formData, idclient: options.own_client_id })
    }
  }, [options])

  const [isBatchModalOpen, setBatchModalOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [batchModalData, setBatchModalData] = useState({
    bookId: [],
    changes: [],
  })

  const handleSelect = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds([...selectedIds].filter(item => item !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  const updateChangesOptions = (name, value) => {
    let updatedChanges = [...batchModalData.changes]

    updatedChanges.forEach((item, index) => {
      if (item.hasOwnProperty(name)) {
        updatedChanges.splice(index, 1)
      }
    })

    updatedChanges.push({ [name]: value })

    setBatchModalData({
      bookId: [...selectedIds],
      changes: updatedChanges,
    })
  }

  const onSubmitModal = () => {
    startBatchEdititng(batchModalData)
    setBatchModalOpen(false)
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const onCreateNewBook = async () => {
    const params = {
      title: '',
      publisher: '',
      year: 0,
    }
    const response = await api.post('/book', params)
    await api.put(`/book/${response.data.book.id}`, { clients: [options.own_client_id] })
    history.push(`/book/${response.data.book.id}/edit?newbook`)
  }

  const onExportBooks = () => {
    if (!selectedIds.length) {
      return createNotification('warning', 'Вы ничего не добавили в список выгрузки')
    }
    exportBooks(selectedIds)
  }

  useEffect(() => {
    if (exports.isCompleted) {
      createNotification('success', 'Задание на выгрузку успешно создано')
      exportBooksClearReducer()
      setSelectedIds([])
    }
    if (exports.isError) {
      createNotification('error', 'Не удалось создать задание на выгрузку')
      exportBooksClearReducer()
    }
  }, [exports])

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  return (
    <SceneContainer title={'Справочники / Библиографические записи'}>
      <BookSearchForm data={formData} setData={setFormData} onSubmit={requestList} />
      <BookBatchEditingModal
        isOpen={isBatchModalOpen}
        setClosed={() => setBatchModalOpen(false)}
        modalData={batchModalData}
        outsecrecyList={outsecrecyList}
        updateChangesOptions={updateChangesOptions}
        onSubmit={onSubmitModal}
      />
      <PaginationComponent />
      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        <BooksList
          data={list}
          handleSelect={handleSelect}
          selectedIds={selectedIds}
          termOfUse={options ? options.termofuseedubook : undefined}
          createBook={onCreateNewBook}
          onExportBooks={onExportBooks}
          setBatchModalOpen={setBatchModalOpen}
          loadSuccess={loadSuccess}
          history={history}
          count={count}
        />
      )}
      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BooksScene)
