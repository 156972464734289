import React from 'react';
import T from 'prop-types';
import PdfPrintPanel from "../../../PDFPrint/Panel";
import addFont from "../../../../fonts/fonts";
import jsPDF from "jspdf";

const titlesList = [
    "Статистические показатели библиотечного фонда, каталогов, картотек, справочно-библиографических данных",
    "Статистические показатели сканирования и оцифровки учебных (научных) материалов, текстовых документов/книг",
    "Статистические показатели разработки ЭОР"
]

const AggregationPDFPrint = ({ title, data, searchData, tableRows }) => {

    const orientation = 'landscape';
    const leftIndent = 15;

    addFont(jsPDF.API);
    const doc = new jsPDF({putOnlyUsedFonts: true, orientation: orientation});
    doc.setFont("Montserrat");

    doc.text(title, leftIndent, 15);

    doc.text(`Организация: ${searchData.client}`, leftIndent, 30);
    doc.text(`Период: c ${searchData.from} по ${searchData.to}`, leftIndent, 40);


    data.forEach(organization => {
        doc.autoTable({
            head: [['Название организации', organization.clientname]],
            body: tableRows.map(({label, value}) => {
                const rowLabel = {content: label, colSpan: value ? 1 : 2}
                const rowValue = organization[value]
                return [rowLabel, rowValue]
            }),
            styles: {font: "Montserrat", fontSize: 10},
            showHead: 'firstPage',
            margin: {
                top: 45
            },
            headStyles: {
                halign: "center",
                valign: "middle",
                lineWidth: 0.25,
                lineColor: 200
            },
            bodyStyles: {
                lineWidth: 0.25,
                lineColor: 200
            },
            willDrawCell: (row) => {
                if (titlesList.includes(row.cell.raw?.content)) {
                    doc.setFont(undefined, 'bold')
                }
            }
        });
    });

    const toPdf = () => {
        doc.save(`${title}.pdf`);
    };

    const toPrint = () => {
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    };

    return (
        <div className={'d-flex justify-content-end'}>
            <PdfPrintPanel loadPdf={toPdf} printPdf={toPrint}/>
        </div>
    );
};

AggregationPDFPrint.propTypes = {
    title: T.string,
    data: T.arrayOf(T.object),
    searchData: T.object
};

export default AggregationPDFPrint;
