import {
    ALPHABET_SINGLE_ITEM_REQUEST,
    ALPHABET_SINGLE_ITEM_SET_POSITION,
    ERROR,
    START,
    SUCCESS
} from "../../action-types";

export const alphabetSingleItemRequestStart = (amount) => ({
    type: `${ALPHABET_SINGLE_ITEM_REQUEST}${START}`,
    payload: amount
});

export const alphabetSingleItemRequestSuccess = (data) => ({
    type: `${ALPHABET_SINGLE_ITEM_REQUEST}${SUCCESS}`,
    payload: data
});

export const alphabetSingleItemRequestError = (error) => ({
    type: `${ALPHABET_SINGLE_ITEM_REQUEST}${ERROR}`,
    payload: error
});

export const alphabetSingleItemSetPosition = (position) => ({
    type: ALPHABET_SINGLE_ITEM_SET_POSITION,
    payload: position
});

