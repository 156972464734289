import { MESSAGE_NOTIFY_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const messageNotificationRequestStart = () => ({
  type: `${MESSAGE_NOTIFY_REQUEST}${START}`
});

export const messageNotificationRequestSuccess = (data) => ({
  type: `${MESSAGE_NOTIFY_REQUEST}${SUCCESS}`,
  payload: data
});

export const messageNotificationRequestError = (error) => ({
  type: `${MESSAGE_NOTIFY_REQUEST}${ERROR}`,
  payload: error
});
