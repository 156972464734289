import React, { useState } from 'react'
import styles from './BookEditIndexTab.module.css'
import ScanImage from './BookScanImage'
import classNames from 'classnames'
import ChooseImage from '../../../ChooseFile'
import photoPlaceholder from '../../../../img/noCover.png'
import { getBase64 } from '../../../../utils/helpers/helpers'
import { Button, Space } from 'antd'

const BookCover = ({ bookId, coverUrl, onUpload, onClean, coverLoading }) => {
  const [image, setImage] = useState(coverUrl)

  const uploadHandler = file => {
    getBase64(file).then(base64 => setImage(base64))
    onUpload({
      file,
      bookId,
    })
  }

  const handleClean = () => {
    onClean({ bookId })
    setImage('')
  }

  return (
    <div className={styles.scansContainer}>
      <div className={styles.scanImageContainer}>
        {coverLoading ? (
          <div>изображение загружается</div>
        ) : (
          <ScanImage url={image} style={styles.scanImage} notFoundImage={photoPlaceholder} />
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <Space>
          <ChooseImage onChange={uploadHandler}>
            <Button type='primary'>Загрузить</Button>
          </ChooseImage>
          {onClean && (
            <Button type='primary' onClick={handleClean} danger>
              Очистить
            </Button>
          )}
        </Space>
      </div>
    </div>
  )
}

export default BookCover
