import React, {useState} from "react";
import T from 'prop-types';
import styles from "./TeacherCard.module.css";
import {Col, Row} from "react-bootstrap";
import ImagePlaceholder from "../../ImagePlaceholder";
import ModalWindow from "../../ModalWindow";

const TeacherCard = ({
                         name,
                         photo,
                         photoExists,
                         contact,
                         email,
                         employername
                     }) => {

    const [modal, setModal] = useState(false);

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }


    return (
        <>
            {modal &&
            <ModalWindow
                isOpen={() => openModal()}
                title={`Преподаватель: ${name}`}
                onRequestClose={() => closeModal()}
                width="450px"
            >
                <div className={styles.modal_container}>
                    <div className="mb-2">
                        {photoExists ? (
                            <img alt={name} className={styles.modal_image} src={photo}/>
                        ) : (
                            <ImagePlaceholder className={styles.modal_placeholder}/>
                        )}
                    </div>
                    <div className={styles.modal_text}>
                        <span>ФИО: <strong>{name}</strong></span>
                        <span>Тел: {contact}</span>
                        <span>Email: {email ? email : "Не указано"}</span>
                        <span>Место работы: {employername ? employername : "Не указано"}</span>
                    </div>
                </div>
            </ModalWindow>
            }
            <div
                className={styles.item}
                onClick={() => openModal()}
            >
                <Row>
                    <Col md={10}>
                        <div className={styles.infoContainer}>
                            <span>ФИО: {name}</span>
                            <span>Тел: {contact}</span>
                            <span>Email: {email ? email : "Не указано"}</span>
                            <span>Место работы: {employername ? employername : "Не указано"}</span>
                        </div>
                    </Col>
                    <Col md={2}>
                        {photoExists ? (
                            <img
                                alt={name}
                                className={styles.image}
                                src={photo}
                            />
                        ) : (
                            <ImagePlaceholder className={styles.image}/>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

TeacherCard.propTypes = {
    name: T.string,
    link: T.string,
    photo: T.string,
    photoExists: T.string,
    position: T.string
};

export default TeacherCard;
