export const BASE_URL_WITHOUT_API = process.env.REACT_APP_URL
    ? process.env.REACT_APP_URL
    : `${window.location.protocol}//${window.location.host}`;


export const BASE_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : `${window.location.protocol}//${window.location.host}/api`;


// export const BASE_URL_WITHOUT_API = 'http://biblio.elib.dmz'
// export const BASE_URL = 'http://biblio.elib.dmz/api'    
