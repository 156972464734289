import { ERROR, RACKS_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const racksListRequestStart = (data) => ({
  type: `${RACKS_LIST_REQUEST}${START}`,
  payload: data
});

export const racksListRequestSuccess = (roomId, data) => ({
  type: `${RACKS_LIST_REQUEST}${SUCCESS}`,
  payload: { roomId, data }
});

export const racksListRequestError = (data) => ({
  type: `${RACKS_LIST_REQUEST}${ERROR}`,
  payload: data
});