import React from 'react'
import { connect } from 'react-redux'
import ReaderHeader from '../Header/ReaderHeader'

const mapStateToProps = ({ auth, options }) => ({
  authData: auth.data,
  layoutOptions: options.layout,
})

const Header = ({ authData, layoutOptions }) => {
  return <>{authData && <ReaderHeader layoutOptions={layoutOptions} authData={authData} />}</>
}

export default connect(mapStateToProps)(Header)
