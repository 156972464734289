import React from 'react'
import PropTypes from 'prop-types'
import BookLoanItem from '../List/Item'

const ListView = ({
  mid,
  data,
  reserve,
  unreserveBook,
  onTurnOut,
  onDelete,
  onEdit,
  isCompact,
  isLibrarian,
  setSearchBookModalData,
  setSearchBookModalVisible,
  outdatedBooks,
  prepareBook,
}) => {
  
  return (
    <div>
      {data.map((item, index) => (
        <BookLoanItem
          currentMid={mid}
          onTurnIn={onTurnOut && (() => onTurnOut && onTurnOut(item.bookinstanceid))}
          data={item}
          key={`${index} ${item.id}`}
          onDelete={onDelete}
          onEdit={onEdit}
          reserve={reserve}
          unreserveBook={unreserveBook}
          isCompact={isCompact}
          isLibrarian={isLibrarian}
          setSearchBookModalData={setSearchBookModalData}
          setSearchBookModalVisible={setSearchBookModalVisible}
          outdatedBooks={outdatedBooks}
          prepareBook={prepareBook}
        />
      ))}
    </div>
  )
}

ListView.propTypes = {
  data: PropTypes.array,
  reserve: PropTypes.bool,
  isLibrarian: PropTypes.bool,
}

export default ListView
