import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ADD } from '../../../constants/modalTypes'
import { BASE_URL_WITHOUT_API } from '../../../constants/api'
import { Button, Space } from 'antd'

const InvSearchFormControls = ({ formData, onSubmit, onClear, onDelete, setModal, selectedIds, setLocationModalData }) => {
  const prtFormular = () => {
    const formularWin = window.open(`${BASE_URL_WITHOUT_API}/inv_printformular/${selectedIds[0]}`)
    formularWin.focus()
  }

  const printEnvelope = () => {
    const printedDoc = window.open(`${BASE_URL_WITHOUT_API}/envelope_print/${selectedIds[0]}`)
    printedDoc.focus()
  }

  return (
    <Row>
      <Col md={6}>
        <Space
        style={{marginBottom: '.5rem', flexWrap: 'wrap'}}>
          <Button style={{marginBottom: '.5rem'}} type='primary' onClick={() => setModal({ modalType: ADD, data: {} })}>
            Добавить
          </Button>
          <Button style={{marginBottom: '.5rem'}} type='primary' onClick={onDelete} danger>
            Удалить
          </Button>
          <Link
            target='_blank'
            to={{
              pathname: '/qr_codes',
              search: qs.stringify({ ids: selectedIds }, { encode: false }),
            }}
          >
            <Button style={{marginBottom: '.5rem'}}  type='primary' disabled={!selectedIds.length}>
              Печать этикеток
            </Button>
          </Link>
          <Button style={{marginBottom: '.5rem'}}  type='primary' onClick={prtFormular} disabled={!selectedIds.length || selectedIds.length > 1}>
            Печать формуляра
          </Button>
          {+formData.library !== 0 && (
            <Button
              type='primary'
              disabled={!selectedIds.length}
              onClick={() =>
                setLocationModalData({
                  library: formData.library,
                  selectedIds,
                })
              }
            >
              Местоположение
            </Button>
          )}
        </Space>
      </Col>
      <Col md={{ span: 6 }} className='text-right'>
        <Space
        style={{marginBottom: '.5rem', flexWrap: 'wrap'}}>
          <Button onClick={printEnvelope} disabled={!selectedIds.length || selectedIds.length > 1}>
            Печать конверта
          </Button>
          <Button onClick={onClear}>Очистить</Button>
          <Button type='primary' onClick={onSubmit}>
            Найти
          </Button>
        </Space>
      </Col>
    </Row>
  )
}

InvSearchFormControls.propTypes = {
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  setModal: PropTypes.func,
  onDelete: PropTypes.func,
  selectedId: PropTypes.any,
}

export default InvSearchFormControls
