import React, { useEffect } from 'react'
import Header from '../../components/Layout/Header'
import Footer from '../../components/Layout/Footer'
import './global.css'
import styles from './Layout.module.css'
import Menu from '../../components/Layout/Menu'
import { connect } from 'react-redux'
import { checkAuthRequestStart } from '../../redux/actions/user/checkAuth'
import { BASE_URL_WITHOUT_API } from '../../constants/api'
import { layoutOptionsGetRequestStart } from '../../redux/actions/options/layout/get'
import { setLocation } from '../../redux/actions/changeLocation/action'

const mapStateToProps = ({ auth, options, historyLocation }) => ({
  auth: auth,
  layoutOptions: options.layout,
  historyLocation,
})

const mapDispatchToProps = {
  checkAuth: checkAuthRequestStart,
  getLayoutOptions: layoutOptionsGetRequestStart,
  setLocation,
}

const Layout = ({ auth, checkAuth, children, getLayoutOptions, layoutOptions, history, historyLocation, setLocation }) => {
  const currentPathName = history.location.pathname

  useEffect(() => {
    getLayoutOptions()
  }, [getLayoutOptions])

  useEffect(() => {
    auth.data === undefined && checkAuth()
  }, [auth.data, checkAuth])

  useEffect(() => {
    if (currentPathName !== historyLocation) {
      setLocation(currentPathName)
    }
  }, [currentPathName])

  const homePageBackgroundImage =
    currentPathName === '/' ? `url(${BASE_URL_WITHOUT_API}${layoutOptions.body.backgroundimage})` : ''

  return (
    <React.Fragment>
      <Header />
      <div style={{ backgroundImage: homePageBackgroundImage, paddingBottom: '1px' }}>
        <div className='container'>
          {auth.data !== undefined && (
            <div className={styles.container}>
              {!!auth.data?.role_permissions.length && <Menu />}
              {children}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
