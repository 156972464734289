import React from 'react'
import T from 'prop-types'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'
import moment from 'moment'

const TestsTable = ({ data, currentPage, resultsPerPage }) => {
  const formatPeriod = period => {
    let result = ''

    if (period?.hours) {
      result += `${period.hours}ч. `
    }
    if (period?.minutes) {
      result += `${period.minutes}м. `
    }
    if (period?.seconds) {
      result += `${period.seconds}сек.`
    }
    return result
  }

  const formatStartTime = startTime => {
    if (startTime) {
      return moment(startTime).format('DD.MM.YYYY hh:mm')
    }
    return 'Не указано'
  }

  const formatEndTime = (startTime, period) => {
    if (startTime) {
      let hours = period.hours || 0
      let minutes = period.minutes || 0
      return moment(startTime).add(hours, 'hours').add(minutes, 'minutes').format('DD.MM.YYYY hh:mm')
    }
    return 'Не указано'
  }

  return (
    <div id='tests-data-table'>
      <DataTable data={data}>
        <DataTableCol title='№ п/п' resolver={item => (currentPage - 1) * resultsPerPage + item.column_index} />
        <DataTableCol
          title='ФИО'
          value='fio'
          colSpan={1}
          resolver={item => item.lastname + ' ' + item.firstname + ' ' + item.patronymic}
        />
        <DataTableCol title='Курс' value='title' colSpan={1} />
        <DataTableCol title='Статус' resolver={e => 'завершен'} colSpan={2} />
        <DataTableCol title='Дата начала изучения' resolver={({ starttime }) => formatStartTime(starttime)} colSpan={2} />
        <DataTableCol
          title='Дата завершения изучения'
          resolver={({ starttime, period }) => formatEndTime(starttime, period)}
          colSpan={2}
        />
        <DataTableCol title='Продолжительность изучения' resolver={({ period }) => formatPeriod(period)} colSpan={2} />
        <DataTableCol title='Результат' value='rating' />
      </DataTable>
    </div>
  )
}

TestsTable.propTypes = {
  data: T.array,
  currentPage: T.number,
  resultsPerPage: T.number,
}

export default TestsTable
