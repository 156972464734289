import { ERROR, LAYOUT_OPTIONS_GET_REQUEST, START, SUCCESS } from "../../action-types";

export const layoutOptionsGetRequestStart = () => ({
  type: `${LAYOUT_OPTIONS_GET_REQUEST}${START}`
});

export const layoutOptionsGetRequestSuccess = (data) => ({
  type: `${LAYOUT_OPTIONS_GET_REQUEST}${SUCCESS}`,
  payload: data
});

export const layoutOptionsGetRequestError = (data) => ({
  type: `${LAYOUT_OPTIONS_GET_REQUEST}${ERROR}`,
  payload: data
});
