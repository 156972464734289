import { COLLECTION_GET_DATA_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const collectionGetDataRequestStart = (collectionId, collectionType) => ({
  type: `${COLLECTION_GET_DATA_REQUEST}${START}`,
  payload: { collectionId, collectionType }
});

export const collectionGetDataRequestSuccess = (data) => ({
  type: `${COLLECTION_GET_DATA_REQUEST}${SUCCESS}`,
  payload: data
});

export const collectionGetDataRequestError = (data) => ({
  type: `${COLLECTION_GET_DATA_REQUEST}${ERROR}`,
  payload: data
});
