import React, {useMemo} from 'react';
import T from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from './DocumentBooksTable.module.css';
import PDFPrint from "../../PDFPrint";
import idGen from "../../../utils/generateID";

const BooksOrderBooksTable = ({data, selectedId, handleSelect}) => {

    const id = useMemo(idGen, [data]);

    return (
        <div className={styles.container}>
            <div className={'d-flex justify-content-between'}>
                <h4>Книги</h4>
                <PDFPrint
                    headers={[
                        {
                            title: 'Название',
                            value: 'title',
                        },{
                            title: 'Кол-во',
                            value: 'amount',
                        },{
                            title: 'Эл. кол-во',
                            value: 'digital_amount',
                        },{
                            title: 'Авторы',
                            value: ({authors}) => authors ? authors.map(item => item.name) : "-",
                        },{
                            title: 'Издательство',
                            value: ({publishers}) => publishers ? publishers.map(item => item.name) : "-",
                        },{
                            title: 'Год издания',
                            value: 'year',
                        },
                    ]}
                    tableData={data}
                />
            </div>

            <DataTable data={data} tableId={id}>
                <DataTableCol colspan={2} title="Название" value="title"/>
                <DataTableCol title="Кол-во" value="amount"/>
                <DataTableCol title="Эл. кол-во" value="digital_amount"/>
                <DataTableCol colspan={3} title="Авторы" resolver={
                    ({authors}) => authors ? authors.map(item => item.name) : "-"
                }/>
                <DataTableCol title="Издательство" resolver={
                    ({publishers}) => publishers ? publishers.map(item => item.name) : "-"
                }/>
                <DataTableCol title="Год издания" value="year"/>
                {/*modalka*/}
                <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                    <input type="checkbox"  checked={item.id === selectedId}
                           onChange={() => handleSelect(item.id)}/>}
                />

            </DataTable>
        </div>
    );
};

BooksOrderBooksTable.propTypes = {
    data: T.array,
    handleSelect: T.func,
};

export default BooksOrderBooksTable;
