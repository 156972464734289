import { SET_SEARCH_AUDIT_SEARCH_DATA } from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { AUDIT_ACCESS_SORT } from "../../../../constants/sort/auditAccessSort";

const initialState = {
  page: 1,
  sortBy: AUDIT_ACCESS_SORT.EVENT_TIME,
  sortDirection: SORT_DIRECTION.DESC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const searchAuditSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SEARCH_AUDIT_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};
