import { ERROR, TURNOUT_STATS_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const turnoutStatsCountRequestStart = () => ({
  type: `${TURNOUT_STATS_COUNT_REQUEST}${START}`
});

export const turnoutStatsCountRequestSuccess = (data) => ({
  type: `${TURNOUT_STATS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const turnoutStatsCountRequestError = (data) => ({
  type: `${TURNOUT_STATS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
