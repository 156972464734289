import { api } from "../../App";

export const getOptionsLogo = () => {
  const config = {
    responseType: 'arraybuffer'
  };

  return api.get('/options/logo', config);
};

export const saveOptionsLogo = (image) => {
  const formData = new FormData();
  formData.append("logo", image);

  const headers = [
    { 'Content-Type': 'multipart/form-data' }
  ];

  return api.put('/options/logo', formData, { headers });
};