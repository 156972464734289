import { all } from 'redux-saga/effects';
import rolesListSaga from "./list/rolesListSaga";
import roleDeleteSaga from "./delete/roleDeleteSaga";
import roleSaveSaga from "./save/roleSaveSaga";
import roleMembersListSaga from "./membersList/roleMembersListSaga";
import roleMembersSaveSaga from "./save/roleMemberSaveSaga";
import roleMembersDeleteSaga from "./delete/roleMemberDeleteSaga";

export default function* rolesSaga() {
  yield all([
    rolesListSaga(),
    roleDeleteSaga(),
    roleSaveSaga(),
    roleMembersListSaga(),
    roleMembersSaveSaga(),
    roleMembersDeleteSaga()
  ]);
}