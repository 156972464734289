import { BOOK_EXPORTS_DELETE, SUCCESS, START, ERROR, RESET } from "../../../actions/action-types";

const initialState = {
  isLoading: false,
  isCompleted: false,
  isError: false,
};

export const booksExportDeleteReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${BOOK_EXPORTS_DELETE}${START}`:
      return {
        ...state,
        isLoading: true,
      };

    case `${BOOK_EXPORTS_DELETE}${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        isCompleted: true
      };

    case `${BOOK_EXPORTS_DELETE}${ERROR}`:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case `${BOOK_EXPORTS_DELETE}${RESET}`:
      return initialState;
    default: return state;
  }
};
