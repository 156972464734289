import React, {useState} from "react";
import {Collapse} from "react-bootstrap";
import styles from './BibliographyDiscipline.module.css';
import Razdel from "../Razdel";

const BibliographyDiscipline = ({ data }) => {
    const { name, razdels } = data;
    const [disc, setDisc] = useState(false);

    const toggleDisc = e => {
        if (e.target.id === "disc") {
            setDisc(!disc);
        }
    };

    return (
        <div onClick={toggleDisc}>
            <div className={styles.tableDiscRow}>
                <div id="disc">
                    Дисциплина: { name }
                </div>
            </div>
            <Collapse in={disc}>
                <div className={'collapse-razdel'}>
                    {razdels.map((razdel, index) => <Razdel key={index} razdel={razdel} />)}
                </div>
            </Collapse>
        </div>
    );
};

export default BibliographyDiscipline;