import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../Modal/InputWrapper'
import InputWrapper from '../../Modal/InputWrapper'
import { Button } from 'antd'

const ExemptTestsForm = ({ data, setData, onSubmit }) => {
  const onChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  return (
    <div className={'formContainer'}>
      <Row className={'justify-content-start'}>
        <Col md={3}>
          <ModalInputWrapper name='from' value={data.from} onChange={onChange} type={'date'} label={'С'} />
        </Col>
        <Col md={3}>
          <ModalInputWrapper name='to' value={data.to} onChange={onChange} type={'date'} label={'По'} />
        </Col>
        <Col sm={'auto'}>
          <ModalInputWrapper label={''}>
            <Button onClick={onSubmit} type='primary'>
              Найти
            </Button>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  )
}

ExemptTestsForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
}

export default ExemptTestsForm
