import React from 'react'
import styles from './AdminAboutPermissionsTable.module.css'

export const Table = () => {
  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <th rowSpan={2}>Режимы</th>
          <th colSpan={3}>Уровни доступа</th>
        </tr>
        <tr>
          <th>Нет</th>
          <th>Чтение</th>
          <th>Полный</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        <tr>
          <td className={styles.blue + ' text-center'} colSpan={4}>
            <strong>Раздел "Настройка"</strong>
          </td>
        </tr>
        <tr>
          <td className={styles.blue}>Настройка прав доступа</td>
          <td>Нет доступа к пункту меню "Настройка прав доступа"</td>
          <td>
            Просмотр:
            <br /> - информации о пользователях и их правах;
            <br /> - расписаний и целостности миграции метаданных;
            <br /> - отчетов по доступу к ресурсам.
          </td>
          <td>
            Редактирование:
            <br /> - создание и управление правами пользователей, группами;
            <br /> - настройки прав доступа к ресурсам по категориям, литерам и сериям;
            <br /> - параметров формирования отчеты по доступу к ресурсам
            <br /> - настройка расписаний и целостности миграции метаданных.
          </td>
        </tr>
        <tr>
          <td className={styles.blue}>Общие настройки:</td>
          <td>Нет доступа к пункту меню "Настройка прав доступа"</td>
          <td>
            Возможность просмотра настроек:
            <br /> - Информации об учреждении;
            <br /> - Выбранного сегмента;
            <br /> - Сроки выдачи и бронирования литературы;
            <br /> - Запреты на выдачу;
            <br /> - Ссылок на внешние информационные ресурсы;
            <br /> - Компонентов медиа портала.
          </td>
          <td>
            Возможность редактирования настроек:
            <br /> - Возможность редактирования настроек:
            <br /> - Возможность редактирования настроек:
            <br /> - Сроки выдачи и бронирования литературы;
            <br /> - Запреты на выдачу;
            <br /> - Ссылок на внешние информационные ресурсы;
            <br /> - Компонентов медиа портала.
          </td>
        </tr>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        <tr>
          <td className={styles.blue + ' text-center'} colSpan={4}>
            <strong>Раздел "Справочники"</strong>
          </td>
        </tr>
        <tr>
          <td className={styles.blue}>Дисциплины</td>
          <td>Нет доступа к пункту меню "Дисциплины"</td>
          <td>Просмотр записей в справочнике «Дисциплины»</td>
          <td>Просмотр, создание и редактирование записей в справочнике «Дисциплины»</td>
        </tr>
        <tr>
          <td className={styles.blue}>Личные дела. Главная</td>
          <td>Нет доступа к пункту меню "Личные дела. Главная"</td>
          <td>Просмотр записей в справочнике «Пользователи»</td>
          <td>Просмотр, создание и редактирование записей в справочнике «Пользователи»</td>
        </tr>
        <tr>
          <td className={styles.blue}>Библиотека. Авторы</td>
          <td>Нет доступа к пункту меню "Библиотека. Авторы"</td>
          <td>Просмотр записей в справочнике «Авторы» </td>
          <td>Просмотр, создание и редактирование записей в справочнике «Авторы»</td>
        </tr>
        <tr>
          <td className={styles.blue}>Библиотека. Издательства</td>
          <td>Нет доступа к пункту меню "Библиотека. Издательства"</td>
          <td>Просмотр записей в справочнике «Издательства»</td>
          <td>Просмотр, создание и редактирование записей в справочнике «Издательства»</td>
        </tr>
        <tr>
          <td className={styles.blue}>Библиотека. Книги</td>
          <td>Нет доступа к пункту меню "Библиотека. Книги"</td>
          <td>Просмотр библиографических записей документов</td>
          <td>Создание и редактирование библиографических записей о документах и первичных документов учета </td>
        </tr>
        <tr>
          <td className={styles.blue}>Заказчики (организации)</td>
          <td>Нет доступа к пункту меню "Заказчики (организации)"</td>
          <td>Просмотр записей в справочнике «Организации»</td>
          <td>Просмотр, создание и редактирование записей в справочнике «Организации»</td>
        </tr>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        <tr>
          <td className={styles.blue + ' text-center'} colSpan={4}>
            <strong>Раздел "Режимы"</strong>
          </td>
        </tr>
        <tr>
          <td className={styles.blue}>Изменить пароль:</td>
          <td>Нет доступа к пункту меню "Изменить пароль:"</td>
          <td></td>
          <td>Право на создание и редактирование пароля в справочнике «Пользователи» </td>
        </tr>
        <tr>
          <td className={styles.blue}>Сжатие материалов</td>
          <td>Нет доступа к пункту меню "Сжатие материалов"</td>
          <td></td>
          <td>Право на сжатие материалов по кнопке «Сжать» в Библиографической записи </td>
        </tr>
        <tr>
          <td className={styles.blue}>Библиотекарь</td>
          <td>Нет доступа к пункту меню "Библиотекарь"</td>
          <td></td>
          <td>Просмотр: библиотечной статистической информации, электронного каталога, отчетов, КСУ, Книги движения</td>
        </tr>
        <tr>
          <td className={styles.blue}>Инвентаризация</td>
          <td>Нет доступа к пункту меню "Инвентаризация"</td>
          <td></td>
          <td>
            Доступ к режиму Инвентаризация, создание и редактирование инвентаризационных документов, проведение
            инвентаризации
          </td>
        </tr>
        <tr>
          <td className={styles.blue}>Преподаватель</td>
          <td>Нет доступа к пункту меню "Преподаватель"</td>
          <td></td>
          <td>
            Доступ к электронному каталогу; Заказ документов; Просмотр библиотечных документов, доступ к ресурсам ЛК
            (Расписание, успеваемость, статистика работы с электронной библиотекой, проверка на заимствование доступ к
            сервисам объединённых образовательных коммуникаций); Просмотр отчетов: по прохождению электронных
            образовательных курсов по обучающимся, результатов тестирования по обучающимся, статистка ответов на
            вопросы, журнала изъятых тестов).
          </td>
        </tr>
        <tr>
          <td className={styles.blue}>Обучающийся</td>
          <td>Нет доступа к пункту меню "Обучающийся"</td>
          <td></td>
          <td>
            Доступ к электронному каталогу; Заказ документов; Просмотр библиотечных документов, доступ к ресурсам ЛК
            (Расписание, успеваемость, статистика работы с электронной библиотекой, проверка на заимствование доступ к
            сервисам объединённых образовательных коммуникаций)
          </td>
        </tr>
      </tbody>
    </table>
  )
}
