import React from 'react';
import PropTypes from 'prop-types';
import styles from './LocationDataContainer.module.css';
import LocationRack from "../Rack";
import Button from "../../Button";
import {ADD, EDIT} from "../../../constants/modalTypes";
import {LOCATION_MODAL} from "../../../constants/locationModal";
import {roomDeleteRequestStart} from "../../../redux/actions/location/rooms/delete";
import {connect} from "react-redux";

const mapDispatchToProps = {
    deleteRoom: roomDeleteRequestStart
};

const LocationDataContainer = ({
                                   rooms, racks, requestRacks, shelves, requestShelves, setModalData,
                                   libraryId, deleteRoom, setEditorData, setSelectedIds, selectedIds
                               }) => {
    const openRoom = (id) => {
        !racks[id] && requestRacks(id);
    };

    const onRoomEdit = (roomData) => {
        setModalData({
            modalType: EDIT,
            locationType: LOCATION_MODAL.ROOM,
            parentId: libraryId,
            data: roomData
        })
    };

    const onNewRack = (parentId) => {
        setModalData({
            modalType: ADD,
            locationType: LOCATION_MODAL.RACK,
            parentId,
            data: null
        })
    };

    const onRoomEditor = (roomData) => {
        setEditorData({
            locationType: LOCATION_MODAL.ROOM,
            parentId: libraryId,
            roomId: roomData.id
        })
    };

    return (
        <div className={styles.container}>
            {rooms.map((item, index) => (
                <div className={styles.room} key={index}>
                    <details onClick={() => openRoom(item.id)} className={styles.roomDetails}>
                        <summary className={styles.roomSummary}>
                            <div className={styles.title}>{item.name}</div>
                            <div>
                                <Button label="План зала" style={styles.button} onClick={() => onRoomEditor(item)}/>
                                <Button label="+" style={styles.button} onClick={() => onNewRack(item.id)}/>
                                <Button label="-" style={styles.button} onClick={() => deleteRoom(libraryId, item.id)}/>
                                <Button label="Редактировать" style={styles.button} onClick={() => onRoomEdit(item)}/>
                            </div>
                        </summary>
                        <div className={styles.racksContainer}>
                            {racks[item.id] &&
                            racks[item.id].map((item, index) =>
                                <LocationRack key={index}
                                              shelves={shelves}
                                              setModalData={setModalData}
                                              requestShelves={requestShelves}
                                              selectedIds={selectedIds}
                                              setSelectedIds={setSelectedIds}
                                              data={item}/>)}
                        </div>
                    </details>
                </div>
            ))}
        </div>
    );
};

LocationDataContainer.propTypes = {
    rooms: PropTypes.array,
    racks: PropTypes.object,
    shelves: PropTypes.object,
    setModalData: PropTypes.func,
    onRoomDelete: PropTypes.func,
    libraryId: PropTypes.string,
    resetModal: PropTypes.func
};

export default connect(null, mapDispatchToProps)(LocationDataContainer);
