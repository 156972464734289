import { combineReducers } from 'redux'
import { documentCountReducer } from './count/documentCountReducer'
import { inventoryListReducer } from './list/inventoryListReducer'
import { documentSearchReducer } from './search/documentSearchReducer'
import { documentBooksReducer } from './books/documentBooksReducer'
import { documentNumberReducer } from './number/documentNumberReducer'
import { documentLatestReducer } from './latest/documentLatestReducer'
import { documentInventaryReducer } from './Inventary/documentInventaryReducer'
import { documentFundAllocationReducer } from './fundAllocation/documentFundAllocationReducer'
import { libraryFundReducer } from './fundAllocation/libraryFundReducer'
import { documentBookDistributeReducer } from './bookDistribute/documentBookDistributeReducer'
import { inventoryLoaderReducer } from './loader/inventoryLoaderReducer'
import { modalLoaderReducer } from './modalLoader/modalLoaderReducer'

export const inventoryReducer = combineReducers({
  count: documentCountReducer,
  list: inventoryListReducer,
  loader: inventoryLoaderReducer,
  search: documentSearchReducer,
  books: documentBooksReducer,
  inventary: documentInventaryReducer,
  bookDistribute: documentBookDistributeReducer,
  number: documentNumberReducer,
  latest: documentLatestReducer,
  libraries: documentFundAllocationReducer,
  libraryFunds: libraryFundReducer,
  modalLoader: modalLoaderReducer
})
