import { call, put, takeLatest } from "redux-saga/effects";
import {getGradeBook} from "../../../api/credits/credits";
import {creditsListRequestError, creditsListRequestSuccess} from "../../actions/credits/list";
import {CREDITS_LIST_REQUEST, START} from "../../actions/action-types";


export default function* creditsWatcher() {
  yield takeLatest(`${CREDITS_LIST_REQUEST}${START}`, listWorker);
}

function* listWorker({ payload: studentId }) {
    try {
        const response = yield call(getGradeBook, studentId);
        yield put(creditsListRequestSuccess(response.data));
    } catch(error) {
        yield put(creditsListRequestError());
    }
}