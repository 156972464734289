import { PUBLISHERS_COUNT_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = 0;

export const publishersCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${PUBLISHERS_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};