import { INVENTORY_LIST_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const inventoryListRequestStart = () => ({
  type: `${INVENTORY_LIST_REQUEST}${START}`
});

export const inventoryListRequestSuccess = (data) => ({
  type: `${INVENTORY_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const inventoryListRequestError = (data) => ({
  type: `${INVENTORY_LIST_REQUEST}${ERROR}`,
  payload: data
});
