import { call, put, takeLatest } from "redux-saga/effects";
import { PERMISSIONS_BY_ROLE_REQUEST, START } from "../../../actions/action-types";
import { permissionsByRoleRequestError, permissionsByRoleRequestSuccess } from "../../../actions/permissions/byRole";
import { getPermissionsByRole } from "../../../../api/permissions/permissions";

export default function* permissionsByRoleWatcher() {
  yield takeLatest(`${PERMISSIONS_BY_ROLE_REQUEST}${START}`, permissionsByRoleWorker)
}

function* permissionsByRoleWorker({ payload: roleId }) {
  try {
    const response = yield call(getPermissionsByRole, roleId);
    // Шифр
    const result = response.data.filter(item => item.parent === 0);
    result.forEach((parent => {
        parent.children = response.data
          .filter(item => item.parent === parent.xp_key);
      })
    );
    yield put(permissionsByRoleRequestSuccess(result));
  } catch (error) {
    yield put(permissionsByRoleRequestError(error));
  }
}

