import React, { useEffect } from 'react'
import T from 'prop-types'
import styles from './AuthForm.module.css'
import Button from '../../Button'
import ModalInputWrapper from '../../Modal/InputWrapper'

const AuthForm = ({ data, setData, onSubmit, onRefreshCaptcha }) => {
  useEffect(() => {
    if (data.captcha_text === undefined || data.captcha_id === '') {
      onRefreshCaptcha()
    }
  }, [onRefreshCaptcha])

  return (
    <form onSubmit={onSubmit} className={styles.container}>
      <div className={styles.label}>Пожалуйста, авторизуйтесь</div>
      <div className={styles.iconContainer}>
        <div className={styles.userIcon} />
      </div>
      <div className={styles.inputContainer}>
        <ModalInputWrapper
          label="Логин"
          value={data.username}
          onChange={(e) => setData({ ...data, username: e.target.value })}
        />
        <ModalInputWrapper
          label="Пароль"
          type={'password'}
          value={data.password}
          onChange={(e) => setData({ ...data, password: e.target.value })}
        />
        <div className={styles.captchaContainer}>
          <div
            className={styles.captcha}
            onClick={onRefreshCaptcha}
            dangerouslySetInnerHTML={{ __html: data.captcha_text }}
          ></div>
          <div className={styles.inputContainer} style={{ marginTop: '1rem' }}>
            <ModalInputWrapper
              label="Введите символы с картинки"
              value={data.captcha}
              onChange={(e) => setData({ ...data, captcha: e.target.value })}
            />
          </div>
        </div>
        <Button onClick={onSubmit} label={'Войти'} type="submit" />
      </div>
    </form>
  )
}

AuthForm.propTypes = {
  data: T.object,
  setData: T.func,
  onSubmit: T.func,
  onRefreshCaptcha: T.func,
}

export default AuthForm
