import { ERROR, ROLE_SAVE_REQUEST, START, SUCCESS } from "../action-types";

export const roleSaveRequestStart = (data) => ({
  type: `${ROLE_SAVE_REQUEST}${START}`,
  payload: data
});

export const roleSaveRequestSuccess = (data) => ({
  type: `${ROLE_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const roleSaveRequestError = (data) => ({
  type: `${ROLE_SAVE_REQUEST}${ERROR}`,
  payload: data
});