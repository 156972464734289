import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import styles from './BooksOrderModal.module.css';
import {ADD, EDIT} from "../../../constants/modalTypes";
import {Col, Row} from "react-bootstrap";
import {documentBookListRequestStart} from "../../../redux/actions/booksOrder/list/list";
import {connect} from "react-redux";
import BooksOrderTable from "../BooksTable";
import {orderBooksDeleteRequestStart} from "../../../redux/actions/booksOrder/orderItem/delete";
import {orderBooksEditRequestStart} from "../../../redux/actions/booksOrder/orderItem/edit";
import {resetCoversFormData, setCoversFormData} from "../../../redux/actions/covers/form";
import DocumentsEditBookOrderForm from "../../BooksOrder/EditBookOrderForm";
import Button from "../../Button";
import BookSearchForm from "../../Covers/SearchForm";
import InvNumbersModalSearchItem from "../../InvNumbers/Modal/SearchItem";
import {coversCountRequestStart} from "../../../redux/actions/covers/count";
import ModalWindow from '../../ModalWindow';
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import BooksOrderModalHeader from "./Header";

const mapStateToProps = ({booksOrder, covers}) => ({
    books: booksOrder.books,
    loader: covers.loader,
    searchResults: covers.list,
    formData: covers.form,
});

const mapDispatchToProps = dispatch => ({
    requestBooks: documentId => dispatch(documentBookListRequestStart(documentId)),
    deleteOrderItem: data => dispatch(orderBooksDeleteRequestStart(data)),
    editOrderItem: data => dispatch(orderBooksEditRequestStart(data)),
    setFormData: (data) => dispatch(setCoversFormData(data)),
    resetFormData: () => dispatch(resetCoversFormData()),
    startCoversSearch: () => dispatch(coversCountRequestStart()),
});

const BooksOrderModal = ({
                             modalData, setModalData, requestBooks, books, deleteOrderItem, editOrderItem,
                             loader, searchResults, formData, setFormData, startCoversSearch, onSubmit, courses, resetFormData
                         }) => {

    const {modalType, data} = modalData;
    const documentId = data ? data.id : null;
    const isVisible = !!modalType;
    const booksArray = books.books || [];
    const [currentBook, setCurrentBook] = useState(null);
    const [searchOpen, setSearchOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        (documentId && modalType === EDIT) && requestBooks(documentId);
    }, [modalType, requestBooks, documentId]);

    useEffect(() => {
        modalType === ADD && setCurrentBook(null);
    }, [modalType]);

    useEffect(() => {
        return () => resetFormData();
    }, []);

    if (modalType === ADD && !data) {
        setModalData({
            modalType,
            data: {
                documentname: "",
                documenttype: "",
                documentdate: new Date().toISOString().split("T")[0],
                source: ""
            }
        })
    }

    const handleSelect = (id) => {
        setSelectedId(selectedId === id ? null : id);
    };

    const onSelectFromSearch = ({bookid, title}) => {
        setCurrentBook({
            bookid,
            title,
            amount: 0,
            digital_amount: 0,
            documentid: documentId
        });
        setSearchOpen(false);
        resetFormData();
    };

    const checkedOnSubmit = () => {
        if (modalData.data.curriculum_course_id) {
            onSubmit(modalData);
        } else {
            setModalData({
                modalType, data: {...data, ['modalSubmitError']: true}
            });
        }
    };

    const onBookAdd = () => {
        modalType === EDIT ? setSearchOpen(!searchOpen) : checkedOnSubmit();
    };

    const onBookSave = () => {
        editOrderItem({bookData: currentBook, document: documentId});
        setCurrentBook(null);
    };

    const onBookDelete = () => {
        deleteOrderItem({
            id: selectedId,
            documentid: documentId
        });
        setSelectedId(null);
    };

    const onCloseModal = () => {
        setModalData({modalType: null, data: null});
        setSearchOpen(false);
    };


    const title = `Заказ литературы от ${books.creationtime ? new Date(books.creationtime).toLocaleDateString() : new Date().toLocaleDateString()}`;

    return (
        <ModalWindow
            isOpen={isVisible}
            onRequestClose={onCloseModal}
            title={title}
        >
            {data && <>
                <BooksOrderModalHeader books={books} courses={courses} modalType={modalType} data={data} setModalData={setModalData}/>
                <Row>
                    <Col md={12}>
                        <div className={'mb-3'}>
                            {modalType === EDIT &&
                            <BooksOrderTable
                                selectedId={selectedId}
                                handleSelect={handleSelect}
                                data={booksArray}
                            />
                            }
                            {currentBook &&
                            <DocumentsEditBookOrderForm
                                data={currentBook}
                                onSave={onBookSave}
                                modalData={modalData}
                                onDelete={currentBook.id ? onBookDelete : () => setCurrentBook(null)}
                                setData={setCurrentBook}
                            />
                            }
                        </div>
                    </Col>
                    <Col md={12}>
                        <Button label={!searchOpen ? "Добавить книгу" : "Отменить"}
                                onClick={onBookAdd}
                                style={'mr-2'}
                        />
                        {selectedId &&
                            <Button label={'Удалить'} onClick={onBookDelete} colorType={'red'}/>
                        }

                        {searchOpen && <>
                            <BookSearchForm data={formData} setData={setFormData} disableDefaultPanel>
                                <Row>
                                    <Col md={{span: 3, offset: 9}} className={"text-right"}>
                                        <Button onClick={startCoversSearch} label={"Найти"}/>
                                    </Col>
                                </Row>
                            </BookSearchForm>
                            {loader && <SpinnerOuter/>}
                            {!!searchResults.length &&
                                <>
                                    <div className={styles.title}>
                                        Результаты поиска
                                    </div>
                                    <div className={styles.searchResultsContainer}>
                                        {searchResults.map((item, index) => (
                                            <InvNumbersModalSearchItem data={item} key={index}
                                                                       onClick={onSelectFromSearch}/>
                                        ))}
                                    </div>
                                </>}
                        </>}
                    </Col>
                </Row>
            </>}
        </ModalWindow>
    );
};

BooksOrderModal.propTypes = {
    modalData: T.object,
    setModalData: T.func,
    onSubmit: T.func,
    types: T.array,
    onSave: T.func,
    courses: T.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(BooksOrderModal);
