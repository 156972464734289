import { ERROR, PERMISSIONS_BY_ROLE_REQUEST, START, SUCCESS } from "../action-types";

export const permissionsByRoleRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_ROLE_REQUEST}${START}`,
  payload: data
});

export const permissionsByRoleRequestSuccess = (data) => ({
  type: `${PERMISSIONS_BY_ROLE_REQUEST}${SUCCESS}`,
  payload: data
});

export const permissionsByRoleRequestError = (data) => ({
  type: `${PERMISSIONS_BY_ROLE_REQUEST}${ERROR}`,
  payload: data
});