import React, { useState, useEffect } from 'react'
import { studentProjectsActions } from '../../redux/factory/studentProjectsReduxData'
import { connect } from 'react-redux'
import Selects from '../../components/StudentProjects/Selects'
import StudentProjectsTable from '../../components/StudentProjects/Table'
import StudentProjectsModal from '../../components/StudentProjects/Modal'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'

const mapStateToProps = ({ studentProjects }) => ({
  years: studentProjects.years,
  terms: studentProjects.terms,
  discs: studentProjects.discs,
  projects: studentProjects.projects,
  project: studentProjects.project,
})

const matchDispatchToProps = {
  yearRequest: studentProjectsActions.studentProjectsYearsRequestStart,
  termRequest: studentProjectsActions.studentProjectsTermsRequestStart,
  discsRequest: studentProjectsActions.studentProjectsDiscsRequestStart,
  projectsRequest: studentProjectsActions.studentProjectsRequestStart,
  projectRequest: studentProjectsActions.studentProjectRequestStart,
  saveProject: studentProjectsActions.saveStudentProjectRequestStart,
}

const StudentProjects = ({
  years,
  yearRequest,
  terms,
  termRequest,
  discs,
  discsRequest,
  projects,
  projectsRequest,
  project,
  projectRequest,
  saveProject,
}) => {
  const [modal, setModal] = useState({
    status: false,
    id: undefined,
  })

  useEffect(() => {
    modal.status && projectRequest(modal.id)
  }, [modal])

  useEffect(() => {
    yearRequest()
  }, [yearRequest])

  useEffect(() => {
    discsRequest()
  }, [discsRequest])

  return (
    <SceneContainer title={'Индивидуальные задания'}>
      <StudentProjectsModal
        setModal={setModal}
        modal={modal}
        project={project}
        projectRequest={projectRequest}
        saveProject={saveProject}
      />
      <Selects years={years} terms={terms} discs={discs} projectsRequest={projectsRequest} termRequest={termRequest} />
      <StudentProjectsTable projects={projects} setModal={setModal} />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, matchDispatchToProps)(StudentProjects)
