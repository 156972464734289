import { ERROR, EPUB_READER_BOOK_REQUEST, START, SUCCESS } from "../action-types";

export const epubReaderBookRequestStart = (bookId) => ({
    type: `${EPUB_READER_BOOK_REQUEST}${START}`,
    payload: bookId
});

export const epubReaderBookRequestSuccess = (data) => ({
    type: `${EPUB_READER_BOOK_REQUEST}${SUCCESS}`,
    payload: data
});

export const epubReaderBookRequestError = (data) => ({
    type: `${EPUB_READER_BOOK_REQUEST}${ERROR}`,
    payload: data
});