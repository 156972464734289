import { call, put, takeLatest } from "redux-saga/effects";
import { REGIONS_LIST_REQUEST, START } from "../../../actions/action-types";
import { regionsListRequestError, regionsListRequestSuccess } from "../../../actions/regions/list";
import { regionsList } from "../../../../api/regions/regions";

export default function* regionsListWatcher() {
  yield takeLatest(`${REGIONS_LIST_REQUEST}${START}`, regionsListWorker);
}

function* regionsListWorker() {
  try {
    const response = yield call(regionsList);
    yield put(regionsListRequestSuccess(response.data));
  } catch (error) {
    yield put(regionsListRequestError(error))
  }
}