import {ERROR, ELECTRONIC_COURSES_ITEM_SAVE, START, SUCCESS} from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const electronicCoursesItemSaveStart = (data, callback) => ({
    type: `${ELECTRONIC_COURSES_ITEM_SAVE}${START}`,
    payload: data,
    callback
});

export const electronicCoursesItemSaveSuccess = (data) => {
    createNotification('success', 'Курс успешно создан');
    return {
        type: `${ELECTRONIC_COURSES_ITEM_SAVE}${SUCCESS}`,
        payload: data
    }
};

export const electronicCoursesItemSaveError = (data) => {
    createNotification('error', 'Ошибка создания курса');
    return {
        type: `${ELECTRONIC_COURSES_ITEM_SAVE}${ERROR}`,
        payload: data
    }
};

