import {
  START,
  SUCCESS,
  ERROR,
  ADMIN_GROUPS_DELETE_GROUP,
} from '../../actions/action-types'

export const adminGroupsDeleteGroup = (payload) => ({
  type: `${ADMIN_GROUPS_DELETE_GROUP}${START}`,
  payload,
})

export const adminGroupsDeleteGroupSuccess = payload => ({
  type: `${ADMIN_GROUPS_DELETE_GROUP}${SUCCESS}`,
  payload,
})

export const adminGroupsDeleteGroupError = payload => ({
  type: `${ADMIN_GROUPS_DELETE_GROUP}${ERROR}`,
  payload,
})
