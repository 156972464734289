import { call, put, takeLatest } from 'redux-saga/effects';
import {BOOK_OPEN_HISTORY_LIST, START} from "../../actions/action-types";
import {getBookOpenHistory} from "../../../api/bookOpenHistory";
import {bookOpenHistoryListRequestError, bookOpenHistoryListRequestSuccess} from "../../actions/bookOpenHistory/list";


export default function* bookOpenHistoryWatcher() {
    yield takeLatest(`${BOOK_OPEN_HISTORY_LIST}${START}`, listWorker);
}

function* listWorker({ payload: mid }) {
    try {
        const response = yield call(getBookOpenHistory, mid);
        yield put(bookOpenHistoryListRequestSuccess(response.data));
    } catch(error) {
        yield put(bookOpenHistoryListRequestError(error));
    }
}