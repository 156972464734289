import {ERROR, START, SUCCESS, TRANSFER_LOG_LIST_REQUEST} from "../../action-types";


export const transferLogListRequestStart = () => ({
    type: `${TRANSFER_LOG_LIST_REQUEST}${START}`
});

export const transferLogListRequestSuccess = (response) => ({
    type: `${TRANSFER_LOG_LIST_REQUEST}${SUCCESS}`,
    payload: response
});

export const transferLogListRequestError = (error) => ({
    type: `${TRANSFER_LOG_LIST_REQUEST}${ERROR}`,
    payload: error
});
