import { all } from 'redux-saga/effects';
import mobileListSaga from './list';
import currentLibSaga from './current';
import responsibleSaga from './responsible';
import libDocumentsSaga from './documents';

export default function* mobileLibrarySaga() {
    yield all([
        mobileListSaga(),
        currentLibSaga(),
        responsibleSaga(),
        libDocumentsSaga()
    ]);
}
