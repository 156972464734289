import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import { ADD, EDIT } from '../../../constants/modalTypes'
import { api } from '../../../App'
import { connect, useSelector } from 'react-redux'
import { documentBookListRequestStart, documentBooksCleanup } from '../../../redux/actions/documents/books/list'
import { institutionsAllListRequestStart } from '../../../redux/actions/institutions/all'
import { librariesListRequestStart } from '../../../redux/actions/documents/fundAllocation/libraries'
import { bookDataRequestStart } from '../../../redux/actions/bookData/data'
import DocumentsEdit from './DocumentsEdit'
import ModalHeader from './DocumentModalHeader'
import ModalWindow from '../../ModalWindow'
import CreateNewLibraryItemModal from './CreateNewLibraryItemModal/CreateNewLibraryItemModal'
import { clearDocumentSearchList } from '../../../redux/actions/documents/modal/clearSearchList'
import { MaterialDestructionActPDFPrint } from './MaterialDestructionActPDF/PDFPrint'
import { WriteOfActPDFPDFPrint } from './WriteOfActPDF/PDFPrint'
import SeizureAct from './SeizureActPdf'
import SecretDestructionActPdf from './SecretDestructionActPdf'
import ReceiptActPdf from './ReceiptActPdf'
import { priceCoeffRequestStart } from '../../../redux/actions/common/priceCoeff/list'
import { invNumbersList } from '../../../api/invNumbers/invNumbers'
import { libraryInstitutionsRequestStart } from '../../../redux/actions/library/info/institutions'
import { militaryRankRequestStart } from '../../../redux/actions/common/militaryRank'

const mapStateToProps = ({ institutions, options, bookData, documents, common, library }) => ({
  institutionsList: institutions.all,
  libraries: documents.libraries,
  clientData: options.data,
  clients: library.info.institutions,
  bookData: bookData,
  current: documents.current,
  books: documents.books,
  options: options.data,
  coeffs: common.price_coeff,
  ranks: common.military_rank,

})

const mapDispatchToProps = {
  requestBooks: documentBookListRequestStart,
  requestInstitutions: institutionsAllListRequestStart,
  requestLibraries: librariesListRequestStart,
  booksCleanup: documentBooksCleanup,
  clearSearchList: clearDocumentSearchList,
  requestData: bookDataRequestStart,
  requestClients: libraryInstitutionsRequestStart,
  requestCoeffs: priceCoeffRequestStart,
  getMilitaryRank: militaryRankRequestStart

}

const DocumentsModal = ({
  books,
  types,
  modalData,
  setModalData,
  requestBooks,
  booksCleanup,
  onSave,
  institutionsList,
  requestInstitutions,
  requestLibraries,
  requestClients,
  clients,
  libraries,
  clientData,
  bookData,
  requestData,
  options,
  clearSearchList,
  current,
  fromMobLib,
  fromLostDoc,
  fromLostDocForTakeAct,
  fromInvBook,
  disabled,
  coeffs,
  requestCoeffs,
  ranks,
  getMilitaryRank
}) => {
  const { modalType, data } = modalData
  const documentId = data ? data.id : null
  const [searchOpen, setSearchOpen] = useState(false)
  const [save, setSave] = useState(false)
  const [currentBook, setCurrentBook] = useState(null)
  const [currentBookData, setCurrentBookData] = useState(null)
  const [formValidity, setFormValidity] = useState(false)
  const [documentid, setDocumentid] = useState(false)
  const [showLibraryItemModal, setShowLibraryItemModal] = useState(false)
  const [value, setValue] = useState(null)
  const documentType = parseInt(modalData.data.documenttype)


  useEffect(() => {
    !coeffs.length && requestCoeffs()
  }, [])
  

  useEffect(() => {
    if (modalData.data) setDocumentid(modalData.data.id)
  }, [modalData])

  useEffect(() => {
    documentId && modalType === EDIT && requestBooks(documentId)
  }, [modalType, requestBooks, documentId])

  const formData = useSelector(({ invNumbers }) => invNumbers.form)
  const searchData = useSelector(({ invNumbers }) => invNumbers.search)
  const bookid = useSelector(({ bookData }) => bookData && bookData.id)
  
  useEffect(() => {
    requestInstitutions()
    !clients?.length && requestClients()
    !ranks?.length && getMilitaryRank()
    
  }, [])

  useEffect(() => {
    invNumbersList({...formData, bookid}, searchData)
  }, [])

  useEffect(() => {
    modalType === ADD && setCurrentBook(null)
  }, [modalType])

  useEffect(() => {
    clientData && requestLibraries(clientData.own_client_id)
  }, [requestLibraries, clientData])

  const onModalSave = (сurrentBookData) => {
    const { bookid, title, number, fundto, instanceid, year } = сurrentBookData
    const writeOffCoeff = coeffs?.find((coeff) => coeff.year == year)?.value || 1
    // Шифр
    const coefficient = documentType == 18 ? writeOffCoeff : 1
    setCurrentBook({
      bookid,
      title,
      year,
      amount: 1,
      price: 0,
      coefficient,
      libnumber: number,
      documentid: documentId,
      fundto: fundto,
      bookinstanceid: instanceid,
      invnumber: instanceid,
    })
  }

  const onCreateNewBook = () => {
    if (modalType === 'ADD' && !save) {
      saveDocument(value)
    }
    if (modalType === 'EDIT') {
      const params = {
        title: '',
        publisher: '',
        year: 0,
      }

      api.post('/book', params).then(res => {
        requestData(res.data.book.id)
        setShowLibraryItemModal(true)
      })
    }
  }

  const onAddBook = () => {
    if (modalType === 'ADD' && !save) {
      saveDocument(value)
      setSearchOpen(!searchOpen)
    } else {
      setSearchOpen(!searchOpen)
    }

    if (modalType === 'EDIT') {
      setSearchOpen(!searchOpen)
    }
  }

  const onSelectFromSearch = item => {
    if (modalType === 'EDIT') {
      onModalSave(item)
    }
  }

  const closeModal = () => {
    booksCleanup()
    setModalData({ modalType: null, data: null })
    setSearchOpen(false)
    setCurrentBook(null)
    setCurrentBookData(null)
    clearSearchList()
  }

  const saveDocument = ({
    documentname,
    documentnumber,
    documenttype,
    documentdate,
    supplier,
    suppliername,
    library,
    description,
    view,
    document_arrival_date,
    document_serial_number,
    disposal_direction,
    disposal_date,
    disposal_org,
    exclusion_reason
  }) => {
    const { id } = data
    const params = {
      id,
      library,
      documentname,
      documentdate,
      documentnumber,
      description,
      suppliername,
      supplier,
      documenttype: Number(documenttype),
      view: parseInt(view),
      document_arrival_date,
      document_serial_number,
      disposal_direction,
      disposal_date,
      disposal_org,
      exclusion_reason
    }

    onSave(params)
    setSave(true)
    setSearchOpen(false)
    setModalData({ modalType: EDIT, data: { ...params } })
  }

  useEffect(() => {
    if (documentId) {
      requestBooks(documentId)
    }
  }, [])
  
  useEffect(() => {
    if (!!current) {
      if (!modalData.data.id) {

        // в current записываем значения только что созданного документа, а при закрытии модалки отчищаем поле
        // и для того чтобы сразу же начать с ним работать, мы записываем данные current в modalData
        setModalData({ ...modalData, data: { id: modalData.data.id, ...current } })
   
      }
    }
  }, [current])


  const renderPDFBlock = () => {
    const magazinesMode = data.view == 3 || data.view == 4 
    const clientInfo = clients?.find(({idclient}) => idclient == clientData?.own_client_id) || {}
    const libName = libraries?.find(({id}) => id == data.library)?.name
    const supplierName = institutionsList.find(({value}) => value == data.supplier)?.label
    switch (documentType) {
      // Шифр
      case 19: return <MaterialDestructionActPDFPrint actData={data} data={books} options={options} />
      case 20: return <SecretDestructionActPdf data={books} actData={data} options={options}/>
      case 18: return <WriteOfActPDFPDFPrint data={books} actData={data} options={options} clientInfo={clientInfo} ranks={ranks} libName={libName} supplierName={supplierName}/>
      case 12: return <SeizureAct data={books} actData={data} options={options}/>
      case 1: return <ReceiptActPdf data={books} actData={data} options={options} magazinesMode={magazinesMode} clientInfo={clientInfo} libName={libName} supplierName={supplierName}/>
      default:
        return;
    }
  }

  return (
    <ModalWindow
      title={`Документ: ${modalType === EDIT ? 'редактирование' : 'добавление'}`}
      isOpen={true}
      onRequestClose={closeModal}
      width={1000}
    >
      {!!books && renderPDFBlock()}
      {modalData && (
        <ModalHeader
          modalData={data}
          setModalData={setModalData}
          libraries={libraries}
          modalType={modalType}
          institutionsList={institutionsList}
          types={types}
          saveDocument={saveDocument}
          setFormValidity={setFormValidity}
          outsecrecy={options.outsecrecy}
          setSearchOpen={setSearchOpen}
          setSave={setSave}
          save={save}
          documentType={documentType}
          setValue={setValue}
          fromMobLib={fromMobLib}
          fromLostDoc={fromLostDoc}
          fromLostDocForTakeAct={fromLostDocForTakeAct}
          disabled={disabled}
        />
      )}
      <DocumentsEdit
        modalData={modalData}
        setModalData={setModalData}
        documentType={documentType}
        onAddBook={onAddBook}
        formValidity={formValidity}
        searchOpen={searchOpen}
        onCreateNewBook={onCreateNewBook}
        clientData={clientData}
        requestBooks={requestBooks}
        currentBook={currentBook}
        setCurrentBook={setCurrentBook}
        documentId={documentid}
        onSelectFromSearch={onSelectFromSearch}
        disabled={disabled}
        coeffs={coeffs}
        fromLostDocForTakeAct={fromLostDocForTakeAct}
        fromInvBook={fromInvBook}
        fromLostDoc={fromLostDoc}
      />
      {showLibraryItemModal && (
        <CreateNewLibraryItemModal
          modalData={modalData}
          showLibraryItemModal={showLibraryItemModal}
          setShowLibraryItemModal={setShowLibraryItemModal}
          bookData={bookData}
          onSelectFromSearch={onSelectFromSearch}
        />
      )}
    </ModalWindow>
  )
}

DocumentsModal.propTypes = {
  modalData: T.object,
  setModalData: T.func,
  types: T.array,
  onSave: T.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsModal)
