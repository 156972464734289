import {
  OUTSECRECY_COUNT_REQUEST,
  SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const outsecrecyCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${OUTSECRECY_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};
