import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import { updateLib, createLib, getCurrentLib, deleteFile, addFile } from '../../../api/mobileLibrary'
import { CURRENT_MOBILE_LIBRARY_REQUEST, MOBILE_LIBRARY_CREATE_REQUEST, MOBILE_LIBRARY_UPDATE_REQUEST, START } from '../../actions/action-types'
import { currentMobileLibraryRequestStart, currentMobileLibraryRequestSuccess } from '../../actions/mobileLibrary/library/current'
import { updateMobileLibraryRequestError, updateMobileLibraryRequestSuccess } from '../../actions/mobileLibrary/library/update'
import { createMobileLibraryRequestError, createMobileLibraryRequestSuccess } from '../../actions/mobileLibrary/library/create'
import { getResponsibleRequestStart } from '../../actions/mobileLibrary/responsible/list'

export default function* currentLibWatcher() {
  yield takeLatest(`${CURRENT_MOBILE_LIBRARY_REQUEST}${START}`, currentLibWorker)
  yield takeLatest(`${MOBILE_LIBRARY_CREATE_REQUEST}${START}`, createLibWorker)
  yield takeLatest(`${MOBILE_LIBRARY_UPDATE_REQUEST}${START}`, updateLibWorker)
}


function* currentLibWorker ({ libId }) {
  try {
    const response = yield call(getCurrentLib, libId)
    yield put(currentMobileLibraryRequestSuccess(response.data))
  } catch (error) {
  }
}

function* updateLibWorker ({ payload }) {
  const {id, mobile_library_files: files, mobile_library_document, ...data} = payload
  try {
    
    yield call(updateLib, id, data)
    const existFiles = yield select(state => state.mobileLibrary.current?.mobile_library_files)

    const resultIds = files.filter(({id}) => id).map(({id}) => id)
    const filesForDelete = existFiles.filter(({id}) => !resultIds.includes(id))
    const filesForAdd = files.filter(({id}) => !id)
    
    yield all(filesForDelete.map(({id}) => call(deleteFile, id)))
    yield all(filesForAdd.map(file => call(addFile, id, file)))

    yield put(updateMobileLibraryRequestSuccess())
    
  } catch (error) {
    yield put(updateMobileLibraryRequestError())
  }
}

function* createLibWorker ({payload: {mobile_library_files, ...data}}) {
  try {
    const payload = {...data, contract_scan: mobile_library_files[0]}
    const response = yield call(createLib, payload)
    yield put(currentMobileLibraryRequestStart(response.data.id))
  } catch (error) {
    yield put(createMobileLibraryRequestError())
  }
}