import React from 'react'
import T from 'prop-types'
import styles from './CoversSearchFormControls.module.css'
import classNames from 'classnames'
import { Button, Space } from 'antd'


const CoversSearchFormControls = ({ onSubmit, onClear, disableAlphabet }) => {

  return (
    <div className={classNames(styles.container, 'd-flex justify-content-end')} style={{margintTop: '1rem'}}>
      <Space>
        {!disableAlphabet && (
          <Button href='/library/alphabet' className={styles.button}>
            Алфавитный указатель
          </Button>
        )}
        <Button onClick={onClear}>Очистить</Button>
          <Button type='primary' onClick={() => onSubmit()}>Найти </Button>
      </Space>
    </div>
  )
}

CoversSearchFormControls.propTypes = {
  onSubmit: T.func,
  onClear: T.func,
}

export default CoversSearchFormControls
