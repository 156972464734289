import {SET_PDF_VIEWER_FILE_DATA} from "../../../actions/action-types";

const initialState = {
  bookId: '',
  fileId: ''
};


export const pdfViewerFileDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PDF_VIEWER_FILE_DATA:
      return action.payload;

    default: return state;
  }
};
