import {
  LAYOUT_OPTIONS_GET_REQUEST,
  SET_LAYOUT_OPTIONS_DATA,
  SUCCESS
} from '../../../actions/action-types';

const initialState = {
  "header": {
    "background": "#376fb9",
    "image": ""
  },
  "menu": {
    "background": "#204778",
    "hover": "#68ade6"
  },
  "body": {
    "backgroundimage": ""
  }
};

export const optionsLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LAYOUT_OPTIONS_GET_REQUEST}${SUCCESS}`:
      return action.payload;
    case `${SET_LAYOUT_OPTIONS_DATA}`:
      return action.payload;

    default:
      return state;
  }
};
