import { MESSAGE_LIST_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const messageListRequestStart = () => ({
  type: `${MESSAGE_LIST_REQUEST}${START}`,
});

export const messageListRequestSuccess = (response) => ({
  type: `${MESSAGE_LIST_REQUEST}${SUCCESS}`,
  payload: response
});

export const messageListRequestError = (error) => ({
  type: `${MESSAGE_LIST_REQUEST}${ERROR}`,
  payload: error
});
