import { all } from 'redux-saga/effects';
import libraryListSaga from "./list/libraryListSaga";
import librarySaveSaga from "./save/librarySaveSaga";
import libraryDeleteSaga from "./delete/libraryDeleteSaga";
import libraryInstitutionsSaga from "./institutions/libraryInstitutionsSaga";
import libraryListFundSaga from "./fund/libraryListFundSaga";
import libraryListPointSaga from "./point/libraryListPointSaga";

export default function* libraryInfoSaga() {
  yield all([
    libraryListSaga(),
    librarySaveSaga(),
    libraryDeleteSaga(),
    libraryInstitutionsSaga(),
    libraryListFundSaga(),
    libraryListPointSaga()
  ]);
}