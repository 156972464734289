import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Pagination from '../../components/Pagination'
import { setRemcomplectSearchData } from '../../redux/actions/remcomplect/search'
import { remcomplectActions } from '../../redux/factory/remcomplectReduxData'
import Items from '../../components/Remcomplect/Items'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import RemcomplectPdfPrint from '../../components/Remcomplect/PdfPrint'
import { OrderMenu } from '../../components/Remcomplect/OrderMenu'

const mapStateToProps = ({ remcomplect }) => ({
  searchData: remcomplect.search,
  list: remcomplect.list,
  count: remcomplect.count,
})

const matchDispatchToProps = {
  setSearchData: setRemcomplectSearchData,
  requestList: remcomplectActions.countRequestStart,
}

const Remcomplect = ({ searchData, count, setSearchData, requestList, list }) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  return (
    <SceneContainer title={'Рекомплектование'}>
      <PaginationComponent />
      <RemcomplectPdfPrint tableData={list} orientation={'portrait'} />
      <OrderMenu requestList={requestList} />
      <Items data={list} />
      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, matchDispatchToProps)(Remcomplect)
