import { ERROR, OPTIONS_DIARY_TYPES_UPDATE, START, SUCCESS } from "../../action-types";

export const optionsDiaryTypesUpdateStart = (data) => ({
  type: `${OPTIONS_DIARY_TYPES_UPDATE}${START}`,
  payload: data
});

export const optionsDiaryTypesUpdateSuccess = (data) => ({
  type: `${OPTIONS_DIARY_TYPES_UPDATE}${SUCCESS}`,
  payload: data
});

export const optionsDiaryTypesUpdateError = (data) => ({
  type: `${OPTIONS_DIARY_TYPES_UPDATE}${ERROR}`,
  payload: data
});