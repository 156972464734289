import { INVENTORY_DELETE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import { createNotification } from '../../../../components/notification/notification';

export const inventoryDeleteRequestStart = (data) => ({
  type: `${INVENTORY_DELETE_REQUEST}${START}`,
  payload: data
});

export const inventoryDeleteRequestSuccess = (data) => {
  createNotification('success', 'Документ успешно удален');
  return {
    type: `${INVENTORY_DELETE_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const inventoryDeleteRequestError = (data) => {
  createNotification('error', 'Не удалось удалить документ');
  return {
    type: `${INVENTORY_DELETE_REQUEST}${ERROR}`,
    payload: data
  };
};
