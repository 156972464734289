import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SearchBooksItem.module.css'
import { Col, Row } from 'react-bootstrap'
import CoverPhotoPlaceholder from '../../Covers/List/Item/CoverPhotoPlaceholder'
import { statusList } from '../../../constants/statusList'
import OpenPhotoModal from '../../OpenPhotoModal'
import { resolveBookCoverUrl } from '../../../utils/resolvePhotoUrl'
import CoverListItemInfo from '../../Covers/List/Item/InfoBlock'
import ItemCatalogCard from './ItemCatalogCard'
import { Checkbox } from 'antd'
import { useSelector } from 'react-redux'

const SearchBooksItem = ({ data, termOfUse, onSelect, isChecked, onClick, children }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [secrId, setSecrId] = useState(null);
  
  const lib = useSelector(state => state?.library?.types?.list);
  
   // Шифр
   const secr = [
    {
        "id": "1",
        "name": "<Не выбрано>"
    },
    {
        "id": "2",
        "name": "дсп"
    },
    {
        "id": "3",
        "name": "секретно"
    },
    {
        "id": "4",
        "name": "несекретно"
    }
];

const {
  authors,
  title,
  year,
  bbk,
  udk,
  publishers,
  pageNum,
  annotation,
  isbn,
  authorSign,
  pubinstanceamount,
} = data;

    const firstAuthor = authors?.split(',')[0];
    const anotherAuthors = authors?.split(',').slice(1, authors.length - 1).join('');
    const allPublishers = publishers;

  useEffect(() => {
    const val = data?.eerOutSecrecyId && secr.filter(el => el.id === data?.eerOutSecrecyId)[0].name;
    setSecrId(val);
  }, [data])

  // const outdated = termOfUse && data.year + termOfUse < new Date().getFullYear()
  const outdated = data.bookduedate && data.year + data.bookduedate < new Date().getFullYear()

  const cutList = (list) => {
    let cutList = '';
    if (list.split(',').length > 2) {
      cutList = list.split(',').slice(1, 3).join('');
      cutList = cutList + ' и др.'
    } else {
      cutList = list;
    }
    return cutList;
  } 

  const getMasCut = (list) => {
    let cutList = '';
    if (list.length > 2) {
      cutList = data?.availableinstances.map((val, index) => index).join(', ').split(', ').slice(1, 3).join(', ');
      cutList = cutList + ' и др.'
    } else if (list.length > 0 && list.length < 3) {
      cutList = data?.availableinstances.map((val, index) => index).join(', ').split(', ').slice(1, 3).join(', ');
    } 
    return cutList;
    
  }

  const documentType = lib.find(el => el.id == data?.typeId)?.name || 'нет'

  return (
    <div onClick={onClick ? () => onClick(data) : null} className={classNames(styles.container, isChecked && styles.checked)}>
    {data.length ? 
      data.map((data)=> 
      <>
      <Row>
        <Col md={3} lg={2}>
          <CoverPhotoPlaceholder url={data.coverImage} bookId={data.bookid} alt={data.title} />
          <div className={styles.status}>{statusList[data.verifyStatus]}</div>
          <OpenPhotoModal
            src={data.subscriptionCover && resolveBookCoverUrl(data.subscriptionCover, data.bookid)}
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
          />
          <ItemCatalogCard label={'Каталожная карточка'} data={data} setModalVisible={setModalVisible} />
        </Col>
        <Col>
        <Row>
        <Col md={{ span: 9, offset: 3 }}>
          <a href={`/book/${data.bookid}`} className={styles.title} target='blank'>
            {data.title}
          </a>
          <div className={styles.value}>{data.authors}</div>
        </Col>
        
        
            <Col md={12}>
                <CoverListItemInfo label={'Год'} value={data.year || data[0]?.year } />
                <CoverListItemInfo label={'ISBN'} value={data.isbn} />
                <CoverListItemInfo label={'Размещение'} value={data.clients || data[0]?.publisher_info[0]?.publisher_name || 'n/a'} />
                <CoverListItemInfo label={'Фонд'} value={data.fund || 'n/a'} />
                <CoverListItemInfo label={'Страниц'} value={data.pageNum} />
                <CoverListItemInfo label={'Издательство'} value={data.publicationPlace || data.publishers} />
                <CoverListItemInfo label={'Гриф'} value={secrId} />
                <CoverListItemInfo label={'Инвентарные номера'} value={data?.number ? cutList(data?.number) : ''} />
                <CoverListItemInfo label={'Номера экземпляров'} value={data?.availableinstances ? getMasCut(data?.availableinstances) : ''} />
              
                <CoverListItemInfo label={'ББК/УДК/СВНИ'} value={`${data.bbk || 'нет'} / ${data.udk || 'нет'} / ${data.svni || 'нет'}`} />
                <CoverListItemInfo label={'Тип документа'} value={documentType} />
                <CoverListItemInfo type="ta"
                 label={'Библиографическое описание'} 
                 value={`${firstAuthor || data[0]?.author_info?.[0]?.author_name || data?.author_info?.[0]?.author_name ||  'Автор не задан'},
                 ${title || data[0]?.title || ''} / ${anotherAuthors || ''}
                 ${year || data[0]?.year || ''} ${(pageNum || '-') && ("-" +  pageNum || '-' + " c.") && ''} ${pubinstanceamount ? pubinstanceamount + " экз." : ""} ${annotation ? annotation : '' }
                  ISBN ${isbn || '-'}
                  изд-во: ${allPublishers || data[0]?.publisher_info[0]?.publisher_name ||  ''}
                  ББК: ${bbk || '-'} 
                  УДК: ${udk || '-'}`
                 } />
                
              </Col>
         
            {outdated && (
              <Col md={{ span: 5, offset: 3 }}>
                <div className={'color-red'}>Превышен срок использования</div>
              </Col>
            )}
          </Row>
        </Col>
        {onSelect && (
          <Col md={1} lg={1}>
            <Checkbox onChange={() => onSelect(data.bookid)} checked={isChecked}></Checkbox>
          </Col>
        )}
        {children && (
          <Col md={12} className={'text-right'}>
            {children}
          </Col>
        )}
      </Row>
      </>
      ) 
     : 
    <>
    <Row>
      <Col md={3} lg={2}>
        <CoverPhotoPlaceholder url={data.coverImage} bookId={data.bookid} alt={data.title} />
        <div className={styles.status}>{statusList[data.verifyStatus]}</div>
        <OpenPhotoModal
          src={data.subscriptionCover && resolveBookCoverUrl(data.subscriptionCover, data.bookid)}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
        />
        <ItemCatalogCard label={'Каталожная карточка'} data={data} setModalVisible={setModalVisible} />
      </Col>
      <Col>
        <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <a href={`/book/${data.bookid}`} className={styles.title} target='blank'>
                {data.title}
              </a>
              <div className={styles.value}>{data.authors}</div>
            </Col>
              
                <Col md={12}>
                    <CoverListItemInfo label={'Год'} value={data.year || data[0]?.year } />
                    <CoverListItemInfo label={'ISBN'} value={data.isbn} />
                    <CoverListItemInfo label={'Размещение'} value={data.clients || data[0]?.publisher_info[0]?.publisher_name || 'n/a'} />
                    <CoverListItemInfo label={'Фонд'} value={data.fund || 'n/a'} />
                    <CoverListItemInfo label={'Страниц'} value={data.pageNum} />
                    <CoverListItemInfo label={'Издательство'} value={data.publicationPlace || data.publishers} />
                    <CoverListItemInfo label={'Гриф'} value={secrId} />
                    <CoverListItemInfo label={'Инвентарные номера'} value={data?.number ? cutList(data?.number) : ''} />
                    <CoverListItemInfo label={'Номера экземпляров'} value={data?.availableinstances ? getMasCut(data?.availableinstances) : ''} />
                  
                    <CoverListItemInfo label={'ББК/УДК/СВНИ'} value={`${data.bbk || 'нет'} / ${data.udk || 'нет'} / ${data.svni || 'нет'}`} />
                    <CoverListItemInfo label={'Тип документа'} value={documentType} />
                    <CoverListItemInfo type="ta"
                    label={'Библиографическое описание'} 
                    value={`${firstAuthor ||  'Автор не задан'},
                    ${title || data[0]?.title || ''} / ${anotherAuthors || ''}
                    ${year || data[0]?.year || ''} ${(pageNum || '-') && ("-" +  pageNum || '-' + " c.") && ''} ${pubinstanceamount ? pubinstanceamount + " экз." : ""} ${annotation ? annotation : '' }
                      ISBN ${isbn || '-'}
                      изд-во: ${allPublishers || data[0]?.publisher_info[0]?.publisher_name ||  ''}
                      ББК: ${bbk || '-'} 
                      УДК: ${udk || '-'}`
                    } />
                    
                  </Col>
            
                {outdated && (
                  <Col md={{ span: 5, offset: 3 }}>
                    <div className={'color-red'}>Превышен срок использования</div>
                  </Col>
                )}
        </Row>
      </Col>
      {onSelect && (
        <Col md={1} lg={1}>
          <Checkbox onChange={() => onSelect(data.bookid)} checked={isChecked}></Checkbox>
        </Col>
      )}
      {children && (
        <Col md={12} className={'text-right'}>
          {children}
        </Col>
      )}
    </Row>
    </>
      }
  </div>
  )
}

SearchBooksItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
}

export default SearchBooksItem
