import { NEW_BOOKS_ORDER_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const newBooksOrderListRequestStart = () => ({
    type: `${NEW_BOOKS_ORDER_REQUEST}${START}`
});

export const newBooksOrderListRequestSuccess = (data) => ({
    type: `${NEW_BOOKS_ORDER_REQUEST}${SUCCESS}`,
    payload: data
});

export const newBooksOrderListRequestError = (data) => ({
    type: `${NEW_BOOKS_ORDER_REQUEST}${ERROR}`,
    payload: data
});
