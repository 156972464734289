import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {rolesListRequestStart} from "../../../redux/actions/roles/list";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import {SORT_DIRECTION} from "../../../constants/sortDirections";
import Pagination from "../../../components/Pagination";
import {permissionsByLiterCountRequestStart} from "../../../redux/actions/permissions/byLiters/count";
import {setPermissionsByLiterFormData} from "../../../redux/actions/permissions/byLiters/form";
import {setPermissionsByLiterSearchData} from "../../../redux/actions/permissions/byLiters/search";
import {permissionsByLiterEditRequestStart} from "../../../redux/actions/permissions/byLiters/edit";
import {permissionsByLiterAddRequestStart} from "../../../redux/actions/permissions/byLiters/add";
import {permissionsByLitersGroupChangesStart} from "../../../redux/actions/permissions/byLiters/groupChange";
import LitersPermissionsDataTable from "../../../components/AdminPermissions/LitersPermissions/DataTable";
import PermissionsSearchForm from "../../../components/AdminPermissions/SearchForm";
import Button from '../../../components/Button';


const mapStateToProps = ({roles, accessLiters}) => ({
    roles: roles.list,
    list: accessLiters.list,
    count: accessLiters.count,
    loader: accessLiters.loader,
    formData: accessLiters.form,
    searchData: accessLiters.search,
});

const mapDispatchToProps = {
    requestList: permissionsByLiterCountRequestStart,
    requestRoles: rolesListRequestStart,
    setFormData: setPermissionsByLiterFormData,
    setSearchData: setPermissionsByLiterSearchData,
    setAccessStatus: permissionsByLiterEditRequestStart,
    addCategoryToRole: permissionsByLiterAddRequestStart,
    startGroupChange: permissionsByLitersGroupChangesStart
};

const SeriesPermissionsScene = (
    {
        roles,
        requestRoles,
        list,
        requestList,
        formData,
        setFormData,
        count,
        loader,
        searchData,
        setSearchData,
        setAccessStatus,
        addCategoryToRole,
        startGroupChange
    }
) => {
    useEffect(() => {
        !roles.length && requestRoles();
    }, [roles, requestRoles]);

    const setSort = (sortBy) => {
        let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ?
            SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;

        setSearchData({
            ...searchData,
            sortDirection: sortDir,
            'orderBy': sortDir === SORT_DIRECTION.ASC ? sortBy : null,
            'orderByD': sortDir === SORT_DIRECTION.DESC ? sortBy : null,
            page: 1
        });
        requestList();
    };

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList();
    };

    const onChangeAccessStatus = (event, series) => {
        const {id, category, role} = series
        const {value} = event.target
        const params = {
            role: role,
            access: value || null,
            literid: category,
            itemid: id
        };
        id ? setAccessStatus(params) : addCategoryToRole(params)
    };

    return (
        <SceneContainer title={'Администрирование / Настройка прав доступа по литерам'}>
            <PermissionsSearchForm
                onSubmit={requestList}
                formData={formData}
                setFormData={setFormData}
                roles={roles}
            />

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <>
                    {!!list.length &&
                    <div>
                        <Button label="Установить 'Чтение' на все литеры" colorType='red' onClick={startGroupChange} />
                        <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                                    setCurrent={setCurrentPage}/>

                        <LitersPermissionsDataTable data={list} setSort={setSort} sortBy={searchData.sortBy}
                                                    onChange={onChangeAccessStatus}/>

                        <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                                    setCurrent={setCurrentPage}/>
                    </div>
                    }
                </>
            }

        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SeriesPermissionsScene);
