import { ERROR, QR_CODES_REQUEST, START, SUCCESS } from "../action-types";

export const qrCodesRequestStart = (data) => ({
  type: `${QR_CODES_REQUEST}${START}`,
  payload: data
});

export const qrCodesRequestSuccess = (data) => ({
  type: `${QR_CODES_REQUEST}${SUCCESS}`,
  payload: data
});

export const qrCodesRequestError = (data) => ({
  type: `${QR_CODES_REQUEST}${ERROR}`,
  payload: data
});

