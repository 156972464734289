import { COLLECTIONS_SAVE_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const collectionsSaveRequestStart = (data, callback) => ({
  type: `${COLLECTIONS_SAVE_REQUEST}${START}`,
  payload: data, callback
});

export const collectionsSaveRequestSuccess = (data) => ({
  type: `${COLLECTIONS_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const collectionsSaveRequestError = (data) => ({
  type: `${COLLECTIONS_SAVE_REQUEST}${ERROR}`,
  payload: data
});
