import {api} from "../../App";

export const getFundMoving = ({from, to, ...data}) => {
    const params = {
        from,
        to,
        ...data
    };

    return api.get(`/fund_moving`, {params});
};
