import { DOCUMENT_BOOK_CLEANUP, DOCUMENT_BOOK_LIST_REQUEST, SUCCESS } from '../../../actions/action-types'

const initialState = []

export const documentBooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${DOCUMENT_BOOK_LIST_REQUEST}${SUCCESS}`:
      return action.payload

    case `${DOCUMENT_BOOK_CLEANUP}`:
      return []

    default:
      return state
  }
}
