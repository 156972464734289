import React, { useState, useMemo } from "react";
import { DragAndDrop, Drag, Drop } from "./drag-and-drop";
import { reorder } from "./helpers.js";
import { Col, Row } from 'react-bootstrap';
import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from './MenuEditor.module.css';
import { Checkbox } from 'antd'

export const NestedListComponent = ({ list, onChange, handleAddMenu, handleDeleteFirst, handleDeleteSecond, categories, setCategories, setmenuList, files, setFiles, role }) => {

  const [dropDown, setDropDown] = useState('');

  const getNormal = (values) => {
    // Шифр
    return values.filter(el => el.parent_id == 0).map(el => ({...el, items: values.filter(_el => _el.parent_id == el.id)}))
  }

  const handleAddMenuLocal = (category, isFile) => {
    handleAddMenu(category, isFile);
    setDropDown(category.id);
  }

  useMemo(() => {
    list && setCategories(list)
  }, [list])

  useMemo(() => {
    let newList = getNormal(list.map(el => ({...el, id: String(el.id)})));
    setCategories(newList);
  }, [list])

  const handleDragEnd = (result) => {
    let updatedCategories
    const { type, source, destination } = result;
    if (!destination) return;

    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    // Reordering items
    if (type === "droppable-item") {
      // If drag and dropping within the same category
      if (sourceCategoryId == destinationCategoryId) {
        const updatedOrder = reorder(
          categories.find((category) => category.id == sourceCategoryId).items,
          source.index,
          destination.index
        );
        updatedCategories = categories.map((category) =>
          category.id !== sourceCategoryId
            ? category
            : { ...category, items: updatedOrder }
        );
      } else {
        const sourceOrder = categories.find(
          (category) => category.id == sourceCategoryId
        ).items;
        const destinationOrder = categories.find(
          (category) => category.id == destinationCategoryId
        ).items;

        const [removed] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, removed);

        destinationOrder[removed] = sourceOrder[removed];
        delete sourceOrder[removed];

        updatedCategories = categories.map((category) =>
          category.id == sourceCategoryId
            ? { ...category, items: sourceOrder }
            : category.id == destinationCategoryId
            ? { ...category, items: destinationOrder }
            : category
        );
      }
    }

    // Reordering categories
    if (type === "droppable-category") {
      updatedCategories = reorder(
        categories,
        source.index,
        destination.index
      );

    }
    setCategories(updatedCategories);

  };

  const handleDrop = (id) => {
    if (id === dropDown) {
      setDropDown('');
    } else {
    setDropDown(id);
    }
  }

  const handleVisible = (item) => {
    let newList = list.map(el => el.id == item.id ? ({...el, visible: !el.visible}) : el)
    setmenuList(newList);
}

  const handleAddFile = (item, e) => {
    const newFileList = [...files].filter(el => el.parent_id != item.id);
    setFiles([...newFileList, {parent_id: item.id, url: e.target.files[0].name, file: e.target.files[0]}])

    const newList = list.map(el => el.id == item.id ? ({...el, url: `${e.target.files[0].name}`}) : el)
    setmenuList(newList);
  } 

  const filteredCategories = categories.filter(cat => !cat.hasOwnProperty('add') || cat.add === true)

    // Выбрать/Убрать роль
  const handleCheck = (cat, id) => {

    if (cat.accessed_roles != null && cat.accessed_roles.includes(Number(id))) {
      const newList = 
      list?.map(el => el.id == cat.id ? ({...el, accessed_roles: el.accessed_roles?.filter(_el => _el != id).length == 0 ? null : el.accessed_roles?.filter(_el => _el != id)}) : el)
      
      setmenuList(newList);
      
    } else {
      const newList = list?.map(el => {
        if (el.id == cat.id) {
           return {...el, accessed_roles: el.accessed_roles == null ? [Number(id)] : [...el.accessed_roles, Number(id)]} 
        } else {
          return el
        }
      })
      setmenuList(newList);
    }
  };

  // Выбрать/Убрать все роли
  const handleCheckAll = (cat) => {
    if (cat.accessed_roles != null && cat.accessed_roles.length == role.length) {
      const newList = list?.map(el => el.id == cat.id ? ({...el, accessed_roles: null }) : el)
      setmenuList(newList);
    } else {
      const arr = role.map((item) => Number(item.pmid));
      const newList = list?.map(el => el.id == cat.id ? ({...el, accessed_roles: [...arr] }) : el)
      setmenuList(newList);
    }
  };

  return (
    <DragAndDrop onDragEnd={handleDragEnd}>
      <Drop id="droppable" type="droppable-category">
        {filteredCategories.map((category, categoryIndex) => {
          const categoryItems = category.items.filter(cat => !cat.hasOwnProperty('add') || cat.add === true)
          return (
            <Drag
              className={styles.draggableCategory}
              key={category.id}
              id={category.id}
              index={categoryIndex}
              dropDown={dropDown}>
                <Row className='flex'>
                    
                    <i 
                    className={`${dropDown === category.id ? styles.dropDown : styles.dropDownActive}`}
                    onClick={() => handleDrop(category.id)}>
                    {category.items.length > 0 ? 
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2rem" width="2rem" xmlns="http://www.w3.org/2000/svg"><path d="M128 192l128 128 128-128z"></path></svg>
                    : <></>}
                    </i>
                    
                    <Col md={5} lg={3}>
                    <ModalInputWrapper
                        disabled={!category.editable}
                        // label={el.title}
                        name={category.id}
                        value={category.title}
                        onChange={(e) => onChange(e, 'title')}/>
                    </Col>
                    
                    <Col md={5} lg={4}>
                    <ModalInputWrapper
                        disabled={!category.editable || category.target_blank}
                        // label={el.url}
                        name={category.id}
                        value={category.url}
                        onChange={(e) => onChange(e, 'url')}/>
                    </Col>

                    
                    {
                      !category.target_blank ?
                      <div className={styles.wrapperOut}>
                      <i 
                        className={styles.addButton}>
                        <svg className={styles.addMenuItem} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg"><path d="M346.5 240H272v-74.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-74.5c-8.8 0-16 6-16 16s7.5 16 16 16H240v74.5c0 9.5 7 16 16 16s16-7.2 16-16V272h74.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path></svg>
                      
                        <div className={styles.addButtonWraper}>
                          <i className={styles.addLink} 
                          onClick={() => handleAddMenuLocal(category, false)}
                          title='Создать пункт меню'><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path></svg></i>
                          <i className={styles.addFile} 
                          title='Создать ссылку на файл' 
                          onClick={() => handleAddMenuLocal(category, true)}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"></path></svg></i>
                        </div>
                      </i>
                    </div>
                    :
                    <></>
                    }

                    {
                      category.target_blank ?
                      <div className={styles.wrapperAddFile}>
                      <i className={styles.addFileBut}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11 11V8H13V11H16V13H13V16H11V13H8V11H11Z"></path></svg>
                      <input 
                        onChange={(e) => handleAddFile(category, e)}
                        type="file"  accept=".pdf" name="file" /></i>
                      </div>
                      :
                      <></>
                    }

                    <div className={styles.wrapperVisible}>
                      <div className={styles.wrapperCheckBox}>
                        <div className={styles.checkBoxTitle}>С какой ролью запрещено:</div>
                        <div className={styles.checkBoxAll}>
                        <Checkbox checked={category.accessed_roles?.length == role.length} onChange={() => handleCheckAll(category)}>
                          Выбрать все
                        </Checkbox>
                        </div>
                          {role.map(el => 
                            <Checkbox onChange={() => handleCheck(category, el.pmid)} 
                            checked={category?.accessed_roles?.includes(el.pmid)}>
                              {el.name}
                            </Checkbox>
                          )}
                      </div>
                      {
                      category.visible ?
                      <i className={styles.iconVisible} title="скрыть" onClick={() => handleVisible(category)}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></i>
                      :
                      <i className={styles.iconVisible} title="показывать" onClick={() => handleVisible(category)}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 0 0 0-51.5zm-63.57-320.64L836 122.88a8 8 0 0 0-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 0 0 0 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 0 0 0 11.31L155.17 889a8 8 0 0 0 11.31 0l712.15-712.12a8 8 0 0 0 0-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 0 0-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 0 1 146.2-106.69L401.31 546.2A112 112 0 0 1 396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 0 0 227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 0 1-112 112z"></path></svg></i>  
                      }
                    </div>

                    {
                      category.editable ?
                      <i 
                      onClick={() => handleDeleteFirst(category.id)}    
                      className={styles.buttonDelete}>
                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg"><path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z"></path></svg>
                      </i>
                      :
                      <></>
                    }

                    </Row>  
              <div className="category-container">

                {
                  dropDown === category.id ?
                  <Drop key={category.id} id={category.id} type="droppable-item" className={styles.wrapper2Step}>
                    {categoryItems.map((item, index) => {
                      return (
                        <Drag
                          className="draggable"
                          key={item.id}
                          id={item.id}
                          index={index}
                          type={'second'}
                        >

                          <Row style={{marginLeft: '1rem'}}>
                          {
                          <>
                          <Col md={5} lg={4}>
                          <ModalInputWrapper
                              disabled={!item.editable}
                              // label={_el.title}
                              name={item.id}
                              value={item.title}
                              onChange={(e) => onChange(e, 'title')}/>
                              
                          </Col>
                          <Col md={5} lg={4}>
                          <ModalInputWrapper
                              disabled={!item.editable || item.target_blank}
                              // label={_el.url}
                              name={item.id}
                              value={item.url}
                              onChange={(e) => onChange(e, 'url')}/>
                          </Col>

                          <>
                          {
                            item.target_blank ?
                            <div className={styles.wrapperAddFile}>
                            <i className={styles.addFileBut}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11 11V8H13V11H16V13H13V16H11V13H8V11H11Z"></path></svg>
                            <input 
                              onChange={(e) => handleAddFile(item, e)}
                              type="file"  
                              accept=".pdf" 
                              name="file" /></i>
                            </div>
                            :
                            <></>
                          }
                          </>

                          <div className={styles.wrapperVisible}>
                          <div className={styles.wrapperCheckBox}>
                            <div className={styles.checkBoxTitle}>С какой ролью запрещено:</div>
                            <div className={styles.checkBoxAll}>
                            <Checkbox checked={item.accessed_roles?.length == role.length} onChange={() => handleCheckAll(item)}>
                              Выбрать все
                            </Checkbox>
                            </div>
                            {role.map(el => 
                              <Checkbox onChange={() => handleCheck(item, el.pmid)} 
                              checked={item?.accessed_roles?.includes(el.pmid)}>
                                {el.name}
                              </Checkbox>
                            )}
                          </div>
                          {
                            item.visible ?
                            <i className={styles.iconVisible} title="скрыть" onClick={() => handleVisible(item)}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></i>
                            :
                            <i className={styles.iconVisible} title="показывать" onClick={() => handleVisible(item)}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 0 0 0-51.5zm-63.57-320.64L836 122.88a8 8 0 0 0-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 0 0 0 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 0 0 0 11.31L155.17 889a8 8 0 0 0 11.31 0l712.15-712.12a8 8 0 0 0 0-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 0 0-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 0 1 146.2-106.69L401.31 546.2A112 112 0 0 1 396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 0 0 227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 0 1-112 112z"></path></svg></i>
                          }
                          </div>

                          {
                            item.editable ?
                            <i 
                            onClick={() => handleDeleteSecond(item.id, item.url)}    
                            className={styles.buttonDelete}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z"></path></svg>
                            </i>
                            :
                            <></>
                          }
                          
                          </>    
                          }
                      </Row>
                        </Drag>
                      );
                    })}
                  </Drop>
                :
                <></>  
                }
              </div>
            </Drag>
          );
        })}
      </Drop>
    </DragAndDrop>
  );
};
