import React, { useEffect, useState } from 'react'
import { auditLogCountRequestStart } from '../../redux/actions/auditLog/count'
import { setAuditLogFormData } from '../../redux/actions/auditLog/formData'
import { setAuditLogSearchData } from '../../redux/actions/auditLog/searchData'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Pagination from '../../components/Pagination'
import AuditLogForm from '../../components/AuditLog/Form'
import AuditLogTable from '../../components/AuditLog/Table'
import { auditGetDatasetsRequestStart } from '../../redux/actions/auditLog/dataset'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'

const mapStateToProps = ({ auditLog }) => ({
  list: auditLog.list,
  loader: auditLog.loader,
  count: auditLog.count,
  formData: auditLog.form,
  searchData: auditLog.search,
  datasetList: auditLog.dataset,
})

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(auditLogCountRequestStart()),
  setFormData: data => dispatch(setAuditLogFormData(data)),
  setSearchData: data => dispatch(setAuditLogSearchData(data)),
  getDataset: () => dispatch(auditGetDatasetsRequestStart()),
})

const AuditLogScene = ({
  list,
  requestList,
  formData,
  setFormData,
  searchData,
  setSearchData,
  datasetList,
  getDataset,
  count,
  loader,
}) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  useEffect(() => {
    !datasetList.length && getDataset()
  }, [datasetList, getDataset])

  const [dir, toggleDir] = useState(true)

  const setSort = sortBy => {
    const direction = dir ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
    setSearchData({ ...searchData, sortBy, sortDirection: direction })
    toggleDir(!dir)
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const searchWithPageReset = () => {
    setSearchData({...searchData, page: 1})
    requestList()
  }

  return (
    <SceneContainer title='Контроль доступа к ресурсам'>
      <Row>
        <Col md={12}>
          <AuditLogForm data={formData} setData={setFormData} search={searchWithPageReset} dataset={datasetList} />
        </Col>
        {loader ? (
          <SpinnerOuter isLoading={loader} />
        ) : (
          <>
            <Col md={12}>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
            </Col>
            <Col md={12}>
              <AuditLogTable data={list} sortBy={searchData.sortBy} setSort={setSort} />
            </Col>
            <Col md={12}>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
            </Col>
          </>
        )}
      </Row>
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogScene)
