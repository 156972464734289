/*IMAGES*/
import readingImage from '../../img/books.jpg'
import videoImage from '../../img/video.jpg'
import tvImage from '../../img/television.jpg'
import audioImage from '../../img/audio__aa.jpg'
import presentImage from '../../img/presentation.jpg'
import documentImage from '../../img/image5.jpg'
import modelImage from '../../img/armata_06_s.jpg'
import nirImage from '../../img/nir.jpg'
import magazineImage from '../../img/bvc432gr.jpg'
import coursesImage from '../../img/534dgsgd.jpg'
import anplImage from '../../img/nf346tbds.jpg'
import panorama from '../../img/panorama.jpg'
import React from 'react'

export const libraryGridData = [
  {
    title: 'Образовательные ресурсы',
    subtitle: 'библиографических записей',
    image: readingImage,
    destination: '/reading',
    id: '1', // Шифр
  },
  {
    title: 'Военное видео',
    subtitle: 'видеозаписей',
    image: videoImage,
    destination: '/librarymaterials/5', // Шифр
    id: '5', // Шифр
  },
  {
    title: 'Цифровое телевидение',
    subtitle: '5 ТВ каналов',
    image: tvImage,
    destination: '/tv',
    id: '',
    isExternal: true,
  },
  {
    title: 'Панорамы',
    subtitle: 'панорам',
    image: panorama,
    destination: '/librarymaterials/16', // Шифр
    id: '16', // Шифр
  },
  {
    title: 'Аудио',
    subtitle: 'аудиозаписей',
    image: audioImage,
    destination: '/librarymaterials/6', // Шифр
    id: '6', // Шифр
  },
  {
    title: 'Презентации',
    subtitle: 'презентаций',
    image: presentImage,
    destination: '/librarymaterials/4', // Шифр
    id: '4', // Шифр
  },
  {
    title: 'Документы РГО',
    subtitle: 'документов',
    image: documentImage,
    destination: '/librarymaterials/17', // Шифр
    id: '17', // Шифр
  },
  {
    title: '3D модели',
    subtitle: 'моделей',
    image: modelImage,
    destination: '/librarymaterials/10', // Шифр
    id: '10', // Шифр
  },
  {
    title: 'Научные работы',
    subtitle: 'научных работ',
    image: nirImage,
    destination: '/librarymaterials/13', // Шифр
    id: '13', // Шифр
  },
  {
    title: 'Журналы, брошюры',
    subtitle: 'периодических изданий',
    image: magazineImage,
    destination: '/librarymaterials/11', // Шифр
    id: '11', // Шифр
  },
  {
    title: 'Электронные учебники',
    subtitle: 'электронных учебников',
    image: coursesImage,
    destination: '/librarymaterials/12', // Шифр
    id: '12', // Шифр
  },
  {
    title: 'Антиплагиат коллекция',
    subtitle: 'записей в базе данных',
    image: anplImage,
    destination: '/borrowing_test',
    id: '',
  },
]
