import { BORROWING_TEST_DELETE_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const borrowingTestDeleteRequestStart = (itemId) => ({
  type: `${BORROWING_TEST_DELETE_REQUEST}${START}`,
  payload: itemId
});

export const borrowingTestDeleteRequestSuccess = () => {
  createNotification(
    'success',
    `Успешно удалено`
  );

  return {
    type: `${BORROWING_TEST_DELETE_REQUEST}${SUCCESS}`
  }
};

export const borrowingTestDeleteRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка удаления. Статус: ${error.response.status}`
  );

  return {
    type: `${BORROWING_TEST_DELETE_REQUEST}${ERROR}`,
    payload: error
  };
};
