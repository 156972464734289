import React, {useState, useEffect} from 'react';
import styles from './DynamicCollectionFormItem.module.css';
import T from 'prop-types';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import {Space, Button} from "antd";

const emptyData = {
    field: "",
    fieldtext: ""
};

const fieldArray = [
    {name: "ISBN", value: "isbn"},
    {name: "ISBN подстрока", value: "isbnlike"},
    {name: "Автор", value: "authorid"},
    {name: "Издательство", value: "publisherid"},
    {name: "Заглавие", value: "title"},
    {name: "Заглавие подстрока", value: "titlelike"},
    {name: "Год", value: "year"},
    {name: "Год после", value: "yearmorethan"},
    {name: "Год до", value: "yearlessthan"},
    {name: "ББК", value: "bbk"},
    {name: "УДК", value: "udk"},
    {name: "Гриф", value: "eeroutsecrecyid"}
]

const DynamicCollectionFormItem = ({data, onSave, onDelete}) => {

    const [formData, setFormData] = useState(data || emptyData);

    useEffect(() => {
        if (!data) {
            setFormData(emptyData);
            return;
        }
        setFormData({...data});
    }, [data]);

    return (
        <div className={styles.container}>
            <Row>
                <Col md={4}>
                    <ModalInputWrapper
                        label={"Поле"}
                    >
                        <select
                            value={formData.field}
                            name={"field"}
                            onChange={({target}) => setFormData({...formData, [target.name]: target.value})}
                        >
                            {fieldArray.map((item, index) =>
                                <option key={'fd' + index} value={item.value}>{item.name}</option>
                            )}
                        </select>
                    </ModalInputWrapper>
                </Col>
                <Col md={4}>
                    <ModalInputWrapper
                        label={"Значение"}
                        value={formData.fieldtext}
                        name={"fieldtext"}
                        onChange={({target}) => setFormData({...formData, [target.name]: target.value})}/>
                </Col>
                <Col md={4}>
                    <ModalInputWrapper label="">
                        <Space>
                            <Button onClick={() => onSave(formData)}>
                                сохранить
                            </Button>
                            {(formData && formData.id !== undefined) &&
                            <Button onClick={() => onDelete(formData.id)} danger>
                                удалить
                            </Button>
                            }
                        </Space>
                    </ModalInputWrapper>
                </Col>
            </Row>
        </div>
    );
};

DynamicCollectionFormItem.propTypes = {
    data: T.object,
    onSave: T.func,
    onDelete: T.func
};

export default DynamicCollectionFormItem;
