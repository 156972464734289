import { SET_REPORTS_SEARCH_DATA } from '../../../actions/action-types'
import { SORT_DIRECTION } from '../../../../constants/sortDirections'
import { RESULTS_PER_PAGE } from '../../../../constants/pagination'

const initialState = {
  page: 1,
  // sortBy: "eventtime",
  // sortDirection: SORT_DIRECTION.DESC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  count: 0,
}

export const reportsSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORTS_SEARCH_DATA:
      return action.payload

    default:
      return state
  }
}
