import { LOST_DOC_DOCUMENTS_REQUEST,LOST_DOC_DOCUMENT_CREATE_REQUEST, LOST_DOC_DOCUMENT_UPDATE_REQUEST,LOST_DOC_DOCUMENT_CLEAN, SUCCESS } from '../../../actions/action-types'

const initialState = {
  write_off_act: null,
  receipt_act: null
}

export const actsReducer = (state = initialState, action) => {

  switch (action.type) {
    case `${LOST_DOC_DOCUMENTS_REQUEST}${SUCCESS}`:
        return action.payload;
    case `${LOST_DOC_DOCUMENT_CREATE_REQUEST}${SUCCESS}`:
        return action.payload;
    case `${LOST_DOC_DOCUMENT_UPDATE_REQUEST}${SUCCESS}`:
        return action.payload;
    case `${LOST_DOC_DOCUMENT_CLEAN}`:
        return initialState;
    default:
      return state;
  }
}


