import React, { useEffect, useState } from 'react'
import { rolesListRequestStart } from '../../redux/actions/roles/list'
import { connect } from 'react-redux'
import { roleSaveRequestStart } from '../../redux/actions/roles/save'
import { roleDeleteRequestStart } from '../../redux/actions/roles/delete'
import AdminRolesTable from '../../components/AdminRoles/Table'
import AdminRolesModal from '../../components/AdminRoles/Modal'
import { roleMembersListRequestStart } from '../../redux/actions/roles/membersList'
import { roleMemberSaveRequestStart } from '../../redux/actions/roles/saveMember'
import { roleMemberDeleteRequestStart } from '../../redux/actions/roles/deleteMember'
import { roleClearNewMembers } from '../../redux/actions/roles/newMembers'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'

const mapStateToProps = ({ roles }) => ({
  list: roles.list,
  members: roles.members,
  newMembers: roles.newMembers,
})

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(rolesListRequestStart()),
  saveRole: data => dispatch(roleSaveRequestStart(data)),
  deleteRole: id => dispatch(roleDeleteRequestStart(id)),
  requestMembers: id => dispatch(roleMembersListRequestStart(id)),
  saveRoleMember: data => dispatch(roleMemberSaveRequestStart(data)),
  deleteRoleMember: data => dispatch(roleMemberDeleteRequestStart(data)),
  roleClearNewMembers: () => dispatch(roleClearNewMembers()),
})

const AdminRolesScene = ({
  list,
  requestList,
  saveRole,
  deleteRole,
  members,
  requestMembers,
  saveRoleMember,
  deleteRoleMember,
  roleClearNewMembers,
  newMembers,
}) => {
  useEffect(() => {
    requestList()
  }, [requestList])

  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedId, setSelectedId] = useState(null)

  const onSubmitModal = data => {
    saveRole(data)
    setModalData({ modalType: null, data: null })
  }

  const handleSelect = id => setSelectedId(selectedId === id ? null : id)

  const onDelete = () => selectedId && deleteRole(selectedId)

  return (
    <SceneContainer title={'Администрирование / Роли'}>
      <AdminRolesModal
        onSaveRoleMember={memberId => {
          saveRoleMember({ roleId: (modalData.data || {}).pmid, memberId })
        }}
        onDeleteRoleMember={memberId => {
          deleteRoleMember({
            roleId: (modalData.data || {}).pmid,
            memberId,
          })
        }}
        onSubmit={onSubmitModal}
        modalData={modalData}
        setModalData={setModalData}
        members={members}
        requestMembers={requestMembers}
        roleClearNewMembers={roleClearNewMembers}
        newMembers={newMembers}
      />
      <AdminRolesTable
        data={list}
        setModal={setModalData}
        selectedId={selectedId}
        handleSelect={handleSelect}
        onDelete={onDelete}
      />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminRolesScene)
