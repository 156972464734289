import moment from 'moment'
import { SET_SCHEDULE_SEARCH_DATA } from '../../../actions/action-types'

const initialState = moment().format('YYYY-MM-DD')

export const scheduleSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULE_SEARCH_DATA:
      return action.payload
    default:
      return state
  }
}
