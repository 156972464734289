import { call, put, takeLatest } from "redux-saga/effects";
import { RACK_DELETE_REQUEST, RACK_SAVE_REQUEST, RACKS_LIST_REQUEST, START } from "../../../actions/action-types";
import {
  racksListRequestError,
  racksListRequestStart,
  racksListRequestSuccess
} from "../../../actions/location/racks/list";
import { addRack, deleteRack, editRack, getRacksList } from "../../../../api/location/racks";
import { rackSaveRequestError, rackSaveRequestSuccess } from "../../../actions/location/racks/save";
import { rackDeleteRequestError, rackDeleteRequestSuccess } from "../../../actions/location/racks/delete";

export default function* racksListWatcher() {
  yield takeLatest(`${RACKS_LIST_REQUEST}${START}`, racksListWorker);
  yield takeLatest(`${RACK_SAVE_REQUEST}${START}`, rackSaveWorker);
  yield takeLatest(`${RACK_DELETE_REQUEST}${START}`, rackDeleteWorker);
}

function* racksListWorker({ payload: roomId }) {
  try {
    const response = yield call(getRacksList, roomId);
    yield put(racksListRequestSuccess(roomId, response.data));
  } catch (error) {
    yield put(racksListRequestError(error));
  }
}

function* rackSaveWorker({ payload: { roomId, data } }) {
  try {
    const response = yield call(data.id ? editRack : addRack, roomId, data);
    yield put(rackSaveRequestSuccess(response.data));
    yield put(racksListRequestStart(roomId));
  } catch (error) {
    yield put(rackSaveRequestError(error));
  }
}

function* rackDeleteWorker({ payload: { roomId, rackId } }) {
  try {
    const response = yield call(deleteRack, roomId, rackId);
    yield put(rackDeleteRequestSuccess(response.data));
    yield put(racksListRequestStart(roomId));
  } catch (error) {
    yield put(rackDeleteRequestError(error));
  }
}