import React, { useEffect } from 'react'
import styles from './Item.module.css'
import CoverListItemInfo from '../../Covers/List/Item/InfoBlock'
import CollapseBlock from '../Collapse'

const WITHOUT_VALUE = 'Нет'

const Item = ({
  mergeBooks,
  item,
  requestBook,
  books,
  collapseId,
  setCollapseId,
  index,
  requestBooksClose,
  disabled
}) => {
  useEffect(() => {
    collapseId === index && requestBook(item)
  }, [collapseId])

  const onClose = () => {
    setCollapseId(false)
    requestBooksClose([])
  }

  const onItemClick = () => {
    if (disabled) return
    collapseId === index ? onClose() : setCollapseId(index)
  }

  return (
    <>
      <div
        className={styles.item}
        onClick={onItemClick}
        aria-expanded={collapseId}
      >
        <div className={styles.title}>{item.title || WITHOUT_VALUE}</div>
        <CoverListItemInfo label={'Дубликаты'} value={item.duplicates || WITHOUT_VALUE} />
        <CoverListItemInfo label={'Год'} value={item.year || WITHOUT_VALUE} />
        <CoverListItemInfo label={'Авторы'} value={item.authorstext || WITHOUT_VALUE} />
        <CoverListItemInfo label={'Страниц'} value={item.pageNum || WITHOUT_VALUE} />
        <CoverListItemInfo label={'Издатель'} value={item.publisherstext || WITHOUT_VALUE} />
      </div>
      <CollapseBlock
        collapseId={collapseId}
        books={books}
        index={index}
        mergeBooks={mergeBooks}
        closeMergeMode={onClose}
      />
    </>
  )
}

export default Item
