import { api } from '../../App'

export const getExemptTestsCount = (searchData) => {
  const params = {
    count: true,
    ...searchData,
  }

  return api.get('/exempttests', { params })
}

export const getExemptTests = ( {paginateFrom, ...searchData}) => {
  const params = {
    paginateFrom,
    ...searchData,
  }
  return api.get('/exempttests', { params })
}

export const createExemptTest = ({ title, date, reason }) => {
  const data = {
    title,
    date,
    reason,
  }

  return api.post('/exempttests', data)
}

export const editExemptTest = ({ title, date, reason, id }) => {
  const data = {
    title,
    date,
    reason,
  }

  return api.put(`/exempttests/${id}`, data)
}

export const deleteExemptTest = id => {
  return api.delete(`/exempttests/${id}`)
}
