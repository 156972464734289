import { api } from "../../App";


export const getBookDistribute = (documentId) => {
  return api.get(`/bookdistribute/${documentId}`);
};

export const setBookDistribute = (data) => {
  return api.post(`/bookdistribute`, data);
};

export const deleteBookDistribute = (data, documentId) => {
  return api.delete(`/bookdistribute/${documentId}`, data);
};
