import { call, put, takeLatest } from "redux-saga/effects";
import { ALPHABET_RANGE_REQUEST, START } from "../../../../actions/action-types";
import { alphabetRangeRequestError, alphabetRangeRequestSuccess } from "../../../../actions/library/alphabet/range";
import { alphabetRanges } from "../../../../../api/library/alphabet";

export default function* alphabetRangeWatcher() {
  yield takeLatest(`${ALPHABET_RANGE_REQUEST}${START}`, alphabetRangeWorker)
}

function* alphabetRangeWorker({payload: char}) {
  try {
    const response = yield call(alphabetRanges, char);
    const result = response.data.map(({index}) => index);
    yield put(alphabetRangeRequestSuccess(result));
  } catch (error) {
    yield put(alphabetRangeRequestError(error))
  }
}