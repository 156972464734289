import React, { useEffect, useState } from "react";
import styles from "./BookEditScene.module.css";
import { bookDataRequestStart } from "../../redux/actions/bookData/data";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import BookEditIndexTab from "../../components/BookEdit/Tabs/IndexTab";
import { bookSaveRequestStart } from "../../redux/actions/books/save";
import { transformBookData } from "../../utils/transformBookData";
import { bookCompressRequestStart } from "../../redux/actions/books/compressBook";
import { bookDecompressRequestStart } from "../../redux/actions/books/decompressBook";
import Organizations from "../../components/BookEdit/Tabs/Organizations";
import { api } from "../../App";
import { createNotification } from "../../components/notification/notification";
import ExpertOpinion from "../../components/BookEdit/Tabs/ExpertOpinion";
import Pages from "../../components/BookEdit/Tabs/Pages";
import AdditionalDataTab from "../../components/BookEdit/Tabs/AdditionalData";
import BookHistoryChangeTab from "../../components/BookEdit/Tabs/HistiryChange/index";
import { bookChangeHistoryActions } from "../../redux/factory/bookHistoryChangeReduxData";
import NotesTab from "../../components/BookEdit/Tabs/Notes/index.jsx";
import { bookNotesActions } from "../../redux/factory/bookNotesReduxData";
import GUKTab from "../../components/BookEdit/Tabs/GUK";
import BookEditFilesTab from "../../components/BookEdit/Tabs/Files";
import { gukTabDataRequestStart } from "../../redux/actions/gukTab/getData";
import BookEditControls from "../../components/BookEdit/Controls";
import { setBookData } from "../../redux/actions/bookData/setData";
import BookEditInvNumbersTab from "../../components/BookEdit/Tabs/InvNumbers";
import { outsecrecyCountRequestStart } from "../../redux/actions/outsecrecy/count";
import moment from "moment";

const mapStateToProps = ({ bookNotes, bookChangeHistory, bookEdit, auth }) => ({
  bookData: bookEdit.data,
  loader: bookEdit.fileLoader,
  historyList: bookChangeHistory.list,
  notesTypes: bookNotes.types,
  notesList: bookNotes.list,
  gukTabData: bookEdit.gukTab,
  authData: auth.data,
});

const mapDispatchToProps = (dispatch) => ({
  requestData: (id) => dispatch(bookDataRequestStart(id)),
  setBookData: (data) => dispatch(setBookData(data)),
  requestGukTabData: () => dispatch(gukTabDataRequestStart()),
  saveData: (data, withoutPopup) =>
    dispatch(bookSaveRequestStart(data, withoutPopup)),
  compressBook: (bookId) => dispatch(bookCompressRequestStart(bookId)),
  decompressBook: (bookId) => dispatch(bookDecompressRequestStart(bookId)),
  getBookChangeHistory: (bookId) =>
    dispatch(bookChangeHistoryActions.listRequestStart(bookId)),
  getNotesTypes: () => dispatch(bookNotesActions.typesRequestStart()),
  getNotesList: (bookId) => dispatch(bookNotesActions.listRequestStart(bookId)),
  addNote: (bookId, data) =>
    dispatch(bookNotesActions.addRequestStart({ bookId, data })),
  delNote: (noteId) => dispatch(bookNotesActions.delRequestStart(noteId)),
  requestOutsecrecy: () => dispatch(outsecrecyCountRequestStart),
});


const scienceWorkRequiredFields = { work_status: '', fio: '', job_title: '', organization: '', year: '' }

const BookEditScene = ({
  match,
  bookData,
  requestData,
  saveData,
  compressBook,
  decompressBook,
  history,
  getBookChangeHistory,
  historyList,
  getNotesTypes,
  notesTypes,
  notesList,
  getNotesList,
  addNote,
  delNote,
  gukTabData,
  requestGukTabData,
  setBookData,
  loader,
}) => {
  const bookId = match.params.id;
  const [saveTime, setSaveTime] = useState(new Date().toISOString());
  const [formErrors, setFormErrors] = useState({
    title: "",
    verifyStatus: "",
    rightofuse: "",
    book_source: "",
    isbn: "",
    eerOutSecrecyId: "",
  });

  useEffect(() => {
    requestData(bookId);
  }, [requestData, bookId]);

  useEffect(() => {
    onSave(true);
  }, [saveTime]);

  useEffect(() => {
    requestGukTabData();
  }, [requestGukTabData]);

  const onSave = (withoutPopup) => {
    if (bookData !== null) {
      if (!!bookData.title) {
        saveData(transformBookData(bookData), withoutPopup);
      } else {
        createNotification("error", 'Пожалуйста, заполните поле "Название" ');
      }
    }
  };

  const validateForm = () => {
    let isFormValid = true;

    const scienceWorksType = bookData.typeId == 13; //13 Шифр

    const _formErrors =  scienceWorksType
      ? {...formErrors, ...scienceWorkRequiredFields}
      : { ...formErrors }
    const errorText = "Обязательное для заполнения";
    for (let prop in _formErrors) {
      if (!bookData[prop]) {
        isFormValid = false;
        _formErrors[prop] = errorText;
      } else {
        _formErrors[prop] = "";
      }
    }
    setFormErrors(_formErrors);

    return isFormValid;
  };

  const checkedOnSave = async() => {
    const isFormValid = validateForm();
    
    if (!isFormValid) {
      createNotification("error", "Необходимо заполнить все обязательные поля");
      return;
    }

    if (!bookData.clients || bookData.clients.length === 0) {
      createNotification("error", "Необходимо указать местоположение");
      return;
    }

    if (isFormValid) {
      const checkRequestBody = {
        title: bookData.title,
        year: Number(bookData.year) || 0,
        pageNum: Number(bookData.pageNum) || 0,
        authorIds: bookData.authors.map((item) => item.id),
        publisherIds: bookData.publishers.map((item) => item.id),
        extractreason: Number(bookData.extractreason) || 0,
        subscriptionenddate: bookData.subscriptionenddate,
        pubManufactureDate: moment(bookData.pubManufactureDate).format(
          "DD.MM.YYYY"
        ),
        eer_purpose: bookData.eer_purpose
          ? parseInt(bookData.eer_purpose)
          : null,
        eer_purpose_subgroup: bookData.eer_purpose_subgroup
          ? parseInt(bookData.eer_purpose_subgroup)
          : null,
        eer_genre: bookData.eer_genre.map((item) => item.value),
        eer_purpose_education_form: parseInt(
          bookData.eer_purpose_education_form
        ),
        eerOutCarrierId: bookData?.eerOutCarrierId
          ? parseInt(bookData.eerOutCarrierId)
          : null,
      };

      
      try {
        const response = await api.post(`/bookduplicate`, checkRequestBody);

        if (response.status == 200) {
          onSave();
          history.location.search = "";
        }
      }
      catch (e) {
          createNotification(
            "error",
            "Такая книга уже существует. Дубликаты запрещены."
          );
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={"d-flex justify-content-between"}>
        <div className={styles.title}>
          Редактирование книги - {bookData?.title}
        </div>
        <div className={styles.title}>{bookData?.bookid}</div>
      </div>
      {bookData && (
        <Tabs id={"bookEditTabs"} defaultActiveKey="index">
          <Tab.Pane
            eventKey="index"
            title="Общие"
            className={styles.tabContent}
          >
            <BookEditIndexTab
              data={bookData}
              setData={setBookData}
              checkedOnSave={checkedOnSave}
              formErrors={formErrors}
            />
          </Tab.Pane>
          <Tab.Pane
            eventKey="location"
            title="Местоположение"
            mountOnEnter={true}
          >
            <Organizations
              setData={(data) => {
                setBookData(data);
                setSaveTime(new Date().toISOString());
              }}
              data={bookData}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="pages" title="Страницы" mountOnEnter={true}>
            <Pages bookId={bookId} />
          </Tab.Pane>
          <Tab.Pane
            eventKey="expert"
            title="Экспертное заключение"
            mountOnEnter={true}
          >
            <ExpertOpinion bookId={bookId} />
          </Tab.Pane>
          <Tab.Pane
            eventKey="changelog"
            title="История изменений"
            mountOnEnter={true}
          >
            <BookHistoryChangeTab
              bookId={bookId}
              getBookChangeHistory={getBookChangeHistory}
              list={historyList || []}
            />
          </Tab.Pane>
          <Tab.Pane
            eventKey="additionalDesc"
            title="Доп. описание"
            mountOnEnter={true}
          >
            <AdditionalDataTab data={bookData} setData={setBookData} formErrors={formErrors}/>
          </Tab.Pane>
          <Tab.Pane eventKey="guk" title="Сведения о ЭОР" mountOnEnter={true}>
            <GUKTab
              data={bookData}
              setData={setBookData}
              selectData={gukTabData}
            />
          </Tab.Pane>
          <Tab.Pane
            eventKey="additionalInfo"
            title="Примечания"
            mountOnEnter={true}
          >
            <NotesTab
              getTypes={getNotesTypes}
              types={notesTypes}
              getList={getNotesList}
              list={notesList || []}
              bookId={bookId}
              addNote={addNote}
              delNote={delNote}
            />
          </Tab.Pane>
          <Tab.Pane
            eventKey="inv_numbers"
            title="Инв.номера"
            mountOnEnter={true}
          >
            <BookEditInvNumbersTab bookData={bookData} bookId={bookId} />
          </Tab.Pane>
          <Tab.Pane eventKey="files" title="Файлы" mountOnEnter={true}>
            <BookEditFilesTab bookId={bookId} />
          </Tab.Pane>
        </Tabs>
      )}
      <BookEditControls
        bookData={bookData}
        bookId={bookId}
        history={history}
        compressBook={compressBook}
        requestData={requestData}
        decompressBook={decompressBook}
        checkedOnSave={checkedOnSave}
        loader={loader}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookEditScene);
