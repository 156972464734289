import { ERROR, IMPORT_BOOKS_REQUEST, START, SUCCESS } from '../action-types'

export const importBooksRequestStart = resolution => ({
  type: `${IMPORT_BOOKS_REQUEST}${START}`, resolution
})

export const importBooksRequestSuccess = data => ({
  type: `${IMPORT_BOOKS_REQUEST}${SUCCESS}`,
  payload: data,
})

export const importBooksRequestError = data => ({
  type: `${IMPORT_BOOKS_REQUEST}${ERROR}`,
  payload: data,
})
