import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bookYearEnd } from '../../api/fundAccounting/list'
import BookYearEndTable from '../../components/FundAccounting/BookYearEndTable'
import FundAccountingSearchForm from '../../components/FundAccounting/SearchForm'
import FundAccountingTable from '../../components/FundAccounting/Table'
import ModalInputWrapper from '../../components/Modal/InputWrapper'
import { createNotification } from '../../components/notification/notification'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { fundAccountingRequestStart } from '../../redux/actions/fundAccounting/list'
import { setFundAccountingSearchData } from '../../redux/actions/fundAccounting/search'
import { libraryListRequestStart } from '../../redux/actions/library/info/list'

const mapStateToProps = ({ fundAccounting: fa, options, library }) => {
  const libraryId = Number(fa.search.libraryid)

  const handler = (i, part) => {
    const res = { ...i, funds: {} }
    part.forEach(k => {
      if (k.orderstr === 1) {
        if (k.supplier_name === i.supplier_name) {
          res.funds[k.fund_name] = k
        }
      }
    })
    return res
  }

  const groupList = {
    part_1: fa.list.filter(i => i.part_num === 1), //Прибытие (часть 1)
    part_2: fa.list.filter(i => i.part_num === 2), //Отбытие (часть 2)

    get funds() {
      let res = {}
      fa.list.forEach(i => {
        if (i.orderstr === 1) {
          res[i.fund_name] = null
        }
      })
      return Object.keys(res)
    },

    get part_1_list_1() {
      return this.part_1.filter(i => i.orderstr === 0).sort((a, b) => a.doc_serial_number - b.doc_serial_number)
    },

    get part_1_list_2() {
      if (libraryId) {
        return this.part_1_list_1.map(i => handler(i, this.part_1))
      }
      return null
    },

    get part_2_list_1() {
      return this.part_2.filter(i => i.orderstr === 0).sort((a, b) => a.doc_serial_number - b.doc_serial_number)
    },

    get part_2_list_2() {
      if (libraryId) {
        return this.part_2_list_1.map(i => handler(i, this.part_2))
      }
      return null
    },
  }

  return {
    list: groupList,
    loader: fa.loader,
    optionsData: options.data,
    librariesList: library.info.list,
    searchData: fa.search,
  }
}

const mapDispatchToProps = {
  setSearchData: setFundAccountingSearchData,
  requestList: fundAccountingRequestStart,
  getLibrariesList: libraryListRequestStart,
}

const FundAccounting = ({
  list,
  loader,
  requestList,
  optionsData,
  getLibrariesList,
  librariesList,
  searchData,
  setSearchData,
}) => {
  const [bookYearEndAvailable, setBookYearEndAvailable] = useState(false)
  const [yearEndData, setYearEndData] = useState([])
  const [yearEndLoader, setYearEndLoader] = useState(false)

  useEffect(() => {
    if (optionsData) {
      getLibrariesList(optionsData.own_client_id)
    }
  }, [getLibrariesList, optionsData])

  useEffect(() => {
    const matchFrom = searchData.from.match(/\d{4}-01-01/)
    const matchTo = searchData.to.match(/\d{4}-12-31/)
    // Шифр
    const matchLibrary = Number(searchData.libraryid) !== 0
    if (matchFrom && matchTo && matchLibrary) {
      setBookYearEndAvailable(true)
    } else {
      setBookYearEndAvailable(false)
    }
  }, [searchData])

  const onRequestYearClose = () => {
    setYearEndLoader(true)
    bookYearEnd(searchData)
      .then(res => {
        setYearEndData(res.data)
        setYearEndLoader(false)
        createNotification('success', 'Закрытие года проведено')
      })
      .catch(e => {
        setYearEndLoader(false)
        createNotification('error', e.response.data.result)
      })
  }

  return (
    <SceneContainer title={'КНИГА СУММАРНОГО УЧЕТА'}>
      <FundAccountingSearchForm
        setSearchData={setSearchData}
        searchData={searchData}
        onSubmit={requestList}
        librariesList={librariesList}
        yearEnd={
          bookYearEndAvailable ? (
            <ModalInputWrapper label={''}>
              <Button onClick={onRequestYearClose}>Закрытие года</Button>
            </ModalInputWrapper>
          ) : null
        }
      />

      <BookYearEndTable data={yearEndData} loader={yearEndLoader} />

      {loader ? <SpinnerOuter isLoading={loader} /> : <div style={{overflowY: 'auto'}}><FundAccountingTable data={list} /></div>}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FundAccounting)
