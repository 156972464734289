import {SUCCESS, TRANSFER_LOG_COUNT_REQUEST} from "../../../../actions/action-types";


const initialState = 0;

export const countReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${TRANSFER_LOG_COUNT_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};