import {
  PERMISSIONS_BY_LITER_COUNT_REQUEST,
  SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const accessLitersCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${PERMISSIONS_BY_LITER_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};