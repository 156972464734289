import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import Button from "../../../Button";
import styles from "../../../FundMovementBook/SearchForm/FundMovementSearchForm.module.css";
import { api } from "../../../../App";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { selectCustomStyles } from "../../../Select/customStyles";

const initialSearchData = {
  date_from: '',
  date_to: '',
  user_id: '',
  title: '',
  number: '',
  library_id: '',
  fund_id: '',
}

const defaultValidationSchema = Yup.object().shape({
  date_from: Yup.date().required("Выберите дату начала"),
  date_to: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("date_from"), "Дата завершения должна быть позже даты начала"),
});

const NotebookLostDocSearchForm = ({
  onSubmit,
  librariesList,
}) => {
  const [fundList, setFundList] = useState([]);

  const modalForm = useFormik({
    initialValues: initialSearchData,
    validationSchema: defaultValidationSchema,
    onSubmit: onSubmit
  });
  
  const { values, errors } = modalForm;
//TODO lost поле с ЮЗЕРОМ из ElectronicResourcesReportForm
const [current, setCurrent] = useState("");
const [users, setUsers] = useState([]);
  const userDataResolver = (data) =>
  data.map((item) => ({
    label: item.lastname + " " + item.firstname + " " + item.patronymic,
    value: item.mid,
  }));

const onUsersInputChange = (name) => {
  const params = {
    paginateFrom: 0,
    search: name,
    orderBy: "lastname",
  };

  api.get("/people", { params }).then((res) => {
    setUsers(userDataResolver(res.data));
  });
};

  useEffect(() => {
    onUsersInputChange()
    if (modalForm.values.library_id > 0 && modalForm.values.library_id !== "" ) {
        api
        .get(`/library/${modalForm.values.library_id}/fund`)
        .then((res) => {
          setFundList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    
    } else {
        setFundList([]);
        modalForm.values.fund_id = ''
    }
  }, [modalForm.values.library_id]);
     modalForm.values.user_id = current;
    

  return (
    <div className={styles.container}>
      <Row>
        <Col md={3}>
          <ModalInputWrapper
            value={values["date_from"]}
            error={errors["date_from"]}
            name="date_from"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"С"}
          />
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            value={values["date_to"]}
            error={errors["date_to"]}
            name="date_to"
            onChange={modalForm.handleChange}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={"Библиотека"}>
            <select
              name="library_id"
              onChange={modalForm.handleChange}
              value={values["library_id"]}

              className={styles.select}
            >
              <option value="">Все</option>
              {librariesList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={"Фонд"}>
            <select
              name="fund_id"
              onChange={modalForm.handleChange}
              value={values["fund_id"]}
              className={styles.select}
            >
          
            <option value="">Все</option>
            {fundList.map((item) => (
              <option value={item.id}>{item.name}</option>
              ))}

            </select>
          </ModalInputWrapper>
        </Col>
        <Col align="Left" md={6}>
        <ModalInputWrapper label="Пользователь">
        <Select
          isSearchable={true}
          isClearable={true}
          onInputChange={onUsersInputChange}
          options={users}
          onChange={(val) => setCurrent(val?.value)}
          placeholder={"Введите пользователя..."}
          value={current?.label}
          error={errors["user_id"]}
          name="user_id"
          noOptionsMessage={() => "Ничего не найдено"}
          styles={selectCustomStyles}
         />
      </ModalInputWrapper>
        </Col>
        <Col align="Left" md={6}>
        <ModalInputWrapper
            label="Название книги*"
            name="title"
            value={values["title"]}
            error={errors["title"]}
            onChange={modalForm.handleChange}
         />
            </Col>
            <Col md={3}>
            <ModalInputWrapper
            name="number"
            value={values["number"]}
            onChange={modalForm.handleChange}
            label={"Инвентарный номер"}
            />
            </Col>
         <Col md={2}>
          <ModalInputWrapper label={""}>
            <Button type='button' label={"Найти"} onClick={modalForm.handleSubmit} />
          </ModalInputWrapper>
        </Col>

      </Row>
    </div>
  );
};

NotebookLostDocSearchForm.propTypes = {
  data: T.object,
  setSearchData: T.func,
  startSearch: T.func,
  types: T.array,
  librariesList: T.array,
};

export default NotebookLostDocSearchForm;
