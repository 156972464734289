import { combineReducers } from "redux";
import { documentTypesCountReducer } from "./count/documentTypesCountReducer";
import { documentTypesListReducer } from "./list/documentTypesListReducer";
import { documentTypesSearchReducer } from "./search/documentTypesSearchReducer";
import { documentTypesAllReducer } from "./all/documentTypesAllReducer";

export const documentTypesReducer = combineReducers({
  count: documentTypesCountReducer,
  list: documentTypesListReducer,
  search: documentTypesSearchReducer,
  all: documentTypesAllReducer
});
