import React from "react";
import styles from "./Table.module.css";
import DataTableCol from "../../../../DataTable/Column";
import DataTable from "../../../../DataTable";

const ExpertOpinionTable = ({ list, selectedId, handleSelect }) => {
  return (
    <div className={styles.table}>
      <DataTable data={list}>
        <DataTableCol
          title="ID"
          value="id"
          colSpan={2}
          className={styles.blueColumn}
        />
        <DataTableCol
          title="Имя файла"
          value="filename"
          colSpan={4}
          className={styles.blueColumn}
        />
        <DataTableCol
          title="Имя"
          value="name"
          colSpan={4}
          className={styles.blueColumn}
        />
        <DataTableCol
          title="Порядок"
          value="ordering"
          colSpan={1}
          className={styles.blueColumn}
        />
        <DataTableCol
          colSpan={1}
          title="Выбрано"
          passClicks
          className={styles.blueColumn}
          resolver={item => (
            <input
              type="checkbox"
              checked={selectedId === item.id}
              onChange={() => handleSelect(item.id)}
            />
          )}
        />
      </DataTable>
    </div>
  );
};

export default ExpertOpinionTable;
