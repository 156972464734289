import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import InputWrapper from "../../Modal/InputWrapper";
import { Button } from "antd";
import { useFormik } from 'formik';
import * as Yup from "yup"


const defaultValidationSchema = Yup.object().shape({
  from: Yup.date(),
  to: Yup.date()
    .min(Yup.ref("from"), "Дата завершения должна быть позже даты начала"),
});




const TestsForm = ({ groups, data, setData, onSubmit }) => {
  
  
  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values)
      onSubmit()
    },
  });
  
  const { values, errors } = modalForm;
  
  
  return (
    <div className={"formContainer"}>
      <Row className={"justify-content-end"}>
        <Col md={3}>
          <InputWrapper label={"Группа"}>
            <select name="gid" onChange={modalForm.handleChange} value={values.gid}>
              <option value={null}>Все</option>
              {groups.map((group) => (
                <option value={group.gid} key={group.gid}>
                  {group.name}
                </option>
              ))}
            </select>
          </InputWrapper>
        </Col>

        <Col md={3}>
          <InputWrapper label={"Курс"}>
            <select name="year" onChange={modalForm.handleChange} value={values.year}>
              <option value={null}>Все</option>
              {new Array(5).fill().map((item, index) => (
                <option value={index + 1} key={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </InputWrapper>
        </Col>

        <Col md={3}>
          <ModalInputWrapper
            name="from"
            value={values.from}
            error={errors.from}
            onChange={modalForm.handleChange}
            type={"date"}
            label={"С"}
          />
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            name="to"
            value={values.to}
            error={errors.to}
            onChange={modalForm.handleChange}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col sm={"auto"}>
          <ModalInputWrapper label={""}>
            <Button onClick={modalForm.handleSubmit} type="primary">
              Найти
            </Button>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

TestsForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default TestsForm;
