import {ERROR, EXEMPT_TEST_LIST_REQUEST, START, SUCCESS} from "../action-types";

export const exemptTestListRequestStart = () => ({
    type: `${EXEMPT_TEST_LIST_REQUEST}${START}`
});

export const exemptTestListRequestSuccess = (data) => ({
    type: `${EXEMPT_TEST_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const exemptTestListRequestError = (error) => ({
    type: `${EXEMPT_TEST_LIST_REQUEST}${ERROR}`,
    payload: error
});
