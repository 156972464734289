import { INVENTORY_SAVE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import { createNotification } from '../../../../components/notification/notification';

export const inventorySaveRequestStart = (data) => ({
  type: `${INVENTORY_SAVE_REQUEST}${START}`,
  payload: data
});

export const inventorySaveRequestSuccess = (data) => {
  createNotification('success', 'Запись успешно сохранена');
  return {
    type: `${INVENTORY_SAVE_REQUEST}${SUCCESS}`,
    payload: data
  }
};

export const inventorySaveRequestError = (data) => {
  createNotification('error', 'Не удалось сохранить запись');
  return {
    type: `${INVENTORY_SAVE_REQUEST}${ERROR}`,
    payload: data
  }
};

