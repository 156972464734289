import React from 'react'
import T from 'prop-types'
import classNames from 'classnames'
import styles from './Input.module.css'
import moment from 'moment'
import { logout } from '../../api/user/auth'

const Input = React.forwardRef(({ isModal, type, placeholder, value, onChange, style, size, name, ...otherProps }, ref) => {
  if (type === 'textarea') {
    return (
      <textarea
        className={classNames(styles.input, style)}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...otherProps}
      />
    )
  }

  return (
    <input
      autoFocus={isModal}
      ref={ref}
      className={classNames(styles.input, style)}
      name={name}
      type={type}
      placeholder={placeholder}
      value={/* type === 'date' ? moment(value).format('YYYY-MM-DD') :  */value}
      max='9999-12-31'
      onChange={onChange}
      size={size}
      {...otherProps}
    />
  )
})
Input.propTypes = {
  size: T.number,
  type: T.string.isRequired,
  onChange: T.func,
  placeholder: T.string,
  value: T.any,
  style: T.string,
  name: T.string,
}

Input.defaultProps = {
  size: 5,
}

export default Input
