import React, { useEffect } from 'react';
import styles from './DocumentTypesScene.module.css';
import { Col, Row } from "react-bootstrap";
import { documentTypesCountRequestStart } from "../../../redux/actions/documents/types/count";
import { setDocumentTypesSearchData } from "../../../redux/actions/documents/types/search";
import { documentTypesSaveRequestStart } from "../../../redux/actions/documents/types/save";
import { documentTypesDeleteRequestStart } from "../../../redux/actions/documents/types/delete";
import { connect } from "react-redux";
import Search from "../../../components/Search";
import Pagination from "../../../components/Pagination";
import DocumentTypesTable from "../../../components/DocumentTypes/Table";

const mapStateToProps = ({ documentTypes }) => ({
  list: documentTypes.list,
  count: documentTypes.count,
  searchData: documentTypes.search
});

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(documentTypesCountRequestStart()),
  setSearchData: (data) => dispatch(setDocumentTypesSearchData(data)),
  saveDocumentType: (data) => dispatch(documentTypesSaveRequestStart(data)),
  deleteDocumentType: (id) => dispatch(documentTypesDeleteRequestStart(id))
});

const DocumentTypesScene = ({ count, list, searchData, requestList, setSearchData, saveDocumentType, deleteDocumentType }) => {
  //const [selectedId, setSelectedId] = useState(null);
  //const [modalData, setModalData] = useState({ modalType: null, data: null });

  useEffect(() => {
    requestList()
  }, [requestList]);

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  const setSort = (sortBy) => {
    setSearchData({ ...searchData, sortBy, page: 1 });
    requestList();
  };

  /*const handleSelect = (id) => setSelectedId(selectedId === id ? null : id);

  const onSubmitModal = () => {
    saveDocumentType(modalData.data);
    setModalData({ modalType: null, data: null });
  };

  const onDelete = () => {
    selectedId && deleteDocumentType(selectedId);
  };*/

  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <div className={styles.title}>
            Справочники / Виды документов
          </div>
        </Col>
        <Col md={6}>
          <Search value={searchData.query} onChange={query => setSearchData({ ...searchData, query })}
                  onSubmit={requestList} placeholder={"поиск"}/>
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </Col>
        <Col md={12}>
          {/*<DocumentTypesModal onSubmit={onSubmitModal} modalData={modalData} setModalData={setModalData}/>*/}
          <DocumentTypesTable data={list}
                              sortBy={searchData.sortBy} setSort={setSort}/>
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypesScene);
