import React from "react";
import T from "prop-types";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import { useFormik } from "formik";
import * as Yup from "yup";

const defaultValidationSchema = Yup.object().shape({
  from: Yup.date(),
  to: Yup.date().min(
    Yup.ref("from"),
    "Дата завершения должна быть позже даты начала"
  ),
});

const SearchAuditForm = ({ data, setData, search }) => {
  const modalForm = useFormik({
    initialValues: data,
    validationSchema: defaultValidationSchema,
    onSubmit: (values) => {
      setData(values);
      search();
    },
  });

  const { values, errors } = modalForm;

  return (
    <div className={"formContainer"}>
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="from"
            value={values.from}
            error={errors.from}
            label="С (дата)"
            type="date"
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            onChange={modalForm.handleChange}
            name="to"
            value={values.to}
            error={errors.to}
            label="До (дата)"
            type="date"
          />
        </Col>
        <Col md={12} className={"text-right"}>
          <Button label={"Найти"} onClick={modalForm.handleSubmit} />
        </Col>
      </Row>
    </div>
  );
};

SearchAuditForm.propTypes = {
  data: T.object,
  setData: T.func,
  search: T.func,
  dataset: T.array,
};

export default SearchAuditForm;
