import {
  ERROR,
  OUTSECRECY_LIST_REQUEST,
  START,
  SUCCESS
} from "../action-types";

export const outsecrecyListRequestStart = () => ({
  type: `${OUTSECRECY_LIST_REQUEST}${START}`
});

export const outsecrecyListRequestSuccess = data => ({
  type: `${OUTSECRECY_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const outsecrecyListRequestError = error => ({
  type: `${OUTSECRECY_LIST_REQUEST}${ERROR}`,
  payload: error
});
