import {CURRENT_MOBILE_LIBRARY_REQUEST, RESET_MOBILE_LIBRARY_DATA, SUCCESS} from "../../../actions/action-types";



const initialState = {
    name: '',
    created_at: '',
    closed_at: '',
    number: '',
    contract_number: '',
    org_id: null,
    subject_id: null
}



export const currentMobileLibraryReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${CURRENT_MOBILE_LIBRARY_REQUEST}${SUCCESS}`:
            return action.payload
        
        case RESET_MOBILE_LIBRARY_DATA:
            return initialState

        default: return state;
    }
};