import {ERROR, START, SUCCESS, ANSWER_STAT_LIST_REQUEST} from "../action-types";

export const answerStatListRequestStart = () => ({
    type: `${ANSWER_STAT_LIST_REQUEST}${START}`
});

export const answerStatListRequestSuccess = (list) => ({
    type: `${ANSWER_STAT_LIST_REQUEST}${SUCCESS}`,
    payload: list
});

export const answerStatListRequestError = (error) => ({
    type: `${ANSWER_STAT_LIST_REQUEST}${ERROR}`,
    payload: error
});


