import {call, put, takeLatest, select} from "redux-saga/effects";
import {START, SVNI_LIST_COLLAPSE, SVNI_LIST_REQUEST} from "../../actions/action-types";
import {svniListRequestError, svniListRequestSuccess} from "../../actions/svni/list";
import {svniList} from "../../../api/svni/list";
import {addToList, collapseList} from "../../../utils/udkAndBbkHelpers";

export default function* svniRequestWatcher() {
    yield takeLatest(`${SVNI_LIST_REQUEST}${START}`, svniRequestWorker);
    yield takeLatest(SVNI_LIST_COLLAPSE, svniCollapseListWorker);
}

function* svniRequestWorker({ payload: { id, search, rewrite } }) {
    const currentList = yield select(({svni}) => svni);
    try {
        const response = yield call(svniList, id, search);
        const result = rewrite ? response.data : addToList(currentList, id, response.data);
        yield put(svniListRequestSuccess(result));
    } catch (error) {
        yield put(svniListRequestError(error));
    }
}

function* svniCollapseListWorker({payload: id}) {
    const currentList = yield select(({svni}) => svni);
    const result = collapseList(currentList, id);
    yield put(svniListRequestSuccess(result));
}
