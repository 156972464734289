import { api } from "../../App";
import { SORT_DIRECTION } from "../../constants/sortDirections";

export const getBookOrdersList = ({ sortBy, maxResults, paginateFrom, sortDirection, query, documenttype, from, to }) => {
  const params = {
    maxResults,
    paginateFrom,
    documenttype,
    from,
    to,
    search: query,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
  };

  return api.get('/bookrequest', { params });
};

export const getBookOrdersCount = ({ query: search, ...other }) => {
  const params = {
    ...other,
    count: true,
    search
  };

  return api.get('/bookrequest', { params });
};

export const getDocumentNumber = () => {
  return api.get(`/documentnumber`);
};

export const saveBookOrder = (data) => {
  return api.post(`/bookrequest/`, data);
}

export const deleteBookOrder = (orderId) => {
  return api.delete(`bookrequest/${orderId}`);
};
