import {combineReducers} from "redux";
import { accessLitersFormReducer } from './form/accessLitersFormReducer';
import { accessLitersListReducer } from './list/accessLitersListReducer';
import { accessLitersCountReducer } from './count/accessLitersCountReducer';
import { accessLitersSearchReducer } from './search/accessLitersSearchReducer';
import { accessLitersLoaderReducer } from './loader/accessLitersLoaderReducer';

export const accessLitersReducer = combineReducers({
  count:  accessLitersCountReducer,
  list:   accessLitersListReducer,
  form:   accessLitersFormReducer,
  search: accessLitersSearchReducer,
  loader: accessLitersLoaderReducer,
});