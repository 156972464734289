import { OPTIONS_SAVE_REQUEST, SET_OPTIONS_DATA, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const optionsStatusReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${OPTIONS_SAVE_REQUEST}${SUCCESS}`:
      return action.payload;

    case SET_OPTIONS_DATA:
      return null;

    default: return state;
  }
};