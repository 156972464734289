import {
    RESET_COVERS_SCAN_FORM_DATA,
    SET_COVERS_SCAN_FORM_DATA,
    SET_LOCATION_CHANGE
} from "../../../actions/action-types";

const initialState = {
    title: "",
    author: "",
    categoryid: "",
    isbn: "",
    year: "",
    udk: "",
    bbk: "",
    discipline: "",
    publisher: "",
    textsearch: "",
    idclient: "",
    issn: "",
    keywords: "",
    typeId: "",
    verifyStatus: "",
    hascover: true
};

export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COVERS_SCAN_FORM_DATA:
            return action.payload;

        case RESET_COVERS_SCAN_FORM_DATA:
            return initialState;

        case SET_LOCATION_CHANGE:
            return initialState;

        default:
            return state;
    }
};
