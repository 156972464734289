import { api } from '../../App';

export const getReport = (id, formData, {sortBy, maxResults, paginateFrom, sortDirection}, search) => {
  const params = {
    maxResults,
    paginateFrom,
    // ...count,
    // [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
  };
  return api.post(`/report/${id}`, formData, { params });
};

export const getReportCount = (id, formData) => {
  const params = {
    count: true,
    // ...count
  };
  return api.post(`/report/${id}`, formData, { params });
};

export const getAllReport = (id, formData) => {
  return api.post(`/report/${id}`, formData);
};

export const getEventsReport = (params) => api.get(`/events_journal`, { params })