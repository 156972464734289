import { AUTHORS_DELETE_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const authorsDeleteRequestStart = (id) => ({
  type: `${AUTHORS_DELETE_REQUEST}${START}`,
  payload: id
});

export const authorsDeleteRequestSuccess = (data) => ({
  type: `${AUTHORS_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const authorsDeleteRequestError = (data) => ({
  type: `${AUTHORS_DELETE_REQUEST}${ERROR}`,
  payload: data
});
