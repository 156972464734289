import { combineReducers } from "redux";
import { libraryMaterialsCountReducer } from "./count/libraryMaterialsCountReducer";
import { libraryMaterialsFormReducer } from "./form/libraryMaterialsFormReducer";
import { libraryMaterialsListReducer } from "./list/libraryMaterialsListReducer";
import { libraryMaterialsLoaderReducer } from './loader/libraryMaterialsLoaderReducer';
import { libraryMaterialsSearchReducer } from "./search/libraryMaterialsSearchReducer";

export const libraryMaterialsReducer = combineReducers({
  count: libraryMaterialsCountReducer,
  list: libraryMaterialsListReducer,
  form: libraryMaterialsFormReducer,
  search: libraryMaterialsSearchReducer,
  loader: libraryMaterialsLoaderReducer,
});
