import { BORROWING_TEST_ADD_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const borrowingTestAddRequestStart = ({ comment, file }) => ({
  type: `${BORROWING_TEST_ADD_REQUEST}${START}`,
  payload: { comment, file }
});

export const borrowingTestAddRequestSuccess = (response) => {
  createNotification(
    'success',
    `Успешно создано`
  );

  return {
    type: `${BORROWING_TEST_ADD_REQUEST}${SUCCESS}`,
    payload: response
  };
};

export const borrowingTestAddRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка создания. Статус: ${error.response.status}`
  );

  return {
    type: `${BORROWING_TEST_ADD_REQUEST}${ERROR}`,
    payload: error
  };
};
