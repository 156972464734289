import { ERROR, EXTERNAL_RESOURCES_FULL_REQUEST, START, SUCCESS } from "../action-types";

export const externalResourcesFullListRequestStart = () => ({
  type: `${EXTERNAL_RESOURCES_FULL_REQUEST}${START}`
});

export const externalResourcesFullListRequestSuccess = (data) => ({
  type: `${EXTERNAL_RESOURCES_FULL_REQUEST}${SUCCESS}`,
  payload: data
});

export const externalResourcesFullListRequestError = (data) => ({
  type: `${EXTERNAL_RESOURCES_FULL_REQUEST}${ERROR}`,
  payload: data
});
