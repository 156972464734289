import { ERROR, BOOK_EDIT_INV_NUMBERS_LIST_REQUEST, START, SUCCESS } from "../../action-types";

export const bookInvNumbersListRequestStart = () => ({
  type: `${BOOK_EDIT_INV_NUMBERS_LIST_REQUEST}${START}`
});

export const bookInvNumbersListRequestSuccess = (data) => ({
  type: `${BOOK_EDIT_INV_NUMBERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookInvNumbersListRequestError = (data) => ({
  type: `${BOOK_EDIT_INV_NUMBERS_LIST_REQUEST}${ERROR}`,
  payload: data
});