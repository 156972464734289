import React from 'react';
import BibliographySpecialization from "../Specialization";
import styles from './BibliographyTable.module.css';

const BibliographyTable = ({milspecs}) => {
    return (
        <div className={styles.table}>
            {milspecs.map((item, index) => (
                <BibliographySpecialization key={index} data={item}/>
            ))}
        </div>
    );
};

export default BibliographyTable;