import { api } from '../../App'
import { SORT_DIRECTION } from '../../constants/sortDirections'

export const getInventaryBook = ({ sortBy, maxResults, paginateFrom, sortDirection, search, documentType, from, to, fund }) => {
  const params = {
    // maxResults,
    // paginateFrom,
    documenttype: documentType,
    from,
    to,
    search,
    fund,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
    sortBy: sortBy,
  }

  return api.get(`/bookinventory`, { params })
}

export const getInventaryBookCount = ({ query: search, ...other }) => {
  const params = {
    ...other,
    count: true,
    search,
  }

  return api.get('/bookinventory', { params })
}
