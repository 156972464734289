import {api} from "../../App";

export const saveJson = (file, format, clientId='') => {
    const request = new FormData();
    request.append('file', file);
    return api.post(`/bookimport`, request, { params: { format, clientId } });
};

export const saveZip = ({id, file}) => {
    return api.put(`/ebook/${id}`, file);
};

// export const saveZip = (file, id='') => {
//     const request = new FormData();
//     request.append('file', file);
//     return api.put(`/ebook/${id}`, file);
// };