import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { invNumbersCountRequestStart } from "../../../redux/actions/invNumbers/count";
import "./PrintInvFormular.css";

const mapStateToProps = ({ invNumbers }) => ({
  list: invNumbers.list
});

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(invNumbersCountRequestStart())
});

const PrintInvFormular = ({ match, requestList, list }) => {
  const [dataObj, setDataObj] = useState(null);

  const getDataObj = () => {
    setDataObj(
      list.find(item => item.instanceid.toString() === match.params.id)
    );
  };

  useEffect(() => {
    requestList();
  }, [requestList]);
  useEffect(() => {
    !!list.length && getDataObj();
  }, [list]);
  useEffect(() => {
    dataObj && window.print();
  });

  return (
    <div className="InvFormular">
      <div className="InvFormularTopRow">
        <div className="InvFormularTopRowLeftBlock">
          <div className="InvFormularTopRowLeftBlockTop"></div>
          <div className="InvFormularTopRowLeftBlockBottom">
            {dataObj && dataObj.barcode}
          </div>
        </div>
        <div className="InvFormularTopRowRightBlock">
          {dataObj && dataObj.number}
        </div>
      </div>
      <div className="InvFormularCenterRow">{dataObj && dataObj.authors}</div>
      <div className="InvFormularCenterRow">{dataObj && dataObj.title}</div>
      <div className="InvFormularCenterRow">{dataObj && dataObj.year}</div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
      <div className="InvFormularBottomRow">
        <div></div>
        <div className="InvFormularBottomRowCenter"></div>
        <div></div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintInvFormular);
