import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getResponsibleRequestStart } from '../../../redux/actions/mobileLibrary/responsible/list'
import { militaryRankRequestStart } from '../../../redux/actions/common/militaryRank'
import ModalWindow from '../../ModalWindow'
import { Table } from 'react-bootstrap'
import { Button} from 'antd'
import InputWrapper from '../../Modal/InputWrapper'
import { useFormik } from 'formik'
import { updateResponsibleRequestStart } from '../../../redux/actions/mobileLibrary/responsible/update'
import { createResponsibleRequestStart } from '../../../redux/actions/mobileLibrary/responsible/create'
import { useContext } from 'react'
import { MobileLibContext } from '../../../utils/context'


export default function Responsible() {
  
  const dispatch = useDispatch()
  const { editMode, disabled, libId, timeOut } = useContext(MobileLibContext)

  const { responsible: data } = useSelector(state => state.mobileLibrary)
  const { military_rank: ranks } = useSelector(state => state.common)

  const [modalData, setModalData] = useState(null)

  useEffect(() => {
    !ranks.length && dispatch(militaryRankRequestStart())
    editMode && dispatch(getResponsibleRequestStart(libId))
  }, [])
  
  const closeModal = () => setModalData(null)
  const openModal = (data) => () => setModalData(data || {})

  const renderRow = (resp) => {
    const {lastname, firstname, patronymic, grade_name, position, appointed_at, resigned_at} = resp
    const fio = `${lastname} ${firstname} ${patronymic}`
    return (
      <tr onClick={openModal(resp)}>
        <td>{fio}</td>
        <td>{grade_name}</td>
        <td>{position}</td>
      </tr>
    )
  }

  return (
    <div className={styles.block}>
      <p className={styles.blockTitle}>Ответственный и командир войсковой части:</p>
      <Table className={styles.respTable}>
        <thead>
          <tr>
            <th>ФИО</th>
            <th>Звание</th>
            <th>Должность</th>
          </tr>
        </thead>
        <tbody>
          {data.map(renderRow)}
        </tbody>
      </Table>
      <Button
        disabled={disabled || timeOut}
        onClick={openModal()}
        type='primary'
        >
        Добавить
      </Button>
      <Modal
        isOpen={!!modalData}
        closeModal={closeModal}
        disabled={disabled || timeOut}
        ranks={ranks}
        editMode={!!modalData?.id}
        data={modalData}
        libId={libId}
      />
    </div>
  )
}


const Modal = ({
  isOpen,
  closeModal,
  ranks,
  data,
  editMode,
  libId,
  disabled
}) => {
  
  const dispatch = useDispatch()

  const submitForm = ({grade_name, ...values}) => {
    const action = editMode ? updateResponsibleRequestStart : createResponsibleRequestStart;
    const payload = {
      ...values,
      mobile_library_id: parseInt(libId),
      grade: parseInt(values.grade)
    }
    dispatch(action(payload))
    closeModal()
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setValues
  } = useFormik({
    initialValues: {
      lastname: "",
      firstname: "",
      patronymic: "",
      grade: "",
      position: "Ответственный",
      appointed_at: null,
      resigned_at: null
    },
    enableReinitialize: true,
    onSubmit: submitForm
  })

  useEffect(() => {
    if (isOpen) {
      if (editMode) {
        setValues(data)
      } else {
        handleReset()
      }
    }
  }, [isOpen])
  

  const renderOption = ({ id_mil_rank, title }) => <option value={id_mil_rank}>{title}</option>
  
  return (
    <ModalWindow
      isOpen={isOpen}
      onRequestClose={closeModal}
      title='Ответственные'
      width='500px'
    >
      <InputWrapper
        label='Фамилия'
        name='lastname'
        value={values.lastname}
        onChange={handleChange}
        disabled={disabled}
        />
      <InputWrapper
        label='Имя'
        name='firstname'
        value={values.firstname}
        onChange={handleChange}
        disabled={disabled}
        />
      <InputWrapper
        label='Отчество'
        name='patronymic'
        value={values.patronymic}
        onChange={handleChange}
        disabled={disabled}
        />
      <InputWrapper label='Звание'>
        <select
          value={values.grade}
          name='grade'
          onChange={handleChange}
          disabled={disabled}
        >
          <option value=''>Выберите звание</option>
          {ranks.map(renderOption)}
        </select>
      </InputWrapper>
      <InputWrapper label='Должность'>
        <select
          value={values.position}
          name='position'
          onChange={handleChange}
          disabled={disabled}
        >
          <option>Командир войсковой части</option>
          <option>Ответственный</option>
        </select>
      </InputWrapper>
      {/* <InputWrapper
        label='Дата назначения'
        name='appointed_at'
        value={values.appointed_at}
        onChange={handleChange}
        type='date'
        disabled={disabled}
        />
      <InputWrapper
        label='Дата снятия'
        name='resigned_at'
        value={values.resigned_at}
        onChange={handleChange}
        type='date'
        disabled={disabled}
      /> */}
      <Button onClick={handleSubmit} disabled={disabled} type='primary'>
        {editMode ? 'Изменить' : 'Добавить'}
      </Button>
    </ModalWindow>
  )
}