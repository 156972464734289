import {api} from "../../App";

export const alphabetTypes = () => {
    return api.get('/alphabetindex');
};

export const alphabetLetters = (type) => {
    const config = {
        params: {
            abc: type
        }
    };

    return api.get('/alphabetindex', config);
};

export const alphabetRanges = (char) => {
    const config = {
        params: {
            char
        }
    };

    return api.get('/alphabetindex', config);
};

export const alphabetCount = ({afrom, ato, author, title, emptyCards}) => {
    const config = {
        params: {
            afrom,
            ato,
            author,
            title,
            emptyCards,
            count: true
        }
    };
    return api.get('/index', config);
};

export const alphabetBooks = ({paginateFrom, afrom, ato, author, title, emptyCards}) => {
    const config = {
        params: {
            maxResults: 1,
            paginateFrom,
            afrom,
            ato,
            author,
            title,
            emptyCards
        }
    };
    return api.get('/index', config);
};

export const alphabetBooksSingleItem = ({number, afrom, ato, author, title, emptyCards}) => {
    const config = {
        params: {
            maxResults: 1,
            paginateFrom: number,
            afrom,
            ato,
            author,
            title,
            emptyCards
        }
    };
    return api.get('/index', config);
};
