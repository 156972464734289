import { START, SUCCESS, ERROR, ADMIN_GROUPS_ADD_GROUP } from '../../actions/action-types'

export const adminGroupsAddGroupsStart = (payload) => ({
  type: `${ADMIN_GROUPS_ADD_GROUP}${START}`,
  payload,
})

export const adminGroupsAddGroupsSuccess = payload => ({
  type: `${ADMIN_GROUPS_ADD_GROUP}${SUCCESS}`,
  payload,
})

export const adminGroupsAddGroupsError = payload => ({
  type: `${ADMIN_GROUPS_ADD_GROUP}${ERROR}`,
  payload,
})
