import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { setAnswerStatSearchData } from '../../redux/actions/answerstat/search'
import { answerStatCountRequestStart } from '../../redux/actions/answerstat/count'
import DataTable from '../../components/DataTable'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import DataTableCol from '../../components/DataTable/Column'
import { ANSWER_STAT_SORT } from '../../constants/sort/answerstatSort'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { AnswerStatPdfPrint } from '../../components/Answerstat/PDFPrint'

const mapStateToProps = ({ answerStat }) => ({
  list: answerStat.list,
  loader: answerStat.loader,
  searchData: answerStat.search,
})

const mapDispatchToProps = {
  requestList: answerStatCountRequestStart,
  setSearchData: setAnswerStatSearchData,
}

const AnswerStat = ({ requestList, setSearchData, list, loader, searchData }) => {
  useEffect(() => {
    requestList()
  }, [])

  const setSort = sortBy => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC

    setSearchData({ ...searchData, sortDirection: sortDir, sortBy, page: 1 })
    requestList()
  }

  return (
    <SceneContainer title='Статистика ответов на вопросы'>
      <AnswerStatPdfPrint />
      {loader ? (
        <SpinnerOuter />
      ) : (
        <div id='AnswerStat'>
          <DataTable data={list} sortBy={searchData.sortBy} onHeadClick={setSort}>
            <DataTableCol title='№' resolver={a => a.column_index} />
            <DataTableCol title='Вопрос' value='question_id' colSpan={3} />
            <DataTableCol title='Тема вопроса' value='question_title' colSpan={3} />
            <DataTableCol sortParams={[ANSWER_STAT_SORT.COUNT]} title='Количество ответов' value='count' colSpan={2} />
            <DataTableCol
              sortParams={[ANSWER_STAT_SORT.CORRECT_ANSWER]}
              title='Количество верных ответов на вопрос (в процентах от общего числа показов)'
              value='correct_answerp'
              colSpan={2}
            />
            <DataTableCol
              sortParams={[ANSWER_STAT_SORT.INCORRECT_ANSWER]}
              title='Количество неверных ответов на вопрос (в процентах от общего числа показов)'
              value='incorrect_answerp'
              colSpan={2}
            />
            <DataTableCol
              title='Количество пропущенных ответов на вопрос (в процентах от общего числа показов)'
              value='skip_answerp'
              colSpan={2}
            />
          </DataTable>
        </div>
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerStat)
