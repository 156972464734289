import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import styles from './BibliographyRazdel.module.css';
import Book from '../Book';

const Razdel = ({ razdel }) => {
    const { name, books } = razdel;

    const [lit, setLit] = useState(false);

    const toggleLit = e => {
        if (e.target.id === "lit") {
            setLit(!lit);
        }
    };

    return (
        <div onClick={toggleLit}>
            <div className={styles.tableLitRow}>
                <div id="lit" className={styles.tableLitRow_titleCell}>
                    { name }
                </div>
            </div>
            <Collapse in={lit}>
                <div className={'collapse-books'}>
                    {books.map((book, index) => <Book key={index} book={book}/>)}
                </div>
            </Collapse>
        </div>
    );
};

export default Razdel;
