import {ERROR, START, SUCCESS, TRANSFER_LOG_EVENTS_REQUEST} from "../../action-types";


export const transferLogEventsRequestStart = (id) => ({
    type: `${TRANSFER_LOG_EVENTS_REQUEST}${START}`,
    payload: id
});

export const transferLogEventsRequestSuccess = (response) => ({
    type: `${TRANSFER_LOG_EVENTS_REQUEST}${SUCCESS}`,
    payload: response
});

export const transferLogEventsRequestError = (error) => ({
    type: `${TRANSFER_LOG_EVENTS_REQUEST}${ERROR}`,
    payload: error
});
