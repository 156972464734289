import {call, put, takeLatest} from "redux-saga/effects";
import {MILITARY_RANK_REQUEST, START} from "../../../actions/action-types";
import {militaryRankRequestSuccess, militaryRankRequestError} from "../../../actions/common/militaryRank";
import { getMilitaryRank } from "../../../../api/common";

export default function* militaryRankWatcher () {
    yield takeLatest(`${MILITARY_RANK_REQUEST}${START}`, getMilitaryRankWorker)
}

function* getMilitaryRankWorker () {
  try {
      const response = yield call(getMilitaryRank);
      yield put(militaryRankRequestSuccess(response.data))
  } catch (error) {
      yield put(militaryRankRequestError(error))
  }
}