import { ERROR, START, SUCCESS, DIRECTORY_LIST_REQUEST } from "../action-types";

export const directoryListRequestStart = () => ({
  type: `${DIRECTORY_LIST_REQUEST}${START}`
});

export const directoryListRequestSuccess = (data) => ({
  type: `${DIRECTORY_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const directoryListRequestError = (data) => ({
  type: `${DIRECTORY_LIST_REQUEST}${ERROR}`,
  payload: data
});