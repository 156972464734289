import React from 'react'
import T from 'prop-types'
import styles from './DataTable.module.css'

const DataTable = ({
  data,
  children,
  sortBy,
  onCellClick,
  onHeadClick,
  tableId,
  width,
  maxWidth,
  pseudoRows,
  otherRows,
  ...props
}) => {
  
  const pseudoRowsList = pseudoRows && new Array (pseudoRows).fill(null)

  return (
    <table id={tableId || null} style={{width: width || '100%', maxWidth: maxWidth || ''}} className={styles.table} {...props}>
      <thead>
        <tr>
          {React.Children.map(children, Column =>
            React.cloneElement(Column, {
              isHeader: true,
              sortBy: sortBy,
              onClick: onHeadClick,
            })
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={`${item.type} ${item.id}`}>
            {React.Children.map(children, (Column, tdIndex) =>
              React.cloneElement(Column, {
                item: item,
                key: `td${tdIndex}${index}`,
                onClick: onCellClick,
                sortBy: sortBy,
                column: index + 1,
              })
            )}
          </tr>
        ))}
        {pseudoRowsList?.map((_, index) => <tr key={index} style={{visibility:'hidden'}}><td colSpan={children.length}></td></tr>)}
        {otherRows}
      </tbody>
  </table>
  )
}

DataTable.propTypes = {
  data: T.array.isRequired,
  modal: T.node,
  sortBy: T.string,
  onCellClick: T.func,
  onHeadClick: T.func,

  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
}

export default DataTable
