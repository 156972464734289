import { LIBRARY_MATERIALS_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const libraryMaterialsCountRequestStart = (id) => ({
  type: `${LIBRARY_MATERIALS_COUNT_REQUEST}${START}`,
  payload: id
});

export const libraryMaterialsCountRequestSuccess = (data) => ({
  type: `${LIBRARY_MATERIALS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryMaterialsCountRequestError = (data) => ({
  type: `${LIBRARY_MATERIALS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
