import React from "react";
import styles from "./SearchByImage.module.css";
import SearchByImageForm from '../../components/SearchByImage/Form';
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import {searchByImageActions} from "../../redux/actions/covers/searchByImage";
import {connect} from "react-redux";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SearchBooksItem from "../../components/UtulityComponents/SearchBooksItem";

const mapStateToProps = ({covers}) => ({
    list: covers.list,
    count: covers.count,
    searchData: covers.search,
    loader: covers.loader,
    loadSuccess: covers.loadSuccess,
});

const mapDispatchToProps = {
    requestList: searchByImageActions.listRequestStart,
    setSearchData: searchByImageActions.setSearchData,
};

const SearchByImage = ({
                           list,
                           requestList,
                           loadSuccess,
                           loader,
                       }) => {



    return (
        <SceneContainer title={'Поиск по картинке'}>

            <SearchByImageForm
                onSubmit={requestList}
            />


            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                (loadSuccess && !list.length) ?
                    <div className="emptyNotify">По Вашему запросу ничего не найдено</div>
                    :
                    <div className={styles.listContainer}>
                        {list.map(item =>
                            <SearchBooksItem data={item}/>
                        )}
                    </div>
            }
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchByImage);
