import { api } from "../../App";

export const outsecrecyList = (orderBy, maxResults, paginateFrom, search) => {
  const params = {
    orderBy,
    maxResults,
    paginateFrom,
    search
  };

  return api.get("/outsecrecy", { params });
};

export const outsecrecyCount = search => {
  const params = {
    count: true,
    search
  };

  return api.get("/outsecrecy", { params });
};
