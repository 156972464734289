import { SUCCESS, UDK_LIST_REQUEST } from "../../actions/action-types";

const initialState = [];

export const udkListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${UDK_LIST_REQUEST}${SUCCESS}`:
      return [...action.payload];

    default: return state;
  }
};