import {
  SUCCESS,
  ADMIN_MODAL_USERS_REQUEST,
  RESET_ADMIN_MODAL_USERS
} from "../../../actions/action-types";

const initialState = [];

export const adminModalListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${ADMIN_MODAL_USERS_REQUEST}${SUCCESS}`:
      return action.payload;
    case RESET_ADMIN_MODAL_USERS:
      return initialState;
    default:
      return state;
  }
};
