import { api } from '../../App';

export const importBooks = (file, params, resolution) => {
    const request = new FormData();
    request.append('file', file);
    resolution && request.append('resolution', resolution)
    return api.post(`/bookimport`, request, {params});
};

export const importBooksZip = (file, params, resolution) => {
    const request = new FormData();
    request.append('file', file);
    resolution && request.append('resolution', resolution)
    return api.post(`/bookimportzip`, request, {params})
};