import {call, put, select, takeLatest} from "redux-saga/effects";
import {countCovers, searchCovers} from "../../../api/covers/search";
import {coversScanPlanCountRequestError, coversScanPlanCountRequestSuccess} from "../../actions/coversScanPlan/count";
import {setCoversScanPlanSearchData} from "../../actions/coversScanPlan/search";
import {
    coversScanPlanListRequestError,
    coversScanPlanListRequestStart,
    coversScanPlanListRequestSuccess
} from "../../actions/coversScanPlan/list";
import {COVERS_SCAN_COUNT_REQUEST, COVERS_SCAN_LIST_REQUEST, START} from "../../actions/action-types";


export default function* coversScanPlanWatcher() {
    yield takeLatest(`${COVERS_SCAN_COUNT_REQUEST}${START}`, countWorker);
    yield takeLatest(`${COVERS_SCAN_LIST_REQUEST}${START}`, listWorker);
}


function* countWorker() {
    const searchData = yield select(({coversScanPlan}) => coversScanPlan.search);
    const formData = yield  select(({coversScanPlan}) => coversScanPlan.form);

    try {
        const response = yield call(countCovers, formData, formData.idclient);
        const count = parseInt(response.data.count, 10);
        yield put(coversScanPlanCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setCoversScanPlanSearchData({...searchData, pageCount}));
        yield put(coversScanPlanListRequestStart());
    } catch(error) {
        yield put(coversScanPlanCountRequestError(error));
    }
}

function* listWorker() {
    const searchData = yield select(({coversScanPlan}) => coversScanPlan.search);
    const formData = yield  select(({coversScanPlan}) => coversScanPlan.form);
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);

    try {
        const response = yield call(searchCovers, formData, searchData, formData.idclient);
        yield put(coversScanPlanListRequestSuccess(response.data));
    } catch(error) {
        yield put(coversScanPlanListRequestError(error));
    }
}