import React from 'react';
import T from 'prop-types';
import styles from './SuggestionsAlphabet.module.css';
import Alphabet from "../Alphabet";

const SuggestionsAlphabet = ({items, ...props}) => {
    const pairs = [];
    items.forEach((item, index) =>
        index < items.length - 1 && pairs.push(`${item} - ${items[index + 1]}`));
    return (
        <Alphabet items={pairs} span={24} {...props} itemClassName={styles.item}/>
    );
};

SuggestionsAlphabet.propTypes = {
    items: T.array
};

export default SuggestionsAlphabet;
