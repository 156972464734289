import { LIBRARY_MATERIALS_COUNT_REQUEST, LIBRARY_MATERIALS_LIST_REQUEST, START, SUCCESS } from "../../../actions/action-types";

const initialState = false;

export const libraryMaterialsLoaderReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${LIBRARY_MATERIALS_COUNT_REQUEST}${START}`:
      return true;

    case `${LIBRARY_MATERIALS_LIST_REQUEST}${SUCCESS}`:
      return false;

    default: return state;
  }
};
