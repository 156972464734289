import { ERROR, ELECTRONIC_COURSES_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const electronicCoursesCountRequestStart = () => ({
    type: `${ELECTRONIC_COURSES_COUNT_REQUEST}${START}`
});

export const electronicCoursesCountRequestSuccess = (data) => ({
    type: `${ELECTRONIC_COURSES_COUNT_REQUEST}${SUCCESS}`,
    payload: data
});

export const electronicCoursesCountRequestError = (data) => ({
    type: `${ELECTRONIC_COURSES_COUNT_REQUEST}${ERROR}`,
    payload: data
});

