import React, { useState } from 'react'
import T from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import styles from './BookEditControls.module.css'
import { Button, Space } from 'antd'
import { api } from '../../../App'
import { createNotification } from '../../notification/notification'

const BookEditControls = ({ bookData, bookId, history, compressBook, decompressBook, checkedOnSave, requestData, loader }) => {
  const [bookDeleteLoading, setBookDeleteLoading] = useState(false)

  const onCancel = () => {
    api.delete(`/book/${bookId}`).then(() => {
      history.push(`/books`)
    })
  }

  const onDeleteBook = async () => {
    setBookDeleteLoading(true)
    requestData(bookId)
    try {
        const response = await api.delete(`/book/${bookId}`);
        if (response.status === 200) {
          createNotification('success', 'Библиографическая запись успешно удалена')
          history.push('/books')
        } 
      } catch (e) {
        e?.response?.data?.message && createNotification('error', e?.response?.data?.message);
      }
      setBookDeleteLoading(false)
    
  }

  const printCatalogCard = () => {
    if (bookId) {
      const printedDoc = window.open(`/catalogcard_print/${bookId}`)
      printedDoc.focus()
    }
  }


  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <Space>
            {history.location.search === '?newbook' ? (
              <Button onClick={onCancel} danger>
                Отменить создание
              </Button>
            ) : (
              <>
                <Button onClick={() => history.push(`/books`)} danger>
                  Закрыть
                </Button>
                <Button type='primary' onClick={onDeleteBook} loading={bookDeleteLoading} danger>
                  Удалить библ. запись
                </Button>
              </>
            )}
          </Space>
        </Col>
        <Col md={6} className={'text-right'}>
          <Space>
            {!bookData?.archived ? (
              <Button onClick={() => compressBook(bookData.bookid)} loading={loader}>
                Сжать
              </Button>
            ) : (
              <Button onClick={() => decompressBook(bookData.bookid)} loading={loader}>
                Распаковать
              </Button>
            )}
            <Button type='ghost' onClick={printCatalogCard}>
              Печать карты
            </Button>
            <Button type='primary' onClick={checkedOnSave}>
              Сохранить
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

BookEditControls.propTypes = {
  bookData: T.object,
  bookId: T.any,
  compressBook: T.func,
  decompressBook: T.func,
  checkedOnSave: T.func,
}

export default BookEditControls
