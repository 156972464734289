import { api } from "../../App";
import {
  STUDENT_PROJECTS_YEARS,
  STUDENT_PROJECTS_TERMS,
  STUDENT_PROJECTS_DISCS,
  STUDENT_PROJECTS,
  STUDENT_PROJECT,
  SAVE_STUDENT_PROJECT,
  START,
  SUCCESS,
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import { combineReducers } from "redux";

const getYears = () => {
  return api.get("/hlms/api/year/scy");
};

const getTerms = id => {
  return api.get(`/hlms/api/year/term/${id}`);
};

const getDiscs = () => {
  return api.get("/hlms/api/course");
};

const getStudentProjects = url => {
  return api.get(`/hlms/api/student_project/${url}`);
};

const saveStudentProject = (id, data) => {
  return api.put(`/hlms/api/student_project/${id}`, data);
};

export const studentProjectsActions = {
  ...createSimpleActionsForReducer(
    "studentProjectsYears",
    STUDENT_PROJECTS_YEARS
  ),
  ...createSimpleActionsForReducer(
    "studentProjectsTerms",
    STUDENT_PROJECTS_TERMS
  ),
  ...createSimpleActionsForReducer(
    "studentProjectsDiscs",
    STUDENT_PROJECTS_DISCS
  ),
  ...createSimpleActionsForReducer("studentProjects", STUDENT_PROJECTS),
  ...createSimpleActionsForReducer("studentProject", STUDENT_PROJECT),
  ...createSimpleActionsForReducer("saveStudentProject", SAVE_STUDENT_PROJECT)
};

export const studentProjectsReducers = combineReducers({
  years: createSimpleReducer([], `${STUDENT_PROJECTS_YEARS}${SUCCESS}`),
  terms: createSimpleReducer([], `${STUDENT_PROJECTS_TERMS}${SUCCESS}`),
  discs: createSimpleReducer([], `${STUDENT_PROJECTS_DISCS}${SUCCESS}`),
  projects: createSimpleReducer([], `${STUDENT_PROJECTS}${SUCCESS}`),
  project: createSimpleReducer([], `${STUDENT_PROJECT}${SUCCESS}`)
});

export function* studentProjectsSagaWatcher() {
  yield takeEvery(`${STUDENT_PROJECTS_YEARS}${START}`, yearsSaga);
  yield takeEvery(`${STUDENT_PROJECTS_TERMS}${START}`, termsSaga);
  yield takeEvery(`${STUDENT_PROJECTS_DISCS}${START}`, discsSaga);
  yield takeEvery(`${STUDENT_PROJECTS}${START}`, projectsSaga);
  yield takeEvery(`${STUDENT_PROJECT}${START}`, projectSaga);
  yield takeEvery(`${SAVE_STUDENT_PROJECT}${START}`, saveProjectSaga);
}

function* yearsSaga() {
  try {
    const response = yield call(getYears);
    yield put(
      studentProjectsActions.studentProjectsYearsRequestSuccess(
        response.data.schoolYear
      )
    );
  } catch (e) {
    console.log(e);
  }
}

function* termsSaga({ payload }) {
  try {
    const response = yield call(getTerms, payload);
    yield put(
      studentProjectsActions.studentProjectsTermsRequestSuccess(
        response.data.terms
      )
    );
  } catch (e) {
    console.log(e);
  }
}

function* discsSaga() {
  try {
    const response = yield call(getDiscs);
    yield put(
      studentProjectsActions.studentProjectsDiscsRequestSuccess(response.data)
    );
  } catch (e) {
    console.log(e);
  }
}

function* projectsSaga({ payload }) {
  try {
    const response = yield call(getStudentProjects, payload);
    yield put(
      studentProjectsActions.studentProjectsRequestSuccess(response.data)
    );
  } catch (e) {
    console.log(e);
  }
}

function* projectSaga({ payload }) {
  try {
    const response = yield call(getStudentProjects, payload);
    yield put(
      studentProjectsActions.studentProjectRequestSuccess(response.data)
    );
  } catch (e) {
    console.log(e);
  }
}

function* saveProjectSaga({ payload }) {
  try {
    const response = yield call(saveStudentProject, payload.id, payload.data);
    if (response.data) {
      yield put(studentProjectsActions.studentProjectRequestStart(payload.id));
    } else {
      throw new Error("Ошибка сервера");
    }
  } catch (e) {
    console.log(e);
  }
}
