import { call, put, takeLatest } from "redux-saga/effects";
import { LIBRARY_TYPE_DELETE_REQUEST, START } from "../../../../actions/action-types";
import { deleteLibraryType } from "../../../../../api/library/types";
import {
  libraryTypeDeleteRequestError,
  libraryTypeDeleteRequestSuccess
} from "../../../../actions/library/types/delete";
import { libraryTypesListRequestStart } from "../../../../actions/library/types/list";

export default function* libraryTypeDeleteWatcher() {
  yield takeLatest(`${LIBRARY_TYPE_DELETE_REQUEST}${START}`, libraryTypeDeleteWorker)
}

function* libraryTypeDeleteWorker({ payload: id }) {
  try {
    const response = yield call(deleteLibraryType, id);
    yield put(libraryTypeDeleteRequestSuccess(response.data));
    yield put(libraryTypesListRequestStart())
  } catch (error) {
    yield put(libraryTypeDeleteRequestError(error.response.data?.result));
  }
}