import { call, put, takeLatest, select } from "redux-saga/effects";
import { RUBRICATOR_COUNT_REQUEST, RUBRICATOR_LIST_REQUEST, START } from "../../../actions/action-types";
import { rubricatorCount, rubricatorList } from "../../../../api/rubricator/rubricator";
import { rubricatorCountRequestError, rubricatorCountRequestSuccess } from "../../../actions/rubricator/count";
import { setRubricatorSearchData } from "../../../actions/rubricator/search";
import {
  rubricatorListRequestError,
  rubricatorListRequestStart,
  rubricatorListRequestSuccess
} from "../../../actions/rubricator/list";

export default function* rubricatorListWatcher() {
  yield takeLatest(`${RUBRICATOR_COUNT_REQUEST}${START}`, rubricatorCountWorker);
  yield takeLatest(`${RUBRICATOR_LIST_REQUEST}${START}`, rubricatorListWorker);
}

function* rubricatorCountWorker() {
  const searchData = yield select(({ rubricator }) => rubricator.search);
  try {
    const response = yield call(rubricatorCount, searchData.query);
    const count = parseInt(response.data.count, 10);
    yield put(rubricatorCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setRubricatorSearchData({ ...searchData, pageCount }));
    yield put(rubricatorListRequestStart());
  } catch (error) {
    yield put(rubricatorCountRequestError(error));
  }
}

function* rubricatorListWorker() {
  const searchData = yield select(({ rubricator }) => rubricator.search);
  const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(rubricatorList, searchData.sortBy, searchData.maxResults, paginateFrom, searchData.query);
    yield put(rubricatorListRequestSuccess(response.data));
  } catch (error) {
    yield put(rubricatorListRequestError(error));
  }
}