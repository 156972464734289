import React, { useRef, useState } from 'react'
import PdfPrintPanel from '../../../PDFPrint/Panel'
import { Button } from 'antd'
import styles from '../DocumentsModal.module.css'
import { useOnClickOutside } from '../../../../utils/hooks'

import { getMagazineData } from './getMagazineData' 
import { getReceiptData } from './getReceiptData' 
import { getVarianceReceiptData } from './getVarianceReceiptData' 



export default function ReceiptActPdf ({ data, options, magazinesMode, actData, clientInfo, libName, supplierName }) {
  
  const [choice, setChoice] = useState('')
  
  const popUpRef = useRef(null)
  useOnClickOutside(popUpRef, () => setChoice(''))

  const onLoadClick = () => {
    magazinesMode
     ? toPdf(getMagazineData, 'Приход газет/журналов')
     : setChoice('load')
  }
  
  const onPrintClick = () => {
    magazinesMode
     ? toPrint(getMagazineData)
     : setChoice('print')
  }
  
  const onChoiceButtonClick = (withVariance) => () => {
    const createDataCB = withVariance ? getVarianceReceiptData : getReceiptData
    const title = withVariance ? 'Поступление' : 'Поступление с расхождениями'
    choice === 'print' ? toPrint(createDataCB) : toPdf(createDataCB, title)
    setChoice('')
  }

  const toPdf = (createDataCB, title) => {
    createDataCB({options, actData, clientInfo, libName, supplierName}).then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = (createDataCB) => {
    createDataCB({options, actData, clientInfo, libName, supplierName}).then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }
  
  const magazineTable = (
    <table hidden id='magazineTable'>
      <thead>
        <tr>
          <th>№ п/п</th>
          <th>Автор, заглавие книги или вид документа</th>
          <th>Количество экземпляров</th>
          <th>Цена, руб., коп.</th>
          <th>Сумма, руб., коп.</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((book, index) => {
          const { price, amount, title, authors } = book
          const titleAuthors = `${title || ''}, ${authors || 'автор не указан'}`
          return (
            <tr>
              <td>{index+1}</td>
              <td>{titleAuthors}</td>
              <td>{amount}</td>
              <td>{price}</td>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )

  const receiptTable = (
    <table hidden id='receiptTable'>
      <thead>
        <tr>
          <th rowSpan={2}>Наименование материальных ценностей</th>
          <th colSpan={2}>Единица измерения</th>
          <th rowSpan={2}>Цена за единицу, руб</th>
          <th rowSpan={2}>Количество</th>
          <th rowSpan={2}>Сумма, руб</th>
          <th colSpan={2}>Корреспондирующие счета</th>
        </tr>
        <tr>
          <th>наименование</th>
          <th>код по ОКЕИ</th>
          <th>дебет</th>
          <th>кредит</th>
        </tr>
        <tr>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6</th>
          <th>7</th>
          <th>8</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((book, index) => {
          const { price, amount, title, authors, sum } = book
          const titleAuthors = `${title || ''}, ${authors || 'автор не указан'}`
          return (
            <tr key={index}>
              <td>{titleAuthors}</td>
              <td>штука</td>
              <td>796</td>
              <td>{price}</td>
              <td>{amount}</td>
              <td>{sum}</td>
              <td></td>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )

  const varianceFirstTable = (
    <table hidden id='varianceFirstTable'>
      <thead>
        <tr>
          <th rowSpan={2}>Место составления Акта</th>
          <th colSpan={2}>Время приемки (час. мин)</th>
          <th rowSpan={2}>Наименование, номер и дата сопроводительного документа</th>
          <th rowSpan={2}>Номер сертификата соответствия</th>
          <th rowSpan={2}>Дата отправки груза со станции (пристани, порта)</th>
        </tr>
        <tr>
          <th>начало</th>
          <th>окончание</th>
        </tr>
      </thead>
      <tbody>
          {new Array(3).fill(null).map((_) => (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  )
  
  const varianceSecondTable = (
    <table hidden id='varianceSecondTable'>
      <thead>
        <tr>
          <th rowSpan={2}>Отметка об опломбировании</th>
          <th rowSpan={2}>Количество мест</th>
          <th rowSpan={2}>Вид упаковки</th>
          <th rowSpan={2}>Наименование груза (мат. ценностей)</th>
          <th colSpan={2}>Единица измерения</th>
          <th colSpan={2}>Масса груза, т</th>
          <th rowSpan={2}>Особые отметки</th>
        </tr>
        <tr>
          <th>наименование</th>
          <th>код по ОКЕИ</th>
          <th>в пункте отправления</th>
          <th>в пункте прибытия</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((book, index) => {
          const { price, amount, title, authors } = book
          const titleAuthors = `${title || ''}, ${authors || 'автор не указан'}`
          return (
            <tr>
              <td>{}</td>
              <td>{}</td>
              <td>{}</td>
              <td>{titleAuthors}</td>
              <td>штука</td>
              <td>796</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
  
  const varianceThirdTable = (
    <table hidden id='varianceThirdTable'>
      <thead>
        <tr>
          <th>Прибытие на место назначения (станция, пристань, порт)</th>
          <th>Выдача груза транспортной организацией</th>
          <th>Вскрытие вагона (других транспортных средств)</th>
          <th>Доставка на склад получателя</th>
        </tr>
      </thead>
      <tbody>
          {new Array(3).fill(null).map((_) => (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  )
  
  const varianceFourthTable = (
    <table hidden id='varianceFourthTable'>
      <thead>
        <tr>
          <th rowSpan={2}>Вид деятельности</th>
          <th colSpan={2}>Корреспондирующие счета</th>
          <th rowSpan={2}>Сумма, руб (0,00)</th>
        </tr>
        <tr>
          <th>по дебету</th>
          <th>по кредиту</th>
        </tr>
      </thead>
      <tbody>
          {new Array(3).fill(null).map((_) => (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  )
  
  const varianceFifthTable = (
    <table hidden id='varianceFifthTable'>
      <thead>
        <tr>
          <th colSpan={3}>Материальные ценности</th>
          <th colSpan={2} rowSpan={2}>Единица измерения</th>
          <th colSpan={4} rowSpan={2}>По документам</th>
          <th colSpan={6}>Фактически</th>
          <th colSpan={2} rowSpan={2}>Брак и бой</th>
          <th colSpan={2} rowSpan={2}>Недостачи</th>
        </tr>
        <tr>
          <th>наименование</th>
          <th colSpan={2}>номер</th>
          <th colSpan={4}>всего</th>
          <th colSpan={2}>в том числе излишки</th>
        </tr>
        <tr>
          <th></th>
          <th>номенклатурный</th>
          <th>паспорта</th>
          <th>наименование</th>
          <th>код по ОКЕИ</th>
          <th>сорт</th>
          <th>количество</th>
          <th>цена, руб</th>
          <th>сумма, руб</th>
          <th>сорт</th>
          <th>количество</th>
          <th>цена, руб</th>
          <th>сумма, руб</th>
          <th>количество</th>
          <th>сумма, руб</th>
          <th>количество</th>
          <th>сумма, руб</th>
          <th>количество</th>
          <th>сумма, руб</th>
        </tr>
      </thead>
      <tbody>
          {new Array(5).fill(null).map((_) => (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  )

  return (
    <div style={{position: 'relative'}} className='ml-auto'>
      {receiptTable}
      {magazineTable}
      {varianceFirstTable}
      {varianceSecondTable}
      {varianceThirdTable}
      {varianceFourthTable}
      {varianceFifthTable}
      <PdfPrintPanel loadPdf={onLoadClick} printPdf={onPrintClick}/>
      {!!choice &&
        <div ref={popUpRef} className={styles.choiceBlock}>
          <Button onClick={onChoiceButtonClick()}>Приемка</Button>
          <Button onClick={onChoiceButtonClick('withVariance')}>Приемка с расхождением/несоответствием</Button>
        </div>
      }
    </div>
  )
}
