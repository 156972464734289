import React from 'react';
import T from 'prop-types';
import classNames from 'classnames';
import styles from './Alphabet.module.css';
import {Col, Row} from "antd";

const Alphabet = ({onSelect, items, span, itemClassName}) => {
    return (
        <div className={styles.container}>
            <Row>
                {items.map((item, index) => (
                    <Col md={span} key={index}>
                        <div className={classNames(styles.mainItem, itemClassName)} onClick={() => onSelect(item)}>
                            {item}
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

Alphabet.propTypes = {
    onSelect: T.func,
    items: T.array,
    itemClassName: T.string,
    span: T.number
};

Alphabet.defaultProps = {
    span: 6
};

export default Alphabet;
