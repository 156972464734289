import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import { getResponsible, updateResponsible, createResponsible } from '../../../api/mobileLibrary'
import { LIBRARY_RESPONSIBLE_CREATE_REQUEST, LIBRARY_RESPONSIBLE_UPDATE_REQUEST, MOBILE_LIBRARY_RESPONSIBLE_REQUEST, START } from '../../actions/action-types'
import { getResponsibleRequestStart, getResponsibleRequestSuccess } from '../../actions/mobileLibrary/responsible/list'
import { updateResponsibleRequestError } from '../../actions/mobileLibrary/responsible/update'

export default function* libResponsibleWatcher() {
  yield takeLatest(`${MOBILE_LIBRARY_RESPONSIBLE_REQUEST}${START}`, getResponsibleWorker)
  yield takeLatest(`${LIBRARY_RESPONSIBLE_CREATE_REQUEST}${START}`, createResponsibleWorker)
  yield takeLatest(`${LIBRARY_RESPONSIBLE_UPDATE_REQUEST}${START}`, updateResponsibleWorker)
}


function* getResponsibleWorker ({ libId }) {
  try {
    const response = yield call(getResponsible, libId)
    yield put(getResponsibleRequestSuccess(response.data.mobile_library_responsible || []))
  } catch (error) {
  }
}

function* updateResponsibleWorker ({ payload: {id, ...data} }) {
  try {
    yield call(updateResponsible, id, data)
    const libId = data.mobile_library_id
    yield put(getResponsibleRequestStart(libId))
    
  } catch (error) {
    yield put(updateResponsibleRequestError(error))
  }
}

function* createResponsibleWorker ({payload}) {
  try {
    yield call(createResponsible, payload)
    const libId = payload.mobile_library_id
    yield put(getResponsibleRequestStart(libId))

  } catch (error) {
  }
}