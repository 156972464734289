import React from 'react';
import T from 'prop-types';
import '../Razdel/Literature.css';
import {Link} from "react-router-dom";

const Index = ({ book }) => {
  const { name, course_one, course_two, course_three, course_four, course_five, course_all } = book;

  return (
    <Link to={`book/${book.library_bookid}`}>
        <div className="LitRow">
          <div className="LitRow_titleCell">{name}</div>
            <div className="LitRow_Cell">{course_one}</div>
            <div className="LitRow_Cell">{course_two}</div>
            <div className="LitRow_Cell">{course_three}</div>
            <div className="LitRow_Cell">{course_four}</div>
            <div className="LitRow_Cell">{course_five}</div>
            <div className="LitRow_Cell">{course_all}</div>
        </div>
    </Link>
  );
};

Index.propTypes = {
  book: T.object.isRequired,
};

export default Index;