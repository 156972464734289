import React from 'react';
import T from 'prop-types';
import styles from './StaticCollectionFormItem.module.css';
import { Col, Row } from "react-bootstrap";

const StaticCollectionFormItem = ({ data, add, onClick }) => {
  return (
    <div className={styles.container}>
      <Row>
        <Col md={4}>
          <div className={styles.infoContainer}>
            {data.title}
          </div>
        </Col>
        <Col md={4}>
          <div className={styles.infoContainer}>
            {data.authors}
          </div>
        </Col>
        <Col md={4} className={"text-right"}>
          <div className={styles.button} onClick={onClick}>
            {add ? "добавить" : "удалить"}
          </div>
        </Col>
      </Row>
    </div>
  );
};

StaticCollectionFormItem.propTypes = {
  data: T.object,
  add: T.bool,
  onClick: T.func,
};

export default StaticCollectionFormItem;
