import {call, put, takeLatest, select} from "redux-saga/effects";
import {REPORT_GET_REQUEST, REPORTS_LIST_REQUEST, REPORT_GET_ALL_REQUEST, START, EVENT_REPORT_REQUEST} from "../../actions/action-types";
import {getReportsList} from "../../../api/report/list";
import {reportsListRequestError, reportsListRequestSuccess} from "../../actions/reports/list";
import {getReport, getAllReport, getReportCount, getEventsReport} from "../../../api/report/report";
import {
    reportGetRequestError,
    reportGetRequestSuccess,
    reportGetAllRequestError,
    reportGetAllRequestSuccess
} from "../../actions/reports/get";
import {setReportsSearchData} from "../../actions/reports/searchData";
import { eventReportRequestError, eventReportRequestSuccess } from "../../actions/reports/eventsReport";


export default function* reportsWatcher() {
    yield takeLatest(`${REPORTS_LIST_REQUEST}${START}`, reportsListWorker);
    yield takeLatest(`${REPORT_GET_REQUEST}${START}`, reportWorker);
    yield takeLatest(`${REPORT_GET_ALL_REQUEST}${START}`, reportAllWorker);
    yield takeLatest(`${EVENT_REPORT_REQUEST}${START}`, eventReportWorker);
}

function* reportsListWorker() {
    try {
        const response = yield call(getReportsList);
        yield put(reportsListRequestSuccess(response.data));
    } catch (error) {
        yield put(reportsListRequestError(error));
    }
}

function* reportWorker({payload: {id, formData}}) {
    const searchData = yield select(({reports}) => reports.search);

    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const responseCount = yield call(getReportCount, id, formData);
        const count = parseInt(responseCount.data[0].count);
        searchData.count = count;
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setReportsSearchData({...searchData, pageCount}));
        const response = yield call(getReport, id, formData, searchData);
        yield put(reportGetRequestSuccess({id, data: response.data}));
    } catch (error) {
        yield put(reportGetRequestError(error));
    }
}

function* reportAllWorker({payload: {id, formData}}) {
    try {
        const response = yield call(getAllReport, id, formData);
        yield put(reportGetAllRequestSuccess({id, data: response.data}));
    } catch (error) {
        yield put(reportGetAllRequestError(error));
    }
}

function* eventReportWorker ({payload}) {
    try {
        const response = yield call(getEventsReport, payload);
        const data = response.data.rows
        yield put(eventReportRequestSuccess({id: 'eventsReport', data}));
    } catch (error) {
        yield put(eventReportRequestError(error));
    }
}
