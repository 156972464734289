import {
  ERROR,
  ROLE_MEMBER_SAVE_REQUEST,
  START,
  SUCCESS
} from "../action-types";

export const roleMemberSaveRequestStart = data => ({
  type: `${ROLE_MEMBER_SAVE_REQUEST}${START}`,
  payload: data
});

export const roleMemberSaveRequestSuccess = data => ({
  type: `${ROLE_MEMBER_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const roleMemberSaveRequestError = data => ({
  type: `${ROLE_MEMBER_SAVE_REQUEST}${ERROR}`,
  payload: data
});
