import { api } from "../../App";


export const getLostDoc = (params) => {
  return api.get(`/lost_docs`, { params });
};

export const getLostDocCount = (params) => {
  return api.get(`/lost_docs/count`, {params});
};

export const addLostDoc = (data) => {
  return api.post(`/lost_docs`, data);
};

// export const createDoc = (data) => {
//   return api.post(`/lost_docs`, data);
// };

// export const updateDoc = (docId, data) => {
//   return api.put(`/lost_docs/${docId}`, data);
// };
