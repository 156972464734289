import { ERROR, REGIONS_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const regionsCountRequestStart = () => ({
  type: `${REGIONS_COUNT_REQUEST}${START}`
});

export const regionsCountRequestSuccess = (data) => ({
  type: `${REGIONS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const regionsCountRequestError = (data) => ({
  type: `${REGIONS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
