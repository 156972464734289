import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import styles from './EditorModal.module.css';
import { Col, Row } from "react-bootstrap";
import Button from "../../Button";
import Composer from "./Composer";
import { racksListRequestStart } from "../../../redux/actions/location/racks/list";
import { shelvesListRequestStart } from "../../../redux/actions/location/shelves/list";
import { rackSaveRequestStart } from "../../../redux/actions/location/racks/save";
import { shelfSaveRequestStart } from "../../../redux/actions/location/shelves/save";
import { roomSaveRequestStart } from "../../../redux/actions/location/rooms/save";
import ModalAddOther from "./ModalAddOther";
import ModalWindow from "../../ModalWindow";

const MAX_HEIGHT_EDITOR = 400;
const wrapperStyles = `background-color: #C0D6DF; width: 100%; height: ${MAX_HEIGHT_EDITOR}px;`;
const gridStyles = 'background-color: #B8BBAA;';

const RectType = {
  RACK: `rack`,
  OTHEROBJECT: `other`,
};

const RectColor = {
  RED: `#e53935`,
  BLUE: '#3949ab'
};

const mapStateToProps = state => ({
  rooms: state.location.rooms,
  racks: state.location.racks,
  shelves: state.location.shelves
});

const mapDispatchToProps = dispatch => ({
  requestRacks: (roomId) => dispatch(racksListRequestStart(roomId)),
  requestShelves: (rackId) => dispatch(shelvesListRequestStart(rackId)),
  saveRack: (roomId, data) => dispatch(rackSaveRequestStart(roomId, data)),
  saveShelf: (rackId, data) => dispatch(shelfSaveRequestStart(rackId, data)),
  saveRoom: (libraryId, data) => dispatch(roomSaveRequestStart(libraryId, data)),
});

const EditorModal = ({ modalData, setEditorData, saveRoom, saveRack, saveShelf, requestShelves, rooms, racks, shelves }) => {
  const { roomId, locationType, parentId } = modalData;
  const isVisible = !!locationType;
  let room = rooms.find((room) => room.id === roomId);

  if (!room) {
    room = {
      name: "",
      code: "",
      layout: null
    }
  }

  let layoutRoom = { racks: {}, otherObjects: [] };

  const generateRectangle = (data) => {    
    const otherObjects = generateOtherObjects();
    const noCoordElement = data.filter(item => !layoutRoom.racks[item.id])

    let result = data.filter(item => layoutRoom.racks[item.id]).map(item =>
      ({
          ...layoutRoom.racks[item.id],
          fill: RectColor.RED,
          title: item.name,
          id: item.id,
          type: RectType.RACK
      }));

    result = result.concat(otherObjects);
    noCoordElement.map(item => {
      const newObject = {
        ...findFreeArea(result, 100, 20),
        fill: RectColor.RED,
        title: item.name,
        id: item.id,
        type: RectType.RACK
      };
      layoutRoom.racks[item.id] = newObject;
      result.push(newObject);
    });
    return result;
  };

  const generateRectangleShelves = (data) => {
    return data.map((item, index) => ({
      top: index * 40 + 5,
      bottom:  index * 40 + 35,
      left: 5,
      right: 395,
      fill: RectColor.RED,
      title: item.name,
      id: item.id
    }));
  };

  const generateOtherObjects = () => {
    if (!layoutRoom.otherObjects) {
      return [];
    }
    return layoutRoom.otherObjects.map((item, index) => ({
        ...item,
        type: RectType.OTHEROBJECT,        
        fill: RectColor.BLUE,
        id: index
      })
    )
  };

  const [rackEdit, setRackEdit] = useState(null);
  const [initialRacks, setInitialRacks] = useState([]);
  const [initialShelf, setInitialShelf] = useState([]);
  const [isVisibleModalOther, setisVisibleModalOther] = useState(false);

  if(room && room.layout !== null ) {
    layoutRoom = JSON.parse(room.layout);   
  }

  useEffect(() => {
    if(room && racks[roomId]) {
      setInitialRacks(generateRectangle(racks[roomId]));
    }
    if(rackEdit && shelves[rackEdit.id] && initialShelf.length !== shelves[rackEdit.id].length) {
      setInitialShelf(generateRectangleShelves(shelves[rackEdit.id]));
    }
  }, [room, racks, shelves]);

  const addRack = () => {
    let maxBottom = 0;
    initialRacks.map((item) => {
      if(maxBottom < item.bottom) {
        maxBottom = item.bottom;
      }
    })
    if(MAX_HEIGHT_EDITOR - maxBottom < 40) {
      return;
    }
    saveRack(roomId, { name: `Стеллаж ${racks[roomId].length + 1}`, code: ""})
  };

  const addObject = (name) => {
    const newObject = findFreeArea(initialRacks, 20, 20);
    newObject.title = name;
    handleVisibleModalClose();
    saveRoom(parentId, {...room, layout: {...layoutRoom, otherObjects: [...layoutRoom.otherObjects, newObject]}});
    
  };

  const findFreeArea = (objects, width, height) => {
    let top = 0;
    let left = 0;
    let isCoord = false;

    for ( var y = 0; y < MAX_HEIGHT_EDITOR - height; y += 5) {
      for ( var x = 0; x < MAX_HEIGHT_EDITOR - width; x += 5) {
        const newObject = { x, y, width, height };
        let isIntersect = false;
        // eslint-disable-next-line no-loop-func
        objects.map((object) => {
          if(!isIntersect) {          
            const objectRect = { 
              x: object.left, 
              y: object.top, 
              width: object.right - object.left, 
              height: object.bottom - object.top
            };
            if (haveIntersection(newObject, objectRect)) {
              isIntersect = true;
              top = y;
              left = x;
            }      
          }    
        });

        //Нет пересечений с другими объектами
        if (!isIntersect) {
          top = y;
          left = x;
          isCoord = true;
        }

        if (isCoord) {
          break;
        }
      }
      if (isCoord) {
        break;
      }
    }

    return {
      top,
      bottom: top + height,
      left,
      right: left + width
    };
  };

  const haveIntersection = (r1, r2) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  };

  const addShelf = () => {
    if(rackEdit === null) {
      return;
    }
    let maxBottom = 0;
    if(initialShelf.length > 0) {
      maxBottom = initialShelf[initialShelf.length - 1].bottom;
    }
    if(MAX_HEIGHT_EDITOR - maxBottom < 40) {
      return;
    }
    saveShelf(rackEdit.id, { name: `Полка ${initialShelf.length + 1}`, code: ""});    
  };

  const handleChangeRect = (index) => {
    const rectChange = initialRacks[index];
    if (rectChange.type === RectType.RACK) {
      requestShelves(rectChange.id);      
      setRackEdit(rectChange);
    } else {
      setRackEdit(null);
    }
    setInitialShelf(generateRectangleShelves([]));    
  };

  const handleClose = () => {
    setEditorData({ modalType: null, room: null });
    setRackEdit(null);
    setInitialShelf([]);
    setInitialRacks([]);
  };

  const handleEditCoordRect= (rack) => {
    if (rack.type === RectType.RACK) {    
      const otherRacks = {};
      initialRacks.map(data => {
        if(data.type === RectType.RACK) {
          otherRacks[data.id] = data
        }
      });
      layoutRoom.racks = {...otherRacks};
      layoutRoom.racks[rack.id] = rack;     
    } else {
      layoutRoom.otherObjects[rack.id] = rack;
    }
    saveRoom(parentId, {...room, layout: layoutRoom});
  };

  const handleVisibleModalOpen = () => setisVisibleModalOther(true);
  const handleVisibleModalClose = () => setisVisibleModalOther(false);

  return (
    <ModalWindow title={`${room? room.name : locationType}: "Редактор"`}
                 onRequestClose={handleClose}
                 isOpen={isVisible}>
      {room &&
      <Row>
        <style>
          {`.composer.composer {${wrapperStyles}}`}
          {`.composer .grid {${gridStyles}}`}
        </style>
        <Col md={3}>
          <Button label="Добавить стеллаж" style={styles.buttonAdd} onClick={addRack}/>
          <Button label="Добавить объект" style={styles.buttonAdd} onClick={handleVisibleModalOpen}/>
        </Col>
        <Col md={9}>
          <Composer
            initialRectangles={initialRacks}
            relativeWidth={MAX_HEIGHT_EDITOR}
            relativeHeight={MAX_HEIGHT_EDITOR}
            wrapperClassName="composer"
            gridClassName="grid"
            onChangeRect={handleChangeRect}
            onChange={handleEditCoordRect}
            setRectangles={setInitialRacks}
          />
        </Col>
        {rackEdit && 
          <React.Fragment>
            <Col md={12}><div className={styles.divider}/></Col>
            <Col md={12}>           
              <Row>
                <Col md={3}>
                  <Button label="Добавить полку" style={styles.buttonAdd} onClick={addShelf}/>
                </Col>
                <Col md={9}>
                  <Row>
                    <Col md={12}>
                      <div className={styles.title}>{rackEdit.title}</div>
                    </Col>
                    <Col md={12}>
                      <Composer
                        initialRectangles={initialShelf}
                        relativeWidth={MAX_HEIGHT_EDITOR}
                        relativeHeight={MAX_HEIGHT_EDITOR}
                        wrapperClassName="composer"
                        gridClassName="grid"
                        isDraggable={false}
                        setRectangles={setInitialShelf}
                        
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </React.Fragment>
        }
        <Col md={12} className={'d-flex justify-content-end'}>
            <Button label={'Закрыть'} onClick={handleClose} colorType={'red'}/>
        </Col>
      </Row>
      }
      <ModalAddOther 
        isVisible={isVisibleModalOther}
        onSave={addObject}
        onClose={handleVisibleModalClose}
      />
    </ModalWindow>
  );
};

EditorModal.propTypes = {
  modalData: PropTypes.object,
  setModalData: PropTypes.func,
  saveRack: PropTypes.func,
  saveShelf: PropTypes.func,
  saveRoom: PropTypes.func,
  requestShelves: PropTypes.func,
  racks: PropTypes.object,
  shelves: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorModal);
