import { AUDIT_DATASETS_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const auditGetDatasetsRequestStart = () => ({
  type: `${AUDIT_DATASETS_REQUEST}${START}`
});

export const auditGetDatasetsRequestSuccess = (data) => ({
  type: `${AUDIT_DATASETS_REQUEST}${SUCCESS}`,
  payload: data
});

export const auditGetDatasetsRequestError = (data) => ({
  type: `${AUDIT_DATASETS_REQUEST}${ERROR}`,
  payload: data
});
