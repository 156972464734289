import { PERMISSIONS_BY_SERIES_COUNT_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const permissionsBySeriesCountRequestStart = (data) => ({
  type: `${PERMISSIONS_BY_SERIES_COUNT_REQUEST}${START}`,
  payload: data
});

export const permissionsBySeriesCountRequestSuccess = (data) => ({
  type: `${PERMISSIONS_BY_SERIES_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const permissionsBySeriesCountRequestError = (data) => ({
  type: `${PERMISSIONS_BY_SERIES_COUNT_REQUEST}${ERROR}`,
  payload: data
});
