import React from 'react';
import T from 'prop-types';
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";
import moment from "moment";

const EventsTable = ({ data }) => {
    return (
        <DataTable data={data}>
            <DataTableCol title="Лог" value={'logtext'} colSpan={2}/>
            <DataTableCol title="Дата" resolver={({ ts }) => moment(ts).format('DD.MM.YYYY')}/>
            <DataTableCol title="Статус" value={'status'}/>
        </DataTable>
    );
};

EventsTable.propTypes = {
    data: T.array,
};

export default EventsTable;