import {
  DOCUMENT_BOOK_LIST_REQUEST,
  START,
  SUCCESS,
  ERROR,
  DOCUMENT_BOOK_CLEANUP,
  INVENTORY_BOOK_CLEANUP, INVENTORY_BOOK_LIST_REQUEST
} from "../../action-types";

export const inventoryBookListRequestStart = (documentId) => ({
  type: `${INVENTORY_BOOK_LIST_REQUEST}${START}`,
  payload: documentId
});

export const inventoryBookListRequestSuccess = (data) => ({
  type: `${INVENTORY_BOOK_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const inventoryBookListRequestError = (data) => ({
  type: `${INVENTORY_BOOK_LIST_REQUEST}${ERROR}`,
  payload: data
});

export const inventoryBooksCleanup = () => ({
  type: `${INVENTORY_BOOK_CLEANUP}`
});
