import { ERROR, RACK_SAVE_REQUEST, START, SUCCESS } from "../../action-types";

export const rackSaveRequestStart = (roomId, data) => ({
  type: `${RACK_SAVE_REQUEST}${START}`,
  payload: { data, roomId }
});

export const rackSaveRequestSuccess = (data) => ({
  type: `${RACK_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const rackSaveRequestError = (data) => ({
  type: `${RACK_SAVE_REQUEST}${ERROR}`,
  payload: data
});
