import { USERS_SAVE_REQUEST_TOGGLE_LOADING } from '../../../actions/action-types'

const initialState = {
  isLoading: false,
}

export const usersSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_SAVE_REQUEST_TOGGLE_LOADING:
      return { isLoading: !state.isLoading }

    default:
      return state
  }
}
