import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import { Radio, Button } from 'antd'
import ModalInputWrapper from '../../../Modal/InputWrapper'

const defaultValidationSchema = Yup.object().shape({
  dateFrom: Yup.date().required("Выберите дату начала"),
  dateTo: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("dateFrom"), "Дата завершения должна быть позже даты начала"),
});

const AggregationReportForm = ({ onSubmit, institutionsList, orgId }) => {
  const dateFormat = 'YYYY-MM-DD'

  const handleSubmit = (values) => {
    const payload = values.mode == 2 ? { ...values, for_all: true } : values
    onSubmit(payload)
  }

  const searchForm = useFormik({
    initialValues: {
      dateFrom: moment().subtract(1, 'months').format(dateFormat),
      dateTo: moment().format(dateFormat),
      mode: 0,
      // Шифр
      client: orgId || 0,
    },
    validationSchema: defaultValidationSchema,
    onSubmit: handleSubmit
  })

  return (
    <div className={'formContainer'}>
      <Row className={'justify-content-between'}>
        <Col md={10} lg={11}>
          <Row>
            <Col>
              <ModalInputWrapper
                label='C*'
                value={searchForm.values.dateFrom}
                name='dateFrom'
                error={searchForm.errors.dateFrom}
                onChange={searchForm.handleChange}
                type='date'
              />
            </Col>
            <Col>
              <ModalInputWrapper
                label='По*'
                value={searchForm.values.dateTo}
                name='dateTo'
                error={searchForm.errors.dateTo}
                onChange={searchForm.handleChange}
                type='date'
              />
            </Col>
            <span className='rowDescription'>показатели рассчитываются за период и на дату "по"</span>
          </Row>
        </Col>

        <Col md={12} lg={12}>
          <ModalInputWrapper label={'Организация'}>
            <select onChange={searchForm.handleChange} value={searchForm.values.client} name='client'>
              <option value={0}>Все</option>
              {institutionsList.map(institution => (
                <option value={institution.value}>{institution.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={6} lg={6}>
          <ModalInputWrapper label={'Режим'}>
            <Radio.Group onChange={searchForm.handleChange} value={searchForm.values.mode} name='mode'>
              <Radio value={2}>Без разбиения</Radio>
              <Radio value={0}>Разбить по организациям</Radio>
              <Radio value={1}>Разбить по библиотекам</Radio>
            </Radio.Group>
          </ModalInputWrapper>
        </Col>
        <Col md={'auto'}>
          <Button onClick={searchForm.handleSubmit} type='primary'>
            Найти
          </Button>
        </Col>
      </Row>
    </div>
  )
}

AggregationReportForm.propTypes = {
  onSubmit: PropTypes.func,
  institutionsList: PropTypes.array,
}

export default AggregationReportForm
