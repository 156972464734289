import React, { useEffect } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { optionsGetRequestStart } from '../redux/actions/options/get'
import { UserRolePermissionsRequestStart } from '../redux/actions/user/rolePermissions'
import AuthScene from '../scenes/Auth'
import Layout from '../scenes/Layout'
import PrivateRoute from '../components/Routing/PrivateRoute'
import IndexScene from '../scenes/Index'
import PublishersScene from '../scenes/Catalogs/Publishers'
import UDKScene from '../scenes/Catalogs/UDK'
import BBKScene from '../scenes/Catalogs/BBK'
import SVNIScene from '../scenes/Catalogs/SVNI'
import AuthorsScene from '../scenes/Catalogs/Authors'
import LibraryAlphabetScene from '../scenes/LibraryAlphabet'
import ElectronicBookImport from '../scenes/ElectronicBookImport'
import InstitutionsScene from '../scenes/Catalogs/Institutions'
import ReaderSearchScene from '../scenes/ReaderSearch'
import LibraryListScene from '../scenes/LibraryList'
import LibraryTypesScene from '../scenes/Catalogs/LibraryTypes'
import BookLoanScene from '../scenes/BookLoan'
import BookLoanHistoryScene from '../scenes/BookLoanHistory'
import BookLoanSpecialtyScene from '../scenes/BookLoanSpecialty'
import BookDescriptionScene from '../scenes/BookDescription'
import ScrollToTop from '../components/Routing/ScrollToTop'
import RubricatorScene from '../scenes/Catalogs/Rubricator'
import InvNumbersScene from '../scenes/Catalogs/InvNumbers'
import CoversScene from '../scenes/Catalogs/Covers'
import BookInventory from '../scenes/BookInventory'
import AdminMenuScene from '../scenes/AdminMenu'
import OptionsScene from '../scenes/OptionsScene'
import AdminRolesScene from '../scenes/AdminRoles'
import AdminUsersScene from '../scenes/AdminUsers'
import Directory from '../scenes/AdminDirectory'
import LocationScene from '../scenes/Location'
import CollectionsScene from '../scenes/Catalogs/Collections'
import AdminPermissionsScene from '../scenes/Permissions/AdminPermissions'
import AdminAboutPermissionsScene from '../scenes/Permissions/AdminAboutPermissions'
import ExternalResourcesScene from '../scenes/ExternalResources'
import ReadingScene from '../scenes/Reading'
import PDFViewerScene from '../scenes/PDFViewer'
import SecurityAccessLogScene from '../scenes/SecurityLog'
import AuditLogScene from '../scenes/AuditLog'
import ReportsScene from '../scenes/Reports/List'
import QRCodesScene from '../scenes/QRCodes'
import ImportScene from '../scenes/ImportScene'
import ExternalResourcesEditScene from '../scenes/ExternalResourcesEdit'
import BookEditScene from '../scenes/BookEdit'
import HavkinaScene from '../scenes/Havkina'
import DocumentTypesScene from '../scenes/Catalogs/DocumentTypes'
import DocumentsScene from '../scenes/Documents'
import InventoryScene from '../scenes/Inventory'
import BooksOrderScene from '../scenes/BooksOrder'
import NewBooksOrderScene from '../scenes/NewBooksOrder'
import SystemInfoScene from '../scenes/SystemInfo'
import BooksScene from '../scenes/Catalogs/Books'
import BookSupply from '../scenes/BookSupply'
import BookSupplyStandarts from '../scenes/BookSupplyStandatrs/BookSupplyStandarts'
import Teachers from '../scenes/Teachers'
import ScheduleOnDate from '../scenes/ScheduleOnDate'
import ExternalSearch from '../scenes/ExternalSearch'
import ScheduleScene from '../scenes/Schedule'
import SearchByImageContainer from '../scenes/SearchByImage'
import FundAccounting from '../scenes/FundAccounting'
import FundMovingScene from '../scenes/FundMoving'
import EnvelopePrint from '../scenes/BookEdit/EnvelopePrint'
import CatalogCard from '../scenes/BookEdit/CatalogCard'
import Formular from '../components/BookLoan/LibraryCardBlock/Formular/Formular'
import PrintInvFormular from '../scenes/Catalogs/InvNumbers/PrintInvFormular'
import StudentProjects from '../scenes/StudentProjects'
import Duplicate from '../scenes/Duplicate'
import TurnoutStats from '../scenes/TurnoutStats'
import SearchAudit from '../scenes/SearchAudit'
import Remcomplect from '../scenes/Remcomplect'
import LibraryMaterials from '../scenes/LibraryMaterials'
import BookScanPlan from '../scenes/BookScanPlan'
import BookExportScene from '../scenes/BookExport'
import BigDataScene from '../scenes/BigData'
import ReportContentScene from '../scenes/ReportContent'
import CheckDataLog from '../scenes/CheckDataLog'
import ElectronicCourses from './../scenes/ElectronicCourses/index'
import CatalogCards from './../scenes/CatalogCards'
import QRCodesSehlvesScene from './../scenes/QRCodesShelves'
import CategoriesScene from './../scenes/Catalogs/Categories'
import AdminCategoriesPermissionsScene from '../scenes/Permissions/AdminCategoriesPermissions'
import SeriesScene from '../scenes/Catalogs/Series'
import LitersScene from '../scenes/Catalogs/Liters'
import LitersPermissionsScene from '../scenes/Permissions/LitersPermissions'
import SeriesPermissionsScene from '../scenes/Permissions/SeriesPermissions'
import BookScanOrdersScene from '../scenes/BookScanOrders'
import MediaResource from '../scenes/MediaResource'
import MediaResourceEdit from '../scenes/MediaResource/Edit'
import MessageScene from '../scenes/Message'
import BorrowingTest from '../scenes/BorrowingTest'
import BookTransfer from '../scenes/BookTransfer'
import BookReserve from '../scenes/BookReserve'
import LibraryCardPass from '../components/BookLoan/LibraryCardBlock/LibraryCardPass'
import SPOIntegrationScene from '../scenes/SPOIntegration'
import CreditsScene from '../scenes/Credits'
import SchedulerScene from '../scenes/Scheduler'
import EPUBReader from '../scenes/EPUBReader'
import TestsScene from '../scenes/Tests'
import NewBooksScene from '../scenes/NewBooks'
import BibliographyScene from '../scenes/Bibliography'
import DisciplineScene from '../scenes/Catalogs/Discipline'
import BookOpenHistory from '../scenes/BookOpenHistory'
import SecretDocBook from '../scenes/SecretDocBook'
import AnswerStat from '../scenes/Answerstat'
import ExemptTests from '../scenes/ExemptTests'
import StateSecretBook from '../scenes/StateSecretBook'
import CoversScanPlanScene from '../scenes/CoversScanPlan'
import TvScene from '../scenes/Tv'
import CurrentPerfomance from '../scenes/CurrentPerfomance'
import Performances from '../scenes/Performances'
import Performance from '../scenes/Perfomance'
import { Antiplagiat } from '../scenes/Antiplagiat'
import AdminGroupsScene from '../scenes/AdminGroups'
import MobileLibraryList from '../scenes/MobileLibrary/list'
import MobileLibraryPage from '../scenes/MobileLibrary/page'


const mapStateToProps = ({ auth, options }) => ({
  options: options.data,
  authData: auth.data,
})

const mapDispatchToProps = dispatch => ({
  requestOptions: () => dispatch(optionsGetRequestStart()),
  requestUserRolePermissions: () => dispatch(UserRolePermissionsRequestStart()),
})

const RoutesComponent = ({ requestOptions, requestUserRolePermissions, options, authData }) => {
  
  useEffect(() => {
    authData && requestOptions()
  }, [authData, requestOptions])

  useEffect(() => {
    requestUserRolePermissions()
  }, [requestUserRolePermissions])

  // useEffect(() => {
  //   if (options) {
  //     document.title = options?.GOU_Name?.join('') || ''
  //   }
  // }, [options])

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path={'/pdfViewer/:bookId/:fileId'} component={PDFViewerScene} />
          <Route exact path={'/epub_reader/:bookId/:fileId'} component={EPUBReader} />
          <Route exact path={'/qr_codes'} component={QRCodesScene} />
          <Route exact path={'/qr_codes_shelves'} component={QRCodesSehlvesScene} />
          <Route exact path={'/envelope_print/:id'} component={EnvelopePrint} />
          <Route exact path={'/catalogcard_print/:id'} component={CatalogCard} />
          <Route exact path={'/formular_print/:mid'} component={Formular} />
          <Route exact path={'/library_card_pass_print/:mid'} component={LibraryCardPass} />
          <Route exact path={'/inv_printformular/:id'} component={PrintInvFormular} />
          <Route
            path={'/'}
            render={history => (
              <Layout history={history}>
                <PrivateRoute exact path={'/'} component={IndexScene} />
                <PrivateRoute exact path={'/rubricator'} component={RubricatorScene} adminOnly />
                <PrivateRoute exact path={'/publishers'} component={PublishersScene} adminOnly />
                <PrivateRoute exact path={'/antiplagiat_collection'} component={Antiplagiat} adminOnly />
                <PrivateRoute exact path={'/udk'} component={UDKScene} />
                <PrivateRoute exact path={'/bbk'} component={BBKScene} />
                <PrivateRoute exact path={'/authors'} component={AuthorsScene} />
                <PrivateRoute exact path={'/book_loan'} component={BookLoanScene} />
                <PrivateRoute exact path={'/book_loan/books'} component={BookLoanHistoryScene} />
                <PrivateRoute exact path={'/book_loan/specialty'} component={BookLoanSpecialtyScene} />
                <PrivateRoute exact path={'/books'} component={BooksScene} adminOnly />
                <PrivateRoute exact path={'/booksexport'} component={BookExportScene} adminOnly />
                <PrivateRoute exact path={'/book/:id'} component={BookDescriptionScene} />
                <PrivateRoute exact path={'/book/:id/edit'} component={BookEditScene} adminOnly />
                <PrivateRoute exact path={'/library'} component={LibraryListScene} />
                <PrivateRoute exact path={'/library/alphabet'} component={LibraryAlphabetScene} />
                <PrivateRoute exact path={'/electronic_book_import'} component={ElectronicBookImport} adminOnly />
                <PrivateRoute exact path={'/library/reader_search'} component={ReaderSearchScene} adminOnly />
                <PrivateRoute exact path={'/library/types'} component={LibraryTypesScene} adminOnly />
                <PrivateRoute exact path={'/institutions'} component={InstitutionsScene} adminOnly />
                <PrivateRoute exact path={'/covers'} component={CoversScene} adminOnly />
                <PrivateRoute exact path={'/coversScanPlan'} component={CoversScanPlanScene} adminOnly />
                <PrivateRoute exact path={'/inv_numbers'} component={InvNumbersScene} adminOnly />
                <PrivateRoute exact path={'/admin/menu'} component={AdminMenuScene} adminOnly />
                <PrivateRoute exact path={'/admin/roles'} component={AdminRolesScene} adminOnly />
                <PrivateRoute exact path={'/admin/groups'} component={AdminGroupsScene} adminOnly />
                <PrivateRoute exact path={'/options'} component={OptionsScene} />
                <PrivateRoute exact path={'/admin/users'} component={AdminUsersScene} adminOnly />
                <PrivateRoute exact path={'/admin/permissions'} component={AdminPermissionsScene} adminOnly />
                <PrivateRoute
                  exact
                  path={'/admin/about_permissions'}
                  component={AdminAboutPermissionsScene}
                  adminOnly
                />
                <PrivateRoute
                  exact
                  path={'/admin/categories_permissions'}
                  component={AdminCategoriesPermissionsScene}
                  adminOnly
                />
                <PrivateRoute exact path={'/admin/liters_permissions'} component={LitersPermissionsScene} adminOnly />
                <PrivateRoute exact path={'/admin/series_permissions'} component={SeriesPermissionsScene} adminOnly />
                <PrivateRoute exact path={'/location'} component={LocationScene} adminOnly />
                <PrivateRoute exact path={'/collections'} component={CollectionsScene} adminOnly />
                <PrivateRoute exact path={'/categories'} component={CategoriesScene} adminOnly />
                <PrivateRoute exact path={'/external'} component={ExternalResourcesScene} />
                <PrivateRoute exact path={'/external/edit'} component={ExternalResourcesEditScene} />
                <PrivateRoute exact path={'/reading'} component={ReadingScene} />
                <PrivateRoute exact path={'/librarymaterials/:id'} component={LibraryMaterials} />
                <PrivateRoute exact path={'/library_card/:id'} component={BookLoanHistoryScene} />
                <PrivateRoute exact path={'/book_open_history/:id'} component={BookOpenHistory} />
                <PrivateRoute exact path={'/bookreserve'} component={BookReserve} adminOnly />
                <PrivateRoute exact path={'/admin/log/audit'} component={AuditLogScene} adminOnly />
                <PrivateRoute exact path={'/admin/log/journal'} component={SecurityAccessLogScene} adminOnly />
                <PrivateRoute exact path={'/admin/system'} component={SystemInfoScene} adminOnly />
                <PrivateRoute exact path={'/admin/log/check'} component={CheckDataLog} adminOnly />
                <PrivateRoute exact path={'/admin/scheduler'} component={SchedulerScene} adminOnly />
                <PrivateRoute exact path={'/admin/directory'} component={Directory} adminOnly />
                <PrivateRoute exact path={'/admin/directory/:id'} component={SVNIScene} adminOnly />
                <PrivateRoute exact path={'/reports'} component={ReportsScene} adminOnly />
                <PrivateRoute exact path={'/report/:id'} component={ReportContentScene} adminOnly />
                <PrivateRoute exact path={'/book_transfer'} component={BookTransfer} />
                <PrivateRoute exact path={'/credits'} component={CreditsScene} />
                <PrivateRoute exact path={'/bigdata'} component={BigDataScene} adminOnly />
                <PrivateRoute exact path={'/import'} component={ImportScene} />
                <PrivateRoute exact path={'/havkina'} component={HavkinaScene} />
                <PrivateRoute exact path={'/document_types'} component={DocumentTypesScene} adminOnly />
                <PrivateRoute exact path={'/documents'} component={DocumentsScene} adminOnly />
                <PrivateRoute exact path={'/inventory'} component={InventoryScene} adminOnly />
                <PrivateRoute exact path={'/booksecretdoc'} component={SecretDocBook} adminOnly />
                <PrivateRoute exact path={'/mobile_library'} component={MobileLibraryList} />
                <PrivateRoute exact path={'/mobile_library/:libId'} component={MobileLibraryPage} />
                <PrivateRoute exact path={'/bookorders'} component={BooksOrderScene} adminOnly />
                <PrivateRoute exact path={'/booksupply'} component={BookSupply} adminOnly />
                <PrivateRoute exact path={'/booksupplystandarts'} component={BookSupplyStandarts} adminOnly />
                <PrivateRoute exact path={'/bookinventory'} component={BookInventory} adminOnly />
                <PrivateRoute exact path={'/fund_accounting'} component={FundAccounting} adminOnly />
                <PrivateRoute exact path={'/newbookorders'} component={NewBooksOrderScene} adminOnly />
                <PrivateRoute exact path={'/fund_moving'} component={FundMovingScene} adminOnly />
                <PrivateRoute exact path={'/teachers'} component={Teachers} />
                <PrivateRoute exact path={'/spo_intergartion'} component={SPOIntegrationScene} adminOnly />
                <PrivateRoute exact path={'/schedule_on_date'} component={ScheduleOnDate} />
                <PrivateRoute exact path={'/external_resources'} component={ExternalSearch} />
                <PrivateRoute exact path={'/student_projects'} component={StudentProjects} />
                <PrivateRoute exact path={'/duplicate'} component={Duplicate} adminOnly />
                <Route exact path='/auth' component={AuthScene} />
                <PrivateRoute exact path={'/schedule'} component={ScheduleScene} />
                <PrivateRoute exact path={'/current_perfomance'} component={CurrentPerfomance} />
                <PrivateRoute exact path={'/performances'} component={Performances} />
                <PrivateRoute exact path={'/performances/:semesterId/:courseId'} component={Performance} />
                <PrivateRoute exact path={'/turnout_stats'} component={TurnoutStats} />
                <PrivateRoute exact path={'/electronic_courses'} component={ElectronicCourses} />
                <PrivateRoute exact path={'/search_audit'} component={SearchAudit} />
                <PrivateRoute exact path={'/remcomplect'} component={Remcomplect} adminOnly />
                <PrivateRoute exact path={'/bookscan'} component={BookScanPlan} adminOnly />
                <PrivateRoute exact path='/search_by_image' component={SearchByImageContainer} />
                <PrivateRoute exact path='/catalogcards' component={CatalogCards} />
                <PrivateRoute exact path='/series' component={SeriesScene} adminOnly />
                <PrivateRoute exact path='/leters' component={LitersScene} adminOnly />
                <PrivateRoute exact path='/book_scan_orders' component={BookScanOrdersScene} />
                <PrivateRoute exact path='/media_resource' component={MediaResource} />
                <PrivateRoute exact path='/media_resource/edit' component={MediaResourceEdit} />
                <PrivateRoute exact path='/message' component={MessageScene} />
                <PrivateRoute exact path='/borrowing_test' component={BorrowingTest} />
                <PrivateRoute exact path='/tests' component={TestsScene} />
                <PrivateRoute exact path='/newbooks' component={NewBooksScene} />
                <PrivateRoute exact path='/bibliography' component={BibliographyScene} />
                <PrivateRoute exact path='/discipline' component={DisciplineScene} adminOnly />
                <PrivateRoute exact path='/answerstat' component={AnswerStat} />
                <PrivateRoute exact path='/exempttests' component={ExemptTests} />
                <PrivateRoute exact path='/state_secret_book' component={StateSecretBook} adminOnly />
                <PrivateRoute exact path='/tv' component={TvScene} />

              </Layout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export const Routes = connect(mapStateToProps, mapDispatchToProps)(RoutesComponent)
