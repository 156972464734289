import {combineReducers} from "redux";
import { accessSeriesFormReducer } from './form/accessSeriesFormReducer';
import { accessSeriesListReducer } from './list/accessSeriesListReducer';
import { accessSeriesCountReducer } from './count/accessSeriesCountReducer';
import { accessSeriesSearchReducer } from './search/accessSeriesSearchReducer';
import { accessSeriesLoaderReducer } from './loader/accessSeriesLoaderReducer';

export const accessSeriesReducer = combineReducers({
  count:  accessSeriesCountReducer,
  list:   accessSeriesListReducer,
  form:   accessSeriesFormReducer,
  search: accessSeriesSearchReducer,
  loader: accessSeriesLoaderReducer,
});