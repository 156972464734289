import React, { useEffect, useState } from "react";
import T from "prop-types";
import styles from "./CoversSearchForm.module.css";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { Col, Row } from "react-bootstrap";
import { libraryInstitutionsRequestStart } from "../../../redux/actions/library/info/institutions";
import { connect } from "react-redux";
import { nonNull } from "../../../utils/strings";
import { verifyStatusList } from "../../../constants/bookVerifyStatus";
import { collectionsListRequestStart } from "../../../redux/actions/collections/list";
import SearchableSelect from "../../Select";
import {
  categoriesToOptions,
  disciplineToOptions,
} from "../../../utils/toSelectOption";
import { categoriesListRequestStart } from "../../../redux/actions/categories/list";
import { litersListRequestStart } from "../../../redux/actions/liters/list";
import CoversSearchFormControls from "../SearchFormControls";
import { seriesListRequestStart } from "../../../redux/actions/series/list";
import { setDisciplineSearchData } from "../../../redux/actions/discipline/searchData";
import { api } from "../../../App";
import { bookSourceRequestStart } from "../../../redux/actions/booksource";

const mapStateToProps = ({
  library,
  collections,
  categories,
  liters,
  series,
  discipline,
  bookSource,
  options,
  historyLocation,
}) => ({
  institutions: library.info.institutions,
  disciplineList: discipline.list,
  disciplineSearch: discipline.search,
  collections: collections.list,
  categoriesList: categories.list,
  liters: liters.list,
  series: series.list,
  bookSource: bookSource,
  options: options.data,
  historyLocation: historyLocation,
});

const mapDispatchToProps = {
  requestInstitutions: libraryInstitutionsRequestStart,
  requestCollections: collectionsListRequestStart,
  requestCategories: categoriesListRequestStart,
  requestLiter: litersListRequestStart,
  requestSeries: seriesListRequestStart,
  setDisciplineSearch: setDisciplineSearchData,
  requestBookSource: bookSourceRequestStart,
};

const CoversSearchForm = ({
  data,
  setData,
  children,
  institutions,
  requestInstitutions,
  hideInstitutions,
  collections,
  requestCollections,
  noVerifyStatus,
  requestCategories,
  categoriesList,
  onSubmit,
  onClear,
  disableAlphabet,
  simplified,
  setDisciplineSearch,
  disciplineSearch,
  disableDefaultPanel = false,
  requestBookSource,
  bookSource,
  historyLocation,
  options,
}) => {
  const [disciplineList, setDisciplineList] = useState(null);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const getFullListDiscipline = () => {
    api.get("/discipline").then((response) => {
      setDisciplineList(response.data);
    })
  };

  const onDisciplineInputChange = (name) => {
    setDisciplineSearch({ ...disciplineSearch, query: name });
    if (!disciplineList) {
      getFullListDiscipline();
    }
  };

  useEffect(() => {
    requestInstitutions();
  }, [requestInstitutions]);

  useEffect(() => {
    requestBookSource();
  }, [requestBookSource]);

  useEffect(() => {
    requestCollections();
  }, [requestCollections]);

  useEffect(() => {
    requestCategories();
  }, [requestCategories]);

  if (noVerifyStatus && !simplified) {
    data.verifyStatus = 2;
  }

  return (
    <div className={styles.container}>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            onChange={onChange}
            name="title"
            onKeyPress={onKeyPress}
            value={data.title}
            label="Заглавие"
          />
        </Col>
        {!simplified && (
          <>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                name="author"
                value={data.author}
                onKeyPress={onKeyPress}
                label="Автор"
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                name="publisher"
                value={data.publisher}
                onKeyPress={onKeyPress}
                label="Издательство"
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                name="isbn"
                value={data.isbn}
                onKeyPress={onKeyPress}
                label="ISBN"
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                name="udk"
                value={data.udk}
                onKeyPress={onKeyPress}
                label="УДК"
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                name="year"
                value={data.year}
                onKeyPress={onKeyPress}
                label="Год"
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper
                onChange={onChange}
                name="bbk"
                value={data.bbk}
                onKeyPress={onKeyPress}
                label="ББК"
              />
            </Col>
            <Col md={6}>
              <ModalInputWrapper label="Категория">
                <SearchableSelect
                  placeholder="Поиск"
                  options={categoriesToOptions(categoriesList)}
                  value={data.categoryid ? undefined : null}
                  onChange={(value) =>
                    setData({ ...data, categoryid: (value || {}).value })
                  }
                />
              </ModalInputWrapper>
            </Col>
            <Col md={6}>
              <ModalInputWrapper label="Дисциплины">
                <SearchableSelect
                  data={data}
                  setData={setData}
                  placeholder="Поиск"
                  onInputChange={onDisciplineInputChange}
                  options={disciplineToOptions(disciplineList)}
                  value={data.cid ? undefined : null}
                  onChange={(value) => setData({ ...data, cid: value.value })}
                />
              </ModalInputWrapper>
            </Col>
            <Col md={12}>
              <ModalInputWrapper
                onChange={onChange}
                name="textsearch"
                value={data.textsearch}
                label="Полнотекстовый поиск"
              />
            </Col>
          </>
        )}
        {!noVerifyStatus && (
          <Col md={6}>
            <ModalInputWrapper label={"Статус"}>
              <select
                value={data.verifyStatus}
                name="verifyStatus"
                onChange={onChange}
              >
                <option value={""}>Не выбрано</option>
                {verifyStatusList.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        )}
        {!hideInstitutions && (
          <Col md={6}>
            <ModalInputWrapper label={"Организация"}>
              <select name="idclient" onChange={onChange} value={data.idclient}>
                <option value="">Не выбрано</option>
                {institutions.map((item, index) => {
                  return (
                    <option
                      value={item.idclient}
                      key={index}
                      selected={options.own_client_id === item.idclient}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </ModalInputWrapper>
          </Col>
        )}
        {!simplified && (
          <Col md={6}>
            <ModalInputWrapper label={"Коллекции"}>
              <select
                value={nonNull(data.collectionid)}
                name="collectionid"
                onChange={onChange}
              >
                <option value={""}>Не выбрано</option>
                {collections.map((item) => (
                  <option value={item.id} key={`collection${item.id}`}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        )}
        {
          <Col md={6}>
            <ModalInputWrapper label={"Источник"}>
              <select
                value={nonNull(data.book_source)}
                className={styles.select}
                name="book_source"
                onChange={onChange}
              >
                <option value={""} disabled>
                  Не выбрано
                </option>
                {bookSource.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </ModalInputWrapper>
          </Col>
        }
        {(historyLocation === "/inv_numbers" ||
          historyLocation === "/books") && (
          <Col md={6}>
            <ModalInputWrapper
              label={"Инвентарный номер"}
              type="text"
              name="number"
              onChange={onChange}
            />
          </Col>
        )}
        <Col md={6}>
          <div className={styles.checkboxContainer}>
            <label>
              <input
                type="checkbox"
                name={"hascover"}
                checked={data.hascover}
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.checked })
                }
              />
              Искать только без обложки
            </label>
          </div>
        </Col>
        <Col md={12}>{children}</Col>
      </Row>
      {!disableDefaultPanel && (
        <CoversSearchFormControls
          disableAlphabet={disableAlphabet}
          onSubmit={onSubmit}
          onClear={onClear}
        />
      )}
    </div>
  );
};

CoversSearchForm.propTypes = {
  data: T.object,
  setData: T.func,
  hideInstitutions: T.bool,
  onSubmit: T.func,
  onClear: T.func,
  disableAlphabet: T.bool,
  disableDefaultPanel: T.bool,
  simplified: T.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoversSearchForm);
