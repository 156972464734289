import { ERROR, START, SUCCESS, USERS_GET_PHOTO_REQUEST } from "../../action-types";

export const usersGetPhotoRequestStart = (mid) => ({
  type: `${USERS_GET_PHOTO_REQUEST}${START}`,
  payload: mid
});

export const usersGetPhotoRequestSuccess = (data) => ({
  type: `${USERS_GET_PHOTO_REQUEST}${SUCCESS}`,
  payload: data
});

export const usersGetPhotoRequestError = (data) => ({
  type: `${USERS_GET_PHOTO_REQUEST}${ERROR}`,
  payload: data
});