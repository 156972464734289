import { ERROR, EXTERNAL_RESOURCES_REQUEST, START, SUCCESS } from "../action-types";

export const externalResourcesRequestStart = () => ({
  type: `${EXTERNAL_RESOURCES_REQUEST}${START}`
});

export const externalResourcesRequestSuccess = (data) => ({
  type: `${EXTERNAL_RESOURCES_REQUEST}${SUCCESS}`,
  payload: data
});

export const externalResourcesRequestError = (data) => ({
  type: `${EXTERNAL_RESOURCES_REQUEST}${ERROR}`,
  payload: data
});