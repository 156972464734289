import React, { useEffect, useState } from 'react'
import ScheduleTable from '../../components/Schedule/ScheduleTable'
import { scheduleActions, requestAction } from '../../redux/factory/scheduleReduxData'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import ScheduleSearchForm from '../../components/Schedule/SearchForm'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'

const mapStateToProps = ({ schedulePersonalReducer }) => ({
  list: schedulePersonalReducer,
  loader: schedulePersonalReducer.loader,
})

const matchDispatchToProps = {
  loadList: scheduleActions.loadRequestStart,
  dataToState: requestAction,
  startRequest: scheduleActions.requestRequestStart,
}

const Schedule = ({ loader, list, loadList, dataToState, startRequest }) => {
  const [selectedCurs, setSelectedCurs] = useState(null)
  useEffect(() => {
    loadList()
  }, [])

  useEffect(() => {
    setSelectedCurs((list && list.userData && list.userData.userDetails[0]?.yeducationyearid) || 1)
  }, [])

  return (
    <SceneContainer title={'Расписание занятий'}>
      {list.userData && (
        <ScheduleSearchForm
          list={list}
          selectedCurs={selectedCurs}
          setSelectedCurs={setSelectedCurs}
          dataToState={dataToState}
          startRequest={startRequest}
        />
      )}

      {loader && <SpinnerOuter isLoading={loader} />}
      {list.response && <ScheduleTable res={list.response} />}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, matchDispatchToProps)(Schedule)
