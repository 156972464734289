import { SERIES_ADD_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const seriesCatalogAddRequestStart = (data) => ({
  type: `${SERIES_ADD_REQUEST}${START}`,
  payload: data
});

export const seriesCatalogAddRequestSuccess = (data) => {
  createNotification('success', 'Серия успешно добавлена');
  return {
    type: `${SERIES_ADD_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const seriesCatalogAddRequestError = (error) => {
  const message = error.response?.data?.result || 'Не удалось добавить серию'
  createNotification('error',  message);
  return {
    type: `${SERIES_ADD_REQUEST}${ERROR}`,
    payload: error
  };
};
