import React, { useState, useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import InputWrapper from '../../Modal/InputWrapper'

import Button from '../../Button'

const Selects = ({ years, terms, discs, projectsRequest, termRequest }) => {
  const refTerms = useRef([])
  const [termId, setTermId] = useState()
  const [discId, setDiscId] = useState(0)

  useEffect(() => {
    if (years.length > 0) termRequest(years.slice(-1)[0].xp_key)
    else years[0] && termRequest(years[0].xp_key)
  }, [years])

  useEffect(() => {
    terms[0] && setTermId(terms[0].trmid)
    !refTerms.current.length && terms.length && projectsRequest(`${terms[1].trmid}/0`)
    refTerms.current = terms
  }, [terms])

  useEffect(() => {
    discs[0] && setDiscId(0)
  }, [discs])

  const onYearChange = e => {
    termRequest(e.target.value)
  }
  const onTermChange = e => {
    setTermId(e.target.value)
  }
  const onDiscChange = e => {
    setDiscId(e.target.value)
  }

  const onRequest = () => {
    projectsRequest(`${termId}/${discId}`)
  }

  return (
    <Row>
      <Col md={2}>
        <InputWrapper label={'Учебный год'}>
          <select onChange={onYearChange}>
            {years.map((item, index) => (
              <option key={item.xp_key} value={item.xp_key} selected={index === years.length - 1}>
                {item.name}
              </option>
            ))}
          </select>
        </InputWrapper>
      </Col>
      <Col md={2}>
        <InputWrapper label={'Семестр'}>
          <select onChange={onTermChange}>
            {terms.map((item, index) => (
              <option key={item.trmid} value={item.trmid} selected={index === terms.length - 1}>
                {item.title}
              </option>
            ))}
          </select>
        </InputWrapper>
      </Col>
      <Col>
        <InputWrapper label={'Дисциплина'}>
          <select onChange={onDiscChange}>
            {/* Шифр */  [{ cid: 0, course: 'все' }, ...discs].map(item => (
              <option key={item.cid} value={item.cid}>
                {item.course}
              </option>
            ))}
          </select>
        </InputWrapper>
      </Col>
      <Col md={'auto'}>
        <InputWrapper label={''}>
          <Button onClick={onRequest} label={'Обновить'} />
        </InputWrapper>
      </Col>
    </Row>
  )
}

export default Selects
