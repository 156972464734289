import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { reportGetRequestStart } from '../../../redux/actions/reports/get'
import StaticInformationReportForm from '../../../components/Reports/StaticInformation/Form'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import DataTable from '../../../components/DataTable'
import DataTableCol from '../../../components/DataTable/Column'

const mapStateToProps = ({ reports }) => ({
  data: reports.report,
  loader: reports.loader,
})

const mapDispatchToProps = {
  requestReport: reportGetRequestStart,
}

const StaticInformationReportScene = ({ ownId, data, loader, requestReport }) => {
  const requestData = formData => {
    requestReport({
      id: ownId,
      formData,
    })
  }

  return (
    <SceneContainer title={'Отчеты / Сведения о статистической информации по структуре фонда'}>
      <StaticInformationReportForm onSubmit={requestData} />

      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        data[ownId] &&
        !!data[ownId].length && (
          <>
            <DataTable data={data[ownId]} onCellClick={() => {}}>
              <DataTableCol colSpan={5} title={'ЭИР по категориям'} value='category' />
              <DataTableCol colSpan={3} title={'Новые поступления'} value='new_books' />
              <DataTableCol colSpan={3} title={'В читальном зале'} value='in_hall' />
              <DataTableCol colSpan={3} title={'Доступны к чтению'} value='ready' />
              <DataTableCol colSpan={3} title={'Заказы на сканирование'} value='scan_orders' />
              <DataTableCol colSpan={3} title={'Объем оцифрованных изданий'} value='scan_complete' />
            </DataTable>
          </>
        )
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticInformationReportScene)
