import { api } from "../../App";

export const getPurpose = () => {
  return api.get(`/purpose`);
};

export const getTechdisributions = () => {
  return api.get(`/techdisributions`);
};

export const getPurposeEducationLevels = () => {
  return api.get(`/purpose_education_levels`);
};

export const getPurposeEducationForms = () => {
  return api.get(`/purpose_education_forms`);
};

export const getPurposeImplementations = () => {
  return api.get(`/purpose_implementations`);
};

export const getPurposeReaders = () => {
  return api.get(`/purpose_readers`);
};

export const getGenres = () => {
  return api.get(`/genres`);
};

export const getTechperiods = () => {
  return api.get(`/techperiods`);
};

export const getTechCommunics = () => {
  return api.get(`/tech_communics`);
};

export const getOutCarriers = () => {
  return api.get(`/out_carriers`);
};

export const getOutStatus = () => {
  return api.get(`/out_status`);
};

export const getNovPublication = () => {
  return api.get(`/nov_publication`);
};

export const getNatureBasicInformation = () => {
  return api.get(`/nature_basic_information`);
};

export const getRepetitionRelease = () => {
  return api.get(`/repetition_release`);
};

export const getInformationTechnologyDesign = () => {
  return api.get(`/information_technology_design`);
};

export const getInstallationMethod = () => {
  return api.get(`/installation_method`);
};

export const getGeneralEducationLevels = () => {
  return api.get(`/general_education_levels`);
};

export const getProfessionalEducationLevels = () => {
  return api.get(`/professional_education_levels`);
};

export const getSubspeciesContinuingEducation = () => {
  return api.get(`/subspecies_continuing_education`);
};

export const getOkso = () => {
  return api.get(`/okso`);
};

export const getInformationChangesAor = () => {
  return api.get(`/information_changes_aor`);
};