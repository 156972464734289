import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { SET_ANSWER_STAT_SEARCH_DATA } from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import {ANSWER_STAT_SORT} from "../../../../constants/sort/answerstatSort";

const initialState = {
    query: "",
    page: 1,
    sortBy: ANSWER_STAT_SORT.INCORRECT_ANSWER,
    sortDirection: SORT_DIRECTION.DESC,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0,
};

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ANSWER_STAT_SEARCH_DATA:
            return { ...action.payload };

        default:
            return state;
    }
};
