import React from 'react'
import T from 'prop-types'
import styles from './DocumentsEditBookForm.module.css'
import InputWrapper from '../../Modal/InputWrapper'
import { Col, Row } from 'react-bootstrap'
import Button from '../../Button'

const InventoryEditBookForm = ({ data, setData, onSave, onDelete }) => {
  const onChange = ({ target: { name, value } }) => setData({ ...data, [name]: value })

  return (
    <Row>
      <Col md={6}>
        <div className={styles.title}>"{data.title}":</div>
        <InputWrapper onChange={onChange} label='Количество' value={data.amount} name='amount' type='number' autocomplete='off' />
      </Col>

      <Col md={12} className={'text-right'}>
        <Button onClick={() => setData(null)} style={'ml-2'} label={'Отменить'} />
        <Button onClick={() => onSave(data)} style={'ml-2'} label={'Сохранить'} />
        <Button onClick={() => onDelete(data)} style={'ml-2'} colorType={'red'} label={'Удалить'} />
      </Col>
    </Row>
  )
}

InventoryEditBookForm.propTypes = {
  data: T.object,
  setData: T.func,
  onSave: T.func,
  onDelete: T.func,
}

export default InventoryEditBookForm
