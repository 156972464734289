import { INVENTORY_BOOK_DELETE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const inventoryBookDeleteRequestStart = (data) => ({
  type: `${INVENTORY_BOOK_DELETE_REQUEST}${START}`,
  payload: data
});

export const inventoryBookDeleteRequestSuccess = (data) => {
  createNotification('success', 'Книга успешно удалена из документа');
  return {
    type: `${INVENTORY_BOOK_DELETE_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const inventoryBookDeleteRequestError = (data) => ({
  type: `${INVENTORY_BOOK_DELETE_REQUEST}${ERROR}`,
  payload: data
});
