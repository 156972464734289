import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import DisciplineTable from '../../../components/Discipline/DisciplineTable'
import { Row, Col } from 'react-bootstrap'
import Pagination from '../../../components/Pagination'
import { disciplineCountRequestStart } from '../../../redux/actions/discipline/count'
import { setDisciplineSearchData } from '../../../redux/actions/discipline/searchData'
import DisciplineSearchForm from '../../../components/Discipline/DisciplineSearch'
import { setDisciplineFormData } from '../../../redux/actions/discipline/form'
import { DisciplineModal } from '../../../components/Discipline/DisciplineModal'
import { disciplineDeleteRequestStart } from '../../../redux/actions/discipline/delete'
import { saveDisciplineRequestStart } from '../../../redux/actions/discipline/save'

const mapStateToProps = ({ discipline }) => ({
  searchData: discipline.search,
  count: discipline.count,
  list: discipline.list,
  formData: discipline.form,
})

const mapDispatchToProps = {
  requestDiscipline: disciplineCountRequestStart,
  setSearchData: setDisciplineSearchData,
  setFormData: setDisciplineFormData,
  deleteDiscipline: disciplineDeleteRequestStart,
  saveDiscipline: saveDisciplineRequestStart,
}

const MODAL_FORM_FIELDS = {
  disciplinename: '',
}

const DisciplineScene = ({
  setSearchData,
  searchData,
  count,
  list,
  requestDiscipline,
  setFormData,
  formData,
  deleteDiscipline,
  saveDiscipline,
}) => {
  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedItem, setSelectedItem] = useState(false)

  useEffect(() => {
    requestDiscipline()
  }, [requestDiscipline])

  const onDeleteDiscipline = () => {
    deleteDiscipline(selectedItem.cid)
    setSelectedItem(false)
  }

  const onSubmitModal = () => {
    saveDiscipline({ ...modalData, data: { ...modalData.data, cid: selectedItem.cid } }, closeModal)
    requestDiscipline()
    setSelectedItem(false)
  }

  const closeModal = () => {
    setModalData({ modalType: null, data: null })
  }

  return (
    <div>
      <SceneContainer title={'Справочники / Дисциплины'}>
        <Row>
          <Col md={12}>
            <DisciplineSearchForm formData={formData} setFormData={setFormData} onSubmit={requestDiscipline} />
            <Pagination
              count={count}
              pageCount={searchData.pageCount}
              current={searchData.page}
              setCurrent={page => {
                setSearchData({ ...searchData, page })
                requestDiscipline()
              }}
            />
            {modalData.data && (
              <DisciplineModal
                data={list}
                selectedItem={selectedItem}
                onSubmit={onSubmitModal}
                setModalData={setModalData}
                modalData={modalData}
                closeModal={closeModal}
              />
            )}
            {count ? (
              <DisciplineTable
                data={list}
                requestDiscipline={requestDiscipline}
                setModal={setModalData}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                initialFormFields={MODAL_FORM_FIELDS}
                onDelete={onDeleteDiscipline}
              />
            ) : (
              <div className='emptyNotify'>По Вашему запросу ничего не найдено</div>
            )}
            <Pagination
              count={count}
              pageCount={searchData.pageCount}
              current={searchData.page}
              setCurrent={page => {
                setSearchData({ ...searchData, page })
                requestDiscipline()
              }}
            />
          </Col>
        </Row>
      </SceneContainer>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DisciplineScene)
