import { DOCUMENT_INSTANCE_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const documentInventaryReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${DOCUMENT_INSTANCE_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default: return state;
    }
};
