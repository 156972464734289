import { ERROR, ELECTRONIC_COURSES_ITEM_FILE_ADD, START, SUCCESS } from '../action-types'
import { createNotification } from '../../../components/notification/notification'

export const electronicCoursesFileAddStart = data => ({
  type: `${ELECTRONIC_COURSES_ITEM_FILE_ADD}${START}`,
  payload: data,
})

export const electronicCoursesFileAddSuccess = data => {
  createNotification('success', 'Файл успешно загружен')
  return {
    type: `${ELECTRONIC_COURSES_ITEM_FILE_ADD}${SUCCESS}`,
    payload: data,
  }
}

export const electronicCoursesFileAddError = data => {
  createNotification('error', 'Ошибка загрузки файла')
  return {
    type: `${ELECTRONIC_COURSES_ITEM_FILE_ADD}${ERROR}`,
    payload: data,
  }
}
