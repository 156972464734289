import {CURRENT_MOBILE_LIBRARY_REQUEST, ERROR, RESET_MOBILE_LIBRARY_DATA, START, SUCCESS} from "../../action-types";

export const currentMobileLibraryRequestStart = (libId) => ({
    type: `${CURRENT_MOBILE_LIBRARY_REQUEST}${START}`,
    libId
});

export const currentMobileLibraryRequestSuccess = (payload) => ({
    type: `${CURRENT_MOBILE_LIBRARY_REQUEST}${SUCCESS}`,
    payload
});

export const currentMobileLibraryRequestError = (payload) => ({
    type: `${CURRENT_MOBILE_LIBRARY_REQUEST}${ERROR}`,
    payload
});

export const resetLibraryData = () => ({
    type: RESET_MOBILE_LIBRARY_DATA
});