import {
  BOOK_RESERVE_COUNT_REQUEST,
  SUCCESS,
  START,
} from "../../../actions/action-types";

const initialState = false;

export const emptyReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_RESERVE_COUNT_REQUEST}${START}`:
      return false;

    case `${BOOK_RESERVE_COUNT_REQUEST}${SUCCESS}`:
      return action.payload === 0;

    default: return state;
  }
};
