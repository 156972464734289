import { call, put, takeLatest } from 'redux-saga/effects'
import { deleteGroup } from '../../../api/groups/groups'
import { ADMIN_GROUPS_DELETE_GROUP, START } from '../../actions/action-types'
import { adminGroupsDeleteGroupError, adminGroupsDeleteGroupSuccess } from '../../actions/adminGroups/deleteGroup'
import { adminGroupsRequestAllGroupsStart } from '../../actions/adminGroups/groups'

export default function* adminGroupsDeleteGroupsWatcher() {
  yield takeLatest(`${ADMIN_GROUPS_DELETE_GROUP}${START}`, deleteGroupWorker)
}

function* deleteGroupWorker({ payload }) {
  try {
    const response = yield call(deleteGroup, payload)
    // yield put(adminGroupsDeleteGroupSuccess(response.data))
    yield put(adminGroupsRequestAllGroupsStart())
    yield
  } catch (error) {
    yield put(adminGroupsDeleteGroupError(error))
  }
}
