import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../../Modal/InputWrapper'
import Button from '../../../Button'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import { api } from '../../../../App'
import styles from './Form.module.css';
import { createNotification } from '../../../notification/notification'

const validationSchema = Yup.object().shape({
  from_date: Yup.date().required("Выберите дату начала"),
  to_date: Yup.date()
    .required("Выберите дату завершения")
    .min(Yup.ref("from_date"), "Дата завершения должна быть позже даты начала"),
});

const StaticInformationReportForm = ({ onSubmit, dataLoc, setData, library, setEdit, edit, localLib, user, newForm, postSearch, setPostSearch }) => {
  
  const [xpClient, setXpClient] = useState([]);

  useEffect(() => {
    api.get('/xp_client', {}).then(res => setXpClient([{ idclient: 0, name: 'все' }, ...res.data]))
  }, [])

  useEffect(() => {
    localLib?.find(lib => lib.idclient == user?.own_client_id)?.id && getData();
  }, [localLib])
  

  const getData = () => {
    setData([]);
    const params = {
      library_id: localLib?.find(lib => lib.idclient == user?.own_client_id)?.id
    };

    if (searchForm.values.user_id ) {params.library_id  = searchForm.values.user_id} 
    if (searchForm.values.user_id == 'all') {delete params.library_id } 
    if (searchForm.values.from_date) {params.from_date = searchForm.values.from_date}
    if (searchForm.values.to_date) {params.to_date = searchForm.values.to_date}
    if (searchForm.values.event_name) {params.event_name = searchForm.values.event_name}
    if (searchForm.values.description) {params.description = searchForm.values.description}
    if (searchForm.values.person) {params.person = searchForm.values.person}

    api.get('/events_journal', { params }).then(res => setData(res.data.rows))
  }
  
  const searchForm = useFormik({
    initialValues: {
      from_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
      mode: 1,
      client: 0,
      event_name: '',
      library_id: localLib?.find(lib => lib.idclient == user?.own_client_id)?.id
    },
    // validationSchema,
    onSubmit: values => onSubmit(values),
  })

  const handleChangeForm = (item, name, e) => {
    const copyData = [...dataLoc];
    const newData = copyData.map(el => {
      if (el.id == item.id) {
        return {...el, [name]: e.target.value}
      } else {
        return {...el}
      }
    })

    setData(newData);
  }

  useEffect(() => {
    if (postSearch.user_id) {
      const params = {};
      params.from_date = postSearch.from_date;
      params.to_date = postSearch.to_date;
      params.event_name = postSearch.event_name;
      api.get('/events_journal', { params }).then(res => setData(res.data.rows));
      
      searchForm.setFieldValue('from_date', postSearch.from_date);
      searchForm.setFieldValue('to_date', postSearch.to_date);
      searchForm.setFieldValue('event_name', postSearch.event_name);
      setPostSearch({});
    }

  }, [postSearch])

  const handleEdit = ( item ) => {
      setEdit(item.id);
  }

  const handleDelete = (id) => {
    api.delete(`/events_journal/${id}`, {}).then(res => {
      createNotification('success', `Успешно`);
      getData();
    })
  }

  const getIcon = (type) => {
    switch (type) {
      case 'save':
        return <i className={styles.buttonIconSave}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M893.3 293.3L730.7 130.7c-7.5-7.5-16.7-13-26.7-16V112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V338.5c0-17-6.7-33.2-18.7-45.2zM384 184h256v104H384V184zm456 656H184V184h136v136c0 17.7 14.3 32 32 32h320c17.7 0 32-14.3 32-32V205.8l136 136V840zM512 442c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144zm0 224c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80z"></path></svg></i>
      case 'edit':
        return <i className={styles.buttonIcon}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32zm-622.3-84c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9z"></path></svg></i>  
      case 'delete':
        return <i className={styles.buttonIcon}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"></path></svg></i>  
      case 'cansel':
        return <i className={styles.buttonIconCansel}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z" opacity=".87"></path><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"></path></svg></i>  
    }
  }

  return (
    <div className={'formContainer'}>

      <Row>
        <Col md={2}>
          <ModalInputWrapper
            label='C*'
            value={moment(searchForm.values.from_date).format('YYYY-MM-DD')}
            name='from_date'
            error={searchForm.errors.from_date}
            onChange={searchForm.handleChange}
            type='date'
          />
        </Col>
        <Col md={2}>
          <ModalInputWrapper
            label='По*'
            value={moment(searchForm.values.to_date).format('YYYY-MM-DD')}
            name='to_date'
            error={searchForm.errors.to_date}
            onChange={searchForm.handleChange}
            type='date'
          />
        </Col>
        <Col>
          <ModalInputWrapper label='Библиотека'>
            {localLib && user?.own_client_id && 
            <select 
            defaultValue={localLib.find(lib => lib.idclient == user?.own_client_id)?.id} name='user_id' 
            value={searchForm.values.user_id || localLib.find(lib => lib.idclient == user?.own_client_id)?.id} 
            onChange={searchForm.handleChange}>
              {localLib.map((e, index) => (
                <option value={e.id} key={`${e.id}_${index}`}>
                  {e.name}
                </option>
              ))}
            </select>
            }
          </ModalInputWrapper>
        </Col>
        </Row>
        
        <Row>
        <Col md={3}>
          <ModalInputWrapper
            label="Наименование мероприятия"
            type="text"
            value={searchForm.values.event_name}
            name="event_name"
            onChange={searchForm.handleChange}>
          </ModalInputWrapper>
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            label="Кто проводил"
            type="text"
            value={searchForm.values.person}
            name="person"
            onChange={searchForm.handleChange}>
          </ModalInputWrapper>
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            label="Примечание"
            type="text"
            value={searchForm.values.description}
            name="description"
            onChange={searchForm.handleChange}>
          </ModalInputWrapper>
        </Col>

        <Col md={'auto'} className={'text-right'} style={{margin: '0 0 0 auto'}}>
          <ModalInputWrapper label={''}>
            <Button label={'Найти'} onClick={getData} />
          </ModalInputWrapper>
        </Col>
      </Row>

      <table className={styles.table}>
          <thead>
            <tr>
              <td className={styles.data}>Дата</td>
              <td>Наименование <br/>мероприятия</td>
              <td className={styles.eventName}>Количество <br/>обслуженных (чел)</td>
              <td>Кто <br/>проводил</td>
              <td>Библиотека</td>
              <td className={styles.descriptionCell}>Примечание</td>
              <td colSpan={2}>Действия</td>
            </tr>
          </thead>
          <tbody>
            {library && dataLoc?.map((el, index) => {
                const libraryName = library.find(({id}) => id == el.library_id)?.name
                return (
                  <tr key={`${el.event_name}_${index}`}>
                    <td className={styles.data}>
                      {/* <input 
                      type="date" 
                      value={ moment(el?.from_date).format('YYYY-MM-DD') }
                      onChange={(e) => handleChangeForm(el, 'from_date', e)}
                      disabled={'disabled'}/>

                      <input 
                      type="date" 
                      value={ moment(el?.to_date).format('YYYY-MM-DD') }
                      onChange={(e) => handleChangeForm(el, 'to_date', e)}
                      disabled={'disabled'}/> */}
                    
                      {moment(el?.from_date).format('DD.MM.YYYY')}
                      <br/>
                      {moment(el?.to_date).format('DD.MM.YYYY')}
                    </td>

                    <td className={styles.eventName}>
                      {/* <input 
                      value={ el.event_name }
                      onChange={(e) => handleChangeForm(el, 'event_name', e)}
                      disabled={'disabled'}/> */}
                      {el.event_name}
                    </td>
            
                    <td className={styles.count}>
                      {/* <input value={ el.people_count }
                      onChange={(e) => handleChangeForm(el, 'people_count', e)}
                      disabled={'disabled'}/> */}
                      {el.people_count}
                    </td>
                    
                      <td>
                      {/* <input value={el.person}
                      title={el.person}
                      onChange={(e) => handleChangeForm(el, 'repson', e)}
                      disabled={'disabled'}/> */}
                        {el.person}
                      </td>
                      
                      <td col='1'>
                        {/* <select 
                        className={styles.selectWrap}
                        defaultValue={el?.library_id}  
                        disabled={'disabled'}
                        onChange={(e) => handleChangeForm(el, 'library_id', e)}>
                          {library.map((e, index) => (
                            <option value={e.id} key={`${el.id}_${index}`}>
                              {e.name}
                            </option>
                          ))}
                        </select> */}
                            {libraryName}
                      </td>
                    <td col='2'>
                      {/* <textarea
                      onChange={(e) => handleChangeForm(el, 'description', e)}
                      disabled={'disabled'}>
                      { el.description }
                      </textarea> */}
                      {el.description}
                    </td>
                  
                    <td>
                        <Button title={edit == el.id ? "Сохранить" : "Редактировать"} onClick={() => handleEdit(el)} label={getIcon('edit')}></Button>
                    </td>
                    <td>
                      <Button onClick={() => handleDelete(el.id)} colorType='red' title="Удалить" label={getIcon('delete')}></Button>
                    </td>

                  </tr>
                )
              }
            )}
          </tbody>
        </table>

    </div>
  )
}

StaticInformationReportForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default StaticInformationReportForm
