import React, { useLayoutEffect, useMemo, useState } from 'react'
import styles from './AggregationReportScene.module.css'
import ReportInfoBlock from '../../../components/Reports/InfoBlock'
import PropTypes from 'prop-types'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import { reportGetRequestStart } from '../../../redux/actions/reports/get'
import { connect } from 'react-redux'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import AggregationPDFPrint from '../../../components/Reports/Aggregation/PDFPrint'
import { AgregationTableRowsLibs, AgregationTableRowsOrg } from './utils'
import AggregationReportForm from '../../../components/Reports/Aggregation/Form'
import { institutionsAllListRequestStart } from '../../../redux/actions/institutions/all'
import moment from 'moment'
import { setReportsSearchData } from '../../../redux/actions/reports/searchData'

const mapStateToProps = ({ reports, institutions, options }) => ({
  data: reports.report,
  loader: reports.loader,
  searchData: reports.search,
  institutionsList: institutions.all,
  orgId: options.data?.own_client_id
})

const mapDispatchToProps = {
  requestReport: reportGetRequestStart,
  requestInstitutions: institutionsAllListRequestStart,
  setSearchData: setReportsSearchData,
}

const AggregationReportScene = ({
  requestReport,
  data,
  ownId,
  loader,
  institutionsList,
  requestInstitutions,
  setSearchData,
  searchData,
  orgId
}) => {
  useLayoutEffect(() => {
    if (!institutionsList.length) {
      requestInstitutions()
    }
    setSearchData({ ...searchData, maxResults: null })
  }, [])

  const [lastSearchData, setLastSearchData] = useState(null)

  const [mode, setMode] = useState(0)

  const aggregationTableRows = useMemo(() => {
    if (mode === 0 || mode === 2) {
      return AgregationTableRowsOrg
    } else if (mode === 1) {
      return AgregationTableRowsLibs
    }
  }, [mode])

  const requestData = ({ date, ...formData }) => {
    
    setLastSearchData({
        from: moment(formData.dateFrom).format('DD.MM.YYYY'),
        to: moment(formData.dateTo).format('DD.MM.YYYY'),
        // Шифр
        client: Number(formData.client) === 0 ? 'Все' : institutionsList.find(el => el.value === Number(formData.client)).label,
    })

    setMode(formData.mode)
    requestReport({ id: ownId, formData })
  }

  const allMode = mode === 2

  return (
    <SceneContainer title='Агрегация информации'>
      <AggregationReportForm orgId={orgId} onSubmit={requestData} institutionsList={institutionsList} />

      {data[ownId] && (
        <AggregationPDFPrint
          title='Агрегация информации'
          data={data[ownId]}
          searchData={lastSearchData}
          tableRows={aggregationTableRows}
        />
      )}

      {loader && <SpinnerOuter isLoading={loader} />}
      {!loader && data[ownId] && (
        <div>
          {data[ownId].map((item, index) => (
            <div className={styles.dataContainer} key={index}>
              {!allMode &&<ReportInfoBlock label={'Организация'} value={item.clientname} strong />}
              {aggregationTableRows.map(el => {
                if (el.value === 'libraryname' && allMode) return null;
                return (
                  <ReportInfoBlock
                    label={el.label}
                    value={item[el.value]}
                    title={!el.value}
                    strong={!el.value}
                  />
                )
              })}
            </div>
          ))}
        </div>
      )}
    </SceneContainer>
  )
}

AggregationReportScene.propTypes = {
  data: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(AggregationReportScene)
