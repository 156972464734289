import { call, put, select, takeLatest } from 'redux-saga/effects'
import { BOOKINVENTORY_COUNT_REQUEST, INVENTARY_BOOK_LOAD_REQUEST, START } from '../../actions/action-types'
import { getInventaryBook, getInventaryBookCount } from '../../../api/inventaryBook/inventaryBook'
import { inventaryBookLoadSuccess, inventaryBookLoadError } from '../../actions/bookInventory/load'
import { bookInventaryCountRequestSuccess, bookInventaryCountRequestError } from '../../actions/bookInventory/count'
import { setBookInventorySearchData } from '../../actions/bookInventory/search'
import { inventaryBookLoadStart } from '../../actions/bookInventory/load'

export default function* inventaryBookWatcher() {
  yield takeLatest(`${BOOKINVENTORY_COUNT_REQUEST}${START}`, inventaryBookCountWorker)
  yield takeLatest(`${INVENTARY_BOOK_LOAD_REQUEST}${START}`, inventaryBookWorker)
}

function* inventaryBookCountWorker() {
  const searchData = yield select(({ inventaryBook }) => inventaryBook.searchData)
  try {
    const response = yield call(getInventaryBookCount, searchData)
    const count = parseInt(response.data[0].count, 10)
    yield put(bookInventaryCountRequestSuccess(count))
    const pageCount = Math.ceil(count / searchData.maxResults)
    yield put(setBookInventorySearchData({ ...searchData, pageCount }))
    yield put(inventaryBookLoadStart())
  } catch (error) {
    yield put(bookInventaryCountRequestError(error))
  }
}

function* inventaryBookWorker() {
  const searchData = yield select(({ inventaryBook }) => inventaryBook.searchData)
  // searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0)
  try {
    const response = yield call(getInventaryBook, searchData)
    yield put(inventaryBookLoadSuccess(response.data))
  } catch (e) {
    yield put(inventaryBookLoadError(e))
  }
}
