import { AUDIT_LOG_COUNT_REQUEST, SUCCESS } from '../../../actions/action-types'

const initialState = 0

export const auditLogCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${AUDIT_LOG_COUNT_REQUEST}${SUCCESS}`:
      return action.payload

    default:
      return state
  }
}
