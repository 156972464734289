import React, { useEffect, useState } from 'react'
import ModalInputWrapper from '../../../Modal/InputWrapper/index'
import CodesSearchModal from '../CodesSearchModal'

export default function FindCodeComponent ({type, value, onChange, directoryList, requestDirectoryList, collapseDirectoryList}) {
  
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!directoryList.length) {
      requestDirectoryList(0, null, false)
    }
  }, [])

  const codeLabel = (() => {
    switch (type) {
      case 'udk': return 'УДК';
      case 'bbk': return 'ББК';
      case 'svni': return 'СВНИ';
      case 'isbn': return 'ISBN';
      default: return 'Неизвестный тип кода';
    }
  })()

  return (
    <>
      <ModalInputWrapper
        name={type}
        value={value}
        onChange={onChange}
        label={codeLabel}
        additionalButtonText='Определить'
        additionalButtonOnClick={() => setModalOpen(true)}
        />
      <CodesSearchModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        label={codeLabel}
        onChange={onChange}
        type={type}
        data={directoryList}
        collapseList={collapseDirectoryList}
        fetchSubList={requestDirectoryList}
        selectMode
      />
    </>
  )
}



