import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ADD, EDIT } from '../../constants/modalTypes'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import { setDocumentSearchData } from '../../redux/actions/documents/documents/search'
import { documentSaveRequestStart } from '../../redux/actions/documents/documents/save'
import { documentTypesAllRequestStart } from '../../redux/actions/documents/types/all'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import DocumentsTable from '../../components/Documents/Table'
import DocumentsModal from '../../components/Documents/Modal'
import Pagination from '../../components/Pagination'
import moment from 'moment'
import { documentsReduxActions } from '../../redux/actions/documents/documents/actions'
import { outsecrecyCountRequestStart } from '../../redux/actions/outsecrecy/count'
import { institutionsAllListRequestStart } from '../../redux/actions/institutions/all'
import { documentBookListRequestStart } from '../../redux/actions/documents/books/list'
import DocumentsSearchForm from '../../components/Documents/SearchForm'
import { setCurrentDocument } from '../../redux/actions/documents/current'
import { WriteOfActPDFPDFPrint } from '../../components/Documents/PDFPrint/PDFPrint'

const mapStateToProps = ({ documents, documentTypes, options, institutions }) => ({
  list: documents.list,
  allTypes: documentTypes.all,
  searchData: documents.search,
  latestDocument: documents.latest,
  count: documents.count,
  latestNumber: documents.number,
  options: options.data,
  institutionsList: institutions.all,
  loader: documents.loader,
})

const mapDispatchToProps = {
  requestBooks: documentBookListRequestStart,
  requestList: documentsReduxActions.countRequestStart,
  requestAllTypes: documentTypesAllRequestStart,
  setSearchData: setDocumentSearchData,
  saveDocument: documentSaveRequestStart,
  deleteDocument: documentsReduxActions.deleteRequestStart,
  requestOutsecrecy: outsecrecyCountRequestStart,
  requestInstitutions: institutionsAllListRequestStart,
  setCurrentDocument: setCurrentDocument,
}

const DocumentsScene = ({
  count,
  list,
  requestList,
  searchData,
  setSearchData,
  saveDocument,
  deleteDocument,
  allTypes,
  requestAllTypes,
  latestDocument,
  latestNumber,
  options,
  requestOutsecrecy,
  institutionsList,
  requestInstitutions,
  setCurrentDocument,
  loader,
}) => {
  const INITIAL_MODAL_DATA = {
    documentname: '',
    documentnumber: latestNumber,
    documenttype: '',
    documentdate: moment().format('YYYY-MM-DD'),
    document_arrival_date: moment().format('YYYY-MM-DD'),
    supplier: null,
    suppliername: '',
    view: '',
    disposal_direction: null,
    disposal_date: null,
    disposal_org: null,
    exclusion_reason: null
  }


  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedIds, setSelectedIds] = useState([])

  useEffect(() => {
    requestList()
  }, [requestList])

  useEffect(() => {
    requestOutsecrecy()
  }, [requestOutsecrecy])

  useEffect(() => {
    requestAllTypes()
  }, [requestAllTypes])

  useEffect(() => {
    requestInstitutions()
  }, [requestInstitutions])

  useEffect(() => {
    setModalData(modalData =>
      modalData.modalType === ADD
        ? {
            modalType: EDIT,
            data: latestDocument,
          }
        : modalData
    )
  }, [latestDocument])

  const onSaveModal = data => {
    saveDocument(data)
  }

  const setSort = sortBy => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC

    setSearchData({ ...searchData, sortDirection: sortDir, sortBy, page: 1 })
    requestList()
  }

  const handleSelect = clickedId => {
    
    const newIdsList = selectedIds.includes(clickedId)
      ? selectedIds.filter(id => id !== clickedId)
      : [...selectedIds, clickedId]
    
    setSelectedIds(newIdsList)
  }

  const onDelete = () => {
    selectedIds.forEach(id => deleteDocument(id))
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )


  return (
    <SceneContainer title={'Документы'}>
      <WriteOfActPDFPDFPrint
        searchData={searchData}
        selectedIds={selectedIds}
        doctypes={allTypes}
        institutionsList={institutionsList}
      />
      {!!allTypes.length && (
        <DocumentsSearchForm
          types={allTypes}
          data={searchData}
          setSearchData={setSearchData}
          startSearch={requestList}
          institutionsList={institutionsList}
        />
      )}
      {modalData.modalType && (
        <DocumentsModal
          modalData={modalData}
          setModalData={setModalData}
          types={allTypes}
          onSave={onSaveModal}
          handleSelect={handleSelect}
          options={options.outsecrecy}
          documenttype={searchData.documenttype}
        />
      )}
      <DocumentsTable
        data={list}
        selectedIds={selectedIds}
        handleSelect={handleSelect}
        onDelete={onDelete}
        setModal={setModalData}
        sortBy={searchData.sortBy}
        setSort={setSort}
        initialModal={INITIAL_MODAL_DATA}
        loader={loader}
        setCurrentDocument={setCurrentDocument}
        paginationComponent={<PaginationComponent />}
      />
      <PaginationComponent />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsScene)
