import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import { rolesListRequestStart } from '../../../redux/actions/roles/list'
import CategoryPermissionsSearchForm from '../../../components/AdminCategoryPermissions/SearchForm'
import CategoryPermissionsDataTable from '../../../components/AdminPermissions/CategoryPermissons/DataTable'
import { setPermissionsByCategoryFormData } from '../../../redux/actions/permissions/byCategories/form'
import { permissionsByCategoryCountRequestStart } from '../../../redux/actions/permissions/byCategories/count'
import { setPermissionsByCategorySearchData } from '../../../redux/actions/permissions/byCategories/search'
import { permissionsByCategoryEditRequestStart } from '../../../redux/actions/permissions/byCategories/edit'
import { permissionsByCategoryAddRequestStart } from '../../../redux/actions/permissions/byCategories/add'
import Pagination from '../../../components/Pagination'
import SpinnerOuter from '../../../components/UtulityComponents/SpinnerOuter'
import { SORT_DIRECTION } from '../../../constants/sortDirections'
import { categoriesListRequestStart } from '../../../redux/actions/categories/list'
import { permissionsByCategoryGroupChangesStart } from '../../../redux/actions/permissions/byCategories/groupChange'
import Button from '../../../components/Button'
import styles from './AdminCategoriesPermissions.module.css'


const mapStateToProps = state => ({
  categories: state.categories.list,
  roles: state.roles.list,
  list: state.accessCategories.list,
  loader: state.accessCategories.loader,
  formData: state.accessCategories.form,
  count: state.accessCategories.count,
  searchData: state.accessCategories.search,
  historyLocation: state.historyLocation,
})

const mapDispatchToProps = {
  requestList: permissionsByCategoryCountRequestStart,
  requestRoles: rolesListRequestStart,
  requestCategories: categoriesListRequestStart,
  setFormData: setPermissionsByCategoryFormData,
  setSearchData: setPermissionsByCategorySearchData,
  setAccessStatus: permissionsByCategoryEditRequestStart,
  addCategoryToRole: permissionsByCategoryAddRequestStart,
  startGroupChange: permissionsByCategoryGroupChangesStart
}

const AdminCategoriesPermissionsScene = ({
  historyLocation,
  categories,
  requestCategories,
  roles,
  list,
  requestRoles,
  formData,
  setFormData,
  startGroupChange,
  requestList,
  count,
  searchData,
  setSearchData,
  loader,
  setAccessStatus,
  addCategoryToRole,
}) => {
  
  const [initButtons, setInitButtons] = useState(false)
  
  useEffect(() => {
    !roles.length && requestRoles()
  }, [roles, requestRoles])

  useEffect(() => {
    !categories.length && requestCategories()
  }, [categories, requestCategories])

  const startSearch = () => {
    requestList()
  }

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const setSort = sortBy => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC

    setSearchData({
      ...searchData,
      sortDirection: sortDir,
      orderBy: sortDir === SORT_DIRECTION.ASC ? sortBy : null,
      orderByD: sortDir === SORT_DIRECTION.DESC ? sortBy : null,
      page: 1,
    })
    requestList()
  }

  const onChangeAccessStatus = (event, category) => {
    const params = {
      role: category.role,
      access: event.target.value || null,
      category: category.category,
      categoryId: category.id,
    }

    category.id ? setAccessStatus(params) : addCategoryToRole(params)
  }
  

  const statusButtonClick = (access) => () => {
    startGroupChange({ access })
    setInitButtons(false)
  }

  return (
    <SceneContainer title={'Администрирование / Настройка прав доступа по категориям'}>
      <CategoryPermissionsSearchForm
        onSubmit={startSearch}
        formData={formData}
        setFormData={setFormData}
        roles={roles}
        categories={categories}
        historyLocation={historyLocation}
      />
      {loader ? (
        <SpinnerOuter isLoading={loader} />
      ) : (
        <>
          {!!list.length && (
            <>
            <div>
            <div style={{display:"flex"}}>
            <>
            <Button label="Установить значение на все категории " onClick= {(()=> setInitButtons((prev)=>!prev))} />
            <div className={styles.setUpAllCategory}>
            {initButtons &&
              <>
              <Button label="Чтение" colorType='red' onClick={statusButtonClick("Чтение")} />
              <Button label="Нет" colorType='red' onClick={statusButtonClick("Нет")} />
              </>
            }
            </div>
            </>
            </div>
      
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />

              <CategoryPermissionsDataTable
                data={list}
                setSort={setSort}
                sortBy={searchData.sortBy}
                onChange={onChangeAccessStatus}
              />

              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
            </div>
            </>

          )}
        </>
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCategoriesPermissionsScene)
