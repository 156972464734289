import React from 'react';
//import T from 'prop-types';
import styles from './QRCodeBlock.module.css';

const QRCodeBlock = () => {
  return (
    <div className={styles.container}>
      <div className={styles.code}/>
      <div className={styles.text}>
        Отсканируйте штрих-код книги или воспользуйтесь <span className={styles.link}>поиском</span>
      </div>
    </div>
  );
};

QRCodeBlock.propTypes = {

};

export default QRCodeBlock;
