import {ANSWER_STAT_LIST_REQUEST, ANSWER_STAT_COUNT_REQUEST, ERROR, START, SUCCESS} from "../../../actions/action-types";

const initialState = false;

export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ANSWER_STAT_COUNT_REQUEST}${START}`:
            return true;

        case `${ANSWER_STAT_LIST_REQUEST}${SUCCESS}`:
            return false;

        case `${ANSWER_STAT_LIST_REQUEST}${ERROR}`:
            return false;

        default: return state;
    }
};