import React, { useRef } from 'react'
import T from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ModalInputWrapper from '../../Modal/InputWrapper'
import { nonNull } from '../../../utils/strings'
import styles from '../../../scenes/OptionsScene/OptionsScene.module.css'
import OptionsLogoWrapper from '../LogoWrapper'
import { Link } from 'react-router-dom'
import PopoverOuter from '../../UtulityComponents/Popover'
import { Button } from 'antd'

const MainOptions = ({ options, setOptions, institutions, outsecrecyOptions, logoUrl, onLoadImage, saveStatus, setScanrate, combineOptionsSave }) => {
  const imageInput = useRef(null)

  const onChange = (name, value) => {
    setOptions({ ...options, [name]: value })
  }

  const onSaveImage = () => {
    imageInput.current.click()
  }

  return (
    <div className={styles.container}>
      <Row>
        <Col md={7}>
          <ModalInputWrapper
            value={options.GOU_Name.join('\n')}
            label={'Полное наименование организации'}
            type='textarea'
            name='GOU_Name'
            onChange={({ target }) => onChange(target.name, target.value.split('\n'))}
          />
        </Col>
        <Col md={7}>
          <Row>
            <Col md={9}>
              <ModalInputWrapper label={'Ссылка в справочнике организаций'}>
                <select
                  value={options.own_client_id}
                  className={styles.select}
                  name='own_client_id'
                  onChange={({ target }) => onChange(target.name, target.value)}
                >
                  {institutions.map((item, index) => (
                    <option value={item.idclient} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </ModalInputWrapper>
            </Col>
            <Col md={3}>
              <ModalInputWrapper label={'ID организации'} value={options.own_client_id} disabled />
            </Col>
          </Row>

          <ModalInputWrapper label={'Сегмент'}>
            <select
              value={nonNull(options.outsecrecy)}
              className={styles.select}
              name='outsecrecy'
              onChange={({ target }) => onChange(target.name, target.value)}
            >
              {outsecrecyOptions().map((item, index) => (
                <option value={item.value} key={index}>
                  {item.label}
                </option>
              ))}
            </select>
          </ModalInputWrapper>
          <ModalInputWrapper
            value={options.address.join('\n')}
            label={'Адрес'}
            name='address'
            onChange={({ target }) => onChange(target.name, target.value.split('\n'))}
          />
        </Col>
        <Col md={7}>
          <ModalInputWrapper
            value={options.phones.join(';')}
            label={'Телефоны'}
            name='phones'
            onChange={({ target }) => onChange(target.name, target.value.split(';'))}
          />
        </Col>
        <Col md={12}>
          <Row>
            <Col md={3}>
              {logoUrl && (
                <PopoverOuter
                  trigger='click'
                  placement='right'
                  title='Загрузить лого'
                  content={
                    <Button onClick={onSaveImage} type='link'>
                      Загрузить лого
                    </Button>
                  }
                >
                  <OptionsLogoWrapper url={logoUrl} />
                </PopoverOuter>
              )}
              <input type='file' className={styles.fileInput} ref={imageInput} onChange={onLoadImage} accept='image/*' />
            </Col>
          </Row>
        </Col>
        {/* <Col md={7}>
          <ModalInputWrapper
            value={options.termofuseedubook}
            label={'Срок использования учебной литературы (лет)'}
            name='termofuseedubook'
            onChange={({ target }) => onChange(target.name, target.value)}
          />
        </Col> */}
        <Col md={7}>
          <ModalInputWrapper
            value={options.bookdaysonhands}
            label={'Срок выдачи литературы (дней)'}
            name='bookdaysonhands'
            onChange={({ target }) => onChange(target.name, target.value)}
          />
        </Col>
        <Col md={7}>
          <ModalInputWrapper
            value={options.plannedscanrate}
            label={'Плановая норма сканирования (дней)'}
            name='plannedscanrate'
            onChange={({ target }) => {
              onChange(target.name, target.value)
              setScanrate(target.value)
            }}
            type={'number'}
          />
        </Col>
        <Col md={7}>
          <ModalInputWrapper
            value={options.gotoscanbutton}
            label={'Перейти к сканированию'}
            name='gotoscanbutton'
            onChange={({ target }) => onChange(target.name, target.value)}
          />
        </Col>
        <Col md={7}>
          <ModalInputWrapper
            value={Math.max(options.timelimitforselectingnewrecordsdays, -options.timelimitforselectingnewrecordsdays)}
            label={'Срок выборки новых записей (дней)'}
            name='timelimitforselectingnewrecordsdays'
            type='number'
            onChange={({ target }) => {
              onChange(target.name, target.value)
            }}
          />
        </Col>
        <Col md={7}>
          <ModalInputWrapper
            label={'Срок бронирования'}
            value={Math.max(options.reservationperiod, -options.reservationperiod)}
            name='reservationperiod'
            type='number'
            onChange={({ target }) => {
              onChange(target.name, target.value)
            }}
          />
        </Col>
        <Col md={7}>
          <label>
            Запрет выдачи литературы при наличии задолженности
            <input
              style={{ marginLeft: '10px' }}
              type='checkbox'
              name='deliveryforbiddenifdebt'
              checked={options.deliveryforbiddenifdebt}
              onChange={({ target }) => onChange(target.name, target.checked)}
            />
          </label>
        </Col>
        <Col md={12}>
          <Link className={styles.link} to={'/external/edit'}>
            Редактирование внешних информационных ресурсов
          </Link>
        </Col>
        <Col md={12}>
          <Link className={styles.link} to={'/media_resource/edit'}>
            Настройка медиа портала
          </Link>
        </Col>
        <Col md={{ span: 2, offset: 8 }}>{saveStatus && <div className={styles.status}>Сохранено!</div>}</Col>
      </Row>

      <div className={'d-flex justify-content-end'}>
        <Button onClick={() => combineOptionsSave()}>Сохранить</Button>
      </div>
    </div>
  )
}

MainOptions.propTypes = {
  options: T.object,
  setOptions: T.func,
  institutions: T.array,
  onLoadImage: T.func,
  onSaveImage: T.func,
}

export default MainOptions
