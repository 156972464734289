import { api } from '../../App';

export const getRacksList = (roomId) => {
  return api.get(`/location/bookshelf/${roomId}`);
};

export const addRack = (roomId, data) => {
  return api.post(`/location/bookshelf/${roomId}`, data);
};

export const editRack = (roomId, data) => {
  return api.put(`/location/bookshelf/${roomId}/${data.id}`, data);
};

export const deleteRack = (roomId, rackId) => {
  return api.delete(`/location/bookshelf/${roomId}/${rackId}`);
};