import jsPDF from "jspdf"
import addFont from "../../../../fonts/fonts"
import moment from "moment"


const orientation = 'portrait'




export const getAdaptiveData = async ({data, books, booksCount, actData, clientInfo, ranks, libName, supplierName}) => {
  addFont(jsPDF.API)

  const {ceoname, ceoposition, ceorank, shortname, kpp, inn} = clientInfo
  const rank = ranks?.find(({id_mil_rank}) => id_mil_rank === ceorank)?.title
  
  const priceSum = books?.reduce((acc, book) => acc + (parseInt(book.sum) || 0) , 0)

  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
  doc.setFont('Montserrat')
  // doc.setFont( undefined, 'bold')
  doc.setFontSize('13')

  doc.text('УТВЕРЖДАЮ', 170, 10, 'center')
  doc.text(ceoposition || '', 170, 16, 'center')
  doc.text(rank || '', 170, 22, 'center')
  doc.text(ceoname || '', 170, 28, 'center')
  doc.text('«_____»___________________20___г.', 115, 36)
  doc.text('А К Т №________', 82, 43)
  doc.text('О списании исключенных объектов библиотечного фонда', 30, 50)
  doc.text('от «_____» _________________20_____г', 65, 57)

  doc.setFontSize('11')
  doc.text('Форма по ОКУД', 130, 67)
  doc.text('Дата', 154, 75)
  doc.text('по ОКПО', 145, 83)
  doc.text('КПП', 154, 91)
  doc.line(170, 55, 200, 55)
  doc.line(170, 62, 200, 62)
  doc.line(170, 69, 200, 69)
  doc.line(170, 76, 200, 76)
  doc.line(170, 83, 200, 83)
  doc.line(170, 90, 200, 90)
  doc.line(170, 97, 200, 97)
  doc.line(170, 104, 200, 104)
  doc.line(170, 55, 170, 104)
  doc.line(200, 55, 200, 104)
  doc.setFontSize('13')
  doc.text('КОДЫ', 172, 60)
  doc.text('0504144', 173, 67)
  doc.text('07726295', 173, 81)
  doc.text(kpp || '', 173, 88)

  doc.setFontSize('10')
  doc.text(`Учреждение       ${shortname || ''}`, 20, 82)
  doc.line(50, 83, 140, 83)

  doc.text(`ИНН                  ${inn || ''}`, 60, 90)
  doc.line(80, 85, 120, 85)
  doc.line(80, 92, 120, 92)
  doc.line(80, 85, 80, 92)
  doc.line(120, 85, 120, 92)

  doc.text(`Структура подразделение                              ${libName || actData?.libraryname || ''}`, 20, 99)
  doc.line(75, 100, 150, 100)

  doc.text(`Причина исключения                                    `, 20, 106)
  doc.line(73, 107, 160, 107)

  doc.text(`Комиссия в составе:  председатель комиссии - `, 20, 113)
  doc.line(57, 114, 200, 114)
  doc.line(20, 119, 200, 119)
  doc.text(`членов комиссии - `, 25, 123)
  doc.line(58, 124, 200, 124)
  doc.line(20, 129, 200, 129)
  doc.line(20, 134, 200, 134)
  doc.line(20, 139, 200, 139)
  doc.text(`назначенная приказом начальника академии от ${moment(data.nomenclatureSignDate).format('DD.MM.YYYY')}г. № ${data.nomenclatureNumber} произвела проверку`, 20, 143)
  doc.line(111, 144, 144, 144)
  doc.line(152, 144, 157, 144)
  doc.text(`                                            списанной из фонда ПВУРЭ литературы`, 20, 148)
  doc.line(20, 149, 200, 149)
  doc.text(`и установила, что перечисленные в прилагаемом списке документы в количестве                     экз.`, 20, 154)
  doc.text(`${booksCount}`, 180, 154)
  doc.line(175, 150, 191, 150)
  doc.line(175, 150, 175, 155)
  doc.line(175, 155, 191, 155)
  doc.line(191, 150, 191, 155)
  doc.text(`на сумму (прописью)`, 20, 165)
  doc.line(60, 166, 200, 166)
  doc.line(165, 167, 187, 167)
  doc.text(
    `и    цифрами                                                                                                                                                              руб.`,
    20,
    171
  )
  doc.line(20, 172, 165, 172)
  doc.line(165, 167, 187, 167)
  doc.line(165, 167, 165, 174)
  doc.line(165, 174, 187, 174)
  doc.line(187, 167, 187, 174)
  doc.text(`${priceSum || ''}`, 170, 172)
  doc.text(`подлежит списанию с учебного и основного фонда и исключению из учета.`, 20, 176)
  doc.text(`Приложение: список на исключение объектов библиотечного фонда на ___ листе(ах).`, 23, 181)
  doc.text(`В инвентарных книгах выбытие объектов библиотечного фонда отмечено.`, 23, 186)

  doc.autoTable({
    html: '#writeOfActPDFPDFTable',
    styles: { font: 'Montserrat', fontSize: 10 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.25,
      lineColor: 200,
      fontSize: 8
    },
    bodyStyles: {
      halign: 'center',
      lineWidth: 0.25,
      lineColor: 200,
    },
    columnStyles: {
      0: { cellWidth: 10 },
      5: { cellWidth: 30 },
      6: { cellWidth: 15 },
      8: { cellWidth: 15 },
    },

    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 197,
  })
  doc.setDrawColor(0, 0, 0)
  doc.setFont( undefined, 'bold')
  doc.setFontSize('9')
  doc.text('Председатель комиссии:', 22, doc.autoTableEndPosY() + 5)
  doc.setFontSize('10')
  doc.setFont( undefined, 'normal')
  doc.line(20, doc.autoTableEndPosY() + 11, 98, doc.autoTableEndPosY() + 11)

  doc.setFontSize('6')
  doc.text('(должность)', 54, doc.autoTableEndPosY() + 13)

  doc.line(120, doc.autoTableEndPosY() + 11, 140, doc.autoTableEndPosY() + 11)
  doc.text('(подпись)', 125, doc.autoTableEndPosY() + 13)

  doc.setFontSize('10')
  doc.line(150, doc.autoTableEndPosY() + 11, 190, doc.autoTableEndPosY() + 11)
  doc.setFontSize('6')
  doc.text('(расшифровка, подпись)', 155, doc.autoTableEndPosY() + 13)

  doc.setFont( undefined, 'bold')
  doc.setFontSize('8')
  doc.text('Члены комиссии', 20, doc.autoTableEndPosY() + 24)



  doc.setFont( undefined, 'normal')
  doc.line(20, doc.autoTableEndPosY() + 35, 100, doc.autoTableEndPosY() + 35)
  doc.setFontSize('6')
  doc.text('(должность)', 50, doc.autoTableEndPosY() + 37)

  doc.line(120, doc.autoTableEndPosY() + 35, 140, doc.autoTableEndPosY() + 35)
  doc.text('(подпись)', 125, doc.autoTableEndPosY() + 37)

  doc.setFontSize('10')
  doc.line(150, doc.autoTableEndPosY() + 35, 190, doc.autoTableEndPosY() + 35)
  doc.setFontSize('6')
  doc.text('(расшифровка, подпись)', 155, doc.autoTableEndPosY() + 37)




  doc.setFontSize('8')
  doc.line(20, doc.autoTableEndPosY() + 46, 100, doc.autoTableEndPosY() + 46)
  doc.setFontSize('6')
  doc.text('(должность)', 50, doc.autoTableEndPosY() + 48)

  doc.line(120, doc.autoTableEndPosY() + 46, 140, doc.autoTableEndPosY() + 46)
  doc.text('(подпись)', 125, doc.autoTableEndPosY() + 48)

  doc.setFontSize('10')
  doc.line(150, doc.autoTableEndPosY() + 46, 190, doc.autoTableEndPosY() + 46)
  doc.setFontSize('6')
  doc.text('(расшифровка, подпись)', 155, doc.autoTableEndPosY() + 48)





  doc.setFontSize('8')
  doc.line(20, doc.autoTableEndPosY() + 57, 100, doc.autoTableEndPosY() + 57)
  doc.setFontSize('6')
  doc.text('(должность)', 50, doc.autoTableEndPosY() + 59)

  doc.line(120, doc.autoTableEndPosY() + 57, 140, doc.autoTableEndPosY() + 57)
  doc.text('(подпись)', 125, doc.autoTableEndPosY() + 59)

  doc.setFontSize('10')
  doc.line(150, doc.autoTableEndPosY() + 57, 190, doc.autoTableEndPosY() + 57)
  doc.setFontSize('6')
  doc.text('(расшифровка, подпись)', 155, doc.autoTableEndPosY() + 59)




  doc.setFontSize('8')
  doc.text('', 20, doc.autoTableEndPosY() + 67)
  doc.line(20, doc.autoTableEndPosY() + 68, 100, doc.autoTableEndPosY() + 68)
  doc.setFontSize('6')
  doc.text('(должность)', 50, doc.autoTableEndPosY() + 70)

  doc.line(120, doc.autoTableEndPosY() + 68, 140, doc.autoTableEndPosY() + 68)
  doc.text('(подпись)', 125, doc.autoTableEndPosY() + 70)

  doc.setFontSize('10')
  doc.text('', 155, doc.autoTableEndPosY() + 67)
  doc.line(150, doc.autoTableEndPosY() + 68, 190, doc.autoTableEndPosY() + 68)
  doc.setFontSize('6')
  doc.text('(расшифровка, подпись)', 155, doc.autoTableEndPosY() + 70)

  doc.setPage(1).setFontSize(10).text(String(doc.getNumberOfPages()), 159, 180)

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}