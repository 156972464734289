import React from 'react';
import PropTypes from 'prop-types';
import {Card} from "react-bootstrap";
import ListItem from "../Item";
import {formatDate} from "../../index";

import styles from './s.module.css';

const ListCard = ({dayItems}) => {


    if(!dayItems && !dayItems.length) {
        return null;
    }

    return (
        <Card className={'mb-2'}>
            <Card.Header>{formatDate(dayItems[0].eventtime)}</Card.Header>
            <Card.Body>
                <ul className={styles.list}>
                    {dayItems.map(item =>
                        <ListItem
                            item={item}
                            key={item.id}
                        />
                    )}
                </ul>

            </Card.Body>
        </Card>
    );
};

ListCard.propTypes = {
    dayItems: PropTypes.array.isRequired,
};

export default ListCard;
