import { BASE_URL } from "../constants/api";
import { api } from '../App';

export const resolveUserPhoto = (mid) => {
  return `${BASE_URL}/people/photo/${mid}`;
};


export const getImageData = (mid) => {
  return  api.get(`/people/photo/${mid}`, {responseType: 'arraybuffer'});
};