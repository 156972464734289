import {
  BORROWING_TEST_LIST_REQUEST,
  BORROWING_TEST_COUNT_REQUEST,
  SUCCESS,
  START,
  ERROR
} from "../../../actions/action-types";

const initialState = false;

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BORROWING_TEST_COUNT_REQUEST}${START}`:
      return true;

    case `${BORROWING_TEST_LIST_REQUEST}${SUCCESS}`:
      return false;

    case `${BORROWING_TEST_LIST_REQUEST}${ERROR}`:
      return false;

    case `${BORROWING_TEST_COUNT_REQUEST}${ERROR}`:
      return false;

    default: return state;
  }
};
