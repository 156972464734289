import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SceneContainer from '../../../components/UtulityComponents/SceneContainer'
import { Table } from './Table'

export default () => {
  return (
    <SceneContainer title={'Администрирование / Настройка прав доступа / Матрица найстройки прав доступа по ролям'}>
      <Link to='/admin/permissions'>
        <Button className='mb-2' size='sm'>
          Назад
        </Button>
      </Link>
      <Table />
      <Link to='/admin/permissions'>
        <Button className='mt-2' size='sm'>
          Назад
        </Button>
      </Link>
    </SceneContainer>
  )
}
