import { BOOK_TRANSFER_LIST_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const bookTransferListRequestStart = () => ({
  type: `${BOOK_TRANSFER_LIST_REQUEST}${START}`,
});

export const bookTransferListRequestSuccess = (response) => ({
  type: `${BOOK_TRANSFER_LIST_REQUEST}${SUCCESS}`,
  payload: response
});

export const bookTransferListRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка загрузки. Статус: ${error.response.status}`
  );

  return {
    type: `${BOOK_TRANSFER_LIST_REQUEST}${ERROR}`,
    payload: error
  };
};
