import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ADD, EDIT } from '../../constants/modalTypes'
import { SORT_DIRECTION } from '../../constants/sortDirections'
import { inventoryCountRequestStart } from '../../redux/actions/inventory/documents/count'
import { setInventorySearchData } from '../../redux/actions/inventory/documents/search'
import { inventorySaveRequestStart } from '../../redux/actions/inventory/documents/save'
import { inventoryDeleteRequestStart } from '../../redux/actions/inventory/documents/delete'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import Table from '../../components/Inventory/Table'
import SearchForm from '../../components/Inventory/SearchForm'
import InventoryModal from '../../components/Inventory/Modal'
import { InventoryDiscrepancyPDFTable } from '../../components/Inventory/DiscrepancyTable'
import Pagination from '../../components/Pagination'
import { availableBooksActions } from '../../redux/factory/availableBooksModalReduxData'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'
import { Button, Space } from 'antd'
import RolePermissionChecker from '../../components/UtulityComponents/RolePermissionChecker'
import classNames from 'classnames'

const mapStateToProps = ({ documentTypes, inventory }) => ({
  list: inventory.list,
  loader: inventory.loader,
  allTypes: documentTypes.all,
  searchData: inventory.search,
  latestDocument: inventory.latest,
  count: inventory.count,
  books: inventory.books,
})

const mapDispatchToProps = {
  requestList: inventoryCountRequestStart,
  saveInventory: inventorySaveRequestStart,
  setSearchData: setInventorySearchData,
  moveBook: availableBooksActions.moveBook,
  deleteInventory: inventoryDeleteRequestStart,
}

const InventoryScene = ({
  books,
  count,
  list,
  loader,
  requestList,
  searchData,
  setSearchData,
  saveInventory,
  deleteInventory,
  allTypes,
  latestDocument,
}) => {
  const [modalData, setModalData] = useState({ modalType: null, data: null })
  const [selectedId, setSelectedId] = useState(null)

  useEffect(() => {
    requestList()
  }, [requestList])

  useEffect(() => {
    setModalData(modalData =>
      modalData.modalType === ADD
        ? {
            modalType: EDIT,
            data: latestDocument,
          }
        : modalData
    )
  }, [latestDocument])

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page })
    requestList()
  }

  const setSort = sortBy => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC

    setSearchData({ ...searchData, sortDirection: sortDir, sortBy, page: 1 })
    requestList()
  }

  const handleSelect = id => {
    setSelectedId(selectedId === id ? null : id)
  }

  const onDelete = () => {
    selectedId && deleteInventory(selectedId)
  }

  const openCreationModal = () => setModalData({ modalType: ADD, data: null })

  const PaginationComponent = () => (
    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage} />
  )

  return (
    <SceneContainer title={'Инвентаризация'}>
      <SearchForm data={searchData} setSearchData={setSearchData} startSearch={requestList} />
      <Space>
        <RolePermissionChecker permissionName={'Режимы,Инвентаризация'}>
          <Button type='primary' className={'mb-1'} onClick={openCreationModal}>
            добавить
          </Button>
        </RolePermissionChecker>
        <RolePermissionChecker permissionName={'Режимы,Инвентаризация'}>
          <Button
            type='primary'
            danger
            className={classNames('mb-1', {
              disabled: !selectedId,
            })}
            title={!selectedId ? 'Необходимо выбрать документ' : null}
            onClick={onDelete}
          >
            удалить
          </Button>
        </RolePermissionChecker>
      </Space>
      {!!books.length && <InventoryDiscrepancyPDFTable books={books} />}
      <PaginationComponent />
      {loader ? (
        <SpinnerOuter />
      ) : (
        <Table
          data={list}
          selectedId={selectedId}
          handleSelect={handleSelect}
          setModal={setModalData}
          sortBy={searchData.sortBy}
          setSort={setSort}
        />
      )}
      <PaginationComponent />

      {modalData.modalType && (
        <InventoryModal list={list} types={allTypes} onSave={saveInventory} setModalData={setModalData} modalData={modalData} />
      )}
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryScene)
