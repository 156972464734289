import { call, put, takeLatest } from 'redux-saga/effects'
import { CHECK_AUTH_REQUEST, START } from '../../actions/action-types'
import { checkAuthRequestError, checkAuthRequestSuccess } from '../../actions/user/checkAuth'
import { checkAuth } from '../../../api/user/auth'
import { searchAuditSetUserMid } from '../../actions/searchAudit/searchData'

export default function* checkAuthWatcher() {
  yield takeLatest(`${CHECK_AUTH_REQUEST}${START}`, checkAuthWorker)
}

function* checkAuthWorker() {
  try {
    const response = yield call(checkAuth)
    yield put(checkAuthRequestSuccess(response.data))
    yield put(searchAuditSetUserMid(response.data.mid))
  } catch (error) {
    yield put(checkAuthRequestError(error))
  }
}
