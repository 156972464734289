import { DOCUMENT_BOOK_DELETE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const documentBookDeleteRequestStart = (data) => ({
  type: `${DOCUMENT_BOOK_DELETE_REQUEST}${START}`,
  payload: data
});

export const documentBookDeleteRequestSuccess = (data) => {
  createNotification('success', 'Книга успешно удалена из документа');
  return {
    type: `${DOCUMENT_BOOK_DELETE_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const documentBookDeleteRequestError = (data) => ({
  type: `${DOCUMENT_BOOK_DELETE_REQUEST}${ERROR}`,
  payload: data
});
