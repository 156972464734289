import { LIBRARY_DOCUMENT_CREATE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const createLibDocumentRequestStart = (data) => ({
  type: `${LIBRARY_DOCUMENT_CREATE_REQUEST}${START}`,
  payload: data
});

export const createLibDocumentRequestSuccess = (response) => ({
  type: `${LIBRARY_DOCUMENT_CREATE_REQUEST}${SUCCESS}`,
  payload: response
});

export const createLibDocumentRequestError = (error) => ({
  type: `${LIBRARY_DOCUMENT_CREATE_REQUEST}${ERROR}`,
  payload: error
});
