import React from 'react';
import T from 'prop-types';
import classNames from 'classnames';
import styles from './BBKListItem.module.css';

const BBKListItem = ({ data, level, fetchSubList, collapseList, onSelect, selectMode }) => {
  const onClick = (e) => {
    if(e.target.tagName !== 'BUTTON') {
      data.subList ? collapseList(data.id) : fetchSubList(data.id);
    }
  };

  const coloredStyle = classNames({
    [styles.firstLevel]: level === 1,
    [styles.secondLevel]: level === 2
  });

  const buttonTdStyle = {textAlign: 'center'}

  const buttonLabel = selectMode ? 'Выбрать' : 'Добавить'


  return (
    <tr onClick={(e) => onClick(e)} className={styles.listItem}>
      {Array(level - 1).fill(1).map((num, index) => (
        <td colSpan={1} key={index}> </td>
      ))}
      <td colSpan={6} className={styles.bbkNumber}>{data.bbk}</td>
      <td colSpan={11 - level} className={coloredStyle}>{data.description}</td>
      <td colSpan={10} className={coloredStyle}>{data.comments}</td>
      {onSelect &&
        <td style={buttonTdStyle} colSpan={3} className={coloredStyle}>
          <button onClick={() => onSelect(data.bbk)}>{buttonLabel}</button>
        </td>
      }
    </tr>
  );
};

BBKListItem.propTypes = {
  data: T.object,
  level: T.number,
  fetchSubList: T.func,
  collapseList: T.func
};

export default BBKListItem;
