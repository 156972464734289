import { FUND_ACCOUNTING_REQUEST, SUCCESS, ERROR, START } from "../../../actions/action-types";

const initialState = false;

export const fundAccountingLoaderReducer = (state = initialState, action) => {

  switch (action.type) {
    case `${FUND_ACCOUNTING_REQUEST}${START}`:
      return true;
    case `${FUND_ACCOUNTING_REQUEST}${SUCCESS}`:
      return false;
    case `${FUND_ACCOUNTING_REQUEST}${ERROR}`:
      return false;
    default: return state;
  }

};
