import React from 'react';
import classNames from "classnames";
import styles from "../ReadingAdvancedSearch.module.css";
import Input from "../../../Input";

const AdvancedSearchItem = ({index, searchData, searchParams, setSearchData}) => {
    const params = searchParams["parameters"];
    const conditions = searchParams["conditions"];
    const logical = searchParams["logical"];
    const isLast = (index + 1 === searchData.length);

    const onChange = (e) => {
        let updatedSearchData = [...searchData];
        searchData[index][e.target.name] = e.target.value;
        setSearchData(updatedSearchData);
    };

    return (
        <div className={'d-flex'}>
            <div className={classNames(styles.fieldWrapper)}>
                <select
                    value={searchData[index]["fieldtitle"]}
                    className={styles.select}
                    name='fieldtitle'
                    onChange={onChange}
                >
                    <option value="">Не выбрано</option>
                    {params.map((item, index) =>
                        <option value={item.fieldname} key={index}>
                            {item.fieldtitle}
                        </option>
                    )}
                </select>
            </div>
            <div className={classNames(styles.fieldWrapper)}>
                <select
                    value={searchData[index]["conditions"]}
                    className={styles.select}
                    name='conditions'
                    onChange={onChange}
                >
                    <option value="">Не выбрано</option>
                    {conditions.map((item, index) =>
                        <option value={item} key={index}>
                            {item}
                        </option>
                    )}
                </select>
            </div>
            <div className={classNames(styles.fieldWrapper)}>
                <Input
                    name={'value'}
                    type={'text'}
                    value={searchData[index]["value"]}
                    onChange={onChange}
                />
            </div>
            <div className={classNames(styles.fieldWrapper)}>
                {!isLast &&
                <select
                    value={searchData[index]["logical"]}
                    className={styles.select}
                    name='logical'
                    onChange={onChange}
                >
                    <option value="">Не выбрано</option>
                    {logical.map((item, index) =>
                        <option value={item} key={index}>
                            {item}
                        </option>
                    )}
                </select>
                }
            </div>
        </div>
    );
};

AdvancedSearchItem.propTypes = {};

export default AdvancedSearchItem;