import React from 'react'
import T from 'prop-types'
import styles from './SendBookList.module.css'
import SendBookListItem from './Item'

const SendBookList = ({ data, userId, inventoryNumber }) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <SendBookListItem data={item} userId={userId} key={index} inventoryNumber={inventoryNumber} />
      ))}
    </div>
  )
}

SendBookList.propTypes = {
  data: T.array,
}

export default SendBookList
