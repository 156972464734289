import {SET_LIBRARY_MATERIALS_FORM_DATA, SET_LOCATION_CHANGE} from "../../../actions/action-types";

export const libMaterialsInitialState = {
    title: "",
    author: "",
    year: "",
    categoryid: {value: '', label: ''},
    latest: false
};

export const libraryMaterialsFormReducer = (state = libMaterialsInitialState, action) => {
    switch (action.type) {
        case SET_LIBRARY_MATERIALS_FORM_DATA:
            return action.payload;
        case SET_LOCATION_CHANGE:
            return libMaterialsInitialState;
        default:
            return state;
    }
};
