import {call, put, takeLatest, select, fork} from "redux-saga/effects";
import {RESERVE_BOOK_REQUEST, START, UNRESERVE_BOOK_REQUEST} from "../../actions/action-types";
import {reserveBook, unReserveBook} from "../../../api/book/reserveBook";
import {reserveBookRequestError, reserveBookRequestSuccess} from "../../actions/books/reserveBook";
import {unreserveBookRequestError, unreserveBookRequestSuccess} from "../../actions/books/unreserveBook";
import {coversCountRequestStart} from "../../actions/covers/count";

import {reservedBooksListRequestStart} from "../../actions/books/reservedList";
import {createNotification} from "../../../components/notification/notification";

export default function* reserveBookWatcher() {
    yield takeLatest(`${RESERVE_BOOK_REQUEST}${START}`, reserveBookWorker);
    yield takeLatest(`${UNRESERVE_BOOK_REQUEST}${START}`, unreserveBookWorker);
}

function* reserveBookWorker({payload: instanceId}) {
    const mid = yield select(({readerData}) => readerData?.mid);
    try {
        const response = yield call(reserveBook, instanceId, mid);
        yield put(reserveBookRequestSuccess(response.data));
        yield fork(createNotification, "success", "Книга забронирована");
        yield put(coversCountRequestStart());
    } catch (error) {
        yield put(reserveBookRequestError(error));
    }
}

function* unreserveBookWorker({payload: {instanceId}}) {
    const mid = yield select(({readerData}) => readerData?.mid);
    try {
        const response = yield call(unReserveBook, instanceId);
        yield put(unreserveBookRequestSuccess(response.data));
        yield fork(createNotification, "success", "Успешно удалено");
        yield put(coversCountRequestStart());
        yield put(reservedBooksListRequestStart(mid));
    } catch (error) {
        yield put(unreserveBookRequestError(error));
    }
}
