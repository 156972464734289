import { ERROR, START, SUCCESS, USERS_DELETE_PHOTO_REQUEST } from "../../action-types";

export const usersDeletePhotoRequestStart = (mid) => ({
  type: `${USERS_DELETE_PHOTO_REQUEST}${START}`,
  payload: mid
});

export const usersDeletePhotoRequestSuccess = (data) => ({
  type: `${USERS_DELETE_PHOTO_REQUEST}${SUCCESS}`,
  payload: data
});

export const usersDeletePhotoRequestError = (data) => ({
  type: `${USERS_DELETE_PHOTO_REQUEST}${ERROR}`,
  payload: data
});