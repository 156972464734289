import React, { useRef, useState } from 'react'
import T from 'prop-types'
import styles from './SearchableSelect.module.css'
import Select from 'react-select'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { selectCustomStyles } from './customStyles'
import { saveAuthorRequestStart } from '../../redux/actions/authors/save'
import { saveDisciplineRequestStart } from '../../redux/actions/discipline/save'
import { disciplineCountRequestStart } from '../../redux/actions/discipline/count'
import { savePublishersDataRequestStart } from '../../redux/actions/publishers/save'
import { disciplineAdd } from '../../api/discipline/discipline'
import { createNotification } from '../notification/notification'
import { SELECT_TYPE } from '../../constants/selectType'

const mapStateToProps = ({ discipline }) => ({
  disciplineData: discipline.list,
})

const matchDispatchToProps = dispatch => ({
  requestDiscipline: requestDisciplineData => dispatch(disciplineCountRequestStart(requestDisciplineData)),
  saveAuthor: authorData => dispatch(saveAuthorRequestStart(authorData)),
  saveDiscipline: discipline => dispatch(saveDisciplineRequestStart(discipline)),
  savePublisher: publisher => dispatch(savePublishersDataRequestStart(publisher)),
})

const SearchableSelect = ({
  label,
  onInputChange = () => {},
  isMulti,
  options,
  onChange,
  value,
  placeholder,
  className,
  noOptionsMessage,
  isClearable,
  isSearchable,
  isLoading,
  onKeyDown,
  selectType,
  author,
  saveAuthor,
  savePublisher,
  saveDiscipline,
  requestDiscipline,
  disciplineData,
  setData,
  data,
  defaultValue,
  disabled,
  onEnterCallback,
  ...props
}) => {
  let timeout = useRef()

  const debouncedOnChange = value => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      onInputChange(value)
    }, 500)
  }

  const onEnter = e => {
    if (e.key == 'Enter') {
      switch (selectType) {
        case SELECT_TYPE.AUTHORS:
          const fio = e.target.value.split(' ')
          const authorData = {
            modalType: 'ADD',
            data: {
              name: e.target.value,
              lastname: fio[0],
              firstname: fio[1],
            },
          }
          saveAuthor(authorData)
          break
        case SELECT_TYPE.PUBLISHERS:
          const payload = {
            modalType: 'ADD',
            data: {
              name: e.target.value
            }
          }
          savePublisher(payload)
          break
        case SELECT_TYPE.SCIENCE_WORKS:
          onEnterCallback(e.target.value, props.name)
          break
        case SELECT_TYPE.LITERS:
          onEnterCallback(e.target.value, props.name)
          break
        case SELECT_TYPE.SERIES:
          onEnterCallback(e.target.value, props.name)
          break
        default:
          break
      }
    }
  }

  const selectRef = useRef(null)

  const emptyInputSearch = () => onInputChange('')

  return (
    <div className={classNames(styles.container, className)}>
      <Select
        isSearchable
        isMulti={isMulti}
        onInputChange={onInputChange ? debouncedOnChange : undefined}
        options={options && [{ label: 'Не выбрано', value: null }, ...options]}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        isDisabled={disabled}
        noOptionsMessage={() => null}
        styles={selectCustomStyles}
        ref={selectRef}
        onKeyDown={onEnter}
        defaultValue={defaultValue}
        onFocus={emptyInputSearch}
        {...props}
      />
    </div>
  )
}

SearchableSelect.propTypes = {
  onInputChange: T.func,
  onChange: T.func,
  options: T.array,
  value: T.any,
  placeholder: T.string,
  isMulti: T.bool,
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchableSelect)
