import { createNotification } from "../../../components/notification/notification";
import { ERROR, INSTITUTIONS_DELETE_REQUEST, START, SUCCESS } from "../action-types";

export const institutionsDeleteRequestStart = (data) => ({
  type: `${INSTITUTIONS_DELETE_REQUEST}${START}`,
  payload: data
});

export const institutionsDeleteRequestSuccess = (data) => ({
  type: `${INSTITUTIONS_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const institutionsDeleteRequestError = (data) => {
  const { error } = data.response.data
  createNotification('error', error || 'Невозможно удалить запись')
  return {
    type: `${INSTITUTIONS_DELETE_REQUEST}${ERROR}`,
    payload: data
  }
};

