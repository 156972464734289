import { api } from "../../App";

export const getDocumentBooksList = (documentId) => {   //Дизайн не предусматривает пагинации
  return api.get(`/bookrequest/${documentId}`);
};

export const addBookToDocument = (data) => {
  return api.post(`/bookrequestbook/${data.documentid}`, data)
}

export const deleteBookToDocument = (bookId) => {
  return api.delete(`/bookrequestbook/${bookId}`);
};

export const editBookToDocument = (data) => {
  return api.put(`/bookrequestbook/${data.id}`, data);
}

