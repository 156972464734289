import { SHELVES_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = {};

export const shelvesReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${SHELVES_LIST_REQUEST}${SUCCESS}`:
      const { rackId, data } = action.payload;
      return {...state, [rackId]: data};

    default: return state;
  }
};