import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";
import {
  getSeriesList,
  getSeriesCount,
  deleteSeriesItem,
  addSeriesItem,
  editSeriesItem
} from '../../../api/series/series';
import {
  SERIES_ADD_REQUEST,
  SERIES_DELETE_REQUEST,
  SERIES_COUNT_REQUEST,
  SERIES_EDIT_REQUEST,
  SERIES_LIST_REQUEST,
  START,
} from '../../actions/action-types';
import {
  seriesCatalogCountRequestStart,
  seriesCatalogCountRequestSuccess,
  seriesCatalogCountRequestError
} from '../../actions/series/count';
import {
  seriesListRequestStart,
  seriesListRequestSuccess,
  seriesListRequestError
} from '../../actions/series/list';
import {
  setSeriesCatalogSearchData
} from '../../actions/series/search';
import {
  seriesCatalogDeleteRequestSuccess,
  seriesCatalogDeleteRequestError
} from '../../actions/series/delete';
import {
  seriesCatalogAddRequestSuccess,
  seriesCatalogAddRequestError
} from '../../actions/series/add';
import {
  seriesCatalogEditRequestSuccess,
  seriesCatalogEditRequestError
} from '../../actions/series/edit';


export default function* seriesWatcher() {
  yield takeLatest(`${SERIES_COUNT_REQUEST}${START}`,countWorker);
  yield takeLatest(`${SERIES_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${SERIES_DELETE_REQUEST}${START}`, deleteWorker);
  yield takeLatest(`${SERIES_ADD_REQUEST}${START}`, addWorker);
  yield takeLatest(`${SERIES_EDIT_REQUEST}${START}`, editWorker);
}

function* countWorker() {
  const formData = yield select(({series}) => series.form);
  const searchData = yield select(({series}) => series.search);
  try {
    const response = yield call(getSeriesCount, {...formData, ...searchData});
    const count = parseInt(response.data.count, 10);
    yield put(seriesCatalogCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setSeriesCatalogSearchData({...searchData, pageCount}));
    yield put(seriesListRequestStart());
  } catch (error) {
    yield put(seriesCatalogCountRequestError(error));
  }
}

function* listWorker() {
  const formData = yield select(({series}) => series.form);
  const searchData = yield select(({series}) => series.search);
  try {
    const response = yield call(getSeriesList, {...formData, ...searchData});
    yield put(seriesListRequestSuccess(response.data));
  } catch (error) {
    yield put(seriesListRequestError(error));
  }
}

function* deleteWorker({payload: itemId}) {
  try {
    yield call(deleteSeriesItem, itemId);
    yield put(seriesCatalogDeleteRequestSuccess());
    yield put(seriesCatalogCountRequestStart());
  } catch (error) {
    yield put(seriesCatalogDeleteRequestError(error));
  }
}

function* addWorker({payload: data}) {
  try {
    yield call(addSeriesItem, data);
    yield put(seriesCatalogAddRequestSuccess());
    yield put(seriesCatalogCountRequestStart());
  } catch (error) {
    yield put(seriesCatalogAddRequestError(error));
  }
}

function* editWorker({payload: data}) {
  try {
    yield call(editSeriesItem, data);
    yield put(seriesCatalogEditRequestSuccess());
    yield put(seriesCatalogCountRequestStart());
  } catch (error) {
    yield put(seriesCatalogEditRequestError(error));
  }
}