import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import { adminGroupsRequestAllGroupsStart } from '../../redux/actions/adminGroups/groups'
import { AdminGroupsTable } from '../../components/AdminGroups/AdminGroupsTable'
import AdminGroupsModal from '../../components/AdminGroups/AdminGroupsModal'
import { adminGroupsDeleteGroup } from '../../redux/actions/adminGroups/deleteGroup'
import { adminGroupsAddGroupsStart } from '../../redux/actions/adminGroups/addGroup'
import { adminGroupsEditGroupStart } from '../../redux/actions/adminGroups/editGroup'
import AdminGroupsForm from '../../components/AdminGroups/AdminGroupsForm'

const mapStateToProps = ({ adminGroups }) => ({
  groups: adminGroups.groups,
})
const mapDispatchToProps = dispatch => ({
  requestAdminGroups: (params) => dispatch(adminGroupsRequestAllGroupsStart(params)),
  deleteGroup: id => dispatch(adminGroupsDeleteGroup(id)),
  addGroup: payload => dispatch(adminGroupsAddGroupsStart(payload)),
  editGroup: (id, payload) => dispatch(adminGroupsEditGroupStart(id, payload)),
})

const AdminGroupsScene = ({ groups, requestAdminGroups, deleteGroup, addGroup, editGroup }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    requestAdminGroups()
  }, [])


  return (
    <SceneContainer title={'Администрирование / Группы'}>
      {modalVisible && (
        <AdminGroupsModal
          addGroup={addGroup}
          editGroup={editGroup}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )}
      <AdminGroupsForm requestAdminGroups={requestAdminGroups} />
      <AdminGroupsTable
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        data={groups}
        deleteGroup={deleteGroup}
        setModalVisible={setModalVisible}
      />
    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroupsScene)
