import { SCHEDULER_LIST_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const schedulerListRequestStart = () => ({
  type: `${SCHEDULER_LIST_REQUEST}${START}`
});

export const schedulerListRequestSuccess = (data) => ({
  type: `${SCHEDULER_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const schedulerListRequestError = (error) => ({
  type: `${SCHEDULER_LIST_REQUEST}${ERROR}`,
  payload: error
});
