import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {scheduleActions} from "../../redux/factory/scheduleReduxData";
import {connect} from "react-redux";
import {bookSupplyListRequestStart} from "../../redux/actions/bookSupply/list";
import BookSupplyTable from "../../components/BookSupply/Table";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import BookSupplyTitle from "../../components/BookSupply/Title";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import styles from './BookSupply.module.css';
import BookSupplyPDFPrint from "../../components/BookSupply/PDFPrint";
import DataTable from "../../components/DataTable";
import DataTableCol from "../../components/DataTable/Column";


const mapStateToProps = ({schedulePersonalReducer, bookSupply}) => ({
    yearsList: schedulePersonalReducer,
    list: bookSupply.list,
    books: bookSupply.books,
    loader: bookSupply.loader,
});

const matchDispatchToProps = {
    requestYears: scheduleActions.loadRequestStart,
    requestList: bookSupplyListRequestStart,
};


const BookSupply = ({ yearsList, requestYears, requestList, list, loader, books }) => {

    const [selectedYear, setYear] = useState('');
    const {} = books;

    useEffect(() => {
        requestYears();
    }, [requestYears]);

    const onSelectChange = (e) => {
        const t = e.target;

        requestList(t.value);
        setYear(t.options[t.selectedIndex].text);
    };
    return (
        <SceneContainer>
            <BookSupplyTitle onSelectChange={onSelectChange} yearsList={yearsList}/>

            <div className={'d-flex justify-content-between align-items-center mt-3'}>
                <Link to="/booksupplystandarts" className={styles.content_link}>
                    Нормы книгообеспеченности
                </Link>
                {!!list.length &&
                <BookSupplyPDFPrint title={`Книгообеспеченность на ${selectedYear} гг.`} data={list}/>
                }
            </div>

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <>
                <BookSupplyTable milspecs={list}/>
                {!!books.length &&
                    <div className="mt-3">
                        <DataTable data={books}>
                            <DataTableCol title="Название" value="book_name" colSpan={3}/>
                            <DataTableCol title="Общее число курсантов" value="people_count"/>
                            <DataTableCol title="Общее число книг" value="book_total_count" />
                            <DataTableCol title="Процент книгообеспеченности" value="booksupply_percent"/>
                        </DataTable>
                    </div>
                }
                </>
            }
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    matchDispatchToProps
)(BookSupply);