import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'

const mapStateToProps = ({ auth }) => ({
  permissions: auth.data.role_permissions,
})

const RolePermissionChecker = ({ children, permissionName, permissions }) => {
  const ACCESS_LEVELS = {
    NONE: 'Нет',
    READONLY: 'Чтение',
    FULL: 'Полный',
  }

  const currentPermission = permissions.find(item => item.RasdelName === permissionName)

  if (!permissions.length) {
    return null
  }

  if (!currentPermission) {
    return null
  }

  if (currentPermission.value === ACCESS_LEVELS.NONE) {
    return null
  }

  if (currentPermission.value === ACCESS_LEVELS.READONLY) {
    return null
  }

  if (currentPermission.value === ACCESS_LEVELS.FULL) {
    return children
  }

  return null
}

RolePermissionChecker.propTypes = {
  children: T.element.isRequired,
  permissionName: T.string.isRequired,
}

export default connect(mapStateToProps, null)(RolePermissionChecker)
