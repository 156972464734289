import React, {useState, useMemo, useLayoutEffect} from 'react';
import {connect} from 'react-redux';
import {bookOpenHistoryListRequestStart} from "../../redux/actions/bookOpenHistory/list";
import moment from "moment";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import {Col, Row} from "react-bootstrap";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import CollapsedBlock from "./Sidebar/CollapsedBlock";
import ListCard from "./List/Card";
import {Link} from "react-router-dom";

import styles from './s.module.css';

const mapStateToProps = ({bookOpenHistory}) => ({
    list: bookOpenHistory.list,
    loader: bookOpenHistory.loader
});

const mapDispatchToProps = {
    requestList: bookOpenHistoryListRequestStart,
};


export function formatDate(date) {
    const WEEK_DAYS = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const _date = moment(date);
    const isToday = _date.isSame(moment(), 'd');
    const isYesterday = _date.isSame(moment().subtract(1, 'days'), 'd');
    const weekDay = _date.weekday();

    if(isToday) {
        return "Сегодня"
    } else if (isYesterday) {
        return "Вчера"
    } else {
        return _date.format(`DD MMMM, ${WEEK_DAYS[weekDay]}`)
    }
}


const BookOpenHistory = ({list, requestList, loader, match}) => {

    const mid = match.params.id;

    /**
     * HOOKS
     * */
    useLayoutEffect(() => {
        requestList(mid);
    }, [mid]);

    const splitList = useMemo(() => splitListToDates(list), [list]);

    const [selectedDate, setSelectedDate] = useState(null);

    /**
     * FUNCTIONS
     * */
    function splitListToDates(items) {
        const result = {};

        items.forEach(item => {

            const eventTime = moment(item.eventtime);

            const year = eventTime.format('YYYY');
            const month = eventTime.format('M');
            const dateProp = eventTime.date();

            result[year] = result[year] || {};
            if (!result[year][month]) {
                result[year][month] = {};
            }

            result[year][month][dateProp] = result[year][month][dateProp] || [];
            result[year][month][dateProp].push(item);
        });

        return result;
    }


    const onSelectFromList = (year, month, day) => {
        if(
            year === selectedDate?.year
            && month === selectedDate?.month
            && day === selectedDate?.day
        ) {
            return setSelectedDate(null);
        }

        setSelectedDate({
            year,
            month,
            day
        });
    };

    return (
        <SceneContainer>
            <div className={'d-flex justify-content-between mb-3'}>
                <h2 className={styles.title}>
                    История просмотров
                </h2>

                <Link to={`/library_card/${mid}`} className={'tableButton'}>
                    Выйти из истории
                </Link>
            </div>

            {loader ?
                <SpinnerOuter/>
            :
                <Row>
                    <Col md={3}>
                        {Object.entries(splitList).reverse().map(year =>
                            <div>
                                {year[0]}
                                {Object.entries(year[1]).reverse().map(month =>
                                    <CollapsedBlock month={month} year={year} onClick={onSelectFromList} selectedDate={selectedDate}/>
                                )}
                            </div>
                        )}
                    </Col>
                    <Col md={9}>
                        <div>
                            {selectedDate ?
                                <ListCard
                                    dayItems={splitList[selectedDate.year][selectedDate.month][selectedDate.day]}
                                />
                                :
                                Object.entries(splitList).reverse().map(year =>
                                    <React.Fragment key={year}>
                                        {+year[0] !== new Date().getFullYear() && year[0]}
                                        {Object.entries(year[1]).reverse().map(month =>
                                            Object.entries(month[1]).reverse().map(day =>
                                                <ListCard dayItems={day[1]} key={`${year}${month}${day}`}/>
                                            )
                                        )}
                                    </React.Fragment>
                                )
                            }

                        </div>
                    </Col>
                </Row>
            }

        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookOpenHistory);
