import { BOOK_ORDER_BOOKS_EDIT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const orderBooksEditRequestStart = (data) => ({
    type: `${BOOK_ORDER_BOOKS_EDIT_REQUEST}${START}`,
    payload: data
});

export const orderBooksEditRequestSuccess = (data) => ({
    type: `${BOOK_ORDER_BOOKS_EDIT_REQUEST}${SUCCESS}`,
    payload: data
});

export const orderBooksEditRequestError = (data) => ({
    type: `${BOOK_ORDER_BOOKS_EDIT_REQUEST}${ERROR}`,
    payload: data
});
