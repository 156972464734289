import { ERROR, LIBRARY_POINT_LIST_REQUEST, START, SUCCESS } from "../../../action-types";

export const libraryPointListRequestStart = (libraryId) => ({
  type: `${LIBRARY_POINT_LIST_REQUEST}${START}`,
  payload: libraryId
});

export const libraryPointListRequestSuccess = (data) => ({
  type: `${LIBRARY_POINT_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryPointListRequestError = (error) => ({
  type: `${LIBRARY_POINT_LIST_REQUEST}${ERROR}`,
  payload: error
});