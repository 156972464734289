import React from 'react'
import T from 'prop-types'
import styles from './BookEditFiles.module.css'
import classNames from 'classnames'
import FileLoader from '../../../UtulityComponents/FileLoader'

const LoadForm = ({ onUpload, loader, modalData }) => {
  return (
    <div className={classNames(styles.loadForm, 'd-flex justify-content-end my-2')}>
      <span className={styles.file}>{modalData?.data?.file?.name}</span>
      <FileLoader onUpload={e => onUpload(e.target.files[0])} loader={loader} />
    </div>
  )
}

LoadForm.propTypes = {
  onUpload: T.func,
}

export default LoadForm
