import React from 'react'
import PropTypes from 'prop-types'
import { Table, Typography } from 'antd'
import moment from 'moment'
import { Tab, Tabs } from 'react-bootstrap'
import styles from './styles.module.css'

const LibrariesDiaryTable = React.memo(({ data, dateFormat, typesList, eventReportData, librariesList }) => {

  const { Column, ColumnGroup } = Table
  const { Text } = Typography

  const {
    date,
    readers_all,
    readers_new,
    readers_new_teachers,
    readers_new_students,
    visiters_all,
    visiters_teachers,
    visiters_students,
    digitbooktakecount,
    ...dynamicFields
  } = data[0] || {}
  

  const leftTable = () => {
    return (
      <Table
        dataSource={data}
        // title={() => 'УЧЕТ ЧИТАТЕЛЕЙ, ВЫДАЧИ КНИГ И ЖУРНАЛОВ\n'}
        pagination={false}
        size='small'
        bordered
        id='libraries-diary-table'
      >
        <Column title='Числа месяца' dataIndex='date' render={text => <Text strong>{text == 'all' ? "Всего" : moment(text).format(dateFormat)}</Text>} />
        <ColumnGroup title='Кол-во пользователей'>
          <Column title='Всего' dataIndex='readers_all' key='readers_all' />
          <ColumnGroup title='В том числе'>
            <Column title='Новых' dataIndex='readers_new' key='readers_new' />
            <Column title='Преподавателей' dataIndex='readers_new_teachers' key='readers_new_teachers' />
            <Column title='Обучающихся' dataIndex='readers_new_students' key='readers_new_students' />
          </ColumnGroup>
        </ColumnGroup>
        <ColumnGroup title='Кол-во посещений'>
          <Column title='Всего' dataIndex='visiters_all' key='visiters_all' />
          <ColumnGroup title='В том числе'>
            <Column title='Преподавателей' dataIndex='visiters_teachers' key='visiters_teachers' />
            <Column title='Обучающихся' dataIndex='visiters_students' key='visiters_students' />
          </ColumnGroup>
        </ColumnGroup>
      </Table>
    )
  }

  const renderDynamicColumns = (columnType) => {

    const title = typesList.find(({translate_name}) => translate_name === columnType)?.name
    return <Column title={title} dataIndex={columnType} key={columnType} />
  }

  const rightTable = () => {

    return (
      <Table
        dataSource={data}
        // title={() => 'УЧЕТ ЧИТАТЕЛЕЙ, ВЫДАЧИ КНИГ И ЖУРНАЛОВ\n'}
        pagination={false}
        size='small'
        bordered
        id='libraries-diary-table'
      >
        <Column title='Число месяца' dataIndex='date' render={text => <Text strong>{text == 'all' ? "Всего" : moment(text).format(dateFormat)}</Text>} />
        <ColumnGroup title='Выдано документов по типам'>
          <Column title='Всего' dataIndex='digitbooktakecount' key='digitbooktakecount' />
          <ColumnGroup title='В том числе'>
            {Object.keys(dynamicFields).map(renderDynamicColumns)}
          </ColumnGroup>
        </ColumnGroup>
      </Table>
    )
  }

  const thirdTable = () => {
    return (
      <Table
        dataSource={eventReportData}
        pagination={false}
        size='small'
        bordered
        id='events-diary-table'
        className={styles.eventsTable}
      >
        
        <Column title='Дата' key='eventDates' render={(row) => (
            <>
              {moment(row.from_date).format('DD.MM.YYYY')}
              <br/>
              {moment(row.to_date).format('DD.MM.YYYY')}
            </>
          )}
        />
        <Column title='Наименование мероприятия' dataIndex='event_name' key='event_name' />
        <Column title='Количество обслуженных (чел)' dataIndex='people_count' key='people_count' />
        <Column title='Кто проводил' dataIndex='person' key='person' />
        <Column title='Библиотека' render={(row) => librariesList.find(({id}) => id == row.library_id)?.name} key='library_id' />
        <Column title='Примечание' dataIndex='description' key='description' />

      </Table>
    )
  }

  return (
    <>    
      <div style={{overflowY:'auto'}}>
        <Tabs 
        defaultActiveKey="1" 
        id={'OptionsTabs'}>
          <Tab title="Часть 1 Учет пользователей и посещений" eventKey="1">
            {leftTable()}
          </Tab>
          <Tab title="Часть 2 Учет книговыдачи" eventKey="2">
            {rightTable()}
          </Tab>
          <Tab title="Часть II. Учет массовых мероприятий (библиографические обзоры, литературные вечера, конференции читателей, книжные выставки, составление рекомендательных списков ) " eventKey="3">
            {thirdTable()}
          </Tab>
        </Tabs>
      </div>    
    </>
  )
})

LibrariesDiaryTable.propTypes = {
  data: PropTypes.array.isRequired,
}

export default LibrariesDiaryTable
