import React from 'react'
import classNames from 'classnames'
import styles from './LinkButton.module.css'

const LinkButton = ({ onClick, children, className }) => {
  return (
    <span onClick={onClick} className={classNames(styles.link, className)}>
      {children}
    </span>
  )
}

export default LinkButton
