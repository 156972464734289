import { ERROR, RUBRICATOR_SAVE_REQUEST, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const rubricatorSaveRequestStart = (data, callback) => ({
  type: `${RUBRICATOR_SAVE_REQUEST}${START}`,
  payload: data, callback
});

export const rubricatorSaveRequestSuccess = (data) => {
  createNotification(
    'success',
    `Сохранено`
  );

  return {
    type: `${RUBRICATOR_SAVE_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const rubricatorSaveRequestError = (error) => {
  const message = error.response?.data?.result || 'Ошибка сохранения';
  createNotification('error', message);
  return {
    type: `${RUBRICATOR_SAVE_REQUEST}${ERROR}`,
    payload: error
  };
};

