import jsPDF from 'jspdf'
import React from 'react'
import addFont from '../../../../fonts/fonts'
import PdfPrintPanel from '../../../PDFPrint/Panel'
import { useSelector } from 'react-redux'
import { getDSPdata } from './getDSPdata'
import { getSecretData } from './getSecretData'
import { getNotSecretData } from './getNotSecretData'
import moment from 'moment'

// 2 - дсп
// 3 - секретно

export const MaterialDestructionActPDFPrint = ({ data, actData }) => {
  const title = 'Уничтожение материалов библиотечного фонда'

  const { outsecrecy } = useSelector(state => state.options.data)
  const { data: clientData } = useSelector(state => state.options)

  const getDataFunction = outsecrecy == 2
    ? getDSPdata
    : outsecrecy == 3
        ? getSecretData
        : getNotSecretData
  

  const toPdf = () => {
    getDataFunction({ data, actData, clientData }).then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    getDataFunction({ data, actData, clientData }).then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }


  const materialDestructionActPDFTable = (
    <table id='materialDestructionActPDFTable' hidden>
      <thead>
        <tr>
          <th rowSpan={2}>
            {outsecrecy == 2 ? 'Организация, уничтожевшая документы' : 'Направлнение выбытия документов'}
          </th>
          <th colSpan={3} >Документ</th>
        </tr>
        <tr>
          <th>наименование</th>
          <th>инв. номер</th>
          <th>дата</th>
        </tr>
      </thead>
      <tbody>
        {data.map((element, index) => {
          const { invnumber, title, authors, year, sum, udk, amount } = element
          const { disposal_org, disposal_direction, documentdate} = actData
          const firstCol = outsecrecy == 2 ? disposal_org : disposal_direction
          const date = moment(documentdate).format('DD.MM.YYYY')
          return (
            <tr>
              <td>{firstCol}</td>
              <td>{title}</td>
              <td>{invnumber}</td>
              <td>{date}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )



  return (
    <div className='ml-auto'>
      {materialDestructionActPDFTable}
      <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf} />
    </div>
  ) 
}
