import { SET_SEARCH_AUDIT_FORM_DATA, SET_SEARCH_AUDIT_MID } from '../../../actions/action-types'
import moment from 'moment'

const initialState = {
  from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  to: moment(new Date()).format('YYYY-MM-DD'),
  clientip: '',
  username: '',
  name: '',
  write: undefined,
  accessdenied: undefined,
  bookid: '',
  affectedid: '',
  dataset: '/api/search',
}

export const searchAuditFormDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_AUDIT_FORM_DATA:
      return action.payload

    case SET_SEARCH_AUDIT_MID:
      return {
        ...state,
        mid: action.payload,
      }

    default:
      return state
  }
}
