import { IMPORT_BOOKS_REQUEST, IMPORT_BOOKS_RESET, SUCCESS } from "../../../actions/action-types";

const initialState = null;

export const importBooksDataReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${IMPORT_BOOKS_REQUEST}${SUCCESS}`:
      return action.payload;


      case `${IMPORT_BOOKS_RESET}`:
        return initialState;

    default: return state;
  }
};