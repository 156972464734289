import { ERROR, BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST, START, SUCCESS } from "../../action-types";

export const bookInvNumbersCountRequestStart = () => ({
  type: `${BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST}${START}`
});

export const bookInvNumbersCountRequestSuccess = (data) => ({
  type: `${BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookInvNumbersCountRequestError = (data) => ({
  type: `${BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
