import React from 'react'
import { Table } from 'react-bootstrap'
import styles from './Table.module.css'
import moment from 'moment'

const TYPE_WORKS = [
  { name: 'Курсовые работы (проекты, задачи)', value: 1 },
  { name: 'Лабораторные и расчетно-графические работы', value: 2 },
  { name: 'Письменные отчеты о выполнении программ практик и индивидуальных заданий', value: 3 },
  { name: 'Выпускная квалификационная работа', value: 4 },
]

const STATUSES = [
  { name: 'Выдано', value: 1 },
  { name: 'На проверке', value: 2 },
  { name: 'Проверено', value: 3 },
  { name: 'Закрыто', value: 4 },
]

const StudentProjectsTable = ({ projects, setModal }) => {
  const sendModal = id => {
    setModal({
      status: true,
      id: id,
    })
  }

  return (
    <>
      {(projects.length > 0 && (
        <Table bordered className={styles.studentProjectsTable}>
          <thead>
            <tr>
              <th>Вид работы</th>
              <th>Дисциплина</th>
              <th>Дата выдачи</th>
              <th>Срок выполнения</th>
              <th>Статус</th>
              <th>Оценка</th>
            </tr>
          </thead>
          <tbody>
            {projects.map(item => (
              <tr key={item.xp_key} onClick={() => sendModal(item.xp_key)}>
                <td>{(item.type && TYPE_WORKS[item.type].name) || 'не указано'}</td>
                <td>{item.coursetitle || 'не указано'}</td>
                <td>{moment(item.issuedate).format('DD.MM.YYYY')}</td>
                <td>{moment(item.duedate).format('DD.MM.YYYY')}</td>
                <td>{(item.status && STATUSES[item.status].name) || 'не указано'}</td>
                <td>{item.grade || 'не указано'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )) || <div className={styles.errorMessage}>По данным параметрам ничего не найдено</div>}
    </>
  )
}

export default StudentProjectsTable
