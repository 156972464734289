import React from 'react'
import T from 'prop-types'
import styles from './DocumentsEditBookForm.module.css'
import ModalInputWrapper from '../../../../../Modal/InputWrapper'
import { Col, Row } from 'react-bootstrap'
import { Button, Space, DatePicker } from 'antd'

const DocumentsEditBookForm = ({ data, setData, onSave, onDelete, type, modalData }) => {
  const onChange = ({ target: { name, value } }) => setData({ ...data, [name]: value })

  const onChangeCheckbox = ({ target: { checked } }) => {
    setData({ ...data, annualset: checked, librelease: null })
  }

  const view = modalData.data.view
  return (
    <div className={styles.container}>
      <Row>
        <Col md={12}>
          <div className={styles.title}>
            "{data.title}": {data.libnumber && type !== 1 && <>Инвентарный номер {data.libnumber}</>}
          </div>
          {/* Шифр */ type === 1 ? (
            <>
              <ModalInputWrapper label='Количество' type='number' name='amount' onChange={onChange} value={data.amount} />
              <ModalInputWrapper label='Цена' type='number' name='price' onChange={onChange} value={data.price} />
              {(view === 3 || view === 4) && (
                <ModalInputWrapper label='Годовой комплект'>
                  <input
                    type='checkbox'
                    name='annualset'
                    onChange={onChangeCheckbox}
                    value={data.annualset}
                    checked={data.annualset}
                  />
                </ModalInputWrapper>
              )}
              {!data.annualset && (
                <>
                  {(view === 3 || view === 4) && (
                    <ModalInputWrapper
                      label='Номер'
                      name='libnumber'
                      onChange={onChange}
                      value={!data.annualset ? null : data.libnumber}
                    />
                  )}
                  {view === 3 && (
                    <ModalInputWrapper label='Выпуск (месяц):'>
                      <DatePicker
                        onChange={(date, dateString) => setData({ ...data, librelease: `${dateString}-28` })}
                        picker='month'
                      />
                    </ModalInputWrapper>
                  )}
                  {view === 4 && (
                    <ModalInputWrapper label='Выпуск (дата):'>
                      <DatePicker onChange={(date, dateString) => setData({ ...data, librelease: dateString })} />
                    </ModalInputWrapper>
                  )}
                </>
              )}
            </>
          ) : type === 18 ? (
            <>
              <ModalInputWrapper label='Количество' type='number' name='amount' value={data.amount} disabled />
              <ModalInputWrapper
                label='Коэффициент перерасчёта цены'
                name='coefficient'
                onChange={onChange}
                value={data.coefficient}
                onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
              />
              <ModalInputWrapper
                label='Цена'
                type='number'
                name='price'
                onChange={onChange}
                value={data.price}
                onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
              />
              <ModalInputWrapper
                label='Цена с учетом коэффициента'
                type='number'
                value={(data.price * data.coefficient).toFixed(2)}
                disabled
              />
            </>
          ) : [19, 20, 12].some(e => e === type) ? (
            <>
              <ModalInputWrapper label='Количество' type='number' name='amount' value={data.amount} disabled />
            </>
          ) : (
            <ModalInputWrapper label='Количество' type='number' name='amount' onChange={onChange} value={data.amount} />
          )}
        </Col>
        <Col md={12} className={'text-right'}>
          <Space>
            <Button type='primary' onClick={() => onSave(data)}>Сохранить</Button>
            <Button type='primary' onClick={() => onDelete(data)} danger>Удалить</Button>
            <Button onClick={() => setData(null)}>Отменить</Button>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

DocumentsEditBookForm.propTypes = {
  data: T.object,
  setData: T.func,
  onSave: T.func,
  onDelete: T.func,
}

export default DocumentsEditBookForm
