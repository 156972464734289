import { combineReducers } from "redux";
import { adminModalUsersCountReducer } from "./count/countReducer";
import { adminModalListReducer } from "./list/listReducer";
import { adminModalUsersSearchReducer } from "./search/searchReducer";

export const adminModalUsersReducer = combineReducers({
  count: adminModalUsersCountReducer,
  list: adminModalListReducer,
  search: adminModalUsersSearchReducer
});
