import React from 'react'
import { Col, Row } from 'react-bootstrap'
import InputWrapper from '../../Modal/InputWrapper'
import { useState } from 'react'
import Button from '../../Button'

const courses = [1, 2, 3, 4, 5]

const AdminGroupsForm = ({ requestAdminGroups }) => {
  
  const [searchParams, setSearchParams] = useState({name: '', year: ''})

  const renderOption = (course) => <option value={course}>{course}</option>
  
  const handleChange = (e) => {
    const { name, value } = e.target
    setSearchParams({...searchParams, [name]: value})
  }

  const handleSubmit = () => {
    requestAdminGroups(searchParams)
  }
  
  return (
    <Row>
        <Col>
            <InputWrapper
              label='Название группы'
              name='name'
              onChange={handleChange}
            />
        </Col>
        <Col lg={1}>
            <InputWrapper label='Курс'>
                <select
                  value={searchParams.year}
                  name='year'
                  onChange={handleChange}
                >
                  <option value={''}>Все</option>
                  {courses.map(renderOption)}
                </select>
            </InputWrapper>
        </Col>
        <Col>
          <InputWrapper label={''}>
            <Button label='Найти' onClick={handleSubmit}/>
          </InputWrapper>
        </Col>
    </Row>
  )
}

export default AdminGroupsForm