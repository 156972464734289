import { ERROR, OPTIONS_DIARY_TYPES_REQUEST, START, SUCCESS } from "../../action-types";

export const optionsDiaryTypesRequestStart = (data) => ({
  type: `${OPTIONS_DIARY_TYPES_REQUEST}${START}`,
  payload: data
});

export const optionsDiaryTypesRequestSuccess = (data) => ({
  type: `${OPTIONS_DIARY_TYPES_REQUEST}${SUCCESS}`,
  payload: data
});

export const optionsDiaryTypesRequestError = (data) => ({
  type: `${OPTIONS_DIARY_TYPES_REQUEST}${ERROR}`,
  payload: data
});