import { call, put, takeLatest, select } from "redux-saga/effects";
import {
    ANSWER_STAT_COUNT_REQUEST,
    ANSWER_STAT_LIST_REQUEST,
    START,
} from "../../actions/action-types";
import {getAnswerStatCount, getAnswerStatList} from "../../../api/answerstat";
import {answerStatCountRequestError, answerStatCountRequestSuccess} from "../../actions/answerstat/count";
import {setAnswerStatSearchData} from "../../actions/answerstat/search";
import {
    answerStatListRequestError,
    answerStatListRequestStart,
    answerStatListRequestSuccess
} from "../../actions/answerstat/list";



export default function* answerStatWatcher() {
    yield takeLatest(`${ANSWER_STAT_COUNT_REQUEST}${START}`, countWorker);
    yield takeLatest(`${ANSWER_STAT_LIST_REQUEST}${START}`, listWorker);
}

function* countWorker() {
    const searchData = yield select(({ answerStat }) => answerStat.search);
    try {
        const response = yield call(getAnswerStatCount);
        const count = parseInt(response.data.count, 10);
        yield put(answerStatCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setAnswerStatSearchData({ ...searchData, pageCount }));
        yield put(answerStatListRequestStart());
    } catch (error) {
        yield put(answerStatCountRequestError(error));
    }
}

function* listWorker() {
    const searchData = yield select(({ answerStat }) => answerStat.search);
    const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getAnswerStatList, {...searchData, paginateFrom});
        yield put(answerStatListRequestSuccess(response.data));
    } catch (error) {
        yield put(answerStatListRequestError(error));
    }
}