import {
    RESET_COVERS_SCAN_FORM_DATA,
    SET_COVERS_SCAN_FORM_DATA
} from "../action-types";

export const setCoversScanPlanFormData = (data) => ({
    type: SET_COVERS_SCAN_FORM_DATA,
    payload: data
});

export const resetCoversScanPlanFormData = () => ({
    type: RESET_COVERS_SCAN_FORM_DATA
});