import { ERROR, LOST_DOC_GET_PDF_REQUEST, START, SUCCESS } from "../action-types";

export const lostDocPdfStart = (payload) => ({
  type: `${LOST_DOC_GET_PDF_REQUEST}${START}`,
  payload,
});

export const lostDocPdfSuccess = (payload) => ({
  type: `${LOST_DOC_GET_PDF_REQUEST}${SUCCESS}`,
  payload
});

export const lostDocPdfError = ({formData}) => ({
  type: `${LOST_DOC_GET_PDF_REQUEST}${ERROR}`,
  payload: {formData}
});