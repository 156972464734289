import {
  SERIES_COUNT_REQUEST,
  SERIES_LIST_REQUEST,
  SUCCESS,
  START,
  ERROR
} from "../../../actions/action-types";

const initialState = false;

export const seriesLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SERIES_COUNT_REQUEST}${START}`:
      return true;

    case `${SERIES_LIST_REQUEST}${SUCCESS}`:
      return false;

    case `${SERIES_LIST_REQUEST}${ERROR}`:
      return false;

    case `${SERIES_COUNT_REQUEST}${ERROR}`:
      return false;

    default: return state;
  }
};
