import { api } from "../../App";

export const getNewBookOrderBooksList = (documentId) => {   //Дизайн не предусматривает пагинации
    return api.get(`/newbookrequest/${documentId}`);
};

export const addBookToDocument = (data) => {
    return api.post(`/newbookrequestbook/${data.document}`, data.bookData)
}

export const editBookToDocument = (data) => {
    return api.put(`/newbookrequestbook/${data.bookData.id}`, data.bookData);
}

export const deleteBookToDocument = (bookId) => {
    return api.delete(`/newbookrequestbook/${bookId}`);
};
