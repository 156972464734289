import {SET_COVERS_FILE_DATA, SET_LOCATION_CHANGE} from "../../../actions/action-types";


const initialState = null;

export const coversFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COVERS_FILE_DATA:
            return action.payload;
        case SET_LOCATION_CHANGE:
            return initialState;

        default:
            return state;
    }
};