import React from 'react'
import T from 'prop-types'
import styles from './AdminRolesTable.module.css'
import { ADD, EDIT } from '../../../constants/modalTypes'
import DataTable from '../../DataTable'
import DataTableCol from '../../DataTable/Column'

const AdminRolesTable = ({ data, setModal, selectedId, handleSelect, onDelete }) => {
  const openEditModal = item => setModal({ modalType: EDIT, data: item })

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={() => setModal({ modalType: ADD, data: null })}>
          добавить
        </div>
        <div className={styles.button} onClick={onDelete}>
          удалить
        </div>
      </div>
      <DataTable data={data} onCellClick={openEditModal}>
        <DataTableCol title='Роль' value='name' colSpan={6} className={styles.leftAlign} />
        <DataTableCol title='Количество пользователей' value='amount'  />
        <DataTableCol
          title='Выбрано'
          passClicks
          resolver={item => <input type='checkbox' checked={item.pmid === selectedId} onChange={() => handleSelect(item.pmid)} />}
        />
      </DataTable>
    </div>
  )
}

AdminRolesTable.propTypes = {
  data: T.array,
  setModal: T.func,
  selectedId: T.any,
  handleSelect: T.func,
  onDelete: T.func,
}

export default AdminRolesTable
