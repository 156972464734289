import React from 'react';
import PropTypes from 'prop-types';
import styles from './CreditsTable.module.css';

const CreditsTable = ({ data }) => {

  const dataInArray = Object.entries(data);

  return (
    <div>
      {!!dataInArray.length &&
        <table className={styles.table}>
          <thead>
            <tr>
              <td colSpan={2}>Дисциплина</td>
              <td>Отчетность</td>
              <td>Оценка</td>
              <td>Дата</td>
              <td>Преподаватель</td>
            </tr>
          </thead>
          <tbody>
            {dataInArray.map(([name, value], index) =>
              <>
                <tr key={index} className={styles.claster}>
                  <td colSpan={6}>
                    { name } семестр
                  </td>
                </tr>
                {value.map((item, index) =>
                  <tr key={`stroke${item.cid}${index}`}>
                    <td colSpan={2}>{ item.course }</td>
                    <td>{ item.alias }</td>
                    <td>{ item.grade }</td>
                    <td>{ item.certdate_txt }</td>
                    <td></td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      }
    </div>
  );
};

CreditsTable.propTypes = {
  data: PropTypes.object
};

export default CreditsTable;