import {call, put, takeLatest, select} from "redux-saga/effects";
import {
    PDF_VIEWER_BOOK_REQUEST,
    PDF_VIEWER_BOOKMARK_REQUEST, PDF_VIEWER_HIGHLIGHT_ADD, PDF_VIEWER_HIGHLIGHT_DELETE, PDF_VIEWER_HIGHLIGHT_SET,
    START
} from "../../actions/action-types";
import {pdfViewerBookRequestError, pdfViewerBookRequestSuccess} from "../../actions/pdfViewer/book";
import {
    pdfViewerBookmarkRequestError,
    pdfViewerBookmarkRequestSuccess
} from "../../actions/pdfViewer/getHighlights";
import {getBookInfo} from "../../../api/book/book";
import {getBookmark, setBookmark} from "../../../api/pdfViewer/bookmark";


export default function* pdfViewerWatcher() {
    yield takeLatest(`${PDF_VIEWER_BOOK_REQUEST}${START}`, pdfViewerBookWorker);
    yield takeLatest(`${PDF_VIEWER_BOOKMARK_REQUEST}${START}`, pdfViewerBookmarkGetWorker);
    yield takeLatest(PDF_VIEWER_HIGHLIGHT_SET, pdfViewerBookmarkSaveWorker);
    yield takeLatest(PDF_VIEWER_HIGHLIGHT_ADD, pdfViewerBookmarkSaveWorker);
    yield takeLatest(PDF_VIEWER_HIGHLIGHT_DELETE, pdfViewerBookmarkSaveWorker);
}

function* pdfViewerBookWorker({payload: bookId}) {
    try {
        const response = yield call(getBookInfo, bookId);
        yield put(pdfViewerBookRequestSuccess(response.data));
    } catch (error) {
        yield put(pdfViewerBookRequestError(error.response));
    }
}

function* pdfViewerBookmarkGetWorker({payload: {bookId, fileId}}) {
    try {
        const response = yield call(getBookmark, bookId, fileId);
        yield put(pdfViewerBookmarkRequestSuccess(response.data.mark ? JSON.parse(response.data.mark) : []));
    } catch (error) {
        yield put(pdfViewerBookmarkRequestError(error));
    }
}

function* pdfViewerBookmarkSaveWorker() {
    let highlights = yield select(({pdfViewer}) => pdfViewer.highlights);
    const {bookId, fileId} = yield select(({pdfViewer}) => pdfViewer.fileData);

    highlights = JSON.stringify(highlights);

    try {
        yield call(setBookmark, bookId, fileId, highlights);
    } catch (error) {
        console.log(error)
    }
}

