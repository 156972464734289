import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Space, Button} from "antd";
import {
    DoubleLeftOutlined,
    LeftOutlined,
    RightOutlined,
    DoubleRightOutlined
} from '@ant-design/icons';

import LibraryCarouselItem from "./Item";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";

import styles from './LibraryCarousel.module.css';


const LibraryCarousel = ({ currentItem, itemsCount, requestSingleItem}) => {

    const openLibraryCard = (bookId) => {
        const libraryCard = window.open(
            `${window.location.origin}/book/${bookId}`
        );
        libraryCard.focus();
    };


    return (
        <div className={styles.container}>

            <div>
                <div className={styles.slideOuter}>
                    {currentItem.data ?
                        <LibraryCarouselItem data={currentItem.data[0]} index={0}/>
                        :
                        <SpinnerOuter/>
                    }
                </div>
                <div className={classNames(styles.carouselControls, 'mt-2 d-flex justify-content-center align-items-center')}>
                    <Space>
                        <Button onClick={() => requestSingleItem(-10)} type="primary"><DoubleLeftOutlined /></Button>
                        <Button onClick={() => requestSingleItem(-1)} type="primary"><LeftOutlined /></Button>
                        <div><span style={{ fontSize: 25, fontWeight: 'bold' }}>{currentItem.position}</span> / {itemsCount}</div>
                        <Button onClick={() => requestSingleItem(1)} type="primary"><RightOutlined /></Button>
                        <Button onClick={() => requestSingleItem(10)} type="primary"><DoubleRightOutlined /></Button>
                        {currentItem.data &&
                            <Button onClick={() => openLibraryCard(currentItem.data[0].bookId)} >Перейти к книге</Button>
                        }
                    </Space>
                </div>

            </div>
        </div>
    );
};

LibraryCarousel.propTypes = {
    data: PropTypes.array,
    nextPage: PropTypes.func,
    prevPage: PropTypes.func
};

export default LibraryCarousel;
