import React from 'react';
import T from 'prop-types';
import styles from './LibraryAlphabetSearch.module.css';
import {Row, Col, Button, Space} from "antd";
import InputWrapper from "../../Modal/InputWrapper";

const LibraryAlphabetSearch = ({data, setData, onSubmit, count}) => {

    return (
        <div className={styles.container}>
            <Row gutter={15} justify="space-between">
                <Col md={12}>
                    <InputWrapper
                        label={'Автор'}
                        value={data.author}
                        onChange={(e) => setData({...data, author: e.target.value})}
                    />
                </Col>
                <Col md={12}>
                    <InputWrapper
                        label={'Название'}
                        alue={data.title}
                        onChange={(e) => setData({...data, title: e.target.value})}
                    />
                </Col>

                <Col md={{span: 9}}>
                    <div className={styles.label}>
                        <input type="checkbox" checked={data.emptyCards}
                               onChange={(e) => setData({...data, emptyCards: e.target.checked})}/> Пустые библиотечные
                        записи
                    </div>
                </Col>
                <Col>
                    <Space>
                        {!!count &&
                        <div className={styles.foundCount}>
                            Найдено {count} записей
                        </div>
                        }
                        <Button onClick={onSubmit} type="primary">Найти</Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

LibraryAlphabetSearch.propTypes = {
    data: T.object,
    setData: T.func,
    onSubmit: T.func,
    count: T.number
};

export default LibraryAlphabetSearch;
