import { ERROR, RUBRICATOR_ITEM_REQUEST, START, SUCCESS } from "../action-types";

export const rubricatorItemRequestStart = (data) => ({
  type: `${RUBRICATOR_ITEM_REQUEST}${START}`,
  payload: data
});

export const rubricatorItemRequestSuccess = (data) => ({
  type: `${RUBRICATOR_ITEM_REQUEST}${SUCCESS}`,
  payload: data
});

export const rubricatorItemRequestError = (data) => ({
  type: `${RUBRICATOR_ITEM_REQUEST}${ERROR}`,
  payload: data
});