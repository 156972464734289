import { ALPHABET_RANGE_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const alphabetRangeRequestStart = (data) => ({
  type: `${ALPHABET_RANGE_REQUEST}${START}`,
  payload: data
});

export const alphabetRangeRequestSuccess = (data) => ({
  type: `${ALPHABET_RANGE_REQUEST}${SUCCESS}`,
  payload: data
});

export const alphabetRangeRequestError = (error) => ({
  type: `${ALPHABET_RANGE_REQUEST}${ERROR}`,
  payload: error
});

