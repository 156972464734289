import {ERROR, MOBILE_LIBRARY_DOCUMENTS_REQUEST, START, SUCCESS} from "../../action-types";

export const getDocumentsRequestStart = (libId) => {
    return {
        type: `${MOBILE_LIBRARY_DOCUMENTS_REQUEST}${START}`,
        libId
    }
};

export const getDocumentsRequestSuccess = (payload) => {
    return {
        type: `${MOBILE_LIBRARY_DOCUMENTS_REQUEST}${SUCCESS}`,
        payload
    }
};

export const getDocumentsRequestError = (payload) => {
    return {
        type: `${MOBILE_LIBRARY_DOCUMENTS_REQUEST}${ERROR}`,
        payload
    }
};