import { AUTHORS_COUNT_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = 0;

export const authorsCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${AUTHORS_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};