import React from 'react';
import T from 'prop-types';
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";

import {WEEK_DAYS} from "../index";


const SchedulerModalTable = ({data, setModal, deleteItem, addItem, handleSelect, selectedId}) => {
    return (
        <div>
            <div onClick={addItem} className={'tableButton mx-2 mb-1'}>
                добавить
            </div>
            <div onClick={() => deleteItem(selectedId)} className={'tableButton mx-2 mb-1'}>
                удалить
            </div>
            <DataTable data={data} onCellClick={item => setModal(item)}>
                <DataTableCol title={'день недели'} resolver={item => {
                    return Number.isInteger(item.day) ? WEEK_DAYS.find(day => item.day == day.number).name : 'Ежедневно'
                }}/>
                <DataTableCol title={'интервал'} value={'interval'}/>
                <DataTableCol title={'время'} resolver={item => {
                    return Array.isArray(item.schedule) ?
                        item.schedule.map(el => <p key={el}>{ el }</p>)
                        :
                        '-'
                }}/>
                <DataTableCol title={'Выбрано'} passClicks resolver={item =>
                    <input type="checkbox"
                           checked={item.id === selectedId}
                           onChange={() => handleSelect(item.id)}/>
                }/>
            </DataTable>
        </div>
    );
};

SchedulerModalTable.propTypes = {
    data: T.array,
    setModal: T.func,
    handleSelect: T.func,
    selectedId: T.number,
};

export default SchedulerModalTable;