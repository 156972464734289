import React, { useState } from 'react'
import Button from "../../components/Button";
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'

import { setImportBooksFormData } from '../../redux/actions/import/form'
import { importBooksRequestStart } from '../../redux/actions/import/import'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import SpinnerOuter from '../../components/UtulityComponents/SpinnerOuter'

import styles from './ImportScene.module.css'
import { useEffect } from 'react'
import { importBookResetData } from '../../redux/actions/import/reset'
import DupliccatesList from './DuplicatesList';


const mapStateToProps = ({ importBooks, options }) => ({
  data: importBooks.data,
  duplicates: importBooks.error,
  formData: importBooks.form,
  loading: importBooks.loader,

  jsonImport: importBooks?.data?.[0],
  // zipURL: importBooks.zipURL,
  rusMarc: importBooks.rusMarc,
  own_client_id: options.data?.own_client_id,
})
const mapDispatchToProps = {
  setFormData: setImportBooksFormData,
  startImport: importBooksRequestStart,
  resetData: importBookResetData,

}

const ImportScene = ({ loading, data, duplicates, formData, setFormData, startImport, own_client_id }) => {
  

  const onChange = (name, value) => setFormData({ ...formData, [name]: value })
  
  const handleFileRead = ({ target }) => {
    onChange(target.name, target.files[0])
  }

  useEffect(() => {
    formData.id = own_client_id;
  }, [own_client_id])


  return (
    <SceneContainer title='Импорт'>

      <Row>
          
      <Col md={6}>
          {loading
            ? <SpinnerOuter/>
            : <div>
                <div className={styles.sectionTitle}>
                    Формат загружаемого файла (кодировка UTF-8):
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.inputContainer}>
                        <input type="radio" checked={formData.format === "json"}
                                id={"json-radio"}
                                className={styles.radioInput}
                                value={"json"}
                                name={"format"}
                                onChange={({target}) => onChange(target.name, target.value)}/>
                        <label htmlFor={"json-radio"}>JSON</label>
                    </div>
                    <div className={styles.inputContainer}>
                        <input type="radio" checked={formData.format === "rusmarc"}
                                id={"rusmarc-radio"}
                                className={styles.radioInput}
                                value={"rusmarc"}
                                name={"format"}
                                onChange={({target}) => onChange(target.name, target.value)}/>
                        <label htmlFor={"rusmarc-radio"}>RUSMARC</label>
                    </div>
                    <div className={styles.inputContainer}>
                        <input type="radio" checked={formData.format === "zip"}
                                id={"zip-radio"}
                                className={styles.radioInput}
                                value={"zip"}
                                name={"format"}
                                onChange={({target}) => onChange(target.name, target.value)}/>
                        <label htmlFor={"zip-radio"}>ZIP</label>
                    </div>
                </div>

                <div className={styles.sectionTitle}>
                    Файл:
                </div>                          

                <div className={styles.sectionContainer}>
                    <input type="file" onChange={handleFileRead} name="file"/>          
                </div>

                <Button
                  label="Загрузить"
                  onClick={() => startImport()}
                  style={styles.button}
                  disabled={!formData?.file || duplicates}
                />

            </div>
          }
        </Col>

        {!!data && (
          <div>
            <div className={styles.sectionTitle}>Результат импорта:</div>
            <div className={styles.dataContainer}>
              {data.length
                ? data.map((item, index) => (
                    <div key={index} className={styles.dataTitle}>
                      {item.title}
                    </div>
                  ))
                : "Нет добавленных библиозаписей"
              }
            </div>
          </div>
        )}
    
      </Row>

    {!!duplicates && <DupliccatesList alrearyAdded={data} data={duplicates} startImport={startImport} />}

    </SceneContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportScene)
