import { call, put, takeLatest } from 'redux-saga/effects'
import { getBookDistribute, setBookDistribute, deleteBookDistribute } from '../../../../api/documents/bookDistribute'
import {
  DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST,
  DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST,
  START,
} from '../../../actions/action-types'
import { bookDistributeSaveRequestSuccess, bookDistributeSaveRequestError } from '../../../actions/documents/bookDistribute/save'
import {
  bookDistributeLoadRequestSuccess,
  bookDistributeLoadRequestError,
  bookDistributeLoadRequestStart,
} from '../../../actions/documents/bookDistribute/load'
import { createNotification } from '../../../../components/notification/notification'
import { documentBookListRequestStart } from '../../../actions/documents/books/list'

export default function* documentBookDistributeWatcher() {
  yield takeLatest(`${DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST}${START}`, bookDistributeSaveWorker)
  yield takeLatest(`${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${START}`, bookDistributeLoadWorker)
}

function* bookDistributeSaveWorker({ payload: data }) {
  try {
    if (data.documentId) {
      yield call(deleteBookDistribute, data.data, data.documentId)
    }
    const response = yield call(setBookDistribute, data.data)
    yield put(bookDistributeSaveRequestSuccess())
    yield call(createNotification, 'success', 'Записи успешно распределены по фондам')
    yield put(documentBookListRequestStart(data.data.documentid))
  } catch (error) {
    yield put(bookDistributeSaveRequestError(error))
    yield call(createNotification, 'error', 'Не удалось распределить записи по фондам')
  }
}

function* bookDistributeLoadWorker({ payload: data }) {
  try {
    const response = yield call(getBookDistribute, data)
    yield put(bookDistributeLoadRequestSuccess(response.data))
  } catch (error) {
    yield put(bookDistributeLoadRequestError(error))
  }
}
