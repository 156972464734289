import { ERROR, START, SUCCESS, CREATE_PRICE_COEFF_REQUEST } from "../../action-types";

export const createPriceCoeffRequestStart = (payload) => ({
  type: `${CREATE_PRICE_COEFF_REQUEST}${START}`, payload
});

export const createPriceCoeffRequestSuccess = (data) => ({
  type: `${CREATE_PRICE_COEFF_REQUEST}${SUCCESS}`,
  payload: data
});

export const createPriceCoeffRequestError = (data) => ({
  type: `${CREATE_PRICE_COEFF_REQUEST}${ERROR}`,
  payload: data
});