import { MEDIA_RESOURCE_GET_FOR_READER_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const mediaResourceReaderRequestStart = () => ({
  type: `${MEDIA_RESOURCE_GET_FOR_READER_REQUEST}${START}`
});

export const mediaResourceReaderRequestSuccess = (response) => ({
  type: `${MEDIA_RESOURCE_GET_FOR_READER_REQUEST}${SUCCESS}`,
  payload: response
});

export const mediaResourceReaderRequestError = (error) => ({
  type: `${MEDIA_RESOURCE_GET_FOR_READER_REQUEST}${ERROR}`,
  payload: error
});
