import {FUND_ACCOUNTING_REQUEST, SUCCESS, ERROR} from "../../../actions/action-types";

const initialState = []

export const fundAccountingListReducer = (state = initialState, action) => {

    switch (action.type) {

        case `${FUND_ACCOUNTING_REQUEST}${SUCCESS}`:
            return action.payload;

        case `${FUND_ACCOUNTING_REQUEST}${ERROR}`:
            return state;

        default:
            return state;
    }

};
