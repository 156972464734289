import React from 'react'
import T from 'prop-types'
import styles from '../s.module.css'
import printIcon from '../../../img/print.png'

const PdfPrintPanel = ({ loadPdf, printPdf }) => {
  return (
    <div className={styles.print}>
      <span onClick={loadPdf}>PDF</span>
      <div>|</div>
      <button onClick={printPdf} title={'Печать'} style={{ backgroundImage: 'url(' + printIcon + ')' }} />
    </div>
  )
}

PdfPrintPanel.propTypes = {
  loadPdf: T.func.isRequired,
  printPdf: T.func.isRequired,
}

export default PdfPrintPanel
