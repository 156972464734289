import { SET_LITERS_SEARCH_DATA } from "../../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const litersSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LITERS_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};
