import React from "react";
import styles from "./ExternalSearch.module.css";
import CoverPhotoPlaceholder from "../Covers/List/Item/CoverPhotoPlaceholder";

const ListItem = ({ imageUrl, name, authors, isbns, link }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          {imageUrl ? (
            <img src={imageUrl} alt={name} className={styles.image} />
          ) : (
            <CoverPhotoPlaceholder />
          )}
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.name}>{name}</div>

          {!!authors && !!authors.length && (
            <div className={styles.author}>
              Автор{authors.length > 1 && "ы"}: {authors.join(", ")}
            </div>
          )}
          <div className={styles.link}>
            Ссылка:{" "}
            <a href={link}>
              {link && link.substr(0, 100)}
              {link && link.length > 100 && "..."}
            </a>
          </div>
          {!!isbns &&
            isbns.length &&
            isbns.map(isbn => {
              if (
                typeof isbn.type !== "string" ||
                isbn.type.toLowerCase().indexOf("isbn") === -1
              )
                return;
              return (
                <div className={styles.author}>
                  {isbn.type}: {isbn.identifier}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
