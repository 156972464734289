import { ERROR, IMPORT_BOOKS_REQUEST, START, SUCCESS } from '../../../actions/action-types'

const initialState = false

export const importBooksLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${IMPORT_BOOKS_REQUEST}${START}`:
      return true

    case `${IMPORT_BOOKS_REQUEST}${SUCCESS}`:
      return false

    case `${IMPORT_BOOKS_REQUEST}${ERROR}`:
      return false

    default:
      return state
  }
}
