import React, { useEffect, useState } from 'react'
import { api } from '../../App'
import { AntiplagiatTable } from '../../components/Antiplagiat/AntiplagiatTable'
import Pagination from '../../components/Pagination'
import SceneContainer from '../../components/UtulityComponents/SceneContainer'
import AntiplagiatModal from '../../components/Antiplagiat/Modal/Index'

export const Antiplagiat = () => {
  const [list, setList] = useState([])
  const [selectedItem, setSelectedItem] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const [modalData, setModalData] = useState(null);

  const closeModal = () => {
    setModalData(null);
  }

  const setCurrentPage = page => {
    setPage(page)
    api
      .get('/antiplagiat_collection?count=true')
      .then(response => setCount(response.data.count))
      .then(api.get(`/antiplagiat_collection?paginateFrom=${page}&maxResults=15`).then(response => setList(response.data)))
  }

  useEffect(() => {
    api
      .get('/antiplagiat_collection?count=true')
      .then(response => setCount(response.data.count))
      .then(api.get(`/antiplagiat_collection?paginateFrom=${page}&maxResults=15`).then(response => setList(response.data)))
  }, [])


  const onDeleteDiscipline = async () => {
    try {
      const response = await api.delete(`/antiplagiat_collection/${selectedItem.documentId}`);
      setSelectedItem(false);
      if (response.status == 200) {
        setCurrentPage(page);
      }
    }
    catch (e) {
      console.error('Ошибка');
    }
  }

  return (
    <SceneContainer title={'Справочники / Антиплагиат коллекция'}>
      {
        modalData && 
        <AntiplagiatModal
        setModalData={setModalData} 
        modalData={modalData} 
        closeModal={closeModal}
        page={page}
        setCurrentPage={setCurrentPage}
        type="edit"
        />
      }

      <Pagination count={count} pageCount={Math.ceil(count / 15)} current={page} setCurrent={setCurrentPage} />
      <AntiplagiatTable 
      setModalData={setModalData}
      modalData={modalData}
      data={list} selectedItem={selectedItem} setSelectedItem={setSelectedItem} onDelete={onDeleteDiscipline} />
      <Pagination count={count} pageCount={Math.ceil(count / 15)} current={page} setCurrent={setCurrentPage} />
    </SceneContainer>
  )
}
