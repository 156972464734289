import { BORROWING_TEST_COUNT_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const borrowingTestCountRequestStart = () => ({
  type: `${BORROWING_TEST_COUNT_REQUEST}${START}`
});

export const borrowingTestCountRequestSuccess = (data) => ({
  type: `${BORROWING_TEST_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const borrowingTestCountRequestError = (error) => ({
  type: `${BORROWING_TEST_COUNT_REQUEST}${ERROR}`,
  payload: error
});
