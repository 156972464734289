import { SORT_DIRECTION } from '../../constants/sortDirections'
import { api } from '../../App'

export const getUsersList = (orderBy, maxResults, paginateFrom, search, sortDirection) => {
  const params = {
    maxResults,
    paginateFrom,
    search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: orderBy,
  }

  return api.get('/people', { params })
}

export const getDirectory = (orderBy, maxResults, paginateFrom, search, sortDirection) => {
  const params = {
    maxResults,
    paginateFrom,
    search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: orderBy,
  }

  return api.get('/all_guides', { params })
}

export const deleteDirectory = mid => {
  return api.delete(`/delete_guide/${mid}`)
}

const getCur = (val) => {
  // delete val.id;
  val.to_edit = true;
  delete val.err;
  if (!val.len) {
    delete val.len;
  }
  return val;
}

export const addDirectory = data => {
  return api.post('/create_guide', {...data, columns: data.columns.reduce((accumulator, currentValue) => [...accumulator, getCur(currentValue) ] , [])})
}


export const editDirectory = data => {
  return api.put(`/update_guide/${data.sql_name}`, {...data, columns: data.columns.reduce((accumulator, currentValue) => [...accumulator, getCur(currentValue) ] , [])})
}

export const getUsersCount = search => {
  const params = {
    count: true,
    search,
  }

  return api.get('/people', { params })
}

export const addUser = data => {
  return api.post('/people', data)
}

export const editUser = data => {
  return api.put(`/people/${data.mid}`, data)
}

export const deleteUser = mid => {
  return api.delete(`/people/${mid}`)
}

export const changeUserPassword = (mid, password) => {
  return api.put(`/people/password/${mid}`, { password })
}

export const getUserPhoto = mid => {
  const config = {
    responseType: 'arraybuffer',
  }

  return api.get(`/people/photo/${mid}`, config)
}

export const saveUserPhoto = (photo, mid) => {
  function convBase64ToFile(strBase64) {
    var tmp = strBase64.split(',')
    var prefix = tmp[0]
    var contentType = prefix.split(/[:;]+/)[1]
    var byteCharacters = atob(tmp[1])

    var byteNumbers = new Array(byteCharacters.length)
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  const img = typeof photo === 'string' ? convBase64ToFile(photo) : photo

  const formData = new FormData()
  formData.append('photo', img)
  const headers = [{ 'Content-Type': 'multipart/form-data' }]

  return api.post(`/people/photo/${mid}`, formData, { headers })
}

export const deleteUserPhoto = mid => {
  return api.delete(`/people/photo/${mid}`)
}
