import { COLLECTION_DELETE_DATA_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const collectionDeleteDataRequestStart = (collectionId, dataId, collectionType) => ({
  type: `${COLLECTION_DELETE_DATA_REQUEST}${START}`,
  payload: { collectionId, dataId, collectionType }
});

export const collectionDeleteDataRequestSuccess = (data) => ({
  type: `${COLLECTION_DELETE_DATA_REQUEST}${SUCCESS}`,
  payload: data
});

export const collectionDeleteDataRequestError = (data) => ({
  type: `${COLLECTION_DELETE_DATA_REQUEST}${ERROR}`,
  payload: data
});
