import {
    BOOK_COVER_SAVE,
    COVERS_SCAN_LIST_REQUEST, DOCUMENT_BOOK_CLEANUP,
    SEARCH_BY_IMAGE_REQUEST,
    SET_LOCATION_CHANGE,
    SUCCESS
} from "../../../actions/action-types";



const setBookCoverByBookId = (state, bookid, fileName) => {
    const bookIndex = state.findIndex(book => book.bookid == bookid);
    const updatedState = [...state];


    if(bookIndex) {
        updatedState[bookIndex].coverImage = fileName;
    }

    return updatedState;
}

const initialState = [];

export const listReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${COVERS_SCAN_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        case `${SEARCH_BY_IMAGE_REQUEST}${SUCCESS}`:
            return action.payload;

        case `${BOOK_COVER_SAVE}${SUCCESS}`:
            return setBookCoverByBookId(state, action.payload.bookId, action.payload.file.name)

        case SET_LOCATION_CHANGE:
            return initialState;

        case DOCUMENT_BOOK_CLEANUP:
            return initialState;

        default:
            return state;
    }
};