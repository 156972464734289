import { ERROR, PUBLISHERS_DELETE_REQUEST, PUBLISHERS_RESET_ERROR } from "../../../actions/action-types";

const initialState = null;

export const publishersErrorReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${PUBLISHERS_DELETE_REQUEST}${ERROR}`:
      return action.payload.log ? action.payload.data : null;

    case PUBLISHERS_RESET_ERROR:
      return null;

    default: return state;
  }
};