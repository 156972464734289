import React from 'react';
import T from 'prop-types';
import PdfPrintPanel from "../../PDFPrint/Panel";
import jsPDF from "jspdf";
import addFont from "../../../fonts/fonts";

const RemcomplectPdfPrint = ({orientation, tableData}) => {

    if(!tableData.length) {
        return null
    }

    const headers = [
        {
            value: 'title',
            title: 'Название книги'
        },{
            value: ({ authors }) => authors && authors.map(author => author.name + ','),
            title: 'Авторы'
        },
    ];

    const createData = () => {
        let result = [];

        tableData.forEach(item => {
            const generatedItem = [];

            headers.forEach(({value}) => {
                generatedItem.push(typeof value === 'function'
                    ? value(item)
                    : (item[value] || '-'));
            });

            result.push(generatedItem);
        });

        return result;
    };

    const createHeaders = () => headers.map(header => header.title);

    const doc = new jsPDF({putOnlyUsedFonts: true, orientation: orientation});
    addFont(jsPDF.API);
    doc.setFont("Montserrat");

    const toPdf = () => {
        doc.save(`рекомплектование.pdf`);
    };

    const toPrint = () => {
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    };


    doc.setFontSize(22);
    doc.text(15, 20, 'Список');

    doc.setFontSize(16);
    doc.text(15, 30, 'библиографических записей на рекомплектование');

    doc.setFontSize(11);
    doc.autoTable({
        head: [createHeaders()],
        body: createData(),
        styles: {font: "Montserrat", fontSize: 10},
        margin: {
            top: 45
        },
        headStyles: {
            halign: "center",
            valign: "middle",
            lineWidth: 0.25,
            lineColor: 200
        },
        bodyStyles: {
            halign: "center",
            lineWidth: 0.25,
            lineColor: 200
        },
    });

    return (
        <PdfPrintPanel loadPdf={toPdf} printPdf={toPrint}/>
    );
};

RemcomplectPdfPrint.propTypes = {
    tableData: T.array,
    orientation: T.oneOf(['landscape', 'portrait'])
};

export default RemcomplectPdfPrint;