import { MESSAGE_EDIT_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const editMessageRequestStart = (id) => ({
  type: `${MESSAGE_EDIT_REQUEST}${START}`,
  payload: id
});

export const editMessageRequestSuccess = (response) => ({
  type: `${MESSAGE_EDIT_REQUEST}${SUCCESS}`,
  payload: response
});

export const editMessageRequestError = (error) => ({
  type: `${MESSAGE_EDIT_REQUEST}${ERROR}`,
  payload: error
});
