import { AUDIT_LOG_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const auditLogCountRequestStart = () => ({
  type: `${AUDIT_LOG_COUNT_REQUEST}${START}`
});

export const auditLogCountRequestSuccess = (data) => ({
  type: `${AUDIT_LOG_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const auditLogCountRequestError = (data) => ({
  type: `${AUDIT_LOG_COUNT_REQUEST}${ERROR}`,
  payload: data
});