import React, {useLayoutEffect, useRef, useState} from 'react';
import T from 'prop-types';
import classNames from "classnames";
import styles from "../../../scenes/SearchByImage/SearchByImage.module.css";

const ImageDropUploader = ({image, uploadImage}) => {

    const uploadContainer = useRef();

    const [dragging, setDragging] = useState(false);

    useLayoutEffect(() => {
        let el = uploadContainer.current;
        el.addEventListener('dragenter', handleDragIn);
        el.addEventListener('dragleave', handleDragOut);
        el.addEventListener('dragover', handleDrag);
        el.addEventListener('drop', handleDrop);

        return () => {
            el.removeEventListener('dragenter', handleDragIn);
            el.removeEventListener('dragleave', handleDragOut);
            el.removeEventListener('dragover', handleDrag);
            el.removeEventListener('drop', handleDrop);
        }
    }, [uploadContainer]);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
            e.dataTransfer.items
            &&
            e.dataTransfer.items.length > 0
            &&
            e.dataTransfer.items[0].kind === 'file'
        ) {
            setDragging(true)
        }
    };

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            uploadImage(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    return (
        <div
            ref={uploadContainer}
            className={classNames({
                [styles.dragging]: dragging
            }, styles.uploadedContainer, 'd-flex justify-content-center align-items-center p-1')}
        >
            {image ?
                <img src={URL.createObjectURL(image)} alt=""/>
                :
                <h4>Перетащите изображение или нажмите "Загрузить картинку"</h4>
            }
        </div>
    );
};

ImageDropUploader.propTypes = {
    image: T.object.isRequired,
    uploadImage: T.func.isRequired,
};

export default ImageDropUploader;