import React from 'react';
import T from 'prop-types';
import styles from './ModalImage.module.css';
import ImagePlaceholder from "../../ImagePlaceholder";

const ModalImage = ({ url, placeholder, ...props }) => (
  <div className={styles.container} {...props}>
    <div className={styles.label}>
      Фото
    </div>
    <div className={styles.imageContainer}>
      {placeholder ?
        <ImagePlaceholder/>
        :
        <img className={styles.image} src={url} alt={""}/>
      }
    </div>
  </div>
);

ModalImage.propTypes = {
  url: T.string,
  placeholder: T.bool
};

export default ModalImage;
