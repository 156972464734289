import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import CoverListItemInfo from "../../Covers/List/Item/InfoBlock";
import CoverPhotoPlaceholder from "../../Covers/List/Item/CoverPhotoPlaceholder";
import styles from "./Item.module.css";

const NOT_INDICATED = "Не указано";

const Item = ({ data }) => {
  return (
    <div className={styles.item}>
      <Row>
        <Col md={2}>
          <CoverPhotoPlaceholder
            url={data.coverImage}
            bookId={data.bookid}
            alt={data.title}
          />
        </Col>
        <Col md={10}>
          <Row>
            <Col>
              <CoverListItemInfo
                label={"Название"}
                value={data.title || NOT_INDICATED}
              />
              <CoverListItemInfo
                label={"Год"}
                value={data.year || NOT_INDICATED}
              />
              <CoverListItemInfo
                label={"IBSN"}
                value={data.isbn || NOT_INDICATED}
              />
              <CoverListItemInfo
                label={"Страниц"}
                value={data.pageNum || NOT_INDICATED}
              />
              <CoverListItemInfo
                label={"Авторы"}
                value={
                  (data.authors && data.authors.map(item => item.name + " ")) ||
                  NOT_INDICATED
                }
              />
              <CoverListItemInfo
                label={"Издания"}
                value={
                  (data.publishers &&
                    data.publishers.map(item => `${item.name} `)) ||
                  NOT_INDICATED
                }
              />
              <CoverListItemInfo
                label={"Срок окончания подписки"}
                value={
                  moment(data.subscriptionenddate).format("DD.MM.YYYY") ||
                  NOT_INDICATED
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Item;
