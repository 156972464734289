import {DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST, START, SUCCESS, ERROR} from "../../action-types";

export const bookDistributeSaveRequestStart = (data, documentId) => ({
    type: `${DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST}${START}`,
    payload: {data, documentId}
});

export const bookDistributeSaveRequestSuccess = (data) => ({
    type: `${DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST}${SUCCESS}`,
    payload: data
});

export const bookDistributeSaveRequestError = (data) => ({
    type: `${DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST}${ERROR}`,
    payload: data
});
