import { DOCUMENT_INSTANCE_DELETE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const documentInstanceDeleteRequestStart = (data) => ({
    type: `${DOCUMENT_INSTANCE_DELETE_REQUEST}${START}`,
    payload: data
});

export const documentInstanceDeleteRequestSuccess = (data) => ({
    type: `${DOCUMENT_INSTANCE_DELETE_REQUEST}${SUCCESS}`,
    payload: data
});

export const documentInstanceDeleteRequestError = (data) => ({
    type: `${DOCUMENT_INSTANCE_DELETE_REQUEST}${ERROR}`,
    payload: data
});
