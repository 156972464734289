import { call, put, takeLatest } from "redux-saga/effects";
import { AUTHOR_IMAGE_REQUEST, START } from "../../../actions/action-types";
import { getAuthorImage } from "../../../../api/authors/authors";
import { authorImageRequestError, authorImageRequestSuccess } from "../../../actions/authors/loadImage";

export default function* authorImageWatcher() {
  yield takeLatest(`${AUTHOR_IMAGE_REQUEST}${START}`, authorImageWorker)
}

function* authorImageWorker({ payload: id }) {
  try {
    const response = yield call(getAuthorImage, id);
    const url = `data:image/jpeg;base64, ${Buffer.from(response.data, 'binary')
      .toString('base64')}`;
    yield put(authorImageRequestSuccess(url));
  } catch (error) {
    yield put(authorImageRequestError(error));
  }
}