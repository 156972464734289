import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import 'webdatarocks/webdatarocks.min.css';
import { bigDataActions } from '../../redux/factory/BigData/bigDataReduxData';
import styles from './BigData.module.css';
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import local from '../../WebDataRocksLocal';
import WebDataRocks from 'webdatarocks/webdatarocks.js';
import '../../css/webdatarocks.css';

const mapStateToProps = ({ bigData }) => ({
  data: bigData.data,
  loader: bigData.loading
});

const mapDispatchToProps = {
  loadData: bigDataActions.bigDataRequestStart,
};

const BigDataScene = ({ data, loadData, loader }) => {

  useEffect(() => {
    loadData(null);
  }, [loadData]);

  useEffect(() => {
    console.log(data)
    if(!!data.length) {
      let pivot = new WebDataRocks({
        container: "#wdr-component",
        toolbar: true,
        report: {
          dataSource: {
            data: data
          }
        },
        global: {
          localization: local
        }
      });
    }
  }, [data]);

  return (
    <div className={styles.container}>
      {loader
        ? <SpinnerOuter isLoading={loader} />
        : <>
            {!data.length && <h1 className={styles.noData}>Данные отсутствуют</h1>}
            <div id="wdr-component" />
          </>
      
      }
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BigDataScene);
