import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from '../reducers/rootReducer'
import rootSaga from '../sagas/rootSaga'

const initialState = {}

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk, sagaMiddleware)))
  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    module.hot.accept('../reducers/rootReducer', () => {
      const nextRootReducer = require('../reducers/rootReducer')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
