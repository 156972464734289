import {
    START,
    SUCCESS,
    ERROR,
    INVENTORY_NEW_BOOK_SAVE_REQUEST
  } from "../../action-types";
  
  export const inventoryNewBookSaveRequestStart = (data, invNumber, documentId) => ({
    type: `${INVENTORY_NEW_BOOK_SAVE_REQUEST}${START}`,
    payload: { data, invNumber, documentId }
  });
  
  export const inventoryNewBookSaveRequestSuccess = (data) => ({
    type: `${INVENTORY_NEW_BOOK_SAVE_REQUEST}${SUCCESS}`,
    payload: data
  });
  
  export const inventoryNewBookSaveRequestError = (data) => ({
    type: `${INVENTORY_NEW_BOOK_SAVE_REQUEST}${ERROR}`,
    payload: data
  });