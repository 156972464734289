import {ERROR, MOBILE_LIBRARY_LIST_REQUEST, SET_MOBILE_LIBRARY_SEARCH_DATA, START, SUCCESS} from "../../action-types";

export const mobileLibraryListRequestStart = (data) => ({
    type: `${MOBILE_LIBRARY_LIST_REQUEST}${START}`,
    payload: data
});

export const mobileLibraryListRequestSuccess = (payload) => ({
    type: `${MOBILE_LIBRARY_LIST_REQUEST}${SUCCESS}`,
    ...payload
});

export const mobileLibraryListRequestError = (data) => ({
    type: `${MOBILE_LIBRARY_LIST_REQUEST}${ERROR}`,
    payload: data
});

export const setMobileLibrarySearchData = (payload) => ({
    type: `${SET_MOBILE_LIBRARY_SEARCH_DATA}`,
    payload
});

