import React, {useEffect, useRef} from 'react';
import videojs from "video.js";
import styles  from './VideoPlayer.module.css';

const VideoPlayer = ({sources}) => {

    const playerRef = useRef();

    useEffect(() => {
        const player = videojs(playerRef.current, {
            controls: true,
            fluid: true,
            preload: 'auto'
        });
        player.src({
            src: !!sources ? sources : {},
            type: 'application/x-mpegURL',
        });
    }, [sources]);

    return (
        <div className={styles.video_placeholder}>
            <video height={400} ref={playerRef} className="video-js" autoPlay={true}/>
        </div>
    );
};

export default VideoPlayer;