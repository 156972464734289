export const SPECIALITY_BOOKS_TEST_DATA = [
  {
    index: 1,
    state: "В наличии",
    title: "Теория и методология политической науки",
    authors: "под общ. ред. С. В. Расторгуева",
    year: "2019",
    isbn: "978-5-4461-0775-9",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
    type: "учебник",
  },
  {
    index: 2,
    title: "Политическая психология",
    authors: "А. Л. Андреев",
    year: "2018",
    type: "учебное пособие",
    state: "На руках",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
    issueDate: "19.05.2019",
    returnDate: "27.05.2019"
  },
  {
    index: 3,
    title: "Политическая социология",
    authors: "Ж. Т. Тощенко",
    year: "2018",
    type: "учебник",
    state: "В наличии",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
  },
  {
    index: 4,
    title: "Регионоведение",
    authors: "В. А. Ачкасова",
    year: "2018",
    type: "учебник",
    state: "На руках",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
    issueDate: "19.05.2019",
    returnDate: "27.05.2019"
  },
  {
    index: 5,
    title: "Коррупция в России: Информационно-правовые методы воздействия",
    authors: "А. Н. Чумиков, М.П. Бочаров",
    year: "2017",
    type: "учебник",
    state: "В наличии",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
  },
  {
    index: 6,
    title: "Теория и методология политической науки",
    authors: "под общ. ред. С. В. Расторгуева",
    year: "2019",
    type: "учебник",
    state: "В наличии",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
  },
  {
    index: 7,
    title: "Политическая психология",
    authors: "А. Л. Андреев",
    year: "2018",
    type: "учебное пособие",
    state: "В наличии",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
  },
  {
    index: 8,
    title: "Политическая социология",
    authors: "Ж. Т. Тощенко",
    year: "2018",
    type: "учебник",
    state: "На руках",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
    issueDate: "19.05.2019",
    returnDate: "27.05.2019"
  },
  {
    index: 9,
    title: "Регионоведение",
    authors: "В. А. Ачкасова",
    year: "2018",
    type: "учебник",
    state: "В наличии",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
  },
  {
    index: 10,
    title: "Коррупция в России: Информационно-правовые методы воздействия",
    authors: "А. Н. Чумиков, М.П. Бочаров",
    year: "2017",
    type: "учебник",
    state: "В наличии",
    isbn: "978-5-496-00109-0",
    library: "Библиотека учебной литературы",
    fund: "Основной фонд",
  },
];