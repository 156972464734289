import React from "react";
import T from "prop-types";
import classNames from "classnames";
import {connect} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import styled from 'styled-components';
import styles from "./MenuItem.module.css";
import { api } from "../../../../App";
import { BASE_URL_WITHOUT_API } from "../../../../constants/api";

const MenuStyled = styled.div`
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    flex-grow: 1;
    overflow: hidden;
    max-width: 200px;

    @media (max-width: 1199px) {
        font-size: 9px;
        font-weight: bold;
        position: relative;
        overflow: initial;
        display: ${props => props.isShow ? 'flex' : 'none'};
    }

.menuActive {
    background-color: ${props => props.options.menu.hover};
    color: #ffffff
}
.menuItem {
    display: block;
    color: #ffffff;
    // padding-top: 14px;
    // padding-bottom: 14px;
    padding: .5rem;
    font-size: 11px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}`;

const MenuPlug = styled.div`
  ${MenuStyled}:hover & {
    background-color: ${props => props.options.menu.hover};
    color: #ffffff
  }`;

const MenuExternal = styled.a`
  ${MenuStyled}:hover & {
    background-color: ${props => props.options.menu.hover};
    color: #ffffff
  }`;

const MenuNavLink = styled(NavLink)`
  ${MenuStyled}:hover & {
    background-color: ${props => props.options.menu.hover};
    color: #ffffff
  }`;

const mapStateToProps = (state) => ({
    authData: state.auth.data,
    messageNotification: state.message.notification,
    state
});

const MenuItem = ({state, link, title, visible, dropdown, target_blank, authData, layoutOptions, messageNotification, mt, isShow, url, setIsShow, mid, secret, isteacher, islibrarian, ...props}) => {
    const isExternalLink = link => !!link && target_blank  

    const getFile = async (fileName) => {
        try {
          const response = await api.get(`/menu_file_settings/files/${fileName}`);
          if (response.status == 200) {
            window.open(`${BASE_URL_WITHOUT_API}/${response.data.url}`, '_blank');
          }
        }
        catch (e) {
        }
      }

    const getRareLink = (link) => {
        if (link == 'secret') {
            if (secret == 3) {
                return '/booksecretdoc';
            } else {
                return '';
            }
        }
        
        if (link == '/library_card/') {
            // if (secret == 3) {
                return `${resolveLink(link)}${mid}`;
            // } 
        }
        
        if (link == '/state_secret_book') {
            // if (secret == 3) {
                return '/state_secret_book';
            // } else {
            //     return '';
            // }
        }

        if (link == '/answerstat') {
            if (isteacher == true) {
                return '/answerstat';
            } else {
                return '';
            }
        }

        if (link == '/exempttests') {
            if (islibrarian == true) {
                return '/exempttests';
            } else {
                return '';
            }
        }
         
        if (link) {
            return resolveLink(link)
        }
    }

    const resolveLink = (link) => {
        if (typeof link === "function") {
            return link(state)
        } else {
            return link;
        }
    }

    return (
        <>
            
            {
            <MenuStyled
                className={classNames(styles.container)}
                options={layoutOptions}
                isShow={isShow}
            >

                {(url) ?
                    <>
                        {target_blank ? (
                            <MenuExternal
                            onClick={() => getFile(url)}
                            style={{color: `${visible ? '' : '#b5b5b5'}`}}
                            href="#"
                                className={'menuItem'}
                                options={layoutOptions}
                                // target="_blank"
                                rel="noopener noreferrer"
                            >
                                {title}
                            </MenuExternal>
                        ) : (
                            <MenuNavLink
                                to={resolveLink(url)}
                                exact
                                activeClassName={'menuActive'}
                                options={layoutOptions}
                                className={'menuItem'}
                            >
                                {title}
                            </MenuNavLink>
                        )}
                    </>
                    :
                    <MenuPlug 
                        className={'menuItem'} 
                        style={{color: `${visible ? '' : '#b5b5b5'}`}} 
                        options={layoutOptions}>
                        {title}
                    </MenuPlug>
                }

                {dropdown && (
                    <div
                        className={classNames(styles.dropdown)}
                    >
                        
                        {dropdown.map((item, index) =>
                            resolveLink(item.url) ?
                                <div key={index}>
                                    {item.target_blank ? (
                                        <a
                                            // href={resolveLink(item.url)}
                                            key={`${index.url}_${index}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            // onClick={() => setIsShow(false)}
                                            onClick={() => getFile(item.url)}
                                        >
                                            <div
                                                style={{color: `${item.visible ? '' : '#b5b5b5'}`}}
                                                className={item.isSubItem ? styles.dropdownSubItem : styles.dropdownItem}
                                            >
                                                {item.title}
                                            </div>
                                        </a>
                                    ) : (
                                        <Link to={getRareLink(item.url)} key={index}>
                                            <div
                                                style={{color: `${item.visible ? '' : '#b5b5b5'}`}}
                                                className={item.isSubItem ? styles.dropdownSubItem : styles.dropdownItem}
                                                onClick={() => setIsShow(false)}>
                                                {item.title}
                                                {item.url === "/message" && messageNotification > 0 &&
                                                <span
                                                    className={classNames("bg_red", styles.notification)}>{messageNotification}</span>
                                                }
                                            </div>
                                        </Link>
                                    )}
                                </div>
                                : null
                        )}
                    </div>
                )}
            </MenuStyled>
            }
        </>
    );
};

MenuItem.propTypes = {
    messageNotification: T.number,
};

export default connect(mapStateToProps, null)(MenuItem);
