import React from "react";
import { Table } from "react-bootstrap";
import TableRow from "../TableRow/TableRow";
import "./BookSupplyStandartsTable.css";

const BookSupplyStandartsTable = props => {
  return (
    <Table bordered className="book_supply_standarts_table">
      <thead className="book_supply_standarts_table_thead">
        <tr>
          <th>№</th>
          <th>Название издания</th>
          <th>1 курс</th>
          <th>2 курс</th>
          <th>3 курс</th>
          <th>4 курс</th>
          <th>5 курс</th>
        </tr>
      </thead>
      <tbody className="book_supply_standarts_table_tbody">
        <TableRow />
        <TableRow />
        <TableRow />
        <TableRow />
        <TableRow />
      </tbody>
    </Table>
  );
};

export default BookSupplyStandartsTable;
