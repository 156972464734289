import {LIBRARY_FUNDS_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const libraryFundRequestStart = (libraryId) => ({
    type: `${LIBRARY_FUNDS_REQUEST}${START}`,
    payload: libraryId
  });

export const libraryFundRequestSuccess = (data) => ({
  type: `${LIBRARY_FUNDS_REQUEST}${SUCCESS}`,
  payload: data
});

export const libraryFundRequestError = (data) => ({
  type: `${LIBRARY_FUNDS_REQUEST}${ERROR}`,
  payload: data
});
