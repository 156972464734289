import {
    LIBRARY_INSTITUTIONS_REQUEST,
    SET_LIBRARY_MATERIALS_SEARCH_DATA,
    SET_LOCATION_CHANGE,
    START
} from "../../../actions/action-types";
import {RESULTS_PER_PAGE} from "../../../../constants/pagination";

const initialState = {
    page: 1,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0
};

export const libraryMaterialsSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LIBRARY_MATERIALS_SEARCH_DATA:
            return action.payload;
        case `${LIBRARY_INSTITUTIONS_REQUEST}${START}`:
            return initialState;
        case SET_LOCATION_CHANGE:
            return initialState;
        default:
            return state;
    }
};
