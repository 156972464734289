export const authorsToOptions = authorList => {
  if (authorList) {
    return authorList.map(({ name, id }) => ({
      label: name ? name : 'Нет имени',
      value: id,
    }))
  }
}

export const litersToOptions = literList => {
  if (literList && literList.length > 0) {
    return literList?.map(({ name, id }) => ({
      label: name ? name : 'Нет литера',
      value: id,
    }))
  }
}

export const rolesToOptions = rolesList => {
  if (rolesList) {
    return rolesList.map(({ name, pmid }) => ({
      label: name,
      value: pmid,
    }))
  }
}

export const categoriesToOptions = categoriesList => {
  if (categoriesList) {
    return categoriesList.map(({ name, id }) => ({
      label: name,
      value: id,
    }))
  }
}

export const disciplineToOptions = disciplineList => {
  if (disciplineList) {
    return disciplineList.map(({ title, cid }) => ({
      label: title,
      value: cid,
    }))
  }
}

export const outsecrecyToOptions = list => {
  if (list) {
    return list.map(({ name, id }) => ({
      label: name,
      value: id,
    }))
  }
}

export const optionsToAuthors = options =>
  options
    ? options.map(({ value, label }) => ({
        id: value,
        name: label,
      }))
    : []

export const optionsToLiters = options =>
  options
    ? options.map(({ value, label }) => ({
        id: value,
        name: label,
      }))
    : []

export const optionsToDiscipline = options => (options ? options.map(({ value }) => value) : [])

export const optionsToCategories = options =>
  options
    ? options.map(({ value, label }) => ({
        id: value,
        name: label,
      }))
    : []

export const publishersToOptions = publishersList => {
  if (publishersList) {
    return publishersList.map(({ name, id }) => ({
      label: name,
      value: id,
    }))
  }
}

export const optionsToPublishers = options =>
  options
    ? options.map(({ value, label }) => ({
        id: value,
        name: label,
      }))
    : []

export const optionsToEOP = options =>
  options
    ? options.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    : []

export const institutionsToOptions = institutionsList => {
  if (institutionsList) {
    return institutionsList.map(({ name, idclient, id }) => ({
      label: name,
      value: id || idclient,
    }))
  }
}

export const institutionToOption = institution => ({
  label: institution.name,
  value: institution.idclient,
})

export const optionsToInstitutions = options =>
  options
    ? options.map(({ value, label }) => ({
        id: value,
        name: label,
      }))
    : []

export const peopleToOption = user => ({
  label: user.displayname,
  value: user.mid,
})
export const categoryToOption = (category) => ({
  label: category?.name,
  value: category?.id
})

export const optionsToPeople = options =>
  options
    ? options.map(({ value, label }) => ({
        mid: value,
        displayname: label,
      }))
    : []


export const responsibleToOptions = rolesList => {
  if (rolesList) {
    return rolesList.map(({ mid, lastname }) => ({
      label: lastname,
      value: mid,
    }))
  }
}