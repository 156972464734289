export const htmlpdf = `
<style>
    #report * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Times New Roman", sans-serif;
    line-height: 180%;
    font-size: 12px;
}
#report h1,
#report h2,
#report h3,
#report h4,
#report h5,
#report h6 {
    font-weight: 700;
}
#report p {
    line-height: 18px;
}
#report .h13 {
    height: 13px;
}
#report .w-100 {
    width: 100%;
}
#report .w150 {
    width: 150px;
}
#report {
    position: fixed;
    top: 0px;
    left: -9000px;
    width: 770px;
    margin: 25px auto;
    background: #fff;
    padding: 45px 35px;
}
#report .block-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 15px;
    margin-left: auto;
    width: 40%;
}
#report .text-center {
    text-align: center;
}
#report .text-right {
    text-align: right;
}
#report .uppercase {
    text-transform: uppercase;
}
#report .underline {
    border-bottom: 1px solid #000;
    padding: 10px 0px 0px 0px;
}
#report .small {
    font-size: 10px;
}
#report .d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
#report .flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
#report .flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
#report .justify-content-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
#report .justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
#report .align-items-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
#report .flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
#report .flex-0 {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
}
#report .mt-15 {
    margin-top: 15px;
}
#report .mb-15 {
    margin-bottom: 15px;
}
#report .ml-15 {
    margin-left: 15px;
}
#report .mr-15 {
    margin-right: 15px;
}
#report .mt-25 {
    margin-top: 25px;
}
#report .ml-25 {
    margin-left: 25px;
}
#report .pd-0 {
    padding: 0;
}
#report table {
    border-collapse: collapse;
}
#report tr, #report tr td, #report tr th {
    text-align: center !important;
}
#report table tr td:first-child,
#report table tr th:first-child {
    width: 5%;
}
#report table tr td:nth-child(2),
#report table tr th:nth-child(2) {
    width: 20%;
}
#report table tr td:nth-child(3),
#report table tr th:nth-child(3) {
    width: 15%;
}
#report table tr td:nth-child(4),
#report table tr th:nth-child(4) {
    width: 15%;
}
#report table tr td:nth-child(5),
#report table tr th:nth-child(5) {
    width: 15%;
}
#report table tr td:nth-child(6),
#report table tr th:nth-child(6) {
    width: 15%;
}
#report table tr td:nth-child(7),
#report table tr th:nth-child(7) {
    width: 15%;
}

    </style>
    <div id="report">
        <div>
            <p class="text-right">гриф секретности</p>
            <p class="text-right">экз. № 1</p>
        </div>
        <div class="block-right mb-25">
            <h3 class="uppercase text-center mb-15">РАЗРЕШАЮ УНИЧТОЖИТЬ</h1>
            <p>Начальник штаба войсковой части</p>
            <p class="underline w-100" id="orgName"></p>
            <div>
                <p class="underline w-100">
                    <span id="nomenclatureName"></span>
                    <span id="nomenclatureApprovalPerson"></span>
                </p>
                <p>(воинское звание, подпись, инициал имени, фамилия)</p>
            </div>
            <p class="underline w-100 mb-15"></p>
            <p id="nomenclatureApprovalDate">«___»__________20__г.</p>
        </div>
        <div class="mt-25">
            <h2 class="text-center uppercase">Акт</h1>
            <h3 class="text-center mb-15">Об уничтожении носителей сведений,
            составляющих государственную тайну</h1>
        </div>
        <div class="d-flex justify-content-between mb-15">
            <p id="nomenclatureSignDate">«___»__________20__г.10</p>
            <p>г. Москва</p>
        </div>
        <div class="d-flex ml-25">
            <div><p>Комиссия в составе</p></div>
            <div class="flex-1">
                <p class="underline pd-0 ml-25" id="commission">состав комиссии</p>
                <p class="small ml-25 text-center">(воинское звание, фамилия, инициалы председателя и членов комиссии) </p>
            </div>
        </div>
        <div>
            <p>произвела отбор следующих секретных документов для уничтожения как утративших практическое
            значение и не имеющих исторической и научной ценности:</p>
        </div>
        <table border="1" cols="7" class="text-center mt-15" align="center" id="bookTable">
            <thead>
            <tr>
                <th>№ п./п.</th>
                <th>Наименование документа</th>
                <th>Номер документа</th>
                <th>Гриф секретности</th>
                <th>Количество экземпляров</th>
                <th>Номер экземпляра</th>
                <th>Общее количество листов</th>
            </tr>
            </thead>
        </table>
        <div class="d-flex mt-15 ml-25">
            <p>Всего подлежит уничтожению </p>
            <div class="w150">
                <p class="underline pd-0 ml-15 mr-15 h13"> </p>
                <p class="small text-center h20">(прописью)</p>
            </div>
            <p>наименований.</p>
        </div>
        <p class="mb-15">Записи акта с учетными данными сверены.</p>
        <div class="d-flex ml-25">
            <p class="w150">Председатель комиссии</p>
            <div class="flex-1">
                <p class="underline pd-0 ml-15 mr-15 w-100">
                    <span id="positioncommissionchairman"></span>
                    <span id="namecommissionchairman"></span>
                </p>
                <p class="text-center">(воинское звание, подпись, инициал имени, фамилия)</p>
            </div>
        </div>
        <div class="d-flex ml-25">
            <p class="w150">Члены комиссии</p>
            <div class="d-flex flex-column flex-0">
                <div>
                    <p class="underline pd-0 ml-15 mr-15 w-100">
                        <span id="positionmembercommission"></span>
                        <span id="namecommissionmember"></span>
                    </p>
                    <p class="text-center">(воинское звание, подпись, инициал имени, фамилия)</p>
                </div>
            </div>
        </div>
    </div>`;