import React from 'react'
import T from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import styles from './MessageItem.module.css'
import classNames from 'classnames'
import { resolveDateWithTimeZone } from '../../../utils/Dates/resolveDateWithTimeZone'
import ReadingListItemFiles from '../../Reading/List/FileList'
import { Button } from 'antd'
import PopoverOuter from '../../UtulityComponents/Popover'

const MessageItem = ({ item, updateMessage, setModalData }) => {
  const { ts, tsread, message, id, subject, title } = item

  const messageDate = resolveDateWithTimeZone(new Date(ts).toLocaleString())
  const shortMessage = message && message.length > 100 ? `${message.substring(0, 100)}...` : message

  const openModal = () => {
    setModalData(item)
    if (!tsread) {
      updateMessage(id)
    }
  }

  return (
    <div className={classNames(styles.shortItem, !tsread && styles.notRead)}>
      <Row>
        <Col md={2} lg={2} onClick={openModal}>
          <span>{messageDate}</span>
        </Col>
        <Col md={2} lg={2} onClick={openModal}>
          <span>{subject}</span>
        </Col>
        <Col md={3} lg={4} onClick={openModal}>
          <span style={{overflowWrap: 'anywhere'}}>{title ? title : ''}</span>
        </Col>
        <Col md={2} lg={2} onClick={openModal}>
          <span style={{overflowWrap: 'anywhere'}}>{shortMessage ? shortMessage : ''}</span>
        </Col>
        <Col md={2} lg={2}>
          {item.files && item.files.length ? (
            <PopoverOuter
              trigger={'click'}
              title='Файлы сообщения'
              content={<ReadingListItemFiles fileList={item.files} bookId={item.bookid} />}
            >
              <Button>Открыть</Button>
            </PopoverOuter>
          ) : (
            <Button disabled>Нет файлов</Button>
          )}
        </Col>
      </Row>
    </div>
  )
}

MessageItem.propTypes = {
  item: T.object,
  setModalData: T.func,
}

export default MessageItem
