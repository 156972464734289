import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import styles from '../DocumentsModal.module.css'
import InvNumbersModalSearchItem from '../../../InvNumbers/Modal/SearchItem'
import InventoryEditBookForm from '../../EditBookForm'
import BookSearchForm from '../../../Covers/SearchForm'
import SpinnerOuter from '../../../UtulityComponents/SpinnerOuter'
import { inventoryBookSaveRequestStart } from '../../../../redux/actions/inventory/books/save'
import { inventoryBookDeleteRequestStart } from '../../../../redux/actions/inventory/books/delete'
import { createNotification } from '../../../notification/notification'
import { api } from '../../../../App'
import { invNumbersCountRequestStart } from '../../../../redux/actions/invNumbers/count'
import { setInvNumbersFormData } from '../../../../redux/actions/invNumbers/form'
import { setInvNumbersSearchData } from '../../../../redux/actions/invNumbers/search'
import InventoryBooksTable from '../../BooksTable'

const mapStateToProps = ({ invNumbers, inventory }) => ({
  searchResults: invNumbers.list,
  count: invNumbers.count,
  loader: invNumbers.loader,
  formData: invNumbers.form,
  searchData: invNumbers.search,
  loadSuccess: invNumbers.loadSuccess,
  books: inventory.books,
  bookDistribute: inventory.bookDistribute,
  booksloader: inventory.modalLoader,
})

const mapDispatchToProps = {
  requestList: invNumbersCountRequestStart,
  setFormData: setInvNumbersFormData,
  setSearchData: setInvNumbersSearchData,
  deleteBook: inventoryBookDeleteRequestStart,
  saveBook: inventoryBookSaveRequestStart,
}

const InventoryEdit = ({
  loader,
  modalData,
  currentBook,
  books,
  setCurrentBook,
  saveBook,
  loadSuccess,
  deleteBook,
  searchOpen,
  onAddBook,
  formData,
  setFormData,
  searchResults,
  onSelectFromSearch,
  requestBooks,
  booksloader,
  onCreateNewBook,
  formValidity,
  documentid,
  requestList,
}) => {
  const QR_CODE_CONNECTION_PORT = 'ws://localhost:18988/qrscanner'

  const onBookSave = data => {
    const params = {
      inventory: Number(data.documentid),
      book_instance: Number(data.book_instance),
      amount: Number(data.amount),
    }
    saveBook(params)
    setCurrentBook(null)
  }

  const onBookDelete = data => {
    deleteBook(data)
    setCurrentBook(null)
  }

  useEffect(() => {
    const qrCodeScannerConnection = new WebSocket(QR_CODE_CONNECTION_PORT)

    qrCodeScannerConnection.onmessage = event => {
      const data = JSON.parse(event.data)
      const { status, qrdata } = data
      if (status === 'QR') {
        const scannedIsnstanceId = qrdata.split(';')[0]
        const body = {
          book_instance: parseInt(scannedIsnstanceId),
          inventory: documentid,
          amount: 1,
        }
        createNotification('success', 'Отсканированный экземпляр добавлен')
        const bookInstance = qrdata.substr(0, qrdata.indexOf(';'))
        const isBook = books.find(e => e.book_instance == bookInstance)
        return isBook
          ? api.put(`/Inventory_spcf/${isBook.id}`, { amount: +isBook.amount + 1 }).then(() => {
              requestBooks(documentid)
            })
          : api.post('/Inventory_spcf', body).then(() => {
              requestBooks(documentid)
            })
      }
    }

    return () => {
      qrCodeScannerConnection.close(1000, 'React компонент демонтирован')
    }
  }, [])

  const NoSearchResults = () => (
    <div className={classNames('text-center', styles.textNotification)}>
      Ничего не найдено, Вы можете &nbsp;
      <span onClick={onCreateNewBook} className={styles.textLink}>
        создать новую библиографическую запись
      </span>
    </div>
  )

  const SearchResultsOutput = () => (
    <>
      <div className={styles.title}>Результаты поиска</div>
      {searchResults.map((item, index) => (
        <InvNumbersModalSearchItem data={item} key={index} onClick={onSelectFromSearch} />
      ))}
    </>
  )

  return (
    <div>
      <div className={styles.title}>Книги</div>
      <SpinnerOuter isLoading={booksloader && !books.length} />
      {!!books.length && (
        <InventoryBooksTable
          requestBooks={requestBooks}
          documentid={documentid}
          data={books}
          modalData={modalData}
          handleSelect={setCurrentBook}
          selectedId={currentBook ? currentBook.id : null}
        />
      )}
      {currentBook && (
        <InventoryEditBookForm
          data={currentBook}
          onSave={onBookSave}
          modalData={modalData}
          onDelete={currentBook.id ? onBookDelete : () => setCurrentBook(null)}
          setData={setCurrentBook}
        />
      )}

      {/* <RolePermissionChecker permissionName={'Режимы,Инвентаризация'}>
        <Button type='primary' onClick={onAddBook} disabled={!formValidity}>
          {!searchOpen ? 'Добавить книгу' : 'Закрыть поиск'}
        </Button>
      </RolePermissionChecker> */}

      {searchOpen && (
        <>
          <BookSearchForm
            data={formData}
            setData={setFormData}
            disableAlphabet={true}
            onSubmit={requestList}
            simplified
            hideInstitutions
            noVerifyStatus
          />

          <SpinnerOuter isLoading={loader} />

          {loadSuccess && (searchResults.length === 0 ? <NoSearchResults /> : <SearchResultsOutput />)}
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryEdit)
