import { all } from 'redux-saga/effects';
import rubricatorListSaga from "./list/rubricatorListSaga";
import rubricatorSaveWatcher from "./save/rubricatorSaveSaga";
import rubricatorDeleteSaga from "./delete/rubricatorDeleteSaga";
import rubricatorParentSearchSaga from "./parentSearch/rubricatorParentSearchSaga";
import rubricatorParentItemSaga from "./parentItem/rubricatorParentItemSaga";

export default function* rubricatorSaga() {
  yield all([
    rubricatorListSaga(),
    rubricatorSaveWatcher(),
    rubricatorDeleteSaga(),
    rubricatorParentSearchSaga(),
    rubricatorParentItemSaga()
  ]);
}